import React, { useState, useMemo, useEffect, forwardRef } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import Header from '../Header'
import Footer from '../Footer'
import { API_URL } from '../config';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import { AES, enc } from "crypto-js";
import $ from 'jquery';

import { useParams  } from "react-router-dom";
// import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
function EditOrder() {
  const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
  const encryptedAccessToken = localStorage.getItem("access_token");
  const decryptedAccessToken = encryptedAccessToken
    ? AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8)
    : null;
  const [suppliers, setSupplier]=useState([]);
  useEffect(() => {

    fetchData5();
  }, [decryptedAccessToken]);

  const fetchData5 = async () => {
    try {
      const response = await axios.get(API_URL+'get/supplier', {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      const data = response.data;
      console.log(data)
      setSupplier(data);

    } catch (error) {
      console.error(error);
    }
  };
  // const body=$(window).height()
  // const header=$("#header").height()
  // const nav=$(".navbar").height()
  // const footer=$(".footer").height()
  // const total_height=header+nav+footer;
  // const window_height=body-total_height;
  // $(".priceAccount div:nth-child(3)").css({"min-height":(window_height)+"px"})
  const [datas,setDatas]=useState([]);
  const [agents,setAgents]=useState([]);
  const [contractNumber, setContractNumber]=useState([]);
  const [company,setCompany]=useState([]);
  const [accountNames, setAccountName]=useState([]);
  const [agent, setAgent]=useState([]);

  const [startDate1, setStartDate1] = useState(new Date());
  const datepick1 =
    startDate1.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    startDate1.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    startDate1.toLocaleDateString("en-US", { day: "2-digit" });
  const CustomInput1 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));
  const [startDate2, setStartDate2] = useState(new Date());
  const datepick2 =
    startDate2.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    startDate1.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    startDate1.toLocaleDateString("en-US", { day: "2-digit" });
  const CustomInput2 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));

  const [startDate3, setStartDate3] = useState(new Date());
  const datepick3 =
    startDate3.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    startDate1.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    startDate1.toLocaleDateString("en-US", { day: "2-digit" });
  const CustomInput3 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));

  const [startDate4, setStartDate4] = useState(new Date());
  const datepick4 =
    startDate4.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    startDate1.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    startDate1.toLocaleDateString("en-US", { day: "2-digit" });
  const CustomInput4 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));

  const [startDate5, setStartDate5] = useState(new Date());
  const datepick5 =
    startDate5.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    startDate5.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    startDate5.toLocaleDateString("en-US", { day: "2-digit" });
  const CustomInput5 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));
  const [startDate6, setStartDate6] = useState(new Date());
  const datepick6 =
    startDate6.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    startDate6.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    startDate6.toLocaleDateString("en-US", { day: "2-digit" });
  const CustomInput6 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));
  const [startDate7, setStartDate7] = useState(new Date());
  const datepick7 =
    startDate7.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    startDate7.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    startDate7.toLocaleDateString("en-US", { day: "2-digit" });
  const CustomInput7 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));
  const [startDate8, setStartDate8] = useState(new Date());
  const datepick8 =
    startDate8.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    startDate8.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    startDate8.toLocaleDateString("en-US", { day: "2-digit" });
  const CustomInput8 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));
  const [startDate9, setStartDate9] = useState(new Date());
  const datepick9 =
    startDate9.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    startDate9.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    startDate9.toLocaleDateString("en-US", { day: "2-digit" });
  const CustomInput9 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));
const [startDate10, setStartDate10] = useState(new Date());
  const datepick10 =
    startDate10.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    startDate10.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    startDate10.toLocaleDateString("en-US", { day: "2-digit" });
  const CustomInput10 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));

const [startDate11, setStartDate11] = useState(new Date());
  const datepick11 =
    startDate11.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    startDate11.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    startDate11.toLocaleDateString("en-US", { day: "2-digit" });
  const CustomInput11 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));

const [startDate12, setStartDate12] = useState(new Date());
  const datepick12 =
    startDate12.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    startDate12.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    startDate12.toLocaleDateString("en-US", { day: "2-digit" }) +
    startDate12.toLocaleTimeString('en-US',' ');
  const CustomInput12 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));
const [startDate13, setStartDate13] = useState(new Date());
  const datepick13 =
    startDate13.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    startDate13.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    startDate13.toLocaleDateString("en-US", { day: "2-digit" });
    // startDate13.toLocaleTimeString('en-US',' ');
  const CustomInput13 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));
  const [startDate14, setStartDate14] = useState(new Date());
  const datepick14 =
    startDate14.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    startDate14.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    startDate14.toLocaleDateString("en-US", { day: "2-digit" }) +
    startDate14.toLocaleTimeString('en-US',' ');
  const CustomInput14 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));
const [startDate15, setStartDate15] = useState(new Date());
  const datepick15 =
    startDate15.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    startDate15.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    startDate15.toLocaleDateString("en-US", { day: "2-digit" }) +
    startDate15.toLocaleTimeString('en-US',' ');
  const CustomInput15 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));
const [startDate16, setStartDate16] = useState(new Date());
  const datepick16 =
    startDate16.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    startDate16.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    startDate16.toLocaleDateString("en-US", { day: "2-digit" }) +
    startDate16.toLocaleTimeString('en-US',' ');
  const CustomInput16 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));

const [startDate17, setStartDate17] = useState(new Date());
  const datepick17 =
    startDate17.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    startDate17.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    startDate17.toLocaleDateString("en-US", { day: "2-digit" }) +
    startDate17.toLocaleTimeString('en-US',' ');
  const CustomInput17 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));

const [startDate18, setStartDate18] = useState(new Date());
  const datepick18 =
    startDate18.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    startDate18.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    startDate18.toLocaleDateString("en-US", { day: "2-digit" }) +
    startDate18.toLocaleTimeString('en-US',' ');
  const CustomInput18 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));
const [startDate19, setStartDate19] = useState(new Date());
  const datepick19 =
    startDate19.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    startDate19.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    startDate19.toLocaleDateString("en-US", { day: "2-digit" }) +
    startDate19.toLocaleTimeString('en-US',' ');
  const CustomInput19 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));

  const params = useParams()
  const idUpdate=params.id

useEffect(() => {

    fetchDataSupplier();
  }, [decryptedAccessToken]);

  const fetchDataSupplier = async () => {
    try {
      const response = await axios.get(API_URL+'all/accounts', {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      const data = response.data.accounts;
      setAccountName(data)
       console.log('Supplier'+ JSON.stringify(data))
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {

    fetchDataSupplier1();
  }, [decryptedAccessToken]);

  const fetchDataSupplier1 = async () => {
    try {
      const response = await axios.get(API_URL+'get/surveyor', {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      const data = response.data;
      setAgent(data)
       console.log('Supplier'+ JSON.stringify(data))
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {

    fetchDataAccount();
  }, [decryptedAccessToken]);

  const fetchDataAccount = async () => {
    try {
      const response = await axios.get(API_URL+'get/all/rwoodcompany', {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      const data = response.data.rwoodcompany;
      setCompany(data)
       console.log(data)
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {

    fetchDataAccount1();
  }, [decryptedAccessToken]);

  const fetchDataAccount1 = async () => {
    try {
      const response = await axios.get(API_URL+'get/all/contract', {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      const data = response.data.contract;
      setContractNumber(data)
       console.log(data)
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {

    fetchDataAccount2();
  }, [decryptedAccessToken]);

  const fetchDataAccount2 = async () => {
    try {
      const response = await axios.get(API_URL+'get/agent', {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      const data = response.data;
      setAgents(data)
       console.log(data)
    } catch (error) {
      console.error(error);
    }
  };
 const { register, handleSubmit,setValue, reset, formState: { errors } } = useForm();
 const [update,setUpdate]=useState({});
 useEffect(() => {

   fetchDataOrder();
}, [decryptedAccessToken]);

 const fetchDataOrder = async () => {
   try {
     const response = await axios.get(API_URL+'get/orderbyID/'+idUpdate, {
       headers: { Authorization: `Bearer ${decryptedAccessToken}`, 'Content-Type': 'application/json'  },        }
     );
     const data = response.data.order_detail[0];

     setUpdate(data);
     setValue('company', data.company);
setValue('currency', data.currency);
setValue('exchange_rate', data.exchange_rate);
setValue('account_name', data.account_name);
setValue('contract_number', data.contract_number);
setValue('order_start_date', data.order_start_date);
setValue('status', data.status);
setValue('opportunity', data.opportunity);
setValue('remark', data.remark);
setValue('locked', data.locked);
setValue('activated_by', data.activated_by);
setValue('activated_date', datepick1);
setValue('order_record_type', data.order_record_type);
setValue('supplier', data.supplier);
setValue('contract_quantity_MT', data.contract_quantity_MT);
// shipment_qty', data.shipment_qty);
// shipment_quality_tolerance', data.shipment_quality_tolerance);
// bl_no', data.bl_no);
setValue('purchase_contract_1', data.purchase_contract_1);
setValue('supplier_contract_refernce_1', data.supplier_contract_refernce_1);
setValue('purchase_contract_status_1', data.purchase_contract_status_1);
setValue('purchase_contract_2', data.purchase_contract_2);
setValue('supplier_contract_refernce_2', data.supplier_contract_refernce_2);
setValue('purchase_contract_status_2', data.purchase_contract_status_2);

setValue('product_specification', data.product_specification);
setValue('laycan_from_date', datepick2);
setValue('laycan_to_date', data.laycan_to_date);
setValue('eta_date', datepick3);
setValue('laycan_month', data.laycan_month);
setValue('laycan_year', data.laycan_year);
setValue('shipment_month', datepick5);
setValue('ship_month', data.ship_month);
setValue('laycan_period', data.laycan_period);
setValue('business_confirm_to_supplier_no', data.business_confirm_to_supplier_no);
setValue('business_confirm_to_supplier_date', datepick6);

setValue('vessel_name', data.vessel_name);
setValue('barge_name', data.barge_name);
setValue('tug_boat_no', data.tug_boat_no);
setValue('tonnage', data.tonnage);
setValue('vessel_nomination_date', datepick7);
setValue('vessel_type', data.vessel_type);
setValue('vessel_nomination_confirm_date', datepick8);
setValue('vessel_nomination_description', data.vessel_nomination_description);
setValue('etb_date', datepick9);
setValue('jakarta_team_member', data.jakarta_team_member);
setValue('loading_rate', data.loading_rate);
setValue('buyer_despatch_demurrage_rate', data.buyer_despatch_demurrage_rate);
// supplier_despatch_demurrage_rate', data.supplier_despatch_demurrage_rate);
setValue('days_allowed_for_laycan', data.days_allowed_for_laycan);
setValue('loading_at', data.loading_at);
setValue('vessel_acceptance_date', datepick9);
setValue('vessel_acceptance_confirm_date', datepick10);
setValue('load_port_agent1', data.load_port_agent1);
setValue('load_port_agent2', data.load_port_agent2);
setValue('load_port_agent3', data.load_port_agent3);
setValue('agent_appointment_date', datepick12);
setValue('shipping_instruction_no', data.shipping_instruction_no);
setValue('shipping_instruction_date', '2023-08-22');
setValue('destination', data.destination);
setValue('destination_port', data.destination_port);
setValue('pre_stowage', data.pre_stowage);
setValue('vessel_arrival_date_and_time', datepick13);
   } catch (error) {
     console.error(error);
   }
 };

 const handleChange = (e) => {
   const { name, value } = e.target;
   setUpdate((prevData) => ({
     ...prevData,
     [name]: value
   }));
 };
 const onSubmit = data =>{

    const encryptedAccessToken = localStorage.getItem('access_token');
    const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';
    const responseData={
     company:data.company,
currency:data.currency,
exchange_rate:data.exchange_rate,
account_name:data.account_name,
contract_number:data.contract_number,
order_start_date:data.order_start_date,
status:data.status,
opportunity:data.opportunity,
remark:data.remark,
locked:data.locked,
activated_by:data.activated_by,
activated_date:datepick1,
order_record_type:data.order_record_type,
supplier:data.supplier,
contract_quantity_MT:data.contract_quantity_MT,
// shipment_qty:data.shipment_qty,
// shipment_quality_tolerance:data.shipment_quality_tolerance,
// bl_no:data.bl_no,
purchase_contract_1:data.purchase_contract_1,
supplier_contract_refernce_1:data.supplier_contract_refernce_1,
purchase_contract_status_1:data.purchase_contract_status_1,
purchase_contract_2:data.purchase_contract_2,
supplier_contract_refernce_2:data.supplier_contract_refernce_2,
purchase_contract_status_2:data.purchase_contract_status_2,

product_specification:data.product_specification,
laycan_from_date:datepick2,
laycan_to_date:data.laycan_to_date,
eta_date:datepick3,
laycan_month:data.laycan_month,
laycan_year:data.laycan_year,
shipment_month:datepick5,
ship_month:data.ship_month,
laycan_period:data.laycan_period,

business_confirm_to_supplier_no:data.business_confirm_to_supplier_no,
business_confirm_to_supplier_date:datepick6,

vessel_name:data.vessel_name,
barge_name:data.barge_name,
tug_boat_no:data.tug_boat_no,
tonnage:data.tonnage,
vessel_nomination_date:datepick7,
vessel_type:data.vessel_type,
vessel_nomination_confirm_date:datepick8,
vessel_nomination_description:data.vessel_nomination_description,
etb_date:datepick9,
jakarta_team_member:data.jakarta_team_member,
loading_rate:data.loading_rate,
buyer_despatch_demurrage_rate:data.buyer_despatch_demurrage_rate,
// supplier_despatch_demurrage_rate:data.supplier_despatch_demurrage_rate,
days_allowed_for_laycan:data.days_allowed_for_laycan,
loading_at:data.loading_at,
vessel_acceptance_date:datepick9,
vessel_acceptance_confirm_date:datepick10,
load_port_agent1:data.load_port_agent1,
load_port_agent2:data.load_port_agent2,
load_port_agent3:data.load_port_agent3,
agent_appointment_date:datepick12,
shipping_instruction_no:data.shipping_instruction_no,
shipping_instruction_date:'2023-08-22',
destination:data.destination,
destination_port:data.destination_port,
pre_stowage:data.pre_stowage,
vessel_arrival_date_and_time:datepick13
    }
    console.log(JSON.stringify(responseData))
     if (encryptedAccessToken) {
       const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);

     fetch(API_URL+'update/order/'+idUpdate, {
               method: "PUT",
               body: JSON.stringify(responseData),
               headers: { Authorization: `Bearer ${decryptedAccessToken}`,
               'Content-Type': 'application/json' },
             })
               .then((response) => response.json())

               .then((response) => {
                console.log('Test'+JSON.stringify(response))
                toast.success('Order Update Successfully', {
                  position: "bottom-center",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                  });
                setTimeout(() => {
                  reset();
                }, 300);

               })

             }

     };

  return (

    <div className="priceAccount" id='orders'>
    <Header/>
    <div>
    <div className="page-header" id=''>
    <div>
      <h2 className="main-content-title tx-24 mg-b-5">Edit Order</h2>
      <ol className="breadcrumb">
        <li className="breadcrumb-item active" aria-current="page">Dashboard / Order / Edit Order</li>
      </ol>
    </div>
    <div className="d-flex">
      <div className="justify-content-center">

        {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
         <a href='/account/add-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</a>
        </button> */}

      </div>
    </div>
  </div>
     <Form onSubmit={handleSubmit(onSubmit)} id='price'>
      <Row className="mb-3">
      <h4 className="heading">Order Details</h4>
      <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Company">
    <Form.Select aria-label="Floating label select example"
    name="company"
    placeholder="Company"
    defaultValue={update.company || ""}  onChange={handleChange}
    {...register("company", { required:false })}
    >
      <option value='' >Select</option>
       {
          company.map((x)=>{
              return(
                  <option value={x.company_name}>{x.company_name}</option>
              )
          })
       }
    </Form.Select>
    {errors.company && <span>Required Account Name</span>}
  </FloatingLabel>
              </Form.Group>

      <Form.Group as={Col} md="6">
               <input type='checkbox' name='locked'/>Locked
              </Form.Group>

              <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Currency">
    <Form.Select aria-label="Floating label select example"
    name="currency"
    placeholder="Currency"
    {...register("currency", { required:false })}
    >
      <option value='' >Select</option>
      <option value='USD' >USD</option>
      <option value='AED' >IDR</option>
    </Form.Select>
    </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Activated By"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Activated By"
            name="activated_by"
            defaultValue={update.activated_by || ""}  onChange={handleChange}
            {...register("activated_by", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Exchange Rate"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Exchange Rate"
            name="exchange_rate"
            defaultValue={update.exchange_rate || ""}  onChange={handleChange}
            {...register("exchange_rate", {valueAsNumber:true, required:false })}
            />
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Activated Date"
            className="mb-3 datepick"


          >
            <DatePicker
      selected={startDate1}
      onChange={(date) => setStartDate1(date)}
      customInput={<CustomInput1 />}
      dayClassName={() => "example-datepicker-day-class"}
      popperClassName="example-datepicker-class"
      todayButton="TODAY"
      dateFormat='yyyy-MM-dd'
      placeholderText='Activated Date'
    />
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Account name">
    <Form.Select aria-label="Floating label select example"
    name="account_name"
    id='account_name'
    placeholder="Account name"
    defaultValue={update.account_name || ""}  onChange={handleChange}
    {...register("account_name", { required:true })}
    >
      <option value='' >Select</option>
       {
          accountNames.map((x)=>{
              return(
                  <option value={x.account_name}>{x.account_name}</option>
              )
          })
       }
    </Form.Select>
  </FloatingLabel>
              </Form.Group>
{/*
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Order Record Type"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Order Record Type"
            name="order_record_type"
            {...register("order_record_type", { required:false })}
            />
          </FloatingLabel>
        </Form.Group> */}
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Buyer Full Name"
            className="mb-3"

          >

            <Form.Control type="text"
            placeholder="Buyer Full Name"
            name="buyer_ful_name"
            defaultValue={update.buyer_full_name || ""}  onChange={handleChange}
            {...register("buyer_ful_name", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Supplier">
    <Form.Select aria-label="Floating label select example"
    name="supplier"
    placeholder="Supplier"
    defaultValue={update.supplier || ""}  onChange={handleChange}
    {...register("supplier", { required: false })}
    >
      <option value='' >Select</option>
       {
          suppliers.map((x)=>{
              return(
                  <option value={x.account_name}>{x.account_name}</option>
              )
          })
       }
    </Form.Select>
    {errors.supplier && <span>Required</span>}
  </FloatingLabel>
              </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Contract Quantity MT"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Contract Quantity MT"
            name="contract_quantity_MT"
            defaultValue={update.contract_quantity_MT || ""}  onChange={handleChange}
            {...register("contract_quantity_MT", {valueAsNumber:true, required:false })}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Contract Number">
    <Form.Select aria-label="Floating label select example"
    name="contract_number"
    placeholder="Contract Number"
    defaultValue={update.contract_number || ""}  onChange={handleChange}
    {...register("contract_number", { required: false })}
    >
      <option value='' >Select</option>
       {
          contractNumber.map((x)=>{
              return(
                  <option value={x.contract_number}>{x.contract_number}</option>
              )
          })
       }
    </Form.Select>
  </FloatingLabel>
              </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Contract Shipment"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Contract Shipment"
            name="contract_shipment"
            defaultValue={update.contract_shipment || ""}  onChange={handleChange}
            {...register("contract_shipment", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Supplier Name"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Supplier Name"
            name="supplier_name"
            defaultValue={update.supplier_name || ""}  onChange={handleChange}
            {...register("supplier_name", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>

               <Form.Group as={Col} md="12">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Supplier Address"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Supplier Address"
                    style={{ height: "100px" }}
                    name="supplier_address"
                    defaultValue={update.supplier_address || ""}  onChange={handleChange}
                    {...register("supplier_address", { required: false })}
                  />
                  {errors.supplier_address && <p id="text-area">Required</p>}
                </FloatingLabel>

              </Form.Group>
      </Row>
      <Row>
        <h4 className="heading">Purchase Contract Information</h4>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Purchase Contract 1"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Purchase Contract 1"
            name="purchase_contract_1"
            defaultValue={update.purchase_contract_1 || ""}  onChange={handleChange}
           {...register("purchase_contract_1", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Purchase Contract 2"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Purchase Contract 2"
            name="purchase_contract_2"
            defaultValue={update.purchase_contract_2 || ""}  onChange={handleChange}
           {...register("purchase_contract_2", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Supplier Contract Refernce 1"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Supplier Contract Refernce 1"
            name="supplier_contract_refernce_1"
            defaultValue={update.supplier_contract_refernce_1 || ""}  onChange={handleChange}
             {...register("supplier_contract_refernce_1", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Supplier Contract Refernce 2"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Supplier Contract Refernce 2"
            name="supplier_contract_refernce_2"
            defaultValue={update.supplier_contract_refernce_2 || ""}  onChange={handleChange}
             {...register("supplier_contract_refernce_2", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Purchase Contract Status 1"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Purchase Contract Status 1"
            name="purchase_contract_status_1"
            defaultValue={update.purchase_contract_status_1 || ""}  onChange={handleChange}
            {...register("purchase_contract_status_1", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Purchase Contract Status 2"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Purchase Contract Status 2"
            name="purchase_contract_status_2"
            defaultValue={update.purchase_contract_status2 || ""}  onChange={handleChange}
            {...register("purchase_contract_status_2", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>

      </Row>

      <Row>
        <h4  className="heading">Laycan Information</h4>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Product Specification"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Product Specification"
            name="product_specification"
            defaultValue={update.product_specification || ""}  onChange={handleChange}
           {...register("product_specification", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Laycan From Date"
            className="mb-3 datepick"


          >
            <DatePicker
      selected={startDate2}
      onChange={(date) => setStartDate2(date)}
      customInput={<CustomInput2 />}
      dayClassName={() => "example-datepicker-day-class"}
      popperClassName="example-datepicker-class"
      todayButton="TODAY"
      dateFormat='yyyy-MM-dd'
      placeholderText='Laycan From Date'
    />
          </FloatingLabel>
        </Form.Group>


        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="ETA Date"
            className="mb-3 datepick"


          >
            <DatePicker
      selected={startDate3}
      onChange={(date) => setStartDate3(date)}
      customInput={<CustomInput3 />}
      dayClassName={() => "example-datepicker-day-class"}
      popperClassName="example-datepicker-class"
      todayButton="TODAY"
      dateFormat='yyyy-MM-dd'
      placeholderText='ETA Date'
    />
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Laycan Month"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="laycan_month"
                    placeholder="Laycan Month"

                    {...register("laycan_month", { required: false })}
                  >
                   <option value="January">January</option><option value="February">February</option><option value="March">March</option><option value="April">April</option><option value="May">May</option><option value="June">June</option><option value="July">July</option><option value="August">August</option><option value="September">September</option><option value="October">October</option><option value="November">November</option><option value="December">December</option>
                  </Form.Select>
                  {errors.laycan_month && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>



        <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Laycan Year"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="laycan_year"
                    placeholder="Laycan Year"
                    defaultValue={update.laycan_year || ""}  onChange={handleChange}
                    {...register("laycan_year", { required: false })}
                  >
                    <option value="">--None--</option><option value="2021">2021</option><option value="2022">2022</option><option value="2023">2023</option><option value="2024">2024</option><option value="2025">2025</option><option value="2026">2026</option><option value="2015">2015</option><option value="2018">2018</option><option value="2019">2019</option><option value="2020">2020</option>
                  </Form.Select>
                  {errors.laycan_year && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Shipment Month"
            className="mb-3 datepick"


          >
            <DatePicker
      selected={startDate5}
      onChange={(date) => setStartDate5(date)}
      customInput={<CustomInput5 />}
      dayClassName={() => "example-datepicker-day-class"}
      popperClassName="example-datepicker-class"
      todayButton="TODAY"
      dateFormat='yyyy-MM-dd'
      placeholderText='Shipment Month'
    />
          </FloatingLabel>
        </Form.Group>


        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Laycan Period"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Laycan Period"
            name="laycan_period"
            defaultValue={update.laycan_period || ""}  onChange={handleChange}
            {...register("laycan_period", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>

      </Row>

      <Row>
        <h4 className="heading">Business Confirmation to Supplier</h4>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Business Confirm To Supplier No"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Business Confirm To Supplier No"
            name="business_confirm_to_supplier_no"
            defaultValue={update.business_confirm_to_supplier_no || ""}  onChange={handleChange}
           {...register("business_confirm_to_supplier_no", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>



        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Business Confirm To Supplier Date"
            className="mb-3 datepick"


          >
            <DatePicker
      selected={startDate6}
      onChange={(date) => setStartDate6(date)}
      customInput={<CustomInput6 />}
      dayClassName={() => "example-datepicker-day-class"}
      popperClassName="example-datepicker-class"
      todayButton="TODAY"
      dateFormat='yyyy-MM-dd'
      placeholderText='Business Confirm To Supplier Date'
    />
          </FloatingLabel>
        </Form.Group>


      </Row>

      <Row>
        <h4  className="heading">Vessel Nomination</h4>


        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Vessel Name"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Vessel Name"
            name="vessel_name"
            defaultValue={update.vessel_name || ""}  onChange={handleChange}
           {...register("vessel_name", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Barge Name"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Barge Name"
            name="barge_name"
            defaultValue={update.barge_name || ""}  onChange={handleChange}
           {...register("barge_name", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>


        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="TUG Boat No"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="TUG Boat No"
            name="tug_boat_no"
            defaultValue={update.tug_boat_no || ""}  onChange={handleChange}
           {...register("tug_boat_no", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Tonnage"
            className="mb-3"
          >

            <Form.Control type="number"
            placeholder="Tonnage"
            name="tonnage"
            defaultValue={update.tonnage || ""}  onChange={handleChange}
           {...register("tonnage", {valueAsNumber:true, required:false })}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Vessel Nomination Date"
            className="mb-3 datepick"


          >
            <DatePicker
      selected={startDate7}
      onChange={(date) => setStartDate7(date)}
      customInput={<CustomInput7 />}
      dayClassName={() => "example-datepicker-day-class"}
      popperClassName="example-datepicker-class"
      todayButton="TODAY"
      dateFormat='yyyy-MM-dd'
      placeholderText="Vessel Nomination Date"
    />
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Vessel Type"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="vessel_type"
                    placeholder="Vessel Type"
                    defaultValue={update.vessel_type || ""}  onChange={handleChange}
                    {...register("vessel_type", { required: false })}
                  >
                    <option value="">--None--</option><option value="Geared (GnG)">Geared (GnG)</option><option value="Gearless">Gearless</option><option value="Geared">Geared</option><option value="Barge">Barge</option>
                  </Form.Select>
                  {errors.vessel_type && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Vessel Nomination Confirm Date"
            className="mb-3 datepick"


          >
            <DatePicker
      selected={startDate8}
      onChange={(date) => setStartDate8(date)}
      customInput={<CustomInput8 />}
      dayClassName={() => "example-datepicker-day-class"}
      popperClassName="example-datepicker-class"
      todayButton="TODAY"
      dateFormat='yyyy-MM-dd'
      placeholderText="Vessel Nomination Confirm Date"
    />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Vessel Nomination Description"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Vessel Nomination Description"
            name="vessel_nomination_description"
            defaultValue={update.vessel_nomination_description || ""}  onChange={handleChange}
           {...register("vessel_nomination_description", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="ETB Date"
            className="mb-3 datepick"


          >
            <DatePicker
      selected={startDate9}
      onChange={(date) => setStartDate9(date)}
      customInput={<CustomInput9 />}
      dayClassName={() => "example-datepicker-day-class"}
      popperClassName="example-datepicker-class"
      todayButton="TODAY"
      dateFormat='yyyy-MM-dd'
      placeholderText='ETB Date'
    />
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Jakarta Team Member"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Jakarta Team Member"
            name="jakarta_team_member"
            defaultValue={update.jakarta_team_member || ""}  onChange={handleChange}
           {...register("jakarta_team_member", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Loading Rate"
            className="mb-3"
          >

            <Form.Control type="number"
            placeholder="Loading Rate"
            name="loading_rate"
            defaultValue={update.loading_rate || ""}  onChange={handleChange}
           {...register("loading_rate", {valueAsNumber:true, required:false })}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Buyer Despatch Demurrage Rate"
            className="mb-3"
          >

            <Form.Control type="number"
            placeholder="Buyer Despatch Demurrage Rate"
            name="buyer_despatch_demurrage_rate"
            defaultValue={update.buyer_despatch_demurrage_rate || ""}  onChange={handleChange}
           {...register("buyer_despatch_demurrage_rate", {valueAsNumber:true, required:false })}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Days Allowed For Laycan"
            className="mb-3"
          >

            <Form.Control type="number"
            placeholder="Days Allowed For Laycan"
            name="days_allowed_for_laycan"
            defaultValue={update.days_allowed_for_laycan || ""}  onChange={handleChange}
           {...register("days_allowed_for_laycan", {valueAsNumber:true, required:false })}
            />
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Loading At"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Loading At"
            name="loading_at"
            defaultValue={update.loading_at || ""}  onChange={handleChange}
           {...register("loading_at", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>

      </Row>
      <Row>
        <h4  className="heading">Vessel Aceptance</h4>



         <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Vessel Acceptance Date"
            className="mb-3 datepick"


          >
            <DatePicker
      selected={startDate10}
      onChange={(date) => setStartDate10(date)}
      customInput={<CustomInput10 />}
      dayClassName={() => "example-datepicker-day-class"}
      popperClassName="example-datepicker-class"
      todayButton="TODAY"
      dateFormat='yyyy-MM-dd'
      placeholderText='Vessel Acceptance Date'
    />
          </FloatingLabel>
        </Form.Group>

         <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Vessel Acceptance Confirm Date"
            className="mb-3 datepick"


          >
            <DatePicker
      selected={startDate11}
      onChange={(date) => setStartDate11(date)}
      customInput={<CustomInput11 />}
      dayClassName={() => "example-datepicker-day-class"}
      popperClassName="example-datepicker-class"
      todayButton="TODAY"
      dateFormat='yyyy-MM-dd'
      placeholderText='Vessel Acceptance Confirm Date'
    />
          </FloatingLabel>
        </Form.Group>

      </Row>
      <Row>
        <h4  className="heading">Agent Appointment</h4>

         <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Load Port Agent 1">
    <Form.Select aria-label="Floating label select example"
    name="load_port_agent1"
    id='account_name'
    placeholder="Load Port Agent 1"
    defaultValue={update.load_port_agent1 || ""}  onChange={handleChange}
    {...register("load_port_agent1", { required:true })}
    >
      <option value='' >Select</option>
       {
          agents.map((x)=>{
              return(
                  <option value={x.account_name}>{x.account_name}</option>
              )
          })
       }
    </Form.Select>
  </FloatingLabel>
              </Form.Group>

         <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Load Port Agent 2">
    <Form.Select aria-label="Floating label select example"
    name="load_port_agent2"
    id='account_name'
    placeholder="Load Port Agent 2"
    defaultValue={update.load_port_agent2 || ""}  onChange={handleChange}
    {...register("load_port_agent2", { required:true })}
    >
      <option value='' >Select</option>
       {
          agents.map((x)=>{
              return(
                  <option value={x.account_name}>{x.account_name}</option>
              )
          })
       }
    </Form.Select>
  </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Load Port Agent 3">
    <Form.Select aria-label="Floating label select example"
    name="load_port_agent3"
    id='account_name'
    placeholder="Load Port Agent 3"
    defaultValue={update.load_port_agent3 || ""}  onChange={handleChange}
    {...register("load_port_agent3", { required:true })}
    >
      <option value='' >Select</option>
       {
          agents.map((x)=>{
              return(
                  <option value={x.account_name}>{x.account_name}</option>
              )
          })
       }
    </Form.Select>
  </FloatingLabel>
              </Form.Group>
         <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Agent Appointment Date"
            className="mb-3 datepick"


          >
            <DatePicker
      selected={startDate12}
      onChange={(date) => setStartDate12(date)}
      customInput={<CustomInput12 />}
      dayClassName={() => "example-datepicker-day-class"}
      popperClassName="example-datepicker-class"
      todayButton="TODAY"
      dateFormat='yyyy-MM-dd hh:mm:aa'
      timeIntervals={15}
    timeCaption="Time"
    showTimeSelect
      placeholderText='Agent Appointment Date'
    />
          </FloatingLabel>
        </Form.Group>

      </Row>

      <Row>
        <h4  className="heading">Shipping Instruction</h4>


        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Shipping Instruction No"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Shipping Instruction No"
            name="shipping_instruction_no"
            defaultValue={update.shipping_instruction_no || ""}  onChange={handleChange}
           {...register("shipping_instruction_no", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Shipping Instruction Date"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Shipping Instruction Date"
            name="shipping_instruction_date"
            defaultValue={update.shipping_instruction_date || ""}  onChange={handleChange}
           {...register("shipping_instruction_date", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Destination"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Destination"
            name="destination"
            defaultValue={update.destination || ""}  onChange={handleChange}
           {...register("destination", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Destination Port"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Destination Port"
            name="destination_port"
            defaultValue={update.destination_port || ""}  onChange={handleChange}
           {...register("destination_port", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>

      </Row>
      <Row>
        <h4  className="heading">Vessel Tracking</h4>


        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Pre-stowage"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Pre-stowage"
            name="pre_stowage"
            defaultValue={update.pre_stowage || ""}  onChange={handleChange}
           {...register("pre_stowage", {valueAsNumber:true, required:false })}
            />
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="ETA notice recieved date 1"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="ETA notice recieved date 1"
            defaultValue={update.eta_notice_recieved_date1 || ""}  onChange={handleChange}
            name="eta_notice_recieved_date1"
           {...register("eta_notice_recieved_date1", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="ETA notice recieved date 2"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="ETA notice recieved date 2"
            name="eta_notice_recieved_date2"
            defaultValue={update.eta_notice_recieved_date2 || ""}  onChange={handleChange}
    {...register("eta_notice_recieved_date2", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="ETA notice recieved date 3"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="ETA notice recieved date 3"
            name="eta_notice_recieved_date3"
            defaultValue={update.eta_notice_recieved_date3 || ""}  onChange={handleChange}
            {...register("eta_notice_recieved_date3", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="ETA notice recieved date 4"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="ETA notice recieved date 4"
            name="eta_notice_recieved_date4"
            defaultValue={update.eta_notice_recieved_date4 || ""}  onChange={handleChange}
           {...register("eta_notice_recieved_date4", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="ETA notice recieved date 5"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="ETA notice recieved date 5"
            name="eta_notice_recieved_date5"
            defaultValue={update.eta_notice_recieved_date5 || ""}  onChange={handleChange}
           {...register("eta_notice_recieved_date5", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>
      </Row>


      <Row>
        <h4  className="heading">Vessel Arrival/Loading Commence</h4>


          <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Vessel arrival date and time"
            className="mb-3 datepick"


          >
            <DatePicker
      selected={startDate13}
      onChange={(date) => setStartDate13(date)}
      customInput={<CustomInput13 />}
      dayClassName={() => "example-datepicker-day-class"}
      popperClassName="example-datepicker-class"
      todayButton="TODAY"
      dateFormat='yyyy-MM-dd hh:mm:aa'
      timeIntervals={15}
    timeCaption="Time"
    showTimeSelect
      placeholderText='Vessel arrival date and time'
    />
          </FloatingLabel>
        </Form.Group>


<Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Commence Loading Date and time"
            className="mb-3 datepick"


          >
            <DatePicker
      selected={startDate14}
      onChange={(date) => setStartDate14(date)}
      customInput={<CustomInput14 />}
      dayClassName={() => "example-datepicker-day-class"}
      popperClassName="example-datepicker-class"
      todayButton="TODAY"
      dateFormat='yyyy-MM-dd hh:mm:aa'
      timeIntervals={15}
    timeCaption="Time"
    showTimeSelect
      placeholderText='Commence Loading Date and time'
    />
          </FloatingLabel>
        </Form.Group>
         <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Completion loading date and time"
            className="mb-3 datepick"


          >
            <DatePicker
      selected={startDate15}
      onChange={(date) => setStartDate15(date)}
      customInput={<CustomInput15 />}
      dayClassName={() => "example-datepicker-day-class"}
      popperClassName="example-datepicker-class"
      todayButton="TODAY"
      dateFormat='yyyy-MM-dd hh:mm:aa'
      timeIntervals={15}
    timeCaption="Time"
    showTimeSelect
      placeholderText='Completion loading date and time'
    />
          </FloatingLabel>
        </Form.Group>
      <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Sailing date and time"
            className="mb-3 datepick"


          >
            <DatePicker
      selected={startDate16}
      onChange={(date) => setStartDate16(date)}
      customInput={<CustomInput16 />}
      dayClassName={() => "example-datepicker-day-class"}
      popperClassName="example-datepicker-class"
      todayButton="TODAY"
      dateFormat='yyyy-MM-dd hh:mm:aa'
      timeIntervals={15}
    timeCaption="Time"
    showTimeSelect
      placeholderText='Sailing date and time'
    />
          </FloatingLabel>
        </Form.Group>
      <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Notice of readiness confirm date"
            className="mb-3 datepick"


          >
            <DatePicker
      selected={startDate17}
      onChange={(date) => setStartDate17(date)}
      customInput={<CustomInput17 />}
      dayClassName={() => "example-datepicker-day-class"}
      popperClassName="example-datepicker-class"
      todayButton="TODAY"
      dateFormat='yyyy-MM-dd hh:mm:aa'
      timeIntervals={15}
    timeCaption="Time"
    showTimeSelect
      placeholderText='Notice of readiness confirm date'
    />
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="NOR Tendered date and time"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="NOR Tendered date and time"
            name="nor_tendered_date_and_time"
            defaultValue={update.nor_tendered_date_and_time || ""}  onChange={handleChange}
           {...register("nor_tendered_date_and_time", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="NOR acceptance date and time"
            className="mb-3 datepick"


          >
            <DatePicker
      selected={startDate18}
      onChange={(date) => setStartDate18(date)}
      customInput={<CustomInput18 />}
      dayClassName={() => "example-datepicker-day-class"}
      popperClassName="example-datepicker-class"
      todayButton="TODAY"
      dateFormat='yyyy-MM-dd hh:mm:aa'
      timeIntervals={15}
    timeCaption="Time"
    showTimeSelect
      placeholderText='NOR acceptance date and time'
    />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Free Detention Time"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Free Detention Time"
            name="free_detention_time"
            defaultValue={update.free_detention_time || ""}  onChange={handleChange}
           {...register("free_detention_time", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Time to count for laytime"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Time to count for laytime"
            name="time_to_count_for_laytime"
            defaultValue={update.time_to_count_for_laytime || ""}  onChange={handleChange}
           {...register("time_to_count_for_laytime", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Turn Time"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Turn Time"
            name="turn_time"
            defaultValue={update.turn_time || ""}  onChange={handleChange}
           {...register("turn_time", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>

      </Row>
      <Row>
        <h4  className="heading">Mate Receipt</h4>


        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Mate recieved date and time"
            className="mb-3 datepick"


          >
            <DatePicker
      selected={startDate19}
      onChange={(date) => setStartDate19(date)}
      customInput={<CustomInput19 />}
      dayClassName={() => "example-datepicker-day-class"}
      popperClassName="example-datepicker-class"
      todayButton="TODAY"
      dateFormat='yyyy-MM-dd hh:mm:aa'
      timeIntervals={15}
    timeCaption="Time"
    showTimeSelect
      placeholderText='Mate recieved date and time'
    />
          </FloatingLabel>
        </Form.Group>

               </Row>
               <Row>
        <h4 className="heading">Nomination of Independent Surveyour (Purchase)</h4>

        <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Surveyor Agent">
    <Form.Select aria-label="Floating label select example"
    name="surveyor_agent"
    placeholder="Surveyor Agent"
    defaultValue={update.surveyor_agent || ""}  onChange={handleChange}
     {...register("surveyor_agent", { required:false })}
    >
      <option value='' >Select</option>
       {
          agent.map((x)=>{
              return(
                  <option value={x.account_name}>{x.account_name}</option>
              )
          })
       }
    </Form.Select>
  </FloatingLabel>
   </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Nomination of independent surveyor date"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Nomination of independent surveyor date"
            name="nomination_of_independent_surveyor_date"
            defaultValue={update.nomination_of_independent_surveyor_date || ""}  onChange={handleChange}
           {...register("nomination_of_independent_surveyor_date", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>
               </Row>

               <Row>
        <h4 className="heading">Nomination of Additional Surveyour (Sales)</h4>

        <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Rwood surveyor agent">
    <Form.Select aria-label="Floating label select example"
    name="rwood_surveyor_agent"
    placeholder="Rwood surveyor agent"
    defaultValue={update.rwood_surveyor_agent || ""}  onChange={handleChange}
     {...register("rwood_surveyor_agent", { required:false })}
    >
      <option value='' >Select</option>
       {
          agent.map((x)=>{
              return(
                  <option value={x.account_name}>{x.account_name}</option>
              )
          })
       }
    </Form.Select>
  </FloatingLabel>
   </Form.Group>

   <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Buyer surveyor agent">
    <Form.Select aria-label="Floating label select example"
    name="buyer_surveyor_agent"
    placeholder="Buyer surveyor agent"
    defaultValue={update.buyer_surveyor_agent || ""}  onChange={handleChange}
     {...register("buyer_surveyor_agent", { required:false })}
    >
      <option value='' >Select</option>
       {
          agent.map((x)=>{
              return(
                  <option value={x.account_name}>{x.account_name}</option>
              )
          })
       }
    </Form.Select>
  </FloatingLabel>
   </Form.Group>

   <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Additional surveyor by rwood confirm date"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Additional surveyor by rwood confirm date"
            name="additional_surveyor_by_rwood_confirm_date"
            defaultValue={update.additional_surveyor_by_rwood_confirm_date || ""}  onChange={handleChange}
           {...register("additional_surveyor_by_rwood_confirm_date", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>
         </Row>

         <Row>
        <h4 className="heading">Summary Documents</h4>

   <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Total LC transer cost"
            className="mb-3"
          >

            <Form.Control type="number"
            placeholder="Total LC transer cost"
            name="total_lc_transer_cost"
            defaultValue={update.total_lc_transer_cost || ""}  onChange={handleChange}
           {...register("total_lc_transer_cost", {valueAsNumber:true, required:false })}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Total other bank cost"
            className="mb-3"
          >

            <Form.Control type="number"
            placeholder="Total other bank cost"
            name="total_other_bank_cost"
            defaultValue={update.total_other_bank_cost || ""}  onChange={handleChange}
           {...register("total_other_bank_cost", {valueAsNumber:true, required:false })}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Total adding confiramtion charges"
            className="mb-3"
          >

            <Form.Control type="number"
            placeholder="Total adding confiramtion charges"
            name="total_adding_confiramtion_charges"
            defaultValue={update.total_adding_confiramtion_charges || ""}  onChange={handleChange}
           {...register("total_adding_confiramtion_charges", {valueAsNumber:true, required:false })}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Total Interest"
            className="mb-3"
          >

            <Form.Control type="number"
            placeholder="Total Interest"
            name="total_interest"
            defaultValue={update.total_interest || ""}  onChange={handleChange}
           {...register("total_interest", {valueAsNumber:true, required:false })}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Total VAT on bank charges"
            className="mb-3"
          >

            <Form.Control type="number"
            placeholder="Total VAT on bank charges"
            name="total_vat_on_bank_charges"
            defaultValue={update.total_vat_on_bank_charges || ""}  onChange={handleChange}
           {...register("total_vat_on_bank_charges", {valueAsNumber:true, required:false })}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Total bill handling charges"
            className="mb-3"
          >

            <Form.Control type="number"
            placeholder="Total bill handling charges"
            name="total_bill_handling_charges"
            defaultValue={update.total_bill_handling_charges || ""}  onChange={handleChange}
           {...register("total_bill_handling_charges", {valueAsNumber:true, required:false })}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Total bank charges"
            className="mb-3"
          >

            <Form.Control type="number"
            placeholder="Total bank charges"
            name="total_bank_charges"
            defaultValue={update.total_bank_charges || ""}  onChange={handleChange}
           {...register("total_bank_charges", {valueAsNumber:true, required:false })}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Total master LC Qty"
            className="mb-3"
          >

            <Form.Control type="number"
            placeholder="Total master LC Qty"
            name="total_master_lc_qty"
            defaultValue={update.total_master_lc_qty || ""}  onChange={handleChange}
           {...register("total_master_lc_qty", {valueAsNumber:true, required:false })}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Total BL Qty"
            className="mb-3"
          >

            <Form.Control type="number"
            placeholder="Total BL Qty"
            name="total_bl_qty"
            defaultValue={update.total_bl_qty || ""}  onChange={handleChange}
           {...register("total_bl_qty", {valueAsNumber:true, required:false })}
            />
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="BL Date"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="BL Date"
            name="bl_date"
            defaultValue={update.bl_date || ""}  onChange={handleChange}
           {...register("bl_date", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="All actual payment recieved date"
            className="mb-3"
          >

            <Form.Control type="number"
            placeholder="All actual payment recieved date"
            name="all_actual_payment_recieved_date"
            defaultValue={update.all_actual_payment_recieved_date || ""}  onChange={handleChange}
           {...register("all_actual_payment_recieved_date", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="All document discount date"
            className="mb-3"
          >

            <Form.Control type="number"
            placeholder="All document discount date"
            name="all_document_discount_date"
            defaultValue={update.all_document_discount_date || ""}  onChange={handleChange}
           {...register("all_document_discount_date", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="All expected value date"
            className="mb-3"
          >

            <Form.Control type="number"
            placeholder="All expected value date"
            name="all_expected_value_date"
            defaultValue={update.all_expected_value_date || ""}  onChange={handleChange}
         {...register("all_expected_value_date", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>

         </Row>


         <Row>
        <h4 className='heading'>Supplier Despatch/Demmurage LTC</h4>

   <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Supplier despacth or demmurage"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Supplier despacth or demmurage"
            name="supplier_despacth_or_demmurage"
            defaultValue={update.supplier_despacth_or_demmurage || ""}  onChange={handleChange}
           {...register("supplier_despacth_or_demmurage", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Supplier despatch or demurrage amount"
            className="mb-3"
          >

            <Form.Control type="number"
            placeholder="Supplier despatch or demurrage amount"
            name="supplier_despatch_or_demurrage_amount"
            defaultValue={update.supplier_despatch_or_demurrage_amount || ""}  onChange={handleChange}
           {...register("supplier_despatch_or_demurrage_amount", {valueAsNumber:true, required:false })}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Supplier laytime time used"
            className="mb-3"
          >

            <Form.Control type="number"
            placeholder="Supplier laytime time used"
            name="supplier_laytime_time_used"
            defaultValue={update.supplier_laytime_time_used || ""}  onChange={handleChange}
           {...register("supplier_laytime_time_used", {valueAsNumber:true, required:false })}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Supplier laytime time saved exceed"
            className="mb-3"
          >

            <Form.Control type="number"
            placeholder="Supplier laytime time saved exceed"
            name="supplier_laytime_time_saved_exceed"
            defaultValue={update.supplier_laytime_time_saved_exceed || ""}  onChange={handleChange}
           {...register("supplier_laytime_time_saved_exceed", {valueAsNumber:true, required:false })}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Supplier despatch demurrage status"
            className="mb-3"
          >

            <Form.Control type="number"
            placeholder="Supplier despatch demurrage status"
            name="supplier_despatch_demurrage_status"
            defaultValue={update.supplier_despatch_demurrage_status || ""}  onChange={handleChange}
           {...register("supplier_despatch_demurrage_status", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="LTC sent to supplier date"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="LTC sent to supplier date"
            name="ltc_sent_to_supplier_date"
            defaultValue={update.ltc_sent_to_supplier_date || ""}  onChange={handleChange}
           {...register("ltc_sent_to_supplier_date", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Supplier LTC  to RW"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Supplier LTC  to RW"
            name="supplier_ltc_to_rw"
            defaultValue={update.supplier_ltc_to_rw || ""}  onChange={handleChange}
           {...register("supplier_ltc_to_rw", {valueAsNumber:true, required:false })}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Supplier Confirm Date"
            className="mb-3"
          >

            <Form.Control type="number"
            placeholder="Supplier Confirm Date"
            name="supplier_confirm_date"
            defaultValue={update.supplier_confirm_date || ""}  onChange={handleChange}
           {...register("supplier_confirm_date", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Supplier remark despacth demmurage"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Supplier remark despacth demmurage"
            name="supplier_remark_despacth_demmurage"
            defaultValue={update.supplier_remark_despacth_demmurage || ""}  onChange={handleChange}
           {...register("supplier_remark_despacth_demmurage", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="BL quantity laytime calculations"
            className="mb-3"
          >

            <Form.Control type="number"
            placeholder="BL quantity laytime calculations"
            name="bl_quantity_laytime_calculations"
            defaultValue={update.bl_quantity_laytime_calculations || ""}  onChange={handleChange}
           {...register("bl_quantity_laytime_calculations", {valueAsNumber:true, required:false })}
            />
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Supplier LTC Reccount"
            className="mb-3"
          >

            <Form.Control type="number"
            placeholder="Supplier LTC Reccount"
            name="supplier_ltc_rec_count"
            defaultValue={update.supplier_ltc_rec_count || ""}  onChange={handleChange}
           {...register("supplier_ltc_rec_count", {valueAsNumber:true, required:false })}
            />
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Supplier Check Status"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Supplier Check Status"
            name="supplier_check_status"
            defaultValue={update.supplier_check_status || ""}  onChange={handleChange}
           {...register("supplier_check_status", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Count of desp demm DN"
            className="mb-3"
          >

            <Form.Control type="number"
            placeholder="Count of desp demm DN"
            name="count_of_desp_demm_dn"
            defaultValue={update.count_of_desp_demm_dn || ""}  onChange={handleChange}
           {...register("count_of_desp_demm_dn", {valueAsNumber:true, required:false })}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Count of Other DN"
            className="mb-3"
          >

            <Form.Control type="number"
            placeholder="Count of Other DN"
            name="count_of_other_dn"
            defaultValue={update.count_of_other_dn || ""}  onChange={handleChange}
           {...register("count_of_other_dn", {valueAsNumber:true, required:false })}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Supplier final dem or despatch payable"
            className="mb-3"
          >

            <Form.Control type="number"
            placeholder="Supplier final dem or despatch payable"
            name="supplier_final_dem_or_despatch_payable"
            defaultValue={update.supplier_final_dem_or_despatch_payable || ""}  onChange={handleChange}
           {...register("supplier_final_dem_or_despatch_payable", {valueAsNumber:true, required:false })}
            />
          </FloatingLabel>
        </Form.Group>



        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Supplier final other payable"
            className="mb-3"
          >

            <Form.Control type="number"
            placeholder="Supplier final other payable"
            name="supplier_final_other_payable"
            defaultValue={update.supplier_final_other_payable || ""}  onChange={handleChange}
           {...register("supplier_final_other_payable", {valueAsNumber:true, required:false })}
            />
          </FloatingLabel>
        </Form.Group>


        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Supplier despatch demurrage amount"
            className="mb-3"
          >

            <Form.Control type="number"
            placeholder="Supplier despatch demurrage amount"
            name="supplier_despatch_demurrage_amount"
            defaultValue={update.supplier_despatch_demurrage_amount || ""}  onChange={handleChange}
           {...register("supplier_despatch_demurrage_amount", { valueAsNumber:true, required:false })}
            />
          </FloatingLabel>
        </Form.Group>


         </Row>
         <Row>
        <h4 className="heading">Buyer Despatch/Demmurage LTC</h4>

   <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Buyer despacth or demmurage"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Buyer despacth or demmurage"
            name="buyer_despacth_or_demmurage"
            defaultValue={update.buyer_despacth_or_demmurage || ""}  onChange={handleChange}
           {...register("buyer_despacth_or_demmurage", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Buyer despatch or demurrage amount"
            className="mb-3"
          >

            <Form.Control type="number"
            placeholder="Buyer despatch or demurrage amount"
            name="buyer_despatch_or_demurrage_amount"
            defaultValue={update.buyer_despatch_or_demurrage_amount || ""}  onChange={handleChange}
           {...register("buyer_despatch_or_demurrage_amount", {valueAsNumber:true, required:false })}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Buyer laytime time used"
            className="mb-3"
          >

            <Form.Control type="number"
            placeholder="Buyer laytime time used"
            name="buyer_laytime_time_used"
            defaultValue={update.buyer_laytime_time_used || ""}  onChange={handleChange}
           {...register("buyer_laytime_time_used", {valueAsNumber:true, required:false })}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Buyer laytime time saved exceed"
            className="mb-3"
          >

            <Form.Control type="number"
            placeholder="Buyer laytime time saved exceed"
            name="buyer_laytime_time_saved_exceed"
            defaultValue={update.buyer_laytime_time_saved_exceed || ""}  onChange={handleChange}
           {...register("buyer_laytime_time_saved_exceed", {valueAsNumber:true, required:false })}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Buyer despatch demurrage status"
            className="mb-3"
          >

            <Form.Control type="number"
            placeholder="Buyer despatch demurrage status"
            name="buyer_despatch_demurrage_status"
            defaultValue={update.buyer_despatch_demurrage_status || ""}  onChange={handleChange}
           {...register("buyer_despatch_demurrage_status", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="LTC sent to buyer date"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="LTC sent to buyer date"
            name="ltc_sent_to_buyer_date"
            defaultValue={update.ltc_sent_to_buyer_date || ""}  onChange={handleChange}
           {...register("ltc_sent_to_buyer_date", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Buyer LTC  to RW"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Buyer LTC  to RW"
            name="buyer_ltc_to_rw"
            defaultValue={update.buyer_ltc_to_rw || ""}  onChange={handleChange}
           {...register("buyer_ltc_to_rw", {valueAsNumber:true, required:false })}
            />
          </FloatingLabel>
        </Form.Group>
         <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Buyer Confirm Date"
            className="mb-3"
          >

            <Form.Control type="number"
            placeholder="Buyer Confirm Date"
            name="buyer_confirm_date"
            defaultValue={update.buyer_confirm_date || ""}  onChange={handleChange}
           {...register("buyer_confirm_date", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Buyer remark despacth demmurage"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Buyer remark despacth demmurage"
            name="buyer_remark_despacth_demmurage"
            defaultValue={update.buyer_remark_despacth_demmurage || ""}  onChange={handleChange}
           {...register("buyer_remark_despacth_demmurage", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Buyer LTC Reccount"
            className="mb-3"
          >

            <Form.Control type="number"
            placeholder="Buyer LTC Reccount"
            name="buyer_ltc_rec_count"
            defaultValue={update.buyer_ltc_rec_count || ""}  onChange={handleChange}
           {...register("buyer_ltc_rec_count", {valueAsNumber:true, required:false })}
            />
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Buyer Check Status"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Buyer Check Status"
            name="buyer_check_status"
            defaultValue={update.buyer_check_status || ""}  onChange={handleChange}
           {...register("buyer_check_status", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Buyer final dem or despatch payable"
            className="mb-3"
          >

            <Form.Control type="number"
            placeholder="Buyer final dem or despatch payable"
            name="buyer_final_dem_or_despatch_payable"
            defaultValue={update.buyer_final_dem_or_despatch_payable || ""}  onChange={handleChange}
           {...register("buyer_final_dem_or_despatch_payable", {valueAsNumber:true, required:false })}
            />
          </FloatingLabel>
        </Form.Group>



        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Buyer final other payable"
            className="mb-3"
          >

            <Form.Control type="number"
            placeholder="Buyer final other payable"
            name="buyer_final_other_payable"
            defaultValue={update.buyer_final_other_payable || ""}  onChange={handleChange}
           {...register("buyer_final_other_payable", {valueAsNumber:true, required:false })}
            />
          </FloatingLabel>
        </Form.Group>


        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Buyer despatch demurrage amount"
            className="mb-3"
          >

            <Form.Control type="number"
            placeholder="Buyer despatch demurrage amount"
            name="buyer_despatch_demurrage_amount"
            defaultValue={update.buyer_despatch_demurrage_amount || ""}  onChange={handleChange}
           {...register("buyer_despatch_demurrage_amount", {valueAsNumber:true, required:false })}
            />
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Sales Invoice"
            className="mb-3"
          >

            <Form.Control type="number"
            placeholder="Sales Invoice"
            name="sales_invoice"
            defaultValue={update.sales_invoice || ""}  onChange={handleChange}
           {...register("sales_invoice", {valueAsNumber:true, required:false })}
            />
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Purchase Invoice"
            className="mb-3"
          >

            <Form.Control type="number"
            placeholder="Purchase Invoice"
            name="purchase_invoice"
            defaultValue={update.purchase_invoice || ""}  onChange={handleChange}
           {...register("purchase_invoice", {valueAsNumber:true, required:false })}
            />
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Surveyor Invoice"
            className="mb-3"
          >

            <Form.Control type="number"
            placeholder="Surveyor Invoice"
            name="surveyor_invoice"
            defaultValue={update.surveyor_invoice || ""}  onChange={handleChange}
           {...register("surveyor_invoice", {valueAsNumber:true, required:false })}
            />
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Load Port Invoice"
            className="mb-3"
          >

            <Form.Control type="number"
            placeholder="Load Port Invoice"
            name="load_port_invoice"
            defaultValue={update.load_port_invoice || ""}  onChange={handleChange}
           {...register("load_port_invoice", {valueAsNumber:true, required:false })}
            />
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="NET des dem inflow or outflow"
            className="mb-3"
          >

            <Form.Control type="number"
            placeholder="NET des dem inflow or outflow"
            name="net_des_dem_inflow_or_outflow"
            defaultValue={update.net_des_dem_inflow_or_outflow || ""}  onChange={handleChange}
           {...register("net_des_dem_inflow_or_outflow", {valueAsNumber:true, required:false })}
            />
          </FloatingLabel>
        </Form.Group>
         </Row>
      <input type="submit" className='price-submit' />
      <div className='priceDiv'><p></p><p></p></div>
    </Form>
    </div>
    <Footer/>
    </div>
  );
}

export default EditOrder;
