




import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from "react-router-dom";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { AES, enc } from 'crypto-js';
import Header from '../Header'
import Footer from '../Footer'
import { API_URL } from '../config';
import $ from 'jquery';
import axios from "axios";
// import { decryptedAccessToken, encryptedAccessToken } from "../../Token";

const AddProducts = () => {
  const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
  const encryptedAccessToken = localStorage.getItem("access_token");
  const decryptedAccessToken = encryptedAccessToken
    ? AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8)
    : null;
const [productAccount, setProductaccount]=useState([]);
const [suppliers, setsupplierData]=useState([]);
const [getPrice, setgetPrice]=useState([]);
// price
useEffect(() => {
  fetchDataPrice();
}, [decryptedAccessToken]);

const fetchDataPrice = async () => {
  try {
    const response = await axios.get(API_URL+'get/all/price_items', {
      headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
    );
    const data = response.data.priceitem;
    // console.log(data)
    setgetPrice(data);

  } catch (error) {
    console.error(error);
  }
};
const [products, setProducts]=useState([]);
useEffect(() => {
  fetchDataMaster();
}, [decryptedAccessToken]);

const fetchDataMaster = async () => {
  try {
    const response = await axios.get(API_URL+'get/allproducts', {
      headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
    );
    const data = response.data;
    console.log(data)
    setProducts(data);

  } catch (error) {
    console.error(error);
  }
};
const [contracts, setContracts]=useState([]);
useEffect(() => {
  fetchDataContract();
}, [decryptedAccessToken]);

const fetchDataContract = async () => {
  try {
    const response = await axios.get(API_URL+'get/all/contract', {
      headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
    );
    const data = response.data.contract;
    console.log(data)
    setContracts(data);

  } catch (error) {
    console.error(error);
  }
};

// product supplier
useEffect(() => {
  fetchDataSupplier();
}, [decryptedAccessToken]);

const fetchDataSupplier = async () => {
  try {
    const response = await axios.get(API_URL+'get/supplier', {
      headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
    );
    const data = response.data;
    // console.log(data)
    setsupplierData(data);

  } catch (error) {
    console.error(error);
  }
};
const [getPrice1, setgetPrice1] = useState([]);
useEffect(() => {
  fetchDataPrices();
}, [decryptedAccessToken]);

const fetchDataPrices = async () => {
  try {
    const response = await axios.get(
      API_URL+"get/all/price_items",
      {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },
      }
    );
    const data = response.data.priceitem;
    // console.log(data)
    setgetPrice1(data);
  } catch (error) {
    console.error(error);
  }
};
  // product account
  useEffect(() => {
    fetchData();
  }, [decryptedAccessToken]);

  const fetchData = async () => {
    try {
      const response = await axios.get(API_URL+'all/accounts', {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      const data = response.data.accounts;
      // console.log(data)
      setProductaccount(data);

    } catch (error) {
      console.error(error);
    }
  };

  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const onSubmit = data =>{
    const responseData={
      product_master:data.product_master,
      product_name:data.product_name,
      supplier:data.supplier,
      product_description:data.product_description,
      // product_family:data.product_family,
      product_code:data.product_code,
      purchase_contract:data.purchase_contract,
      buyer_contract:data.buyer_contract,
      product_available_for_short_term_confirm:data.product_available_for_short_term_confirm,
      buyer_name:data.buyer_name,
      contract_shipment:data.contract_shipment,
      quantity_MT:data.quantity_MT,
      product_year:data.product_year,
      product_month:data.product_month,
      // price_type:data.price_type,
      // price_basis_GAR:data.price_basis_GAR,
      // product_status:data.product_status,
      // laycan_from_date:data.laycan_from_date,
      // laycan_to_date:data.laycan_to_date,
      // port_of_loading:data.port_of_loading,
      // shipping_term:data.shipping_term,
      // vessel_type:data.vessel_type,
      // total_moisture_typical:data.total_moisture_typical,
      // total_moisture_minimum:data.total_moisture_minimum,
      // total_moisture_maximum:data.total_moisture_maximum,
      // ash_typical_arb:data.ash_typical_arb,
      // ash_minimum_arb:data.ash_minimum_arb,
      // ash_maximum_arb:data.ash_maximum_arb,
      // sulphur_typical_arb:data.sulphur_typical_arb,
      // sulphur_minimum_arb:data.sulphur_minimum_arb,
      // sulphur_maximum_arb:data.sulphur_maximum_arb,
      // volatile_matter_typical_arb:data.volatile_matter_typical_arb,
      // volatile_matter_minimum_arb:data.volatile_matter_minimum_arb,
      // volatile_matter_maximum_arb:data.volatile_matter_maximum_arb,

      // moisture_typical:data.moisture_typical,
      // moisture_minimum:data.moisture_minimum,
      // ash_typical:data.ash_typical,
      // ash_minimum:data.ash_minimum,
      // ash_maximum:data.ash_maximum,

      // volatile_matter_typical:data.volatile_matter_typical,
      // volatile_matter_minimum:data.volatile_matter_minimum,
      // volatile_matter_maximum:data.volatile_matter_maximum,
      // fixed_carbon_by_difference:data.fixed_carbon_by_difference,
      // fixed_carbon_typical:data.fixed_carbon_typical,
      // fixed_carbon_minimum:data.fixed_carbon_minimum,
      // fixed_carbon_maximum:data.fixed_carbon_maximum,

      // gross_air_dried_typical:data.gross_air_dried_typical,
      // gross_air_dried_min:data.gross_air_dried_min,
      // gross_air_dried_max:data.gross_air_dried_max,
      // gross_as_received_min:data.gross_as_received_min,
      // gross_as_received_max:data.gross_as_received_max,
      // net_as_received_min:data.net_as_received_min,
      // net_as_received_max:data.net_as_received_max,

      // hgi_typical:data.hgi_typical,
      // hgi_min:data.hgi_min,
      // hgi_max:data.hgi_max,

      // carbon_typical:data.carbon_typical,
      // carbon_min:data.carbon_min,
      // carbon_max:data.carbon_max,

      // hydrogen_typical:data.hydrogen_typical,
      // hydrogen_min:data.hydrogen_min,
      // hydrogen_max:data.hydrogen_max,

      // nitrogen_typical:data.nitrogen_typical,
      // nitrogen_min:data.nitrogen_min,
      // nitrogen_max:data.nitrogen_max,

      // sulphur_typical:data.sulphur_typical,
      // sulphur_min:data.sulphur_min,
      // sulphur_max_ultimate_analysis:data.sulphur_max_ultimate_analysis,
      // oxygen_typical:data.oxygen_typical,
      // oxygen_min:data.oxygen_min,
      // oxygen_max:data.oxygen_max,

      // sulphur_typical_adb:data.sulphur_typical_adb,
      // sulphur_min_adb:data.sulphur_min_adb,
      // product_sulphur_max:data.product_sulphur_max,

      // initial_typical:data.initial_typical,
      // initial_minimum:data.initial_minimum,
      // initial_maximum:data.initial_maximum,
      // spherical_typical:data.spherical_typical,
      // spherical_minimum:data.spherical_minimum,
      // spherical_maximum:data.spherical_maximum,
      // hemispherical_typical:data.hemispherical_typical,
      // hemispherical_minimum:data.hemispherical_minimum,
      // hemispherical_maximum:data.hemispherical_maximum,
      // flow_typical:data.flow_typical,
      // flow_minimum:data.flow_minimum,
      // flow_maximum:data.flow_maximum,

      // oxidising_initial_typical:data.oxidising_initial_typical,
      // oxidising_initial_minimum:data.oxidising_initial_minimum,
      // oxidising_initial_maximum:data.oxidising_initial_maximum,
      // oxidising_spherical_typical:data.oxidising_spherical_typical,
      // oxidising_spherical_minimum:data.oxidising_spherical_minimum,
      // oxidising_spherical_maximum:data.oxidising_spherical_maximum,
      // oxidising_hemispherical_typical:data.oxidising_hemispherical_typical,
      // oxidising_hemispherical_minimum:data.oxidising_hemispherical_minimum,
      // oxidising_hemispherical_maximum:data.oxidising_hemispherical_maximum,
      // oxidising_flow_typical:data.oxidising_flow_typical,
      // oxidising_flow_minimum:data.oxidising_flow_minimum,
      // oxidising_flow_maximum:data.oxidising_flow_maximum,

      // SiO2_Typical:data.SiO2_Typical,
      // SiO2_Minimum:data.SiO2_Minimum,
      // SiO2_Maximum:data.SiO2_Maximum,
      // Al2O3_Typical:data.Al2O3_Typical,
      // Al2O3_Minimum:data.Al2O3_Minimum,
      // Al2O3_Maximum:data.Al2O3_Maximum,
      // Fe2O3_Typical:data.Fe2O3_Typical,
      // Fe2O3_Minimum:data.Fe2O3_Minimum,
      // Fe2O3_Maximum:data.Fe2O3_Maximum,
      // CaO_Typical:data.CaO_Typical,
      // CaO_Minimum:data.CaO_Minimum,
      // CaO_Maximum:data.CaO_Maximum,
      // MgO_Typical:data.MgO_Typical,
      // MgO_Minimum:data.MgO_Minimum,
      // MgO_Maximum:data.MgO_Maximum,
      // MnO_Typical:data.MnO_Typical,
      // MnO_Minimum:data.MnO_Minimum,
      // MnO_Maximum:data.MnO_Maximum,
      // TiO2_Typical:data.TiO2_Typical,
      // TiO2_Minimum:data.TiO2_Minimum,
      // TiO2_Maximum:data.TiO2_Maximum,
      // Na2O_Typical:data.Na2O_Typical,
      // Na2O_Minimum:data.Na2O_Minimum,
      // Na2O_Maximum:data.Na2O_Maximum,
      // K2O_Typical:data.K2O_Typical,
      // K2O_Minimum:data.K2O_Minimum,
      // K2O_Maximum:data.K2O_Maximum,
      // P2O5_Typical:data.P2O5_Typical,
      // P2O5_Minimum:data.P2O5_Minimum,
      // P2O5_Maximum:data.P2O5_Maximum,
      // SO3_Typical:data.SO3_Typical,
      // SO3_Minimum:data.SO3_Minimum,
      // SO3_Maximum:data.SO3_Maximum,

      // te_arsenic_as_typical_in_air_dried_coal:data.te_arsenic_as_typical_in_air_dried_coal,
      // te_arsenic_as_minimum_in_air_dried_coal:data.te_arsenic_as_minimum_in_air_dried_coal,
      // te_arsenic_as_maximum_in_air_dried_coal:data.te_arsenic_as_maximum_in_air_dried_coal,
      // te_antimony_sb_typical_in_air_dried_coal:data.te_antimony_sb_typical_in_air_dried_coal,
      // te_antimony_sb_minimum_in_air_dried_coal:data.te_antimony_sb_minimum_in_air_dried_coal,
      // te_antimony_sb_maximum_in_air_dried_coal:data.te_antimony_sb_maximum_in_air_dried_coal,
      // te_beryllium_be_typical_in_air_dried_coal:data.te_beryllium_be_typical_in_air_dried_coal,
      // te_beryllium_be_minimum_in_air_dried_coal:data.te_beryllium_be_minimum_in_air_dried_coal,
      // te_beryllium_be_maximum_in_air_dried_coal:data.te_beryllium_be_maximum_in_air_dried_coal,
      // te_boron_b_typical_in_air_dried_coal:data.te_boron_b_typical_in_air_dried_coal,
      // te_boron_b_minimum_in_air_dried_coal:data.te_boron_b_minimum_in_air_dried_coal,
      // te_boron_b_maximum_in_air_dried_coal:data.te_boron_b_maximum_in_air_dried_coal,
      // te_cadmium_cd_typical_in_air_dried_coal:data.te_cadmium_cd_typical_in_air_dried_coal,
      // te_cadmium_cd_minimum_in_air_dried_coal:data.te_cadmium_cd_minimum_in_air_dried_coal,
      // te_cadmium_cd_maximum_in_air_dried_coal:data.te_cadmium_cd_maximum_in_air_dried_coal,
      // te_chromium_cr_typical_in_air_dried_coal:data.te_chromium_cr_typical_in_air_dried_coal,
      // te_chromium_cr_minimum_in_air_dried_coal:data.te_chromium_cr_minimum_in_air_dried_coal,
      // te_chromium_cr_maximum_in_air_dried_coal:data.te_chromium_cr_maximum_in_air_dried_coal,
      // te_copper_cu_typical_in_air_dried_coal:data.te_copper_cu_typical_in_air_dried_coal,
      // te_copper_cu_minimum_in_air_dried_coal:data.te_copper_cu_minimum_in_air_dried_coal,
      // te_copper_cu_maximum_in_air_dried_coal:data.te_copper_cu_maximum_in_air_dried_coal,
      // te_chlorine_cl_typical_in_air_dried_coal:data.te_chlorine_cl_typical_in_air_dried_coal,
      // te_chlorine_cl_minimum_in_air_dried_coal:data.te_chlorine_cl_minimum_in_air_dried_coal,
      // te_chlorine_cl_maximum_in_air_dried_coal:data.te_chlorine_cl_maximum_in_air_dried_coal,
      // te_fluorine_f_typical_in_air_dried_coal:data.te_fluorine_f_typical_in_air_dried_coal,
      // te_fluorine_f_minimum_in_air_dried_coal:data.te_fluorine_f_minimum_in_air_dried_coal,
      // te_fluorine_f_maximum_in_air_dried_coal:data.te_fluorine_f_maximum_in_air_dried_coal,
      // te_mercury_hg_typical_in_air_dried_coal:data.te_mercury_hg_typical_in_air_dried_coal,
      // te_mercury_hg_minimum_in_air_dried_coal:data.te_mercury_hg_minimum_in_air_dried_coal,
      // te_mercury_hg_maximum_in_air_dried_coal:data.te_mercury_hg_maximum_in_air_dried_coal,
      // te_molybdenum_mo_typical_in_air_dried_coal:data.te_molybdenum_mo_typical_in_air_dried_coal,
      // te_molybdenum_mo_minimum_in_air_dried_coal:data.te_molybdenum_mo_minimum_in_air_dried_coal,
      // te_molybdenum_mo_maximum_in_air_dried_coal:data.te_molybdenum_mo_maximum_in_air_dried_coal,
      // te_lead_pb_typical_in_air_dried_coal:data.te_lead_pb_typical_in_air_dried_coal,
      // te_lead_pb_minimum_in_air_dried_coal:data.te_lead_pb_minimum_in_air_dried_coal,
      // te_lead_pb_maximum_in_air_dried_coal:data.te_lead_pb_maximum_in_air_dried_coal,
      // te_phosphorus_p_typical_in_air_dried_coal:data.te_phosphorus_p_typical_in_air_dried_coal,
      // te_phosphorus_p_minimum_in_air_dried_coal:data.te_phosphorus_p_minimum_in_air_dried_coal,
      // te_phosphorus_p_maximum_in_air_dried_coal:data.te_phosphorus_p_maximum_in_air_dried_coal,
      // te_selenium_se_typical_in_air_dried_coal:data.te_selenium_se_typical_in_air_dried_coal,
      // te_selenium_se_minimum_in_air_dried_coal:data.te_selenium_se_minimum_in_air_dried_coal,
      // te_selenium_se_maximum_in_air_dried_coal:data.te_selenium_se_maximum_in_air_dried_coal,
      // te_vanadium_v_typical_in_air_dried_coal:data.te_vanadium_v_typical_in_air_dried_coal,
      // te_vanadium_v_minimum_in_air_dried_coal:data.te_vanadium_v_minimum_in_air_dried_coal,
      // te_vanadium_v_maximum_in_air_dried_coal:data.te_vanadium_v_maximum_in_air_dried_coal,
      // te_zinc_zn_typical_in_air_dried_coal:data.te_zinc_zn_typical_in_air_dried_coal,
      // te_zinc_zn_minimum_in_air_dried_coal:data.te_zinc_zn_minimum_in_air_dried_coal,
      // te_zinc_zn_maximum_in_air_dried_coal:data.te_zinc_zn_maximum_in_air_dried_coal,

      // te_arsenic_as_typical:data.te_arsenic_as_typical,
      // te_arsenic_as_minimum:data.te_arsenic_as_minimum,
      // te_arsenic_as_maximum:data.te_arsenic_as_maximum,
      // te_antimony_sb_typical:data.te_antimony_sb_typical,
      // te_antimony_sb_minimum:data.te_antimony_sb_minimum,
      // te_antimony_sb_maximum:data.te_antimony_sb_maximum,
      // te_boron_b_typical:data.te_boron_b_typical,
      // te_boron_b_minimum:data.te_boron_b_minimum,
      // te_boron_b_maximum:data.te_boron_b_maximum,
      // te_cadmium_cd_typical:data.te_cadmium_cd_typical,
      // te_cadmium_cd_minimum:data.te_cadmium_cd_minimum,
      // te_cadmium_cd_maximum:data.te_cadmium_cd_maximum,
      // te_chromium_cr_typical:data.te_chromium_cr_typical,
      // te_chromium_cr_minimum:data.te_chromium_cr_minimum,
      // te_chromium_cr_maximum:data.te_chromium_cr_maximum,
      // te_copper_cu_typical:data.te_copper_cu_typical,
      // te_copper_cu_minimum:data.te_copper_cu_minimum,
      // te_copper_cu_maximum:data.te_copper_cu_maximum,
      // te_chlorine_cl_typical:data.te_chlorine_cl_typical,
      // te_chlorine_cl_minimum:data.te_chlorine_cl_minimum,
      // te_chlorine_cl_maximum:data.te_chlorine_cl_maximum,
      // te_fluorine_f_typical:data.te_fluorine_f_typical,
      // te_fluorine_f_minimum:data.te_fluorine_f_minimum,
      // te_fluorine_f_maximum:data.te_fluorine_f_maximum,
      // te_mercury_hg_typical:data.te_mercury_hg_typical,
      // te_mercury_hg_minimum:data.te_mercury_hg_minimum,
      // te_mercury_hg_maximum:data.te_mercury_hg_maximum,
      // te_molybdenum_mo_typical:data.te_molybdenum_mo_typical,
      // te_molybdenum_mo_minimum:data.te_molybdenum_mo_minimum,
      // te_molybdenum_mo_maximum:data.te_molybdenum_mo_maximum,
      // te_lead_pb_typical:data.te_lead_pb_typical,
      // te_lead_pb_minimum:data.te_lead_pb_minimum,
      // te_lead_pb_maximum:data.te_lead_pb_maximum,
      // te_phosphorus_p_typical:data.te_phosphorus_p_typical,
      // te_phosphorus_p_minimum:data.te_phosphorus_p_minimum,
      // te_phosphorus_p_maximum:data.te_phosphorus_p_maximum,
      // te_selenium_se_typical:data.te_selenium_se_typical,
      // te_selenium_se_minimum:data.te_selenium_se_minimum,
      // te_selenium_se_maximum:data.te_selenium_se_maximum,
      // te_vanadium_v_typical:data.te_vanadium_v_typical,
      // te_vanadium_v_min:data.te_vanadium_v_min,
      // te_vanadium_v_max:data.te_vanadium_v_max,
      // te_zinc_zn_typical:data.te_zinc_zn_typical,
      // te_zinc_zn_min:data.te_zinc_zn_min,
      // te_zinc_zn_max:data.te_zinc_zn_max,

      // size_0_to_50mm_typical:data.size_0_to_50mm_typical,
      // size_0_to_50mm_min:data.size_0_to_50mm_min,
      // size_0_to_50mm_max:data.size_0_to_50mm_max,
      // above_0_to_50mm_typical:data.above_0_to_50mm_typical,
      // above_0_to_50mm_min:data.above_0_to_50mm_min,
      // above_0_to_50mm_max:data.above_0_to_50mm_max,
      // under_2mm_typical:data.under_2mm_typical,
      // under_2mm_min:data.under_2mm_min,
      // under_2mm_max:data.under_2mm_max,
      // size_above_2mm_typical:data.size_above_2mm_typical,
      // size_above_2mm_min:data.size_above_2mm_min,
      // size_above_2mm_max:data.size_above_2mm_max

  };
    console.log(JSON.stringify(data))
    const encryptedAccessToken = localStorage.getItem('access_token');
      const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';
      if (encryptedAccessToken) {
        const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);

      fetch(API_URL+'add/products', {
                method: "POST",
                body: JSON.stringify(responseData),
                headers: { Authorization: `Bearer ${decryptedAccessToken}`,
                'Content-Type': 'application/json' },
              })
                .then((response) => response.json())

                .then((response) => {
                  console.log(response)
                  toast.success('Product Code Added Successfully', {
                    position: "bottom-center",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
                  setTimeout(() => {
                    reset();
                  }, 300);

                })

              }

      };

    return (
<>
<Header/>
<div className="contactAccount"  id='product-master'>
      <div className="page-header" id='add-account'>
    <div>
      <h2 className="main-content-title tx-24 mg-b-5">Product </h2>
      <ol className="breadcrumb">
        <li className="breadcrumb-item active" aria-current="page">Dashboard / Product / Add Product</li>
      </ol>
    </div>
    <div className="d-flex">
      <div className="justify-content-center">
        {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
          <Link to='/accounts/add-record'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</Link>
        </button> */}
      </div>
    </div>
  </div>
      <Form onSubmit={handleSubmit(onSubmit)}>
      <Row className="mb-3">
        <h4 className='heading'>Information</h4>
        {/* <h4 className='heading'>Account Information</h4> */}
                {/* <h4 className='heading'>Account Information</h4> */}
                <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Product Code"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Product Code"
              name="product_code"
              {...register("product_code", { required: false })}
            />
            {errors.product_code && <span>Required </span>}
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Product Family">
    <Form.Select aria-label="Floating label select example"
    name="product_family"
    placeholder="Product Family"
    defaultValue=""
    {...register("product_family", { required: false })}
    >

<option value='' >----None---- </option>


    </Form.Select>
    {errors.product_family && <span>Required</span>}
  </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Product Master">
    <Form.Select aria-label="Floating label select example"
    name="product_master"
    placeholder="Product Master"
    defaultValue=""
    {...register("product_master", { required: false })}
    >

<option value='' >Select </option>


{products.length>0?(
        <>
        {
            products.map((x)=>{
                return(
                    <option value={x.product_code}>{x.product_code}</option>
                )
            })
         }
       </>
       ):(

        <option className='no-data'>No data  available</option>
        )}


    </Form.Select>
    {errors.product_master && <span>Required</span>}
  </FloatingLabel>

              </Form.Group>


        <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Supplier">
    <Form.Select aria-label="Floating label select example"
    name="supplier"
    placeholder="Supplier"
    defaultValue=""
    {...register("supplier", { required: false })}
    >

<option value='' >Select </option>

{productAccount.length>0?(
        <>
         {
            productAccount.map((x)=>{
                return(
                    <option value={x.account_name}>{x.account_name}</option>
                )
            })
         }

       </>
       ):(

        <option className='no-data'>No data  available</option>
        )}

    </Form.Select>
    {errors.supplier && <span>Required</span>}
  </FloatingLabel>
</Form.Group>
<Form.Group as={Col} md="12">
        <FloatingLabel controlId="floatingTextarea2" label="Product Description">
        <Form.Control
          as="textarea"
          placeholder="Product Description"
          style={{ height: '200px!important' }}
          name="product_description"
          {...register("product_description", { required: false })}
          />
           {errors.product_description && <p id='text-area'>Required</p>}
      </FloatingLabel>
    </Form.Group>
    <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Product Name"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Product Name"
              name="product_name"
              {...register("product_name", { required: false })}
            />
            {errors.product_name && <span>Required </span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6" className='p-1'>
        <label>
        <input
        type="checkbox"
          name="short_term_available"
        />
         &nbsp;&nbsp;Short Term Available
      </label>

        </Form.Group>


        <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Purchase Contract">
    <Form.Select aria-label="Floating label select example"
    name="purchase_contract"
    placeholder="Purchase Contract"
    defaultValue=""
    {...register("purchase_contract", { required: false })}
    >

<option value='' >Select </option>


{contracts.length>0?(
        <>
         {
            contracts.map((x)=>{
                return(
                    <option value={x.contract_number}>{x.contract_number}</option>
                )
            })
         }

       </>
       ):(

        <option className='no-data'>No data  available</option>
        )}

    </Form.Select>
    {errors.purchase_contract && <span>Required</span>}
  </FloatingLabel>
</Form.Group>
<Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Buyer Name"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="Buyer Name"
                    name="buyer_name"
                    {...register("buyer_name", { required: false })}
                    />
                     {errors.buyer_name && <span>Required</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Buyer Contract">
    <Form.Select aria-label="Floating label select example"
    name="buyer_contract"
    placeholder="Buyer Contract"
    defaultValue=""
    {...register("buyer_contract", { required: false })}
    >

<option value='' >Select </option>


{contracts.length>0?(
        <>
                  {
            contracts.map((x)=>{
                return(
                    <option value={x.contract_number}>{x.contract_number}</option>
                )
            })
         }


       </>
       ):(

        <option className='no-data'>No data  available</option>
        )}


    </Form.Select>
    {errors.buyer_contract && <span>Required</span>}
  </FloatingLabel>
</Form.Group>
<Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Contract Shipment"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="Contract Shipment"
                      name="contract_shipment"
                      {...register("contract_shipment", { required: false })}
                    />
                    {errors.contract_shipment && <span>Required Moisture Typical</span>}
                  </FloatingLabel>
                </Form.Group>
      </Row>
      <Row className="mb-3">
        <h4 className='heading'>Product Availability</h4>
        <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Quantity MT"
                className="mb-3"
              >

                <Form.Control type="text"
                placeholder="Quantity MT"
                name="quantity_mt"
                {...register("quantity_mt", {  required: true })}
                />
                 {errors.quantity_mt && <span>Required</span>}
                 </FloatingLabel>
            </Form.Group>

        <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Status">
    <Form.Select aria-label="Floating label select example"
    name="status"
    placeholder="Status"
    defaultValue=""
    {...register("status", { required: false })}
    >

<option value='' >----None---- </option>
<option value='Sold' >Sold</option>
<option value='UnSold' >----UnSold---- </option>


    </Form.Select>
    {errors.status && <span>Required</span>}
  </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">

            <FloatingLabel controlId="floatingSelect"
            className='dropDown' label="Product Year">
  <Form.Select aria-label="Floating label select example"
  name="product_year"
  placeholder="Product Year"
  defaultValue=""
  {...register("product_year", { required: false })}
  >
    <option value='' >---None---</option>
    <option value="2021">2021</option>
    <option value="2022">2022</option>
    <option value="2023">2023</option>
    <option value="2024">2024</option>
    <option value="2025">2025</option>
    <option value="2026">2026</option>
    <option value="2015">2015</option>
    <option value="2019">2019</option>
    <option value="2020">2020</option>
  </Form.Select>
  {errors.product_year && <span>Required </span>}
</FloatingLabel>
            </Form.Group>

        <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Product Month"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="product_month"
                    placeholder="Product Month"

                    {...register("product_month", { required: false })}
                  >
                   <option value="January">January</option><option value="February">February</option><option value="March">March</option><option value="April">April</option><option value="May">May</option><option value="June">June</option><option value="July">July</option><option value="August">August</option><option value="September">September</option><option value="October">October</option><option value="November">November</option><option value="December">December</option>
                  </Form.Select>
                  {errors.product_month && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Price type"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="price_type"
                    placeholder="Price type"

                    {...register("price_type", { required: true })}
                  >
                    <option value="">Select</option>


{getPrice1.length>0?(
        <>
               {getPrice1.map((x) => {
                      return (
                        <option value={x.price_type_name}>
                          {x.price_type_name}
                        </option>
                      );
                    })}


       </>
       ):(

        <option className='no-data'>No data  available</option>
        )}
                  </Form.Select>
                  {errors.price_type && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Port Of Loading"
                  className="mb-3"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Port Of Loading"
                    name="port_of_loading"
                    style={{ height: "100px!important" }}
                    {...register("port_of_loading", { required: false })}
                  />
                  {errors.port_of_loading && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Price"
                  className="mb-3"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Price"
                    name="price"
                    style={{ height: "100px!important" }}
                    {...register("price", { required: false })}
                  />
                  {errors.price && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Shipping Term"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="shipping_term"
                    placeholder="Shipping Term"

                    {...register("shipping_term", { required: false })}
                  >
                    <option value="">Select</option>

                  </Form.Select>
                  {errors.shipping_term && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

        <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Vessel Type"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="vessel_type"
                    placeholder="Vessel Type"

                    {...register("vessel_type", { required: false })}
                  >
                    <option value="">--None--</option><option value="Geared (GnG)">Geared (GnG)</option><option value="Gearless">Gearless</option><option value="Geared">Geared</option><option value="Barge">Barge</option>
                  </Form.Select>
                  {errors.vessel_type && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Price Basis GAR"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="Price Basis GAR"
                      name="price_basis_GAR"
                      {...register("price_basis_GAR", { required: false })}
                    />
                    {errors.price_basis_GAR && <span>Required Moisture Typical</span>}
                  </FloatingLabel>
                </Form.Group>

        <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Vessel Type"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="vessel_type"
                    placeholder="Vessel Type"

                    {...register("vessel_type", { required: false })}
                  >
                    <option value="">--None--</option><option value="Geared (GnG)">Geared (GnG)</option><option value="Gearless">Gearless</option><option value="Geared">Geared</option><option value="Barge">Barge</option>
                  </Form.Select>
                  {errors.vessel_type && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

        </Row>
      <Row className="mb-3">
        <h4 className='heading'>%As Received Basis</h4>

                {/* <h4 className='heading'>Account Information</h4> */}
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Total Moisture Typical"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="Tota Moisture Typical"
                      name="total_moisture_typical"
                      {...register("total_moisture_typical", { required: false })}
                    />
                    {errors.total_moisture_typical && <span>Required Moisture Typical</span>}
                  </FloatingLabel>
                </Form.Group>


                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Total Moisture Minimum"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="Total Moisture Minimum"
                    name="total_moisture_minimum"
                    {...register("total_moisture_minimum", { required: false })}
                    />
                     {errors.total_moisture_minimum && <span>Required Moisture Minimum</span>}
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Total Moisture Maximum"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="Total Moisture Maximum"
                    name="total_moisture_maximum"
                    {...register("total_moisture_maximum", { required: false })}
                    />
                     {errors.total_moisture_maximum && <span>Required Moisture Minimum</span>}
                  </FloatingLabel>
                </Form.Group>



                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Ash typical arb"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="Ash typical arb"
                    name="ash_typical_arb"
                    {...register("ash_typical_arb", { required: false })}
                    />
                     {errors.ash_typical_arb && <span>Required Ash Typical</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Ash Minimum ARB"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="Ash Minimum ARB"
                    name="ash_minimum"
                    {...register("ash_minimum", { required: false })}
                    />
                     {errors.ash_minimum && <span>Required Ash Minimum</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Ash Maximum ARB"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="Ash Maximum ARB"
                    name="ash_maximum"
                    {...register("ash_maximum", { required: false })}
                    />
                     {errors.ash_maximum && <span>Required Ash Maximum</span>}
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Sulphur Typical ARB"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="Sulphur Typical ARB"
                    name="sulphur_typical_arb"
                    {...register("sulphur_typical_arb", { required: false })}
                    />
                     {errors.sulphur_typical_arb && <span>Required Sulphur Typical Arb</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Volatile Matter Minimum ARB"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="Volatile Matter Minimum ARB"
                    name="volatile_matter_minimum"
                    {...register("volatile_matter_minimum", { required: false })}
                    />
                     {errors.volatile_matter_minimum && <span>Required Volatile Matter Minimum</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Volatile Matter Maximum ARB"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="Volatile Matter Maximum ARB"
                    name="volatile_matter_maximum"
                    {...register("volatile_matter_maximum", { required: false })}
                    />
                     {errors.volatile_matter_maximum && <span>Required Volatile Matter Maximum</span>}
                  </FloatingLabel>
                </Form.Group>
        </Row>

        <Row className="mb-3">
                <h4 className='heading'>Proximate Analysis %Air Dried Basis</h4>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Moisture Typical"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="Moisture Typical"
                      name="moisture_typical"
                      {...register("moisture_typical", { required: false })}
                    />
                    {errors.moisture_typical && <span>Required Moisture Typical</span>}
                  </FloatingLabel>
                </Form.Group>


                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Moisture Minimum"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="Moisture Minimum"
                    name="moisture_minimum"
                    {...register("moisture_minimum", { required: false })}
                    />
                     {errors.moisture_minimum && <span>Required Moisture Minimum</span>}
                  </FloatingLabel>
                </Form.Group>



                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Ash Typical"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="Ash Typical"
                    name="ash_typical"
                    {...register("ash_typical", { required: false })}
                    />
                     {errors.ash_typical && <span>Required Ash Typical</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Ash Minimum"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="Ash Minimum"
                    name="ash_minimum"
                    {...register("ash_minimum", { required: false })}
                    />
                     {errors.ash_minimum && <span>Required Ash Minimum</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Ash Maximum"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="Ash Maximum"
                    name="ash_maximum"
                    {...register("ash_maximum", { required: false })}
                    />
                     {errors.ash_maximum && <span>Required Ash Maximum</span>}
                  </FloatingLabel>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <h4 className='heading'>CALORIFIC VALUE, Keal/kg</h4>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Gross Air Dried Typical"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="Gross Air Dried Typical"
                      name="gross_air_dried_typical"
                      {...register("gross_air_dried_typical", {valueAsNumber:true, required: false })}
                    />
                    {errors.gross_air_dried_typical && <span>Required Gross Air Dried Typical</span>}
                  </FloatingLabel>
                </Form.Group>


                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Gross Air Dried Min"
                    className="mb-3"
                  >
                    <Form.Control type="text"
                    placeholder="Gross Air Dried Min"
                    name="gross_air_dried_min"
                    {...register("gross_air_dried_min", { valueAsNumber:true, required: false })}
                    />
                     {errors.gross_air_dried_min && <span>Required Gross Air Dried Min</span>}
                  </FloatingLabel>
                </Form.Group>



                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Gross Air Dried Max"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="Gross Air Dried Max"
                    name="gross_air_dried_max"
                    {...register("gross_air_dried_max", {valueAsNumber:true, required: false })}
                    />
                     {errors.gross_air_dried_max && <span>Required Gross Air Dried Max</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Gross As Recieved Min"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="Gross As Recieved Min"
                    name="gross_as_received_min"
                    {...register("gross_as_received_min", {valueAsNumber:true, required: false })}
                    />
                     {errors.gross_as_received_min && <span>Required Gross As Recieved Min</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Gross As Recieved Max"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="Gross As Recieved Max"
                    name="gross_as_received_max"
                    {...register("gross_as_received_max", {valueAsNumber:true, required: false })}
                    />
                     {errors.gross_as_received_max && <span>Required Gross As Recieved Max</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Net As Recieved Min"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="Net As Recieved Min"
                    name="net_as_received_min"
                    {...register("net_as_received_min", {valueAsNumber:true, required: false })}
                    />
                     {errors.net_as_received_min && <span>Required Net As Recieved Min</span>}
                  </FloatingLabel>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <h4 className='heading'>HGI</h4>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="HGI typical"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="HGI typical"
                    name="hgi_typical"
                    {...register("hgi_typical", { required: false })}
                    />
                     {errors.hgi_typical && <span>Required Volatile Matter Maximum</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="HGI minimum"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="HGI minimum"
                    name="hgi_min"
                    {...register("hgi_min", { required: false })}
                    />
                     {errors.hgi_min && <span>Required Volatile Matter Maximum</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="HGI max"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="HGI Max"
                    name="hgi_max"
                    {...register("hgi_max", { required: false })}
                    />
                     {errors.hgi_max && <span>Required Volatile Matter Maximum</span>}
                  </FloatingLabel>
                </Form.Group>
                </Row>
                <Row className="mb-3">
                <h4 className='heading'>ULTIMATE ANALYSIS %dry ash free basis</h4>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Carbon Typical"
                    className="mb-3"


                  >
                    <Form.Control
                      type="text"
                      placeholder="Carbon Typical"
                      name="carbon_typical"
                      {...register("carbon_typical", { valueAsNumber:true, required: false })}
                    />
                    {errors.carbon_typical && <span>Required Carbon Typical</span>}
                  </FloatingLabel>
                </Form.Group>


                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Carbon Min"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="Carbon Min"
                    name="carbon_min"
                    {...register("carbon_min", {valueAsNumber:true,  required: false })}
                    />
                     {errors.carbon_min && <span>Required Carbon Min</span>}
                  </FloatingLabel>
                </Form.Group>



                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Carbon Max"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="Carbon Max"
                    name="carbon_max"
                    {...register("carbon_max", {valueAsNumber:true,  required: false })}
                    />
                     {errors.carbon_max && <span>Required Carbon Max</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Hydrogen Typical"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="Hydrogen Typical"
                    name="hydrogen_typical"
                    {...register("hydrogen_typical", { valueAsNumber:true, required: false })}
                    />
                     {errors.hydrogen_typical && <span>Required Hydrogen Typical</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Hydrogen Min"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="Hydrogen Min"
                    name="hydrogen_min"
                    {...register("hydrogen_min", {valueAsNumber:true,  required: false })}
                    />
                     {errors.hydrogen_min && <span>Required Hydrogen Min</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Hydrogen Max"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="Hydrogen Max"
                    name="hydrogen_max"
                    {...register("hydrogen_max", { valueAsNumber:true, required: false })}
                    />
                     {errors.hydrogen_max && <span>Required Hydrogen Max</span>}
                  </FloatingLabel>
                </Form.Group>


                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Nitrogen Typical"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="Nitrogen Typical"
                    name="nitrogen_typical"
                    {...register("nitrogen_typical", {valueAsNumber:true,  required: false })}
                    />
                     {errors.nitrogen_typical && <span>Required Nitrogen Typical</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Nitrogen Min"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="Nitrogen Min"
                    name="nitrogen_min"
                    {...register("nitrogen_min", { valueAsNumber:true, required: false })}
                    />
                     {errors.nitrogen_min && <span>Required Nitrogen Min</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Nitrogen Max"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="Nitrogen Max"
                    name="nitrogen_max"
                    {...register("nitrogen_max", {valueAsNumber:true,  required: false })}
                    />
                     {errors.nitrogen_max && <span>Nitrogen Max</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Sulphur Typical"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="Sulphur Typical"
                    name="sulphur_typical"
                    {...register("sulphur_typical", {valueAsNumber:true,  required: false })}
                    />
                     {errors.sulphur_typical && <span>Required Sulphur Typical</span>}
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Sulphur Minimum"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="Sulphur Minimum"
                    name="sulphur_minimum"
                    {...register("sulphur_minimum", { valueAsNumber:true, required: false })}
                    />
                     {errors.sulphur_minimum && <span>Required Sulphur Min</span>}
                  </FloatingLabel>
                </Form.Group>



                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxygen Typical"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="Oxygen Typical"
                    name="oxygen_typical"
                    {...register("oxygen_typical", { valueAsNumber:true, required: false })}
                    />
                     {errors.oxygen_typical && <span>Required Oxygen Typical</span>}
                  </FloatingLabel>
                </Form.Group>


                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxygen Minimum"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="Oxygen Minimum"
                    name="oxygen_min"
                    {...register("oxygen_min", {valueAsNumber:true,  required: false })}
                    />
                     {errors.oxygen_min && <span>Required Oxygen Min</span>}
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxygen Maximum"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="Oxygen Maximum"
                    name="oxygen_maximum"
                    {...register("oxygen_maximum", {valueAsNumber:true,  required: false })}
                    />
                     {errors.oxygen_maximum && <span>Required Oxygen Min</span>}
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxygen Maximum"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="Oxygen Maximum"
                    name="oxygen_max"
                    {...register("oxygen_max", {valueAsNumber:true,  required: false })}
                    />
                     {errors.oxygen_max && <span>Required Oxygen Max</span>}
                  </FloatingLabel>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <h4 className='heading'>SULPHUR %air dried basis</h4>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Sulphur Typical Adb"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="Sulphur Typical Adb"
                      name="sulphur_typical_adb"
                      {...register("sulphur_typical_adb", {valueAsNumber:true, required: false })}
                    />
                    {errors.sulphur_typical_adb && <span>Required Sulphur Typical Adb</span>}
                  </FloatingLabel>
                </Form.Group>


                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Sulphur Minimum Adb"
                    className="mb-3"
                  >

                    <Form.Control type="number"
                    placeholder="Sulphur Minimum Adb"
                    name="sulphur_min_adb"
                    {...register("sulphur_min_adb", { valueAsNumber:true,required: false })}
                    />
                     {errors.sulphur_min_adb && <span>Required Sulphur Min Adb</span>}
                  </FloatingLabel>
                </Form.Group>



                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Sulphur Maximum"
                    className="mb-3"
                  >

                    <Form.Control type="number"
                    placeholder="Sulphur Maximum"
                    name="product_sulphur_max"
                    {...register("product_sulphur_max", {valueAsNumber:true, required: false })}
                    />
                     {errors.product_sulphur_max && <span>Required Sulphur Max</span>}
                  </FloatingLabel>
                </Form.Group>

              </Row>
              <Row className="mb-3">
                <h4 className='heading'>ASH FUSION TEMPERATURE, Deg C Oxidizing</h4>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxidizing Initial Deformtion Typical"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="Oxidizing Initial Deformtion Typical"
                      name="oxidizing_initial_typical"
                      {...register("oxidizing_initial_typical", {valueAsNumber:true, required: false })}
                    />
                    {errors.oxidizing_initial_typical && <span>Required Initial Typical</span>}
                  </FloatingLabel>
                </Form.Group>


                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxidizing Initial Deformtion Minimum"
                    className="mb-3"
                  >

                    <Form.Control type="number"
                    placeholder="Oxidizing Initial Deformtion Minimum"
                    name="oxidizing_initial_minimum"
                    {...register("oxidizing_initial_minimum", { valueAsNumber:true,required: false })}
                    />
                     {errors.oxidizing_initial_minimum && <span>Required Initial Minimum</span>}
                  </FloatingLabel>
                </Form.Group>


                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxidizing Initial Deformtion Maximum"
                    className="mb-3"
                  >

                    <Form.Control type="number"
                    placeholder="Oxidizing Initial Deformtion Maximum"
                    name="oxidizing_initial_maximum"
                    {...register("oxidizing_initial_maximum", {valueAsNumber:true, required: false })}
                    />
                     {errors.oxidizing_initial_maximum && <span>Required Initial Maximum</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxidizing Spherical Typical"
                    className="mb-3"
                  >

                    <Form.Control type="number"
                    placeholder="Oxidizing Spherical Typical"
                    name="oxidizing_spherical_typical"
                    {...register("oxidizing_spherical_typical", {valueAsNumber:true, required: false })}
                    />
                     {errors.oxidizing_spherical_typical && <span>Required Spherical Typical</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxidizing Spherical Minimum"
                    className="mb-3"
                  >

                    <Form.Control type="number"
                    placeholder="Oxidizing Spherical Minimum"
                    name="oxidizing_spherical_minimum"
                    {...register("oxidizing_spherical_minimum", {valueAsNumber:true, required: false })}
                    />
                     {errors.oxidizing_spherical_minimum && <span>Required Spherical Minimum</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxidizing Spherical Maximum"
                    className="mb-3"
                  >

                    <Form.Control type="number"
                    placeholder="Oxidizing Spherical Maximum"
                    name="oxidizing_spherical_maximum"
                    {...register("oxidizing_spherical_maximum", {valueAsNumber:true, required: false })}
                    />
                     {errors.oxidizing_spherical_maximum && <span>Required Spherical Maximum</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxidizing Hemispherical Typical"
                    className="mb-3"
                  >

                    <Form.Control type="number"
                    placeholder="Oxidizing Hemispherical Typical"
                    name="oxidizing_hemispherical_typical"
                    {...register("oxidizing_hemispherical_typical", {valueAsNumber:true, required: false })}
                    />
                     {errors.oxidizing_hemispherical_typical && <span>Required Hemispherical Typical</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxidizing Hemispherical Minimum"
                    className="mb-3"
                  >

                    <Form.Control type="number"
                    placeholder="Oxidizing Hemispherical Minimum"
                    name="hemispherical_minimum"
                    {...register("hemispherical_minimum", {valueAsNumber:true, required: false })}
                    />
                     {errors.hemispherical_minimum && <span>Required Hemispherical Minimum</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxidizing Hemispherical Maximum"
                    className="mb-3"
                  >

                    <Form.Control type="number"
                    placeholder="Oxidizing Hemispherical Maximum"
                    name="oxidizing_hemispherical_maximum"
                    {...register("oxidizing_hemispherical_maximum", {valueAsNumber:true, required: false })}
                    />
                     {errors.oxidizing_hemispherical_maximum && <span>Required Hemispherical Maximum</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxidizing Flow Typical"
                    className="mb-3"
                  >

                    <Form.Control type="number"
                    placeholder="Oxidizing Flow Typical"
                    name="sulphur_max"
                    {...register("flow_typical", {valueAsNumber:true, required: false })}
                    />
                     {errors.flow_typical && <span>Required Flow Typical</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxidizing Flow Minimum"
                    className="mb-3"
                  >

                    <Form.Control type="number"
                    placeholder="Oxidizing Flow Minimum"
                    name="oxidizing_flow_minimum"
                    {...register("oxidizing_flow_minimum", {valueAsNumber:true, required: false })}
                    />
                     {errors.oxidizing_flow_minimum && <span>Required Flow Minimum</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Oxidizing Flow Maximum"
                    className="mb-3"
                  >

                    <Form.Control type="number"
                    placeholder="Oxidizing Flow Maximum"
                    name="oxidizing_flow_maximum"
                    {...register("oxidizing_flow_maximum", {valueAsNumber:true, required: false })}
                    />
                     {errors.oxidizing_flow_maximum && <span>Required Flow Maximum</span>}
                  </FloatingLabel>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <h4 className='heading'>ASH ANALYSIS %Dry Basis in Ash</h4>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="SiO2 Typical"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="SiO2 Typical"
                      name="SiO2_Typical"
                      {...register("SiO2_Typical", {valueAsNumber:true, required: false })}
                    />
                    {errors.SiO2_Typical && <span>Required SiO2 Typical </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="SiO2 Minimum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="SiO2 Minimum"
                      name="SiO2_Minimum"
                      {...register("SiO2_Minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors.SiO2_Minimum && <span>Required SiO2 Minimum</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="SiO2 Maximum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="SiO2 Maximum"
                      name="SiO2_Maximum"
                      {...register("SiO2_Maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors.SiO2_Maximum && <span>Required SiO2 Maximum </span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Al2O3 Typical"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="Al2O3 Typical"
                      name="Al2O3_Typical"
                      {...register("Al2O3_Typical", {valueAsNumber:true, required: false })}
                    />
                    {errors.Al2O3_Typical && <span>Required Al2O3 Typical</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Al2O3 Minimum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="Al2O3 Minimum"
                      name="Al2O3_Minimum"
                      {...register("Al2O3_Minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors.Al2O3_Minimum && <span>Required Al2O3 Minimum</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Al2O3 Maximum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="Al2O3 Maximum"
                      name="Al2O3_Maximum"
                      {...register("Al2O3_Maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors.Al2O3_Maximum && <span>Required Al2O3 Maximum</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Fe2O3 Typical"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="Fe2O3 Typical"
                      name="Fe2O3_Typical"
                      {...register("Fe2O3_Typical", {valueAsNumber:true, required: false })}
                    />
                    {errors.Fe2O3_Typical && <span>Required Fe2O3 Typical</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Fe2O3 Minimum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="Fe2O3 Minimum"
                      name="Fe2O3_Minimum"
                      {...register("Fe2O3_Minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors.Fe2O3_Minimum && <span>Required Fe2O3 Minimum</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Fe2O3 Maximum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="Fe2O3 Maximum"
                      name="Fe2O3_Maximum"
                      {...register("Fe2O3_Maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors.Fe2O3_Maximum && <span>Required Fe2O3 Maximum</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="CaO Typical"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="CaO Typical"
                      name="CaO_Typical"
                      {...register("CaO_Typical", {valueAsNumber:true, required: false })}
                    />
                    {errors.CaO_Typical && <span>Required CaO Typical</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="CaO Minimum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="CaO Minimum"
                      name="CaO_Minimum"
                      {...register("CaO_Minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors.CaO_Minimum && <span>Required CaO Minimum</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="CaO Maximum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="CaO Maximum"
                      name="CaO_Maximum"
                      {...register("CaO_Maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors.CaO_Maximum && <span>Required CaO Maximum</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="MgO Typical"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="MgO Typical"
                      name="MgO_Typical"
                      {...register("MgO_Typical", {valueAsNumber:true, required: false })}
                    />
                    {errors.MgO_Typical && <span>Required MgO Typical</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="MgO Minimum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="MgO Minimum"
                      name="MgO_Minimum"
                      {...register("MgO_Minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors.MgO_Minimum && <span>Required MgO Minimum</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="MgO Maximum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="MgO Maximum"
                      name="MgO_Maximum"
                      {...register("MgO_Maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors.MgO_Maximum && <span>Required MgO Maximum</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="MnO Typical"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="MnO Typical"
                      name="MnO_Typical"
                      {...register("MnO_Typical", {valueAsNumber:true, required: false })}
                    />
                    {errors.MnO_Typical && <span>Required MnO Typical</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="MnO Minimum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="MnO Minimum"
                      name="MnO_Minimum"
                      {...register("MnO_Minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors.MnO_Minimum && <span>Required MnO Minimum</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="MnO Maximum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="MnO Maximum"
                      name="MnO_Maximum"
                      {...register("MnO_Maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors.MnO_Maximum && <span>Required MnO Maximum</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TiO2 Typical"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="TiO2 Typical"
                      name="TiO2_Typical"
                      {...register("TiO2_Typical", {valueAsNumber:true, required: false })}
                    />
                    {errors.TiO2_Typical && <span>Required TiO2 Typical</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TiO2 Minimum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="TiO2 Minimum"
                      name="TiO2_Minimum"
                      {...register("TiO2_Minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors.TiO2_Minimum && <span>Required TiO2 Minimum</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TiO2 Maximum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="TiO2 Maximum"
                      name="TiO2_Maximum"
                      {...register("TiO2_Maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors.TiO2_Maximum && <span>Required TiO2 Maximum</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Na2O Typical"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="Na2O Typical"
                      name="Na2O_Typical"
                      {...register("Na2O_Typical", {valueAsNumber:true, required: false })}
                    />
                    {errors.Na2O_Typical && <span>Required Na2O Typical</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Na2O Minimum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="Na2O Minimum"
                      name="Na2O_Minimum"
                      {...register("Na2O_Minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors.Na2O_Minimum && <span>Required Na2O Minimum</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Na2O Maximum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="Na2O Maximum"
                      name="Na2O_Maximum"
                      {...register("Na2O_Maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors.Na2O_Maximum && <span>Required Na2O Maximum</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="K2O Typical"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="K2O Typical"
                      name="K2O_Typical"
                      {...register("K2O_Typical", {valueAsNumber:true, required: false })}
                    />
                    {errors.K2O_Typical && <span>Required K2O Typical</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="K2O Minimum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="K2O Minimum"
                      name="K2O_Minimum"
                      {...register("K2O_Minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors.K2O_Minimum && <span>Required K2O Minimum</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="K2O Maximum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="K2O Maximum"
                      name="K2O_Maximum"
                      {...register("K2O_Maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors.K2O_Maximum && <span>Required K2O Maximum</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="P2O5 Typical"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="P2O5 Typical"
                      name="P2O5_Typical"
                      {...register("P2O5_Typical", {valueAsNumber:true, required: false })}
                    />
                    {errors.P2O5_Typical && <span>Required P2O5 Typical</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="P2O5 Minimum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="P2O5 Minimum"
                      name="P2O5_Minimum"
                      {...register("P2O5_Minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors.P2O5_Minimum && <span>Required P2O5 Minimum</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="P2O5 Maximum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="P2O5 Maximum"
                      name="P2O5_Maximum"
                      {...register("P2O5_Maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors.P2O5_Maximum && <span>Required P2O5 Maximum</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="SO3 Typical"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="SO3 Typical"
                      name="SO3_Typical"
                      {...register("SO3_Typical", {valueAsNumber:true, required: false })}
                    />
                    {errors.SO3_Typical && <span>Required SO3 Typical</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="SO3 Minimum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="SO3 Minimum"
                      name="SO3_Minimum"
                      {...register("SO3_Minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors.SO3_Minimum && <span>Required SO3 Minimum</span>}
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="SO3 Maximum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="SO3 Maximum"
                      name="SO3_Maximum"
                      {...register("SO3_Maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors.SO3_Maximum && <span>Required SO3 Maximum</span>}
                  </FloatingLabel>
                </Form.Group>




              </Row>
              <Row className="mb-3">
                <h4 className='heading'>Trace Elements, ppm Air Dried in Coal</h4>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE arsenic as typical"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="TE arsenic as typical"
                      name="te_arsenic_as_typical_in_air_dried_coal"
                      {...register("te_arsenic_as_typical_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_arsenic_as_typical_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE rsenic as minimum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="TE rsenic as minimum"
                      name="te_arsenic_as_minimum_in_air_dried_coal"
                      {...register("te_arsenic_as_minimum_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_arsenic_as_minimum_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE arsenic as maximum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="TE arsenic as maximum"
                      name="te_arsenic_as_maximum_in_air_dried_coal"
                      {...register("te_arsenic_as_maximum_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_arsenic_as_maximum_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE antimony sb typical"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="TE antimony sb typical"
                      name="te_antimony_sb_typical_in_air_dried_coal"
                      {...register("te_antimony_sb_typical_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_antimony_sb_typical_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE antimony sb minimum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="TE antimony sb minimum"
                      name="te_antimony_sb_minimum_in_air_dried_coal"
                      {...register("te_antimony_sb_minimum_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_antimony_sb_minimum_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE antimony sb maximum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="TE antimony sb maximum"
                      name="te_antimony_sb_maximum_in_air_dried_coal"
                      {...register("te_antimony_sb_maximum_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_antimony_sb_maximum_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE boron b typical"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="TE boron b typical"
                      name="te_boron_b_typical_in_air_dried_coal"
                      {...register("te_boron_b_typical_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_boron_b_typical_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE boron b minimum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="TE boron b minimum"
                      name="te_boron_b_minimum_in_air_dried_coal"
                      {...register("te_boron_b_minimum_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_boron_b_minimum_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE boron b maximum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="TE boron b maximum"
                      name="te_boron_b_maximum_in_air_dried_coal"
                      {...register("te_boron_b_maximum_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_boron_b_maximum_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE cadmium cd typical"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="TE cadmium cd typical"
                      name="te_cadmium_cd_typical_in_air_dried_coal"
                      {...register("te_cadmium_cd_typical_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_cadmium_cd_typical_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE cadmium cd minimum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="TE cadmium cd minimum"
                      name="te_cadmium_cd_minimum_in_air_dried_coal"
                      {...register("te_cadmium_cd_minimum_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_cadmium_cd_minimum_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE cadamium cd maximum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="TE cadamium cd maximum"
                      name="te_cadamium_cd_maximum_in_air_dried_coal"
                      {...register("te_cadamium_cd_maximum_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_cadamium_cd_maximum_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE chromium cr typical"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="TE chromium cr typical"
                      name="te_chromium_cr_typical_in_air_dried_coal"
                      {...register("te_chromium_cr_typical_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_chromium_cr_typical_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE chromium cr minimum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="TE chromium cr minimum"
                      name="te_chromium_cr_minimum_in_air_dried_coal"
                      {...register("te_chromium_cr_minimum_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_chromium_cr_minimum_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE chromium cr maximum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="TE chromium cr maximum"
                      name="te_chromium_cr_maximum_in_air_dried_coal"
                      {...register("te_chromium_cr_maximum_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_chromium_cr_maximum_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE copper cu typical"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="TE copper cu typical"
                      name="te_copper_cu_typical_in_air_dried_coal"
                      {...register("te_copper_cu_typical_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_copper_cu_typical_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE copper cu minimum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="TE copper cu minimum"
                      name="te_copper_cu_minimum_in_air_dried_coal"
                      {...register("te_copper_cu_minimum_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_copper_cu_minimum_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE copper cu maximum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="TE copper cu maximum"
                      name="te_copper_cu_maximum_in_air_dried_coal"
                      {...register("te_copper_cu_maximum_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_copper_cu_maximum_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
         {/*  */}
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE chlorine cl typical"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="TE chlorine cl typical"
                      name="te_chlorine_cl_typical_in_air_dried_coal"
                      {...register("te_chlorine_cl_typical_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_chlorine_cl_typical_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE chlorine cl minimum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="TE chlorine cl minimum"
                      name="te_chlorine_cl_minimum_in_air_dried_coal"
                      {...register("te_chlorine_cl_minimum_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_chlorine_cl_minimum_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE chlorine cl maximum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="TE chlorine cl maximum"
                      name="te_chlorine_cl_maximum_in_air_dried_coal"
                      {...register("te_chlorine_cl_maximum_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_chlorine_cl_maximum_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE fluorine f typical"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="TE fluorine f typical"
                      name="te_fluorine_f_typical_in_air_dried_coal"
                      {...register("te_fluorine_f_typical_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_fluorine_f_typical_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE fluorine f minimum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="TE fluorine f minimum"
                      name="te_fluorine_f_minimum_in_air_dried_coal"
                      {...register("te_fluorine_f_minimum_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_fluorine_f_minimum_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE fluorine f maximum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="TE fluorine f maximum"
                      name="te_fluorine_f_maximum_in_air_dried_coal"
                      {...register("te_fluorine_f_maximum_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_fluorine_f_maximum_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE mercury hg typical"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="TE mercury hg typical"
                      name="te_mercury_hg_typical_in_air_dried_coal"
                      {...register("te_mercury_hg_typical_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_mercury_hg_typical_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE mercury hg minimum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="TE mercury hg minimum"
                      name="te_mercury_hg_minimum_in_air_dried_coal"
                      {...register("te_mercury_hg_minimum_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_mercury_hg_minimum_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE mercury hg maximum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="TE mercury hg maximum"
                      name="te_mercury_hg_maximum_in_air_dried_coal"
                      {...register("te_mercury_hg_maximum_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_mercury_hg_maximum_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE molybdenum mo typical"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="TE molybdenum mo typical"
                      name="te_molybdenum_mo_typical_in_air_dried_coal"
                      {...register("te_molybdenum_mo_typical_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_molybdenum_mo_typical_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE molybdenum mo minimum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="TE molybdenum mo minimum"
                      name="te_molybdenum_mo_minimum_in_air_dried_coal"
                      {...register("te_molybdenum_mo_minimum_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_molybdenum_mo_minimum_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE molybdenum mo maximum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="TE molybdenum mo maximum"
                      name="te_molybdenum_mo_maximum_in_air_dried_coal"
                      {...register("te_molybdenum_mo_maximum_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_molybdenum_mo_maximum_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE lead pb typical"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="TE lead pb typical"
                      name="te_lead_pb_typical_in_air_dried_coal"
                      {...register("te_lead_pb_typical_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_lead_pb_typical_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE lead pb minimum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="TE lead pb minimum"
                      name="te_lead_pb_minimum_in_air_dried_coal"
                      {...register("te_lead_pb_minimum_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_lead_pb_minimum_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE lead pb maximum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="TE lead pb maximum"
                      name="te_lead_pb_maximum_in_air_dried_coal"
                      {...register("te_lead_pb_maximum_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_lead_pb_maximum_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE phosphorus p typical"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="TE phosphorus p typical"
                      name="te_phosphorus_p_typical_in_air_dried_coal"
                      {...register("te_phosphorus_p_typical_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_phosphorus_p_typical_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE phosphorus p minimum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="TE phosphorus p minimum"
                      name="te_phosphorus_p_minimum_in_air_dried_coal"
                      {...register("te_phosphorus_p_minimum_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_phosphorus_p_minimum_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE phosphorus p maximum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="TE phosphorus p maximum"
                      name="te_phosphorus_p_maximum_in_air_dried_coal"
                      {...register("te_phosphorus_p_maximum_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_phosphorus_p_maximum_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE selenium se typical"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="TE selenium se typical"
                      name="te_selenium_se_typical_in_air_dried_coal"
                      {...register("te_selenium_se_typical_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_selenium_se_typical_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE selenium se minimum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="TE selenium se minimum"
                      name="te_selenium_se_minimum_in_air_dried_coal"
                      {...register("te_selenium_se_minimum_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_selenium_se_minimum_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE selenium se maximum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="TE selenium se maximum"
                      name="te_selenium_se_maximum_in_air_dried_coal"
                      {...register("te_selenium_se_maximum_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_selenium_se_maximum_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE vanadium v typical"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="TE vanadium v typical"
                      name="te_vanadium_v_typical_in_air_dried_coal"
                      {...register("te_vanadium_v_typical_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_vanadium_v_typical_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE vanadium v min"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="TE vanadium v min"
                      name="te_vanadium_v_min_in_air_dried_coal"
                      {...register("te_vanadium_v_min_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_vanadium_v_min_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE vanadium v max"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="TE vanadium v max"
                      name="te_vanadium_v_max_in_air_dried_coal"
                      {...register("te_vanadium_v_max_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_vanadium_v_max_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE zinc zn typical"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="TE zinc zn typical"
                      name="te_zinc_zn_typical_in_air_dried_coal"
                      {...register("te_zinc_zn_typical_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_zinc_zn_typical_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE zinc zn min"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="TE zinc zn min"
                      name="te_zinc_zn_min_in_air_dried_coal"
                      {...register("te_zinc_zn_min_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_zinc_zn_min_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TE zinc zn max"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="TE zinc zn max"
                      name="te_zinc_zn_max_in_air_dried_coal"
                      {...register("te_zinc_zn_max_in_air_dried_coal", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_zinc_zn_max_in_air_dried_coal && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>



              </Row>

              <Row className="mb-3">
                <h4 className='heading'>Trace Elements, ppm Dried Basis</h4>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="te arsenic as typical"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="te arsenic as typical"
                      name="te_arsenic_as_typical"
                      {...register("te_arsenic_as_typical", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_arsenic_as_typical && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te arsenic as minimum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="Te arsenic as minimum"
                      name="te_arsenic_as_minimum"
                      {...register("te_arsenic_as_minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_arsenic_as_minimum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te arsenic as maximum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="Te arsenic as maximum"
                      name="te_arsenic_as_maximum"
                      {...register("te_arsenic_as_maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_arsenic_as_maximum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te antimony sb typical"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="Te antimony sb typical"
                      name="te_antimony_sb_typical"
                      {...register("te_antimony_sb_typical", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_antimony_sb_typical && <span>Required</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te antimony sb minimum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="Te antimony sb minimum"
                      name="te_antimony_sb_minimum"
                      {...register("te_antimony_sb_minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_antimony_sb_minimum && <span>Required</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te antimony sb maximum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="Te antimony sb maximum"
                      name="te_antimony_sb_maximum"
                      {...register("te_antimony_sb_maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_antimony_sb_maximum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te beryllium be typical"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="Te beryllium be typical"
                      name="te_beryllium_be_typical"
                      {...register("te_beryllium_be_typical", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_beryllium_be_typical && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te beryllium be minimum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="Te beryllium be minimum"
                      name="te_beryllium_be_minimum"
                      {...register("te_beryllium_be_minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_beryllium_be_minimum && <span>Required</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te beryllium be maximum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="Te beryllium be maximum"
                      name="te_beryllium_be_maximum"
                      {...register("te_beryllium_be_maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_beryllium_be_maximum && <span>Required</span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te boron b typical"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="Te boron b typical"
                      name="te_boron_b_typical"
                      {...register("te_boron_b_typical", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_boron_b_typical && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te boron b minimum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="Te boron b minimum"
                      name="te_boron_b_minimum"
                      {...register("te_boron_b_minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_boron_b_minimum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te boron b maximum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="Te boron b maximum"
                      name="te_boron_b_maximum"
                      {...register("te_boron_b_maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_boron_b_maximum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te cadmium cd typical"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="Te cadmium cd typical"
                      name="te_cadmium_cd_typical"
                      {...register("te_cadmium_cd_typical", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_cadmium_cd_typical && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te cadmium cd minimum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="Te cadmium cd minimum"
                      name="te_cadmium_cd_minimum"
                      {...register("te_cadmium_cd_minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_cadmium_cd_minimum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te cadmium cd maximum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="Te cadmium cd maximum"
                      name="te_cadmium_cd_maximum"
                      {...register("te_cadmium_cd_maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_cadmium_cd_maximum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te chromium cr typical"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="Te chromium cr typical"
                      name="te_chromium_cr_typical"
                      {...register("te_chromium_cr_typical", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_chromium_cr_typical && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te chromium cr minimum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="Te chromium cr minimum"
                      name="te_chromium_cr_minimum"
                      {...register("te_chromium_cr_minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_chromium_cr_minimum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te chromium cr maximum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="Te chromium cr maximum"
                      name="te_chromium_cr_maximum"
                      {...register("te_chromium_cr_maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_chromium_cr_maximum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te copper cu typical"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="Te copper cu typical"
                      name="te_copper_cu_typical"
                      {...register("te_copper_cu_typical", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_copper_cu_typical&& <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te copper cu minimum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="Te copper cu minimum"
                      name="te_copper_cu_minimum"
                      {...register("te_copper_cu_minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_copper_cu_minimum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te copper cu maximum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="Te copper cu maximum"
                      name="te_copper_cu_maximum"
                      {...register("te_copper_cu_maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_copper_cu_maximum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te chlorine cl typical"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="Te chlorine cl typical"
                      name="te_chlorine_cl_typical"
                      {...register("te_chlorine_cl_typical", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_chlorine_cl_typical && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te chlorine cl minimum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="Te chlorine cl minimum"
                      name="te_chlorine_cl_minimum"
                      {...register("te_chlorine_cl_minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_chlorine_cl_minimum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te chlorine cl maximum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="Te chlorine cl maximum"
                      name="te_chlorine_cl_maximum"
                      {...register("te_chlorine_cl_maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_chlorine_cl_maximum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te fluorine f typical"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="Te fluorine f typical"
                      name="te_fluorine_f_typical"
                      {...register("te_fluorine_f_typical", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_fluorine_f_typical && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te fluorine f minimum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="Te fluorine f minimum"
                      name="te_fluorine_f_minimum"
                      {...register("te_fluorine_f_minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_fluorine_f_minimum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te fluorine f maximum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="Te fluorine f maximum"
                      name="te_fluorine_f_maximum"
                      {...register("te_fluorine_f_maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_fluorine_f_maximum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te mercury hg typical"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="Te mercury hg typical"
                      name="te_mercury_hg_typical"
                      {...register("te_mercury_hg_typical", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_mercury_hg_typical && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te mercury hg minimum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="Te mercury hg minimum"
                      name="te_mercury_hg_minimum"
                      {...register("te_mercury_hg_minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_mercury_hg_minimum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te mercury hg maximum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="Te mercury hg maximum"
                      name="te_mercury_hg_maximum"
                      {...register("te_mercury_hg_maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_mercury_hg_maximum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te molybdenum mo typical"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="Te molybdenum mo typical"
                      name="te_molybdenum_mo_typical"
                      {...register("te_molybdenum_mo_typical", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_molybdenum_mo_typical && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te molybdenum mo minimum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="Te molybdenum mo minimum"
                      name="te_molybdenum_mo_minimum"
                      {...register("te_molybdenum_mo_minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_molybdenum_mo_minimum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te molybdenum mo maximum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="Te molybdenum mo maximum"
                      name="te_molybdenum_mo_maximum"
                      {...register("te_molybdenum_mo_maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_molybdenum_mo_maximum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te lead pb typical"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="Te lead pb typical"
                      name="te_lead_pb_typical"
                      {...register("te_lead_pb_typical", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_lead_pb_typical && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te lead pb minimum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="Te lead pb minimum"
                      name="te_lead_pb_minimum"
                      {...register("te_lead_pb_minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_lead_pb_minimum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te lead pb maximum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="Te lead pb maximum"
                      name="te_lead_pb_maximum"
                      {...register("te_lead_pb_maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_lead_pb_maximum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te phosphorus p typical"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="Te phosphorus p typical"
                      name="te_phosphorus_p_typical"
                      {...register("te_phosphorus_p_typical", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_phosphorus_p_typical && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te phosphorus p minimum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="Te phosphorus p minimum"
                      name="te_phosphorus_p_minimum"
                      {...register("te_phosphorus_p_minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_phosphorus_p_minimum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te phosphorus p maximum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="Te phosphorus p maximum"
                      name="te_phosphorus_p_maximum"
                      {...register("te_phosphorus_p_maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_phosphorus_p_maximum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te selenium se typical"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="Te selenium se typical"
                      name="te_selenium_se_typical"
                      {...register("te_selenium_se_typical", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_selenium_se_typical && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te selenium se minimum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="Te selenium se minimum"
                      name="te_selenium_se_minimum"
                      {...register("te_selenium_se_minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_selenium_se_minimum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te selenium se maximum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="Te selenium se maximum"
                      name="te_selenium_se_maximum"
                      {...register("te_selenium_se_maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_selenium_se_maximum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te vanadium v typical"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="Te vanadium v typical"
                      name="te_vanadium_v_typical"
                      {...register("te_vanadium_v_typical", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_vanadium_v_typical && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te vanadium v minimum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="Te vanadium v minimum"
                      name="te_vanadium_v_minimum"
                      {...register("te_vanadium_v_minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_vanadium_v_minimum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te vanadium v maximum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="Te vanadium v maximum"
                      name="te_vanadium_v_maximum"
                      {...register("te_vanadium_v_maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_vanadium_v_maximum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te zinc zn typical"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="Te zinc zn typical"
                      name="te_zinc_zn_typical"
                      {...register("te_zinc_zn_typical", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_zinc_zn_typical && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te zinc zn minimum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="Te zinc zn minimum"
                      name="te_zinc_zn_minimum"
                      {...register("te_zinc_zn_minimum", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_zinc_zn_minimum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Te zinc zn maximum"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="Te zinc zn maximum"
                      name="te_zinc_zn_maximum"
                      {...register("te_zinc_zn_maximum", {valueAsNumber:true, required: false })}
                    />
                    {errors.te_zinc_zn_maximum && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>


              </Row>
              <Row className="mb-3">
                <h4 className='heading'>SIZING, %</h4>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Size 0 to 50mm typical"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="Size 0 to 50mm typical"
                      name="size_0_to_50mm_typical"
                      {...register("size_0_to_50mm_typical", { valueAsNumber:true, required: false })}
                    />
                    {errors.size_0_to_50mm_typical && <span>Required</span>}
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Size 0 to 50mm min"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="Size 0 to 50mm min"
                      name="size_0_to_50mm_min"
                      {...register("size_0_to_50mm_min", { valueAsNumber:true, required: false })}
                    />
                    {errors.size_0_to_50mm_min && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Size 0 to 50mm max"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="Size 0 to 50mm max"
                      name="size_0_to_50mm_max"
                      {...register("size_0_to_50mm_max", { valueAsNumber:true, required: false })}
                    />
                    {errors.size_0_to_50mm_max && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Above 0 to 50mm typical"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="Above 0 to 50mm typical"
                      name="above_0_to_50mm_typical"
                      {...register("above_0_to_50mm_typical", { valueAsNumber:true, required: false })}
                    />
                    {errors.above_0_to_50mm_typical && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Above 0 to 50mm min"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="Above 0 to 50mm min"
                      name="above_0_to_50mm_min"
                      {...register("above_0_to_50mm_min", { valueAsNumber:true, required: false })}
                    />
                    {errors.above_0_to_50mm_min && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group> <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Above 0 to 50mm max"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="Above 0 to 50mm max"
                      name="above_0_to_50mm_max"
                      {...register("above_0_to_50mm_max", { valueAsNumber:true, required: false })}
                    />
                    {errors.above_0_to_50mm_max && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group> <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Under 2mm typical"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="Under 2mm typical"
                      name="under_2mm_typical"
                      {...register("under_2mm_typical", { valueAsNumber:true, required: false })}
                    />
                    {errors.under_2mm_typical && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group> <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Under 2mm min"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="Under 2mm min"
                      name="under_2mm_min"
                      {...register("under_2mm_min", { valueAsNumber:true, required: false })}
                    />
                    {errors.under_2mm_min && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group> <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Under 2mm max"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="Under 2mm max"
                      name="under_2mm_max"
                      {...register("under_2mm_max", { valueAsNumber:true, required: false })}
                    />
                    {errors.under_2mm_max && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group> <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Size above 2mm typical"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="Size above 2mm typical"
                      name="size_above_2mm_typical"
                      {...register("size_above_2mm_typical", { valueAsNumber:true, required: false })}
                    />
                    {errors.size_above_2mm_typical && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group> <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Size above 2mm min"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="Size above 2mm min"
                      name="size_above_2mm_min"
                      {...register("size_above_2mm_min", { valueAsNumber:true, required: false })}
                    />
                    {errors.size_above_2mm_min && <span>Required </span>}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Size above 2mm max"
                    className="mb-3"


                  >
                    <Form.Control
                      type="number"
                      placeholder="Size above 2mm max"
                      name="size_above_2mm_max"
                      {...register("size_above_2mm_max", { valueAsNumber:true, required: false })}
                    />
                    {errors.size_above_2mm_max && <span>Required </span>}
                  </FloatingLabel>
                  </Form.Group>

              </Row>
              <Row className="mb-3">
                <h4 className='heading'>Testing Method</h4>
                <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Testing Method">
    <Form.Select aria-label="Floating label select example"
    name="testing_method"
    placeholder="Testing method"
    defaultValue=""
    {...register("testing_method", { required: false })}
    >

<option value='' >Select </option>
<option value='According to ASTM Standard Method Unless Otherwise Stated' >According to ASTM Standard Method Unless Otherwise Stated</option>
<option value='According to ISO Methods'>According to ISO Methods</option>
<option value='According to ISO Methods Except HGI, Trace Elements and Ash Analysis to ASTM'>According to ISO Methods Except HGI, Trace Elements and Ash Analysis to ASTM</option>
<option value='According to ISO Methods Except HGI to ASTM'>According to ISO Methods Except HGI to ASTM</option>
<option value='According to ISO Methods, Except HGI and Ash Analysis to ASTM'>According to ISO Methods, Except HGI and Ash Analysis to ASTM</option>
<option value='According to ISO Methods, Except HGI as per ASTM Test Method'>According to ISO Methods, Except HGI as per ASTM Test Method</option>
    </Form.Select>
    {errors.testing_method && <span>Required</span>}
  </FloatingLabel>
              </Form.Group>
              </Row>

      <input type="submit"  className="addaccount-save" value='Save'/>
      <div className='successDiv'><p></p><p></p></div>
    </Form>
    <p></p><p></p>
    </div>
    <Footer/>

</>
    );




};


export default AddProducts;