import { createSlice } from '@reduxjs/toolkit';
import {produce} from 'immer'; // Ensure immer is imported for immutability

const initialState = {

  filteredData: {},
  responseData: null
};

const filterDataSlice = createSlice({
  name: 'filterData',
  initialState,
  reducers: {
    setJsonData: (state, action) => {
       
      console.log(action.payload,'payload')
      if (Object.keys(action.payload).length === 0) {

        state.filteredData = {}

      } else {

        state.filteredData = { ...state.filteredData, ...action.payload };

      }
       
      
    },

    setFilteredData: (state, action) => {

      state.responseData = action.payload

    }

  },

});

export const { setJsonData, setFilteredData } = filterDataSlice.actions;
export default filterDataSlice.reducer;
