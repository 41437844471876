import React, { useEffect, useState, forwardRef } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { useForm, Controller } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import Header from '../Header'
import Footer from '../Footer'
import { AES, enc } from 'crypto-js';
import 'react-date-picker/dist/DatePicker.css';
import RecentItem from "../recentitem/rececentItem";
import 'react-calendar/dist/Calendar.css';
import {Link, useNavigate } from "react-router-dom";
import { API_URL } from '../config';
import DatePicker from "react-datepicker";
// import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import { useParams  } from "react-router-dom";
function  AddFinance(props) {
  const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
  const encryptedAccessToken = localStorage.getItem("access_token");
  const decryptedAccessToken = encryptedAccessToken
    ? AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8)
    : null;
  const navigate=useNavigate();
  const {register, handleSubmit, reset, formState: { errors } } = useForm();
     const onSubmit = data =>{
        const responseData={
          billing_doc_record_type:data.billing_doc_record_type,
          line_item:data.line_item,
          lineitem_type:data.lineitem_type,
          owner :data.owner,
          debit_credit:data.debit_credit,
          calculation_type:data.calculation_type,
          scale_base_type:data.scale_base_type,
          order_object_field_name:data.order_object_field_name,
          payable_dr_acc_group:data.payable_dr_acc_group,
          payable_dr_chart_account_no:data.payable_dr_chart_account_no,
          payable_dr_chart_account_desc:data.payable_dr_chart_account_desc,
          payable_dr_gl_type:data.payable_dr_gl_type,
          payable_dr_field_name:data.payable_dr_field_name,
          payable_cr_acc_group:data.payable_cr_acc_group,
          payable_cr_chart_account_no:data.payable_cr_chart_account_no,
          payable_cr_chart_acc_desc:data.payable_cr_chart_acc_desc,
          payable_cr_gl_type:data.payable_cr_gl_type,
          payable_cr_field_name:data.payable_cr_field_name,
          payment_dr_acc_group:data.payment_dr_acc_group,
          payment_dr_chart_account_no:data.payment_dr_chart_account_no,
          payment_dr_chart_account_desc:data.payment_dr_chart_account_desc,
          payment_dr_gl_type:data.payment_dr_gl_type,
          payment_dr_field_name:data.payment_dr_field_name,
          payment_cr_acc_group:data.payment_cr_acc_group,
          payment_cr_chart_account_no:data.payment_cr_chart_account_no,
          payment_cr_chart_acc_desc:data.payment_cr_chart_acc_desc,
          payment_cr_gl_type:data.payment_cr_gl_type,
          payment_cr_field_name:data.payment_cr_field_name,

          };
      const encryptedAccessToken = localStorage.getItem('access_token');
        const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';
        if (encryptedAccessToken) {
          const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);

      fetch(`${API_URL}add/financeconfig`, {
        method: "POST",
        body: JSON.stringify(responseData),
        headers: { Authorization: `Bearer ${decryptedAccessToken}`,
        'Content-Type': 'application/json' },
      })
        .then((response) => response.json())
        .then((response) => {
          navigate(`/finance/finance-details/${response.id}`)
          toast.success('Added Finance Successfully', {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
          setTimeout(() => {
            reset();
          }, 300);
        })

        console.log(JSON.stringify(data))
      }
};

  return (

    <div className="contactAccount" id='contacts'>
    <Header/>
    <div className="row">
       <div className="col-md-2 recent-item">
       <RecentItem/>
</div>
       <div className="col-md-10 right-section">
    <div className="page-header" id='add-account'>
    <div>
      <h2 className="main-content-title tx-24 mg-b-5">Add New Finance </h2>
      <ol className="breadcrumb">
        <li className="breadcrumb-item active" aria-current="page">Dashboard / Finance / Add New Finance</li>
      </ol>
    </div>
    <div className="d-flex">
      <div className="justify-content-center">

        {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
         <a href='/account/add-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</a>
        </button> */}

      </div>
    </div>
  </div>
   <Form onSubmit={handleSubmit(onSubmit)}>
   <p className="edit-cancel">
              <input type="submit" className="account-save" value="Save" />
              <Link to={"/finance/finance-list"}>
                <input type="submit" className="account-save" value="Cancel" />
              </Link>
            </p>
      <Row className="mb-3">

      <div className='col-md-12'>
      <h4 className='heading'>Information</h4>
        </div>


    <Form.Group
              as={Col}
              md="6"

            >
    <FloatingLabel
            controlId="floatingInput"
            label="Billing Doc Record Type"
            className="mb-3"
          >
            <Form.Control
              type="text"
              placeholder="Billing Doc Record Type"
              name="billing_doc_record_type"
              tabIndex={1}
              {...register("billing_doc_record_type", { required: false })}
            />
             {errors.billing_doc_record_type && <span>Required</span>}
          </FloatingLabel>

            </Form.Group>
            <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Owner"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Owner"
            name="owner"
            tabIndex={4}
            {...register("owner", { required: false })}
            />
             {errors.owner && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="line item"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="line item"
            name="line_item"
            tabIndex={2}
            {...register("line_item", { required: false })}
            />
             {errors.last_name && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6"></Form.Group>
        <Form.Group
              as={Col}
              md="6"
              id=''
            >
              <FloatingLabel controlId="floatingSelect" className='dropDown' label="Line Item Type">
      <Form.Select aria-label="Floating label select example"
      name="lineitem_type"
      placeholder="Line Item Type"
      tabIndex={3}
      {...register("lineitem_type", { required: false })}
      >
       <option value="">--None--</option><option value="Line Item Level">Line Item Level</option><option value="Header Level">Header Level</option>
      </Form.Select>
      {errors.lineitem_type && <span>Required</span>}
    </FloatingLabel>

    </Form.Group>


      <div className='col-md-12'>
      <h4 className='heading'>Configuration</h4>
        </div>

        <Form.Group
              as={Col}
              md="6"
              id=''
            >
              <FloatingLabel controlId="floatingSelect" className='dropDown' label="Debit Credit">
      <Form.Select aria-label="Floating label select example"
      name="debit_credit"
      placeholder="Debit Credit"
      tabIndex={5}
       
      {...register("debit_credit", { required: false })}
      >
       <option value="">--None--</option><option value="Line Item Level">Line Item Level</option><option value="Header Level">Header Level</option>
      </Form.Select>
      {errors.debit_credit && <span>Required</span>}
    </FloatingLabel>

    </Form.Group>

        <Form.Group
              as={Col}
              md="6"
              id=''
            >
              <FloatingLabel controlId="floatingSelect" className='dropDown' label="Order Object Field Name">
      <Form.Select aria-label="Floating label select example"
      name="order_object_field_name"
      placeholder="Order Object Field Name"
      tabIndex={8}
      {...register("order_object_field_name", { required: false })}
      >
       <option value="">--None--</option><option value="Buyer_Final_Dem_Despatch_Payable__c">Buyer Final Dem/Despatch Payable</option><option value="Buyer_Final_Other_Payable__c">Buyer Final Other Payable</option><option value="Supplier_Final_Dem_Despatch_Payable__c">Supplier Final Dem/Despatch Payable</option><option value="Supplier_Final_Other_Payable__c">Supplier Final Other Payable</option><option value="Sales_Invoice__c">Sales Invoice</option><option value="Purchase_Invoice__c">Purchase Invoice</option><option value="Surveyor_Invoice__c">Surveyor Invoice</option><option value="Load_Port_Invoice__c">Load Port Invoice</option>
      </Form.Select>
      {errors.order_object_field_name && <span>Required</span>}
    </FloatingLabel>

    </Form.Group>

        <Form.Group
              as={Col}
              md="6"
              id=''
            >
              <FloatingLabel controlId="floatingSelect" className='dropDown' label="Calculation Type">
      <Form.Select aria-label="Floating label select example"
      name="calculation_type"
      tabIndex={6}
      placeholder="Calculation Type"
      defaultValue=''
      {...register("calculation_type", { required: false })}
      >
     <option value="">--None--</option><option value="Percentage">Percentage</option><option value="Volume Base">Volume Base</option>
      </Form.Select>
      {errors.calculation_type && <span>Required</span>}
    </FloatingLabel>

    </Form.Group>
    <Form.Group
              as={Col}
              md="6"
              id=''
            ></Form.Group>
    <Form.Group
              as={Col}
              md="6"
              id=''
            >
              <FloatingLabel controlId="floatingSelect" className='dropDown' label="Scale Base Type">
      <Form.Select aria-label="Floating label select example"
      name="scale_base_type"
      placeholder="Scale Base Type"
      tabIndex={7}
      {...register("scale_base_type", { required: false })}
      >
     <option value="">--None--</option><option value="Percentage">Percentage</option><option value="Volume Base">Volume Base</option>
      </Form.Select>
      {errors.scale_base_type && <span>Required</span>}
    </FloatingLabel>

    </Form.Group>


    <div className='col-md-12'>
      <h4 className='heading'>Payable / Receivable Config</h4>
        </div>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Payable DR Chart of Account No"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Payable DR Chart of Account No"
            name="payable_dr_chart_account_no"
            tabIndex={8}

            {...register("payable_dr_chart_account_no", { required: false })}
            />
             {errors.payable_dr_chart_account_no && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Payable CR Chart of Account No"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Payable CR Chart of Account No"
            name="payable_cr_chart_account_no"
            tabIndex={11}
            {...register("payable_cr_chart_account_no", { required: false })}
            />
             {errors.payable_cr_chart_account_no && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>

         <Form.Group
              as={Col}
              md="6"
              id=''
            >
              <FloatingLabel controlId="floatingSelect" className='dropDown' label="Payable DR GL Type">
      <Form.Select aria-label="Floating label select example"
      name="payable_dr_gl_type"
      placeholder="Payable DR GL Type"
      tabIndex={9}
      {...register("payable_dr_gl_type", { required: false })}
      >
       <option value="">--None--</option><option value="Buyer">Buyer</option><option value="Supplier">Supplier</option><option value="Surveyor">Surveyor</option><option value="Agent">Agent</option><option value="Bank">Bank</option><option value="Account">Account</option>
      </Form.Select>
      {errors.payable_dr_gl_type && <span>Required</span>}
    </FloatingLabel>

    </Form.Group>


         <Form.Group
              as={Col}
              md="6"
              id=''
            >
              <FloatingLabel controlId="floatingSelect" className='dropDown' label="Payable CR GL Type">
      <Form.Select aria-label="Floating label select example"
      name="payable_cr_gl_type"
      placeholder="Payable CR GL Type"
      tabIndex={12}
      {...register("payable_cr_gl_type", { required: false })}
      >
       <option value="">--None--</option><option value="Buyer">Buyer</option><option value="Supplier">Supplier</option><option value="Surveyor">Surveyor</option><option value="Agent">Agent</option><option value="Bank">Bank</option><option value="Account">Account</option>
      </Form.Select>
      {errors.payable_cr_gl_type && <span>Required</span>}
    </FloatingLabel>

    </Form.Group>

        <Form.Group
              as={Col}
              md="6"
              id=''
            >
              <FloatingLabel controlId="floatingSelect" className='dropDown' label="Payable DR Field Name">
      <Form.Select aria-label="Floating label select example"
      name="payable_dr_field_name"
      placeholder="Payable DR Field Name"
      tabIndex={10}
      {...register("payable_dr_field_name", { required: false })}
      >
        <option value="">--None--</option><option value="Ledger_1__c">Ledger 1</option><option value="Ledger_2__c">Ledger 2</option><option value="Ledger_3__c">Ledger 3</option><option value="Ledger_4__c">Ledger 4</option>
         </Form.Select>
      {errors.payable_dr_field_name && <span>Required</span>}
    </FloatingLabel>

    </Form.Group>

    <Form.Group
              as={Col}
              md="6"
              id=''
            >
              <FloatingLabel controlId="floatingSelect" className='dropDown' label="Payable CR Field Name">
      <Form.Select aria-label="Floating label select example"
      name="payable_cr_field_name"
      placeholder="Payable CR Field Name"
      tabIndex={13}
      {...register("payable_cr_field_name", { required: false })}
      >
        <option value="">--None--</option><option value="Ledger_1__c">Ledger 1</option><option value="Ledger_2__c">Ledger 2</option><option value="Ledger_3__c">Ledger 3</option><option value="Ledger_4__c">Ledger 4</option>
         </Form.Select>
      {errors.payable_cr_field_name && <span>Required</span>}
    </FloatingLabel>

    </Form.Group>



    <div className='col-md-12'>
      <h4 className='heading'>Payment / Receivable Config</h4>
        </div>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Payment DR Chart of Account No"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Payment DR Chart of Account No"
            name="payment_dr_chart_account_no"
            tabIndex={14}
            {...register("payment_dr_chart_account_no", { required: false })}
            />
             {errors.payment_dr_chart_account_no && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Payment CR Chart of Account No"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Payment CR Chart of Account No"
            tabIndex={17}
            name="payment_cr_chart_account_no"

            {...register("payment_cr_chart_account_no", { required: false })}
            />
             {errors.payment_cr_chart_account_no && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>

         <Form.Group
              as={Col}
              md="6"
              id=''
            >
              <FloatingLabel controlId="floatingSelect" className='dropDown' label="Payment DR GL Type">
      <Form.Select aria-label="Floating label select example"
      name="payment_dr_gl_type"
      placeholder="Payment DR GL Type"
      tabIndex={15}
      {...register("payment_dr_gl_type", { required: false })}
      >
       <option value="">--None--</option><option value="Buyer">Buyer</option><option value="Supplier">Supplier</option><option value="Surveyor">Surveyor</option><option value="Agent">Agent</option><option value="Bank">Bank</option><option value="Account">Account</option>
      </Form.Select>
      {errors.payment_dr_gl_type && <span>Required</span>}
    </FloatingLabel>

    </Form.Group>


         <Form.Group
              as={Col}
              md="6"
              id=''
            >
              <FloatingLabel controlId="floatingSelect" className='dropDown' label="Payment CR GL Type">
      <Form.Select aria-label="Floating label select example"
      name="payment_cr_gl_type"
      placeholder="Payment CR GL Type"
      tabIndex={18}
      {...register("payment_cr_gl_type", { required: false })}
      >
       <option value="">--None--</option><option value="Buyer">Buyer</option><option value="Supplier">Supplier</option><option value="Surveyor">Surveyor</option><option value="Agent">Agent</option><option value="Bank">Bank</option><option value="Account">Account</option>
      </Form.Select>
      {errors.payment_cr_gl_type && <span>Required</span>}
    </FloatingLabel>

    </Form.Group>

        <Form.Group
              as={Col}
              md="6"
              id=''
            >
              <FloatingLabel controlId="floatingSelect" className='dropDown' label="Payment DR Field Name">
      <Form.Select aria-label="Floating label select example"
      name="payment_dr_field_name"
      placeholder="Payment DR Field Name"
      tabIndex={16}
      {...register("payment_dr_field_name", { required: false })}
      >
        <option value="">--None--</option><option value="Ledger_1__c">Ledger 1</option><option value="Ledger_2__c">Ledger 2</option><option value="Ledger_3__c">Ledger 3</option><option value="Ledger_4__c">Ledger 4</option>
         </Form.Select>
      {errors.payment_dr_field_name && <span>Required</span>}
    </FloatingLabel>

    </Form.Group>

    <Form.Group
              as={Col}
              md="6"
              id=''
            >
              <FloatingLabel controlId="floatingSelect" className='dropDown' label="Payment CR Field Name">
      <Form.Select aria-label="Floating label select example"
      name="payment_cr_field_name"
      placeholder="Payment CR Field Name"
      tabIndex={19}
      {...register("payment_cr_field_name", { required: false })}
      >
        <option value="">--None--</option><option value="Ledger_1__c">Ledger 1</option><option value="Ledger_2__c">Ledger 2</option><option value="Ledger_3__c">Ledger 3</option><option value="Ledger_4__c">Ledger 4</option>
         </Form.Select>
      {errors.payment_cr_field_name && <span>Required</span>}
    </FloatingLabel>

    </Form.Group>

           </Row>
           <p className="edit-cancel">
              <input type="submit" className="account-save" value="Save" />
              <Link to={"/finance/finance-list"}>
                <input type="submit" className="account-save" value="Cancel" />
              </Link>
            </p>
      <div className='contact-success'><p></p><p></p></div>
    </Form>
    </div>  </div>
    <Footer/>
    </div>
  );
}

export default AddFinance;
