// import React, { Component, useState, useEffect, forwardRef, useContext } from "react";
// import { Card, OverlayTrigger, Tooltip, Table } from "react-bootstrap";
// import "datatables.net-dt/js/dataTables.dataTables.js";
// import { AES, enc } from "crypto-js";
// import "datatables.net-responsive-dt";
// import "datatables.net-dt/css/jquery.dataTables.min.css";
// import "react-data-table-component-extensions/dist/index.css";
// import { Link, useNavigate } from "react-router-dom";
// import { TokenContext } from "../AuthProvider";
// import Header from "../Header";
// import Footer from "../Footer";
// import { API_URL } from '../config';
// import { parse, format, isValid } from 'date-fns';
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import $ from 'jquery';
// import { useForm } from "react-hook-form";
// import Popup from "reactjs-popup";
// import Container from "react-bootstrap/Container";
// import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";
// import {
//   FaEnvelope,
//   FaPhoneSquareAlt,
//   FaMobileAlt,
//   FaFax,
// } from "react-icons/fa";
// import axios from "axios";
// import { BsFillEnvelopeFill } from "react-icons/bs";
// import { FaPencilAlt, FaTrashAlt, FaPlus } from "react-icons/fa";
// // import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
// import { useParams } from "react-router-dom";
// import DatePicker from "react-datepicker";
// import ContractShippmentDetails from "../shippment/contractShippmentDetails copy";

// const AllQuoteView = () => {
//   const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
//   const encryptedAccessToken = localStorage.getItem("access_token");
//   const decryptedAccessToken = encryptedAccessToken
//     ? AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8)
//     : null;
//   const navigate = useNavigate();
//   const params = useParams();
//   const id = params.id;
//   const userRole = localStorage.getItem("role");
//   const [opp, setOpp] = useState([])
//   const [locked, setCheck1] = useState(false)
//   // console.log(locked)

//   const [startDate, setStartDate] = useState(null);
//   const birthDate = startDate ? `${startDate.getDate().toString().padStart(2, '0')}/${(startDate.getMonth() + 1).toString().padStart(2, '0')}/${startDate.getFullYear()}` : null;
//   const handleStartDateChange = (date) => {
//     setStartDate(date);
//   };


//   const [startDate1, setStartDate1] = useState(null);
//   const birthDate1 = startDate1 ? `${startDate1.getDate().toString().padStart(2, '0')}/${(startDate1.getMonth() + 1).toString().padStart(2, '0')}/${startDate1.getFullYear()}` : null;
//   const handleStartDateChange1 = (date) => {
//     setStartDate1(date);
//   };

//   const [start, setStart] = useState(new Date());
//   const startDates =
//     start.toLocaleDateString("en-US", { year: "numeric" }) +
//     "-" +
//     start.toLocaleDateString("en-US", { month: "2-digit" }) +
//     "-" +
//     start.toLocaleDateString("en-US", { day: "2-digit" });
//   const ExampleCustomInput2 = forwardRef(({ value, onClick, onChange }, ref) => (
//     <input
//       value={value}
//       className="example-custom-input"
//       onClick={onClick}
//       onChange={onChange}
//       name="contract_start_date"
//       ref={ref}
//     ></input>
//   ));
//   const [effective, setEffective] = useState(new Date());
//   const contractEffective =
//     effective.toLocaleDateString("en-US", { year: "numeric" }) +
//     "-" +
//     effective.toLocaleDateString("en-US", { month: "2-digit" }) +
//     "-" +
//     effective.toLocaleDateString("en-US", { day: "2-digit" });
//   const ExampleCustomInput1 = forwardRef(({ value, onClick, onChange }, ref) => (
//     <input
//       value={value}
//       className="example-custom-input"
//       onClick={onClick}
//       onChange={onChange}
//       name="contract_effective_date"
//       ref={ref}
//     ></input>
//   ));
//   useEffect(() => {

//     fetchDataOpp();
//   }, [decryptedAccessToken]);

//   const fetchDataOpp = async () => {
//     try {
//       const response = await axios.get(`${API_URL}all/opportunity`, {
//         headers: { Authorization: `Bearer ${decryptedAccessToken}` },
//       }
//       );
//       const data = response.data.opportunity;
//       // console.log(data)
//       setOpp(data);

//     } catch (error) {
//       console.error(error);
//     }
//   };

//   const [data, setData] = useState({});
//   const [data1, setData1] = useState([]);
//   const [isEditMode, setIsEditMode] = useState(false);
//   const [editedData, setEditedData] = useState({});
//   const [surveyors, setSurveyor] = useState([]);

//   useEffect(() => {
//     fetchData6();
//   }, [decryptedAccessToken]);

//   const fetchData6 = async () => {
//     try {
//       const response = await axios.get(
//         `${API_URL}get/surveyor`,
//         {
//           headers: { Authorization: `Bearer ${decryptedAccessToken}` },
//         }
//       );
//       const data = response.data;
//       // console.log(JSON.stringify(data))
//       setSurveyor(data);
//     } catch (error) {
//       console.error(error);
//     }
//   };


//   const handleEditClick = () => {
//     setIsEditMode(true);
//     setEditedData(data);
//   };

//   const handleChange = (e) => {
//     const { name, value, } = e.target;
//     setData1((prevState) => ({
//       ...prevState,
//       [name]: value,
//     }));
//   };

//   const handleCheckboxChange = (event) => {
//     setCheck1(event.target.checked);
//   };


//   const handleCancelEdit = () => {
//     setIsEditMode(false);
//     setEditedData(data);
//     setTimeout(() => {
//       $('#tcf').DataTable();
//       $('#quote').DataTable();
//       $('#quote-product').DataTable();
//     }, 10);
//   };
//   const [traderData, setTraderData] = useState([]);
//   useEffect(() => {
//     fetchDataTrader();
//   }, [decryptedAccessToken]);

//   const fetchDataTrader = async () => {
//     try {
//       const response = await axios.get(
//         `${API_URL}get/all/rwoodusers`,
//         {
//           headers: { Authorization: `Bearer ${decryptedAccessToken}` },
//         }
//       );
//       const data = response.data.rwoodusers;      // console.log(data)
//       setTraderData(data);
//     } catch (error) {
//       console.error(error);
//     }
//   };
//   // console.log(userRole);

//   const { username, token } = useContext(TokenContext);
//   const [datas, setDatas] = useState([]);

//   const [tcf, setTCF] = useState([]);
//   const [quote, setQuote] = useState([]);
//   const [quoteProduct, setQuoteproduct] = useState([]);
//   const [lengths, setLengths] = useState([]);
//   const [startDate2, setStartDate2] = useState(null);
//   const fetchData = async () => {
//     try {
//       const response = await axios.get(
//         `${API_URL}get/all/offer_quote_byID/` + id,
//         {
//           headers: { Authorization: `Bearer ${decryptedAccessToken}` },
//         }
//       );
//       console.log('response', response)
//       const data = response.data.quote_details[0];
//       let birth_Date = data.quote_date

//       if (birth_Date !== null) {
//         const defaultDatepicker = parse(birth_Date, 'dd/MM/yyyy', new Date());
//         setStartDate2(defaultDatepicker);
//       } else {
//         setStartDate2(null)
//       }
//       const dataTcf = response.data.tcf;
//       const quote = response.data.quote_shipment;
//       const quote_products = response.data.quote_product;
//       const quote_product_len = response.data.quote_product_length; // Assuming quote_length is what you want to track
//       const numberInt = parseInt(quote_product_len, 10);
//       setLengths(numberInt);
//       console.log(response)
//       setDatas(data);
//       setData1(data);
//       setTCF(dataTcf);
//       setQuote(quote);
//       setQuoteproduct(quote_products);

//       setTimeout(() => {
//         $('#tcf').DataTable();
//         $('#quote').DataTable();
//         $('#quote-product').DataTable();
//       }, 10);
//     } catch (error) {
//       console.error(error);
//     }
//   };


//   useEffect(() => {
//     fetchData(); // Call the fetchData function quoteProduct

//   }, [decryptedAccessToken, data]);



//   const [isButtonEnabled, setIsButtonEnabled] = useState(false);
//   useEffect(() => {
//     setIsButtonEnabled(true);
//   }, []);
//   const [isSubmitted, setIsSubmitted] = useState(false);


//   const createContract = () => {
//     console.log("");

//     if (lengths > 0) {
//       const encryptedAccessToken = localStorage.getItem("access_token");
//       const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";

//       if (encryptedAccessToken) {
//         const decryptedAccessToken = AES.decrypt(
//           encryptedAccessToken,
//           secret_key
//         ).toString(enc.Utf8);

//         fetch(`${API_URL}add/quotes/contract/` + id, {
//           method: "POST",
//           headers: {
//             Authorization: `Bearer ${decryptedAccessToken}`,
//             "Content-Type": "application/json",
//           },
//         })
//           .then((response) => {
//             if (!response.ok) {
//               // Handle non-successful response
//               console.error("Request failed with status:", response.status);
//               throw new Error(`Request failed with status ${response.status}`);
//             }
//             return response.json();
//           })
//           .then((response) => {
//             console.log(response);
//             let contractId = response.contract_id;
//             navigate(`/contract/contract-details/${contractId}`);
//             fetch(`${API_URL}add/contract_ship/${id}/${contractId}`, {
//               method: "POST",
//               headers: {
//                 Authorization: `Bearer ${decryptedAccessToken}`,
//                 "Content-Type": "application/json",
//               },
//             })
//               .then((response) => {
//                 if (!response.ok) {
//                   console.error("Request failed with status:", response.status);
//                   throw new Error(`Request failed with status ${response.status}`);
//                 }
//                 return response.json();
//               })
//               .then((response) => {
//                 console.log("**********", id, contractId);
//                 fetch(`${API_URL}add/contract_products/${id}/${contractId}`,
//                   {
//                     method: "POST",
//                     headers: {
//                       Authorization: `Bearer ${decryptedAccessToken}`,
//                       "Content-Type": "application/json",
//                     },
//                   }
//                 )
//                   .then((response) => {

//                     // console.log(response)
//                     if (!response.ok) {
//                       console.error("Request failed with status:", response.status);
//                       throw new Error(`Request failed with status ${response.status}`);
//                     }
//                     return response.json();
//                   })
//                   .then((response) => {
//                     console.log(response)
//                     if (response.msg === "success") {
//                       setTimeout(() => {
//                         // reset();
//                         navigate(`/contract/contract-details/${contractId}`);
//                       }, 2000);
//                     }
//                   });
//               });
//           })
//           .catch((error) => {
//             console.error("Error:", error);
//             // Handle errors here
//           });
//       }
//     } else {
//       toast.error("Create Quote Product First ", {
//         position: "bottom-center",
//         autoClose: 2000,
//         hideProgressBar: false,
//         toastId: "success1",
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: undefined,
//         theme: "light",
//       });
//     }
//   };

//   const [bdate, setbirthDate] = useState('')
//   useEffect(() => {
//     const dateObject = new Date(startDate2);
//     if (dateObject) {
//       const formattedDate = format(dateObject, 'dd/MM/yyyy', { timeZone: 'UTC' });
//       setbirthDate(formattedDate)
//       console.log(formattedDate)
//     }
//   })
//   const [lockedOne, setLockedOne] = useState({})
//   const check_locked = data1.locked;

//   useEffect(() => {
//     setLockedOne(check_locked); // Update lockeds with the value of tt
//   }, [check_locked]); // Run this effect whenever tt changes

//   useEffect(() => {
//   }, [lockedOne]); // Run this effect whenever lockeds changes



//   const handleCheckboxLocked = (e) => {
//     setLockedOne(e.target.checked);
//     // setLockeds(!lockeds)
//     // Debug log

//   };


//   const handleSaveEdit = (e) => {
//     setIsSubmitted(true)
//     e.preventDefault();
//     setTimeout(() => {
//       $('#tcf').DataTable();
//       $('#quote').DataTable();
//       $('#quote-product').DataTable();
//     }, 10);
//     const responseData = {
//       "locked": lockedOne,
//       "account_name": data1.account_name,
//       "additional_to_city": data1.additional_to_city,
//       "additional_to_country": data1.additional_to_country,
//       "additional_to_name": data1.additional_to_name,
//       "additional_to_state_province": data1.additional_to_state_province,
//       "additional_to_street": data1.additional_to_street,
//       "additional_to_zip_postal_code": data1.additional_to_zip_postal_code,
//       "applicable_law": data1.applicable_law,
//       "arbitration": data1.arbitration,
//       "phone_fax": data1.phone_fax,
//       "bill_to_city": data1.bill_to_city,
//       "bill_to_country": data1.bill_to_country,
//       "bill_to_name": data1.bill_to_name,
//       "bill_to_state_province": data1.bill_to_state_province,
//       "bill_to_street": data1.bill_to_street,
//       "bill_to_zip_postal_code": data1.bill_to_zip_postal_code,
//       "bonus_penalty_clause": data1.bonus_penalty_clause,
//       "commodity": data1.commodity,
//       "contact_name": data1.contact_name,
//       "contract": data1.contract,
//       "currency": data1.currency,
//       "description": data1.description,
//       "destination": data1.destination,
//       "email": data1.email,
//       "trader_name": data1.trader_name,
//       // "total_price":parseFloat(data1.total_price),
//       "exchange_rate": data1.exchange_rate,
//       "expiration_date": birthDate1,
//       "general_terms": data1.general_terms,
//       "no_of_product": data1.no_of_product,
//       "no_of_quote_shipment": data1.no_of_quote_shipment,
//       "offer_validity_terms": data1.offer_validity_terms,
//       "opportunity_name": data1.opportunity_name,
//       "origin": data1.origin,
//       "additional_to": data1.additional_to,
//       "quote_to": data1.quote_to,
//       "phone": data1.phone,
//       "bill_to": data1.bill_to,
//       "ship_to": data1.ship_to,
//       "created_by": data1.created_by,
//       "modified_by": data1.modified_by,
//       "payment_terms": data1.payment_terms,
//       "phone_fax": data1.phone_fax,
//       "product_description": data1.product_description,
//       "quantity_MT": parseFloat(data1.quantity_MT),
//       "quote_date": bdate,
//       "quote_name": data1.quote_name,
//       "quote_number": data1.quote_number,
//       "quote_to_city": data1.quote_to_city,
//       "quote_to_country": data1.quote_to_country,
//       "quote_to_name": data1.quote_to_name,
//       "quote_to_state_province": data1.quote_to_state_province,
//       "quote_to_street": data1.quote_to_street,
//       "quote_to_zip_postal_code": data1.quote_to_zip_postal_code,
//       "rejections": data1.rejections,
//       "rwood_surveyor_agent": data1.rwood_surveyor_agent,
//       "ship_to_city": data1.ship_to_city,
//       "ship_to_country": data1.ship_to_country,
//       "ship_to_name": data1.ship_to_name,
//       "ship_to_state_province": data1.ship_to_state_province,
//       "ship_to_street": data1.ship_to_street,
//       "ship_to_zip_postal_code": data1.ship_to_zip_postal_code,
//       "status": data1.status,
//       "supplier": data1.supplier,
//       "surveyor_2": data1.surveyor_2,
//       "suveyor_3": data1.suveyor_3,
//       "trader_name": data1.trader_name,
//     }
//     console.log(JSON.stringify(responseData))
//     fetch(`${API_URL}update_offer_quotes/${id}`, {
//       method: 'PUT',
//       body: JSON.stringify(responseData),
//       headers: {
//         Authorization: `Bearer ${decryptedAccessToken}`,
//         'Content-Type': 'application/json'
//       },

//     })
//       .then((response) => response.json())
//       .then((updatedData) => {
//         console.log(updatedData)
//         fetchData()
//         if(updatedData.success===true){

//           const messageContainer = document.getElementById('messageContainer');

//         // Create the success message element
//         const successMessage = document.createElement('div');
//         successMessage.className = 'error-message';
//         successMessage.innerText = updatedData.msg;

//         // Append the success message to the container
//         messageContainer.appendChild(successMessage);

//         // Scroll to the top
//         window.scrollTo({
//             top: 0,
//             behavior: "smooth"
//         });
//         setTimeout(() => {
//           successMessage.remove();
//       }, 2000);
//       setData(updatedData);
//         setIsEditMode(false);


//         }
//         else if(updatedData.success === false) {
//           const messageContainer = document.getElementById('messageContainer');

//         // Create the success message element
//         const successMessage = document.createElement('div');
//         successMessage.className = 'error-message';
//         successMessage.innerText = updatedData.msg;

//         // Append the success message to the container
//         messageContainer.appendChild(successMessage);

//         // Scroll to the top
//         window.scrollTo({
//             top: 0,
//             behavior: "smooth"
//         });
//         setTimeout(() => {
//           successMessage.remove();
//       }, 2000);
//         }


//       })
//       .catch((error) => console.error(error));
//   };
//   console.log(lengths);


//   if (!datas) {
//     return null;
//   }
//   return (
//     <div id="view-page">
//       <Header />
//       <div className="contactAccount">

//         <div className="page-header" id='add-account'>
//           <div>
//             <h2 className="main-content-title tx-24 mg-b-5">Quotes Detalis</h2>
//             <ol className="breadcrumb">
//               <li className="breadcrumb-item active" aria-current="page">Dashboard / Quotes Detalis</li>
//             </ol>
//           </div>
//           <div className="d-flex opd">
//             <div className="justify-content-center">
//               {/*<button type="button" className="btn btn-primary my-2 btn-icon-text">
//        <Link to={`/opportunity/add-shipment/${id}`} > <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Opp Shipment</Link>
//       </button>
//       <button type="button" className="btn btn-primary my-2 btn-icon-text">
//        <Link to={`/opportunity/add-quote/${id}`} > <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Quotes</Link>
//       </button>
//       <button type="button" className="btn btn-primary my-2 btn-icon-text">
//        <Link to={`/all-quotes`}> <i className="fe fe-download-cloud me-2"></i><FaPlus/> All Quotes</Link>
//       </button>*/}

//             </div>
//           </div>
//         </div>
//       </div>
//       {data1.quote_record_type === 'Buyer - Long Term' ? (
//         <div className="tasks edit-view">
//  <div id="messageContainer"></div>
//           {isEditMode ? (
//             <>
//               <p className='edit-btn'>
//                 <input
//                   type="submit"
//                   className="account-save"
//                   onClick={handleSaveEdit}
//                   //value={isSubmitted ? "Saving" : "Save"}
//                  value="Save"
//                 />
//                 <button className="btn btn-primary" onClick={handleCancelEdit}>Cancel</button>
//               </p>
//               <table class="table table-bordered account-table tables">
//                 <tbody>
//                   <tr>
//                     <td>
//                       <span>Quote Number</span>
//                       <br />
//                       <input
//                         type="text" className='form-control'
//                         name="quote_number"
//                         disabled
//                         defaultValue={data1.quote_number || ''}
//                         onChange={handleChange}
//                       />
//                     </td>
//                     <td>
//                       <span>Quote Record Type</span>
//                       <br />
//                       <input
//                         type="text" className='form-control'
//                         name="quote_record_type"
//                         disabled
//                         defaultValue={data1.quote_record_type || ''}
//                         onChange={handleChange}
//                       />
//                     </td>


//                   </tr>
//                   <tr>
//                     <td>
//                       <span>Locked</span>
//                       <br />
//                       <input
//                         type='checkbox'
//                         name='locked'
//                         checked={lockedOne} // Use the state variable to determine if the checkbox is checked
//                         onChange={handleCheckboxLocked} // Handle the change event
//                         className='mx-3'
//                       />
//                     </td>
//                     <td>
//                       <span>Currency</span>
//                       <br />

//                       <select name="currency"
//                         defaultValue={data1.currency || ''}
//                         onChange={handleChange} className="form-control">
//                         <option defaultValue='' >---None---</option>
//                         <option defaultValue='USD' >USD</option>
//                         <option defaultValue='IDR' >IDR</option>
//                       </select>
//                     </td>
//                   </tr>
//                   <tr>
//                     <td>
//                       <span>Exchange Rate</span>
//                       <br />
//                       <input
//                         type="text" className='form-control'
//                         name="exchange_rate"
//                         defaultValue={data1.exchange_rate || ''}
//                         onChange={handleChange}
//                       />
//                     </td>
//                     <td>
//                       <span>Opportunity Name</span>
//                       <br />

//                       <select className='form-control'
//                         name="opportunity_name"
//                         defaultValue={data1.opportunity_name || ''}>
//                         <option value='' >Select</option>
//                         {
//                           opp.map((x) => {
//                             return (
//                               <option value={x.opportunity_name}>{x.opportunity_name}</option>
//                             )
//                           })
//                         }
//                       </select>
//                     </td>
//                   </tr>
//                   <tr>
//                     <td>
//                       <span>Quote Name</span>
//                       <br />
//                       <input
//                         type="text" className='form-control'
//                         name="quote_name"
//                         defaultValue={data1.quote_name || ''}
//                         onChange={handleChange}
//                       />
//                     </td>
//                     <td>
//                       <span>Status</span>
//                       <br />
//                       <select
//                         name="status"
//                         className='form-control'
//                         defaultValue={data1.status || ''}
//                         onChange={handleChange}
//                       >
//                         <option value='Offered'>Offered</option>
//                         <option value='Accepted'>Accepted</option>
//                         <option value='Rejected'>Rejected</option>
//                         <option value='Sold'>Sold</option>
//                       </select>
//                     </td>

//                   </tr>
//                   <tr>
//                     <td>
//                       <span>Account Name</span>
//                       <br />
//                       <input
//                         type="text" className='form-control record-type'
//                         name="account_name"
//                         disabled
//                         defaultValue={data1.account_name || ''}
//                         onChange={handleChange}
//                       />
//                     </td>
//                     <td>
//                       <span>Supplier</span>
//                       <br />
//                       <input
//                         type="text" className='form-control'
//                         name="supplier"
//                         defaultValue={data1.supplier || ''}
//                         onChange={handleChange}
//                       />
//                     </td>
//                   </tr><tr><td>
//                     <span>Contact Name</span>
//                     <br />
//                     <input
//                       type="text" className='form-control'
//                       name="contact_name"
//                       defaultValue={data1.contact_name || ''}
//                       onChange={handleChange}
//                     />
//                   </td>

//                     <td>
//                       <span>Quote Date</span>
//                       <br />
//                       <DatePicker
//                         selected={startDate2}
//                         onChange={(date) => setStartDate2(date)}
//                         dateFormat="dd/MM/yyyy"
//                         placeholderText="Select a date"
//                       />

//                     </td>

//                   </tr>
//                   <tr>
//                     <td>
//                       <span>Expiration Date</span>
//                       <br />

//                       <DatePicker
//                         selected={startDate1}
//                         onChange={handleStartDateChange1}

//                         dateFormat='dd/MM/yyyy'
//                         placeholderText="Select"
//                       />

//                     </td>
//                     <td>
//                       <span>Trader Name</span>
//                       <br />


//                       <select className='form-control'
//                         name="trader_name"
//                         defaultValue={data1.trader_name || ''} onChange={handleChange}>
//                         <option value='' >Select</option>
//                         {
//                           traderData.map((x, i) => {
//                             return (
//                               <option key={i} value={x.rwood_username}>
//                                 {x.rwood_username}
//                               </option>
//                             )
//                           })
//                         }
//                       </select>
//                     </td>

//                   </tr>
//                   <tr>

//                     <td>
//                       <span>No of Quote shipment</span>
//                       <br />
//                       <input
//                         type="text" className='form-control'
//                         name="no_of_quote_shipment"
//                         defaultValue={data1.no_of_quote_shipment || ''}
//                         onChange={handleChange}
//                       />
//                     </td>

//                   </tr>
//                 </tbody>
//               </table>
//               <div className="col-md-12" id='ax.account_details'>
//                 <h4 className="heading">Product information</h4>
//                 <hr></hr>
//               </div>
//               <table class="table table-bordered account-table tables">
//                 <tbody>
//                   <tr>
//                     <td>
//                       <span>Quantity MT</span>
//                       <br />
//                       <input
//                         type="text" className='form-control'
//                         name="quantity_MT"
//                         defaultValue={data1.quantity_MT || ''}
//                         onChange={handleChange}
//                       />
//                     </td>
//                     <td>
//                       <span>Contract</span>
//                       <br />
//                       <input
//                         type="text" className='form-control'
//                         name="contract"
//                         defaultValue={data1.contract || ''}
//                         onChange={handleChange}
//                       />
//                     </td>
//                   </tr>
//                   <tr>
//                     <td>
//                       <span>Product Description</span>
//                       <br />
//                       <input
//                         type="text" className='form-control'
//                         name="product_description"
//                         defaultValue={data1.product_description || ''}
//                         onChange={handleChange}
//                       />
//                     </td>

//                     <td>
//                       {/* <span>Total Price</span> */}
//                       <br />
//                       {/* <input
//               type="text" className='form-control'
//               name="total_price"
//               defaultValue={data1.total_price || ''}
//               onChange={handleChange}
//             /> */}
//                     </td>


//                   </tr>
//                 </tbody>
//               </table>
//               <div className="col-md-12" id='ax.account_details'>
//                 <h4 className="heading">Market Information</h4>
//                 <hr></hr>
//               </div>
//               <table class="table table-bordered account-table tables">
//                 <tbody>
//                   <tr>
//                     <td>
//                       <span>Origin</span>
//                       <br />
//                       <input
//                         type="text" className='form-control'
//                         name="origin"
//                         defaultValue={data1.origin || ''}
//                         onChange={handleChange}
//                       />
//                     </td>
//                     <td>
//                       <span>Commodity</span>
//                       <br />
//                       <select className='form-control'
//                         name="commodity"
//                         defaultValue={data1.commodity || ''}
//                         onChange={handleChange}>
//                         <option>---None---</option>
//                         <option defaultValue="Indonesian Steam Coal in Bulk (&quot;Coal&quot;)">Indonesian Steam Coal in Bulk ("Coal")</option><option defaultValue="US Coal">US Coal</option><option defaultValue="Russia Coal">Russia Coal</option><option defaultValue="SA Coal">SA Coal</option><option defaultValue="Clinker in Bulk">Clinker in Bulk</option><option defaultValue="Australian Steam Coal">Australian Steam Coal</option>
//                       </select>
//                     </td>

//                   </tr>
//                   <tr>
//                     <td>
//                       <span>Destination</span>
//                       <br />

//                       <input
//                         type="text" className='form-control'
//                         name="destination"
//                         defaultValue={data1.destination || ''}
//                         onChange={handleChange}
//                       />
//                     </td>
//                     <td></td>
//                   </tr>
//                 </tbody>
//               </table>
//               <div className="col-md-12" id='ax.account_details'>
//                 <h4 className="heading">Price and Price Adjustment</h4>
//                 <hr></hr>
//               </div>
//               <table class="table table-bordered account-table tables">
//                 <tbody>
//                   <tr>
//                     <td>
//                       <span>Bonus/Penalty Clause</span>
//                       <br />

//                       <textarea className='form-control'
//                         name="bonus_penalty_clause"
//                         defaultValue={data1.bonus_penalty_clause || ''}
//                         onChange={handleChange}>

//                       </textarea>

//                     </td>
//                     <td>
//                       <span>Rejections</span>
//                       <br />

//                       <textarea className='form-control'
//                         name="rejections"
//                         defaultValue={data1.rejections || ''}
//                         onChange={handleChange}>
//                       </textarea>
//                     </td>

//                   </tr>
//                   <tr>
//                     <td>
//                       <span>Payment Term</span>
//                       <br />


//                       <textarea className='form-control'
//                         name="payment_terms"
//                         defaultValue={data1.payment_terms || ''}
//                         onChange={handleChange}>
//                       </textarea>
//                     </td>
//                     <td>
//                       <span>General Terms</span>
//                       <br />


//                       <textarea className='form-control'
//                         name="general_terms"
//                         defaultValue={data1.general_terms || ''}
//                         onChange={handleChange}>
//                       </textarea>
//                     </td>

//                   </tr>
//                 </tbody>
//               </table>
//               <div className="col-md-12" id='ax.account_details'>
//                 <h4 className="heading">Terms & Conditions</h4>
//                 <hr></hr>
//               </div>
//               <table class="table table-bordered account-table tables">
//                 <tbody>
//                   <tr>
//                     <td>
//                       <span>Arbitration</span>
//                       <br />

//                       <select className='form-control'
//                         name="arbitration"
//                         defaultValue={data1.arbitration || ''}
//                         onChange={handleChange}>
//                         <option value="">--None--</option><option value="In Singapore as per SIAC">In Singapore as per SIAC</option><option value="Singapore Chamber of Maritime Arbitration (SCMA)">Singapore Chamber of Maritime Arbitration (SCMA)</option><option value="Laws of England and Wales">Laws of England and Wales</option><option value="Rules of Badan Arbitrase Nasional Indonesia (BANI)">Rules of Badan Arbitrase Nasional Indonesia (BANI)</option>
//                       </select>
//                     </td>
//                     <td>
//                       <span>Rwood Surveyor Agent</span>
//                       <br />
//                       <input
//                         type="text" className='form-control'
//                         name="rwood_surveyor_agent"
//                         defaultValue={data1.rwood_surveyor_agent || ''}
//                         onChange={handleChange}
//                       />
//                     </td>

//                   </tr>
//                   <tr>
//                     <td>
//                       <span>Applicable Law</span>
//                       <br />

//                       <select className='form-control'
//                         name="applicable_law"
//                         defaultValue={data1.applicable_law || ''}
//                         onChange={handleChange}>
//                         <option value="">--None--</option><option value="English Law">English Law</option><option value="Singapore Law">Singapore Law</option><option value="Indonesian Law">Indonesian Law</option>
//                       </select>
//                     </td>
//                     <td>
//                       <span>Surveyor 2	</span>
//                       <br />

//                       <select className='form-control'
//                         name="surveyor_2"
//                         defaultValue={data1.surveyor_2 || ''}
//                         onChange={handleChange}>
//                         <option>---None---</option>
//                         {surveyors.map((x) => {
//                           return (
//                             <option value={x.account_name}>{x.account_name}</option>
//                           );
//                         })}
//                       </select>
//                     </td>
//                   </tr>
//                   <tr>
//                     <td>
//                       <span>Surveyor 3</span>
//                       <br />

//                       <select className='form-control'
//                         name="suveyor_3"
//                         defaultValue={data1.suveyor_3 || ''}
//                         onChange={handleChange}>
//                         <option>---None---</option>
//                         {surveyors.map((x) => {
//                           return (
//                             <option value={x.account_name}>{x.account_name}</option>
//                           );
//                         })}
//                       </select>
//                     </td>   <td>
//                       <span>Offer Validity Terms</span>
//                       <br />

//                       <textarea className='form-control'
//                         name="offer_validity_terms"
//                         defaultValue={data1.offer_validity_terms || ''}
//                         onChange={handleChange}>

//                       </textarea>
//                     </td>

//                   </tr>
//                   <tr>
//                     <td>
//                       <span>Description</span>
//                       <br />


//                       <textarea className='form-control'
//                         name="description"
//                         defaultValue={data1.description || ''}
//                         onChange={handleChange}>

//                       </textarea>
//                     </td>
//                     <td>

//                     </td>

//                   </tr>
//                 </tbody>
//               </table>

//               <div className="col-md-12" id='ax.account_details'>
//                 <h4 className="heading">Prepared For</h4>
//                 <hr></hr>
//               </div>
//               <table class="table table-bordered account-table tables">
//                 <tbody>
//                   <tr>
//                     <td>
//                       <span>Quote To Name</span>
//                       <br />
//                       <input
//                         type="text" className='form-control'
//                         name="quote_to_name"
//                         defaultValue={data1.quote_to_name || ''}
//                         onChange={handleChange}
//                       />
//                     </td>
//                     <td>
//                       <span>Additional To Name</span>
//                       <br />
//                       <input
//                         type="text" className='form-control'
//                         name="additional_to_name"
//                         defaultValue={data1.additional_to_name || ''}
//                         onChange={handleChange}
//                       />
//                     </td>

//                   </tr>
//                   <tr>

//                     <td>
//                       <span>quote to street</span>
//                       <br />
//                       <input
//                         type="text" className='form-control'
//                         name="quote_to_street"
//                         defaultValue={data1.quote_to_street || ''}
//                         onChange={handleChange}
//                       />
//                     </td>
//                     <td>
//                       <span>additional to street</span>
//                       <br />
//                       {/* {data1.additional_to} */}
//                       <input
//                         type="text" className='form-control'
//                         name="additional_to_street"
//                         defaultValue={data1.additional_to_street || ''}
//                         onChange={handleChange}
//                       />
//                     </td>
//                   </tr>
//                   <tr>

//                     <td>
//                       <span>Quote To Zip/Postal Code	</span>
//                       <br />
//                       {/* {data1.phone} */}
//                       <input
//                         type="text" className='form-control'
//                         name="quote_to_zip_postal_code"
//                         defaultValue={data1.quote_to_zip_postal_code || ''}
//                         onChange={handleChange}
//                       />
//                     </td>
//                     <td>
//                       <span>Additional To Zip/Postal Code	</span>
//                       <br />
//                       {/* {data1.phone_fax} */}
//                       <input
//                         type="text" className='form-control'
//                         name="additional_to_zip_postal_code"
//                         defaultValue={data1.additional_to_zip_postal_code || ''}
//                         onChange={handleChange}
//                       />
//                     </td>
//                   </tr>
//                   <tr>

//                     <td>
//                       <span>quote to city	</span>
//                       <br />
//                       {/* {data1.phone_fax} */}
//                       <input
//                         type="text" className='form-control'
//                         name="quote_to_city"
//                         defaultValue={data1.quote_to_city || ''}
//                         onChange={handleChange}
//                       />
//                     </td>
//                     <td>
//                       <span>Additional To City		</span>
//                       <br />
//                       {/* {data1.phone_fax} */}
//                       <input
//                         type="text" className='form-control'
//                         name="additional_to_city"
//                         defaultValue={data1.additional_to_city || ''}
//                         onChange={handleChange}
//                       />
//                     </td>
//                   </tr>
//                   <tr>

//                     <td>
//                       <span>Quote To State/Province	</span>
//                       <br />
//                       {/* {data1.phone_fax} */}
//                       <input
//                         type="text" className='form-control'
//                         name="quote_to_state_province"
//                         defaultValue={data1.quote_to_state_province || ''}
//                         onChange={handleChange}
//                       />
//                     </td>

//                     <td>
//                       <span>Additional To State/Province		</span>
//                       <br />
//                       {/* {data1.phone_fax} */}
//                       <input
//                         type="text" className='form-control'
//                         name="additional_to_state_province"
//                         defaultValue={data1.additional_to_state_province || ''}
//                         onChange={handleChange}
//                       />
//                     </td>
//                   </tr>
//                   <tr>


//                     <td>
//                       <span>Quote To Country	</span>
//                       <br />
//                       {/* {data1.phone_fax} */}
//                       <input
//                         type="text" className='form-control'
//                         name="quote_to_country"
//                         defaultValue={data1.quote_to_country || ''}
//                         onChange={handleChange}
//                       />
//                     </td>
//                     <td>
//                       <span>Additional To Country	</span>
//                       <br />
//                       {/* {data1.phone_fax} */}
//                       <input
//                         type="text" className='form-control'
//                         name="additional_to_country"
//                         defaultValue={data1.additional_to_country || ''}
//                         onChange={handleChange}
//                       />
//                     </td>
//                   </tr>
//                   <tr>



//                   </tr>
//                   <tr>
//                     <td>
//                       <span>Email</span>
//                       <br />
//                       {/* {data1.phone_fax} */}
//                       <input
//                         type="text" className='form-control'
//                         name="email"
//                         defaultValue={data1.email || ''}
//                         onChange={handleChange}
//                       />
//                     </td>
//                     <td>

//                     </td>

//                   </tr>
//                   <tr>
//                     <td>
//                       <span>Phone</span>
//                       <br />
//                       {/* {data1.phone_fax} */}
//                       <input
//                         type="text" className='form-control'
//                         name="phone"
//                         defaultValue={data1.phone || ''}
//                         onChange={handleChange}
//                       />
//                     </td>
//                     <td>

//                     </td>

//                   </tr>
//                   <tr>
//                     <td>
//                       <span>Fax</span>
//                       <br />
//                       {/* {data1.phone_fax} */}
//                       <input
//                         type="text" className='form-control'
//                         name="fax"
//                         defaultValue={data1.fax || ''}
//                         onChange={handleChange}
//                       />
//                     </td>
//                     <td>

//                     </td>

//                   </tr>
//                 </tbody>
//               </table>

//               <div className="col-md-12" id='ax.account_details'>
//                 <h4 className="heading">Address Information</h4>
//                 <hr></hr>
//               </div>
//               <table class="table table-bordered account-table tables">
//                 <tbody>
//                   <tr>
//                     <td>
//                       <span>Bill To Name</span>
//                       <br />
//                       {/* {data1.bill_to_name} */}
//                       <input
//                         type="text" className='form-control'
//                         name="bill_to_name"
//                         defaultValue={data1.bill_to_name || ''}
//                         onChange={handleChange}
//                       />
//                     </td>
//                     <td>
//                       <span>Ship To Name</span>
//                       <br />
//                       {/* {data1.ship_to_name} */}
//                       <input
//                         type="text" className='form-control'
//                         name="ship_to_name"
//                         defaultValue={data1.ship_to_name || ''}
//                         onChange={handleChange}
//                       />
//                     </td>

//                   </tr>
//                   <tr>
//                     <td>
//                       <span>bill to street</span>
//                       <br />
//                       {/* {data1.bill_to} */}
//                       <input
//                         type="text" className='form-control'
//                         name="bill_to_street"
//                         defaultValue={data1.bill_to_street || ''}
//                         onChange={handleChange}
//                       />
//                     </td>
//                     <td>
//                       <span>Ship To Street</span>
//                       <br />
//                       <input
//                         type="text" className='form-control'
//                         name="ship_to_street"
//                         defaultValue={data1.ship_to_street || ''}
//                         onChange={handleChange}
//                       />
//                     </td>

//                   </tr>
//                   <tr>
//                     <td>
//                       <span>Bill To Zip/Postal Code	</span>
//                       <br />
//                       {/* {data1.bill_to} */}
//                       <input
//                         type="text" className='form-control'
//                         name="bill_to_zip_postal_code"
//                         defaultValue={data1.bill_to_zip_postal_code || ''}
//                         onChange={handleChange}
//                       />
//                     </td>
//                     <td>
//                       <span>Ship To Zip/Postal Code	</span>
//                       <br />
//                       <input
//                         type="text" className='form-control'
//                         name="ship_to_zip_postal_code"
//                         defaultValue={data1.ship_to_zip_postal_code || ''}
//                         onChange={handleChange}
//                       />
//                     </td>

//                   </tr>
//                   <tr>
//                     <td>
//                       <span>Bill To City	</span>
//                       <br />
//                       {/* {data1.bill_to} */}
//                       <input
//                         type="text" className='form-control'
//                         name="bill_to_city"
//                         defaultValue={data1.bill_to_city || ''}
//                         onChange={handleChange}
//                       />
//                     </td>
//                     <td>
//                       <span>Ship To City	</span>
//                       <br />
//                       <input
//                         type="text" className='form-control'
//                         name="ship_to_city"
//                         defaultValue={data1.ship_to_city || ''}
//                         onChange={handleChange}
//                       />
//                     </td>

//                   </tr>
//                   <tr>
//                     <td>
//                       <span>Bill To State/Province	</span>
//                       <br />
//                       {/* {data1.bill_to} */}
//                       <input
//                         type="text" className='form-control'
//                         name="bill_to_state_province"
//                         defaultValue={data1.bill_to_state_province || ''}
//                         onChange={handleChange}
//                       />
//                     </td>
//                     <td>
//                       <span>Ship To State/Province	</span>
//                       <br />
//                       <input
//                         type="text" className='form-control'
//                         name="ship_to_state_province"
//                         defaultValue={data1.ship_to_state_province || ''}
//                         onChange={handleChange}
//                       />
//                     </td>

//                   </tr>

//                   <tr>
//                     <td>
//                       <span>Bill To Country	</span>
//                       <br />
//                       {/* {data1.bill_to} */}
//                       <input
//                         type="text" className='form-control'
//                         name="bill_to_country"
//                         defaultValue={data1.bill_to_country || ''}
//                         onChange={handleChange}
//                       />
//                     </td>
//                     <td>
//                       <span>Ship To Country	</span>
//                       <br />
//                       <input
//                         type="text" className='form-control'
//                         name="ship_to_country"
//                         defaultValue={data1.ship_to_country || ''}
//                         onChange={handleChange}
//                       />
//                     </td>

//                   </tr>
//                 </tbody>
//               </table>
//               {/* <div className="col-md-12" id='ax.account_details'>
//    <h4 className="heading">System Information</h4>
//    <hr></hr>
//    </div>
//         <table class="table table-bordered account-table tables">
//          <tbody>
//           <tr>
//           <td>
//                 <span>Created By</span>
//                 <br />
//                 <input
//               type="text" className='form-control'
//               name="created_by"
//               defaultValue={data1.created_by || ''}
//               onChange={handleChange}
//             />
//               </td>
//               <td>
//                 <span>Modified By</span>
//                 <br />
//                 <input
//               type="text" className='form-control'
//               name="modified_by"
//               defaultValue={data1.modified_by || ''}
//               onChange={handleChange}
//             />
//               </td>
//           </tr>

//           </tbody>
//         </table> */}
//               <p className='edit-btn'>
//                 <input
//                   type="submit"
//                   className="account-save"
//                   onClick={handleSaveEdit}
//                   //value={isSubmitted ? "Saving" : "Save"}
//                  value="Save"
//                 />
//                 <button className="btn btn-primary" onClick={handleCancelEdit}>Cancel</button>
//               </p>

//             </>

//           ) : (
//             <>
//               <div className="edit-delete">
//                 <span id="edit" onClick={handleEditClick}>
//                   Edit
//                 </span>
//                 <span>
//                   <Link to={`/opportunity/opportunity-detail/${data1.opportunity_id}`}> Cancel</Link>
//                 </span>
//                 {data1.status === 'Accepted' ? (
//                   <span onClick={createContract}>Create Contract</span>
//                 ) : (
//                   <></>
//                 )}
//               </div>

//               <div className="col-md-12" id='ax.account_details'>
//                 <h4 className="heading">Quote Detail</h4>
//                 <hr></hr>
//               </div>

//               <table class="table table-bordered account-table tables">
//                 <tbody>
//                   <tr>
//                     <td id="td-right">
//                       <span>Quote Number</span>
//                     </td><td>
//                       {data1.quote_number}
//                     </td>
//                     <td id="td-right">
//                       <span>Quote Record Type</span>
//                     </td>
//                     <td>
//                       {data1.quote_record_type}
//                     </td>

//                   </tr>
//                   <tr>
//                     <td id="td-right">
//                       <span>Currency</span>
//                     </td><td>
//                       {data1.currency}
//                     </td>
//                     <td id="td-right">
//                       <span>Locked</span>
//                     </td><td>
//                       <input
//                         type='checkbox'
//                         name='locked'
//                         checked={lockedOne} // Use the state variable to determine if the checkbox is checked
//                         onChange={handleCheckboxLocked} // Handle the change event
//                         className='mx-3' disabled={isButtonEnabled}
//                       />
//                     </td>
//                   </tr>
//                   <tr>
//                     <td id="td-right">
//                       <span>Exchange Rate</span>
//                     </td><td>
//                       {data1.exchange_rate}
//                     </td>
//                     <td id="td-right">
//                       <span>Opportunity Name</span>
//                     </td><td>
//                       <Link to={`/opportunity/opportunity-detail/${data1.opportunity_id}`}>{data1.opportunity_name}</Link>
//                     </td>
//                   </tr><tr>
//                     <td id="td-right">
//                       <span>Quote Name</span>
//                     </td><td>
//                       {data1.quote_name}
//                     </td>
//                     <td id="td-right">
//                       <span>Status</span>
//                     </td><td>
//                       {data1.status || 'Offered'}
//                     </td>
//                   </tr>
//                   <tr>

//                   </tr>
//                   <tr>
//                     <td id="td-right">
//                       <span>Account Name</span>
//                     </td><td>
//                       <Link to={`/accounts/${data1.account_id}`}>{data1.account_name}</Link>
//                     </td>
//                     <td id="td-right">
//                       <span>Supplier</span>
//                     </td><td>
//                       <Link to={`/accounts/${data1.supplier_id}`}>{data1.supplier}</Link>
//                     </td>
//                   </tr>
//                   <tr>
//                     <td id="td-right">
//                       <span>Contact Name</span>
//                     </td><td>
//                       {data1.contact_name}
//                     </td>

//                     <td id="td-right">
//                       <span>Quote Date</span>
//                     </td><td>
//                       {data1.quote_date}
//                     </td>

//                   </tr>
//                   <tr>
//                     <td id="td-right">
//                       <span>Expiration Date</span>
//                     </td><td>
//                       {data1.expiration_date}
//                     </td>
//                     <td id="td-right">
//                       <span>Trader Name</span>
//                     </td><td>
//                       <Link to={'/user/user-details/' + data1.trader_id}> {data1.trader_name}</Link>
//                     </td>


//                   </tr>
//                   <tr>
//                     <td id="td-right">
//                       <span>No of Quote shipment</span>
//                     </td><td>
//                       {data1.no_of_quote_shipment}
//                     </td>

//                     <td></td>
//                   </tr>
//                 </tbody>
//               </table>
//               <div className="col-md-12" id='ax.account_details'>
//                 <h4 className="heading">Product information</h4>
//                 <hr></hr>
//               </div>
//               <table class="table table-bordered account-table tables">
//                 <tbody>
//                   <tr>

//                     <td id="td-right">
//                       <span>Quantity MT
//                       </span>
//                     </td><td>
//                       {data1.quantity_MT}
//                     </td>
//                     <td id="td-right">
//                       <span>Contract</span>
//                     </td><td>

//                       <Link to={'/contract/contract-details/' + data1.contractId}> {data1.contract}</Link>
//                     </td>

//                   </tr>
//                   <tr>

//                     <td id="td-right">
//                       <span>Product Description	</span>
//                     </td><td>
//                       {data1.product_description}
//                     </td>
//                     <td id="td-right">
//                       <span>Total Price</span>
//                     </td><td>
//                       {data1.total_price}
//                     </td>

//                   </tr>

//                 </tbody>
//               </table>


//               <div className="col-md-12" id='ax.account_details'>
//                 <h4 className="heading">Market Information</h4>
//                 <hr></hr>
//               </div>
//               <table class="table table-bordered account-table tables">
//                 <tbody>
//                   <tr>
//                     <td id="td-right">
//                       <span>origin</span>
//                     </td><td>
//                       {data1.origin}
//                     </td>

//                     <td id="td-right">
//                       <span>Commodity</span>
//                     </td><td>
//                       {data1.commodity}
//                     </td>

//                   </tr>
//                   <tr>
//                     <td id="td-right">
//                       <span>Destination</span>
//                     </td><td>
//                       {data1.destination}
//                     </td>

//                     <td id="td-right">
//                       {/* <span>Commodity</span> */}
//                     </td><td>
//                       {/* {data1.commodity} */}
//                     </td>

//                   </tr>

//                 </tbody>
//               </table>
//               <div className="col-md-12" id='ax.account_details'>
//                 <h4 className="heading">Price and Price Adjustment</h4>
//                 <hr></hr>
//               </div>
//               <table class="table table-bordered account-table tables">
//                 <tbody>
//                   <tr>
//                     <td id="td-right">
//                       <span>Bonus/Penalty Clause</span>
//                     </td><td>
//                       {data1.bonus_penalty_clause}
//                     </td>
//                     <td id="td-right">
//                       <span>Rejections</span>
//                     </td><td>
//                       {data1.rejections}
//                     </td>

//                   </tr>
//                   <tr>

//                     <td id="td-right">
//                       <span>Payment Term</span>
//                     </td><td>
//                       {data1.payment_terms}
//                     </td>
//                     <td id="td-right">
//                       <span>General Terms</span>
//                     </td><td>
//                       {data1.general_terms}
//                     </td>

//                   </tr>
//                 </tbody>
//               </table>
//               <div className="col-md-12" id='ax.account_details'>
//                 <h4 className="heading">Terms & Conditions</h4>
//                 <hr></hr>
//               </div>
//               <table class="table table-bordered account-table tables">
//                 <tbody>
//                   <tr>
//                     <td id="td-right">
//                       <span>Arbitration</span>
//                     </td><td>
//                       {data1.arbitration}
//                     </td>
//                     <td id="td-right">
//                       <span>Rwood Surveyor Agent</span>
//                     </td><td>
//                       {data1.rwood_surveyor_agent}
//                     </td>

//                   </tr>
//                   <tr>
//                     <td id="td-right">
//                       <span>Applicable Law</span>
//                     </td><td>
//                       {data1.applicable_law}
//                     </td>
//                     <td id="td-right">
//                       <span>Surveyor 2	</span>
//                     </td><td>
//                       {data1.surveyor_2}
//                     </td>
//                   </tr>
//                   <tr>
//                     <td id="td-right">
//                       <span>Offer Validity Terms</span>
//                     </td><td>
//                       {data1.offer_validity_terms}
//                     </td>
//                     <td id="td-right">
//                       <span>Surveyor 3</span>
//                     </td><td>
//                       {data1.suveyor_3}
//                     </td>
//                   </tr>
//                   <tr>
//                     <td id="td-right">
//                       <span>Description</span>
//                     </td><td>
//                       {data1.description}
//                     </td>
//                     <td id="td-right">
//                       {/* <span>Surveyor 3</span> */}
//                     </td><td>
//                       {/* {data1.suveyor_3} */}
//                     </td>
//                   </tr>
//                 </tbody>
//               </table>

//               <div className="col-md-12" id='ax.account_details'>
//                 <h4 className="heading">Prepared For</h4>
//                 <hr></hr>
//               </div>
//               <table class="table table-bordered account-table tables">
//                 <tbody>

//                   <tr>

//                     <td id="td-right">
//                       <span>quote to name</span>
//                     </td><td>
//                       {data1.quote_to_name}
//                     </td>
//                     <td id="td-right">
//                       <span>additional to name</span>
//                     </td><td>
//                       {data1.additional_to_name}
//                     </td>

//                   </tr>
//                   <tr>

//                     <td id="td-right">
//                       <span>quote to street</span>
//                     </td><td>
//                       {data1.quote_to_street}
//                     </td>
//                     <td id="td-right">
//                       <span>additional to street</span>
//                     </td><td>
//                       {data1.additional_to_street}
//                     </td>

//                   </tr>
//                   <tr>

//                     <td id="td-right">
//                       <span>quote to zip postal code</span>
//                     </td><td>
//                       {data1.quote_to_zip_postal_code}
//                     </td>
//                     <td id="td-right">
//                       <span>additional to zip postal code</span>
//                     </td><td>
//                       {data1.additional_to_zip_postal_code}
//                     </td>

//                   </tr>
//                   <tr>

//                     <td id="td-right">
//                       <span>quote to city</span>
//                     </td><td>
//                       {data1.quote_to_city}
//                     </td>
//                     <td id="td-right">
//                       <span>additional to city</span>
//                     </td><td>
//                       {data1.additional_to_city}
//                     </td>

//                   </tr>
//                   <tr>

//                     <td id="td-right">
//                       <span>quote to state province</span>
//                     </td><td>
//                       {data1.quote_to_state_province}
//                     </td>
//                     <td id="td-right">
//                       <span>additional to state province</span>
//                     </td><td>
//                       {data1.additional_to_state_province}
//                     </td>

//                   </tr>
//                   <tr>

//                     <td id="td-right">
//                       <span>quote to country</span>
//                     </td><td>
//                       {data1.quote_to_country}
//                     </td>
//                     <td id="td-right">
//                       <span>additional to country</span>
//                     </td><td>
//                       {data1.additional_to_country}
//                     </td>

//                   </tr>
//                   <tr>

//                     <td id="td-right">
//                       <span>email</span>
//                     </td><td>
//                       {data1.email}
//                     </td>
//                     <td id="td-right">
//                       {/* <span>Additionasdsdame</span> */}
//                     </td><td>
//                       {/* {data1.additional_to_name} */}
//                     </td>

//                   </tr>
//                   <tr>

//                     <td id="td-right">
//                       <span>Phone</span>
//                     </td><td>
//                       {data1.phone}
//                     </td>
//                     <td id="td-right">
//                       {/* <span>Additionasdsdame</span> */}
//                     </td><td>
//                       {/* {data1.additional_to_name} */}
//                     </td>

//                   </tr>
//                   <tr>

//                     <td id="td-right">
//                       <span>Fax</span>
//                     </td><td>
//                       {data1.phone_fax}
//                     </td>
//                     <td id="td-right">
//                       {/* <span>Additionasdsdame</span> */}
//                     </td><td>
//                       {/* {data1.additional_to_name} */}
//                     </td>

//                   </tr>
//                 </tbody>
//               </table>

//               <div className="col-md-12" id='ax.account_details'>
//                 <h4 className="heading">Address Information</h4>
//                 <hr></hr>
//               </div>
//               <table class="table table-bordered account-table tables">
//                 <tbody>
//                   <tr>
//                     <td id="td-right">
//                       <span>bill to name</span>
//                     </td><td>
//                       {data1.bill_to_name}
//                     </td>
//                     <td id="td-right">
//                       <span>ship to name</span>
//                     </td><td>
//                       {data1.ship_to_name}
//                     </td>

//                   </tr>
//                   <tr>
//                     <td id="td-right">
//                       <span>bill to street</span>
//                     </td><td>
//                       {data1.bill_to_street}
//                     </td>
//                     <td id="td-right">
//                       <span>ship to street</span>
//                     </td><td>
//                       {data1.ship_to_street}
//                     </td>

//                   </tr>
//                   <tr>
//                     <td id="td-right">
//                       <span>bill to zip postal code</span>
//                     </td><td>
//                       {data1.bill_to_zip_postal_code}
//                     </td>
//                     <td id="td-right">
//                       <span>ship to zip_postal code</span>
//                     </td><td>
//                       {data1.ship_to_zip_postal_code}
//                     </td>

//                   </tr>
//                   <tr>
//                     <td id="td-right">
//                       <span>bill to city</span>
//                     </td><td>
//                       {data1.bill_to_city}
//                     </td>
//                     <td id="td-right">
//                       <span>ship to city</span>
//                     </td><td>
//                       {data1.ship_to_city}
//                     </td>

//                   </tr>
//                   <tr>
//                     <td id="td-right">
//                       <span>bill to state province</span>
//                     </td><td>
//                       {data1.bill_to_state_province}
//                     </td>
//                     <td id="td-right">
//                       <span>ship to state province</span>
//                     </td><td>
//                       {data1.ship_to_state_province}
//                     </td>

//                   </tr>
//                   <tr>
//                     <td id="td-right">
//                       <span>bill to country</span>
//                     </td><td>
//                       {data1.bill_to_country}
//                     </td>
//                     <td id="td-right">
//                       <span>ship to country</span>
//                     </td><td>
//                       {data1.ship_to_country}
//                     </td>

//                   </tr>
//                 </tbody>
//               </table>
//               {/* <div className="col-md-12" id='ax.account_details'>
//    <h4 className="heading">System Information</h4>
//    <hr></hr>
//    </div>
//         <table class="table table-bordered account-table tables">
//          <tbody>
//           <tr>
//           <td id="td-right">
//                 <span>Created By</span>
//                 </td><td>
//                 {data1.created_by}
//               </td>
//               <td id="td-right">
//                 <span>Modified By</span>
//                 </td><td>
//                 {data1.modified_by}
//               </td>

//           </tr>
//            </tbody>
//         </table> */}

// <Row id="table-styles">
//                     <div className="col-md-12" id='head'>
//                       <h4 className="heading"><span>Quote Shipment</span>
//                         {/* <span><Popup trigger={<span className="button add-accountrecord"> <FaPlus/> New </span>} modal>

//     <form onSubmit={handleSubmittcf} className="add-accounts">
//      <div className='form-group'>
//      <label>TCF Record Type</label><br/>
//      <select name='account_record' className='form-control' onChange={handleChangetcf} value={formData.account_record}>
//        <option >--Select TCF Record Type--</option>
//        <option value='Buyer - Spot'>Buyer - Spot</option>
//        <option value='Buyer - Long Term'>Buyer - Long Term</option>
//        </select>
//        </div>
//        <input type='submit' className="btn btn-primary " value='Submit'/>

//        </form>
// </Popup></span> */}
//                       </h4>
//                       <div id='inner-heading'>

//                       </div>
//                       <hr></hr>
//                     </div>
//                     <table id='quote' className="">
//                       <thead>
//                         <tr>
//                           <th>Quote Shipment Code</th>
//                           <th>Quantity</th>
//                           <th>Laycan</th>
//                           <th>Pricing</th>
//                           <th>Quanity Info	</th>
//                           <th>Vessel Type	</th>
//                           <th>Shipping Term
//                           </th>
//                         </tr>
//                       </thead>

//                       {quote.length > 0 ?

//                         <tbody>
//                           {
//                             quote.map((x, i) => (
//                               <tr>
//                                 <td><Link to={'/quote-shipment/detail/' + x.id}>{x.quote_shipment_code}</Link></td>
//                                 <td>{x.quote_shipment_quantity}</td>
//                                 <td>{x.quote_shipment_laycan}</td>
//                                 <td>{x.quote_shipment_pricing}</td>
//                                 <td>{x.quote_shipment_quantity_info
//                                 }</td>

//                                 <td>{x.quote_shipment_vessel_type
//                                 }</td>
//                                 <td>{x.quote_shipment_shipping_term
//                                 }</td>
//                               </tr>
//                             ))}
//                         </tbody>
//                         : <tbody>
//                           <tr>
//                             <td></td>
//                             <td></td>
//                             <td></td>
//                             <td>No data available</td>
//                             <td></td>
//                             <td></td><td></td>

//                           </tr>
//                         </tbody>
//                       }
//                     </table>

//                   </Row>
//                       <Row id="table-styles">
//                     <div className="col-md-12" id='head'>
//                       <h4 className="heading"><span>Quote Product</span>
//                         <span>
//                           <Link to={"/quote-product/select/" + id}>
//                             <i className="fe fe-download-cloud me-2"></i>
//                               New
//                           </Link>
//                         </span>
//                         {/* <span><Popup trigger={<span className="button add-accountrecord"> <FaPlus/> New </span>} modal>

//     <form onSubmit={handleSubmittcf} className="add-accounts">
//      <div className='form-group'>
//      <label>TCF Record Type</label><br/>
//      <select name='account_record' className='form-control' onChange={handleChangetcf} value={formData.account_record}>
//        <option >--Select TCF Record Type--</option>
//        <option value='Buyer - Spot'>Buyer - Spot</option>
//        <option value='Buyer - Long Term'>Buyer - Long Term</option>
//        </select>
//        </div>
//        <input type='submit' className="btn btn-primary " value='Submit'/>

//        </form>
// </Popup></span> */}
//                       </h4>
//                       <div id='inner-heading'>

//                       </div>
//                       <hr></hr>
//                     </div>
//                     <table id='quote-product' className="">
//                       <thead>
//                         <tr>
//                           <th>Quote Product Name	</th>
//                           <th>Quote Shipment	</th>
//                           <th>Gross As Received Typical	</th>
//                           <th>Ash Content Typical	</th>
//                           <th>Total Moisture Typical	</th>
//                           <th>Total Sulphur Typical ARB
//                           </th>
//                         </tr>
//                       </thead>

//                       {quoteProduct.length > 0 ?

//                         <tbody>
//                           {
//                             quoteProduct.map((y, i) => (
//                               <tr>

//                                 <td><span id='codes'><Link to={'/opportunity/quote-detail/' + y.id}>{y.quote_product_code}</Link></span></td>
//                                 <td>{y.quote_shipment}</td>
//                                 <td>{y.gross_as_received_typical	}</td>
//                                 <td>{y.ash_typical	}</td>
//                                 <td>{y.total_moisture_typical	}</td>
//                                 <td>{y.sulphur_typical_arb
// 	}</td>
//                               </tr>
//                             ))}
//                         </tbody>
//                         : <tbody>
//                           <tr>
//                             <td></td>
//                             <td></td>
//                             <td>No data available</td>
//                             <td></td>
//                             <td></td>
//                             <td></td>

//                           </tr>
//                         </tbody>
//                       }
//                     </table>

//                   </Row>
//                   <Row id="table-styles">
//      <div className="col-md-12" id="head">
//        <h4 className="heading">
//          <span>Quote Clauses
//          </span>
//          <span>
//            <i className=""></i> New Quote Clauses
//            </span>

//        </h4>
//        <hr>
//        </hr>
//      </div>
//      <table id="history" className="">
//        <tr>
//          <td>
//            <p class="no-records"> No records to display </p>
//          </td>
//        </tr>
//      </table>
//    </Row>
//    <Row id="table-styles">
//                     <div className="col-md-12" id='head'>
//                       <h4 className="heading"><span>Trade Confirmation Form</span>

//                       </h4>
//                       <div id='inner-heading'>

//                       </div>
//                       <hr></hr>
//                     </div>
//                     <table id='tcf' className="">
//                       <thead>
//                         <tr>
//                           <th>TCF No</th>
//                           <th>tcf status</th>
//                           <th>Buyer</th>
//                           <th>supplier</th>
//                           <th>Quantity (MT)	</th>
//                           <th>Contractual GAR (P)	</th>
//                           <th>Actual GAR (P)</th>
//                           <th>Actual GAR (S)</th>
//                         </tr>
//                       </thead>

//                       {tcf.length > 0 ?

//                         <tbody>
//                           {
//                             tcf.map((x, i) => (
//                               <tr>
//                                 <td><Link to={"/tcf/tcf-details/" + x.id}>{x.tcf_no}</Link></td>
//                                 <td>{x.tcf_status}</td>
//                                 <td><Link to={"/accounts/" + x.account_id}>{x.buyer}</Link></td><td><Link to={"/accounts/" + x.supplier_id}>{x.supplier}</Link></td>
//                                 <td>{x.quantity_MT}</td>
//                                 <td>{x.contractual_GAR_P}</td>
//                                 <td>{x.actual_gar_p}</td>
//                                 <td>{x.actual_gar_s}</td>
//                               </tr>
//                             ))}
//                         </tbody>
//                         : <tbody>
//                           <tr>
//                             <td></td>
//                             <td></td><td></td>
//                             <td></td>
//                             <td>No data available</td>
//                             <td></td>
//                             <td></td>
//                             <td></td>
//                           </tr>
//                         </tbody>
//                       }
//                     </table>

//                   </Row>

//    <Row id="table-styles">
//      <div className="col-md-12" id="head">
//        <h4 className="heading">
//          <span>Quote PDFs
//          </span>

//        </h4>
//        <hr>
//        </hr>
//      </div>
//      <table id="history" className="">
//        <tr>
//          <td>
//            <p class="no-records"> No records to display </p>
//          </td>
//        </tr>
//      </table>
//    </Row>
//    <Row id="table-styles">
//      <div className="col-md-12" id="head">
//        <h4 className="heading">
//          <span>Notes
//          </span>
//          <span>New Note
//          </span>
//        </h4>
//        <hr>
//        </hr>
//      </div>
//      <table id="history" className="">
//        <tr>
//          <td>
//            <p class="no-records"> No records to display </p>
//          </td>
//        </tr>
//      </table>
//    </Row>
//    <Row id="table-styles">
//      <div className="col-md-12" id="head">
//        <h4 className="heading">
//          <span>Files
//          </span>
//          <span>Upload Files         </span>
//        </h4>
//        <hr>
//        </hr>
//      </div>
//      <table id="history" className="">
//        <tr>
//          <td>
//            <p class="no-records"> No records to display </p>
//          </td>
//        </tr>
//      </table>
//    </Row>

//    <Row id="table-styles">
//      <div className="col-md-12" id="head">
//        <h4 className="heading">
//          <span>Open Activities

//          </span>
//          <span>New Task       </span>
//          <span>New Event       </span>
//        </h4>
//        <hr>
//        </hr>
//      </div>
//      <table id="history" className="">
//        <tr>
//          <td>
//            <p class="no-records"> No records to display </p>
//          </td>
//        </tr>
//      </table>
//    </Row>
//    <Row id="table-styles">
//      <div className="col-md-12" id="head">
//        <h4 className="heading">
//          <span>Notes & Attachments</span>
//          <span>
//             New Note </span>
//          <span>
//            Attach File </span>
//        </h4>
//        <hr>
//        </hr>
//      </div>
//      <table id="history" className="">
//        <tr>
//          <td>
//            <p class="no-records"> No records to display </p>
//          </td>
//        </tr>
//      </table>
//    </Row>
//    <Row id="table-styles">
//      <div className="col-md-12" id="head">
//        <h4 className="heading">
//          <span>Activity History
//          </span>
//          <span>
//             Log a Call </span>
//          <span>
//            Mail Merge </span>
//        </h4>
//        <hr>
//        </hr>
//      </div>
//      <table id="history" className="">
//        <tr>
//          <td>
//            <p class="no-records"> No records to display </p>
//          </td>
//        </tr>
//      </table>
//    </Row>

//    <Row id="table-styles">
//      <div className="col-md-12" id="head">
//        <h4 className="heading">
//          <span>Approval History

//          </span>
//          <span>
//             Submit for Approval</span>

//        </h4>
//        <hr>
//        </hr>
//      </div>
//      <table id="history" className="">
//        <tr>
//          <td>
//            <p class="no-records"> No records to display </p>
//          </td>
//        </tr>
//      </table>
//    </Row>
//             </>
//           )}</div>
//       ) : (
//         <>
//           {data1.quote_record_type === 'Buyer - Spot' ? (
//             <div className="tasks edit-view">
//                <div id="messageContainer"></div>

//               {isEditMode ? (
//                 <>
//                   <p className='edit-btn'>
//                     <input
//                       type="submit"
//                       className="account-save"
//                       onClick={handleSaveEdit}
//                       //value={isSubmitted ? "Saving" : "Save"}
//                      value="Save"
//                     />
//                     <button className="btn btn-primary" onClick={handleCancelEdit}>Cancel</button>
//                   </p>
//                   <table class="table table-bordered account-table tables">
//                     <tbody>

//                       <tr>
//                         <td>
//                           <span>Quote Name</span>
//                           <br />
//                           <input
//                             type="text" className='form-control'
//                             name="quote_name"
//                             defaultValue={data1.quote_name || ''}
//                             onChange={handleChange}
//                           />
//                         </td>
//                         <td>
//                           <span>Quote Number</span>
//                           <br />
//                           <input
//                             type="text" className='form-control'
//                             name="quote_number"
//                             disabled
//                             defaultValue={data1.quote_number || ''}
//                             onChange={handleChange}
//                           />
//                         </td>
//                       </tr>
//                       <tr>
//                         <td>
//                           <span>Currency</span>
//                           <br />

//                           <select name="currency"
//                             defaultValue={data1.currency || ''}
//                             onChange={handleChange} className="form-control">
//                             <option defaultValue='' >---None---</option>
//                             <option defaultValue='USD' >USD</option>
//                             <option defaultValue='IDR' >IDR</option>
//                           </select>
//                         </td>
//                         <td>
//                           <span>Quote Record Type</span>
//                           <br />
//                           <input
//                             type="text" className='form-control'
//                             name="quote_record_type"
//                             disabled
//                             defaultValue={data1.quote_record_type || ''}
//                             onChange={handleChange}
//                           />
//                         </td>

//                       </tr>
//                       <tr>
//                         <td>
//                           <span>Exchange Rate</span>
//                           <br />
//                           <input
//                             type="text" className='form-control '
//                             name="exchange_rate"

//                             defaultValue={data1.exchange_rate || ''}
//                             onChange={handleChange}
//                           />
//                         </td>
//                         <td>

//                         </td>


//                       </tr>
//                       <tr>

//                         <td>
//                           <span>Opportunity Name</span>
//                           <br />

//                           <select className='form-control'
//                             name="opportunity_name"
//                             defaultValue={data1.opportunity_name || ''}>
//                             <option value='' >Select</option>
//                             {
//                               opp.map((x) => {
//                                 return (
//                                   <option value={x.opportunity_name}>{x.opportunity_name}</option>
//                                 )
//                               })
//                             }
//                           </select>
//                         </td>
//                         <td>
//                           <span>Status</span>
//                           <br />
//                           <select
//                             name="status"
//                             className='form-control'
//                             defaultValue={data1.status || ''}
//                             onChange={handleChange}
//                           >
//                             <option value='Offered'>Offered</option>
//                             <option value='Accepted'>Accepted</option>
//                             <option value='Rejected'>Rejected</option>
//                             <option value='Sold'>Sold</option>
//                           </select>
//                         </td>


//                       </tr>
//                       <tr>
//                         <td>
//                           <span>Account Name</span>
//                           <br />
//                           <input
//                             type="text" className='form-control record-type'
//                             name="account_name"
//                             disabled
//                             defaultValue={data1.account_name || ''}
//                             onChange={handleChange}
//                           />
//                         </td>
//                         <td>

//                         </td>

//                       </tr>
//                       <tr>
//                         <td>
//                           <span>Supplier</span>
//                           <br />
//                           <input
//                             type="text" className='form-control'
//                             name="supplier"
//                             defaultValue={data1.supplier || ''}
//                             onChange={handleChange}
//                           />
//                         </td>
//                         <td></td>
//                       </tr>
//                       <tr>
//                         <td>
//                           <span>Trader Name</span>
//                           <br />


//                           <select className='form-control'
//                             name="trader_name"
//                             defaultValue={data1.trader_name || ''} onChange={handleChange}>
//                             <option value='' >Select</option>
//                             {
//                               traderData.map((x, i) => {
//                                 return (
//                                   <option key={i} value={x.rwood_username}>
//                                     {x.rwood_username}
//                                   </option>
//                                 )
//                               })
//                             }
//                           </select>
//                         </td>
//                         <td></td>
//                       </tr>
//                       <tr>
//                         <td>
//                           <span>quote to name</span>
//                           <br />
//                           <input
//                             type="text" className='form-control'
//                             name="quote_to_name"
//                             defaultValue={data1.quote_to_name || ''}
//                             onChange={handleChange}
//                           />
//                         </td>
//                         <td></td>
//                       </tr>
//                       <tr>
//                         <td>
//                           <span>Quote Date</span>
//                           <br />
//                           <DatePicker
//                             selected={start}
//                             onChange={(date) => setStart(date)}
//                             customInput={<ExampleCustomInput2 />}
//                             dayClassName={() => "example-datepicker-day-class"}
//                             popperClassName="edit-date"
//                             todayButton="TODAY"
//                             dateFormat="yyyy-MM-dd"
//                             placeholderText="Choose Date"
//                           />
//                         </td>
//                         <td></td>
//                       </tr>
//                       <tr>
//                         <td>
//                           <span>Expiration Date</span>
//                           <br />

//                           <DatePicker
//                             selected={effective}
//                             onChange={(date) => setEffective(date)}
//                             customInput={<ExampleCustomInput1 />}
//                             dayClassName={() => "example-datepicker-day-class"}
//                             popperClassName="edit-date"
//                             todayButton="TODAY"
//                             dateFormat="yyyy-MM-dd"
//                             placeholderText="Choose Date"
//                           />
//                         </td>
//                         <td></td>
//                       </tr>
//                     </tbody>
//                   </table>
//                   <div className="col-md-12" id='ax.account_details'>
//                     <h4 className="heading">Product information</h4>
//                     <hr></hr>
//                   </div>
//                   <table class="table table-bordered account-table tables">
//                     <tbody>
//                       <tr>
//                         <td>
//                           <span>Origin</span>
//                           <br />
//                           <input
//                             type="text" className='form-control'
//                             name="origin"
//                             defaultValue={data1.origin || ''}
//                             onChange={handleChange}
//                           />
//                         </td>
//                         <td>
//                           <span>Quantity MT</span>
//                           <br />
//                           <input
//                             type="text" className='form-control'
//                             name="quantity_MT"
//                             defaultValue={data1.quantity_MT || ''}
//                             onChange={handleChange}
//                           />
//                         </td>
//                       </tr>
//                       <tr>
//                         <td>
//                           <span>Commodity</span>
//                           <br />
//                           <select className='form-control'
//                             name="commodity"
//                             defaultValue={data1.commodity || ''}
//                             onChange={handleChange}>
//                             <option>---None---</option>
//                             <option defaultValue="Indonesian Steam Coal in Bulk (&quot;Coal&quot;)">Indonesian Steam Coal in Bulk ("Coal")</option><option defaultValue="US Coal">US Coal</option><option defaultValue="Russia Coal">Russia Coal</option><option defaultValue="SA Coal">SA Coal</option><option defaultValue="Clinker in Bulk">Clinker in Bulk</option><option defaultValue="Australian Steam Coal">Australian Steam Coal</option>
//                           </select>
//                         </td>
//                         <td>
//                           <span>Product Description</span>
//                           <br />
//                           <input
//                             type="text" className='form-control'
//                             name="product_description"
//                             defaultValue={data1.product_description || ''}
//                             onChange={handleChange}
//                           />
//                         </td>

//                       </tr>
//                       <tr>
//                         <td>
//                           <span>Contract</span>
//                           <br />
//                           <input
//                             type="text" className='form-control'
//                             name="contract"
//                             defaultValue={data1.contract || ''}
//                             onChange={handleChange}
//                           />
//                         </td>
//                         <td></td>
//                       </tr>
//                     </tbody>
//                   </table>
//                   <div className="col-md-12" id='ax.account_details'>
//                     <h4 className="heading">Market Information</h4>
//                     <hr></hr>
//                   </div>
//                   <table class="table table-bordered account-table tables">
//                     <tbody>

//                       <tr>
//                         <td>
//                           <span>Destination</span>
//                           <br />

//                           <input
//                             type="text" className='form-control'
//                             name="destination"
//                             defaultValue={data1.destination || ''}
//                             onChange={handleChange}
//                           />
//                         </td>
//                         <td></td>
//                       </tr>
//                     </tbody>
//                   </table>
//                   <div className="col-md-12" id='ax.account_details'>
//                     <h4 className="heading">Price and Price Adjustment</h4>
//                     <hr></hr>
//                   </div>
//                   <table class="table table-bordered account-table tables">
//                     <tbody>
//                       <tr>
//                         <td>
//                           <span>Bonus/Penalty Clause</span>
//                           <br />

//                           <textarea className='form-control'
//                             name="bonus_penalty_clause"
//                             defaultValue={data1.bonus_penalty_clause || ''}
//                             onChange={handleChange}>

//                           </textarea>

//                         </td>
//                         <td>
//                           <span>Rejections</span>
//                           <br />

//                           <textarea className='form-control'
//                             name="rejections"
//                             defaultValue={data1.rejections || ''}
//                             onChange={handleChange}>
//                           </textarea>
//                         </td>

//                       </tr>
//                       <tr >
//                         <td>
//                           <span>Payment Term</span>
//                           <br />


//                           <textarea className='form-control'
//                             name="payment_terms"
//                             defaultValue={data1.payment_terms || ''}
//                             onChange={handleChange}>
//                           </textarea>
//                         </td>
//                         <td>
//                           <span>General Terms</span>
//                           <br />


//                           <textarea className='form-control'
//                             name="general_terms"
//                             defaultValue={data1.general_terms || ''}
//                             onChange={handleChange}>
//                           </textarea>
//                         </td>

//                       </tr>
//                     </tbody>
//                   </table>
//                   <div className="col-md-12" id='ax.account_details'>
//                     <h4 className="heading">Terms & Conditions</h4>
//                     <hr></hr>
//                   </div>
//                   <table class="table table-bordered account-table tables">
//                     <tbody>
//                       <tr>
//                         <td>
//                           <span>Arbitration</span>
//                           <br />

//                           <select className='form-control'
//                             name="arbitration"
//                             defaultValue={data1.arbitration || ''}
//                             onChange={handleChange}>
//                             <option value="">--None--</option><option value="In Singapore as per SIAC">In Singapore as per SIAC</option><option value="Singapore Chamber of Maritime Arbitration (SCMA)">Singapore Chamber of Maritime Arbitration (SCMA)</option><option value="Laws of England and Wales">Laws of England and Wales</option><option value="Rules of Badan Arbitrase Nasional Indonesia (BANI)">Rules of Badan Arbitrase Nasional Indonesia (BANI)</option>
//                           </select>
//                         </td>
//                         <td>
//                           <span>Rwood Surveyor Agent</span>
//                           <br />
//                           <input
//                             type="text" className='form-control'
//                             name="rwood_surveyor_agent"
//                             defaultValue={data1.rwood_surveyor_agent || ''}
//                             onChange={handleChange}
//                           />
//                         </td>

//                       </tr>
//                       <tr>
//                         <td>
//                           <span>Applicable Law</span>
//                           <br />

//                           <select className='form-control'
//                             name="applicable_law"
//                             defaultValue={data1.applicable_law || ''}
//                             onChange={handleChange}>
//                             <option value="">--None--</option><option value="English Law">English Law</option><option value="Singapore Law">Singapore Law</option><option value="Indonesian Law">Indonesian Law</option>
//                           </select>
//                         </td>
//                         <td>
//                           <span>Surveyor 2	</span>
//                           <br />

//                           <select className='form-control'
//                             name="surveyor_2"
//                             defaultValue={data1.surveyor_2 || ''}
//                             onChange={handleChange}>
//                             <option>---None---</option>
//                             {surveyors.map((x) => {
//                               return (
//                                 <option value={x.account_name}>{x.account_name}</option>
//                               );
//                             })}
//                           </select>
//                         </td>
//                       </tr>
//                       <tr>
//                         <td>
//                           <span>Surveyor 3</span>
//                           <br />

//                           <select className='form-control'
//                             name="suveyor_3"
//                             defaultValue={data1.suveyor_3 || ''}
//                             onChange={handleChange}>
//                             <option>---None---</option>
//                             {surveyors.map((x) => {
//                               return (
//                                 <option value={x.account_name}>{x.account_name}</option>
//                               );
//                             })}
//                           </select>
//                         </td>   <td>
//                           <span>Offer Validity Terms</span>
//                           <br />

//                           <textarea className='form-control'
//                             name="offer_validity_terms"
//                             defaultValue={data1.offer_validity_terms || ''}
//                             onChange={handleChange}>

//                           </textarea>
//                         </td>

//                       </tr>
//                       <tr>
//                         <td>
//                           <span>Description</span>
//                           <br />


//                           <textarea className='form-control'
//                             name="description"
//                             defaultValue={data1.description || ''}
//                             onChange={handleChange}>

//                           </textarea>
//                         </td>
//                         <td>

//                         </td>

//                       </tr>
//                     </tbody>
//                   </table>

//                   <div className="col-md-12" id='ax.account_details'>
//                     <h4 className="heading">Prepared For</h4>
//                     <hr></hr>
//                   </div>
//                   <table class="table table-bordered account-table tables">
//                     <tbody>
//                       <tr>
//                         <td>
//                           <span>Contact Name</span>
//                           <br />
//                           <input
//                             type="text" className='form-control'
//                             name="contact_name"
//                             defaultValue={data1.contact_name || ''}
//                             onChange={handleChange}
//                           />
//                         </td>
//                         <td>
//                           <span>Additional To Name</span>
//                           <br />
//                           <input
//                             type="text" className='form-control'
//                             name="additional_to_name"
//                             defaultValue={data1.additional_to_name || ''}
//                             onChange={handleChange}
//                           />
//                         </td>

//                       </tr>
//                       <tr>

//                         <td>
//                           <span>quote to street</span>
//                           <br />
//                           <input
//                             type="text" className='form-control'
//                             name="quote_to_street"
//                             defaultValue={data1.quote_to_street || ''}
//                             onChange={handleChange}
//                           />
//                         </td>
//                         <td>
//                           <span>additional to street</span>
//                           <br />
//                           {/* {data1.additional_to} */}
//                           <input
//                             type="text" className='form-control'
//                             name="additional_to_street"
//                             defaultValue={data1.additional_to_street || ''}
//                             onChange={handleChange}
//                           />
//                         </td>
//                       </tr>
//                       <tr>

//                         <td>
//                           <span>Quote To Zip/Postal Code	</span>
//                           <br />
//                           {/* {data1.phone} */}
//                           <input
//                             type="text" className='form-control'
//                             name="quote_to_zip_postal_code"
//                             defaultValue={data1.quote_to_zip_postal_code || ''}
//                             onChange={handleChange}
//                           />
//                         </td>
//                         <td>
//                           <span>Additional To Zip/Postal Code	</span>
//                           <br />
//                           {/* {data1.phone_fax} */}
//                           <input
//                             type="text" className='form-control'
//                             name="additional_to_zip_postal_code"
//                             defaultValue={data1.additional_to_zip_postal_code || ''}
//                             onChange={handleChange}
//                           />
//                         </td>
//                       </tr>
//                       <tr>

//                         <td>
//                           <span>quote to city	</span>
//                           <br />
//                           {/* {data1.phone_fax} */}
//                           <input
//                             type="text" className='form-control'
//                             name="quote_to_city"
//                             defaultValue={data1.quote_to_city || ''}
//                             onChange={handleChange}
//                           />
//                         </td>
//                         <td>
//                           <span>Additional To City		</span>
//                           <br />
//                           {/* {data1.phone_fax} */}
//                           <input
//                             type="text" className='form-control'
//                             name="additional_to_city"
//                             defaultValue={data1.additional_to_city || ''}
//                             onChange={handleChange}
//                           />
//                         </td>
//                       </tr>
//                       <tr>

//                         <td>
//                           <span>Quote To State/Province	</span>
//                           <br />
//                           {/* {data1.phone_fax} */}
//                           <input
//                             type="text" className='form-control'
//                             name="quote_to_state_province"
//                             defaultValue={data1.quote_to_state_province || ''}
//                             onChange={handleChange}
//                           />
//                         </td>

//                         <td>
//                           <span>Additional To State/Province		</span>
//                           <br />
//                           {/* {data1.phone_fax} */}
//                           <input
//                             type="text" className='form-control'
//                             name="additional_to_state_province"
//                             defaultValue={data1.additional_to_state_province || ''}
//                             onChange={handleChange}
//                           />
//                         </td>
//                       </tr>
//                       <tr>


//                         <td>
//                           <span>Quote To Country	</span>
//                           <br />
//                           {/* {data1.phone_fax} */}
//                           <input
//                             type="text" className='form-control'
//                             name="quote_to_country"
//                             defaultValue={data1.quote_to_country || ''}
//                             onChange={handleChange}
//                           />
//                         </td>
//                         <td>
//                           <span>Additional To Country	</span>
//                           <br />
//                           {/* {data1.phone_fax} */}
//                           <input
//                             type="text" className='form-control'
//                             name="additional_to_country"
//                             defaultValue={data1.additional_to_country || ''}
//                             onChange={handleChange}
//                           />
//                         </td>
//                       </tr>
//                       <tr>



//                       </tr>
//                       <tr>
//                         <td>
//                           <span>Email</span>
//                           <br />
//                           {/* {data1.phone_fax} */}
//                           <input
//                             type="text" className='form-control'
//                             name="email"
//                             defaultValue={data1.email || ''}
//                             onChange={handleChange}
//                           />
//                         </td>
//                         <td>

//                         </td>

//                       </tr>
//                       <tr>
//                         <td>
//                           <span>Phone</span>
//                           <br />
//                           {/* {data1.phone_fax} */}
//                           <input
//                             type="text" className='form-control'
//                             name="phone"
//                             defaultValue={data1.phone || ''}
//                             onChange={handleChange}
//                           />
//                         </td>
//                         <td>

//                         </td>

//                       </tr>
//                       <tr>
//                         <td>
//                           <span>Fax</span>
//                           <br />
//                           {/* {data1.phone_fax} */}
//                           <input
//                             type="text" className='form-control'
//                             name="fax"
//                             defaultValue={data1.fax || ''}
//                             onChange={handleChange}
//                           />
//                         </td>
//                         <td>

//                         </td>

//                       </tr>
//                     </tbody>
//                   </table>

//                   <div className="col-md-12" id='ax.account_details'>
//                     <h4 className="heading">Address Information</h4>
//                     <hr></hr>
//                   </div>
//                   <table class="table table-bordered account-table tables">
//                     <tbody>
//                       <tr>
//                         <td>
//                           <span>Bill To Name</span>
//                           <br />
//                           {/* {data1.bill_to_name} */}
//                           <input
//                             type="text" className='form-control'
//                             name="bill_to_name"
//                             defaultValue={data1.bill_to_name || ''}
//                             onChange={handleChange}
//                           />
//                         </td>
//                         <td>
//                           <span>Ship To Name</span>
//                           <br />
//                           {/* {data1.ship_to_name} */}
//                           <input
//                             type="text" className='form-control'
//                             name="ship_to_name"
//                             defaultValue={data1.ship_to_name || ''}
//                             onChange={handleChange}
//                           />
//                         </td>

//                       </tr>
//                       <tr>
//                         <td>
//                           <span>bill to street</span>
//                           <br />
//                           {/* {data1.bill_to} */}
//                           <input
//                             type="text" className='form-control'
//                             name="bill_to_street"
//                             defaultValue={data1.bill_to_street || ''}
//                             onChange={handleChange}
//                           />
//                         </td>
//                         <td>
//                           <span>Ship To Street</span>
//                           <br />
//                           <input
//                             type="text" className='form-control'
//                             name="ship_to_street"
//                             defaultValue={data1.ship_to_street || ''}
//                             onChange={handleChange}
//                           />
//                         </td>

//                       </tr>
//                       <tr>
//                         <td>
//                           <span>Bill To Zip/Postal Code	</span>
//                           <br />
//                           {/* {data1.bill_to} */}
//                           <input
//                             type="text" className='form-control'
//                             name="bill_to_zip_postal_code"
//                             defaultValue={data1.bill_to_zip_postal_code || ''}
//                             onChange={handleChange}
//                           />
//                         </td>
//                         <td>
//                           <span>Ship To Zip/Postal Code	</span>
//                           <br />
//                           <input
//                             type="text" className='form-control'
//                             name="ship_to_zip_postal_code"
//                             defaultValue={data1.ship_to_zip_postal_code || ''}
//                             onChange={handleChange}
//                           />
//                         </td>

//                       </tr>
//                       <tr>
//                         <td>
//                           <span>Bill To City	</span>
//                           <br />
//                           {/* {data1.bill_to} */}
//                           <input
//                             type="text" className='form-control'
//                             name="bill_to_city"
//                             defaultValue={data1.bill_to_city || ''}
//                             onChange={handleChange}
//                           />
//                         </td>
//                         <td>
//                           <span>Ship To City	</span>
//                           <br />
//                           <input
//                             type="text" className='form-control'
//                             name="ship_to_city"
//                             defaultValue={data1.ship_to_city || ''}
//                             onChange={handleChange}
//                           />
//                         </td>

//                       </tr>
//                       <tr>
//                         <td>
//                           <span>Bill To State/Province	</span>
//                           <br />
//                           {/* {data1.bill_to} */}
//                           <input
//                             type="text" className='form-control'
//                             name="bill_to_state_province"
//                             defaultValue={data1.bill_to_state_province || ''}
//                             onChange={handleChange}
//                           />
//                         </td>
//                         <td>
//                           <span>Ship To State/Province	</span>
//                           <br />
//                           <input
//                             type="text" className='form-control'
//                             name="ship_to_state_province"
//                             defaultValue={data1.ship_to_state_province || ''}
//                             onChange={handleChange}
//                           />
//                         </td>

//                       </tr>

//                       <tr>
//                         <td>
//                           <span>Bill To Country	</span>
//                           <br />
//                           {/* {data1.bill_to} */}
//                           <input
//                             type="text" className='form-control'
//                             name="bill_to_country"
//                             defaultValue={data1.bill_to_country || ''}
//                             onChange={handleChange}
//                           />
//                         </td>
//                         <td>
//                           <span>Ship To Country	</span>
//                           <br />
//                           <input
//                             type="text" className='form-control'
//                             name="ship_to_country"
//                             defaultValue={data1.ship_to_country || ''}
//                             onChange={handleChange}
//                           />
//                         </td>

//                       </tr>
//                     </tbody>
//                   </table>
//                   {/* <div className="col-md-12" id='ax.account_details'>
//    <h4 className="heading">System Information</h4>
//    <hr></hr>
//    </div>
//         <table class="table table-bordered account-table tables">
//          <tbody>
//           <tr>
//           <td>
//                 <span>Created By</span>
//                 <br />
//                 <input
//               type="text" className='form-control'
//               name="created_by"
//               defaultValue={data1.created_by || ''}
//               onChange={handleChange}
//             />
//               </td>
//               <td>
//                 <span>Modified By</span>
//                 <br />
//                 <input
//               type="text" className='form-control'
//               name="modified_by"
//               defaultValue={data1.modified_by || ''}
//               onChange={handleChange}
//             />
//               </td>
//           </tr>

//           </tbody>
//         </table> */}
//                   <p className='edit-btn'>
//                     <input
//                       type="submit"
//                       className="account-save"
//                       onClick={handleSaveEdit}
//                       //value={isSubmitted ? "Saving" : "Save"}
//                      value="Save"
//                     />
//                     <button className="btn btn-primary" onClick={handleCancelEdit}>Cancel</button>
//                   </p>

//                 </>

//               ) : (
//                 <>
//                   <div className="edit-delete">
//                     <span id="edit" onClick={handleEditClick}>
//                       Edit
//                     </span>
//                     <span>
//                       <Link to={`/opportunity/opportunity-detail/${data1.opportunity_id}`}> Cancel</Link>
//                     </span>
//                     {data1.status === 'Accepted' ? (
//                       <span onClick={createContract}>Create Contract</span>
//                     ) : (
//                       <></>
//                     )}
//                   </div>

//                   <div className="col-md-12" id='ax.account_details'>
//                     <h4 className="heading">Quote Detail</h4>
//                     <hr></hr>
//                   </div>

//                   <table class="table table-bordered account-table tables">
//                     <tbody>
//                     <tr>
//                     <td id="td-right">
//                           <span>Quote Name</span>
//                         </td><td>
//                           {data1.quote_name}
//                         </td>
//                         <td id="td-right">
//                           <span>Quote Number</span>
//                         </td><td>
//                           {data1.quote_number}
//                         </td>
//                     </tr>
//                     <tr>
//                     <td id="td-right">
//                           <span>Currency</span>
//                         </td><td>
//                           {data1.currency}
//                         </td>
//                         <td id="td-right">
//                           <span>Quote Record Type</span>
//                         </td>
//                         <td>
//                           {data1.quote_record_type}
//                         </td>
//                     </tr>
//                     <tr>
//                     <td id="td-right">
//                           <span>Exchange Rate</span>
//                         </td><td>
//                           {data1.exchange_rate}
//                         </td>
//                         <td id="td-right">
//                           <span>Locked</span>
//                         </td><td>
//                           <input
//                             type='checkbox'
//                             name='locked'
//                             checked={lockedOne} // Use the state variable to determine if the checkbox is checked
//                             onChange={handleCheckboxLocked} // Handle the change event
//                             className='mx-3' disabled={isButtonEnabled}
//                           />
//                         </td>
//                     </tr>
//                     <tr>
//                     <td id="td-right">
//                           <span>Opportunity Name</span>
//                         </td><td>
//                           <Link to={`/opportunity/opportunity-detail/${data1.opportunity_id}`}>{data1.opportunity_name}</Link>
//                         </td>
//                         <td id="td-right">
//                           <span>Status</span>
//                         </td><td>
//                           {data1.status || 'Offered'}
//                         </td>
//                     </tr>
//                     <tr>
//                     <td id="td-right">
//                           <span>Account Name</span>
//                         </td><td>
//                           <Link to={`/accounts/${data1.account_id}`}>{data1.account_name}</Link>
//                         </td>
//                         <td id="td-right">
//                           <span>No of Product
//                           </span>
//                         </td><td>
//                           {data1.no_of_product
//                           }
//                         </td>
//                     </tr>
//                     <tr>
//                     <td id="td-right">
//                           <span>Supplier</span>
//                         </td><td>
//                           <Link to={`/accounts/${data1.supplier_id}`}>{data1.supplier}</Link>
//                         </td>
//                         <td id="td-right">
//                           <span>No of Quote shipment</span>
//                         </td><td>
//                           {data1.no_of_quote_shipment}
//                         </td>
//                     </tr>
//                     <tr>
//                     <td id="td-right">
//                           <span>Trader Name</span>
//                         </td><td>
//                           <Link to={'/user/user-details/' + data1.trader_id}> {data1.trader_name}</Link>
//                         </td>
//                         <td id="td-right">
//                         </td><td>
//                         </td>
//                     </tr>
//                     <tr>
//                     <td id="td-right">
//                           <span>Quote To Name
//                           </span>
//                         </td><td>
//                            {data1.quote_to_name
//                            }
//                         </td>
//                         <td id="td-right">
//                         </td><td>
//                         </td>
//                     </tr>
//                     <tr>
//                     <td id="td-right">
//                           <span>Quote Date</span>
//                         </td><td>
//                           {data1.quote_date}
//                         </td>
//                         <td id="td-right">
//                         </td><td>
//                         </td>
//                     </tr>
//                     <tr>
//                     <td id="td-right">
//                           <span>Expiration Date</span>
//                         </td><td>
//                           {data1.expiration_date}
//                         </td>
//                         <td id="td-right">
//                         </td><td>
//                         </td>
//                     </tr>
//                      </tbody>
//                   </table>
//                   <div className="col-md-12" id='ax.account_details'>
//                     <h4 className="heading">Product information</h4>
//                     <hr></hr>
//                   </div>
//                   <table class="table table-bordered account-table tables">
//                     <tbody>
//                       <tr>
//                         <td id="td-right">
//                           <span>origin
//                           </span>
//                         </td><td>
//                           {data1.origin}
//                         </td>
//                         <td id="td-right">
//                           <span>Quantity MT
//                           </span>
//                         </td><td>
//                           {data1.quantity_MT}
//                         </td>
//                       </tr>
//                       <tr>
//                         <td id="td-right">
//                           <span>Commodity</span>
//                         </td><td>
//                           {data1.commodity}
//                         </td>
//                         <td id="td-right">
//                           <span>Product Description	</span>
//                         </td><td>
//                           {data1.product_description}
//                         </td>

//                       </tr>
//                       <tr>
//                         <td id="td-right">
//                           <span>Contract</span>
//                         </td><td>
//                         <Link to={'/contract/contract-details/' + data1.contractId}> {data1.contract}</Link>
//                         </td>
//                         <td id="td-right">
//                           {/* <span>Contract</span> */}
//                         </td><td>
//                           {/* {data1.contract} */}
//                         </td>

//                       </tr>

//                     </tbody>
//                   </table>


//                   <div className="col-md-12" id='ax.account_details'>
//                     <h4 className="heading">Market Information</h4>
//                     <hr></hr>
//                   </div>
//                   <table class="table table-bordered account-table tables">
//                     <tbody>
//                       <tr>
//                         <td id="td-right">
//                           <span>destination</span>
//                         </td><td>
//                           {data1.destination}
//                         </td>

//                         <td id="td-right">
//                           {/* <span>Commodity</span> */}
//                         </td><td>
//                           {/* {data1.commodity} */}
//                         </td>

//                       </tr>


//                     </tbody>
//                   </table>
//                   <div className="col-md-12" id='ax.account_details'>
//                     <h4 className="heading">Price and Price Adjustment</h4>
//                     <hr></hr>
//                   </div>
//                   <table class="table table-bordered account-table tables">
//                     <tbody>
//                       <tr>
//                         <td id="td-right">
//                           <span>Bonus/Penalty Clause</span>
//                         </td><td>
//                           {data1.bonus_penalty_clause}
//                         </td>
//                         <td id="td-right">
//                           {/* <span>Rejections</span> */}
//                         </td><td>
//                           {/* {data1.rejections} */}
//                         </td>

//                       </tr>
//                       <tr>
//                         <td id="td-right">
//                           <span>Rejections</span>
//                         </td><td>
//                           {data1.rejections}
//                         </td>
//                         <td id="td-right">
//                           {/* <span>Payment Term</span> */}
//                         </td><td>
//                           {/* {data1.payment_terms} */}
//                         </td>


//                       </tr>
//                       <tr>
//                         <td id="td-right">
//                           <span>Payment Term</span>
//                         </td><td>
//                           {data1.payment_terms}
//                         </td>
//                         <td id="td-right">
//                           {/* <span>General Terms</span> */}
//                         </td><td>
//                           {/* {data1.general_terms} */}
//                         </td>
//                       </tr>
//                       <tr>
//                         <td id="td-right">
//                           <span>General Terms</span>
//                         </td><td>
//                           {data1.general_terms}
//                         </td>
//                         <td id="td-right">
//                           {/* <span>Payment Term</span> */}
//                         </td><td>
//                           {/* {data1.payment_terms} */}
//                         </td>

//                       </tr>
//                     </tbody>
//                   </table>
//                   <div className="col-md-12" id='ax.account_details'>
//                     <h4 className="heading">Terms & Conditions</h4>
//                     <hr></hr>
//                   </div>
//                   <table class="table table-bordered account-table tables">
//                     <tbody>
//                       <tr>
//                         <td id="td-right">
//                           <span>Arbitration</span>
//                         </td><td>
//                           {data1.arbitration}
//                         </td>
//                         <td id="td-right">
//                           <span>Rwood Surveyor Agent</span>
//                         </td><td>
//                           {data1.rwood_surveyor_agent}
//                         </td>

//                       </tr>
//                       <tr>
//                         <td id="td-right">
//                           <span>Applicable Law</span>
//                         </td><td>
//                           {data1.applicable_law}
//                         </td>
//                         <td id="td-right">
//                           <span>Surveyor 2	</span>
//                         </td><td>
//                           {data1.surveyor_2}
//                         </td>
//                       </tr>
//                       <tr>
//                         <td id="td-right">
//                           <span>Offer Validity Terms</span>
//                         </td><td>
//                           {data1.offer_validity_terms}
//                         </td>
//                         <td id="td-right">
//                           <span>Surveyor 3</span>
//                         </td><td>
//                           {data1.suveyor_3}
//                         </td>
//                       </tr>
//                       <tr>
//                         <td id="td-right">
//                           <span>Description</span>
//                         </td><td>
//                           {data1.description}
//                         </td>
//                         <td id="td-right">
//                           {/* <span>Surveyor 3</span> */}
//                         </td><td>
//                           {/* {data1.suveyor_3} */}
//                         </td>
//                       </tr>
//                     </tbody>
//                   </table>

//                   <div className="col-md-12" id='ax.account_details'>
//                     <h4 className="heading">Prepared For</h4>
//                     <hr></hr>
//                   </div>
//                   <table class="table table-bordered account-table tables">
//                     <tbody>


//                       <tr>

//                         <td id="td-right">
//                           <span>Contact Name</span>
//                         </td><td>
//                           {data1.contact_name}
//                         </td>
//                         <td id="td-right">
//                           <span>additional to name</span>
//                         </td><td>
//                           {data1.additional_to_name}
//                         </td>

//                       </tr>
//                       <tr>

//                         <td id="td-right">
//                           <span>quote to name</span>
//                         </td><td>
//                           {data1.quote_to_name}
//                         </td>
//                         <td id="td-right">
//                           <span>additional to</span>
//                         </td><td>
//                           {data1.additional_to}
//                         </td>

//                       </tr>

//                       <tr>

//                         <td id="td-right">
//                           <span>Email</span>
//                         </td><td>
//                           {data1.email}
//                         </td>
//                         <td id="td-right">
//                           {/* <span>additional to name</span> */}
//                         </td><td>
//                           {/* {data1.additional_to_name} */}
//                         </td>

//                       </tr>
//                       <tr>

//                         <td id="td-right">
//                           <span>Phone</span>
//                         </td><td>
//                           {data1.phone_fax}
//                         </td>
//                         <td id="td-right">
//                           {/* <span>additional to name</span> */}
//                         </td><td>
//                           {/* {data1.additional_to_name} */}
//                         </td>

//                       </tr>
//                       <tr>

//                         <td id="td-right">
//                           <span>Fax</span>
//                         </td><td>
//                           {data1.fax}
//                         </td>
//                         <td id="td-right">
//                           {/* <span>additional to name</span> */}
//                         </td><td>
//                           {/* {data1.additional_to_name} */}
//                         </td>

//                       </tr>
//                     </tbody>
//                   </table>

//                   <div className="col-md-12" id='ax.account_details'>
//                     <h4 className="heading">Address Information</h4>
//                     <hr></hr>
//                   </div>
//                   <table class="table table-bordered account-table tables">
//                     <tbody>
//                       <tr>
//                         <td id="td-right">
//                           <span>bill to name</span>
//                         </td><td>
//                           {data1.bill_to_name}
//                         </td>
//                         <td id="td-right">
//                           <span>ship to name</span>
//                         </td><td>
//                           {data1.ship_to_name}
//                         </td>

//                       </tr>
//                       {/* <tr>
//           <td id="td-right">
//                 <span>bill to</span>
//                 </td><td>
//                 {data1.bill_to}
//               </td>
//               <td id="td-right">
//                 <span>ship to</span>
//                 </td><td>
//                 {data1.ship_to}
//               </td>

//           </tr>   */}

//                       <tr>
//                         <td id="td-right">
//                           <span>bill to country</span>
//                         </td><td>
//                           {data1.bill_to_country}
//                         </td>
//                         <td id="td-right">
//                           <span>ship to country</span>
//                         </td><td>
//                           {data1.ship_to_country}
//                         </td>

//                       </tr>
//                     </tbody>
//                   </table>
//                   <div className="col-md-12" id='ax.account_details'>
//                     <h4 className="heading">System Information</h4>
//                     <hr></hr>
//                   </div>
//                   <table class="table table-bordered account-table tables">
//                     <tbody>
//                       <tr>
//                         <td id="td-right">
//                           <span>Created By
//                           </span>
//                         </td><td>
//                           {data1.created_by
//                           }
//                         </td>
//                         <td id="td-right">
//                           <span>Last Modified By
//                           </span>
//                         </td><td>
//                           {data1.modified_by}
//                         </td>


//                       </tr>
//                     </tbody>
//                   </table>
//                   {/* <div className="col-md-12" id='ax.account_details'>
//    <h4 className="heading">System Information</h4>
//    <hr></hr>
//    </div>
//         <table class="table table-bordered account-table tables">
//          <tbody>
//           <tr>
//           <td id="td-right">
//                 <span>Created By</span>
//                 </td><td>
//                 {data1.created_by}
//               </td>
//               <td id="td-right">
//                 <span>Modified By</span>
//                 </td><td>
//                 {data1.modified_by}
//               </td>

//           </tr>
//            </tbody>
//         </table> */}
//  <Row id="table-styles">
//                     <div className="col-md-12" id='head'>
//                       <h4 className="heading"><span>Quote Shipment</span>
//                         {/* <span><Popup trigger={<span className="button add-accountrecord"> <FaPlus/> New </span>} modal>

//     <form onSubmit={handleSubmittcf} className="add-accounts">
//      <div className='form-group'>
//      <label>TCF Record Type</label><br/>
//      <select name='account_record' className='form-control' onChange={handleChangetcf} value={formData.account_record}>
//        <option >--Select TCF Record Type--</option>
//        <option value='Buyer - Spot'>Buyer - Spot</option>
//        <option value='Buyer - Long Term'>Buyer - Long Term</option>
//        </select>
//        </div>
//        <input type='submit' className="btn btn-primary " value='Submit'/>

//        </form>
// </Popup></span> */}
//                       </h4>
//                       <div id='inner-heading'>

//                       </div>
//                       <hr></hr>
//                     </div>
//                     <table id='quote' className="">
//                       <thead>
//                         <tr>
//                           <th>Quote Shipment Code</th>
//                           <th>Quantity</th>
//                           <th>Laycan</th>
//                           <th>Pricing</th>
//                           <th>Quanity Info	</th>
//                           <th>Vessel Type	</th>
//                           <th>Shipping Term
//                           </th>
//                         </tr>
//                       </thead>

//                       {quote.length > 0 ?

//                         <tbody>
//                           {
//                             quote.map((x, i) => (
//                               <tr>
//                                 <td><Link to={'/quote-shipment/detail/' + x.id}>{x.quote_shipment_code}</Link></td>
//                                 <td>{x.quote_shipment_quantity}</td>
//                                 <td>{x.quote_shipment_laycan}</td>
//                                 <td>{x.quote_shipment_pricing}</td>
//                                 <td>{x.quote_shipment_quantity_info
//                                 }</td>

//                                 <td>{x.quote_shipment_vessel_type
//                                 }</td>
//                                 <td>{x.quote_shipment_shipping_term
//                                 }</td>
//                               </tr>
//                             ))}
//                         </tbody>
//                         : <tbody>
//                           <tr>
//                             <td></td>
//                             <td></td>
//                             <td></td>
//                             <td>No data available</td>
//                             <td></td>
//                             <td></td><td></td>

//                           </tr>
//                         </tbody>
//                       }
//                     </table>

//                   </Row>
//                       <Row id="table-styles">
//                     <div className="col-md-12" id='head'>
//                       <h4 className="heading"><span>Quote Product</span>
//                         <span>
//                           <Link to={"/quote-product/select/" + id}>
//                             <i className="fe fe-download-cloud me-2"></i>
//                               New
//                           </Link>
//                         </span>
//                         {/* <span><Popup trigger={<span className="button add-accountrecord"> <FaPlus/> New </span>} modal>

//     <form onSubmit={handleSubmittcf} className="add-accounts">
//      <div className='form-group'>
//      <label>TCF Record Type</label><br/>
//      <select name='account_record' className='form-control' onChange={handleChangetcf} value={formData.account_record}>
//        <option >--Select TCF Record Type--</option>
//        <option value='Buyer - Spot'>Buyer - Spot</option>
//        <option value='Buyer - Long Term'>Buyer - Long Term</option>
//        </select>
//        </div>
//        <input type='submit' className="btn btn-primary " value='Submit'/>

//        </form>
// </Popup></span> */}
//                       </h4>
//                       <div id='inner-heading'>

//                       </div>
//                       <hr></hr>
//                     </div>
//                     <table id='quote-product' className="">
//                       <thead>
//                         <tr>
//                           <th>Quote Product Name	</th>
//                           <th>Quote Shipment	</th>
//                           <th>Gross As Received Typical	</th>
//                           <th>Ash Content Typical	</th>
//                           <th>Total Moisture Typical	</th>
//                           <th>Total Sulphur Typical ARB
//                           </th>
//                         </tr>
//                       </thead>

//                       {quoteProduct.length > 0 ?

//                         <tbody>
//                           {
//                             quoteProduct.map((y, i) => (
//                               <tr>

//                                 <td><span id='codes'><Link to={'/opportunity/quote-detail/' + y.id}>{y.quotes_name}</Link></span></td>
//                                 <td>{y.quotes_shippment}</td>
//                                 <td>{y.gross_as_received_typical	}</td>
//                                 <td>{y.ash_content_typical	}</td>
//                                 <td>{y.total_moisture_typical	}</td>
//                                 <td>{y.total_sulphur_typical_arb
// 	}</td>
//                               </tr>
//                             ))}
//                         </tbody>
//                         : <tbody>
//                           <tr>
//                             <td></td>
//                             <td></td>
//                             <td>No data available</td>
//                             <td></td>
//                             <td></td>
//                             <td></td>

//                           </tr>
//                         </tbody>
//                       }
//                     </table>

//                   </Row>
//                   <Row id="table-styles">
//      <div className="col-md-12" id="head">
//        <h4 className="heading">
//          <span>Quote Clauses
//          </span>
//          <span>
//            <i className=""></i> New Quote Clauses
//            </span>

//        </h4>
//        <hr>
//        </hr>
//      </div>
//      <table id="history" className="">
//        <tr>
//          <td>
//            <p class="no-records"> No records to display </p>
//          </td>
//        </tr>
//      </table>
//    </Row>
//    <Row id="table-styles">
//                     <div className="col-md-12" id='head'>
//                       <h4 className="heading"><span>Trade Confirmation Form</span>

//                       </h4>
//                       <div id='inner-heading'>

//                       </div>
//                       <hr></hr>
//                     </div>
//                     <table id='tcf' className="">
//                       <thead>
//                         <tr>
//                           <th>TCF No</th>
//                           <th>tcf status</th>
//                           <th>Buyer</th>
//                           <th>supplier</th>
//                           <th>Quantity (MT)	</th>
//                           <th>Contractual GAR (P)	</th>
//                           <th>Actual GAR (P)</th>
//                           <th>Actual GAR (S)</th>
//                         </tr>
//                       </thead>

//                       {tcf.length > 0 ?

//                         <tbody>
//                           {
//                             tcf.map((x, i) => (
//                               <tr>
//                                 <td><Link to={"/tcf/tcf-details/" + x.id}>{x.tcf_no}</Link></td>
//                                 <td>{x.tcf_status}</td>
//                                 <td><Link to={"/accounts/" + x.account_id}>{x.buyer}</Link></td>

//                                 <td><Link to={"/accounts/" + x.supplier_id}>{x.supplier}</Link></td>
//                                 <td>{x.quantity_MT}</td>
//                                 <td>{x.contractual_GAR_P}</td>
//                                 <td>{x.actual_gar_p}</td>
//                                 <td>{x.actual_gar_s}</td>
//                               </tr>
//                             ))}
//                         </tbody>
//                         : <tbody>
//                           <tr>
//                             <td></td>
//                             <td></td><td></td>
//                             <td></td>
//                             <td>No data available</td>
//                             <td></td>
//                             <td></td>
//                             <td></td>
//                           </tr>
//                         </tbody>
//                       }
//                     </table>

//                   </Row>

//    <Row id="table-styles">
//      <div className="col-md-12" id="head">
//        <h4 className="heading">
//          <span>Quote PDFs
//          </span>

//        </h4>
//        <hr>
//        </hr>
//      </div>
//      <table id="history" className="">
//        <tr>
//          <td>
//            <p class="no-records"> No records to display </p>
//          </td>
//        </tr>
//      </table>
//    </Row>
//    <Row id="table-styles">
//      <div className="col-md-12" id="head">
//        <h4 className="heading">
//          <span>Notes
//          </span>
//          <span>New Note
//          </span>
//        </h4>
//        <hr>
//        </hr>
//      </div>
//      <table id="history" className="">
//        <tr>
//          <td>
//            <p class="no-records"> No records to display </p>
//          </td>
//        </tr>
//      </table>
//    </Row>
//    <Row id="table-styles">
//      <div className="col-md-12" id="head">
//        <h4 className="heading">
//          <span>Files
//          </span>
//          <span>Upload Files         </span>
//        </h4>
//        <hr>
//        </hr>
//      </div>
//      <table id="history" className="">
//        <tr>
//          <td>
//            <p class="no-records"> No records to display </p>
//          </td>
//        </tr>
//      </table>
//    </Row>

//    <Row id="table-styles">
//      <div className="col-md-12" id="head">
//        <h4 className="heading">
//          <span>Open Activities

//          </span>
//          <span>New Task       </span>
//          <span>New Event       </span>
//        </h4>
//        <hr>
//        </hr>
//      </div>
//      <table id="history" className="">
//        <tr>
//          <td>
//            <p class="no-records"> No records to display </p>
//          </td>
//        </tr>
//      </table>
//    </Row>
//    <Row id="table-styles">
//      <div className="col-md-12" id="head">
//        <h4 className="heading">
//          <span>Notes & Attachments</span>
//          <span>
//             New Note </span>
//          <span>
//            Attach File </span>
//        </h4>
//        <hr>
//        </hr>
//      </div>
//      <table id="history" className="">
//        <tr>
//          <td>
//            <p class="no-records"> No records to display </p>
//          </td>
//        </tr>
//      </table>
//    </Row>
//    <Row id="table-styles">
//      <div className="col-md-12" id="head">
//        <h4 className="heading">
//          <span>Activity History
//          </span>
//          <span>
//             Log a Call </span>
//          <span>
//            Mail Merge </span>
//        </h4>
//        <hr>
//        </hr>
//      </div>
//      <table id="history" className="">
//        <tr>
//          <td>
//            <p class="no-records"> No records to display </p>
//          </td>
//        </tr>
//      </table>
//    </Row>

//    <Row id="table-styles">
//      <div className="col-md-12" id="head">
//        <h4 className="heading">
//          <span>Approval History

//          </span>
//          <span>
//             Submit for Approval</span>

//        </h4>
//        <hr>
//        </hr>
//      </div>
//      <table id="history" className="">
//        <tr>
//          <td>
//            <p class="no-records"> No records to display </p>
//          </td>
//        </tr>
//      </table>
//    </Row>

//                                  </>
//               )}</div>

//           ) : (

//             <>
//               {data1.quote_record_type === 'Supplier - Spot' ? (
//                 <div className="tasks edit-view">
//  <div id="messageContainer"></div>
//                   {isEditMode ? (
//                     <>
//                       <p className='edit-btn'>
//                         <input
//                           type="submit"
//                           className="account-save"
//                           onClick={handleSaveEdit}
//                           //value={isSubmitted ? "Saving" : "Save"}
//                          value="Save"
//                         />
//                         <button className="btn btn-primary" onClick={handleCancelEdit}>Cancel</button>
//                       </p>
//                       <table class="table table-bordered account-table tables">
//                         <tbody>
//                           <tr>
//                             <td>
//                               <span>Quote Number</span>
//                               <br />
//                               <input
//                                 type="text" className='form-control'
//                                 name="quote_number"
//                                 disabled
//                                 defaultValue={data1.quote_number || ''}
//                                 onChange={handleChange}
//                               />
//                             </td>
//                             <td>
//                               <span>Quote Record Type</span>
//                               <br />
//                               <input
//                                 type="text" className='form-control'
//                                 name="quote_record_type"
//                                 disabled
//                                 defaultValue={data1.quote_record_type || ''}
//                                 onChange={handleChange}
//                               />
//                             </td>

//                           </tr>
//                           <tr>
//                             <td>
//                               <span>Currency</span>
//                               <br />

//                               <select name="currency"
//                                 defaultValue={data1.currency || ''}
//                                 onChange={handleChange} className="form-control">
//                                 <option defaultValue='' >---None---</option>
//                                 <option defaultValue='USD' >USD</option>
//                                 <option defaultValue='IDR' >IDR</option>
//                               </select>
//                             </td>
//                             <td></td>
//                           </tr>
//                           <tr>
//                             <td>
//                               <span>Exchange Rate</span>
//                               <br />
//                               <input
//                                 type="text" className='form-control'
//                                 name="exchange_rate"
//                                 defaultValue={data1.exchange_rate || ''}
//                                 onChange={handleChange}
//                               />
//                             </td>
//                             <td></td>
//                           </tr>
//                           <tr>
//                             <td>
//                               <span>Quote Name</span>
//                               <br />
//                               <input
//                                 type="text" className='form-control'
//                                 name="quote_name"
//                                 defaultValue={data1.quote_name || ''}
//                                 onChange={handleChange}
//                               />
//                             </td>
//                             <td>
//                               <span>Status</span>
//                               <br />
//                               <select
//                                 name="status"
//                                 className='form-control'
//                                 defaultValue={data1.status || ''}
//                                 onChange={handleChange}
//                               >
//                                 <option value='Offered'>Offered</option>
//                                 <option value='Accepted'>Accepted</option>
//                                 <option value='Rejected'>Rejected</option>
//                                 <option value='Sold'>Sold</option>
//                               </select>
//                             </td>

//                           </tr>
//                           <tr>
//                             <td>
//                               <span>Opportunity Name</span>
//                               <br />

//                               <select className='form-control'
//                                 name="opportunity_name"
//                                 defaultValue={data1.opportunity_name || ''}>
//                                 <option value='' >Select</option>
//                                 {
//                                   opp.map((x) => {
//                                     return (
//                                       <option value={x.opportunity_name}>{x.opportunity_name}</option>
//                                     )
//                                   })
//                                 }
//                               </select>
//                             </td>
//                             <td>
//                               <span>Supplier</span>
//                               <br />
//                               <input
//                                 type="text" className='form-control'
//                                 name="supplier"
//                                 defaultValue={data1.supplier || ''}
//                                 onChange={handleChange}
//                               />
//                             </td>


//                           </tr>
//                           <tr>
//                             <td>
//                               <span>Account Name</span>
//                               <br />
//                               <input
//                                 type="text" className='form-control record-type'
//                                 name="account_name"
//                                 disabled
//                                 defaultValue={data1.account_name || ''}
//                                 onChange={handleChange}
//                               />
//                             </td>
//                             <td>
//                               <span>Line Items</span>
//                               <br />
//                               <input
//                                 type="text" className='form-control record-type'
//                                 name="line_items"
//                                 disabled
//                                 defaultValue={data1.line_items || ''}
//                                 onChange={handleChange}
//                               />
//                             </td>
//                           </tr>
//                           <tr>
//                             <td>
//                               <span>quote to name</span>
//                               <br />
//                               <input
//                                 type="text" className='form-control'
//                                 name="quote_to_name"
//                                 defaultValue={data1.quote_to_name || ''}
//                                 onChange={handleChange}
//                               />
//                             </td>
//                             <td></td>
//                           </tr>
//                           <tr>
//                             <td>
//                               <span>Quote Date</span>
//                               <br />
//                               <DatePicker
//                                 selected={start}
//                                 onChange={(date) => setStart(date)}
//                                 customInput={<ExampleCustomInput2 />}
//                                 dayClassName={() => "example-datepicker-day-class"}
//                                 popperClassName="edit-date"
//                                 todayButton="TODAY"
//                                 dateFormat="yyyy-MM-dd"
//                                 placeholderText="Choose Date"
//                               />
//                             </td>
//                             <td></td>
//                           </tr>
//                           <tr>
//                             <td>
//                               <span>Expiration Date</span>
//                               <br />

//                               <DatePicker
//                                 selected={effective}
//                                 onChange={(date) => setEffective(date)}
//                                 customInput={<ExampleCustomInput1 />}
//                                 dayClassName={() => "example-datepicker-day-class"}
//                                 popperClassName="edit-date"
//                                 todayButton="TODAY"
//                                 dateFormat="yyyy-MM-dd"
//                                 placeholderText="Choose Date"
//                               />
//                             </td>
//                             <td></td>
//                           </tr>

//                         </tbody>
//                       </table>
//                       <div className="col-md-12" id='ax.account_details'>
//                         <h4 className="heading">Product information</h4>
//                         <hr></hr>
//                       </div>
//                       <table class="table table-bordered account-table tables">
//                         <tbody>
//                           <tr>
//                             <td>
//                               <span>Origin</span>
//                               <br />
//                               <input
//                                 type="text" className='form-control'
//                                 name="origin"
//                                 defaultValue={data1.origin || ''}
//                                 onChange={handleChange}
//                               />
//                             </td>
//                             <td>
//                               <span>Quantity MT</span>
//                               <br />
//                               <input
//                                 type="text" className='form-control'
//                                 name="quantity_MT"
//                                 defaultValue={data1.quantity_MT || ''}
//                                 onChange={handleChange}
//                               />
//                             </td>
//                           </tr>
//                           <tr>
//                             <td>
//                               <span>Commodity</span>
//                               <br />
//                               <select className='form-control'
//                                 name="commodity"
//                                 defaultValue={data1.commodity || ''}
//                                 onChange={handleChange}>
//                                 <option>---None---</option>
//                                 <option defaultValue="Indonesian Steam Coal in Bulk (&quot;Coal&quot;)">Indonesian Steam Coal in Bulk ("Coal")</option><option defaultValue="US Coal">US Coal</option><option defaultValue="Russia Coal">Russia Coal</option><option defaultValue="SA Coal">SA Coal</option><option defaultValue="Clinker in Bulk">Clinker in Bulk</option><option defaultValue="Australian Steam Coal">Australian Steam Coal</option>
//                               </select>
//                             </td>
//                             <td>
//                               <span>Product Description</span>
//                               <br />
//                               <input
//                                 type="text" className='form-control'
//                                 name="product_description"
//                                 defaultValue={data1.product_description || ''}
//                                 onChange={handleChange}
//                               />
//                             </td>

//                           </tr>
//                           <tr>
//                             <td>
//                               <span>Contract</span>
//                               <br />
//                               <input
//                                 type="text" className='form-control'
//                                 name="contract"
//                                 defaultValue={data1.contract || ''}
//                                 onChange={handleChange}
//                               />
//                             </td>
//                             <td></td>
//                           </tr>
//                         </tbody>
//                       </table>
//                       <div className="col-md-12" id='ax.account_details'>
//                         <h4 className="heading">Market Information</h4>
//                         <hr></hr>
//                       </div>
//                       <table class="table table-bordered account-table tables">
//                         <tbody>

//                           <tr>
//                             <td>
//                               <span>Destination</span>
//                               <br />

//                               <input
//                                 type="text" className='form-control'
//                                 name="destination"
//                                 defaultValue={data1.destination || ''}
//                                 onChange={handleChange}
//                               />
//                             </td>
//                             <td></td>
//                           </tr>
//                         </tbody>
//                       </table>
//                       <div className="col-md-12" id='ax.account_details'>
//                         <h4 className="heading">Price and Price Adjustment</h4>
//                         <hr></hr>
//                       </div>
//                       <table class="table table-bordered account-table tables">
//                         <tbody>
//                           <tr>
//                             <td>
//                               <span>Bonus/Penalty Clause</span>
//                               <br />

//                               <textarea className='form-control'
//                                 name="bonus_penalty_clause"
//                                 defaultValue={data1.bonus_penalty_clause || ''}
//                                 onChange={handleChange}>

//                               </textarea>

//                             </td>
//                             <td>
//                               <span>Rejections</span>
//                               <br />

//                               <textarea className='form-control'
//                                 name="rejections"
//                                 defaultValue={data1.rejections || ''}
//                                 onChange={handleChange}>
//                               </textarea>
//                             </td>

//                           </tr>
//                           <tr >
//                             <td>
//                               <span>Payment Term</span>
//                               <br />


//                               <textarea className='form-control'
//                                 name="payment_terms"
//                                 defaultValue={data1.payment_terms || ''}
//                                 onChange={handleChange}>
//                               </textarea>
//                             </td>
//                             <td>
//                               <span>General Terms</span>
//                               <br />


//                               <textarea className='form-control'
//                                 name="general_terms"
//                                 defaultValue={data1.general_terms || ''}
//                                 onChange={handleChange}>
//                               </textarea>
//                             </td>

//                           </tr>
//                         </tbody>
//                       </table>
//                       <div className="col-md-12" id='ax.account_details'>
//                         <h4 className="heading">Terms & Conditions</h4>
//                         <hr></hr>
//                       </div>
//                       <table class="table table-bordered account-table tables">
//                         <tbody>
//                           <tr>
//                             <td>
//                               <span>Arbitration</span>
//                               <br />

//                               <select className='form-control'
//                                 name="arbitration"
//                                 defaultValue={data1.arbitration || ''}
//                                 onChange={handleChange}>
//                                 <option value="">--None--</option><option value="In Singapore as per SIAC">In Singapore as per SIAC</option><option value="Singapore Chamber of Maritime Arbitration (SCMA)">Singapore Chamber of Maritime Arbitration (SCMA)</option><option value="Laws of England and Wales">Laws of England and Wales</option><option value="Rules of Badan Arbitrase Nasional Indonesia (BANI)">Rules of Badan Arbitrase Nasional Indonesia (BANI)</option>
//                               </select>
//                             </td>
//                             <td>
//                               <span>Rwood Surveyor Agent</span>
//                               <br />
//                               <input
//                                 type="text" className='form-control'
//                                 name="rwood_surveyor_agent"
//                                 defaultValue={data1.rwood_surveyor_agent || ''}
//                                 onChange={handleChange}
//                               />
//                             </td>

//                           </tr>
//                           <tr>
//                             <td>
//                               <span>Applicable Law</span>
//                               <br />

//                               <select className='form-control'
//                                 name="applicable_law"
//                                 defaultValue={data1.applicable_law || ''}
//                                 onChange={handleChange}>
//                                 <option value="">--None--</option><option value="English Law">English Law</option><option value="Singapore Law">Singapore Law</option><option value="Indonesian Law">Indonesian Law</option>
//                               </select>
//                             </td>
//                             <td>
//                               <span>Surveyor 2	</span>
//                               <br />

//                               <select className='form-control'
//                                 name="surveyor_2"
//                                 defaultValue={data1.surveyor_2 || ''}
//                                 onChange={handleChange}>
//                                 <option>---None---</option>
//                                 {surveyors.map((x) => {
//                                   return (
//                                     <option value={x.account_name}>{x.account_name}</option>
//                                   );
//                                 })}
//                               </select>
//                             </td>
//                           </tr>
//                           <tr>
//                             <td>
//                               <span>Offer Validity Terms</span>
//                               <br />

//                               <textarea className='form-control'
//                                 name="offer_validity_terms"
//                                 defaultValue={data1.offer_validity_terms || ''}
//                                 onChange={handleChange}>

//                               </textarea>
//                             </td>
//                             <td>
//                               <span>Surveyor 3</span>
//                               <br />

//                               <select className='form-control'
//                                 name="suveyor_3"
//                                 defaultValue={data1.suveyor_3 || ''}
//                                 onChange={handleChange}>
//                                 <option>---None---</option>
//                                 {surveyors.map((x) => {
//                                   return (
//                                     <option value={x.account_name}>{x.account_name}</option>
//                                   );
//                                 })}
//                               </select>
//                             </td>

//                           </tr>
//                           <tr>
//                             <td>
//                               <span>Description</span>
//                               <br />


//                               <textarea className='form-control'
//                                 name="description"
//                                 defaultValue={data1.description || ''}
//                                 onChange={handleChange}>

//                               </textarea>
//                             </td>
//                             <td>

//                             </td>

//                           </tr>
//                         </tbody>
//                       </table>

//                       <div className="col-md-12" id='ax.account_details'>
//                         <h4 className="heading">Prepared For</h4>
//                         <hr></hr>
//                       </div>
//                       <table class="table table-bordered account-table tables">
//                         <tbody>
//                           <tr>
//                             <td>
//                               <span>Contact Name</span>
//                               <br />
//                               <input
//                                 type="text" className='form-control'
//                                 name="contact_name"
//                                 defaultValue={data1.contact_name || ''}
//                                 onChange={handleChange}
//                               />
//                             </td>
//                             <td>
//                               <span>Additional To Name</span>
//                               <br />
//                               <input
//                                 type="text" className='form-control'
//                                 name="additional_to_name"
//                                 defaultValue={data1.additional_to_name || ''}
//                                 onChange={handleChange}
//                               />
//                             </td>

//                           </tr>
//                           <tr>

//                             <td>
//                               <span>quote to street</span>
//                               <br />
//                               <input
//                                 type="text" className='form-control'
//                                 name="quote_to_street"
//                                 defaultValue={data1.quote_to_street || ''}
//                                 onChange={handleChange}
//                               />
//                             </td>
//                             <td>
//                               <span>additional to street</span>
//                               <br />
//                               {/* {data1.additional_to} */}
//                               <input
//                                 type="text" className='form-control'
//                                 name="additional_to_street"
//                                 defaultValue={data1.additional_to_street || ''}
//                                 onChange={handleChange}
//                               />
//                             </td>
//                           </tr>
//                           <tr>

//                             <td>
//                               <span>Quote To Zip/Postal Code	</span>
//                               <br />
//                               {/* {data1.phone} */}
//                               <input
//                                 type="text" className='form-control'
//                                 name="quote_to_zip_postal_code"
//                                 defaultValue={data1.quote_to_zip_postal_code || ''}
//                                 onChange={handleChange}
//                               />
//                             </td>
//                             <td>
//                               <span>Additional To Zip/Postal Code	</span>
//                               <br />
//                               {/* {data1.phone_fax} */}
//                               <input
//                                 type="text" className='form-control'
//                                 name="additional_to_zip_postal_code"
//                                 defaultValue={data1.additional_to_zip_postal_code || ''}
//                                 onChange={handleChange}
//                               />
//                             </td>
//                           </tr>
//                           <tr>

//                             <td>
//                               <span>quote to city	</span>
//                               <br />
//                               {/* {data1.phone_fax} */}
//                               <input
//                                 type="text" className='form-control'
//                                 name="quote_to_city"
//                                 defaultValue={data1.quote_to_city || ''}
//                                 onChange={handleChange}
//                               />
//                             </td>
//                             <td>
//                               <span>Additional To City		</span>
//                               <br />
//                               {/* {data1.phone_fax} */}
//                               <input
//                                 type="text" className='form-control'
//                                 name="additional_to_city"
//                                 defaultValue={data1.additional_to_city || ''}
//                                 onChange={handleChange}
//                               />
//                             </td>
//                           </tr>
//                           <tr>

//                             <td>
//                               <span>Quote To State/Province	</span>
//                               <br />
//                               {/* {data1.phone_fax} */}
//                               <input
//                                 type="text" className='form-control'
//                                 name="quote_to_state_province"
//                                 defaultValue={data1.quote_to_state_province || ''}
//                                 onChange={handleChange}
//                               />
//                             </td>

//                             <td>
//                               <span>Additional To State/Province		</span>
//                               <br />
//                               {/* {data1.phone_fax} */}
//                               <input
//                                 type="text" className='form-control'
//                                 name="additional_to_state_province"
//                                 defaultValue={data1.additional_to_state_province || ''}
//                                 onChange={handleChange}
//                               />
//                             </td>
//                           </tr>
//                           <tr>


//                             <td>
//                               <span>Quote To Country	</span>
//                               <br />
//                               {/* {data1.phone_fax} */}
//                               <input
//                                 type="text" className='form-control'
//                                 name="quote_to_country"
//                                 defaultValue={data1.quote_to_country || ''}
//                                 onChange={handleChange}
//                               />
//                             </td>
//                             <td>
//                               <span>Additional To Country	</span>
//                               <br />
//                               {/* {data1.phone_fax} */}
//                               <input
//                                 type="text" className='form-control'
//                                 name="additional_to_country"
//                                 defaultValue={data1.additional_to_country || ''}
//                                 onChange={handleChange}
//                               />
//                             </td>
//                           </tr>
//                           <tr>



//                           </tr>
//                           <tr>
//                             <td>
//                               <span>Email</span>
//                               <br />
//                               {/* {data1.phone_fax} */}
//                               <input
//                                 type="text" className='form-control'
//                                 name="email"
//                                 defaultValue={data1.email || ''}
//                                 onChange={handleChange}
//                               />
//                             </td>
//                             <td>

//                             </td>

//                           </tr>
//                           <tr>
//                             <td>
//                               <span>Phone</span>
//                               <br />
//                               {/* {data1.phone_fax} */}
//                               <input
//                                 type="text" className='form-control'
//                                 name="phone"
//                                 defaultValue={data1.phone || ''}
//                                 onChange={handleChange}
//                               />
//                             </td>
//                             <td>

//                             </td>

//                           </tr>
//                           <tr>
//                             <td>
//                               <span>Fax</span>
//                               <br />
//                               {/* {data1.phone_fax} */}
//                               <input
//                                 type="text" className='form-control'
//                                 name="fax"
//                                 defaultValue={data1.fax || ''}
//                                 onChange={handleChange}
//                               />
//                             </td>
//                             <td>

//                             </td>

//                           </tr>
//                         </tbody>
//                       </table>

//                       <div className="col-md-12" id='ax.account_details'>
//                         <h4 className="heading">Address Information</h4>
//                         <hr></hr>
//                       </div>
//                       <table class="table table-bordered account-table tables">
//                         <tbody>
//                           <tr>
//                             <td>
//                               <span>Bill To Name</span>
//                               <br />
//                               {/* {data1.bill_to_name} */}
//                               <input
//                                 type="text" className='form-control'
//                                 name="bill_to_name"
//                                 defaultValue={data1.bill_to_name || ''}
//                                 onChange={handleChange}
//                               />
//                             </td>
//                             <td>
//                               <span>Ship To Name</span>
//                               <br />
//                               {/* {data1.ship_to_name} */}
//                               <input
//                                 type="text" className='form-control'
//                                 name="ship_to_name"
//                                 defaultValue={data1.ship_to_name || ''}
//                                 onChange={handleChange}
//                               />
//                             </td>

//                           </tr>
//                           <tr>
//                             <td>
//                               <span>bill to street</span>
//                               <br />
//                               {/* {data1.bill_to} */}
//                               <input
//                                 type="text" className='form-control'
//                                 name="bill_to_street"
//                                 defaultValue={data1.bill_to_street || ''}
//                                 onChange={handleChange}
//                               />
//                             </td>
//                             <td>
//                               <span>Ship To Street</span>
//                               <br />
//                               <input
//                                 type="text" className='form-control'
//                                 name="ship_to_street"
//                                 defaultValue={data1.ship_to_street || ''}
//                                 onChange={handleChange}
//                               />
//                             </td>

//                           </tr>
//                           <tr>
//                             <td>
//                               <span>Bill To Zip/Postal Code	</span>
//                               <br />
//                               {/* {data1.bill_to} */}
//                               <input
//                                 type="text" className='form-control'
//                                 name="bill_to_zip_postal_code"
//                                 defaultValue={data1.bill_to_zip_postal_code || ''}
//                                 onChange={handleChange}
//                               />
//                             </td>
//                             <td>
//                               <span>Ship To Zip/Postal Code	</span>
//                               <br />
//                               <input
//                                 type="text" className='form-control'
//                                 name="ship_to_zip_postal_code"
//                                 defaultValue={data1.ship_to_zip_postal_code || ''}
//                                 onChange={handleChange}
//                               />
//                             </td>

//                           </tr>
//                           <tr>
//                             <td>
//                               <span>Bill To City	</span>
//                               <br />
//                               {/* {data1.bill_to} */}
//                               <input
//                                 type="text" className='form-control'
//                                 name="bill_to_city"
//                                 defaultValue={data1.bill_to_city || ''}
//                                 onChange={handleChange}
//                               />
//                             </td>
//                             <td>
//                               <span>Ship To City	</span>
//                               <br />
//                               <input
//                                 type="text" className='form-control'
//                                 name="ship_to_city"
//                                 defaultValue={data1.ship_to_city || ''}
//                                 onChange={handleChange}
//                               />
//                             </td>

//                           </tr>
//                           <tr>
//                             <td>
//                               <span>Bill To State/Province	</span>
//                               <br />
//                               {/* {data1.bill_to} */}
//                               <input
//                                 type="text" className='form-control'
//                                 name="bill_to_state_province"
//                                 defaultValue={data1.bill_to_state_province || ''}
//                                 onChange={handleChange}
//                               />
//                             </td>
//                             <td>
//                               <span>Ship To State/Province	</span>
//                               <br />
//                               <input
//                                 type="text" className='form-control'
//                                 name="ship_to_state_province"
//                                 defaultValue={data1.ship_to_state_province || ''}
//                                 onChange={handleChange}
//                               />
//                             </td>

//                           </tr>

//                           <tr>
//                             <td>
//                               <span>Bill To Country	</span>
//                               <br />
//                               {/* {data1.bill_to} */}
//                               <input
//                                 type="text" className='form-control'
//                                 name="bill_to_country"
//                                 defaultValue={data1.bill_to_country || ''}
//                                 onChange={handleChange}
//                               />
//                             </td>
//                             <td>
//                               <span>Ship To Country	</span>
//                               <br />
//                               <input
//                                 type="text" className='form-control'
//                                 name="ship_to_country"
//                                 defaultValue={data1.ship_to_country || ''}
//                                 onChange={handleChange}
//                               />
//                             </td>

//                           </tr>
//                         </tbody>
//                       </table>
//                       {/* <div className="col-md-12" id='ax.account_details'>
//    <h4 className="heading">System Information</h4>
//    <hr></hr>
//    </div>
//         <table class="table table-bordered account-table tables">
//          <tbody>
//           <tr>
//           <td>
//                 <span>Created By</span>
//                 <br />
//                 <input
//               type="text" className='form-control'
//               name="created_by"
//               defaultValue={data1.created_by || ''}
//               onChange={handleChange}
//             />
//               </td>
//               <td>
//                 <span>Modified By</span>
//                 <br />
//                 <input
//               type="text" className='form-control'
//               name="modified_by"
//               defaultValue={data1.modified_by || ''}
//               onChange={handleChange}
//             />
//               </td>
//           </tr>

//           </tbody>
//         </table> */}
//                       <p className='edit-btn'>
//                         <input
//                           type="submit"
//                           className="account-save"
//                           onClick={handleSaveEdit}
//                           //value={isSubmitted ? "Saving" : "Save"}
//                          value="Save"
//                         />
//                         <button className="btn btn-primary" onClick={handleCancelEdit}>Cancel</button>
//                       </p>

//                     </>

//                   ) : (
//                     <>
//                       <div className="edit-delete">
//                         <span id="edit" onClick={handleEditClick}>
//                           Edit
//                         </span>
//                         <span>
//                           <Link to={`/opportunity/opportunity-detail/${data1.opportunity_id}`}> Cancel</Link>
//                         </span>
//                         {data1.status === 'Accepted' ? (
//                           <span onClick={createContract}>Create Contract</span>
//                         ) : (
//                           <></>
//                         )}
//                       </div>

//                       <div className="col-md-12" id='ax.account_details'>
//                         <h4 className="heading">Quote Detail</h4>
//                         <hr></hr>
//                       </div>

//                       <table class="table table-bordered account-table tables">
//                         <tbody>
//                           <tr>
//                             <td id="td-right">
//                               <span>Quote Number</span>
//                             </td><td>
//                               {data1.quote_number}
//                             </td>
//                             <td id="td-right">
//                               <span>Quote Name</span>
//                             </td><td>
//                               {data1.quote_name}
//                             </td>

//                           </tr>
//                           <tr>
//                             <td id="td-right">
//                               <span>Currency</span>
//                             </td><td>
//                               {data1.currency}
//                             </td>
//                             <td id="td-right">
//                               <span>Quote Record Type</span>
//                             </td>
//                             <td>
//                               {data1.quote_record_type}
//                             </td>

//                           </tr>
//                           <tr>
//                             <td id="td-right">
//                               <span>Exchange Rate</span>
//                             </td><td>
//                               {data1.exchange_rate}
//                             </td>
//                             <td id="td-right">
//                               <span>Locked</span>
//                             </td><td>
//                               <input
//                                 type='checkbox'
//                                 name='locked'
//                                 checked={lockedOne} // Use the state variable to determine if the checkbox is checked
//                                 onChange={handleCheckboxLocked} // Handle the change event
//                                 className='mx-3' disabled={isButtonEnabled}
//                               />
//                             </td>


//                           </tr>
//                           <tr>
//                             <td id="td-right">
//                               <span>Opportunity Name</span>
//                             </td><td>
//                               <Link to={`/opportunity/opportunity-detail/${data1.opportunity_id}`}>{data1.opportunity_name}</Link>
//                             </td>
//                             <td id="td-right">
//                               <span>Status</span>
//                             </td><td>
//                               {data1.status || 'Offered'}
//                             </td>

//                           </tr>
//                           <tr>
//                             <td id="td-right">
//                               <span>Account Name</span>
//                             </td><td>
//                               <Link to={`/accounts/${data1.account_id}`}>{data1.account_name}</Link>
//                             </td>
//                             <td id="td-right">
//                               <span>no of product</span>
//                             </td><td>
//                               {data1.no_of_product}
//                             </td>

//                           </tr>
//                           <tr>
//                             <td id="td-right">
//                               <span>Supplier</span>
//                             </td><td>
//                               <Link to={`/accounts/${data1.supplier_id}`}>{data1.supplier}</Link>
//                             </td>
//                             <td id="td-right">
//                               <span>No of Quote shipment</span>
//                             </td><td>
//                               {data1.no_of_quote_shipment}
//                             </td>

//                           </tr>
//                           <tr>
//                             <td id="td-right">
//                               <span>Trader Name</span>
//                             </td><td>
//                               <Link to={'/user/user-details/' + data1.trader_id}> {data1.trader_name}</Link>
//                             </td>
//                             <td id="td-right">
//                             </td><td>
//                             </td>


//                           </tr>
//                           <tr>
//                             <td id="td-right">
//                               <span>Quote to Name</span>
//                             </td><td>
//                               {data1.quote_to_name}
//                             </td>
//                             <td id="td-right">
//                             </td><td>
//                             </td>


//                           </tr>
//                           <tr>
//                             <td id="td-right">
//                               <span>Quote Date</span>
//                             </td><td>
//                               {data1.quote_date}
//                             </td>
//                             <td id="td-right">
//                             </td><td>
//                             </td>

//                           </tr>
//                           <tr>
//                             <td id="td-right">
//                               <span>Expiration Date</span>
//                             </td><td>
//                               {data1.expiration_date}
//                             </td>
//                             <td id="td-right">
//                             </td><td>
//                             </td>

//                           </tr>
//                         </tbody>
//                       </table>
//                       <div className="col-md-12" id='ax.account_details'>
//                         <h4 className="heading">Product information</h4>
//                         <hr></hr>
//                       </div>
//                       <table class="table table-bordered account-table tables">
//                         <tbody>
//                           <tr>
//                             <td id="td-right">
//                               <span>origin
//                               </span>
//                             </td><td>
//                               {data1.origin}
//                             </td>
//                             <td id="td-right">
//                               <span>Quantity MT
//                               </span>
//                             </td><td>
//                               {data1.quantity_MT}
//                             </td>
//                           </tr>
//                           <tr>
//                             <td id="td-right">
//                               <span>Commodity</span>
//                             </td><td>
//                               {data1.commodity}
//                             </td>
//                             <td id="td-right">
//                               <span>Product Description	</span>
//                             </td><td>
//                               {data1.product_description}
//                             </td>

//                           </tr>
//                           <tr>
//                             <td id="td-right">
//                               <span>Contract</span>
//                             </td><td>
//                             <Link to={'/contract/contract-details/' + data1.contractId}> {data1.contract}</Link>
//                             </td>
//                             <td id="td-right">
//                               {/* <span>Contract</span> */}
//                             </td><td>
//                               {/* {data1.contract} */}
//                             </td>

//                           </tr>

//                         </tbody>
//                       </table>


//                       <div className="col-md-12" id='ax.account_details'>
//                         <h4 className="heading">Market Information</h4>
//                         <hr></hr>
//                       </div>
//                       <table class="table table-bordered account-table tables">
//                         <tbody>
//                           <tr>
//                             <td id="td-right">
//                               <span>destination</span>
//                             </td><td>
//                               {data1.destination}
//                             </td>

//                             <td id="td-right">
//                               {/* <span>Commodity</span> */}
//                             </td><td>
//                               {/* {data1.commodity} */}
//                             </td>

//                           </tr>


//                         </tbody>
//                       </table>
//                       <div className="col-md-12" id='ax.account_details'>
//                         <h4 className="heading">Price and Price Adjustment</h4>
//                         <hr></hr>
//                       </div>
//                       <table class="table table-bordered account-table tables">
//                         <tbody>
//                           <tr>
//                             <td id="td-right">
//                               <span>Bonus/Penalty Clause</span>
//                             </td><td>
//                               {data1.bonus_penalty_clause}
//                             </td>
//                             <td id="td-right">
//                               {/* <span>Rejections</span> */}
//                             </td><td>
//                               {/* {data1.rejections} */}
//                             </td>

//                           </tr>
//                           <tr>
//                             <td id="td-right">
//                               <span>Rejections</span>
//                             </td><td>
//                               {data1.rejections}
//                             </td>
//                             <td id="td-right">
//                               {/* <span>Payment Term</span> */}
//                             </td><td>
//                               {/* {data1.payment_terms} */}
//                             </td>


//                           </tr>
//                           <tr>
//                             <td id="td-right">
//                               <span>Payment Term</span>
//                             </td><td>
//                               {data1.payment_terms}
//                             </td>
//                             <td id="td-right">
//                               {/* <span>General Terms</span> */}
//                             </td><td>
//                               {/* {data1.general_terms} */}
//                             </td>
//                           </tr>
//                           <tr>
//                             <td id="td-right">
//                               <span>General Terms</span>
//                             </td><td>
//                               {data1.general_terms}
//                             </td>
//                             <td id="td-right">
//                               {/* <span>Payment Term</span> */}
//                             </td><td>
//                               {/* {data1.payment_terms} */}
//                             </td>

//                           </tr>
//                         </tbody>
//                       </table>
//                       <div className="col-md-12" id='ax.account_details'>
//                         <h4 className="heading">Terms & Conditions</h4>
//                         <hr></hr>
//                       </div>
//                       <table class="table table-bordered account-table tables">
//                         <tbody>
//                           <tr>
//                             <td id="td-right">
//                               <span>Arbitration</span>
//                             </td><td>
//                               {data1.arbitration}
//                             </td>
//                             <td id="td-right">
//                               <span>Rwood Surveyor Agent</span>
//                             </td><td>
//                               {data1.rwood_surveyor_agent}
//                             </td>

//                           </tr>
//                           <tr>
//                             <td id="td-right">
//                               <span>Applicable Law</span>
//                             </td><td>
//                               {data1.applicable_law}
//                             </td>
//                             <td id="td-right">
//                               <span>Surveyor 2	</span>
//                             </td><td>
//                               {data1.surveyor_2}
//                             </td>
//                           </tr>
//                           <tr>
//                             <td id="td-right">
//                               <span>Offer Validity Terms</span>
//                             </td><td>
//                               {data1.offer_validity_terms}
//                             </td>
//                             <td id="td-right">
//                               <span>Surveyor 3</span>
//                             </td><td>
//                               {data1.suveyor_3}
//                             </td>
//                           </tr>
//                           <tr>
//                             <td id="td-right">
//                               <span>Description</span>
//                             </td><td>
//                               {data1.description}
//                             </td>
//                             <td id="td-right">
//                               {/* <span>Surveyor 3</span> */}
//                             </td><td>
//                               {/* {data1.suveyor_3} */}
//                             </td>
//                           </tr>
//                         </tbody>
//                       </table>

//                       <div className="col-md-12" id='ax.account_details'>
//                         <h4 className="heading">Prepared For</h4>
//                         <hr></hr>
//                       </div>
//                       <table class="table table-bordered account-table tables">
//                         <tbody>


//                           <tr>

//                             <td id="td-right">
//                               <span>Contact Name</span>
//                             </td><td>
//                               {data1.contact_name}
//                             </td>
//                             <td id="td-right">
//                               <span>additional to name</span>
//                             </td><td>
//                               {data1.additional_to_name}
//                             </td>

//                           </tr>
//                           <tr>

//                             <td id="td-right">
//                               <span>quote to name</span>
//                             </td><td>
//                               {data1.quote_to_name}
//                             </td>
//                             <td id="td-right">
//                               <span>additional to</span>
//                             </td><td>
//                               {data1.additional_to}
//                             </td>

//                           </tr>

//                           <tr>

//                             <td id="td-right">
//                               <span>Email</span>
//                             </td><td>
//                               {data1.email}
//                             </td>
//                             <td id="td-right">
//                               {/* <span>additional to name</span> */}
//                             </td><td>
//                               {/* {data1.additional_to_name} */}
//                             </td>

//                           </tr>
//                           <tr>

//                             <td id="td-right">
//                               <span>Phone</span>
//                             </td><td>
//                               {data1.phone_fax}
//                             </td>
//                             <td id="td-right">
//                               {/* <span>additional to name</span> */}
//                             </td><td>
//                               {/* {data1.additional_to_name} */}
//                             </td>

//                           </tr>
//                           <tr>

//                             <td id="td-right">
//                               <span>Fax</span>
//                             </td><td>
//                               {data1.fax}
//                             </td>
//                             <td id="td-right">
//                               {/* <span>additional to name</span> */}
//                             </td><td>
//                               {/* {data1.additional_to_name} */}
//                             </td>

//                           </tr>
//                         </tbody>
//                       </table>

//                       <div className="col-md-12" id='ax.account_details'>
//                         <h4 className="heading">Address Information</h4>
//                         <hr></hr>
//                       </div>
//                       <table class="table table-bordered account-table tables">
//                         <tbody>
//                           <tr>
//                             <td id="td-right">
//                               <span>bill to name</span>
//                             </td><td>
//                               {data1.bill_to_name}
//                             </td>
//                             <td id="td-right">
//                               <span>ship to name</span>
//                             </td><td>
//                               {data1.ship_to_name}
//                             </td>

//                           </tr>
//                           {/* <tr>
//           <td id="td-right">
//                 <span>bill to</span>
//                 </td><td>
//                 {data1.bill_to}
//               </td>
//               <td id="td-right">
//                 <span>ship to</span>
//                 </td><td>
//                 {data1.ship_to}
//               </td>

//           </tr>   */}

//                           <tr>
//                             <td id="td-right">
//                               <span>bill to country</span>
//                             </td><td>
//                               {data1.bill_to_country}
//                             </td>
//                             <td id="td-right">
//                               <span>ship to country</span>
//                             </td><td>
//                               {data1.ship_to_country}
//                             </td>

//                           </tr>
//                         </tbody>
//                       </table>
//                       <div className="col-md-12" id='ax.account_details'>
//    <h4 className="heading">System Information</h4>
//    <hr></hr>
//    </div>
//         <table class="table table-bordered account-table tables">
//          <tbody>
//           <tr>
//           <td id="td-right">
//                 <span>Created By</span>
//                 </td><td>
//                 {data1.created_by}
//               </td>
//               <td id="td-right">
//                 <span>Modified By</span>
//                 </td><td>
//                 {data1.modified_by}
//               </td>

//           </tr>
//            </tbody>
//         </table>


//  <Row id="table-styles">
//                     <div className="col-md-12" id='head'>
//                       <h4 className="heading"><span>Quote Shipment</span>
//                         {/* <span><Popup trigger={<span className="button add-accountrecord"> <FaPlus/> New </span>} modal>

//     <form onSubmit={handleSubmittcf} className="add-accounts">
//      <div className='form-group'>
//      <label>TCF Record Type</label><br/>
//      <select name='account_record' className='form-control' onChange={handleChangetcf} value={formData.account_record}>
//        <option >--Select TCF Record Type--</option>
//        <option value='Buyer - Spot'>Buyer - Spot</option>
//        <option value='Buyer - Long Term'>Buyer - Long Term</option>
//        </select>
//        </div>
//        <input type='submit' className="btn btn-primary " value='Submit'/>

//        </form>
// </Popup></span> */}
//                       </h4>
//                       <div id='inner-heading'>

//                       </div>
//                       <hr></hr>
//                     </div>
//                     <table id='quote' className="">
//                       <thead>
//                         <tr>
//                           <th>Quote Shipment Code</th>
//                           <th>Quantity</th>
//                           <th>Laycan</th>
//                           <th>Pricing</th>
//                           <th>Quanity Info	</th>
//                           <th>Vessel Type	</th>
//                           <th>Shipping Term
//                           </th>
//                         </tr>
//                       </thead>

//                       {quote.length > 0 ?

//                         <tbody>
//                           {
//                             quote.map((x, i) => (
//                               <tr>
//                                 <td><Link to={'/quote-shipment/detail/' + x.id}>{x.quote_shipment_code}</Link></td>
//                                 <td>{x.quote_shipment_quantity}</td>
//                                 <td>{x.quote_shipment_laycan}</td>
//                                 <td>{x.quote_shipment_pricing}</td>
//                                 <td>{x.quote_shipment_quantity_info
//                                 }</td>

//                                 <td>{x.quote_shipment_vessel_type
//                                 }</td>
//                                 <td>{x.quote_shipment_shipping_term}</td>
//                               </tr>
//                             ))}
//                         </tbody>
//                         : <tbody>
//                           <tr>
//                             <td></td>
//                             <td></td>
//                             <td></td>
//                             <td>No data available</td>
//                             <td></td>
//                             <td></td><td></td>

//                           </tr>
//                         </tbody>
//                       }
//                     </table>

//                   </Row>
//                       <Row id="table-styles">
//                     <div className="col-md-12" id='head'>
//                       <h4 className="heading"><span>Quote Product</span>
//                         <span>
//                           <Link to={"/quote-product/select/" + id}>
//                             <i className="fe fe-download-cloud me-2"></i>
//                               New
//                           </Link>
//                         </span>
//                         {/* <span><Popup trigger={<span className="button add-accountrecord"> <FaPlus/> New </span>} modal>

//     <form onSubmit={handleSubmittcf} className="add-accounts">
//      <div className='form-group'>
//      <label>TCF Record Type</label><br/>
//      <select name='account_record' className='form-control' onChange={handleChangetcf} value={formData.account_record}>
//        <option >--Select TCF Record Type--</option>
//        <option value='Buyer - Spot'>Buyer - Spot</option>
//        <option value='Buyer - Long Term'>Buyer - Long Term</option>
//        </select>
//        </div>
//        <input type='submit' className="btn btn-primary " value='Submit'/>

//        </form>
// </Popup></span> */}
//                       </h4>
//                       <div id='inner-heading'>

//                       </div>
//                       <hr></hr>
//                     </div>
//                     <table id='quote-product' className="">
//                       <thead>
//                         <tr>
//                           <th>Quote Product Name	</th>
//                           <th>Quote Shipment	</th>
//                           <th>Gross As Received Typical	</th>
//                           <th>Ash Content Typical	</th>
//                           <th>Total Moisture Typical	</th>
//                           <th>Total Sulphur Typical ARB
//                           </th>
//                         </tr>
//                       </thead>

//                       {quoteProduct.length > 0 ?

//                         <tbody>
//                           {
//                             quoteProduct.map((y, i) => (
//                               <tr>

//                                 <td><span id='codes'><Link to={'/opportunity/quote-detail/' + y.id}>{y.quotes_name}</Link></span></td>
//                                 <td>{y.quotes_shippment}</td>
//                                 <td>{y.gross_as_received_typical	}</td>
//                                 <td>{y.ash_content_typical	}</td>
//                                 <td>{y.total_moisture_typical	}</td>
//                                 <td>{y.total_sulphur_typical_arb
// 	}</td>
//                               </tr>
//                             ))}
//                         </tbody>
//                         : <tbody>
//                           <tr>
//                             <td></td>
//                             <td></td>
//                             <td>No data available</td>
//                             <td></td>
//                             <td></td>
//                             <td></td>

//                           </tr>
//                         </tbody>
//                       }
//                     </table>

//                   </Row>
//                   <Row id="table-styles">
//      <div className="col-md-12" id="head">
//        <h4 className="heading">
//          <span>Quote Clauses
//          </span>
//          <span>
//            <i className=""></i> New Quote Clauses
//            </span>

//        </h4>
//        <hr>
//        </hr>
//      </div>
//      <table id="history" className="">
//        <tr>
//          <td>
//            <p class="no-records"> No records to display </p>
//          </td>
//        </tr>
//      </table>
//    </Row>
//    <Row id="table-styles">
//                     <div className="col-md-12" id='head'>
//                       <h4 className="heading"><span>Trade Confirmation Form</span>

//                       </h4>
//                       <div id='inner-heading'>

//                       </div>
//                       <hr></hr>
//                     </div>
//                     <table id='tcf' className="">
//                       <thead>
//                         <tr>
//                           <th>TCF No</th>
//                           <th>tcf status</th>
//                           <th>Buyer</th>
//                           <th>supplier</th>
//                           <th>Quantity (MT)	</th>
//                           <th>Contractual GAR (P)	</th>
//                           <th>Actual GAR (P)</th>
//                           <th>Actual GAR (S)</th>
//                         </tr>
//                       </thead>

//                       {tcf.length > 0 ?

//                         <tbody>
//                           {
//                             tcf.map((x, i) => (
//                               <tr>
//                                 <td><Link to={"/tcf/tcf-details/" + x.id}>{x.tcf_no}</Link></td>
//                                 <td>{x.tcf_status}</td>
//                                 <td><Link to={"/accounts/" + x.account_id}>{x.buyer}</Link></td>
//                                 <td><Link to={"/accounts/" + x.supplier_id}>{x.supplier}</Link></td>
//                                 <td>{x.quantity_MT}</td>
//                                 <td>{x.contractual_GAR_P}</td>
//                                 <td>{x.actual_gar_p}</td>
//                                 <td>{x.actual_gar_s}</td>
//                               </tr>
//                             ))}
//                         </tbody>
//                         : <tbody>
//                           <tr>
//                             <td></td>
//                             <td></td><td></td>
//                             <td></td>
//                             <td>No data available</td>
//                             <td></td>
//                             <td></td>
//                             <td></td>
//                           </tr>
//                         </tbody>
//                       }
//                     </table>

//                   </Row>

//    <Row id="table-styles">
//      <div className="col-md-12" id="head">
//        <h4 className="heading">
//          <span>Quote PDFs
//          </span>

//        </h4>
//        <hr>
//        </hr>
//      </div>
//      <table id="history" className="">
//        <tr>
//          <td>
//            <p class="no-records"> No records to display </p>
//          </td>
//        </tr>
//      </table>
//    </Row>
//    <Row id="table-styles">
//      <div className="col-md-12" id="head">
//        <h4 className="heading">
//          <span>Notes
//          </span>
//          <span>New Note
//          </span>
//        </h4>
//        <hr>
//        </hr>
//      </div>
//      <table id="history" className="">
//        <tr>
//          <td>
//            <p class="no-records"> No records to display </p>
//          </td>
//        </tr>
//      </table>
//    </Row>
//    <Row id="table-styles">
//      <div className="col-md-12" id="head">
//        <h4 className="heading">
//          <span>Files
//          </span>
//          <span>Upload Files         </span>
//        </h4>
//        <hr>
//        </hr>
//      </div>
//      <table id="history" className="">
//        <tr>
//          <td>
//            <p class="no-records"> No records to display </p>
//          </td>
//        </tr>
//      </table>
//    </Row>

//    <Row id="table-styles">
//      <div className="col-md-12" id="head">
//        <h4 className="heading">
//          <span>Open Activities

//          </span>
//          <span>New Task       </span>
//          <span>New Event       </span>
//        </h4>
//        <hr>
//        </hr>
//      </div>
//      <table id="history" className="">
//        <tr>
//          <td>
//            <p class="no-records"> No records to display </p>
//          </td>
//        </tr>
//      </table>
//    </Row>
//    <Row id="table-styles">
//      <div className="col-md-12" id="head">
//        <h4 className="heading">
//          <span>Notes & Attachments</span>
//          <span>
//             New Note </span>
//          <span>
//            Attach File </span>
//        </h4>
//        <hr>
//        </hr>
//      </div>
//      <table id="history" className="">
//        <tr>
//          <td>
//            <p class="no-records"> No records to display </p>
//          </td>
//        </tr>
//      </table>
//    </Row>
//    <Row id="table-styles">
//      <div className="col-md-12" id="head">
//        <h4 className="heading">
//          <span>Activity History
//          </span>
//          <span>
//             Log a Call </span>
//          <span>
//            Mail Merge </span>
//        </h4>
//        <hr>
//        </hr>
//      </div>
//      <table id="history" className="">
//        <tr>
//          <td>
//            <p class="no-records"> No records to display </p>
//          </td>
//        </tr>
//      </table>
//    </Row>

//    <Row id="table-styles">
//      <div className="col-md-12" id="head">
//        <h4 className="heading">
//          <span>Approval History

//          </span>
//          <span>
//             Submit for Approval</span>

//        </h4>
//        <hr>
//        </hr>
//      </div>
//      <table id="history" className="">
//        <tr>
//          <td>
//            <p class="no-records"> No records to display </p>
//          </td>
//        </tr>
//      </table>
//    </Row>
//                     </>
//                   )}</div>

//               ) : (

//                 <>
//                   {data1.quote_record_type === 'Supplier - Long Term' ? (
//                     <div className="tasks edit-view">
//  <div id="messageContainer"></div>
//                       {isEditMode ? (
//                         <>
//                           <p className='edit-btn'>
//                             <input
//                               type="submit"
//                               className="account-save"
//                               onClick={handleSaveEdit}
//                               // //value={isSubmitted ? "Saving" : "Save"}
//                              value="Save"
//                             />
//                             <button className="btn btn-primary" onClick={handleCancelEdit}>Cancel</button>
//                           </p>
//                           <table class="table table-bordered account-table tables">
//                             <tbody>
//                               <tr>
//                                 <td>
//                                   <span>Quote Number</span>
//                                   <br />
//                                   <input
//                                     type="text" className='form-control'
//                                     name="quote_number"
//                                     disabled
//                                     defaultValue={data1.quote_number || ''}
//                                     onChange={handleChange}
//                                   />
//                                 </td>
//                                 <td>
//                                   <span>Quote Record Type</span>
//                                   <br />
//                                   <input
//                                     type="text" className='form-control'
//                                     name="quote_record_type"
//                                     disabled
//                                     defaultValue={data1.quote_record_type || ''}
//                                     onChange={handleChange}
//                                   />
//                                 </td>

//                               </tr>
//                               <tr>
//                                 <td>
//                                   <span>Currency</span>
//                                   <br />

//                                   <select name="currency"
//                                     defaultValue={data1.currency || ''}
//                                     onChange={handleChange} className="form-control">
//                                     <option defaultValue='' >---None---</option>
//                                     <option defaultValue='USD' >USD</option>
//                                     <option defaultValue='IDR' >IDR</option>
//                                   </select>
//                                 </td>
//                                 <td></td>
//                               </tr>
//                               <tr>
//                                 <td>
//                                   <span>Exchange Rate</span>
//                                   <br />
//                                   <input
//                                     type="text" className='form-control'
//                                     name="exchange_rate"
//                                     defaultValue={data1.exchange_rate || ''}
//                                     onChange={handleChange}
//                                   />
//                                 </td>
//                                 <td></td>
//                               </tr>
//                               <tr>
//                                 <td>
//                                   <span>Quote Name</span>
//                                   <br />
//                                   <input
//                                     type="text" className='form-control'
//                                     name="quote_name"
//                                     defaultValue={data1.quote_name || ''}
//                                     onChange={handleChange}
//                                   />
//                                 </td>
//                                 <td>
//                                   <span>Status</span>
//                                   <br />
//                                   <select
//                                     name="status"
//                                     className='form-control'
//                                     defaultValue={data1.status || ''}
//                                     onChange={handleChange}
//                                   >
//                                     <option value='Offered'>Offered</option>
//                                     <option value='Accepted'>Accepted</option>
//                                     <option value='Rejected'>Rejected</option>
//                                     <option value='Sold'>Sold</option>
//                                   </select>
//                                 </td>

//                               </tr>
//                               <tr>
//                                 <td>
//                                   <span>Opportunity Name</span>
//                                   <br />

//                                   <select className='form-control'
//                                     name="opportunity_name"
//                                     defaultValue={data1.opportunity_name || ''}>
//                                     <option value='' >Select</option>
//                                     {
//                                       opp.map((x) => {
//                                         return (
//                                           <option value={x.opportunity_name}>{x.opportunity_name}</option>
//                                         )
//                                       })
//                                     }
//                                   </select>
//                                 </td>
//                                 <td>
//                                   <span>Supplier</span>
//                                   <br />
//                                   <input
//                                     type="text" className='form-control'
//                                     name="supplier"
//                                     defaultValue={data1.supplier || ''}
//                                     onChange={handleChange}
//                                   />
//                                 </td>


//                               </tr>
//                               <tr>
//                                 <td>
//                                   <span>Account Name</span>
//                                   <br />
//                                   <input
//                                     type="text" className='form-control record-type'
//                                     name="account_name"
//                                     disabled
//                                     defaultValue={data1.account_name || ''}
//                                     onChange={handleChange}
//                                   />
//                                 </td>
//                                 <td>
//                                   <span>Line Items</span>
//                                   <br />
//                                   <input
//                                     type="text" className='form-control record-type'
//                                     name="line_items"
//                                     disabled
//                                     defaultValue={data1.line_items || ''}
//                                     onChange={handleChange}
//                                   />
//                                 </td>
//                               </tr>
//                               <tr>
//                                 <td>
//                                   <span>quote to name</span>
//                                   <br />
//                                   <input
//                                     type="text" className='form-control'
//                                     name="quote_to_name"
//                                     defaultValue={data1.quote_to_name || ''}
//                                     onChange={handleChange}
//                                   />
//                                 </td>
//                                 <td></td>
//                               </tr>
//                               <tr>
//                                 <td>
//                                   <span>Quote Date</span>
//                                   <br />
//                                   <DatePicker
//                                     selected={start}
//                                     onChange={(date) => setStart(date)}
//                                     customInput={<ExampleCustomInput2 />}
//                                     dayClassName={() => "example-datepicker-day-class"}
//                                     popperClassName="edit-date"
//                                     todayButton="TODAY"
//                                     dateFormat="yyyy-MM-dd"
//                                     placeholderText="Choose Date"
//                                   />
//                                 </td>
//                                 <td></td>
//                               </tr>
//                               <tr>
//                                 <td>
//                                   <span>Expiration Date</span>
//                                   <br />

//                                   <DatePicker
//                                     selected={effective}
//                                     onChange={(date) => setEffective(date)}
//                                     customInput={<ExampleCustomInput1 />}
//                                     dayClassName={() => "example-datepicker-day-class"}
//                                     popperClassName="edit-date"
//                                     todayButton="TODAY"
//                                     dateFormat="yyyy-MM-dd"
//                                     placeholderText="Choose Date"
//                                   />
//                                 </td>
//                                 <td></td>
//                               </tr>

//                             </tbody>
//                           </table>
//                           <div className="col-md-12" id='ax.account_details'>
//                             <h4 className="heading">Product information</h4>
//                             <hr></hr>
//                           </div>
//                           <table class="table table-bordered account-table tables">
//                             <tbody>
//                               <tr>
//                                 <td>
//                                   <span>Origin</span>
//                                   <br />
//                                   <input
//                                     type="text" className='form-control'
//                                     name="origin"
//                                     defaultValue={data1.origin || ''}
//                                     onChange={handleChange}
//                                   />
//                                 </td>
//                                 <td>
//                                   <span>Quantity MT</span>
//                                   <br />
//                                   <input
//                                     type="text" className='form-control'
//                                     name="quantity_MT"
//                                     defaultValue={data1.quantity_MT || ''}
//                                     onChange={handleChange}
//                                   />
//                                 </td>
//                               </tr>
//                               <tr>
//                                 <td>
//                                   <span>Commodity</span>
//                                   <br />
//                                   <select className='form-control'
//                                     name="commodity"
//                                     defaultValue={data1.commodity || ''}
//                                     onChange={handleChange}>
//                                     <option>---None---</option>
//                                     <option defaultValue="Indonesian Steam Coal in Bulk (&quot;Coal&quot;)">Indonesian Steam Coal in Bulk ("Coal")</option><option defaultValue="US Coal">US Coal</option><option defaultValue="Russia Coal">Russia Coal</option><option defaultValue="SA Coal">SA Coal</option><option defaultValue="Clinker in Bulk">Clinker in Bulk</option><option defaultValue="Australian Steam Coal">Australian Steam Coal</option>
//                                   </select>
//                                 </td>
//                                 <td>
//                                   <span>Product Description</span>
//                                   <br />
//                                   <input
//                                     type="text" className='form-control'
//                                     name="product_description"
//                                     defaultValue={data1.product_description || ''}
//                                     onChange={handleChange}
//                                   />
//                                 </td>

//                               </tr>
//                               <tr>
//                                 <td>
//                                   <span>Contract</span>
//                                   <br />
//                                   <input
//                                     type="text" className='form-control'
//                                     name="contract"
//                                     defaultValue={data1.contract || ''}
//                                     onChange={handleChange}
//                                   />
//                                 </td>
//                                 <td></td>
//                               </tr>
//                             </tbody>
//                           </table>
//                           <div className="col-md-12" id='ax.account_details'>
//                             <h4 className="heading">Market Information</h4>
//                             <hr></hr>
//                           </div>
//                           <table class="table table-bordered account-table tables">
//                             <tbody>

//                               <tr>
//                                 <td>
//                                   <span>Destination</span>
//                                   <br />

//                                   <input
//                                     type="text" className='form-control'
//                                     name="destination"
//                                     defaultValue={data1.destination || ''}
//                                     onChange={handleChange}
//                                   />
//                                 </td>
//                                 <td></td>
//                               </tr>
//                             </tbody>
//                           </table>
//                           <div className="col-md-12" id='ax.account_details'>
//                             <h4 className="heading">Price and Price Adjustment</h4>
//                             <hr></hr>
//                           </div>
//                           <table class="table table-bordered account-table tables">
//                             <tbody>
//                               <tr>
//                                 <td>
//                                   <span>Bonus/Penalty Clause</span>
//                                   <br />

//                                   <textarea className='form-control'
//                                     name="bonus_penalty_clause"
//                                     defaultValue={data1.bonus_penalty_clause || ''}
//                                     onChange={handleChange}>

//                                   </textarea>

//                                 </td>
//                                 <td>
//                                   <span>Rejections</span>
//                                   <br />

//                                   <textarea className='form-control'
//                                     name="rejections"
//                                     defaultValue={data1.rejections || ''}
//                                     onChange={handleChange}>
//                                   </textarea>
//                                 </td>

//                               </tr>
//                               <tr >
//                                 <td>
//                                   <span>Payment Term</span>
//                                   <br />


//                                   <textarea className='form-control'
//                                     name="payment_terms"
//                                     defaultValue={data1.payment_terms || ''}
//                                     onChange={handleChange}>
//                                   </textarea>
//                                 </td>
//                                 <td>
//                                   <span>General Terms</span>
//                                   <br />


//                                   <textarea className='form-control'
//                                     name="general_terms"
//                                     defaultValue={data1.general_terms || ''}
//                                     onChange={handleChange}>
//                                   </textarea>
//                                 </td>

//                               </tr>
//                             </tbody>
//                           </table>
//                           <div className="col-md-12" id='ax.account_details'>
//                             <h4 className="heading">Terms & Conditions</h4>
//                             <hr></hr>
//                           </div>
//                           <table class="table table-bordered account-table tables">
//                             <tbody>
//                               <tr>
//                                 <td>
//                                   <span>Arbitration</span>
//                                   <br />

//                                   <select className='form-control'
//                                     name="arbitration"
//                                     defaultValue={data1.arbitration || ''}
//                                     onChange={handleChange}>
//                                     <option value="">--None--</option><option value="In Singapore as per SIAC">In Singapore as per SIAC</option><option value="Singapore Chamber of Maritime Arbitration (SCMA)">Singapore Chamber of Maritime Arbitration (SCMA)</option><option value="Laws of England and Wales">Laws of England and Wales</option><option value="Rules of Badan Arbitrase Nasional Indonesia (BANI)">Rules of Badan Arbitrase Nasional Indonesia (BANI)</option>
//                                   </select>
//                                 </td>
//                                 <td>
//                                   <span>Rwood Surveyor Agent</span>
//                                   <br />
//                                   <input
//                                     type="text" className='form-control'
//                                     name="rwood_surveyor_agent"
//                                     defaultValue={data1.rwood_surveyor_agent || ''}
//                                     onChange={handleChange}
//                                   />
//                                 </td>

//                               </tr>
//                               <tr>
//                                 <td>
//                                   <span>Applicable Law</span>
//                                   <br />

//                                   <select className='form-control'
//                                     name="applicable_law"
//                                     defaultValue={data1.applicable_law || ''}
//                                     onChange={handleChange}>
//                                     <option value="">--None--</option><option value="English Law">English Law</option><option value="Singapore Law">Singapore Law</option><option value="Indonesian Law">Indonesian Law</option>
//                                   </select>
//                                 </td>
//                                 <td>
//                                   <span>Surveyor 2	</span>
//                                   <br />

//                                   <select className='form-control'
//                                     name="surveyor_2"
//                                     defaultValue={data1.surveyor_2 || ''}
//                                     onChange={handleChange}>
//                                     <option>---None---</option>
//                                     {surveyors.map((x) => {
//                                       return (
//                                         <option value={x.account_name}>{x.account_name}</option>
//                                       );
//                                     })}
//                                   </select>
//                                 </td>
//                               </tr>
//                               <tr>
//                                 <td>
//                                   <span>Offer Validity Terms</span>
//                                   <br />

//                                   <textarea className='form-control'
//                                     name="offer_validity_terms"
//                                     defaultValue={data1.offer_validity_terms || ''}
//                                     onChange={handleChange}>

//                                   </textarea>
//                                 </td>
//                                 <td>
//                                   <span>Surveyor 3</span>
//                                   <br />

//                                   <select className='form-control'
//                                     name="suveyor_3"
//                                     defaultValue={data1.suveyor_3 || ''}
//                                     onChange={handleChange}>
//                                     <option>---None---</option>
//                                     {surveyors.map((x) => {
//                                       return (
//                                         <option value={x.account_name}>{x.account_name}</option>
//                                       );
//                                     })}
//                                   </select>
//                                 </td>

//                               </tr>
//                               <tr>
//                                 <td>
//                                   <span>Description</span>
//                                   <br />


//                                   <textarea className='form-control'
//                                     name="description"
//                                     defaultValue={data1.description || ''}
//                                     onChange={handleChange}>

//                                   </textarea>
//                                 </td>
//                                 <td>

//                                 </td>

//                               </tr>
//                             </tbody>
//                           </table>

//                           <div className="col-md-12" id='ax.account_details'>
//                             <h4 className="heading">Prepared For</h4>
//                             <hr></hr>
//                           </div>
//                           <table class="table table-bordered account-table tables">
//                             <tbody>
//                               <tr>
//                                 <td>
//                                   <span>Contact Name</span>
//                                   <br />
//                                   <input
//                                     type="text" className='form-control'
//                                     name="contact_name"
//                                     defaultValue={data1.contact_name || ''}
//                                     onChange={handleChange}
//                                   />
//                                 </td>
//                                 <td>
//                                   <span>Additional To Name</span>
//                                   <br />
//                                   <input
//                                     type="text" className='form-control'
//                                     name="additional_to_name"
//                                     defaultValue={data1.additional_to_name || ''}
//                                     onChange={handleChange}
//                                   />
//                                 </td>

//                               </tr>
//                               <tr>

//                                 <td>
//                                   <span>quote to street</span>
//                                   <br />
//                                   <input
//                                     type="text" className='form-control'
//                                     name="quote_to_street"
//                                     defaultValue={data1.quote_to_street || ''}
//                                     onChange={handleChange}
//                                   />
//                                 </td>
//                                 <td>
//                                   <span>additional to street</span>
//                                   <br />
//                                   {/* {data1.additional_to} */}
//                                   <input
//                                     type="text" className='form-control'
//                                     name="additional_to_street"
//                                     defaultValue={data1.additional_to_street || ''}
//                                     onChange={handleChange}
//                                   />
//                                 </td>
//                               </tr>
//                               <tr>

//                                 <td>
//                                   <span>Quote To Zip/Postal Code	</span>
//                                   <br />
//                                   {/* {data1.phone} */}
//                                   <input
//                                     type="text" className='form-control'
//                                     name="quote_to_zip_postal_code"
//                                     defaultValue={data1.quote_to_zip_postal_code || ''}
//                                     onChange={handleChange}
//                                   />
//                                 </td>
//                                 <td>
//                                   <span>Additional To Zip/Postal Code	</span>
//                                   <br />
//                                   {/* {data1.phone_fax} */}
//                                   <input
//                                     type="text" className='form-control'
//                                     name="additional_to_zip_postal_code"
//                                     defaultValue={data1.additional_to_zip_postal_code || ''}
//                                     onChange={handleChange}
//                                   />
//                                 </td>
//                               </tr>
//                               <tr>

//                                 <td>
//                                   <span>quote to city	</span>
//                                   <br />
//                                   {/* {data1.phone_fax} */}
//                                   <input
//                                     type="text" className='form-control'
//                                     name="quote_to_city"
//                                     defaultValue={data1.quote_to_city || ''}
//                                     onChange={handleChange}
//                                   />
//                                 </td>
//                                 <td>
//                                   <span>Additional To City		</span>
//                                   <br />
//                                   {/* {data1.phone_fax} */}
//                                   <input
//                                     type="text" className='form-control'
//                                     name="additional_to_city"
//                                     defaultValue={data1.additional_to_city || ''}
//                                     onChange={handleChange}
//                                   />
//                                 </td>
//                               </tr>
//                               <tr>

//                                 <td>
//                                   <span>Quote To State/Province	</span>
//                                   <br />
//                                   {/* {data1.phone_fax} */}
//                                   <input
//                                     type="text" className='form-control'
//                                     name="quote_to_state_province"
//                                     defaultValue={data1.quote_to_state_province || ''}
//                                     onChange={handleChange}
//                                   />
//                                 </td>

//                                 <td>
//                                   <span>Additional To State/Province		</span>
//                                   <br />
//                                   {/* {data1.phone_fax} */}
//                                   <input
//                                     type="text" className='form-control'
//                                     name="additional_to_state_province"
//                                     defaultValue={data1.additional_to_state_province || ''}
//                                     onChange={handleChange}
//                                   />
//                                 </td>
//                               </tr>
//                               <tr>


//                                 <td>
//                                   <span>Quote To Country	</span>
//                                   <br />
//                                   {/* {data1.phone_fax} */}
//                                   <input
//                                     type="text" className='form-control'
//                                     name="quote_to_country"
//                                     defaultValue={data1.quote_to_country || ''}
//                                     onChange={handleChange}
//                                   />
//                                 </td>
//                                 <td>
//                                   <span>Additional To Country	</span>
//                                   <br />
//                                   {/* {data1.phone_fax} */}
//                                   <input
//                                     type="text" className='form-control'
//                                     name="additional_to_country"
//                                     defaultValue={data1.additional_to_country || ''}
//                                     onChange={handleChange}
//                                   />
//                                 </td>
//                               </tr>
//                               <tr>



//                               </tr>
//                               <tr>
//                                 <td>
//                                   <span>Email</span>
//                                   <br />
//                                   {/* {data1.phone_fax} */}
//                                   <input
//                                     type="text" className='form-control'
//                                     name="email"
//                                     defaultValue={data1.email || ''}
//                                     onChange={handleChange}
//                                   />
//                                 </td>
//                                 <td>

//                                 </td>

//                               </tr>
//                               <tr>
//                                 <td>
//                                   <span>Phone</span>
//                                   <br />
//                                   {/* {data1.phone_fax} */}
//                                   <input
//                                     type="text" className='form-control'
//                                     name="phone"
//                                     defaultValue={data1.phone || ''}
//                                     onChange={handleChange}
//                                   />
//                                 </td>
//                                 <td>

//                                 </td>

//                               </tr>
//                               <tr>
//                                 <td>
//                                   <span>Fax</span>
//                                   <br />
//                                   {/* {data1.phone_fax} */}
//                                   <input
//                                     type="text" className='form-control'
//                                     name="fax"
//                                     defaultValue={data1.fax || ''}
//                                     onChange={handleChange}
//                                   />
//                                 </td>
//                                 <td>

//                                 </td>

//                               </tr>
//                             </tbody>
//                           </table>

//                           <div className="col-md-12" id='ax.account_details'>
//                             <h4 className="heading">Address Information</h4>
//                             <hr></hr>
//                           </div>
//                           <table class="table table-bordered account-table tables">
//                             <tbody>
//                               <tr>
//                                 <td>
//                                   <span>Bill To Name</span>
//                                   <br />
//                                   {/* {data1.bill_to_name} */}
//                                   <input
//                                     type="text" className='form-control'
//                                     name="bill_to_name"
//                                     defaultValue={data1.bill_to_name || ''}
//                                     onChange={handleChange}
//                                   />
//                                 </td>
//                                 <td>
//                                   <span>Ship To Name</span>
//                                   <br />
//                                   {/* {data1.ship_to_name} */}
//                                   <input
//                                     type="text" className='form-control'
//                                     name="ship_to_name"
//                                     defaultValue={data1.ship_to_name || ''}
//                                     onChange={handleChange}
//                                   />
//                                 </td>

//                               </tr>
//                               <tr>
//                                 <td>
//                                   <span>bill to street</span>
//                                   <br />
//                                   {/* {data1.bill_to} */}
//                                   <input
//                                     type="text" className='form-control'
//                                     name="bill_to_street"
//                                     defaultValue={data1.bill_to_street || ''}
//                                     onChange={handleChange}
//                                   />
//                                 </td>
//                                 <td>
//                                   <span>Ship To Street</span>
//                                   <br />
//                                   <input
//                                     type="text" className='form-control'
//                                     name="ship_to_street"
//                                     defaultValue={data1.ship_to_street || ''}
//                                     onChange={handleChange}
//                                   />
//                                 </td>

//                               </tr>
//                               <tr>
//                                 <td>
//                                   <span>Bill To Zip/Postal Code	</span>
//                                   <br />
//                                   {/* {data1.bill_to} */}
//                                   <input
//                                     type="text" className='form-control'
//                                     name="bill_to_zip_postal_code"
//                                     defaultValue={data1.bill_to_zip_postal_code || ''}
//                                     onChange={handleChange}
//                                   />
//                                 </td>
//                                 <td>
//                                   <span>Ship To Zip/Postal Code	</span>
//                                   <br />
//                                   <input
//                                     type="text" className='form-control'
//                                     name="ship_to_zip_postal_code"
//                                     defaultValue={data1.ship_to_zip_postal_code || ''}
//                                     onChange={handleChange}
//                                   />
//                                 </td>

//                               </tr>
//                               <tr>
//                                 <td>
//                                   <span>Bill To City	</span>
//                                   <br />
//                                   {/* {data1.bill_to} */}
//                                   <input
//                                     type="text" className='form-control'
//                                     name="bill_to_city"
//                                     defaultValue={data1.bill_to_city || ''}
//                                     onChange={handleChange}
//                                   />
//                                 </td>
//                                 <td>
//                                   <span>Ship To City	</span>
//                                   <br />
//                                   <input
//                                     type="text" className='form-control'
//                                     name="ship_to_city"
//                                     defaultValue={data1.ship_to_city || ''}
//                                     onChange={handleChange}
//                                   />
//                                 </td>

//                               </tr>
//                               <tr>
//                                 <td>
//                                   <span>Bill To State/Province	</span>
//                                   <br />
//                                   {/* {data1.bill_to} */}
//                                   <input
//                                     type="text" className='form-control'
//                                     name="bill_to_state_province"
//                                     defaultValue={data1.bill_to_state_province || ''}
//                                     onChange={handleChange}
//                                   />
//                                 </td>
//                                 <td>
//                                   <span>Ship To State/Province	</span>
//                                   <br />
//                                   <input
//                                     type="text" className='form-control'
//                                     name="ship_to_state_province"
//                                     defaultValue={data1.ship_to_state_province || ''}
//                                     onChange={handleChange}
//                                   />
//                                 </td>

//                               </tr>

//                               <tr>
//                                 <td>
//                                   <span>Bill To Country	</span>
//                                   <br />
//                                   {/* {data1.bill_to} */}
//                                   <input
//                                     type="text" className='form-control'
//                                     name="bill_to_country"
//                                     defaultValue={data1.bill_to_country || ''}
//                                     onChange={handleChange}
//                                   />
//                                 </td>
//                                 <td>
//                                   <span>Ship To Country	</span>
//                                   <br />
//                                   <input
//                                     type="text" className='form-control'
//                                     name="ship_to_country"
//                                     defaultValue={data1.ship_to_country || ''}
//                                     onChange={handleChange}
//                                   />
//                                 </td>

//                               </tr>
//                             </tbody>
//                           </table>
//                           {/* <div className="col-md-12" id='ax.account_details'>
//      <h4 className="heading">System Information</h4>
//      <hr></hr>
//      </div>
//           <table class="table table-bordered account-table tables">
//            <tbody>
//             <tr>
//             <td>
//                   <span>Created By</span>
//                   <br />
//                   <input
//                 type="text" className='form-control'
//                 name="created_by"
//                 defaultValue={data1.created_by || ''}
//                 onChange={handleChange}
//               />
//                 </td>
//                 <td>
//                   <span>Modified By</span>
//                   <br />
//                   <input
//                 type="text" className='form-control'
//                 name="modified_by"
//                 defaultValue={data1.modified_by || ''}
//                 onChange={handleChange}
//               />
//                 </td>
//             </tr>

//             </tbody>
//           </table> */}
//                           <p className='edit-btn'>
//                             <input
//                               type="submit"
//                               className="account-save"
//                               onClick={handleSaveEdit}
//                               //value={isSubmitted ? "Saving" : "Save"}
//                              value="Save"
//                             />
//                             <button className="btn btn-primary" onClick={handleCancelEdit}>Cancel</button>
//                           </p>

//                         </>

//                       ) : (
//                         <>
//                           <div className="edit-delete">
//                             <span id="edit" onClick={handleEditClick}>
//                               Edit
//                             </span>
//                             <span>
//                               <Link to={`/opportunity/opportunity-detail/${data1.opportunity_id}`}> Cancel</Link>
//                             </span>
//                             {data1.status === 'Accepted' ? (
//                               <span onClick={createContract}>Create Contract</span>
//                             ) : (
//                               <></>
//                             )}
//                           </div>

//                           <div className="col-md-12" id='ax.account_details'>
//                             <h4 className="heading">Quote Detail</h4>
//                             <hr></hr>
//                           </div>

//                           <table class="table table-bordered account-table tables">
//                             <tbody>
//                               <tr>
//                                 <td id="td-right">
//                                   <span>Quote Number</span>
//                                 </td><td>
//                                   {data1.quote_number}
//                                 </td>
//                                 <td id="td-right">
//                                   <span>Quote Record Type</span>
//                                 </td>
//                                 <td>
//                                   {data1.quote_record_type}
//                                 </td>

//                               </tr>
//                               <tr>
//                                 <td id="td-right">
//                                   <span>Currency</span>
//                                 </td><td>
//                                   {data1.currency}
//                                 </td>
//                                 <td id="td-right">
//                                   <span>Locked</span>
//                                 </td><td>
//                                   <input
//                                     type='checkbox'
//                                     name='locked'
//                                     checked={lockedOne} // Use the state variable to determine if the checkbox is checked
//                                     onChange={handleCheckboxLocked} // Handle the change event
//                                     className='mx-3' disabled={isButtonEnabled}
//                                   />
//                                 </td>
//                               </tr>
//                               <tr>
//                                 <td id="td-right">
//                                   <span>Exchange Rate</span>
//                                 </td><td>
//                                   {data1.exchange_rate}
//                                 </td>
//                                 <td id="td-right">
//                                   {/* <span>quote name</span> */}
//                                 </td><td>
//                                   {/* {data1.quote_name} */}
//                                 </td>
//                               </tr><tr>
//                                 <td id="td-right">
//                                   <span>Quote Name</span>
//                                 </td><td>
//                                   {data1.quote_name}
//                                 </td>
//                                 <td id="td-right">
//                                   <span>Status</span>
//                                 </td><td>
//                                   {data1.status || 'Offered'}
//                                 </td>
//                               </tr>
//                               <tr>

//                               </tr>
//                               <tr>
//                                 <td id="td-right">
//                                   <span>opportunity Name</span>
//                                 </td><td>
//                                   <Link to={`/opportunity/opportunity-detail/${data1.opportunity_id}`}>{data1.opportunity_name}</Link>
//                                 </td>
//                                 <td id="td-right">
//                                   <span>Supplier</span>
//                                 </td><td>
//                                   <Link to={`/accounts/${data1.supplier_id}`}>{data1.supplier}</Link>
//                                 </td>
//                               </tr>
//                               <tr>
//                                 <td id="td-right">
//                                   <span>Account Name</span>
//                                 </td><td>
//                                   <Link to={`/accounts/${data1.account_id}`}>{data1.account_name}</Link>
//                                 </td>

//                                 <td id="td-right">
//                                   <span>line items</span>
//                                 </td><td>
//                                   {data1.line_items}
//                                 </td>

//                               </tr>
//                               <tr>
//                                 <td id="td-right">
//                                   <span>quote to name</span>
//                                 </td><td>
//                                   {data1.quote_to_name}
//                                 </td>
//                                 <td id="td-right">
//                                   <span>Trader Name</span>
//                                 </td><td>
//                                   <Link to={'/user/user-details/' + data1.trader_id}> {data1.trader_name}</Link>
//                                 </td>


//                               </tr>

//                               <tr>
//                                 <td id="td-right">
//                                   <span>Quote Date</span>
//                                 </td><td>
//                                   {data1.quote_date}
//                                 </td>

//                                 <td></td>
//                               </tr>
//                               <tr>
//                                 <td id="td-right">
//                                   <span>Expiration Date</span>
//                                 </td><td>
//                                   {data1.expiration_date}
//                                 </td>
//                                 <td id="td-right">
//                                   <span>Trader Name</span>
//                                 </td><td>
//                                   <Link to={'/user/user-details/' + data1.trader_id}> {data1.trader_name}</Link>
//                                 </td>


//                               </tr>
//                             </tbody>
//                           </table>
//                           <div className="col-md-12" id='ax.account_details'>
//                             <h4 className="heading">Product information</h4>
//                             <hr></hr>
//                           </div>
//                           <table class="table table-bordered account-table tables">
//                             <tbody>
//                               <tr>
//                                 <td id="td-right">
//                                   <span>origin
//                                   </span>
//                                 </td><td>
//                                   {data1.origin}
//                                 </td>
//                                 <td id="td-right">
//                                   <span>Quantity MT
//                                   </span>
//                                 </td><td>
//                                   {data1.quantity_MT}
//                                 </td>
//                               </tr>
//                               <tr>
//                                 <td id="td-right">
//                                   <span>Commodity</span>
//                                 </td><td>
//                                   {data1.commodity}
//                                 </td>
//                                 <td id="td-right">
//                                   <span>Product Description	</span>
//                                 </td><td>
//                                   {data1.product_description}
//                                 </td>

//                               </tr>
//                               <tr>
//                                 <td id="td-right">
//                                   <span>Contract</span>
//                                 </td><td>
//                                 <Link to={'/contract/contract-details/' + data1.contractId}> {data1.contract}</Link>
//                                 </td>
//                                 <td id="td-right">
//                                   {/* <span>Contract</span> */}
//                                 </td><td>
//                                   {/* {data1.contract} */}
//                                 </td>

//                               </tr>

//                             </tbody>
//                           </table>


//                           <div className="col-md-12" id='ax.account_details'>
//                             <h4 className="heading">Market Information</h4>
//                             <hr></hr>
//                           </div>
//                           <table class="table table-bordered account-table tables">
//                             <tbody>
//                               <tr>
//                                 <td id="td-right">
//                                   <span>destination</span>
//                                 </td><td>
//                                   {data1.destination}
//                                 </td>

//                                 <td id="td-right">
//                                   {/* <span>Commodity</span> */}
//                                 </td><td>
//                                   {/* {data1.commodity} */}
//                                 </td>

//                               </tr>


//                             </tbody>
//                           </table>
//                           <div className="col-md-12" id='ax.account_details'>
//                             <h4 className="heading">Price and Price Adjustment</h4>
//                             <hr></hr>
//                           </div>
//                           <table class="table table-bordered account-table tables">
//                             <tbody>
//                               <tr>
//                                 <td id="td-right">
//                                   <span>Bonus/Penalty Clause</span>
//                                 </td><td>
//                                   {data1.bonus_penalty_clause}
//                                 </td>
//                                 <td id="td-right">
//                                   {/* <span>Rejections</span> */}
//                                 </td><td>
//                                   {/* {data1.rejections} */}
//                                 </td>

//                               </tr>
//                               <tr>
//                                 <td id="td-right">
//                                   <span>Rejections</span>
//                                 </td><td>
//                                   {data1.rejections}
//                                 </td>
//                                 <td id="td-right">
//                                   {/* <span>Payment Term</span> */}
//                                 </td><td>
//                                   {/* {data1.payment_terms} */}
//                                 </td>


//                               </tr>
//                               <tr>
//                                 <td id="td-right">
//                                   <span>Payment Term</span>
//                                 </td><td>
//                                   {data1.payment_terms}
//                                 </td>
//                                 <td id="td-right">
//                                   {/* <span>General Terms</span> */}
//                                 </td><td>
//                                   {/* {data1.general_terms} */}
//                                 </td>
//                               </tr>
//                               <tr>
//                                 <td id="td-right">
//                                   <span>General Terms</span>
//                                 </td><td>
//                                   {data1.general_terms}
//                                 </td>
//                                 <td id="td-right">
//                                   {/* <span>Payment Term</span> */}
//                                 </td><td>
//                                   {/* {data1.payment_terms} */}
//                                 </td>

//                               </tr>
//                             </tbody>
//                           </table>
//                           <div className="col-md-12" id='ax.account_details'>
//                             <h4 className="heading">Terms & Conditions</h4>
//                             <hr></hr>
//                           </div>
//                           <table class="table table-bordered account-table tables">
//                             <tbody>
//                               <tr>
//                                 <td id="td-right">
//                                   <span>Arbitration</span>
//                                 </td><td>
//                                   {data1.arbitration}
//                                 </td>
//                                 <td id="td-right">
//                                   <span>Rwood Surveyor Agent</span>
//                                 </td><td>
//                                   {data1.rwood_surveyor_agent}
//                                 </td>

//                               </tr>
//                               <tr>
//                                 <td id="td-right">
//                                   <span>Applicable Law</span>
//                                 </td><td>
//                                   {data1.applicable_law}
//                                 </td>
//                                 <td id="td-right">
//                                   <span>Surveyor 2	</span>
//                                 </td><td>
//                                   {data1.surveyor_2}
//                                 </td>
//                               </tr>
//                               <tr>
//                                 <td id="td-right">
//                                   <span>Offer Validity Terms</span>
//                                 </td><td>
//                                   {data1.offer_validity_terms}
//                                 </td>
//                                 <td id="td-right">
//                                   <span>Surveyor 3</span>
//                                 </td><td>
//                                   {data1.suveyor_3}
//                                 </td>
//                               </tr>
//                               <tr>
//                                 <td id="td-right">
//                                   <span>Description</span>
//                                 </td><td>
//                                   {data1.description}
//                                 </td>
//                                 <td id="td-right">
//                                   {/* <span>Surveyor 3</span> */}
//                                 </td><td>
//                                   {/* {data1.suveyor_3} */}
//                                 </td>
//                               </tr>
//                             </tbody>
//                           </table>

//                           <div className="col-md-12" id='ax.account_details'>
//                             <h4 className="heading">Prepared For</h4>
//                             <hr></hr>
//                           </div>
//                           <table class="table table-bordered account-table tables">
//                             <tbody>


//                               <tr>

//                                 <td id="td-right">
//                                   <span>Contact Name</span>
//                                 </td><td>
//                                   {data1.contact_name}
//                                 </td>
//                                 <td id="td-right">
//                                   <span>additional to name</span>
//                                 </td><td>
//                                   {data1.additional_to_name}
//                                 </td>

//                               </tr>
//                               <tr>

//                                 <td id="td-right">
//                                   <span>quote to name</span>
//                                 </td><td>
//                                   {data1.quote_to_name}
//                                 </td>
//                                 <td id="td-right">
//                                   <span>additional to</span>
//                                 </td><td>
//                                   {data1.additional_to}
//                                 </td>

//                               </tr>

//                               <tr>

//                                 <td id="td-right">
//                                   <span>Email</span>
//                                 </td><td>
//                                   {data1.email}
//                                 </td>
//                                 <td id="td-right">
//                                   {/* <span>additional to name</span> */}
//                                 </td><td>
//                                   {/* {data1.additional_to_name} */}
//                                 </td>

//                               </tr>
//                               <tr>

//                                 <td id="td-right">
//                                   <span>Phone</span>
//                                 </td><td>
//                                   {data1.phone_fax}
//                                 </td>
//                                 <td id="td-right">
//                                   {/* <span>additional to name</span> */}
//                                 </td><td>
//                                   {/* {data1.additional_to_name} */}
//                                 </td>

//                               </tr>
//                               <tr>

//                                 <td id="td-right">
//                                   <span>Fax</span>
//                                 </td><td>
//                                   {data1.fax}
//                                 </td>
//                                 <td id="td-right">
//                                   {/* <span>additional to name</span> */}
//                                 </td><td>
//                                   {/* {data1.additional_to_name} */}
//                                 </td>

//                               </tr>
//                             </tbody>
//                           </table>

//                           <div className="col-md-12" id='ax.account_details'>
//                             <h4 className="heading">Address Information</h4>
//                             <hr></hr>
//                           </div>
//                           <table class="table table-bordered account-table tables">
//                             <tbody>
//                               <tr>
//                                 <td id="td-right">
//                                   <span>bill to name</span>
//                                 </td><td>
//                                   {data1.bill_to_name}
//                                 </td>
//                                 <td id="td-right">
//                                   <span>ship to name</span>
//                                 </td><td>
//                                   {data1.ship_to_name}
//                                 </td>

//                               </tr>
//                               {/* <tr>
//             <td id="td-right">
//                   <span>bill to</span>
//                   </td><td>
//                   {data1.bill_to}
//                 </td>
//                 <td id="td-right">
//                   <span>ship to</span>
//                   </td><td>
//                   {data1.ship_to}
//                 </td>

//             </tr>
//                 */}
//                               <tr>
//                                 <td id="td-right">
//                                   <span>bill to country</span>
//                                 </td><td>
//                                   {data1.bill_to_country}
//                                 </td>
//                                 <td id="td-right">
//                                   <span>ship to country</span>
//                                 </td><td>
//                                   {data1.ship_to_country}
//                                 </td>

//                               </tr>
//                             </tbody>
//                           </table>
//                           {/* <div className="col-md-12" id='ax.account_details'>
//      <h4 className="heading">System Information</h4>
//      <hr></hr>
//      </div>
//           <table class="table table-bordered account-table tables">
//            <tbody>
//             <tr>
//             <td id="td-right">
//                   <span>Created By</span>
//                   </td><td>
//                   {data1.created_by}
//                 </td>
//                 <td id="td-right">
//                   <span>Modified By</span>
//                   </td><td>
//                   {data1.modified_by}
//                 </td>

//             </tr>
//              </tbody>
//           </table> */}


//  <Row id="table-styles">
//                     <div className="col-md-12" id='head'>
//                       <h4 className="heading"><span>Quote Shipment</span>
//                         {/* <span><Popup trigger={<span className="button add-accountrecord"> <FaPlus/> New </span>} modal>

//     <form onSubmit={handleSubmittcf} className="add-accounts">
//      <div className='form-group'>
//      <label>TCF Record Type</label><br/>
//      <select name='account_record' className='form-control' onChange={handleChangetcf} value={formData.account_record}>
//        <option >--Select TCF Record Type--</option>
//        <option value='Buyer - Spot'>Buyer - Spot</option>
//        <option value='Buyer - Long Term'>Buyer - Long Term</option>
//        </select>
//        </div>
//        <input type='submit' className="btn btn-primary " value='Submit'/>

//        </form>
// </Popup></span> */}
//                       </h4>
//                       <div id='inner-heading'>

//                       </div>
//                       <hr></hr>
//                     </div>
//                     <table id='quote' className="">
//                       <thead>
//                         <tr>
//                           <th>Quote Shipment Code</th>
//                           <th>Quantity</th>
//                           <th>Laycan</th>
//                           <th>Pricing</th>
//                           <th>Quanity Info	</th>
//                           <th>Vessel Type	</th>
//                           <th>Shipping Term
//                           </th>
//                         </tr>
//                       </thead>

//                       {quote.length > 0 ?

//                         <tbody>
//                           {
//                             quote.map((x, i) => (
//                               <tr>
//                                 <td><Link to={'/quote-shipment/detail/' + x.id}>{x.quote_shipment_code}</Link></td>
//                                 <td>{x.quote_shipment_quantity}</td>
//                                 <td>{x.quote_shipment_laycan}</td>
//                                 <td>{x.quote_shipment_pricing}</td>
//                                 <td>{x.quote_shipment_quantity_info
//                                 }</td>

//                                 <td>{x.quote_shipment_vessel_type
//                                 }</td>
//                                 <td>{x.quote_shipment_shipping_term
//                                 }</td>
//                               </tr>
//                             ))}
//                         </tbody>
//                         : <tbody>
//                           <tr>
//                             <td></td>
//                             <td></td>
//                             <td></td>
//                             <td>No data available</td>
//                             <td></td>
//                             <td></td><td></td>

//                           </tr>
//                         </tbody>
//                       }
//                     </table>

//                   </Row>
//                       <Row id="table-styles">
//                     <div className="col-md-12" id='head'>
//                       <h4 className="heading"><span>Quote Product</span>
//                         <span>
//                           <Link to={"/quote-product/select/" + id}>
//                             <i className="fe fe-download-cloud me-2"></i>
//                               New
//                           </Link>
//                         </span>
//                         {/* <span><Popup trigger={<span className="button add-accountrecord"> <FaPlus/> New </span>} modal>

//     <form onSubmit={handleSubmittcf} className="add-accounts">
//      <div className='form-group'>
//      <label>TCF Record Type</label><br/>
//      <select name='account_record' className='form-control' onChange={handleChangetcf} value={formData.account_record}>
//        <option >--Select TCF Record Type--</option>
//        <option value='Buyer - Spot'>Buyer - Spot</option>
//        <option value='Buyer - Long Term'>Buyer - Long Term</option>
//        </select>
//        </div>
//        <input type='submit' className="btn btn-primary " value='Submit'/>

//        </form>
// </Popup></span> */}
//                       </h4>
//                       <div id='inner-heading'>

//                       </div>
//                       <hr></hr>
//                     </div>
//                     <table id='quote-product' className="">
//                       <thead>
//                         <tr>
//                           <th>Quote Product Name	</th>
//                           <th>Quote Shipment	</th>
//                           <th>Gross As Received Typical	</th>
//                           <th>Ash Content Typical	</th>
//                           <th>Total Moisture Typical	</th>
//                           <th>Total Sulphur Typical ARB
//                           </th>
//                         </tr>
//                       </thead>

//                       {quoteProduct.length > 0 ?

//                         <tbody>
//                           {
//                             quoteProduct.map((y, i) => (
//                               <tr>

//                                 <td><span id='codes'><Link to={'/opportunity/quote-detail/' + y.id}>{y.quotes_name}</Link></span></td>
//                                 <td>{y.quotes_shippment}</td>
//                                 <td>{y.gross_as_received_typical	}</td>
//                                 <td>{y.ash_content_typical	}</td>
//                                 <td>{y.total_moisture_typical	}</td>
//                                 <td>{y.total_sulphur_typical_arb
// 	}</td>
//                               </tr>
//                             ))}
//                         </tbody>
//                         : <tbody>
//                           <tr>
//                             <td></td>
//                             <td></td>
//                             <td>No data available</td>
//                             <td></td>
//                             <td></td>
//                             <td></td>

//                           </tr>
//                         </tbody>
//                       }
//                     </table>

//                   </Row>
//                   <Row id="table-styles">
//      <div className="col-md-12" id="head">
//        <h4 className="heading">
//          <span>Quote Clauses
//          </span>
//          <span>
//            <i className=""></i> New Quote Clauses
//            </span>

//        </h4>
//        <hr>
//        </hr>
//      </div>
//      <table id="history" className="">
//        <tr>
//          <td>
//            <p class="no-records"> No records to display </p>
//          </td>
//        </tr>
//      </table>
//    </Row>
//    <Row id="table-styles">
//                     <div className="col-md-12" id='head'>
//                       <h4 className="heading"><span>Trade Confirmation Form</span>

//                       </h4>
//                       <div id='inner-heading'>

//                       </div>
//                       <hr></hr>
//                     </div>
//                     <table id='tcf' className="">
//                       <thead>
//                         <tr>
//                           <th>TCF No</th>
//                           <th>tcf status</th>
//                           <th>Buyer</th>
//                           <th>supplier</th>
//                           <th>Quantity (MT)	</th>
//                           <th>Contractual GAR (P)	</th>
//                           <th>Actual GAR (P)</th>
//                           <th>Actual GAR (S)</th>
//                         </tr>
//                       </thead>

//                       {tcf.length > 0 ?

//                         <tbody>
//                           {
//                             tcf.map((x, i) => (
//                               <tr>
//                                 <td><Link to={"/tcf/tcf-details/" + x.id}>{x.tcf_no}</Link></td>
//                                 <td>{x.tcf_status}</td>
//                                 <td><Link to={"/accounts/" + x.account_id}>{x.buyer}</Link></td><td><Link to={"/accounts/" + x.supplier_id}>{x.supplier}</Link></td>
//                                 <td>{x.quantity_MT}</td>
//                                 <td>{x.contractual_GAR_P}</td>
//                                 <td>{x.actual_gar_p}</td>
//                                 <td>{x.actual_gar_s}</td>
//                               </tr>
//                             ))}
//                         </tbody>
//                         : <tbody>
//                           <tr>
//                             <td></td>
//                             <td></td><td></td>
//                             <td></td>
//                             <td>No data available</td>
//                             <td></td>
//                             <td></td>
//                             <td></td>
//                           </tr>
//                         </tbody>
//                       }
//                     </table>

//                   </Row>

//    <Row id="table-styles">
//      <div className="col-md-12" id="head">
//        <h4 className="heading">
//          <span>Quote PDFs
//          </span>

//        </h4>
//        <hr>
//        </hr>
//      </div>
//      <table id="history" className="">
//        <tr>
//          <td>
//            <p class="no-records"> No records to display </p>
//          </td>
//        </tr>
//      </table>
//    </Row>
//    <Row id="table-styles">
//      <div className="col-md-12" id="head">
//        <h4 className="heading">
//          <span>Notes
//          </span>
//          <span>New Note
//          </span>
//        </h4>
//        <hr>
//        </hr>
//      </div>
//      <table id="history" className="">
//        <tr>
//          <td>
//            <p class="no-records"> No records to display </p>
//          </td>
//        </tr>
//      </table>
//    </Row>
//    <Row id="table-styles">
//      <div className="col-md-12" id="head">
//        <h4 className="heading">
//          <span>Files
//          </span>
//          <span>Upload Files         </span>
//        </h4>
//        <hr>
//        </hr>
//      </div>
//      <table id="history" className="">
//        <tr>
//          <td>
//            <p class="no-records"> No records to display </p>
//          </td>
//        </tr>
//      </table>
//    </Row>

//    <Row id="table-styles">
//      <div className="col-md-12" id="head">
//        <h4 className="heading">
//          <span>Open Activities

//          </span>
//          <span>New Task       </span>
//          <span>New Event       </span>
//        </h4>
//        <hr>
//        </hr>
//      </div>
//      <table id="history" className="">
//        <tr>
//          <td>
//            <p class="no-records"> No records to display </p>
//          </td>
//        </tr>
//      </table>
//    </Row>
//    <Row id="table-styles">
//      <div className="col-md-12" id="head">
//        <h4 className="heading">
//          <span>Notes & Attachments</span>
//          <span>
//             New Note </span>
//          <span>
//            Attach File </span>
//        </h4>
//        <hr>
//        </hr>
//      </div>
//      <table id="history" className="">
//        <tr>
//          <td>
//            <p class="no-records"> No records to display </p>
//          </td>
//        </tr>
//      </table>
//    </Row>
//    <Row id="table-styles">
//      <div className="col-md-12" id="head">
//        <h4 className="heading">
//          <span>Activity History
//          </span>
//          <span>
//             Log a Call </span>
//          <span>
//            Mail Merge </span>
//        </h4>
//        <hr>
//        </hr>
//      </div>
//      <table id="history" className="">
//        <tr>
//          <td>
//            <p class="no-records"> No records to display </p>
//          </td>
//        </tr>
//      </table>
//    </Row>

//    <Row id="table-styles">
//      <div className="col-md-12" id="head">
//        <h4 className="heading">
//          <span>Approval History

//          </span>
//          <span>
//             Submit for Approval</span>

//        </h4>
//        <hr>
//        </hr>
//      </div>
//      <table id="history" className="">
//        <tr>
//          <td>
//            <p class="no-records"> No records to display </p>
//          </td>
//        </tr>
//      </table>
//    </Row>
//                         </>
//                       )}</div>

//                   ) : (<></>)}
//                 </>
//               )}
//             </>
//           )}
//         </>
//       )}
//       <p> <p></p><p></p></p>
//       <Footer />
//     </div>
//   );
// };

// export default AllQuoteView;


import React, { Component, useState, useEffect, forwardRef, useContext } from "react";
import { Card, OverlayTrigger, Tooltip, Table } from "react-bootstrap";
import "datatables.net-dt/js/dataTables.dataTables.js";
import { AES, enc } from "crypto-js";
import "datatables.net-responsive-dt";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "react-data-table-component-extensions/dist/index.css";
import { Link, useNavigate } from "react-router-dom";
import { TokenContext } from "../AuthProvider";
import Header from "../Header";
import Footer from "../Footer";
import { API_URL } from '../config';
import { parse, format, isValid } from 'date-fns';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import RecentItems from "../recentItems/RecentItems";
import $ from 'jquery';
import { useForm } from "react-hook-form";
import Popup from "reactjs-popup";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  FaEnvelope,
  FaPhoneSquareAlt,
  FaMobileAlt,
  FaFax,
} from "react-icons/fa";
import axios from "axios";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { FaPencilAlt, FaTrashAlt, FaPlus } from "react-icons/fa";
// import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
import { useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import ContractShippmentDetails from "../shippment/contractShippmentDetails copy";

const AllQuoteView = () => {




  const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
  const encryptedAccessToken = localStorage.getItem("access_token");
  const decryptedAccessToken = encryptedAccessToken
    ? AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8)
    : null;

  const navigate = useNavigate();
  const params = useParams();
  const id = params.id;
  const userRole = localStorage.getItem("role");
  const [opp, setOpp] = useState([])
  const [locked, setCheck1] = useState(false)
  // console.log(locked)

  const [startDate, setStartDate] = useState(null);
  const birthDate = startDate ? `${startDate.getDate().toString().padStart(2, '0')}/${(startDate.getMonth() + 1).toString().padStart(2, '0')}/${startDate.getFullYear()}` : null;
  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const [isContractCreateEnabled, setisContractCreateEnabled] = useState(false);

  const [startDate1, setStartDate1] = useState(null);
  const birthDate1 = startDate1 ? `${startDate1.getDate().toString().padStart(2, '0')}/${(startDate1.getMonth() + 1).toString().padStart(2, '0')}/${startDate1.getFullYear()}` : null;
  const handleStartDateChange1 = (date) => {
    setStartDate1(date);
  };

  const [start, setStart] = useState(new Date());
  const startDates =
    start.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    start.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    start.toLocaleDateString("en-US", { day: "2-digit" });
  const ExampleCustomInput2 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="example-custom-input"
      onClick={onClick}
      onChange={onChange}
      name="contract_start_date"
      ref={ref}
    ></input>
  ));
  const [effective, setEffective] = useState(new Date());
  const contractEffective =
    effective.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    effective.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    effective.toLocaleDateString("en-US", { day: "2-digit" });
  const ExampleCustomInput1 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="example-custom-input"
      onClick={onClick}
      onChange={onChange}
      name="contract_effective_date"
      ref={ref}
    ></input>
  ));
  useEffect(() => {

    fetchDataOpp();
  }, [decryptedAccessToken]);

  const fetchDataOpp = async () => {
    try {
      const response = await axios.get(`${API_URL}all/opportunity`, {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },
      }
      );
      const data = response.data.opportunity;
      // console.log(data)
      setOpp(data);

    } catch (error) {
      console.error(error);
    }
  };

  const [data, setData] = useState({});
  const [data1, setData1] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editedData, setEditedData] = useState({});
  const [surveyors, setSurveyor] = useState([]);

  useEffect(() => {
    fetchData6();
  }, [decryptedAccessToken]);

  const fetchData6 = async () => {
    try {
      const response = await axios.get(
        `${API_URL}get/surveyor`,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data;
      // console.log(JSON.stringify(data))
      setSurveyor(data);
    } catch (error) {
      console.error(error);
    }
  };


  const handleEditClick = () => {
    setIsEditMode(true);
    setEditedData(data);
  };

  const handleChange = (e) => {
    const { name, value, } = e.target;
    setData1((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (event) => {
    setCheck1(event.target.checked);
  };


  const handleCancelEdit = () => {
    setIsEditMode(false);
    setEditedData(data);
    setTimeout(() => {
      $('#tcf').DataTable();
      $('#quote').DataTable();
      $('#quote-product').DataTable();
    }, 10);
  };
  const [traderData, setTraderData] = useState([]);
  useEffect(() => {
    fetchDataTrader();
  }, [decryptedAccessToken]);

  const fetchDataTrader = async () => {
    try {
      const response = await axios.get(
        `${API_URL}get/all/rwoodusers`,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data.rwoodusers;      // console.log(data)
      setTraderData(data);
    } catch (error) {
      console.error(error);
    }
  };
  // console.log(userRole);

  const { username, token } = useContext(TokenContext);
  const [datas, setDatas] = useState([]);

  const [tcf, setTCF] = useState([]);
  const [quote, setQuote] = useState([]);
  const [quoteProduct, setQuoteproduct] = useState([]);
  const [lengths, setLengths] = useState([]);
  const [startDate2, setStartDate2] = useState(null);
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${API_URL}get/all/offer_quote_byID/` + id,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      console.log('response', response)
      const data = response.data.quote_details[0];
      let birth_Date = data.quote_date

      if (birth_Date !== null) {
        const defaultDatepicker = parse(birth_Date, 'dd/MM/yyyy', new Date());
        setStartDate2(defaultDatepicker);
      } else {
        setStartDate2(null)
      }
      const dataTcf = response.data.tcf;
      const quote = response.data.quote_shipment;
      const quote_products = response.data.quote_product;
      const quote_product_len = response.data.quote_product_length; // Assuming quote_length is what you want to track
      const numberInt = parseInt(quote_product_len, 10);
      setLengths(numberInt);
      console.log(response)
      setDatas(data);
      setData1(data);
      setTCF(dataTcf);
      setQuote(quote);
      setQuoteproduct(quote_products);

      setTimeout(() => {
        $('#tcf').DataTable();
        $('#quote').DataTable();
        $('#quote-product').DataTable();
      }, 10);
    } catch (error) {
      console.error(error);
    }
  };


  useEffect(() => {
    fetchData(); // Call the fetchData function quoteProduct

  }, [decryptedAccessToken, data]);



  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  useEffect(() => {
    setIsButtonEnabled(true);
  }, []);
  const [isSubmitted, setIsSubmitted] = useState(false);


  const createContract = async () => {

    try {

      setisContractCreateEnabled(true);

      const encryptedAccessToken = localStorage.getItem("access_token");

      const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";

      const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);

      const CONTRACT_URL = `${API_URL}add/quotes/contract/${id}`

      const PAY_LOAD = {}

      let isContractShipmentCreated = false;

      let isContractProductCreated = false;

      const contractResponse = await axios.post(CONTRACT_URL, PAY_LOAD, {

        headers: {

          Authorization: `Bearer ${decryptedAccessToken}`,
          "Content-Type": "application/json",

        },

      })

      if (contractResponse.status === 200)  {

        const contractId = contractResponse?.data?.contract_id;


        if (!id){

          toast.info('Quote is Missing !!!', {

            position: "bottom-center",
            autoClose: 2000,

          });

        }


        if (!contractId){

          toast.info('Contract is Missing !!!', {

            position: "bottom-center",
            autoClose: 2000,

          });

        }

        if (id && contractId) {


          try {

            const COTRACT_SHIPMENT_URL = `${API_URL}add/contract_ship/${id}/${contractId}`

            const contractShipmentResponse = await axios.post(COTRACT_SHIPMENT_URL, PAY_LOAD, {

              headers: {

                Authorization: `Bearer ${decryptedAccessToken}`,
                "Content-Type": "application/json",

              },

            })


            if (contractShipmentResponse.status === 200) {

              isContractShipmentCreated = true;

              toast.success(contractShipmentResponse.data?.msg, {

                position: "bottom-center",
                autoClose: 2000,

              });

            }

          } catch (err) {

            toast.info(err.data?.msg, {

              position: "bottom-center",
              autoClose: 2000,

            });

          }

          try {


            const CONTRACT_PRODUCT_URL = `${API_URL}add/contract_products/${id}/${contractId}`

            const contractProductResponse = await axios.post(CONTRACT_PRODUCT_URL, PAY_LOAD, {

              headers: {

                Authorization: `Bearer ${decryptedAccessToken}`,
                "Content-Type": "application/json",

              },

            })

            if (contractProductResponse.status === 200) {

              isContractProductCreated = true;

              toast.success(contractProductResponse.data?.msg, {

                position: "bottom-center",
                autoClose: 1000,

              });

            }

          } catch (err) {

            toast.info(err.data?.msg, {

              position: "bottom-center",
              autoClose: 1000,

            });

          }

          if (isContractShipmentCreated && isContractProductCreated) {

            setTimeout(()=> {

              navigate(`/contract/contract-details/${contractId}`)

            }, 2000)

          }

        }

      }


    } catch (err) {

      toast.error("Error Creating Contract !!!", {

        position: "bottom-center",
        autoClose: 1000,

      });

    } finally {

      setisContractCreateEnabled(false);

    }


  }

  // const createContract = () => {
  //   console.log("");

  //   if (lengths > 0) {
  //     const encryptedAccessToken = localStorage.getItem("access_token");
  //     const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";

  //     if (encryptedAccessToken) {
  //       const decryptedAccessToken = AES.decrypt(
  //         encryptedAccessToken,
  //         secret_key
  //       ).toString(enc.Utf8);

  //       fetch(`${API_URL}add/quotes/contract/` + id, {
  //         method: "POST",
  //         headers: {
  //           Authorization: `Bearer ${decryptedAccessToken}`,
  //           "Content-Type": "application/json",
  //         },
  //       })
  //         .then((response) => {
  //           if (!response.ok) {
  //             // Handle non-successful response
  //             console.error("Request failed with status:", response.status);
  //             throw new Error(`Request failed with status ${response.status}`);
  //           }
  //           return response.json();
  //         })
  //         .then((response) => {
  //           console.log(response);
  //           let contractId = response.contract_id;
  //           navigate(`/contract/contract-details/${contractId}`);
  //           fetch(`${API_URL}add/contract_ship/${id}/${contractId}`, {
  //             method: "POST",
  //             headers: {
  //               Authorization: `Bearer ${decryptedAccessToken}`,
  //               "Content-Type": "application/json",
  //             },
  //           })
  //             .then((response) => {
  //               if (!response.ok) {
  //                 console.error("Request failed with status:", response.status);
  //                 throw new Error(`Request failed with status ${response.status}`);
  //               }
  //               return response.json();
  //             })
  //             .then((response) => {
  //               console.log("**********", id, contractId);
  //               fetch(`${API_URL}add/contract_products/${id}/${contractId}`,
  //                 {
  //                   method: "POST",
  //                   headers: {
  //                     Authorization: `Bearer ${decryptedAccessToken}`,
  //                     "Content-Type": "application/json",
  //                   },
  //                 }
  //               )
  //                 .then((response) => {

  //                   // console.log(response)
  //                   if (!response.ok) {
  //                     console.error("Request failed with status:", response.status);
  //                     throw new Error(`Request failed with status ${response.status}`);
  //                   }
  //                   return response.json();
  //                 })
  //                 .then((response) => {
  //                   console.log(response)
  //                   if (response.msg === "success") {
  //                     setTimeout(() => {
  //                       // reset();
  //                       navigate(`/contract/contract-details/${contractId}`);
  //                     }, 2000);
  //                   }
  //                 });
  //             });
  //         })
  //         .catch((error) => {
  //           console.error("Error:", error);
  //           // Handle errors here
  //         });
  //     }
  //   } else {
  //     toast.error("Create Quote Product First ", {
  //       position: "bottom-center",
  //       autoClose: 2000,
  //       hideProgressBar: false,
  //       toastId: "success1",
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: "light",
  //     });
  //   }
  // };

  const [bdate, setbirthDate] = useState('')
  useEffect(() => {
    const dateObject = new Date(startDate2);
    if (dateObject) {
      const formattedDate = format(dateObject, 'dd/MM/yyyy', { timeZone: 'UTC' });
      setbirthDate(formattedDate)
      console.log(formattedDate)
    }
  })
  const [lockedOne, setLockedOne] = useState({})
  const check_locked = data1.locked;

  useEffect(() => {
    setLockedOne(check_locked); // Update lockeds with the value of tt
  }, [check_locked]); // Run this effect whenever tt changes

  useEffect(() => {
  }, [lockedOne]); // Run this effect whenever lockeds changes



  const handleCheckboxLocked = (e) => {
    setLockedOne(e.target.checked);
    // setLockeds(!lockeds)
    // Debug log

  };


  const handleSaveEdit = (e) => {
    setIsSubmitted(true)
    e.preventDefault();
    setTimeout(() => {
      $('#tcf').DataTable();
      $('#quote').DataTable();
      $('#quote-product').DataTable();
    }, 10);
    const responseData = {
      "locked": lockedOne,
      "account_name": data1.account_name,
      "additional_to_city": data1.additional_to_city,
      "additional_to_country": data1.additional_to_country,
      "additional_to_name": data1.additional_to_name,
      "additional_to_state_province": data1.additional_to_state_province,
      "additional_to_street": data1.additional_to_street,
      "additional_to_zip_postal_code": data1.additional_to_zip_postal_code,
      "applicable_law": data1.applicable_law,
      "arbitration": data1.arbitration,
      "phone_fax": data1.phone_fax,
      "bill_to_city": data1.bill_to_city,
      "bill_to_country": data1.bill_to_country,
      "bill_to_name": data1.bill_to_name,
      "bill_to_state_province": data1.bill_to_state_province,
      "bill_to_street": data1.bill_to_street,
      "bill_to_zip_postal_code": data1.bill_to_zip_postal_code,
      "bonus_penalty_clause": data1.bonus_penalty_clause,
      "commodity": data1.commodity,
      "contact_name": data1.contact_name,
      "contract": data1.contract,
      "currency": data1.currency,
      "description": data1.description,
      "destination": data1.destination,
      "email": data1.email,
      "trader_name": data1.trader_name,
      // "total_price":parseFloat(data1.total_price),
      "exchange_rate": data1.exchange_rate,
      "expiration_date": birthDate1,
      "general_terms": data1.general_terms,
      "no_of_product": data1.no_of_product,
      "no_of_quote_shipment": data1.no_of_quote_shipment,
      "offer_validity_terms": data1.offer_validity_terms,
      "opportunity_name": data1.opportunity_name,
      "origin": data1.origin,
      "additional_to": data1.additional_to,
      "quote_to": data1.quote_to,
      "phone": data1.phone,
      "bill_to": data1.bill_to,
      "ship_to": data1.ship_to,
      "created_by": data1.created_by,
      "modified_by": data1.modified_by,
      "payment_terms": data1.payment_terms,
      "phone_fax": data1.phone_fax,
      "product_description": data1.product_description,
      "quantity_MT": parseFloat(data1.quantity_MT),
      "quote_date": bdate,
      "quote_name": data1.quote_name,
      "quote_number": data1.quote_number,
      "quote_to_city": data1.quote_to_city,
      "quote_to_country": data1.quote_to_country,
      "quote_to_name": data1.quote_to_name,
      "quote_to_state_province": data1.quote_to_state_province,
      "quote_to_street": data1.quote_to_street,
      "quote_to_zip_postal_code": data1.quote_to_zip_postal_code,
      "rejections": data1.rejections,
      "rwood_surveyor_agent": data1.rwood_surveyor_agent,
      "ship_to_city": data1.ship_to_city,
      "ship_to_country": data1.ship_to_country,
      "ship_to_name": data1.ship_to_name,
      "ship_to_state_province": data1.ship_to_state_province,
      "ship_to_street": data1.ship_to_street,
      "ship_to_zip_postal_code": data1.ship_to_zip_postal_code,
      "status": data1.status,
      "supplier": data1.supplier,
      "surveyor_2": data1.surveyor_2,
      "suveyor_3": data1.suveyor_3,
      "trader_name": data1.trader_name,
    }
    console.log(JSON.stringify(responseData))
    fetch(`${API_URL}update_offer_quotes/${id}`, {
      method: 'PUT',
      body: JSON.stringify(responseData),
      headers: {
        Authorization: `Bearer ${decryptedAccessToken}`,
        'Content-Type': 'application/json'
      },

    })
      .then((response) => response.json())
      .then((updatedData) => {
        console.log(updatedData)
        fetchData()
        if(updatedData.success===true){

          const messageContainer = document.getElementById('messageContainer');

        // Create the success message element
        const successMessage = document.createElement('div');
        successMessage.className = 'error-message';
        successMessage.innerText = updatedData.msg;

        // Append the success message to the container
        messageContainer.appendChild(successMessage);

        // Scroll to the top
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        setTimeout(() => {
          successMessage.remove();
      }, 2000);
      setData(updatedData);
        setIsEditMode(false);


        }
        else if(updatedData.success === false) {
          const messageContainer = document.getElementById('messageContainer');

        // Create the success message element
        const successMessage = document.createElement('div');
        successMessage.className = 'error-message';
        successMessage.innerText = updatedData.msg;

        // Append the success message to the container
        messageContainer.appendChild(successMessage);

        // Scroll to the top
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        setTimeout(() => {
          successMessage.remove();
      }, 2000);
        }


      })
      .catch((error) => console.error(error));
  };
  console.log(lengths);


  if (!datas) {
    return null;
  }
  return (
    <div id="view-page">
      <Header />
      <div className="row">
      <div className="col-md-2">
            <RecentItems/>
          </div>
          <div className="col-md-10">
      <div className="contactAccount">

        <div className="page-header" id='add-account'>
          <div>
            <h2 className="main-content-title tx-24 mg-b-5">Quotes Detalis</h2>
            <ol className="breadcrumb">
              <li className="breadcrumb-item active" aria-current="page">Dashboard / Quotes Detalis</li>
            </ol>
          </div>
          <div className="d-flex opd">
            <div className="justify-content-center">
              {/*<button type="button" className="btn btn-primary my-2 btn-icon-text">
       <Link to={`/opportunity/add-shipment/${id}`} > <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Opp Shipment</Link>
      </button>
      <button type="button" className="btn btn-primary my-2 btn-icon-text">
       <Link to={`/opportunity/add-quote/${id}`} > <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Quotes</Link>
      </button>
      <button type="button" className="btn btn-primary my-2 btn-icon-text">
       <Link to={`/all-quotes`}> <i className="fe fe-download-cloud me-2"></i><FaPlus/> All Quotes</Link>
      </button>*/}

            </div>
          </div>
        </div>
      </div>
      {data1.quote_record_type === 'Buyer - Long Term' ? (
        <div className="tasks edit-view">
          <ToastContainer />
 <div id="messageContainer"></div>
          {isEditMode ? (
            <>
              <p className='edit-btn'>
                <input
                  type="submit"
                  className="account-save"
                  onClick={handleSaveEdit}
                  //value={isSubmitted ? "Saving" : "Save"}
                 value="Save"
                />
                <button className="btn btn-primary" onClick={handleCancelEdit}>Cancel</button>
              </p>
              <table class="table table-bordered account-table tables">
                <tbody>
                  <tr>
                    <td>
                      <span>Quote Number</span>
                      <br />
                      <input
                        type="text" className='form-control'
                        name="quote_number"
                        disabled
                        defaultValue={data1.quote_number || ''}
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <span>Quote Record Type</span>
                      <br />
                      <input
                        type="text" className='form-control'
                        name="quote_record_type"
                        disabled
                        defaultValue={data1.quote_record_type || ''}
                        onChange={handleChange}
                      />
                    </td>


                  </tr>
                  <tr>
                    <td>
                      <span>Locked</span>
                      <br />
                      <input
                        type='checkbox'
                        name='locked'
                        checked={lockedOne} // Use the state variable to determine if the checkbox is checked
                        onChange={handleCheckboxLocked} // Handle the change event
                        className='mx-3'
                      />
                    </td>
                    <td>
                      <span>Currency</span>
                      <br />

                      <select name="currency"
                        defaultValue={data1.currency || ''}
                        onChange={handleChange} className="form-control">
                        <option defaultValue='' >---None---</option>
                        <option defaultValue='USD' >USD</option>
                        <option defaultValue='IDR' >IDR</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>Exchange Rate</span>
                      <br />
                      <input
                        type="text" className='form-control'
                        name="exchange_rate"
                        defaultValue={data1.exchange_rate || ''}
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <span>Opportunity Name</span>
                      <br />

                      <select className='form-control'
                        name="opportunity_name"
                        defaultValue={data1.opportunity_name || ''}>
                        <option value='' >Select</option>
                        {
                          opp.map((x) => {
                            return (
                              <option value={x.opportunity_name}>{x.opportunity_name}</option>
                            )
                          })
                        }
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>Quote Name</span>
                      <br />
                      <input
                        type="text" className='form-control'
                        name="quote_name"
                        defaultValue={data1.quote_name || ''}
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <span>Status</span>
                      <br />
                      <select
                        name="status"
                        className='form-control'
                        defaultValue={data1.status || ''}
                        onChange={handleChange}
                      >
                        <option value='Offered'>Offered</option>
                        <option value='Accepted'>Accepted</option>
                        <option value='Rejected'>Rejected</option>
                        <option value='Sold'>Sold</option>
                      </select>
                    </td>

                  </tr>
                  <tr>
                    <td>
                      <span>Account Name</span>
                      <br />
                      <input
                        type="text" className='form-control record-type'
                        name="account_name"
                        disabled
                        defaultValue={data1.account_name || ''}
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <span>Supplier</span>
                      <br />
                      <input
                        type="text" className='form-control'
                        name="supplier"
                        defaultValue={data1.supplier || ''}
                        onChange={handleChange}
                      />
                    </td>
                  </tr><tr><td>
                    <span>Contact Name</span>
                    <br />
                    <input
                      type="text" className='form-control'
                      name="contact_name"
                      defaultValue={data1.contact_name || ''}
                      onChange={handleChange}
                    />
                  </td>

                    <td>
                      <span>Quote Date</span>
                      <br />
                      <DatePicker
                        selected={startDate2}
                        onChange={(date) => setStartDate2(date)}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Select a date"
                      />

                    </td>

                  </tr>
                  <tr>
                    <td>
                      <span>Expiration Date</span>
                      <br />

                      <DatePicker
                        selected={startDate1}
                        onChange={handleStartDateChange1}

                        dateFormat='dd/MM/yyyy'
                        placeholderText="Select"
                      />

                    </td>
                    <td>
                      <span>Trader Name</span>
                      <br />


                      <select className='form-control'
                        name="trader_name"
                        defaultValue={data1.trader_name || ''} onChange={handleChange}>
                        <option value='' >Select</option>
                        {
                          traderData.map((x, i) => {
                            return (
                              <option key={i} value={x.rwood_username}>
                                {x.rwood_username}
                              </option>
                            )
                          })
                        }
                      </select>
                    </td>

                  </tr>
                  <tr>

                    <td>
                      <span>No of Quote shipment</span>
                      <br />
                      <input
                        type="text" className='form-control'
                        name="no_of_quote_shipment"
                        defaultValue={data1.no_of_quote_shipment || ''}
                        onChange={handleChange}
                      />
                    </td>

                  </tr>
                </tbody>
              </table>
              <div className="col-md-12" id='ax.account_details'>
                <h4 className="heading">Product information</h4>
                <hr></hr>
              </div>
              <table class="table table-bordered account-table tables">
                <tbody>
                  <tr>
                    <td>
                      <span>Quantity MT</span>
                      <br />
                      <input
                        type="text" className='form-control'
                        name="quantity_MT"
                        defaultValue={data1.quantity_MT || ''}
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <span>Contract</span>
                      <br />
                      <input
                        type="text" className='form-control'
                        name="contract"
                        defaultValue={data1.contract || ''}
                        onChange={handleChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>Product Description</span>
                      <br />
                      <input
                        type="text" className='form-control'
                        name="product_description"
                        defaultValue={data1.product_description || ''}
                        onChange={handleChange}
                      />
                    </td>

                    <td>
                      {/* <span>Total Price</span> */}
                      <br />
                      {/* <input
              type="text" className='form-control'
              name="total_price"
              defaultValue={data1.total_price || ''}
              onChange={handleChange}
            /> */}
                    </td>


                  </tr>
                </tbody>
              </table>
              <div className="col-md-12" id='ax.account_details'>
                <h4 className="heading">Market Information</h4>
                <hr></hr>
              </div>
              <table class="table table-bordered account-table tables">
                <tbody>
                  <tr>
                    <td>
                      <span>Origin</span>
                      <br />
                      <input
                        type="text" className='form-control'
                        name="origin"
                        defaultValue={data1.origin || ''}
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <span>Commodity</span>
                      <br />
                      <select className='form-control'
                        name="commodity"
                        defaultValue={data1.commodity || ''}
                        onChange={handleChange}>
                        <option>---None---</option>
                        <option defaultValue="Indonesian Steam Coal in Bulk (&quot;Coal&quot;)">Indonesian Steam Coal in Bulk ("Coal")</option><option defaultValue="US Coal">US Coal</option><option defaultValue="Russia Coal">Russia Coal</option><option defaultValue="SA Coal">SA Coal</option><option defaultValue="Clinker in Bulk">Clinker in Bulk</option><option defaultValue="Australian Steam Coal">Australian Steam Coal</option>
                      </select>
                    </td>

                  </tr>
                  <tr>
                    <td>
                      <span>Destination</span>
                      <br />

                      <input
                        type="text" className='form-control'
                        name="destination"
                        defaultValue={data1.destination || ''}
                        onChange={handleChange}
                      />
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
              <div className="col-md-12" id='ax.account_details'>
                <h4 className="heading">Price and Price Adjustment</h4>
                <hr></hr>
              </div>
              <table class="table table-bordered account-table tables">
                <tbody>
                  <tr>
                    <td>
                      <span>Bonus/Penalty Clause</span>
                      <br />

                      <textarea className='form-control'
                        name="bonus_penalty_clause"
                        defaultValue={data1.bonus_penalty_clause || ''}
                        onChange={handleChange}>

                      </textarea>

                    </td>
                    <td>
                      <span>Rejections</span>
                      <br />

                      <textarea className='form-control'
                        name="rejections"
                        defaultValue={data1.rejections || ''}
                        onChange={handleChange}>
                      </textarea>
                    </td>

                  </tr>
                  <tr>
                    <td>
                      <span>Payment Term</span>
                      <br />


                      <textarea className='form-control'
                        name="payment_terms"
                        defaultValue={data1.payment_terms || ''}
                        onChange={handleChange}>
                      </textarea>
                    </td>
                    <td>
                      <span>General Terms</span>
                      <br />


                      <textarea className='form-control'
                        name="general_terms"
                        defaultValue={data1.general_terms || ''}
                        onChange={handleChange}>
                      </textarea>
                    </td>

                  </tr>
                </tbody>
              </table>
              <div className="col-md-12" id='ax.account_details'>
                <h4 className="heading">Terms & Conditions</h4>
                <hr></hr>
              </div>
              <table class="table table-bordered account-table tables">
                <tbody>
                  <tr>
                    <td>
                      <span>Arbitration</span>
                      <br />

                      <select className='form-control'
                        name="arbitration"
                        defaultValue={data1.arbitration || ''}
                        onChange={handleChange}>
                        <option value="">--None--</option><option value="In Singapore as per SIAC">In Singapore as per SIAC</option><option value="Singapore Chamber of Maritime Arbitration (SCMA)">Singapore Chamber of Maritime Arbitration (SCMA)</option><option value="Laws of England and Wales">Laws of England and Wales</option><option value="Rules of Badan Arbitrase Nasional Indonesia (BANI)">Rules of Badan Arbitrase Nasional Indonesia (BANI)</option>
                      </select>
                    </td>
                    <td>
                      <span>Rwood Surveyor Agent</span>
                      <br />
                      <input
                        type="text" className='form-control'
                        name="rwood_surveyor_agent"
                        defaultValue={data1.rwood_surveyor_agent || ''}
                        onChange={handleChange}
                      />
                    </td>

                  </tr>
                  <tr>
                    <td>
                      <span>Applicable Law</span>
                      <br />

                      <select className='form-control'
                        name="applicable_law"
                        defaultValue={data1.applicable_law || ''}
                        onChange={handleChange}>
                        <option value="">--None--</option><option value="English Law">English Law</option><option value="Singapore Law">Singapore Law</option><option value="Indonesian Law">Indonesian Law</option>
                      </select>
                    </td>
                    <td>
                      <span>Surveyor 2	</span>
                      <br />

                      <select className='form-control'
                        name="surveyor_2"
                        defaultValue={data1.surveyor_2 || ''}
                        onChange={handleChange}>
                        <option>---None---</option>
                        {surveyors.map((x) => {
                          return (
                            <option value={x.account_name}>{x.account_name}</option>
                          );
                        })}
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>Surveyor 3</span>
                      <br />

                      <select className='form-control'
                        name="suveyor_3"
                        defaultValue={data1.suveyor_3 || ''}
                        onChange={handleChange}>
                        <option>---None---</option>
                        {surveyors.map((x) => {
                          return (
                            <option value={x.account_name}>{x.account_name}</option>
                          );
                        })}
                      </select>
                    </td>   <td>
                      <span>Offer Validity Terms</span>
                      <br />

                      <textarea className='form-control'
                        name="offer_validity_terms"
                        defaultValue={data1.offer_validity_terms || ''}
                        onChange={handleChange}>

                      </textarea>
                    </td>

                  </tr>
                  <tr>
                    <td>
                      <span>Description</span>
                      <br />


                      <textarea className='form-control'
                        name="description"
                        defaultValue={data1.description || ''}
                        onChange={handleChange}>

                      </textarea>
                    </td>
                    <td>

                    </td>

                  </tr>
                </tbody>
              </table>

              <div className="col-md-12" id='ax.account_details'>
                <h4 className="heading">Prepared For</h4>
                <hr></hr>
              </div>
              <table class="table table-bordered account-table tables">
                <tbody>
                  <tr>
                    <td>
                      <span>Quote To Name</span>
                      <br />
                      <input
                        type="text" className='form-control'
                        name="quote_to_name"
                        defaultValue={data1.quote_to_name || ''}
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <span>Additional To Name</span>
                      <br />
                      <input
                        type="text" className='form-control'
                        name="additional_to_name"
                        defaultValue={data1.additional_to_name || ''}
                        onChange={handleChange}
                      />
                    </td>

                  </tr>
                  <tr>

                    <td>
                      <span>quote to street</span>
                      <br />
                      <input
                        type="text" className='form-control'
                        name="quote_to_street"
                        defaultValue={data1.quote_to_street || ''}
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <span>additional to street</span>
                      <br />
                      {/* {data1.additional_to} */}
                      <input
                        type="text" className='form-control'
                        name="additional_to_street"
                        defaultValue={data1.additional_to_street || ''}
                        onChange={handleChange}
                      />
                    </td>
                  </tr>
                  <tr>

                    <td>
                      <span>Quote To Zip/Postal Code	</span>
                      <br />
                      {/* {data1.phone} */}
                      <input
                        type="text" className='form-control'
                        name="quote_to_zip_postal_code"
                        defaultValue={data1.quote_to_zip_postal_code || ''}
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <span>Additional To Zip/Postal Code	</span>
                      <br />
                      {/* {data1.phone_fax} */}
                      <input
                        type="text" className='form-control'
                        name="additional_to_zip_postal_code"
                        defaultValue={data1.additional_to_zip_postal_code || ''}
                        onChange={handleChange}
                      />
                    </td>
                  </tr>
                  <tr>

                    <td>
                      <span>quote to city	</span>
                      <br />
                      {/* {data1.phone_fax} */}
                      <input
                        type="text" className='form-control'
                        name="quote_to_city"
                        defaultValue={data1.quote_to_city || ''}
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <span>Additional To City		</span>
                      <br />
                      {/* {data1.phone_fax} */}
                      <input
                        type="text" className='form-control'
                        name="additional_to_city"
                        defaultValue={data1.additional_to_city || ''}
                        onChange={handleChange}
                      />
                    </td>
                  </tr>
                  <tr>

                    <td>
                      <span>Quote To State/Province	</span>
                      <br />
                      {/* {data1.phone_fax} */}
                      <input
                        type="text" className='form-control'
                        name="quote_to_state_province"
                        defaultValue={data1.quote_to_state_province || ''}
                        onChange={handleChange}
                      />
                    </td>

                    <td>
                      <span>Additional To State/Province		</span>
                      <br />
                      {/* {data1.phone_fax} */}
                      <input
                        type="text" className='form-control'
                        name="additional_to_state_province"
                        defaultValue={data1.additional_to_state_province || ''}
                        onChange={handleChange}
                      />
                    </td>
                  </tr>
                  <tr>


                    <td>
                      <span>Quote To Country	</span>
                      <br />
                      {/* {data1.phone_fax} */}
                      <input
                        type="text" className='form-control'
                        name="quote_to_country"
                        defaultValue={data1.quote_to_country || ''}
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <span>Additional To Country	</span>
                      <br />
                      {/* {data1.phone_fax} */}
                      <input
                        type="text" className='form-control'
                        name="additional_to_country"
                        defaultValue={data1.additional_to_country || ''}
                        onChange={handleChange}
                      />
                    </td>
                  </tr>
                  <tr>



                  </tr>
                  <tr>
                    <td>
                      <span>Email</span>
                      <br />
                      {/* {data1.phone_fax} */}
                      <input
                        type="text" className='form-control'
                        name="email"
                        defaultValue={data1.email || ''}
                        onChange={handleChange}
                      />
                    </td>
                    <td>

                    </td>

                  </tr>
                  <tr>
                    <td>
                      <span>Phone</span>
                      <br />
                      {/* {data1.phone_fax} */}
                      <input
                        type="text" className='form-control'
                        name="phone"
                        defaultValue={data1.phone || ''}
                        onChange={handleChange}
                      />
                    </td>
                    <td>

                    </td>

                  </tr>
                  <tr>
                    <td>
                      <span>Fax</span>
                      <br />
                      {/* {data1.phone_fax} */}
                      <input
                        type="text" className='form-control'
                        name="fax"
                        defaultValue={data1.fax || ''}
                        onChange={handleChange}
                      />
                    </td>
                    <td>

                    </td>

                  </tr>
                </tbody>
              </table>

              <div className="col-md-12" id='ax.account_details'>
                <h4 className="heading">Address Information</h4>
                <hr></hr>
              </div>
              <table class="table table-bordered account-table tables">
                <tbody>
                  <tr>
                    <td>
                      <span>Bill To Name</span>
                      <br />
                      {/* {data1.bill_to_name} */}
                      <input
                        type="text" className='form-control'
                        name="bill_to_name"
                        defaultValue={data1.bill_to_name || ''}
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <span>Ship To Name</span>
                      <br />
                      {/* {data1.ship_to_name} */}
                      <input
                        type="text" className='form-control'
                        name="ship_to_name"
                        defaultValue={data1.ship_to_name || ''}
                        onChange={handleChange}
                      />
                    </td>

                  </tr>
                  <tr>
                    <td>
                      <span>bill to street</span>
                      <br />
                      {/* {data1.bill_to} */}
                      <input
                        type="text" className='form-control'
                        name="bill_to_street"
                        defaultValue={data1.bill_to_street || ''}
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <span>Ship To Street</span>
                      <br />
                      <input
                        type="text" className='form-control'
                        name="ship_to_street"
                        defaultValue={data1.ship_to_street || ''}
                        onChange={handleChange}
                      />
                    </td>

                  </tr>
                  <tr>
                    <td>
                      <span>Bill To Zip/Postal Code	</span>
                      <br />
                      {/* {data1.bill_to} */}
                      <input
                        type="text" className='form-control'
                        name="bill_to_zip_postal_code"
                        defaultValue={data1.bill_to_zip_postal_code || ''}
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <span>Ship To Zip/Postal Code	</span>
                      <br />
                      <input
                        type="text" className='form-control'
                        name="ship_to_zip_postal_code"
                        defaultValue={data1.ship_to_zip_postal_code || ''}
                        onChange={handleChange}
                      />
                    </td>

                  </tr>
                  <tr>
                    <td>
                      <span>Bill To City	</span>
                      <br />
                      {/* {data1.bill_to} */}
                      <input
                        type="text" className='form-control'
                        name="bill_to_city"
                        defaultValue={data1.bill_to_city || ''}
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <span>Ship To City	</span>
                      <br />
                      <input
                        type="text" className='form-control'
                        name="ship_to_city"
                        defaultValue={data1.ship_to_city || ''}
                        onChange={handleChange}
                      />
                    </td>

                  </tr>
                  <tr>
                    <td>
                      <span>Bill To State/Province	</span>
                      <br />
                      {/* {data1.bill_to} */}
                      <input
                        type="text" className='form-control'
                        name="bill_to_state_province"
                        defaultValue={data1.bill_to_state_province || ''}
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <span>Ship To State/Province	</span>
                      <br />
                      <input
                        type="text" className='form-control'
                        name="ship_to_state_province"
                        defaultValue={data1.ship_to_state_province || ''}
                        onChange={handleChange}
                      />
                    </td>

                  </tr>

                  <tr>
                    <td>
                      <span>Bill To Country	</span>
                      <br />
                      {/* {data1.bill_to} */}
                      <input
                        type="text" className='form-control'
                        name="bill_to_country"
                        defaultValue={data1.bill_to_country || ''}
                        onChange={handleChange}
                      />
                    </td>
                    <td>
                      <span>Ship To Country	</span>
                      <br />
                      <input
                        type="text" className='form-control'
                        name="ship_to_country"
                        defaultValue={data1.ship_to_country || ''}
                        onChange={handleChange}
                      />
                    </td>

                  </tr>
                </tbody>
              </table>
              {/* <div className="col-md-12" id='ax.account_details'>
   <h4 className="heading">System Information</h4>
   <hr></hr>
   </div>
        <table class="table table-bordered account-table tables">
         <tbody>
          <tr>
          <td>
                <span>Created By</span>
                <br />
                <input
              type="text" className='form-control'
              name="created_by"
              defaultValue={data1.created_by || ''}
              onChange={handleChange}
            />
              </td>
              <td>
                <span>Modified By</span>
                <br />
                <input
              type="text" className='form-control'
              name="modified_by"
              defaultValue={data1.modified_by || ''}
              onChange={handleChange}
            />
              </td>
          </tr>

          </tbody>
        </table> */}
              <p className='edit-btn'>
                <input
                  type="submit"
                  className="account-save"
                  onClick={handleSaveEdit}
                  //value={isSubmitted ? "Saving" : "Save"}
                 value="Save"
                />
                <button className="btn btn-primary" onClick={handleCancelEdit}>Cancel</button>
              </p>

            </>

          ) : (
            <>
              <div className="edit-delete">
                <span id="edit" onClick={handleEditClick}>
                  Edit
                </span>
                <span>
                  <Link to={`/opportunity/opportunity-detail/${data1.opportunity_id}`}> Cancel</Link>
                </span>
                {data1.status === 'Accepted' ? (
                  <button  onClick={createContract}>Create Contract</button>
                ) : (
                  <></>
                )}
              </div>

              <div className="col-md-12" id='ax.account_details'>
                <h4 className="heading">Quote Detail</h4>
                <hr></hr>
              </div>

              <table class="table table-bordered account-table tables">
                <tbody>
                  <tr>
                    <td id="td-right">
                      <span>Quote Number</span>
                    </td><td>
                      {data1.quote_number}
                    </td>
                    <td id="td-right">
                      <span>Quote Record Type</span>
                    </td>
                    <td>
                      {data1.quote_record_type}
                    </td>

                  </tr>
                  <tr>
                    <td id="td-right">
                      <span>Currency</span>
                    </td><td>
                      {data1.currency}
                    </td>
                    <td id="td-right">
                      <span>Locked</span>
                    </td><td>
                      <input
                        type='checkbox'
                        name='locked'
                        checked={lockedOne} // Use the state variable to determine if the checkbox is checked
                        onChange={handleCheckboxLocked} // Handle the change event
                        className='mx-3' disabled={isButtonEnabled}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td id="td-right">
                      <span>Exchange Rate</span>
                    </td><td>
                      {data1.exchange_rate}
                    </td>
                    <td id="td-right">
                      <span>Opportunity Name</span>
                    </td><td>
                      <Link to={`/opportunity/opportunity-detail/${data1.opportunity_id}`}>{data1.opportunity_name}</Link>
                    </td>
                  </tr><tr>
                    <td id="td-right">
                      <span>Quote Name</span>
                    </td><td>
                      {data1.quote_name}
                    </td>
                    <td id="td-right">
                      <span>Status</span>
                    </td><td>
                      {data1.status || 'Offered'}
                    </td>
                  </tr>
                  <tr>

                  </tr>
                  <tr>
                    <td id="td-right">
                      <span>Account Name</span>
                    </td><td>
                      <Link to={`/accounts/${data1.account_id}`}>{data1.account_name}</Link>
                    </td>
                    <td id="td-right">
                      <span>Supplier</span>
                    </td><td>
                      <Link to={`/accounts/${data1.supplier_id}`}>{data1.supplier}</Link>
                    </td>
                  </tr>
                  <tr>
                    <td id="td-right">
                      <span>Contact Name</span>
                    </td><td>
                      {data1.contact_name}
                    </td>

                    <td id="td-right">
                      <span>Quote Date</span>
                    </td><td>
                      {data1.quote_date}
                    </td>

                  </tr>
                  <tr>
                    <td id="td-right">
                      <span>Expiration Date</span>
                    </td><td>
                      {data1.expiration_date}
                    </td>
                    <td id="td-right">
                      <span>Trader Name</span>
                    </td><td>
                      <Link to={'/user/user-details/' + data1.trader_id}> {data1.trader_name}</Link>
                    </td>


                  </tr>
                  <tr>
                    <td id="td-right">
                      <span>No of Quote shipment</span>
                    </td><td>
                      {data1.no_of_quote_shipment}
                    </td>

                    <td></td>
                  </tr>
                </tbody>
              </table>
              <div className="col-md-12" id='ax.account_details'>
                <h4 className="heading">Product information</h4>
                <hr></hr>
              </div>
              <table class="table table-bordered account-table tables">
                <tbody>
                  <tr>

                    <td id="td-right">
                      <span>Quantity MT
                      </span>
                    </td><td>
                      {data1.quantity_MT}
                    </td>
                    <td id="td-right">
                      <span>Contract</span>
                    </td><td>

                      <Link to={'/contract/contract-details/' + data1.contractId}> {data1.contract}</Link>
                    </td>

                  </tr>
                  <tr>

                    <td id="td-right">
                      <span>Product Description	</span>
                    </td><td>
                      {data1.product_description}
                    </td>
                    <td id="td-right">
                      <span>Total Price</span>
                    </td><td>
                      {data1.total_price}
                    </td>

                  </tr>

                </tbody>
              </table>


              <div className="col-md-12" id='ax.account_details'>
                <h4 className="heading">Market Information</h4>
                <hr></hr>
              </div>
              <table class="table table-bordered account-table tables">
                <tbody>
                  <tr>
                    <td id="td-right">
                      <span>origin</span>
                    </td><td>
                      {data1.origin}
                    </td>

                    <td id="td-right">
                      <span>Commodity</span>
                    </td><td>
                      {data1.commodity}
                    </td>

                  </tr>
                  <tr>
                    <td id="td-right">
                      <span>Destination</span>
                    </td><td>
                      {data1.destination}
                    </td>

                    <td id="td-right">
                      {/* <span>Commodity</span> */}
                    </td><td>
                      {/* {data1.commodity} */}
                    </td>

                  </tr>

                </tbody>
              </table>
              <div className="col-md-12" id='ax.account_details'>
                <h4 className="heading">Price and Price Adjustment</h4>
                <hr></hr>
              </div>
              <table class="table table-bordered account-table tables">
                <tbody>
                  <tr>
                    <td id="td-right">
                      <span>Bonus/Penalty Clause</span>
                    </td><td>
                      {data1.bonus_penalty_clause}
                    </td>
                    <td id="td-right">
                      <span>Rejections</span>
                    </td><td>
                      {data1.rejections}
                    </td>

                  </tr>
                  <tr>

                    <td id="td-right">
                      <span>Payment Term</span>
                    </td><td>
                      {data1.payment_terms}
                    </td>
                    <td id="td-right">
                      <span>General Terms</span>
                    </td><td>
                      {data1.general_terms}
                    </td>

                  </tr>
                </tbody>
              </table>
              <div className="col-md-12" id='ax.account_details'>
                <h4 className="heading">Terms & Conditions</h4>
                <hr></hr>
              </div>
              <table class="table table-bordered account-table tables">
                <tbody>
                  <tr>
                    <td id="td-right">
                      <span>Arbitration</span>
                    </td><td>
                      {data1.arbitration}
                    </td>
                    <td id="td-right">
                      <span>Rwood Surveyor Agent</span>
                    </td><td>
                      {data1.rwood_surveyor_agent}
                    </td>

                  </tr>
                  <tr>
                    <td id="td-right">
                      <span>Applicable Law</span>
                    </td><td>
                      {data1.applicable_law}
                    </td>
                    <td id="td-right">
                      <span>Surveyor 2	</span>
                    </td><td>
                      {data1.surveyor_2}
                    </td>
                  </tr>
                  <tr>
                    <td id="td-right">
                      <span>Offer Validity Terms</span>
                    </td><td>
                      {data1.offer_validity_terms}
                    </td>
                    <td id="td-right">
                      <span>Surveyor 3</span>
                    </td><td>
                      {data1.suveyor_3}
                    </td>
                  </tr>
                  <tr>
                    <td id="td-right">
                      <span>Description</span>
                    </td><td>
                      {data1.description}
                    </td>
                    <td id="td-right">
                      {/* <span>Surveyor 3</span> */}
                    </td><td>
                      {/* {data1.suveyor_3} */}
                    </td>
                  </tr>
                </tbody>
              </table>

              <div className="col-md-12" id='ax.account_details'>
                <h4 className="heading">Prepared For</h4>
                <hr></hr>
              </div>
              <table class="table table-bordered account-table tables">
                <tbody>

                  <tr>

                    <td id="td-right">
                      <span>quote to name</span>
                    </td><td>
                      {data1.quote_to_name}
                    </td>
                    <td id="td-right">
                      <span>additional to name</span>
                    </td><td>
                      {data1.additional_to_name}
                    </td>

                  </tr>
                  <tr>

                    <td id="td-right">
                      <span>quote to street</span>
                    </td><td>
                      {data1.quote_to_street}
                    </td>
                    <td id="td-right">
                      <span>additional to street</span>
                    </td><td>
                      {data1.additional_to_street}
                    </td>

                  </tr>
                  <tr>

                    <td id="td-right">
                      <span>quote to zip postal code</span>
                    </td><td>
                      {data1.quote_to_zip_postal_code}
                    </td>
                    <td id="td-right">
                      <span>additional to zip postal code</span>
                    </td><td>
                      {data1.additional_to_zip_postal_code}
                    </td>

                  </tr>
                  <tr>

                    <td id="td-right">
                      <span>quote to city</span>
                    </td><td>
                      {data1.quote_to_city}
                    </td>
                    <td id="td-right">
                      <span>additional to city</span>
                    </td><td>
                      {data1.additional_to_city}
                    </td>

                  </tr>
                  <tr>

                    <td id="td-right">
                      <span>quote to state province</span>
                    </td><td>
                      {data1.quote_to_state_province}
                    </td>
                    <td id="td-right">
                      <span>additional to state province</span>
                    </td><td>
                      {data1.additional_to_state_province}
                    </td>

                  </tr>
                  <tr>

                    <td id="td-right">
                      <span>quote to country</span>
                    </td><td>
                      {data1.quote_to_country}
                    </td>
                    <td id="td-right">
                      <span>additional to country</span>
                    </td><td>
                      {data1.additional_to_country}
                    </td>

                  </tr>
                  <tr>

                    <td id="td-right">
                      <span>email</span>
                    </td><td>
                      {data1.email}
                    </td>
                    <td id="td-right">
                      {/* <span>Additionasdsdame</span> */}
                    </td><td>
                      {/* {data1.additional_to_name} */}
                    </td>

                  </tr>
                  <tr>

                    <td id="td-right">
                      <span>Phone</span>
                    </td><td>
                      {data1.phone}
                    </td>
                    <td id="td-right">
                      {/* <span>Additionasdsdame</span> */}
                    </td><td>
                      {/* {data1.additional_to_name} */}
                    </td>

                  </tr>
                  <tr>

                    <td id="td-right">
                      <span>Fax</span>
                    </td><td>
                      {data1.phone_fax}
                    </td>
                    <td id="td-right">
                      {/* <span>Additionasdsdame</span> */}
                    </td><td>
                      {/* {data1.additional_to_name} */}
                    </td>

                  </tr>
                </tbody>
              </table>

              <div className="col-md-12" id='ax.account_details'>
                <h4 className="heading">Address Information</h4>
                <hr></hr>
              </div>
              <table class="table table-bordered account-table tables">
                <tbody>
                  <tr>
                    <td id="td-right">
                      <span>bill to name</span>
                    </td><td>
                      {data1.bill_to_name}
                    </td>
                    <td id="td-right">
                      <span>ship to name</span>
                    </td><td>
                      {data1.ship_to_name}
                    </td>

                  </tr>
                  <tr>
                    <td id="td-right">
                      <span>bill to street</span>
                    </td><td>
                      {data1.bill_to_street}
                    </td>
                    <td id="td-right">
                      <span>ship to street</span>
                    </td><td>
                      {data1.ship_to_street}
                    </td>

                  </tr>
                  <tr>
                    <td id="td-right">
                      <span>bill to zip postal code</span>
                    </td><td>
                      {data1.bill_to_zip_postal_code}
                    </td>
                    <td id="td-right">
                      <span>ship to zip_postal code</span>
                    </td><td>
                      {data1.ship_to_zip_postal_code}
                    </td>

                  </tr>
                  <tr>
                    <td id="td-right">
                      <span>bill to city</span>
                    </td><td>
                      {data1.bill_to_city}
                    </td>
                    <td id="td-right">
                      <span>ship to city</span>
                    </td><td>
                      {data1.ship_to_city}
                    </td>

                  </tr>
                  <tr>
                    <td id="td-right">
                      <span>bill to state province</span>
                    </td><td>
                      {data1.bill_to_state_province}
                    </td>
                    <td id="td-right">
                      <span>ship to state province</span>
                    </td><td>
                      {data1.ship_to_state_province}
                    </td>

                  </tr>
                  <tr>
                    <td id="td-right">
                      <span>bill to country</span>
                    </td><td>
                      {data1.bill_to_country}
                    </td>
                    <td id="td-right">
                      <span>ship to country</span>
                    </td><td>
                      {data1.ship_to_country}
                    </td>

                  </tr>
                </tbody>
              </table>
              {/* <div className="col-md-12" id='ax.account_details'>
   <h4 className="heading">System Information</h4>
   <hr></hr>
   </div>
        <table class="table table-bordered account-table tables">
         <tbody>
          <tr>
          <td id="td-right">
                <span>Created By</span>
                </td><td>
                {data1.created_by}
              </td>
              <td id="td-right">
                <span>Modified By</span>
                </td><td>
                {data1.modified_by}
              </td>

          </tr>
           </tbody>
        </table> */}

<Row id="table-styles">
                    <div className="col-md-12" id='head'>
                      <h4 className="heading"><span>Quote Shipment</span>
                        {/* <span><Popup trigger={<span className="button add-accountrecord"> <FaPlus/> New </span>} modal>

    <form onSubmit={handleSubmittcf} className="add-accounts">
     <div className='form-group'>
     <label>TCF Record Type</label><br/>
     <select name='account_record' className='form-control' onChange={handleChangetcf} value={formData.account_record}>
       <option >--Select TCF Record Type--</option>
       <option value='Buyer - Spot'>Buyer - Spot</option>
       <option value='Buyer - Long Term'>Buyer - Long Term</option>
       </select>
       </div>
       <input type='submit' className="btn btn-primary " value='Submit'/>

       </form>
</Popup></span> */}
                      </h4>
                      <div id='inner-heading'>

                      </div>
                      <hr></hr>
                    </div>
                    <table id='quote' className="">
                      <thead>
                        <tr>
                          <th>Quote Shipment Code</th>
                          <th>Quantity</th>
                          <th>Laycan</th>
                          <th>Pricing</th>
                          <th>Quanity Info	</th>
                          <th>Vessel Type	</th>
                          <th>Shipping Term
                          </th>
                        </tr>
                      </thead>

                      {quote.length > 0 ?

                        <tbody>
                          {
                            quote.map((x, i) => (
                              <tr>
                                <td><Link to={'/quote-shipment/detail/' + x.id}>{x.quote_shipment_code}</Link></td>
                                <td>{x.quote_shipment_quantity}</td>
                                <td>{x.quote_shipment_laycan}</td>
                                <td>{x.quote_shipment_pricing}</td>
                                <td>{x.quote_shipment_quantity_info
                                }</td>

                                <td>{x.quote_shipment_vessel_type
                                }</td>
                                <td>{x.quote_shipment_shipping_term
                                }</td>
                              </tr>
                            ))}
                        </tbody>
                        : <tbody>
                          <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>No data available</td>
                            <td></td>
                            <td></td><td></td>

                          </tr>
                        </tbody>
                      }
                    </table>

                  </Row>
                      <Row id="table-styles">
                    <div className="col-md-12" id='head'>
                      <h4 className="heading"><span>Quote Product</span>
                        <span>
                          <Link to={"/quote-product/select/" + id}>
                            <i className="fe fe-download-cloud me-2"></i>
                              New
                          </Link>
                        </span>
                        {/* <span><Popup trigger={<span className="button add-accountrecord"> <FaPlus/> New </span>} modal>

    <form onSubmit={handleSubmittcf} className="add-accounts">
     <div className='form-group'>
     <label>TCF Record Type</label><br/>
     <select name='account_record' className='form-control' onChange={handleChangetcf} value={formData.account_record}>
       <option >--Select TCF Record Type--</option>
       <option value='Buyer - Spot'>Buyer - Spot</option>
       <option value='Buyer - Long Term'>Buyer - Long Term</option>
       </select>
       </div>
       <input type='submit' className="btn btn-primary " value='Submit'/>

       </form>
</Popup></span> */}
                      </h4>
                      <div id='inner-heading'>

                      </div>
                      <hr></hr>
                    </div>
                    <table id='quote-product' className="">
                      <thead>
                        <tr>
                          <th>Quote Product Name	</th>
                          <th>Quote Shipment	</th>
                          <th>Gross As Received Typical	</th>
                          <th>Ash Content Typical	</th>
                          <th>Total Moisture Typical	</th>
                          <th>Total Sulphur Typical ARB
                          </th>
                        </tr>
                      </thead>

                      {quoteProduct.length > 0 ?

                        <tbody>
                          {
                            quoteProduct.map((y, i) => (
                              <tr>

                                <td><span id='codes'><Link to={'/opportunity/quote-detail/' + y.id}>{y.quote_product_code}</Link></span></td>
                                <td>{y.quote_shipment}</td>
                                <td>{y.gross_as_received_typical	}</td>
                                <td>{y.ash_typical	}</td>
                                <td>{y.total_moisture_typical	}</td>
                                <td>{y.sulphur_typical_arb
	}</td>
                              </tr>
                            ))}
                        </tbody>
                        : <tbody>
                          <tr>
                            <td></td>
                            <td></td>
                            <td>No data available</td>
                            <td></td>
                            <td></td>
                            <td></td>

                          </tr>
                        </tbody>
                      }
                    </table>

                  </Row>
                  <Row id="table-styles">
     <div className="col-md-12" id="head">
       <h4 className="heading">
         <span>Quote Clauses
         </span>
         <span>
           <i className=""></i> New Quote Clauses
           </span>

       </h4>
       <hr>
       </hr>
     </div>
     <table id="history" className="">
       <tr>
         <td>
           <p class="no-records"> No records to display </p>
         </td>
       </tr>
     </table>
   </Row>
   <Row id="table-styles">
                    <div className="col-md-12" id='head'>
                      <h4 className="heading"><span>Trade Confirmation Form</span>

                      </h4>
                      <div id='inner-heading'>

                      </div>
                      <hr></hr>
                    </div>
                    <table id='tcf' className="">
                      <thead>
                        <tr>
                          <th>TCF No</th>
                          <th>tcf status</th>
                          <th>Buyer</th>
                          <th>supplier</th>
                          <th>Quantity (MT)	</th>
                          <th>Contractual GAR (P)	</th>
                          <th>Actual GAR (P)</th>
                          <th>Actual GAR (S)</th>
                        </tr>
                      </thead>

                      {tcf.length > 0 ?

                        <tbody>
                          {
                            tcf.map((x, i) => (
                              <tr>
                                <td><Link to={"/tcf/tcf-details/" + x.id}>{x.tcf_no}</Link></td>
                                <td>{x.tcf_status}</td>
                                <td><Link to={"/accounts/" + x.account_id}>{x.buyer}</Link></td><td><Link to={"/accounts/" + x.supplier_id}>{x.supplier}</Link></td>
                                <td>{x.quantity_MT}</td>
                                <td>{x.contractual_GAR_P}</td>
                                <td>{x.actual_gar_p}</td>
                                <td>{x.actual_gar_s}</td>
                              </tr>
                            ))}
                        </tbody>
                        : <tbody>
                          <tr>
                            <td></td>
                            <td></td><td></td>
                            <td></td>
                            <td>No data available</td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        </tbody>
                      }
                    </table>

                  </Row>

   <Row id="table-styles">
     <div className="col-md-12" id="head">
       <h4 className="heading">
         <span>Quote PDFs
         </span>

       </h4>
       <hr>
       </hr>
     </div>
     <table id="history" className="">
       <tr>
         <td>
           <p class="no-records"> No records to display </p>
         </td>
       </tr>
     </table>
   </Row>
   <Row id="table-styles">
     <div className="col-md-12" id="head">
       <h4 className="heading">
         <span>Notes
         </span>
         <span>New Note
         </span>
       </h4>
       <hr>
       </hr>
     </div>
     <table id="history" className="">
       <tr>
         <td>
           <p class="no-records"> No records to display </p>
         </td>
       </tr>
     </table>
   </Row>
   <Row id="table-styles">
     <div className="col-md-12" id="head">
       <h4 className="heading">
         <span>Files
         </span>
         <span>Upload Files         </span>
       </h4>
       <hr>
       </hr>
     </div>
     <table id="history" className="">
       <tr>
         <td>
           <p class="no-records"> No records to display </p>
         </td>
       </tr>
     </table>
   </Row>

   <Row id="table-styles">
     <div className="col-md-12" id="head">
       <h4 className="heading">
         <span>Open Activities

         </span>
         <span>New Task       </span>
         <span>New Event       </span>
       </h4>
       <hr>
       </hr>
     </div>
     <table id="history" className="">
       <tr>
         <td>
           <p class="no-records"> No records to display </p>
         </td>
       </tr>
     </table>
   </Row>
   <Row id="table-styles">
     <div className="col-md-12" id="head">
       <h4 className="heading">
         <span>Notes & Attachments</span>
         <span>
            New Note </span>
         <span>
           Attach File </span>
       </h4>
       <hr>
       </hr>
     </div>
     <table id="history" className="">
       <tr>
         <td>
           <p class="no-records"> No records to display </p>
         </td>
       </tr>
     </table>
   </Row>
   <Row id="table-styles">
     <div className="col-md-12" id="head">
       <h4 className="heading">
         <span>Activity History
         </span>
         <span>
            Log a Call </span>
         <span>
           Mail Merge </span>
       </h4>
       <hr>
       </hr>
     </div>
     <table id="history" className="">
       <tr>
         <td>
           <p class="no-records"> No records to display </p>
         </td>
       </tr>
     </table>
   </Row>

   <Row id="table-styles">
     <div className="col-md-12" id="head">
       <h4 className="heading">
         <span>Approval History

         </span>
         <span>
            Submit for Approval</span>

       </h4>
       <hr>
       </hr>
     </div>
     <table id="history" className="">
       <tr>
         <td>
           <p class="no-records"> No records to display </p>
         </td>
       </tr>
     </table>
   </Row>
            </>
          )}</div>
      ) : (
        <>
          {data1.quote_record_type === 'Buyer - Spot' ? (
            <div className="tasks edit-view">
               <div id="messageContainer"></div>

              {isEditMode ? (
                <>
                  <p className='edit-btn'>
                    <input
                      type="submit"
                      className="account-save"
                      onClick={handleSaveEdit}
                      //value={isSubmitted ? "Saving" : "Save"}
                     value="Save"
                    />
                    <button className="btn btn-primary" onClick={handleCancelEdit}>Cancel</button>
                  </p>
                  <table class="table table-bordered account-table tables">
                    <tbody>

                      <tr>
                        <td>
                          <span>Quote Name</span>
                          <br />
                          <input
                            type="text" className='form-control'
                            name="quote_name"
                            defaultValue={data1.quote_name || ''}
                            onChange={handleChange}
                          />
                        </td>
                        <td>
                          <span>Quote Number</span>
                          <br />
                          <input
                            type="text" className='form-control'
                            name="quote_number"
                            disabled
                            defaultValue={data1.quote_number || ''}
                            onChange={handleChange}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>Currency</span>
                          <br />

                          <select name="currency"
                            defaultValue={data1.currency || ''}
                            onChange={handleChange} className="form-control">
                            <option defaultValue='' >---None---</option>
                            <option defaultValue='USD' >USD</option>
                            <option defaultValue='IDR' >IDR</option>
                          </select>
                        </td>
                        <td>
                          <span>Quote Record Type</span>
                          <br />
                          <input
                            type="text" className='form-control'
                            name="quote_record_type"
                            disabled
                            defaultValue={data1.quote_record_type || ''}
                            onChange={handleChange}
                          />
                        </td>

                      </tr>
                      <tr>
                        <td>
                          <span>Exchange Rate</span>
                          <br />
                          <input
                            type="text" className='form-control '
                            name="exchange_rate"

                            defaultValue={data1.exchange_rate || ''}
                            onChange={handleChange}
                          />
                        </td>
                        <td>

                        </td>


                      </tr>
                      <tr>

                        <td>
                          <span>Opportunity Name</span>
                          <br />

                          <select className='form-control'
                            name="opportunity_name"
                            defaultValue={data1.opportunity_name || ''}>
                            <option value='' >Select</option>
                            {
                              opp.map((x) => {
                                return (
                                  <option value={x.opportunity_name}>{x.opportunity_name}</option>
                                )
                              })
                            }
                          </select>
                        </td>
                        <td>
                          <span>Status</span>
                          <br />
                          <select
                            name="status"
                            className='form-control'
                            defaultValue={data1.status || ''}
                            onChange={handleChange}
                          >
                            <option value='Offered'>Offered</option>
                            <option value='Accepted'>Accepted</option>
                            <option value='Rejected'>Rejected</option>
                            <option value='Sold'>Sold</option>
                          </select>
                        </td>


                      </tr>
                      <tr>
                        <td>
                          <span>Account Name</span>
                          <br />
                          <input
                            type="text" className='form-control record-type'
                            name="account_name"
                            disabled
                            defaultValue={data1.account_name || ''}
                            onChange={handleChange}
                          />
                        </td>
                        <td>

                        </td>

                      </tr>
                      <tr>
                        <td>
                          <span>Supplier</span>
                          <br />
                          <input
                            type="text" className='form-control'
                            name="supplier"
                            defaultValue={data1.supplier || ''}
                            onChange={handleChange}
                          />
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>
                          <span>Trader Name</span>
                          <br />


                          <select className='form-control'
                            name="trader_name"
                            defaultValue={data1.trader_name || ''} onChange={handleChange}>
                            <option value='' >Select</option>
                            {
                              traderData.map((x, i) => {
                                return (
                                  <option key={i} value={x.rwood_username}>
                                    {x.rwood_username}
                                  </option>
                                )
                              })
                            }
                          </select>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>
                          <span>quote to name</span>
                          <br />
                          <input
                            type="text" className='form-control'
                            name="quote_to_name"
                            defaultValue={data1.quote_to_name || ''}
                            onChange={handleChange}
                          />
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>
                          <span>Quote Date</span>
                          <br />
                          <DatePicker
                            selected={start}
                            onChange={(date) => setStart(date)}
                            customInput={<ExampleCustomInput2 />}
                            dayClassName={() => "example-datepicker-day-class"}
                            popperClassName="edit-date"
                            todayButton="TODAY"
                            dateFormat="yyyy-MM-dd"
                            placeholderText="Choose Date"
                          />
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>
                          <span>Expiration Date</span>
                          <br />

                          <DatePicker
                            selected={effective}
                            onChange={(date) => setEffective(date)}
                            customInput={<ExampleCustomInput1 />}
                            dayClassName={() => "example-datepicker-day-class"}
                            popperClassName="edit-date"
                            todayButton="TODAY"
                            dateFormat="yyyy-MM-dd"
                            placeholderText="Choose Date"
                          />
                        </td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="col-md-12" id='ax.account_details'>
                    <h4 className="heading">Product information</h4>
                    <hr></hr>
                  </div>
                  <table class="table table-bordered account-table tables">
                    <tbody>
                      <tr>
                        <td>
                          <span>Origin</span>
                          <br />
                          <input
                            type="text" className='form-control'
                            name="origin"
                            defaultValue={data1.origin || ''}
                            onChange={handleChange}
                          />
                        </td>
                        <td>
                          <span>Quantity MT</span>
                          <br />
                          <input
                            type="text" className='form-control'
                            name="quantity_MT"
                            defaultValue={data1.quantity_MT || ''}
                            onChange={handleChange}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>Commodity</span>
                          <br />
                          <select className='form-control'
                            name="commodity"
                            defaultValue={data1.commodity || ''}
                            onChange={handleChange}>
                            <option>---None---</option>
                            <option defaultValue="Indonesian Steam Coal in Bulk (&quot;Coal&quot;)">Indonesian Steam Coal in Bulk ("Coal")</option><option defaultValue="US Coal">US Coal</option><option defaultValue="Russia Coal">Russia Coal</option><option defaultValue="SA Coal">SA Coal</option><option defaultValue="Clinker in Bulk">Clinker in Bulk</option><option defaultValue="Australian Steam Coal">Australian Steam Coal</option>
                          </select>
                        </td>
                        <td>
                          <span>Product Description</span>
                          <br />
                          <input
                            type="text" className='form-control'
                            name="product_description"
                            defaultValue={data1.product_description || ''}
                            onChange={handleChange}
                          />
                        </td>

                      </tr>
                      <tr>
                        <td>
                          <span>Contract</span>
                          <br />
                          <input
                            type="text" className='form-control'
                            name="contract"
                            defaultValue={data1.contract || ''}
                            onChange={handleChange}
                          />
                        </td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="col-md-12" id='ax.account_details'>
                    <h4 className="heading">Market Information</h4>
                    <hr></hr>
                  </div>
                  <table class="table table-bordered account-table tables">
                    <tbody>

                      <tr>
                        <td>
                          <span>Destination</span>
                          <br />

                          <input
                            type="text" className='form-control'
                            name="destination"
                            defaultValue={data1.destination || ''}
                            onChange={handleChange}
                          />
                        </td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="col-md-12" id='ax.account_details'>
                    <h4 className="heading">Price and Price Adjustment</h4>
                    <hr></hr>
                  </div>
                  <table class="table table-bordered account-table tables">
                    <tbody>
                      <tr>
                        <td>
                          <span>Bonus/Penalty Clause</span>
                          <br />

                          <textarea className='form-control'
                            name="bonus_penalty_clause"
                            defaultValue={data1.bonus_penalty_clause || ''}
                            onChange={handleChange}>

                          </textarea>

                        </td>
                        <td>
                          <span>Rejections</span>
                          <br />

                          <textarea className='form-control'
                            name="rejections"
                            defaultValue={data1.rejections || ''}
                            onChange={handleChange}>
                          </textarea>
                        </td>

                      </tr>
                      <tr >
                        <td>
                          <span>Payment Term</span>
                          <br />


                          <textarea className='form-control'
                            name="payment_terms"
                            defaultValue={data1.payment_terms || ''}
                            onChange={handleChange}>
                          </textarea>
                        </td>
                        <td>
                          <span>General Terms</span>
                          <br />


                          <textarea className='form-control'
                            name="general_terms"
                            defaultValue={data1.general_terms || ''}
                            onChange={handleChange}>
                          </textarea>
                        </td>

                      </tr>
                    </tbody>
                  </table>
                  <div className="col-md-12" id='ax.account_details'>
                    <h4 className="heading">Terms & Conditions</h4>
                    <hr></hr>
                  </div>
                  <table class="table table-bordered account-table tables">
                    <tbody>
                      <tr>
                        <td>
                          <span>Arbitration</span>
                          <br />

                          <select className='form-control'
                            name="arbitration"
                            defaultValue={data1.arbitration || ''}
                            onChange={handleChange}>
                            <option value="">--None--</option><option value="In Singapore as per SIAC">In Singapore as per SIAC</option><option value="Singapore Chamber of Maritime Arbitration (SCMA)">Singapore Chamber of Maritime Arbitration (SCMA)</option><option value="Laws of England and Wales">Laws of England and Wales</option><option value="Rules of Badan Arbitrase Nasional Indonesia (BANI)">Rules of Badan Arbitrase Nasional Indonesia (BANI)</option>
                          </select>
                        </td>
                        <td>
                          <span>Rwood Surveyor Agent</span>
                          <br />
                          <input
                            type="text" className='form-control'
                            name="rwood_surveyor_agent"
                            defaultValue={data1.rwood_surveyor_agent || ''}
                            onChange={handleChange}
                          />
                        </td>

                      </tr>
                      <tr>
                        <td>
                          <span>Applicable Law</span>
                          <br />

                          <select className='form-control'
                            name="applicable_law"
                            defaultValue={data1.applicable_law || ''}
                            onChange={handleChange}>
                            <option value="">--None--</option><option value="English Law">English Law</option><option value="Singapore Law">Singapore Law</option><option value="Indonesian Law">Indonesian Law</option>
                          </select>
                        </td>
                        <td>
                          <span>Surveyor 2	</span>
                          <br />

                          <select className='form-control'
                            name="surveyor_2"
                            defaultValue={data1.surveyor_2 || ''}
                            onChange={handleChange}>
                            <option>---None---</option>
                            {surveyors.map((x) => {
                              return (
                                <option value={x.account_name}>{x.account_name}</option>
                              );
                            })}
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>Surveyor 3</span>
                          <br />

                          <select className='form-control'
                            name="suveyor_3"
                            defaultValue={data1.suveyor_3 || ''}
                            onChange={handleChange}>
                            <option>---None---</option>
                            {surveyors.map((x) => {
                              return (
                                <option value={x.account_name}>{x.account_name}</option>
                              );
                            })}
                          </select>
                        </td>   <td>
                          <span>Offer Validity Terms</span>
                          <br />

                          <textarea className='form-control'
                            name="offer_validity_terms"
                            defaultValue={data1.offer_validity_terms || ''}
                            onChange={handleChange}>

                          </textarea>
                        </td>

                      </tr>
                      <tr>
                        <td>
                          <span>Description</span>
                          <br />


                          <textarea className='form-control'
                            name="description"
                            defaultValue={data1.description || ''}
                            onChange={handleChange}>

                          </textarea>
                        </td>
                        <td>

                        </td>

                      </tr>
                    </tbody>
                  </table>

                  <div className="col-md-12" id='ax.account_details'>
                    <h4 className="heading">Prepared For</h4>
                    <hr></hr>
                  </div>
                  <table class="table table-bordered account-table tables">
                    <tbody>
                      <tr>
                        <td>
                          <span>Contact Name</span>
                          <br />
                          <input
                            type="text" className='form-control'
                            name="contact_name"
                            defaultValue={data1.contact_name || ''}
                            onChange={handleChange}
                          />
                        </td>
                        <td>
                          <span>Additional To Name</span>
                          <br />
                          <input
                            type="text" className='form-control'
                            name="additional_to_name"
                            defaultValue={data1.additional_to_name || ''}
                            onChange={handleChange}
                          />
                        </td>

                      </tr>
                      <tr>

                        <td>
                          <span>quote to street</span>
                          <br />
                          <input
                            type="text" className='form-control'
                            name="quote_to_street"
                            defaultValue={data1.quote_to_street || ''}
                            onChange={handleChange}
                          />
                        </td>
                        <td>
                          <span>additional to street</span>
                          <br />
                          {/* {data1.additional_to} */}
                          <input
                            type="text" className='form-control'
                            name="additional_to_street"
                            defaultValue={data1.additional_to_street || ''}
                            onChange={handleChange}
                          />
                        </td>
                      </tr>
                      <tr>

                        <td>
                          <span>Quote To Zip/Postal Code	</span>
                          <br />
                          {/* {data1.phone} */}
                          <input
                            type="text" className='form-control'
                            name="quote_to_zip_postal_code"
                            defaultValue={data1.quote_to_zip_postal_code || ''}
                            onChange={handleChange}
                          />
                        </td>
                        <td>
                          <span>Additional To Zip/Postal Code	</span>
                          <br />
                          {/* {data1.phone_fax} */}
                          <input
                            type="text" className='form-control'
                            name="additional_to_zip_postal_code"
                            defaultValue={data1.additional_to_zip_postal_code || ''}
                            onChange={handleChange}
                          />
                        </td>
                      </tr>
                      <tr>

                        <td>
                          <span>quote to city	</span>
                          <br />
                          {/* {data1.phone_fax} */}
                          <input
                            type="text" className='form-control'
                            name="quote_to_city"
                            defaultValue={data1.quote_to_city || ''}
                            onChange={handleChange}
                          />
                        </td>
                        <td>
                          <span>Additional To City		</span>
                          <br />
                          {/* {data1.phone_fax} */}
                          <input
                            type="text" className='form-control'
                            name="additional_to_city"
                            defaultValue={data1.additional_to_city || ''}
                            onChange={handleChange}
                          />
                        </td>
                      </tr>
                      <tr>

                        <td>
                          <span>Quote To State/Province	</span>
                          <br />
                          {/* {data1.phone_fax} */}
                          <input
                            type="text" className='form-control'
                            name="quote_to_state_province"
                            defaultValue={data1.quote_to_state_province || ''}
                            onChange={handleChange}
                          />
                        </td>

                        <td>
                          <span>Additional To State/Province		</span>
                          <br />
                          {/* {data1.phone_fax} */}
                          <input
                            type="text" className='form-control'
                            name="additional_to_state_province"
                            defaultValue={data1.additional_to_state_province || ''}
                            onChange={handleChange}
                          />
                        </td>
                      </tr>
                      <tr>


                        <td>
                          <span>Quote To Country	</span>
                          <br />
                          {/* {data1.phone_fax} */}
                          <input
                            type="text" className='form-control'
                            name="quote_to_country"
                            defaultValue={data1.quote_to_country || ''}
                            onChange={handleChange}
                          />
                        </td>
                        <td>
                          <span>Additional To Country	</span>
                          <br />
                          {/* {data1.phone_fax} */}
                          <input
                            type="text" className='form-control'
                            name="additional_to_country"
                            defaultValue={data1.additional_to_country || ''}
                            onChange={handleChange}
                          />
                        </td>
                      </tr>
                      <tr>



                      </tr>
                      <tr>
                        <td>
                          <span>Email</span>
                          <br />
                          {/* {data1.phone_fax} */}
                          <input
                            type="text" className='form-control'
                            name="email"
                            defaultValue={data1.email || ''}
                            onChange={handleChange}
                          />
                        </td>
                        <td>

                        </td>

                      </tr>
                      <tr>
                        <td>
                          <span>Phone</span>
                          <br />
                          {/* {data1.phone_fax} */}
                          <input
                            type="text" className='form-control'
                            name="phone"
                            defaultValue={data1.phone || ''}
                            onChange={handleChange}
                          />
                        </td>
                        <td>

                        </td>

                      </tr>
                      <tr>
                        <td>
                          <span>Fax</span>
                          <br />
                          {/* {data1.phone_fax} */}
                          <input
                            type="text" className='form-control'
                            name="fax"
                            defaultValue={data1.fax || ''}
                            onChange={handleChange}
                          />
                        </td>
                        <td>

                        </td>

                      </tr>
                    </tbody>
                  </table>

                  <div className="col-md-12" id='ax.account_details'>
                    <h4 className="heading">Address Information</h4>
                    <hr></hr>
                  </div>
                  <table class="table table-bordered account-table tables">
                    <tbody>
                      <tr>
                        <td>
                          <span>Bill To Name</span>
                          <br />
                          {/* {data1.bill_to_name} */}
                          <input
                            type="text" className='form-control'
                            name="bill_to_name"
                            defaultValue={data1.bill_to_name || ''}
                            onChange={handleChange}
                          />
                        </td>
                        <td>
                          <span>Ship To Name</span>
                          <br />
                          {/* {data1.ship_to_name} */}
                          <input
                            type="text" className='form-control'
                            name="ship_to_name"
                            defaultValue={data1.ship_to_name || ''}
                            onChange={handleChange}
                          />
                        </td>

                      </tr>
                      <tr>
                        <td>
                          <span>bill to street</span>
                          <br />
                          {/* {data1.bill_to} */}
                          <input
                            type="text" className='form-control'
                            name="bill_to_street"
                            defaultValue={data1.bill_to_street || ''}
                            onChange={handleChange}
                          />
                        </td>
                        <td>
                          <span>Ship To Street</span>
                          <br />
                          <input
                            type="text" className='form-control'
                            name="ship_to_street"
                            defaultValue={data1.ship_to_street || ''}
                            onChange={handleChange}
                          />
                        </td>

                      </tr>
                      <tr>
                        <td>
                          <span>Bill To Zip/Postal Code	</span>
                          <br />
                          {/* {data1.bill_to} */}
                          <input
                            type="text" className='form-control'
                            name="bill_to_zip_postal_code"
                            defaultValue={data1.bill_to_zip_postal_code || ''}
                            onChange={handleChange}
                          />
                        </td>
                        <td>
                          <span>Ship To Zip/Postal Code	</span>
                          <br />
                          <input
                            type="text" className='form-control'
                            name="ship_to_zip_postal_code"
                            defaultValue={data1.ship_to_zip_postal_code || ''}
                            onChange={handleChange}
                          />
                        </td>

                      </tr>
                      <tr>
                        <td>
                          <span>Bill To City	</span>
                          <br />
                          {/* {data1.bill_to} */}
                          <input
                            type="text" className='form-control'
                            name="bill_to_city"
                            defaultValue={data1.bill_to_city || ''}
                            onChange={handleChange}
                          />
                        </td>
                        <td>
                          <span>Ship To City	</span>
                          <br />
                          <input
                            type="text" className='form-control'
                            name="ship_to_city"
                            defaultValue={data1.ship_to_city || ''}
                            onChange={handleChange}
                          />
                        </td>

                      </tr>
                      <tr>
                        <td>
                          <span>Bill To State/Province	</span>
                          <br />
                          {/* {data1.bill_to} */}
                          <input
                            type="text" className='form-control'
                            name="bill_to_state_province"
                            defaultValue={data1.bill_to_state_province || ''}
                            onChange={handleChange}
                          />
                        </td>
                        <td>
                          <span>Ship To State/Province	</span>
                          <br />
                          <input
                            type="text" className='form-control'
                            name="ship_to_state_province"
                            defaultValue={data1.ship_to_state_province || ''}
                            onChange={handleChange}
                          />
                        </td>

                      </tr>

                      <tr>
                        <td>
                          <span>Bill To Country	</span>
                          <br />
                          {/* {data1.bill_to} */}
                          <input
                            type="text" className='form-control'
                            name="bill_to_country"
                            defaultValue={data1.bill_to_country || ''}
                            onChange={handleChange}
                          />
                        </td>
                        <td>
                          <span>Ship To Country	</span>
                          <br />
                          <input
                            type="text" className='form-control'
                            name="ship_to_country"
                            defaultValue={data1.ship_to_country || ''}
                            onChange={handleChange}
                          />
                        </td>

                      </tr>
                    </tbody>
                  </table>
                  {/* <div className="col-md-12" id='ax.account_details'>
   <h4 className="heading">System Information</h4>
   <hr></hr>
   </div>
        <table class="table table-bordered account-table tables">
         <tbody>
          <tr>
          <td>
                <span>Created By</span>
                <br />
                <input
              type="text" className='form-control'
              name="created_by"
              defaultValue={data1.created_by || ''}
              onChange={handleChange}
            />
              </td>
              <td>
                <span>Modified By</span>
                <br />
                <input
              type="text" className='form-control'
              name="modified_by"
              defaultValue={data1.modified_by || ''}
              onChange={handleChange}
            />
              </td>
          </tr>

          </tbody>
        </table> */}
                  <p className='edit-btn'>
                    <input
                      type="submit"
                      className="account-save"
                      onClick={handleSaveEdit}
                      //value={isSubmitted ? "Saving" : "Save"}
                     value="Save"
                    />
                    <button className="btn btn-primary" onClick={handleCancelEdit}>Cancel</button>
                  </p>

                </>

              ) : (
                <>
                  <div className="edit-delete">
                    <span id="edit" onClick={handleEditClick}>
                      Edit
                    </span>
                    <span>
                      <Link to={`/opportunity/opportunity-detail/${data1.opportunity_id}`}> Cancel</Link>
                    </span>
                    {data1.status === 'Accepted' ? (
                      <span onClick={createContract}>Create Contract</span>
                    ) : (
                      <></>
                    )}
                  </div>

                  <div className="col-md-12" id='ax.account_details'>
                    <h4 className="heading">Quote Detail</h4>
                    <hr></hr>
                  </div>

                  <table class="table table-bordered account-table tables">
                    <tbody>
                    <tr>
                    <td id="td-right">
                          <span>Quote Name</span>
                        </td><td>
                          {data1.quote_name}
                        </td>
                        <td id="td-right">
                          <span>Quote Number</span>
                        </td><td>
                          {data1.quote_number}
                        </td>
                    </tr>
                    <tr>
                    <td id="td-right">
                          <span>Currency</span>
                        </td><td>
                          {data1.currency}
                        </td>
                        <td id="td-right">
                          <span>Quote Record Type</span>
                        </td>
                        <td>
                          {data1.quote_record_type}
                        </td>
                    </tr>
                    <tr>
                    <td id="td-right">
                          <span>Exchange Rate</span>
                        </td><td>
                          {data1.exchange_rate}
                        </td>
                        <td id="td-right">
                          <span>Locked</span>
                        </td><td>
                          <input
                            type='checkbox'
                            name='locked'
                            checked={lockedOne} // Use the state variable to determine if the checkbox is checked
                            onChange={handleCheckboxLocked} // Handle the change event
                            className='mx-3' disabled={isButtonEnabled}
                          />
                        </td>
                    </tr>
                    <tr>
                    <td id="td-right">
                          <span>Opportunity Name</span>
                        </td><td>
                          <Link to={`/opportunity/opportunity-detail/${data1.opportunity_id}`}>{data1.opportunity_name}</Link>
                        </td>
                        <td id="td-right">
                          <span>Status</span>
                        </td><td>
                          {data1.status || 'Offered'}
                        </td>
                    </tr>
                    <tr>
                    <td id="td-right">
                          <span>Account Name</span>
                        </td><td>
                          <Link to={`/accounts/${data1.account_id}`}>{data1.account_name}</Link>
                        </td>
                        <td id="td-right">
                          <span>No of Product
                          </span>
                        </td><td>
                          {data1.no_of_product
                          }
                        </td>
                    </tr>
                    <tr>
                    <td id="td-right">
                          <span>Supplier</span>
                        </td><td>
                          <Link to={`/accounts/${data1.supplier_id}`}>{data1.supplier}</Link>
                        </td>
                        <td id="td-right">
                          <span>No of Quote shipment</span>
                        </td><td>
                          {data1.no_of_quote_shipment}
                        </td>
                    </tr>
                    <tr>
                    <td id="td-right">
                          <span>Trader Name</span>
                        </td><td>
                          <Link to={'/user/user-details/' + data1.trader_id}> {data1.trader_name}</Link>
                        </td>
                        <td id="td-right">
                        </td><td>
                        </td>
                    </tr>
                    <tr>
                    <td id="td-right">
                          <span>Quote To Name
                          </span>
                        </td><td>
                           {data1.quote_to_name
                           }
                        </td>
                        <td id="td-right">
                        </td><td>
                        </td>
                    </tr>
                    <tr>
                    <td id="td-right">
                          <span>Quote Date</span>
                        </td><td>
                          {data1.quote_date}
                        </td>
                        <td id="td-right">
                        </td><td>
                        </td>
                    </tr>
                    <tr>
                    <td id="td-right">
                          <span>Expiration Date</span>
                        </td><td>
                          {data1.expiration_date}
                        </td>
                        <td id="td-right">
                        </td><td>
                        </td>
                    </tr>
                     </tbody>
                  </table>
                  <div className="col-md-12" id='ax.account_details'>
                    <h4 className="heading">Product information</h4>
                    <hr></hr>
                  </div>
                  <table class="table table-bordered account-table tables">
                    <tbody>
                      <tr>
                        <td id="td-right">
                          <span>origin
                          </span>
                        </td><td>
                          {data1.origin}
                        </td>
                        <td id="td-right">
                          <span>Quantity MT
                          </span>
                        </td><td>
                          {data1.quantity_MT}
                        </td>
                      </tr>
                      <tr>
                        <td id="td-right">
                          <span>Commodity</span>
                        </td><td>
                          {data1.commodity}
                        </td>
                        <td id="td-right">
                          <span>Product Description	</span>
                        </td><td>
                          {data1.product_description}
                        </td>

                      </tr>
                      <tr>
                        <td id="td-right">
                          <span>Contract</span>
                        </td><td>
                        <Link to={'/contract/contract-details/' + data1.contractId}> {data1.contract}</Link>
                        </td>
                        <td id="td-right">
                          {/* <span>Contract</span> */}
                        </td><td>
                          {/* {data1.contract} */}
                        </td>

                      </tr>

                    </tbody>
                  </table>


                  <div className="col-md-12" id='ax.account_details'>
                    <h4 className="heading">Market Information</h4>
                    <hr></hr>
                  </div>
                  <table class="table table-bordered account-table tables">
                    <tbody>
                      <tr>
                        <td id="td-right">
                          <span>destination</span>
                        </td><td>
                          {data1.destination}
                        </td>

                        <td id="td-right">
                          {/* <span>Commodity</span> */}
                        </td><td>
                          {/* {data1.commodity} */}
                        </td>

                      </tr>


                    </tbody>
                  </table>
                  <div className="col-md-12" id='ax.account_details'>
                    <h4 className="heading">Price and Price Adjustment</h4>
                    <hr></hr>
                  </div>
                  <table class="table table-bordered account-table tables">
                    <tbody>
                      <tr>
                        <td id="td-right">
                          <span>Bonus/Penalty Clause</span>
                        </td><td>
                          {data1.bonus_penalty_clause}
                        </td>
                        <td id="td-right">
                          {/* <span>Rejections</span> */}
                        </td><td>
                          {/* {data1.rejections} */}
                        </td>

                      </tr>
                      <tr>
                        <td id="td-right">
                          <span>Rejections</span>
                        </td><td>
                          {data1.rejections}
                        </td>
                        <td id="td-right">
                          {/* <span>Payment Term</span> */}
                        </td><td>
                          {/* {data1.payment_terms} */}
                        </td>


                      </tr>
                      <tr>
                        <td id="td-right">
                          <span>Payment Term</span>
                        </td><td>
                          {data1.payment_terms}
                        </td>
                        <td id="td-right">
                          {/* <span>General Terms</span> */}
                        </td><td>
                          {/* {data1.general_terms} */}
                        </td>
                      </tr>
                      <tr>
                        <td id="td-right">
                          <span>General Terms</span>
                        </td><td>
                          {data1.general_terms}
                        </td>
                        <td id="td-right">
                          {/* <span>Payment Term</span> */}
                        </td><td>
                          {/* {data1.payment_terms} */}
                        </td>

                      </tr>
                    </tbody>
                  </table>
                  <div className="col-md-12" id='ax.account_details'>
                    <h4 className="heading">Terms & Conditions</h4>
                    <hr></hr>
                  </div>
                  <table class="table table-bordered account-table tables">
                    <tbody>
                      <tr>
                        <td id="td-right">
                          <span>Arbitration</span>
                        </td><td>
                          {data1.arbitration}
                        </td>
                        <td id="td-right">
                          <span>Rwood Surveyor Agent</span>
                        </td><td>
                          {data1.rwood_surveyor_agent}
                        </td>

                      </tr>
                      <tr>
                        <td id="td-right">
                          <span>Applicable Law</span>
                        </td><td>
                          {data1.applicable_law}
                        </td>
                        <td id="td-right">
                          <span>Surveyor 2	</span>
                        </td><td>
                          {data1.surveyor_2}
                        </td>
                      </tr>
                      <tr>
                        <td id="td-right">
                          <span>Offer Validity Terms</span>
                        </td><td>
                          {data1.offer_validity_terms}
                        </td>
                        <td id="td-right">
                          <span>Surveyor 3</span>
                        </td><td>
                          {data1.suveyor_3}
                        </td>
                      </tr>
                      <tr>
                        <td id="td-right">
                          <span>Description</span>
                        </td><td>
                          {data1.description}
                        </td>
                        <td id="td-right">
                          {/* <span>Surveyor 3</span> */}
                        </td><td>
                          {/* {data1.suveyor_3} */}
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <div className="col-md-12" id='ax.account_details'>
                    <h4 className="heading">Prepared For</h4>
                    <hr></hr>
                  </div>
                  <table class="table table-bordered account-table tables">
                    <tbody>


                      <tr>

                        <td id="td-right">
                          <span>Contact Name</span>
                        </td><td>
                          {data1.contact_name}
                        </td>
                        <td id="td-right">
                          <span>additional to name</span>
                        </td><td>
                          {data1.additional_to_name}
                        </td>

                      </tr>
                      <tr>

                        <td id="td-right">
                          <span>quote to name</span>
                        </td><td>
                          {data1.quote_to_name}
                        </td>
                        <td id="td-right">
                          <span>additional to</span>
                        </td><td>
                          {data1.additional_to}
                        </td>

                      </tr>

                      <tr>

                        <td id="td-right">
                          <span>Email</span>
                        </td><td>
                          {data1.email}
                        </td>
                        <td id="td-right">
                          {/* <span>additional to name</span> */}
                        </td><td>
                          {/* {data1.additional_to_name} */}
                        </td>

                      </tr>
                      <tr>

                        <td id="td-right">
                          <span>Phone</span>
                        </td><td>
                          {data1.phone_fax}
                        </td>
                        <td id="td-right">
                          {/* <span>additional to name</span> */}
                        </td><td>
                          {/* {data1.additional_to_name} */}
                        </td>

                      </tr>
                      <tr>

                        <td id="td-right">
                          <span>Fax</span>
                        </td><td>
                          {data1.fax}
                        </td>
                        <td id="td-right">
                          {/* <span>additional to name</span> */}
                        </td><td>
                          {/* {data1.additional_to_name} */}
                        </td>

                      </tr>
                    </tbody>
                  </table>

                  <div className="col-md-12" id='ax.account_details'>
                    <h4 className="heading">Address Information</h4>
                    <hr></hr>
                  </div>
                  <table class="table table-bordered account-table tables">
                    <tbody>
                      <tr>
                        <td id="td-right">
                          <span>bill to name</span>
                        </td><td>
                          {data1.bill_to_name}
                        </td>
                        <td id="td-right">
                          <span>ship to name</span>
                        </td><td>
                          {data1.ship_to_name}
                        </td>

                      </tr>
                      {/* <tr>
          <td id="td-right">
                <span>bill to</span>
                </td><td>
                {data1.bill_to}
              </td>
              <td id="td-right">
                <span>ship to</span>
                </td><td>
                {data1.ship_to}
              </td>

          </tr>   */}

                      <tr>
                        <td id="td-right">
                          <span>bill to country</span>
                        </td><td>
                          {data1.bill_to_country}
                        </td>
                        <td id="td-right">
                          <span>ship to country</span>
                        </td><td>
                          {data1.ship_to_country}
                        </td>

                      </tr>
                    </tbody>
                  </table>
                  <div className="col-md-12" id='ax.account_details'>
                    <h4 className="heading">System Information</h4>
                    <hr></hr>
                  </div>
                  <table class="table table-bordered account-table tables">
                    <tbody>
                      <tr>
                        <td id="td-right">
                          <span>Created By
                          </span>
                        </td><td>
                          {data1.created_by
                          }
                        </td>
                        <td id="td-right">
                          <span>Last Modified By
                          </span>
                        </td><td>
                          {data1.modified_by}
                        </td>


                      </tr>
                    </tbody>
                  </table>
                  {/* <div className="col-md-12" id='ax.account_details'>
   <h4 className="heading">System Information</h4>
   <hr></hr>
   </div>
        <table class="table table-bordered account-table tables">
         <tbody>
          <tr>
          <td id="td-right">
                <span>Created By</span>
                </td><td>
                {data1.created_by}
              </td>
              <td id="td-right">
                <span>Modified By</span>
                </td><td>
                {data1.modified_by}
              </td>

          </tr>
           </tbody>
        </table> */}
 <Row id="table-styles">
                    <div className="col-md-12" id='head'>
                      <h4 className="heading"><span>Quote Shipment</span>
                        {/* <span><Popup trigger={<span className="button add-accountrecord"> <FaPlus/> New </span>} modal>

    <form onSubmit={handleSubmittcf} className="add-accounts">
     <div className='form-group'>
     <label>TCF Record Type</label><br/>
     <select name='account_record' className='form-control' onChange={handleChangetcf} value={formData.account_record}>
       <option >--Select TCF Record Type--</option>
       <option value='Buyer - Spot'>Buyer - Spot</option>
       <option value='Buyer - Long Term'>Buyer - Long Term</option>
       </select>
       </div>
       <input type='submit' className="btn btn-primary " value='Submit'/>

       </form>
</Popup></span> */}
                      </h4>
                      <div id='inner-heading'>

                      </div>
                      <hr></hr>
                    </div>
                    <table id='quote' className="">
                      <thead>
                        <tr>
                          <th>Quote Shipment Code</th>
                          <th>Quantity</th>
                          <th>Laycan</th>
                          <th>Pricing</th>
                          <th>Quanity Info	</th>
                          <th>Vessel Type	</th>
                          <th>Shipping Term
                          </th>
                        </tr>
                      </thead>

                      {quote.length > 0 ?

                        <tbody>
                          {
                            quote.map((x, i) => (
                              <tr>
                                <td><Link to={'/quote-shipment/detail/' + x.id}>{x.quote_shipment_code}</Link></td>
                                <td>{x.quote_shipment_quantity}</td>
                                <td>{x.quote_shipment_laycan}</td>
                                <td>{x.quote_shipment_pricing}</td>
                                <td>{x.quote_shipment_quantity_info
                                }</td>

                                <td>{x.quote_shipment_vessel_type
                                }</td>
                                <td>{x.quote_shipment_shipping_term
                                }</td>
                              </tr>
                            ))}
                        </tbody>
                        : <tbody>
                          <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>No data available</td>
                            <td></td>
                            <td></td><td></td>

                          </tr>
                        </tbody>
                      }
                    </table>

                  </Row>
                      <Row id="table-styles">
                    <div className="col-md-12" id='head'>
                      <h4 className="heading"><span>Quote Product</span>
                        <span>
                          <Link to={"/quote-product/select/" + id}>
                            <i className="fe fe-download-cloud me-2"></i>
                              New
                          </Link>
                        </span>
                        {/* <span><Popup trigger={<span className="button add-accountrecord"> <FaPlus/> New </span>} modal>

    <form onSubmit={handleSubmittcf} className="add-accounts">
     <div className='form-group'>
     <label>TCF Record Type</label><br/>
     <select name='account_record' className='form-control' onChange={handleChangetcf} value={formData.account_record}>
       <option >--Select TCF Record Type--</option>
       <option value='Buyer - Spot'>Buyer - Spot</option>
       <option value='Buyer - Long Term'>Buyer - Long Term</option>
       </select>
       </div>
       <input type='submit' className="btn btn-primary " value='Submit'/>

       </form>
</Popup></span> */}
                      </h4>
                      <div id='inner-heading'>

                      </div>
                      <hr></hr>
                    </div>
                    <table id='quote-product' className="">
                      <thead>
                        <tr>
                          <th>Quote Product Name	</th>
                          <th>Quote Shipment	</th>
                          <th>Gross As Received Typical	</th>
                          <th>Ash Content Typical	</th>
                          <th>Total Moisture Typical	</th>
                          <th>Total Sulphur Typical ARB
                          </th>
                        </tr>
                      </thead>

                      {quoteProduct.length > 0 ?

                        <tbody>
                          {
                            quoteProduct.map((y, i) => (
                              <tr>

                                <td><span id='codes'><Link to={'/opportunity/quote-detail/' + y.id}>{y.quotes_name}</Link></span></td>
                                <td>{y.quotes_shippment}</td>
                                <td>{y.gross_as_received_typical	}</td>
                                <td>{y.ash_content_typical	}</td>
                                <td>{y.total_moisture_typical	}</td>
                                <td>{y.total_sulphur_typical_arb
	}</td>
                              </tr>
                            ))}
                        </tbody>
                        : <tbody>
                          <tr>
                            <td></td>
                            <td></td>
                            <td>No data available</td>
                            <td></td>
                            <td></td>
                            <td></td>

                          </tr>
                        </tbody>
                      }
                    </table>

                  </Row>
                  <Row id="table-styles">
     <div className="col-md-12" id="head">
       <h4 className="heading">
         <span>Quote Clauses
         </span>
         <span>
           <i className=""></i> New Quote Clauses
           </span>

       </h4>
       <hr>
       </hr>
     </div>
     <table id="history" className="">
       <tr>
         <td>
           <p class="no-records"> No records to display </p>
         </td>
       </tr>
     </table>
   </Row>
   <Row id="table-styles">
                    <div className="col-md-12" id='head'>
                      <h4 className="heading"><span>Trade Confirmation Form</span>

                      </h4>
                      <div id='inner-heading'>

                      </div>
                      <hr></hr>
                    </div>
                    <table id='tcf' className="">
                      <thead>
                        <tr>
                          <th>TCF No</th>
                          <th>tcf status</th>
                          <th>Buyer</th>
                          <th>supplier</th>
                          <th>Quantity (MT)	</th>
                          <th>Contractual GAR (P)	</th>
                          <th>Actual GAR (P)</th>
                          <th>Actual GAR (S)</th>
                        </tr>
                      </thead>

                      {tcf.length > 0 ?

                        <tbody>
                          {
                            tcf.map((x, i) => (
                              <tr>
                                <td><Link to={"/tcf/tcf-details/" + x.id}>{x.tcf_no}</Link></td>
                                <td>{x.tcf_status}</td>
                                <td><Link to={"/accounts/" + x.account_id}>{x.buyer}</Link></td>

                                <td><Link to={"/accounts/" + x.supplier_id}>{x.supplier}</Link></td>
                                <td>{x.quantity_MT}</td>
                                <td>{x.contractual_GAR_P}</td>
                                <td>{x.actual_gar_p}</td>
                                <td>{x.actual_gar_s}</td>
                              </tr>
                            ))}
                        </tbody>
                        : <tbody>
                          <tr>
                            <td></td>
                            <td></td><td></td>
                            <td></td>
                            <td>No data available</td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        </tbody>
                      }
                    </table>

                  </Row>

   <Row id="table-styles">
     <div className="col-md-12" id="head">
       <h4 className="heading">
         <span>Quote PDFs
         </span>

       </h4>
       <hr>
       </hr>
     </div>
     <table id="history" className="">
       <tr>
         <td>
           <p class="no-records"> No records to display </p>
         </td>
       </tr>
     </table>
   </Row>
   <Row id="table-styles">
     <div className="col-md-12" id="head">
       <h4 className="heading">
         <span>Notes
         </span>
         <span>New Note
         </span>
       </h4>
       <hr>
       </hr>
     </div>
     <table id="history" className="">
       <tr>
         <td>
           <p class="no-records"> No records to display </p>
         </td>
       </tr>
     </table>
   </Row>
   <Row id="table-styles">
     <div className="col-md-12" id="head">
       <h4 className="heading">
         <span>Files
         </span>
         <span>Upload Files         </span>
       </h4>
       <hr>
       </hr>
     </div>
     <table id="history" className="">
       <tr>
         <td>
           <p class="no-records"> No records to display </p>
         </td>
       </tr>
     </table>
   </Row>

   <Row id="table-styles">
     <div className="col-md-12" id="head">
       <h4 className="heading">
         <span>Open Activities

         </span>
         <span>New Task       </span>
         <span>New Event       </span>
       </h4>
       <hr>
       </hr>
     </div>
     <table id="history" className="">
       <tr>
         <td>
           <p class="no-records"> No records to display </p>
         </td>
       </tr>
     </table>
   </Row>
   <Row id="table-styles">
     <div className="col-md-12" id="head">
       <h4 className="heading">
         <span>Notes & Attachments</span>
         <span>
            New Note </span>
         <span>
           Attach File </span>
       </h4>
       <hr>
       </hr>
     </div>
     <table id="history" className="">
       <tr>
         <td>
           <p class="no-records"> No records to display </p>
         </td>
       </tr>
     </table>
   </Row>
   <Row id="table-styles">
     <div className="col-md-12" id="head">
       <h4 className="heading">
         <span>Activity History
         </span>
         <span>
            Log a Call </span>
         <span>
           Mail Merge </span>
       </h4>
       <hr>
       </hr>
     </div>
     <table id="history" className="">
       <tr>
         <td>
           <p class="no-records"> No records to display </p>
         </td>
       </tr>
     </table>
   </Row>

   <Row id="table-styles">
     <div className="col-md-12" id="head">
       <h4 className="heading">
         <span>Approval History

         </span>
         <span>
            Submit for Approval</span>

       </h4>
       <hr>
       </hr>
     </div>
     <table id="history" className="">
       <tr>
         <td>
           <p class="no-records"> No records to display </p>
         </td>
       </tr>
     </table>
   </Row>

                                 </>
              )}</div>

          ) : (

            <>
              {data1.quote_record_type === 'Supplier - Spot' ? (
                <div className="tasks edit-view">
 <div id="messageContainer"></div>
                  {isEditMode ? (
                    <>
                      <p className='edit-btn'>
                        <input
                          type="submit"
                          className="account-save"
                          onClick={handleSaveEdit}
                          //value={isSubmitted ? "Saving" : "Save"}
                         value="Save"
                        />
                        <button className="btn btn-primary" onClick={handleCancelEdit}>Cancel</button>
                      </p>
                      <table class="table table-bordered account-table tables">
                        <tbody>
                          <tr>
                            <td>
                              <span>Quote Number</span>
                              <br />
                              <input
                                type="text" className='form-control'
                                name="quote_number"
                                disabled
                                defaultValue={data1.quote_number || ''}
                                onChange={handleChange}
                              />
                            </td>
                            <td>
                              <span>Quote Record Type</span>
                              <br />
                              <input
                                type="text" className='form-control'
                                name="quote_record_type"
                                disabled
                                defaultValue={data1.quote_record_type || ''}
                                onChange={handleChange}
                              />
                            </td>

                          </tr>
                          <tr>
                            <td>
                              <span>Currency</span>
                              <br />

                              <select name="currency"
                                defaultValue={data1.currency || ''}
                                onChange={handleChange} className="form-control">
                                <option defaultValue='' >---None---</option>
                                <option defaultValue='USD' >USD</option>
                                <option defaultValue='IDR' >IDR</option>
                              </select>
                            </td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>
                              <span>Exchange Rate</span>
                              <br />
                              <input
                                type="text" className='form-control'
                                name="exchange_rate"
                                defaultValue={data1.exchange_rate || ''}
                                onChange={handleChange}
                              />
                            </td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>
                              <span>Quote Name</span>
                              <br />
                              <input
                                type="text" className='form-control'
                                name="quote_name"
                                defaultValue={data1.quote_name || ''}
                                onChange={handleChange}
                              />
                            </td>
                            <td>
                              <span>Status</span>
                              <br />
                              <select
                                name="status"
                                className='form-control'
                                defaultValue={data1.status || ''}
                                onChange={handleChange}
                              >
                                <option value='Offered'>Offered</option>
                                <option value='Accepted'>Accepted</option>
                                <option value='Rejected'>Rejected</option>
                                <option value='Sold'>Sold</option>
                              </select>
                            </td>

                          </tr>
                          <tr>
                            <td>
                              <span>Opportunity Name</span>
                              <br />

                              <select className='form-control'
                                name="opportunity_name"
                                defaultValue={data1.opportunity_name || ''}>
                                <option value='' >Select</option>
                                {
                                  opp.map((x) => {
                                    return (
                                      <option value={x.opportunity_name}>{x.opportunity_name}</option>
                                    )
                                  })
                                }
                              </select>
                            </td>
                            <td>
                              <span>Supplier</span>
                              <br />
                              <input
                                type="text" className='form-control'
                                name="supplier"
                                defaultValue={data1.supplier || ''}
                                onChange={handleChange}
                              />
                            </td>


                          </tr>
                          <tr>
                            <td>
                              <span>Account Name</span>
                              <br />
                              <input
                                type="text" className='form-control record-type'
                                name="account_name"
                                disabled
                                defaultValue={data1.account_name || ''}
                                onChange={handleChange}
                              />
                            </td>
                            <td>
                              <span>Line Items</span>
                              <br />
                              <input
                                type="text" className='form-control record-type'
                                name="line_items"
                                disabled
                                defaultValue={data1.line_items || ''}
                                onChange={handleChange}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span>quote to name</span>
                              <br />
                              <input
                                type="text" className='form-control'
                                name="quote_to_name"
                                defaultValue={data1.quote_to_name || ''}
                                onChange={handleChange}
                              />
                            </td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>
                              <span>Quote Date</span>
                              <br />
                              <DatePicker
                                selected={start}
                                onChange={(date) => setStart(date)}
                                customInput={<ExampleCustomInput2 />}
                                dayClassName={() => "example-datepicker-day-class"}
                                popperClassName="edit-date"
                                todayButton="TODAY"
                                dateFormat="yyyy-MM-dd"
                                placeholderText="Choose Date"
                              />
                            </td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>
                              <span>Expiration Date</span>
                              <br />

                              <DatePicker
                                selected={effective}
                                onChange={(date) => setEffective(date)}
                                customInput={<ExampleCustomInput1 />}
                                dayClassName={() => "example-datepicker-day-class"}
                                popperClassName="edit-date"
                                todayButton="TODAY"
                                dateFormat="yyyy-MM-dd"
                                placeholderText="Choose Date"
                              />
                            </td>
                            <td></td>
                          </tr>

                        </tbody>
                      </table>
                      <div className="col-md-12" id='ax.account_details'>
                        <h4 className="heading">Product information</h4>
                        <hr></hr>
                      </div>
                      <table class="table table-bordered account-table tables">
                        <tbody>
                          <tr>
                            <td>
                              <span>Origin</span>
                              <br />
                              <input
                                type="text" className='form-control'
                                name="origin"
                                defaultValue={data1.origin || ''}
                                onChange={handleChange}
                              />
                            </td>
                            <td>
                              <span>Quantity MT</span>
                              <br />
                              <input
                                type="text" className='form-control'
                                name="quantity_MT"
                                defaultValue={data1.quantity_MT || ''}
                                onChange={handleChange}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span>Commodity</span>
                              <br />
                              <select className='form-control'
                                name="commodity"
                                defaultValue={data1.commodity || ''}
                                onChange={handleChange}>
                                <option>---None---</option>
                                <option defaultValue="Indonesian Steam Coal in Bulk (&quot;Coal&quot;)">Indonesian Steam Coal in Bulk ("Coal")</option><option defaultValue="US Coal">US Coal</option><option defaultValue="Russia Coal">Russia Coal</option><option defaultValue="SA Coal">SA Coal</option><option defaultValue="Clinker in Bulk">Clinker in Bulk</option><option defaultValue="Australian Steam Coal">Australian Steam Coal</option>
                              </select>
                            </td>
                            <td>
                              <span>Product Description</span>
                              <br />
                              <input
                                type="text" className='form-control'
                                name="product_description"
                                defaultValue={data1.product_description || ''}
                                onChange={handleChange}
                              />
                            </td>

                          </tr>
                          <tr>
                            <td>
                              <span>Contract</span>
                              <br />
                              <input
                                type="text" className='form-control'
                                name="contract"
                                defaultValue={data1.contract || ''}
                                onChange={handleChange}
                              />
                            </td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                      <div className="col-md-12" id='ax.account_details'>
                        <h4 className="heading">Market Information</h4>
                        <hr></hr>
                      </div>
                      <table class="table table-bordered account-table tables">
                        <tbody>

                          <tr>
                            <td>
                              <span>Destination</span>
                              <br />

                              <input
                                type="text" className='form-control'
                                name="destination"
                                defaultValue={data1.destination || ''}
                                onChange={handleChange}
                              />
                            </td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                      <div className="col-md-12" id='ax.account_details'>
                        <h4 className="heading">Price and Price Adjustment</h4>
                        <hr></hr>
                      </div>
                      <table class="table table-bordered account-table tables">
                        <tbody>
                          <tr>
                            <td>
                              <span>Bonus/Penalty Clause</span>
                              <br />

                              <textarea className='form-control'
                                name="bonus_penalty_clause"
                                defaultValue={data1.bonus_penalty_clause || ''}
                                onChange={handleChange}>

                              </textarea>

                            </td>
                            <td>
                              <span>Rejections</span>
                              <br />

                              <textarea className='form-control'
                                name="rejections"
                                defaultValue={data1.rejections || ''}
                                onChange={handleChange}>
                              </textarea>
                            </td>

                          </tr>
                          <tr >
                            <td>
                              <span>Payment Term</span>
                              <br />


                              <textarea className='form-control'
                                name="payment_terms"
                                defaultValue={data1.payment_terms || ''}
                                onChange={handleChange}>
                              </textarea>
                            </td>
                            <td>
                              <span>General Terms</span>
                              <br />


                              <textarea className='form-control'
                                name="general_terms"
                                defaultValue={data1.general_terms || ''}
                                onChange={handleChange}>
                              </textarea>
                            </td>

                          </tr>
                        </tbody>
                      </table>
                      <div className="col-md-12" id='ax.account_details'>
                        <h4 className="heading">Terms & Conditions</h4>
                        <hr></hr>
                      </div>
                      <table class="table table-bordered account-table tables">
                        <tbody>
                          <tr>
                            <td>
                              <span>Arbitration</span>
                              <br />

                              <select className='form-control'
                                name="arbitration"
                                defaultValue={data1.arbitration || ''}
                                onChange={handleChange}>
                                <option value="">--None--</option><option value="In Singapore as per SIAC">In Singapore as per SIAC</option><option value="Singapore Chamber of Maritime Arbitration (SCMA)">Singapore Chamber of Maritime Arbitration (SCMA)</option><option value="Laws of England and Wales">Laws of England and Wales</option><option value="Rules of Badan Arbitrase Nasional Indonesia (BANI)">Rules of Badan Arbitrase Nasional Indonesia (BANI)</option>
                              </select>
                            </td>
                            <td>
                              <span>Rwood Surveyor Agent</span>
                              <br />
                              <input
                                type="text" className='form-control'
                                name="rwood_surveyor_agent"
                                defaultValue={data1.rwood_surveyor_agent || ''}
                                onChange={handleChange}
                              />
                            </td>

                          </tr>
                          <tr>
                            <td>
                              <span>Applicable Law</span>
                              <br />

                              <select className='form-control'
                                name="applicable_law"
                                defaultValue={data1.applicable_law || ''}
                                onChange={handleChange}>
                                <option value="">--None--</option><option value="English Law">English Law</option><option value="Singapore Law">Singapore Law</option><option value="Indonesian Law">Indonesian Law</option>
                              </select>
                            </td>
                            <td>
                              <span>Surveyor 2	</span>
                              <br />

                              <select className='form-control'
                                name="surveyor_2"
                                defaultValue={data1.surveyor_2 || ''}
                                onChange={handleChange}>
                                <option>---None---</option>
                                {surveyors.map((x) => {
                                  return (
                                    <option value={x.account_name}>{x.account_name}</option>
                                  );
                                })}
                              </select>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span>Offer Validity Terms</span>
                              <br />

                              <textarea className='form-control'
                                name="offer_validity_terms"
                                defaultValue={data1.offer_validity_terms || ''}
                                onChange={handleChange}>

                              </textarea>
                            </td>
                            <td>
                              <span>Surveyor 3</span>
                              <br />

                              <select className='form-control'
                                name="suveyor_3"
                                defaultValue={data1.suveyor_3 || ''}
                                onChange={handleChange}>
                                <option>---None---</option>
                                {surveyors.map((x) => {
                                  return (
                                    <option value={x.account_name}>{x.account_name}</option>
                                  );
                                })}
                              </select>
                            </td>

                          </tr>
                          <tr>
                            <td>
                              <span>Description</span>
                              <br />


                              <textarea className='form-control'
                                name="description"
                                defaultValue={data1.description || ''}
                                onChange={handleChange}>

                              </textarea>
                            </td>
                            <td>

                            </td>

                          </tr>
                        </tbody>
                      </table>

                      <div className="col-md-12" id='ax.account_details'>
                        <h4 className="heading">Prepared For</h4>
                        <hr></hr>
                      </div>
                      <table class="table table-bordered account-table tables">
                        <tbody>
                          <tr>
                            <td>
                              <span>Contact Name</span>
                              <br />
                              <input
                                type="text" className='form-control'
                                name="contact_name"
                                defaultValue={data1.contact_name || ''}
                                onChange={handleChange}
                              />
                            </td>
                            <td>
                              <span>Additional To Name</span>
                              <br />
                              <input
                                type="text" className='form-control'
                                name="additional_to_name"
                                defaultValue={data1.additional_to_name || ''}
                                onChange={handleChange}
                              />
                            </td>

                          </tr>
                          <tr>

                            <td>
                              <span>quote to street</span>
                              <br />
                              <input
                                type="text" className='form-control'
                                name="quote_to_street"
                                defaultValue={data1.quote_to_street || ''}
                                onChange={handleChange}
                              />
                            </td>
                            <td>
                              <span>additional to street</span>
                              <br />
                              {/* {data1.additional_to} */}
                              <input
                                type="text" className='form-control'
                                name="additional_to_street"
                                defaultValue={data1.additional_to_street || ''}
                                onChange={handleChange}
                              />
                            </td>
                          </tr>
                          <tr>

                            <td>
                              <span>Quote To Zip/Postal Code	</span>
                              <br />
                              {/* {data1.phone} */}
                              <input
                                type="text" className='form-control'
                                name="quote_to_zip_postal_code"
                                defaultValue={data1.quote_to_zip_postal_code || ''}
                                onChange={handleChange}
                              />
                            </td>
                            <td>
                              <span>Additional To Zip/Postal Code	</span>
                              <br />
                              {/* {data1.phone_fax} */}
                              <input
                                type="text" className='form-control'
                                name="additional_to_zip_postal_code"
                                defaultValue={data1.additional_to_zip_postal_code || ''}
                                onChange={handleChange}
                              />
                            </td>
                          </tr>
                          <tr>

                            <td>
                              <span>quote to city	</span>
                              <br />
                              {/* {data1.phone_fax} */}
                              <input
                                type="text" className='form-control'
                                name="quote_to_city"
                                defaultValue={data1.quote_to_city || ''}
                                onChange={handleChange}
                              />
                            </td>
                            <td>
                              <span>Additional To City		</span>
                              <br />
                              {/* {data1.phone_fax} */}
                              <input
                                type="text" className='form-control'
                                name="additional_to_city"
                                defaultValue={data1.additional_to_city || ''}
                                onChange={handleChange}
                              />
                            </td>
                          </tr>
                          <tr>

                            <td>
                              <span>Quote To State/Province	</span>
                              <br />
                              {/* {data1.phone_fax} */}
                              <input
                                type="text" className='form-control'
                                name="quote_to_state_province"
                                defaultValue={data1.quote_to_state_province || ''}
                                onChange={handleChange}
                              />
                            </td>

                            <td>
                              <span>Additional To State/Province		</span>
                              <br />
                              {/* {data1.phone_fax} */}
                              <input
                                type="text" className='form-control'
                                name="additional_to_state_province"
                                defaultValue={data1.additional_to_state_province || ''}
                                onChange={handleChange}
                              />
                            </td>
                          </tr>
                          <tr>


                            <td>
                              <span>Quote To Country	</span>
                              <br />
                              {/* {data1.phone_fax} */}
                              <input
                                type="text" className='form-control'
                                name="quote_to_country"
                                defaultValue={data1.quote_to_country || ''}
                                onChange={handleChange}
                              />
                            </td>
                            <td>
                              <span>Additional To Country	</span>
                              <br />
                              {/* {data1.phone_fax} */}
                              <input
                                type="text" className='form-control'
                                name="additional_to_country"
                                defaultValue={data1.additional_to_country || ''}
                                onChange={handleChange}
                              />
                            </td>
                          </tr>
                          <tr>



                          </tr>
                          <tr>
                            <td>
                              <span>Email</span>
                              <br />
                              {/* {data1.phone_fax} */}
                              <input
                                type="text" className='form-control'
                                name="email"
                                defaultValue={data1.email || ''}
                                onChange={handleChange}
                              />
                            </td>
                            <td>

                            </td>

                          </tr>
                          <tr>
                            <td>
                              <span>Phone</span>
                              <br />
                              {/* {data1.phone_fax} */}
                              <input
                                type="text" className='form-control'
                                name="phone"
                                defaultValue={data1.phone || ''}
                                onChange={handleChange}
                              />
                            </td>
                            <td>

                            </td>

                          </tr>
                          <tr>
                            <td>
                              <span>Fax</span>
                              <br />
                              {/* {data1.phone_fax} */}
                              <input
                                type="text" className='form-control'
                                name="fax"
                                defaultValue={data1.fax || ''}
                                onChange={handleChange}
                              />
                            </td>
                            <td>

                            </td>

                          </tr>
                        </tbody>
                      </table>

                      <div className="col-md-12" id='ax.account_details'>
                        <h4 className="heading">Address Information</h4>
                        <hr></hr>
                      </div>
                      <table class="table table-bordered account-table tables">
                        <tbody>
                          <tr>
                            <td>
                              <span>Bill To Name</span>
                              <br />
                              {/* {data1.bill_to_name} */}
                              <input
                                type="text" className='form-control'
                                name="bill_to_name"
                                defaultValue={data1.bill_to_name || ''}
                                onChange={handleChange}
                              />
                            </td>
                            <td>
                              <span>Ship To Name</span>
                              <br />
                              {/* {data1.ship_to_name} */}
                              <input
                                type="text" className='form-control'
                                name="ship_to_name"
                                defaultValue={data1.ship_to_name || ''}
                                onChange={handleChange}
                              />
                            </td>

                          </tr>
                          <tr>
                            <td>
                              <span>bill to street</span>
                              <br />
                              {/* {data1.bill_to} */}
                              <input
                                type="text" className='form-control'
                                name="bill_to_street"
                                defaultValue={data1.bill_to_street || ''}
                                onChange={handleChange}
                              />
                            </td>
                            <td>
                              <span>Ship To Street</span>
                              <br />
                              <input
                                type="text" className='form-control'
                                name="ship_to_street"
                                defaultValue={data1.ship_to_street || ''}
                                onChange={handleChange}
                              />
                            </td>

                          </tr>
                          <tr>
                            <td>
                              <span>Bill To Zip/Postal Code	</span>
                              <br />
                              {/* {data1.bill_to} */}
                              <input
                                type="text" className='form-control'
                                name="bill_to_zip_postal_code"
                                defaultValue={data1.bill_to_zip_postal_code || ''}
                                onChange={handleChange}
                              />
                            </td>
                            <td>
                              <span>Ship To Zip/Postal Code	</span>
                              <br />
                              <input
                                type="text" className='form-control'
                                name="ship_to_zip_postal_code"
                                defaultValue={data1.ship_to_zip_postal_code || ''}
                                onChange={handleChange}
                              />
                            </td>

                          </tr>
                          <tr>
                            <td>
                              <span>Bill To City	</span>
                              <br />
                              {/* {data1.bill_to} */}
                              <input
                                type="text" className='form-control'
                                name="bill_to_city"
                                defaultValue={data1.bill_to_city || ''}
                                onChange={handleChange}
                              />
                            </td>
                            <td>
                              <span>Ship To City	</span>
                              <br />
                              <input
                                type="text" className='form-control'
                                name="ship_to_city"
                                defaultValue={data1.ship_to_city || ''}
                                onChange={handleChange}
                              />
                            </td>

                          </tr>
                          <tr>
                            <td>
                              <span>Bill To State/Province	</span>
                              <br />
                              {/* {data1.bill_to} */}
                              <input
                                type="text" className='form-control'
                                name="bill_to_state_province"
                                defaultValue={data1.bill_to_state_province || ''}
                                onChange={handleChange}
                              />
                            </td>
                            <td>
                              <span>Ship To State/Province	</span>
                              <br />
                              <input
                                type="text" className='form-control'
                                name="ship_to_state_province"
                                defaultValue={data1.ship_to_state_province || ''}
                                onChange={handleChange}
                              />
                            </td>

                          </tr>

                          <tr>
                            <td>
                              <span>Bill To Country	</span>
                              <br />
                              {/* {data1.bill_to} */}
                              <input
                                type="text" className='form-control'
                                name="bill_to_country"
                                defaultValue={data1.bill_to_country || ''}
                                onChange={handleChange}
                              />
                            </td>
                            <td>
                              <span>Ship To Country	</span>
                              <br />
                              <input
                                type="text" className='form-control'
                                name="ship_to_country"
                                defaultValue={data1.ship_to_country || ''}
                                onChange={handleChange}
                              />
                            </td>

                          </tr>
                        </tbody>
                      </table>
                      {/* <div className="col-md-12" id='ax.account_details'>
   <h4 className="heading">System Information</h4>
   <hr></hr>
   </div>
        <table class="table table-bordered account-table tables">
         <tbody>
          <tr>
          <td>
                <span>Created By</span>
                <br />
                <input
              type="text" className='form-control'
              name="created_by"
              defaultValue={data1.created_by || ''}
              onChange={handleChange}
            />
              </td>
              <td>
                <span>Modified By</span>
                <br />
                <input
              type="text" className='form-control'
              name="modified_by"
              defaultValue={data1.modified_by || ''}
              onChange={handleChange}
            />
              </td>
          </tr>

          </tbody>
        </table> */}
                      <p className='edit-btn'>
                        <input
                          type="submit"
                          className="account-save"
                          onClick={handleSaveEdit}
                          //value={isSubmitted ? "Saving" : "Save"}
                         value="Save"
                        />
                        <button className="btn btn-primary" onClick={handleCancelEdit}>Cancel</button>
                      </p>

                    </>

                  ) : (
                    <>
                      <div className="edit-delete">
                        <span id="edit" onClick={handleEditClick}>
                          Edit
                        </span>
                        <span>
                          <Link to={`/opportunity/opportunity-detail/${data1.opportunity_id}`}> Cancel</Link>
                        </span>
                        {data1.status === 'Accepted' ? (
                          <span onClick={createContract}>Create Contract</span>
                        ) : (
                          <></>
                        )}
                      </div>

                      <div className="col-md-12" id='ax.account_details'>
                        <h4 className="heading">Quote Detail</h4>
                        <hr></hr>
                      </div>

                      <table class="table table-bordered account-table tables">
                        <tbody>
                          <tr>
                            <td id="td-right">
                              <span>Quote Number</span>
                            </td><td>
                              {data1.quote_number}
                            </td>
                            <td id="td-right">
                              <span>Quote Name</span>
                            </td><td>
                              {data1.quote_name}
                            </td>

                          </tr>
                          <tr>
                            <td id="td-right">
                              <span>Currency</span>
                            </td><td>
                              {data1.currency}
                            </td>
                            <td id="td-right">
                              <span>Quote Record Type</span>
                            </td>
                            <td>
                              {data1.quote_record_type}
                            </td>

                          </tr>
                          <tr>
                            <td id="td-right">
                              <span>Exchange Rate</span>
                            </td><td>
                              {data1.exchange_rate}
                            </td>
                            <td id="td-right">
                              <span>Locked</span>
                            </td><td>
                              <input
                                type='checkbox'
                                name='locked'
                                checked={lockedOne} // Use the state variable to determine if the checkbox is checked
                                onChange={handleCheckboxLocked} // Handle the change event
                                className='mx-3' disabled={isButtonEnabled}
                              />
                            </td>


                          </tr>
                          <tr>
                            <td id="td-right">
                              <span>Opportunity Name</span>
                            </td><td>
                              <Link to={`/opportunity/opportunity-detail/${data1.opportunity_id}`}>{data1.opportunity_name}</Link>
                            </td>
                            <td id="td-right">
                              <span>Status</span>
                            </td><td>
                              {data1.status || 'Offered'}
                            </td>

                          </tr>
                          <tr>
                            <td id="td-right">
                              <span>Account Name</span>
                            </td><td>
                              <Link to={`/accounts/${data1.account_id}`}>{data1.account_name}</Link>
                            </td>
                            <td id="td-right">
                              <span>no of product</span>
                            </td><td>
                              {data1.no_of_product}
                            </td>

                          </tr>
                          <tr>
                            <td id="td-right">
                              <span>Supplier</span>
                            </td><td>
                              <Link to={`/accounts/${data1.supplier_id}`}>{data1.supplier}</Link>
                            </td>
                            <td id="td-right">
                              <span>No of Quote shipment</span>
                            </td><td>
                              {data1.no_of_quote_shipment}
                            </td>

                          </tr>
                          <tr>
                            <td id="td-right">
                              <span>Trader Name</span>
                            </td><td>
                              <Link to={'/user/user-details/' + data1.trader_id}> {data1.trader_name}</Link>
                            </td>
                            <td id="td-right">
                            </td><td>
                            </td>


                          </tr>
                          <tr>
                            <td id="td-right">
                              <span>Quote to Name</span>
                            </td><td>
                              {data1.quote_to_name}
                            </td>
                            <td id="td-right">
                            </td><td>
                            </td>


                          </tr>
                          <tr>
                            <td id="td-right">
                              <span>Quote Date</span>
                            </td><td>
                              {data1.quote_date}
                            </td>
                            <td id="td-right">
                            </td><td>
                            </td>

                          </tr>
                          <tr>
                            <td id="td-right">
                              <span>Expiration Date</span>
                            </td><td>
                              {data1.expiration_date}
                            </td>
                            <td id="td-right">
                            </td><td>
                            </td>

                          </tr>
                        </tbody>
                      </table>
                      <div className="col-md-12" id='ax.account_details'>
                        <h4 className="heading">Product information</h4>
                        <hr></hr>
                      </div>
                      <table class="table table-bordered account-table tables">
                        <tbody>
                          <tr>
                            <td id="td-right">
                              <span>origin
                              </span>
                            </td><td>
                              {data1.origin}
                            </td>
                            <td id="td-right">
                              <span>Quantity MT
                              </span>
                            </td><td>
                              {data1.quantity_MT}
                            </td>
                          </tr>
                          <tr>
                            <td id="td-right">
                              <span>Commodity</span>
                            </td><td>
                              {data1.commodity}
                            </td>
                            <td id="td-right">
                              <span>Product Description	</span>
                            </td><td>
                              {data1.product_description}
                            </td>

                          </tr>
                          <tr>
                            <td id="td-right">
                              <span>Contract</span>
                            </td><td>
                            <Link to={'/contract/contract-details/' + data1.contractId}> {data1.contract}</Link>
                            </td>
                            <td id="td-right">
                              {/* <span>Contract</span> */}
                            </td><td>
                              {/* {data1.contract} */}
                            </td>

                          </tr>

                        </tbody>
                      </table>


                      <div className="col-md-12" id='ax.account_details'>
                        <h4 className="heading">Market Information</h4>
                        <hr></hr>
                      </div>
                      <table class="table table-bordered account-table tables">
                        <tbody>
                          <tr>
                            <td id="td-right">
                              <span>destination</span>
                            </td><td>
                              {data1.destination}
                            </td>

                            <td id="td-right">
                              {/* <span>Commodity</span> */}
                            </td><td>
                              {/* {data1.commodity} */}
                            </td>

                          </tr>


                        </tbody>
                      </table>
                      <div className="col-md-12" id='ax.account_details'>
                        <h4 className="heading">Price and Price Adjustment</h4>
                        <hr></hr>
                      </div>
                      <table class="table table-bordered account-table tables">
                        <tbody>
                          <tr>
                            <td id="td-right">
                              <span>Bonus/Penalty Clause</span>
                            </td><td>
                              {data1.bonus_penalty_clause}
                            </td>
                            <td id="td-right">
                              {/* <span>Rejections</span> */}
                            </td><td>
                              {/* {data1.rejections} */}
                            </td>

                          </tr>
                          <tr>
                            <td id="td-right">
                              <span>Rejections</span>
                            </td><td>
                              {data1.rejections}
                            </td>
                            <td id="td-right">
                              {/* <span>Payment Term</span> */}
                            </td><td>
                              {/* {data1.payment_terms} */}
                            </td>


                          </tr>
                          <tr>
                            <td id="td-right">
                              <span>Payment Term</span>
                            </td><td>
                              {data1.payment_terms}
                            </td>
                            <td id="td-right">
                              {/* <span>General Terms</span> */}
                            </td><td>
                              {/* {data1.general_terms} */}
                            </td>
                          </tr>
                          <tr>
                            <td id="td-right">
                              <span>General Terms</span>
                            </td><td>
                              {data1.general_terms}
                            </td>
                            <td id="td-right">
                              {/* <span>Payment Term</span> */}
                            </td><td>
                              {/* {data1.payment_terms} */}
                            </td>

                          </tr>
                        </tbody>
                      </table>
                      <div className="col-md-12" id='ax.account_details'>
                        <h4 className="heading">Terms & Conditions</h4>
                        <hr></hr>
                      </div>
                      <table class="table table-bordered account-table tables">
                        <tbody>
                          <tr>
                            <td id="td-right">
                              <span>Arbitration</span>
                            </td><td>
                              {data1.arbitration}
                            </td>
                            <td id="td-right">
                              <span>Rwood Surveyor Agent</span>
                            </td><td>
                              {data1.rwood_surveyor_agent}
                            </td>

                          </tr>
                          <tr>
                            <td id="td-right">
                              <span>Applicable Law</span>
                            </td><td>
                              {data1.applicable_law}
                            </td>
                            <td id="td-right">
                              <span>Surveyor 2	</span>
                            </td><td>
                              {data1.surveyor_2}
                            </td>
                          </tr>
                          <tr>
                            <td id="td-right">
                              <span>Offer Validity Terms</span>
                            </td><td>
                              {data1.offer_validity_terms}
                            </td>
                            <td id="td-right">
                              <span>Surveyor 3</span>
                            </td><td>
                              {data1.suveyor_3}
                            </td>
                          </tr>
                          <tr>
                            <td id="td-right">
                              <span>Description</span>
                            </td><td>
                              {data1.description}
                            </td>
                            <td id="td-right">
                              {/* <span>Surveyor 3</span> */}
                            </td><td>
                              {/* {data1.suveyor_3} */}
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <div className="col-md-12" id='ax.account_details'>
                        <h4 className="heading">Prepared For</h4>
                        <hr></hr>
                      </div>
                      <table class="table table-bordered account-table tables">
                        <tbody>


                          <tr>

                            <td id="td-right">
                              <span>Contact Name</span>
                            </td><td>
                              {data1.contact_name}
                            </td>
                            <td id="td-right">
                              <span>additional to name</span>
                            </td><td>
                              {data1.additional_to_name}
                            </td>

                          </tr>
                          <tr>

                            <td id="td-right">
                              <span>quote to name</span>
                            </td><td>
                              {data1.quote_to_name}
                            </td>
                            <td id="td-right">
                              <span>additional to</span>
                            </td><td>
                              {data1.additional_to}
                            </td>

                          </tr>

                          <tr>

                            <td id="td-right">
                              <span>Email</span>
                            </td><td>
                              {data1.email}
                            </td>
                            <td id="td-right">
                              {/* <span>additional to name</span> */}
                            </td><td>
                              {/* {data1.additional_to_name} */}
                            </td>

                          </tr>
                          <tr>

                            <td id="td-right">
                              <span>Phone</span>
                            </td><td>
                              {data1.phone_fax}
                            </td>
                            <td id="td-right">
                              {/* <span>additional to name</span> */}
                            </td><td>
                              {/* {data1.additional_to_name} */}
                            </td>

                          </tr>
                          <tr>

                            <td id="td-right">
                              <span>Fax</span>
                            </td><td>
                              {data1.fax}
                            </td>
                            <td id="td-right">
                              {/* <span>additional to name</span> */}
                            </td><td>
                              {/* {data1.additional_to_name} */}
                            </td>

                          </tr>
                        </tbody>
                      </table>

                      <div className="col-md-12" id='ax.account_details'>
                        <h4 className="heading">Address Information</h4>
                        <hr></hr>
                      </div>
                      <table class="table table-bordered account-table tables">
                        <tbody>
                          <tr>
                            <td id="td-right">
                              <span>bill to name</span>
                            </td><td>
                              {data1.bill_to_name}
                            </td>
                            <td id="td-right">
                              <span>ship to name</span>
                            </td><td>
                              {data1.ship_to_name}
                            </td>

                          </tr>
                          {/* <tr>
          <td id="td-right">
                <span>bill to</span>
                </td><td>
                {data1.bill_to}
              </td>
              <td id="td-right">
                <span>ship to</span>
                </td><td>
                {data1.ship_to}
              </td>

          </tr>   */}

                          <tr>
                            <td id="td-right">
                              <span>bill to country</span>
                            </td><td>
                              {data1.bill_to_country}
                            </td>
                            <td id="td-right">
                              <span>ship to country</span>
                            </td><td>
                              {data1.ship_to_country}
                            </td>

                          </tr>
                        </tbody>
                      </table>
                      <div className="col-md-12" id='ax.account_details'>
   <h4 className="heading">System Information</h4>
   <hr></hr>
   </div>
        <table class="table table-bordered account-table tables">
         <tbody>
          <tr>
          <td id="td-right">
                <span>Created By</span>
                </td><td>
                {data1.created_by}
              </td>
              <td id="td-right">
                <span>Modified By</span>
                </td><td>
                {data1.modified_by}
              </td>

          </tr>
           </tbody>
        </table>


 <Row id="table-styles">
                    <div className="col-md-12" id='head'>
                      <h4 className="heading"><span>Quote Shipment</span>
                        {/* <span><Popup trigger={<span className="button add-accountrecord"> <FaPlus/> New </span>} modal>

    <form onSubmit={handleSubmittcf} className="add-accounts">
     <div className='form-group'>
     <label>TCF Record Type</label><br/>
     <select name='account_record' className='form-control' onChange={handleChangetcf} value={formData.account_record}>
       <option >--Select TCF Record Type--</option>
       <option value='Buyer - Spot'>Buyer - Spot</option>
       <option value='Buyer - Long Term'>Buyer - Long Term</option>
       </select>
       </div>
       <input type='submit' className="btn btn-primary " value='Submit'/>

       </form>
</Popup></span> */}
                      </h4>
                      <div id='inner-heading'>

                      </div>
                      <hr></hr>
                    </div>
                    <table id='quote' className="">
                      <thead>
                        <tr>
                          <th>Quote Shipment Code</th>
                          <th>Quantity</th>
                          <th>Laycan</th>
                          <th>Pricing</th>
                          <th>Quanity Info	</th>
                          <th>Vessel Type	</th>
                          <th>Shipping Term
                          </th>
                        </tr>
                      </thead>

                      {quote.length > 0 ?

                        <tbody>
                          {
                            quote.map((x, i) => (
                              <tr>
                                <td><Link to={'/quote-shipment/detail/' + x.id}>{x.quote_shipment_code}</Link></td>
                                <td>{x.quote_shipment_quantity}</td>
                                <td>{x.quote_shipment_laycan}</td>
                                <td>{x.quote_shipment_pricing}</td>
                                <td>{x.quote_shipment_quantity_info
                                }</td>

                                <td>{x.quote_shipment_vessel_type
                                }</td>
                                <td>{x.quote_shipment_shipping_term}</td>
                              </tr>
                            ))}
                        </tbody>
                        : <tbody>
                          <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>No data available</td>
                            <td></td>
                            <td></td><td></td>

                          </tr>
                        </tbody>
                      }
                    </table>

                  </Row>
                      <Row id="table-styles">
                    <div className="col-md-12" id='head'>
                      <h4 className="heading"><span>Quote Product</span>
                        <span>
                          <Link to={"/quote-product/select/" + id}>
                            <i className="fe fe-download-cloud me-2"></i>
                              New
                          </Link>
                        </span>
                        {/* <span><Popup trigger={<span className="button add-accountrecord"> <FaPlus/> New </span>} modal>

    <form onSubmit={handleSubmittcf} className="add-accounts">
     <div className='form-group'>
     <label>TCF Record Type</label><br/>
     <select name='account_record' className='form-control' onChange={handleChangetcf} value={formData.account_record}>
       <option >--Select TCF Record Type--</option>
       <option value='Buyer - Spot'>Buyer - Spot</option>
       <option value='Buyer - Long Term'>Buyer - Long Term</option>
       </select>
       </div>
       <input type='submit' className="btn btn-primary " value='Submit'/>

       </form>
</Popup></span> */}
                      </h4>
                      <div id='inner-heading'>

                      </div>
                      <hr></hr>
                    </div>
                    <table id='quote-product' className="">
                      <thead>
                        <tr>
                          <th>Quote Product Name	</th>
                          <th>Quote Shipment	</th>
                          <th>Gross As Received Typical	</th>
                          <th>Ash Content Typical	</th>
                          <th>Total Moisture Typical	</th>
                          <th>Total Sulphur Typical ARB
                          </th>
                        </tr>
                      </thead>

                      {quoteProduct.length > 0 ?

                        <tbody>
                          {
                            quoteProduct.map((y, i) => (
                              <tr>

                                <td><span id='codes'><Link to={'/opportunity/quote-detail/' + y.id}>{y.quotes_name}</Link></span></td>
                                <td>{y.quotes_shippment}</td>
                                <td>{y.gross_as_received_typical	}</td>
                                <td>{y.ash_content_typical	}</td>
                                <td>{y.total_moisture_typical	}</td>
                                <td>{y.total_sulphur_typical_arb
	}</td>
                              </tr>
                            ))}
                        </tbody>
                        : <tbody>
                          <tr>
                            <td></td>
                            <td></td>
                            <td>No data available</td>
                            <td></td>
                            <td></td>
                            <td></td>

                          </tr>
                        </tbody>
                      }
                    </table>

                  </Row>
                  <Row id="table-styles">
     <div className="col-md-12" id="head">
       <h4 className="heading">
         <span>Quote Clauses
         </span>
         <span>
           <i className=""></i> New Quote Clauses
           </span>

       </h4>
       <hr>
       </hr>
     </div>
     <table id="history" className="">
       <tr>
         <td>
           <p class="no-records"> No records to display </p>
         </td>
       </tr>
     </table>
   </Row>
   <Row id="table-styles">
                    <div className="col-md-12" id='head'>
                      <h4 className="heading"><span>Trade Confirmation Form</span>

                      </h4>
                      <div id='inner-heading'>

                      </div>
                      <hr></hr>
                    </div>
                    <table id='tcf' className="">
                      <thead>
                        <tr>
                          <th>TCF No</th>
                          <th>tcf status</th>
                          <th>Buyer</th>
                          <th>supplier</th>
                          <th>Quantity (MT)	</th>
                          <th>Contractual GAR (P)	</th>
                          <th>Actual GAR (P)</th>
                          <th>Actual GAR (S)</th>
                        </tr>
                      </thead>

                      {tcf.length > 0 ?

                        <tbody>
                          {
                            tcf.map((x, i) => (
                              <tr>
                                <td><Link to={"/tcf/tcf-details/" + x.id}>{x.tcf_no}</Link></td>
                                <td>{x.tcf_status}</td>
                                <td><Link to={"/accounts/" + x.account_id}>{x.buyer}</Link></td>
                                <td><Link to={"/accounts/" + x.supplier_id}>{x.supplier}</Link></td>
                                <td>{x.quantity_MT}</td>
                                <td>{x.contractual_GAR_P}</td>
                                <td>{x.actual_gar_p}</td>
                                <td>{x.actual_gar_s}</td>
                              </tr>
                            ))}
                        </tbody>
                        : <tbody>
                          <tr>
                            <td></td>
                            <td></td><td></td>
                            <td></td>
                            <td>No data available</td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        </tbody>
                      }
                    </table>

                  </Row>

   <Row id="table-styles">
     <div className="col-md-12" id="head">
       <h4 className="heading">
         <span>Quote PDFs
         </span>

       </h4>
       <hr>
       </hr>
     </div>
     <table id="history" className="">
       <tr>
         <td>
           <p class="no-records"> No records to display </p>
         </td>
       </tr>
     </table>
   </Row>
   <Row id="table-styles">
     <div className="col-md-12" id="head">
       <h4 className="heading">
         <span>Notes
         </span>
         <span>New Note
         </span>
       </h4>
       <hr>
       </hr>
     </div>
     <table id="history" className="">
       <tr>
         <td>
           <p class="no-records"> No records to display </p>
         </td>
       </tr>
     </table>
   </Row>
   <Row id="table-styles">
     <div className="col-md-12" id="head">
       <h4 className="heading">
         <span>Files
         </span>
         <span>Upload Files         </span>
       </h4>
       <hr>
       </hr>
     </div>
     <table id="history" className="">
       <tr>
         <td>
           <p class="no-records"> No records to display </p>
         </td>
       </tr>
     </table>
   </Row>

   <Row id="table-styles">
     <div className="col-md-12" id="head">
       <h4 className="heading">
         <span>Open Activities

         </span>
         <span>New Task       </span>
         <span>New Event       </span>
       </h4>
       <hr>
       </hr>
     </div>
     <table id="history" className="">
       <tr>
         <td>
           <p class="no-records"> No records to display </p>
         </td>
       </tr>
     </table>
   </Row>
   <Row id="table-styles">
     <div className="col-md-12" id="head">
       <h4 className="heading">
         <span>Notes & Attachments</span>
         <span>
            New Note </span>
         <span>
           Attach File </span>
       </h4>
       <hr>
       </hr>
     </div>
     <table id="history" className="">
       <tr>
         <td>
           <p class="no-records"> No records to display </p>
         </td>
       </tr>
     </table>
   </Row>
   <Row id="table-styles">
     <div className="col-md-12" id="head">
       <h4 className="heading">
         <span>Activity History
         </span>
         <span>
            Log a Call </span>
         <span>
           Mail Merge </span>
       </h4>
       <hr>
       </hr>
     </div>
     <table id="history" className="">
       <tr>
         <td>
           <p class="no-records"> No records to display </p>
         </td>
       </tr>
     </table>
   </Row>

   <Row id="table-styles">
     <div className="col-md-12" id="head">
       <h4 className="heading">
         <span>Approval History

         </span>
         <span>
            Submit for Approval</span>

       </h4>
       <hr>
       </hr>
     </div>
     <table id="history" className="">
       <tr>
         <td>
           <p class="no-records"> No records to display </p>
         </td>
       </tr>
     </table>
   </Row>
                    </>
                  )}</div>

              ) : (

                <>
                  {data1.quote_record_type === 'Supplier - Long Term' ? (
                    <div className="tasks edit-view">
 <div id="messageContainer"></div>
                      {isEditMode ? (
                        <>
                          <p className='edit-btn'>
                            <input
                              type="submit"
                              className="account-save"
                              onClick={handleSaveEdit}
                              // //value={isSubmitted ? "Saving" : "Save"}
                             value="Save"
                            />
                            <button className="btn btn-primary" onClick={handleCancelEdit}>Cancel</button>
                          </p>
                          <table class="table table-bordered account-table tables">
                            <tbody>
                              <tr>
                                <td>
                                  <span>Quote Number</span>
                                  <br />
                                  <input
                                    type="text" className='form-control'
                                    name="quote_number"
                                    disabled
                                    defaultValue={data1.quote_number || ''}
                                    onChange={handleChange}
                                  />
                                </td>
                                <td>
                                  <span>Quote Record Type</span>
                                  <br />
                                  <input
                                    type="text" className='form-control'
                                    name="quote_record_type"
                                    disabled
                                    defaultValue={data1.quote_record_type || ''}
                                    onChange={handleChange}
                                  />
                                </td>

                              </tr>
                              <tr>
                                <td>
                                  <span>Currency</span>
                                  <br />

                                  <select name="currency"
                                    defaultValue={data1.currency || ''}
                                    onChange={handleChange} className="form-control">
                                    <option defaultValue='' >---None---</option>
                                    <option defaultValue='USD' >USD</option>
                                    <option defaultValue='IDR' >IDR</option>
                                  </select>
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>
                                  <span>Exchange Rate</span>
                                  <br />
                                  <input
                                    type="text" className='form-control'
                                    name="exchange_rate"
                                    defaultValue={data1.exchange_rate || ''}
                                    onChange={handleChange}
                                  />
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>
                                  <span>Quote Name</span>
                                  <br />
                                  <input
                                    type="text" className='form-control'
                                    name="quote_name"
                                    defaultValue={data1.quote_name || ''}
                                    onChange={handleChange}
                                  />
                                </td>
                                <td>
                                  <span>Status</span>
                                  <br />
                                  <select
                                    name="status"
                                    className='form-control'
                                    defaultValue={data1.status || ''}
                                    onChange={handleChange}
                                  >
                                    <option value='Offered'>Offered</option>
                                    <option value='Accepted'>Accepted</option>
                                    <option value='Rejected'>Rejected</option>
                                    <option value='Sold'>Sold</option>
                                  </select>
                                </td>

                              </tr>
                              <tr>
                                <td>
                                  <span>Opportunity Name</span>
                                  <br />

                                  <select className='form-control'
                                    name="opportunity_name"
                                    defaultValue={data1.opportunity_name || ''}>
                                    <option value='' >Select</option>
                                    {
                                      opp.map((x) => {
                                        return (
                                          <option value={x.opportunity_name}>{x.opportunity_name}</option>
                                        )
                                      })
                                    }
                                  </select>
                                </td>
                                <td>
                                  <span>Supplier</span>
                                  <br />
                                  <input
                                    type="text" className='form-control'
                                    name="supplier"
                                    defaultValue={data1.supplier || ''}
                                    onChange={handleChange}
                                  />
                                </td>


                              </tr>
                              <tr>
                                <td>
                                  <span>Account Name</span>
                                  <br />
                                  <input
                                    type="text" className='form-control record-type'
                                    name="account_name"
                                    disabled
                                    defaultValue={data1.account_name || ''}
                                    onChange={handleChange}
                                  />
                                </td>
                                <td>
                                  <span>Line Items</span>
                                  <br />
                                  <input
                                    type="text" className='form-control record-type'
                                    name="line_items"
                                    disabled
                                    defaultValue={data1.line_items || ''}
                                    onChange={handleChange}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <span>quote to name</span>
                                  <br />
                                  <input
                                    type="text" className='form-control'
                                    name="quote_to_name"
                                    defaultValue={data1.quote_to_name || ''}
                                    onChange={handleChange}
                                  />
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>
                                  <span>Quote Date</span>
                                  <br />
                                  <DatePicker
                                    selected={start}
                                    onChange={(date) => setStart(date)}
                                    customInput={<ExampleCustomInput2 />}
                                    dayClassName={() => "example-datepicker-day-class"}
                                    popperClassName="edit-date"
                                    todayButton="TODAY"
                                    dateFormat="yyyy-MM-dd"
                                    placeholderText="Choose Date"
                                  />
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>
                                  <span>Expiration Date</span>
                                  <br />

                                  <DatePicker
                                    selected={effective}
                                    onChange={(date) => setEffective(date)}
                                    customInput={<ExampleCustomInput1 />}
                                    dayClassName={() => "example-datepicker-day-class"}
                                    popperClassName="edit-date"
                                    todayButton="TODAY"
                                    dateFormat="yyyy-MM-dd"
                                    placeholderText="Choose Date"
                                  />
                                </td>
                                <td></td>
                              </tr>

                            </tbody>
                          </table>
                          <div className="col-md-12" id='ax.account_details'>
                            <h4 className="heading">Product information</h4>
                            <hr></hr>
                          </div>
                          <table class="table table-bordered account-table tables">
                            <tbody>
                              <tr>
                                <td>
                                  <span>Origin</span>
                                  <br />
                                  <input
                                    type="text" className='form-control'
                                    name="origin"
                                    defaultValue={data1.origin || ''}
                                    onChange={handleChange}
                                  />
                                </td>
                                <td>
                                  <span>Quantity MT</span>
                                  <br />
                                  <input
                                    type="text" className='form-control'
                                    name="quantity_MT"
                                    defaultValue={data1.quantity_MT || ''}
                                    onChange={handleChange}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <span>Commodity</span>
                                  <br />
                                  <select className='form-control'
                                    name="commodity"
                                    defaultValue={data1.commodity || ''}
                                    onChange={handleChange}>
                                    <option>---None---</option>
                                    <option defaultValue="Indonesian Steam Coal in Bulk (&quot;Coal&quot;)">Indonesian Steam Coal in Bulk ("Coal")</option><option defaultValue="US Coal">US Coal</option><option defaultValue="Russia Coal">Russia Coal</option><option defaultValue="SA Coal">SA Coal</option><option defaultValue="Clinker in Bulk">Clinker in Bulk</option><option defaultValue="Australian Steam Coal">Australian Steam Coal</option>
                                  </select>
                                </td>
                                <td>
                                  <span>Product Description</span>
                                  <br />
                                  <input
                                    type="text" className='form-control'
                                    name="product_description"
                                    defaultValue={data1.product_description || ''}
                                    onChange={handleChange}
                                  />
                                </td>

                              </tr>
                              <tr>
                                <td>
                                  <span>Contract</span>
                                  <br />
                                  <input
                                    type="text" className='form-control'
                                    name="contract"
                                    defaultValue={data1.contract || ''}
                                    onChange={handleChange}
                                  />
                                </td>
                                <td></td>
                              </tr>
                            </tbody>
                          </table>
                          <div className="col-md-12" id='ax.account_details'>
                            <h4 className="heading">Market Information</h4>
                            <hr></hr>
                          </div>
                          <table class="table table-bordered account-table tables">
                            <tbody>

                              <tr>
                                <td>
                                  <span>Destination</span>
                                  <br />

                                  <input
                                    type="text" className='form-control'
                                    name="destination"
                                    defaultValue={data1.destination || ''}
                                    onChange={handleChange}
                                  />
                                </td>
                                <td></td>
                              </tr>
                            </tbody>
                          </table>
                          <div className="col-md-12" id='ax.account_details'>
                            <h4 className="heading">Price and Price Adjustment</h4>
                            <hr></hr>
                          </div>
                          <table class="table table-bordered account-table tables">
                            <tbody>
                              <tr>
                                <td>
                                  <span>Bonus/Penalty Clause</span>
                                  <br />

                                  <textarea className='form-control'
                                    name="bonus_penalty_clause"
                                    defaultValue={data1.bonus_penalty_clause || ''}
                                    onChange={handleChange}>

                                  </textarea>

                                </td>
                                <td>
                                  <span>Rejections</span>
                                  <br />

                                  <textarea className='form-control'
                                    name="rejections"
                                    defaultValue={data1.rejections || ''}
                                    onChange={handleChange}>
                                  </textarea>
                                </td>

                              </tr>
                              <tr >
                                <td>
                                  <span>Payment Term</span>
                                  <br />


                                  <textarea className='form-control'
                                    name="payment_terms"
                                    defaultValue={data1.payment_terms || ''}
                                    onChange={handleChange}>
                                  </textarea>
                                </td>
                                <td>
                                  <span>General Terms</span>
                                  <br />


                                  <textarea className='form-control'
                                    name="general_terms"
                                    defaultValue={data1.general_terms || ''}
                                    onChange={handleChange}>
                                  </textarea>
                                </td>

                              </tr>
                            </tbody>
                          </table>
                          <div className="col-md-12" id='ax.account_details'>
                            <h4 className="heading">Terms & Conditions</h4>
                            <hr></hr>
                          </div>
                          <table class="table table-bordered account-table tables">
                            <tbody>
                              <tr>
                                <td>
                                  <span>Arbitration</span>
                                  <br />

                                  <select className='form-control'
                                    name="arbitration"
                                    defaultValue={data1.arbitration || ''}
                                    onChange={handleChange}>
                                    <option value="">--None--</option><option value="In Singapore as per SIAC">In Singapore as per SIAC</option><option value="Singapore Chamber of Maritime Arbitration (SCMA)">Singapore Chamber of Maritime Arbitration (SCMA)</option><option value="Laws of England and Wales">Laws of England and Wales</option><option value="Rules of Badan Arbitrase Nasional Indonesia (BANI)">Rules of Badan Arbitrase Nasional Indonesia (BANI)</option>
                                  </select>
                                </td>
                                <td>
                                  <span>Rwood Surveyor Agent</span>
                                  <br />
                                  <input
                                    type="text" className='form-control'
                                    name="rwood_surveyor_agent"
                                    defaultValue={data1.rwood_surveyor_agent || ''}
                                    onChange={handleChange}
                                  />
                                </td>

                              </tr>
                              <tr>
                                <td>
                                  <span>Applicable Law</span>
                                  <br />

                                  <select className='form-control'
                                    name="applicable_law"
                                    defaultValue={data1.applicable_law || ''}
                                    onChange={handleChange}>
                                    <option value="">--None--</option><option value="English Law">English Law</option><option value="Singapore Law">Singapore Law</option><option value="Indonesian Law">Indonesian Law</option>
                                  </select>
                                </td>
                                <td>
                                  <span>Surveyor 2	</span>
                                  <br />

                                  <select className='form-control'
                                    name="surveyor_2"
                                    defaultValue={data1.surveyor_2 || ''}
                                    onChange={handleChange}>
                                    <option>---None---</option>
                                    {surveyors.map((x) => {
                                      return (
                                        <option value={x.account_name}>{x.account_name}</option>
                                      );
                                    })}
                                  </select>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <span>Offer Validity Terms</span>
                                  <br />

                                  <textarea className='form-control'
                                    name="offer_validity_terms"
                                    defaultValue={data1.offer_validity_terms || ''}
                                    onChange={handleChange}>

                                  </textarea>
                                </td>
                                <td>
                                  <span>Surveyor 3</span>
                                  <br />

                                  <select className='form-control'
                                    name="suveyor_3"
                                    defaultValue={data1.suveyor_3 || ''}
                                    onChange={handleChange}>
                                    <option>---None---</option>
                                    {surveyors.map((x) => {
                                      return (
                                        <option value={x.account_name}>{x.account_name}</option>
                                      );
                                    })}
                                  </select>
                                </td>

                              </tr>
                              <tr>
                                <td>
                                  <span>Description</span>
                                  <br />


                                  <textarea className='form-control'
                                    name="description"
                                    defaultValue={data1.description || ''}
                                    onChange={handleChange}>

                                  </textarea>
                                </td>
                                <td>

                                </td>

                              </tr>
                            </tbody>
                          </table>

                          <div className="col-md-12" id='ax.account_details'>
                            <h4 className="heading">Prepared For</h4>
                            <hr></hr>
                          </div>
                          <table class="table table-bordered account-table tables">
                            <tbody>
                              <tr>
                                <td>
                                  <span>Contact Name</span>
                                  <br />
                                  <input
                                    type="text" className='form-control'
                                    name="contact_name"
                                    defaultValue={data1.contact_name || ''}
                                    onChange={handleChange}
                                  />
                                </td>
                                <td>
                                  <span>Additional To Name</span>
                                  <br />
                                  <input
                                    type="text" className='form-control'
                                    name="additional_to_name"
                                    defaultValue={data1.additional_to_name || ''}
                                    onChange={handleChange}
                                  />
                                </td>

                              </tr>
                              <tr>

                                <td>
                                  <span>quote to street</span>
                                  <br />
                                  <input
                                    type="text" className='form-control'
                                    name="quote_to_street"
                                    defaultValue={data1.quote_to_street || ''}
                                    onChange={handleChange}
                                  />
                                </td>
                                <td>
                                  <span>additional to street</span>
                                  <br />
                                  {/* {data1.additional_to} */}
                                  <input
                                    type="text" className='form-control'
                                    name="additional_to_street"
                                    defaultValue={data1.additional_to_street || ''}
                                    onChange={handleChange}
                                  />
                                </td>
                              </tr>
                              <tr>

                                <td>
                                  <span>Quote To Zip/Postal Code	</span>
                                  <br />
                                  {/* {data1.phone} */}
                                  <input
                                    type="text" className='form-control'
                                    name="quote_to_zip_postal_code"
                                    defaultValue={data1.quote_to_zip_postal_code || ''}
                                    onChange={handleChange}
                                  />
                                </td>
                                <td>
                                  <span>Additional To Zip/Postal Code	</span>
                                  <br />
                                  {/* {data1.phone_fax} */}
                                  <input
                                    type="text" className='form-control'
                                    name="additional_to_zip_postal_code"
                                    defaultValue={data1.additional_to_zip_postal_code || ''}
                                    onChange={handleChange}
                                  />
                                </td>
                              </tr>
                              <tr>

                                <td>
                                  <span>quote to city	</span>
                                  <br />
                                  {/* {data1.phone_fax} */}
                                  <input
                                    type="text" className='form-control'
                                    name="quote_to_city"
                                    defaultValue={data1.quote_to_city || ''}
                                    onChange={handleChange}
                                  />
                                </td>
                                <td>
                                  <span>Additional To City		</span>
                                  <br />
                                  {/* {data1.phone_fax} */}
                                  <input
                                    type="text" className='form-control'
                                    name="additional_to_city"
                                    defaultValue={data1.additional_to_city || ''}
                                    onChange={handleChange}
                                  />
                                </td>
                              </tr>
                              <tr>

                                <td>
                                  <span>Quote To State/Province	</span>
                                  <br />
                                  {/* {data1.phone_fax} */}
                                  <input
                                    type="text" className='form-control'
                                    name="quote_to_state_province"
                                    defaultValue={data1.quote_to_state_province || ''}
                                    onChange={handleChange}
                                  />
                                </td>

                                <td>
                                  <span>Additional To State/Province		</span>
                                  <br />
                                  {/* {data1.phone_fax} */}
                                  <input
                                    type="text" className='form-control'
                                    name="additional_to_state_province"
                                    defaultValue={data1.additional_to_state_province || ''}
                                    onChange={handleChange}
                                  />
                                </td>
                              </tr>
                              <tr>


                                <td>
                                  <span>Quote To Country	</span>
                                  <br />
                                  {/* {data1.phone_fax} */}
                                  <input
                                    type="text" className='form-control'
                                    name="quote_to_country"
                                    defaultValue={data1.quote_to_country || ''}
                                    onChange={handleChange}
                                  />
                                </td>
                                <td>
                                  <span>Additional To Country	</span>
                                  <br />
                                  {/* {data1.phone_fax} */}
                                  <input
                                    type="text" className='form-control'
                                    name="additional_to_country"
                                    defaultValue={data1.additional_to_country || ''}
                                    onChange={handleChange}
                                  />
                                </td>
                              </tr>
                              <tr>



                              </tr>
                              <tr>
                                <td>
                                  <span>Email</span>
                                  <br />
                                  {/* {data1.phone_fax} */}
                                  <input
                                    type="text" className='form-control'
                                    name="email"
                                    defaultValue={data1.email || ''}
                                    onChange={handleChange}
                                  />
                                </td>
                                <td>

                                </td>

                              </tr>
                              <tr>
                                <td>
                                  <span>Phone</span>
                                  <br />
                                  {/* {data1.phone_fax} */}
                                  <input
                                    type="text" className='form-control'
                                    name="phone"
                                    defaultValue={data1.phone || ''}
                                    onChange={handleChange}
                                  />
                                </td>
                                <td>

                                </td>

                              </tr>
                              <tr>
                                <td>
                                  <span>Fax</span>
                                  <br />
                                  {/* {data1.phone_fax} */}
                                  <input
                                    type="text" className='form-control'
                                    name="fax"
                                    defaultValue={data1.fax || ''}
                                    onChange={handleChange}
                                  />
                                </td>
                                <td>

                                </td>

                              </tr>
                            </tbody>
                          </table>

                          <div className="col-md-12" id='ax.account_details'>
                            <h4 className="heading">Address Information</h4>
                            <hr></hr>
                          </div>
                          <table class="table table-bordered account-table tables">
                            <tbody>
                              <tr>
                                <td>
                                  <span>Bill To Name</span>
                                  <br />
                                  {/* {data1.bill_to_name} */}
                                  <input
                                    type="text" className='form-control'
                                    name="bill_to_name"
                                    defaultValue={data1.bill_to_name || ''}
                                    onChange={handleChange}
                                  />
                                </td>
                                <td>
                                  <span>Ship To Name</span>
                                  <br />
                                  {/* {data1.ship_to_name} */}
                                  <input
                                    type="text" className='form-control'
                                    name="ship_to_name"
                                    defaultValue={data1.ship_to_name || ''}
                                    onChange={handleChange}
                                  />
                                </td>

                              </tr>
                              <tr>
                                <td>
                                  <span>bill to street</span>
                                  <br />
                                  {/* {data1.bill_to} */}
                                  <input
                                    type="text" className='form-control'
                                    name="bill_to_street"
                                    defaultValue={data1.bill_to_street || ''}
                                    onChange={handleChange}
                                  />
                                </td>
                                <td>
                                  <span>Ship To Street</span>
                                  <br />
                                  <input
                                    type="text" className='form-control'
                                    name="ship_to_street"
                                    defaultValue={data1.ship_to_street || ''}
                                    onChange={handleChange}
                                  />
                                </td>

                              </tr>
                              <tr>
                                <td>
                                  <span>Bill To Zip/Postal Code	</span>
                                  <br />
                                  {/* {data1.bill_to} */}
                                  <input
                                    type="text" className='form-control'
                                    name="bill_to_zip_postal_code"
                                    defaultValue={data1.bill_to_zip_postal_code || ''}
                                    onChange={handleChange}
                                  />
                                </td>
                                <td>
                                  <span>Ship To Zip/Postal Code	</span>
                                  <br />
                                  <input
                                    type="text" className='form-control'
                                    name="ship_to_zip_postal_code"
                                    defaultValue={data1.ship_to_zip_postal_code || ''}
                                    onChange={handleChange}
                                  />
                                </td>

                              </tr>
                              <tr>
                                <td>
                                  <span>Bill To City	</span>
                                  <br />
                                  {/* {data1.bill_to} */}
                                  <input
                                    type="text" className='form-control'
                                    name="bill_to_city"
                                    defaultValue={data1.bill_to_city || ''}
                                    onChange={handleChange}
                                  />
                                </td>
                                <td>
                                  <span>Ship To City	</span>
                                  <br />
                                  <input
                                    type="text" className='form-control'
                                    name="ship_to_city"
                                    defaultValue={data1.ship_to_city || ''}
                                    onChange={handleChange}
                                  />
                                </td>

                              </tr>
                              <tr>
                                <td>
                                  <span>Bill To State/Province	</span>
                                  <br />
                                  {/* {data1.bill_to} */}
                                  <input
                                    type="text" className='form-control'
                                    name="bill_to_state_province"
                                    defaultValue={data1.bill_to_state_province || ''}
                                    onChange={handleChange}
                                  />
                                </td>
                                <td>
                                  <span>Ship To State/Province	</span>
                                  <br />
                                  <input
                                    type="text" className='form-control'
                                    name="ship_to_state_province"
                                    defaultValue={data1.ship_to_state_province || ''}
                                    onChange={handleChange}
                                  />
                                </td>

                              </tr>

                              <tr>
                                <td>
                                  <span>Bill To Country	</span>
                                  <br />
                                  {/* {data1.bill_to} */}
                                  <input
                                    type="text" className='form-control'
                                    name="bill_to_country"
                                    defaultValue={data1.bill_to_country || ''}
                                    onChange={handleChange}
                                  />
                                </td>
                                <td>
                                  <span>Ship To Country	</span>
                                  <br />
                                  <input
                                    type="text" className='form-control'
                                    name="ship_to_country"
                                    defaultValue={data1.ship_to_country || ''}
                                    onChange={handleChange}
                                  />
                                </td>

                              </tr>
                            </tbody>
                          </table>
                          {/* <div className="col-md-12" id='ax.account_details'>
     <h4 className="heading">System Information</h4>
     <hr></hr>
     </div>
          <table class="table table-bordered account-table tables">
           <tbody>
            <tr>
            <td>
                  <span>Created By</span>
                  <br />
                  <input
                type="text" className='form-control'
                name="created_by"
                defaultValue={data1.created_by || ''}
                onChange={handleChange}
              />
                </td>
                <td>
                  <span>Modified By</span>
                  <br />
                  <input
                type="text" className='form-control'
                name="modified_by"
                defaultValue={data1.modified_by || ''}
                onChange={handleChange}
              />
                </td>
            </tr>

            </tbody>
          </table> */}
                          <p className='edit-btn'>
                            <input
                              type="submit"
                              className="account-save"
                              onClick={handleSaveEdit}
                              //value={isSubmitted ? "Saving" : "Save"}
                             value="Save"
                            />
                            <button className="btn btn-primary" onClick={handleCancelEdit}>Cancel</button>
                          </p>

                        </>

                      ) : (
                        <>
                          <div className="edit-delete">
                            <span id="edit" onClick={handleEditClick}>
                              Edit
                            </span>
                            <span>
                              <Link to={`/opportunity/opportunity-detail/${data1.opportunity_id}`}> Cancel</Link>
                            </span>
                            {data1.status === 'Accepted' ? (
                              <span onClick={createContract}>Create Contract</span>
                            ) : (
                              <></>
                            )}
                          </div>

                          <div className="col-md-12" id='ax.account_details'>
                            <h4 className="heading">Quote Detail</h4>
                            <hr></hr>
                          </div>

                          <table class="table table-bordered account-table tables">
                            <tbody>
                              <tr>
                                <td id="td-right">
                                  <span>Quote Number</span>
                                </td><td>
                                  {data1.quote_number}
                                </td>
                                <td id="td-right">
                                  <span>Quote Record Type</span>
                                </td>
                                <td>
                                  {data1.quote_record_type}
                                </td>

                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>Currency</span>
                                </td><td>
                                  {data1.currency}
                                </td>
                                <td id="td-right">
                                  <span>Locked</span>
                                </td><td>
                                  <input
                                    type='checkbox'
                                    name='locked'
                                    checked={lockedOne} // Use the state variable to determine if the checkbox is checked
                                    onChange={handleCheckboxLocked} // Handle the change event
                                    className='mx-3' disabled={isButtonEnabled}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>Exchange Rate</span>
                                </td><td>
                                  {data1.exchange_rate}
                                </td>
                                <td id="td-right">
                                  {/* <span>quote name</span> */}
                                </td><td>
                                  {/* {data1.quote_name} */}
                                </td>
                              </tr><tr>
                                <td id="td-right">
                                  <span>Quote Name</span>
                                </td><td>
                                  {data1.quote_name}
                                </td>
                                <td id="td-right">
                                  <span>Status</span>
                                </td><td>
                                  {data1.status || 'Offered'}
                                </td>
                              </tr>
                              <tr>

                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>opportunity Name</span>
                                </td><td>
                                  <Link to={`/opportunity/opportunity-detail/${data1.opportunity_id}`}>{data1.opportunity_name}</Link>
                                </td>
                                <td id="td-right">
                                  <span>Supplier</span>
                                </td><td>
                                  <Link to={`/accounts/${data1.supplier_id}`}>{data1.supplier}</Link>
                                </td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>Account Name</span>
                                </td><td>
                                  <Link to={`/accounts/${data1.account_id}`}>{data1.account_name}</Link>
                                </td>

                                <td id="td-right">
                                  <span>line items</span>
                                </td><td>
                                  {data1.line_items}
                                </td>

                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>quote to name</span>
                                </td><td>
                                  {data1.quote_to_name}
                                </td>
                                <td id="td-right">
                                  <span>Trader Name</span>
                                </td><td>
                                  <Link to={'/user/user-details/' + data1.trader_id}> {data1.trader_name}</Link>
                                </td>


                              </tr>

                              <tr>
                                <td id="td-right">
                                  <span>Quote Date</span>
                                </td><td>
                                  {data1.quote_date}
                                </td>

                                <td></td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>Expiration Date</span>
                                </td><td>
                                  {data1.expiration_date}
                                </td>
                                <td id="td-right">
                                  <span>Trader Name</span>
                                </td><td>
                                  <Link to={'/user/user-details/' + data1.trader_id}> {data1.trader_name}</Link>
                                </td>


                              </tr>
                            </tbody>
                          </table>
                          <div className="col-md-12" id='ax.account_details'>
                            <h4 className="heading">Product information</h4>
                            <hr></hr>
                          </div>
                          <table class="table table-bordered account-table tables">
                            <tbody>
                              <tr>
                                <td id="td-right">
                                  <span>origin
                                  </span>
                                </td><td>
                                  {data1.origin}
                                </td>
                                <td id="td-right">
                                  <span>Quantity MT
                                  </span>
                                </td><td>
                                  {data1.quantity_MT}
                                </td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>Commodity</span>
                                </td><td>
                                  {data1.commodity}
                                </td>
                                <td id="td-right">
                                  <span>Product Description	</span>
                                </td><td>
                                  {data1.product_description}
                                </td>

                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>Contract</span>
                                </td><td>
                                <Link to={'/contract/contract-details/' + data1.contractId}> {data1.contract}</Link>
                                </td>
                                <td id="td-right">
                                  {/* <span>Contract</span> */}
                                </td><td>
                                  {/* {data1.contract} */}
                                </td>

                              </tr>

                            </tbody>
                          </table>


                          <div className="col-md-12" id='ax.account_details'>
                            <h4 className="heading">Market Information</h4>
                            <hr></hr>
                          </div>
                          <table class="table table-bordered account-table tables">
                            <tbody>
                              <tr>
                                <td id="td-right">
                                  <span>destination</span>
                                </td><td>
                                  {data1.destination}
                                </td>

                                <td id="td-right">
                                  {/* <span>Commodity</span> */}
                                </td><td>
                                  {/* {data1.commodity} */}
                                </td>

                              </tr>


                            </tbody>
                          </table>
                          <div className="col-md-12" id='ax.account_details'>
                            <h4 className="heading">Price and Price Adjustment</h4>
                            <hr></hr>
                          </div>
                          <table class="table table-bordered account-table tables">
                            <tbody>
                              <tr>
                                <td id="td-right">
                                  <span>Bonus/Penalty Clause</span>
                                </td><td>
                                  {data1.bonus_penalty_clause}
                                </td>
                                <td id="td-right">
                                  {/* <span>Rejections</span> */}
                                </td><td>
                                  {/* {data1.rejections} */}
                                </td>

                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>Rejections</span>
                                </td><td>
                                  {data1.rejections}
                                </td>
                                <td id="td-right">
                                  {/* <span>Payment Term</span> */}
                                </td><td>
                                  {/* {data1.payment_terms} */}
                                </td>


                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>Payment Term</span>
                                </td><td>
                                  {data1.payment_terms}
                                </td>
                                <td id="td-right">
                                  {/* <span>General Terms</span> */}
                                </td><td>
                                  {/* {data1.general_terms} */}
                                </td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>General Terms</span>
                                </td><td>
                                  {data1.general_terms}
                                </td>
                                <td id="td-right">
                                  {/* <span>Payment Term</span> */}
                                </td><td>
                                  {/* {data1.payment_terms} */}
                                </td>

                              </tr>
                            </tbody>
                          </table>
                          <div className="col-md-12" id='ax.account_details'>
                            <h4 className="heading">Terms & Conditions</h4>
                            <hr></hr>
                          </div>
                          <table class="table table-bordered account-table tables">
                            <tbody>
                              <tr>
                                <td id="td-right">
                                  <span>Arbitration</span>
                                </td><td>
                                  {data1.arbitration}
                                </td>
                                <td id="td-right">
                                  <span>Rwood Surveyor Agent</span>
                                </td><td>
                                  {data1.rwood_surveyor_agent}
                                </td>

                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>Applicable Law</span>
                                </td><td>
                                  {data1.applicable_law}
                                </td>
                                <td id="td-right">
                                  <span>Surveyor 2	</span>
                                </td><td>
                                  {data1.surveyor_2}
                                </td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>Offer Validity Terms</span>
                                </td><td>
                                  {data1.offer_validity_terms}
                                </td>
                                <td id="td-right">
                                  <span>Surveyor 3</span>
                                </td><td>
                                  {data1.suveyor_3}
                                </td>
                              </tr>
                              <tr>
                                <td id="td-right">
                                  <span>Description</span>
                                </td><td>
                                  {data1.description}
                                </td>
                                <td id="td-right">
                                  {/* <span>Surveyor 3</span> */}
                                </td><td>
                                  {/* {data1.suveyor_3} */}
                                </td>
                              </tr>
                            </tbody>
                          </table>

                          <div className="col-md-12" id='ax.account_details'>
                            <h4 className="heading">Prepared For</h4>
                            <hr></hr>
                          </div>
                          <table class="table table-bordered account-table tables">
                            <tbody>


                              <tr>

                                <td id="td-right">
                                  <span>Contact Name</span>
                                </td><td>
                                  {data1.contact_name}
                                </td>
                                <td id="td-right">
                                  <span>additional to name</span>
                                </td><td>
                                  {data1.additional_to_name}
                                </td>

                              </tr>
                              <tr>

                                <td id="td-right">
                                  <span>quote to name</span>
                                </td><td>
                                  {data1.quote_to_name}
                                </td>
                                <td id="td-right">
                                  <span>additional to</span>
                                </td><td>
                                  {data1.additional_to}
                                </td>

                              </tr>

                              <tr>

                                <td id="td-right">
                                  <span>Email</span>
                                </td><td>
                                  {data1.email}
                                </td>
                                <td id="td-right">
                                  {/* <span>additional to name</span> */}
                                </td><td>
                                  {/* {data1.additional_to_name} */}
                                </td>

                              </tr>
                              <tr>

                                <td id="td-right">
                                  <span>Phone</span>
                                </td><td>
                                  {data1.phone_fax}
                                </td>
                                <td id="td-right">
                                  {/* <span>additional to name</span> */}
                                </td><td>
                                  {/* {data1.additional_to_name} */}
                                </td>

                              </tr>
                              <tr>

                                <td id="td-right">
                                  <span>Fax</span>
                                </td><td>
                                  {data1.fax}
                                </td>
                                <td id="td-right">
                                  {/* <span>additional to name</span> */}
                                </td><td>
                                  {/* {data1.additional_to_name} */}
                                </td>

                              </tr>
                            </tbody>
                          </table>

                          <div className="col-md-12" id='ax.account_details'>
                            <h4 className="heading">Address Information</h4>
                            <hr></hr>
                          </div>
                          <table class="table table-bordered account-table tables">
                            <tbody>
                              <tr>
                                <td id="td-right">
                                  <span>bill to name</span>
                                </td><td>
                                  {data1.bill_to_name}
                                </td>
                                <td id="td-right">
                                  <span>ship to name</span>
                                </td><td>
                                  {data1.ship_to_name}
                                </td>

                              </tr>
                              {/* <tr>
            <td id="td-right">
                  <span>bill to</span>
                  </td><td>
                  {data1.bill_to}
                </td>
                <td id="td-right">
                  <span>ship to</span>
                  </td><td>
                  {data1.ship_to}
                </td>

            </tr>
                */}
                              <tr>
                                <td id="td-right">
                                  <span>bill to country</span>
                                </td><td>
                                  {data1.bill_to_country}
                                </td>
                                <td id="td-right">
                                  <span>ship to country</span>
                                </td><td>
                                  {data1.ship_to_country}
                                </td>

                              </tr>
                            </tbody>
                          </table>
                          {/* <div className="col-md-12" id='ax.account_details'>
     <h4 className="heading">System Information</h4>
     <hr></hr>
     </div>
          <table class="table table-bordered account-table tables">
           <tbody>
            <tr>
            <td id="td-right">
                  <span>Created By</span>
                  </td><td>
                  {data1.created_by}
                </td>
                <td id="td-right">
                  <span>Modified By</span>
                  </td><td>
                  {data1.modified_by}
                </td>

            </tr>
             </tbody>
          </table> */}


 <Row id="table-styles">
                    <div className="col-md-12" id='head'>
                      <h4 className="heading"><span>Quote Shipment</span>
                        {/* <span><Popup trigger={<span className="button add-accountrecord"> <FaPlus/> New </span>} modal>

    <form onSubmit={handleSubmittcf} className="add-accounts">
     <div className='form-group'>
     <label>TCF Record Type</label><br/>
     <select name='account_record' className='form-control' onChange={handleChangetcf} value={formData.account_record}>
       <option >--Select TCF Record Type--</option>
       <option value='Buyer - Spot'>Buyer - Spot</option>
       <option value='Buyer - Long Term'>Buyer - Long Term</option>
       </select>
       </div>
       <input type='submit' className="btn btn-primary " value='Submit'/>

       </form>
</Popup></span> */}
                      </h4>
                      <div id='inner-heading'>

                      </div>
                      <hr></hr>
                    </div>
                    <table id='quote' className="">
                      <thead>
                        <tr>
                          <th>Quote Shipment Code</th>
                          <th>Quantity</th>
                          <th>Laycan</th>
                          <th>Pricing</th>
                          <th>Quanity Info	</th>
                          <th>Vessel Type	</th>
                          <th>Shipping Term
                          </th>
                        </tr>
                      </thead>

                      {quote.length > 0 ?

                        <tbody>
                          {
                            quote.map((x, i) => (
                              <tr>
                                <td><Link to={'/quote-shipment/detail/' + x.id}>{x.quote_shipment_code}</Link></td>
                                <td>{x.quote_shipment_quantity}</td>
                                <td>{x.quote_shipment_laycan}</td>
                                <td>{x.quote_shipment_pricing}</td>
                                <td>{x.quote_shipment_quantity_info
                                }</td>

                                <td>{x.quote_shipment_vessel_type
                                }</td>
                                <td>{x.quote_shipment_shipping_term
                                }</td>
                              </tr>
                            ))}
                        </tbody>
                        : <tbody>
                          <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>No data available</td>
                            <td></td>
                            <td></td><td></td>

                          </tr>
                        </tbody>
                      }
                    </table>

                  </Row>
                      <Row id="table-styles">
                    <div className="col-md-12" id='head'>
                      <h4 className="heading"><span>Quote Product</span>
                        <span>
                          <Link to={"/quote-product/select/" + id}>
                            <i className="fe fe-download-cloud me-2"></i>
                              New
                          </Link>
                        </span>
                        {/* <span><Popup trigger={<span className="button add-accountrecord"> <FaPlus/> New </span>} modal>

    <form onSubmit={handleSubmittcf} className="add-accounts">
     <div className='form-group'>
     <label>TCF Record Type</label><br/>
     <select name='account_record' className='form-control' onChange={handleChangetcf} value={formData.account_record}>
       <option >--Select TCF Record Type--</option>
       <option value='Buyer - Spot'>Buyer - Spot</option>
       <option value='Buyer - Long Term'>Buyer - Long Term</option>
       </select>
       </div>
       <input type='submit' className="btn btn-primary " value='Submit'/>

       </form>
</Popup></span> */}
                      </h4>
                      <div id='inner-heading'>

                      </div>
                      <hr></hr>
                    </div>
                    <table id='quote-product' className="">
                      <thead>
                        <tr>
                          <th>Quote Product Name	</th>
                          <th>Quote Shipment	</th>
                          <th>Gross As Received Typical	</th>
                          <th>Ash Content Typical	</th>
                          <th>Total Moisture Typical	</th>
                          <th>Total Sulphur Typical ARB
                          </th>
                        </tr>
                      </thead>

                      {quoteProduct.length > 0 ?

                        <tbody>
                          {
                            quoteProduct.map((y, i) => (
                              <tr>

                                <td><span id='codes'><Link to={'/opportunity/quote-detail/' + y.id}>{y.quotes_name}</Link></span></td>
                                <td>{y.quotes_shippment}</td>
                                <td>{y.gross_as_received_typical	}</td>
                                <td>{y.ash_content_typical	}</td>
                                <td>{y.total_moisture_typical	}</td>
                                <td>{y.total_sulphur_typical_arb
	}</td>
                              </tr>
                            ))}
                        </tbody>
                        : <tbody>
                          <tr>
                            <td></td>
                            <td></td>
                            <td>No data available</td>
                            <td></td>
                            <td></td>
                            <td></td>

                          </tr>
                        </tbody>
                      }
                    </table>

                  </Row>
                  <Row id="table-styles">
     <div className="col-md-12" id="head">
       <h4 className="heading">
         <span>Quote Clauses
         </span>
         <span>
           <i className=""></i> New Quote Clauses
           </span>

       </h4>
       <hr>
       </hr>
     </div>
     <table id="history" className="">
       <tr>
         <td>
           <p class="no-records"> No records to display </p>
         </td>
       </tr>
     </table>
   </Row>
   <Row id="table-styles">
                    <div className="col-md-12" id='head'>
                      <h4 className="heading"><span>Trade Confirmation Form</span>

                      </h4>
                      <div id='inner-heading'>

                      </div>
                      <hr></hr>
                    </div>
                    <table id='tcf' className="">
                      <thead>
                        <tr>
                          <th>TCF No</th>
                          <th>tcf status</th>
                          <th>Buyer</th>
                          <th>supplier</th>
                          <th>Quantity (MT)	</th>
                          <th>Contractual GAR (P)	</th>
                          <th>Actual GAR (P)</th>
                          <th>Actual GAR (S)</th>
                        </tr>
                      </thead>

                      {tcf.length > 0 ?

                        <tbody>
                          {
                            tcf.map((x, i) => (
                              <tr>
                                <td><Link to={"/tcf/tcf-details/" + x.id}>{x.tcf_no}</Link></td>
                                <td>{x.tcf_status}</td>
                                <td><Link to={"/accounts/" + x.account_id}>{x.buyer}</Link></td><td><Link to={"/accounts/" + x.supplier_id}>{x.supplier}</Link></td>
                                <td>{x.quantity_MT}</td>
                                <td>{x.contractual_GAR_P}</td>
                                <td>{x.actual_gar_p}</td>
                                <td>{x.actual_gar_s}</td>
                              </tr>
                            ))}
                        </tbody>
                        : <tbody>
                          <tr>
                            <td></td>
                            <td></td><td></td>
                            <td></td>
                            <td>No data available</td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        </tbody>
                      }
                    </table>

                  </Row>

   <Row id="table-styles">
     <div className="col-md-12" id="head">
       <h4 className="heading">
         <span>Quote PDFs
         </span>

       </h4>
       <hr>
       </hr>
     </div>
     <table id="history" className="">
       <tr>
         <td>
           <p class="no-records"> No records to display </p>
         </td>
       </tr>
     </table>
   </Row>
   <Row id="table-styles">
     <div className="col-md-12" id="head">
       <h4 className="heading">
         <span>Notes
         </span>
         <span>New Note
         </span>
       </h4>
       <hr>
       </hr>
     </div>
     <table id="history" className="">
       <tr>
         <td>
           <p class="no-records"> No records to display </p>
         </td>
       </tr>
     </table>
   </Row>
   <Row id="table-styles">
     <div className="col-md-12" id="head">
       <h4 className="heading">
         <span>Files
         </span>
         <span>Upload Files         </span>
       </h4>
       <hr>
       </hr>
     </div>
     <table id="history" className="">
       <tr>
         <td>
           <p class="no-records"> No records to display </p>
         </td>
       </tr>
     </table>
   </Row>

   <Row id="table-styles">
     <div className="col-md-12" id="head">
       <h4 className="heading">
         <span>Open Activities

         </span>
         <span>New Task       </span>
         <span>New Event       </span>
       </h4>
       <hr>
       </hr>
     </div>
     <table id="history" className="">
       <tr>
         <td>
           <p class="no-records"> No records to display </p>
         </td>
       </tr>
     </table>
   </Row>
   <Row id="table-styles">
     <div className="col-md-12" id="head">
       <h4 className="heading">
         <span>Notes & Attachments</span>
         <span>
            New Note </span>
         <span>
           Attach File </span>
       </h4>
       <hr>
       </hr>
     </div>
     <table id="history" className="">
       <tr>
         <td>
           <p class="no-records"> No records to display </p>
         </td>
       </tr>
     </table>
   </Row>
   <Row id="table-styles">
     <div className="col-md-12" id="head">
       <h4 className="heading">
         <span>Activity History
         </span>
         <span>
            Log a Call </span>
         <span>
           Mail Merge </span>
       </h4>
       <hr>
       </hr>
     </div>
     <table id="history" className="">
       <tr>
         <td>
           <p class="no-records"> No records to display </p>
         </td>
       </tr>
     </table>
   </Row>

   <Row id="table-styles">
     <div className="col-md-12" id="head">
       <h4 className="heading">
         <span>Approval History

         </span>
         <span>
            Submit for Approval</span>

       </h4>
       <hr>
       </hr>
     </div>
     <table id="history" className="">
       <tr>
         <td>
           <p class="no-records"> No records to display </p>
         </td>
       </tr>
     </table>
   </Row>
                        </>
                      )}</div>

                  ) : (<></>)}
                </>
              )}
            </>
          )}
        </>
      )}</div></div>
      <p> <p></p><p></p></p>
      <Footer />
    </div>
  );
};

export default AllQuoteView;
