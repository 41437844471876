import React, {
  Component,
  useState,
  useEffect,
  useContext,
  forwardRef,
} from "react";
import { Card, OverlayTrigger, Table } from "react-bootstrap";
import Tooltip from '../tooltip/Tooltip';
import { IoIosHelpCircle } from "react-icons/io";
import "datatables.net-dt/js/dataTables.dataTables.js";
import { AES, enc } from "crypto-js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_URL } from '../config';
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import "datatables.net-responsive-dt";
import Editor from 'react-simple-wysiwyg';
import RecentItem from "../recentitem/rececentItem";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "react-data-table-component-extensions/dist/index.css";
import { parse, format, isValid } from "date-fns";
import { Link, useNavigate } from "react-router-dom";
import { TokenContext } from "../AuthProvider";
import Container from "react-bootstrap/Container";
import Popup from "reactjs-popup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Header from "../Header";
import Footer from "../Footer";

import {
  FaEnvelope,
  FaPhoneSquareAlt,
  FaMobileAlt,
  FaFax,
} from "react-icons/fa";
import axios from "axios";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { FaPencilAlt, FaTrashAlt, FaPlus } from "react-icons/fa";
// import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
import $ from "jquery";
import { useParams } from "react-router-dom";
import DatePicker from "react-datepicker";

const ViewContracts = () => {
  const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
  const encryptedAccessToken = localStorage.getItem("access_token");
  const decryptedAccessToken = encryptedAccessToken
    ? AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8)
    : null;

  const params = useParams();
  const id = params.id;
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [data, setData] = useState({});
  const [document, setDocument] = useState([]);
  const [history, setHistory] = useState([]);

  const [ContractStart, setContractStart] = useState(null);
  const [lengths, setLengths] = useState([]);
  const [lengthShipment, setLengthshipment] = useState([]);

  const [errors, setErrors] = useState('');
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [contractEffectiveDate, setContractEffective] = useState("");
  const [renewal, setRenewal] = useState("");
  const [signed, setSigned] = useState("");

  const [customersigned, setCustomerSigned] = useState("");
  const [quoteTable, setQuoteTable] = useState([]);
  const [originals, setOriginals] = useState("");
  const [originalcontract, setOriginalcontract] = useState("");
  const birthDate = startDate
    ? `${startDate.getDate().toString().padStart(2, "0")}/${(
      startDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}/${startDate.getFullYear()}`
    : null;

  const effectiveDate = endDate
    ? `${endDate.getDate().toString().padStart(2, "0")}/${(
      endDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}/${endDate.getFullYear()}`
    : null;



  const options = { minimumFractionDigits: 2, maximumFractionDigits: 2 };
  const formatNumber = (number) => {
    return new Intl.NumberFormat("en-US", options).format(number);
  };

  const renderShipmentQty = (tcfValues) => {
    if (tcfValues > 0) {
      return <span className='sr'>{formatNumber(tcfValues)}</span>;
    } else if (tcfValues < 0) {
      return <span className='sr'>({formatNumber(Math.abs(tcfValues))})</span>;
    } else {
      return <span className='sr'>{formatNumber(tcfValues)}</span>;
    }
  };



  // const signedDate = signed ? `${signed.getDate().toString().padStart(2, '0')}/${(signed.getMonth() + 1).toString().padStart(2, '0')}/${signed.getFullYear()}` : null;
  // const renewalDate = renewal ? `${renewal.getDate().toString().padStart(2, '0')}/${(renewal.getMonth() + 1).toString().padStart(2, '0')}/${renewal.getFullYear()}` : null;
  // const originalDate = originals ? `${originals.getDate().toString().padStart(2, '0')}/${(originals.getMonth() + 1).toString().padStart(2, '0')}/${originals.getFullYear()}` : null;
  // const originalContract = originalcontract ? `${originalcontract.getDate().toString().padStart(2, '0')}/${(originalcontract.getMonth() + 1).toString().padStart(2, '0')}/${originalcontract.getFullYear()}` : null;

  const handleStartDateChange = (date) => {
    setStartDate(date);
    console.log(startDate)
  };
  const handleEndDateChanges = (date) => {
    setEndDate(date);
  };

  const handleEndDateChange = (date) => {
    setContractEffective(date);
  };

  const handleRenewal = (date) => {
    setRenewal(date);
  };
  const handleSigned = (date) => {
    setSigned(date);
  };

  const handleOriginal = (date) => {
    setOriginals(date);
  };

  const handleOriginalcontract = (date) => {
    setOriginalcontract(date);
  };
  const handleCustomerSignedDate = (date) => {
    setCustomerSigned(date);
  };
  const [supplierID, setSupplierID] = useState([])

  const [contractDocument, setcontractDocument] = useState([]);
  const { username, token } = useContext(TokenContext);
  const [opportunity, setOpportunity] = useState([]);
  const [conShipment, setContractship] = useState([]);
  const [conShipmentParent, setContractshipParent] = useState([]);
  const [tcf, setTcf] = useState([]);
  const [payable,setPayable]= useState([]);

  const [contractProduct, setContractProduct] = useState([]);
  const [orderData, setOrdersData] = useState([]);
  const conShipmentParents = [...(conShipment || []), ...(conShipmentParent || [])]; // Using default empty array if conShipment is undefined
const [buyerProduct,setBuyerProduct]=useState([]);
const [receipts,setReceipts]=useState([]);


  useEffect(() => {
    setTimeout(fetchData, 1000);
  }, [decryptedAccessToken, id]);
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${API_URL}get/contractbyID/${id}`,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );

      if (response.status === 200) {
        const data = response.data[0];
        console.log("Response data:", data);
        setPayable(data.payable_receivable_lineitem)
        setReceipts(data.payable_receipts_lineitem)
        const contractDetails = data.contract_details;
        const contractShipments = data.contract_shipments;
        const documents = data.documents;
        const history = data.history;
        const supplierId = data.supplier_id;
        const contractData = data.contract_products;
        const tcfs = data.tcf;
        const orders = data.orders;
        const opportunity = data.opportunity;

        console.log("contractProducts:", contractData);

        setLengths(response.data.length);
        setLengthshipment(contractShipments.length);
        setSupplierID(supplierId);
        setOpportunity(opportunity);
        setQuoteTable(data.quotes);
        setBuyerProduct(data.products)
        setContractship(contractShipments.parent_shipments || null);
        setContractshipParent(contractShipments.child_shipments || null);
        setDocument(documents);
        setHistory(history);
        setTcf(tcfs);
        setOrdersData(orders);
        setContractProduct(contractData); // This should work if contractProducts is valid
        setDatas(contractDetails);

        const parseDate = (dateString) =>
          dateString ? parse(dateString, "dd/MM/yyyy", new Date()) : null;

        setStartDate(parseDate(contractDetails.contract_start_date));
        setEndDate(parseDate(contractDetails.contract_end_date));
        setContractEffective(parseDate(contractDetails.contract_effective_date));
        setRenewal(parseDate(contractDetails.renewal_date));
        setSigned(parseDate(contractDetails.company_signed_date));
        setOriginals(parseDate(contractDetails.original_contract_recieved_date));
        setOriginalcontract(parseDate(contractDetails.original_contract_sent_date));
        setCustomerSigned(parseDate(contractDetails.customer_signed_date));

        setTimeout(() => {
          console.log("Initializing DataTables");
          $("#opportunity").DataTable();
          $("#payables").DataTable();
          $("#receipt").DataTable();
          
           $("#history").DataTable();$("#products").DataTable();
          $("#tcf").DataTable();
           $("#orders").DataTable();$("#contract-ships").DataTable();
          $("#quotes").DataTable();
          $("#historys").DataTable();
          $("#document").DataTable();
          $("#shipment").DataTable();
          $("#contract-product").DataTable();
          $("#contract-ship").DataTable();
        }, 100);
      } else {
        toast.error("No Data Available", {
          position: "bottom-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };




  const [datas, setDatas] = useState([]);
  const [contractdate, setContractDate] = useState("");

  useEffect(() => {
    const dateObject = new Date(ContractStart);
    if (dateObject) {
      const formattedDate = format(dateObject, "dd/MM/yyyy", {
        timeZone: "UTC",
      });
      setContractDate(formattedDate);
    }
  });


  const [description_1, setDescription_1] = useState()
  const [pricingTerm, setPricingTerm] = useState()
  const [bonus, setBonus] = useState()
  const [priceAdjust, setPriceAdjust] = useState()
  const [paymentTerms, setPaymentTerms] = useState()
  const [rejections, setRejections] = useState()
  const [generalTerms, setGeneralTerms] = useState()
  const [productdesc, setProductdesc] = useState()
  const [pendingMatter, setPendingMatter] = useState()
  const [remarks, setRemarks] = useState()
  const [interlab, setInterlab] = useState()


  useEffect(() => {
    if (datas.rejections || datas.interlab_tolerance || datas.pending_matters || datas.remark || datas.product_description || datas.general_terms || datas.description || datas.pricing_terms || datas.price_adjustment_clause || datas.bonus_penalty_clause || datas.payment_terms) {
      setDescription_1(datas.description);
      setPricingTerm(datas.pricing_terms)
      setPriceAdjust(datas.price_adjustment_clause)
      setBonus(datas.bonus_penalty_clause)
      setPaymentTerms(datas.payment_terms)
      setRejections(datas.rejections)
      setGeneralTerms(datas.general_terms)
      setProductdesc(datas.product_description)
      setPendingMatter(datas.pending_matters)
      setRemarks(datas.remark)
      setInterlab(datas.interlab_tolerance)
    }
  }, [datas]);

  function onChangeInterlab(e) {
    setInterlab(e.target.value);
  }
  function onChangePending(e) {
    setPendingMatter(e.target.value);
  }
  function onChangeRemarks(e) {
    setRemarks(e.target.value);
  }
  function onChangeProductDesc(e) {
    setProductdesc(e.target.value);
  }
  function onChangeGeneralTerms(e) {
    setGeneralTerms(e.target.value);
  }
  function onChangeDescription(e) {
    setDescription_1(e.target.value);
  }
  function onChangePricingTerm(e) {
    setPricingTerm(e.target.value);
  }
  function onChangePricingAdjust(e) {
    setPriceAdjust(e.target.value);
  }
  function onChangeBonus(e) {
    setBonus(e.target.value);
  }
  function onChangePaymentTerms(e) {
    setPaymentTerms(e.target.value);
  }
  function onChangeRejections(e) {
    setRejections(e.target.value);
  }




  // locked
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);

  const [lockeds, setLockeds] = useState(null);
  const check_box = datas.locked;
  console.log(check_box)
  useEffect(() => {
    setLockeds(check_box);
  }, [check_box]); // Run this effect whenever tt changes

  useEffect(() => {
    console.log("Value of lockeds:", lockeds); // Log lockeds
  }, [lockeds]); // Run

  const handleCheckboxChange = (e) => {
    setLockeds(e.target.checked);
  };


  useEffect(() => {
    setIsButtonEnabled(true);
  }, []);


  const [getPrice, setgetPrice] = useState([]);

  useEffect(() => {
    fetchDataPrice();
  }, [decryptedAccessToken]);

  const fetchDataPrice = async () => {
    try {
      const response = await axios.get(
        `${API_URL}get/all/price_items`,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data.priceitem;
      // console.log(data)
      setgetPrice(data);
    } catch (error) {
      console.error(error);
    }
  };

  const [check, setCheck1] = useState(false);
  // const [startDate, setStartDate] = useState(new Date());

  const [priceBook, setPriceBook] = useState([]);
  useEffect(() => {
    fetchData2();
  }, [decryptedAccessToken]);

  const fetchData2 = async () => {
    try {
      const response = await axios.get(
        `${API_URL}get/all/pricebook`,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data.pricebook;
      console.log(response)
      setPriceBook(data);
    } catch (error) {
      console.error(error);
    }
  };

  const [supplierData, setsupplierData] = useState([]);

  useEffect(() => {
    fetchDataSupplier();
  }, [decryptedAccessToken]);

  const fetchDataSupplier = async () => {
    try {
      const response = await axios.get(
        `${API_URL}get/supplier`,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data;
      // console.log(data)
      setsupplierData(data);
    } catch (error) {
      console.error(error);
    }
  };

  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    fetchDataContacts();
  }, [decryptedAccessToken]);

  const fetchDataContacts = async () => {
    try {
      const response = await axios.get(
        `${API_URL}allcontacts`,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      // const data = response.data.buyer_product;
      const data = response.data.contact;
      console.log(data);
      setContacts(data);
    } catch (error) {
      console.error(error);
    }
  };


  const [accountName, setAccountName] = useState([]);
  useEffect(() => {
    fetchDataAccountName();
  }, [decryptedAccessToken]);

  const fetchDataAccountName = async () => {
    try {
      const response = await axios.get(
        `${API_URL}all/accounts`,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data.accounts;
      setAccountName(data);
      console.log(data);
    } catch (error) {
      console.error(error);
    }
  };

  const [surveyors, setSurveyor] = useState([]);

  useEffect(() => {
    fetchData6();
  }, [decryptedAccessToken]);

  const fetchData6 = async () => {
    try {
      const response = await axios.get(
        `${API_URL}get/surveyor`,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data;
      console.log(JSON.stringify(data))
      setSurveyor(data);
    } catch (error) {
      console.error(error);
    }
  };

  const [companyData, setcompanyData] = useState([]);
  useEffect(() => {
    fetchDataCompany();
  }, [decryptedAccessToken]);

  const fetchDataCompany = async () => {
    try {
      const response = await axios.get(
        `${API_URL}get/all/rwoodcompany`,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data.rwoodcompany;
      // console.log(data)
      setcompanyData(data);
    } catch (error) {
      console.error(error);
    }
  };




  const [traderData, setTraderData] = useState([]);

  useEffect(() => {
    fetchDataTrader();
  }, [decryptedAccessToken]);

  const fetchDataTrader = async () => {
    try {
      const response = await axios.get(
        `${API_URL}get/all/rwoodusers`,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data.rwoodusers;
      // console.log(data)
      setTraderData(data);
    } catch (error) {
      console.error(error);
    }
  };
  const navigate = useNavigate();
  const [formData, setFromdata] = useState({
    account_record: "Buyer - Long Term",
  });

  const handleChange = (e) => {
    setFromdata({ ...formData, [e.target.name]: e.target.value });
  };

  const [error, setError] = useState({});
  const validate = () => {
    const newErrors = {};
    if (!formData.account_record) {
      newErrors.account_record = 'Record Type is required';
    }
    return newErrors;
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setError(validationErrors);
    } else {
      const encryptedAccessToken = localStorage.getItem("access_token");
      const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
      if (encryptedAccessToken) {
        const decryptedAccessToken = AES.decrypt(
          encryptedAccessToken,
          secret_key
        ).toString(enc.Utf8);

        fetch(`${API_URL}add/record`, {
          method: "POST",
          body: JSON.stringify(formData),
          headers: {
            Authorization: `Bearer ${decryptedAccessToken}`,
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())

          .then((response) => {
            const account_record_new = response.account_type;

            navigate("/contract/shipment-by-contract/" + id, {
              state: { account_record: account_record_new },
            });
            if (response.message === "record addedd successfully") {
            }
          });
      }
    }
  };

  // opportunity information
  const [formDataopp, setFromdataopp] = useState({
    type_of_new_record: "",
  });

  const handleChangeOpp = (e) => {
    setFromdataopp({ ...formDataopp, [e.target.name]: e.target.value });
  };

  const handleSubmitOpp = (event) => {
    event.preventDefault();
    const encryptedAccessToken = localStorage.getItem("access_token");
    const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
    if (encryptedAccessToken) {
      const decryptedAccessToken = AES.decrypt(
        encryptedAccessToken,
        secret_key
      ).toString(enc.Utf8);

      fetch(`${API_URL}add/new/record`, {
        method: "POST",
        body: JSON.stringify(formDataopp),
        headers: {
          Authorization: `Bearer ${decryptedAccessToken}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())

        .then((response) => {
          const record_type_new = response.account_type;

          navigate("/contract/contract-by-opportunitys/" + id, {
            state: { record_type: record_type_new },
          });
          if (response.message === "record addedd successfully") {
          }
        });
    }
  };

  const [formDatatcf, setFromdatatcf] = useState({
    account_record: "",
  });

  const handleChangetcf = (e) => {
    setFromdata({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmittcf = (event) => {
    event.preventDefault();

    const encryptedAccessToken = localStorage.getItem("access_token");
    const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
    if (encryptedAccessToken) {
      const decryptedAccessToken = AES.decrypt(
        encryptedAccessToken,
        secret_key
      ).toString(enc.Utf8);

      fetch(`${API_URL}add/record`, {
        method: "POST",
        body: JSON.stringify(formData),
        headers: {
          Authorization: `Bearer ${decryptedAccessToken}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())

        .then((response) => {
          const account_record_new = response.account_type;

          navigate("/contract/contract-by-tcf/" + id, {
            state: { account_record: account_record_new },
          });
          if (response.message === "record addedd successfully") {
          }
        });
    }
  };

  const userRole = localStorage.getItem("role");
  // console.log(userRole);

  function numberToText(number) {
    const numbersMap = {
      0: 'zero', 1: 'one', 2: 'two', 3: 'three', 4: 'four',
      5: 'five', 6: 'six', 7: 'seven', 8: 'eight', 9: 'nine', 10: 'ten',
      11: 'eleven', 12: 'twelve', 13: 'thirteen', 14: 'fourteen',
      15: 'fifteen', 16: 'sixteen', 17: 'seventeen', 18: 'eighteen', 19: 'nineteen'
      // Add more numbers as needed
    };

    const tensMap = {
      2: 'twenty', 3: 'thirty', 4: 'forty', 5: 'fifty',
      6: 'sixty', 7: 'seventy', 8: 'eighty', 9: 'ninety'
    };

    if (!Number.isFinite(number)) {
      return 'infinity';
    }

    if (number < 0) {
      return 'negative ' + numberToText(-number);
    }

    if (number < 20) {
      return numbersMap[number];
    }

    if (number < 100) {
      const tens = Math.floor(number / 10);
      const remainder = number % 10;
      const tenText = tensMap[tens];
      const remainderText = remainder === 0 ? '' : `-${numbersMap[remainder]}`;
      return `${tenText}${remainderText}`;
    }

    if (number < 1000) {
      const hundreds = Math.floor(number / 100);
      const remainder = number % 100;
      const hundredText = `${numbersMap[hundreds]} hundred`;
      const remainderText = remainder === 0 ? '' : ` and ${numberToText(remainder)}`;
      return `${hundredText}${remainderText}`;
    }

    // Handle numbers greater than or equal to 1000
    if (number < 1000000) {
      const thousands = Math.floor(number / 1000);
      const remainder = number % 1000;
      const thousandsText = `${numberToText(thousands)} thousand`;
      const remainderText = remainder === 0 ? '' : ` ${numberToText(remainder)}`;
      return `${thousandsText}${remainderText}`;
    }

    // Add more handling for larger numbers if needed

    return 'number out of range';
  }


  const [data1, setData1] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editedData, setEditedData] = useState([]);
  const handleEditClick = () => {
    setIsEditMode(true);
    setEditedData(data);
    // console.log(isEditMode);
    $("#opportunity").DataTable();
     $("#history").DataTable();$("#products").DataTable();
    $("#tcf").DataTable();
     $("#orders").DataTable();$("#contract-ships").DataTable(); $("#quotes").DataTable();
    $("#historys").DataTable();
    $("#document").DataTable();
    $("#shipment").DataTable();
    $("#contract-product").DataTable();
    $("#contract-ship").DataTable();
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setDatas((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    const newValue = e.target.value;
    // Assuming each document is separated by a newline character
    const newList = newValue.split('\n');
    setDocument(newList);
  };

  setTimeout(() => {
    $("#opportunity").DataTable();
     $("#history").DataTable();$("#products").DataTable();
    $("#tcf").DataTable();
     $("#orders").DataTable();$("#contract-ships").DataTable(); $("#quotes").DataTable();
     $("#history").DataTable();$("#products").DataTable();
    $("#document").DataTable();
    $("#shipment").DataTable();
    $("#contract-product").DataTable();
    $("#contract-ship").DataTable();
  }, 100);

  const handleCancelEdit = () => {
    setIsEditMode(false);
    setEditedData(data);
  };

  const handleSaveEdit = (e) => {
    $("#opportunity").DataTable();
     $("#history").DataTable();$("#products").DataTable();
    $("#tcf").DataTable();
     $("#orders").DataTable();$("#contract-ships").DataTable(); $("#quotes").DataTable();

    $("#historys").DataTable();
    $("#document").DataTable();
    $("#shipment").DataTable();
    $("#contract-product").DataTable();
    $("#contract-ship").DataTable();

    if (datas.contract_record_type === "Buyer - Long Term") {
      setTimeout(() => {
        $("#opportunity").DataTable();
         $("#history").DataTable();$("#products").DataTable();
        $("#tcf").DataTable();
         $("#orders").DataTable();$("#contract-ships").DataTable();
        $("#contract-product").DataTable();
        $("#contract-ship").DataTable();
         $("#history").DataTable();$("#products").DataTable();
        $("#document").DataTable();
        $("#shipment").DataTable();
        $("#contract-product").DataTable();
        $("#contract-ship").DataTable();
      }, 100);

      e.preventDefault();
      setIsSubmitted(true)
      const responseData = {
        locked: lockeds,
        currency: datas.currency,
        exchange_rate: datas.exchange_rate,
        contract_record_type: datas.contract_record_type,
        Rwood_Contract_No: datas.Rwood_Contract_No,
        account_name: datas.account_name,
        contract_status: datas.contract_status,
        ppending_matters: pendingMatter,
        contract_start_date: startDate ? format(startDate, "dd/MM/yyyy") : null,
        remark: remarks, billing_address: datas.billing_address, shipping_address: datas.shipping_address,
        contract_end_date: endDate ? format(endDate, "dd/MM/yyyy") : null,
        company_signed_date: signed ? format(signed, "dd/MM/yyyy") : null,
        original_contract_recieved_date: originals ? format(originals, "dd/MM/yyyy") : null,
        master_conference_reference: datas.master_conference_reference,
        renewal_date: renewal ? format(renewal, "dd/MM/yyyy") : null,
        renewal_contract_number: parseInt(datas.renewal_contract_number),
        company: datas.company,
        trader_name: datas.trader_name,
        contract_owner: datas.contract_owner,
        price_book: datas.price_book,
        supplier: datas.supplier,
        status: datas.status,
        contract_term_in_months: parseInt(datas.contract_term_in_months),
        contract_effective_date: contractEffectiveDate ? format(contractEffectiveDate, "dd/MM/yyyy") : null,
        owner_expiration_notice: datas.owner_expiration_notice,
        origin: datas.origin,
        interlab_tolerance: interlab,
        customer_signed_date: customersigned ? format(customersigned, 'dd/MM/yyyy') : null,
        commodity: datas.commodity,
        contract_quantity_in_MT: datas.contract_quantity_in_MT,
        port_of_loading: datas.port_of_loading,
        destination: datas.destination,
        pricing_terms: pricingTerm,
        price_adjust_clause: priceAdjust,
        bonus_penalty_clause: bonus,
        payment_terms: paymentTerms,
        general_terms: generalTerms,
        shipping_city: datas.shipping_city,
        hs_code: datas.hs_code,
        applicable_law: datas.applicable_law,
        arbitration: datas.arbitration,
        lc_type: datas.lc_type,
        original_contract_sent_date: originalcontract ? format(originalcontract, "dd/MM/yyyy") : null,
        payment_details: datas.payment_details,
        master_lc_tolerance: datas.master_lc_tolerance,
        lc_validity_no_of_days: datas.lc_validity_no_of_days,
        loading_rate_per_day: datas.loading_rate_per_day,
        master_lc_presentation_days: datas.master_lc_presentation_days,
        lc_opening_days: datas.lc_opening_days,
        lc_opening_days_in_words: datas.lc_opening_days_in_words,
        work_days_for_time_to_copy_doc_to_buyer: datas.work_days_for_time_to_copy_doc_to_buyer,
        document_presentation_period: datas.document_presentation_period,
        // list_of_documents_required: document,
        special_terms: datas.special_terms,
        // "company_signed_date":format(signed, 'dd/MM/yyyy'),
        price_PMT: datas.price_PMT,
        price_PMT_in_words: datas.price_PMT_in_words,
        price_basis: datas.price_basis,
        price_type: datas.price_type,
        price_basis_GAR: datas.price_basis_GAR,
        price_adjustment_clause: priceAdjust,
        description: description_1,
        rejections: rejections,
        rwood_surveryor_agent: datas.rwood_surveryor_agent,
        surveyor_2: datas.surveyor_2,
        surveyor_3: datas.surveyor_3,
        kind_attention: datas.kind_attention,
        customer_signed_by: datas.customer_signed_by,
        customer_signed_title: datas.customer_signed_title,
        company_signed_by: datas.company_signed_by,
        lc_presentation_period: datas.lc_presentation_period,
        product_description: productdesc,
        billing_street: datas.billing_street,
        billing_zip_postal_code: datas.billing_zip_postal_code,
        billing_city: datas.billing_city,
        billing_state_or_province: datas.billing_state_or_province,
        billing_country: datas.billing_country,
        latest_date_of_shipment: datas.latest_date_of_shipment,
      };
      console.log(JSON.stringify(responseData));

      axios.put(`${API_URL}update/contract/${id}`, responseData, {
          headers: {
            Authorization: `Bearer ${decryptedAccessToken}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log(response)
          const updatedData = response.data;
          setDatas(updatedData); console.log(response.data.success)

          if (response.data.success ===false) {
            alert(response.data.msg)
            window.scrollTo({
              top: 0,
              behavior: 'smooth'
            }); // Assuming error message is provided by the server
            setTimeout(() => {
              setErrors(response.data.msg)
            }, 300);

            fetchData();
            setIsEditMode(false);

          } else if(response.data.success ===true) {
            setTimeout(() => {
              setIsSubmitted(false)
              $("#opportunity").DataTable();
               $("#history").DataTable();$("#products").DataTable();
              $("#tcf").DataTable();
               $("#orders").DataTable();$("#contract-ships").DataTable();
               $("#history").DataTable();$("#products").DataTable();
              $("#document").DataTable();
              $("#shipment").DataTable();
              $("#contract-product").DataTable();
              $("#contract-ship").DataTable();
            }, 500);

            fetchData();
            setIsEditMode(false);
          }
        })

        .catch((error) => console.error(error));
    }
    if (datas.contract_record_type === "Buyer - Spot") {
      setTimeout(() => {
        $("#opportunity").DataTable();
         $("#history").DataTable();$("#products").DataTable();
        $("#tcf").DataTable();
         $("#orders").DataTable();$("#contract-ships").DataTable();
         $("#history").DataTable();$("#products").DataTable();
        $("#document").DataTable();
        $("#shipment").DataTable();
        $("#contract-product").DataTable();
        $("#contract-ship").DataTable();
      }, 100);

      e.preventDefault();
      setIsSubmitted(true)
      const responseData = {
        locked: lockeds,
        currency: datas.currency,
        exchange_rate: datas.exchange_rate,
        contract_record_type: datas.contract_record_type,
        Rwood_Contract_No: datas.Rwood_Contract_No,
        account_name: datas.account_name,
        original_contract_recieved_date: originals ? format(originals, "dd/MM/yyyy") : null,
        contract_status: datas.contract_status,
        ppending_matters: pendingMatter,
        remark: remarks, billing_address: datas.billing_address, shipping_address: datas.shipping_address,
        master_conference_reference: datas.master_conference_reference,
        renewal_date: renewal ? format(renewal, "dd/MM/yyyy") : null,
        renewal_contract_number: parseInt(datas.renewal_contract_number),
        company: datas.company,
        trader_name: datas.trader_name,
        contract_owner: datas.contract_owner,
        price_book: datas.price_book,
        supplier: datas.supplier,
        status: datas.status,
        contract_term_in_months: parseInt(datas.contract_term_in_months),
        owner_expiration_notice: datas.owner_expiration_notice,
        origin: datas.origin,
        interlab_tolerance: interlab,
        commodity: datas.commodity,
        contract_quantity_in_MT: datas.contract_quantity_in_MT,
        port_of_loading: datas.port_of_loading,
        destination: datas.destination,
        pricing_terms: pricingTerm,
        price_adjust_clause: priceAdjust,
        bonus_penalty_clause: bonus,
        payment_terms: paymentTerms,
        general_terms: generalTerms,
        shipping_city: datas.shipping_city,
        hs_code: datas.hs_code,
        applicable_law: datas.applicable_law,
        arbitration: datas.arbitration,
        contract_effective_date: contractEffectiveDate ? format(contractEffectiveDate, "dd/MM/yyyy") : null,
        lc_type: datas.lc_type,
        contract_start_date: startDate ? format(startDate, "dd/MM/yyyy") : null,
        company_signed_date: signed ? format(signed, "dd/MM/yyyy") : null,
        customer_signed_date: customersigned ? format(customersigned, 'dd/MM/yyyy') : null,
        payment_details: datas.payment_details,
        master_lc_tolerance: datas.master_lc_tolerance,
        lc_validity_no_of_days: datas.lc_validity_no_of_days,
        loading_rate_per_day: datas.loading_rate_per_day,
        master_lc_presentation_days: datas.master_lc_presentation_days,
        lc_opening_days: datas.lc_opening_days,
        lc_opening_days_in_words: datas.lc_opening_days_in_words,
        work_days_for_time_to_copy_doc_to_buyer:
          datas.work_days_for_time_to_copy_doc_to_buyer,
        document_presentation_period: datas.document_presentation_period,
        // list_of_documents_required: document,
        special_terms: datas.special_terms,
        price_PMT: datas.price_PMT,
        price_PMT_in_words: datas.price_PMT_in_words,
        price_basis: datas.price_basis,
        price_type: datas.price_type,
        price_basis_GAR: datas.price_basis_GAR,
        price_adjustment_clause: priceAdjust,
        description: description_1,
        rejections: rejections,
        rwood_surveryor_agent: datas.rwood_surveryor_agent,
        surveyor_2: datas.surveyor_2,
        surveyor_3: datas.surveyor_3,
        kind_attention: datas.kind_attention,
        customer_signed_by: datas.customer_signed_by,
        customer_signed_title: datas.customer_signed_title,
        original_contract_sent_date: originalcontract ? format(originalcontract, "dd/MM/yyyy") : null,
        company_signed_by: datas.company_signed_by,
        lc_presentation_period: datas.lc_presentation_period,
        product_description: productdesc,
        billing_street: datas.billing_street,
        billing_zip_postal_code: datas.billing_zip_postal_code,
        billing_city: datas.billing_city,
        billing_state_or_province: datas.billing_state_or_province,
        billing_country: datas.billing_country,
      };

      console.log(JSON.stringify(responseData));

      axios
        .put(`${API_URL}update/contract/${id}`, JSON.stringify(responseData), {
          headers: {
            Authorization: `Bearer ${decryptedAccessToken}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          const updatedData = response.data;
          setDatas(updatedData); console.log(response.data.success)

          fetchData();
          setIsEditMode(false);
          if (response.data.success ===false) {
            alert(response.data.msg)
            window.scrollTo({
              top: 0,
              behavior: 'smooth'
            }); // Assuming error message is provided by the server
            setTimeout(() => {
              setErrors(response.data.msg)
            }, 300);

            fetchData();
            setIsEditMode(false);

          } else if(response.data.success ===true) {
            setTimeout(() => {
              setIsSubmitted(false)
              $("#opportunity").DataTable();
               $("#history").DataTable();$("#products").DataTable();
              $("#tcf").DataTable();
               $("#orders").DataTable();$("#contract-ships").DataTable();
               $("#history").DataTable();$("#products").DataTable();
              $("#document").DataTable();
              $("#shipment").DataTable();
              $("#contract-product").DataTable();
              $("#contract-ship").DataTable();
            }, 500);

            fetchData();
            setIsEditMode(false);
          }
        })
        .catch((error) => console.error(error));
    }
    if (datas.contract_record_type === "Supplier") {
      setTimeout(() => {
        $("#opportunity").DataTable();
         $("#history").DataTable();$("#products").DataTable();
        $("#tcf").DataTable();
         $("#orders").DataTable();$("#contract-ships").DataTable();
         $("#history").DataTable();$("#products").DataTable();
        $("#document").DataTable();
        $("#shipment").DataTable();
        $("#contract-product").DataTable();
        $("#contract-ship").DataTable();
      }, 100);

      e.preventDefault();
      setIsSubmitted(true)
      const responseData = {
        locked: lockeds,
        currency: datas.currency,
        exchange_rate: datas.exchange_rate,
        contract_record_type: datas.contract_record_type,
        Rwood_Contract_No: datas.Rwood_Contract_No,
        account_name: datas.account_name,
        contract_status: datas.contract_status,
        ppending_matters: pendingMatter,
        remark: remarks, billing_address: datas.billing_address, shipping_address: datas.shipping_address,
        master_conference_reference: datas.master_conference_reference,
        renewal_date: renewal ? format(renewal, "dd/MM/yyyy") : null,
        renewal_contract_number: parseInt(datas.renewal_contract_number),
        company: datas.company,
        trader_name: datas.trader_name,
        contract_owner: datas.contract_owner,
        price_book: datas.price_book,
        supplier: datas.supplier,
        status: datas.status,
        contract_term_in_months: parseInt(datas.contract_term_in_months),
        owner_expiration_notice: datas.owner_expiration_notice,
        origin: datas.origin,
        interlab_tolerance: interlab,
        commodity: datas.commodity,
        contract_quantity_in_MT: datas.contract_quantity_in_MT,
        port_of_loading: datas.port_of_loading,
        destination: datas.destination,
        pricing_terms: pricingTerm,
        price_adjust_clause: priceAdjust,
        bonus_penalty_clause: bonus,
        payment_terms: paymentTerms,
        general_terms: generalTerms,
        shipping_city: datas.shipping_city,
        hs_code: datas.hs_code,
        contract_start_date: startDate ? format(startDate, "dd/MM/yyyy") : null,
        company_signed_date: signed ? format(signed, "dd/MM/yyyy") : null,
        applicable_law: datas.applicable_law,
        arbitration: datas.arbitration,
        lc_type: datas.lc_type,
        payment_details: datas.payment_details,
        master_lc_tolerance: datas.master_lc_tolerance,
        lc_validity_no_of_days: datas.lc_validity_no_of_days,
        loading_rate_per_day: datas.loading_rate_per_day,
        master_lc_presentation_days: datas.master_lc_presentation_days,
        lc_opening_days: datas.lc_opening_days,
        lc_opening_days_in_words: datas.lc_opening_days_in_words,
        work_days_for_time_to_copy_doc_to_buyer:
          datas.work_days_for_time_to_copy_doc_to_buyer,
        document_presentation_period: datas.document_presentation_period,
        // list_of_documents_required: document,
        special_terms: datas.special_terms,
        price_PMT: datas.price_PMT,
        price_PMT_in_words: datas.price_PMT_in_words,
        price_basis: datas.price_basis,
        price_type: datas.price_type,
        price_basis_GAR: datas.price_basis_GAR,
        price_adjustment_clause: priceAdjust,
        description: description_1,
        rejections: rejections,
        rwood_surveryor_agent: datas.rwood_surveryor_agent,
        surveyor_2: datas.surveyor_2,
        surveyor_3: datas.surveyor_3,
        kind_attention: datas.kind_attention,
        customer_signed_by: datas.customer_signed_by,
        customer_signed_title: datas.customer_signed_title,
        company_signed_by: datas.company_signed_by,
        lc_presentation_period: datas.lc_presentation_period,
        product_description: productdesc,
        original_contract_recieved_date: originals ? format(originals, "dd/MM/yyyy") : null,
        original_contract_sent_date: originalcontract ? format(originalcontract, "dd/MM/yyyy") : null,
        billing_street: datas.billing_street,
        billing_zip_postal_code: datas.billing_zip_postal_code,
        billing_city: datas.billing_city,
        billing_state_or_province: datas.billing_state_or_province,
        billing_country: datas.billing_country,
        customer_signed_date: customersigned ? format(customersigned, "dd/MM/yyyy") : null,
      };
      console.log(JSON.stringify(responseData));
      axios
        .put(`${API_URL}update/contract/${id}`, JSON.stringify(responseData), {
          headers: {
            Authorization: `Bearer ${decryptedAccessToken}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          const updatedData = response.data;
          setDatas(updatedData); console.log(response.data.success)

          if (response.data.success ===false) {
            alert(response.data.msg)
            window.scrollTo({
              top: 0,
              behavior: 'smooth'
            }); // Assuming error message is provided by the server
            setTimeout(() => {
              setErrors(response.data.msg)
            }, 300);

            fetchData();
            setIsEditMode(false);

          } else if(response.data.success ===true) {
            setTimeout(() => {
              setIsSubmitted(false)
              $("#opportunity").DataTable();
               $("#history").DataTable();$("#products").DataTable();
              $("#tcf").DataTable();
               $("#orders").DataTable();$("#contract-ships").DataTable();
               $("#history").DataTable();$("#products").DataTable();
              $("#document").DataTable();
              $("#shipment").DataTable();
              $("#contract-product").DataTable();
              $("#contract-ship").DataTable();
            }, 500);

            fetchData();
            setIsEditMode(false);
          }
        })
        .catch((error) => console.error(error));
    }
    if (datas.contract_record_type === "Supplier - Long Term") {
      setTimeout(() => {
        $("#opportunity").DataTable();
         $("#history").DataTable();$("#products").DataTable();
        $("#tcf").DataTable();
         $("#orders").DataTable();$("#contract-ships").DataTable(); $("#quotes").DataTable();
         $("#history").DataTable();$("#products").DataTable();
        $("#document").DataTable();
        $("#shipment").DataTable();
        $("#contract-product").DataTable();
        $("#contract-ship").DataTable();
      }, 100);

      e.preventDefault();
      setIsSubmitted(true)
      const responseData = {
        locked: lockeds,
        currency: datas.currency,
        exchange_rate: datas.exchange_rate,
        contract_record_type: datas.contract_record_type,
        Rwood_Contract_No: datas.Rwood_Contract_No,
        account_name: datas.account_name,
        contract_status: datas.contract_status,
        ppending_matters: pendingMatter,
        remark: remarks, billing_address: datas.billing_address, shipping_address: datas.shipping_address,
        master_conference_reference: datas.master_conference_reference,
        renewal_date: renewal ? format(renewal, "dd/MM/yyyy") : null,
        renewal_contract_number: parseInt(datas.renewal_contract_number),
        company: datas.company,
        trader_name: datas.trader_name,
        contract_owner: datas.contract_owner,
        price_book: datas.price_book,
        supplier: datas.supplier,
        status: datas.status,
        contract_term_in_months: parseInt(datas.contract_term_in_months),
        owner_expiration_notice: datas.owner_expiration_notice,
        origin: datas.origin,
        interlab_tolerance: interlab,
        commodity: datas.commodity,
        contract_quantity_in_MT: datas.contract_quantity_in_MT,
        port_of_loading: datas.port_of_loading,
        destination: datas.destination,
        pricing_terms: pricingTerm,
        price_adjust_clause: priceAdjust,
        bonus_penalty_clause: bonus,
        payment_terms: paymentTerms,
        general_terms: generalTerms,
        shipping_city: datas.shipping_city,
        shipping_country: datas.shipping_country,
        shipping_state_or_province: datas.shipping_state_or_province,
        shipping_zip_postal_code: datas.shipping_zip_postal_code,
        shipping_street: datas.shipping_street,
        hs_code: datas.hs_code,
        applicable_law: datas.applicable_law,
        arbitration: datas.arbitration,
        lc_type: datas.lc_type,
        contract_start_date: startDate ? format(startDate, "dd/MM/yyyy") : null,
        company_signed_date: signed ? format(signed, "dd/MM/yyyy") : null,
        payment_details: datas.payment_details,
        master_lc_tolerance: datas.master_lc_tolerance,
        lc_validity_no_of_days: datas.lc_validity_no_of_days,
        loading_rate_per_day: datas.loading_rate_per_day,
        master_lc_presentation_days: datas.master_lc_presentation_days,
        lc_opening_days: datas.lc_opening_days,
        lc_opening_days_in_words: datas.lc_opening_days_in_words,
        work_days_for_time_to_copy_doc_to_buyer:
          datas.work_days_for_time_to_copy_doc_to_buyer,
        document_presentation_period: datas.document_presentation_period,
        // list_of_documents_required: document,
        special_terms: datas.special_terms,
        price_PMT: datas.price_PMT,
        price_PMT_in_words: datas.price_PMT_in_words,
        price_basis: datas.price_basis,
        price_type: datas.price_type,
        price_basis_GAR: datas.price_basis_GAR,
        price_adjustment_clause: priceAdjust,
        description: description_1,
        rejections: rejections,
        rwood_surveryor_agent: datas.rwood_surveryor_agent,
        surveyor_2: datas.surveyor_2,
        surveyor_3: datas.surveyor_3,
        kind_attention: datas.kind_attention,
        customer_signed_by: datas.customer_signed_by,
        customer_signed_title: datas.customer_signed_title,
        company_signed_by: datas.company_signed_by,
        lc_presentation_period: datas.lc_presentation_period,
        product_description: productdesc,
        original_contract_recieved_date: originals ? format(originals, "dd/MM/yyyy") : null,
        billing_street: datas.billing_street,
        contract_effective_date: contractEffectiveDate ? format(contractEffectiveDate, "dd/MM/yyyy") : null,

        billing_zip_postal_code: datas.billing_zip_postal_code,
        billing_city: datas.billing_city,
        original_contract_sent_date: originalcontract ? format(originalcontract, "dd/MM/yyyy") : null,
        billing_state_or_province: datas.billing_state_or_province,
        billing_country: datas.billing_country,
        customer_signed_date: customersigned ? format(customersigned, "dd/MM/yyyy") : null,
      };

      console.log(JSON.stringify(responseData));

      axios
        .put(`${API_URL}update/contract/${id}`, JSON.stringify(responseData), {
          headers: {
            Authorization: `Bearer ${decryptedAccessToken}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          const updatedData = response.data;
          setDatas(updatedData); console.log(response.data.success)

          if (response.data.success ===false) {
            alert(response.data.msg)
            window.scrollTo({
              top: 0,
              behavior: 'smooth'
            }); // Assuming error message is provided by the server
            setTimeout(() => {
              setErrors(response.data.msg)
            }, 300);

            fetchData();
            setIsEditMode(false);

          } else if(response.data.success ===true) {
            setTimeout(() => {
              setIsSubmitted(false)
              $("#opportunity").DataTable();
               $("#history").DataTable();$("#products").DataTable();
              $("#tcf").DataTable();
               $("#orders").DataTable();$("#contract-ships").DataTable();
               $("#history").DataTable();$("#products").DataTable();
              $("#document").DataTable();
              $("#shipment").DataTable();
              $("#contract-product").DataTable();
              $("#contract-ship").DataTable();
            }, 500);

            fetchData();
            setIsEditMode(false);
          }
        })
        .catch((error) => console.error(error));
    }
    if (datas.contract_record_type === "Supplier - Spot") {
      setTimeout(() => {
        $("#opportunity").DataTable();
         $("#history").DataTable();$("#products").DataTable();
        $("#tcf").DataTable();
         $("#orders").DataTable();$("#contract-ships").DataTable(); $("#quotes").DataTable();
         $("#history").DataTable();$("#products").DataTable();
        $("#document").DataTable();
        $("#shipment").DataTable();
        $("#contract-product").DataTable();
        $("#contract-ship").DataTable();
      }, 100);

      e.preventDefault();
      setIsSubmitted(true)
      const responseData = {
        locked: lockeds,
        currency: datas.currency,
        exchange_rate: datas.exchange_rate,
        contract_record_type: datas.contract_record_type,
        Rwood_Contract_No: datas.Rwood_Contract_No,
        account_name: datas.account_name,
        contract_status: datas.contract_status,
        ppending_matters: pendingMatter,
        remark: remarks, billing_address: datas.billing_address, shipping_address: datas.shipping_address,
        master_conference_reference: datas.master_conference_reference,
        renewal_date: renewal ? format(renewal, "dd/MM/yyyy") : null,
        renewal_contract_number: parseInt(datas.renewal_contract_number),
        company: datas.company,
        trader_name: datas.trader_name,
        contract_owner: datas.contract_owner,
        price_book: datas.price_book,
        supplier: datas.supplier,
        status: datas.status,
        contract_term_in_months: parseInt(datas.contract_term_in_months),
        owner_expiration_notice: datas.owner_expiration_notice,
        origin: datas.origin,
        interlab_tolerance: interlab,
        commodity: datas.commodity,
        contract_quantity_in_MT: datas.contract_quantity_in_MT,
        port_of_loading: datas.port_of_loading,
        destination: datas.destination,
        pricing_terms: pricingTerm,
        price_adjust_clause: priceAdjust,
        bonus_penalty_clause: bonus,
        payment_terms: paymentTerms,
        general_terms: generalTerms,
        shipping_city: datas.shipping_city,
        shipping_country: datas.shipping_country,
        shipping_state_or_province: datas.shipping_state_or_province,
        shipping_zip_postal_code: datas.shipping_zip_postal_code,
        shipping_street: datas.shipping_street,
        hs_code: datas.hs_code,
        applicable_law: datas.applicable_law,
        arbitration: datas.arbitration,
        lc_type: datas.lc_type,
        contract_start_date: startDate ? format(startDate, "dd/MM/yyyy") : null,
        company_signed_date: signed ? format(signed, "dd/MM/yyyy") : null,
        payment_details: datas.payment_details,
        master_lc_tolerance: datas.master_lc_tolerance,
        lc_validity_no_of_days: datas.lc_validity_no_of_days,
        loading_rate_per_day: datas.loading_rate_per_day,
        master_lc_presentation_days: datas.master_lc_presentation_days,
        lc_opening_days: datas.lc_opening_days,
        lc_opening_days_in_words: datas.lc_opening_days_in_words,
        contract_end_date: endDate ? format(endDate, "dd/MM/yyyy") : null,
        work_days_for_time_to_copy_doc_to_buyer:
          datas.work_days_for_time_to_copy_doc_to_buyer,
        document_presentation_period: datas.document_presentation_period,
        // list_of_documents_required: document,
        special_terms: datas.special_terms,
        price_PMT: datas.price_PMT,
        price_PMT_in_words: datas.price_PMT_in_words,
        price_basis: datas.price_basis,
        price_type: datas.price_type,
        price_basis_GAR: datas.price_basis_GAR,
        price_adjustment_clause: priceAdjust,
        description: description_1,
        rejections: rejections,
        rwood_surveryor_agent: datas.rwood_surveryor_agent,
        surveyor_2: datas.surveyor_2,
        surveyor_3: datas.surveyor_3,
        kind_attention: datas.kind_attention,
        customer_signed_by: datas.customer_signed_by,
        customer_signed_title: datas.customer_signed_title,
        company_signed_by: datas.company_signed_by,
        lc_presentation_period: datas.lc_presentation_period,
        product_description: productdesc,
        original_contract_recieved_date: originals ? format(originals, "dd/MM/yyyy") : null,
        billing_street: datas.billing_street,
        contract_effective_date: contractEffectiveDate ? format(contractEffectiveDate, "dd/MM/yyyy") : null,

        billing_zip_postal_code: datas.billing_zip_postal_code,
        billing_city: datas.billing_city,
        original_contract_sent_date: originalcontract ? format(originalcontract, "dd/MM/yyyy") : null,
        billing_state_or_province: datas.billing_state_or_province,
        billing_country: datas.billing_country,
        customer_signed_date: customersigned ? format(customersigned, "dd/MM/yyyy") : null,
      };
      console.log(JSON.stringify(responseData));
      axios
        .put(`${API_URL}update/contract/${id}`, JSON.stringify(responseData), {
          headers: {
            Authorization: `Bearer ${decryptedAccessToken}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          const updatedData = response.data;
          setDatas(updatedData); console.log(response.data.success)
          if (response.data.success ===false) {
            alert(response.data.msg)
            window.scrollTo({
              top: 0,
              behavior: 'smooth'
            }); // Assuming error message is provided by the server
            setTimeout(() => {
              setErrors(response.data.msg)
            }, 300);

            fetchData();
            setIsEditMode(false);

          } else if(response.data.success ===true) {
            setTimeout(() => {
              setIsSubmitted(false)
              $("#opportunity").DataTable();
               $("#history").DataTable();$("#products").DataTable();
              $("#tcf").DataTable();
               $("#orders").DataTable();$("#contract-ships").DataTable();
               $("#history").DataTable();$("#products").DataTable();
              $("#document").DataTable();
              $("#shipment").DataTable();
              $("#contract-product").DataTable();
              $("#contract-ship").DataTable();
            }, 500);

            fetchData();
            setIsEditMode(false);
          }
        })
        .catch((error) => console.error(error));
    }
  };



  const [users, setUsers] = useState([]);

  useEffect(() => {
    fetchDatausers();
  }, [decryptedAccessToken]);

  const fetchDatausers = async () => {
    try {
      const response = await axios.get(
        `${API_URL}get/all/users`,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data;
      console.log(data);
      setUsers(data);
    } catch (error) {
      console.error(error);
    }
  };


  return (
    <div id="view-page">
      <Header />
      <div className="contactAccount  contract-page full-container">
        <div className="row">
          <div className="col-md-2 recent-item">
            <RecentItem />
          </div>
          <div className="col-md-10 right-section">
            <div className="page-header" id="add-account">
              <div>
                <h2 className="main-content-title tx-24 mg-b-5">
                  Contract Details
                </h2>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item active" aria-current="page">
                    Dashboard / Contract Details
                  </li>
                </ol>
              </div>
            </div>

            {lengths > 0 ? (
              <>
                {datas.contract_record_type === "Buyer - Long Term" ? (
                  <>
                    {isEditMode ? (
                      <div className="tasks">
                        <p className="edit-btn">
                          <input
                            type="submit"
                            className="account-save"
                            onClick={handleSaveEdit}
                            value="Save"
                            //disabled={isSubmitted}
                          />
                          <button
                            className="btn btn-primary"
                            onClick={handleCancelEdit}
                          >
                            Cancel
                          </button>
                        </p>
                        <p className="errors">{errors}</p>
                        <Row className="mb-3">
                          <h4 className="heading">Contract Information</h4>
                          <Form.Group as={Col} md="6">
                            <label>Locked</label>
                            <input
                              type='checkbox'
                              name='locked'
                              checked={lockeds} // Use the state variable to determine if the checkbox is checked
                              onChange={handleCheckboxChange} // Handle the change event
                              className='mx-3'
                              tabIndex={1}
                            />
                          </Form.Group>
                          <Form.Group as={Col} md="6">
                            <FloatingLabel
                              controlId="floatingSelect"
                              className="dropDown"
                              label="Company"
                            >
                              <Form.Select
                                aria-label="Floating label select example"
                                name="company"
                                placeholder="Company"
                                className="error-validation"
                                tabIndex={15}
                                value={datas.company || ""}
                                onChange={handleChangeInput}
                              >
                                <option value="">Select </option>
                                {companyData.map((x, i) => {
                                  return (
                                    <option key={i} value={x.company_name}>
                                      {x.company_name}
                                    </option>
                                  );
                                })}
                              </Form.Select>
                            </FloatingLabel>
                          </Form.Group>

                          <Form.Group as={Col} md="6">
                            <FloatingLabel
                              controlId="floatingSelect"
                              className="dropDown"
                              label="Currency"
                            >
                              <Form.Select
                                aria-label="Floating label select example"
                                name="currency"
                                placeholder="Currency"
                                tabIndex={2}
                                className="error-validation"
                                value={datas.currency || ""}
                                onChange={handleChangeInput}
                              >
                                <option value="">Select </option>
                                <option value="USD">USD </option>
                                <option value="IDR">IDR </option>
                              </Form.Select>
                            </FloatingLabel>
                          </Form.Group>

                          <Form.Group as={Col} md="6">
                            <FloatingLabel
                              controlId="floatingSelect"
                              className="dropDown"
                              label="Trader Name"
                            >
                              <Form.Select
                                aria-label="Floating label select example"
                                name="trader_name"
                                placeholder="Trader Name"
                                tabIndex={16}
                                value={datas.trader_name || ""}
                                onChange={handleChangeInput}
                              >
                                <option value="">Select </option>
                                {traderData.map((x, i) => {
                                  return (
                                    <option key={i} value={x.rwood_username}>
                                      {x.rwood_username}
                                    </option>
                                  );
                                })}
                              </Form.Select>
                            </FloatingLabel>
                          </Form.Group>
                          <Form.Group as={Col} md="6">
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Exchange rate"
                              className="mb-3"
                            >
                              <Form.Control
                                type="text"
                                placeholder="Exchange rate "
                                name="exchange_rate"
                                tabIndex={3}
                                defaultValue={datas.exchange_rate || ""}
                                onChange={handleChangeInput}
                              />
                            </FloatingLabel>
                          </Form.Group>
                          <Form.Group as={Col} md="6">
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Contract Owner"
                              className="mb-3"
                            >
                              <Form.Control
                                type="text"
                                placeholder="Contract Owner"
                                tabIndex={17}
                                name="contract_owner"
                                defaultValue={datas.contract_owner || ""}
                                onChange={handleChangeInput}
                              />
                            </FloatingLabel>
                          </Form.Group>
                          <Form.Group as={Col} md="6">
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Contract record type"
                              className="mb-3"
                            >
                              <Form.Control
                                type="text"
                                placeholder="Contract record type"
                                name="contract_record_type"
                                className="record-type"
                                tabIndex={4}
                                defaultValue={datas.contract_record_type}
                                onChange={handleChangeInput}
                              />
                            </FloatingLabel>
                          </Form.Group>
                          <Form.Group as={Col} md="6">
                            {" "}
                          </Form.Group>
                          {/* <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Price book">
    <Form.Select aria-label="Floating label select example"
    name="price_book"
    placeholder="Price book"
     value={datas.price_book || ""}
onChange={handleChangeInput}
    >
      <option value='' >Select</option>
       {
          priceBook.map((x)=>{
              return(
                  <option value={x.price_book_name}>{x.price_book_name}</option>
              )
          })
       }
    </Form.Select>

  </FloatingLabel>
              </Form.Group>
               */}
                          <Form.Group as={Col} md="6">
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Rwood contract no"
                              className="mb-3"
                            >
                              <Form.Control
                                type="text"
                                placeholder="Rwood contract no"
                                name="Rwood_Contract_No"
                                tabIndex={5}
                                defaultValue={datas.Rwood_Contract_No || ""}
                                onChange={handleChangeInput}

                              />
                            </FloatingLabel>
                          </Form.Group>
                          {/* <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Status"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="status"
                    placeholder="Status"
                    value={datas.status || ""}
onChange={handleChangeInput}
                  >
                    <option value="">Select </option>
                    <option value="Draft">Draft </option>
                    <option value="Approved">Approved </option>
                  </Form.Select>

                </FloatingLabel>
              </Form.Group> */}
                          <Form.Group as={Col} md="6">
                            {" "}
                          </Form.Group>

                          <Form.Group as={Col} md="6">
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Contract Number"
                              className="mb-3"
                            >
                              <Form.Control
                                type="text"
                                placeholder="Contract Number"
                                tabIndex={6}
                                name="contract_number"
                                defaultValue={datas.contract_number || ""}
                                onChange={handleChangeInput} disabled
                              />
                            </FloatingLabel>
                          </Form.Group>

                          <Form.Group as={Col} md="6">
                            <FloatingLabel
                              controlId="floatingSelect"
                              className="dropDown"
                              label="Supplier"
                            >
                              <Form.Select
                                aria-label="Floating label select example"
                                name="supplier"
                                placeholder="Supplier"
                                className="error-validation"
                                tabIndex={18}
                                value={datas.supplier || ""}
                                onChange={handleChangeInput}
                              >
                                <option value="">Select </option>
                                {supplierData.map((x) => {
                                  return (
                                    <option value={x.account_name}>
                                      {x.account_name}
                                    </option>
                                  );
                                })}
                              </Form.Select>
                            </FloatingLabel>
                          </Form.Group>
                          <Form.Group as={Col} md="6">
                            <FloatingLabel
                              controlId="floatingSelect"
                              className="dropDown"
                              label="Account Name"
                            >
                              <Form.Select
                                aria-label="Floating label select example"
                                name="account_name"
                                placeholder="Account Name"
                                tabIndex={7}
                                className="error-validation"
                                value={datas.account_name || ""}
                                onChange={handleChangeInput}
                              >
                                <option value="">Select </option>
                                {accountName.map((x) => {
                                  return (
                                    <option value={x.account_name}>
                                      {x.account_name}
                                    </option>
                                  );
                                })}
                              </Form.Select>
                            </FloatingLabel>
                          </Form.Group>
                          <Form.Group as={Col} md="6">
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Contract term in months"
                              className="mb-3"
                            >
                              <Form.Control
                                type="text"
                                placeholder="Contract term in months"
                                tabIndex={19}
                                name="contract_term_in_months"
                                defaultValue={
                                  datas.contract_term_in_months || ""
                                }
                                onChange={handleChangeInput}
                              />
                            </FloatingLabel>
                          </Form.Group>
                          <Form.Group as={Col} md="6">
                            <FloatingLabel
                              controlId="floatingSelect"
                              className="dropDown"
                              label="Contract Status"
                            >
                              <Form.Select
                                aria-label="Floating label select example"
                                name="contract_status"
                                placeholder="Contract Status"
                                tabIndex={8}
                                value={datas.contract_status || ""}
                                onChange={handleChangeInput}
                              >
                                <option value="In Approval Process">
                                  In Approval Process
                                </option>
                                <option value="Under Review">Under Review</option>
                                <option value="Activated">Activated</option>
                                <option value="Draft" selected>Draft</option>
                                <option value="Under Review">
                                  Under Review
                                </option>
                                <option value="Draft Sent for Signing">
                                  Draft Sent for Signing
                                </option>
                                <option value="Draft Received for Signing">
                                  Draft Received for Signing
                                </option>
                                <option value="Signed Contract yet to Receive">
                                  Signed Contract yet to Receive
                                </option>
                                <option value="Signed Contract Sent for Sign">
                                  Signed Contract Sent for Sign
                                </option>
                                <option value="Signed Original Contract Need to Courier">
                                  Signed Original Contract Need to Courier
                                </option>
                                <option value="Signed Original Contract yet to Receive">
                                  Signed Original Contract yet to Receive
                                </option>
                                <option value="Original Contract Signed by Both Parties">
                                  Original Contract Signed by Both Parties
                                </option>
                                <option value="Original Contract Signed and Received by Both Parties">
                                  Original Contract Signed and Received by Both
                                  Parties
                                </option>
                                <option value="Closed">Closed</option>
                                <option value="Partially completed">
                                  Partially completed
                                </option>
                                <option value="Completed">Completed</option>
                                <option value="Cancelled">Cancelled</option>
                                <option value="Partially cancelled">
                                  Partially cancelled
                                </option>
                                <option value="Shipments - Arutmin">
                                  Shipments - Arutmin
                                </option>
                                <option value="Partially Signed Contract">
                                  Partially Signed Contract
                                </option>
                              </Form.Select>
                            </FloatingLabel>
                          </Form.Group>

                          <Form.Group as={Col} md="6">
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Contract Start Date"
                              className="mb-3 date-picker"
                            >
                              <DatePicker
                                selected={startDate}
                                onChange={handleStartDateChange}
                                selectsStart
                                dateFormat="dd/MM/yyyy"
                                tabIndex={20}
                                placeholderText="Select"
                              />
                            </FloatingLabel>
                          </Form.Group>

                          <Form.Group as={Col} md="6" className='textEditor'>
                            <label>Pending Matter</label>
                            <Editor value={pendingMatter} onChange={onChangePending} tabIndex={10}/>
                          </Form.Group>
                          <Form.Group as={Col} md="6">
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Contract End Date"
                              className="mb-3 date-picker"
                            >
                              <DatePicker
                                selected={endDate}
                                onChange={handleEndDateChanges}
                                selectsStart
                                dateFormat="dd/MM/yyyy"
                                placeholderText="Select"
                                disabled={true}
                                tabIndex={21}
                              />
                            </FloatingLabel>
                          </Form.Group>

                          <Form.Group as={Col} md="6" className='textEditor tooltips'>
                            <label>Remark</label>
                            <Editor value={remarks} onChange={onChangeRemarks} tabIndex={11}/>
                            <Tooltip text={<span>Any Specific Remark related to Contract</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                          </Form.Group>
                          <Form.Group as={Col} md="6" className="tooltips">
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Contract Effective Date"
                              className="mb-3 date-picker"
                            >
                              <DatePicker
                                selected={contractEffectiveDate}
                                onChange={handleEndDateChange}
                                dateFormat="dd/MM/yyyy"
                                tabIndex={22}
                                placeholderText="Select Date"
                              />
                            </FloatingLabel>
                            <Tooltip text={<span>Contract Effective Date</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                          </Form.Group>
                          <Form.Group as={Col} md="6" className="tooltips">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Master Contract Reference"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="Master Contract Reference"
                                    name="master_conference_reference"
                                    tabIndex={12}
                                    defaultValue={
                                      datas.master_conference_reference || ""
                                    }
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                                <Tooltip text={<span>Give master contract reference, if any</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                              </Form.Group>
                          <Form.Group as={Col} md="6"></Form.Group>
                          <Form.Group as={Col} md="6">
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Renewal date"
                              className="mb-3 date-picker"
                            >
                              <DatePicker
                                selected={renewal}
                                tabIndex={13}
                                onChange={handleRenewal}
                                dateFormat="dd/MM/yyyy"
                                placeholderText="Select Date"
                              />
                            </FloatingLabel>
                          </Form.Group>

                          <Form.Group as={Col} md="6"></Form.Group>
                          <Form.Group as={Col} md="6">
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Renewal contract number"
                              className="mb-3"
                            >
                              <Form.Control
                                type="number"
                                tabIndex={14}
                                placeholder="Renewal contract number "
                                name="renewal_contract_number"
                                defaultValue={
                                  datas.renewal_contract_number || ""
                                }
                                onChange={handleChangeInput}
                              />
                            </FloatingLabel>
                          </Form.Group>

                        </Row>
                        <Row className="mb-3">
                          <h4 className="heading">
                            Commodity and Contracted Quantity
                          </h4>
                          <Form.Group as={Col} md="6" className='textEditor'>
                            <label>interlab tolerance</label>
                            <Editor value={interlab} onChange={onChangeInterlab} tabIndex={23}/>
                          </Form.Group>
                          <Form.Group as={Col} md="6">
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Contract quantity in MT"
                              className="mb-3"
                            >
                              <Form.Control
                                type="number"
                                placeholder="Contract quantity in MT"
                                name="contract_quantity_in_MT"
                                tabIndex={24}
                                className="error-validation"
                                defaultValue={
                                  datas.contract_quantity_in_MT || ""
                                }
                                onChange={handleChangeInput}
                              />
                            </FloatingLabel>
                          </Form.Group>
                        </Row>
                        <Row className="mb-3">
                          <h4 className="heading">Market Information</h4>
                          <Form.Group as={Col} md="6">
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Origin"
                              className="mb-3"
                            >
                              <Form.Control
                                type="text"
                                placeholder="Origin"
                                name="origin"
                                tabIndex={25}
                                defaultValue={datas.origin || ""}
                                onChange={handleChangeInput}
                              />
                            </FloatingLabel>
                          </Form.Group>
                          <Form.Group as={Col} md="6">
                            <FloatingLabel
                              controlId="floatingSelect"
                              className="dropDown"
                              label="Commodity"
                            >
                              <Form.Select
                                aria-label="Floating label select example"
                                name="commodity"
                                tabIndex={27}
                                placeholder="Commodity"
                                value={datas.commodity || ""}
                                onChange={handleChangeInput}
                              >
                                <option value='Indonesian Steam Coal in Bulk ("Coal")'>
                                  Indonesian Steam Coal in Bulk ("Coal")
                                </option>
                                <option value="US Coal">US Coal</option>
                                <option value="Russia Coal">Russia Coal</option>
                                <option value="SA Coal">SA Coal</option>
                                <option value="Clinker in Bulk">
                                  Clinker in Bulk
                                </option>
                                <option value="Australian Steam Coal">
                                  Australian Steam Coal
                                </option>
                              </Form.Select>
                            </FloatingLabel>
                          </Form.Group>

                          <Form.Group as={Col} md="6">
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Destination"
                              className="mb-3"
                            >
                              <Form.Control
                                type="text"
                                placeholder="Destination"
                                tabIndex={26}
                                name="destination"
                                defaultValue={datas.destination || ""}
                                onChange={handleChangeInput}
                              />
                            </FloatingLabel>
                          </Form.Group>

                        </Row>
                        <Row className="mb-3">
                          <h4 className="heading">
                            Price and Price Adjustment
                          </h4>
                          <Form.Group as={Col} md="6" className='textEditor'>
                            <label>Pricing Terms</label>
                            <Editor value={pricingTerm} onChange={onChangePricingTerm} tabIndex={28} />
                          </Form.Group>
                          <Form.Group as={Col} md="6"></Form.Group>
                          <Form.Group as={Col} md="6" className='textEditor'>
                            <label>Price  Adjustment Clause</label>
                            <Editor value={priceAdjust} onChange={onChangePricingAdjust} tabIndex={29} />
                          </Form.Group>
                          <Form.Group as={Col} md="6"></Form.Group>
                          <Form.Group as={Col} md="6" className='textEditor'>
                            <label>Bonus penalty clause</label>
                            <Editor value={bonus} onChange={onChangeBonus} tabIndex={30}/>

                          </Form.Group>

                          <Form.Group as={Col} md="6"></Form.Group>
                          <Form.Group as={Col} md="6" className='textEditor'>
                            <label>Payment terms</label>
                            <Editor value={paymentTerms} onChange={onChangePaymentTerms} tabIndex={31} />

                          </Form.Group>
                          <Form.Group as={Col} md="6"></Form.Group>
                          <Form.Group as={Col} md="6" className='textEditor'>
                            <label>General terms</label>
                            <Editor value={generalTerms} onChange={onChangeGeneralTerms} tabIndex={31} />
                          </Form.Group>
                          <Form.Group as={Col} md="6"></Form.Group>
                          <Form.Group as={Col} md="6" className='textEditor'>
                            <label>Rejections</label>
                            <Editor value={rejections} onChange={onChangeRejections} tabIndex={32} />
                          </Form.Group>
                          <Form.Group as={Col} md="6"></Form.Group>
                          <Form.Group as={Col} md="6">
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Hs code"
                              className="mb-3"
                            >
                              <Form.Control
                                type="text"
                                placeholder="Hs code"
                                name="hs_code"
                                tabIndex={33}
                                defaultValue={datas.hs_code || ""}
                                onChange={handleChangeInput}
                              />
                            </FloatingLabel>
                          </Form.Group>
                        </Row>
                        <Row className="mb-3">
                          <h4 className="heading">Payment Information</h4>
                          <Form.Group as={Col} md="6">
                            <FloatingLabel
                              controlId="floatingSelect"
                              className="dropDown"
                              label="LC Type"
                            >
                              <Form.Select
                                aria-label="Floating label select example"
                                name="lc_type"
                                placeholder="LC Type"
                                tabIndex={34}
                                value={datas.lc_type || ""}
                                onChange={handleChangeInput}
                              >
                                <option value="">Select</option>
                                <option value="Irrevocable transferrable Letter of Credit">
                                  Irrevocable transferrable Letter of Credit
                                </option>
                                <option value="Irrevocable Non transferrable Letter of Credit">
                                  Irrevocable Non transferrable Letter of Credit
                                </option>
                                <option value="Irrevocable Letter of Credit">
                                  Irrevocable Letter of Credit
                                </option>
                                <option value="Irrevocable Back to Back LC">
                                  Irrevocable Back to Back LC
                                </option>
                                <option value="Payment and Receipt">
                                  Payment and Receipt
                                </option>
                                <option value="Standby_letter_of_credit_c">
                                  Standby Letter of Credit
                                </option>
                              </Form.Select>
                            </FloatingLabel>
                          </Form.Group>
                          <Form.Group as={Col} md="6">
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Master lc presentation days"
                              className="mb-3"
                            >
                              <Form.Control
                                type="text"
                                placeholder="Master lc presentation days"
                                tabIndex={39}
                                name="master_lc_presentation_days"
                                defaultValue={
                                  datas.master_lc_presentation_days || ""
                                }
                                onChange={handleChangeInput}
                              />
                            </FloatingLabel>
                          </Form.Group>
                          <Form.Group as={Col} md="6">
                            <FloatingLabel
                              controlId="floatingSelect"
                              className="dropDown"
                              label="Payment Details"
                            >
                              <Form.Select
                                aria-label="Floating label select example"
                                name="payment_details"
                                placeholder="Payment Details"
                                value={datas.payment_details || ""}
                                onChange={handleChangeInput}
                                tabIndex={35}
                              >
                                <option value="">--None--</option><option value="AT SIGHT">AT SIGHT</option><option value="Usance">Usance</option><option value="Telegraphic Transfer">Telegraphic Transfer</option>
                              </Form.Select>
                            </FloatingLabel>
                          </Form.Group>

                          <Form.Group as={Col} md="6">
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Lc opening days"
                              className="mb-3"
                            >
                              <Form.Control
                                type="number"
                                placeholder="Lc opening days"
                                name="lc_opening_days"
                                tabIndex={40}
                                defaultValue={datas.lc_opening_days || ""}
                                onChange={handleChangeInput}
                              />
                            </FloatingLabel>
                          </Form.Group>
                          <Form.Group as={Col} md="6">
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Master lc tolerance (%)"
                              className="mb-3"
                            >
                              <Form.Control
                                type="number"
                                placeholder="Master lc tolerance (%)"
                                name="master_lc_tolerance"
                                tabIndex={36}
                                defaultValue={datas.master_lc_tolerance || ""}
                                onChange={handleChangeInput}
                              />
                            </FloatingLabel>
                          </Form.Group>

                          <Form.Group as={Col} md="6">
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Lc opening days in words"
                              className="mb-3"
                            >
                              <Form.Control
                                type="text"
                                placeholder="Lc opening days in words"
                                name="lc_opening_days_in_words"
                                tabIndex={41}
                                defaultValue={
                                  datas.lc_opening_days_in_words || ""
                                }
                                onChange={handleChangeInput}
                              />
                            </FloatingLabel>
                          </Form.Group>
                          <Form.Group as={Col} md="6">
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Lc validity no of days"
                              className="mb-3"
                            >
                              <Form.Control
                                type="number"
                                placeholder="Lc validity no of days"
                                name="lc_validity_no_of_days"
                                tabIndex={37}
                                defaultValue={
                                  datas.lc_validity_no_of_days || ""
                                }
                                onChange={handleChangeInput}
                              />
                            </FloatingLabel>
                          </Form.Group>
                          <Form.Group as={Col} md="6">
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Document presentation period"
                              className="mb-3"
                            >
                              <Form.Control
                                type="text"
                                placeholder="Document presentation period"
                                name="document_presentation_period"
                                tabIndex={42}
                                defaultValue={
                                  datas.document_presentation_period || ""
                                }
                                onChange={handleChangeInput}
                              />
                            </FloatingLabel>
                          </Form.Group>
                          <Form.Group as={Col} md="6">
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Latest date of shipment"
                              className="mb-3"
                            >
                              <Form.Control
                                type="text"
                                tabIndex={38}
                                placeholder="Latest date of shipment"
                                name="latest_date_of_shipment"
                                defaultValue={
                                  datas.latest_date_of_shipment || ""
                                }
                                onChange={handleChangeInput}
                              />
                            </FloatingLabel>
                          </Form.Group>
                          <Form.Group as={Col} md="6"></Form.Group>
                          <Form.Group as={Col} md="6">
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Loading rate per day"
                              className="mb-3"
                            >
                              <Form.Control
                                type="text"
                                tabIndex={39}
                                placeholder="Loading rate per day"
                                name="loading_rate_per_day"
                                defaultValue={datas.loading_rate_per_day || ""}
                                onChange={handleChangeInput}
                              />
                            </FloatingLabel>
                          </Form.Group>
                        </Row>
                        <Row className="mb-3">
                          <h4 className="heading">
                            Important Terms Information
                          </h4>
                          <Form.Group as={Col} md="12" className='lists'>
                            <FloatingLabel
                              controlId="floatingInput"
                              label="List of documents required"
                              className="mb-3"
                            >
                              <Form.Control
                                as="textarea"
                                tabIndex={43}
                                style={{ height: "200px!important" }}
                                placeholder="List of documents required"
                                name="list_of_documents_required"
                                defaultValue={document.map((x) => x.list_of_docuemnts).join('\n')}
                                onChange={handleChangeInput}
                              />
                            </FloatingLabel>
                          </Form.Group>

                        </Row>
                        <Row className="mb-3">
                          <h4 className="heading">Signature Information</h4>
                          <Form.Group as={Col} md="6">
                            <FloatingLabel
                              controlId="floatingSelect"
                              className="dropDown"
                              label="Kind attention"
                            >
                              <Form.Select
                                aria-label="Floating label select example"
                                name="kind_attention"
                                tabIndex={44}
                                placeholder="Kind attention"
                                defaultValue={datas.kind_attention || ""}
                                onChange={handleChangeInput}
                              >
                                <option value="">Select </option>
                                {contacts.map((x) => {
                                  return (
                                    <option value={x.contact_full_name}>
                                      {x.contact_full_name}
                                    </option>
                                  );
                                })}
                              </Form.Select>
                            </FloatingLabel>
                          </Form.Group>

                          <Form.Group as={Col} md="6"></Form.Group>
                          <Form.Group as={Col} md="6">
                            <FloatingLabel
                              controlId="floatingSelect"
                              className="dropDown"
                              label="Customer signed by"
                            >
                              <Form.Select
                                aria-label="Floating label select example"
                                name="customer_signed_by"
                                tabIndex={45}
                                placeholder="Customer signed by"
                                value={datas.customer_signed_by || ""}
                                onChange={handleChangeInput}
                              >
                                <option value="">Select </option>
                                {contacts.map((x) => {
                                  return (
                                    <option value={x.contact_full_name}>
                                      {x.contact_full_name}
                                    </option>
                                  );
                                })}
                              </Form.Select>
                            </FloatingLabel>
                          </Form.Group><Form.Group as={Col} md="6"></Form.Group>
                          <Form.Group as={Col} md="6">
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Customer signed title"
                              className="mb-3"
                            >
                              <Form.Control
                                type="text"
                                placeholder="Customer signed title"
                                name="customer_signed_title"
                                tabIndex={46}
                                defaultValue={datas.customer_signed_title || ""}
                                onChange={handleChangeInput}
                              />
                            </FloatingLabel>
                          </Form.Group>
                          <Form.Group as={Col} md="6"></Form.Group>

                          <Form.Group as={Col} md="6">
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Customer Signed Date"
                              className="mb-3 date-picker"
                            >
                              <DatePicker
                                selected={customersigned}
                                onChange={handleCustomerSignedDate}
                                selectsStart
                                dateFormat="dd/MM/yyyy"
                                tabIndex={47}
                                placeholderText="Select"
                              />
                            </FloatingLabel>
                          </Form.Group>
                          <Form.Group as={Col} md="6">
                            <FloatingLabel
                              controlId="floatingSelect"
                              className="dropDown"
                              label="Company signed by"
                            >
                              <Form.Select
                                aria-label="Floating label select example"
                                name="company_signed_by"
                                placeholder="Company signed by"
                                tabIndex={49}
                                value={datas.company_signed_by || ""}
                                onChange={handleChangeInput}
                              >
                                <option value="">Select </option>
                                {users && users.length > 0 && users.map((x) => {
                                  // Check if the username is not null or an empty string
                                  if (x.username !== null && x.username !== "") {
                                    return (
                                      <option key={x.username} value={x.username}>
                                        {x.username}
                                      </option>
                                    );
                                  } else {
                                    return null; // Skip rendering if username is null or empty
                                  }
                                })}

                              </Form.Select>
                            </FloatingLabel>
                          </Form.Group>
                          <Form.Group as={Col} md="6">
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Original Contract Recieved Date"
                              className="mb-3 date-picker"
                            >
                              <DatePicker
                                selected={originals}
                                onChange={handleOriginal}
                                tabIndex={48}
                                dateFormat="dd/MM/yyyy"
                                placeholderText="Select Date"
                              />
                            </FloatingLabel>
                          </Form.Group>
                          <Form.Group as={Col} md="6">
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Company Signed Date"
                              className="mb-3 date-picker"
                            >
                              <DatePicker
                                selected={signed}
                                onChange={handleSigned}
                                tabIndex={50}
                                dateFormat="dd/MM/yyyy"
                                placeholderText="Select Date"
                              />
                            </FloatingLabel>
                          </Form.Group>
                          <Form.Group as={Col} md="6"></Form.Group>
                          <Form.Group as={Col} md="6">
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Original contract sent date"
                              className="mb-3 date-picker"
                            >
                              <DatePicker
                                selected={originalcontract}
                                onChange={handleOriginalcontract}
                                dateFormat="dd/MM/yyyy"
                                tabIndex={51}
                                placeholderText="Select Date"
                              />
                            </FloatingLabel>
                          </Form.Group>
                        </Row>
                        <Row className="mb-3">
                          <h4 className="heading">Other Standard Terms</h4>
                          <Form.Group as={Col} md="6">
                            <FloatingLabel
                              controlId="floatingSelect"
                              className="dropDown"
                              label="Applicable Law"
                            >
                              <Form.Select
                                aria-label="Floating label select example"
                                name="applicable_law"
                                tabIndex={52}
                                placeholder="Applicable Law"
                                value={datas.applicable_law || ""}
                                onChange={handleChangeInput}
                              >
                                <option value="">--None--</option>
                                <option value="English Law">English Law</option>
                                <option value="Singapore Law">
                                  Singapore Law
                                </option>
                                <option value="Indonesian Law">
                                  Indonesian Law
                                </option>
                              </Form.Select>
                            </FloatingLabel>
                          </Form.Group>
                          <Form.Group as={Col} md="6">
                            <FloatingLabel
                              controlId="floatingSelect"
                              className="dropDown"
                              label="Rwood surveryor agent 1"
                            >
                              <Form.Select
                                aria-label="Floating label select example"
                                name="rwood_surveryor_agent"
                                placeholder="Rwood Surveryor Agent 1
"tabIndex={54}
                                value={datas.rwood_surveryor_agent || ""}
                                onChange={handleChangeInput}
                              >
                                <option value="">Select </option>
                                {surveyors.map((x) => {
                                  return (
                                    <option value={x.account_name}>
                                      {x.account_name}
                                    </option>
                                  );
                                })}
                              </Form.Select>
                            </FloatingLabel>
                          </Form.Group>
                          <Form.Group as={Col} md="6">
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Arbitration"
                              className="mb-3"
                            >
                              <Form.Control
                                type="text"
                                placeholder="Arbitration"
                                tabIndex={53}
                                name="arbitration"
                                defaultValue={datas.arbitration || ""}
                                onChange={handleChangeInput}
                              />
                            </FloatingLabel>
                          </Form.Group>

                          <Form.Group as={Col} md="6">
                            <FloatingLabel
                              controlId="floatingSelect"
                              className="dropDown"
                              label="Rwood Surveryor Agent 2"
                            >
                              <Form.Select
                                aria-label="Floating label select example"
                                name="surveyor_2"
                                placeholder="Rwood Surveryor Agent 2"
                                value={datas.surveyor_2 || ""}
                                tabIndex={55}
                                onChange={handleChangeInput}
                              >
                                <option value="">Select </option>
                                {surveyors.map((x) => {
                                  return (
                                    <option value={x.account_name}>
                                      {x.account_name}
                                    </option>
                                  );
                                })}
                              </Form.Select>
                            </FloatingLabel>
                          </Form.Group>
                          <Form.Group as={Col} md="6"></Form.Group>
                          <Form.Group as={Col} md="6">
                            <FloatingLabel
                              controlId="floatingSelect"
                              className="dropDown"
                              label="Rwood Surveryor Agent 3"
                            >
                              <Form.Select
                                aria-label="Floating label select example"
                                name="surveyor_3"
                                placeholder="Rwood Surveryor Agent 3"
                                value={datas.surveyor_3 || ""}
                                tabIndex={56}
                                onChange={handleChangeInput}
                              >
                                <option value="">Select </option>
                                {surveyors.map((x) => {
                                  return (
                                    <option value={x.account_name}>
                                      {x.account_name}
                                    </option>
                                  );
                                })}
                              </Form.Select>
                            </FloatingLabel>
                          </Form.Group>
                        </Row>

                        {/* <Row className="mb-3">
                          <h4 className="heading">
                            List Of Fields
                          </h4>
                          <Form.Group as={Col} md="6">
                            <FloatingLabel
                              controlId="floatingTextarea2"
                              label="LC Presentation Period"
                            >
                              <Form.Control
                                as="textarea"
                                placeholder="LC Presentation Period"
                                style={{ height: "100px" }}
                                name="lc_presentation_period"
                                defaultValue={
                                  datas.lc_presentation_period || ""
                                }
                                onChange={handleChangeInput}
                              />
                            </FloatingLabel>
                          </Form.Group>
                          <Form.Group as={Col} md="6"></Form.Group>
                          <Form.Group as={Col} md="6" className='textEditor'>
                            <label>Description</label>
                            <Editor value={description_1} onChange={onChangeDescription} />
                          </Form.Group>
                          <Form.Group as={Col} md="6"></Form.Group>
                          <Form.Group as={Col} md="6" className='textEditor'>
                            <label>Product Description</label>
                            <Editor value={productdesc} onChange={onChangeProductDesc} />
                          </Form.Group>
                          <Form.Group as={Col} md="6"></Form.Group>
                        </Row> */}
                        <p className="edit-btn">
                          <input
                            type="submit"
                            className="account-save"
                            onClick={handleSaveEdit}
                            value="Save"
                            //disabled={isSubmitted}
                          />
                          <button
                            className="btn btn-primary"
                            onClick={handleCancelEdit}
                          >
                            Cancel
                          </button>
                        </p>
                      </div>
                    ) : (
                      <>
                        <div className="contactAccount fulid-section">
                          <div className="tasks">
                            <p className="link-texts"><span><a href="#list-document">List of Documents</a> | </span><span><a href="#contract-product">Contract Products </a>|</span> <span><a href="#contract-ship">Contract Shipments </a>| </span><span>Products (Buyer Contract) |</span><span> <a href="#opportunity">Opportunities </a>|</span><span> <a href='#quotes'>Quotes  </a>|</span> <span><a href="#tcf">Trade Confirmation Forms </a>| </span><span><a href="#orders">Orders </a>|</span><span> Activity History | </span><span>Contract History  |</span></p>
                            <div className="edit-delete">
                              <span id="edit" onClick={handleEditClick}>
                                Edit
                              </span>
                              <span>
                                <Link to={"/contract/create-contract-list"}>
                                  Cancel
                                </Link>
                              </span>
                              <span>Activate</span>
                              <span>Generate Contract</span>
                              <span>Generate Contract Clauses</span>
                              <span>Shipment Checklist</span>
                            </div>
                            <div className="">
                              <div className="row">
                                <div className="col-md-12" id="head">
                                  <h4 className="heading">
                                    Contract Details
                                  </h4>

                                  <hr></hr>
                                </div>
                              </div>
                              <table class="table table-bordered account-table tables">
                                <tbody>
                                  <tr>
                                    <td id="td-right">
                                      <span>Locked</span>{" "}
                                    </td>
                                    <td> <input
                                      type='checkbox'
                                      name='locked'
                                      checked={lockeds} // Use the state variable to determine if the checkbox is checked
                                      onChange={handleCheckboxChange} // Handle the change event
                                      className='mx-3' disabled={isButtonEnabled}
                                    /></td>
                                    <td id="td-right">
                                      <span>Company</span>
                                    </td>
                                    <td>
                                      <Link
                                        to={`/company/company-details/${datas.rwood_company_id}`}
                                      >
                                        {datas.company}
                                      </Link>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td id="td-right">
                                      <span>Currency</span>
                                    </td>
                                    <td>{datas.currency}</td>
                                    <td id="td-right">
                                      <span>Trader Name</span>
                                    </td>
                                    <td>
                                      {datas.trader_name !== null && datas.trader_name !== "" ? (
                                        <Link to={"/user/user-details/" + datas.rwood_user_id}>
                                          {datas.trader_name}
                                        </Link>
                                      ) : (
                                        <span>{datas.trader_name}</span>
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td id="td-right">
                                      <span>Exchange Rate</span>
                                    </td>
                                    <td>{datas.exchange_rate}</td>
                                    <td id="td-right">
                                      <span>Contract Owner</span>
                                    </td>
                                    <td>{datas.contract_owner}</td>
                                  </tr>
                                  <tr>
                                    <td id="td-right">
                                      <span>Contract record type</span>
                                    </td>
                                    <td>{datas.contract_record_type}</td>
                                    <td id="td-right">
                                      <span>Price Book</span>
                                    </td>
                                    <td>{datas.price_book}</td>
                                  </tr>
                                  <tr>
                                    <td id="td-right">
                                      <span>Contract Number</span>
                                    </td>
                                    <td><Link to={`/contract/contract-details/${datas.id}`}>{datas.contract_number}</Link></td>
                                    <td id="td-right">
                                      {/* <span>Supplier</span> */}
                                    </td>
                                    <td>
                                      {/* <Link to={`/accounts/${datas.supplier_id}`}>{datas.supplier}</Link> */}
                                      </td>
                                  </tr>
                                  <tr>
                                    <td id="td-right">
                                      <span>Rwood contract no</span>
                                    </td>
                                    <td><Link to={`/contract/contract-details/${datas.id}`}>{datas.Rwood_Contract_No}</Link></td>
                                    <td id="td-right">
                                      <span>Supplier</span>
                                    </td>
                                    <td><Link to={`/accounts/${datas.supplier_id}`}>{datas.supplier}</Link></td>
                                  </tr>
                                  <tr>
                                    <td id="td-right">
                                      <span>Account Name</span>
                                    </td>
                                    <td>
                                      <Link
                                        to={"/accounts/" + datas.account_id}
                                      >
                                        {" "}
                                        {datas.account_name}{" "}
                                      </Link>
                                    </td>

                                    <td id="td-right">
                                      <span>Contract term in months</span>
                                    </td>
                                    <td>{datas.contract_term_in_months}</td>
                                  </tr>
                                  <tr>
                                    <td id="td-right">
                                      <span>Buyer Billing Address
                                      </span>
                                    </td>
                                    <td>
                                      {datas.billing_address}{" "}
                                    </td>

                                    <td id="td-right">
                                      <span>Contract Start Date</span>
                                    </td>
                                    <td>{datas.contract_start_date}</td>
                                  </tr><tr>
                                    <td id="td-right">
                                      <span>Contract Status</span>
                                    </td>
                                    <td>{datas.contract_status}</td>
                                    <td id="td-right">
                                      <span>Contract End Date</span>
                                    </td>
                                    <td>{datas.contract_end_date}</td>
                                  </tr><tr>
                                    <td id="td-right">
                                      <span>Pending Matters</span>
                                    </td>
                                    <td><div dangerouslySetInnerHTML={{ __html: datas.pending_matters }} /></td>
                                    <td id="td-right">
                                      <span className="a-a">
                                      Contract Effective Date{" "}
                                                <Tooltip text={<span>Contract Effective Date</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                                              </span>
                                      
                                    </td>
                                    <td>{datas.contract_effective_date}</td>
                                  </tr>
                                  <tr>
                                    <td id="td-right">
                                       <span className="a-a">
                                          Remark{" "}
                                                <Tooltip text={<span>Any Specific Remark related to Contract</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                                              </span>
                                    </td>
                                    <td><div dangerouslySetInnerHTML={{ __html: datas.remark }} /></td>

                                    <td id="td-right">
                                      {/*  <span className="a-a">
                                          Remark{" "}
                                                <Tooltip text={<span>Any Specific Remark related to Contract</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                                              </span> */}
                                    </td>
                                    <td>
                                    </td>


                                  </tr>
                                  <tr>

                                    <td id="td-right">

                                       <span className="a-a">
                                          Master Contract Reference{" "}
                                                <Tooltip text={<span>Give master contract reference, if any</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                                              </span>
                                    </td>
                                    <td>{datas.master_conference_reference}</td>
                                    <td id="td-right">
                                      {/* <span>Renewal contract number</span> */}
                                    </td>
                                    <td>
                                      {/* {datas.renewal_contract_number} */}
                                    </td>
                                  </tr><tr>

                                    <td id="td-right">
                                      <span>Renewal Date</span>
                                    </td>
                                    <td>{datas.renewal_date}</td>
                                    <td id="td-right">
                                      {/* <span>Contract Number</span> */}
                                    </td>
                                    <td>
                                      {/* {datas.contract_number} */}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td id="td-right">
                                      <span>Renewal contract number</span>
                                    </td>
                                    <td>{datas.renewal_contract_number}</td>
                                    <td id="td-right">
                                      {/* <span>Contract Number</span> */}
                                    </td>
                                    <td>
                                      {/* {datas.contract_number} */}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div className="">
                              <div className="row">
                                <div className="col-md-12" id="head">
                                  <h4 className="heading">Current Status</h4>
                                  <hr></hr>
                                </div>
                              </div>
                              <table class="table table-bordered account-table tables">
                                <tbody>
                                  <tr>
                                    <td id="td-right">
                                    <span className="a-a">
                                          No of Shipments{" "}
                                                <Tooltip text={<span>Roll of No of shipments (Contract Shipments)</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                                              </span>                                    </td>
                                    <td> {renderShipmentQty(datas.no_of_shipments)} </td>
                                    <td id="td-right">
                                      <span>Number of orders</span>
                                    </td>
                                    <td> {renderShipmentQty(datas.no_of_orders)} </td>
                                  </tr>
                                  <tr>
                                  <td id="td-right">
                                      <span>No of Contract Product

                                      </span>
                                    </td>
                                   <td> {renderShipmentQty(datas.no_of_contract_products)} </td>
                                    <td id="td-right">
                                      <span>order quantity</span>
                                    </td>
                                    <td> {renderShipmentQty(datas.order_quantity)} </td>

                                  </tr>
                                </tbody>
                              </table>
                            </div>

                            <div className="">
                              <div className="row">
                                <div className="col-md-12" id="head">
                                  <h4 className="heading">
                                    Commodity and Contracted Quantity
                                  </h4>
                                  <hr></hr>
                                </div>
                              </div>
                              <table class="table table-bordered account-table tables">
                                <tbody>

                                  <tr>
                                    <td id="td-right">
                                      <span>Interlab Tolerance</span>
                                    </td>
                                    <td><div dangerouslySetInnerHTML={{ __html: datas.interlab_tolerance }} /></td>
                                    <td id="td-right">
                                      <span>Contract Quantity in MT</span>
                                    </td>
                                    <td>{renderShipmentQty(datas.contract_quantity_in_MT)}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div className="">
                              <div className="row">
                                <div className="col-md-12" id="head">
                                  <h4 className="heading">
                                    Market Informatione
                                  </h4>
                                  <hr></hr>
                                </div>
                              </div>
                              <table class="table table-bordered account-table tables">
                                <tbody>
                                  <tr>
                                    <td id="td-right">
                                      <span>Origin</span>
                                    </td>
                                    <td>{datas.origin}</td>
                                    <td id="td-right">
                                      <span>Commodity</span>
                                    </td>
                                    <td>{datas.commodity}</td>
                                  </tr>

                                  <tr>
                                    <td id="td-right">
                                      <span>Destination</span>
                                    </td>
                                    <td>{datas.destination}</td>
                                    <td id="td-right">
                                      {/* <span>Pricing Terms</span> */}
                                    </td>
                                    <td></td>
                                  </tr>

                                </tbody>
                              </table>
                            </div>
                            <div className="">
                              <div className="row">
                                <div className="col-md-12" id="head">
                                  <h4 className="heading">
                                    Price and Price Adjustment
                                  </h4>
                                  <hr></hr>
                                </div>
                              </div>
                              <table class="table table-bordered account-table tables">
                                <tbody>
                                  <tr>
                                    <td id="td-right">
                                      <span>Pricing Terms</span>
                                    </td>
                                    <td><div dangerouslySetInnerHTML={{ __html: datas.pricing_terms }} /></td>
                                    <td id="td-right">
                                      {/* <span>Destination</span> */}
                                    </td>
                                    <td>{/* {datas.destination} */}</td>
                                  </tr>
                                  <tr>
                                    <td id="td-right">
                                      <span>Price adjustment clause</span>
                                    </td>
                                    <td><div dangerouslySetInnerHTML={{ __html: datas.price_adjustment_clause }} /></td>
                                    <td id="td-right">
                                      {/* <span>Bonus penalty clause</span> */}
                                    </td>
                                    <td>
                                      {/* <div dangerouslySetInnerHTML={{ __html: datas.bonus_penalty_clause }} /> */}
                                    </td>
                                  </tr>

                                  <tr>
                                    <td id="td-right">
                                      <span>Bonus penalty clause</span>
                                    </td>
                                    <td><div dangerouslySetInnerHTML={{ __html: datas.bonus_penalty_clause }} /></td>

                                    <td id="td-right">
                                      <span></span>
                                    </td>
                                    <td></td>
                                  </tr>


                                  <tr>
                                    <td id="td-right">
                                      <span>General terms</span>
                                    </td>
                                    <td><div dangerouslySetInnerHTML={{ __html: datas.general_terms }} /></td>
                                    <td id="td-right">
                                      {/* <span>General terms</span> */}
                                    </td>
                                    <td></td>
                                  </tr>

                                  <tr>
                                    <td id="td-right">
                                      <span>Rejections</span>
                                    </td>
                                    <td><div dangerouslySetInnerHTML={{ __html: datas.rejections }} /></td>
                                    <td id="td-right"></td>
                                    <td></td>
                                  </tr>

                                  <tr>
                                    <td id="td-right">
                                      <span>HS code</span>
                                    </td>
                                    <td>{datas.hs_code}</td>
                                    <td id="td-right"></td>
                                    <td></td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>

                            <div className="">
                              <div className="row">
                                <div className="col-md-12" id="head">
                                  <h4 className="heading">
                                    Payment Information
                                  </h4>
                                  <hr></hr>
                                </div>
                              </div>
                              <table class="table table-bordered account-table tables">
                                <tbody>
                                  <tr>
                                    <td id="td-right">
                                      <span>LC Type</span>
                                    </td>
                                    <td>{datas.lc_type}</td>
                                    <td id="td-right">
                                      <span>Master lc presentation days</span>
                                    </td>
                                    <td>{datas.master_lc_presentation_days}</td>
                                  </tr>
                                  <tr>
                                    <td id="td-right">
                                      <span>Payment Details </span>
                                    </td>
                                    <td>{datas.payment_details}</td>
                                    <td id="td-right">
                                      <span>Lc opening days</span>
                                    </td>
                                    <td>{datas.lc_opening_days}</td>
                                  </tr>

                                  <tr>
                                    <td id="td-right">
                                      <span>Master lc tolerance</span>
                                    </td>
                                    <td>{datas.master_lc_tolerance} <span>%</span></td>
                                    <td id="td-right">
                                      <span>Lc opening days in words</span>
                                    </td>
                                    <td>{datas.lc_opening_days_in_words}</td>
                                  </tr>

                                  <tr>
                                    <td id="td-right">
                                      <span>Total LC Value</span>
                                    </td>
                                    <td>{datas.total_lc_value}<span>%</span></td>
                                    <td id="td-right">
                                      <span>Document presentation period</span>
                                    </td>
                                    <td>
                                      {datas.document_presentation_period}
                                    </td>
                                  </tr>

                                  <tr>
                                    <td id="td-right">
                                      <span>Lc validity no of days</span>
                                    </td>
                                    <td>{datas.lc_validity_no_of_days}</td>
                                    <td id="td-right">
                                      {/* <span>Document presentation period</span> */}
                                    </td>
                                    <td>
                                      {/* {datas.document_presentation_period} */}
                                    </td>
                                  </tr>

                                  <tr>
                                    <td id="td-right">
                                      <span>Latest date of shipment</span>
                                    </td>
                                    <td>{datas.latest_date_of_shipment}</td>
                                    <td id="td-right"></td>
                                    <td></td>
                                  </tr>

                                  <tr>
                                    <td id="td-right">
                                    <span className="a-a">
                                        Loading Rate Per Day{" "}
                                                <Tooltip text={<span>Loading Rate /Discharge Rate</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                                              </span>
                                    </td>
                                    <td>{datas.loading_rate_per_day}</td>
                                    <td id="td-right"></td>
                                    <td></td>
                                  </tr>
                                  {/* <tr>
                                    <td id="td-right">
                                      <span>Payment terms</span>
                                    </td>
                                    <td><div dangerouslySetInnerHTML={{ __html: datas.payment_terms }} /></td>
                                    <td id="td-right">
                                      {/* <span>General terms</span> */}
                                  {/* </td>
                                    <td>

                                    </td>
                                  </tr> */}
                                </tbody>
                              </table>
                            </div>
                            <div className="">
                              <div className="row">
                                <div className="col-md-12" id="head">
                                  <h4 className="heading">
                                    Important Terms Information
                                  </h4>
                                  <hr></hr>
                                </div>
                              </div>
                              <table class="table table-bordered account-table tables">
                                <tbody>
                                  <tr>
                                    <td id="td-right">
                                      <span>List of documents required</span>
                                    </td>
                                    <td style={{ width: '75%' }}>
                                      <ol>
                                        {document.map((x) => {
                                          console.log(x); // Log the entire object to inspect its structure and properties
                                          return (
                                            <li key={x.id}>
                                              {`${x.list_of_docuemnts} in ${numberToText(x.original_in_no)} ${x.original_in_no} Originals and ${numberToText(x.copies_in_no)} ${x.copies_in_no} Copies  ${x.suffix}.`}
                                            </li>
                                          );
                                        })}
                                      </ol>
                                    </td>
                                    <td></td>
                                    <td></td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>

                            <div className="">
                              <div className="row">
                                <div className="col-md-12" id="head">
                                  <h4 className="heading">
                                    Signature Information
                                  </h4>
                                  <hr></hr>
                                </div>
                              </div>
                              <table class="table table-bordered account-table tables">
                                <tbody>

                                  <tr>
                                    <td id="td-right">
                                      <span>kind attention</span>
                                    </td>
                                    <td>{datas.kind_attention}</td>
                                    <td id="td-right">
                                      {/* <span>Customer signed by</span> */}
                                    </td>
                                    <td>
                                      {/* {datas.customer_signed_by} */}
                                    </td>
                                  </tr>

                                  <tr>
                                    <td id="td-right">
                                      {/* <span>Customer signed by</span> */}
                                    </td>
                                    <td>
                                      {/* {datas.customer_signed_by} */}
                                    </td>
                                    <td id="td-right">
                                      {/* <span>Company signed by</span> */}
                                    </td>
                                    <td>
                                      {/* {datas.company_signed_by} */}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td id="td-right">
                                      <span>Customer signed title</span>
                                    </td>
                                    <td>
                                      {datas.customer_signed_title}
                                    </td>
                                    <td id="td-right">
                                      {/* <span>Company signed by</span> */}
                                    </td>
                                    <td>
                                      {/* {datas.company_signed_by} */}
                                    </td>
                                  </tr>

                                  <tr>
                                    <td id="td-right">
                                      <span>customer signed date</span>
                                    </td>
                                    <td>{datas.customer_signed_date}</td>

                                    <td id="td-right">
                                      <span>company signed by</span>
                                    </td>
                                    <td>

                                      {datas.company_signed_by}
                                    </td>

                                  </tr>

                                  <tr>
                                    <td id="td-right">
                                      <span>Customer signed by</span>
                                    </td>
                                    <td>
                                      {datas.original_contract_recieved_date}
                                    </td>
                                    <td id="td-right">
                                      <span>company signed date</span>
                                    </td>
                                    <td>{datas.company_signed_date}</td>




                                  </tr>
                                  <tr>
                                    <td id="td-right"></td>
                                    <td></td>

                                    <td id="td-right">
                                      <span>
                                        {/* Original contract sent date */}
                                      </span>
                                    </td>
                                    <td>
                                      {/* {datas.original_contract_sent_date} */}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>

                            <div className="">
                              <div className="row">
                                <div className="col-md-12" id="head">
                                  <h4 className="heading">
                                    Other Standard Terms
                                  </h4>
                                  <hr></hr>
                                </div>
                              </div>
                              <table class="table table-bordered account-table tables">
                                <tbody>
                                  <tr>
                                    <td id="td-right">
                                      <span>Applicable law</span>
                                    </td>
                                    <td>{datas.applicable_law}</td>
                                    <td id="td-right">
                                      <span>Rwood Surveryor Agent 1</span>
                                    </td>
                                    <td>{datas.rwood_surveryor_agent}</td>
                                  </tr>
                                  <tr>
                                    <td id="td-right">
                                      <span>Arbitration</span>
                                    </td>
                                    <td>{datas.arbitration}</td>
                                    <td id="td-right">
                                      <span>Rwood Surveryor Agent 2</span>
                                    </td>
                                    <td>{datas.surveyor_2}</td>
                                  </tr>
                                  <tr>
                                    <td id="td-right">
                                      <span></span>
                                    </td>
                                    <td></td>
                                    <td id="td-right">
                                      <span>Rwood Surveryor Agent 3</span>
                                    </td>
                                    <td>{datas.surveyor_3}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div className="">
                                      <div className="row">
                                        <div className="col-md-12" id="head">
                                          <h4 className="heading">
                                            System Information
                                          </h4>
                                          <hr></hr>
                                        </div>
                                      </div>
                                      <table class="table table-bordered account-table tables">
                                        <tbody>

                                          <tr>
                                            <td id="td-right">
                                              <span>Activated By
                                              </span>
                                            </td>
                                            <td>{datas.activated_by
                                            }</td>
                                            <td id="td-right">
                                              <span>Activated Date
                                              </span>
                                            </td>
                                            <td>{datas.activated_date
                                            }</td>
                                          </tr>
                                          <tr>
                                            <td id="td-right">
                                              <span>Created By
                                              </span>
                                            </td>
                                            <td>
                                              {datas.created_by
                                              }
                                            </td>

                                            <td id="td-right">
                                              <span>Last Modified By
                                              </span>
                                            </td>
                                            <td>{datas.last_modified_by
                                            }</td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>

                            {/* <div className="">
                              <div className="row">
                                <div className="col-md-12" id="head">
                                  <h4 className="heading">
                                    List Of Fields-Not Required
                                  </h4>
                                  <hr></hr>
                                </div>
                              </div>
                              <table class="table table-bordered account-table tables">
                                <tbody>
                                  <tr>
                                    <td id="td-right">
                                      <span>LC Presentation Period</span>
                                    </td>
                                    <td>{datas.lc_presentation_period}</td>
                                    <td id="td-right">
                                      <span>Description</span>
                                    </td>
                                    <td><div dangerouslySetInnerHTML={{ __html: datas.description }} /></td>
                                  </tr>
                                  <tr>
                                    <td id="td-right">
                                      <span>Product Description</span>
                                    </td>
                                    <td><div dangerouslySetInnerHTML={{ __html: datas.product_description }} /></td>
                                    <td id="td-right"></td>
                                    <td></td>
                                  </tr>
                                  <tr>
                                    <td id="td-right">
                                      <span>Created By</span>
                                    </td>
                                    <td>{datas.created_by}</td>
                                    <td id="td-right">
                                      <span>Last Modified By
                                      </span>
                                    </td>
                                    <td>{datas.last_modified_by}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div> */}
                          </div>
                        </div>

                        <div
                          className="tasks view-table"
                          Style="box-showdow:0px 13px 20px #d6d1d1"
                        >
                          <Row id="table-styles">
                            <div className="col-md-12" id="list-document">
                              <h4 className="heading">
                                <span>List of Documents</span>
                                <span>
                                  <Link to={"/contract/add-document/" + id}>
                                    <i className="fe fe-download-cloud me-2"></i>
                                    New
                                  </Link>{" "}
                                </span>
                              </h4>
                              <hr></hr>
                            </div>
                            <table id="document" className="">
                              <thead>
                                <tr>
                                  <th>contract list of docs</th>
                                  <th>document required name</th>
                                  <th>list of docuemnts</th>
                                  <th>original in no</th>
                                  <th>Copies In No</th>
                                </tr>
                              </thead>

                              {document.length > 0 ? (
                                <tbody>
                                  {document.map((y, i) => (
                                    <tr>
                                      <td className="">
                                        <Link
                                          to={"/contract/document-view/" + y.id}
                                        >
                                          {y.contract_list_of_docs}
                                        </Link>
                                      </td>
                                      <td>{y.document_required_name}</td>
                                      <td>{y.list_of_docuemnts}</td>
                                      <td>{y.original_in_no}</td>
                                      <td>{y.copies_in_no}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              ) : (
                                <tbody>
                                  <tr>
                                    <td></td>
                                    <td></td>
                                    <td>No data available</td>
                                    <td></td> <td></td>
                                  </tr>
                                </tbody>
                              )}
                            </table>
                          </Row>

                          <Row id="table-styles">
                            <div className="col-md-12" id="head">
                              <h4 className="heading">
                                <span>Contract Product</span>
                              </h4>
                              <hr></hr>
                            </div>
                            <table id="contract-product" className="">
                              <thead>
                                <tr>
                                  <th>Contract Product Number</th>
                                  <th>Product Master</th>
                                  <th>Gross As Received Typical	</th>
                                  <th>Total Moisture Typical	</th>
                                  <th>Ash Content Typical ARB	</th>
                                  <th>Total Sulphur Typical ARB	</th>
                                  <th>Price Basis GAR
                                  </th>
                                </tr>
                              </thead>

                              {contractProduct.length > 0 ? (
                                <tbody>
                                  {contractProduct.map((x, i) => (
                                    <tr>
                                      <td><Link
                                        to={`/contract-product/details/${x.id}`}
                                      >{x.contract_product_number}</Link></td>
                                      <td>
                                        <Link
                                          to={`/productmaster/product-detail/${x.id}`}
                                        >
                                          {" "}
                                          {x.product_master}{" "}
                                        </Link>
                                      </td>
                                      <td>{renderShipmentQty(x.gross_as_received_typical)}</td>
                                      <td>{renderShipmentQty(x.total_moisture_typical)}</td>
                                      <td>{renderShipmentQty(x.ash_typical_arb)}</td>
                                      <td>{renderShipmentQty(x.sulphur_typical_arb)}</td>
                                      <td>{renderShipmentQty(x.price_basis_GAR)}</td>

                                    </tr>
                                  ))}
                                </tbody>
                              ) : (
                                <tbody>
                                  <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>No data available</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                  </tr>
                                </tbody>
                              )}
                            </table>
                          </Row>


                          <Row id="table-styles">
                            <div className="col-md-12" id="head">
                              <h4 className="heading">
                                <span>Contract Shipment</span>
                                <span>
                                  <Popup
                                    trigger={
                                      <a className="button add-accountrecord">
                                        {" "}
                                        New{" "}
                                      </a>
                                    }
                                    modal
                                  >
                                    <form
                                      onSubmit={handleSubmit}
                                      className="add-accounts"
                                    >
                                      <div className="form-group">
                                        <label>
                                          Contract Shipment Record Type
                                        </label>
                                        <br />
                                        <select
                                          name="account_record"
                                          className="form-control"
                                          onChange={handleChange}
                                          value={formData.account_record}
                                        >
                                          <option value="">--Select record type--</option>
                                          <option value="Buyer - Long Term">
                                            Buyer - Long Term
                                          </option>
                                          <option value="Buyer - Spot">
                                            Buyer - Spot
                                          </option>
                                          <option value="Supplier - Long Term">
                                            Supplier - Long Term
                                          </option>
                                          <option value="Supplier - Spot">
                                            Supplier - Spot
                                          </option>
                                        </select>
                                        {error.account_record && <div className="errors-msg">{error.account_record}</div>}
                                      </div>
                                      <input
                                        type="submit"
                                        className="btn btn-primary "
                                        value="Submit"
                                      />
                                    </form>
                                  </Popup>
                                </span>
                              </h4>

                              <hr></hr>
                            </div>
                            <div id="inner-heading"></div>
                            {conShipmentParents.length > 0 ? (
                              <table id="contract-ship" className="">
                                <thead>
                                  <tr>
                                    <th>Record Type</th>
                                    <th>Contract Ship Code</th>
                                    <th>Shipment Status</th>
                                    <th>Parent Shipment</th>
                                    <th>Pricing</th>
                                    <th>Laycan</th>
                                    <th>Quantity Info</th>
                                    <th>No. of Shipments</th>
                                    <th>No. of Orders</th>
                                    <th>TCF Count</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {conShipmentParents.map((y, i) => (
                                    <tr key={i}>
                                      <td>{y.record_type}</td>
                                      <td>
                                        <Link to={`/shipment/contract-shipment-details/${y.id}`}>
                                          <span id="codes">{y.contract_ship_code}</span>
                                        </Link>
                                      </td>
                                      <td>{y.contract_shipment_status}</td>
                                      <td>
                                        {y.contract_ship_code === null ? (
                                          <Link to={`/shipment/contract-shipment-details/${y.id}`}>
                                            <span id="codes">{y.contract_ship_code}</span>
                                          </Link>
                                        ) : (
                                          y.parent_shipment !== null && y.parentship_id !== null && (
                                            <Link to={`/shipment/contract-shipment-details/${y.parentship_id}`}>
                                              {y.parent_shipment}
                                            </Link>
                                          )
                                        )}
                                      </td>
                                      <td>{y.pricing}</td>
                                      <td>{y.laycan}</td>
                                      <td>{y.quantity_info}</td>
                                      <td>{y.no_of_shipments}</td>
                                      <td>{y.no_of_orders}</td>
                                      <td>{y.tcf_count}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            ) : (
                              <p>No data available</p>
                            )}

                          </Row>
                          <Row id="table-styles">
                                <div className="col-md-12" id="head">
                                  <h4 className="heading">
                                    <span>Products (Buyer Contract)</span>
                                    <span>New</span>
                                  </h4>
                                  <hr></hr>
                                </div>
                                {buyerProduct.length > 0 ? (
                                  <table id="contract-ships" className="">
                                    <thead>
                                      <tr>
                                      <th>Product Name	</th>
                                      <th>Contract Shipment	</th>
                                      <th>Product Code	</th>
                                      <th>Product No	</th>
                                      <th>Quantity (MT)	</th>
                                      <th>Status</th>
                                      <th>Buyer Name
                                      </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {buyerProduct.map((y, i) => (
                                        <tr key={i}>
                                          <td><Link to={`/product/product-detail/${y.product_master_id}`}>{y.product_name}</Link></td>
                                           <td><Link to={`/shipment/contract-shipment-details/${y.contract_shipment_id}`}>{y.contract_shipment}</Link></td>
                                          <td><Link to={`/product/product-detail/${y.product_master_id}`}>{y.product_code}</Link></td>
                                          <td>{y.product_no}</td>
                                          <td>{y.quantity_MT}</td>
                                          <td>{y.status}</td>
                                          <td>{y.buyer_name}</td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                ) : (
                                  <tbody>
                                  <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>No data available</td>
                                    <td></td>
                                    <td></td>
                                  </tr>
                                </tbody>
                                )}
                              </Row>
                          <Row id="table-styles">
                            <div
                              className="col-md-12"
                              id="head"
                            >
                              <h4 className="heading">
                                <span>
                                  Opportunity Information{" "}
                                </span>{" "}
                                {/* <Popup trigger={ <span className="button add-accountrecord"> New </span>} modal> <form onSubmit={handleSubmitOpp} className="add-accounts">
                      <div className='form-group'>
                        <label>Opportunity Record Type</label>
                        <br />
                        <select className='form-control' name="type_of_new_record" onChange={handleChangeOpp} value={formDataopp.account_record}>
                          <option>----Select----</option>
                          <option value='Buyer'>Buyer</option>
                          <option value='Buyer - Long Term'>Buyer - Long Term</option>
                          <option value='Buyer - Spot'>Buyer - Spot</option>
                          <option value='Supplier'>Supplier</option>
                          <option value='Supplier - Long Term'>Supplier - Long Term</option>
                          <option value='Supplier - Spot'>Supplier - Spot</option>
                        </select>
                      </div>
                      <input type='submit' className="btn btn-primary " value='Submit' />
                    </form>
                  </Popup> */}
                              </h4>
                              <hr></hr>
                            </div>
                            <table
                              id="opportunity"
                              className=""
                            >
                              <thead>
                                <tr>
                                  <th>Opportunity Name</th>

                                  <th>Opportunity No</th>
                                  <th>Stage</th>
                                  <th>Quantity (MT)	</th>
                                  <th>Supplier</th>
                                  <th>No of Order</th>
                                </tr>
                              </thead>{" "}
                              {opportunity.length > 0 ? (
                                <tbody>
                                  {" "}
                                  {opportunity.map((y, i) => (
                                    <tr>
                                      <td>
                                        <Link
                                          to={`/opportunity/opportunity-detail/${y.id}`}
                                        >
                                          {y.opportunity_name}
                                        </Link>
                                      </td>
                                      <td>
                                        <Link to={`/opportunity/opportunity-detail/${y.id}`}>{y.opportunity_no} </Link>
                                      </td>
                                      <td>{y.stage}</td>
                                      <td>{y.quantity_mt}</td>
                                      <td><Link to={`/accounts/${y.supplier_id}`}>{y.supplier} </Link></td>
                                      <td>{y.no_of_order}</td>
                                    </tr>
                                  ))}{" "}
                                </tbody>
                              ) : (
                                <tbody>
                                  <tr>
                                    <td></td>
                                    <td></td>
                                    <td>No data available</td>
                                    <td></td>
                                    <td></td><td></td>
                                  </tr>
                                </tbody>
                              )}
                            </table>
                          </Row>


                          <Row id="table-styles">
                            <div className="col-md-12" id="head">
                              <h4 className="heading">
                                <span>Quotes</span>
                              </h4>
                              <hr></hr>
                            </div>
                            <table id="quotes" className="">
                              <thead>
                                <tr>
                                  <th>Quote Number</th>
                                  <th>Quote Name</th>
                                  <th>Account Name</th>
                                  <th>Contact Name</th>
                                  <th>Expiration Date</th>
                                  <th>Product Description	</th>
                                  <th>Status</th>

                                </tr></thead>
                              {quoteTable.length > 0 ? (
                                <tbody>
                                  {quoteTable.map((y, i) => (
                                    <tr>
                                      <td>
                                        <span id="codes">
                                          <Link to={`/opportunity/quote-view/${y.id}`}>{y.quote_number}                                         </Link>
                                        </span>
                                      </td>
                                      <td><Link to={`/opportunity/quote-view/${y.id}`}>{y.quote_name}  </Link> </td>
                                      <td><Link to={`/accounts/${y.account_id}`}>{y.account_name} </Link></td>
                                      <td>{y.contact_name}</td>
                                      <td>{y.expiration_date}</td>
                                      <td>{y.product_description}</td>
                                      <td>{y.status}</td>

                                    </tr>
                                  ))}
                                </tbody>
                              ) : (
                                <tbody>
                                  <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>No data available</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                  </tr>
                                </tbody>
                              )}
                            </table>
                          </Row>


                          <Row id="table-styles">
                            <div className="col-md-12" id="head">
                              <h4 className="heading">
                                <span>Trade Confirmation Forms</span>
                                <span>
                                  <Popup
                                    trigger={
                                      <a className="button add-accountrecord">
                                        {" "}
                                        New{" "}
                                      </a>
                                    }
                                    modal
                                  >
                                    <form
                                      onSubmit={handleSubmittcf}
                                      className="add-accounts"
                                    >
                                      <div className="form-group">
                                        <label>
                                          TCF Record Type
                                        </label>
                                        <br />
                                        <select
                                          name="account_record"
                                          className="form-control"
                                          onChange={handleChangetcf}
                                          value={
                                            formData.account_record
                                          }
                                        >
                                          <option>
                                            --Select TCF Record
                                            Type--
                                          </option>
                                          <option value="Buyer - Spot">
                                            Buyer - Spot
                                          </option>
                                          <option value="Buyer - Long Term">
                                            Buyer - Long Term
                                          </option>
                                        </select>
                                      </div>
                                      <input
                                        type="submit"
                                        className="btn btn-primary "
                                        value="Submit"
                                      />
                                    </form>
                                  </Popup>
                                </span>
                              </h4>
                              <hr></hr>
                            </div>
                            <table id="tcf" className="">
                              <thead>
                                <tr>
                                  <th>TCF No</th>
                                  <th>TCF Status</th>
                                  <th>BL Quantity</th>
                                  <th>BL date</th>
                                  <th>Laycan</th>
                                  <th>contract shipment</th>
                                  <th>product</th>
                                  <th>Vessel Name	</th>
                                  <th>Proposed Laycan From	</th>
                                  <th>Proposed Laycan To</th>
                                  <th>Ship Month</th>
                                </tr>
                              </thead>

                              {tcf.length > 0 ? (
                                <tbody>
                                  {tcf.map((y, i) => (
                                    <tr>
                                      <td>
                                        <span id="codes">
                                          <Link
                                            to={`/tcf/tcf-details/${y.id}`}
                                          >
                                            {y.tcf_no}
                                          </Link>
                                        </span>
                                      </td>
                                      <td>{y.tcf_status}</td>
                                      <td>{y.actual_bl_qty}</td>
                                      <td>{y.actual_bl_date}</td>
                                      <td>{y.laycan}</td>
                                      <td><Link to={`/shipment/contract-shipment-details/${y.contract_ship_id}`}>{y.contract_shipment}</Link></td>
                                      <td><Link to={`/product/product-detail/${y.product_id}`}>{y.product}</Link></td>
                                      <td>{y.vessel_name}</td>
                                      <td>{y.proposed_laycan_from}</td>
                                      <td>{y.proposed_laycan_to}</td>
                                      <td>{y.ship_month
                                      }</td>
                                    </tr>
                                  ))}
                                </tbody>
                              ) : (
                                <tbody>
                                  <tr>
                                    <td></td><td></td>
                                    <td></td>
                                    <td></td> <td></td> <td></td>
                                    <td>No data available</td>
                                    <td></td>
                                    <td></td>
                                    <td></td><td></td>
                                  </tr>
                                </tbody>
                              )}
                            </table>
                          </Row>
                          <Row id="table-styles">
                            <div className="col-md-12" id="head">
                              <h4 className="heading">
                                <span>Orders Information </span>
                              </h4>
                              <hr></hr>
                            </div>
                            <table id="orders" className="">
                              <thead>
                                <tr>
                                  <th>Order Number</th>
                                  <th>	Order Start Date</th>
                                  <th>Contract Shipment</th>
                                  <th>Vessel Barge Name	</th>
                                  <th>Ship Month	</th>
                                  <th>Laycan From Date</th>
                                  <th>Status</th>
                                  <th>Shipment Qty</th>
                                  <th>Account Name</th>
                                </tr>
                              </thead>

                              {orderData.length > 0 ? (
                                <tbody>
                                  {orderData.map((y, i) => (
                                    <tr>
                                      <td> <Link
                                        to={`/order/view-order-details/${y.id}`}
                                      >{y.order_no}</Link></td>
                                      <td>{y.order_start_date}</td>
                                      <td><Link to={`/shipment/contract-shipment-details/${y.contract_ship_id}`}>{y.contract_shipment}</Link></td>
                                      <td>{y.vessel_barge_name}</td>
                                      <td>{y.ship_month}</td>
                                      <td>{y.laycan_from_date}</td>
                                      <td>{y.status}</td>
                                      <td>{y.shipment_qty}</td>
                                      <td><Link to={`/accounts/${y.account_id}`}>{y.account_name} </Link></td>

                                    </tr>
                                  ))}
                                </tbody>
                              ) : (
                                <tbody>
                                  <tr><td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>No data available</td>
                                    <td></td>
                                    <td></td>
                                    <td></td><td></td>
                                  </tr>
                                </tbody>
                              )}
                            </table>
                          </Row>
                          <Row id="table-styles">
                            <div className="col-md-12" id="head">
                              <h4 className="heading">
                                <span>Activity History</span>
                                <span>
                                  <i className=""></i>
                                  Log a Call
                                </span>
                                <span>
                                  <i className=""></i>
                                  Mail merge
                                </span>
                              </h4>
                              <hr></hr>
                            </div>
                            <table id="history" className="">
                              <tr>
                                <td>
                                  <p class="no-records">
                                    No records to display
                                  </p>
                                </td>
                              </tr>
                            </table>
                          </Row>

                          <Row id="table-styles">
                            <div className="col-md-12" id="head">
                              <h4 className="heading">
                                <span>Contract History</span>
                              </h4>
                              <hr></hr>
                            </div>
                            <table id="history" className="">
                              <tr>
                                <td>
                                  <p class="no-records">
                                    No records to display
                                  </p>
                                </td>
                              </tr>
                            </table>
                          </Row>
                          <Row id="table-styles">
                            <div className="col-md-12" id="head">
                              <h4 className="heading">
                                <span>Open Activities</span>
                                <span>
                                  <i className=""></i>
                                  New Task
                                </span>
                                <span>
                                  <i className=""></i>
                                  New Event
                                </span>
                              </h4>
                              <hr></hr>
                            </div>
                            <table id="history" className="">
                              <tr>
                                <td>
                                  <p class="no-records">
                                    No records to display
                                  </p>
                                </td>
                              </tr>
                            </table>
                          </Row>

                          <Row id="table-styles">
                            <div className="col-md-12" id="head">
                              <h4 className="heading">
                                <span>Files</span>
                                <span>
                                  <i className=""></i>
                                  Upload Files
                                </span>
                              </h4>
                              <hr></hr>
                            </div>
                            <table id="history" className="">
                              <tr>
                                <td>
                                  <p class="no-records">
                                    No records to display
                                  </p>
                                </td>
                              </tr>
                            </table>
                          </Row>
                          <Row id="table-styles">
                            <div className="col-md-12" id="head">
                              <h4 className="heading">
                                <span>Notes & Attachments</span>
                                <span>
                                  <i className=""></i>
                                  New Note
                                </span>
                                <span>
                                  <i className=""></i>
                                  Attach File
                                </span>
                              </h4>
                              <hr></hr>
                            </div>
                            <table id="history" className="">
                              <tr>
                                <td>
                                  <p class="no-records">
                                    No records to display
                                  </p>
                                </td>
                              </tr>
                            </table>
                          </Row>

                          <Row id="table-styles">
                            <div className="col-md-12" id="head">
                              <h4 className="heading">
                                <span>Contract Clauses</span>
                                <span>
                                  <i className=""></i>
                                  New Contract Clause
                                </span>
                              </h4>
                              <hr></hr>
                            </div>
                            <table id="history" className="">
                              <tr>
                                <td>
                                  <p class="no-records">
                                    No records to display
                                  </p>
                                </td>
                              </tr>
                            </table>
                          </Row>
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {datas.contract_record_type === "Buyer - Spot" ? (
                      <>
                        {isEditMode ? (
                          <div className="tasks">
                            <p className="edit-btn">
                              <input
                                type="submit"
                                className="account-save"
                                onClick={handleSaveEdit}
                                value="Save"
                                //disabled={isSubmitted}
                              />
                              <button
                                className="btn btn-primary"
                                onClick={handleCancelEdit}
                              >
                                Cancel
                              </button>
                            </p>
                            <p className="errors">{errors}</p>
                            <Row className="mb-3">
                              <h4 className="heading">Contract Information</h4>
                              <Form.Group as={Col} md="6">
                            <label>Locked</label>
                            <input
                              type='checkbox'
                              name='locked'
                              tabIndex={1}
                              checked={lockeds} // Use the state variable to determine if the checkbox is checked
                              onChange={handleCheckboxChange} // Handle the change event
                              className='mx-3'
                            />
                          </Form.Group>
                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Contract Owner"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="Contract Owner"
                                    name="contract_owner"
                                    tabIndex={15}
                                    defaultValue={datas.contract_owner || ""}
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                              </Form.Group>
                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingSelect"
                                  className="dropDown"
                                  label="Currency"
                                >
                                  <Form.Select
                                    aria-label="Floating label select example"
                                    name="currency"
                                    placeholder="Currency"
                                    tabIndex={2}
                                    className="error-validation"
                                    value={datas.currency || ""}
                                    onChange={handleChangeInput}
                                  >
                                    <option value="">Select </option>
                                    <option value="USD">USD </option>
                                    <option value="IDR">IDR </option>
                                  </Form.Select>
                                </FloatingLabel>
                              </Form.Group>
                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingSelect"
                                  className="dropDown"
                                  label="Trader Name"
                                >
                                  <Form.Select
                                    aria-label="Floating label select example"
                                    name="trader_name"
                                    placeholder="Trader Name"
                                    tabIndex={16}
                                    value={datas.trader_name || ""}
                                    onChange={handleChangeInput}
                                  >
                                    <option value="">Select </option>
                                    {traderData.map((x, i) => {
                                      return (
                                        <option
                                          key={i}
                                          value={x.rwood_username}
                                        >
                                          {x.rwood_username}
                                        </option>
                                      );
                                    })}
                                  </Form.Select>
                                </FloatingLabel>
                              </Form.Group>
                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Exchange rate"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="Exchange rate "
                                    name="exchange_rate"
                                    tabIndex={3}
                                    defaultValue={datas.exchange_rate || ""}
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                              </Form.Group>
                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingSelect"
                                  className="dropDown"
                                  label="Supplier"
                                >
                                  <Form.Select
                                    aria-label="Floating label select example"
                                    name="supplier"
                                    tabIndex={17}
                                    placeholder="Supplier"
                                    className="error-validation"
                                    value={datas.supplier || ""}
                                    onChange={handleChangeInput}
                                  >
                                    <option value="">Select </option>
                                    {supplierData.map((x) => {
                                      return (
                                        <option value={x.account_name}>
                                          {x.account_name}
                                        </option>
                                      );
                                    })}
                                  </Form.Select>
                                </FloatingLabel>
                              </Form.Group>
                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingSelect"
                                  className="dropDown"
                                  label="Company"
                                >
                                  <Form.Select
                                    aria-label="Floating label select example"
                                    name="company"
                                    placeholder="Company"
                                    className="error-validation"
                                    tabIndex={4}
                                    value={datas.company || ""}
                                    onChange={handleChangeInput}
                                  >
                                    <option value="">Select </option>
                                    {companyData.map((x, i) => {
                                      return (
                                        <option key={i} value={x.company_name}>
                                          {x.company_name}
                                        </option>
                                      );
                                    })}
                                  </Form.Select>
                                </FloatingLabel>
                              </Form.Group>
                              <Form.Group as={Col} md="6">
                                {" "}
                              </Form.Group>
                              {/* <Form.Group as={Col} md="6">

           <FloatingLabel controlId="floatingSelect"
           className='dropDown' label="Price book">
 <Form.Select aria-label="Floating label select example"
 name="price_book"
 placeholder="Price book"
  value={datas.price_book || ""}
onChange={handleChangeInput}
 >
   <option value='' >Select</option>
    {
       priceBook.map((x)=>{
           return(
               <option value={x.price_book_name}>{x.price_book_name}</option>
           )
       })
    }
 </Form.Select>

</FloatingLabel>
           </Form.Group>
      */}

                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Contract record type"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="Contract record type"
                                    tabIndex={5}
                                    name="contract_record_type"
                                    className="record-type"
                                    defaultValue={datas.contract_record_type}
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                              </Form.Group>
                              <Form.Group as={Col} md="6">
                                {" "}
                              </Form.Group>

                              {/* <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingSelect"
               className="dropDown"
               label="Status"
               >
               <Form.Select
               aria-label="Floating label select example"
               name="status"
               placeholder="Status"
               className="error-validation"
               value={datas.status || ""}
               onChange={handleChangeInput}
               >
               <option value="">Select </option>
               <option value="Draft">Draft </option>
               <option value="Approved">Approved </option>
               </Form.Select>
            </FloatingLabel>
         </Form.Group>
                  */}
                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Rwood contract no"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="Rwood contract no"
                                    tabIndex={6}
                                    name="Rwood_Contract_No"
                                    defaultValue={datas.Rwood_Contract_No || ""}
                                    onChange={handleChangeInput}

                                  />
                                </FloatingLabel>
                              </Form.Group>
                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Contract term in months"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="Contract term in months"
                                    name="contract_term_in_months"
                                    tabIndex={18}
                                    defaultValue={
                                      datas.contract_term_in_months || ""
                                    }
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                              </Form.Group>

                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingSelect"
                                  className="dropDown"
                                  label="Account Name"
                                >
                                  <Form.Select
                                    aria-label="Floating label select example"
                                    tabIndex={7}
                                    name="account_name"
                                    placeholder="Account Name"
                                    className="error-validation"
                                    value={datas.account_name || ""}
                                    onChange={handleChangeInput}
                                  >
                                    <option value="">Select </option>
                                    {accountName.map((x) => {
                                      return (
                                        <option value={x.account_name}>
                                          {x.account_name}
                                        </option>
                                      );
                                    })}
                                  </Form.Select>
                                </FloatingLabel>
                              </Form.Group>
                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Contract quantity in MT"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="number"
                                    placeholder="Contract quantity in MT"
                                    tabIndex={19}
                                    name="contract_quantity_in_MT"
                                    className="error-validation"
                                    defaultValue={
                                      datas.contract_quantity_in_MT || ""
                                    }
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                              </Form.Group>

                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingSelect"
                                  className="dropDown"
                                  label="Contract Status"
                                >
                                  <Form.Select
                                    aria-label="Floating label select example"
                                    name="contract_status"
                                    placeholder="Contract Status"
                                    tabIndex={8}
                                    value={datas.contract_status || ""}
                                    onChange={handleChangeInput}
                                  >
                                    <option value="In Approval Process">
                                      In Approval Process
                                    </option>
                                    <option value="Under Review">Under Review</option>
                                    <option value="Activated">Activated</option>
                                    <option value="Draft" selected>Draft</option>
                                    <option value="Under Review">
                                      Under Review
                                    </option>
                                    <option value="Draft Sent for Signing">
                                      Draft Sent for Signing
                                    </option>
                                    <option value="Draft Received for Signing">
                                      Draft Received for Signing
                                    </option>
                                    <option value="Signed Contract yet to Receive">
                                      Signed Contract yet to Receive
                                    </option>
                                    <option value="Signed Contract Sent for Sign">
                                      Signed Contract Sent for Sign
                                    </option>
                                    <option value="Signed Original Contract Need to Courier">
                                      Signed Original Contract Need to Courier
                                    </option>
                                    <option value="Signed Original Contract yet to Receive">
                                      Signed Original Contract yet to Receive
                                    </option>
                                    <option value="Original Contract Signed by Both Parties">
                                      Original Contract Signed by Both Parties
                                    </option>
                                    <option value="Original Contract Signed and Received by Both Parties">
                                      Original Contract Signed and Received by
                                      Both Parties
                                    </option>
                                    <option value="Closed">Closed</option>
                                    <option value="Partially completed">
                                      Partially completed
                                    </option>
                                    <option value="Completed">Completed</option>
                                    <option value="Cancelled">Cancelled</option>
                                    <option value="Partially cancelled">
                                      Partially cancelled
                                    </option>
                                    <option value="Shipments - Arutmin">
                                      Shipments - Arutmin
                                    </option>
                                    <option value="Partially Signed Contract">
                                      Partially Signed Contract
                                    </option>
                                  </Form.Select>
                                </FloatingLabel>
                              </Form.Group>

                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Contract Start Date"
                                  className="mb-3 date-picker"
                                >
                                  <DatePicker
                                    selected={startDate}
                                    tabIndex={20}
                                    onChange={handleStartDateChange}
                                    selectsStart
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="Select"
                                  />
                                </FloatingLabel>
                              </Form.Group>

                              <Form.Group as={Col} md="6" className='textEditor tooltips'>
                                <label>Pending Matter</label>
                                <Editor value={pendingMatter} onChange={onChangePending} tabIndex={9}/>
                              </Form.Group>
                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Contract Effective Date"
                                  className="mb-3 date-picker"
                                >
                                  <DatePicker
                                    selected={contractEffectiveDate}
                                    onChange={handleEndDateChange}
                                    dateFormat="dd/MM/yyyy"
                                    tabIndex={21}
                                    placeholderText="Select Date"
                                    
                                  />
                                </FloatingLabel>
                                <Tooltip text={<span>Contract Effective Date</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                              </Form.Group>

                              <Form.Group as={Col} md="6" className='textEditor tooltips'>
                                          <label>Remark</label>
                                          <Editor value={remarks} onChange={onChangeRemarks} tabIndex={10}/>
                                          <Tooltip text={<span>Any Specific Remark related to Contract</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                                        </Form.Group>
                              <Form.Group as={Col} md="6"></Form.Group>

                              <Form.Group as={Col} md="6" className="tooltips">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Master Contract Reference"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="Master Contract Reference"
                                    name="master_conference_reference"
                                    tabIndex={11}
                                    defaultValue={
                                      datas.master_conference_reference || ""
                                    }
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                                <Tooltip text={<span>Give master contract reference, if any</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                              </Form.Group>
                              <Form.Group as={Col} md="6"></Form.Group>

                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Renewal date"
                                  className="mb-3 date-picker"
                                >
                                  <DatePicker
                                    selected={renewal}
                                    onChange={handleRenewal}
                                    tabIndex={12}
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="Select Date"
                                  />
                                </FloatingLabel>
                              </Form.Group>
                              <Form.Group as={Col} md="6"></Form.Group>

                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Renewal contract number"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="number"
                                    placeholder="Renewal contract number "
                                    name="renewal_contract_number"
                                    tabIndex={13}
                                    defaultValue={
                                      datas.renewal_contract_number || ""
                                    }
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                              </Form.Group>
                              <Form.Group as={Col} md="6"></Form.Group>
                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Contract Number"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="Contract Number"
                                    name="contract_number"
                                    tabIndex={14}
                                    defaultValue={datas.contract_number || ""}
                                    onChange={handleChangeInput} disabled
                                  />
                                </FloatingLabel>
                              </Form.Group>

                              <Form.Group as={Col} md="6"></Form.Group>
                            </Row>
                            <Row className="mb-3">
                              <h4 className="heading">
                                Commodity and Contracted Quantity
                              </h4>
                              <Form.Group as={Col} md="6" className='textEditor'>
                                <label>interlab tolerance</label>
                                <Editor value={interlab} onChange={onChangeInterlab} tabIndex={22}/>
                              </Form.Group>
                              <Form.Group as={Col} md="6"></Form.Group>
                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Contract quantity in MT"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="number"
                                    placeholder="Contract quantity in MT"
                                    name="contract_quantity_in_MT"
                                    className="error-validation"
                                    tabIndex={23}
                                    defaultValue={
                                      datas.contract_quantity_in_MT || ""
                                    }
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                              </Form.Group>
                            </Row>
                            <Row className="mb-3">
                              <h4 className="heading">Market Information</h4>
                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Origin"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="Origin"
                                    name="origin"
                                    tabIndex={24}
                                    defaultValue={datas.origin || ""}
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                              </Form.Group>
                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingSelect"
                                  className="dropDown"
                                  label="Commodity"
                                >
                                  <Form.Select
                                    aria-label="Floating label select example"
                                    name="commodity"
                                    tabIndex={26}
                                    placeholder="Commodity"
                                    value={datas.commodity || ""}
                                    onChange={handleChangeInput}
                                  >
                                    <option>---None---</option>
                                    <option value='Indonesian Steam Coal in Bulk ("Coal")'>
                                      Indonesian Steam Coal in Bulk ("Coal")
                                    </option>
                                    <option value="US Coal">US Coal</option>
                                    <option value="Russia Coal">
                                      Russia Coal
                                    </option>
                                    <option value="SA Coal">SA Coal</option>
                                    <option value="Clinker in Bulk">
                                      Clinker in Bulk
                                    </option>
                                    <option value="Australian Steam Coal">
                                      Australian Steam Coal
                                    </option>
                                  </Form.Select>
                                </FloatingLabel>
                              </Form.Group>

                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="port of loading"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="port of loading"
                                    name="port_of_loading"
                                    tabIndex={25}
                                    defaultValue={datas.port_of_loading || ""}
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                              </Form.Group>
                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Destination"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="text"
                                    tabIndex={27}
                                    placeholder="Destination"
                                    name="destination"
                                    defaultValue={datas.destination || ""}
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                              </Form.Group>
                            </Row>
                            <Row className="mb-3">
                              <h4 className="heading">
                                Price and Price Adjustment
                              </h4>

                              <Form.Group as={Col} md="6" className='textEditor'>
                                <label>Price  Adjustment Clause</label>
                                <Editor value={priceAdjust} onChange={onChangePricingAdjust} tabIndex={28}/>
                              </Form.Group>
                              <Form.Group as={Col} md="6"></Form.Group>
                              <Form.Group as={Col} md="6" className='textEditor'>
                                <label>Bonus penalty clause</label>
                                <Editor value={bonus} onChange={onChangeBonus} tabIndex={29}/>

                              </Form.Group>

                              <Form.Group as={Col} md="6"></Form.Group>
                              <Form.Group as={Col} md="6" className='textEditor'>
                                <label>Rejections</label>
                                <Editor value={rejections} onChange={onChangeRejections} tabIndex={30}/>
                              </Form.Group>
                              <Form.Group as={Col} md="6"></Form.Group>
                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Hs code"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="Hs code"
                                    tabIndex={31}
                                    name="hs_code"
                                    defaultValue={datas.hs_code || ""}
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                              </Form.Group>
                              <Form.Group as={Col} md="6"></Form.Group>
                              <Form.Group as={Col} md="6" className='textEditor'>
                                <label>Pricing Terms</label>
                                <Editor value={pricingTerm} onChange={onChangePricingTerm} tabIndex={32}/>
                              </Form.Group>
                              <Form.Group as={Col} md="6"></Form.Group>
                            </Row>
                            <Row className="mb-3">
                              <h4 className="heading">Payment Information</h4>
                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingSelect"
                                  className="dropDown"
                                  label="LC Type"
                                >
                                  <Form.Select
                                    aria-label="Floating label select example"
                                    name="lc_type"
                                    tabIndex={33}
                                    placeholder="LC Type"
                                    value={datas.lc_type || ""}
                                    onChange={handleChangeInput}
                                  >
                                    <option value="">Select</option>
                                    <option value="">--None--</option>
                                    <option value="Irrevocable transferrable Letter of Credit">
                                      Irrevocable transferrable Letter of Credit
                                    </option>
                                    <option value="Irrevocable Non transferrable Letter of Credit">
                                      Irrevocable Non transferrable Letter of
                                      Credit
                                    </option>
                                    <option value="Irrevocable Letter of Credit">
                                      Irrevocable Letter of Credit
                                    </option>
                                    <option value="Irrevocable Back to Back LC">
                                      Irrevocable Back to Back LC
                                    </option>
                                    <option value="Payment and Receipt">
                                      Payment and Receipt
                                    </option>
                                    <option value="Standby_letter_of_credit_c">
                                      Standby Letter of Credit
                                    </option>
                                  </Form.Select>
                                </FloatingLabel>
                              </Form.Group>
                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Master lc presentation days"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="Master lc presentation days"
                                    name="master_lc_presentation_days"
                                    tabIndex={39}
                                    defaultValue={
                                      datas.master_lc_presentation_days || ""
                                    }
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                              </Form.Group>
                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingSelect"
                                  className="dropDown"
                                  label="Payment Details"
                                >
                                  <Form.Select
                                    aria-label="Floating label select example"
                                    name="payment_details"
                                    placeholder="Payment Details"
                                    tabIndex={34}
                                    value={datas.payment_details || ""}
                                    onChange={handleChangeInput}
                                  >
                                    <option value="">--None--</option><option value="AT SIGHT">AT SIGHT</option><option value="Usance">Usance</option><option value="Telegraphic Transfer">Telegraphic Transfer</option>
                                  </Form.Select>
                                </FloatingLabel>
                              </Form.Group>

                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Lc opening days"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="number"
                                    placeholder="Lc opening days"
                                    name="lc_opening_days"
                                    tabIndex={40}
                                    defaultValue={datas.lc_opening_days || ""}
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                              </Form.Group>
                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Master lc tolerance (%)"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="number"
                                    tabIndex={35}
                                    placeholder="Master lc tolerance (%)"
                                    name="master_lc_tolerance"
                                    defaultValue={
                                      datas.master_lc_tolerance || ""
                                    }
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                              </Form.Group>

                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Lc opening days in words"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="Lc opening days in words"
                                    tabIndex={41}
                                    name="lc_opening_days_in_words"
                                    defaultValue={
                                      datas.lc_opening_days_in_words || ""
                                    }
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                              </Form.Group>
                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Lc validity no of days"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="number"
                                    tabIndex={36}
                                    placeholder="Lc validity no of days"
                                    name="lc_validity_no_of_days"
                                    defaultValue={
                                      datas.lc_validity_no_of_days || ""
                                    }
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                              </Form.Group>
                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Document presentation period"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="Document presentation period"
                                    name="document_presentation_period"
                                    tabIndex={42}
                                    defaultValue={
                                      datas.document_presentation_period || ""
                                    }
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                              </Form.Group>
                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Loading rate per day"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="Loading rate per day"
                                    name="loading_rate_per_day"
                                    tabIndex={37}
                                    defaultValue={
                                      datas.loading_rate_per_day || ""
                                    }
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                              </Form.Group>
                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Work Days for time to copy doc to Buyer	"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="Work Days for time to copy doc to Buyer	"
                                    name="work_days_for_time_to_copy_doc_to_buyer"
                                    tabIndex={43}
                                    defaultValue={
                                      datas.work_days_for_time_to_copy_doc_to_buyer ||
                                      ""
                                    }
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                              </Form.Group>

                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Latest date of shipment"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="Latest date of shipment"
                                    name="latest_date_of_shipment"
                                    tabIndex={38}
                                    defaultValue={
                                      datas.latest_date_of_shipment || ""
                                    }
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                              </Form.Group>
                            </Row>
                            <Row className="mb-3">
                              <h4 className="heading">
                                Important Terms Information
                              </h4>
                              
                              <Form.Group as={Col} md="12" className='lists'>
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="List of documents required"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    as="textarea"
                                    tabIndex={44}
                                    style={{ height: "200px" }} // removed "!important" as it's not needed here
                                    placeholder="List of documents required"
                                    name="list_of_documents_required"
                                    defaultValue={document.map((x, index) => `${index + 1}. ${x.list_of_docuemnts}`).join('\n')}
                                    onChange={handleChangeInput} // assuming handleChangeInput is defined elsewhere
                                  />
                                </FloatingLabel>
                              </Form.Group>

                            </Row>
                            <Row className="mb-3">
                              <h4 className="heading">Signature Information</h4>
                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingSelect"
                                  className="dropDown"
                                  label="Kind attention"
                                >
                                  <Form.Select
                                    aria-label="Floating label select example"
                                    name="kind_attention"
                                    tabIndex={45}
                                    placeholder="Kind attention"
                                    defaultValue={datas.kind_attention || ""}
                                    onChange={handleChangeInput}
                                  >
                                    <option value="">Select </option>
                                    {contacts.map((x) => {
                                      return (
                                        <option value={x.contact_full_name}>
                                          {x.contact_full_name}
                                        </option>
                                      );
                                    })}
                                  </Form.Select>
                                </FloatingLabel>
                              </Form.Group>
                              <Form.Group as={Col} md="6"> </Form.Group>

                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingSelect"
                                  className="dropDown"
                                  label="Customer signed by"
                                >
                                  <Form.Select
                                    aria-label="Floating label select example"
                                    name="customer_signed_by"
                                    tabIndex={46}
                                    placeholder="Customer signed by"
                                    value={datas.customer_signed_by || ""}
                                    onChange={handleChangeInput}
                                  >
                                    <option value="">Select </option>
                                    {contacts.map((x) => {
                                      return (
                                        <option value={x.contact_full_name}>
                                          {x.contact_full_name}
                                        </option>
                                      );
                                    })}
                                  </Form.Select>
                                </FloatingLabel>
                              </Form.Group><Form.Group as={Col} md="6"></Form.Group>
                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Customer signed title"

                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="text"
                                    tabIndex={47}
                                    placeholder="Customer signed title"
                                    name="customer_signed_title"
                                    defaultValue={
                                      datas.customer_signed_title || ""
                                    }
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                              </Form.Group>
                              <Form.Group as={Col} md="6"></Form.Group>
                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Customer Signed Date"
                                  className="mb-3 date-picker"
                                >
                                  <DatePicker
                                    selected={customersigned}
                                    onChange={handleCustomerSignedDate}
                                    tabIndex={48}
                                    selectsStart
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="Select"
                                  />
                                </FloatingLabel>
                              </Form.Group>
                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingSelect"
                                  className="dropDown"
                                  label="Company signed by"
                                >
                                  <Form.Select
                                    aria-label="Floating label select example"
                                    name="company_signed_by"
                                    tabIndex={50}
                                    placeholder="Company signed by"
                                    value={datas.company_signed_by || ""}
                                    onChange={handleChangeInput}
                                  >
                                    <option value="">Select </option>{" "}
                                    {users && users.length > 0 && users.map((x) => {
                                      // Check if the username is not null or an empty string
                                      if (x.username !== null && x.username !== "") {
                                        return (
                                          <option key={x.username} value={x.username}>
                                            {x.username}
                                          </option>
                                        );
                                      } else {
                                        return null; // Skip rendering if username is null or empty
                                      }
                                    })}

                                  </Form.Select>
                                </FloatingLabel>
                              </Form.Group>
                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Original Contract Recieved Date"
                                  className="mb-3 date-picker"
                                >
                                  <DatePicker
                                    selected={originals}
                                    onChange={handleOriginal}
                                    dateFormat="dd/MM/yyyy"
                                    tabIndex={49}
                                    placeholderText="Select Date"
                                  />
                                </FloatingLabel>
                              </Form.Group>
                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Company Signed Date"
                                  className="mb-3 date-picker"
                                >
                                  <DatePicker
                                    selected={signed}
                                    onChange={handleSigned}
                                    dateFormat="dd/MM/yyyy"
                                    tabIndex={51}
                                    placeholderText="Select Date"
                                  />
                                </FloatingLabel>
                              </Form.Group>
                              <Form.Group as={Col} md="6"></Form.Group>
                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Original contract sent date"
                                  className="mb-3 date-picker"
                                >
                                  <DatePicker
                                    selected={originalcontract}
                                    onChange={handleOriginalcontract}
                                    tabIndex={52}
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="Select Date"
                                  />
                                </FloatingLabel>
                              </Form.Group>
                            </Row>
                            <Row className="mb-3">
                              <h4 className="heading">Other Standard Terms</h4>
                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingSelect"
                                  className="dropDown"
                                  label="Applicable Law"
                                >
                                  <Form.Select
                                    aria-label="Floating label select example"
                                    name="applicable_law"
                                    tabIndex={53}
                                    placeholder="Applicable Law"
                                    value={datas.applicable_law || ""}
                                    onChange={handleChangeInput}
                                  >
                                    <option value="">--None--</option>
                                    <option value="English Law">
                                      English Law
                                    </option>
                                    <option value="Singapore Law">
                                      Singapore Law
                                    </option>
                                    <option value="Indonesian Law">
                                      Indonesian Law
                                    </option>
                                  </Form.Select>
                                </FloatingLabel>
                              </Form.Group>
                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingSelect"
                                  className="dropDown"
                                  label="Rwood surveryor agent 1"
                                >
                                  <Form.Select
                                    aria-label="Floating label select example"
                                    name="rwood_surveryor_agent"
                                    tabIndex={55}
                                    placeholder="Rwood Surveryor Agent 1
"
                                    value={datas.rwood_surveryor_agent || ""}
                                    onChange={handleChangeInput}
                                  >
                                    <option value="">Select </option>
                                    {surveyors.map((x) => {
                                      return (
                                        <option value={x.account_name}>
                                          {x.account_name}
                                        </option>
                                      );
                                    })}
                                  </Form.Select>
                                </FloatingLabel>
                              </Form.Group>
                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Arbitration"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="Arbitration"
                                    name="arbitration"
                                    tabIndex={54}
                                    defaultValue={datas.arbitration || ""}
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                              </Form.Group>

                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingSelect"
                                  className="dropDown"
                                  label="Rwood Surveryor Agent 2"
                                >
                                  <Form.Select
                                    aria-label="Floating label select example"
                                    name="surveyor_2"
                                    placeholder="Rwood Surveryor Agent 2"
                                    tabIndex={56}
                                    value={datas.surveyor_2 || ""}
                                    onChange={handleChangeInput}
                                  >
                                    <option value="">Select </option>
                                    {surveyors.map((x) => {
                                      return (
                                        <option value={x.account_name}>
                                          {x.account_name}
                                        </option>
                                      );
                                    })}
                                  </Form.Select>
                                </FloatingLabel>
                              </Form.Group>
                              <Form.Group as={Col} md="6"></Form.Group>
                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingSelect"
                                  className="dropDown"
                                  label="Rwood Surveryor Agent 3"
                                >
                                  <Form.Select
                                    aria-label="Floating label select example"
                                    name="surveyor_3"
                                    placeholder="Rwood Surveryor Agent 3"
                                    tabIndex={57}
                                    value={datas.surveyor_3 || ""}
                                    onChange={handleChangeInput}
                                  >
                                    <option value="">Select </option>
                                    {surveyors.map((x) => {
                                      return (
                                        <option value={x.account_name}>
                                          {x.account_name}
                                        </option>
                                      );
                                    })}
                                  </Form.Select>
                                </FloatingLabel>
                              </Form.Group>
                            </Row>

                            {/* <Row className="mb-3">
                              <h4 className="heading">
                                List Of Fields-Not Required
                              </h4>

                              <Form.Group as={Col} md="6" className='textEditor'>
                                <label>Product Description</label>
                                <Editor value={productdesc} onChange={onChangeProductDesc} />
                              </Form.Group>
                              <Form.Group as={Col} md="6"></Form.Group>
                              <Form.Group as={Col} md="6" className='textEditor'>
                                <label>Description</label>
                                <Editor value={description_1} onChange={onChangeDescription} />
                              </Form.Group>
                              <Form.Group as={Col} md="6"></Form.Group>
                            </Row> */}
                            <p className="edit-btn">
                              <input
                                type="submit"
                                className="account-save"
                                onClick={handleSaveEdit}
                                value="Save"
                                //disabled={isSubmitted}
                              />
                              <button
                                className="btn btn-primary"
                                onClick={handleCancelEdit}
                              >
                                Cancel
                              </button>
                            </p>
                          </div>
                        ) : (
                          <>
                            <div className="contactAccount fulid-section">
                              <div className="tasks">
                                <p className="link-texts"><span><a href="#list-document">List of Documents</a> | </span><span><a href="#contract-product">Contract Products </a>|</span> <span><a href="#contract-ship">Contract Shipments </a>| </span><span>Products (Buyer Contract) |</span><span> <a href="#opportunity">Opportunities </a>|</span><span> <a href='#quotes'>Quotes  </a>|</span> <span><a href="#tcf">Trade Confirmation Forms </a>| </span><span><a href="#orders">Orders </a>|</span><span> Activity History | </span><span>Contract History  |</span></p>
                                <div className="edit-delete">
                                  <span id="edit" onClick={handleEditClick}>
                                    Edit
                                  </span>
                                  <span>
                                    <Link to={"/contract/create-contract-list"}>
                                      Cancel
                                    </Link>
                                  </span>
                                  <span>Activate</span>
                                  <span>Generate Contract</span>
                                  <span>Generate Contract Clauses</span>
                                  <span>Shipment Checklist</span>
                                </div>
                                <div className="">
                                  <div className="row">
                                    <div className="col-md-12" id="head">
                                      <h4 className="heading">
                                        Contract Details
                                      </h4>

                                      <hr></hr>
                                    </div>
                                  </div>
                                  <table class="table table-bordered account-table tables">
                                    <tbody>
                                      <tr>
                                        <td id="td-right">
                                          {/* <span>rwood contract No</span> */}
                                        </td>
                                        <td>
                                          {/* {datas.Rwood_Contract_No} */}
                                          </td>
                                        <td id="td-right">
                                          <span>Locked</span>{" "}
                                        </td>
                                        <td> <input
                                          type='checkbox'
                                          name='locked'
                                          checked={lockeds} // Use the state variable to determine if the checkbox is checked
                                          onChange={handleCheckboxChange} // Handle the change event
                                          className='mx-3' disabled={isButtonEnabled}
                                        /></td>

                                      </tr>
                                      <tr>
                                        <td id="td-right">
                                          <span>Currency</span>
                                        </td>
                                        <td>{datas.currency}</td>
                                        <td id="td-right">
                                          <span>Trader Name</span>
                                        </td>
                                        <td>
                                          {datas.trader_name !== null && datas.trader_name !== "" ? (
                                            <Link to={"/user/user-details/" + datas.rwood_user_id}>
                                              {datas.trader_name}
                                            </Link>
                                          ) : (
                                            <span>{datas.trader_name}</span>
                                          )}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td id="td-right">
                                          <span>Exchange Rate</span>
                                        </td>
                                        <td>{datas.exchange_rate}</td>
                                        <td id="td-right">
                                          <span>Supplier</span>
                                        </td>
                                        <td><Link to={`/accounts/${datas.supplier_id}`}>{datas.supplier}</Link></td>
                                      </tr>
                                      <tr>
                                        <td id="td-right">
                                          <span>Company</span>
                                        </td>
                                        <td>
                                          <Link
                                            to={`/company/company-details/${datas.rwood_company_id}`}
                                          >
                                            {datas.company}
                                          </Link>
                                        </td>
                                        <td id="td-right">
                                          <span>Contract Owner</span>
                                        </td>
                                        <td>{datas.contract_owner}</td>
                                      </tr>
                                      <tr>
                                        <td id="td-right">
                                          <span>Contract record type</span>
                                        </td>
                                        <td>{datas.contract_record_type}</td>
                                        <td id="td-right">
                                          {/* <span>Status</span> */}
                                        </td>
                                        <td>{/* {datas.status} */}</td>
                                      </tr>
                                      <tr>
                                        <td id="td-right">
                                          <span>Contract Number</span>
                                        </td>
                                        <td><Link to={`/contract/contract-details/${datas.id}`}>{datas.contract_number}</Link></td>
                                        <td id="td-right">
                                          <span>Contract term in months</span>
                                        </td>
                                        <td>{datas.contract_term_in_months}</td>
                                      </tr>
                                      <tr>
                                        <td id="td-right">
                                          <span>Account Name</span>
                                        </td>
                                        <td>
                                          <Link
                                            to={"/accounts/" + datas.account_id}
                                          >
                                            {" "}
                                            {datas.account_name}{" "}
                                          </Link>
                                        </td>
                                        <td id="td-right">
                                          <span>Contract Quantity in MT</span>
                                        </td>
                                        <td>{renderShipmentQty(datas.contract_quantity_in_MT)}</td>
                                      </tr>
                                      <tr>
                                        <td id="td-right">
                                          <span>Buyer Billing Address
                                          </span>
                                        </td>
                                        <td>
                                          {datas.billing_address}{" "}
                                        </td>

                                        <td id="td-right">
                                          <span>Contract Start Date</span>
                                        </td>
                                        <td>
                                          {datas.contract_start_date}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td id="td-right">
                                          <span>Contract Status</span>
                                        </td>
                                        <td>{datas.contract_status}</td>

                                        <td id="td-right">
                                          <span>Contract End Date</span>
                                        </td>
                                        <td>{datas.contract_end_date}</td>
                                      </tr>
                                      <tr>
                                        <td id="td-right">
                                          <span>Pending Matters</span>
                                        </td>
                                        <td><div dangerouslySetInnerHTML={{ __html: datas.pending_matters }} /></td>

                                        <td id="td-right">
                                          <span className="a-a">
                                      Contract Effective Date{" "}
                                                <Tooltip text={<span>Contract Effective Date</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                                              </span>
                                        </td>
                                        <td>{datas.contract_effective_date}</td>
                                      </tr>

                                            <tr>
                                            <td id="td-right">
                                              <span>Rwood contract no</span>
                                            </td>
                                            <td> <Link to={`/contract/contract-details/${datas.id}`}>{datas.Rwood_Contract_No}</Link> </td>

                                        <td id="td-right"></td>
                                        <td></td>
                                      </tr>


                                       <tr>
                                        <td id="td-right">
                                          <span className="a-a">
                                          Remark{" "}
                                                <Tooltip text={<span>Any Specific Remark related to Contract</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                                              </span>
                                        </td>
                                        <td><div dangerouslySetInnerHTML={{ __html: datas.remark }} /></td>

                                        <td id="td-right"></td>
                                        <td></td>
                                      </tr>
                                      <tr>
                                        <td id="td-right">
                                          <span>Renewal contract number</span>
                                        </td>
                                        <td>{datas.renewal_contract_number}</td>
                                        <td id="td-right">

                                        <span className="a-a">
                                          Master Contract Reference{" "}
                                                <Tooltip text={<span>Give master contract reference, if any</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                                              </span>
                                        </td>
                                        <td>{datas.master_conference_reference}</td>

                                      </tr>
                                      <tr>

                                        <td id="td-right">
                                          <span>Renewal Date</span>
                                        </td>
                                        <td>{datas.renewal_date}</td>

                                        <td id="td-right">
                                          {/* <span>Contract Number</span> */}
                                        </td>
                                        <td>
                                          {/* {datas.contract_number} */}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                                <div className="">
                                  <div className="row">
                                    <div className="col-md-12" id="head">
                                      <h4 className="heading">
                                        Current Status
                                      </h4>
                                      <hr></hr>
                                    </div>
                                  </div>
                                  <table class="table table-bordered account-table tables">
                                    <tbody>
                                      <tr>
                                        <td id="td-right">
                                        <span className="a-a">
                                          No of Shipments{" "}
                                                <Tooltip text={<span>Roll of No of shipments (Contract Shipments)</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                                              </span>
                                        </td>
                                        <td> {renderShipmentQty(datas.no_of_shipments)} </td>
                                        <td id="td-right">
                                          <span>Number of orders</span>
                                        </td>
                                        <td> {renderShipmentQty(datas.no_of_orders)} </td>
                                      </tr>
                                      <tr>
                                        <td id="td-right">
                                          <span>order quantity</span>
                                        </td>
                                        <td> {renderShipmentQty(datas.order_quantity)} </td>
                                        <td id="td-right">
                                          {/* <span>Number of orders	 */}
                                          {/* </span> */}
                                        </td>
                                        {/* <td> {renderShipmentQty(datas.no_of_orders)} </td> */}
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                                <div className="">
                                  <div className="row">
                                    <div className="col-md-12" id="head">
                                      <h4 className="heading">
                                        Commodity and Contracted Quantity
                                      </h4>
                                      <hr></hr>
                                    </div>
                                  </div>
                                  <table class="table table-bordered account-table tables">
                                    <tbody>

                                      <tr>
                                        <td id="td-right">
                                          <span>Interlab Tolerance</span>
                                        </td>
                                        <td><div dangerouslySetInnerHTML={{ __html: datas.interlab_tolerance }} /></td>
                                        <td id="td-right"></td>
                                        <td></td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>

                                <div className="">
                                  <div className="row">
                                    <div className="col-md-12" id="head">
                                      <h4 className="heading">
                                        Market Informatione
                                      </h4>
                                      <hr></hr>
                                    </div>
                                  </div>
                                  <table class="table table-bordered account-table tables">
                                    <tbody>
                                      <tr>
                                        <td id="td-right">
                                          <span>Origin</span>
                                        </td>
                                        <td>{datas.origin}</td>
                                        <td id="td-right"></td>
                                        <td></td>
                                      </tr>

                                      <tr>
                                        <td id="td-right">
                                          <span>Commodity</span>
                                        </td>
                                        <td>{datas.commodity}</td>
                                        <td id="td-right"></td>
                                        <td></td>
                                      </tr>
                                      <tr>
                                        <td id="td-right">
                                          <span>port of loading</span>
                                        </td>
                                        <td>{datas.port_of_loading}</td>
                                        <td id="td-right">
                                          {/* <span>Destination</span> */}
                                        </td>
                                        <td>{/* {datas.destination} */}</td>
                                      </tr>
                                      <tr>
                                        <td id="td-right">
                                          <span>Destination</span>
                                        </td>
                                        <td>{datas.destination}</td>
                                        <td id="td-right">
                                          {/* <span>Pricing Terms</span> */}
                                        </td>
                                        <td></td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                                <div className="">
                                  <div className="row">
                                    <div className="col-md-12" id="head">
                                      <h4 className="heading">
                                        Price and Price Adjustment
                                      </h4>
                                      <hr></hr>
                                    </div>
                                  </div>
                                  <table class="table table-bordered account-table tables">
                                    <tbody>
                                      <tr>
                                        <td id="td-right">
                                          <span>Price adjustment clause</span>
                                        </td>
                                        <td><div dangerouslySetInnerHTML={{ __html: datas.price_adjustment_clause }} /></td>
                                        <td id="td-right">
                                          {/* <span>Bonus penalty clause</span> */}
                                        </td>
                                        <td>
                                          {/* <div dangerouslySetInnerHTML={{ __html: datas.bonus_penalty_clause }} /> */}
                                        </td>
                                      </tr>

                                      <tr>
                                        <td id="td-right">
                                          <span>Bonus penalty clause</span>
                                        </td>
                                        <td><div dangerouslySetInnerHTML={{ __html: datas.bonus_penalty_clause }} /></td>
                                        <td id="td-right">
                                          <span></span>
                                        </td>
                                        <td></td>
                                      </tr>

                                      <tr>
                                        <td id="td-right">
                                          <span>Rejections</span>
                                        </td>
                                        <td><div dangerouslySetInnerHTML={{ __html: datas.rejections }} /></td>
                                        <td id="td-right"></td>
                                        <td></td>
                                      </tr>

                                      <tr>
                                        <td id="td-right">
                                          <span>HS code</span>
                                        </td>
                                        <td>{datas.hs_code}</td>
                                        <td id="td-right"></td>
                                        <td></td>
                                      </tr>

                                      <tr>
                                        <td id="td-right">
                                          <span>Pricing Term</span>
                                        </td>
                                        <td><div dangerouslySetInnerHTML={{ __html: datas.pricing_terms }} /></td>
                                        <td id="td-right"></td>
                                        <td></td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>

                                <div className="">
                                  <div className="row">
                                    <div className="col-md-12" id="head">
                                      <h4 className="heading">
                                        Payment Information
                                      </h4>
                                      <hr></hr>
                                    </div>
                                  </div>
                                  <table class="table table-bordered account-table tables">
                                    <tbody>
                                      <tr>
                                        <td id="td-right">
                                          <span>LC Type</span>
                                        </td>
                                        <td>{datas.lc_type}</td>
                                        <td id="td-right">
                                          <span>
                                            Master lc presentation days
                                          </span>
                                        </td>
                                        <td>
                                          {datas.master_lc_presentation_days}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td id="td-right">
                                          <span>Payment Details </span>
                                        </td>
                                        <td>{datas.payment_details}</td>
                                        <td id="td-right">
                                          <span>Lc opening days</span>
                                        </td>
                                        <td>{datas.lc_opening_days}</td>
                                      </tr>

                                      <tr>
                                        <td id="td-right">
                                          <span>Master lc tolerance</span>
                                        </td>
                                        <td>{datas.master_lc_tolerance} <span>%</span></td>
                                        <td id="td-right">
                                          <span>Lc opening days in words</span>
                                        </td>
                                        <td>
                                          {datas.lc_opening_days_in_words}
                                        </td>
                                      </tr>

                                      <tr>
                                        <td id="td-right">
                                          <span>Total LC Value</span>
                                        </td>
                                        <td>{datas.total_lc_value}<span>%</span></td>
                                        <td id="td-right">
                                          <span>
                                            Document presentation period
                                          </span>
                                        </td>
                                        <td>
                                          {datas.document_presentation_period}
                                        </td>
                                      </tr>

                                      <tr>
                                        <td id="td-right">
                                          <span>Lc validity no of days</span>
                                        </td>
                                        <td>{datas.lc_validity_no_of_days}</td>
                                        <td id="td-right">
                                          {/* <span>Document presentation period</span> */}
                                        </td>
                                        <td>
                                          {/* {datas.document_presentation_period} */}
                                        </td>
                                      </tr>

                                      <tr>
                                        <td id="td-right">
                                          {/* <span>Document presentation period</span> */}
                                        </td>
                                        <td>
                                          {/* {datas.document_presentation_period} */}
                                        </td>
                                        <td id="td-right">
                                          <span>
                                            Work Days for time to copy doc to
                                            Buyer{" "}
                                          </span>
                                        </td>
                                        <td>
                                          {
                                            datas.work_days_for_time_to_copy_doc_to_buyer
                                          }
                                        </td>
                                      </tr>

                                      <tr>
                                        <td id="td-right">
                                          <span>Latest date of shipment</span>
                                        </td>
                                        <td>{datas.latest_date_of_shipment}</td>
                                        <td id="td-right"></td>
                                        <td></td>
                                      </tr>

                                      <tr>
                                        <td id="td-right">
                                        <span className="a-a">
                                        Loading Rate Per Day{" "}
                                                <Tooltip text={<span>Loading Rate /Discharge Rate</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                                              </span>
                                        </td>
                                        <td>{datas.loading_rate_per_day}</td>
                                        <td id="td-right"></td>
                                        <td></td>
                                      </tr>

                                      <tr>
                                        <td id="td-right">
                                          <span>Latest Date of Shipment </span>
                                        </td>
                                        <td>{datas.latest_date_of_shipment}</td>
                                        <td id="td-right"></td>
                                        <td></td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                                <div className="">
                                  <div className="row">
                                    <div className="col-md-12" id="head">
                                      <h4 className="heading">
                                        Important Terms Information
                                      </h4>
                                      <hr></hr>
                                    </div>
                                  </div>
                                  <table class="table table-bordered account-table tables">
                                    <tbody>
                                      <tr>
                                        <td id="td-right">
                                          <span>
                                            List of documents required
                                          </span>
                                        </td>
                                        <td style={{ width: '75%' }}>
                                          <ol>
                                            {document.map((x) => {
                                              console.log(x); // Log the entire object to inspect its structure and properties
                                              return (
                                                <li key={x.id}>
                                                  {`${x.list_of_docuemnts} in ${numberToText(x.original_in_no)} ${x.original_in_no} Originals and ${numberToText(x.copies_in_no)} ${x.copies_in_no} Copies  ${x.suffix}.`}
                                                </li>
                                              );
                                            })}
                                          </ol>
                                        </td>
                                        <td></td>
                                        <td></td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>

                                <div className="">
                                  <div className="row">
                                    <div className="col-md-12" id="head">
                                      <h4 className="heading">
                                        Signature Information
                                      </h4>
                                      <hr></hr>
                                    </div>
                                  </div>
                                  <table class="table table-bordered account-table tables">
                                    <tbody>

                                      <tr>
                                        <td id="td-right">
                                          <span>kind attention</span>
                                        </td>
                                        <td>{datas.kind_attention}</td>
                                        <td id="td-right">
                                          {/* <span>Customer signed by</span> */}
                                        </td>
                                        <td>
                                          {/* {datas.customer_signed_by} */}
                                        </td>
                                      </tr>

                                      <tr>
                                        <td id="td-right">
                                          {/* <span>Customer signed by</span> */}
                                        </td>
                                        <td>
                                          {/* {datas.customer_signed_by} */}
                                        </td>
                                        <td id="td-right">
                                          {/* <span>Company signed by</span> */}
                                        </td>
                                        <td>
                                          {/* {datas.company_signed_by} */}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td id="td-right">
                                          <span>Customer signed title</span>
                                        </td>
                                        <td>
                                          {datas.customer_signed_title}
                                        </td>
                                        <td id="td-right">
                                          {/* <span>Company signed by</span> */}
                                        </td>
                                        <td>
                                          {/* {datas.company_signed_by} */}
                                        </td>
                                      </tr>

                                      <tr>
                                        <td id="td-right">
                                          <span>Customer signed date</span>
                                        </td>
                                        <td>{datas.customer_signed_date}</td>

                                        <td id="td-right">
                                          <span>company signed by</span>
                                        </td>
                                        <td>

                                          {datas.company_signed_by}
                                        </td>

                                      </tr>

                                      <tr>
                                        <td id="td-right">
                                          <span>Customer signed by</span>
                                        </td>
                                        <td>
                                          {datas.original_contract_recieved_date}
                                        </td>
                                        <td id="td-right">
                                          <span>company signed date</span>
                                        </td>
                                        <td>{datas.company_signed_date}</td>




                                      </tr>
                                      <tr>
                                        <td id="td-right"></td>
                                        <td></td>

                                        <td id="td-right">
                                          <span>
                                            {/* Original contract sent date */}
                                          </span>
                                        </td>
                                        <td>
                                          {/* {datas.original_contract_sent_date} */}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>

                                <div className="">
                                  <div className="row">
                                    <div className="col-md-12" id="head">
                                      <h4 className="heading">
                                        Other Standard Terms
                                      </h4>
                                      <hr></hr>
                                    </div>
                                  </div>
                                  <table class="table table-bordered account-table tables">
                                    <tbody>
                                      <tr>
                                        <td id="td-right">
                                          <span>Applicable law</span>
                                        </td>
                                        <td>{datas.applicable_law}</td>
                                        <td id="td-right">
                                          <span>Rwood Surveryor Agent 1</span>
                                        </td>
                                        <td>{datas.rwood_surveryor_agent}</td>
                                      </tr>
                                      <tr>
                                        <td id="td-right">
                                          <span>Arbitration</span>
                                        </td>
                                        <td>{datas.arbitration}</td>
                                        <td id="td-right">
                                          <span>Rwood Surveryor Agent 2</span>
                                        </td>
                                        <td>{datas.surveyor_2}</td>
                                      </tr>

                                      <tr>
                                        <td id="td-right"></td>
                                        <td></td>
                                        <td id="td-right">
                                          <span>Rwood Surveryor Agent 3</span>
                                        </td>
                                        <td>{datas.surveyor_3}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                                <div className="">
                                      <div className="row">
                                        <div className="col-md-12" id="head">
                                          <h4 className="heading">
                                            System Information
                                          </h4>
                                          <hr></hr>
                                        </div>
                                      </div>
                                      <table class="table table-bordered account-table tables">
                                        <tbody>

                                          <tr>
                                            <td id="td-right">
                                              <span>Activated By
                                              </span>
                                            </td>
                                            <td>{datas.activated_by
                                            }</td>
                                            <td id="td-right">
                                              <span>Activated Date
                                              </span>
                                            </td>
                                            <td>{datas.activated_date
                                            }</td>
                                          </tr>
                                          <tr>
                                            <td id="td-right">
                                              <span>Created By
                                              </span>
                                            </td>
                                            <td>
                                              {datas.created_by
                                              }
                                            </td>

                                            <td id="td-right">
                                              <span>Last Modified By
                                              </span>
                                            </td>
                                            <td>{datas.last_modified_by
                                            }</td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
{/*
                                <div className="">
                                  <div className="row">
                                    <div className="col-md-12" id="head">
                                      <h4 className="heading">
                                        List Of Fields-Not Required
                                      </h4>
                                      <hr></hr>
                                    </div>
                                  </div>
                                  <table class="table table-bordered account-table tables">
                                    <tbody>
                                      <tr>
                                        <td id="td-right">
                                          <span>Product Description</span>
                                        </td>
                                        <td><div dangerouslySetInnerHTML={{ __html: datas.product_description }} /></td>

                                        <td id="td-right"></td>
                                        <td></td>
                                      </tr>
                                      <tr>
                                        <td id="td-right">
                                          <span>Description</span>
                                        </td>
                                        <td><div dangerouslySetInnerHTML={{ __html: datas.description }} /></td>
                                        <td id="td-right"></td>
                                        <td></td>
                                      </tr>
                                      <tr>
                                        <td id="td-right">
                                          <span>Created By</span>
                                        </td>
                                        <td>{datas.created_by}</td>
                                        <td id="td-right">
                                          <span>Last Modified By
                                          </span>
                                        </td>
                                        <td>{datas.last_modified_by}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div> */}
                              </div>
                            </div>

                            <div
                              className="tasks view-table"
                              Style="box-showdow:0px 13px 20px #d6d1d1"
                            >
                              <Row id="table-styles">
                                <div className="col-md-12" id="head">
                                  <h4 className="heading">
                                    <span>List of Documents</span>
                                    <span>
                                      <Link to={"/contract/add-document/" + id}>
                                        <i className="fe fe-download-cloud me-2"></i>
                                        New
                                      </Link>{" "}
                                    </span>
                                  </h4>
                                  <hr></hr>
                                </div>
                                <table id="document" className="">
                                  <thead>
                                    <tr>
                                      <th>contract list of docs</th>
                                      <th>document required name</th>
                                      <th>list of docuemnts</th>
                                      <th>original in no</th>
                                      <th>Copies In No</th>
                                    </tr>
                                  </thead>

                                  {document.length > 0 ? (
                                    <tbody>
                                      {document.map((y, i) => (
                                        <tr>
                                          <td className="">
                                            <Link
                                              to={
                                                "/contract/document-view/" +
                                                y.id
                                              }
                                            >
                                              {y.contract_list_of_docs}
                                            </Link>
                                          </td>
                                          <td>{y.document_required_name}</td>
                                          <td>{y.list_of_docuemnts}</td>
                                          <td>{y.original_in_no}</td>
                                          <td>{y.copies_in_no}</td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  ) : (
                                    <tbody>
                                      <tr>
                                        <td></td>
                                        <td></td>
                                        <td>No data available</td>
                                        <td></td> <td></td>
                                      </tr>
                                    </tbody>
                                  )}
                                </table>
                              </Row>

                              <Row id="table-styles">
                            <div className="col-md-12" id="head">
                              <h4 className="heading">
                                <span>Contract Product</span>
                              </h4>
                              <hr></hr>
                            </div>
                            <table id="contract-product" className="">
                              <thead>
                                <tr>
                                  <th>Contract Product Number</th>
                                  <th>Product Master</th>
                                  <th>Gross As Received Typical	</th>
                                  <th>Total Moisture Typical	</th>
                                  <th>Ash Content Typical ARB	</th>
                                  <th>Total Sulphur Typical ARB	</th>
                                  <th>Price Basis GAR
                                  </th>
                                </tr>
                              </thead>

                              {contractProduct.length > 0 ? (
                                <tbody>
                                  {contractProduct.map((x, i) => (
                                    <tr>
                                      <td><Link
                                        to={`/contract-product/details/${x.id}`}
                                      >{x.contract_product_number}</Link></td>
                                      <td>
                                        <Link
                                          to={`/productmaster/product-detail/${x.id}`}
                                        >
                                          {" "}
                                          {x.product_master}{" "}
                                        </Link>
                                      </td>
                                      <td>{renderShipmentQty(x.gross_as_received_typical)}</td>
                                      <td>{renderShipmentQty(x.total_moisture_typical)}</td>
                                      <td>{renderShipmentQty(x.ash_typical_arb)}</td>
                                      <td>{renderShipmentQty(x.sulphur_typical_arb)}</td>
                                      <td>{renderShipmentQty(x.price_basis_GAR)}</td>

                                    </tr>
                                  ))}
                                </tbody>
                              ) : (
                                <tbody>
                                  <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>No data available</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                  </tr>
                                </tbody>
                              )}
                            </table>
                          </Row>




                              <Row id="table-styles">
                                <div className="col-md-12" id="head">
                                  <h4 className="heading">
                                    <span>Contract Shipment</span>
                                    <span>
                                      <Popup
                                        trigger={
                                          <a className="button add-accountrecord">
                                            {" "}
                                            New{" "}
                                          </a>
                                        }
                                        modal
                                      >
                                        <form
                                          onSubmit={handleSubmit}
                                          className="add-accounts"
                                        >
                                          <div className="form-group">
                                            <label>
                                              Contract Shipment Record Type
                                            </label>
                                            <br />
                                            <select
                                              name="account_record"
                                              className="form-control"
                                              onChange={handleChange}
                                              value={formData.account_record}
                                            >
                                              <option value="">--Select record type--</option>
                                              <option value="Buyer - Long Term">
                                                Buyer - Long Term
                                              </option>
                                              <option value="Buyer - Spot">
                                                Buyer - Spot
                                              </option>
                                              <option value="Supplier - Long Term">
                                                Supplier - Long Term
                                              </option>
                                              <option value="Supplier - Spot">
                                                Supplier - Spot
                                              </option>
                                            </select>
                                            {error.account_record && <div className="errors-msg">{error.account_record}</div>}
                                          </div>
                                          <input
                                            type="submit"
                                            className="btn btn-primary "
                                            value="Submit"
                                          />
                                        </form>
                                      </Popup>
                                    </span>
                                  </h4>

                                  <hr></hr>
                                </div>
                                <div id="inner-heading"></div>
                                {conShipmentParents.length > 0 ? (
                                  <table id="contract-ship" className="">
                                    <thead>
                                      <tr>
                                        <th>Record Type</th>
                                        <th>Contract Ship Code</th>
                                        <th>Shipment Status</th>
                                        <th>Parent Shipment</th>
                                        <th>Pricing</th>
                                        <th>Laycan</th>
                                        <th>Quantity Info</th>
                                        <th>No. of Shipments</th>
                                        <th>No. of Orders</th>
                                        <th>TCF Count</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {conShipmentParents.map((y, i) => (
                                        <tr key={i}>
                                          <td>{y.record_type}</td>
                                          <td>
                                            <Link to={`/shipment/contract-shipment-details/${y.id}`}>
                                              <span id="codes">{y.contract_ship_code}</span>
                                            </Link>
                                          </td>
                                          <td>{y.contract_shipment_status}</td>
                                          <td>
                                            {y.contract_ship_code === null ? (
                                              <Link to={`/shipment/contract-shipment-details/${y.id}`}>
                                                <span id="codes">{y.contract_ship_code}</span>
                                              </Link>
                                            ) : (
                                              y.parent_shipment !== null && y.parentship_id !== null && (
                                                <Link to={`/shipment/contract-shipment-details/${y.parentship_id}`}>
                                                  {y.parent_shipment}
                                                </Link>
                                              )
                                            )}
                                          </td>
                                          <td>{y.pricing}</td>
                                          <td>{y.laycan}</td>
                                          <td>{y.quantity_info}</td>
                                          <td>{y.no_of_shipments}</td>
                                          <td>{y.no_of_orders}</td>
                                          <td>{y.tcf_count}</td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                ) : (
                                  <p>No data available</p>
                                )}
                              </Row>
                              <Row id="table-styles">
                                <div className="col-md-12" id="head">
                                  <h4 className="heading">
                                    <span>Products (Buyer Contract)</span>
                                    <span>New</span>
                                  </h4>
                                  <hr></hr>
                                </div>
                                {buyerProduct.length > 0 ? (
                                  <table id="contract-ships" className="">
                                    <thead>
                                      <tr>
                                      <th>Product Name	</th>
                                      <th>Contract Shipment	</th>
                                      <th>Product Code	</th>
                                      <th>Product No	</th>
                                      <th>Quantity (MT)	</th>
                                      <th>Status</th>
                                      <th>Buyer Name
                                      </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {buyerProduct.map((y, i) => (
                                        <tr key={i}>
                                          <td><Link to={`/product/product-detail/${y.product_master_id}`}>{y.product_name}</Link></td>
                                           <td><Link to={`/shipment/contract-shipment-details/${y.contract_shipment_id}`}>{y.contract_shipment}</Link></td>
                                          <td><Link to={`/product/product-detail/${y.product_master_id}`}>{y.product_code}</Link></td>
                                          <td>{y.product_no}</td>
                                          <td>{y.quantity_MT}</td>
                                          <td>{y.status}</td>
                                          <td>{y.buyer_name}</td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                ) : (
                                  <tbody>
                                  <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>No data available</td>
                                    <td></td>
                                    <td></td>
                                  </tr>
                                </tbody>
                                )}
                              </Row>
                              <Row id="table-styles">
                                <div
                                  className="col-md-12"
                                  id="head"
                                >
                                  <h4 className="heading">
                                    <span>
                                      Opportunity Information{" "}
                                    </span>{" "}
                                    {/* <Popup trigger={ <span className="button add-accountrecord"> New </span>} modal> <form onSubmit={handleSubmitOpp} className="add-accounts">
                      <div className='form-group'>
                        <label>Opportunity Record Type</label>
                        <br />
                        <select className='form-control' name="type_of_new_record" onChange={handleChangeOpp} value={formDataopp.account_record}>
                          <option>----Select----</option>
                          <option value='Buyer'>Buyer</option>
                          <option value='Buyer - Long Term'>Buyer - Long Term</option>
                          <option value='Buyer - Spot'>Buyer - Spot</option>
                          <option value='Supplier'>Supplier</option>
                          <option value='Supplier - Long Term'>Supplier - Long Term</option>
                          <option value='Supplier - Spot'>Supplier - Spot</option>
                        </select>
                      </div>
                      <input type='submit' className="btn btn-primary " value='Submit' />
                    </form>
                  </Popup> */}
                                  </h4>
                                  <hr></hr>
                                </div>
                                <table
                                  id="opportunity"
                                  className=""
                                >
                                  <thead>
                                    <tr>
                                      <th>Opportunity Name</th>

                                      <th>Opportunity No</th>
                                      <th>Stage</th>
                                      <th>Quantity (MT)	</th>
                                      <th>Supplier</th>
                                      <th>No of Order</th>
                                    </tr>
                                  </thead>{" "}
                                  {opportunity.length > 0 ? (
                                    <tbody>
                                      {" "}
                                      {opportunity.map((y, i) => (
                                        <tr>
                                          <td>
                                            <Link
                                              to={`/opportunity/opportunity-detail/${y.id}`}
                                            >
                                              {y.opportunity_name}
                                            </Link>
                                          </td>
                                          <td>
                                            <Link
                                              to={`/opportunity/opportunity-detail/${y.id}`}
                                            >
                                              {y.opportunity_no}
                                            </Link>
                                          </td>
                                          <td>{y.stage}</td>
                                          <td>{y.quantity_mt}</td>
                                          <td><Link to={`/accounts/${y.supplier_id}`}>{y.supplier} </Link></td>
                                          <td>{y.no_of_order}</td>
                                        </tr>
                                      ))}{" "}
                                    </tbody>
                                  ) : (
                                    <tbody>
                                      <tr>
                                        <td></td>
                                        <td></td>
                                        <td>No data available</td>
                                        <td></td>
                                        <td></td><td></td>
                                      </tr>
                                    </tbody>
                                  )}
                                </table>
                              </Row>

                              <Row id="table-styles">
                                <div className="col-md-12" id="head">
                                  <h4 className="heading">
                                    <span>Quotes</span>
                                  </h4>
                                  <hr></hr>
                                </div>
                                <table id="quotes" className="">
                                  <thead>
                                    <tr>
                                      <th>Quote Number</th>
                                      <th>Quote Name</th>
                                      <th>Account Name</th>
                                      <th>Contact Name</th>
                                      <th>Expiration Date</th>
                                      <th>Product Description	</th>
                                      <th>Status</th>

                                    </tr></thead>
                                  {quoteTable.length > 0 ? (
                                    <tbody>
                                      {quoteTable.map((y, i) => (
                                        <tr>
                                          <td>
                                            <span id="codes">
                                              <Link
                                                to={`/opportunity/quote-view/${y.id}`}
                                              >
                                                {y.quote_number}
                                              </Link>
                                            </span>
                                          </td>
                                          <td><Link to={`/opportunity/quote-view/${y.id}`}>{y.quote_name}  </Link> </td>
                                          <td><Link to={`/accounts/${y.account_id}`}>{y.account_name} </Link></td>
                                          <td>{y.contact_name}</td>
                                          <td>{y.expiration_date}</td>
                                          <td>{y.product_description}</td>
                                          <td>{y.status}</td>

                                        </tr>
                                      ))}
                                    </tbody>
                                  ) : (
                                    <tbody>
                                      <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>No data available</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                      </tr>
                                    </tbody>
                                  )}
                                </table>
                              </Row>



                              <Row id="table-styles">
                                <div className="col-md-12" id="head">
                                  <h4 className="heading">
                                    <span>Trade Confirmation Forms</span>
                                    <span>
                                      <Popup
                                        trigger={
                                          <a className="button add-accountrecord">
                                            {" "}
                                            New{" "}
                                          </a>
                                        }
                                        modal
                                      >
                                        <form
                                          onSubmit={handleSubmittcf}
                                          className="add-accounts"
                                        >
                                          <div className="form-group">
                                            <label>
                                              TCF Record Type
                                            </label>
                                            <br />
                                            <select
                                              name="account_record"
                                              className="form-control"
                                              onChange={handleChangetcf}
                                              value={
                                                formData.account_record
                                              }
                                            >
                                              <option>
                                                --Select TCF Record
                                                Type--
                                              </option>
                                              <option value="Buyer - Spot">
                                                Buyer - Spot
                                              </option>
                                              <option value="Buyer - Long Term">
                                                Buyer - Long Term
                                              </option>
                                            </select>
                                          </div>
                                          <input
                                            type="submit"
                                            className="btn btn-primary "
                                            value="Submit"
                                          />
                                        </form>
                                      </Popup>
                                    </span>
                                  </h4>
                                  <hr></hr>
                                </div>
                                <table id="tcf" className="">
                                  <thead>
                                    <tr>
                                      <th>TCF No</th>
                                      <th>TCF Status</th>
                                      <th>BL Quantity</th>
                                      <th>BL date</th>
                                      <th>Laycan</th>
                                      <th>contract shipment</th>
                                      <th>product</th>
                                      <th>Vessel Name	</th>
                                      <th>Proposed Laycan From	</th>
                                      <th>Proposed Laycan To</th>
                                      <th>Ship Month</th>
                                    </tr>
                                  </thead>

                                  {tcf.length > 0 ? (
                                    <tbody>
                                      {tcf.map((y, i) => (
                                        <tr>
                                          <td>
                                            <span id="codes">
                                              <Link
                                                to={`/tcf/tcf-details/${y.id}`}
                                              >
                                                {y.tcf_no}
                                              </Link>
                                            </span>
                                          </td>
                                          <td>{y.tcf_status}</td>
                                          <td>{y.actual_bl_qty}</td>
                                          <td>{y.actual_bl_date}</td>
                                          <td>{y.laycan}</td>
                                          <td><Link to={`/shipment/contract-shipment-details/${y.contract_ship_id}`}>{y.contract_shipment}</Link></td>
                                          <td><Link to={`/product/product-detail/${y.product_id}`}>{y.product}</Link></td>
                                          <td>{y.vessel_name}</td>
                                          <td>{y.proposed_laycan_from}</td>
                                          <td>{y.proposed_laycan_to}</td>
                                          <td>{y.ship_month
                                          }</td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  ) : (
                                    <tbody>
                                      <tr>
                                        <td></td><td></td>
                                        <td></td>
                                        <td></td> <td></td> <td></td>
                                        <td>No data available</td>
                                        <td></td>
                                        <td></td>
                                        <td></td><td></td>
                                      </tr>
                                    </tbody>
                                  )}
                                </table>
                              </Row>
                              <Row id="table-styles">
                                <div className="col-md-12" id="head">
                                  <h4 className="heading">
                                    <span>Orders Information </span>
                                  </h4>
                                  <hr></hr>
                                </div>
                                <table id="orders" className="">
                                  <thead>
                                    <tr>
                                      <th>Order Number</th>
                                      <th>	Order Start Date</th>
                                      <th>Contract Shipment</th>
                                      <th>Vessel Barge Name	</th>
                                      <th>Ship Month	</th>
                                      <th>Laycan From Date</th>
                                      <th>Status</th>
                                      <th>Shipment Qty</th>
                                      <th>Account Name</th>
                                    </tr>
                                  </thead>

                                  {orderData.length > 0 ? (
                                    <tbody>
                                      {orderData.map((y, i) => (
                                        <tr>
                                          <td> <Link
                                            to={`/order/view-order-details/${y.id}`}
                                          >{y.order_no}</Link></td>
                                          <td>{y.order_start_date}</td>
                                          <td><Link to={`/shipment/contract-shipment-details/${y.contract_ship_id}`}>{y.contract_shipment}</Link></td>
                                          <td>{y.vessel_barge_name}</td>
                                          <td>{y.ship_month}</td>
                                          <td>{y.laycan_from_date}</td>
                                          <td>{y.status}</td>
                                          <td>{y.shipment_qty}</td>
                                          <td><Link to={`/accounts/${y.account_id}`}>{y.account_name} </Link></td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  ) : (
                                    <tbody>
                                      <tr><td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>No data available</td>
                                        <td></td>
                                        <td></td>
                                        <td></td><td></td>
                                      </tr>
                                    </tbody>
                                  )}
                                </table>
                              </Row>
                              <Row id="table-styles">
                                <div className="col-md-12" id="head">
                                  <h4 className="heading">
                                    <span>Rw Payable/ Receivable LineItem
                                    </span>
                                    <span>
                                      <i className=""></i>
                                      New Rw Payable/ Receivable LineItem

                                    </span>

                                  </h4>
                                  <hr></hr>
                                </div>
                                <table id="payables" className="">
                  <thead>
                    <tr>
                      <th>RW Billing	</th>
                      <th>Rw Payable/ Receivable LineItem	</th>
                      <th>Quantity	</th>
                      <th>Amount		</th>
                      <th>Line Item		</th>
                      <th>BL Date	</th>
                      <th>Description		</th>
                      <th>Destination		</th>
                      <th>Document Date	</th>
                      <th>Vessel Name
                      </th>
                    </tr>
                  </thead>

                  {payable.length > 0 ? (
                    <tbody>
                      {payable.map((y, i) => (
                        <tr>

 

                          <td>{y.rw_billing}</td>
                          <td>{y.rw_payment_receivable_lineitem}</td>
                          <td>{y.quantity
                          }</td> 
                          <td>{renderShipmentQty(y.amount)}</td>
                          <td>{y.lineitem
                          }</td>
                          <td>{y.bl_date}</td>
                          <td>{y.description}</td>
                          <td>{y.destination}</td>
                          <td>{y.document_date}</td>
                          <td>{y.vessel_name}</td>
                        </tr>
                      ))}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>No data available</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  )}
                </table>
                              </Row>
                              <Row id="table-styles">
                                <div className="col-md-12" id="head">
                                  <h4 className="heading">
                                    <span>RW Payments/Receipts LineItem

                                    </span>
                                    <span>
                                      <i className=""></i>
                                      New RW Payments/Receipts LineItem


                                    </span>

                                  </h4>
                                  <hr></hr>
                                </div>
                                <table id="receipt" className="">
                  <thead>
                    <tr>
                      <th>RW Payment/Receipt</th>
                      <th>RW Billing	</th>
                      <th>Record Type	</th>
                      <th>Line Item	</th>
                      <th>Account</th>
                      <th>Amount</th>
                      <th>Description</th>
                      <th>Created Date
                      </th>
                    </tr>
                  </thead>

                  {receipts.length > 0 ? (
                    <tbody>
                      {receipts.map((y, i) => (
                        <tr>

<td><Link

   onClick={(e) => {
     e.preventDefault(); // Prevent default action
     // Perform custom action here
     navigate("/order/order-receipt-details/" + y.rw_pay_receipt_detail_id, {
       state: { postId:id },
     });

   }}
 >
   {y.rw_payment_receipt}
 </Link></td>

                          <td> <Link to={"/order/order-receivable-details/" + y.rw_pay_recieve_id}>
                              {y.rw_billing}
                            </Link></td>
                          <td>{y.record_type}</td>
                          <td>{y.line_item}</td>
                          <td><Link to={"/accounts/" + y.account_id}>{y.account}</Link></td>
                          <td> {renderShipmentQty(y.amount)}</td>
                          <td>{y.description}</td>
                          <td>{y.created_at}</td>
                        </tr>
                      ))}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>No data available</td>
                        <td></td>
                        <td></td><td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  )}
                </table>
                              </Row>

                              <Row id="table-styles">
                                <div className="col-md-12" id="head">
                                  <h4 className="heading">
                                    <span>Activity History</span>
                                    <span>
                                      <i className=""></i>
                                      Log a Call
                                    </span>
                                    <span>
                                      <i className=""></i>
                                      Mail merge
                                    </span>
                                  </h4>
                                  <hr></hr>
                                </div>
                                <table id="history" className="">
                                  <tr>
                                    <td>
                                      <p class="no-records">
                                        No records to display
                                      </p>
                                    </td>
                                  </tr>
                                </table>
                              </Row>

                              <Row id="table-styles">
                                <div className="col-md-12" id="head">
                                  <h4 className="heading">
                                    <span>Contract History</span>
                                  </h4>
                                  <hr></hr>
                                </div>
                                <table id="history" className="">
                                  <tr>
                                    <td>
                                      <p class="no-records">
                                        No records to display
                                      </p>
                                    </td>
                                  </tr>
                                </table>
                              </Row>
                              <Row id="table-styles">
                                <div className="col-md-12" id="head">
                                  <h4 className="heading">
                                    <span>Open Activities</span>
                                    <span>
                                      <i className=""></i>
                                      New Task
                                    </span>
                                    <span>
                                      <i className=""></i>
                                      New Event
                                    </span>
                                  </h4>
                                  <hr></hr>
                                </div>
                                <table id="history" className="">
                                  <tr>
                                    <td>
                                      <p class="no-records">
                                        No records to display
                                      </p>
                                    </td>
                                  </tr>
                                </table>
                              </Row>

                              <Row id="table-styles">
                                <div className="col-md-12" id="head">
                                  <h4 className="heading">
                                    <span>Files</span>
                                    <span>
                                      <i className=""></i>
                                      Upload Files
                                    </span>
                                  </h4>
                                  <hr></hr>
                                </div>
                                <table id="history" className="">
                                  <tr>
                                    <td>
                                      <p class="no-records">
                                        No records to display
                                      </p>
                                    </td>
                                  </tr>
                                </table>
                              </Row>
                              <Row id="table-styles">
                                <div className="col-md-12" id="head">
                                  <h4 className="heading">
                                    <span>Notes & Attachments</span>
                                    <span>
                                      <i className=""></i>
                                      New Note
                                    </span>
                                    <span>
                                      <i className=""></i>
                                      Attach File
                                    </span>
                                  </h4>
                                  <hr></hr>
                                </div>
                                <table id="history" className="">
                                  <tr>
                                    <td>
                                      <p class="no-records">
                                        No records to display
                                      </p>
                                    </td>
                                  </tr>
                                </table>
                              </Row>

                              <Row id="table-styles">
                                <div className="col-md-12" id="head">
                                  <h4 className="heading">
                                    <span>Contract Clauses</span>
                                    <span>
                                      <i className=""></i>
                                      New Contract Clause
                                    </span>
                                  </h4>
                                  <hr></hr>
                                </div>
                                <table id="history" className="">
                                  <tr>
                                    <td>
                                      <p class="no-records">
                                        No records to display
                                      </p>
                                    </td>
                                  </tr>
                                </table>
                              </Row>
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {datas.contract_record_type ===
                          "Supplier - Long Term" ? (
                          <>
                            {isEditMode ? (
                              <div className="tasks">
                                <p className="edit-btn">
                                  <input
                                    type="submit"
                                    className="account-save"
                                    onClick={handleSaveEdit}
                                    value="Save"
                                    //disabled={isSubmitted}
                                  />
                                  <button
                                    className="btn btn-primary"
                                    onClick={handleCancelEdit}
                                  >
                                    {" "}
                                    Cancel{" "}
                                  </button>
                                </p>
                                <p className="errors">{errors}</p>
                                <Row className="mb-3">
                                  <h4 className="heading">
                                    Contract Information
                                  </h4>
                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingSelect"
                                      className="dropDown"
                                      label="Currency"
                                    >
                                      <Form.Select
                                        aria-label="Floating label select example"
                                        name="currency"
                                        placeholder="Currency"
                                        className="error-validation"
                                        tabIndex={1}
                                        value={datas.currency || ""}
                                        onChange={handleChangeInput}
                                      >
                                        <option value="">Select </option>
                                        <option value="USD">USD </option>
                                        <option value="IDR">IDR </option>
                                      </Form.Select>
                                    </FloatingLabel>
                                  </Form.Group>
                                  <Form.Group as={Col} md="6">
                            <label>Locked</label>
                            <input
                              type='checkbox'
                              
                              name='locked'
                              checked={lockeds} // Use the state variable to determine if the checkbox is checked
                              onChange={handleCheckboxChange} // Handle the change event
                              className='mx-3'
                              tabIndex={11}
                            />
                          </Form.Group>
                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="Exchange rate"
                                      className="mb-3"
                                    >
                                      <Form.Control
                                        type="text"
                                        placeholder="Exchange rate "
                                        tabIndex={2}
                                        name="exchange_rate"
                                        defaultValue={datas.exchange_rate || ""}
                                        onChange={handleChangeInput}
                                      />
                                    </FloatingLabel>
                                  </Form.Group>
                                  <Form.Group as={Col} md="6">
                                    {" "}
                                  </Form.Group>
                                  {/*
          <Form.Group as={Col} md="6">
            <FloatingLabel controlId="floatingSelect" className="dropDown" label="Status">
              <Form.Select aria-label="Floating label select example" name="status" placeholder="Status" className="error-validation" value={datas.status || "" } onChange={handleChangeInput}>
                <option value="">Select </option>
                <option value="Draft">Draft </option>
                <option value="Approved">Approved </option>
              </Form.Select>
            </FloatingLabel>
          </Form.Group> */}
                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingSelect"
                                      className="dropDown"
                                      label="Account Name"
                                    >
                                      <Form.Select
                                        aria-label="Floating label select example"
                                        name="account_name"
                                        tabIndex={3}
                                        placeholder="Account Name"
                                        className="error-validation"
                                        value={datas.account_name || ""}
                                        onChange={handleChangeInput}
                                      >
                                        <option value="">Select </option>{" "}
                                        {accountName.map((x) => {
                                          return (
                                            <option value={x.account_name}>
                                              {x.account_name}
                                            </option>
                                          );
                                        })}
                                      </Form.Select>
                                    </FloatingLabel>
                                  </Form.Group>

                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="Contract record type"
                                      className="mb-3"
                                    >
                                      <Form.Control
                                        type="text"
                                        tabIndex={12}
                                        placeholder="Contract record type"
                                        name="contract_record_type"
                                        className="record-type"
                                        defaultValue={
                                          datas.contract_record_type || ""
                                        }
                                        onChange={handleChangeInput}
                                      />
                                    </FloatingLabel>
                                  </Form.Group>

                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="Rwood contract No"
                                      className="mb-3"
                                    >
                                      <Form.Control
                                        type="text"
                                        placeholder="Rwood contract No"
                                        name="Rwood_Contract_No"
                                        tabIndex={4}
                                        defaultValue={
                                          datas.Rwood_Contract_No || ""
                                        }
                                        onChange={handleChangeInput}
                                        disabled
                                      />
                                    </FloatingLabel>
                                  </Form.Group>

                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="Contract Owner"
                                      className="mb-3"
                                    >
                                      <Form.Control
                                        type="text"
                                        placeholder="Contract Owner"
                                        tabIndex={13}
                                        name="contract_owner"
                                        defaultValue={
                                          datas.contract_owner || ""
                                        }
                                        onChange={handleChangeInput}
                                      />
                                    </FloatingLabel>
                                  </Form.Group>
                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="Contract Start Date"
                                      className="mb-3 date-picker"
                                    >
                                      <DatePicker
                                        selected={startDate}
                                        onChange={handleStartDateChange}
                                        selectsStart
                                        tabIndex={5}
                                        dateFormat="dd/MM/yyyy"
                                        placeholderText="Select"
                                      />
                                    </FloatingLabel>
                                  </Form.Group>
                                  <Form.Group as={Col} md="6"></Form.Group>
                                  {/* <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingSelect"
                                      className="dropDown"
                                      label="Owner Expiration Notice"
                                    >
                                      <Form.Select
                                        aria-label="Floating label select example"
                                        name="owner_expiration_notice"
                                        placeholder="Owner Expiration Notice"
                                        value={
                                          datas.owner_expiration_notice || ""
                                        }
                                        onChange={handleChangeInput}
                                      >
                                        <option>---None---</option>
                                        <option value="30">30 Days</option>
                                        <option value="45">45 Days</option>
                                        <option value="60">60 Days</option>
                                        <option value="90">90 Days</option>
                                        <option value="120">120 Days</option>
                                      </Form.Select>
                                    </FloatingLabel>
                                  </Form.Group> */}

                                  <Form.Group as={Col} md="6" className="tooltips">
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="Contract Effective Date"
                                      className="mb-3 date-picker"
                                    >
                                      <DatePicker
                                        selected={contractEffectiveDate}
                                        onChange={handleEndDateChange}
                                        dateFormat="dd/MM/yyyy"
                                        tabIndex={6}
                                        placeholderText="Select Date"
                                      />{" "}
                                    </FloatingLabel>
                                    <Tooltip text={<span>Contract Effective Date</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                                  </Form.Group>

                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="Contract term in months"
                                      className="mb-3"
                                    >
                                      <Form.Control
                                        type="text"

                                        tabIndex={14}
                                        placeholder="Contract term in months"
                                        name="contract_term_in_months"
                                        defaultValue={
                                          datas.contract_term_in_months || ""
                                        }
                                        onChange={handleChangeInput}
                                      />
                                    </FloatingLabel>
                                  </Form.Group>
                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingSelect"
                                      className="dropDown"
                                      label="Contract Status"
                                    >
                                      <Form.Select
                                        aria-label="Floating label select example"
                                        name="contract_status"
                                        placeholder="Contract Status"
                                        tabIndex={7}
                                        value={datas.contract_status || ""}
                                        onChange={handleChangeInput}
                                      >
                                        <option value="">--None--</option>
                                        <option value="In Approval Process">
                                          In Approval Process
                                        </option>
                                        <option value="Activated">
                                          Activated
                                        </option>
                                        <option value="Draft" selected>Draft</option>
                                        <option value="Under Review">
                                          Under Review
                                        </option>
                                        <option value="Draft Sent for Signing">
                                          Draft Sent for Signing
                                        </option>
                                        <option value="Draft Received for Signing">
                                          Draft Received for Signing
                                        </option>
                                        <option value="Signed Contract yet to Receive">
                                          Signed Contract yet to Receive
                                        </option>
                                        <option value="Signed Contract Sent for Sign">
                                          Signed Contract Sent for Sign
                                        </option>
                                        <option value="Signed Original Contract Need to Courier">
                                          Signed Original Contract Need to
                                          Courier
                                        </option>
                                        <option value="Signed Original Contract yet to Receive">
                                          Signed Original Contract yet to
                                          Receive
                                        </option>
                                        <option value="Original Contract Signed by Both Parties">
                                          Original Contract Signed by Both
                                          Parties
                                        </option>
                                        <option value="Original Contract Signed and Received by Both Parties">
                                          Original Contract Signed and Received
                                          by Both Parties
                                        </option>
                                        <option value="Closed">Closed</option>
                                        <option value="Partially completed">
                                          Partially completed
                                        </option>
                                        <option value="Completed">
                                          Completed
                                        </option>
                                        <option value="Cancelled">
                                          Cancelled
                                        </option>
                                        <option value="Partially cancelled">
                                          Partially cancelled
                                        </option>
                                        <option value="Shipments - Arutmin">
                                          Shipments - Arutmin
                                        </option>
                                        <option value="Partially Signed Contract">
                                          Partially Signed Contract
                                        </option>
                                      </Form.Select>
                                    </FloatingLabel>
                                  </Form.Group>

                                  <Form.Group as={Col} md="6" className='textEditor'>
                                    <label>Pending Matter</label>
                                    <Editor value={pendingMatter} onChange={onChangePending} tabIndex={15}/>
                                  </Form.Group>
                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingSelect"
                                      className="dropDown"
                                      label="Trader Name"
                                    >
                                      <Form.Select
                                        aria-label="Floating label select example"
                                        name="trader_name"
                                        tabIndex={8}
                                        placeholder="Trader Name"
                                        value={datas.trader_name || ""}
                                        onChange={handleChangeInput}
                                      >
                                        <option value="">Select </option>{" "}
                                        {traderData.map((x, i) => {
                                          return (
                                            <option
                                              key={i}
                                              value={x.rwood_username}
                                            >
                                              {" "}
                                              {x.rwood_username}{" "}
                                            </option>
                                          );
                                        })}
                                      </Form.Select>
                                    </FloatingLabel>
                                  </Form.Group>

                                  <Form.Group as={Col} md="6" className="tooltips">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Master Contract Reference"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="Master Contract Reference"
                                    name="master_conference_reference"
                                    tabIndex={16}
                                    defaultValue={
                                      datas.master_conference_reference || ""
                                    }
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                                <Tooltip text={<span>Give master contract reference, if any</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                              </Form.Group>
                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingSelect"
                                      className="dropDown"
                                      label="Company"
                                    >
                                      <Form.Select
                                        aria-label="Floating label select example"
                                        name="company"
                                        placeholder="Company"
                                        tabIndex={9}
                                        className="error-validation"
                                        value={datas.company || ""}
                                        onChange={handleChangeInput}
                                      >
                                        <option value="">Select </option>{" "}
                                        {companyData.map((x, i) => {
                                          return (
                                            <option
                                              key={i}
                                              value={x.company_name}
                                            >
                                              {" "}
                                              {x.company_name}{" "}
                                            </option>
                                          );
                                        })}
                                      </Form.Select>
                                    </FloatingLabel>
                                  </Form.Group>

                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="Renewal date"
                                      className="mb-3 date-picker"
                                    >
                                      <DatePicker
                                        selected={renewal}
                                        onChange={handleRenewal}
                                        dateFormat="dd/MM/yyyy"
                                        tabIndex={17}
                                        placeholderText="Select Date"
                                      />
                                    </FloatingLabel>
                                  </Form.Group>
                                  <Form.Group as={Col} md="6"></Form.Group>
                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="Renewal contract number"
                                      className="mb-3"
                                    >
                                      <Form.Control
                                        type="number"
                                        placeholder="Renewal contract number "
                                        name="renewal_contract_number "
                                        tabIndex={18}
                                        defaultValue={
                                          datas.renewal_contract_number || ""
                                        }
                                        onChange={handleChangeInput}
                                      />
                                    </FloatingLabel>
                                  </Form.Group>

                                  <Form.Group as={Col} md="6"></Form.Group>
                                  <Form.Group as={Col} md="6" className='textEditor tooltips'>
                                    <label>Remark</label>
                                    <Editor value={remarks} onChange={onChangeRemarks} tabIndex={19}/>
                                    <Tooltip text={<span>Any Specific Remark related to Contract</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                                  </Form.Group>
                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="Contract Number"
                                      className="mb-3"
                                    >
                                      <Form.Control
                                        type="text"
                                        placeholder="Contract Number"
                                        name="contract_number"
                                        tabIndex={10}
                                        defaultValue={datas.contract_number || ""}
                                        onChange={handleChangeInput} disabled
                                      />
                                    </FloatingLabel>
                                  </Form.Group>

                                </Row>

                                <Row className="mb-3">
                                  <h4 className="heading">
                                    Commodity and Contracted Quantity
                                  </h4>
                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="Port Of Loading"
                                      className="mb-3"
                                    >
                                      <Form.Control
                                        type="text"
                                        placeholder="Port Of Loading"
                                        name="port_of_loading"

                                        tabIndex={20}
                                        defaultValue={
                                          datas.port_of_loading || ""
                                        }
                                        onChange={handleChangeInput}
                                      />
                                    </FloatingLabel>
                                  </Form.Group>
                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="Origin"
                                      className="mb-3"
                                    >
                                      <Form.Control
                                        type="text"
                                        placeholder="Origin"
                                        name="origin"
                                        tabIndex={22}
                                        defaultValue={datas.origin || ""}
                                        onChange={handleChangeInput}
                                      />
                                    </FloatingLabel>
                                  </Form.Group>
                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="Contract quantity in MT"
                                      className="mb-3"
                                    >
                                      <Form.Control
                                        type="number"
                                        placeholder="Contract quantity in MT"
                                        name="contract_quantity_in_MT"
                                        className="error-validation"
                                        tabIndex={21}
                                        defaultValue={
                                          datas.contract_quantity_in_MT || ""
                                        }
                                        onChange={handleChangeInput}
                                      />
                                    </FloatingLabel>
                                  </Form.Group>
                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingSelect"
                                      className="dropDown"
                                      label="Commodity"
                                    >
                                      <Form.Select
                                        aria-label="Floating label select example"
                                        name="commodity"
                                        placeholder="Commodity"
                                        tabIndex={23}
                                        value={datas.commodity || ""}
                                        onChange={handleChangeInput}
                                      >
                                        <option>---None---</option>
                                        <option value='Indonesian Steam Coal in Bulk ("Coal")'>
                                          Indonesian Steam Coal in Bulk ("Coal")
                                        </option>
                                        <option value="US Coal">US Coal</option>
                                        <option value="Russia Coal">
                                          Russia Coal
                                        </option>
                                        <option value="SA Coal">SA Coal</option>
                                        <option value="Clinker in Bulk">
                                          Clinker in Bulk
                                        </option>
                                        <option value="Australian Steam Coal">
                                          Australian Steam Coal
                                        </option>
                                      </Form.Select>
                                    </FloatingLabel>
                                  </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                  <h4 className="heading">
                                    Price and Price Adjustment
                                  </h4>
                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="Price PMT"
                                      className="mb-3"
                                    >
                                      <Form.Control
                                        type="number"
                                        placeholder="Price PMT"
                                        name="price_PMT"
                                        tabIndex={24}
                                        defaultValue={datas.price_PMT || ""}
                                        onChange={handleChangeInput}
                                      />
                                    </FloatingLabel>
                                  </Form.Group>
                                  <Form.Group as={Col} md="6">
                                    {" "}
                                  </Form.Group>
                                  {/* <Form.Group as={Col} md="6">
            <FloatingLabel controlId="floatingSelect" className='dropDown' label="Price book">
              <Form.Select aria-label="Floating label select example" name="price_book" placeholder="Price book" value={datas.price_book || "" } onChange={handleChangeInput}>
                <option value=''>Select</option> { priceBook.map((x)=>{ return( <option value={x.price_book_name}>{x.price_book_name}</option> ) }) }
              </Form.Select>
            </FloatingLabel>
          </Form.Group> */}
                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="Price PMT in words"
                                      className="mb-3"
                                    >
                                      <Form.Control
                                        type="text"
                                        tabIndex={25}
                                        placeholder="Price PMT in words"
                                        name="price_PMT_in_words"
                                        defaultValue={
                                          datas.price_PMT_in_words || ""
                                        }
                                        onChange={handleChangeInput}
                                      />
                                    </FloatingLabel>
                                  </Form.Group>
                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingSelect"
                                      className="dropDown"
                                      label="Price Basis"
                                    >
                                      <Form.Select
                                        aria-label="Floating label select example"
                                        name="price_basis"
                                        tabIndex={29}
                                        placeholder="Price Basis"
                                        value={datas.price_basis || ""}
                                        onChange={handleChangeInput}
                                      >
                                        <option value="">--None--</option>
                                        <option value="In Approval Process">
                                          In Approval Process
                                        </option>
                                        <option value="Activated">
                                          Activated
                                        </option>
                                        <option value="Draft">Draft</option>
                                        <option value="Under Review">
                                          Under Review
                                        </option>
                                        <option value="Draft Sent for Signing">
                                          Draft Sent for Signing
                                        </option>
                                        <option value="Draft Received for Signing">
                                          Draft Received for Signing
                                        </option>
                                        <option value="Signed Contract yet to Receive">
                                          Signed Contract yet to Receive
                                        </option>
                                        <option value="Signed Contract Sent for Sign">
                                          Signed Contract Sent for Sign
                                        </option>
                                        <option value="Signed Original Contract Need to Courier">
                                          Signed Original Contract Need to
                                          Courier
                                        </option>
                                        <option value="Signed Original Contract yet to Receive">
                                          Signed Original Contract yet to
                                          Receive
                                        </option>
                                        <option value="Original Contract Signed by Both Parties">
                                          Original Contract Signed by Both
                                          Parties
                                        </option>
                                        <option value="Original Contract Signed and Received by Both Parties">
                                          Original Contract Signed and Received
                                          by Both Parties
                                        </option>
                                        <option value="Closed">Closed</option>
                                        <option value="Partially completed">
                                          Partially completed
                                        </option>
                                        <option value="Completed">
                                          Completed
                                        </option>
                                        <option value="Cancelled">
                                          Cancelled
                                        </option>
                                        <option value="Partially cancelled">
                                          Partially cancelled
                                        </option>
                                        <option value="Shipments - Arutmin">
                                          Shipments - Arutmin
                                        </option>
                                        <option value="Partially Signed Contract">
                                          Partially Signed Contract
                                        </option>
                                      </Form.Select>
                                    </FloatingLabel>
                                  </Form.Group>
                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingSelect"
                                      className="dropDown"
                                      label="Price type"
                                    >
                                      <Form.Select
                                        aria-label="Floating label select example"
                                        name="price_type"
                                        tabIndex={26}
                                        placeholder="Price type"
                                        value={datas.price_type || ""}
                                        onChange={handleChangeInput}
                                      >
                                        <option value="">Select</option>{" "}
                                        {getPrice.map((x) => {
                                          return (
                                            <option value={x.price_type_name}>
                                              {" "}
                                              {x.price_type_name}{" "}
                                            </option>
                                          );
                                        })}
                                      </Form.Select>
                                    </FloatingLabel>
                                  </Form.Group>
                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="Price basis GAR"
                                      className="mb-3"
                                    >
                                      <Form.Control
                                        type="number"
                                        placeholder="Price basis GAR"
                                        name="price_basis_GAR"
                                        tabIndex={30}
                                        defaultValue={
                                          datas.price_basis_GAR || ""
                                        }
                                        onChange={handleChangeInput}
                                      />
                                    </FloatingLabel>
                                  </Form.Group>
                                  <Form.Group as={Col} md="6" className='textEditor'>
                                    <label>Price  Adjustment Clause</label>
                                    <Editor value={priceAdjust} onChange={onChangePricingAdjust} tabIndex={27}/>
                                  </Form.Group>
                                  <Form.Group as={Col} md="6" className='textEditor'>
                                    <label>Rejections</label>
                                    <Editor value={rejections} onChange={onChangeRejections} tabIndex={31}/>
                                  </Form.Group>
                                  <Form.Group as={Col} md="6" className='textEditor' tabIndex={28}>
                                    <label>Description</label>
                                    <Editor value={description_1} onChange={onChangeDescription} />
                                  </Form.Group>
                                  <Form.Group as={Col} md="6"></Form.Group>
                                </Row>
                                <Row className="mb-3">
                                  <h4 className="heading">
                                    Other Standard Term From Supplier
                                  </h4>

                                  <Form.Group as={Col} md="6" className='textEditor'>
                                    <label>Pricing Terms</label>
                                    <Editor value={pricingTerm} onChange={onChangePricingTerm} tabIndex={32} />
                                  </Form.Group>
                                  <Form.Group as={Col} md="6"></Form.Group>

                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingSelect"
                                      className="dropDown"
                                      label="Applicable Law"
                                    >
                                      <Form.Select
                                        aria-label="Floating label select example"
                                        name="applicable_law"
                                        tabIndex={33}
                                        placeholder="Applicable Law"
                                        value={datas.applicable_law || ""}
                                        onChange={handleChangeInput}
                                      >
                                        <option value="">--None--</option>
                                        <option value="English Law">
                                          English Law
                                        </option>
                                        <option value="Singapore Law">
                                          Singapore Law
                                        </option>
                                        <option value="Indonesian Law">
                                          Indonesian Law
                                        </option>
                                      </Form.Select>
                                    </FloatingLabel>
                                  </Form.Group>
                                  <Form.Group as={Col} md="6"></Form.Group>
                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingSelect"
                                      className="dropDown"
                                      label="Arbitration"
                                    >
                                      <Form.Select
                                        aria-label="Floating label select example"
                                        name="arbitration"
                                        placeholder="Arbitration"
                                        tabIndex={34}
                                        value={datas.arbitration || ""}
                                        onChange={handleChangeInput}
                                      >
                                        <option value="">--None--</option>
                                        <option value="In Singapore as per SIAC">
                                          In Singapore as per SIAC
                                        </option>
                                        <option value="Singapore Chamber of Maritime Arbitration (SCMA)">
                                          Singapore Chamber of Maritime
                                          Arbitration (SCMA)
                                        </option>
                                        <option value="Laws of England and Wales">
                                          Laws of England and Wales
                                        </option>
                                        <option value="Rules of Badan Arbitrase Nasional Indonesia (BANI)">
                                          Rules of Badan Arbitrase Nasional
                                          Indonesia (BANI)
                                        </option>
                                      </Form.Select>
                                    </FloatingLabel>
                                  </Form.Group>
                                  <Form.Group as={Col} md="6"></Form.Group>
                                </Row>
                                {/* <Row className="mb-3">
                                  <h4 className="heading">
                                    Description Information
                                  </h4>
                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="Loading rate per day"
                                      className="mb-3"
                                    >
                                      <Form.Control
                                        type="text"
                                        placeholder="Loading rate per day"
                                        name="loading_rate_per_day"
                                        defaultValue={
                                          datas.loading_rate_per_day || ""
                                        }
                                        onChange={handleChangeInput}
                                      />
                                    </FloatingLabel>
                                  </Form.Group>
                                  <Form.Group as={Col} md="6"></Form.Group>
                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="Special Terms"
                                      className="mb-3"
                                    >
                                      <Form.Control
                                        as="textarea"
                                        placeholder="Special Terms"
                                        name="special_terms"
                                        defaultValue={datas.special_terms || ""}
                                        onChange={handleChangeInput}
                                      />
                                    </FloatingLabel>
                                  </Form.Group>
                                  <Form.Group as={Col} md="6"></Form.Group>
                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="Bonus penalty clause"
                                      className="mb-3"
                                    >
                                      <Form.Control
                                        as="textarea"
                                        placeholder="Bonus penalty clause"
                                        name="bonus_penalty_clause"
                                        defaultValue={
                                          datas.bonus_penalty_clause || ""
                                        }
                                        onChange={handleChangeInput}
                                      />
                                    </FloatingLabel>
                                  </Form.Group>
                                </Row> */}
                                <Row className="mb-3">
                                  <h4 className="heading">Address Information</h4>
                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingTextarea2"
                                      label="billing address"
                                    >
                                      <Form.Control
                                        as="textarea"
                                        tabIndex={35}
                                        placeholder="billing address"
                                        style={{ height: "100px!important" }}
                                        name="billing_address"
                                        defaultValue={datas.billing_address || ""}
                                        onChange={handleChangeInput}
                                      />
                                    </FloatingLabel>
                                  </Form.Group>
                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingTextarea2"
                                      label="shipping address"
                                    >
                                      <Form.Control
                                        as="textarea"
                                        tabIndex={36}
                                        placeholder="shipping address"
                                        style={{ height: "100px!important" }}
                                        name="shipping_address"
                                        defaultValue={datas.shipping_address || ""}
                                        onChange={handleChangeInput}
                                      />
                                    </FloatingLabel>
                                  </Form.Group>
                                  {/*
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing street"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing street"
                    name="billing_street"
                    {...register("billing_street", { required: false })}
                  />
                  {errors.billing_street && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping street"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Shipping street"
                    name="shipping_street"
                    {...register("shipping_street", { required: false })}
                  />
                  {errors.shipping_street && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing zip / postal code"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing zip / postal code"
                    name="billing_zip_postal_code"
                    {...register("billing_zip_postal_code", {
                      required: false,
                    })}
                  />
                  {errors.billing_zip_postal_code && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping zip / postal code"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Shipping zip / postal code"
                    name="shipping_zip_postal_code"
                    {...register("shipping_zip_postal_code", {
                      required: false,
                    })}
                  />
                  {errors.shipping_zip_postal_code && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing city"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing city"
                    name="billing_city"
                    {...register("billing_city", { required: false })}
                  />
                  {errors.billing_city && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping city"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Shipping city"
                    name="shipping_city"
                    {...register("shipping_city", { required: false })}
                  />
                  {errors.shipping_city && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing state / province"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing state / province"
                    name="billing_state_or_province"
                    {...register("billing_state_or_province", {
                      required: false,
                    })}
                  />
                  {errors.billing_state_or_province && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping state / province"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Shipping state / province"
                    name="shipping_state_or_province"
                    {...register("shipping_state_or_province", {
                      required: false,
                    })}
                  />
                  {errors.shipping_state_or_province && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing country"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing country"
                    name="billing_country"
                    {...register("billing_country", { required: false })}
                  />
                  {errors.billing_country && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping country"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Shipping country"
                    name="shipping_country"
                    {...register("shipping_country", { required: false })}
                  />
                  {errors.shipping_country && <span>Required </span>}
                </FloatingLabel>
              </Form.Group> */}
                                </Row>
                                <Row className="mb-3">
                                  <h4 className="heading">
                                    Signature Information
                                  </h4>
                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingSelect"
                                      className="dropDown"
                                      label="Customer signed by"
                                    >
                                      <Form.Select
                                        aria-label="Floating label select example"
                                        tabIndex={37}
                                        name="customer_signed_by"
                                        placeholder="Customer signed by"
                                        value={datas.customer_signed_by || ""}
                                        onChange={handleChangeInput}
                                      >
                                        <option value="">Select </option>{" "}
                                        {contacts.map((x) => {
                                          return (
                                            <option value={x.contact_full_name}>
                                              {" "}
                                              {x.contact_full_name}{" "}
                                            </option>
                                          );
                                        })}
                                      </Form.Select>
                                    </FloatingLabel>
                                  </Form.Group>
                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingSelect"
                                      className="dropDown"
                                      label="Company signed by"
                                    >
                                      <Form.Select
                                        aria-label="Floating label select example"
                                        name="company_signed_by"
                                        tabIndex={41}
                                        placeholder="Company signed by"
                                        value={datas.company_signed_by || ""}
                                        onChange={handleChangeInput}
                                      >
                                        <option value="">Select </option>{" "}
                                        {users && users.length > 0 && users.map((x) => {
                                          // Check if the username is not null or an empty string
                                          if (x.username !== null && x.username !== "") {
                                            return (
                                              <option key={x.username} value={x.username}>
                                                {x.username}
                                              </option>
                                            );
                                          } else {
                                            return null; // Skip rendering if username is null or empty
                                          }
                                        })}

                                      </Form.Select>
                                    </FloatingLabel>
                                  </Form.Group>
                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="Customer signed title"
                                      className="mb-3"
                                    >
                                      <Form.Control
                                        type="text"
                                        placeholder="Customer signed title"
                                        name="customer_signed_title"
                                        tabIndex={38}
                                        defaultValue={
                                          datas.customer_signed_title || ""
                                        }
                                        onChange={handleChangeInput}
                                      />
                                    </FloatingLabel>
                                  </Form.Group>
                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="Company Signed Date"
                                      className="mb-3 date-picker"
                                    >
                                      <DatePicker
                                        selected={signed}
                                        onChange={handleSigned}
                                        tabIndex={42}
                                        dateFormat="dd/MM/yyyy"
                                        placeholderText="Select Date"
                                      />
                                    </FloatingLabel>
                                  </Form.Group>
                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="Customer Signed Date"
                                      className="mb-3 date-picker"
                                    >
                                      <DatePicker
                                        selected={customersigned}
                                        onChange={handleCustomerSignedDate}
                                        tabIndex={39}
                                        selectsStart
                                        dateFormat="dd/MM/yyyy"
                                        placeholderText="Select"
                                      />
                                    </FloatingLabel>
                                  </Form.Group>
                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="Original Contract Recieved Date"
                                      className="mb-3 date-picker"
                                    >
                                      <DatePicker
                                        selected={originals}
                                        onChange={handleOriginal}
                                        dateFormat="dd/MM/yyyy"
                                        tabIndex={43}
                                        placeholderText="Select Date"
                                      />
                                    </FloatingLabel>
                                  </Form.Group>
                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="Original contract sent date"
                                      className="mb-3 date-picker"
                                    >
                                      <DatePicker
                                        selected={originalcontract}
                                        tabIndex={40}
                                        onChange={handleOriginalcontract}
                                        dateFormat="dd/MM/yyyy"
                                        placeholderText="Select Date"
                                      />
                                    </FloatingLabel>
                                  </Form.Group>
                                </Row>
                                <p className="edit-btn">
                                  <input
                                    type="submit"
                                    className="account-save"
                                    onClick={handleSaveEdit}
                                    value="Save"
                                    //disabled={isSubmitted}
                                  />
                                  <button
                                    className="btn btn-primary"
                                    onClick={handleCancelEdit}
                                  >
                                    {" "}
                                    Cancel{" "}
                                  </button>
                                </p>
                              </div>
                            ) : (
                              <div className="contactAccount fulid-section">
                                <div className="tasks">
                                  <p className="link-texts"><span><a href="#list-document">List of Documents</a> | </span><span><a href="#contract-product">Contract Products </a>|</span> <span><a href="#contract-ship">Contract Shipments </a>| </span><span>Products (Buyer Contract) |</span><span> <a href="#opportunity">Opportunities </a>|</span><span> <a href='#quotes'>Quotes  </a>|</span> <span><a href="#tcf">Trade Confirmation Forms </a>| </span><span><a href="#orders">Orders </a>|</span><span> Activity History | </span><span>Contract History  |</span></p>
                                  <div className="edit-delete">
                                    <span id="edit" onClick={handleEditClick}>
                                      {" "}
                                      Edit{" "}
                                    </span>
                                    <span>
                                      <Link
                                        to={"/contract/create-contract-list"}
                                      >
                                        Cancel
                                      </Link>
                                    </span>
                                    <span> Activate </span>
                                    <span> Generate Contract </span>
                                    <span> Generate Contract Clauses </span>
                                    <span> Shipment Checklist </span>
                                  </div>
                                  <div className="">
                                    <div className="">
                                      <div className="row">
                                        <div className="col-md-12" id="head">
                                          <h4 className="heading">
                                            Information
                                          </h4>
                                          <hr></hr>
                                        </div>
                                      </div>
                                      <table class="table table-bordered account-table tables">
                                        <tbody>

                                          <tr>

                                            <td id="td-right">
                                              <span>Locked</span>
                                            </td>
                                            <td>  <input
                                              type='checkbox'
                                              name='locked'
                                              checked={lockeds} // Use the state variable to determine if the checkbox is checked
                                              onChange={handleCheckboxChange} // Handle the change event
                                              className='mx-3' disabled={isButtonEnabled}
                                            /> </td>
                                             <td id="td-right">
                                              {/* <span>Contract Number</span> */}
                                            </td>
                                            <td>
                                              {/* {datas.contract_number} */}
                                            </td>
                                          </tr>

                                          <tr>
                                            <td id="td-right">
                                              <span>Currency</span>
                                            </td>
                                            <td> {datas.currency} </td>
                                            <td id="td-right">
                                              <span>Status</span>
                                            </td>
                                            <td>{datas.status}</td>
                                          </tr>
                                          <tr>
                                            <td id="td-right">
                                              <span>Exchange Rate</span>
                                            </td>
                                            <td> {datas.exchange_rate} </td>
                                            <td id="td-right">
                                              <span>Contract record type</span>
                                            </td>
                                            <td>
                                              {" "}
                                              {datas.contract_record_type}{" "}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td id="td-right">
                                              <span>Account Name</span>
                                            </td>
                                            <td>
                                              <Link
                                                to={
                                                  "/accounts/" +
                                                  datas.account_id
                                                }
                                              >
                                                {" "}
                                                {datas.account_name}{" "}
                                              </Link>
                                            </td>
                                            <td id="td-right">
                                              <span>Contract Owner</span>
                                            </td>
                                            <td> {datas.contract_owner} </td>
                                          </tr>
                                          <tr>
                                            <td id="td-right">
                                              <span>Buyer Billing Address
                                              </span>
                                            </td>
                                            <td>
                                              {datas.billing_address}{" "}
                                            </td>

                                            <td id="td-right">
                                              {/* <span>Contract term in months</span> */}
                                            </td>
                                            <td>
                                              {/* {datas.contract_term_in_months} */}
                                            </td>
                                          </tr>
                                          {/* <tr>
                                        <td id="td-right">
                                          <span>Contract Number</span>
                                        </td>
                                        <td><Link to={`/contract/contract-details/${datas.id}`}>{datas.contract_number}</Link></td>
                                        <td id="td-right">
                                          <span>Contract term in months</span>
                                        </td>
                                        <td>{datas.contract_term_in_months}</td>
                                      </tr> */}
                                      <tr>
                                        <td id="td-right">
                                          <span>Contract Number</span>
                                        </td>
                                        <td><Link to={`/contract/contract-details/${datas.id}`}>{datas.contract_number}</Link></td>
                                        <td id="td-right">
                                          {/* <span>Contract term in months</span> */}
                                        </td>
                                        <td>
                                          {/* {datas.contract_term_in_months} */}
                                          </td>
                                      </tr>


                                          <tr>
                                            <td id="td-right">
                                              <span>Rwood contract no</span>
                                            </td>
                                            <td> <Link to={`/contract/contract-details/${datas.id}`}>{datas.Rwood_Contract_No}</Link> </td>
                                            <td id="td-right">
                                              <span>
                                                Owner Expiration Notice{" "}
                                              </span>
                                            </td>
                                            <td>
                                              {" "}
                                              {
                                                datas.owner_expiration_notice
                                              }{" "}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td id="td-right">
                                              <span>Contract Start Date</span>
                                            </td>
                                            <td>
                                              {" "}
                                              {datas.contract_start_date}{" "}
                                            </td>
                                            <td id="td-right">
                                              <span>
                                                Contract term in months
                                              </span>
                                            </td>
                                            <td>
                                              {" "}
                                              {
                                                datas.contract_term_in_months
                                              }{" "}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td id="td-right">
                                              <span>Contract End Date</span>
                                            </td>
                                            <td> {datas.contract_end_date} </td>
                                            <td id="td-right">
                                              <span>Pending Matters</span>
                                            </td>
                                            <td> <div dangerouslySetInnerHTML={{ __html: datas.pending_matters }} /> </td>
                                          </tr>
                                          <tr>
                                            <td id="td-right">
                                              <span>
                                                Contract Effective Date
                                              </span>
                                            </td>
                                            <td>
                                              {" "}
                                              {
                                                datas.contract_effective_date
                                              }{" "}
                                            </td>
                                            <td id="td-right">
                                            <span className="a-a">
                                          Master Contract Reference{" "}
                                                <Tooltip text={<span>Give master contract reference, if any</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                                              </span>
                                            </td>
                                            <td>
                                              {" "}
                                              {
                                                datas.master_conference_reference
                                              }{" "}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td id="td-right">
                                              <span>Contract Status</span>
                                            </td>
                                            <td> {datas.contract_status} </td>
                                            <td id="td-right">
                                              <span>Renewal Date</span>
                                            </td>
                                            <td> {datas.renewal_date} </td>
                                          </tr>
                                          <tr>
                                            <td id="td-right">
                                              <span> Trader Name </span>
                                            </td>
                                            <td>{datas.trader_name !== null && datas.trader_name !== "" ? (
                                              <Link to={"/user/user-details/" + datas.rwood_user_id}>
                                                {datas.trader_name}
                                              </Link>
                                            ) : (
                                              <span>{datas.trader_name}</span>
                                            )}</td>

                                            <td id="td-right">
                                              <span>
                                                Renewal contract number
                                              </span>
                                            </td>
                                            <td>
                                              {" "}
                                              {
                                                datas.renewal_contract_number
                                              }{" "}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td id="td-right">
                                              <span>Company</span>
                                            </td>
                                            <td>
                                              <Link
                                                to={`/company/company-details/${datas.rwood_company_id}`}
                                              >
                                                {datas.company}
                                              </Link>
                                            </td>
                                            <td id="td-right">
                                            <span className="a-a">
                                          Remark{" "}
                                                <Tooltip text={<span>Any Specific Remark related to Contract</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                                              </span>
                                            </td>
                                            <td> <div dangerouslySetInnerHTML={{ __html: datas.remark }} /> </td>
                                          </tr>


                                        </tbody>
                                      </table>
                                    </div>
                                    <div className="">
                                      <div className="row">
                                        <div className="col-md-12" id="head">
                                          <h4 className="heading">
                                            Commodity and Contracted Quantity
                                          </h4>
                                          <hr></hr>
                                        </div>
                                      </div>
                                      <table class="table table-bordered account-table tables">
                                        <tbody>
                                          <tr>
                                            <td id="td-right">
                                              <span>Port Of Loading</span>
                                            </td>
                                            <td> {datas.port_of_loading} </td>
                                            <td id="td-right">
                                              <span>Origin</span>
                                            </td>
                                            <td> {datas.origin} </td>
                                          </tr>
                                          <tr>
                                            <td id="td-right">
                                              <span>
                                                Contract Quantity in MT
                                              </span>
                                            </td>
                                            <td>
                                              {" "}
                                              {
                                                datas.contract_quantity_in_MT
                                              }{" "}
                                            </td>
                                            <td id="td-right">
                                              <span>Commodity</span>
                                            </td>
                                            <td> {datas.commodity} </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                    <div className="">
                                      <div className="row">
                                        <div className="col-md-12" id="head">
                                          <h4 className="heading">
                                            Price and Price Adjustment
                                          </h4>
                                          <hr></hr>
                                        </div>
                                      </div>
                                      <table class="table table-bordered account-table tables">
                                        <tbody>
                                          <tr>
                                            <td id="td-right">
                                              <span>Price PMT</span>
                                            </td>
                                            <td> {datas.price_PMT} </td>

                                            <td id="td-right">
                                              {/* <span>Price Book</span> */}
                                            </td>
                                            <td>{/* {datas.price_book} */}</td>
                                          </tr>
                                          <tr>
                                            <td id="td-right">
                                              <span>Price PMT in words</span>
                                            </td>
                                            <td>
                                              {" "}
                                              {datas.price_PMT_in_words}{" "}
                                            </td>
                                            <td id="td-right">
                                              <span>Price Basis</span>
                                            </td>
                                            <td> {datas.price_basis} </td>
                                          </tr>
                                          <tr>
                                            <td id="td-right">
                                              <span>Price Type</span>
                                            </td>
                                            <td> {datas.price_type} </td>
                                            <td id="td-right">
                                              <span>Price Basis GAR</span>
                                            </td>
                                            <td> {datas.price_basis_GAR} </td>
                                          </tr>
                                          <tr>
                                            <td id="td-right">
                                              <span>
                                                Price adjustment clause
                                              </span>
                                            </td>
                                            <td>
                                              <div dangerouslySetInnerHTML={{ __html: datas.price_adjustment_clause }} />
                                            </td>
                                            <td id="td-right">
                                              <span>Rejections</span>
                                            </td>
                                            <td> <div dangerouslySetInnerHTML={{ __html: datas.rejections }} /> </td>
                                          </tr>
                                          <tr>
                                            <td id="td-right">
                                              <span>description</span>
                                            </td>
                                            <td> <div dangerouslySetInnerHTML={{ __html: datas.description }} /> </td>
                                            <td id="td-right">
                                              <span></span>
                                            </td>
                                            <td>  </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                    <div className="">
                                      <div className="row">
                                        <div className="col-md-12" id="head">
                                          <h4 className="heading">
                                            Other Standard Term From Supplier
                                          </h4>
                                          <hr></hr>
                                        </div>
                                      </div>
                                      <table class="table table-bordered account-table tables">
                                        <tbody>
                                          <tr>
                                            <td id="td-right">
                                              <span>Pricing Terms</span>
                                            </td>
                                            <td> <div dangerouslySetInnerHTML={{ __html: datas.pricing_terms }} /> </td>
                                            <td id="td-right">
                                            </td>
                                            <td></td>
                                          </tr>

                                          {/* <tr>
                                            <td id="td-right">
                                              <span>Payment Terms</span>
                                            </td>
                                            <td> <div dangerouslySetInnerHTML={{ __html: datas.payment_terms }} /> </td>
                                            <td id="td-right">
                                            </td>
                                            <td> </td>
                                          </tr> */}
                                          <tr>
                                            <td id="td-right">
                                              <span>Applicable Law</span>
                                            </td>
                                            <td> {datas.applicable_law} </td>
                                            <td id="td-right">
                                              {/* <span>Arbitration</span> */}
                                            </td>
                                            <td> </td>
                                          </tr>
                                          <tr>
                                            <td id="td-right">
                                              <span>Arbitration</span>
                                            </td>
                                            <td> {datas.arbitration} </td>
                                            <td id="td-right">
                                              {/* <span>General Terms</span> */}
                                            </td>
                                            <td> </td>
                                          </tr>
                                          {/* <tr>
                                            <td id="td-right">
                                              <span>General Terms</span>
                                            </td>
                                            <td> <div dangerouslySetInnerHTML={{ __html: datas.general_terms }} /> </td>
                                            <td id="td-right">
                                            </td>
                                            <td> </td>
                                          </tr> */}
                                        </tbody>
                                      </table>
                                    </div>
                                    {/* <div className="">
                                      <div className="row">
                                        <div className="col-md-12" id="head">
                                          <h4 className="heading">
                                            Description Information
                                          </h4>
                                          <hr></hr>
                                        </div>
                                      </div>
                                      <table class="table table-bordered account-table tables">
                                        <tbody>
                                          <tr>
                                            <td id="td-right">
                                              <span>Loading rate per day</span>
                                            </td>
                                            <td>
                                              {" "}
                                              {datas.loading_rate_per_day}{" "}
                                            </td>
                                            <td id="td-right">
                                              {/* <span>Special Terms</span>
                                            </td>
                                            <td> </td>
                                          </tr>
                                          <tr>
                                            <td id="td-right">
                                              <span>Special Terms</span>
                                            </td>
                                            <td> {datas.special_terms} </td>
                                            <td id="td-right">
                                              {/* <span>Special Terms</span>
                                            </td>
                                            <td> </td>
                                          </tr>
                                          <tr>
                                            <td id="td-right">
                                              <span>Bonus penalty clause</span>
                                            </td>
                                            <td>
                                              {" "}
                                              <div dangerouslySetInnerHTML={{ __html: datas.bonus_penalty_clause }} />{" "}
                                            </td>
                                            <td id="td-right"></td>
                                            <td></td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div> */}
                                    <div className="">
                                      <div className="row">
                                        <div className="col-md-12" id="head">
                                          <h4 className="heading">
                                            Address Information
                                          </h4>
                                          <hr></hr>
                                        </div>
                                      </div>
                                      <table class="table table-bordered account-table tables">
                                        <tbody>
                                          <tr>
                                            <td id="td-right">
                                              <span>Billing Address</span>
                                            </td>
                                            <td> {datas.billing_address} </td>
                                            <td id="td-right">
                                              <span>Shipping Address</span>
                                            </td>
                                            <td> {datas.shipping_address} </td>
                                          </tr>

                                        </tbody>
                                      </table>
                                    </div>
                                    <div className="">
                                      <div className="row">
                                        <div className="col-md-12" id="head">
                                          <h4 className="heading">
                                            Signature Information
                                          </h4>
                                          <hr></hr>
                                        </div>
                                      </div>
                                      <table class="table table-bordered account-table tables">
                                        <tbody>

                                          <tr>
                                            <td id="td-right">
                                              <span>Customer signed by</span>
                                            </td>
                                            <td>{datas.customer_signed_by}</td>
                                            <td id="td-right">
                                              <span>Company signed by</span>
                                            </td>
                                            <td>{datas.company_signed_by}</td>
                                          </tr>
                                          <tr>
                                            <td id="td-right">
                                              <span>Customer signed title</span>
                                            </td>
                                            <td>
                                              {datas.customer_signed_title}
                                            </td>

                                            <td id="td-right">
                                              <span>Company signed date</span>
                                            </td>
                                            <td>{datas.company_signed_date}</td>
                                          </tr>
                                          <tr>
                                            <td id="td-right">
                                              <span>Customer signed date</span>
                                            </td>
                                            <td>
                                              {datas.customer_signed_date}
                                            </td>
                                            <td id="td-right">
                                              <span>
                                                Original contract recieved date
                                              </span>
                                            </td>
                                            <td>
                                              {
                                                datas.original_contract_recieved_date
                                              }
                                            </td>
                                          </tr>
                                          <tr>
                                            <td id="td-right">
                                              <span>
                                                {/* Original contract sent date */}
                                              </span>
                                            </td>
                                            <td>
                                              {
                                                // datas.original_contract_sent_date
                                              }
                                            </td>
                                            <td id="td-right"></td>
                                            <td></td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                    <div className="">
                                      <div className="row">
                                        <div className="col-md-12" id="head">
                                          <h4 className="heading">
                                            System Information
                                          </h4>
                                          <hr></hr>
                                        </div>
                                      </div>
                                      <table class="table table-bordered account-table tables">
                                        <tbody>

                                          <tr>
                                            <td id="td-right">
                                              <span>Activated By
                                              </span>
                                            </td>
                                            <td>{datas.activated_by
                                            }</td>
                                            <td id="td-right">
                                              <span>Activated Date
                                              </span>
                                            </td>
                                            <td>{datas.activated_date
                                            }</td>
                                          </tr>
                                          <tr>
                                            <td id="td-right">
                                              <span>Created By
                                              </span>
                                            </td>
                                            <td>
                                              {datas.created_by
                                              }
                                            </td>

                                            <td id="td-right">
                                              <span>Last Modified By
                                              </span>
                                            </td>
                                            <td>{datas.last_modified_by
                                            }</td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="tasks view-table"
                                  Style="box-showdow:0px 13px 20px #d6d1d1"
                                >







                                  <Row id="table-styles">
                                    <div className="col-md-12" id="head">
                                      <h4 className="heading">
                                        <span>Contract Shipment</span>
                                        <span>
                                          <Popup
                                            trigger={
                                              <a className="button add-accountrecord">
                                                {" "}
                                                New{" "}
                                              </a>
                                            }
                                            modal
                                          >
                                            <form
                                              onSubmit={handleSubmit}
                                              className="add-accounts"
                                            >
                                              <div className="form-group">
                                                <label>
                                                  Contract Shipment Record Type
                                                </label>
                                                <br />
                                                <select
                                                  name="account_record"
                                                  className="form-control"
                                                  onChange={handleChange}
                                                  value={formData.account_record}
                                                >
                                                  <option value="">--Select record type--</option>
                                                  <option value="Buyer - Long Term">
                                                    Buyer - Long Term
                                                  </option>
                                                  <option value="Buyer - Spot">
                                                    Buyer - Spot
                                                  </option>
                                                  <option value="Supplier - Long Term">
                                                    Supplier - Long Term
                                                  </option>
                                                  <option value="Supplier - Spot">
                                                    Supplier - Spot
                                                  </option>
                                                </select>
                                                {error.account_record && <div className="errors-msg">{error.account_record}</div>}
                                              </div>
                                              <input
                                                type="submit"
                                                className="btn btn-primary "
                                                value="Submit"
                                              />
                                            </form>
                                          </Popup>
                                        </span>
                                      </h4>

                                      <hr></hr>
                                    </div>
                                    <div id="inner-heading"></div>
                                    {conShipmentParents.length > 0 ? (
                                      <table id="contract-ship" className="">
                                        <thead>
                                          <tr>
                                            <th>Record Type</th>
                                            <th>Contract Ship Code</th>
                                            <th>Shipment Status</th>
                                            <th>Parent Shipment</th>
                                            <th>Pricing</th>
                                            <th>Laycan</th>
                                            <th>Quantity Info</th>
                                            <th>No. of Shipments</th>
                                            <th>No. of Orders</th>
                                            <th>TCF Count</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {conShipmentParents.map((y, i) => (
                                            <tr key={i}>
                                              <td>{y.record_type}</td>
                                              <td>
                                                <Link to={`/shipment/contract-shipment-details/${y.id}`}>
                                                  <span id="codes">{y.contract_ship_code}</span>
                                                </Link>
                                              </td>
                                              <td>{y.contract_shipment_status}</td>
                                              <td>
                                                {y.contract_ship_code === null ? (
                                                  <Link to={`/shipment/contract-shipment-details/${y.id}`}>
                                                    <span id="codes">{y.contract_ship_code}</span>
                                                  </Link>
                                                ) : (
                                                  y.parent_shipment !== null && y.parentship_id !== null && (
                                                    <Link to={`/shipment/contract-shipment-details/${y.parentship_id}`}>
                                                      {y.parent_shipment}
                                                    </Link>
                                                  )
                                                )}
                                              </td>
                                              <td>{y.pricing}</td>
                                              <td>{y.laycan}</td>
                                              <td>{y.quantity_info}</td>
                                              <td>{y.no_of_shipments}</td>
                                              <td>{y.no_of_orders}</td>
                                              <td>{y.tcf_count}</td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </table>
                                    ) : (
                                      <p>No data available</p>
                                    )}
                                  </Row>
                                  <Row id="table-styles">
                                    <div className="col-md-12" id="head">
                                      <h4 className="heading">
                                        <span>Products</span>
                                      </h4>
                                      <hr>
                                      </hr>
                                    </div>
                                    <table id="history" className="">
                                      <tr>
                                        <td>
                                          <p class="no-records"> No records to display </p>
                                        </td>
                                      </tr>
                                    </table>
                                  </Row>
                                  <Row id="table-styles">
                                    <div className="col-md-12" id="head">
                                      <h4 className="heading">
                                        <span>Trade Confirmation Forms</span>
                                        <span>
                                          <Popup
                                            trigger={
                                              <a className="button add-accountrecord">
                                                {" "}
                                                New{" "}
                                              </a>
                                            }
                                            modal
                                          >
                                            <form
                                              onSubmit={handleSubmittcf}
                                              className="add-accounts"
                                            >
                                              <div className="form-group">
                                                <label>
                                                  TCF Record Type
                                                </label>
                                                <br />
                                                <select
                                                  name="account_record"
                                                  className="form-control"
                                                  onChange={handleChangetcf}
                                                  value={
                                                    formData.account_record
                                                  }
                                                >
                                                  <option>
                                                    --Select TCF Record
                                                    Type--
                                                  </option>
                                                  <option value="Buyer - Spot">
                                                    Buyer - Spot
                                                  </option>
                                                  <option value="Buyer - Long Term">
                                                    Buyer - Long Term
                                                  </option>
                                                </select>
                                              </div>
                                              <input
                                                type="submit"
                                                className="btn btn-primary "
                                                value="Submit"
                                              />
                                            </form>
                                          </Popup>
                                        </span>
                                      </h4>
                                      <hr></hr>
                                    </div>
                                    <table id="tcf" className="">
                                      <thead>
                                        <tr>
                                          <th>TCF No</th>
                                          <th>TCF Status</th>
                                          <th>BL Quantity</th>
                                          <th>BL date</th>
                                          <th>Laycan</th>
                                          <th>contract shipment</th>
                                          <th>product</th>
                                          <th>Vessel Name	</th>
                                          <th>Proposed Laycan From	</th>
                                          <th>Proposed Laycan To</th>
                                          <th>Ship Month</th>
                                        </tr>
                                      </thead>

                                      {tcf.length > 0 ? (
                                        <tbody>
                                          {tcf.map((y, i) => (
                                            <tr>
                                              <td>
                                                <span id="codes">
                                                  <Link
                                                    to={`/tcf/tcf-details/${y.id}`}
                                                  >
                                                    {y.tcf_no}
                                                  </Link>
                                                </span>
                                              </td>
                                              <td>{y.tcf_status}</td>
                                              <td>{y.actual_bl_qty}</td>
                                              <td>{y.actual_bl_date}</td>
                                              <td>{y.laycan}</td>
                                              <td><Link to={`/shipment/contract-shipment-details/${y.contract_ship_id}`}>{y.contract_shipment}</Link></td>
                                              <td><Link to={`/product/product-detail/${y.product_id}`}>{y.product}</Link></td>
                                              <td>{y.vessel_name}</td>
                                              <td>{y.proposed_laycan_from}</td>
                                              <td>{y.proposed_laycan_to}</td>
                                              <td>{y.ship_month
                                              }</td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      ) : (
                                        <tbody>
                                          <tr>
                                            <td></td><td></td>
                                            <td></td>
                                            <td></td> <td></td> <td></td>
                                            <td>No data available</td>
                                            <td></td>
                                            <td></td>
                                            <td></td><td></td>
                                          </tr>
                                        </tbody>
                                      )}
                                    </table>
                                  </Row>
                                  <Row id="table-styles">
                                    <div className="col-md-12" id="head">
                                      <h4 className="heading">
                                        <span>Contract History
                                        </span>
                                      </h4>
                                      <hr>
                                      </hr>
                                    </div>
                                    <table id="history" className="">
                                      <tr>
                                        <td>
                                          <p class="no-records"> No records to display </p>
                                        </td>
                                      </tr>
                                    </table>
                                  </Row>
                                  <Row id="table-styles">
                                    <div className="col-md-12" id="head">
                                      <h4 className="heading">
                                        <span>Open Activities

                                        </span>
                                      </h4>
                                      <hr>
                                      </hr>
                                    </div>
                                    <table id="history" className="">
                                      <tr>
                                        <td>
                                          <p class="no-records"> No records to display </p>
                                        </td>
                                      </tr>
                                    </table>
                                  </Row>
                                  <Row id="table-styles">
                                    <div className="col-md-12" id="head">
                                      <h4 className="heading">
                                        <span>Activity History


                                        </span>
                                      </h4>
                                      <hr>
                                      </hr>
                                    </div>
                                    <table id="history" className="">
                                      <tr>
                                        <td>
                                          <p class="no-records"> No records to display </p>
                                        </td>
                                      </tr>
                                    </table>
                                  </Row>
                                  <Row id="table-styles">
                                    <div
                                      className="col-md-12"
                                      id="head"
                                    >
                                      <h4 className="heading">
                                        <span>
                                          Opportunity Information{" "}
                                        </span>{" "}
                                        {/* <Popup trigger={ <span className="button add-accountrecord"> New </span>} modal> <form onSubmit={handleSubmitOpp} className="add-accounts">
                      <div className='form-group'>
                        <label>Opportunity Record Type</label>
                        <br />
                        <select className='form-control' name="type_of_new_record" onChange={handleChangeOpp} value={formDataopp.account_record}>
                          <option>----Select----</option>
                          <option value='Buyer'>Buyer</option>
                          <option value='Buyer - Long Term'>Buyer - Long Term</option>
                          <option value='Buyer - Spot'>Buyer - Spot</option>
                          <option value='Supplier'>Supplier</option>
                          <option value='Supplier - Long Term'>Supplier - Long Term</option>
                          <option value='Supplier - Spot'>Supplier - Spot</option>
                        </select>
                      </div>
                      <input type='submit' className="btn btn-primary " value='Submit' />
                    </form>
                  </Popup> */}
                                      </h4>
                                      <hr></hr>
                                    </div>
                                    <table
                                      id="opportunity"
                                      className=""
                                    >
                                      <thead>
                                        <tr>
                                          <th>Opportunity Name</th>

                                          <th>Opportunity No</th>
                                          <th>Stage</th>
                                          <th>Quantity (MT) </th>
                                          <th>Supplier</th>
                                          <th>No of Order</th>
                                        </tr>
                                      </thead>{" "}
                                      {opportunity.length > 0 ? (
                                        <tbody>
                                          {" "}
                                          {opportunity.map((y, i) => (
                                            <tr>
                                              <td>
                                                <Link
                                                  to={`/opportunity/opportunity-detail/${y.id}`}
                                                >
                                                  {y.opportunity_name}
                                                </Link>
                                              </td>
                                              <td>
                                                <Link
                                                  to={`/opportunity/opportunity-detail/${y.id}`}
                                                >
                                                  {y.opportunity_no}
                                                </Link>
                                              </td>
                                              <td>{y.stage}</td>
                                              <td>{y.quantity_mt}</td>
                                              <td><Link to={`/accounts/${y.supplier_id}`}>{y.supplier} </Link></td>
                                              <td>{y.no_of_order}</td>
                                            </tr>
                                          ))}{" "}
                                        </tbody>
                                      ) : (
                                        <tbody>
                                          <tr>
                                            <td></td>
                                            <td></td>
                                            <td>No data available</td>
                                            <td></td>
                                            <td></td><td></td>
                                          </tr>
                                        </tbody>
                                      )}
                                    </table>
                                  </Row>
                                  <Row id="table-styles">
                                    <div className="col-md-12" id="head">
                                      <h4 className="heading">
                                        <span>Quotes</span>
                                      </h4>
                                      <hr></hr>
                                    </div>
                                    <table id="quotes" className="">
                                      <thead>
                                        <tr>
                                          <th>Quote Number</th>
                                          <th>Quote Name</th>
                                          <th>Account Name</th>
                                          <th>Contact Name</th>
                                          <th>Expiration Date</th>
                                          <th>Product Description	</th>
                                          <th>Status</th>

                                        </tr></thead>
                                      {quoteTable.length > 0 ? (
                                        <tbody>
                                          {quoteTable.map((y, i) => (
                                            <tr>
                                              <td>
                                                <span id="codes">
                                                  <Link
                                                    to={`/opportunity/quote-view/${y.id}`}
                                                  >
                                                    {y.quote_number}
                                                  </Link>
                                                </span>
                                              </td>
                                              <td><Link to={`/opportunity/quote-view/${y.id}`}>{y.quote_name}  </Link> </td>
                                              <td><Link to={`/accounts/${y.account_id}`}>{y.account_name} </Link></td>
                                              <td>{y.contact_name}</td>
                                              <td>{y.expiration_date}</td>
                                              <td>{y.product_description}</td>
                                              <td>{y.status}</td>

                                            </tr>
                                          ))}
                                        </tbody>
                                      ) : (
                                        <tbody>
                                          <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>No data available</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                          </tr>
                                        </tbody>
                                      )}
                                    </table>
                                  </Row>
                                  <Row id="table-styles">
                                    <div className="col-md-12" id="head">
                                      <h4 className="heading">
                                        <span>Orders  </span>
                                      </h4>
                                      <hr></hr>
                                    </div>
                                    <table id="orders" className="">
                                      <thead>
                                        <tr>
                                          <th>Order Number</th>
                                          <th>  Order Start Date</th>
                                          <th>Contract Shipment</th>
                                          <th>Vessel Barge Name	</th>
                                          <th>Ship Month    </th>
                                          <th>Laycan From Date</th>
                                          <th>Status</th>
                                          <th>Shipment Qty</th>
                                          <th>Account Name</th>
                                        </tr>
                                      </thead>

                                      {orderData.length > 0 ? (
                                        <tbody>
                                          {orderData.map((y, i) => (
                                            <tr>
                                              <td> <Link
                                                to={`/order/view-order-details/${y.id}`}
                                              >{y.order_no}</Link></td>
                                              <td>{y.order_start_date}</td>
                                              <td><Link to={`/shipment/contract-shipment-details/${y.contract_ship_id}`}>{y.contract_shipment}</Link></td>
                                              <td>{y.vessel_barge_name}</td>
                                              <td>{y.ship_month}</td>
                                              <td>{y.laycan_from_date}</td>
                                              <td>{y.status}</td>
                                              <td>{y.shipment_qty}</td>
                                              <td><Link to={`/accounts/${y.account_id}`}>{y.account_name} </Link></td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      ) : (
                                        <tbody>
                                          <tr><td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>No data available</td>
                                            <td></td>
                                            <td></td>
                                            <td></td><td></td>
                                          </tr>
                                        </tbody>
                                      )}
                                    </table>
                                  </Row>

                                  <Row id="table-styles">
                                    <div className="col-md-12" id="head">
                                      <h4 className="heading">
                                        <span>Notes & Attachments</span>
                                        <span>
                                          <i className=""></i>
                                          New Note
                                        </span>
                                        <span>
                                          <i className=""></i>
                                          Attach File
                                        </span>
                                      </h4>
                                      <hr></hr>
                                    </div>
                                    <table id="history" className="">
                                      <tr>
                                        <td>
                                          <p class="no-records">No records to display</p>
                                        </td>
                                      </tr>
                                    </table>
                                  </Row>
                                  <Row id="table-styles">
                                    <div className="col-md-12" id="head">
                                      <h4 className="heading">
                                        <span>Product Masters
                                        </span>
                                        <span>
                                          <i className=""></i>
                                          New Product Masters

                                        </span>

                                      </h4>
                                      <hr></hr>
                                    </div>
                                    <table id="history" className="">
                                      <tr>
                                        <td>
                                          <p class="no-records">No records to display</p>
                                        </td>
                                      </tr>
                                    </table>
                                  </Row>
                                  <Row id="table-styles">
                                    <div className="col-md-12" id="head">
                                      <h4 className="heading">
                                        <span>Notes</span>
                                        <span>
                                          <i className=""></i>
                                          New Note
                                        </span>
                                      </h4>
                                      <hr></hr>
                                    </div>
                                    <table id="history" className="">
                                      <tr>
                                        <td>
                                          <p class="no-records">No records to display</p>
                                        </td>
                                      </tr>
                                    </table>
                                  </Row>

                                  <Row id="table-styles">
                                    <div className="col-md-12" id="head">
                                      <h4 className="heading">
                                        <span>Files</span>
                                        <span>
                                          <i className=""></i>
                                          Upload Files
                                        </span>
                                      </h4>
                                      <hr></hr>
                                    </div>
                                    <table id="history" className="">
                                      <tr>
                                        <td>
                                          <p class="no-records">No records to display</p>
                                        </td>
                                      </tr>
                                    </table>
                                  </Row>


                                  <Row id="table-styles">
                                    <div className="col-md-12" id="head">
                                      <h4 className="heading">
                                        <span>List of Documents</span>
                                        <span>
                                          <Link
                                            to={"/contract/add-document/" + id}
                                          >
                                            <i className="fe fe-download-cloud me-2"></i>{" "}
                                            New{" "}
                                          </Link>
                                        </span>
                                      </h4>
                                      <hr></hr>
                                    </div>
                                    <table id="document" className="">
                                      <thead>
                                        <tr>
                                          <th>contract list of docs</th>
                                          <th>document required name</th>
                                          <th>list of docuemnts</th>
                                          <th>original in no</th>
                                          <th>Copies In No</th>
                                        </tr>
                                      </thead>{" "}
                                      {document.length > 0 ? (
                                        <tbody>
                                          {" "}
                                          {document.map((y, i) => (
                                            <tr>
                                              <td className="">
                                                <Link
                                                  to={
                                                    "/contract/document-view/" +
                                                    y.id
                                                  }
                                                >
                                                  {y.contract_list_of_docs}
                                                </Link>
                                              </td>
                                              <td>
                                                {y.document_required_name}
                                              </td>
                                              <td>{y.list_of_docuemnts}</td>
                                              <td>{y.original_in_no}</td>
                                              <td>{y.copies_in_no}</td>
                                            </tr>
                                          ))}{" "}
                                        </tbody>
                                      ) : (
                                        <tbody>
                                          <tr>
                                            <td></td>
                                            <td></td>
                                            <td>No data available</td>
                                            <td></td>
                                            <td></td>
                                          </tr>
                                        </tbody>
                                      )}
                                    </table>
                                  </Row>
                                  <Row id="table-styles">
                                    <div className="col-md-12" id="head">
                                      <h4 className="heading">
                                        <span>History Information</span>
                                      </h4>
                                      <hr></hr>
                                    </div>
                                    <table id="historys" className="">
                                      <thead>
                                        <tr>
                                          <th>Field Name</th>
                                          <th>New Value</th>
                                          <th>Old Value</th>
                                          <th>Changed By</th>
                                          <th>Change Time</th>
                                        </tr>
                                      </thead>{" "}
                                      {history.length > 0 ? (
                                        <tbody>
                                          {" "}
                                          {history.map((y, i) => (
                                            <tr>
                                              <td>{y.field_name}</td>
                                              <td>{y.new_value}</td>
                                              <td>{y.old_value}</td>
                                              <td>{y.changed_by}</td>
                                              <td>{y.change_time}</td>
                                            </tr>
                                          ))}{" "}
                                        </tbody>
                                      ) : (
                                        <tbody>
                                          <tr>
                                            <td></td>
                                            <td></td>
                                            <td>No data available</td>
                                            <td></td>
                                            <td></td>
                                          </tr>
                                        </tbody>
                                      )}
                                    </table>
                                  </Row>
                                </div>
                              </div>
                            )}
                          </>
                        ) : (
                          <>
                            {datas.contract_record_type === "Supplier" ? (
                              <>
                                {isEditMode ? (
                                  <>
                                    <div className="tasks">
                                      <p className="edit-btn">
                                        <input
                                          type="submit"
                                          className="account-save"
                                          onClick={handleSaveEdit}
                                          value="Save"
                                          //disabled={isSubmitted}
                                        />
                                        <button
                                          className="btn btn-primary"
                                          onClick={handleCancelEdit}
                                        >
                                          Cancel
                                        </button>
                                      </p>
                                      <p className="errors">{errors}</p>
                                      <Row className="mb-3">
                                        <h4 className="heading">Contract Information</h4>
                                        <Form.Group as={Col} md="6" id="lockeds">
                                          <input
                                            type="checkbox"
                                            name="locked"
                                            onChange={(e) => setCheck1(e.target.checked)}
                                          />
                                          <label>Locked</label>
                                        </Form.Group>

                                        <Form.Group as={Col} md="6">
                                          <FloatingLabel
                                            controlId="floatingSelect"
                                            className="dropDown"
                                            label="Company"
                                          >
                                            <Form.Select
                                              aria-label="Floating label select example"
                                              name="company"
                                              placeholder="Company"
                                              className="error-validation"
                                              value={datas.company || ""}
                                              onChange={handleChangeInput}
                                            >
                                              <option value="">Select </option>
                                              {companyData.map((x, i) => {
                                                return (
                                                  <option key={i} value={x.company_name}>
                                                    {x.company_name}
                                                  </option>
                                                );
                                              })}
                                            </Form.Select>
                                          </FloatingLabel>
                                        </Form.Group>

                                        <Form.Group as={Col} md="6">
                                          <FloatingLabel
                                            controlId="floatingSelect"
                                            className="dropDown"
                                            label="Currency"
                                          >
                                            <Form.Select
                                              aria-label="Floating label select example"
                                              name="currency"
                                              placeholder="Currency"
                                              className="error-validation"
                                              value={datas.currency || ""}
                                              onChange={handleChangeInput}
                                            >
                                              <option value="">Select </option>
                                              <option value="USD">USD </option>
                                              <option value="IDR">IDR </option>
                                            </Form.Select>
                                          </FloatingLabel>
                                        </Form.Group>

                                        <Form.Group as={Col} md="6">
                                          <FloatingLabel
                                            controlId="floatingSelect"
                                            className="dropDown"
                                            label="Trader Name"
                                          >
                                            <Form.Select
                                              aria-label="Floating label select example"
                                              name="trader_name"
                                              placeholder="Trader Name"
                                              value={datas.trader_name || ""}
                                              onChange={handleChangeInput}
                                            >
                                              <option value="">Select </option>
                                              {traderData.map((x, i) => {
                                                return (
                                                  <option key={i} value={x.rwood_username}>
                                                    {x.rwood_username}
                                                  </option>
                                                );
                                              })}
                                            </Form.Select>
                                          </FloatingLabel>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6">
                                          <FloatingLabel
                                            controlId="floatingInput"
                                            label="Exchange rate"
                                            className="mb-3"
                                          >
                                            <Form.Control
                                              type="text"
                                              placeholder="Exchange rate "
                                              name="exchange_rate"
                                              defaultValue={datas.exchange_rate || ""}
                                              onChange={handleChangeInput}
                                            />
                                          </FloatingLabel>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6">
                                          <FloatingLabel
                                            controlId="floatingInput"
                                            label="Contract Owner"
                                            className="mb-3"
                                          >
                                            <Form.Control
                                              type="text"
                                              placeholder="Contract Owner"
                                              name="contract_owner"
                                              defaultValue={datas.contract_owner || ""}
                                              onChange={handleChangeInput}
                                            />
                                          </FloatingLabel>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6">
                                          <FloatingLabel
                                            controlId="floatingInput"
                                            label="Contract record type"
                                            className="mb-3"
                                          >
                                            <Form.Control
                                              type="text"
                                              placeholder="Contract record type"
                                              name="contract_record_type"
                                              className="record-type"
                                              defaultValue={datas.contract_record_type}
                                              onChange={handleChangeInput}
                                            />
                                          </FloatingLabel>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6">
                                          {" "}
                                        </Form.Group>
                                        {/* <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Price book">
    <Form.Select aria-label="Floating label select example"
    name="price_book"
    placeholder="Price book"
     value={datas.price_book || ""}
onChange={handleChangeInput}
    >
      <option value='' >Select</option>
       {
          priceBook.map((x)=>{
              return(
                  <option value={x.price_book_name}>{x.price_book_name}</option>
              )
          })
       }
    </Form.Select>

  </FloatingLabel>
              </Form.Group>
               */}
                                        <Form.Group as={Col} md="6">
                                          <FloatingLabel
                                            controlId="floatingInput"
                                            label="Rwood contract no"
                                            className="mb-3"
                                          >
                                            <Form.Control
                                              type="text"
                                              placeholder="Rwood contract no"
                                              name="Rwood_Contract_No"
                                              defaultValue={datas.Rwood_Contract_No || ""}
                                              onChange={handleChangeInput}

                                            />
                                          </FloatingLabel>
                                        </Form.Group>
                                        {/* <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Status"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="status"
                    placeholder="Status"
                    value={datas.status || ""}
onChange={handleChangeInput}
                  >
                    <option value="">Select </option>
                    <option value="Draft">Draft </option>
                    <option value="Approved">Approved </option>
                  </Form.Select>

                </FloatingLabel>
              </Form.Group> */}
                                        <Form.Group as={Col} md="6">
                                          {" "}
                                        </Form.Group>

                                        <Form.Group as={Col} md="6">
                                          <FloatingLabel
                                            controlId="floatingInput"
                                            label="Contract Number"
                                            className="mb-3"
                                          >
                                            <Form.Control
                                              type="text"
                                              placeholder="Contract Number"
                                              name="contract_number"
                                              defaultValue={datas.contract_number || ""}
                                              onChange={handleChangeInput} disabled
                                            />
                                          </FloatingLabel>
                                        </Form.Group>

                                        <Form.Group as={Col} md="6">
                                          <FloatingLabel
                                            controlId="floatingSelect"
                                            className="dropDown"
                                            label="Supplier"
                                          >
                                            <Form.Select
                                              aria-label="Floating label select example"
                                              name="supplier"
                                              placeholder="Supplier"
                                              className="error-validation"
                                              value={datas.supplier || ""}
                                              onChange={handleChangeInput}
                                            >
                                              <option value="">Select </option>
                                              {supplierData.map((x) => {
                                                return (
                                                  <option value={x.account_name}>
                                                    {x.account_name}
                                                  </option>
                                                );
                                              })}
                                            </Form.Select>
                                          </FloatingLabel>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6">
                                          <FloatingLabel
                                            controlId="floatingSelect"
                                            className="dropDown"
                                            label="Account Name"
                                          >
                                            <Form.Select
                                              aria-label="Floating label select example"
                                              name="account_name"
                                              placeholder="Account Name"
                                              className="error-validation"
                                              value={datas.account_name || ""}
                                              onChange={handleChangeInput}
                                            >
                                              <option value="">Select </option>
                                              {accountName.map((x) => {
                                                return (
                                                  <option value={x.account_name}>
                                                    {x.account_name}
                                                  </option>
                                                );
                                              })}
                                            </Form.Select>
                                          </FloatingLabel>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6">
                                          <FloatingLabel
                                            controlId="floatingInput"
                                            label="Contract term in months"
                                            className="mb-3"
                                          >
                                            <Form.Control
                                              type="text"
                                              placeholder="Contract term in months"
                                              name="contract_term_in_months"
                                              defaultValue={
                                                datas.contract_term_in_months || ""
                                              }
                                              onChange={handleChangeInput}
                                            />
                                          </FloatingLabel>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6">
                                          <FloatingLabel
                                            controlId="floatingSelect"
                                            className="dropDown"
                                            label="Contract Status"
                                          >
                                            <Form.Select
                                              aria-label="Floating label select example"
                                              name="contract_status"
                                              placeholder="Contract Status"
                                              value={datas.contract_status || ""}
                                              onChange={handleChangeInput}
                                            >
                                              <option value="In Approval Process">
                                                In Approval Process
                                              </option>
                                              <option value="Under Review">Under Review</option>
                                              <option value="Activated">Activated</option>
                                              <option value="Draft" selected>Draft</option>
                                              <option value="Under Review">
                                                Under Review
                                              </option>
                                              <option value="Draft Sent for Signing">
                                                Draft Sent for Signing
                                              </option>
                                              <option value="Draft Received for Signing">
                                                Draft Received for Signing
                                              </option>
                                              <option value="Signed Contract yet to Receive">
                                                Signed Contract yet to Receive
                                              </option>
                                              <option value="Signed Contract Sent for Sign">
                                                Signed Contract Sent for Sign
                                              </option>
                                              <option value="Signed Original Contract Need to Courier">
                                                Signed Original Contract Need to Courier
                                              </option>
                                              <option value="Signed Original Contract yet to Receive">
                                                Signed Original Contract yet to Receive
                                              </option>
                                              <option value="Original Contract Signed by Both Parties">
                                                Original Contract Signed by Both Parties
                                              </option>
                                              <option value="Original Contract Signed and Received by Both Parties">
                                                Original Contract Signed and Received by Both
                                                Parties
                                              </option>
                                              <option value="Closed">Closed</option>
                                              <option value="Partially completed">
                                                Partially completed
                                              </option>
                                              <option value="Completed">Completed</option>
                                              <option value="Cancelled">Cancelled</option>
                                              <option value="Partially cancelled">
                                                Partially cancelled
                                              </option>
                                              <option value="Shipments - Arutmin">
                                                Shipments - Arutmin
                                              </option>
                                              <option value="Partially Signed Contract">
                                                Partially Signed Contract
                                              </option>
                                            </Form.Select>
                                          </FloatingLabel>
                                        </Form.Group>

                                        <Form.Group as={Col} md="6">
                                          <FloatingLabel
                                            controlId="floatingInput"
                                            label="Contract Start Date"
                                            className="mb-3 date-picker"
                                          >
                                            <DatePicker
                                              selected={startDate}
                                              onChange={handleStartDateChange}
                                              selectsStart
                                              dateFormat="dd/MM/yyyy"
                                              placeholderText="Select"
                                            />
                                          </FloatingLabel>
                                        </Form.Group>

                                        <Form.Group as={Col} md="6" className='textEditor'>
                                          <label>Pending Matter</label>
                                          <Editor value={pendingMatter} onChange={onChangePending} />
                                        </Form.Group>
                                        <Form.Group as={Col} md="6">
                                          <FloatingLabel
                                            controlId="floatingInput"
                                            label="Contract End Date"
                                            className="mb-3 date-picker"
                                          >
                                            <DatePicker
                                              selected={endDate}
                                              onChange={handleEndDateChanges}
                                              selectsStart
                                              dateFormat="dd/MM/yyyy"
                                              placeholderText="Select"
                                              disabled={true}
                                            />
                                          </FloatingLabel>
                                        </Form.Group>

                                        <Form.Group as={Col} md="6" className='textEditor tooltips'>
                                          <label>Remark</label>
                                          <Editor value={remarks} onChange={onChangeRemarks} />
                                          <Tooltip text={<span>Any Specific Remark related to Contract</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" className="tooltips">
                                          <FloatingLabel
                                            controlId="floatingInput"
                                            label="Contract Effective Date"
                                            className="mb-3 date-picker"
                                          >
                                            <DatePicker
                                              selected={contractEffectiveDate}
                                              onChange={handleEndDateChange}
                                              dateFormat="dd/MM/yyyy"
                                              placeholderText="Select Date"
                                            />
                                          </FloatingLabel>
                                          <Tooltip text={<span>Contract Effective Date</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" className="tooltips">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Master Contract Reference"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="Master Contract Reference"
                                    name="master_conference_reference"
                                    defaultValue={
                                      datas.master_conference_reference || ""
                                    }
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                                <Tooltip text={<span>Give master contract reference, if any</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                              </Form.Group>
                                        <Form.Group as={Col} md="6"></Form.Group>
                                        <Form.Group as={Col} md="6">
                                          <FloatingLabel
                                            controlId="floatingInput"
                                            label="Renewal date"
                                            className="mb-3 date-picker"
                                          >
                                            <DatePicker
                                              selected={renewal}
                                              onChange={handleRenewal}
                                              dateFormat="dd/MM/yyyy"
                                              placeholderText="Select Date"
                                            />
                                          </FloatingLabel>
                                        </Form.Group>

                                        <Form.Group as={Col} md="6"></Form.Group>
                                        <Form.Group as={Col} md="6">
                                          <FloatingLabel
                                            controlId="floatingInput"
                                            label="Renewal contract number"
                                            className="mb-3"
                                          >
                                            <Form.Control
                                              type="number"
                                              placeholder="Renewal contract number "
                                              name="renewal_contract_number"
                                              defaultValue={
                                                datas.renewal_contract_number || ""
                                              }
                                              onChange={handleChangeInput}
                                            />
                                          </FloatingLabel>
                                        </Form.Group>

                                      </Row> <Row className="mb-3">
                                        <h4 className="heading">
                                          Contract Information
                                        </h4>
                                        <Form.Group as={Col} md="6">
                                          <FloatingLabel
                                            controlId="floatingSelect"
                                            className="dropDown"
                                            label="Currency"
                                          >
                                            <Form.Select
                                              aria-label="Floating label select example"
                                              name="currency"
                                              placeholder="Currency"
                                              className="error-validation"
                                              value={datas.currency || ""}
                                              onChange={handleChangeInput}
                                            >
                                              <option value="">Select </option>
                                              <option value="USD">USD </option>
                                              <option value="IDR">IDR </option>
                                            </Form.Select>
                                          </FloatingLabel>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6">
                                          <label>Locked</label>
                                          <input
                                            type='checkbox'
                                            name='locked'
                                            checked={lockeds} // Use the state variable to determine if the checkbox is checked
                                            onChange={handleCheckboxChange} // Handle the change event
                                            className='mx-3'
                                          /></Form.Group>
                                        <Form.Group as={Col} md="6">
                                          <FloatingLabel
                                            controlId="floatingInput"
                                            label="Exchange rate"
                                            className="mb-3"
                                          >
                                            <Form.Control
                                              type="text"
                                              placeholder="Exchange rate "
                                              name="exchange_rate"
                                              defaultValue={
                                                datas.exchange_rate || ""
                                              }
                                              onChange={handleChangeInput}
                                            />
                                          </FloatingLabel>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6">
                                          {" "}
                                        </Form.Group>
                                        {/* <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingSelect"
               className="dropDown"
               label="Status"
               >
               <Form.Select
               aria-label="Floating label select example"
               name="status"
               placeholder="Status"
               className="error-validation"
               value={datas.status || ""}
               onChange={handleChangeInput}
               >
               <option value="">Select </option>
               <option value="Draft">Draft </option>
               <option value="Approved">Approved </option>
               </Form.Select>
            </FloatingLabel>
         </Form.Group> */}
                                        <Form.Group as={Col} md="6">
                                          <FloatingLabel
                                            controlId="floatingSelect"
                                            className="dropDown"
                                            label="Account Name"
                                          >
                                            <Form.Select
                                              aria-label="Floating label select example"
                                              name="account_name"
                                              placeholder="Account Name"
                                              className="error-validation"
                                              value={datas.account_name || ""}
                                              onChange={handleChangeInput}
                                            >
                                              <option value="">Select </option>
                                              {accountName.map((x) => {
                                                return (
                                                  <option
                                                    value={x.account_name}
                                                  >
                                                    {x.account_name}
                                                  </option>
                                                );
                                              })}
                                            </Form.Select>
                                          </FloatingLabel>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6">
                                          <FloatingLabel
                                            controlId="floatingInput"
                                            label="Contract record type"
                                            className="mb-3"
                                          >
                                            <Form.Control
                                              type="text"
                                              placeholder="Contract record type"
                                              name="contract_record_type"
                                              className="record-type"
                                              defaultValue={
                                                datas.contract_record_type || ""
                                              }
                                              onChange={handleChangeInput}
                                            />
                                          </FloatingLabel>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6">
                                          <FloatingLabel
                                            controlId="floatingInput"
                                            label="Contract Owner"
                                            className="mb-3"
                                          >
                                            <Form.Control
                                              type="text"
                                              placeholder="Contract Owner"
                                              name="contract_owner"
                                              defaultValue={
                                                datas.contract_owner || ""
                                              }
                                              onChange={handleChangeInput}
                                            />
                                          </FloatingLabel>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6">
                                          <FloatingLabel
                                            controlId="floatingInput"
                                            label="Rwood contract no"
                                            className="mb-3"
                                          >
                                            <Form.Control
                                              type="text"
                                              placeholder="Rwood contract no"
                                              name="Rwood_Contract_No"
                                              defaultValue={
                                                datas.Rwood_Contract_No || ""
                                              }
                                              onChange={handleChangeInput} disabled
                                            />
                                          </FloatingLabel>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6">
                                          <FloatingLabel
                                            controlId="floatingInput"
                                            label="Contract Start Date"
                                            className="mb-3 date-picker"
                                          >
                                            <DatePicker
                                              selected={startDate}
                                              onChange={handleStartDateChange}
                                              selectsStart
                                              dateFormat="dd/MM/yyyy"
                                              placeholderText="Select"
                                            />
                                          </FloatingLabel>
                                        </Form.Group>
                                        {/* <Form.Group as={Col} md="6">
                                          <FloatingLabel
                                            controlId="floatingSelect"
                                            className="dropDown"
                                            label="Owner Expiration Notice"
                                          >
                                            <Form.Select
                                              aria-label="Floating label select example"
                                              name="owner_expiration_notice"
                                              placeholder="Owner Expiration Notice"
                                              value={
                                                datas.owner_expiration_notice ||
                                                ""
                                              }
                                              onChange={handleChangeInput}
                                            >
                                              <option>---None---</option>
                                              <option value="30">
                                                30 Days
                                              </option>
                                              <option value="45">
                                                45 Days
                                              </option>
                                              <option value="60">
                                                60 Days
                                              </option>
                                              <option value="90">
                                                90 Days
                                              </option>
                                              <option value="120">
                                                120 Days
                                              </option>
                                            </Form.Select>
                                          </FloatingLabel>
                                        </Form.Group> */}
                                        <Form.Group as={Col} md="6"></Form.Group>
                                        <Form.Group as={Col} md="6" className="tooltips">
                                          <FloatingLabel
                                            controlId="floatingInput"
                                            label="Contract Effective Date"
                                            className="mb-3 date-picker"
                                          >
                                            <DatePicker
                                              selected={contractEffectiveDate}
                                              onChange={handleEndDateChange}
                                              dateFormat="dd/MM/yyyy"
                                              placeholderText="Select Date"
                                            />
                                          </FloatingLabel>
                                          <Tooltip text={<span>Contract Effective Date</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6">
                                          <FloatingLabel
                                            controlId="floatingInput"
                                            label="Contract term in months"
                                            className="mb-3"
                                          >
                                            <Form.Control
                                              type="text"
                                              placeholder="Contract term in months"
                                              name="contract_term_in_months"
                                              defaultValue={
                                                datas.contract_term_in_months ||
                                                ""
                                              }
                                              onChange={handleChangeInput}
                                            />
                                          </FloatingLabel>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6">
                                          <FloatingLabel
                                            controlId="floatingSelect"
                                            className="dropDown"
                                            label="Contract Status"
                                          >
                                            <Form.Select
                                              aria-label="Floating label select example"
                                              name="contract_status"
                                              placeholder="Contract Status"
                                              value={
                                                datas.contract_status || ""
                                              }
                                              onChange={handleChangeInput}
                                            >
                                              <option value="In Approval Process">
                                                In Approval Process
                                              </option>
                                              <option value="Activated">
                                                Activated
                                              </option>
                                              <option value="Draft" selected>Draft</option>

                                              <option value="Under Review">
                                                Under Review
                                              </option>
                                              <option value="Draft Sent for Signing">
                                                Draft Sent for Signing
                                              </option>
                                              <option value="Draft Received for Signing">
                                                Draft Received for Signing
                                              </option>
                                              <option value="Signed Contract yet to Receive">
                                                Signed Contract yet to Receive
                                              </option>
                                              <option value="Signed Contract Sent for Sign">
                                                Signed Contract Sent for Sign
                                              </option>
                                              <option value="Signed Original Contract Need to Courier">
                                                Signed Original Contract Need to
                                                Courier
                                              </option>
                                              <option value="Signed Original Contract yet to Receive">
                                                Signed Original Contract yet to
                                                Receive
                                              </option>
                                              <option value="Original Contract Signed by Both Parties">
                                                Original Contract Signed by Both
                                                Parties
                                              </option>
                                              <option value="Original Contract Signed and Received by Both Parties">
                                                Original Contract Signed and
                                                Received by Both Parties
                                              </option>
                                              <option value="Closed">
                                                Closed
                                              </option>
                                              <option value="Partially completed">
                                                Partially completed
                                              </option>
                                              <option value="Completed">
                                                Completed
                                              </option>
                                              <option value="Cancelled">
                                                Cancelled
                                              </option>
                                              <option value="Partially cancelled">
                                                Partially cancelled
                                              </option>
                                              <option value="Shipments - Arutmin">
                                                Shipments - Arutmin
                                              </option>
                                              <option value="Partially Signed Contract">
                                                Partially Signed Contract
                                              </option>
                                            </Form.Select>
                                          </FloatingLabel>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" className='textEditor'>
                                          <label>Pending Matter</label>
                                          <Editor value={pendingMatter} onChange={onChangePending} />
                                        </Form.Group>
                                        <Form.Group as={Col} md="6">
                                          <FloatingLabel
                                            controlId="floatingSelect"
                                            className="dropDown"
                                            label="Trader Name"
                                          >
                                            <Form.Select
                                              aria-label="Floating label select example"
                                              name="trader_name"
                                              placeholder="Trader Name"
                                              value={datas.trader_name || ""}
                                              onChange={handleChangeInput}
                                            >
                                              <option value="">Select </option>
                                              {traderData.map((x, i) => {
                                                return (
                                                  <option
                                                    key={i}
                                                    value={x.rwood_username}
                                                  >
                                                    {x.rwood_username}
                                                  </option>
                                                );
                                              })}
                                            </Form.Select>
                                          </FloatingLabel>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" className="tooltips">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Master Contract Reference"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="Master Contract Reference"
                                    name="master_conference_reference"
                                    defaultValue={
                                      datas.master_conference_reference || ""
                                    }
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                                <Tooltip text={<span>Give master contract reference, if any</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                              </Form.Group>
                                        <Form.Group as={Col} md="6">
                                          <FloatingLabel
                                            controlId="floatingSelect"
                                            className="dropDown"
                                            label="Company"
                                          >
                                            <Form.Select
                                              aria-label="Floating label select example"
                                              name="company"
                                              placeholder="Company"
                                              className="error-validation"
                                              value={datas.company || ""}
                                              onChange={handleChangeInput}
                                            >
                                              <option value="">Select </option>
                                              {companyData.map((x, i) => {
                                                return (
                                                  <option
                                                    key={i}
                                                    value={x.company_name}
                                                  >
                                                    {x.company_name}
                                                  </option>
                                                );
                                              })}
                                            </Form.Select>
                                          </FloatingLabel>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6">
                                          <FloatingLabel
                                            controlId="floatingInput"
                                            label="Renewal date"
                                            className="mb-3 date-picker"
                                          >
                                            <DatePicker
                                              selected={renewal}
                                              onChange={handleRenewal}
                                              dateFormat="dd/MM/yyyy"
                                              placeholderText="Select Date"
                                            />
                                          </FloatingLabel>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6">
                                          <FloatingLabel
                                            controlId="floatingInput"
                                            label="Renewal contract number"
                                            className="mb-3"
                                          >
                                            <Form.Control
                                              type="number"
                                              placeholder="Renewal contract number "
                                              name="renewal_contract_number "
                                              defaultValue={
                                                datas.renewal_contract_number ||
                                                ""
                                              }
                                              onChange={handleChangeInput}
                                            />
                                          </FloatingLabel>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6"></Form.Group>
                                        <Form.Group as={Col} md="6" className='textEditor tooltips'>
                                          <label>Remark</label>
                                          <Editor value={remarks} onChange={onChangeRemarks} />
                                          <Tooltip text={<span>Any Specific Remark related to Contract</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6"></Form.Group>
                                        <Form.Group as={Col} md="6">
                                          <FloatingLabel
                                            controlId="floatingInput"
                                            label="Contract Number"
                                            className="mb-3"
                                          >
                                            <Form.Control
                                              type="text"
                                              placeholder="Rwood contract number"
                                              name="contract_number"
                                              defaultValue={datas.contract_number || ""}
                                              onChange={handleChangeInput} disabled
                                            />
                                          </FloatingLabel>
                                        </Form.Group>

                                      </Row>
                                      <Row className="mb-3">
                                        <h4 className="heading">
                                          Commodity and Contracted Quantity
                                        </h4>
                                        <Form.Group as={Col} md="6">
                                          <FloatingLabel
                                            controlId="floatingInput"
                                            label="Port Of Loading"
                                            className="mb-3"
                                          >
                                            <Form.Control
                                              type="text"
                                              placeholder="Port Of Loading"
                                              name="port_of_loading"
                                              defaultValue={
                                                datas.port_of_loading || ""
                                              }
                                              onChange={handleChangeInput}
                                            />
                                          </FloatingLabel>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6">
                                          <FloatingLabel
                                            controlId="floatingInput"
                                            label="Origin"
                                            className="mb-3"
                                          >
                                            <Form.Control
                                              type="text"
                                              placeholder="Origin"
                                              name="origin"
                                              defaultValue={datas.origin || ""}
                                              onChange={handleChangeInput}
                                            />
                                          </FloatingLabel>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6">
                                          <FloatingLabel
                                            controlId="floatingInput"
                                            label="Contract quantity in MT"
                                            className="mb-3"
                                          >
                                            <Form.Control
                                              type="number"
                                              placeholder="Contract quantity in MT"
                                              name="contract_quantity_in_MT"
                                              className="error-validation"
                                              defaultValue={
                                                datas.contract_quantity_in_MT ||
                                                ""
                                              }
                                              onChange={handleChangeInput}
                                            />
                                          </FloatingLabel>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6">
                                          <FloatingLabel
                                            controlId="floatingSelect"
                                            className="dropDown"
                                            label="Commodity"
                                          >
                                            <Form.Select
                                              aria-label="Floating label select example"
                                              name="commodity"
                                              placeholder="Commodity"
                                              value={datas.commodity || ""}
                                              onChange={handleChangeInput}
                                            >
                                              <option value='Indonesian Steam Coal in Bulk ("Coal")'>
                                                Indonesian Steam Coal in Bulk
                                                ("Coal")
                                              </option>
                                              <option value="US Coal">
                                                US Coal
                                              </option>
                                              <option value="Russia Coal">
                                                Russia Coal
                                              </option>
                                              <option value="SA Coal">
                                                SA Coal
                                              </option>
                                              <option value="Clinker in Bulk">
                                                Clinker in Bulk
                                              </option>
                                              <option value="Australian Steam Coal">
                                                Australian Steam Coal
                                              </option>
                                            </Form.Select>
                                          </FloatingLabel>
                                        </Form.Group>
                                      </Row>
                                      <Row className="mb-3">
                                        <h4 className="heading">
                                          Price and Price Adjustment
                                        </h4>
                                        <Form.Group as={Col} md="6">
                                          <FloatingLabel
                                            controlId="floatingInput"
                                            label="Price PMT"
                                            className="mb-3"
                                          >
                                            <Form.Control
                                              type="number"
                                              placeholder="Price PMT"
                                              name="price_PMT"
                                              defaultValue={
                                                datas.price_PMT || ""
                                              }
                                              onChange={handleChangeInput}
                                            />
                                          </FloatingLabel>
                                        </Form.Group>
                                        {/* <Form.Group as={Col} md="6">
            <FloatingLabel controlId="floatingSelect"
               className='dropDown' label="Price book">
               <Form.Select aria-label="Floating label select example"
               name="price_book"
               placeholder="Price book"
               value={datas.price_book || ""}
               onChange={handleChangeInput}
               >
               <option value='' >Select</option>
               {
               priceBook.map((x)=>{
               return(
               <option value={x.price_book_name}>{x.price_book_name}</option>
               )
               })
               }
               </Form.Select>
            </FloatingLabel>
         </Form.Group> */}
                                        <Form.Group as={Col} md="6">
                                          <FloatingLabel
                                            controlId="floatingInput"
                                            label="Price PMT in words"
                                            className="mb-3"
                                          >
                                            <Form.Control
                                              type="text"
                                              placeholder="Price PMT in words"
                                              name="price_PMT_in_words"
                                              defaultValue={
                                                datas.price_PMT_in_words || ""
                                              }
                                              onChange={handleChangeInput}
                                            />
                                          </FloatingLabel>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6">
                                          <FloatingLabel
                                            controlId="floatingSelect"
                                            className="dropDown"
                                            label="Price Basis"
                                          >
                                            <Form.Select
                                              aria-label="Floating label select example"
                                              name="price_basis"
                                              placeholder="Price Basis"
                                              value={datas.price_basis || ""}
                                              onChange={handleChangeInput}
                                            >
                                              <option value="">--None--</option>
                                              <option value="In Approval Process">
                                                In Approval Process
                                              </option>
                                              <option value="Activated">
                                                Activated
                                              </option>
                                              <option value="Draft">
                                                Draft
                                              </option>
                                              <option value="Under Review">
                                                Under Review
                                              </option>
                                              <option value="Draft Sent for Signing">
                                                Draft Sent for Signing
                                              </option>
                                              <option value="Draft Received for Signing">
                                                Draft Received for Signing
                                              </option>
                                              <option value="Signed Contract yet to Receive">
                                                Signed Contract yet to Receive
                                              </option>
                                              <option value="Signed Contract Sent for Sign">
                                                Signed Contract Sent for Sign
                                              </option>
                                              <option value="Signed Original Contract Need to Courier">
                                                Signed Original Contract Need to
                                                Courier
                                              </option>
                                              <option value="Signed Original Contract yet to Receive">
                                                Signed Original Contract yet to
                                                Receive
                                              </option>
                                              <option value="Original Contract Signed by Both Parties">
                                                Original Contract Signed by Both
                                                Parties
                                              </option>
                                              <option value="Original Contract Signed and Received by Both Parties">
                                                Original Contract Signed and
                                                Received by Both Parties
                                              </option>
                                              <option value="Closed">
                                                Closed
                                              </option>
                                              <option value="Partially completed">
                                                Partially completed
                                              </option>
                                              <option value="Completed">
                                                Completed
                                              </option>
                                              <option value="Cancelled">
                                                Cancelled
                                              </option>
                                              <option value="Partially cancelled">
                                                Partially cancelled
                                              </option>
                                              <option value="Shipments - Arutmin">
                                                Shipments - Arutmin
                                              </option>
                                              <option value="Partially Signed Contract">
                                                Partially Signed Contract
                                              </option>
                                            </Form.Select>
                                          </FloatingLabel>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6">
                                          <FloatingLabel
                                            controlId="floatingSelect"
                                            className="dropDown"
                                            label="Price type"
                                          >
                                            <Form.Select
                                              aria-label="Floating label select example"
                                              name="price_type"
                                              placeholder="Price type"
                                              value={datas.price_type || ""}
                                              onChange={handleChangeInput}
                                            >
                                              <option value="">Select</option>
                                              {getPrice.map((x) => {
                                                return (
                                                  <option
                                                    value={x.price_type_name}
                                                  >
                                                    {x.price_type_name}
                                                  </option>
                                                );
                                              })}
                                            </Form.Select>
                                          </FloatingLabel>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6">
                                          <FloatingLabel
                                            controlId="floatingInput"
                                            label="Price basis GAR"
                                            className="mb-3"
                                          >
                                            <Form.Control
                                              type="number"
                                              placeholder="Price basis GAR"
                                              name="price_basis_GAR"
                                              defaultValue={
                                                datas.price_basis_GAR || ""
                                              }
                                              onChange={handleChangeInput}
                                            />
                                          </FloatingLabel>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" className='textEditor'>
                                          <label>Price  Adjustment Clause</label>
                                          <Editor value={priceAdjust} onChange={onChangePricingAdjust} />
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" className='textEditor'>
                                          <label>Rejections</label>
                                          <Editor value={rejections} onChange={onChangeRejections} />
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" className='textEditor'>
                                          <label>Description</label>
                                          <Editor value={description_1} onChange={onChangeDescription} />
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" className='textEditor'>
                                          <label>Pricing Terms</label>
                                          <Editor value={pricingTerm} onChange={onChangePricingTerm} />
                                        </Form.Group>
                                      </Row>
                                      <Row className="mb-3">
                                        <h4 className="heading">
                                          Other Standard Term From Supplier
                                        </h4>
                                        {/* <Form.Group as={Col} md="12">
                                          <FloatingLabel
                                            controlId="floatingTextarea2"
                                            label="Payment Terms"
                                          >
                                            <Form.Control
                                              as="textarea"
                                              placeholder="Payment Terms"
                                              style={{
                                                height: "100px!important",
                                              }}
                                              name="payment_terms"
                                              defaultValue={
                                                datas.payment_terms || ""
                                              }
                                              onChange={handleChangeInput}
                                            />
                                          </FloatingLabel>
                                        </Form.Group> */}
                                        <Form.Group as={Col} md="6">
                                          <FloatingLabel
                                            controlId="floatingSelect"
                                            className="dropDown"
                                            label="Applicable Law"
                                          >
                                            <Form.Select
                                              aria-label="Floating label select example"
                                              name="applicable_law"
                                              placeholder="Applicable Law"
                                              value={datas.applicable_law || ""}
                                              onChange={handleChangeInput}
                                            >
                                              <option value="">--None--</option>
                                              <option value="English Law">
                                                English Law
                                              </option>
                                              <option value="Singapore Law">
                                                Singapore Law
                                              </option>
                                              <option value="Indonesian Law">
                                                Indonesian Law
                                              </option>
                                            </Form.Select>
                                          </FloatingLabel>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6"></Form.Group>
                                        <Form.Group as={Col} md="6">
                                          <FloatingLabel
                                            controlId="floatingSelect"
                                            className="dropDown"
                                            label="Arbitration"
                                          >
                                            <Form.Select
                                              aria-label="Floating label select example"
                                              name="arbitration"
                                              placeholder="Arbitration"
                                              value={datas.arbitration || ""}
                                              onChange={handleChangeInput}
                                            >
                                              <option value="">--None--</option>
                                              <option value="In Singapore as per SIAC">
                                                In Singapore as per SIAC
                                              </option>
                                              <option value="Singapore Chamber of Maritime Arbitration (SCMA)">
                                                Singapore Chamber of Maritime
                                                Arbitration (SCMA)
                                              </option>
                                              <option value="Laws of England and Wales">
                                                Laws of England and Wales
                                              </option>
                                              <option value="Rules of Badan Arbitrase Nasional Indonesia (BANI)">
                                                Rules of Badan Arbitrase
                                                Nasional Indonesia (BANI)
                                              </option>
                                            </Form.Select>
                                          </FloatingLabel>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6"></Form.Group>
                                        {/* <Form.Group as={Col} md="6">
                                          <FloatingLabel
                                            controlId="floatingInput"
                                            label="General terms"
                                            className="mb-3"
                                          >
                                            <Form.Control
                                              as="textarea"
                                              placeholder="General terms"
                                              name="general_terms"
                                              defaultValue={
                                                datas.general_terms || ""
                                              }
                                              onChange={handleChangeInput}
                                            />
                                          </FloatingLabel>
                                        </Form.Group> */}
                                      </Row>
                                      {/* <Row className="mb-3">
                                        <h4 className="heading">
                                          Description Information
                                        </h4>
                                        <Form.Group as={Col} md="6">
                                          <FloatingLabel
                                            controlId="floatingInput"
                                            label="Loading rate per day"
                                            className="mb-3"
                                          >
                                            <Form.Control
                                              type="text"
                                              placeholder="Loading rate per day"
                                              name="loading_rate_per_day"
                                              defaultValue={
                                                datas.loading_rate_per_day || ""
                                              }
                                              onChange={handleChangeInput}
                                            />
                                          </FloatingLabel>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6">
                                          <FloatingLabel
                                            controlId="floatingInput"
                                            label="Special Terms"
                                            className="mb-3"
                                          >
                                            <Form.Control
                                              as="textarea"
                                              placeholder="Special Terms"
                                              name="special_terms"
                                              defaultValue={
                                                datas.special_terms || ""
                                              }
                                              onChange={handleChangeInput}
                                            />
                                          </FloatingLabel>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6">
                                          <FloatingLabel
                                            controlId="floatingInput"
                                            label="Bonus penalty clause"
                                            className="mb-3"
                                          >
                                            <Form.Control
                                              as="textarea"
                                              placeholder="Bonus penalty clause"
                                              name="bonus_penalty_clause"
                                              defaultValue={
                                                datas.bonus_penalty_clause || ""
                                              }
                                              onChange={handleChangeInput}
                                            />
                                          </FloatingLabel>
                                        </Form.Group>
                                      </Row> */}
                                      <Row className="mb-3">
                                        <h4 className="heading">Address Information</h4>
                                        <Form.Group as={Col} md="6">
                                          <FloatingLabel
                                            controlId="floatingTextarea2"
                                            label="billing address"
                                          >
                                            <Form.Control
                                              as="textarea"
                                              placeholder="billing address"
                                              style={{ height: "100px!important" }}
                                              name="billing_address"
                                              defaultValue={datas.billing_address || ""}
                                              onChange={handleChangeInput}
                                            />
                                          </FloatingLabel>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6">
                                          <FloatingLabel
                                            controlId="floatingTextarea2"
                                            label="shipping address"
                                          >
                                            <Form.Control
                                              as="textarea"
                                              placeholder="shipping address"
                                              style={{ height: "100px!important" }}
                                              name="shipping_address"
                                              defaultValue={datas.shipping_address || ""}
                                              onChange={handleChangeInput}
                                            />
                                          </FloatingLabel>
                                        </Form.Group>
                                        {/*
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing street"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing street"
                    name="billing_street"
                    {...register("billing_street", { required: false })}
                  />
                  {errors.billing_street && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping street"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Shipping street"
                    name="shipping_street"
                    {...register("shipping_street", { required: false })}
                  />
                  {errors.shipping_street && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing zip / postal code"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing zip / postal code"
                    name="billing_zip_postal_code"
                    {...register("billing_zip_postal_code", {
                      required: false,
                    })}
                  />
                  {errors.billing_zip_postal_code && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping zip / postal code"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Shipping zip / postal code"
                    name="shipping_zip_postal_code"
                    {...register("shipping_zip_postal_code", {
                      required: false,
                    })}
                  />
                  {errors.shipping_zip_postal_code && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing city"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing city"
                    name="billing_city"
                    {...register("billing_city", { required: false })}
                  />
                  {errors.billing_city && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping city"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Shipping city"
                    name="shipping_city"
                    {...register("shipping_city", { required: false })}
                  />
                  {errors.shipping_city && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing state / province"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing state / province"
                    name="billing_state_or_province"
                    {...register("billing_state_or_province", {
                      required: false,
                    })}
                  />
                  {errors.billing_state_or_province && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping state / province"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Shipping state / province"
                    name="shipping_state_or_province"
                    {...register("shipping_state_or_province", {
                      required: false,
                    })}
                  />
                  {errors.shipping_state_or_province && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing country"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing country"
                    name="billing_country"
                    {...register("billing_country", { required: false })}
                  />
                  {errors.billing_country && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping country"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Shipping country"
                    name="shipping_country"
                    {...register("shipping_country", { required: false })}
                  />
                  {errors.shipping_country && <span>Required </span>}
                </FloatingLabel>
              </Form.Group> */}
                                      </Row>
                                      <Row className="mb-3">
                                        <h4 className="heading">
                                          Signature Information
                                        </h4>
                                        <Form.Group as={Col} md="6">
                                          <FloatingLabel
                                            controlId="floatingSelect"
                                            className="dropDown"
                                            label="Customer signed by"
                                          >
                                            <Form.Select
                                              aria-label="Floating label select example"
                                              name="customer_signed_by"
                                              placeholder="Customer signed by"
                                              value={
                                                datas.customer_signed_by || ""
                                              }
                                              onChange={handleChangeInput}
                                            >
                                              <option value="">Select </option>
                                              {contacts.map((x) => {
                                                return (
                                                  <option
                                                    value={x.contact_full_name}
                                                  >
                                                    {x.contact_full_name}
                                                  </option>
                                                );
                                              })}
                                            </Form.Select>
                                          </FloatingLabel>
                                        </Form.Group>
                                        {/* <Form.Group as={Col} md="6">
                                          <FloatingLabel
                                            controlId="floatingSelect"
                                            className="dropDown"
                                            label="Company signed by"
                                          >
                                            <Form.Select
                                              aria-label="Floating label select example"
                                              name="company_signed_by"
                                              placeholder="Company signed by"
                                              value={
                                                datas.company_signed_by || ""
                                              }
                                              onChange={handleChangeInput}
                                            >
                                              <option value="">Select </option>
                                              {users.map((x) => {
                                                return;
                                                <option value={x.username}>
                                                  {x.username}
                                                </option>;
                                              })}
                                            </Form.Select>
                                          </FloatingLabel>
                                        </Form.Group> */}
                                        <Form.Group as={Col} md="6">
                                          <FloatingLabel
                                            controlId="floatingInput"
                                            label="Customer signed title"
                                            className="mb-3"
                                          >
                                            <Form.Control
                                              type="text"
                                              placeholder="Customer signed title"
                                              name="customer_signed_title"
                                              defaultValue={
                                                datas.customer_signed_title ||
                                                ""
                                              }
                                              onChange={handleChangeInput}
                                            />
                                          </FloatingLabel>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6"></Form.Group>
                                        <Form.Group as={Col} md="6">
                                          <FloatingLabel
                                            controlId="floatingInput"
                                            label="Original Contract Recieved Date"
                                            className="mb-3 date-picker"
                                          >
                                            <DatePicker
                                              selected={originals}
                                              onChange={handleOriginal}
                                              dateFormat="dd/MM/yyyy"
                                              placeholderText="Select Date"
                                            />
                                          </FloatingLabel>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6">
                                          <FloatingLabel
                                            controlId="floatingInput"
                                            label="Company Signed Date"
                                            className="mb-3 date-picker"
                                          >
                                            <DatePicker
                                              selected={signed}
                                              onChange={handleSigned}
                                              dateFormat="dd/MM/yyyy"
                                              placeholderText="Select Date"
                                            />
                                          </FloatingLabel>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6">
                                          <FloatingLabel
                                            controlId="floatingInput"
                                            label="Customer Signed Date"
                                            className="mb-3 date-picker"
                                          >
                                            <DatePicker
                                              selected={customersigned}
                                              onChange={
                                                handleCustomerSignedDate
                                              }
                                              selectsStart
                                              dateFormat="dd/MM/yyyy"
                                              placeholderText="Select"
                                            />
                                          </FloatingLabel>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6">
                                          <FloatingLabel
                                            controlId="floatingInput"
                                            label="Original contract sent date"
                                            className="mb-3 date-picker"
                                          >
                                            <DatePicker
                                              selected={originalcontract}
                                              onChange={handleOriginalcontract}
                                              dateFormat="dd/MM/yyyy"
                                              placeholderText="Select Date"
                                            />
                                          </FloatingLabel>
                                        </Form.Group>
                                      </Row>
                                      <p className="edit-btn">
                                        <input
                                          type="submit"
                                          className="account-save"
                                          onClick={handleSaveEdit}
                                          value="Save"
                                          //disabled={isSubmitted}
                                        />
                                        <button
                                          className="btn btn-primary"
                                          onClick={handleCancelEdit}
                                        >
                                          Cancel
                                        </button>
                                      </p>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="contactAccount fulid-section">
                                      <div className="tasks">
                                        <div className="edit-delete">
                                          <span
                                            id="edit"
                                            onClick={handleEditClick}
                                          >
                                            Edit
                                          </span>
                                          <span>
                                            <Link
                                              to={
                                                "/contract/create-contract-list"
                                              }
                                            >
                                              Cancel
                                            </Link>
                                          </span>
                                          <span>Activate</span>
                                          <span>Generate Contract</span>
                                          <span>Generate Contract Clauses</span>
                                          <span>Shipment Checklist</span>
                                        </div>
                                        <div className="">
                                          <div className="">
                                            <div className="row">
                                              <div className="col-md-12" id="head">
                                                <h4 className="heading">
                                                  Information
                                                </h4>
                                                <hr></hr>
                                              </div>
                                            </div>
                                            <table class="table table-bordered account-table tables">
                                              <tbody>
                                                <tr>
                                                  <td id="td-right">
                                                    <span>rwood contract number</span>
                                                  </td>
                                                  <td>
                                                    {datas.contract_number}
                                                  </td>
                                                  <td id="td-right">
                                                    <span>Locked</span>
                                                  </td>
                                                  <td>  <input
                                                    type='checkbox'
                                                    name='locked'
                                                    checked={lockeds} // Use the state variable to determine if the checkbox is checked
                                                    onChange={handleCheckboxChange} // Handle the change event
                                                    className='mx-3' disabled={isButtonEnabled}
                                                  /> </td>
                                                </tr>

                                                <tr>
                                                  <td id="td-right">
                                                    <span>Currency</span>
                                                  </td>
                                                  <td> {datas.currency} </td>
                                                  <td id="td-right">
                                                    <span>Status</span>
                                                  </td>
                                                  <td>{datas.status}</td>
                                                </tr>
                                                <tr>
                                                  <td id="td-right">
                                                    <span>Exchange Rate</span>
                                                  </td>
                                                  <td> {datas.exchange_rate} </td>
                                                  <td id="td-right">
                                                    <span>Contract record type</span>
                                                  </td>
                                                  <td>
                                                    {" "}
                                                    {datas.contract_record_type}{" "}
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td id="td-right">
                                                    <span>Account Name</span>
                                                  </td>
                                                  <td>
                                                    <Link
                                                      to={
                                                        "/accounts/" +
                                                        datas.account_id
                                                      }
                                                    >
                                                      {" "}
                                                      {datas.account_name}{" "}
                                                    </Link>
                                                  </td>
                                                  <td id="td-right">
                                                    <span>Contract Owner</span>
                                                  </td>
                                                  <td> {datas.contract_owner} </td>
                                                </tr>
                                                <tr>
                                                  <td id="td-right">
                                                    <span>Buyer Billing Address
                                                    </span>
                                                  </td>
                                                  <td>
                                                    {datas.billing_address}{" "}
                                                  </td>

                                                  <td id="td-right">
                                                    {/* <span>Contract term in months</span> */}
                                                  </td>
                                                  <td>
                                                    {/* {datas.contract_term_in_months} */}
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td id="td-right">
                                                    <span>Rwood contract no</span>
                                                  </td>
                                                  <td> <Link to={`/contract/contract-details/${datas.id}`}>{datas.Rwood_Contract_No}</Link> </td>
                                                  <td id="td-right">
                                                    <span>
                                                      Owner Expiration Notice{" "}
                                                    </span>
                                                  </td>
                                                  <td>
                                                    {" "}
                                                    {
                                                      datas.owner_expiration_notice
                                                    }{" "}
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td id="td-right">
                                                    <span>Contract Start Date</span>
                                                  </td>
                                                  <td>
                                                    {" "}
                                                    {datas.contract_start_date}{" "}
                                                  </td>
                                                  <td id="td-right">
                                                    <span>
                                                      Contract term in months
                                                    </span>
                                                  </td>
                                                  <td>
                                                    {" "}
                                                    {
                                                      datas.contract_term_in_months
                                                    }{" "}
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td id="td-right">
                                                    <span>Contract End Date</span>
                                                  </td>
                                                  <td> {datas.contract_end_date} </td>
                                                  <td id="td-right">
                                                    <span>Pending Matters</span>
                                                  </td>
                                                  <td> <div dangerouslySetInnerHTML={{ __html: datas.pending_matters }} /> </td>
                                                </tr>
                                                <tr>
                                                  <td id="td-right">
                                                    <span>
                                                      Contract Effective Date
                                                    </span>
                                                  </td>
                                                  <td>
                                                    {" "}
                                                    {
                                                      datas.contract_effective_date
                                                    }{" "}
                                                  </td>
                                                  <td id="td-right">
                                                  <span className="a-a">
                                          Master Contract Reference{" "}
                                                <Tooltip text={<span>Give master contract reference, if any</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                                              </span>
                                                  </td>
                                                  <td>
                                                    {" "}
                                                    {
                                                      datas.master_conference_reference
                                                    }{" "}
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td id="td-right">
                                                    <span>Contract Status</span>
                                                  </td>
                                                  <td> {datas.contract_status} </td>
                                                  <td id="td-right">
                                                    <span>Renewal Date</span>
                                                  </td>
                                                  <td> {datas.renewal_date} </td>
                                                </tr>
                                                <tr>
                                                  <td id="td-right">
                                                    <span> Trader Name </span>
                                                  </td>
                                                  <td>{datas.trader_name !== null && datas.trader_name !== "" ? (
                                                    <Link to={"/user/user-details/" + datas.rwood_user_id}>
                                                      {datas.trader_name}
                                                    </Link>
                                                  ) : (
                                                    <span>{datas.trader_name}</span>
                                                  )}</td>

                                                  <td id="td-right">
                                                    <span>
                                                      Renewal contract number
                                                    </span>
                                                  </td>
                                                  <td>
                                                    {" "}
                                                    {
                                                      datas.renewal_contract_number
                                                    }{" "}
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td id="td-right">
                                                    <span>Company</span>
                                                  </td>
                                                  <td>
                                                    <Link
                                                      to={`/company/company-details/${datas.rwood_company_id}`}
                                                    >
                                                      {datas.company}
                                                    </Link>
                                                  </td>
                                                  <td id="td-right">
                                                     <span className="a-a">
                                          Remark{" "}
                                                <Tooltip text={<span>Any Specific Remark related to Contract</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                                              </span>
                                                  </td>
                                                  <td> <div dangerouslySetInnerHTML={{ __html: datas.remark }} /> </td>
                                                </tr>


                                              </tbody>
                                            </table>
                                          </div>
                                          <div className="">
                                            <div className="row">
                                              <div className="col-md-12" id="head">
                                                <h4 className="heading">
                                                  Commodity and Contracted Quantity
                                                </h4>
                                                <hr></hr>
                                              </div>
                                            </div>
                                            <table class="table table-bordered account-table tables">
                                              <tbody>
                                                <tr>
                                                  <td id="td-right">
                                                    <span>Port Of Loading</span>
                                                  </td>
                                                  <td> {datas.port_of_loading} </td>
                                                  <td id="td-right">
                                                    <span>Origin</span>
                                                  </td>
                                                  <td> {datas.origin} </td>
                                                </tr>
                                                <tr>
                                                  <td id="td-right">
                                                    <span>
                                                      Contract Quantity in MT
                                                    </span>
                                                  </td>
                                                  <td>
                                                    {" "}
                                                    {
                                                      datas.contract_quantity_in_MT
                                                    }{" "}
                                                  </td>
                                                  <td id="td-right">
                                                    <span>Commodity</span>
                                                  </td>
                                                  <td> {datas.commodity} </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                          <div className="">
                                            <div className="row">
                                              <div className="col-md-12" id="head">
                                                <h4 className="heading">
                                                  Price and Price Adjustment
                                                </h4>
                                                <hr></hr>
                                              </div>
                                            </div>
                                            <table class="table table-bordered account-table tables">
                                              <tbody>
                                                <tr>
                                                  <td id="td-right">
                                                    <span>Price PMT</span>
                                                  </td>
                                                  <td> {datas.price_PMT} </td>

                                                  <td id="td-right">
                                                    {/* <span>Price Book</span> */}
                                                  </td>
                                                  <td>{/* {datas.price_book} */}</td>
                                                </tr>
                                                <tr>
                                                  <td id="td-right">
                                                    <span>Price PMT in words</span>
                                                  </td>
                                                  <td>
                                                    {" "}
                                                    {datas.price_PMT_in_words}{" "}
                                                  </td>
                                                  <td id="td-right">
                                                    <span>Price Basis</span>
                                                  </td>
                                                  <td> {datas.price_basis} </td>
                                                </tr>
                                                <tr>
                                                  <td id="td-right">
                                                    <span>Price Type</span>
                                                  </td>
                                                  <td> {datas.price_type} </td>
                                                  <td id="td-right">
                                                    <span>Price Basis GAR</span>
                                                  </td>
                                                  <td> {datas.price_basis_GAR} </td>
                                                </tr>
                                                <tr>
                                                  <td id="td-right">
                                                    <span>
                                                      Price adjustment clause
                                                    </span>
                                                  </td>
                                                  <td>
                                                    <div dangerouslySetInnerHTML={{ __html: datas.price_adjustment_clause }} />
                                                  </td>
                                                  <td id="td-right">
                                                    <span>Rejections</span>
                                                  </td>
                                                  <td> <div dangerouslySetInnerHTML={{ __html: datas.rejections }} /> </td>
                                                </tr>
                                                <tr>
                                                  <td id="td-right">
                                                    <span>description</span>
                                                  </td>
                                                  <td> <div dangerouslySetInnerHTML={{ __html: datas.description }} /> </td>
                                                  <td id="td-right">
                                                    <span></span>
                                                  </td>
                                                  <td>  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                          <div className="">
                                            <div className="row">
                                              <div className="col-md-12" id="head">
                                                <h4 className="heading">
                                                  Other Standard Term From Supplier
                                                </h4>
                                                <hr></hr>
                                              </div>
                                            </div>
                                            <table class="table table-bordered account-table tables">
                                              <tbody>
                                                <tr>
                                                  <td id="td-right">
                                                    <span>Pricing Terms</span>
                                                  </td>
                                                  <td> <div dangerouslySetInnerHTML={{ __html: datas.pricing_terms }} /> </td>
                                                  <td id="td-right">
                                                  </td>
                                                  <td></td>
                                                </tr>

                                                {/* <tr>
                                            <td id="td-right">
                                              <span>Payment Terms</span>
                                            </td>
                                            <td> <div dangerouslySetInnerHTML={{ __html: datas.payment_terms }} /> </td>
                                            <td id="td-right">
                                            </td>
                                            <td> </td>
                                          </tr> */}
                                                <tr>
                                                  <td id="td-right">
                                                    <span>Applicable Law</span>
                                                  </td>
                                                  <td> {datas.applicable_law} </td>
                                                  <td id="td-right">
                                                    {/* <span>Arbitration</span> */}
                                                  </td>
                                                  <td> </td>
                                                </tr>
                                                <tr>
                                                  <td id="td-right">
                                                    <span>Arbitration</span>
                                                  </td>
                                                  <td> {datas.arbitration} </td>
                                                  <td id="td-right">
                                                    {/* <span>General Terms</span> */}
                                                  </td>
                                                  <td> </td>
                                                </tr>
                                                {/* <tr>
                                            <td id="td-right">
                                              <span>General Terms</span>
                                            </td>
                                            <td> <div dangerouslySetInnerHTML={{ __html: datas.general_terms }} /> </td>
                                            <td id="td-right">
                                            </td>
                                            <td> </td>
                                          </tr> */}
                                              </tbody>
                                            </table>
                                          </div>
                                          {/* <div className="">
                                      <div className="row">
                                        <div className="col-md-12" id="head">
                                          <h4 className="heading">
                                            Description Information
                                          </h4>
                                          <hr></hr>
                                        </div>
                                      </div>
                                      <table class="table table-bordered account-table tables">
                                        <tbody>
                                          <tr>
                                            <td id="td-right">
                                              <span>Loading rate per day</span>
                                            </td>
                                            <td>
                                              {" "}
                                              {datas.loading_rate_per_day}{" "}
                                            </td>
                                            <td id="td-right">
                                              {/* <span>Special Terms</span>
                                            </td>
                                            <td> </td>
                                          </tr>
                                          <tr>
                                            <td id="td-right">
                                              <span>Special Terms</span>
                                            </td>
                                            <td> {datas.special_terms} </td>
                                            <td id="td-right">
                                              {/* <span>Special Terms</span>
                                            </td>
                                            <td> </td>
                                          </tr>
                                          <tr>
                                            <td id="td-right">
                                              <span>Bonus penalty clause</span>
                                            </td>
                                            <td>
                                              {" "}
                                              <div dangerouslySetInnerHTML={{ __html: datas.bonus_penalty_clause }} />{" "}
                                            </td>
                                            <td id="td-right"></td>
                                            <td></td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div> */}
                                          <div className="">
                                            <div className="row">
                                              <div className="col-md-12" id="head">
                                                <h4 className="heading">
                                                  Address Information
                                                </h4>
                                                <hr></hr>
                                              </div>
                                            </div>
                                            <table class="table table-bordered account-table tables">
                                              <tbody>
                                                <tr>
                                                  <td id="td-right">
                                                    <span>Billing Address</span>
                                                  </td>
                                                  <td> {datas.billing_address} </td>
                                                  <td id="td-right">
                                                    <span>Shipping Address</span>
                                                  </td>
                                                  <td> {datas.shipping_address} </td>
                                                </tr>

                                              </tbody>
                                            </table>
                                          </div>
                                          <div className="">
                                            <div className="row">
                                              <div className="col-md-12" id="head">
                                                <h4 className="heading">
                                                  Signature Information
                                                </h4>
                                                <hr></hr>
                                              </div>
                                            </div>
                                            <table class="table table-bordered account-table tables">
                                              <tbody>

                                                <tr>
                                                  <td id="td-right">
                                                    <span>Customer signed by</span>
                                                  </td>
                                                  <td>{datas.customer_signed_by}</td>
                                                  <td id="td-right">
                                                    <span>Company signed by</span>
                                                  </td>
                                                  <td>{datas.company_signed_by}</td>
                                                </tr>
                                                <tr>
                                                  <td id="td-right">
                                                    <span>Customer signed title</span>
                                                  </td>
                                                  <td>
                                                    {datas.customer_signed_title}
                                                  </td>

                                                  <td id="td-right">
                                                    <span>Company signed date</span>
                                                  </td>
                                                  <td>{datas.company_signed_date}</td>
                                                </tr>
                                                <tr>
                                                  <td id="td-right">
                                                    <span>Customer signed date</span>
                                                  </td>
                                                  <td>
                                                    {datas.customer_signed_date}
                                                  </td>
                                                  <td id="td-right">
                                                    <span>
                                                      Original contract recieved date
                                                    </span>
                                                  </td>
                                                  <td>
                                                    {
                                                      datas.original_contract_recieved_date
                                                    }
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td id="td-right">
                                                    <span>
                                                      {/* Original contract sent date */}
                                                    </span>
                                                  </td>
                                                  <td>
                                                    {
                                                      // datas.original_contract_sent_date
                                                    }
                                                  </td>
                                                  <td id="td-right"></td>
                                                  <td></td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                        <div className="">
                                          <div className="row">
                                            <div
                                              className="col-md-12"
                                              id="head"
                                            >
                                              <h4 className="heading">
                                                Commodity and Contracted
                                                Quantity
                                              </h4>
                                              <hr></hr>
                                            </div>
                                          </div>
                                          <table class="table table-bordered account-table tables">
                                            <tbody>
                                              <tr>
                                                <td id="td-right">
                                                  <span>Port Of Loading</span>
                                                </td>
                                                <td>{datas.port_of_loading}</td>
                                                <td id="td-right">
                                                  <span>Origin</span>
                                                </td>
                                                <td>{datas.origin}</td>
                                              </tr>

                                              <tr>
                                                <td id="td-right">
                                                  <span>
                                                    Contract Quantity in MT
                                                  </span>
                                                </td>
                                                <td>
                                                  {
                                                    datas.contract_quantity_in_MT
                                                  }
                                                </td>
                                                <td id="td-right">
                                                  <span>Commodity</span>
                                                </td>
                                                <td>{datas.commodity}</td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>

                                        <div className="">
                                          <div className="row">
                                            <div
                                              className="col-md-12"
                                              id="head"
                                            >
                                              <h4 className="heading">
                                                Price and Price Adjustment
                                              </h4>
                                              <hr></hr>
                                            </div>
                                          </div>
                                          <table class="table table-bordered account-table tables">
                                            <tbody>
                                              <tr>
                                                <td id="td-right">
                                                  <span>Price PMT</span>
                                                </td>
                                                <td>{datas.price_PMT}</td>
                                                <td id="td-right">
                                                  {/* <span>Price Book</span> */}
                                                </td>
                                                <td>
                                                  {/* {datas.price_book} */}
                                                </td>
                                              </tr>

                                              <tr>
                                                <td id="td-right">
                                                  <span>Payment terms</span>
                                                </td>
                                                <td><div dangerouslySetInnerHTML={{ __html: datas.payment_terms }} /></td>
                                                <td id="td-right">
                                                  <span>
                                                    Price PMT in words
                                                  </span>
                                                </td>
                                                <td>
                                                  {datas.price_PMT_in_words}
                                                </td>
                                              </tr>

                                              <tr>
                                                <td id="td-right">
                                                  <span>Price Basis</span>
                                                </td>
                                                <td>{datas.price_basis}</td>
                                                <td id="td-right">
                                                  <span>Price Type</span>
                                                </td>
                                                <td>{datas.price_type}</td>
                                              </tr>

                                              <tr>
                                                <td id="td-right">
                                                  <span>Price Basis GAR</span>
                                                </td>
                                                <td>{datas.price_basis_GAR}</td>
                                                <td id="td-right">
                                                  <span>
                                                    Price adjustment clause
                                                  </span>
                                                </td>
                                                <td>
                                                  <div dangerouslySetInnerHTML={{ __html: datas.price_adjustment_clause }} />
                                                </td>
                                              </tr>

                                              <tr>
                                                <td id="td-right">
                                                  <span>Rejections</span>
                                                </td>
                                                <td><div dangerouslySetInnerHTML={{ __html: datas.rejections }} /></td>
                                                <td id="td-right">
                                                  <span>
                                                    Price adjustment clause
                                                  </span>
                                                </td>
                                                <td><div dangerouslySetInnerHTML={{ __html: datas.description }} /></td>
                                              </tr>

                                              <tr>
                                                <td id="td-right">
                                                  <span>Pricing Terms</span>
                                                </td>
                                                <td><div dangerouslySetInnerHTML={{ __html: datas.pricing_terms }} /></td>
                                                <td id="td-right"></td>
                                                <td></td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>

                                        <div className="">
                                          <div className="row">
                                            <div
                                              className="col-md-12"
                                              id="head"
                                            >
                                              <h4 className="heading">
                                                Other Standard Term From
                                                Supplier
                                              </h4>
                                              <hr></hr>
                                            </div>
                                          </div>
                                          <table class="table table-bordered account-table tables">
                                            <tbody>
                                              <tr>
                                                <td id="td-right">
                                                  <span>Payment Terms</span>
                                                </td>
                                                <td><div dangerouslySetInnerHTML={{ __html: datas.payment_terms }} /></td>
                                                <td id="td-right">
                                                  <span>Applicable Law</span>
                                                </td>
                                                <td>{datas.applicable_law}</td>
                                              </tr>

                                              <tr>
                                                <td id="td-right">
                                                  <span>Arbitration</span>
                                                </td>
                                                <td>{datas.arbitration}</td>
                                                <td id="td-right">
                                                  <span>General Terms</span>
                                                </td>
                                                <td><div dangerouslySetInnerHTML={{ __html: datas.general_terms }} /></td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                        {/* <div className="">
                                          <div className="row">
                                            <div
                                              className="col-md-12"
                                              id="head"
                                            >
                                              <h4 className="heading">
                                                Description Information
                                              </h4>
                                              <hr></hr>
                                            </div>
                                          </div>
                                          <table class="table table-bordered account-table tables">
                                            <tbody>
                                              <tr>
                                                <td id="td-right">
                                                  <span>
                                                    Loading rate per day
                                                  </span>
                                                </td>
                                                <td>
                                                  {datas.loading_rate_per_day}
                                                </td>
                                                <td id="td-right">
                                                  <span>Special Terms</span>
                                                </td>
                                                <td>{datas.special_terms}</td>
                                              </tr>

                                              <tr>
                                                <td id="td-right">
                                                  <span>
                                                    Bonus penalty clause
                                                  </span>
                                                </td>
                                                <td>
                                                  <div dangerouslySetInnerHTML={{ __html: datas.bonus_penalty_clause }} />
                                                </td>
                                                <td id="td-right"></td>
                                                <td></td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div> */}

                                        <div className="">
                                          <div className="row">
                                            <div
                                              className="col-md-12"
                                              id="head"
                                            >
                                              <h4 className="heading">
                                                Other Standard Terms
                                              </h4>
                                              <hr></hr>
                                            </div>
                                          </div>
                                          <table class="table table-bordered account-table tables">
                                            <tbody>
                                              <tr>
                                                <td id="td-right">
                                                  <span>Billing Street</span>
                                                </td>
                                                <td>{datas.billing_street}</td>
                                                <td id="td-right">
                                                  <span>Shipping Street</span>
                                                </td>
                                                <td>{datas.shipping_street}</td>
                                              </tr>
                                              <tr>
                                                <td id="td-right">
                                                  <span>
                                                    Billing Zip Postal Code
                                                  </span>
                                                </td>
                                                <td>
                                                  {
                                                    datas.billing_zip_postal_code
                                                  }
                                                </td>
                                                <td id="td-right">
                                                  <span>
                                                    Shipping zip postal code
                                                  </span>
                                                </td>
                                                <td>
                                                  {
                                                    datas.shipping_zip_postal_code
                                                  }
                                                </td>
                                              </tr>

                                              <tr>
                                                <td id="td-right">
                                                  <span>Billing City</span>
                                                </td>
                                                <td>{datas.billing_city}</td>
                                                <td id="td-right">
                                                  <span>Shipping City</span>
                                                </td>
                                                <td>{datas.shipping_city}</td>
                                              </tr>

                                              <tr>
                                                <td id="td-right">
                                                  <span>
                                                    Billing state or province
                                                  </span>
                                                </td>
                                                <td>
                                                  {
                                                    datas.billing_state_or_province
                                                  }
                                                </td>
                                                <td id="td-right">
                                                  <span>
                                                    Shipping state or province
                                                  </span>
                                                </td>
                                                <td>
                                                  {
                                                    datas.shipping_state_or_province
                                                  }
                                                </td>
                                              </tr>

                                              <tr>
                                                <td id="td-right">
                                                  <span>Billing Country</span>
                                                </td>
                                                <td>{datas.billing_country}</td>
                                                <td id="td-right">
                                                  <span>Shipping Country</span>
                                                </td>
                                                <td>
                                                  {datas.shipping_scountry}
                                                </td>
                                              </tr>
                                              <tr>
                                            <td id="td-right">
                                              <span>Created By
                                              </span>
                                            </td>
                                            <td>
                                              {datas.created_by
                                              }
                                            </td>

                                            <td id="td-right">
                                              <span>Last Modified By
                                              </span>
                                            </td>
                                            <td>{datas.last_modified_by
                                            }</td>
                                          </tr>
                                            </tbody>
                                          </table>
                                        </div>

                                        {/* <div className="">
                                          <div className="row">
                                            <div
                                              className="col-md-12"
                                              id="head"
                                            >
                                              <h4 className="heading">
                                                List Of Fields-Not Required
                                              </h4>
                                              <hr></hr>
                                            </div>
                                          </div>
                                          <table class="table table-bordered account-table tables">
                                            <tbody>
                                              <tr>
                                                <td id="td-right">
                                                  <span>
                                                    Customer signed by
                                                  </span>
                                                </td>
                                                <td>
                                                  {datas.customer_signed_by}
                                                </td>
                                                <td id="td-right">
                                                  <span>Company signed by</span>
                                                </td>
                                                <td>
                                                  {datas.company_signed_by}
                                                </td>

                                              </tr>
                                              <tr>

                                                <td id="td-right">

                                                  <span>
                                                    Customer signed title
                                                  </span>
                                                </td>
                                                <td>
                                                  {datas.customer_signed_title}
                                                </td>
                                                <td id="td-right">
                                                  <span>
                                                    Original Contract Recieved
                                                    Date
                                                  </span>
                                                </td>
                                                <td>
                                                  {
                                                    datas.original_contract_recieved_date
                                                  }
                                                </td>
                                              </tr>

                                              <tr>
                                                <td id="td-right">
                                                  <span>
                                                    Company Signed Date
                                                  </span>
                                                </td>
                                                <td>
                                                  {datas.company_signed_date}
                                                </td>

                                                <td id="td-right">
                                                  <span>
                                                    Original contract sent date
                                                  </span>
                                                </td>
                                                <td>
                                                  {
                                                    datas.original_contract_sent_date
                                                  }
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div> */}
                                      </div>
                                    </div>

                                    <div
                                      className="tasks view-table"
                                      Style="box-showdow:0px 13px 20px #d6d1d1"
                                    >
                                      <Row id="table-styles">
                                        <div className="col-md-12" id="head">
                                          <h4 className="heading">
                                            <span>History Information</span>
                                          </h4>
                                          <hr></hr>
                                        </div>
                                        <table id="historys" className="">
                                          <thead>
                                            <tr>
                                              <th>Field Name</th>
                                              <th>New Value</th>
                                              <th>Old Value</th>
                                              <th>Changed By</th>
                                              <th>Change Time</th>
                                            </tr>
                                          </thead>

                                          {history.length > 0 ? (
                                            <tbody>
                                              {history.map((y, i) => (
                                                <tr>
                                                  <td>{y.field_name}</td>
                                                  <td>{y.new_value}</td>
                                                  <td>{y.old_value}</td>
                                                  <td>{y.changed_by}</td>
                                                  <td>{y.change_time}</td>
                                                </tr>
                                              ))}
                                            </tbody>
                                          ) : (
                                            <tbody>
                                              <tr>
                                                <td></td>
                                                <td></td>
                                                <td>No data available</td>
                                                <td></td>
                                                <td></td>
                                              </tr>
                                            </tbody>
                                          )}
                                        </table>
                                      </Row>






                                      <Row id="table-styles">
                                        <div className="col-md-12" id="head">
                                          <h4 className="heading">
                                            <span>Contract Shipment</span>
                                            <span>
                                              <Popup
                                                trigger={
                                                  <a className="button add-accountrecord">
                                                    {" "}
                                                    New{" "}
                                                  </a>
                                                }
                                                modal
                                              >
                                                <form
                                                  onSubmit={handleSubmit}
                                                  className="add-accounts"
                                                >
                                                  <div className="form-group">
                                                    <label>
                                                      Contract Shipment Record Type
                                                    </label>
                                                    <br />
                                                    <select
                                                      name="account_record"
                                                      className="form-control"
                                                      onChange={handleChange}
                                                      value={formData.account_record}
                                                    >
                                                      <option value="">--Select record type--</option>
                                                      <option value="Buyer - Long Term">
                                                        Buyer - Long Term
                                                      </option>
                                                      <option value="Buyer - Spot">
                                                        Buyer - Spot
                                                      </option>
                                                      <option value="Supplier - Long Term">
                                                        Supplier - Long Term
                                                      </option>
                                                      <option value="Supplier - Spot">
                                                        Supplier - Spot
                                                      </option>
                                                    </select>
                                                    {error.account_record && <div className="errors-msg">{error.account_record}</div>}
                                                  </div>
                                                  <input
                                                    type="submit"
                                                    className="btn btn-primary "
                                                    value="Submit"
                                                  />
                                                </form>
                                              </Popup>
                                            </span>
                                          </h4>

                                          <hr></hr>
                                        </div>
                                        {conShipmentParents.length > 0 ? (
                                          <table id="contract-ship" className="">
                                            <thead>
                                              <tr>
                                                <th>Record Type</th>
                                                <th>Contract Ship Code</th>
                                                <th>Shipment Status</th>
                                                <th>Parent Shipment</th>
                                                <th>Pricing</th>
                                                <th>Laycan</th>
                                                <th>Quantity Info</th>
                                                <th>No. of Shipments</th>
                                                <th>No. of Orders</th>
                                                <th>TCF Count</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {conShipmentParents.map((y, i) => (
                                                <tr key={i}>
                                                  <td>{y.record_type}</td>
                                                  <td>
                                                    <Link to={`/shipment/contract-shipment-details/${y.id}`}>
                                                      <span id="codes">{y.contract_ship_code}</span>
                                                    </Link>
                                                  </td>
                                                  <td>{y.contract_shipment_status}</td>
                                                  <td>
                                                    {y.contract_ship_code === null ? (
                                                      <Link to={`/shipment/contract-shipment-details/${y.id}`}>
                                                        <span id="codes">{y.contract_ship_code}</span>
                                                      </Link>
                                                    ) : (
                                                      y.parent_shipment !== null && y.parentship_id !== null && (
                                                        <Link to={`/shipment/contract-shipment-details/${y.parentship_id}`}>
                                                          {y.parent_shipment}
                                                        </Link>
                                                      )
                                                    )}
                                                  </td>
                                                  <td>{y.pricing}</td>
                                                  <td>{y.laycan}</td>
                                                  <td>{y.quantity_info}</td>
                                                  <td>{y.no_of_shipments}</td>
                                                  <td>{y.no_of_orders}</td>
                                                  <td>{y.tcf_count}</td>
                                                </tr>
                                              ))}
                                            </tbody>
                                          </table>
                                        ) : (
                                          <p>No data available</p>
                                        )}
                                      </Row>
                                      <Row id="table-styles">
                                        <div
                                          className="col-md-12"
                                          id="head"
                                        >
                                          <h4 className="heading">
                                            <span>
                                              Opportunity Information{" "}
                                            </span>{" "}
                                            {/* <Popup trigger={ <span className="button add-accountrecord"> New </span>} modal> <form onSubmit={handleSubmitOpp} className="add-accounts">
                      <div className='form-group'>
                        <label>Opportunity Record Type</label>
                        <br />
                        <select className='form-control' name="type_of_new_record" onChange={handleChangeOpp} value={formDataopp.account_record}>
                          <option>----Select----</option>
                          <option value='Buyer'>Buyer</option>
                          <option value='Buyer - Long Term'>Buyer - Long Term</option>
                          <option value='Buyer - Spot'>Buyer - Spot</option>
                          <option value='Supplier'>Supplier</option>
                          <option value='Supplier - Long Term'>Supplier - Long Term</option>
                          <option value='Supplier - Spot'>Supplier - Spot</option>
                        </select>
                      </div>
                      <input type='submit' className="btn btn-primary " value='Submit' />
                    </form>
                  </Popup> */}
                                          </h4>
                                          <hr></hr>
                                        </div>
                                        <table
                                          id="opportunity"
                                          className=""
                                        >
                                          <thead>
                                            <tr>
                                              <th>Opportunity Name</th>

                                              <th>Opportunity No</th>
                                              <th>Stage</th>
                                              <th>Quantity (MT)	</th>
                                              <th>Supplier</th>
                                              <th>No of Order</th>
                                            </tr>
                                          </thead>{" "}
                                          {opportunity.length > 0 ? (
                                            <tbody>
                                              {" "}
                                              {opportunity.map((y, i) => (
                                                <tr>
                                                  <td>
                                                    <Link
                                                      to={`/opportunity/opportunity-detail/${y.id}`}
                                                    >
                                                      {y.opportunity_name}
                                                    </Link>
                                                  </td>
                                                  <td>
                                                    <Link
                                                      to={`/opportunity/opportunity-detail/${y.id}`}
                                                    >
                                                      {y.opportunity_no}
                                                    </Link>
                                                  </td>
                                                  <td>{y.stage}</td>
                                                  <td>{y.quantity_mt}</td>
                                                  <td><Link to={`/accounts/${y.supplier_id}`}>{y.supplier} </Link></td>
                                                  <td>{y.no_of_order}</td>
                                                </tr>
                                              ))}{" "}
                                            </tbody>
                                          ) : (
                                            <tbody>
                                              <tr>
                                                <td></td>
                                                <td></td>
                                                <td>No data available</td>
                                                <td></td>
                                                <td></td><td></td>
                                              </tr>
                                            </tbody>
                                          )}
                                        </table>
                                      </Row>


                                      <Row id="table-styles">
                                        <div className="col-md-12" id="head">
                                          <h4 className="heading">
                                            <span>Trade Confirmation Forms</span>
                                            <span>
                                              <Popup
                                                trigger={
                                                  <a className="button add-accountrecord">
                                                    {" "}
                                                    New{" "}
                                                  </a>
                                                }
                                                modal
                                              >
                                                <form
                                                  onSubmit={handleSubmittcf}
                                                  className="add-accounts"
                                                >
                                                  <div className="form-group">
                                                    <label>
                                                      TCF Record Type
                                                    </label>
                                                    <br />
                                                    <select
                                                      name="account_record"
                                                      className="form-control"
                                                      onChange={handleChangetcf}
                                                      value={
                                                        formData.account_record
                                                      }
                                                    >
                                                      <option>
                                                        --Select TCF Record
                                                        Type--
                                                      </option>
                                                      <option value="Buyer - Spot">
                                                        Buyer - Spot
                                                      </option>
                                                      <option value="Buyer - Long Term">
                                                        Buyer - Long Term
                                                      </option>
                                                    </select>
                                                  </div>
                                                  <input
                                                    type="submit"
                                                    className="btn btn-primary "
                                                    value="Submit"
                                                  />
                                                </form>
                                              </Popup>
                                            </span>
                                          </h4>
                                          <hr></hr>
                                        </div>
                                        <table id="tcf" className="">
                                          <thead>
                                            <tr>
                                              <th>TCF No</th>
                                              <th>TCF Status</th>
                                              <th>BL Quantity</th>
                                              <th>BL date</th>
                                              <th>Laycan</th>
                                              <th>contract shipment</th>
                                              <th>product</th>
                                              <th>Vessel Name	</th>
                                              <th>Proposed Laycan From	</th>
                                              <th>Proposed Laycan To</th>
                                              <th>Ship Month</th>
                                            </tr>
                                          </thead>

                                          {tcf.length > 0 ? (
                                            <tbody>
                                              {tcf.map((y, i) => (
                                                <tr>
                                                  <td>
                                                    <span id="codes">
                                                      <Link
                                                        to={`/tcf/tcf-details/${y.id}`}
                                                      >
                                                        {y.tcf_no}
                                                      </Link>
                                                    </span>
                                                  </td>
                                                  <td>{y.tcf_status}</td>
                                                  <td>{y.actual_bl_qty}</td>
                                                  <td>{y.actual_bl_date}</td>
                                                  <td>{y.laycan}</td>
                                                  <td><Link to={`/shipment/contract-shipment-details/${y.contract_ship_id}`}>{y.contract_shipment}</Link></td>
                                                  <td><Link to={`/product/product-detail/${y.product_id}`}>{y.product}</Link></td>
                                                  <td>{y.vessel_name}</td>
                                                  <td>{y.proposed_laycan_from}</td>
                                                  <td>{y.proposed_laycan_to}</td>
                                                  <td>{y.ship_month
                                                  }</td>
                                                </tr>
                                              ))}
                                            </tbody>
                                          ) : (
                                            <tbody>
                                              <tr>
                                                <td></td><td></td>
                                                <td></td>
                                                <td></td> <td></td> <td></td>
                                                <td>No data available</td>
                                                <td></td>
                                                <td></td>
                                                <td></td><td></td>
                                              </tr>
                                            </tbody>
                                          )}
                                        </table>
                                      </Row>
                                      <Row id="table-styles">
                                        <div className="col-md-12" id="head">
                                          <h4 className="heading">
                                            <span>Orders Information </span>
                                          </h4>
                                          <hr></hr>
                                        </div>
                                        <table id="orders" className="">
                                          <thead>
                                            <tr>
                                              <th>Order Number</th>
                                              <th>	Order Start Date</th>
                                              <th>Contract Shipment</th>
                                              <th>Vessel Barge Name	</th>
                                              <th>Ship Month	</th>
                                              <th>Laycan From Date</th>
                                              <th>Status</th>
                                              <th>Shipment Qty</th>
                                              <th>Account Name</th>
                                            </tr>
                                          </thead>

                                          {orderData.length > 0 ? (
                                            <tbody>
                                              {orderData.map((y, i) => (
                                                <tr>
                                                  <td> <Link
                                                    to={`/order/view-order-details/${y.id}`}
                                                  >{y.order_no}</Link></td>
                                                  <td>{y.order_start_date}</td>
                                                  <td><Link to={`/shipment/contract-shipment-details/${y.contract_ship_id}`}>{y.contract_shipment}</Link></td>
                                                  <td>{y.vessel_barge_name}</td>
                                                  <td>{y.ship_month}</td>
                                                  <td>{y.laycan_from_date}</td>
                                                  <td>{y.status}</td>
                                                  <td>{y.shipment_qty}</td>
                                                  <td><Link to={`/accounts/${y.account_id}`}>{y.account_name} </Link></td>
                                                </tr>
                                              ))}
                                            </tbody>
                                          ) : (
                                            <tbody>
                                              <tr><td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td>No data available</td>
                                                <td></td>
                                                <td></td>
                                                <td></td><td></td>
                                              </tr>
                                            </tbody>
                                          )}
                                        </table>
                                      </Row>


                                      <Row id="table-styles">
                            <div className="col-md-12" id="head">
                              <h4 className="heading">
                                <span>Contract Product</span>
                              </h4>
                              <hr></hr>
                            </div>
                            <table id="contract-product" className="">
                              <thead>
                                <tr>
                                  <th>Contract Product Number</th>
                                  <th>Product Master</th>
                                  <th>Gross As Received Typical	</th>
                                  <th>Total Moisture Typical	</th>
                                  <th>Ash Content Typical ARB	</th>
                                  <th>Total Sulphur Typical ARB	</th>
                                  <th>Price Basis GAR
                                  </th>
                                </tr>
                              </thead>

                              {contractProduct.length > 0 ? (
                                <tbody>
                                  {contractProduct.map((x, i) => (
                                    <tr>
                                      <td><Link
                                        to={`/contract-product/details/${x.id}`}
                                      >{x.contract_product_number}</Link></td>
                                      <td>
                                        <Link
                                          to={`/productmaster/product-detail/${x.id}`}
                                        >
                                          {" "}
                                          {x.product_master}{" "}
                                        </Link>
                                      </td>
                                      <td>{renderShipmentQty(x.gross_as_received_typical)}</td>
                                      <td>{renderShipmentQty(x.total_moisture_typical)}</td>
                                      <td>{renderShipmentQty(x.ash_typical_arb)}</td>
                                      <td>{renderShipmentQty(x.sulphur_typical_arb)}</td>
                                      <td>{renderShipmentQty(x.price_basis_GAR)}</td>

                                    </tr>
                                  ))}
                                </tbody>
                              ) : (
                                <tbody>
                                  <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>No data available</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                  </tr>
                                </tbody>
                              )}
                            </table>
                          </Row>    <Row id="table-styles">
                                        <div className="col-md-12" id="head">
                                          <h4 className="heading">
                                            <span>List of Documents</span>
                                            <span>
                                              {" "}
                                              <Link
                                                to={
                                                  "/contract/add-document/" + id
                                                }
                                              >
                                                <i className="fe fe-download-cloud me-2"></i>
                                                New
                                              </Link>{" "}
                                            </span>
                                          </h4>
                                          <hr></hr>
                                        </div>
                                        <table id="document" className="">
                                          <thead>
                                            <tr>
                                              <th>contract list of docs</th>
                                              <th>document required name</th>
                                              <th>list of docuemnts</th>
                                              <th>original in no</th>
                                              <th>Copies In No</th>
                                            </tr>
                                          </thead>

                                          {document.length > 0 ? (
                                            <tbody>
                                              {document.map((y, i) => (
                                                <tr>
                                                  <td className="">
                                                    <Link
                                                      to={
                                                        "/contract/document-view/" +
                                                        y.id
                                                      }
                                                    >
                                                      {y.contract_list_of_docs}
                                                    </Link>
                                                  </td>
                                                  <td>
                                                    {y.document_required_name}
                                                  </td>
                                                  <td>{y.list_of_docuemnts}</td>
                                                  <td>{y.original_in_no}</td>
                                                  <td>{y.copies_in_no}</td>
                                                </tr>
                                              ))}
                                            </tbody>
                                          ) : (
                                            <tbody>
                                              <tr>
                                                <td></td>
                                                <td></td>

                                                <td>No data available</td>
                                                <td></td>
                                                <td></td>
                                              </tr>
                                            </tbody>
                                          )}
                                        </table>
                                      </Row>
                                    </div>
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                {datas.contract_record_type ===
                                  "Supplier - Spot" ? (
                                  <>
                                    {isEditMode ? (
                                      <div className="tasks">
                                        <p className="edit-btn">
                                          <input
                                            type="submit"
                                            className="account-save"
                                            onClick={handleSaveEdit}
                                            value="Save"
                                            //disabled={isSubmitted}
                                          />
                                          <button
                                            className="btn btn-primary"
                                            onClick={handleCancelEdit}
                                          >
                                            {" "}
                                            Cancel{" "}
                                          </button>
                                        </p>
                                        <p className="errors">{errors}</p>
                                        <Row className="mb-3">
                                          <h4 className="heading">
                                            Contract Information
                                          </h4>
                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingSelect"
                                              className="dropDown"
                                              label="Currency"
                                            >
                                              <Form.Select
                                                aria-label="Floating label select example"
                                                name="currency"
                                                placeholder="Currency"
                                                tabIndex={1}
                                                className="error-validation"
                                                value={datas.currency || ""}
                                                onChange={handleChangeInput}
                                              >
                                                <option value="">
                                                  Select{" "}
                                                </option>
                                                <option value="USD">
                                                  USD{" "}
                                                </option>
                                                <option value="IDR">
                                                  IDR{" "}
                                                </option>
                                              </Form.Select>
                                            </FloatingLabel>
                                          </Form.Group>
                                          <Form.Group as={Col} md="6">
                                            <label>Locked</label>
                                            <input
                                              type='checkbox'
                                              name='locked'
                                              tabIndex={11}
                                              checked={lockeds} // Use the state variable to determine if the checkbox is checked
                                              onChange={handleCheckboxChange} // Handle the change event
                                              className='mx-3'
                                            /></Form.Group>
                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Exchange rate"
                                              className="mb-3"
                                            >
                                              <Form.Control
                                                type="text"
                                                placeholder="Exchange rate "
                                                name="exchange_rate"
                                                tabIndex={2}
                                                defaultValue={
                                                  datas.exchange_rate || ""
                                                }
                                                onChange={handleChangeInput}
                                              />
                                            </FloatingLabel>
                                          </Form.Group>
                                          <Form.Group as={Col} md="6">
                                            {" "}
                                          </Form.Group>
                                          {/* <Form.Group as={Col} md="6">
            <FloatingLabel controlId="floatingSelect" className="dropDown" label="Status">
              <Form.Select aria-label="Floating label select example" name="status" placeholder="Status" className="error-validation" value={datas.status || "" } onChange={handleChangeInput}>
                <option value="">Select </option>
                <option value="Draft">Draft </option>
                <option value="Approved">Approved </option>
              </Form.Select>
            </FloatingLabel>
          </Form.Group> */}
                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingSelect"
                                              className="dropDown"
                                              label="Account Name"
                                            >
                                              <Form.Select
                                                aria-label="Floating label select example"
                                                name="account_name"
                                                placeholder="Account Name"
                                                className="error-validation"
                                                tabIndex={3}
                                                value={datas.account_name || ""}
                                                onChange={handleChangeInput}
                                              >
                                                <option value="">
                                                  Select{" "}
                                                </option>{" "}
                                                {accountName.map((x) => {
                                                  return (
                                                    <option
                                                      value={x.account_name}
                                                    >
                                                      {x.account_name}
                                                    </option>
                                                  );
                                                })}
                                              </Form.Select>
                                            </FloatingLabel>
                                          </Form.Group>

                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Contract record type"
                                              className="mb-3"
                                            >
                                              <Form.Control
                                                type="text"
                                                placeholder="Contract record type"
                                                name="contract_record_type"
                                                tabIndex={12}
                                                className="record-type"
                                                defaultValue={
                                                  datas.contract_record_type ||
                                                  ""
                                                }
                                                onChange={handleChangeInput}
                                              />
                                            </FloatingLabel>
                                          </Form.Group>

                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Rwood contract No"
                                              className="mb-3"
                                            >
                                              <Form.Control
                                                type="text"
                                                placeholder="Rwood contract No"
                                                tabIndex={4}
                                                name="Rwood_Contract_No"
                                                defaultValue={
                                                  datas.Rwood_Contract_No || ""
                                                }
                                                onChange={handleChangeInput} disabled
                                              />
                                            </FloatingLabel>
                                          </Form.Group>

                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Contract Owner"
                                              className="mb-3"
                                            >
                                              <Form.Control
                                                type="text"
                                                placeholder="Contract Owner"
                                                name="contract_owner"
                                                tabIndex={13}
                                                defaultValue={
                                                  datas.contract_owner || ""
                                                }
                                                onChange={handleChangeInput}
                                              />
                                            </FloatingLabel>
                                          </Form.Group>
                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Contract Start Date"
                                              className="mb-3 date-picker"
                                            >
                                              <DatePicker
                                                selected={startDate}
                                                onChange={handleStartDateChange}
                                                selectsStart
                                                tabIndex={5}
                                                dateFormat="dd/MM/yyyy"
                                                placeholderText="Select"
                                              />
                                            </FloatingLabel>
                                          </Form.Group>
                                          <Form.Group as={Col} md="6"></Form.Group>
                                          {/*
                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingSelect"
                                              className="dropDown"
                                              label="Owner Expiration Notice"
                                            >
                                              <Form.Select
                                                aria-label="Floating label select example"
                                                name="owner_expiration_notice"
                                                placeholder="Owner Expiration Notice"
                                                value={
                                                  datas.owner_expiration_notice ||
                                                  ""
                                                }
                                                onChange={handleChangeInput}
                                              >
                                                <option>---None---</option>
                                                <option value="30">
                                                  30 Days
                                                </option>
                                                <option value="45">
                                                  45 Days
                                                </option>
                                                <option value="60">
                                                  60 Days
                                                </option>
                                                <option value="90">
                                                  90 Days
                                                </option>
                                                <option value="120">
                                                  120 Days
                                                </option>
                                              </Form.Select>
                                            </FloatingLabel>
                                          </Form.Group> */}

                                          <Form.Group as={Col} md="6" className="tooltips">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Contract Effective Date"
                                              className="mb-3 date-picker"
                                            >
                                              <DatePicker
                                                selected={contractEffectiveDate}
                                                onChange={handleEndDateChange}
                                                tabIndex={6}
                                                dateFormat="dd/MM/yyyy"
                                                placeholderText="Select Date"
                                              />{" "}
                                            </FloatingLabel>
                                            <Tooltip text={<span>Contract Effective Date</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                                          </Form.Group>

                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Contract term in months"
                                              className="mb-3"
                                            >
                                              <Form.Control
                                                type="text"
                                                placeholder="Contract term in months"
                                                name="contract_term_in_months"
                                                tabIndex={14}
                                                defaultValue={
                                                  datas.contract_term_in_months ||
                                                  ""
                                                }
                                                onChange={handleChangeInput}
                                              />
                                            </FloatingLabel>
                                          </Form.Group>
                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingSelect"
                                              className="dropDown"
                                              label="Contract Status"
                                            >
                                              <Form.Select
                                                aria-label="Floating label select example"
                                                name="contract_status"
                                                tabIndex={7}
                                                placeholder="Contract Status"
                                                value={
                                                  datas.contract_status || ""
                                                }
                                                onChange={handleChangeInput}
                                              >
                                                <option value="In Approval Process">
                                                  In Approval Process
                                                </option>
                                                <option value="Activated">
                                                  Activated
                                                </option>
                                                <option value="Draft" selected>Draft</option>
                                                <option value="Under Review">
                                                  Under Review
                                                </option>
                                                <option value="Draft Sent for Signing">
                                                  Draft Sent for Signing
                                                </option>
                                                <option value="Draft Received for Signing">
                                                  Draft Received for Signing
                                                </option>
                                                <option value="Signed Contract yet to Receive">
                                                  Signed Contract yet to Receive
                                                </option>
                                                <option value="Signed Contract Sent for Sign">
                                                  Signed Contract Sent for Sign
                                                </option>
                                                <option value="Signed Original Contract Need to Courier">
                                                  Signed Original Contract Need
                                                  to Courier
                                                </option>
                                                <option value="Signed Original Contract yet to Receive">
                                                  Signed Original Contract yet
                                                  to Receive
                                                </option>
                                                <option value="Original Contract Signed by Both Parties">
                                                  Original Contract Signed by
                                                  Both Parties
                                                </option>
                                                <option value="Original Contract Signed and Received by Both Parties">
                                                  Original Contract Signed and
                                                  Received by Both Parties
                                                </option>
                                                <option value="Closed">
                                                  Closed
                                                </option>
                                                <option value="Partially completed">
                                                  Partially completed
                                                </option>
                                                <option value="Completed">
                                                  Completed
                                                </option>
                                                <option value="Cancelled">
                                                  Cancelled
                                                </option>
                                                <option value="Partially cancelled">
                                                  Partially cancelled
                                                </option>
                                                <option value="Shipments - Arutmin">
                                                  Shipments - Arutmin
                                                </option>
                                                <option value="Partially Signed Contract">
                                                  Partially Signed Contract
                                                </option>
                                              </Form.Select>
                                            </FloatingLabel>
                                          </Form.Group>

                                          <Form.Group as={Col} md="6" className='textEditor'>
                                            <label>Pending Matter</label>
                                            <Editor value={pendingMatter} onChange={onChangePending} tabIndex={15}/>
                                          </Form.Group>
                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingSelect"
                                              className="dropDown"
                                              label="Trader Name"
                                            >
                                              <Form.Select
                                                aria-label="Floating label select example"
                                                name="trader_name"
                                                tabIndex={8}
                                                placeholder="Trader Name"
                                                value={datas.trader_name || ""}
                                                onChange={handleChangeInput}
                                              >
                                                <option value="">
                                                  Select{" "}
                                                </option>{" "}
                                                {traderData.map((x, i) => {
                                                  return (
                                                    <option
                                                      key={i}
                                                      value={x.rwood_username}
                                                    >
                                                      {" "}
                                                      {x.rwood_username}{" "}
                                                    </option>
                                                  );
                                                })}
                                              </Form.Select>
                                            </FloatingLabel>
                                          </Form.Group>
                                         
                                          <Form.Group as={Col} md="6" className="tooltips">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Master Contract Reference"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="Master Contract Reference"
                                    name="master_conference_reference"
                                    tabIndex={16}
                                    defaultValue={
                                      datas.master_conference_reference || ""
                                    }
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                                <Tooltip text={<span>Give master contract reference, if any</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                              </Form.Group>
                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingSelect"
                                              className="dropDown"
                                              label="Company"
                                            >
                                              <Form.Select
                                                aria-label="Floating label select example"
                                                name="company"
                                                tabIndex={9}
                                                placeholder="Company"
                                                className="error-validation"
                                                value={datas.company || ""}
                                                onChange={handleChangeInput}
                                              >
                                                <option value="">
                                                  Select{" "}
                                                </option>{" "}
                                                {companyData.map((x, i) => {
                                                  return (
                                                    <option
                                                      key={i}
                                                      value={x.company_name}
                                                    >
                                                      {" "}
                                                      {x.company_name}{" "}
                                                    </option>
                                                  );
                                                })}
                                              </Form.Select>
                                            </FloatingLabel>
                                          </Form.Group>

                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Renewal date"
                                              className="mb-3 date-picker"
                                            >
                                              <DatePicker
                                                selected={renewal}
                                                onChange={handleRenewal}
                                                tabIndex={17}
                                                dateFormat="dd/MM/yyyy"
                                                placeholderText="Select Date"
                                              />
                                            </FloatingLabel>
                                          </Form.Group>
                                          <Form.Group
                                            as={Col}
                                            md="6"
                                          ></Form.Group>
                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Renewal contract number"
                                              className="mb-3"
                                            >
                                              <Form.Control
                                                type="number"
                                                placeholder="Renewal contract number "
                                                tabIndex={18}
                                                name="renewal_contract_number "
                                                defaultValue={
                                                  datas.renewal_contract_number ||
                                                  ""
                                                }
                                                onChange={handleChangeInput}
                                              />
                                            </FloatingLabel>
                                          </Form.Group>
                                          <Form.Group as={Col} md="6"></Form.Group>
                                          <Form.Group
                                            as={Col}
                                            md="6"
                                          ></Form.Group>
                                          <Form.Group as={Col} md="6" className='textEditor tooltips'>
                                            <label>Remark</label>
                                            <Editor value={remarks} onChange={onChangeRemarks} />
                                            <Tooltip text={<span>Any Specific Remark related to Contract</span>} placement="left" tabIndex={10}>
      <IoIosHelpCircle id="help" />
      </Tooltip>
                                          </Form.Group>
                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Rwood contract number"
                                              className="mb-3"
                                            >
                                              <Form.Control
                                                type="text"
                                                tabIndex={19}
                                                placeholder="Rwood contract number"
                                                name="contract_number"
                                                defaultValue={datas.contract_number || ""}
                                                onChange={handleChangeInput} disabled
                                              />
                                            </FloatingLabel>
                                          </Form.Group>

                                        </Row>

                                        <Row className="mb-3">
                                          <h4 className="heading">
                                            Commodity and Contracted Quantity
                                          </h4>
                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Port Of Loading"
                                              className="mb-3"
                                            >
                                              <Form.Control
                                                type="text"
                                                placeholder="Port Of Loading"
                                                name="port_of_loading"
                                                tabIndex={20}
                                                defaultValue={
                                                  datas.port_of_loading || ""
                                                }
                                                onChange={handleChangeInput}
                                              />
                                            </FloatingLabel>
                                          </Form.Group>
                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Origin"
                                              className="mb-3"
                                            >
                                              <Form.Control
                                                type="text"
                                                placeholder="Origin"
                                                name="origin"
                                                tabIndex={22}
                                                defaultValue={
                                                  datas.origin || ""
                                                }
                                                onChange={handleChangeInput}
                                              />
                                            </FloatingLabel>
                                          </Form.Group>
                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Contract quantity in MT"
                                              className="mb-3"
                                            >
                                              <Form.Control
                                                type="number"
                                                placeholder="Contract quantity in MT"
                                                name="contract_quantity_in_MT"
                                                className="error-validation"
                                                tabIndex={21}
                                                defaultValue={
                                                  datas.contract_quantity_in_MT ||
                                                  ""
                                                }
                                                onChange={handleChangeInput}
                                              />
                                            </FloatingLabel>
                                          </Form.Group>
                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingSelect"
                                              className="dropDown"
                                              label="Commodity"
                                            >
                                              <Form.Select
                                                aria-label="Floating label select example"
                                                tabIndex={23}
                                                name="commodity"
                                                placeholder="Commodity"
                                                value={datas.commodity || ""}
                                                onChange={handleChangeInput}
                                              >
                                                <option>---None---</option>
                                                <option value='Indonesian Steam Coal in Bulk ("Coal")'>
                                                  Indonesian Steam Coal in Bulk
                                                  ("Coal")
                                                </option>
                                                <option value="US Coal">
                                                  US Coal
                                                </option>
                                                <option value="Russia Coal">
                                                  Russia Coal
                                                </option>
                                                <option value="SA Coal">
                                                  SA Coal
                                                </option>
                                                <option value="Clinker in Bulk">
                                                  Clinker in Bulk
                                                </option>
                                                <option value="Australian Steam Coal">
                                                  Australian Steam Coal
                                                </option>
                                              </Form.Select>
                                            </FloatingLabel>
                                          </Form.Group>
                                        </Row>
                                        <Row className="mb-3">
                                          <h4 className="heading">
                                            Price and Price Adjustment
                                          </h4>
                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Price PMT"
                                              className="mb-3"
                                            >
                                              <Form.Control
                                                type="number"
                                                placeholder="Price PMT"
                                                tabIndex={24}
                                                name="price_PMT"
                                                defaultValue={
                                                  datas.price_PMT || ""
                                                }
                                                onChange={handleChangeInput}
                                              />
                                            </FloatingLabel>
                                          </Form.Group>
                                          <Form.Group as={Col} md="6">
                                            {" "}
                                          </Form.Group>
                                          {/* <Form.Group as={Col} md="6">
            <FloatingLabel controlId="floatingSelect" className='dropDown' label="Price book">
              <Form.Select aria-label="Floating label select example" name="price_book" placeholder="Price book" value={datas.price_book || "" } onChange={handleChangeInput}>
                <option value=''>Select</option> { priceBook.map((x)=>{ return( <option value={x.price_book_name}>{x.price_book_name}</option> ) }) }
              </Form.Select>
            </FloatingLabel>
          </Form.Group> */}
                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Price PMT in words"
                                              className="mb-3"
                                            >
                                              <Form.Control
                                                type="text"
                                                placeholder="Price PMT in words"
                                                tabIndex={25}
                                                name="price_PMT_in_words"
                                                defaultValue={
                                                  datas.price_PMT_in_words || ""
                                                }
                                                onChange={handleChangeInput}
                                              />
                                            </FloatingLabel>
                                          </Form.Group>
                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingSelect"
                                              className="dropDown"
                                              label="Price Basis"
                                            >
                                              <Form.Select
                                                aria-label="Floating label select example"
                                                name="price_basis"
                                                tabIndex={29}
                                                placeholder="Price Basis"
                                                value={datas.price_basis || ""}
                                                onChange={handleChangeInput}
                                              >
                                                <option value="">
                                                  --None--
                                                </option>
                                                <option value="In Approval Process">
                                                  In Approval Process
                                                </option>
                                                <option value="Activated">
                                                  Activated
                                                </option>
                                                <option value="Draft">
                                                  Draft
                                                </option>
                                                <option value="Under Review">
                                                  Under Review
                                                </option>
                                                <option value="Draft Sent for Signing">
                                                  Draft Sent for Signing
                                                </option>
                                                <option value="Draft Received for Signing">
                                                  Draft Received for Signing
                                                </option>
                                                <option value="Signed Contract yet to Receive">
                                                  Signed Contract yet to Receive
                                                </option>
                                                <option value="Signed Contract Sent for Sign">
                                                  Signed Contract Sent for Sign
                                                </option>
                                                <option value="Signed Original Contract Need to Courier">
                                                  Signed Original Contract Need
                                                  to Courier
                                                </option>
                                                <option value="Signed Original Contract yet to Receive">
                                                  Signed Original Contract yet
                                                  to Receive
                                                </option>
                                                <option value="Original Contract Signed by Both Parties">
                                                  Original Contract Signed by
                                                  Both Parties
                                                </option>
                                                <option value="Original Contract Signed and Received by Both Parties">
                                                  Original Contract Signed and
                                                  Received by Both Parties
                                                </option>
                                                <option value="Closed">
                                                  Closed
                                                </option>
                                                <option value="Partially completed">
                                                  Partially completed
                                                </option>
                                                <option value="Completed">
                                                  Completed
                                                </option>
                                                <option value="Cancelled">
                                                  Cancelled
                                                </option>
                                                <option value="Partially cancelled">
                                                  Partially cancelled
                                                </option>
                                                <option value="Shipments - Arutmin">
                                                  Shipments - Arutmin
                                                </option>
                                                <option value="Partially Signed Contract">
                                                  Partially Signed Contract
                                                </option>
                                              </Form.Select>
                                            </FloatingLabel>
                                          </Form.Group>
                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingSelect"
                                              className="dropDown"
                                              label="Price type"
                                            >
                                              <Form.Select
                                                aria-label="Floating label select example"
                                                name="price_type"
                                                tabIndex={26}
                                                placeholder="Price type"
                                                value={datas.price_type || ""}
                                                onChange={handleChangeInput}
                                              >
                                                <option value="">Select</option>{" "}
                                                {getPrice.map((x) => {
                                                  return (
                                                    <option
                                                      value={x.price_type_name}
                                                    >
                                                      {" "}
                                                      {x.price_type_name}{" "}
                                                    </option>
                                                  );
                                                })}
                                              </Form.Select>
                                            </FloatingLabel>
                                          </Form.Group>
                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Price basis GAR"
                                              className="mb-3"
                                            >
                                              <Form.Control
                                                type="number"
                                                placeholder="Price basis GAR"
                                                name="price_basis_GAR"
                                                tabIndex={30}
                                                defaultValue={
                                                  datas.price_basis_GAR || ""
                                                }
                                                onChange={handleChangeInput}
                                              />
                                            </FloatingLabel>
                                          </Form.Group>
                                          <Form.Group as={Col} md="6" className='textEditor'>
                                            <label>Price  Adjustment Clause</label>
                                            <Editor value={priceAdjust} onChange={onChangePricingAdjust} tabIndex={27}/>
                                          </Form.Group>
                                          <Form.Group as={Col} md="6" className='textEditor'>
                                            <label>Rejections</label>
                                            <Editor value={rejections} onChange={onChangeRejections} tabIndex={31}/>
                                          </Form.Group>
                                          <Form.Group as={Col} md="6" className='textEditor'>
                                            <label>Description</label>
                                            <Editor value={description_1} onChange={onChangeDescription} tabIndex={28}/>
                                          </Form.Group>
                                          <Form.Group as={Col} md="6" className='textEditor'>
                                            <label>Pricing Terms</label>
                                            <Editor value={pricingTerm} onChange={onChangePricingTerm} tabIndex={32}/>
                                          </Form.Group>
                                        </Row>
                                        <Row className="mb-3">
                                          <h4 className="heading">
                                            Other Standard Term From Supplier
                                          </h4>
                                          {/* <Form.Group as={Col} md="12">
                                            <FloatingLabel
                                              controlId="floatingTextarea2"
                                              label="Payment Terms"
                                            >
                                              <Form.Control
                                                as="textarea"
                                                placeholder="Payment Terms"
                                                style={{
                                                  height: "100px!important",
                                                }}
                                                name="payment_terms"
                                                defaultValue={
                                                  datas.payment_terms || ""
                                                }
                                                onChange={handleChangeInput}
                                              />
                                            </FloatingLabel>
                                          </Form.Group> */}

                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingSelect"
                                              className="dropDown"
                                              label="Applicable Law"
                                            >
                                              <Form.Select
                                              tabIndex={33}
                                                aria-label="Floating label select example"
                                                name="applicable_law"
                                                placeholder="Applicable Law"
                                                value={
                                                  datas.applicable_law || ""
                                                }
                                                onChange={handleChangeInput}
                                              >
                                                <option value="">
                                                  --None--
                                                </option>
                                                <option value="English Law">
                                                  English Law
                                                </option>
                                                <option value="Singapore Law">
                                                  Singapore Law
                                                </option>
                                                <option value="Indonesian Law">
                                                  Indonesian Law
                                                </option>
                                              </Form.Select>
                                            </FloatingLabel>
                                          </Form.Group>
                                          <Form.Group
                                            as={Col}
                                            md="6"
                                          ></Form.Group>
                                          <Form.Group as={Col} md="6"></Form.Group>
                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingSelect"
                                              className="dropDown"
                                              label="Arbitration"
                                            >
                                              <Form.Select
                                                aria-label="Floating label select example"
                                                name="arbitration"
                                                placeholder="Arbitration"
                                                tabIndex={33}
                                                value={datas.arbitration || ""}
                                                onChange={handleChangeInput}
                                              >
                                                <option value="">
                                                  --None--
                                                </option>
                                                <option value="In Singapore as per SIAC">
                                                  In Singapore as per SIAC
                                                </option>
                                                <option value="Singapore Chamber of Maritime Arbitration (SCMA)">
                                                  Singapore Chamber of Maritime
                                                  Arbitration (SCMA)
                                                </option>
                                                <option value="Laws of England and Wales">
                                                  Laws of England and Wales
                                                </option>
                                                <option value="Rules of Badan Arbitrase Nasional Indonesia (BANI)">
                                                  Rules of Badan Arbitrase
                                                  Nasional Indonesia (BANI)
                                                </option>
                                              </Form.Select>
                                            </FloatingLabel>
                                          </Form.Group>
                                          <Form.Group
                                            as={Col}
                                            md="6"
                                          ></Form.Group>
                                          <Form.Group as={Col} md="6" className='textEditor'>
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="General terms"
                                              className="mb-3"
                                            >
                                              <Editor tabIndex={34} value={generalTerms} onChange={onChangeGeneralTerms} />
                                            </FloatingLabel>
                                          </Form.Group>
                                        </Row>
                                        {/* <Row className="mb-3">
                                          <h4 className="heading">
                                            Description Information
                                          </h4>
                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Loading rate per day"
                                              className="mb-3"
                                            >
                                              <Form.Control
                                                type="text"
                                                placeholder="Loading rate per day"
                                                name="loading_rate_per_day"
                                                defaultValue={
                                                  datas.loading_rate_per_day ||
                                                  ""
                                                }
                                                onChange={handleChangeInput}
                                              />
                                            </FloatingLabel>
                                          </Form.Group>
                                          <Form.Group
                                            as={Col}
                                            md="6"
                                          ></Form.Group>
                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Special Terms"
                                              className="mb-3"
                                            >
                                              <Form.Control
                                                as="textarea"
                                                placeholder="Special Terms"
                                                name="special_terms"
                                                defaultValue={
                                                  datas.special_terms || ""
                                                }
                                                onChange={handleChangeInput}
                                              />
                                            </FloatingLabel>
                                          </Form.Group>
                                          <Form.Group
                                            as={Col}
                                            md="6"
                                          ></Form.Group>
                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Bonus penalty clause"
                                              className="mb-3"
                                            >
                                              <Form.Control
                                                as="textarea"
                                                placeholder="Bonus penalty clause"
                                                name="bonus_penalty_clause"
                                                defaultValue={
                                                  datas.bonus_penalty_clause ||
                                                  ""
                                                }
                                                onChange={handleChangeInput}
                                              />
                                            </FloatingLabel>
                                          </Form.Group>
                                        </Row> */}
                                        <Row className="mb-3">
                                          <h4 className="heading">Address Information</h4>
                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingTextarea2"
                                              label="billing address"
                                            >
                                              <Form.Control
                                                as="textarea"
                                                placeholder="billing address"
                                                style={{ height: "100px!important" }}
                                                name="billing_address"
                                                tabIndex={35}
                                                defaultValue={datas.billing_address || ""}
                                                onChange={handleChangeInput}
                                              />
                                            </FloatingLabel>
                                          </Form.Group>
                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingTextarea2"
                                              label="shipping address"
                                            >
                                              <Form.Control
                                                as="textarea"
                                                placeholder="shipping address"
                                                tabIndex={36}
                                                style={{ height: "100px!important" }}
                                                name="shipping_address"
                                                defaultValue={datas.shipping_address || ""}
                                                onChange={handleChangeInput}
                                              />
                                            </FloatingLabel>
                                          </Form.Group>
                                          {/*
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing street"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing street"
                    name="billing_street"
                    {...register("billing_street", { required: false })}
                  />
                  {errors.billing_street && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping street"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Shipping street"
                    name="shipping_street"
                    {...register("shipping_street", { required: false })}
                  />
                  {errors.shipping_street && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing zip / postal code"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing zip / postal code"
                    name="billing_zip_postal_code"
                    {...register("billing_zip_postal_code", {
                      required: false,
                    })}
                  />
                  {errors.billing_zip_postal_code && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping zip / postal code"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Shipping zip / postal code"
                    name="shipping_zip_postal_code"
                    {...register("shipping_zip_postal_code", {
                      required: false,
                    })}
                  />
                  {errors.shipping_zip_postal_code && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing city"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing city"
                    name="billing_city"
                    {...register("billing_city", { required: false })}
                  />
                  {errors.billing_city && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping city"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Shipping city"
                    name="shipping_city"
                    {...register("shipping_city", { required: false })}
                  />
                  {errors.shipping_city && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing state / province"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing state / province"
                    name="billing_state_or_province"
                    {...register("billing_state_or_province", {
                      required: false,
                    })}
                  />
                  {errors.billing_state_or_province && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping state / province"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Shipping state / province"
                    name="shipping_state_or_province"
                    {...register("shipping_state_or_province", {
                      required: false,
                    })}
                  />
                  {errors.shipping_state_or_province && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing country"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing country"
                    name="billing_country"
                    {...register("billing_country", { required: false })}
                  />
                  {errors.billing_country && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping country"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Shipping country"
                    name="shipping_country"
                    {...register("shipping_country", { required: false })}
                  />
                  {errors.shipping_country && <span>Required </span>}
                </FloatingLabel>
              </Form.Group> */}
                                        </Row>
                                        <Row className="mb-3">
                                          <h4 className="heading">
                                            Signature Information
                                          </h4>
                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingSelect"
                                              className="dropDown"
                                              label="Customer signed by"
                                            >
                                              <Form.Select
                                                aria-label="Floating label select example"
                                                name="customer_signed_by"
                                                tabIndex={37}
                                                placeholder="Customer signed by"
                                                value={
                                                  datas.customer_signed_by || ""
                                                }
                                                onChange={handleChangeInput}
                                              >
                                                <option value="">
                                                  Select{" "}
                                                </option>{" "}
                                                {contacts.map((x) => {
                                                  return (
                                                    <option
                                                      value={
                                                        x.contact_full_name
                                                      }
                                                    >
                                                      {" "}
                                                      {x.contact_full_name}{" "}
                                                    </option>
                                                  );
                                                })}
                                              </Form.Select>
                                            </FloatingLabel>
                                          </Form.Group>
                                          {/* <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingSelect"
                                              className="dropDown"
                                              label="Company signed by"
                                            >
                                              <Form.Select
                                                aria-label="Floating label select example"
                                                name="company_signed_by"
                                                placeholder="Company signed by"
                                                value={
                                                  datas.company_signed_by || ""
                                                }
                                                onChange={handleChangeInput}
                                              >
                                                <option value="">
                                                  Select{" "}
                                                </option>{" "}
                                                {users.map((x) => {
                                                  return (
                                                    <option value={x.username}>
                                                      {x.username}
                                                    </option>
                                                  );
                                                })}
                                              </Form.Select>
                                            </FloatingLabel>
                                          </Form.Group> */}
                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Customer signed title"
                                              className="mb-3"
                                            >
                                              <Form.Control
                                                type="text"
                                                placeholder="Customer signed title"
                                                name="customer_signed_title"
                                                tabIndex={40}
                                                defaultValue={
                                                  datas.customer_signed_title ||
                                                  ""
                                                }
                                                onChange={handleChangeInput}
                                              />
                                            </FloatingLabel>
                                          </Form.Group>
                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Company Signed Date"
                                              className="mb-3 date-picker"
                                            >
                                              <DatePicker
                                                selected={signed}
                                                onChange={handleSigned}
                                                dateFormat="dd/MM/yyyy"
                                                tabIndex={38}
                                                placeholderText="Select Date"
                                              />
                                            </FloatingLabel>
                                          </Form.Group>
                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Customer Signed Date"
                                              className="mb-3 date-picker"
                                            >
                                              <DatePicker
                                                selected={customersigned}
                                                onChange={
                                                  handleCustomerSignedDate
                                                }
                                                selectsStart
                                                tabIndex={41}
                                                dateFormat="dd/MM/yyyy"
                                                placeholderText="Select"
                                              />
                                            </FloatingLabel>
                                          </Form.Group>
                                          <Form.Group as={Col} md="6"></Form.Group>
                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Original Contract Recieved Date"
                                              className="mb-3 date-picker"
                                            >
                                              <DatePicker
                                                selected={originals}
                                                onChange={handleOriginal}
                                                dateFormat="dd/MM/yyyy"
                                                tabIndex={42}
                                                placeholderText="Select Date"
                                              />
                                            </FloatingLabel>
                                          </Form.Group>
                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Original contract sent date"
                                              className="mb-3 date-picker"
                                            >
                                              <DatePicker
                                                selected={originalcontract}
                                                onChange={
                                                  handleOriginalcontract
                                                }
                                                dateFormat="dd/MM/yyyy"
                                                tabIndex={39}
                                                placeholderText="Select Date"
                                              />
                                            </FloatingLabel>
                                          </Form.Group>
                                        </Row>

                                        <p className="edit-btn">
                                          <input
                                            type="submit"
                                            className="account-save"
                                            onClick={handleSaveEdit}
                                            value="Save"
                                            //disabled={isSubmitted}
                                          />
                                          <button
                                            className="btn btn-primary"
                                            onClick={handleCancelEdit}
                                          >
                                            {" "}
                                            Cancel{" "}
                                          </button>
                                        </p>
                                      </div>
                                    ) : (
                                      <div className="contactAccount fulid-section">
                                        <div className="tasks">
                                          <div className="edit-delete">
                                            <span
                                              id="edit"
                                              onClick={handleEditClick}
                                            >
                                              {" "}
                                              Edit{" "}
                                            </span>
                                            <span>
                                              <Link
                                                to={
                                                  "/contract/create-contract-list"
                                                }
                                              >
                                                Cancel
                                              </Link>
                                            </span>
                                            <span> Activate </span>
                                            <span> Generate Contract </span>
                                            <span>
                                              {" "}
                                              Generate Contract Clauses{" "}
                                            </span>
                                            <span> Shipment Checklist </span>
                                          </div>
                                          <div className="">
                                            <div className="">
                                              <div className="row">
                                                <div
                                                  className="col-md-12"
                                                  id="head"
                                                >
                                                  <h4 className="heading">
                                                    Information
                                                  </h4>
                                                  <hr></hr>
                                                </div>
                                              </div>
                                              <table class="table table-bordered account-table tables">
                                                <tbody>
                                                  <tr>
                                                    <td id="td-right">
                                                      <span>
                                                        rwood contract number
                                                      </span>
                                                    </td>
                                                    <td>
                                                      {
                                                        datas.contract_number
                                                      }
                                                    </td>
                                                    <td id="td-right">
                                                      <span>Locked</span>
                                                    </td>
                                                    <td> <input
                                                      type='checkbox'
                                                      name='locked'
                                                      checked={lockeds} // Use the state variable to determine if the checkbox is checked
                                                      onChange={handleCheckboxChange} // Handle the change event
                                                      className='mx-3' disabled={isButtonEnabled}
                                                    /> </td>
                                                  </tr>

                                                  <tr>
                                                    <td id="td-right">
                                                      <span>Currency</span>
                                                    </td>
                                                    <td> {datas.currency} </td>
                                                    <td id="td-right">
                                                      <span>Status</span>
                                                    </td>
                                                    <td>{datas.status}</td>
                                                  </tr>
                                                  <tr>
                                                    <td id="td-right">
                                                      <span>Exchange Rate</span>
                                                    </td>
                                                    <td>
                                                      {" "}
                                                      {datas.exchange_rate}{" "}
                                                    </td>
                                                    <td id="td-right">
                                                      <span>
                                                        Contract record type
                                                      </span>
                                                    </td>
                                                    <td>
                                                      {" "}
                                                      {
                                                        datas.contract_record_type
                                                      }{" "}
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td id="td-right">
                                                      <span>Account Name</span>
                                                    </td>
                                                    <td>
                                                      <Link
                                                        to={
                                                          "/accounts/" +
                                                          datas.account_id
                                                        }
                                                      >
                                                        {" "}
                                                        {
                                                          datas.account_name
                                                        }{" "}
                                                      </Link>
                                                    </td>
                                                    <td id="td-right">
                                                      <span>
                                                        Contract Owner
                                                      </span>
                                                    </td>
                                                    <td>
                                                      {" "}
                                                      {
                                                        datas.contract_owner
                                                      }{" "}
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td id="td-right">
                                                      <span>Buyer Billing Address
                                                      </span>
                                                    </td>
                                                    <td>
                                                      {datas.billing_address}{" "}
                                                    </td>

                                                    <td id="td-right">
                                                      {/* <span>Contract term in months</span> */}
                                                    </td>
                                                    <td>
                                                      {/* {datas.contract_term_in_months} */}
                                                    </td>
                                                  </tr>
                                                  <tr>
                                        <td id="td-right">
                                          <span>Contract Number</span>
                                        </td>
                                        <td><Link to={`/contract/contract-details/${datas.id}`}>{datas.contract_number}</Link></td>
                                        <td id="td-right">
                                          {/* <span>Contract term in months</span> */}
                                        </td>
                                        <td>
                                          {/* {datas.contract_term_in_months} */}
                                          </td>
                                      </tr>
                                                  <tr>
                                                    <td id="td-right">
                                                      <span>
                                                        Rwood contract no
                                                      </span>
                                                    </td>
                                                    <td>
                                                      {" "}
                                                      {
                                                        <Link to={`/contract/contract-details/${datas.id}`}>{datas.Rwood_Contract_No}</Link>
                                                      }{" "}
                                                    </td>
                                                    <td id="td-right">
                                                      <span>
                                                        Owner Expiration Notice{" "}
                                                      </span>
                                                    </td>
                                                    <td>
                                                      {" "}
                                                      {
                                                        datas.owner_expiration_notice
                                                      }{" "}
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td id="td-right">
                                                      <span>
                                                        Contract Start Date
                                                      </span>
                                                    </td>
                                                    <td>
                                                      {" "}
                                                      {
                                                        datas.contract_start_date
                                                      }{" "}
                                                    </td>
                                                    <td id="td-right">
                                                      <span>
                                                        Contract term in months
                                                      </span>
                                                    </td>
                                                    <td>
                                                      {" "}
                                                      {
                                                        datas.contract_term_in_months
                                                      }{" "}
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td id="td-right">
                                                      <span>
                                                        Contract End Date
                                                      </span>
                                                    </td>
                                                    <td>
                                                      {" "}
                                                      {
                                                        datas.contract_end_date
                                                      }{" "}
                                                    </td>
                                                    <td id="td-right">
                                                      <span>
                                                        Pending Matters
                                                      </span>
                                                    </td>
                                                    <td>
                                                      {" "}
                                                      {
                                                        datas.pending_matters
                                                      }{" "}
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td id="td-right">
                                                      <span>
                                                        Contract Effective Date
                                                      </span>
                                                    </td>
                                                    <td>
                                                      {" "}
                                                      {
                                                        datas.contract_effective_date
                                                      }{" "}
                                                    </td>
                                                    <td id="td-right">
                                                    <span className="a-a">
                                          Master Contract Reference{" "}
                                                <Tooltip text={<span>Give master contract reference, if any</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                                              </span>
                                                    </td>
                                                    <td>
                                                      {" "}
                                                      {
                                                        datas.master_conference_reference
                                                      }{" "}
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td id="td-right">
                                                      <span>
                                                        Contract Status
                                                      </span>
                                                    </td>
                                                    <td>
                                                      {" "}
                                                      {
                                                        datas.contract_status
                                                      }{" "}
                                                    </td>
                                                    <td id="td-right">
                                                      <span>Renewal Date</span>
                                                    </td>
                                                    <td>
                                                      {" "}
                                                      {datas.renewal_date}{" "}
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td id="td-right">
                                                      <span> Trader Name </span>
                                                    </td>
                                                    <td>
                                                      {datas.trader_name !== null && datas.trader_name !== "" ? (
                                                        <Link to={"/user/user-details/" + datas.rwood_user_id}>
                                                          {datas.trader_name}
                                                        </Link>
                                                      ) : (
                                                        <span>{datas.trader_name}</span>
                                                      )}
                                                    </td>

                                                    <td id="td-right">
                                                      <span>
                                                        Renewal contract number
                                                      </span>
                                                    </td>
                                                    <td>
                                                      {" "}
                                                      {
                                                        datas.renewal_contract_number
                                                      }{" "}
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td id="td-right">
                                                      <span>Company</span>
                                                    </td>
                                                    <td>
                                                      <Link
                                                        to={`/company/company-details/${datas.rwood_company_id}`}
                                                      >
                                                        {datas.company}
                                                      </Link>
                                                    </td>
                                                    <td id="td-right">
                                                       <span className="a-a">
                                          Remark{" "}
                                                <Tooltip text={<span>Any Specific Remark related to Contract</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                                              </span>
                                                    </td>
                                                    <td> <div dangerouslySetInnerHTML={{ __html: datas.remark }} /> </td>
                                                  </tr>

                                                </tbody>
                                              </table>
                                            </div>
                                            <div className="">
                                              <div className="row">
                                                <div
                                                  className="col-md-12"
                                                  id="head"
                                                >
                                                  <h4 className="heading">
                                                    Commodity and Contracted
                                                    Quantity
                                                  </h4>
                                                  <hr></hr>
                                                </div>
                                              </div>
                                              <table class="table table-bordered account-table tables">
                                                <tbody>
                                                  <tr>
                                                    <td id="td-right">
                                                      <span>
                                                        Port Of Loading
                                                      </span>
                                                    </td>
                                                    <td>
                                                      {" "}
                                                      {
                                                        datas.port_of_loading
                                                      }{" "}
                                                    </td>
                                                    <td id="td-right">
                                                      <span>Origin</span>
                                                    </td>
                                                    <td> {datas.origin} </td>
                                                  </tr>
                                                  <tr>
                                                    <td id="td-right">
                                                      <span>
                                                        Contract Quantity in MT
                                                      </span>
                                                    </td>
                                                    <td>
                                                      {" "}
                                                      {
                                                        datas.contract_quantity_in_MT
                                                      }{" "}
                                                    </td>
                                                    <td id="td-right">
                                                      <span>Commodity</span>
                                                    </td>
                                                    <td> {datas.commodity} </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                            <div className="">
                                              <div className="row">
                                                <div
                                                  className="col-md-12"
                                                  id="head"
                                                >
                                                  <h4 className="heading">
                                                    Price and Price Adjustment
                                                  </h4>
                                                  <hr></hr>
                                                </div>
                                              </div>
                                              <table class="table table-bordered account-table tables">
                                                <tbody>
                                                  <tr>
                                                    <td id="td-right">
                                                      <span>Price PMT</span>
                                                    </td>
                                                    <td> {datas.price_PMT} </td>

                                                    <td id="td-right">
                                                      {/* <span>Price Book</span> */}
                                                    </td>
                                                    <td>
                                                      {/* {datas.price_book}  */}
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td id="td-right">
                                                      <span>
                                                        Price PMT in words
                                                      </span>
                                                    </td>
                                                    <td>
                                                      {" "}
                                                      {
                                                        datas.price_PMT_in_words
                                                      }{" "}
                                                    </td>
                                                    <td id="td-right">
                                                      <span>Price Basis</span>
                                                    </td>
                                                    <td>
                                                      {" "}
                                                      {datas.price_basis}{" "}
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td id="td-right">
                                                      <span>Price Type</span>
                                                    </td>
                                                    <td>
                                                      {" "}
                                                      {datas.price_type}{" "}
                                                    </td>
                                                    <td id="td-right">
                                                      <span>
                                                        Price Basis GAR
                                                      </span>
                                                    </td>
                                                    <td>
                                                      {" "}
                                                      {
                                                        datas.price_basis_GAR
                                                      }{" "}
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td id="td-right">
                                                      <span>
                                                        Price adjustment clause
                                                      </span>
                                                    </td>
                                                    <td>
                                                      <div dangerouslySetInnerHTML={{ __html: datas.price_adjustment_clause }} />
                                                    </td>
                                                    <td id="td-right">
                                                      <span>Rejections</span>
                                                    </td>
                                                    <td>
                                                      {" "}
                                                      <div dangerouslySetInnerHTML={{ __html: datas.rejections }} />{" "}
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td id="td-right">
                                                      <span>description</span>
                                                    </td>
                                                    <td>
                                                      {" "}
                                                      <div dangerouslySetInnerHTML={{ __html: datas.description }} />{" "}
                                                    </td>
                                                    <td id="td-right">
                                                      <span>Pricing Terms</span>
                                                    </td>
                                                    <td>
                                                      {" "}
                                                      <div dangerouslySetInnerHTML={{ __html: datas.pricing_terms }} />{" "}
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                            <div className="">
                                              <div className="row">
                                                <div
                                                  className="col-md-12"
                                                  id="head"
                                                >
                                                  <h4 className="heading">
                                                    Other Standard Term From
                                                    Supplier
                                                  </h4>
                                                  <hr></hr>
                                                </div>
                                              </div>
                                              <table class="table table-bordered account-table tables">
                                                <tbody>
                                                  <tr>
                                                    <td id="td-right">
                                                      <span>Payment Terms</span>
                                                    </td>
                                                    <td>
                                                      {" "}
                                                      <div dangerouslySetInnerHTML={{ __html: datas.payment_terms }} />{" "}
                                                    </td>
                                                    <td id="td-right">
                                                      {/* <span>Applicable Law</span> */}
                                                    </td>
                                                    <td> </td>
                                                  </tr>
                                                  <tr>
                                                    <td id="td-right">
                                                      <span>
                                                        Applicable Law
                                                      </span>
                                                    </td>
                                                    <td>
                                                      {" "}
                                                      {
                                                        datas.applicable_law
                                                      }{" "}
                                                    </td>
                                                    <td id="td-right">
                                                      {/* <span>Arbitration</span> */}
                                                    </td>
                                                    <td> </td>
                                                  </tr>
                                                  <tr>
                                                    <td id="td-right">
                                                      <span>Arbitration</span>
                                                    </td>
                                                    <td>
                                                      {" "}
                                                      {datas.arbitration}{" "}
                                                    </td>
                                                    <td id="td-right">
                                                      {/* <span>General Terms</span> */}
                                                    </td>
                                                    <td> </td>
                                                  </tr>
                                                  <tr>
                                                    <td id="td-right">
                                                      <span>General Terms</span>
                                                    </td>
                                                    <td>
                                                      {" "}
                                                      <div dangerouslySetInnerHTML={{ __html: datas.general_terms }} />{" "}
                                                    </td>
                                                    <td id="td-right">
                                                      {/* <span>Arbitration</span> */}
                                                    </td>
                                                    <td> </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                            {/* <div className="">
                                              <div className="row">
                                                <div
                                                  className="col-md-12"
                                                  id="head"
                                                >
                                                  <h4 className="heading">
                                                    Description Information
                                                  </h4>
                                                  <hr></hr>
                                                </div>
                                              </div>
                                              <table class="table table-bordered account-table tables">
                                                <tbody>
                                                  <tr>
                                                    <td id="td-right">
                                                      <span>
                                                        Loading rate per day
                                                      </span>
                                                    </td>
                                                    <td>
                                                      {" "}
                                                      {
                                                        datas.loading_rate_per_day
                                                      }{" "}
                                                    </td>
                                                    <td id="td-right">
                                                      {/* <span>Special Terms</span>
                                                    </td>
                                                    <td> </td>
                                                  </tr>
                                                  <tr>
                                                    <td id="td-right">
                                                      <span>Special Terms</span>
                                                    </td>
                                                    <td>
                                                      {" "}
                                                      {datas.special_terms}{" "}
                                                    </td>
                                                    <td id="td-right">
                                                      {/* <span>Special Terms</span>
                                                    </td>
                                                    <td> </td>
                                                  </tr>
                                                  <tr>
                                                    <td id="td-right">
                                                      <span>
                                                        Bonus penalty clause
                                                      </span>
                                                    </td>
                                                    <td>
                                                      {" "}
                                                      {
                                                        datas.bonus_penalty_clause
                                                      }{" "}
                                                    </td>
                                                    <td id="td-right"></td>
                                                    <td></td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div> */}
                                            <div className="">
                                              <div className="row">
                                                <div
                                                  className="col-md-12"
                                                  id="head"
                                                >
                                                  <h4 className="heading">
                                                    Other Standard Terms
                                                  </h4>
                                                  <hr></hr>
                                                </div>
                                              </div>
                                              <table class="table table-bordered account-table tables">
                                                <tbody>
                                                  <tr>
                                                    <td id="td-right">
                                                      <span>
                                                        Billing Address
                                                      </span>
                                                    </td>
                                                    <td>
                                                      {" "}
                                                      {
                                                        datas.billing_address
                                                      }{" "}
                                                    </td>
                                                    <td id="td-right">
                                                      <span>
                                                        Shipping Address
                                                      </span>
                                                    </td>
                                                    <td>
                                                      {" "}
                                                      {
                                                        datas.shipping_address
                                                      }{" "}
                                                    </td>
                                                  </tr>
                                                  {/* <tr>
                                                    <td id="td-right">
                                                      <span>
                                                        Billing Zip Postal Code
                                                      </span>
                                                    </td>
                                                    <td>
                                                      {" "}
                                                      {
                                                        datas.billing_zip_postal_code
                                                      }{" "}
                                                    </td>
                                                    <td id="td-right">
                                                      <span>
                                                        Shipping zip postal code
                                                      </span>
                                                    </td>
                                                    <td>
                                                      {" "}
                                                      {
                                                        datas.shipping_zip_postal_code
                                                      }{" "}
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td id="td-right">
                                                      <span>Billing City</span>
                                                    </td>
                                                    <td>
                                                      {" "}
                                                      {datas.billing_city}{" "}
                                                    </td>
                                                    <td id="td-right">
                                                      <span>Shipping City</span>
                                                    </td>
                                                    <td>
                                                      {" "}
                                                      {datas.shipping_city}{" "}
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td id="td-right">
                                                      <span>
                                                        Billing state or
                                                        province
                                                      </span>
                                                    </td>
                                                    <td>
                                                      {" "}
                                                      {
                                                        datas.billing_state_or_province
                                                      }{" "}
                                                    </td>
                                                    <td id="td-right">
                                                      <span>
                                                        Shipping state or
                                                        province
                                                      </span>
                                                    </td>
                                                    <td>
                                                      {" "}
                                                      {
                                                        datas.shipping_state_or_province
                                                      }{" "}
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td id="td-right">
                                                      <span>
                                                        Billing Country
                                                      </span>
                                                    </td>
                                                    <td>
                                                      {" "}
                                                      {
                                                        datas.billing_country
                                                      }{" "}
                                                    </td>
                                                    <td id="td-right">
                                                      <span>
                                                        Shipping Country
                                                      </span>
                                                    </td>
                                                    <td>
                                                      {" "}
                                                      {
                                                        datas.shipping_country
                                                      }{" "}
                                                    </td>
                                                  </tr> */}
                                                </tbody>
                                              </table>
                                            </div>
                                            <div className="">
                                              <div className="row">
                                                <div
                                                  className="col-md-12"
                                                  id="head"
                                                >
                                                  <h4 className="heading">
                                                    Signature Information
                                                  </h4>
                                                  <hr></hr>
                                                </div>
                                              </div>
                                              <table class="table table-bordered account-table tables">
                                                <tbody>
                                                  <tr>
                                                    <td id="td-right">
                                                      <span>
                                                        Customer signed by
                                                      </span>
                                                    </td>
                                                    <td>
                                                      {datas.customer_signed_by}
                                                    </td>
                                                    <td id="td-right">
                                                      <span>
                                                        Company signed by
                                                      </span>
                                                    </td>
                                                    <td>
                                                      {datas.company_signed_by}
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td id="td-right">
                                                      <span>
                                                        Customer signed title
                                                      </span>
                                                    </td>
                                                    <td>
                                                      {
                                                        datas.customer_signed_title
                                                      }
                                                    </td>

                                                    <td id="td-right">
                                                      <span>
                                                        Company signed date
                                                      </span>
                                                    </td>
                                                    <td>
                                                      {
                                                        datas.company_signed_date
                                                      }
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td id="td-right">
                                                      <span>
                                                        Customer signed date
                                                      </span>
                                                    </td>
                                                    <td>
                                                      {
                                                        datas.customer_signed_date
                                                      }
                                                    </td>
                                                    <td id="td-right">
                                                      <span>
                                                        Original contract
                                                        recieved date
                                                      </span>
                                                    </td>
                                                    <td>
                                                      {
                                                        datas.original_contract_recieved_date
                                                      }
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td id="td-right">
                                                      <span>
                                                        Original contract sent
                                                        date
                                                      </span>
                                                    </td>
                                                    <td>
                                                      {
                                                        datas.original_contract_sent_date
                                                      }
                                                    </td>
                                                    <td id="td-right"></td>
                                                    <td></td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                            <div className="">
                                      <div className="row">
                                        <div className="col-md-12" id="head">
                                          <h4 className="heading">
                                            System Information
                                          </h4>
                                          <hr></hr>
                                        </div>
                                      </div>
                                      <table class="table table-bordered account-table tables">
                                        <tbody>

                                          <tr>
                                            <td id="td-right">
                                              <span>Activated By
                                              </span>
                                            </td>
                                            <td>{datas.activated_by
                                            }</td>
                                            <td id="td-right">
                                              <span>Activated Date
                                              </span>
                                            </td>
                                            <td>{datas.activated_date
                                            }</td>
                                          </tr>
                                          <tr>
                                            <td id="td-right">
                                              <span>Created By
                                              </span>
                                            </td>
                                            <td>
                                              {datas.created_by
                                              }
                                            </td>

                                            <td id="td-right">
                                              <span>Last Modified By
                                              </span>
                                            </td>
                                            <td>{datas.last_modified_by
                                            }</td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                          </div>
                                        </div>
                                        <div
                                          className="tasks view-table"
                                          Style="box-showdow:0px 13px 20px #d6d1d1"
                                        >






                                          <Row id="table-styles">
                                            <div className="col-md-12" id="head">
                                              <h4 className="heading">
                                                <span>Contract Shipment</span>
                                                <span>
                                                  <Popup
                                                    trigger={
                                                      <a className="button add-accountrecord">
                                                        {" "}
                                                        New{" "}
                                                      </a>
                                                    }
                                                    modal
                                                  >
                                                    <form
                                                      onSubmit={handleSubmit}
                                                      className="add-accounts"
                                                    >
                                                      <div className="form-group">
                                                        <label>
                                                          Contract Shipment Record Type
                                                        </label>
                                                        <br />
                                                        <select
                                                          name="account_record"
                                                          className="form-control"
                                                          onChange={handleChange}
                                                          value={formData.account_record}
                                                        >
                                                          <option value="">--Select record type--</option>
                                                          <option value="Buyer - Long Term">
                                                            Buyer - Long Term
                                                          </option>
                                                          <option value="Buyer - Spot">
                                                            Buyer - Spot
                                                          </option>
                                                          <option value="Supplier - Long Term">
                                                            Supplier - Long Term
                                                          </option>
                                                          <option value="Supplier - Spot">
                                                            Supplier - Spot
                                                          </option>
                                                        </select>
                                                        {error.account_record && <div className="errors-msg">{error.account_record}</div>}
                                                      </div>
                                                      <input
                                                        type="submit"
                                                        className="btn btn-primary "
                                                        value="Submit"
                                                      />
                                                    </form>
                                                  </Popup>
                                                </span>
                                              </h4>

                                              <hr></hr>
                                            </div>
                                            <div id="inner-heading"></div>
                                            {conShipmentParents.length > 0 ? (
                                              <table id="contract-ship" className="">
                                                <thead>
                                                  <tr>
                                                    <th>Record Type</th>
                                                    <th>Contract Ship Code</th>
                                                    <th>Shipment Status</th>
                                                    <th>Parent Shipment</th>
                                                    <th>Pricing</th>
                                                    <th>Laycan</th>
                                                    <th>Quantity Info</th>
                                                    <th>No of Shipments</th>
                                                    <th>No of Orders</th>
                                                    <th>TCF Count</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {conShipmentParents.map((y, i) => (
                                                    <tr key={i}>
                                                      <td>{y.record_type}</td>
                                                      <td>
                                                        <Link to={`/shipment/contract-shipment-details/${y.id}`}>
                                                          <span id="codes">{y.contract_ship_code}</span>
                                                        </Link>
                                                      </td>
                                                      <td>{y.contract_shipment_status}</td>
                                                      <td>
                                                        {y.contract_ship_code === null ? (
                                                          <Link to={`/shipment/contract-shipment-details/${y.id}`}>
                                                            <span id="codes">{y.contract_ship_code}</span>
                                                          </Link>
                                                        ) : (
                                                          y.parent_shipment !== null && y.parentship_id !== null && (
                                                            <Link to={`/shipment/contract-shipment-details/${y.parentship_id}`}>
                                                              {y.parent_shipment}
                                                            </Link>
                                                          )
                                                        )}
                                                      </td>
                                                      <td>{y.pricing}</td>
                                                      <td>{y.laycan}</td>
                                                      <td>{y.quantity_info}</td>
                                                      <td>{y.no_of_shipments}</td>
                                                      <td>{y.no_of_orders}</td>
                                                      <td>{y.tcf_count}</td>
                                                    </tr>
                                                  ))}
                                                </tbody>
                                              </table>
                                            ) : (
                                              <p>No data available</p>
                                            )}
                                          </Row>
                                          <Row id="table-styles">
                                            <div className="col-md-12" id="head">
                                              <h4 className="heading">
                                                <span>Products </span>
                                              </h4>
                                              <hr></hr>
                                            </div>
                                            <table id="products" className="">
                                              <thead>
                                                <tr>
                                                  <th>Product Name	</th>
                                                  <th>Buyer Name	</th>
                                                  <th>Buyer Contract	</th>
                                                  <th>Quantity (MT)	</th>
                                                  <th>Laycan From Date	</th>
                                                  <th>Price</th>
                                                  <th>Product No
                                                  </th>
                                                </tr>
                                              </thead>

                                               <tbody>
                                                <tr>
                                                  <td></td>
                                                  <td></td>
                                                  <td></td>
                                                  <td>No data available</td>
                                                  <td></td>
                                                  <td></td>
                                                  <td></td>
                                                </tr>
                                               </tbody>
                                            </table>
                                          </Row>
                                          <Row id="table-styles">
                                            <div className="col-md-12" id="head">
                                              <h4 className="heading">
                                                <span>Trade Confirmation Forms</span>
                                                <span>
                                                  <Popup
                                                    trigger={
                                                      <a className="button add-accountrecord">
                                                        {" "}
                                                        New{" "}
                                                      </a>
                                                    }
                                                    modal
                                                  >
                                                    <form
                                                      onSubmit={handleSubmittcf}
                                                      className="add-accounts"
                                                    >
                                                      <div className="form-group">
                                                        <label>
                                                          TCF Record Type
                                                        </label>
                                                        <br />
                                                        <select
                                                          name="account_record"
                                                          className="form-control"
                                                          onChange={handleChangetcf}
                                                          value={
                                                            formData.account_record
                                                          }
                                                        >
                                                          <option>
                                                            --Select TCF Record
                                                            Type--
                                                          </option>
                                                          <option value="Buyer - Spot">
                                                            Buyer - Spot
                                                          </option>
                                                          <option value="Buyer - Long Term">
                                                            Buyer - Long Term
                                                          </option>
                                                        </select>
                                                      </div>
                                                      <input
                                                        type="submit"
                                                        className="btn btn-primary "
                                                        value="Submit"
                                                      />
                                                    </form>
                                                  </Popup>
                                                </span>
                                              </h4>
                                              <hr></hr>
                                            </div>
                                            <table id="tcf" className="">
                                              <thead>
                                                <tr>
                                                  <th>TCF No</th>
                                                  <th>TCF Status</th>
                                                  <th>BL Quantity</th>
                                                  <th>BL date</th>
                                                  <th>Laycan</th>
                                                  <th>contract shipment</th>
                                                  <th>product</th>
                                                  <th>Vessel Name </th>
                                                  <th>Proposed Laycan From </th>
                                                  <th>Proposed Laycan To</th>
                                                  <th>Ship Month</th>
                                                </tr>
                                              </thead>

                                              {tcf.length > 0 ? (
                                                <tbody>
                                                  {tcf.map((y, i) => (
                                                    <tr>
                                                      <td>
                                                        <span id="codes">
                                                          <Link
                                                            to={`/tcf/tcf-details/${y.id}`}
                                                          >
                                                            {y.tcf_no}
                                                          </Link>
                                                        </span>
                                                      </td>
                                                      <td>{y.tcf_status}</td>
                                                      <td>{y.actual_bl_qty}</td>
                                                      <td>{y.actual_bl_date}</td>
                                                      <td>{y.laycan}</td>
                                                      <td><Link to={`/shipment/contract-shipment-details/${y.contract_ship_id}`}>{y.contract_shipment}</Link></td>

                                                      <td><Link to={`/product/product-detail/${y.product_id}`}>{y.product}</Link></td>
                                                      <td>{y.vessel_name}</td>
                                                      <td>{y.proposed_laycan_from}</td>
                                                      <td>{y.proposed_laycan_to}</td>
                                                      <td>{y.ship_month
                                                      }</td>
                                                    </tr>
                                                  ))}
                                                </tbody>
                                              ) : (
                                                <tbody>
                                                  <tr>
                                                    <td></td><td></td>
                                                    <td></td>
                                                    <td></td> <td></td> <td></td>
                                                    <td>No data available</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td><td></td>
                                                  </tr>
                                                </tbody>
                                              )}
                                            </table>
                                          </Row>
                                          <Row id="table-styles">
     <div className="col-md-12" id="head">
       <h4 className="heading">
         <span>Contract History
         </span>
       </h4>
       <hr>
       </hr>
     </div>
     <table id="history" className="">
       <tr>
         <td>
           <p class="no-records"> No records to display </p>
         </td>
       </tr>
     </table>
   </Row>
   <Row id="table-styles">
       <div className="col-md-12" id="head">
         <h4 className="heading">
           <span>Open Activities</span>
           <span>
             <i className=""></i> New Task </span>
           <span>
             <i className=""></i> New Event </span>
         </h4>
         <hr>
         </hr>
       </div>
       <table id="history" className="">
         <tr>
           <td>
             <p class="no-records"> No records to display </p>
           </td>
         </tr>
       </table>
     </Row>
     <Row id="table-styles">
       <div className="col-md-12" id="head">
         <h4 className="heading">
           <span>Activity History</span>
           <span>
             <i className=""></i> Log a Call </span>
           <span>
             <i className=""></i> Mail merge </span>
         </h4>
         <hr>
         </hr>
       </div>
       <table id="history" className="">
         <tr>
           <td>
             <p class="no-records"> No records to display </p>
           </td>
         </tr>
       </table>
     </Row>
     <Row id="table-styles">
                                            <div
                                              className="col-md-12"
                                              id="head"
                                            >
                                              <h4 className="heading">
                                                <span>
                                                  Opportunity {" "}
                                                </span>{" "}
                                                {/* <Popup trigger={ <span className="button add-accountrecord"> New </span>} modal> <form onSubmit={handleSubmitOpp} className="add-accounts">
                      <div className='form-group'>
                        <label>Opportunity Record Type</label>
                        <br />
                        <select className='form-control' name="type_of_new_record" onChange={handleChangeOpp} value={formDataopp.account_record}>
                          <option>----Select----</option>
                          <option value='Buyer'>Buyer</option>
                          <option value='Buyer - Long Term'>Buyer - Long Term</option>
                          <option value='Buyer - Spot'>Buyer - Spot</option>
                          <option value='Supplier'>Supplier</option>
                          <option value='Supplier - Long Term'>Supplier - Long Term</option>
                          <option value='Supplier - Spot'>Supplier - Spot</option>
                        </select>
                      </div>
                      <input type='submit' className="btn btn-primary " value='Submit' />
                    </form>
                  </Popup> */}
                                              </h4>
                                              <hr></hr>
                                            </div>
                                            <table
                                              id="opportunity"
                                              className=""
                                            >
                                              <thead>
                                                <tr>
                                                  <th>Opportunity Name</th>

                                                  <th>Opportunity No</th>
                                                  <th>Stage</th>
                                                  <th>Quantity (MT)  </th>
                                                  <th>Supplier</th>
                                                  <th>No of Order</th>
                                                </tr>
                                              </thead>{" "}
                                              {opportunity.length > 0 ? (
                                                <tbody>
                                                  {" "}
                                                  {opportunity.map((y, i) => (
                                                    <tr>
                                                      <td>
                                                        <Link
                                                          to={`/opportunity/opportunity-detail/${y.id}`}
                                                        >
                                                          {y.opportunity_name}
                                                        </Link>
                                                      </td>
                                                      <td>
                                                        <Link
                                                          to={`/opportunity/opportunity-detail/${y.id}`}
                                                        >
                                                          {y.opportunity_no}
                                                        </Link>
                                                      </td>
                                                      <td>{y.stage}</td>
                                                      <td>{y.quantity_mt}</td>
                                                      <td><Link to={`/accounts/${y.supplier_id}`}>{y.supplier} </Link></td>
                                                      <td>{y.no_of_order}</td>
                                                    </tr>
                                                  ))}{" "}
                                                </tbody>
                                              ) : (
                                                <tbody>
                                                  <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td>No data available</td>
                                                    <td></td>
                                                    <td></td><td></td>
                                                  </tr>
                                                </tbody>
                                              )}
                                            </table>
                                          </Row>

                              <Row id="table-styles">
                                <div className="col-md-12" id="head">
                                  <h4 className="heading">
                                    <span>Quotes</span>
                                  </h4>
                                  <hr></hr>
                                </div>
                                <table id="quotes" className="">
                                  <thead>
                                    <tr>
                                      <th>Quote Number</th>
                                      <th>Quote Name</th>
                                      <th>Account Name</th>
                                      <th>Contact Name</th>
                                      <th>Expiration Date</th>
                                      <th>Product Description	</th>
                                      <th>Status</th>

                                    </tr></thead>
                                  {quoteTable.length > 0 ? (
                                    <tbody>
                                      {quoteTable.map((y, i) => (
                                        <tr>
                                          <td>
                                            <span id="codes">
                                              <Link
                                                to={`/opportunity/quote-view/${y.id}`}
                                              >
                                                {y.quote_number}
                                              </Link>
                                            </span>
                                          </td>
                                          <td><Link to={`/opportunity/quote-view/${y.id}`}>{y.quote_name}  </Link> </td>
                                          <td><Link to={`/accounts/${y.account_id}`}>{y.account_name} </Link></td>
                                          <td>{y.contact_name}</td>
                                          <td>{y.expiration_date}</td>
                                          <td>{y.product_description}</td>
                                          <td>{y.status}</td>

                                        </tr>
                                      ))}
                                    </tbody>
                                  ) : (
                                    <tbody>
                                      <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>No data available</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                      </tr>
                                    </tbody>
                                  )}
                                </table>
                              </Row>
                              <Row id="table-styles">
                                            <div className="col-md-12" id="head">
                                              <h4 className="heading">
                                                <span>Orders </span>
                                              </h4>
                                              <hr></hr>
                                            </div>
                                            <table id="orders" className="">
                                              <thead>
                                                <tr>
                                                  <th>Order Number</th>
                                                  <th>   Order Start Date</th>
                                                  <th>Contract Shipment</th>
                                                  <th>Vessel Barge Name	</th>
                                                  <th>Ship Month  </th>
                                                  <th>Laycan From Date</th>
                                                  <th>Status</th>
                                                  <th>Shipment Qty</th>
                                                  <th>Account Name</th>
                                                </tr>
                                              </thead>

                                              {orderData.length > 0 ? (
                                                <tbody>
                                                  {orderData.map((y, i) => (
                                                    <tr>
                                                      <td> <Link
                                                        to={`/order/view-order-details/${y.id}`}
                                                      >{y.order_no}</Link></td>
                                                      <td>{y.order_start_date}</td>
                                                      <td><Link to={`/shipment/contract-shipment-details/${y.contract_ship_id}`}>{y.contract_shipment}</Link></td>
                                                      <td>{y.vessel_barge_name}</td>
                                                      <td>{y.ship_month}</td>
                                                      <td>{y.laycan_from_date}</td>
                                                      <td>{y.status}</td>
                                                      <td>{y.shipment_qty}</td>
                                                      <td><Link to={`/accounts/${y.account_id}`}>{y.account_name} </Link></td>
                                                    </tr>
                                                  ))}
                                                </tbody>
                                              ) : (
                                                <tbody>
                                                  <tr><td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>No data available</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td><td></td>
                                                  </tr>
                                                </tbody>
                                              )}
                                            </table>
                                          </Row>
                                          <Row id="table-styles">
       <div className="col-md-12" id="head">
         <h4 className="heading">
           <span>Notes & Attachments</span>
           <span>
             <i className=""></i> New Note </span>
           <span>
             <i className=""></i> Attach File </span>
         </h4>
         <hr>
         </hr>
       </div>
       <table id="history" className="">
         <tr>
           <td>
             <p class="no-records"> No records to display </p>
           </td>
         </tr>
       </table>
     </Row>
     <Row id="table-styles">
                                    <div className="col-md-12" id="head">
                                      <h4 className="heading">
                                        <span>Product Masters
                                        </span>
                                        <span>
                                          <i className=""></i>
                                          New Product Masters

                                        </span>

                                      </h4>
                                      <hr></hr>
                                    </div>
                                    <table id="history" className="">
                                      <tr>
                                        <td>
                                          <p class="no-records">No records to display</p>
                                        </td>
                                      </tr>
                                    </table>
                                  </Row>
                                  <Row id="table-styles">
     <div className="col-md-12" id="head">
       <h4 className="heading">
         <span>Notes </span>
         <span>
           <i className=""></i> New Note </span>
       </h4>
       <hr>
       </hr>
     </div>
     <table id="history" className="">
       <tr>
         <td>
           <p class="no-records"> No records to display </p>
         </td>
       </tr>
     </table>
   </Row>
   <Row id="table-styles">
     <div className="col-md-12" id="head">
       <h4 className="heading">
         <span>Files </span>
         <span>
           <i className=""></i> Upload File </span>
       </h4>
       <hr>
       </hr>
     </div>
     <table id="history" className="">
       <tr>
         <td>
           <p class="no-records"> No records to display </p>
         </td>
       </tr>
     </table>
   </Row>


</div>
                                      </div>
                                    )}
                                  </>
                                ) : (
                                  <p></p>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            ) : (
              <div className="tasks">
                <h1 className="no-data-detail"><img src='../../images/loading.gif' className="loading" /></h1>
              </div>
            )}
          </div>
        </div>
      </div>
      <p></p><p></p>
      <Footer />
    </div>
  );
};

export default ViewContracts;
