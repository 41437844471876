import React, { Component, useState, useEffect, useContext } from "react";
import { Card, OverlayTrigger, Tooltip, Table } from "react-bootstrap";
import "datatables.net-dt/js/dataTables.dataTables.js";
import { AES, enc } from "crypto-js";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import "datatables.net-responsive-dt";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import { API_URL } from '../config';
import "react-data-table-component-extensions/dist/index.css";
import { Link, useNavigate } from "react-router-dom";
import { TokenContext } from "../AuthProvider";
import Header from "../Header";
import Footer from "../Footer";
import $ from 'jquery';
import RecentItems from "../recentItems/RecentItems";
import Popup from "reactjs-popup";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  FaEnvelope,
  FaPhoneSquareAlt,
  FaMobileAlt,
  FaFax,
} from "react-icons/fa";
import axios from "axios";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { FaPencilAlt, FaTrashAlt, FaPlus } from "react-icons/fa";
// import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
import { useParams } from "react-router-dom";
const BuyerView = () => {
  const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
  const encryptedAccessToken = localStorage.getItem("access_token");
  const decryptedAccessToken = encryptedAccessToken
    ? AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8)
    : null;
  const navigate = useNavigate();
  const params = useParams();
  const id = params.id;
  const userRole = localStorage.getItem("role");
  console.log(userRole);
  const { username, token } = useContext(TokenContext);
  const [datas, setDatas] = useState([]);
  const [opp, setOpp] = useState([]);
  const [data, setData] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [editedData, setEditedData] = useState({});
  const [shippment, setShippment]=useState([]);
  const [allorder, setAllorder]=useState([]);


  useEffect(() => {

    fetchDataOrder();
  }, [decryptedAccessToken]);

  const fetchDataOrder = async () => {
    try {
      const response = await axios.get(`${API_URL}get/allorders`, {
        headers: { Authorization: `Bearer ${decryptedAccessToken}`, 'Content-Type': 'application/json'  },        }
      );
      const data = response.data.orders;
      console.log(data)
      setAllorder(data);
      $(document).ready(function(){
        // $('#tables').DataTable().destroy();
        // setTimeout(()=>{
          $('#tables').DataTable();
        // },500)
       })
    } catch (error) {
      console.error(error);
    }
  };



 useEffect(() => {

  fetchDataShipment();
}, [decryptedAccessToken]);

const fetchDataShipment = async () => {
  try {
    const response = await axios.get(`${API_URL}get/products_api`, {
      headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
    );
    const data = response.data;

    setShippment(data)
    console.log(JSON.stringify(data))

  } catch (error) {
    console.error(error);
  }
};

const [serveyour, setServeyor]=useState([]);

useEffect(() => {

  fetchDataServeyour();
}, [decryptedAccessToken]);

const fetchDataServeyour = async () => {
  try {
    const response = await axios.get(`${API_URL}get/surveyor`, {
      headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
    );
    const data = response.data;

    setServeyor(data)
    console.log(data)

  } catch (error) {
    console.error(error);
  }
};


  useEffect(() => {
    fetchData();
  }, [decryptedAccessToken]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${API_URL}get/buyer_productbyID/${id}`,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data
      console.log(data);
       setDatas(data)
    } catch (error) {
      console.error(error);
    }
  };
  //  edit
  const handleEditClick = () => {
    setIsEditMode(true);
    setEditedData(data);
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setDatas((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCancelEdit = () => {
    setIsEditMode(false);
    setEditedData(data);
  };

  const handleSaveEdit = (e) => {
    e.preventDefault();
    const responseData={
      "      product":datas.product,
      "product_master":datas.product_master,
      "buyer_product_type":datas.buyer_product_type,
      "surveyor_name":datas.surveyor_name,
      "order":datas.order,
      "gross_as_recieved":datas.gross_as_recieved,
      "gross_air_dried":datas.gross_air_dried,
      "net_as_recieved":datas.net_as_recieved,
      "total_moisture":datas.total_moisture,
      "ash_ARB":datas.ash_ARB,
      "sulphur_ARB":datas.sulphur_ARB,
      "volatile_matter_ARB":datas.volatile_matter_ARB,
      "moisture_in_air_dried_basis":datas.moisture_in_air_dried_basis,
      "ash_in_air_dried_basis":datas.ash_in_air_dried_basis,
      "volatile_mater_in_air_dried_basis":datas.volatile_mater_in_air_dried_basis,
      "fixed_carbon_by_difference_in_air_dried_basis":datas.fixed_carbon_by_difference_in_air_dried_basis,
      "fixed_carbon_in_air_dried_basis":datas.fixed_carbon_in_air_dried_basis,
      "hgi":datas.hgi,
      "carbon_dry_ash_free_basis":datas.carbon_dry_ash_free_basis,
      "hydrogen_dry_ash_free_basis":datas.hydrogen_dry_ash_free_basis,
      "nitrogen_dry_ash_free_basis":datas.nitrogen_dry_ash_free_basis,
      "sulphur_dry_ash_free_basis":datas.sulphur_dry_ash_free_basis,
      "oxygen_dry_ash_free_basis":datas.oxygen_dry_ash_free_basis,
      "sulphur_ADB":datas.sulphur_ADB,
      "initial_deformation_deg_c_reducing":datas.initial_deformation_deg_c_reducing,
      "spherical_deg_c_reducing":datas.spherical_deg_c_reducing,
      "hemispherical_deg_c_reducing":datas.hemispherical_deg_c_reducing,
      "flow_deg_c_reducing":datas.flow_deg_c_reducing,
      "oxidizing_hemispherical":datas.oxidizing_hemispherical,
      "oxidizing_spherical":datas.oxidizing_spherical,
      "oxidizing_flow":datas.oxidizing_flow,
      "SiO2_dry_basis_in_ash":datas.SiO2_dry_basis_in_ash,
      "Mn3O4_typical_dry_basis_in_ash":datas.Mn3O4_typical_dry_basis_in_ash,
      "AI2O3_dry_basis_in_ash ":datas.AI2O3_dry_basis_in_ash,
      "Fe2O3_dry_basis_in_ash":datas.Fe2O3_dry_basis_in_ash,
      "CaO_dry_basis_in_ash":datas.CaO_dry_basis_in_ash,
      "MgO_dry_basis_in_ash":datas.MgO_dry_basis_in_ash,
      "TiO2_dry_basis_in_ash":datas.TiO2_dry_basis_in_ash,
      "NA2O_dry_basis_in_ash":datas.NA2O_dry_basis_in_ash,
      "K2O_dry_basis_in_ash":datas.K2O_dry_basis_in_ash,
      "P2O5_dry_basis_in_ash":datas.P2O5_dry_basis_in_ash,
      "SO3_dry_basis_in_ash":datas.SO3_dry_basis_in_ash,

      "TE_arsenic_As_air_dried_coal":datas.TE_arsenic_As_air_dried_coal,
      "TE_antimony_Sb_air_dried_coal":datas.TE_antimony_Sb_air_dried_coal,
      "TE_beryllium_Be_air_dried_coal":datas.TE_beryllium_Be_air_dried_coal,
      "TE_boron_B_air_dried_coal":datas.TE_boron_B_air_dried_coal,
      "TE_cadmium_Cd_air_dried_coal":datas.TE_cadmium_Cd_air_dried_coal,
      "TE_chromium_Cr_air_dried_coal":datas.TE_chromium_Cr_air_dried_coal,
      "TE_copper_Fe_air_dried_coal":datas.TE_copper_Fe_air_dried_coal,
      "TE_fluorine_F_air_dried_coal":datas.TE_fluorine_F_air_dried_coal,
      "TE_mercury_Hg_air_dried_coal":datas.TE_mercury_Hg_air_dried_coal,
      "TE_molybdenum_Mo_air_dried_coal":datas.TE_molybdenum_Mo_air_dried_coal,
      "TE_lead_Pb_air_dried_coal":datas.TE_lead_Pb_air_dried_coal,
      "TE_phosporous_P_air_dried_coal":datas.TE_phosporous_P_air_dried_coal,
      "TE_selenium_Se_air_dried_coal":datas.TE_selenium_Se_air_dried_coal,
      "TE_vanadium_V_air_dried_coal":datas.TE_vanadium_V_air_dried_coal,
      "TE_Zinc_Zn_air_dried_coal":datas.TE_Zinc_Zn_air_dried_coal,
      "TE_arsenic_As_dried_basis":datas.TE_arsenic_As_dried_basis,
      "TE_antimony_Sb_dried_basis":datas.TE_antimony_Sb_dried_basis,
      "TE_beryllium_Be_dried_basis":datas.TE_beryllium_Be_dried_basis,
      "TE_boron_B_dried_basis":datas.TE_boron_B_dried_basis,
      "TE_cadmium_Cd_dried_basis":datas.TE_cadmium_Cd_dried_basis,
      "TE_chromium_Cr_dried_basis":datas.TE_chromium_Cr_dried_basis,
      "TE_copper_Fe_dried_basis":datas.TE_copper_Fe_dried_basis,
      "TE_fluorine_F_dried_basis":datas.TE_fluorine_F_dried_basis,
      "TE_mercury_Hg_dried_basis":datas.TE_mercury_Hg_dried_basis,
      "TE_molybdenum_Mo_dried_basis":datas.TE_molybdenum_Mo_dried_basis,
      "TE_lead_Pb_dried_basis":datas.TE_lead_Pb_dried_basis,
      "TE_phosporous_P_dried_basis":datas.TE_phosporous_P_dried_basis,
      "TE_selenium_Se_dried_basis":datas.TE_selenium_Se_dried_basis,
      "TE_vanadium_V_dried_basis":datas.TE_vanadium_V_dried_basis,
      "TE_Zinc_Zn_dried_basis":datas.TE_Zinc_Zn_dried_basis,
      "size_0_to_50_mm":datas.size_0_to_50_mm,
      "above_50_mm":datas.above_50_mm,
      "under_2_mm":datas.under_2_mm,
      "size_above_2mm_typical":datas.size_above_2mm_typical,
      "size_above_2mm_rejection":datas.size_above_2mm_rejection,
      "testing_method":datas.testing_method
      }
      console.log(JSON.stringify(responseData))
    fetch(`${API_URL}update/buyer_product/${id}`, {
      method: 'PUT',
      body: JSON.stringify(responseData),
      headers: { Authorization: `Bearer ${decryptedAccessToken}`,
              'Content-Type': 'application/json' },

    })
      .then((response) => response.json())
      .then((updatedData) => {
        console.log(updatedData)
         fetchData();
        setData(updatedData);
        setIsEditMode(false);
      })
      .catch((error) => console.error(error));
  };

  return (
    <div id="view-page" className="buyerView">
      <Header />
      <div className="row">
      <div className="col-md-2">
            <RecentItems/>
          </div>
          <div className="col-md-10">
      <div className="contactAccount">
        <div className="page-header btn-margin" id="add-account">
          <div>
            <h2 className="main-content-title tx-24 mg-b-5">
              Buyer Product Details
            </h2>
            <ol className="breadcrumb">
              <li className="breadcrumb-item active" aria-current="page">
                Dashboard / Buyer / Buyer details
              </li>
            </ol>
          </div>
        </div>
        {isEditMode ? (
          <div className="tasks">
                <p className="edit-btn">
    <button className="btn btn-primary" onClick={handleSaveEdit}>
      Save
    </button>
    <button className="btn btn-primary" onClick={handleCancelEdit}>
      Cancel
    </button>
  </p>
        <Row className="mb-3">
        <h4 className='heading'>Information</h4>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Product"
            className="mb-3"


          >
            <Form.Control defaultValue={datas.product || ""}
 onChange={handleChangeInput}
              type="text"
              placeholder="Product"
              name="product"
              />
          </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Product Master">
    <Form.Select aria-label="Floating label select example"

    name="product_master"
   defaultValue={datas.product_master || ""}
 onChange={handleChangeInput}
    >
      <option value='' >Select</option>


{shippment.length>0?(
                 <>

                          {
          shippment.map((x)=>{
              return(
                <option key={x.id} value={x.product_name}>
                {x.product_name}
              </option>
              )
          })
       }



                </>
                ):(

                 <option className='no-data'>No data  available</option>
                 )}

    </Form.Select>
  </FloatingLabel>
              </Form.Group>

          <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Buyer Product Type">
    <Form.Select aria-label="Floating label select example"

    name="buyer_product_type"
  defaultValue={datas.buyer_product_type || ""}
 onChange={handleChangeInput}
    >
       <option value="">--None--</option><option value="Load Port - Buyer">Load Port - Buyer</option><option value="Load Port - Supplier">Load Port - Supplier</option><option value="Discharge Port - Buyer">Discharge Port - Buyer</option><option value="Umpire Surveyor Report">Umpire Surveyor Report</option>
    </Form.Select>
  </FloatingLabel>
              </Form.Group>
          <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Surveyor Name">
    <Form.Select aria-label="Floating label select example"

    name="surveyor_name"
     defaultValue={datas.surveyor_name || ""}
 onChange={handleChangeInput}
    >
      <option value='' >Select</option>



{serveyour.length>0?(
                 <>

                          {
          serveyour.map((x)=>{
              return(
                <option key={x.id} value={x.account_name}>
                {x.account_name}
              </option>
              )
          })
       }



                </>
                ):(

                 <option className='no-data'>No data  available</option>
                 )}
    </Form.Select>
  </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Order">
    <Form.Select aria-label="Floating label select example"

    name="order"
     defaultValue={datas.order || ""}
 onChange={handleChangeInput}
    >
      <option value='' >Select</option>

{allorder.length>0?(
                 <>

                          {
          allorder.map((x, i)=>{
              return(
                <option key={i} value={x.order_code_no}>
                {x.order_code_no}
              </option>
              )
          })
       }





                </>
                ):(

                 <option className='no-data'>No data  available</option>
                 )}
    </Form.Select>
  </FloatingLabel>
              </Form.Group>
        {/* <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Type">
    <Form.Select aria-label="Floating label select example"

    name="type"
     defaultValue={datas.type || ""}
 onChange={handleChangeInput}
    >
      <option value="">--None--</option><option value="Load Port - Buyer">Load Port - Buyer</option><option value="Load Port - Supplier">Load Port - Supplier</option><option value="Discharge Port - Buyer">Discharge Port - Buyer</option><option value="Umpire Surveyor Report">Umpire Surveyor Report</option>
    </Form.Select>
  </FloatingLabel>
              </Form.Group>  */}
      </Row>
      <Row className="mb-3">
        <h4 className='heading'>CALORIFIC VALUE, kcal / kg</h4>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Gross As Recieved"
            className="mb-3"


          >
            <Form.Control defaultValue={datas.gross_as_recieved || ""}
 onChange={handleChangeInput}
              type="text"
              placeholder="Gross As Recieved"
              name="gross_as_recieved"
              />
          </FloatingLabel>
          </Form.Group>
         <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Gross Air Dried"
            className="mb-3"


          >
            <Form.Control defaultValue={datas.gross_air_dried || ""}
 onChange={handleChangeInput}
              type="text"
              placeholder="Gross Air Dried"
              name="gross_air_dried"
              />
          </FloatingLabel>
          </Form.Group>
         <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Net As Recieved"
            className="mb-3"


          >
            <Form.Control defaultValue={datas.net_as_recieved || ""}
 onChange={handleChangeInput}
              type="text"
              placeholder="Net As Recieved"
              name="net_as_recieved"
              />
          </FloatingLabel>
          </Form.Group>

      </Row>

    <Row className="mb-3">
        <h4 className='heading'>%As Received Basis</h4>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Total Moisture"
            className="mb-3"


          >
            <Form.Control defaultValue={datas.total_moisture || ""}
 onChange={handleChangeInput}
              type="text"
              placeholder="Total Moisture"
              name="total_moisture"
              />
          </FloatingLabel>
          </Form.Group>
         <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Ash ARB"
            className="mb-3"


          >
            <Form.Control defaultValue={datas.ash_ARB || ""}
 onChange={handleChangeInput}
              type="text"
              placeholder="Ash ARB"
              name="ash_ARB"
              />
          </FloatingLabel>
          </Form.Group>
         <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Sulphur ARB"
            className="mb-3"


          >
            <Form.Control defaultValue={datas.sulphur_ARB || ""}
 onChange={handleChangeInput}
              type="text"
              placeholder="Sulphur ARB"
              name="sulphur_ARB"
            />
          </FloatingLabel>
          </Form.Group>
         <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Volatile Matter ARB"
            className="mb-3"


          >
            <Form.Control defaultValue={datas.volatile_matter_ARB || ""}
 onChange={handleChangeInput}
              type="text"
              placeholder="Volatile Matter ARB"
              name="volatile_matter_ARB"
              />
          </FloatingLabel>
          </Form.Group>
      </Row>
    <Row className="mb-3">
        <h4 className='heading'>HGI</h4>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="HGI"
            className="mb-3"


          >
            <Form.Control defaultValue={datas.hgi || ""}
 onChange={handleChangeInput}
              type="text"
              placeholder="HGI"
              name="hgi"
              />
          </FloatingLabel>
          </Form.Group>
      </Row>
    <Row className="mb-3">
        <h4 className='heading'>ULTIMATE ANALYSIS, %dry ash free basis</h4>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Carbon"
            className="mb-3"


          >
            <Form.Control defaultValue={datas.carbon_dry_ash_free_basis || ""}
 onChange={handleChangeInput}
              type="text"
              placeholder="Carbon"
              name="carbon_dry_ash_free_basis"
              />
          </FloatingLabel>
          </Form.Group>
         <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Hydrogen"
            className="mb-3"


          >
            <Form.Control defaultValue={datas.hydrogen_dry_ash_free_basis || ""}
 onChange={handleChangeInput}
              type="text"
              placeholder="Hydrogen"
              name="hydrogen_dry_ash_free_basis"
              />
          </FloatingLabel>
          </Form.Group>
         <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Nitrogen"
            className="mb-3"


          >
            <Form.Control defaultValue={datas.nitrogen_dry_ash_free_basis || ""}
 onChange={handleChangeInput}
              type="text"
              placeholder="Nitrogen"
              name="nitrogen_dry_ash_free_basis"
              />
          </FloatingLabel>
          </Form.Group>
         <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Sulphur"
            className="mb-3"


          >
            <Form.Control defaultValue={datas.sulphur_dry_ash_free_basis || ""}
 onChange={handleChangeInput}
              type="text"
              placeholder="Sulphur"
              name="sulphur_dry_ash_free_basis"
              />
          </FloatingLabel>
          </Form.Group>

         <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Oxygen"
            className="mb-3"


          >
            <Form.Control defaultValue={datas.oxygen_dry_ash_free_basis || ""}
 onChange={handleChangeInput}
              type="text"
              placeholder="Oxygen"
              name="oxygen_dry_ash_free_basis"
              />
          </FloatingLabel>
          </Form.Group>
      </Row>
    <Row className="mb-3">
        <h4 className='heading'>SULPHUR, %air dried basis</h4>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Sulphur ADB"
            className="mb-3"


          >
            <Form.Control defaultValue={datas.sulphur_ADB || ""}
 onChange={handleChangeInput}
              type="text"
              placeholder="Sulphur ADB"
              name="sulphur_ADB"
              />
          </FloatingLabel>
          </Form.Group>
      </Row>
      <Row className="mb-3">
        <h4 className='heading'>ASH FUSION TEMPERATURE, Deg (C) Reducing</h4>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Initial Deformation"
            className="mb-3"


          >
            <Form.Control defaultValue={datas.initial_deformation_deg_c_reducing || ""}
 onChange={handleChangeInput}
              type="text"
              placeholder="Initial Deformation"
              name="initial_deformation_deg_c_reducing"
              />
          </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Spherical"
            className="mb-3"


          >
            <Form.Control defaultValue={datas.spherical_deg_c_reducing || ""}
 onChange={handleChangeInput}
              type="text"
              placeholder="Spherical"
              name="spherical_deg_c_reducing"
             />
          </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Hemispherical"
            className="mb-3"


          >
            <Form.Control defaultValue={datas.hemispherical_deg_c_reducing || ""}
 onChange={handleChangeInput}
              type="text"
              placeholder="Hemispherical"
              name="hemispherical_deg_c_reducing"
              />
          </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Flow"
            className="mb-3"


          >
            <Form.Control defaultValue={datas.flow_deg_c_reducing || ""}
 onChange={handleChangeInput}
              type="text"
              placeholder="Flow"
              name="flow_deg_c_reducing"
            />
          </FloatingLabel>
          </Form.Group>
      </Row>

      <Row className="mb-3">
        <h4 className='heading'>ASH FUSION TEMPERATURE, Deg C Oxidizing</h4>
           <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Oxidizing Hemispherical"
            className="mb-3"


          >
            <Form.Control defaultValue={datas.oxidizing_hemispherical || ""}
 onChange={handleChangeInput}
              type="text"
              placeholder="Oxidizing Hemispherical"
              name="oxidizing_hemispherical"
             />
          </FloatingLabel>
          </Form.Group><Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Oxidizing Spherical"
            className="mb-3"


          >
            <Form.Control defaultValue={datas.oxidizing_spherical || ""}
 onChange={handleChangeInput}
              type="text"
              placeholder="Oxidizing Spherical"
              name="oxidizing_spherical"
              />
          </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Oxidizing Flow"
            className="mb-3"


          >
            <Form.Control defaultValue={datas.oxidizing_flow || ""}
 onChange={handleChangeInput}
              type="text"
              placeholder="Oxidizing Flow"
              name="oxidizing_flow"
             />
          </FloatingLabel>
          </Form.Group>
      </Row>
      <Row className="mb-3">
        <h4 className='heading'>ASH ANALYSIS, %dry basis in ash</h4>
           <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="SiO2"
            className="mb-3"


          >
            <Form.Control defaultValue={datas.SiO2_dry_basis_in_ash || ""}
 onChange={handleChangeInput}
              type="text"
              placeholder="SiO2"
              name="SiO2_dry_basis_in_ash"
              />
          </FloatingLabel>
          </Form.Group><Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Mn3O4"
            className="mb-3"


          >
            <Form.Control defaultValue={datas.Mn3O4_typical_dry_basis_in_ash || ""}
 onChange={handleChangeInput}
              type="text"
              placeholder="Mn3O4"
              name="Mn3O4_typical_dry_basis_in_ash"
              />
          </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="AI2O3"
            className="mb-3"


          >
            <Form.Control defaultValue={datas.AI2O3_dry_basis_in_ash || ""}
 onChange={handleChangeInput}
              type="text"
              placeholder="AI2O3"
              name="AI2O3_dry_basis_in_ash"
              />
          </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Fe2O3"
            className="mb-3"


          >
            <Form.Control defaultValue={datas.Fe2O3_dry_basis_in_ash || ""}
 onChange={handleChangeInput}
              type="text"
              placeholder="Fe2O3"
              name="Fe2O3_dry_basis_in_ash"
              />
          </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="CaO"
            className="mb-3"


          >
            <Form.Control defaultValue={datas.CaO_dry_basis_in_ash || ""}
 onChange={handleChangeInput}
              type="text"
              placeholder="CaO"
              name="CaO_dry_basis_in_ash"
              />
          </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="MgO"
            className="mb-3"


          >
            <Form.Control defaultValue={datas.MgO_dry_basis_in_ash || ""}
 onChange={handleChangeInput}
              type="text"
              placeholder="MgO"
              name="MgO_dry_basis_in_ash"
              />
          </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="TiO2"
            className="mb-3"


          >
            <Form.Control defaultValue={datas.TiO2_dry_basis_in_ash || ""}
 onChange={handleChangeInput}
              type="text"
              placeholder="TiO2"
              name="TiO2_dry_basis_in_ash"
              />
          </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="NA2O"
            className="mb-3"


          >
            <Form.Control defaultValue={datas.NA2O_dry_basis_in_ash || ""}
 onChange={handleChangeInput}
              type="text"
              placeholder="NA2O"
              name="NA2O_dry_basis_in_ash"
              />
          </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="K2O"
            className="mb-3"


          >
            <Form.Control defaultValue={datas.K2O_dry_basis_in_ash || ""}
 onChange={handleChangeInput}
              type="text"
              placeholder="K2O"
              name="K2O_dry_basis_in_ash"
              />
          </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="P2O5"
            className="mb-3"


          >
            <Form.Control defaultValue={datas.P2O5_dry_basis_in_ash || ""}
 onChange={handleChangeInput}
              type="text"
              placeholder="P2O5"
              name="P2O5_dry_basis_in_ash"
              />
          </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="SO3"
            className="mb-3"


          >
            <Form.Control defaultValue={datas.SO3_dry_basis_in_ash || ""}
 onChange={handleChangeInput}
              type="text"
              placeholder="SO3"
              name="SO3_dry_basis_in_ash"
             />
          </FloatingLabel>
          </Form.Group>
      </Row>
      <Row className="mb-3">
        <h4 className='heading'>TRACE ELEMENTS, ppm Air Dried in Coal</h4>
           <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="TE Arsenic As"
            className="mb-3"


          >
            <Form.Control defaultValue={datas.TE_arsenic_As_air_dried_coal || ""}
 onChange={handleChangeInput}
              type="text"
              placeholder="TE Arsenic As"
              name="TE_arsenic_As_air_dried_coal"
              />
          </FloatingLabel>
          </Form.Group><Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="TE Antimony Sb"
            className="mb-3"


          >
            <Form.Control defaultValue={datas.TE_antimony_Sb_air_dried_coal || ""}
 onChange={handleChangeInput}
              type="text"
              placeholder="TE Antimony Sb"
              name="TE_antimony_Sb_air_dried_coal"
             />
          </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="TE Beryllium Be"
            className="mb-3"


          >
            <Form.Control defaultValue={datas.TE_beryllium_Be_air_dried_coal || ""}
 onChange={handleChangeInput}
              type="text"
              placeholder="TE Beryllium Be"
              name="TE_beryllium_Be_air_dried_coal"
              />
          </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="TE Boron B"
            className="mb-3"


          >
            <Form.Control defaultValue={datas.TE_boron_B_air_dried_coal || ""}
 onChange={handleChangeInput}
              type="text"
              placeholder="TE Boron B"
              name="TE_boron_B_air_dried_coal"
              />
          </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="TE Cadmium Cd"
            className="mb-3"


          >
            <Form.Control defaultValue={datas.TE_cadmium_Cd_air_dried_coal || ""}
 onChange={handleChangeInput}
              type="text"
              placeholder="TE Cadmium Cd"
              name="TE_cadmium_Cd_air_dried_coal"
              />
          </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="TE chromium Cr"
            className="mb-3"


          >
            <Form.Control defaultValue={datas.TE_chromium_Cr_air_dried_coal || ""}
 onChange={handleChangeInput}
              type="text"
              placeholder="TE chromium Cr"
              name="TE_chromium_Cr_air_dried_coal"
             />
          </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="TE Copper Fe"
            className="mb-3"


          >
            <Form.Control defaultValue={datas.TE_copper_Fe_air_dried_coal || ""}
 onChange={handleChangeInput}
              type="text"
              placeholder="TE Copper Fe"
              name="TE_copper_Fe_air_dried_coal"
              />
          </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="TE Fluorine F"
            className="mb-3"


          >
            <Form.Control defaultValue={datas.TE_fluorine_F_air_dried_coal || ""}
 onChange={handleChangeInput}
              type="text"
              placeholder="TE Fluorine F"
              name="TE_fluorine_F_air_dried_coal"
             />
          </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="TE Mercury Hg"
            className="mb-3"


          >
            <Form.Control defaultValue={datas.TE_mercury_Hg_air_dried_coal || ""}
 onChange={handleChangeInput}
              type="text"
              placeholder="TE Mercury Hg"
              name="TE_mercury_Hg_air_dried_coal"
              />
          </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="TE Molybdenum Mo"
            className="mb-3"


          >
            <Form.Control defaultValue={datas.TE_molybdenum_Mo_air_dried_coal || ""}
 onChange={handleChangeInput}
              type="text"
              placeholder="TE Molybdenum Mo"
              name="TE_molybdenum_Mo_air_dried_coal"
              />
          </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="TE Lead Pb"
            className="mb-3"


          >
            <Form.Control defaultValue={datas.TE_lead_Pb_air_dried_coal || ""}
 onChange={handleChangeInput}
              type="text"
              placeholder="TE Lead Pb"
              name="TE_lead_Pb_air_dried_coal"
              />
          </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="TE Phosporous P"
            className="mb-3"


          >
            <Form.Control defaultValue={datas.TE_phosporous_P_air_dried_coal || ""}
 onChange={handleChangeInput}
              type="text"
              placeholder="TE Phosporous P"
              name="TE_phosporous_P_air_dried_coal"
              />
          </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="TE Selenium Se"
            className="mb-3"


          >
            <Form.Control defaultValue={datas.TE_selenium_Se_air_dried_coal || ""}
 onChange={handleChangeInput}
              type="text"
              placeholder="TE Selenium Se"
              name="TE_selenium_Se_air_dried_coal"
              />
          </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="TE Vanadium V"
            className="mb-3"


          >
            <Form.Control defaultValue={datas.TE_vanadium_V_air_dried_coal || ""}
 onChange={handleChangeInput}
              type="text"
              placeholder="TE Vanadium V"
              name="TE_vanadium_V_air_dried_coal"
             />
          </FloatingLabel>
          </Form.Group>
          <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="TE Zinc Zn"
            className="mb-3"


          >
            <Form.Control defaultValue={datas.TE_Zinc_Zn_air_dried_coal || ""}
 onChange={handleChangeInput}
              type="text"
              placeholder="TE Zinc Zn"
              name="TE_Zinc_Zn_air_dried_coal"
             />
          </FloatingLabel>
          </Form.Group>
      </Row>
      <Row className="mb-3">
        <h4 className='heading'>Sizing, %</h4>
           <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="size 0 To 50 mm"
            className="mb-3"


          >
            <Form.Control defaultValue={datas.size_0_to_50_mm || ""}
 onChange={handleChangeInput}
              type="text"
              placeholder="size 0 To 50 mm"
              name="size_0_to_50_mm"
              />
          </FloatingLabel>
          </Form.Group><Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Above 50 mm"
            className="mb-3"


          >
            <Form.Control defaultValue={datas.above_50_mm || ""}
 onChange={handleChangeInput}
              type="text"
              placeholder="Above 50 mm"
              name="above_50_mm"
              />
          </FloatingLabel>
          </Form.Group><Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Under 2 mm"
            className="mb-3"


          >
            <Form.Control defaultValue={datas.under_2_mm || ""}
 onChange={handleChangeInput}
              type="text"
              placeholder="Under 2 mm"
              name="under_2_mm"
              />
          </FloatingLabel>
          </Form.Group><Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Size Above 2mm Typical"
            className="mb-3"


          >
            <Form.Control defaultValue={datas.size_above_2mm_typical || ""}
 onChange={handleChangeInput}
              type="text"
              placeholder="Size Above 2mm Typical"
              name="size_above_2mm_typical"
              />
          </FloatingLabel>
          </Form.Group><Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Size Above 2mm Rejection"
            className="mb-3"


          >
            <Form.Control defaultValue={datas.size_above_2mm_rejection || ""}
 onChange={handleChangeInput}
              type="text"
              placeholder="Size Above 2mm Rejection"
              name="size_above_2mm_rejection"
             />
          </FloatingLabel>
          </Form.Group>
          </Row>
          <Row className="mb-3">
                <h4 className='heading'>Testing Method</h4>
                <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Testing Method">
    <Form.Select aria-label="Floating label select example"
    name="testing_method"
    placeholder="Testing method"
    defaultValue={datas.testing_method || ""}
 onChange={handleChangeInput}
    >

<option value='' >Select </option>
<option value='According to ASTM Standard Method Unless Otherwise Stated' >According to ASTM Standard Method Unless Otherwise Stated</option>
<option value='According to ISO Methods'>According to ISO Methods</option>
<option value='According to ISO Methods Except HGI, Trace Elements and Ash Analysis to ASTM'>According to ISO Methods Except HGI, Trace Elements and Ash Analysis to ASTM</option>
<option value='According to ISO Methods Except HGI to ASTM'>According to ISO Methods Except HGI to ASTM</option>
<option value='According to ISO Methods, Except HGI and Ash Analysis to ASTM'>According to ISO Methods, Except HGI and Ash Analysis to ASTM</option>
<option value='According to ISO Methods, Except HGI as per ASTM Test Method'>According to ISO Methods, Except HGI as per ASTM Test Method</option>
    </Form.Select>

  </FloatingLabel>
              </Form.Group>
              </Row>
              <p className="edit-btn">
    <button className="btn btn-primary" onClick={handleSaveEdit}>
      Save
    </button>
    <button className="btn btn-primary" onClick={handleCancelEdit}>
      Cancel
    </button>
  </p>
            </div>
        ):(
        <div className="tasks">
           <p className="edit-btn">
           <div className="edit-delete">
                  <span id="edit" onClick={handleEditClick}>
                    Edit
                  </span>
                  <span>
                    Delete
                  </span>
                  <span>
                    Clone
                  </span>


                  </div>
                            </p>
          <div className="">
            <div className="row">
              <div className="col-md-12" id="adatas">
                <h4 className="heading">Information</h4>
                <hr></hr>
              </div>
            </div>
            <div id="contracts">
            <table class="table table-bordered account-table tables">
<tbody><tr>
                <td id="td-right"><span>product</span>
                    </td><td>
                    {datas.product}
                 </td>
                <td id="td-right"><span>Product Master</span>
                    </td><td>
                    {datas.product_master}
                 </td>

              </tr>
              <tr>
              <td id="td-right"><span>Buyer Product Type</span>
                    </td><td>
                    {datas.buyer_product_type}
                 </td>
                <td id="td-right"><span>Surveyor Name</span>
                    </td><td>
                    {datas.surveyor_name}
                 </td>


              </tr>
              <tr>
               <td id="td-right"><span>Order</span>
                    </td><td>
                    {datas.order}
                 </td>
                 <td></td>
              </tr>
               </tbody>
                </table>
            </div>
          </div>
          <div className="">
            <div className="row">
              <div className="col-md-12" id="adatas">
                <h4 className="heading">CALORIFIC VALUE, kcal / kg</h4>
                <hr></hr>
              </div>
            </div>
            <div id="contracts">
            <table class="table table-bordered account-table tables">
<tbody>


<tr>
                <td  id="td-right">
                    <span>gross as recieved</span>
                    </td><td>
                    {datas.gross_as_recieved}
                  </td>
                  <td  id="td-right">
                    <span>gross air dried</span>
                    </td><td>
                    {datas.gross_air_dried}
                  </td>

                </tr>
                <tr>
 <td  id="td-right">
                    <span>net as recieved</span>
                    </td><td>
                    {datas.net_as_recieved}
                  </td>
                  <td  id="td-right"></td>
</tr>

               </tbody>
                </table>
            </div>
          </div>
          <div className="">
            <div className="row">
              <div className="col-md-12" id="adatas">
                <h4 className="heading">%As Received Basis</h4>
                <hr></hr>
              </div>
            </div>
            <div id="contracts">

            <table class="table table-bordered account-table tables">
<tbody>

 <tr>
                <td id="td-right">
                    <span>total moisture</span>
                    </td><td>
                    {datas.total_moisture}
                 </td>
                <td id="td-right">
                    <span>ash ARB</span>
                    </td><td>
                    {datas.ash_ARB}
                 </td>
                  </tr>

              <tr>
                <td id="td-right">
                    <span>sulphur ARB</span>
                    </td><td>
                    {datas.sulphur_ARB}
                 </td>

                <td id="td-right">
                    <span>volatile matter ARB</span>
                    </td><td>
                    {datas.volatile_matter_ARB}
                 </td>


              </tr>

               </tbody>
                </table>


            </div>
          </div>
          <div className="">
            <div className="row">
              <div className="col-md-12" id="adatas">
                <h4 className="heading">HGI</h4>
                <hr></hr>
              </div>
            </div>
            <div id="contracts">
            <table class="table table-bordered account-table tables">
<tbody>

 <tr>
                <td id="td-right">
                    <span>HGI</span>
                    </td><td>
                    {datas.hgi}
                 </td>
                <td id="td-right">

                    </td><td>

                 </td>
                  </tr>

               </tbody>
                </table>
            </div>
          </div>


          <div className="">
            <div className="row">
              <div className="col-md-12" id="adatas">
                <h4 className="heading">ULTIMATE ANALYSIS, %dry ash free basis</h4>
                <hr></hr>
              </div>
            </div>
            <div id="contracts">
            <table class="table table-bordered account-table tables">
<tbody>

 <tr>
                <td id="td-right">
                    <span>carbon dry ash free basis</span>
                    </td><td>
                    {datas.carbon_dry_ash_free_basis}
                  </td>
                  <td id="td-right">
                    <span>hydrogen dry ash free basis</span>
                    </td><td>
                    {datas.hydrogen_dry_ash_free_basis}
                  </td>

              </tr>
              <tr>
                <td id="td-right">
                    <span>nitrogen dry ash free basis</span>
                    </td><td>
                    {datas.nitrogen_dry_ash_free_basis}
                  </td>

                <td id="td-right">
                    <span>sulphur dry ash free basis</span>
                    </td><td>
                    {datas.sulphur_dry_ash_free_basis}
                  </td>

              </tr>
              <tr>
              <td id="td-right">
                    <span>oxygen dry ash free basis</span>
                    </td><td>
                    {datas.oxygen_dry_ash_free_basis}
                  </td>
                  <td></td></tr>
               </tbody>
                </table>
            </div>
          </div>

          <div className="">
            <div className="row">
              <div className="col-md-12" id="adatas">
                <h4 className="heading">SULPHUR, %air dried basis</h4>
                <hr></hr>
              </div>
            </div>
            <div id="contracts">
            <table class="table table-bordered account-table tables">
<tbody>

 <tr>
                <td id="td-right">
                    <span>Sulphur ADB</span>
                    </td><td>
                    {datas.sulphur_ADB}
                  </td>
                  <td id="td-right">

                    </td><td>

                  </td>

              </tr>

               </tbody>
                </table>

            </div>
          </div>
          <div className="">
            <div className="row">
              <div className="col-md-12" id="adatas">
                <h4 className="heading">ASH FUSION TEMPERATURE, Deg (C) Reducing</h4>
                <hr></hr>
              </div>
            </div>
            <div id="contracts">
            <table class="table table-bordered account-table tables">
<tbody>

    <tr>
                <td id="td-right">
                    <span>Initial Deformation</span>
                    </td><td>
                    {datas.initial_deformation_deg_c_reducing}
                  </td>
                 <td id="td-right">
                    <span>Spherical</span>
                    </td><td>
                    {datas.spherical_deg_c_reducing}
                  </td>



              </tr>
            <tr>

                <td id="td-right">
                    <span>Hemispherical</span>
                    </td><td>
                    {datas.hemispherical_deg_c_reducing}
                  </td>
                <td id="td-right">
                    <span>Flow</span>
                    </td><td>
                    {datas.flow_deg_c_reducing}
                  </td>


              </tr>

               </tbody>
                </table>
            </div>
          </div>
          <div className="">
            <div className="row">
              <div className="col-md-12" id="adatas">
                <h4 className="heading">ASH FUSION TEMPERATURE, Deg C Oxidizing</h4>
                <hr></hr>
              </div>
            </div>
            <div id="contracts">
            <table class="table table-bordered account-table tables">
<tbody>
  <tr>
                <td id="td-right">
                    <span>Oxidizing Hemispherical</span>
                    </td><td>
                    {datas.oxidizing_hemispherical}
                  </td><td id="td-right">
                    <span>Oxidizing Spherical</span>
                    </td><td>
                    {datas.oxidizing_spherical}
                  </td>


              </tr>
<tr>
 <td id="td-right">
                    <span>Oxidizing Flow</span>
                    </td><td>
                    {datas.oxidizing_flow}
                  </td>
                  <td></td></tr>
               </tbody>
                </table>

            </div>
          </div>
          <div className="">
            <div className="row">
              <div className="col-md-12" id="adatas">
                <h4 className="heading">ASH ANALYSIS, %dry basis in ash</h4>
                <hr></hr>
              </div>
            </div>
            <div id="contracts">
            <table class="table table-bordered account-table tables">
  <tbody>
    <tr>
      <td id="td-right"> <span>SiO2</span> </td>
      <td> {datas.SiO2_dry_basis_in_ash} </td>
      <td id="td-right"> <span>Mn3O4</span> </td>
      <td> {datas.Mn3O4_typical_dry_basis_in_ash} </td>
    </tr>
    <tr>
      <td id="td-right"> <span>AI2O3</span> </td>
      <td> {datas.AI2O3_dry_basis_in_ash} </td>
      <td id="td-right"> <span>Fe2O3</span> </td>
      <td> {datas.Fe2O3_dry_basis_in_ash} </td>
    </tr>
    <tr>
    <td id="td-right"> <span>CaO</span> </td>
      <td> {datas.CaO_dry_basis_in_ash} </td>
      <td id="td-right"> <span>MgO</span> </td>
      <td> {datas.MgO_dry_basis_in_ash} </td>
    </tr>
    <tr>
      <td id="td-right"> <span>NA2O</span> </td>
      <td> {datas.NA2O_dry_basis_in_ash} </td>
      <td id="td-right"><span>K2O</span> </td>
      <td> {datas.K2O_dry_basis_in_ash} </td>
    </tr>
    <tr>
      <td id="td-right"> <span>P2O5</span> </td>
      <td> {datas.P2O5_dry_basis_in_ash} </td>
      <td id="td-right"> <span>SO3</span> </td>
      <td> {datas.SO3_dry_basis_in_ash} </td>
    </tr>
  </tbody>
</table>
            </div>
          </div>

          <div className="">
            <div className="row">
              <div className="col-md-12" id="adatas">
                <h4 className="heading">TRACE ELEMENTS, ppm Air Dried in Coal</h4>
                <hr></hr>
              </div>
            </div>
            <div id="contracts">
            <table class="table table-bordered account-table tables">
  <tbody>
    <tr>
      <td id="td-right"> <span>SiO2</span> </td>
      <td> {datas.SiO2_dry_basis_in_ash} </td>
      <td id="td-right"> <span>Mn3O4</span> </td>
      <td> {datas.Mn3O4_typical_dry_basis_in_ash} </td>
    </tr>
    <tr>
      <td id="td-right"> <span>TE Arsenic As</span> </td>
      <td> {datas.TE_arsenic_As_air_dried_coal} </td>
      <td id="td-right"> <span>TE Antimony Sb</span> </td>
      <td> {datas.TE_antimony_Sb_air_dried_coal} </td>
    </tr>
    <tr>
      <td id="td-right"> <span>TE Beryllium Be</span> </td>
      <td> {datas.TE_beryllium_Be_air_dried_coal} </td>
      <td id="td-right"> <span>TE Boron B</span> </td>
      <td> {datas.TE_boron_B_air_dried_coal} </td>
    </tr>
    <tr>
    <td id="td-right"> <span>TE Cadmium Cd</span> </td>
    <td> {datas.TE_cadmium_Cd_air_dried_coal} </td>
    <td id="td-right"> <span>TE chromium Cr</span> </td>
    <td> {datas.TE_chromium_Cr_air_dried_coal} </td>
    </tr>
    <tr>
      <td id="td-right"> <span>TE Copper Fe</span> </td>
      <td> {datas.TE_copper_Fe_air_dried_coal} </td>
      <td id="td-right"> <span>TE Fluorine F</span> </td>
      <td> {datas.TE_fluorine_F_air_dried_coal} </td>
    </tr>
    <tr>
      <td id="td-right"> <span>TE Mercury Hg</span> </td>
      <td> {datas.TE_mercury_Hg_air_dried_coal} </td>
      <td id="td-right"> <span>TE Molybdenum Mo</span> </td>
      <td> {datas.TE_molybdenum_Mo_air_dried_coal} </td>
    </tr>
     <tr>
     <td id="td-right"><span>TE Lead Pb</span> </td>
    <td> {datas.TE_lead_Pb_air_dried_coal} </td>
    <td id="td-right"><span>TE Phosporous P</span> </td>
    <td> {datas.TE_phosporous_P_air_dried_coal} </td>
    </tr>
    <tr>
      <td id="td-right"> <span>TE Selenium Se</span> </td>
      <td> {datas.TE_selenium_Se_air_dried_coal} </td>
      <td id="td-right"><span>TE Vanadium V</span> </td>
      <td> {datas.TE_vanadium_V_air_dried_coal} </td>
    </tr>
    <tr>
    <td id="td-right"> <span>TE Zinc Zn</span> </td>
      <td> {datas.TE_Zinc_Zn_air_dried_coal} </td>
      <td></td>
    </tr>
  </tbody>
</table>
            </div>
          </div>


          <div className="">
            <div className="row">
              <div className="col-md-12" id="adatas">
                <h4 className="heading">Sizing, %</h4>
                <hr></hr>
              </div>
            </div>
            <div id="contracts">
            <table class="table table-bordered account-table tables">
  <tbody>
     <tr>
                <td id="td-right">
                    <span>size 0 To 50 mm</span>
                    </td><td>
                    {datas.size_0_to_50_mm}
                  </td><td id="td-right">
                    <span>Above 50 MM</span>
                    </td><td>
                    {datas.above_50_mm}
                  </td>


              </tr>

                          <tr>
                           <td id="td-right">
                    <span>TUnder 2 MM</span>
                    </td><td>
                    {datas.under_2_mm}
                  </td>
                <td id="td-right">
                    <span>Size Above 2mm Typical</span>
                    </td><td>
                    {datas.size_above_2mm_typical}
                  </td>
                </tr>

                <tr>
                <td id="td-right">
                    <span>Size Above 2mm Rejection</span>
                    </td><td>
                    {datas.size_above_2mm_rejection}
                  </td>
<td></td>
                  </tr>
  </tbody>
</table>

            </div>
          </div>



          <div className="">
            <div className="row">
              <div className="col-md-12" id="adatas">
                <h4 className="heading">Testing Method</h4>
                <hr></hr>
              </div>
            </div>
            <div id="contracts">
            <table class="table table-bordered account-table tables">
  <tbody>
     <tr>
                <td id="td-right">
                    <span>Testing Method</span>
                    </td><td>
                    {datas.testing_method}
                  </td><td id="td-right">

                    </td><td>
                  </td>


              </tr>


  </tbody>
</table>

            </div>
          </div>
        </div>
        )}
      </div></div></div>
      <Footer />
    </div>
  );
};

export default BuyerView;
