

import React, { useEffect, useState } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { AES, enc } from 'crypto-js';
import Header from '../Header'
import Footer from '../Footer'
import $ from 'jquery';
import axios from "axios";
// import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_URL } from "../config";
function AddcontractClasue() {
  const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
  const encryptedAccessToken = localStorage.getItem("access_token");
  const decryptedAccessToken = encryptedAccessToken
    ? AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8)
    : null;
   const [contract, setContract] = useState([]);
  // bank
  useEffect(() => {
    fetchBank();
  }, [decryptedAccessToken]);

  const fetchBank = async () => {
    try {
      const response = await axios.get(
        `${API_URL}get/all/contract`,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data.contract;
      setContract(data);
      console.log("Supplier" + JSON.stringify(data));
    } catch (error) {
      console.error(error);
    }
  };
 console.log(contract)

  const body=$(window).height()
  const header=$("#header").height()
  const nav=$(".navbar").height()
  const footer=$(".footer").height()
  const total_height=header+nav+footer;
  const window_height=body-total_height;
  console.log(total_height)
  console.log(body)
  $(".contactAccount").css({"min-height":(window_height)+"px"})

    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const onSubmit = data =>{
      const responseData={
        contract:data.contract,
        contract_clause_name:data.contract_clause_name,
        clause_no:data.clause_no,
        clause_type:data.clause_type,
        definations:data.definations,
      }
        const encryptedAccessToken = localStorage.getItem('access_token');
        const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';
        console.log(JSON.stringify(data))
        if (encryptedAccessToken) {
          const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);

        fetch(`${API_URL}add/contract_clause`, {
                  method: "POST",
                  body: JSON.stringify(responseData),
                  headers: { Authorization: `Bearer ${decryptedAccessToken}`,
                  'Content-Type': 'application/json' },
                })
                  .then((response) => response.json())

                  .then((response) => {
                      toast.success("Added succesfully", {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            setTimeout(() => {
              reset();
            }, 300);
                  })

                }

        };

  return (

    <div className="addAccount">
    <Header/>
    <div className="page-header" id='add-account'>
    <div>
      <h2 className="main-content-title tx-24 mg-b-5">Add Contract Clasue</h2>
      <ol className="breadcrumb">
        <li className="breadcrumb-item active" aria-current="page">Dashboard / Add Contract Clasue </li>
      </ol>
    </div>
    <div className="d-flex">
      <div className="justify-content-center">

        {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
         <a href='/account/add-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</a>
        </button> */}

      </div>
    </div>
  </div>    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row className="mb-3">
        <h4 className='heading'>Contract Clasue Information</h4>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Contract Clause Name"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Contract Clause Name"
              name="contract_clause_name"
              {...register("contract_clause_name", { required: true })}
            />
            {errors.contract_clause_name && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Clause No"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Clause No"
              name="clause_no"
              {...register("clause_no", { required: true })}
            />
            {errors.clause_no && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Clause Type"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Clause Type"
              name="clause_type"
              {...register("clause_type", { required: true })}
            />
            {errors.clause_type && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
            <FloatingLabel
              controlId="floatingSelect"
              className="dropDown"
              label="Contract"
            >
              <Form.Select
                aria-label="Floating label select example"
                name="contract"
                id="account_name"
                placeholder="Contract"
                defaultValue=""
                {...register("contract", { required: false })}
              >
                <option>Select</option>
                {contract.map((x) => {
                  return (
                    <option value={x.contract_number}>{x.contract_number}</option>
                  );
                })}
              </Form.Select>
              {errors.contract && <span>Required </span>}
            </FloatingLabel>
          </Form.Group>

        <Form.Group as={Col} md="12">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Definations"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Definations"
                    style={{ height: "180px!important" }}
                    name="definations"
                    {...register("definations", { required: false })}
                  />
                  {errors.definations && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>
                </Row>
      <input type="submit"  className="addaccount-save" value='Save'/>
      <div className='successDiv'><p></p><p></p></div>
    </Form>
    <Footer/>
    </div>
  );
}

export default AddcontractClasue;

