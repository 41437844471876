import React, { useEffect, useState, forwardRef } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { useForm, Controller } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import Header from '../Header'
import Footer from '../Footer'
import { AES, enc } from 'crypto-js';
import 'react-date-picker/dist/DatePicker.css';
import { IoIosHelpCircle } from "react-icons/io";
import 'react-calendar/dist/Calendar.css';
import $ from 'jquery'
import {
  Card,
  OverlayTrigger,
  Tooltip,
  Table,
} from "react-bootstrap";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { API_URL } from '../config';
import DatePicker from "react-datepicker";
// import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
import axios from "axios";
import Select from 'react-select';
import RecentItem from "../recentitem/rececentItem";
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import { useParams, useNavigate, Link } from "react-router-dom";

function  AddContacts(props) {
  const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
  const encryptedAccessToken = localStorage.getItem("access_token");
  const decryptedAccessToken = encryptedAccessToken
    ? AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8)
    : null;
  const navigate = useNavigate();
  const [isFormsubmit, setFormsubmited] = useState(false);
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Please Enter Full Company Name here
    </Tooltip>
  );
  // const [startDate1, setStartDate1] = useState(new Date());

  //  const birthDate=startDate1.toLocaleDateString("en-US", { year: 'numeric' })+"-"+(startDate1.toLocaleDateString("en-US", { month: '2-digit' }))+"-"+(startDate1.toLocaleDateString("en-US", { day: '2-digit' }))
  //  console.log(birthDate)
  // const CustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
  //   <input
  //     value={value}
  //     className="custom-input"
  //     onClick={onClick}
  //     name="birthdate"
  //     onChange={onChange}
  //     ref={ref}
  //   ></input>
  // ));

  const params = useParams()
  const [startDate, setStartDate] = useState(null);
  const birthDate = startDate ? `${startDate.getDate().toString().padStart(2, '0')}/${(startDate.getMonth() + 1).toString().padStart(2, '0')}/${startDate.getFullYear()}` : null;
  const handleStartDateChange = (date) => {
    setStartDate(date);
  };



  const [accountNames, setAccountName]=useState([]);
  const body=$(window).height()
  const header=$("#header").height()
  const nav=$(".navbar").height()
  const footer=$(".footer").height()
  const total_height=header+nav+footer;
  const window_height=body-total_height;
  console.log(window_height)
  const [firstName, setFirstName] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const handleFirstNameChange = (e) => {
    const value = e.target.value;
    setFirstName(value);

    // Here you can perform your validation logic
    // if (value.trim() === '') {
    //   setFirstNameError('First name is required');
    // } else {
    //   setFirstNameError('');
    // }
  };



  useEffect(() => {
    fetchDataSupplier();
  }, [decryptedAccessToken]);

  const fetchDataSupplier = async () => {
    try {
      const response = await axios.get(`${API_URL}all/accounts`, {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      const data = response.data.accounts;
      setAccountName(data)
       console.log('Supplier'+ JSON.stringify(data))
    } catch (error) {
      console.error(error);
    }
  };

  const optionsCompany = accountNames.map(item => ({
    value: item.account_name,
    label: item.account_name
  }));
  const [companydrop, setCompanydrop] = useState(null);
  const [error, setError] = useState({});
  const handleCompany = (selectedOption) => {
    setCompanydrop(selectedOption);
    if (selectedOption) {
      setError((prevErrors) => ({ ...prevErrors, companydrop: '' }));
    }
  };
  const validateForm = () => {
    const newErrors = {};
    if (!companydrop) {
      newErrors.companydrop = 'Please select an account name';
    }
    setError(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  $(".contactAccount").css({"min-height":(window_height)+"px"})
const id=params.id

     const {register, handleSubmit, reset, formState: { errors } } = useForm();
    //  console.log(birthDate)
    const onSubmit = data =>{
      setFormsubmited(true)
    //   if (firstName.trim() === '') {
    //     setFirstNameError('First name is required');
    // // Scroll to the top of the page smoothly
    // window.scrollTo({
    //   top: 0,
    //   behavior: 'smooth'
    // });
    // return;
    //   }
    //   else{
      if (validateForm()) {
        const responseData={
            saluation:data.saluation,
            first_name:data.first_name,
            last_name:data.last_name,
            birthdate:birthDate?birthDate:null,
            title:data.title,
            account_name:companydrop.value,
            reports_to:data.reports_to,
            contact_email:data.contact_email,
            contact_mobile:data.contact_mobile,
            contact_owner:data.contact_owner,
            department:data.department,
            lead_source:data.lead_source,
            mail_street:data.mail_street,
            mail_postal_code:data.mail_postal_code,
            mail_city:data.mail_city,
            mail_state:data.mail_state,
            mail_country:data.mail_country

          };

      const encryptedAccessToken = localStorage.getItem('access_token');
        const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';
        if (encryptedAccessToken) {
          const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);

          axios.post(`${API_URL}all/contact/add`, responseData, {
            headers: {
              Authorization: `Bearer ${decryptedAccessToken}`,
              'Content-Type': 'application/json',
            },
          })
        .then((response) => {
        console.log(response)

        const contactId=response.data.contact_id;
        if (response.data.success === true) {
          document.getElementById('messageContainer').innerHTML = `
              <div class="success-message">
              ${response.data.message}
              </div>
          `;

            window.scrollTo({
              top: 0,
              behavior: "smooth" // Optional: animated scrolling
          });
          navigate(`/contact/contact-details/${contactId}`);

      } else if (response.data.success === false) {
        // setFormsubmited(true)
          document.getElementById('messageContainer').innerHTML = `
              <div class="error-message">
                  ${response.data.message}
              </div>
          `;

          // Scroll to the top of the page
          window.scrollTo({
              top: 0,
              behavior: "smooth" // Optional: animated scrolling
          });
      }
          //  else{
          //   toast.error("Check with fields", {
          //     position: "bottom-center",
          //     autoClose: 1000,
          //     hideProgressBar: false,
          //     closeOnClick: true,
          //     pauseOnHover: true,
          //     draggable: true,
          //     progress: undefined,
          //     theme: "light",})

          // }

        })


      // }
    }
  }
};

  return (

    <div className="contactAccount full-container" id='contacts'>
    <Header/>
    <div className="row">
    <div className="col-md-2 recent-item">
          <RecentItem/>
        </div>
        <div className="col-md-10 right-section contact-right">
    <div className="page-header" id='add-account'>
    <div>
      <h2 className="main-content-title tx-24 mg-b-5">Add New Contact </h2>
      <ol className="breadcrumb">
        <li className="breadcrumb-item active" aria-current="page">Dashboard / Contact / Add New Contact</li>
      </ol>
    </div>
    <div className="d-flex">
      <div className="justify-content-center">

        {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
         <a href='/account/add-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</a>
        </button> */}

      </div>
    </div>
  </div>
   <Form onSubmit={handleSubmit(onSubmit)}>
   {/* <p type="invalid" className="errors">{firstNameError}</p> */}
   <div id="messageContainer"></div>
   <p className='edit-cancel'>  <input
      type="submit"
      className="account-save"
      value={isFormsubmit ? "Saving" : "Save"}
      disabled={isFormsubmit}
    /><Link to={'/contact/contact-list'}><input type="submit" className="account-save" value="Cancel" /></Link></p>
      <Row className="mb-3">

      <div className='col-md-12'>
      <h4 className='heading'>Contact Information</h4>
        </div>

        <Form.Group
              as={Col}
              md="6"
              id=''
            >
              <FloatingLabel controlId="floatingSelect" className='dropDown' label="Salutation">
      <Form.Select aria-label="Floating label select example"
      name="saluation"
      placeholder="Salutation"
      tabIndex={1}
      {...register("saluation", { required: false })}
      >
        <option value='' disabled>--None--</option>
        <option value="Mr">Mr</option>
        <option value="Ms">Ms</option>
        <option value="Mrs">Mrs</option>
        <option value="Dr">Dr</option>
        <option value="Prof">Prof</option>
      </Form.Select>
      {errors.saluation && <span>Required</span>}
    </FloatingLabel>

    </Form.Group>
    <Form.Group
              as={Col}
              md="6"

            >
    <FloatingLabel
            controlId="floatingInput"
            label="contact owner"
            className="mb-3 tooltips-right"
          >
            <Form.Control
              type="text"
              placeholder="contact owner"
              tabIndex={6}
              name="contact_owner"
              {...register("contact_owner", { required: false })}
            />
             {errors.contact_owner && <span>Required</span>}
             {/* <span id='tooltip' data-tooltip-id="button-tooltip11" data-tooltip-place='left'>
                                    <IoIosHelpCircle />
            </span>
            <ReactTooltip
            id="button-tooltip11"
            place="bottom"
            content="Discounted Price as per Vessel Type \r\u003Cbr\u003EIf Geared (GNG) - 0.90\r\u003Cbr\u003Eif Grearless - 0.92"
          /> */}
          </FloatingLabel>

            </Form.Group>

            <Form.Group as={Col} md="6">
        <FloatingLabel controlId="floatingInput" label="First Name" className="mb-3">
          <Form.Control
            type="text"
            
            placeholder="First Name"
            name="first_name"
            tabIndex={2}
            // value={firstName}
            // onChange={handleFirstNameChange}
            // isInvalid={!!firstNameError} // Mark the control as invalid if there's an error
            {...register("first_name", { required: false })}
          />

        </FloatingLabel>
      </Form.Group>


        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Reports to"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Reports to"
            name="reports_to"
            tabIndex={7}
            {...register("reports_to", { required: false })}
            />
             {errors.reports_to && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>



            <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Last Name"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Last Name"
            name="last_name"
            tabIndex={3}
            className="error-validation"
            {...register("last_name", { required: false })}
            />
             {errors.last_name && <span>Error: You must enter a value
</span>}
          </FloatingLabel>
        </Form.Group>


        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Department"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Department"
            name="department"
            tabIndex={8}
            {...register("department", { required: false })}
            />
             {errors.department && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>


        <Form.Group as={Col} md="6" controlId="floatingSelect">
        <FloatingLabel label="Account name" className="select-dropdown">
          <Select
            options={optionsCompany}
            value={companydrop}
            onChange={handleCompany}
            className={error.companydrop ? 'is-invalid' : ''}
            tabIndex={4}/>
        </FloatingLabel>
        {error.companydrop && (
          <div className="invalid-feedback d-block">
            {error.companydrop}
          </div>
        )}
      </Form.Group>

        <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Lead Source">
    <Form.Select aria-label="Floating label select example"
    name="lead_source"
    id='account_name'
    tabIndex={9}
    placeholder="Lead Source"
    {...register("lead_source", { required:false })}
    >
      <option value="">--None--</option>
    <option value="Advertisement">Advertisement</option>
    <option value="Customer Event">Customer Event</option>
    <option value="Employee Referral">Employee Referral</option>
    <option value="Google AdWords">Google AdWords</option>
    <option value="Other">Other</option>
    <option value="Partner">Partner</option>
    <option value="Purchased List">Purchased List</option>
    <option value="Trade Show">Trade Show</option>
    <option value="Webinar">Webinar</option>
    <option value="Website">Website</option>
    <option value="Rajgopalan">Rajgopalan</option>
    <option value="Olivia">Olivia</option>

    </Form.Select>
    {errors.lead_source && <span>Required </span>}
  </FloatingLabel>
              </Form.Group>


              <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Birth Date"
            className="mb-3 datepick"


          >
              <DatePicker
              selected={startDate}
        onChange={handleStartDateChange}
        tabIndex={5}
        dateFormat='dd/MM/yyyy'
         placeholderText="Select"
      />



          </FloatingLabel>
        </Form.Group>



      </Row>
      <Row>
      <div className='col-md-12'>
      <h4 className='heading'>Contact Information</h4>
        </div>
        <Form.Group as={Col} md="6" id='contact-title'>
          <FloatingLabel
            controlId="floatingInput"
            label="Title"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Title"
            name="title"
            tabIndex={10}
            {...register("title", { required: false })}
            />
             {errors.title && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
         <Form.Group as={Col} md="6"></Form.Group>
         <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Contact Email"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Contact Email"
            name="contact_email"
            tabIndex={11}
            {...register("contact_email", { required: false })}
            />
             {errors.contact_email && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6"></Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Contact Mobile"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Contact Mobile"
            name="contact_mobile"
            tabIndex={11}
            {...register("contact_mobile", { required: false })}
            />
             {errors.contact_mobile && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>

      </Row>

      <Row>
      <div className='col-md-12'>
      <h4 className='heading'>Address Information</h4>
        </div>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Mail Street"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Mail Street"
            name="mail_street"
            tabIndex={12}
            {...register("mail_street", { required: false })}
            />
             {errors.mail_street && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6"></Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Mail postal code"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Mail postal code"
            name="mail_postal_code"
            tabIndex={12}
            {...register("mail_postal_code", { required: false })}
            />
             {errors.mail_postal_code && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6"></Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Mail city"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Mail city"
            name="mail_city"
            tabIndex={13}
            {...register("mail_city", { required: false })}
            />
             {errors.mail_city && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6"></Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Mail state"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Mail state"
            name="mail_state"
            tabIndex={14}
            {...register("mail_state", { required: false })}
            />
             {errors.mail_state && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6"></Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Mail country"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Mail country"
            name="mail_country"
            tabIndex={15}
            {...register("mail_country", { required: false })}
            />
             {errors.mail_country && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>

      </Row>
      <p className='edit-cancel'>  <input
      type="submit"
      className="account-save"
      value={isFormsubmit ? "Saving" : "Save"}
      disabled={isFormsubmit}
    /><Link to={'/contact/contact-list'}><input type="submit" className="account-save" value="Cancel" /></Link></p>
      <div className='contact-success'><p></p><p></p></div>
    </Form>
    </div> </div>
    <Footer/>
    </div>
  );
}

export default AddContacts;
