import React, { useEffect, useState } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { AES, enc } from "crypto-js";
import Header from "../Header";
import Footer from "../Footer";
import $ from "jquery";
import axios from "axios";
import { useParams } from "react-router-dom";
// import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_URL } from '../config';
function EditContract() {
  const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
  const encryptedAccessToken = localStorage.getItem("access_token");
  const decryptedAccessToken = encryptedAccessToken
    ? AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8)
    : null;
  const body = $(window).height();
  const header = $("#header").height();
  const nav = $(".navbar").height();
  const footer = $(".footer").height();
  const total_height = header + nav + footer;
  const window_height = body - total_height;
  console.log(total_height);
  console.log(body);

  const params = useParams();
  const idUpdate = params.id;
  $(".contactAccount").css({ "min-height": window_height + "px" });
  const [account, setAccount] = useState([]);
  const [update, setUpdate] = useState([]);
  const [companyData, setcompanyData] = useState([]);
  const [traderData, setTraderData] = useState([]);
  const [supplierData, setsupplierData] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [users, setUsers] = useState([]);
  const [surveyors, setSurveyor] = useState([]);
  const [getPrice, setgetPrice] = useState([]);
  useEffect(() => {
    fetchDataPrice();
  }, [decryptedAccessToken]);

  const fetchDataPrice = async () => {
    try {
      const response = await axios.get(
        `${API_URL}get/all/price_items`,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data.priceitem;
      // console.log(data)
      setgetPrice(data);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchData6();
  }, [decryptedAccessToken]);

  const fetchData6 = async () => {
    try {
      const response = await axios.get(
        `${API_URL}get/surveyor`,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data;
      // console.log(JSON.stringify(data))
      setSurveyor(data);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchDatausers();
  }, [decryptedAccessToken]);

  const fetchDatausers = async () => {
    try {
      const response = await axios.get(
        `${API_URL}get/all/users`,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data;
      console.log(data);
      setUsers(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDataContacts();
  }, [decryptedAccessToken]);

  const fetchDataContacts = async () => {
    try {
      const response = await axios.get(
        `${API_URL}allcontacts`,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data.contact;
      // console.log(data)
      setContacts(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDataSupplier();
  }, [decryptedAccessToken]);

  const fetchDataSupplier = async () => {
    try {
      const response = await axios.get(
        `${API_URL}get/supplier`,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data;
      // console.log(data)
      setsupplierData(data);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchDataTrader();
  }, [decryptedAccessToken]);

  const fetchDataTrader = async () => {
    try {
      const response = await axios.get(
        `${API_URL}get/all/rwoodusers`,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data;
      // console.log(data)
      setTraderData(data);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchDataCompany();
  }, [decryptedAccessToken]);

  const fetchDataCompany = async () => {
    try {
      const response = await axios.get(
        `${API_URL}get/all/rwoodcompany`,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data.rwoodcompany;
      // console.log(data)
      setcompanyData(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [decryptedAccessToken]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${API_URL}get/contractbyID/` + idUpdate,
        {
          headers: {
            Authorization: `Bearer ${decryptedAccessToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      const data = response.data[0].contract_details;
      setAccount(data);
      setValue('locked',  true);
      setValue('company',  data.company);
      setValue('currency',  data.currency);
      setValue('trader_name',  data.trader_name);
      setValue('exchange_rate',  data.exchange_rate);
      setValue('contract_record_type',  data.contract_record_type);
      setValue('price_book',  data.price_book);
      setValue('contract_owner',  data.contract_owner);
      setValue('Rwood_Contract_No',  data.Rwood_Contract_No);
      setValue('status',  data.status);
      setValue('account_name',  data.account_name);
      setValue('contract_status',  data.contract_status);
      setValue('pending_matters',  data.pending_matters);
      setValue('contract_term_in_months',  data.contract_term_in_months);
      setValue('remark',  data.remark);
      setValue('supplier',  data.supplier);
      setValue('master_conference_reference',  data.master_conference_reference);
      // setValue('contract_start_date',  renewalDate);
      // setValue('renewal_date',  renewalDate);
      setValue('renewal_contract_number',  data.renewal_contract_number);
      setValue('origin',  data.origin);
      setValue('commodity',  data.commodity);
      setValue('interlab_tolerance',  data.interlab_tolerance);
      setValue('contract_quantity_in_MT',  data.contract_quantity_in_MT);
      setValue('destination',  data.destination);
      setValue('pricing_terms',  data.pricing_terms);
      setValue('price_adjust_clause',  data.price_adjust_clause);
      setValue('bonus_penalty_clause',  data.bonus_penalty_clause);
      setValue('payment_terms',  data.payment_terms);
      setValue('general_terms',  data.general_terms);
      setValue('hs_code',  data.hs_code);
      setValue('rejections',  data.rejections);

      setValue('loading_rate_per_day',  data.loading_rate_per_day);
      setValue('lc_type',  data.lc_type);
      setValue('master_lc_presentation_days',  data.master_lc_presentation_days);
      setValue('payment_details',  data.payment_details);
      setValue('master_lc_tolerance',  data.master_lc_tolerance);
      setValue('lc_validity_no_of_days',  data.lc_validity_no_of_days);
      setValue('lc_opening_days',  data.lc_opening_days);
      setValue('lc_opening_days_in_words',  data.lc_opening_days_in_words);
      setValue('list_of_documents_required',  data.list_of_documents_required);
      setValue('kind_attention',  data.kind_attention);
      setValue('customer_signed_by',  data.customer_signed_by);
      setValue('customer_signed_title',  data.customer_signed_title);
      setValue('company_signed_by',  data.company_signed_by);
      setValue('company_signed_date',  data.company_signed_date);
      setValue('original_contract_recieved_date',  data.original_contract_recieved_date);
      setValue('original_contract_sent_date',  data.original_contract_sent_date);
      setValue('applicable_law',  data.applicable_law);

      setValue('arbitration',  data.arbitration);
      setValue('rwood_surveryor_agent',  data.rwood_surveryor_agent);
      setValue('surveyor_2',  data.surveyor_2);
      setValue('surveyor_3',  data.surveyor_3);
      setValue('description',  data.description);
      setValue('lc_presentation_period',  data.lc_presentation_period);
      setValue('product_description',  data.product_description);
    } catch (error) {
      console.error(error);
    }
  };
console.log(account.contract_record_type)
  console.log(account);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  if (account.contract_record_type === "Buyer Long Term") {
    const onSubmit = (data) => {
      const responseData = {
        locked: true,
        company: data.company,
        currency: data.currency,
        trader_name: data.trader_name,
        exchange_rate: data.exchange_rate,
        contract_record_type: data.contract_record_type,
        price_book: data.price_book,
        contract_owner: data.contract_owner,
        Rwood_Contract_No: data.Rwood_Contract_No,
        status: data.status,
        account_name: data.account_name,
        contract_status: data.contract_status,
        pending_matters: data.pending_matters,
        contract_term_in_months: data.contract_term_in_months,
        remark: data.remark,
        supplier: data.supplier,
        master_conference_reference: data.master_conference_reference,
        // contract_start_date: renewalDate,
        // renewal_date: renewalDate,
        renewal_contract_number: data.renewal_contract_number,
        origin: data.origin,
        commodity: data.commodity,
        interlab_tolerance: data.interlab_tolerance,
        contract_quantity_in_MT: data.contract_quantity_in_MT,
        destination: data.destination,
        pricing_terms: data.pricing_terms,
        price_adjust_clause: data.price_adjust_clause,
        bonus_penalty_clause: data.bonus_penalty_clause,
        payment_terms: data.payment_terms,
        general_terms: data.general_terms,
        hs_code: data.hs_code,
        rejections: data.rejections,

        loading_rate_per_day: data.loading_rate_per_day,
        lc_type: data.lc_type,
        master_lc_presentation_days: data.master_lc_presentation_days,
        payment_details: data.payment_details,
        master_lc_tolerance: data.master_lc_tolerance,
        lc_validity_no_of_days: data.lc_validity_no_of_days,
        lc_opening_days: data.lc_opening_days,
        lc_opening_days_in_words: data.lc_opening_days_in_words,
        list_of_documents_required: data.list_of_documents_required,
        kind_attention: data.kind_attention,
        customer_signed_by: data.customer_signed_by,
        customer_signed_title: data.customer_signed_title,
        company_signed_by: data.company_signed_by,
        company_signed_date: data.company_signed_date,
        original_contract_recieved_date: data.original_contract_recieved_date,
        original_contract_sent_date: data.original_contract_sent_date,
        applicable_law: data.applicable_law,

        arbitration: data.arbitration,
        rwood_surveryor_agent: data.rwood_surveryor_agent,
        surveyor_2: data.surveyor_2,
        surveyor_3: data.surveyor_3,
        description: data.description,
        lc_presentation_period: data.lc_presentation_period,
        product_description: data.product_description,
      };
      // alert(JSON.stringify(responseData));
      const encryptedAccessToken = localStorage.getItem("access_token");
      const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
      if (encryptedAccessToken) {
        const decryptedAccessToken = AES.decrypt(
          encryptedAccessToken,
          secret_key
        ).toString(enc.Utf8);

        fetch(`${API_URL}update/contract/` + idUpdate, {
          method: "PUT",
          body: JSON.stringify(responseData),
          headers: {
            Authorization: `Bearer ${decryptedAccessToken}`,
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())

          .then((response) => {
            // console.log(response);
            toast.success("Contract Updated Successfully", {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          });
      }
    };
    const handleChange = (e) => {
      const { name, value } = e.target;
      setUpdate((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    };
    return (
      <>
        <Header />
        <div className="contactAccount contracts">
          <div className="page-header" id="add-account">
            <div>
              <h2 className="main-content-title tx-24 mg-b-5">
                Edit Buyer Long Term Contract{" "}
              </h2>
              <ol className="breadcrumb">
                <li className="breadcrumb-item active" aria-current="page">
                  Dashboard / Contract / Edit Buyer Long Term
                </li>
              </ol>
            </div>
            <div className="d-flex">
              <div className="justify-content-center">
                {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
          <Link to='/accounts/add-record'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</Link>
        </button> */}
              </div>
            </div>
          </div>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className="mb-3">
              <h4 className="heading">Contract Information</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Company"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="company"
                    placeholder="Company"
                    defaultValue={account.company || ""}
                    onChange={handleChange}
                    {...register("company", { required: false })}
                  >
                    <option value="">Select </option>
                    {companyData.map((x, i) => {
                      return (
                        <option key={i} value={x.company_name}>
                          {x.company_name}
                        </option>
                      );
                    })}
                  </Form.Select>
                  {errors.company && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Currency"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="currency"
                    placeholder="Currency"
                    defaultValue={account.currency || ""}
                    onChange={handleChange}
                    {...register("currency", { required: true })}
                  >
                    <option value="">Select </option>
                    <option value="AED">AED </option>
                    <option value="USD">USD </option>
                  </Form.Select>
                  {errors.owner_expiration_notice && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Trader Name"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="trader_name"
                    placeholder="Trader Name"
                    defaultValue={account.trader_name || ""}
                    onChange={handleChange}
                    {...register("trader_name", { required: false })}
                  >
                    <option value="">Select </option>
                    {traderData.map((x, i) => {
                      return (
                        <option key={i} value={x.rwood_username}>
                          {x.rwood_username}
                        </option>
                      );
                    })}
                  </Form.Select>
                  {errors.trader_name && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Exchange rate"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Exchange rate "
                    name="exchange_rate"
                    defaultValue={account.exchange_rate || ""}
                    onChange={handleChange}
                    {...register("exchange_rate", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.exchange_rate && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Contract record type"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Contract record type"
                    defaultValue={account.contract_record_type || ""}
                    onChange={handleChange}
                    name="contract_record_type"
                    {...register("contract_record_type", { required: true })}
                  />
                  {errors.contract_record_type && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Contract Owner"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Contract Owner"
                    name="contract_owner"
                    defaultValue={account.contract_owner || ""}
                    onChange={handleChange}
                    {...register("contract_owner", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.contract_owner && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Price book"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Price book"
                    name="price_book"
                    defaultValue={account.price_book || ""}
                    onChange={handleChange}
                    {...register("price_book", { required: false })}
                  />
                  {errors.price_book && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Rwood contract no"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Rwood contract no"
                    name="Rwood_Contract_No"
                    defaultValue={account.Rwood_Contract_No || ""}
                    onChange={handleChange}
                    {...register("Rwood_Contract_No", { required: false })}
                  />
                  {errors.Rwood_Contract_No && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Status"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="status"
                    defaultValue={account.status || ""}
                    onChange={handleChange}
                    placeholder="Status"
                    {...register("status", { required: false })}
                  >
                    <option value="">Select </option>
                    <option value="Draft">Draft </option>
                    <option value="Approved">Approved </option>
                  </Form.Select>
                  {errors.status && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Contract Status"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="contract_status"
                    defaultValue={account.contract_status || ""}
                    onChange={handleChange}
                    placeholder="Contract Status"
                    {...register("contract_status", { required: false })}
                  >
                    <option value="">Select </option>
                    <option value="Draft">Draft </option>
                    <option value="Approved">Approved </option>
                  </Form.Select>
                  {errors.contract_status && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Contract term in months"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Contract term in months"
                    name="contract_term_in_months"
                    defaultValue={account.contract_term_in_months || ""}
                    onChange={handleChange}
                    {...register("contract_term_in_months", {
                      required: false,
                    })}
                  />
                  {errors.contract_term_in_months && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Pending Matters"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Pending Matters"
                    style={{ height: "100px!important" }}
                    name="pending_matters"
                    defaultValue={account.pending_matters || ""}
                    onChange={handleChange}
                    {...register("pending_matters", { required: false })}
                  />
                  {errors.pending_matters && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel controlId="floatingTextarea2" label="Remark">
                  <Form.Control
                    as="textarea"
                    placeholder="Remark"
                    style={{ height: "100px!important" }}
                    name="remark"
                    defaultValue={account.remark || ""}
                    onChange={handleChange}
                    {...register("remark", { required: false })}
                  />
                  {errors.remark && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Contract start date"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Contract start date"
                    name="contract_start_date"
                    defaultValue={account.contract_start_date || ""}
                    onChange={handleChange}
                    {...register("contract_start_date", { required: false })}
                  />
                  {errors.contract_start_date && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Master conference reference"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Master conference reference"
                    name="master_conference_reference"
                    defaultValue={account.master_conference_reference || ""}
                    onChange={handleChange}
                    {...register("master_conference_reference", {
                      required: false,
                    })}
                  />
                  {errors.master_conference_reference && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Renewal contract number"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Renewal contract number "
                    name="renewal_contract_number"
                    defaultValue={account.renewal_contract_number || ""}
                    onChange={handleChange}
                    {...register("renewal_contract_number ", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.renewal_contract_number && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Commodity and Contracted Quantity</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Origin"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Origin"
                    name="origin"
                    defaultValue={account.origin || ""}
                    onChange={handleChange}
                    {...register("origin", { required: false })}
                  />
                  {errors.origin && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Commodity"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Commodity"
                    name="commodity"
                    defaultValue={account.commodity || ""}
                    onChange={handleChange}
                    {...register("commodity", { required: false })}
                  />
                  {errors.commodity && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Interlab tolerance"
                  className="mb-3"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Interlab tolerance"
                    name="interlab_tolerance"
                    defaultValue={account.interlab_tolerance || ""}
                    onChange={handleChange}
                    style={{ height: "100px!important" }}
                    {...register("interlab_tolerance", { required: false })}
                  />
                  {errors.interlab_tolerance && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Contract quantity in MT"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Contract quantity in MT"
                    name="contract_quantity_in_MT"
                    defaultValue={account.contract_quantity_in_MT || ""}
                    onChange={handleChange}
                    {...register("contract_quantity_in_MT", {
                      valueAsNumber: true,
                      required: true,
                    })}
                  />
                  {errors.contract_quantity_in_MT && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Market Information</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Destination"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Destination"
                    name="destination"
                    defaultValue={account.destination || ""}
                    onChange={handleChange}
                    {...register("destination", { required: false })}
                  />
                  {errors.destination && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Pricing terms"
                  className="mb-3"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Pricing terms"
                    name="pricing_terms"
                    defaultValue={account.pricing_terms || ""}
                    onChange={handleChange}
                    style={{ height: "100px!important" }}
                    {...register("pricing_terms", { required: false })}
                  />
                  {errors.pricing_terms && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Price and Price Adjustment</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Price adjustment clause"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Price adjustment clause"
                    name="price_adjustment_clause"
                    defaultValue={account.price_adjustment_clause || ""}
                    onChange={handleChange}
                    {...register("price_adjustment_clause", {
                      required: false,
                    })}
                  />
                  {errors.price_adjustment_clause && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Bonus penalty clause"
                  className="mb-3"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Bonus penalty clause"
                    name="bonus_penalty_clause"
                    defaultValue={account.bonus_penalty_clause || ""}
                    onChange={handleChange}
                    {...register("bonus_penalty_clause", { required: false })}
                  />
                  {errors.bonus_penalty_clause && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Payment terms"
                  className="mb-3"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Payment terms"
                    name="payment_terms"
                    defaultValue={account.payment_terms || ""}
                    onChange={handleChange}
                    {...register("payment_terms", { required: false })}
                  />
                  {errors.payment_terms && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="General terms"
                  className="mb-3"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="General terms"
                    name="general_terms"
                    defaultValue={account.general_terms || ""}
                    onChange={handleChange}
                    {...register("general_terms", { required: false })}
                  />
                  {errors.general_terms && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Rejections"
                  className="mb-3"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Rejections"
                    name="rejections"
                    defaultValue={account.rejections || ""}
                    onChange={handleChange}
                    {...register("rejections", { required: false })}
                  />
                  {errors.rejections && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Hs code"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Hs code"
                    name="hs_code"
                    defaultValue={account.hs_code || ""}
                    onChange={handleChange}
                    {...register("hs_code", { required: false })}
                  />
                  {errors.hs_code && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Payment Information</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="LC Type"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="lc_type"
                    placeholder="LC Type"
                    defaultValue={account.lc_type || ""}
                    onChange={handleChange}
                    {...register("lc_type", { required: false })}
                  >
                    <option value="">Select</option>
                    <option value="">--None--</option><option value="Irrevocable transferrable Letter of Credit">Irrevocable transferrable Letter of Credit</option><option value="Irrevocable Non transferrable Letter of Credit">Irrevocable Non transferrable Letter of Credit</option><option value="Irrevocable Letter of Credit">Irrevocable Letter of Credit</option><option value="Irrevocable Back to Back LC">Irrevocable Back to Back LC</option><option value="Payment and Receipt">Payment and Receipt</option><option value="Standby_letter_of_credit_c">Standby Letter of Credit</option>
                  </Form.Select>
                  {errors.lc_type && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Master lc presentation days"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Master lc presentation days"
                    name="master_lc_presentation_days"
                    defaultValue={account.master_lc_presentation_days || ""}
                    onChange={handleChange}
                    {...register("master_lc_presentation_days", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.master_lc_presentation_days && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Lc opening days"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Lc opening days"
                    name="lc_opening_days"
                    defaultValue={account.lc_opening_days || ""}
                    onChange={handleChange}
                    {...register("lc_opening_days", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.lc_opening_days && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Master lc tolerance"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Master lc tolerance"
                    name="master_lc_tolerance"
                    defaultValue={account.master_lc_tolerance || ""}
                    onChange={handleChange}
                    {...register("master_lc_tolerance", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.master_lc_tolerance && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Lc opening days"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Lc opening days"
                    name="lc_opening_days"
                    defaultValue={account.lc_opening_days || ""}
                    onChange={handleChange}
                    {...register("lc_opening_days", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.lc_opening_days && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>{" "}
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Lc opening days in words"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Lc opening days in words"
                    name="lc_opening_days_in_words"
                    defaultValue={account.lc_opening_days_in_words || ""}
                    onChange={handleChange}
                    {...register("lc_opening_days_in_words", {
                      required: false,
                    })}
                  />
                  {errors.lc_opening_days_in_words && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Lc validity no of days"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Lc validity no of days"
                    name="lc_validity_no_of_days"
                    defaultValue={account.lc_validity_no_of_days || ""}
                    onChange={handleChange}
                    {...register("lc_validity_no_of_days", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.lc_validity_no_of_days && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Document presentation period"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Document presentation period"
                    name="document_presentation_period"
                    defaultValue={account.document_presentation_period || ""}
                    onChange={handleChange}
                    {...register("document_presentation_period", {
                      required: false,
                    })}
                  />
                  {errors.document_presentation_period && (
                    <span>Required </span>
                  )}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Latest date of shipment"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Latest date of shipment"
                    name="latest_date_of_shipment"
                    defaultValue={account.latest_date_of_shipment || ""}
                    onChange={handleChange}
                    {...register("latest_date_of_shipment", {
                      required: false,
                    })}
                  />
                  {errors.latest_date_of_shipment && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Loading rate per day"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Loading rate per day"
                    name="loading_rate_per_day"
                    defaultValue={account.loading_rate_per_day || ""}
                    onChange={handleChange}
                    {...register("loading_rate_per_day", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.loading_rate_per_day && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Important Terms Information</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="List of documents required"
                  className="mb-3"
                >
                  <Form.Control
                    as="textarea"
                    style={{ height: "100px!important" }}
                    placeholder="List of documents required"
                    name="list_of_documents_required"
                    defaultValue={account.list_of_documents_required || ""}
                    onChange={handleChange}
                    {...register("list_of_documents_required", {
                      required: false,
                    })}
                  />
                  {errors.list_of_documents_required && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <input type="submit" className="addaccount-save" value="Save" />
            <div className="successDiv">
              <p></p><p></p>
            </div>
          </Form>
          <p></p><p></p>
        </div>
        <Footer />
      </>
    );
  }
  else if (account.contract_record_type === "Contract Buyer Spot") {
    const onSubmit = (data) => {
      const responseData = {
        locked: true,
        currency: data.currency,
        exchange_rate: data.exchange_rate,
        contract_record_type: data.contract_record_type,
        Rwood_Contract_No: data.Rwood_Contract_No,
        account_name: data.account_name,
        contract_status: data.contract_status,
        pending_matters: data.pending_matters,
        remark: data.remark,
        master_conference_reference: data.master_conference_reference,
        // renewal_date: renewalDate,
        renewal_contract_number: data.renewal_contract_number,
        company: data.company,
        trader_name: data.trader_name,
        contract_owner: data.contract_owner,
        price_book: data.price_book,
        supplier: data.supplier,
        status: data.status,
        contract_term_in_months: data.contract_term_in_months,
        // contract_start_date: renewalDate,
        owner_expiration_notice: data.owner_expiration_notice,
        origin: data.origin,
        interlab_tolerance: data.interlab_tolerance,
        commodity: data.commodity,
        contract_quantity_in_MT: data.contract_quantity_in_MT,
        port_of_loading: data.port_of_loading,
        destination: data.destination,
        pricing_terms: data.pricing_terms,
        price_adjust_clause: data.price_adjust_clause,
        bonus_penalty_clause: data.bonus_penalty_clause,
        payment_terms: data.payment_terms,
        general_terms: data.general_terms,
        shipping_city: data.shipping_city,
        hs_code: data.hs_code,
        applicable_law: data.applicable_law,
        arbitration: data.arbitration,
        lc_type: data.lc_type,
        payment_details: data.payment_details,
        master_lc_tolerance: data.master_lc_tolerance,
        lc_validity_no_of_days: data.lc_validity_no_of_days,
        loading_rate_per_day: data.loading_rate_per_day,
        master_lc_presentation_days: data.master_lc_presentation_days,
        lc_opening_days: data.lc_opening_days,
        lc_opening_days_in_words: data.lc_opening_days_in_words,
        work_days_for_time_to_copy_doc_to_buyer:
          data.work_days_for_time_to_copy_doc_to_buyer,
        document_presentation_period: data.document_presentation_period,
        list_of_documents_required: data.list_of_documents_required,
        special_terms: data.special_terms,
        price_PMT: data.price_PMT,
        price_PMT_in_words: data.price_PMT_in_words,
        price_basis: data.price_basis,
        price_type: data.price_type,
        price_basis_GAR: data.price_basis_GAR,
        price_adjustment_clause: data.price_adjustment_clause,
        description: data.description,
        rejections: data.rejections,
        rwood_surveryor_agent: data.rwood_surveryor_agent,
        surveyor_2: data.surveyor_2,
        surveyor_3: data.surveyor_3,
        kind_attention: data.kind_attention,
        customer_signed_by: data.customer_signed_by,
        customer_signed_title: data.customer_signed_title,
        company_signed_by: data.company_signed_by,
        lc_presentation_period: data.lc_presentation_period,
        product_description: data.product_description,
        billing_street: data.billing_street,
        billing_zip_postal_code: data.billing_zip_postal_code,
        billing_city: data.billing_city,
        billing_state_or_province: data.billing_state_or_province,
        billing_country: data.billing_country,
      };
      console.log(JSON.stringify(responseData));
      const encryptedAccessToken = localStorage.getItem("access_token");
      const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
      if (encryptedAccessToken) {
        const decryptedAccessToken = AES.decrypt(
          encryptedAccessToken,
          secret_key
        ).toString(enc.Utf8);

        fetch(`${API_URL}update/contract/` + idUpdate, {
          method: "PUT",
          body: JSON.stringify(responseData),
          headers: {
            Authorization: `Bearer ${decryptedAccessToken}`,
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())

          .then((response) => {
            console.log(response);
            toast.success("Contract Updated Successfully", {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          });
      }
    };
    const handleChange = (e) => {
      const { name, value } = e.target;
      setUpdate((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    };
    return (
      <>
        <Header />
        <div className="contactAccount contracts">
          <div className="page-header" id="add-account">
            <div>
              <h2 className="main-content-title tx-24 mg-b-5">
                Edit Contract Buyer Spot{" "}
              </h2>
              <ol className="breadcrumb">
                <li className="breadcrumb-item active" aria-current="page">
                  Dashboard / Contract / Edit Contract Buyer Spot
                </li>
              </ol>
            </div>
            <div className="d-flex">
              <div className="justify-content-center">
                {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
          <Link to='/accounts/add-record'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</Link>
        </button> */}
              </div>
            </div>
          </div>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className="mb-3">
              <h4 className="heading">Contract Information</h4>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Contract Owner"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Contract Owner"
                    name="contract_owner"
                    defaultValue={account.contract_owner || ""}
                    onChange={handleChange}
                    {...register("contract_owner", { required: false })}
                  />
                  {errors.contract_owner && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Currency"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="currency"
                    placeholder="Currency"
                    defaultValue={account.currency || ""}
                    onChange={handleChange}
                    {...register("currency", { required: false })}
                  >
                    <option value="">Select </option>
                    <option value="">INR </option>
                    <option value="">AED </option>
                    <option value="">USD </option>
                  </Form.Select>
                  {errors.currency && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Trader Name"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="trader_name"
                    placeholder="Trader Name"
                    defaultValue={account.trader_name || ""}
                    onChange={handleChange}
                    {...register("trader_name", { required: false })}
                  >
                    <option value="">Select </option>
                    {traderData.map((x, i) => {
                      return (
                        <option key={i} value={x.rwood_username}>
                          {x.rwood_username}
                        </option>
                      );
                    })}
                  </Form.Select>
                  {errors.trader_name && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Exchange rate"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Exchange rate "
                    name="exchange_rate"
                    defaultValue={account.exchange_rate || ""}
                    onChange={handleChange}
                    {...register("exchange_rate", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.exchange_rate && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Company"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="company"
                    placeholder="Company"
                    defaultValue={account.company || ""}
                    onChange={handleChange}
                    {...register("company", { required: false })}
                  >
                    <option value="">Select </option>
                    {companyData.map((x, i) => {
                      return (
                        <option key={i} value={x.company_name}>
                          {x.company_name}
                        </option>
                      );
                    })}
                  </Form.Select>
                  {errors.company && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Supplier"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="supplier"
                    placeholder="Supplier"
                    defaultValue={account.supplier || ""}
                    onChange={handleChange}
                    {...register("supplier", { required: true })}
                  >
                    <option value="">Select </option>
                    {supplierData.map((x) => {
                      return (
                        <option value={x.account_name}>{x.account_name}</option>
                      );
                    })}
                  </Form.Select>
                  {errors.supplier && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Price book"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Price book"
                    name="price_book"
                    defaultValue={account.price_book || ""}
                    onChange={handleChange}
                    {...register("price_book", { required: false })}
                  />
                  {errors.price_book && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Status"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="status"
                    placeholder="Status"
                    defaultValue={account.status || ""}
                    onChange={handleChange}
                    {...register("status", { required: true })}
                  >
                    <option value="">Select </option>
                    <option value="Draft">Draft </option>
                    <option value="Approved">Approved </option>
                  </Form.Select>
                  {errors.status && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Rwood contract no"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Rwood contract no"
                    name="Rwood_Contract_No"
                    defaultValue={account.Rwood_Contract_No || ""}
                    onChange={handleChange}
                    {...register("Rwood_Contract_No", { required: false })}
                  />
                  {errors.Rwood_Contract_No && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Contract term in months"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Contract term in months"
                    name="contract_term_in_months"
                    defaultValue={account.contract_term_in_months || ""}
                    onChange={handleChange}
                    {...register("contract_term_in_months", {
                      required: true,
                    })}
                  />
                  {errors.contract_term_in_months && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Pending Matters"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Pending Matters"
                    style={{ height: "100px!important" }}
                    name="pending_matters"
                    defaultValue={account.pending_matters || ""}
                    onChange={handleChange}
                    {...register("pending_matters", { required: false })}
                  />
                  {errors.pending_matters && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel controlId="floatingTextarea2" label="Remark">
                  <Form.Control
                    as="textarea"
                    placeholder="Remark"
                    style={{ height: "100px!important" }}
                    name="remark"
                    {...register("remark", { required: false })}
                  />
                  {errors.remark && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Master conference reference"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Master conference reference"
                    name="master_conference_reference"
                    defaultValue={account.master_conference_reference || ""}
                    onChange={handleChange}
                    {...register("master_conference_reference", {
                      required: false,
                    })}
                  />
                  {errors.master_conference_reference && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Renewal contract number"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Renewal contract number "
                    name="renewal_contract_number"
                    defaultValue={account.renewal_contract_number || ""}
                    onChange={handleChange}
                    {...register("renewal_contract_number ", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.renewal_contract_number && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Commodity and Contracted Quantity</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Origin"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Origin"
                    name="origin"
                    defaultValue={account.origin || ""}
                    onChange={handleChange}
                    {...register("origin", { required: false })}
                  />
                  {errors.origin && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Commodity"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Commodity"
                    name="commodity"
                    defaultValue={account.commodity || ""}
                    onChange={handleChange}
                    {...register("commodity", { required: false })}
                  />
                  {errors.commodity && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Interlab tolerance"
                  className="mb-3"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Interlab tolerance"
                    name="interlab_tolerance"
                    defaultValue={account.interlab_tolerance || ""}
                    onChange={handleChange}
                    style={{ height: "100px!important" }}
                    {...register("interlab_tolerance", { required: false })}
                  />
                  {errors.interlab_tolerance && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Market Information</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Port Of Loading"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Port Of Loading"
                    name="port_of_loading"
                    defaultValue={account.port_of_loading || ""}
                    onChange={handleChange}
                    {...register("port_of_loading", { required: false })}
                  />
                  {errors.port_of_loading && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Destination"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Destination"
                    name="destination"
                    defaultValue={account.destination || ""}
                    onChange={handleChange}
                    {...register("destination", { required: false })}
                  />
                  {errors.destination && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Price and Price Adjustment</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Price adjustment clause"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Price adjustment clause"
                    name="price_adjustment_clause"
                    defaultValue={account.price_adjustment_clause || ""}
                    onChange={handleChange}
                    {...register("price_adjustment_clause", {
                      required: true,
                    })}
                  />
                  {errors.price_adjustment_clause && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Bonus penalty clause"
                  className="mb-3"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Bonus penalty clause"
                    name="bonus_penalty_clause"
                    defaultValue={account.bonus_penalty_clause || ""}
                    onChange={handleChange}
                    {...register("bonus_penalty_clause", { required: false })}
                  />
                  {errors.bonus_penalty_clause && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Payment terms"
                  className="mb-3"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Payment terms"
                    name="payment_terms"
                    defaultValue={account.payment_terms || ""}
                    onChange={handleChange}
                    {...register("payment_terms", { required: false })}
                  />
                  {errors.payment_terms && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Rejections"
                  className="mb-3"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Rejections"
                    name="rejections"
                    defaultValue={account.rejections || ""}
                    onChange={handleChange}
                    {...register("rejections", { required: false })}
                  />
                  {errors.rejections && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Hs code"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Hs code"
                    name="hs_code"
                    defaultValue={account.hs_code || ""}
                    onChange={handleChange}
                    {...register("hs_code", { required: false })}
                  />
                  {errors.hs_code && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Pricing Terms"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Pricing Terms"
                    style={{ height: "100px!important" }}
                    name="pricing_terms"
                    defaultValue={account.pricing_terms || ""}
                    onChange={handleChange}
                    {...register("pricing_terms", { required: false })}
                  />
                  {errors.pricing_terms && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Payment Information</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="LC Type"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="lc_type"
                    placeholder="LC Type"
                    defaultValue={account.lc_type || ""}
                    onChange={handleChange}
                    {...register("lc_type", { required: false })}
                  >
                    <option value="">--None--</option><option value="Irrevocable transferrable Letter of Credit">Irrevocable transferrable Letter of Credit</option><option value="Irrevocable Non transferrable Letter of Credit">Irrevocable Non transferrable Letter of Credit</option><option value="Irrevocable Letter of Credit">Irrevocable Letter of Credit</option><option value="Irrevocable Back to Back LC">Irrevocable Back to Back LC</option><option value="Payment and Receipt">Payment and Receipt</option><option value="Standby_letter_of_credit_c">Standby Letter of Credit</option>
                  </Form.Select>
                  {errors.lc_type && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Master lc presentation days"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Master lc presentation days"
                    name="master_lc_presentation_days"
                    defaultValue={account.master_lc_presentation_days || ""}
                    onChange={handleChange}
                    {...register("master_lc_presentation_days", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.master_lc_presentation_days && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Payment Details"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Master lc presentation days"
                    name="payment_details"
                    defaultValue={account.payment_details || ""}
                    onChange={handleChange}
                    {...register("payment_details", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.payment_details && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Lc opening days"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Lc opening days"
                    name="lc_opening_days"
                    defaultValue={account.lc_opening_days || ""}
                    onChange={handleChange}
                    {...register("lc_opening_days", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.lc_opening_days && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Master lc tolerance"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Master lc tolerance"
                    name="master_lc_tolerance"
                    defaultValue={account.master_lc_tolerance || ""}
                    onChange={handleChange}
                    {...register("master_lc_tolerance", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.master_lc_tolerance && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Lc opening days"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Lc opening days"
                    name="lc_opening_days"
                    defaultValue={account.lc_opening_days || ""}
                    onChange={handleChange}
                    {...register("lc_opening_days", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.lc_opening_days && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>{" "}
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Lc opening days in words"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Lc opening days in words"
                    name="lc_opening_days_in_words"
                    defaultValue={account.lc_opening_days_in_words || ""}
                    onChange={handleChange}
                    {...register("lc_opening_days_in_words", {
                      required: false,
                    })}
                  />
                  {errors.lc_opening_days_in_words && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Lc validity no of days"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Lc validity no of days"
                    name="lc_validity_no_of_days"
                    defaultValue={account.lc_validity_no_of_days || ""}
                    onChange={handleChange}
                    {...register("lc_validity_no_of_days", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.lc_validity_no_of_days && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Document presentation period"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Document presentation period"
                    name="document_presentation_period"
                    defaultValue={account.document_presentation_period || ""}
                    onChange={handleChange}
                    {...register("document_presentation_period", {
                      required: false,
                    })}
                  />
                  {errors.document_presentation_period && (
                    <span>Required </span>
                  )}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Latest date of shipment"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Latest date of shipment"
                    name="latest_date_of_shipment"
                    defaultValue={account.latest_date_of_shipment || ""}
                    onChange={handleChange}
                    {...register("latest_date_of_shipment", {
                      required: false,
                    })}
                  />
                  {errors.latest_date_of_shipment && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Loading rate per day"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Loading rate per day"
                    name="loading_rate_per_day"
                    defaultValue={account.loading_rate_per_day || ""}
                    onChange={handleChange}
                    {...register("loading_rate_per_day", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.loading_rate_per_day && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Important Terms Information</h4>
              <Form.Group as={Col} md="12">
                <FloatingLabel
                  controlId="floatingInput"
                  label="List of documents required"
                  className="mb-3"
                >
                  <Form.Control
                    as="textarea"
                    style={{ height: "100px!important" }}
                    placeholder="List of documents required"
                    name="list_of_documents_required"
                    defaultValue={account.list_of_documents_required || ""}
                    onChange={handleChange}
                    {...register("list_of_documents_required", {
                      required: false,
                    })}
                  />
                  {errors.list_of_documents_required && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <h4 className="heading">Signatre Information</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Kind attention"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="kind_attention"
                    placeholder="Kind attention"
                    defaultValue={account.kind_attention || ""}
                    onChange={handleChange}
                    {...register("kind_attention", { required: false })}
                  >
                    <option value="">Select </option>
                    {contacts.map((x) => {
                      return (
                        <option value={x.contact_full_name}>
                          {x.contact_full_name}
                        </option>
                      );
                    })}
                  </Form.Select>
                  {errors.kind_attention && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Customer signed by"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="customer_signed_by"
                    placeholder="Customer signed by"
                    defaultValue={account.customer_signed_by || ""}
                    onChange={handleChange}
                    {...register("customer_signed_by", { required: false })}
                  >
                    <option value="">Select </option>
                    {contacts.map((x) => {
                      return (
                        <option value={x.contact_full_name}>
                          {x.contact_full_name}
                        </option>
                      );
                    })}
                  </Form.Select>
                  {errors.customer_signed_by && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Customer signed title"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Customer signed title"
                    name="customer_signed_title"
                    defaultValue={account.customer_signed_title || ""}
                    onChange={handleChange}
                    {...register("customer_signed_title", { required: false })}
                  />
                  {errors.customer_signed_title && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Company signed by"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="company_signed_by"
                    placeholder="Company signed by"
                    defaultValue={account.latest_date_of_shipment || ""}
                    onChange={handleChange}
                    {...register("company_signed_by", { required: false })}
                  >
                    <option value="">Select </option>
                    {users.map((x) => {
                      return <option value={x.username}>{x.username}</option>;
                    })}
                  </Form.Select>
                  {errors.username && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Original contract recieved date"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Original contract recieved date"
                    name="original_contract_recieved_date"
                    defaultValue={account.original_contract_recieved_date || ""}
                    onChange={handleChange}
                    {...register("original_contract_recieved_date", {
                      required: false,
                    })}
                  />
                  {errors.original_contract_recieved_date && (
                    <span>Required </span>
                  )}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Company signed date"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Company signed date"
                    name="company_signed_date"
                    defaultValue={account.company_signed_date || ""}
                    onChange={handleChange}
                    {...register("company_signed_date", { required: false })}
                  />
                  {errors.company_signed_date && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Original contract sent date"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Original contract sent date"
                    name="original_contract_sent_date"
                    defaultValue={account.original_contract_sent_date || ""}
                    onChange={handleChange}
                    {...register("original_contract_sent_date", {
                      required: false,
                    })}
                  />
                  {errors.original_contract_sent_date && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Other Standard Terms</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Applicable law"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Applicable law"
                    name="applicable_law"
                    defaultValue={account.applicable_law || ""}
                    onChange={handleChange}
                    {...register("applicable_law", { required: false })}
                  />
                  {errors.applicable_law && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Rwood surveryor agent"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="rwood_surveryor_agent"
                    placeholder="Rwood surveryor agent"
                    defaultValue={account.rwood_surveryor_agent || ""}
                    onChange={handleChange}
                    {...register("rwood_surveryor_agent", { required: false })}
                  >
                    <option value="">Select </option>
                    {surveyors.map((x) => {
                      return (
                        <option value={x.account_name}>{x.account_name}</option>
                      );
                    })}
                  </Form.Select>
                  {errors.rwood_surveryor_agent && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Arbitration"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Arbitration"
                    name="arbitration"
                    defaultValue={account.arbitration || ""}
                    onChange={handleChange}
                    {...register("arbitration", { required: false })}
                  />
                  {errors.arbitration && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Surveyor 1"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="surveyor_1"
                    placeholder="Surveyor 1"
                    defaultValue={account.surveyor_1 || ""}
                    onChange={handleChange}
                    {...register("surveyor_1", { required: false })}
                  >
                    <option value="">Select </option>
                    {surveyors.map((x) => {
                      return (
                        <option value={x.account_name}>{x.account_name}</option>
                      );
                    })}
                  </Form.Select>
                  {errors.surveyor_1 && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Surveyor 2"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="surveyor_2"
                    placeholder="Surveyor 2"
                    defaultValue={account.surveyor_2 || ""}
                    onChange={handleChange}
                    {...register("surveyor_2", { required: false })}
                  >
                    <option value="">Select </option>
                    {surveyors.map((x) => {
                      return (
                        <option value={x.account_name}>{x.account_name}</option>
                      );
                    })}
                  </Form.Select>
                  {errors.surveyor_2 && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">List Of Fields-Not Required</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Product description"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Product description"
                    style={{ height: "100px" }}
                    name="product_description"
                    defaultValue={account.product_description || ""}
                    onChange={handleChange}
                    {...register("product_description", { required: false })}
                  />
                  {errors.product_description && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Description"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Description"
                    style={{ height: "100px" }}
                    name="description"
                    defaultValue={account.description || ""}
                    onChange={handleChange}
                    {...register("description", { required: false })}
                  />
                  {errors.description && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <input type="submit" className="addaccount-save" value="Save" />
            <div className="successDiv">
              <p></p><p></p>
            </div>
          </Form>
          <p></p><p></p>
        </div>
        <Footer />
      </>
    );
  }
  else if (account.contract_record_type === "Contract Supplier Long Term") {
    const onSubmit = (data) => {
      const responseData = {
        locked: true,
        currency: data.currency,
        exchange_rate: data.exchange_rate,
        contract_record_type: data.contract_record_type,
        Rwood_Contract_No: data.Rwood_Contract_No,
        account_name: data.account_name,
        contract_status: data.contract_status,
        pending_matters: data.pending_matters,
        remark: data.remark,
        master_conference_reference: data.master_conference_reference,
        //renewal_date: renewalDate,
        renewal_contract_number: data.renewal_contract_number,
        company: data.company,
        trader_name: data.trader_name,
        contract_owner: data.contract_owner,
        price_book: data.price_book,
        supplier: data.supplier,
        status: data.status,
        contract_term_in_months: data.contract_term_in_months,
        //contract_start_date: renewalDate,
        owner_expiration_notice: data.owner_expiration_notice,
        origin: data.origin,
        interlab_tolerance: data.interlab_tolerance,
        commodity: data.commodity,
        contract_quantity_in_MT: data.contract_quantity_in_MT,
        port_of_loading: data.port_of_loading,
        destination: data.destination,
        pricing_terms: data.pricing_terms,
        price_adjust_clause: data.price_adjust_clause,
        bonus_penalty_clause: data.bonus_penalty_clause,
        payment_terms: data.payment_terms,
        general_terms: data.general_terms,
        shipping_city: data.shipping_city,
        hs_code: data.hs_code,
        applicable_law: data.applicable_law,
        arbitration: data.arbitration,
        lc_type: data.lc_type,
        payment_details: data.payment_details,
        master_lc_tolerance: data.master_lc_tolerance,
        lc_validity_no_of_days: data.lc_validity_no_of_days,
        loading_rate_per_day: data.loading_rate_per_day,
        master_lc_presentation_days: data.master_lc_presentation_days,
        lc_opening_days: data.lc_opening_days,
        lc_opening_days_in_words: data.lc_opening_days_in_words,
        work_days_for_time_to_copy_doc_to_buyer:
          data.work_days_for_time_to_copy_doc_to_buyer,
        document_presentation_period: data.document_presentation_period,
        list_of_documents_required: data.list_of_documents_required,
        special_terms: data.special_terms,
        price_PMT: data.price_PMT,
        price_PMT_in_words: data.price_PMT_in_words,
        price_basis: data.price_basis,
        price_type: data.price_type,
        price_basis_GAR: data.price_basis_GAR,
        price_adjustment_clause: data.price_adjustment_clause,
        description: data.description,
        rejections: data.rejections,
        rwood_surveryor_agent: data.rwood_surveryor_agent,
        surveyor_2: data.surveyor_2,
        surveyor_3: data.surveyor_3,
        kind_attention: data.kind_attention,
        customer_signed_by: data.customer_signed_by,
        customer_signed_title: data.customer_signed_title,
        company_signed_by: data.company_signed_by,
        lc_presentation_period: data.lc_presentation_period,
        product_description: data.product_description,
        billing_street: data.billing_street,
        billing_zip_postal_code: data.billing_zip_postal_code,
        billing_city: data.billing_city,
        billing_state_or_province: data.billing_state_or_province,
        billing_country: data.billing_country,
      };
      console.log(JSON.stringify(responseData));
      const encryptedAccessToken = localStorage.getItem("access_token");
      const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
      if (encryptedAccessToken) {
        const decryptedAccessToken = AES.decrypt(
          encryptedAccessToken,
          secret_key
        ).toString(enc.Utf8);

        fetch(`${API_URL}add/contract`, {
          method: "POST",
          body: JSON.stringify(responseData),
          headers: {
            Authorization: `Bearer ${decryptedAccessToken}`,
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())

          .then((response) => {
            console.log(response);
            toast.success("Contract Updated Successfully", {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });

          });
      }
    };
const handleChange = (e) => {
      const { name, value } = e.target;
      setUpdate((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    };
    return (
      <>
        <Header />
        <div className="contactAccount contracts">
          <div className="page-header" id="add-account">
            <div>
              <h2 className="main-content-title tx-24 mg-b-5">
                Edit Contract Supplier Long Term{" "}
              </h2>
              <ol className="breadcrumb">
                <li className="breadcrumb-item active" aria-current="page">
                  Dashboard / Contract / Edit Contract Supplier Long Term
                </li>
              </ol>
            </div>
            <div className="d-flex">
              <div className="justify-content-center">
                {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
          <Link to='/accounts/add-record'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</Link>
        </button> */}
              </div>
            </div>
          </div>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className="mb-3">
              <h4 className="heading">Contract Information</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Currency"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Currency"
                    name="currency"
                    defaultValue={account.currency || ""}
                    onChange={handleChange}
                    {...register("currency", { required: false })}
                  />
                  {errors.currency && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Exchange rate"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Exchange rate "
                    name="exchange_rate"
                    defaultValue={account.exchange_rate || ""}
                    onChange={handleChange}
                    {...register("exchange_rate", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.exchange_rate && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Status"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="status"
                    placeholder="Status"
                    defaultValue={account.status || ""}
                    onChange={handleChange}
                    {...register("status", { required: false })}
                  >
                    <option value="">Select </option>
                    <option value="Draft">Draft </option>
                    <option value="Approved">Approved </option>
                  </Form.Select>
                  {errors.status && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Account Name"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="account_name"
                    placeholder="Account Name"
                    defaultValue={account.account_name || ""}
                    onChange={handleChange}
                    {...register("account_name", { required: false })}
                  >
                    <option value="">Select </option>
                    {supplierData.map((x) => {
                      return (
                        <option value={x.account_name}>{x.account_name}</option>
                      );
                    })}
                  </Form.Select>
                  {errors.account_name && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Contract record type"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Contract record type"
                    defaultValue={account.contract_record_type || ""}
                    onChange={handleChange}
                    name="contract_record_type"
                    {...register("contract_record_type", { required: false })}
                  />
                  {errors.contract_record_type && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Contract Owner"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Contract Owner"
                    name="contract_owner"
                    defaultValue={account.contract_owner || ""}
                    onChange={handleChange}
                    {...register("contract_owner", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.contract_owner && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Contract start date"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Contract start date"
                    name="contract_start_date"
                    defaultValue={account.contract_start_date || ""}
                    onChange={handleChange}
                    {...register("contract_start_date", { required: false })}
                  />
                  {errors.contract_start_date && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Owner Expiration Notice"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="owner_expiration_notice"
                    placeholder="Owner Expiration Notice"
                    defaultValue={account.owner_expiration_notice || ""}
                    onChange={handleChange}
                    {...register("owner_expiration_notice", {
                      required: false,
                    })}
                  >
                    <option value="">Select </option>
                    <option value="test1">test1 </option>
                    <option value="test2">test2 </option>
                  </Form.Select>
                  {errors.owner_expiration_notice && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Contract effective date"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Contract effective date"
                    name="contract_effective_date"
                    defaultValue={account.contract_effective_date || ""}
                    onChange={handleChange}
                    {...register("contract_effective_date", {
                      required: false,
                    })}
                  />
                  {errors.contract_effective_date && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Contract term in months"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Contract term in months"
                    name="contract_term_in_months"
                    defaultValue={account.contract_term_in_months || ""}
                    onChange={handleChange}
                    {...register("contract_term_in_months", {
                      required: false,
                    })}
                  />
                  {errors.contract_term_in_months && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Contract Status"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="contract_status"
                    placeholder="Contract Status"
                    defaultValue={account.contract_status || ""}
                    onChange={handleChange}
                    {...register("contract_status", { required: false })}
                  >
                    <option value="">Select </option>
                    <option value="Draft">Draft </option>
                    <option value="Approved">Approved </option>
                  </Form.Select>
                  {errors.contract_status && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Pending Matters"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Pending Matters"
                    style={{ height: "100px!important" }}
                    name="pending_matters"
                    defaultValue={account.pending_matters || ""}
                    onChange={handleChange}
                    {...register("pending_matters", { required: false })}
                  />
                  {errors.pending_matters && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Trader Name"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="trader_name"
                    placeholder="Trader Name"
                    defaultValue={account.trader_name || ""}
                    onChange={handleChange}
                    {...register("trader_name", { required: false })}
                  >
                    <option value="">Select </option>
                    {traderData.map((x, i) => {
                      return (
                        <option key={i} value={x.rwood_username}>
                          {x.rwood_username}
                        </option>
                      );
                    })}
                  </Form.Select>
                  {errors.trader_name && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Master Contract Reference"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Master Contract Reference"
                    name="master_conference_reference"
                    defaultValue={account.master_conference_reference || ""}
                    onChange={handleChange}
                    {...register("master_conference_reference", {
                      required: false,
                    })}
                  />
                  {errors.master_conference_reference && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Company"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="company"
                    placeholder="Company"
                    defaultValue={account.company || ""}
                    onChange={handleChange}
                    {...register("company", { required: false })}
                  >
                    <option value="">Select </option>
                    {companyData.map((x, i) => {
                      return (
                        <option key={i} value={x.company_name}>
                          {x.company_name}
                        </option>
                      );
                    })}
                  </Form.Select>
                  {errors.company && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
               <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Renewal contract number"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Renewal contract number "
                    name="renewal_contract_number "
               defaultValue={account.renewal_contract_number || ""}
                    onChange={handleChange}
                    {...register("renewal_contract_number ", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.renewal_contract_number && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel controlId="floatingTextarea2" label="Remark">
                  <Form.Control
                    as="textarea"
                    placeholder="Remark"
                    style={{ height: "100px!important" }}
                    name="remark"
                    defaultValue={account.remark || ""}
                    onChange={handleChange}
                    {...register("remark", { required: false })}
                  />
                  {errors.remark && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <h4 className="heading">Commodity and Contracted Quantity</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Port Of Loading"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Port Of Loading"
                    name="port_of_loading"
                    defaultValue={account.port_of_loading || ""}
                    onChange={handleChange}
                    {...register("port_of_loading", { required: false })}
                  />
                  {errors.port_of_loading && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Origin"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Origin"
                    name="origin"
                    defaultValue={account.origin || ""}
                    onChange={handleChange}
                    {...register("origin", { required: false })}
                  />
                  {errors.origin && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Contract quantity in MT"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Contract quantity in MT"
                    name="contract_quantity_in_MT"
                    defaultValue={account.contract_quantity_in_MT || ""}
                    onChange={handleChange}
                    {...register("contract_quantity_in_MT", {
                      valueAsNumber: true,
                      required: true,
                    })}
                  />
                  {errors.contract_quantity_in_MT && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Commodity"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Commodity"
                    name="commodity"
                    defaultValue={account.commodity || ""}
                    onChange={handleChange}
                    {...register("commodity", { required: false })}
                  />
                  {errors.commodity && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Price and Price Adjustment</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Price PMT"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Price PMT"
                    name="price_PMT"
                    defaultValue={account.price_PMT || ""}
                    onChange={handleChange}
                    {...register("price_PMT", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.price_PMT && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Price book"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Price book"
                    name="price_book"
                    defaultValue={account.price_book || ""}
                    onChange={handleChange}
                    {...register("price_book", { required: false })}
                  />
                  {errors.price_book && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Price PMT in words"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Price PMT in words"
                    name="price_PMT_in_words"
                    defaultValue={account.price_PMT_in_words || ""}
                    onChange={handleChange}
                    {...register("price_PMT_in_words", { required: false })}
                  />
                  {errors.price_PMT_in_words && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Price basis"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Price basis"
                    name="price_basis"
                    defaultValue={account.price_basis || ""}
                    onChange={handleChange}
                    {...register("price_basis", { required: false })}
                  />
                  {errors.price_basis && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Price type"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="price_type"
                    placeholder="Price type"
                    defaultValue={account.price_type || ""}
                    onChange={handleChange}
                    {...register("price_type", { required: false })}
                  >
                    <option value="">Select</option>
                    {getPrice.map((x) => {
                      return (
                        <option value={x.price_type_name}>
                          {x.price_type_name}
                        </option>
                      );
                    })}
                  </Form.Select>
                  {errors.price_type && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Price basis GAR"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Price basis GAR"
                    name="price_basis_GAR"
                    defaultValue={account.price_basis_GAR || ""}
                    onChange={handleChange}
                    {...register("price_basis_GAR", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.price_basis_GAR && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Price adjustment clause"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Price adjustment clause"
                    name="price_adjustment_clause"
                    defaultValue={account.price_adjustment_clause || ""}
                    onChange={handleChange}
                    {...register("price_adjustment_clause", {
                      required: false,
                    })}
                  />
                  {errors.price_adjustment_clause && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Rejections"
                  className="mb-3"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Rejections"
                    name="rejections"
                    defaultValue={account.rejections || ""}
                    onChange={handleChange}
                    {...register("rejections", { required: false })}
                  />
                  {errors.rejections && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Description"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Description"
                    style={{ height: "100px" }}
                    name="description"
                    defaultValue={account.description || ""}
                    onChange={handleChange}
                    {...register("description", { required: false })}
                  />
                  {errors.description && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Pricing Terms"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Pricing Terms"
                    style={{ height: "100px!important" }}
                    name="pricing_terms"

                    defaultValue={account.pricing_terms || ""}
                    onChange={handleChange}
                    {...register("pricing_terms", { required: false })}
                  />
                  {errors.pricing_terms && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Other Standard Term From Supplier</h4>

              <Form.Group as={Col} md="12">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Payment Terms"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Payment Terms"
                    style={{ height: "100px!important" }}
                    name="payment_terms"
                    defaultValue={account.payment_terms || ""}
                    onChange={handleChange}
                    {...register("payment_terms", { required: false })}
                  />
                  {errors.payment_terms && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Applicable law"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Applicable law"
                    name="applicable_law"

                    defaultValue={account.applicable_law || ""}
                    onChange={handleChange}
                    {...register("applicable_law", { required: false })}
                  />
                  {errors.applicable_law && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Arbitration"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Arbitration"
                    name="arbitration"
                    defaultValue={account.arbitration || ""}
                    onChange={handleChange}
                    {...register("arbitration", { required: false })}
                  />
                  {errors.arbitration && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="General terms"
                  className="mb-3"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="General terms"
                    name="general_terms"
                    defaultValue={account.general_terms || ""}
                    onChange={handleChange}
                    {...register("general_terms", { required: false })}
                  />
                  {errors.general_terms && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Description Information</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Loading rate per day"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Loading rate per day"
                    name="loading_rate_per_day"
                    defaultValue={account.loading_rate_per_day || ""}
                    onChange={handleChange}


                    {...register("loading_rate_per_day", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.loading_rate_per_day && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Special Terms"
                  className="mb-3"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Special Terms"
                    name="special_terms"
                    defaultValue={account.special_terms || ""}
                    onChange={handleChange}
                    {...register("special_terms", { required: false })}
                  />
                  {errors.special_terms && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Bonus penalty clause"
                  className="mb-3"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Bonus penalty clause"
                    name="bonus_penalty_clause"
                    defaultValue={account.bonus_penalty_clause || ""}
                    onChange={handleChange}
                    {...register("bonus_penalty_clause", { required: false })}
                  />
                  {errors.bonus_penalty_clause && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <h4 className="heading">Description Information</h4>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing street"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing street"
                    name="billing_street"
                    defaultValue={account.billing_street || ""}
                    onChange={handleChange}
                    {...register("billing_street", { required: false })}
                  />
                  {errors.billing_street && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping street"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Shipping street"
                    name="shipping_street"
                    defaultValue={account.shipping_street || ""}
                    onChange={handleChange}
                    {...register("shipping_street", { required: false })}
                  />
                  {errors.shipping_street && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing zip postal code"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing zip postal code"
                    name="billing_zip_postal_code"

                    defaultValue={account.billing_zip_postal_code || ""}
                    onChange={handleChange}
                    {...register("billing_zip_postal_code", {
                      required: false,
                    })}
                  />
                  {errors.billing_zip_postal_code && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping zip postal code"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Shipping zip postal code"
                    name="shipping_zip_postal_code"
                    defaultValue={account.shipping_zip_postal_code || ""}
                    onChange={handleChange}
                    {...register("shipping_zip_postal_code", {
                      required: false,
                    })}
                  />
                  {errors.shipping_zip_postal_code && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing city"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing city"
                    name="billing_city"
                    defaultValue={account.billing_city || ""}
                    onChange={handleChange}
                    {...register("billing_city", { required: false })}
                  />
                  {errors.billing_city && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping city"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Shipping city"
                    name="shipping_city"

                    defaultValue={account.shipping_city || ""}
                    onChange={handleChange}
                    {...register("shipping_city", { required: false })}
                  />
                  {errors.shipping_city && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing state or province"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing state or province"
                    name="billing_state_or_province"
                    defaultValue={account.billing_state_or_province || ""}
                    onChange={handleChange}
                    {...register("billing_state_or_province", {
                      required: false,
                    })}
                  />
                  {errors.billing_state_or_province && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping state or province"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Shipping state or province"
                    name="shipping_state_or_province"
                    defaultValue={account.shipping_state_or_province || ""}
                    onChange={handleChange}
                    {...register("shipping_state_or_province", {
                      required: false,
                    })}
                  />
                  {errors.shipping_state_or_province && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Billing country"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Billing country"
                    name="billing_country"
                    defaultValue={account.billing_country || ""}
                    onChange={handleChange}
                    {...register("billing_country", { required: false })}
                  />
                  {errors.billing_country && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Shipping country"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Shipping country"
                    name="shipping_country"
                    defaultValue={account.shipping_country || ""}
                    onChange={handleChange}
                    {...register("shipping_country", { required: false })}
                  />
                  {errors.shipping_country && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <h4 className="heading">Signatre Information</h4>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Customer signed by"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="customer_signed_by"
                    placeholder="Customer signed by"
                    defaultValue={account.customer_signed_by || ""}
                    onChange={handleChange}
                    {...register("customer_signed_by", { required: false })}
                  >
                    <option value="">Select </option>
                    {contacts.map((x) => {
                      return (
                        <option value={x.contact_full_name}>
                          {x.contact_full_name}
                        </option>
                      );
                    })}
                  </Form.Select>
                  {errors.customer_signed_by && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Customer signed title"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Customer signed title"
                    name="customer_signed_title"
                   defaultValue={account.customer_signed_title || ""}
                    onChange={handleChange}

                    {...register("customer_signed_title", { required: false })}
                  />
                  {errors.customer_signed_title && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Company signed by"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="company_signed_by"
                    placeholder="Company signed by"

                   defaultValue={account.company_signed_by || ""}
                    onChange={handleChange}
                    {...register("company_signed_by", { required: false })}
                  >
                    <option value="">Select </option>
                    {users.map((x) => {
                      return <option value={x.username}>{x.username}</option>;
                    })}
                  </Form.Select>
                  {errors.username && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Original contract recieved date"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Original contract recieved date"
                    name="original_contract_recieved_date"
                   defaultValue={account.original_contract_recieved_date || ""}
                    onChange={handleChange}
                    {...register("original_contract_recieved_date", {
                      required: false,
                    })}
                  />
                  {errors.original_contract_recieved_date && (
                    <span>Required </span>
                  )}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Company signed date"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Company signed date"
                    name="company_signed_date"
                    defaultValue={account.company_signed_date || ""}
                    onChange={handleChange}
                    {...register("company_signed_date", { required: false })}
                  />
                  {errors.company_signed_date && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Original contract sent date"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Original contract sent date"
                    name="original_contract_sent_date"
                    defaultValue={account.original_contract_sent_date || ""}
                    onChange={handleChange}
                    {...register("original_contract_sent_date", {
                      required: false,
                    })}
                  />
                  {errors.original_contract_sent_date && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
            </Row>

            <input type="submit" className="addaccount-save" value="Save" />
            <div className="successDiv">
              <p></p><p></p>
            </div>
          </Form>
          <p></p><p></p>
        </div>
        <Footer />
      </>
    );
  }
}

export default EditContract;
