import React, { useState, useMemo, useEffect, forwardRef } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import Header from '../Header'
import Footer from '../Footer'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import { AES, enc } from "crypto-js";
import { API_URL } from '../config';
import $ from 'jquery';
// import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
function AddOrder() {
  const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
  const encryptedAccessToken = localStorage.getItem("access_token");
  const decryptedAccessToken = encryptedAccessToken
    ? AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8)
    : null;
  const [suppliers, setSupplier]=useState([]);
  useEffect(() => {

    fetchData5();
  }, [decryptedAccessToken]);

  const fetchData5 = async () => {
    try {
      const response = await axios.get(API_URL+'get/supplier', {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      const data = response.data;
      console.log(data)
      setSupplier(data);

    } catch (error) {
      console.error(error);
    }
  };
  // const body=$(window).height()
  // const header=$("#header").height()
  // const nav=$(".navbar").height()
  // const footer=$(".footer").height()
  // const total_height=header+nav+footer;
  // const window_height=body-total_height;
  // $(".priceAccount div:nth-child(3)").css({"min-height":(window_height)+"px"})
  const [datas,setDatas]=useState([]);
  const [agents,setAgents]=useState([]);
  const [contractNumber, setContractNumber]=useState([]);
  const [company,setCompany]=useState([]);
  const [accountNames, setAccountName]=useState([]);
  const [agent, setAgent]=useState([]);

  const [startDate1, setStartDate1] = useState(new Date());
  const datepick1 =
    startDate1.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    startDate1.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    startDate1.toLocaleDateString("en-US", { day: "2-digit" });
  const CustomInput1 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));
  const [startDate2, setStartDate2] = useState(new Date());
  const datepick2 =
    startDate2.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    startDate1.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    startDate1.toLocaleDateString("en-US", { day: "2-digit" });
  const CustomInput2 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));

  const [startDate3, setStartDate3] = useState(new Date());
  const datepick3 =
    startDate3.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    startDate1.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    startDate1.toLocaleDateString("en-US", { day: "2-digit" });
  const CustomInput3 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));

  const [startDate4, setStartDate4] = useState(new Date());
  const datepick4 =
    startDate4.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    startDate1.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    startDate1.toLocaleDateString("en-US", { day: "2-digit" });
  const CustomInput4 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));

  const [startDate5, setStartDate5] = useState(new Date());
  const datepick5 =
    startDate5.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    startDate5.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    startDate5.toLocaleDateString("en-US", { day: "2-digit" });
  const CustomInput5 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));
  const [startDate6, setStartDate6] = useState(new Date());
  const datepick6 =
    startDate6.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    startDate6.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    startDate6.toLocaleDateString("en-US", { day: "2-digit" });
  const CustomInput6 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));
  const [startDate7, setStartDate7] = useState(new Date());
  const datepick7 =
    startDate7.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    startDate7.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    startDate7.toLocaleDateString("en-US", { day: "2-digit" });
  const CustomInput7 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));
  const [startDate8, setStartDate8] = useState(new Date());
  const datepick8 =
    startDate8.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    startDate8.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    startDate8.toLocaleDateString("en-US", { day: "2-digit" });
  const CustomInput8 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));
  const [startDate9, setStartDate9] = useState(new Date());
  const datepick9 =
    startDate9.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    startDate9.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    startDate9.toLocaleDateString("en-US", { day: "2-digit" });
  const CustomInput9 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));
const [startDate10, setStartDate10] = useState(new Date());
  const datepick10 =
    startDate10.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    startDate10.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    startDate10.toLocaleDateString("en-US", { day: "2-digit" });
  const CustomInput10 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));

const [startDate11, setStartDate11] = useState(new Date());
  const datepick11 =
    startDate11.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    startDate11.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    startDate11.toLocaleDateString("en-US", { day: "2-digit" });
  const CustomInput11 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));

const [startDate12, setStartDate12] = useState(new Date());
  const datepick12 =
    startDate12.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    startDate12.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    startDate12.toLocaleDateString("en-US", { day: "2-digit" }) +
    startDate12.toLocaleTimeString('en-US',' ');
  const CustomInput12 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));
const [startDate13, setStartDate13] = useState(new Date());
  const datepick13 =
    startDate13.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    startDate13.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    startDate13.toLocaleDateString("en-US", { day: "2-digit" });
    // startDate13.toLocaleTimeString('en-US',' ');
  const CustomInput13 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));
  const [startDate14, setStartDate14] = useState(new Date());
  const datepick14 =
    startDate14.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    startDate14.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    startDate14.toLocaleDateString("en-US", { day: "2-digit" }) +
    startDate14.toLocaleTimeString('en-US',' ');
  const CustomInput14 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));
const [startDate15, setStartDate15] = useState(new Date());
  const datepick15 =
    startDate15.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    startDate15.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    startDate15.toLocaleDateString("en-US", { day: "2-digit" }) +" "+
    startDate15.toLocaleTimeString('en-US',' ');
  const CustomInput15 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));
const [startDate16, setStartDate16] = useState(new Date());
  const datepick16 =
    startDate16.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    startDate16.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    startDate16.toLocaleDateString("en-US", { day: "2-digit" }) +
    startDate16.toLocaleTimeString('en-US',' ');
  const CustomInput16 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));

const [startDate17, setStartDate17] = useState(new Date());
  const datepick17 =
    startDate17.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    startDate17.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    startDate17.toLocaleDateString("en-US", { day: "2-digit" }) +
    startDate17.toLocaleTimeString('en-US',' ');
  const CustomInput17 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));

const [startDate18, setStartDate18] = useState(new Date());
  const datepick18 =
    startDate18.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    startDate18.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    startDate18.toLocaleDateString("en-US", { day: "2-digit" }) +
    startDate18.toLocaleTimeString('en-US',' ');
  const CustomInput18 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));
const [startDate19, setStartDate19] = useState(new Date());
  const datepick19 =
    startDate19.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    startDate19.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    startDate19.toLocaleDateString("en-US", { day: "2-digit" }) +
    startDate19.toLocaleTimeString('en-US',' ');
  const CustomInput19 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));

  const [orderStart, setOrderStart] = useState(new Date());
  const orderstartDate =
  orderStart.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    orderStart.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    orderStart.toLocaleDateString("en-US", { day: "2-digit" });
  const CustomOrder = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));

  const [confirmDate, setConfirmDate] = useState(new Date());
  const confirmDates =
  confirmDate.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    confirmDate.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    confirmDate.toLocaleDateString("en-US", { day: "2-digit" });
  const CustomConfirmDate = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));
  const [shippingDate, setShippingDate] = useState(new Date());
  const shipping_date =
  shippingDate.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    shippingDate.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    shippingDate.toLocaleDateString("en-US", { day: "2-digit" });
  const Customshipping = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));

  const [eta1, setEta1] = useState(new Date());
  const etaDate1 =
  eta1.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    eta1.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    eta1.toLocaleDateString("en-US", { day: "2-digit" });
  const CustomEta1 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));



  const [eta2, setEta2] = useState(new Date());
  const etaDate2 =
  eta2.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    eta2.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    eta2.toLocaleDateString("en-US", { day: "2-digit" });
  const CustomEta2 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));



const [eta3, setEta3] = useState(new Date());
  const etaDate3 =
  eta3.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    eta3.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    eta3.toLocaleDateString("en-US", { day: "2-digit" });
  const CustomEta3 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));



const [eta4, setEta4] = useState(new Date());
  const etaDate4=
  eta4.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    eta4.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    eta4.toLocaleDateString("en-US", { day: "2-digit" });
  const CustomEta4 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));


const [eta5, setEta5] = useState(new Date());
  const etaDate5 =
  eta5.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    eta5.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    eta5.toLocaleDateString("en-US", { day: "2-digit" });
  const CustomEta5 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));
  const [confirmDate1, setConfirmDate1] = useState(new Date());
  const confirmDates1 =
  confirmDate1.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    confirmDate1.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    confirmDate1.toLocaleDateString("en-US", { day: "2-digit" });
  const CustomConfirmDate1 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));
  const [laycantoDate, setLaycanToDate] = useState(new Date());
  const laycanToDates =
  laycantoDate.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    laycantoDate.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    laycantoDate.toLocaleDateString("en-US", { day: "2-digit" });
  const Customlaycantodate = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));
useEffect(() => {

    fetchDataSupplier();
  }, [decryptedAccessToken]);

  const fetchDataSupplier = async () => {
    try {
      const response = await axios.get(API_URL+'all/accounts', {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      const data = response.data.accounts;
      setAccountName(data)
       console.log(data)
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {

    fetchDataSupplier1();
  }, [decryptedAccessToken]);

  const fetchDataSupplier1 = async () => {
    try {
      const response = await axios.get(API_URL+'get/surveyor', {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      const data = response.data;
      setAgent(data)
       console.log('Supplier'+ JSON.stringify(data))
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {

    fetchDataAccount();
  }, [decryptedAccessToken]);

  const fetchDataAccount = async () => {
    try {
      const response = await axios.get(API_URL+'get/all/rwoodcompany', {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      const data = response.data.rwoodcompany;
      setCompany(data)
       console.log(data)
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {

    fetchDataAccount1();
  }, [decryptedAccessToken]);

  const fetchDataAccount1 = async () => {
    try {
      const response = await axios.get(API_URL+'get/all/contract', {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      const data = response.data.contract;
      setContractNumber(data)
       console.log(data)
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {

    fetchDataAccount2();
  }, [decryptedAccessToken]);

  const fetchDataAccount2 = async () => {
    try {
      const response = await axios.get(API_URL+'get/agent', {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      const data = response.data;
      setAgents(data)
       console.log(data)
    } catch (error) {
      console.error(error);
    }
  };

  // contract number
   const [contractNo,setcontractNo]=useState([])
  useEffect(() => {

    contractNumbers();
  }, [decryptedAccessToken]);

  const contractNumbers = async () => {
    try {
      const response = await axios.get(API_URL+'get/agent', {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      const data = response.data;
      setcontractNo(data)
       console.log(data)
    } catch (error) {
      console.error(error);
    }
  };



 const { register, handleSubmit, reset, formState: { errors } } = useForm();
 const onSubmit = data =>{

    const encryptedAccessToken = localStorage.getItem('access_token');
    const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';
    const responseData={
     company:data.company,
currency:data.currency,
exchange_rate:data.exchange_rate,
account_name:data.account_name,
contract_number:data.contract_number,
order_start_date:orderstartDate,
status:data.status,
opportunity:data.opportunity,
remark:data.remark,
locked:data.locked,
activated_by:data.activated_by,
activated_date:datepick1,
order_record_type:data.order_record_type,
supplier:data.supplier,
contract_quantity_MT:data.contract_quantity_MT,
// shipment_qty:data.shipment_qty,
// shipment_quality_tolerance:data.shipment_quality_tolerance,
bl_no:data.bl_no,
purchase_contract_1:data.purchase_contract_1,
supplier_contract_refernce_1:data.supplier_contract_refernce_1,
purchase_contract_status_1:data.purchase_contract_status_1,
purchase_contract_2:data.purchase_contract_2,
supplier_contract_refernce_2:data.supplier_contract_refernce_2,
purchase_contract_status_2:data.purchase_contract_status_2,

product_specification:data.product_specification,
laycan_from_date:datepick2,
laycan_to_date:laycanToDates,
eta_date:datepick3,
laycan_month:data.laycan_month,
laycan_year:data.laycan_year,
shipment_month:datepick5,
ship_month:data.ship_month,
laycan_period:data.laycan_period,

business_confirm_to_supplier_no:data.business_confirm_to_supplier_no,
business_confirm_to_supplier_date:datepick6,

vessel_name:data.vessel_name,
barge_name:data.barge_name,
tug_boat_no:data.tug_boat_no,
tonnage:data.tonnage,
vessel_nomination_date:datepick7,
vessel_type:data.vessel_type,
vessel_nomination_confirm_date:datepick8,
vessel_nomination_description:data.vessel_nomination_description,
etb_date:datepick9,
jakarta_team_member:data.jakarta_team_member,
loading_rate:data.loading_rate,
buyer_despatch_demurrage_rate:data.buyer_despatch_demurrage_rate,
// supplier_despatch_demurrage_rate:data.supplier_despatch_demurrage_rate,
days_allowed_for_laycan:data.days_allowed_for_laycan,
loading_at:data.loading_at,
vessel_acceptance_date:datepick9,
vessel_acceptance_confirm_date:datepick10,
load_port_agent1:data.load_port_agent1,
load_port_agent2:data.load_port_agent2,
load_port_agent3:data.load_port_agent3,
agent_appointment_date:datepick12,
shipping_instruction_no:data.shipping_instruction_no,
shipping_instruction_date:shipping_date,
supplier_despatch_or_demurrage_amount:data.supplier_despatch_or_demurrage_amount,
destination:data.destination,
buyer_confirm_date:confirmDates1,
supplier_confirm_date:confirmDates,
destination_port:data.destination_port,
pre_stowage:data.pre_stowage,
contract_shipment:data.contract_shipment,
vessel_arrival_date_and_time:datepick13,
eta_notice_recieved_date1:etaDate1,
eta_notice_recieved_date2:etaDate2,
eta_notice_recieved_date3:etaDate3,
eta_notice_recieved_date4:etaDate4,
eta_notice_recieved_date5:etaDate5

    }
    console.log(JSON.stringify(responseData))
     if (encryptedAccessToken) {
       const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);

     fetch(API_URL+'add/rwood_orders', {
               method: "POST",
               body: JSON.stringify(responseData),
               headers: { Authorization: `Bearer ${decryptedAccessToken}`,
               'Content-Type': 'application/json' },
             })
               .then((response) => response.json())

               .then((response) => {
                console.log('Test'+JSON.stringify(response))
                toast.success('order added successfully', {
                  position: "bottom-center",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                  });
                setTimeout(() => {
                  reset();
                }, 300);

               })

             }

     };

  return (

    <div className="priceAccount" id='orders'>
    <Header/>
    <div>
    <div className="page-header" id=''>
    <div>
      <h2 className="main-content-title tx-24 mg-b-5">Add Order</h2>
      <ol className="breadcrumb">
        <li className="breadcrumb-item active" aria-current="page">Dashboard / Order / Add Order</li>
      </ol>
    </div>
    <div className="d-flex">
      <div className="justify-content-center">

        {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
         <a href='/account/add-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</a>
        </button> */}

      </div>
    </div>
  </div>
  <Form onSubmit={handleSubmit(onSubmit)} id='price'>
  <Row className="mb-3">
      <h4 className="heading">Order Details</h4>
  <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Company">
    <Form.Select aria-label="Floating label select example"
    name="company"
    placeholder="Company"
    {...register("company", { required:false })}
    >
      <option value='' >Select</option>
      {company.length>0?(
        <>
      {
        company.map((x)=>{
            return(
                <option value={x.company_name}>{x.company_name}</option>
            )
        })
     }
       </>
       ):(

        <option className='no-data'>No data  available</option>
        )}
    </Form.Select>
    {errors.company && <span>Required </span>}
  </FloatingLabel>
              </Form.Group>

      <Form.Group as={Col} md="6">
               <input type='checkbox' name='locked'/>Locked
              </Form.Group>

              <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Currency">
    <Form.Select aria-label="Floating label select example"
    name="currency"
    placeholder="Currency"
    {...register("currency", { required:false })}
    >
      <option value='' >Select</option>
      <option value='USD' >USD</option>
      <option value='AED' >IDR</option>
    </Form.Select>
    </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Order Record Type"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Order Record Type"
            name="order_record_type"
            {...register("order_record_type", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Exchange Rate"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Exchange Rate"
            name="exchange_rate"
            {...register("exchange_rate", {valueAsNumber:true, required:false })}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Supplier">
    <Form.Select aria-label="Floating label select example"
    name="supplier"
    placeholder="Supplier"
    className="error-validation"
    {...register("supplier", { required: true })}
    >
      <option value='' >Select</option>

       {suppliers.length>0?(
        <>
      {
          suppliers.map((x)=>{
              return(
                  <option value={x.account_name}>{x.account_name}</option>
              )
          })
       }
       </>
       ):(

        <option className='no-data'>No data  available</option>
        )}
    </Form.Select>
    {errors.supplier && <span>Required</span>}
  </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Account Name ">
    <Form.Select aria-label="Floating label select example"
    name="account_name"
    id='account_name'
    className="error-validation"
    placeholder="Account Name"

    {...register("account_name", { required:true })}
    >
      <option value='' >Select</option>

        {agents.length>0?(
        <>
      {
          agents.map((x)=>{
              return(
                  <option value={x.account_name}>{x.account_name}</option>
              )
          })
       }
       </>
       ):(

        <option className='no-data'>No data  available</option>
        )}
    </Form.Select>
    {errors.account_name && <span>Required </span>}
  </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Contract Number">
    <Form.Select aria-label="Floating label select example"
    name="contract_number"
    placeholder="Contract Number"
    {...register("contract_number", { required: false })}
    >
      <option value='' >Select</option>

        {contractNumber.length>0?(
        <>
      {
          contractNumber.map((x)=>{
              return(
                  <option value={x.contract_number}>{x.contract_number}</option>
              )
          })
       }
       </>
       ):(

        <option className='no-data'>No data  available</option>
        )}
    </Form.Select>
  </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Contract Shipment"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Contract Shipment"
            name="contract_shipment"
            {...register("contract_shipment", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="BL No"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="BL No"
            name="bl_no"
            {...register("bl_no", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Order Start Date"
            className="mb-3 datepick"


          >
            <DatePicker
      selected={orderStart}
      onChange={(date) => setOrderStart(date)}
      customInput={<CustomOrder />}
      dayClassName={() => "example-datepicker-day-class"}
      popperClassName="example-datepicker-class"
      todayButton="TODAY"
      dateFormat='yyyy-MM-dd'
      timeIntervals={15}
    timeCaption="Time"
    showTimeSelect
      placeholderText='Order Start Date'
    />
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6"></Form.Group>
        <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Status"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="status"
                    placeholder="Status"
                    className='error-validation'

                    {...register("status", { required: false })}
                  >
                  <option value="Additional Surveyor appointed by Rwood">Additional Surveyor appointed by Rwood</option>
<option value="Additional Surveyors certificates">Additional Surveyors certificates</option>
<option value="Agent Appointment">Agent Appointment</option>
<option value="AI-COO Courier">AI-COO Courier</option>
<option value="AI-COO issued">AI-COO issued</option>
<option value="Bl and AI COO Confirm">Bl and AI COO Confirm</option>
<option value="BL Issued">BL Issued</option>
<option value="Business Confirmation to Supplier">Business Confirmation to Supplier</option>
<option value="Cancelled">Cancelled</option>
<option value="Checking of Import Invoice">Checking of Import Invoice</option>
<option value="Transfer LC">Child LC</option>
<option value="Commercial Invoice">Commercial Invoice</option>
<option value="Commercial Invoice Bill of Exchange">Commercial Invoice Bill of Exchange</option>
<option value="Documents Arrive at RWood Bank">Documents Arrive at RWood Bank</option>
<option value="Documents negotiation by Rwood">Documents negotiation by Rwood</option>
<option value="Documents Submitted for LC Negotiation">Documents Submitted for LC Negotiation</option>
<option value="Draft">Draft</option>
<option value="Laycan Confirmation" selected="selected">Laycan Confirmation</option>
<option value="LC Discounting Application">LC Discounting Application</option>
<option value="Master LC Issued">Master LC Issued</option>
<option value="Negotiated Document Status">Negotiated Document Status</option>
<option value="NN Documets Sent">NN Documets Sent</option>
<option value="Nomination of Independent Surveyor">Nomination of Independent Surveyor</option>
<option value="Notice of Readiness">Notice of Readiness</option>
<option value="Shipments - Arutmin">Shipments - Arutmin</option>
<option value="Shipping Instruction">Shipping Instruction</option>
<option value="Surveyor Documents Issued">Surveyor Documents Issued</option>
<option value="Surveyor Send Invoice to Rwood">Surveyor Send Invoice to Rwood</option>
<option value="Vessel Acceptance">Vessel Acceptance</option>
<option value="Vessel Arrival / Loading commence">Vessel Arrival / Loading commence</option>
<option value="Vessel Loading Complete/Departure">Vessel Loading Complete/Departure</option>
<option value="Vessel Nomination">Vessel Nomination</option>
<option value="Cancelled with Penalty">Cancelled with Penalty</option>

                  </Form.Select>
                  {errors.status && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Opportunity"
                  className="mb-3"
                >

                  <Form.Control type="text"
                  placeholder="Opportunity"
                  name="opportunity"
                  {...register("opportunity", {  required: false })}
                  />
                   {errors.opportunity && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Remark"
                  className="mb-3"
                >

                  <Form.Control type="text"
                  placeholder="Remark"
                  name="remark"

                  {...register("remark", {  required: false })}
                  />
                   {errors.remark && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              </Row>
              <Row>
        <h4 className="heading">Purchase Contract Information</h4>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Purchase Contract 1"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Purchase Contract 1"
            name="purchase_contract_1"
           {...register("purchase_contract_1", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Purchase Contract 2"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Purchase Contract 2"
            name="purchase_contract_2"
           {...register("purchase_contract_2", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>

      </Row>
      <Row>
        <h4  className="heading">Laycan Information</h4>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Laycan From Date"
            className="mb-3 datepick"


          >
            <DatePicker
      selected={startDate2}
      onChange={(date) => setStartDate2(date)}
      customInput={<CustomInput2 />}
      dayClassName={() => "example-datepicker-day-class"}
      popperClassName="example-datepicker-class"
      todayButton="TODAY"
      dateFormat='yyyy-MM-dd'
      placeholderText='Laycan From Date'
    />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Laycan Month"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="laycan_month"
                    placeholder="Laycan Month"

                    {...register("laycan_month", { required: false })}
                  >
                   <option value="January">January</option><option value="February">February</option><option value="March">March</option><option value="April">April</option><option value="May">May</option><option value="June">June</option><option value="July">July</option><option value="August">August</option><option value="September">September</option><option value="October">October</option><option value="November">November</option><option value="December">December</option>
                  </Form.Select>
                  {errors.laycan_month && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Laycan To Date"
            className="mb-3 datepick"


          >
            <DatePicker
      selected={laycantoDate}
      onChange={(date) => setLaycanToDate(date)}
      customInput={<Customlaycantodate />}
      dayClassName={() => "example-datepicker-day-class"}
      popperClassName="example-datepicker-class"
      todayButton="TODAY"
      dateFormat='yyyy-MM-dd'
      placeholderText='Laycan To Date'
    />
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Laycan Year"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="laycan_year"
                    placeholder="Laycan Year"

                    {...register("laycan_year", { required: false })}
                  >
                    <option value="">--None--</option><option value="2021">2021</option><option value="2022">2022</option><option value="2023">2023</option><option value="2024">2024</option><option value="2025">2025</option><option value="2026">2026</option><option value="2015">2015</option><option value="2018">2018</option><option value="2019">2019</option><option value="2020">2020</option>
                  </Form.Select>
                  {errors.laycan_year && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="ETA Date"
            className="mb-3 datepick"


          >
            <DatePicker
      selected={startDate3}
      onChange={(date) => setStartDate3(date)}
      customInput={<CustomInput3 />}
      dayClassName={() => "example-datepicker-day-class"}
      popperClassName="example-datepicker-class"
      todayButton="TODAY"
      dateFormat='yyyy-MM-dd'
      placeholderText='ETA Date'
    />
          </FloatingLabel>
        </Form.Group>


        </Row>
        <Row>
        <h4 className="heading">Business Confirmation to Supplier</h4>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Business Confirm To Supplier No"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Business Confirm To Supplier No"
            name="business_confirm_to_supplier_no"
           {...register("business_confirm_to_supplier_no", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>



        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Business Confirm To Supplier Date"
            className="mb-3 datepick"


          >
            <DatePicker
      selected={startDate6}
      onChange={(date) => setStartDate6(date)}
      customInput={<CustomInput6 />}
      dayClassName={() => "example-datepicker-day-class"}
      popperClassName="example-datepicker-class"
      todayButton="TODAY"
      dateFormat='yyyy-MM-dd'
      placeholderText='Business Confirm To Supplier Date'
    />
          </FloatingLabel>
        </Form.Group>


      </Row>
      <Row>
        <h4  className="heading">Vessel Nomination</h4>


        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Vessel Name"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Vessel Name"
            name="vessel_name"
           {...register("vessel_name", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="ETB Date"
            className="mb-3 datepick"


          >
            <DatePicker
      selected={startDate9}
      onChange={(date) => setStartDate9(date)}
      customInput={<CustomInput9 />}
      dayClassName={() => "example-datepicker-day-class"}
      popperClassName="example-datepicker-class"
      todayButton="TODAY"
      dateFormat='yyyy-MM-dd'
      placeholderText='ETB Date'
    />
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Barge Name"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Barge Name"
            name="barge_name"
           {...register("barge_name", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Jakarta Team Member"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Jakarta Team Member"
            name="jakarta_team_member"
           {...register("jakarta_team_member", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>


        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="TUG Boat No"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="TUG Boat No"
            name="tug_boat_no"
           {...register("tug_boat_no", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Loading Rate"
            className="mb-3"
          >

            <Form.Control type="number"
            placeholder="Loading Rate"
            name="loading_rate"
           {...register("loading_rate", {valueAsNumber:true, required:false })}
            />
          </FloatingLabel>
        </Form.Group>


        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Tonnage"
            className="mb-3"
          >

            <Form.Control type="number"
            placeholder="Tonnage"
            name="tonnage"
           {...register("tonnage", {valueAsNumber:true, required:false })}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6"></Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Buyer Despatch Demurrage Rate"
            className="mb-3"
          >

            <Form.Control type="number"
            placeholder="Buyer Despatch Demurrage Rate"
            name="buyer_despatch_demurrage_rate"
           {...register("buyer_despatch_demurrage_rate", {valueAsNumber:true, required:false })}
            />
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Vessel Nomination Date"
            className="mb-3 datepick"


          >
            <DatePicker
      selected={startDate7}
      onChange={(date) => setStartDate7(date)}
      customInput={<CustomInput7 />}
      dayClassName={() => "example-datepicker-day-class"}
      popperClassName="example-datepicker-class"
      todayButton="TODAY"
      dateFormat='yyyy-MM-dd'
      placeholderText="Vessel Nomination Date"
    />
          </FloatingLabel>
        </Form.Group>


        <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Vessel Type"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="vessel_type"
                    placeholder="Vessel Type"

                    {...register("vessel_type", { required: false })}
                  >
                    <option value="">--None--</option><option value="Geared (GnG)">Geared (GnG)</option><option value="Gearless">Gearless</option><option value="Geared">Geared</option><option value="Barge">Barge</option>
                  </Form.Select>
                  {errors.vessel_type && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Loading At"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Loading At"
            name="loading_at"
           {...register("loading_at", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>


        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Vessel Nomination Confirm Date"
            className="mb-3 datepick"


          >
            <DatePicker
      selected={startDate8}
      onChange={(date) => setStartDate8(date)}
      customInput={<CustomInput8 />}
      dayClassName={() => "example-datepicker-day-class"}
      popperClassName="example-datepicker-class"
      todayButton="TODAY"
      dateFormat='yyyy-MM-dd'
      placeholderText="Vessel Nomination Confirm Date"
    />
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6"></Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Vessel Nomination Description"
            className="mb-3"
          >

            <Form.Control  as="textarea"
            placeholder="Vessel Nomination Description"
            name="vessel_nomination_description"
           {...register("vessel_nomination_description", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>

        </Row>

      <Row>
        <h4  className="heading">Vessel Aceptance</h4>



         <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Vessel Acceptance Date"
            className="mb-3 datepick"


          >
            <DatePicker
      selected={startDate10}
      onChange={(date) => setStartDate10(date)}
      customInput={<CustomInput10 />}
      dayClassName={() => "example-datepicker-day-class"}
      popperClassName="example-datepicker-class"
      todayButton="TODAY"
      dateFormat='yyyy-MM-dd'
      placeholderText='Vessel Acceptance Date'
    />
          </FloatingLabel>
        </Form.Group>

         <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Vessel Acceptance Confirm Date"
            className="mb-3 datepick"


          >
            <DatePicker
      selected={startDate11}
      onChange={(date) => setStartDate11(date)}
      customInput={<CustomInput11 />}
      dayClassName={() => "example-datepicker-day-class"}
      popperClassName="example-datepicker-class"
      todayButton="TODAY"
      dateFormat='yyyy-MM-dd'
      placeholderText='Vessel Acceptance Confirm Date'
    />
          </FloatingLabel>
        </Form.Group>

      </Row>

      <Row>
        <h4  className="heading">Agent Appointment</h4>

         <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Load Port Agent 1">
    <Form.Select aria-label="Floating label select example"
    name="load_port_agent1"
    id='account_name'
    className="error-validation"
    placeholder="Load Port Agent 1"

    {...register("load_port_agent1", { required:true })}
    >
      <option value='' >Select</option>

       {agents.length>0?(
        <>
      {
          agents.map((x)=>{
              return(
                  <option value={x.account_name}>{x.account_name}</option>
              )
          })
       }
       </>
       ):(

        <option className='no-data'>No data  available</option>
        )}
    </Form.Select>
    {errors.load_port_agent1 && <span>Required </span>}
  </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Agent Appointment Date"
            className="mb-3 datepick"


          >
            <DatePicker
      selected={startDate12}
      onChange={(date) => setStartDate12(date)}
      customInput={<CustomInput12 />}
      dayClassName={() => "example-datepicker-day-class"}
      popperClassName="example-datepicker-class"
      todayButton="TODAY"
      dateFormat='yyyy-MM-dd hh:mm:aa'
      timeIntervals={15}
    timeCaption="Time"
    showTimeSelect
      placeholderText='Agent Appointment Date'
    />
          </FloatingLabel>
        </Form.Group>



         <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Load Port Agent 2">
    <Form.Select aria-label="Floating label select example"
    name="load_port_agent2"
    id='account_name'
    placeholder="Load Port Agent 2"
    className="error-validation"

    {...register("load_port_agent2", { required:true })}
    >
      <option value='' >Select</option>
      {agents.length>0?(
        <>
      {
          agents.map((x)=>{
              return(
                  <option value={x.account_name}>{x.account_name}</option>
              )
          })
       }
       </>
       ):(

        <option className='no-data'>No data  available</option>
        )}
    </Form.Select>
    {errors.load_port_agent2 && <span>Required </span>}
  </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Load Port Agent 3">
    <Form.Select aria-label="Floating label select example"
    name="load_port_agent3"
    id='account_name'
    className="error-validation"
    placeholder="Load Port Agent 3"

    {...register("load_port_agent3", { required:true })}
    >
      <option value='' >Select</option>
      {agents.length>0?(
        <>
      {
          agents.map((x)=>{
              return(
                  <option value={x.account_name}>{x.account_name}</option>
              )
          })
       }
       </>
       ):(

        <option className='no-data'>No data  available</option>
        )}
    </Form.Select>
    {errors.load_port_agent3 && <span>Required </span>}
  </FloatingLabel>
              </Form.Group>

      </Row>
      <Row>
        <h4  className="heading">Shipping Instruction</h4>


        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Shipping Instruction No"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Shipping Instruction No"
            name="shipping_instruction_no"
           {...register("shipping_instruction_no", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>



        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Destination"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Destination"
            name="destination"
           {...register("destination", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="shipping instruction"
            className="mb-3 datepick"


          >
            <DatePicker
      selected={shippingDate}
      onChange={(date) => setShippingDate(date)}
      customInput={<Customshipping />}
      dayClassName={() => "example-datepicker-day-class"}
      popperClassName="example-datepicker-class"
      todayButton="TODAY"
      dateFormat='yyyy-MM-dd hh:mm:aa'
      timeIntervals={15}
    timeCaption="Time"
    showTimeSelect
      placeholderText='shipping instruction'
    />
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Destination Port"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Destination Port"
            name="destination_port"
           {...register("destination_port", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>

      </Row>
      <Row>
        <h4  className="heading">Vessel Tracking</h4>


        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Pre-stowage"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Pre-stowage"
            name="pre_stowage"
           {...register("pre_stowage", {valueAsNumber:true, required:false })}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="ETA notice recieved date 1"
            className="mb-3 datepick"


          >
            <DatePicker
      selected={eta1}
      onChange={(date) => setEta1(date)}
      customInput={<CustomEta1 />}
      dayClassName={() => "example-datepicker-day-class"}
      popperClassName="example-datepicker-class"
      todayButton="TODAY"
      dateFormat='yyyy-MM-dd'
      timeIntervals={15}
    timeCaption="Time"
    showTimeSelect
      placeholderText="ETA notice recieved date 1"
    />
          </FloatingLabel>
        </Form.Group>



        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="ETA notice recieved date 2"
            className="mb-3 datepick"


          >
            <DatePicker
      selected={eta2}
      onChange={(date) => setEta2(date)}
      customInput={<CustomEta2 />}
      dayClassName={() => "example-datepicker-day-class"}
      popperClassName="example-datepicker-class"
      todayButton="TODAY"
      dateFormat='yyyy-MM-dd'
      timeIntervals={15}
    timeCaption="Time"
    showTimeSelect
      placeholderText="ETA notice recieved date 2"
    />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="ETA notice recieved date 3"
            className="mb-3 datepick"


          >
            <DatePicker
      selected={eta3}
      onChange={(date) => setEta3(date)}
      customInput={<CustomEta3 />}
      dayClassName={() => "example-datepicker-day-class"}
      popperClassName="example-datepicker-class"
      todayButton="TODAY"
      dateFormat='yyyy-MM-dd'
      timeIntervals={15}
    timeCaption="Time"
    showTimeSelect
      placeholderText="ETA notice recieved date 3"
    />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="ETA notice recieved date 4"
            className="mb-3 datepick"


          >
            <DatePicker
      selected={eta4}
      onChange={(date) => setEta4(date)}
      customInput={<CustomEta4 />}
      dayClassName={() => "example-datepicker-day-class"}
      popperClassName="example-datepicker-class"
      todayButton="TODAY"
      dateFormat='yyyy-MM-dd'
      timeIntervals={15}
    timeCaption="Time"
    showTimeSelect
      placeholderText="ETA notice recieved date 4"
    />
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="ETA notice recieved date 5"
            className="mb-3 datepick"


          >
            <DatePicker
      selected={eta5}
      onChange={(date) => setEta5(date)}
      customInput={<CustomEta5 />}
      dayClassName={() => "example-datepicker-day-class"}
      popperClassName="example-datepicker-class"
      todayButton="TODAY"
      dateFormat='yyyy-MM-dd'
      timeIntervals={15}
    timeCaption="Time"
    showTimeSelect
      placeholderText="ETA notice recieved date 5"
    />
          </FloatingLabel>
        </Form.Group>

      </Row>
      <Row>
        <h4  className="heading">Vessel Arrival/Loading Commence</h4>


          <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Vessel arrival date and time"
            className="mb-3 datepick"


          >
            <DatePicker
      selected={startDate13}
      onChange={(date) => setStartDate13(date)}
      customInput={<CustomInput13 />}
      dayClassName={() => "example-datepicker-day-class"}
      popperClassName="example-datepicker-class"
      todayButton="TODAY"
      dateFormat='yyyy-MM-dd hh:mm:aa'
      timeIntervals={15}
    timeCaption="Time"
    showTimeSelect
      placeholderText='Vessel arrival date and time'
    />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Notice of readiness confirm date"
            className="mb-3 datepick"


          >
            <DatePicker
      selected={startDate17}
      onChange={(date) => setStartDate17(date)}
      customInput={<CustomInput17 />}
      dayClassName={() => "example-datepicker-day-class"}
      popperClassName="example-datepicker-class"
      todayButton="TODAY"
      dateFormat='yyyy-MM-dd hh:mm:aa'
      timeIntervals={15}
    timeCaption="Time"
    showTimeSelect
      placeholderText='Notice of readiness confirm date'
    />
          </FloatingLabel>
        </Form.Group>

<Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Commence Loading Date and time"
            className="mb-3 datepick"


          >
            <DatePicker
      selected={startDate14}
      onChange={(date) => setStartDate14(date)}
      customInput={<CustomInput14 />}
      dayClassName={() => "example-datepicker-day-class"}
      popperClassName="example-datepicker-class"
      todayButton="TODAY"
      dateFormat='yyyy-MM-dd hh:mm:aa'
      timeIntervals={15}
    timeCaption="Time"
    showTimeSelect
      placeholderText='Commence Loading Date and time'
    />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="NOR Tendered date and time"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="NOR Tendered date and time"
            name="nor_tendered_date_and_time"
           {...register("nor_tendered_date_and_time", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6"></Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="NOR acceptance date and time"
            className="mb-3 datepick"


          >
            <DatePicker
      selected={startDate18}
      onChange={(date) => setStartDate18(date)}
      customInput={<CustomInput18 />}
      dayClassName={() => "example-datepicker-day-class"}
      popperClassName="example-datepicker-class"
      todayButton="TODAY"
      dateFormat='yyyy-MM-dd hh:mm:aa'
      timeIntervals={15}
    timeCaption="Time"
    showTimeSelect
      placeholderText='NOR acceptance date and time'
    />
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Completion loading date and time"
            className="mb-3 datepick"


          >
            <DatePicker
      selected={startDate15}
      onChange={(date) => setStartDate15(date)}
      customInput={<CustomInput15 />}
      dayClassName={() => "example-datepicker-day-class"}
      popperClassName="example-datepicker-class"
      todayButton="TODAY"
      dateFormat='yyyy-MM-dd hh:mm:aa'
      timeIntervals={15}
    timeCaption="Time"
    showTimeSelect
      placeholderText='Completion loading date and time'
    />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Time to count for laytime"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Time to count for laytime"
            name="time_to_count_for_laytime"
           {...register("time_to_count_for_laytime", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>

      <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Sailing date and time"
            className="mb-3 datepick"


          >
            <DatePicker
      selected={startDate16}
      onChange={(date) => setStartDate16(date)}
      customInput={<CustomInput16 />}
      dayClassName={() => "example-datepicker-day-class"}
      popperClassName="example-datepicker-class"
      todayButton="TODAY"
      dateFormat='yyyy-MM-dd hh:mm:aa'
      timeIntervals={15}
    timeCaption="Time"
    showTimeSelect
      placeholderText='Sailing date and time'
    />
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Turn Time"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Turn Time"
            name="turn_time "
           {...register("turn_time", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>



</Row>
<Row>
        <h4  className="heading">Mate Receipt</h4>


        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Mate recieved date and time"
            className="mb-3 datepick"


          >
            <DatePicker
      selected={startDate19}
      onChange={(date) => setStartDate19(date)}
      customInput={<CustomInput19 />}
      dayClassName={() => "example-datepicker-day-class"}
      popperClassName="example-datepicker-class"
      todayButton="TODAY"
      dateFormat='yyyy-MM-dd hh:mm:aa'
      timeIntervals={15}
    timeCaption="Time"
    showTimeSelect
      placeholderText='Mate recieved date and time'
    />
          </FloatingLabel>
        </Form.Group>

               </Row>
               <Row>
        <h4 className="heading">Nomination of Independent Surveyour (Purchase)</h4>

        <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Surveyor Agent">
    <Form.Select aria-label="Floating label select example"
    name="surveyor_agent"
    placeholder="Surveyor Agent"

     {...register("surveyor_agent", { required:false })}
    >
      <option value='' >Select</option>
      {agents.length>0?(
        <>
      {
          agents.map((x)=>{
              return(
                  <option value={x.account_name}>{x.account_name}</option>
              )
          })
       }
       </>
       ):(

        <option className='no-data'>No data  available</option>
        )}
    </Form.Select>
  </FloatingLabel>
   </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Nomination of independent surveyor date"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Nomination of independent surveyor date"
            name="nomination_of_independent_surveyor_date"
           {...register("nomination_of_independent_surveyor_date", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>
               </Row>
               <Row>
        <h4 className="heading">Nomination of Additional Surveyour (Sales)</h4>

        <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Rwood surveyor agent">
    <Form.Select aria-label="Floating label select example"
    name="rwood_surveyor_agent"
    placeholder="Rwood surveyor agent"

     {...register("rwood_surveyor_agent", { required:false })}
    >
      <option value='' >Select</option>
      {agents.length>0?(
        <>
      {
          agents.map((x)=>{
              return(
                  <option value={x.account_name}>{x.account_name}</option>
              )
          })
       }
       </>
       ):(

        <option className='no-data'>No data  available</option>
        )}
    </Form.Select>
  </FloatingLabel>
   </Form.Group>

   <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Additional surveyor by rwood confirm date"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Additional surveyor by rwood confirm date"
            name="additional_surveyor_by_rwood_confirm_date"
           {...register("additional_surveyor_by_rwood_confirm_date", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>

   <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Buyer surveyor agent">
    <Form.Select aria-label="Floating label select example"
    name="buyer_surveyor_agent"
    placeholder="Buyer surveyor agent"
     {...register("buyer_surveyor_agent", { required:false })}
    >
      <option value='' >Select</option>
      {agents.length>0?(
        <>
      {
          agents.map((x)=>{
              return(
                  <option value={x.account_name}>{x.account_name}</option>
              )
          })
       }
       </>
       ):(

        <option className='no-data'>No data  available</option>
        )}
    </Form.Select>
  </FloatingLabel>
   </Form.Group>
         </Row>
         <Row>
         <h4 className="heading">Summary of Documents</h4>
         <Form.Group as={Col} md="6"></Form.Group>
         <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="BL Date"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="BL Date"
            name="bl_date"
           {...register("bl_date", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6"></Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="All actual payment recieved date"
            className="mb-3"
          >

            <Form.Control type="number"
            placeholder="All actual payment recieved date"
            name="all_actual_payment_recieved_date"
           {...register("all_actual_payment_recieved_date", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6"></Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="All document discount date"
            className="mb-3"
          >

            <Form.Control type="number"
            placeholder="All document discount date"
            name="all_document_discount_date"
           {...register("all_document_discount_date", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6"></Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="All expected value date"
            className="mb-3"
          >

            <Form.Control type="number"
            placeholder="All expected value date"
            name="all_expected_value_date"
         {...register("all_expected_value_date", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>
        </Row>
        <Row>
        <h4 className='heading'>Supplier Despatch/Demmurage LTC</h4>
        <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Supplier despacth or demmurage">
    <Form.Select aria-label="Floating label select example"
    name="supplier_despacth_or_demmurage"
    placeholder="Supplier despacth or demmurage"
    {...register("supplier_despacth_or_demmurage", { required:false })}
    >
      <option value="">--None--</option><option value="Despatch">Despatch</option><option value="Demmurage">Demurage</option>
    </Form.Select>
    {errors.supplier_despacth_or_demmurage && <span>Required </span>}
  </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="BL quantity laytime calculations"
            className="mb-3"
          >

            <Form.Control type="number"
            placeholder="BL quantity laytime calculations"
            name="bl_quantity_laytime_calculations"
           {...register("bl_quantity_laytime_calculations", {valueAsNumber:true, required:false })}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Supplier despatch or demurrage amount"
            className="mb-3"
          >

            <Form.Control type="number"
            placeholder="Supplier despatch or demurrage amount"
            name="supplier_despatch_or_demurrage_amount"
           {...register("supplier_despatch_or_demurrage_amount", {valueAsNumber:true, required:false })}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Supplier Check Status"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Supplier Check Status"
            name="supplier_check_status"
           {...register("supplier_check_status", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Supplier laytime time used"
            className="mb-3"
          >

            <Form.Control type="number"
            placeholder="Supplier laytime time used"
            name="supplier_laytime_time_used"
           {...register("supplier_laytime_time_used", {valueAsNumber:true, required:false })}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Buyer LTC Reccount"
            className="mb-3"
          >

            <Form.Control type="number"
            placeholder="Buyer LTC Reccount"
            name="buyer_ltc_rec_count"
           {...register("buyer_ltc_rec_count", {valueAsNumber:true, required:false })}
            />
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Supplier laytime time saved exceed"
            className="mb-3"
          >

            <Form.Control type="number"
            placeholder="Supplier laytime time saved exceed"
            name="supplier_laytime_time_saved_exceed"
           {...register("supplier_laytime_time_saved_exceed", {valueAsNumber:true, required:false })}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6"></Form.Group>
        <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Supplier despatch demurrage status">
    <Form.Select aria-label="Floating label select example"
    name="supplier_despatch_demurrage_status"
    placeholder="Supplier despatch demurrage status"
    {...register("supplier_despatch_demurrage_status", { required:false })}
    >
      <option value="">--None--</option><option value="Draft">Draft</option><option value="Approved by Rwood">Approved by Rwood</option><option value="Not Confirmed">Not Confirmed</option><option value="Accepted">Confirmed</option><option value="Settled">Settled</option><option value="Split BL">Split BL</option><option value="-NA-">-NA-</option><option value="Not Confirmed but Paid">Not Confirmed but Paid</option><option value="Not Confirmed but Settled">Not Confirmed but Settled</option>
    </Form.Select>
    </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Count of desp demm DN"
            className="mb-3"
          >

            <Form.Control type="number"
            placeholder="Count of desp demm DN"
            name="count_of_desp_demm_dn"
           {...register("count_of_desp_demm_dn", {valueAsNumber:true, required:false })}
            />
          </FloatingLabel>
        </Form.Group>

              <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="LTC sent to supplier date"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="LTC sent to supplier date"
            name="ltc_sent_to_supplier_date"
           {...register("ltc_sent_to_supplier_date", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Count of Other DN"
            className="mb-3"
          >

            <Form.Control type="number"
            placeholder="Count of Other DN"
            name="count_of_other_dn"
           {...register("count_of_other_dn", {valueAsNumber:true, required:false })}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Supplier LTC  to RW"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Supplier LTC  to RW"
            name="supplier_ltc_to_rw"
           {...register("supplier_ltc_to_rw", {valueAsNumber:true, required:false })}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Supplier final dem or despatch payable"
            className="mb-3"
          >

            <Form.Control type="number"
            placeholder="Supplier final dem or despatch payable"
            name="supplier_final_dem_or_despatch_payable"
           {...register("supplier_final_dem_or_despatch_payable", {valueAsNumber:true, required:false })}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Confirm Date"
            className="mb-3 datepick"


          >
            <DatePicker
      selected={confirmDate}
      onChange={(date) => setConfirmDate(date)}
      customInput={<CustomConfirmDate />}
      dayClassName={() => "example-datepicker-day-class"}
      popperClassName="example-datepicker-class"
      todayButton="TODAY"
      dateFormat='yyyy-MM-dd'
      placeholderText='Confirm Date'
    />
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Supplier final other payable"
            className="mb-3"
          >

            <Form.Control type="number"
            placeholder="Supplier final other payable"
            name="supplier_final_other_payable"
           {...register("supplier_final_other_payable", {valueAsNumber:true, required:false })}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Supplier remark despacth demmurage"
            className="mb-3"
          >

            <Form.Control as="textarea"
            placeholder="Supplier remark despacth demmurage"
            name="supplier_remark_despacth_demmurage"
           {...register("supplier_remark_despacth_demmurage", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6"></Form.Group>
         </Row>
         <Row>
        <h4 className='heading'>Buyer Despatch/Demmurage LTC</h4>
        <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Buyer despacth or demmurage">
    <Form.Select aria-label="Floating label select example"
    name="supplier_despacth_or_demmurage"
    placeholder="Buyer despacth or demmurage"
    {...register("buyer_despacth_or_demmurage", { required:false })}
    >
      <option value="">--None--</option><option value="Despatch">Despatch</option><option value="Demmurage">Demurage</option>
    </Form.Select>
    {errors.buyer_despacth_or_demmurage && <span>Required </span>}
  </FloatingLabel>
              </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Buyer Check Status"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Buyer Check Status"
            name="supplier_check_status"
           {...register("buyer_check_status", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Buyer despatch or demurrage amount"
            className="mb-3"
          >

            <Form.Control type="number"
            placeholder="Buyer despatch or demurrage amount"
            name="supplier_despatch_or_demurrage_amount"
           {...register("buyer_despatch_or_demurrage_amount", {valueAsNumber:true, required:false })}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Buyer laytime time used"
            className="mb-3"
          >

            <Form.Control type="number"
            placeholder="Buyer laytime time used"
            name="buyer_laytime_time_used"
           {...register("buyer_laytime_time_used", {valueAsNumber:true, required:false })}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Buyer laytime time saved exceed"
            className="mb-3"
          >

            <Form.Control type="number"
            placeholder="Buyer laytime time saved exceed"
            name="supplier_laytime_time_saved_exceed"
           {...register("buyer_laytime_time_saved_exceed", {valueAsNumber:true, required:false })}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6"></Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Buyer final dem or despatch payable"
            className="mb-3"
          >

            <Form.Control type="number"
            placeholder="Buyer final dem or despatch payable"
            name="supplier_final_dem_or_despatch_payable"
           {...register("buyer_final_dem_or_despatch_payable", {valueAsNumber:true, required:false })}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Buyer despatch demurrage status">
    <Form.Select aria-label="Floating label select example"
    name="supplier_despatch_demurrage_status"
    placeholder="Buyer despatch demurrage status"
    {...register("buyer_despatch_demurrage_status", { required:false })}
    >
      <option value="">--None--</option><option value="Draft">Draft</option><option value="Approved by Rwood">Approved by Rwood</option><option value="Not Confirmed">Not Confirmed</option><option value="Accepted">Confirmed</option><option value="Settled">Settled</option><option value="Split BL">Split BL</option><option value="-NA-">-NA-</option><option value="Not Confirmed but Paid">Not Confirmed but Paid</option><option value="Not Confirmed but Settled">Not Confirmed but Settled</option>
    </Form.Select>
    </FloatingLabel>
              </Form.Group>  <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Buyer final other payable"
            className="mb-3"
          >

            <Form.Control type="number"
            placeholder="Buyer final other payable"
            name="supplier_final_other_payable"
           {...register("buyer_final_other_payable", {valueAsNumber:true, required:false })}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="LTC sent to Buyer date"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="LTC sent to Buyer date"
            name="ltc_sent_to_buyer_date"
           {...register("ltc_sent_to_buyer_date", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Buyer LTC  to RW"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Buyer LTC  to RW"
            name="supplier_ltc_to_rw"
           {...register("buyer_ltc_to_rw", {valueAsNumber:true, required:false })}
            />
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Confirm Date"
            className="mb-3 datepick"


          >
            <DatePicker
      selected={confirmDate1}
      onChange={(date) => setConfirmDate1(date)}
      customInput={<CustomConfirmDate1 />}
      dayClassName={() => "example-datepicker-day-class"}
      popperClassName="example-datepicker-class"
      todayButton="TODAY"
      dateFormat='yyyy-MM-dd'
      placeholderText='Confirm Date'
    />
          </FloatingLabel>
        </Form.Group>


        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Buyer remark despacth demmurage"
            className="mb-3"
          >

            <Form.Control as="textarea"
            placeholder="Buyer remark despacth demmurage"
            name="buyer_remark_despacth_demmurage"
           {...register("buyer_remark_despacth_demmurage", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>


                 </Row>
                 <Row className="mb-3">
      <h4 className="heading">Summary Payable Receivable
</h4>


<Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Sales invoice"
            className="mb-3"
          >

            <Form.Control as="textarea"
            placeholder="Sales invoice"
            name="sales_invoice"
           {...register("sales_invoice", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>


<Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Surveyor Invoice"
            className="mb-3"
          >

            <Form.Control as="textarea"
            placeholder="Surveyor Invoice"
            name="surveyor_invoice"
           {...register("surveyor_invoice", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Purchase Invoice"
            className="mb-3"
          >

            <Form.Control as="textarea"
            placeholder="Purchase Invoice"
            name="purchase_invoice"
           {...register("purchase_invoice", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Load Port Invoice"
            className="mb-3"
          >

            <Form.Control as="textarea"
            placeholder="Load Port Invoice"
            name="load_port_invoice"
           {...register("load_port_invoice", { required:false })}
            />
          </FloatingLabel>
        </Form.Group>
        <input type="submit" className='price-submit' />
      <div className='priceDiv'><p></p><p></p></div>
      </Row>
  </Form>
    </div>
    <Footer/>
    </div>
  );
}

export default AddOrder;
