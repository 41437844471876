import React, { Component, useState, useEffect, useContext, forwardRef } from "react";
import { Card, OverlayTrigger, Tooltip, Table } from "react-bootstrap";
import "datatables.net-dt/js/dataTables.dataTables.js";
import { AES, enc } from "crypto-js";
import "datatables.net-responsive-dt";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "react-data-table-component-extensions/dist/index.css";
import { Link, useNavigate } from "react-router-dom";
import { TokenContext } from "../AuthProvider";
import RecentItem from "../recentitem/rececentItem";
import Header from "../Header";
import Footer from "../Footer";
import $ from "jquery";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Popup from "reactjs-popup";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { parse, format , isValid } from 'date-fns';
import Col from "react-bootstrap/Col";
import {
  FaEnvelope,
  FaPhoneSquareAlt,
  FaMobileAlt,
  FaFax,
} from "react-icons/fa";
import axios from "axios";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { FaPencilAlt, FaTrashAlt, FaPlus } from "react-icons/fa";
// import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
import { useParams } from "react-router-dom";
import { API_URL } from "../config";
const ViewaccountMaster = () => {
  const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
  const encryptedAccessToken = localStorage.getItem("access_token");
  const decryptedAccessToken = encryptedAccessToken
    ? AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8)
    : null;
  const [data, setData] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [editedData, setEditedData] = useState([]);
  const handleEditClick = () => {
    setIsEditMode(true);
    setEditedData(data);
  };


  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setDatas((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };


 const handleCancelEdit = () => {
    setIsEditMode(false);
    setEditedData(data);

  };



  const navigate = useNavigate();
  const [formData, setFromdata] = useState({
    account_record: "",
  });

  const handleChange = (e) => {
    setFromdata({ ...formData, [e.target.name]: e.target.value });
  };


  const params = useParams();
  const id = params.id;
  const userRole = localStorage.getItem("role");
  console.log(userRole);
  const { username, token } = useContext(TokenContext);
  const [datas, setDatas] = useState([]);
  const [lenthData, setlenthData] = useState([]);
  const [historys, setHistory] = useState([]);
  const [opp, setOpp] = useState([]);
  const [bdate, setbirthDate]=useState('')
  const [accountNamedrop, setAccountnameDropdown]=useState('')

  useEffect(() => {
    fetchData();
  }, [decryptedAccessToken], lenthData);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${API_URL}get/chart_account_master/${id}`,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );

      console.log(response,"********")
      setDatas(response.data.chart_account_master_detail[0]);

    } catch (error) {
      console.error(error);
    }
  };



  const [lockeds, setLockeds] = useState(null);
  const check_box = datas.locked;
  console.log(check_box);
  useEffect(() => {
    setLockeds(check_box);
  }, [check_box]); // Run this effect whenever tt changes

  useEffect(() => {
    console.log("Value of lockeds:", lockeds); // Log lockeds
  }, [lockeds]); // Run

  const handleCheckboxChange = (e) => {
    setLockeds(e.target.checked);
  };



  const handleSaveEdit = async (date) => {


    const responseData={
      "chart_of_account_no":datas.chart_of_account_no,
      "owner":datas.owner,
      "account_type":datas.account_type,
      "type_of_entry":datas.type_of_entry,
      "group_code":datas.group_code,
      "sub_group_code":datas.sub_group_code,
      "ledger_code":datas.ledger_code,
      "subledger_code":datas.subledger_code,
      "sub_subledger_code":datas.sub_subledger_code ,
      "ledger_name":datas.ledger_name,

    };
    console.log(JSON.stringify(responseData))

    fetch(`${API_URL}update/chart_of_account_master/${id}`, {
      method: 'PUT',
      body: JSON.stringify(responseData),
      headers: { Authorization: `Bearer ${decryptedAccessToken}`,
              'Content-Type': 'application/json' },

    })
      .then((response) => response.json())
      .then((updatedData) => {
        console.log(updatedData)
        fetchData();
        if(updatedData.msg==='contact updated successfully'){

          // fetchData();
      }

        setData(updatedData);
        setIsEditMode(false);
      })
      .catch((error) => console.error(error));
    }

  return (
    <div id="view-page">
      <Header />
      <>
            {/* {lenthData.length > 0 ? ( */}
      <div className="contactAccount">
      <div className="row">
          <div className="col-md-2 recent-item">
            <RecentItem />
          </div>
          <div className="col-md-10 right-section">
        <div className="page-header btn-margin" id="add-account">
          <div>
            <h2 className="main-content-title tx-24 mg-b-5">
            Account Master
            </h2>
            <ol className="breadcrumb">
              <li className="breadcrumb-item active" aria-current="page">
                Dashboard / Account Master / details
              </li>
            </ol>
          </div>
        </div>
        {isEditMode ? (

         <div className="tasks">

            <>

            <p className='edit-btn'>
     <button className='btn btn-primary' onClick={handleSaveEdit}>Save</button>
     <button className="btn btn-primary" onClick={handleCancelEdit}>Cancel</button>


     </p>
     <Row className="mb-3">

      <div className='col-md-12'>
      <h4 className='heading'>Information</h4>
        </div>


    <Form.Group
              as={Col}
              md="6"

            >
    <FloatingLabel
            controlId="floatingInput"
            label="Chart of Account No	"
            className="mb-3"
          >
            <Form.Control
              type="text"
              placeholder="Chart of Account No	"
              name="chart_of_account_no"
              defaultValue={datas.chart_of_account_no || ""}
     onChange={handleChangeInput}/>
          </FloatingLabel>

            </Form.Group>


        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Owner"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Owner"
            name="owner"
            defaultValue={datas.owner || ""}
     onChange={handleChangeInput}/>
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
                            <FloatingLabel
                              controlId="floatingSelect"
                              className="dropDown"
                              label="Account Type	"
                            >
                              <Form.Select
                                aria-label="Floating label select example"
                                name="account_type"
                                placeholder="Account Type	"
                                defaultValue={datas.account_type || ""}
                                onChange={handleChangeInput}
                              >
                               <option value="">--None--</option><option value="Income">Income</option><option value="Expense">Expense</option><option value="Asset">Asset</option><option value="Liability">Liability</option>
                              </Form.Select>
                            </FloatingLabel>
                          </Form.Group>
                          <Form.Group as={Col} md="6">
                            <label>Locked</label>
                            <input
                              type="checkbox"
                              name="locked"
                              checked={lockeds} // Use the state variable to determine if the checkbox is checked
                              onChange={handleCheckboxChange} // Handle the change event
                              className="mx-3"
                            />
                          </Form.Group>
                          <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Ledger Name	
"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Ledger Name	
"
            name="ledger_name"
            defaultValue={datas.ledger_name || ""}
     onChange={handleChangeInput}/>
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
                            <FloatingLabel
                              controlId="floatingSelect"
                              className="dropDown"
                              label="Type of Entry"
                            >
                              <Form.Select
                                aria-label="Floating label select example"
                                name="type_of_entry"
                                placeholder="Type of Entry"
                                defaultValue={datas.type_of_entry || ""}
                                onChange={handleChangeInput}
                              >
                               <option value="">--None--</option><option value="Income">Income</option><option value="Expense">Expense</option><option value="Asset">Asset</option><option value="Liability">Liability</option>
                              </Form.Select>
                            </FloatingLabel>
                          </Form.Group>


            <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Group Code"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Group Code"
            name="group_code"
             defaultValue={datas.group_code || ""}
     onChange={handleChangeInput}/>
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6"> </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Sub Group Code	"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Sub Group Code	"
            name="sub_group_code"
             defaultValue={datas.sub_group_code || ""}
     onChange={handleChangeInput}/>
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6"> </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Ledger Code"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Ledger Code	"
            name="ledger_code"
             defaultValue={datas.ledger_code || ""}
     onChange={handleChangeInput}/>
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6"> </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Subledger Code	"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Subledger Code	"
            name="subledger_code"
             defaultValue={datas.subledger_code || ""}
     onChange={handleChangeInput}/>
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6"> </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Sub SubLedger Code	"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Sub SubLedger Code		"
            name="sub_subledger_code"
             defaultValue={datas.sub_subledger_code || ""}
     onChange={handleChangeInput}/>
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6"> </Form.Group>

     </Row>
     <p className='edit-btn'>
     <button className='btn btn-primary' onClick={handleSaveEdit}>Save</button>
     <button className="btn btn-primary" onClick={handleCancelEdit}>Cancel</button>


     </p> </>

         </div>
        ):(
          <>




         <div className="tasks">
         <div className="edit-delete">
                  <span id="edit" onClick={handleEditClick}>
                    Edit
                  </span>
                  <span>
                  <Link to={'/price/price-list'}> Cancel</Link>
                  </span>

                  </div>

             <>
             <div className="">
               <div id="contracts">
                 <div className="">
                   <div className="row">
                     <div className="col-md-12" id="head">
                       <h4 className="heading">Information</h4>
                       <hr></hr>
                     </div>
                   </div>
                   <table class="table table-bordered account-table tables">
                     <tbody>
                        <tr>
                         <td id="td-right">
                           <span>Chart of Account No
</span>
                         </td>
                         <td>{datas.chart_of_account_no}</td>
                         <td id="td-right">
                           <span>Owner
</span>
                         </td>
                         <td>{datas.owner}</td>

                       </tr>

                       <tr>
                         <td id="td-right">
                           <span>Account Type
</span>
                         </td>
                         <td>{datas.account_type}</td>
                         <td id="td-right">
                           <span>Locked
</span>
                         </td>
                         <td>{datas.locked}</td>

                       </tr>


                        <tr>
                         <td id="td-right">
                           <span>Acc Code	</span>
                         </td>
                         <td>{datas.acc_code}</td>
                         <td id="td-right">
                           <span>  Type of Entry	</span>
                         </td>
                         <td>{datas.type_of_entry}</td>
                       </tr>
                       <tr>
                         <td id="td-right">
                           <span>ledger name	</span>
                         </td>
                         <td>{datas.ledger_name}</td>
                         <td id="td-right">
                           <span></span>
                         </td>
                         <td></td>
                       </tr>


                     

                       <tr>
                         <td id="td-right">
                           <span>Group Code
</span>
                         </td>
                         <td>{datas.group_code}</td>
                         <td id="td-right">
                         </td>
                         <td></td>
                       </tr>

                       <tr>
                         <td id="td-right">
                           <span> Sub Group Code		</span>
                         </td>
                         <td>{datas.sub_group_code}</td>
                         <td id="td-right">
                         </td>
                         <td></td>
                       </tr>

                       <tr>
                         <td id="td-right">
                           <span> Ledger Code	</span>
                         </td>
                         <td>{datas.ledger_code}</td>
                         <td id="td-right">
                         </td>
                         <td></td>
                       </tr>

                       <tr>
                         <td id="td-right">
                           <span> Subledger Code
</span>
                         </td>
                         <td>{datas.subledger_code}</td>
                         <td id="td-right">
                         </td>
                         <td></td>
                       </tr>
                       <tr>
                         <td id="td-right">
                           <span> Sub SubLedger Code
</span>
                         </td>
                         <td>{datas.sub_subledger_code}</td>
                         <td id="td-right">
                         </td>
                         <td></td>
                       </tr>

                      </tbody>
                   </table>



                 </div>
               <div className="edit-delete">
                  <span id="edit" onClick={handleEditClick}>
                    Edit
                  </span>
                  <span>
                  <Link to={'/price/price-list'}> Cancel</Link>
                  </span>

                  </div>
               </div>
             </div>

            <div className="">
               <div id="contracts">


                        <Row id="table-styles">
                          <div className="col-md-12" id="head">
                            <h4 className="heading">
                              <span>Notes</span>
                              <span>
                                <i className=""></i>
                                New Note
                              </span>
                            </h4>
                            <hr></hr>
                          </div>
                          <table id="history" className="">
                            <tr>
                              <td>
                                <p class="no-records">No records to display</p>
                              </td>
                            </tr>
                          </table>
                        </Row>
                        <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Cases</span>
                                      <span>
                                        <i className=""></i>
                                        New Case
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">
                                          No records to display
                                        </p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>
                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Open Activities</span>
                                      <span>
                                        <i className=""></i>
                                        New Task
                                      </span>
                                      <span>
                                        <i className=""></i>
                                        New Event
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">
                                          No records to display
                                        </p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>
                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Activity History</span>
                                      <span>
                                        <i className=""></i>
                                        Log a Call
                                      </span>
                                      <span>
                                        <i className=""></i>
                                        Mail merge
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">
                                          No records to display
                                        </p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>

               </div>
             </div>
           </>

         </div>

       </>
        )
}
</div></div></div>
      {/* ):(
        <div className="tasks">
        <h1 className="no-data-detail"><img src='../../images/loading.gif' className="loading"/></h1>
        </div>
      )} */}
</>

      <Footer />
    </div>
  );
};

export default ViewaccountMaster;
