import React, { Component, useState, useEffect, useContext, forwardRef } from "react";
import { Card, OverlayTrigger, Tooltip, Table } from "react-bootstrap";
import "datatables.net-dt/js/dataTables.dataTables.js";
import { AES, enc } from "crypto-js";
import "datatables.net-responsive-dt";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "react-data-table-component-extensions/dist/index.css";
import { Link, useNavigate } from "react-router-dom";
import { TokenContext } from "../AuthProvider";
import Header from "../Header";
import Footer from "../Footer";
import RecentItems from "../recentItems/RecentItems";
import { API_URL } from '../config';
import $ from "jquery";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Popup from "reactjs-popup";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { parse, format , isValid } from 'date-fns';
import Col from "react-bootstrap/Col";
import {
  FaEnvelope,
  FaPhoneSquareAlt,
  FaMobileAlt,
  FaFax,
} from "react-icons/fa";
import axios from "axios";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { FaPencilAlt, FaTrashAlt, FaPlus } from "react-icons/fa";
// import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
import { useParams } from "react-router-dom";
const ContactViewPage = () => {
  const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
  const encryptedAccessToken = localStorage.getItem("access_token");
  const decryptedAccessToken = encryptedAccessToken
    ? AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8)
    : null;

  const [accountNames, setAccountName]=useState([]);
  const [apiDate, setApiDate] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [startDate1, setStartDate1] = useState(null);
  const [inputValue, setInputValue] = useState('');

  const [isSubmitted, setIsSubmitted] = useState(false);
  useEffect(() => {
    fetchDataSupplier();
  }, [decryptedAccessToken]);

  const fetchDataSupplier = async () => {
    try {
      const response = await axios.get(`${API_URL}all/accounts`, {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      const data = response.data.accounts;

      setAccountName(data)
       console.log('Supplier'+ JSON.stringify(data))
    } catch (error) {
      console.error(error);
    }
  };



  //  const birthDate=startDate1.toLocaleDateString("en-US", { year: 'numeric' })+"-"+(startDate1.toLocaleDateString("en-US", { month: '2-digit' }))+"-"+(startDate1.toLocaleDateString("en-US", { day: '2-digit' }))
  // const CustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
  //   <input
  //     value={value}
  //     className="custom-input"
  //     onClick={onClick}
  //     name="birthdate"
  //     onChange={onChange}
  //     ref={ref}
  //   ></input>
  // ));
  // edit option
  const [data, setData] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [editedData, setEditedData] = useState([]);
  const handleEditClick = () => {
    setIsEditMode(true);
    setEditedData(data);
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setDatas((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };


 const handleCancelEdit = () => {
    setIsEditMode(false);
    setEditedData(data);

  };



  const navigate = useNavigate();
  const [formData, setFromdata] = useState({
    account_record: "",
  });

  const handleChange = (e) => {
    setFromdata({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const encryptedAccessToken = localStorage.getItem("access_token");
    const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
    if (encryptedAccessToken) {
      const decryptedAccessToken = AES.decrypt(
        encryptedAccessToken,
        secret_key
      ).toString(enc.Utf8);

      fetch(`${API_URL}add/record`, {
        method: "POST",
        body: JSON.stringify(formData),
        headers: {
          Authorization: `Bearer ${decryptedAccessToken}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())

        .then((response) => {
          console.log(response);
          console.log(response.status);
          const account_record_new = response.account_type;

          navigate("/contact/contact-by-opportunity/" + id, {
            state: { account_record: account_record_new },
          });
          if (response.status === 200) {

          }
        });
    }
  };


  const params = useParams();
  const id = params.id;
  const userRole = localStorage.getItem("role");
  console.log(userRole);
  const { username, token } = useContext(TokenContext);
  const [datas, setDatas] = useState([]);
  const [lenthData, setlenthData] = useState([]);
  const [historys, setHistory] = useState([]);
  const [opp, setOpp] = useState([]);
  const [bdate, setbirthDate]=useState('')
  const [accountNamedrop, setAccountnameDropdown]=useState('')

  useEffect(() => {
    fetchData();
  }, [decryptedAccessToken]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${API_URL}allcontactsbyID/` + id,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      console.log(response)
      const data = response.data[0].contact_details;

      // navigate("/accounts/" + response.account_id);
      // setTimeout(() => {
      //   //navigate("/accounts/2");
      //   reset2();
      // }, 2000);
      console.log(response)
      const dropdownValue=response.data[0].contact_details.account_name;
      setAccountnameDropdown(dropdownValue)
      const dataLength = response.data;
      const historys = response.data[0].history;
      const opportunies = response.data[0].opportunity;
      let birth_Date=data.birthdate

      if (birth_Date !== null){
        const defaultDatepicker = parse(birth_Date, 'dd/MM/yyyy', new Date());
        setStartDate1(defaultDatepicker);
      }else{
        setStartDate1(new Date())
      }



      setDatas(data);
      setlenthData(dataLength)
      setHistory(historys)
      setOpp(opportunies)
      const dateFromApi = response.data[0].contact_details.birthdate;
      setApiDate(dateFromApi)
      setSelectedDate(new Date(dateFromApi));
        setInputValue(dateFromApi);
      setTimeout(() => {
        $("#quotes").DataTable();
        $("#history").DataTable();
      }, 10);
    } catch (error) {
      console.error(error);
    }
  };


  useEffect(() => {
    fetchDataSupplier();
  }, [decryptedAccessToken]);

  useEffect(() => {
    // Set the selected option to 'Siva' when accountName changes
    if (accountNamedrop.length > 0) {
      setAccountnameDropdown({ value: accountNamedrop, label: accountNamedrop });
    }
  }, [accountNamedrop]);



  const options = accountNames.map(account => ({
    value: account.account_name,
    label: account.account_name
  }));

  useEffect(()=>{
    const dateObject = new Date(startDate1);
    if (dateObject) {
      const formattedDate = format(dateObject, 'dd/MM/yyyy', { timeZone: 'UTC' });
      setbirthDate(formattedDate)
      console.log(formattedDate)
    }
  })

  console.log(typeof(bdate))

  // if (!datas) {
  //   return null;
  // }
  setTimeout(() => {
    $("#quotes").DataTable();
    $("#history").DataTable();
  }, 10);
  const handleSaveEdit = async (date) => {

    setIsSubmitted(true);

    const responseData={
      "saluation":datas.saluation,
      "first_name":datas.first_name,
      "last_name":datas.last_name,
      "birthdate":bdate?bdate:null,
      "title":datas.title,
      "account_name":accountNamedrop.value,
      "reports_to":datas.reports_to,
      "contact_email":datas.contact_email,
      "contact_mobile":datas.contact_mobile,
      "contact_owner":datas.contact_owner,
      "department":datas.department,
      "lead_source":datas.lead_source,
      "mail_street":datas.mail_street,
      "mail_postal_code":datas.mail_postal_code,
      "mail_city":datas.mail_city,
      "mail_state":datas.mail_state,
      "mail_country":datas.mail_country

    };
    console.log(JSON.stringify(responseData))

    fetch(`${API_URL}contact/update/${id}`, {
      method: 'PUT',
      body: JSON.stringify(responseData),
      headers: { Authorization: `Bearer ${decryptedAccessToken}`,
              'Content-Type': 'application/json' },

    })
      .then((response) => response.json())
      .then((updatedData) => {
        console.log(updatedData)
        fetchData();
        setIsSubmitted(false);
        if(updatedData.msg==='contact updated successfully'){

          // fetchData();
      }

        setData(updatedData);
        setIsEditMode(false);
      })
      .catch((error) => console.error(error));
    }

  return (
    <div id="view-page">
      <Header />
      <div className="row">
      <div className="col-md-2">
            <RecentItems/>
          </div>
          <div className="col-md-10">
            {lenthData.length > 0 ? (
      <div className="contactAccount fulid-section">
        <div className="page-header btn-margin " id="add-account">
          <div>
            <h2 className="main-content-title tx-24 mg-b-5">
              Contacts Details
            </h2>
            <ol className="breadcrumb">
              <li className="breadcrumb-item active" aria-current="page">
                Dashboard / Contact / Contacts details
              </li>
            </ol>
          </div>
        </div>
        {isEditMode ? (

         <div className="tasks">

            <>
            {/* {console} */}

            <p className='edit-btn'>
            <input
      type="submit"
      className="account-save"
      onClick={handleSaveEdit}
      value={isSubmitted ? "Saving" : "Save"}
      disabled={isSubmitted}
    />
     <button className="btn btn-primary" onClick={handleCancelEdit}>Cancel</button>


     </p>
     <Row className="mb-3">

      <div className='col-md-12'>
      <h4 className='heading'>Contact Information</h4>
        </div>

        <Form.Group
              as={Col}
              md="6"
              id=''
            >
              <FloatingLabel controlId="floatingSelect" className='dropDown' label="Salutation">
      <Form.Select aria-label="Floating label select example"
      name="saluation"
      placeholder="Salutation"
      tabIndex={1}
      defaultValue={datas.saluation || ""}
     onChange={handleChangeInput}
      >
        <option value='' disabled>--None--</option>
        <option value="Mr">Mr</option>
        <option value="Ms">Ms</option>
        <option value="Mrs">Mrs</option>
        <option value="Dr">Dr</option>
        <option value="Prof">Prof</option>
      </Form.Select>
    </FloatingLabel>
    </Form.Group>
    <Form.Group
              as={Col}
              md="6"

            >
    <FloatingLabel
            controlId="floatingInput"
            label="contact owner"
            className="mb-3"
          >
            <Form.Control
              type="text"
              placeholder="contact owner"
              name="contact_owner"
              tabIndex={6}
              defaultValue={datas.contact_owner || ""}
     onChange={handleChangeInput}/>
          </FloatingLabel>

            </Form.Group>

    <Form.Group
              as={Col}
              md="6"

            >
    <FloatingLabel
            controlId="floatingInput"
            label="First Name"
            className="mb-3"
          >
            <Form.Control
              type="text"
              placeholder="First Name"
              name="first_name"
              tabIndex={2}
              defaultValue={datas.first_name || ""}
     onChange={handleChangeInput}
     />
          </FloatingLabel>

            </Form.Group>



        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Reports to"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Reports to"
            name="reports_to"
            tabIndex={7}
            defaultValue={datas.reports_to || ""}
     onChange={handleChangeInput}/>
          </FloatingLabel>
        </Form.Group>



            <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Last Name"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Last Name"
            name="last_name"
            tabIndex={3}
            className="error-validation"
             defaultValue={datas.last_name || ""}
     onChange={handleChangeInput}/>
          </FloatingLabel>
        </Form.Group>


        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Department"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Department"
            name="department"
            tabIndex={8}
            defaultValue={datas.department || ""}
     onChange={handleChangeInput}/>
          </FloatingLabel>
        </Form.Group>


        <Form.Group as={Col} md="6">
      <FloatingLabel controlId="floatingSelect" label="Account name" className="select-dropdown">
        <Select
        className="select"
          value={accountNamedrop}
          onChange={setAccountnameDropdown}
          options={options}
          tabIndex={4}
        />
      </FloatingLabel>
    </Form.Group>


        <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Lead Source">
    <Form.Select aria-label="Floating label select example"
    name="lead_source"
    id='account_name'
    tabIndex={9}
    placeholder="Lead Source"
    className="error-validation"
 defaultValue={datas.lead_source || ""}
     onChange={handleChangeInput}    >
      <option value="">--None--</option>
    <option value="Advertisement">Advertisement</option>
    <option value="Customer Event">Customer Event</option>
    <option value="Employee Referral">Employee Referral</option>
    <option value="Google AdWords">Google AdWords</option>
    <option value="Other">Other</option>
    <option value="Partner">Partner</option>
    <option value="Purchased List">Purchased List</option>
    <option value="Trade Show">Trade Show</option>
    <option value="Webinar">Webinar</option>
    <option value="Website">Website</option>
    <option value="Rajgopalan">Rajgopalan</option>
    <option value="Olivia">Olivia</option>

    </Form.Select>
  </FloatingLabel>
              </Form.Group>


              <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Birth Date"
            className="mb-3 datepick"


          >
         <DatePicker
          selected={startDate1}
          onChange={(date) => setStartDate1(date)}
          dateFormat="dd/MM/yyyy"
          placeholderText="Select a date"
          tabIndex={5}
      />


          </FloatingLabel>
        </Form.Group>



      </Row>
     <Row>
     <div className='col-md-12'>
     <h4 className='heading'>Contact Information</h4>
     </div>
     <Form.Group as={Col} md="6" id='contact-title'>
     <FloatingLabel
     controlId="floatingInput"
     label="Title"
     className="mb-3"
     >

     <Form.Control
     type="text"
     placeholder="Title"
     name="title"
     tabIndex={10}
     defaultValue={datas.title || ""}
     onChange={handleChangeInput}/>
     </FloatingLabel>
     </Form.Group>
     <Form.Group as={Col} md="6"></Form.Group>
     <Form.Group as={Col} md="6">
     <FloatingLabel
     controlId="floatingInput"
     label="Contact Email"
     className="mb-3"
     >

     <Form.Control type="text"
     placeholder="Contact Email"
     name="contact_email"
     tabIndex={11}
     defaultValue={datas.contact_email || ""}
     onChange={handleChangeInput}/>
     </FloatingLabel>
     </Form.Group>

     <Form.Group as={Col} md="6"></Form.Group>
     <Form.Group as={Col} md="6">
     <FloatingLabel
     controlId="floatingInput"
     label="Contact Mobile"
     className="mb-3"
     >

     <Form.Control type="text"
     placeholder="Contact Mobile"
     name="contact_mobile"
     tabIndex={12}
     defaultValue={datas.contact_mobile || ""}
     onChange={handleChangeInput}/>
     </FloatingLabel>
     </Form.Group>

     </Row>

     <Row>
     <div className='col-md-12'>
     <h4 className='heading'>Address Information</h4>
     </div>
     <Form.Group as={Col} md="6">
     <FloatingLabel
     controlId="floatingInput"
     label="Mail Street"
     className="mb-3"
     >

     <Form.Control type="text"
     placeholder="Mail Street"
     tabIndex={13}
     name="mail_street"
     defaultValue={datas.mail_street || ""}
     onChange={handleChangeInput}/>
     </FloatingLabel>
     </Form.Group>
     <Form.Group as={Col} md="6"></Form.Group>
     <Form.Group as={Col} md="6">
     <FloatingLabel
     controlId="floatingInput"
     label="Mail postal code"
     className="mb-3"
     >

     <Form.Control type="text"
     placeholder="Mail postal code"
     tabIndex={14}
     name="mail_postal_code"
     defaultValue={datas.mail_postal_code || ""}
     onChange={handleChangeInput}/>
     </FloatingLabel>
     </Form.Group>
     <Form.Group as={Col} md="6"></Form.Group>
     <Form.Group as={Col} md="6">
     <FloatingLabel
     controlId="floatingInput"
     label="Mail city"
     className="mb-3"
     >

     <Form.Control type="text"
     placeholder="Mail city"
     name="mail_city"
     tabIndex={15}
     defaultValue={datas.mail_city || ""}
     onChange={handleChangeInput}/>
     </FloatingLabel>
     </Form.Group>
     <Form.Group as={Col} md="6"></Form.Group>
     <Form.Group as={Col} md="6">
     <FloatingLabel
     controlId="floatingInput"
     label="Mail state"
     className="mb-3"
     >

     <Form.Control type="text"
     placeholder="Mail state"
     name="mail_state"
     tabIndex={16}
     defaultValue={datas.mail_state || ""}
     onChange={handleChangeInput}/>
     </FloatingLabel>
     </Form.Group>
     <Form.Group as={Col} md="6"></Form.Group>
     <Form.Group as={Col} md="6">
     <FloatingLabel
     controlId="floatingInput"
     label="Mail country"
     className="mb-3"
     >

     <Form.Control type="text"
     placeholder="Mail country"
     name="mail_country"
     tabIndex={17}
     defaultValue={datas.mail_country || ""}
     onChange={handleChangeInput}/>
     </FloatingLabel>
     </Form.Group>

     </Row>
     <p className='edit-btn'>
     <input
      type="submit"
      className="account-save"
      onClick={handleSaveEdit}
      value={isSubmitted ? "Saving" : "Save"}
      disabled={isSubmitted}
    />
     <button className="btn btn-primary" onClick={handleCancelEdit}>Cancel</button>


     </p> </>

         </div>
        ):(
          <>




         <div className="tasks">
         <div className="edit-delete">
                  <span id="edit" onClick={handleEditClick}>
                    Edit
                  </span>
                  <span>
                  <Link to={'/contact/contact-list'}> Cancel</Link>
                  </span>

                  </div>

             <>
             <div className="">
               <div id="contracts">
                 <div className="">
                   <div className="row">
                     <div className="col-md-12" id="head">
                       <h4 className="heading">Information</h4>
                       <hr></hr>
                     </div>
                   </div>
                   <table class="table table-bordered account-table tables">
                     <tbody>
                        <tr>
                         <td id="td-right">
                           <span>Contact Code</span>
                         </td>
                         <td>{datas.contact_code}</td>
                         <td id="td-right">
                           <span>contact owner</span>
                         </td>
                         <td>{datas.contact_owner}</td>
                       </tr>

                       <tr>
                         <td id="td-right">
                           <span>first name</span>
                         </td>
                         <td>{datas.first_name}</td>
                         <td id="td-right">
                           <span>reports to</span>
                         </td>
                         <td>{datas.reports_to}</td>
                       </tr>


                        <tr>
                         <td id="td-right">
                           <span>last name</span>
                         </td>
                         <td>{datas.last_name}</td>
                         <td id="td-right">
                           <span>department</span>
                         </td>
                         <td>{datas.department}</td>
                       </tr>


                        <tr>
                         <td id="td-right">
                           <span>account name</span>
                         </td>
                         <td><Link to={  "/accounts/" + lenthData[0].account_id} > {datas.account_name} </Link></td>
                         <td id="td-right">
                           <span>lead source</span>
                         </td>
                         <td>{datas.lead_source}</td>
                       </tr>


                        <tr>

                         <td id="td-right">
                           <span>birth Date</span>
                         </td>
                         <td>{datas.birthdate}</td>
                         <td id="td-right">
                           {/* <span>ContactCode</span> */}
                         </td>
                         <td>
                          {/* {datas.contact_code} */}
                          </td>
                       </tr>

                     </tbody>
                   </table>
                 </div>
                 <div className="">
                   <div className="row">
                     <div className="col-md-12" id="head">
                       <h4 className="heading">Contact Information</h4>
                       <hr></hr>
                     </div>
                   </div>
                   <table class="table table-bordered account-table tables">
                     <tbody>
                       <tr>
                         <td id="td-right">
                           <span>Title</span>
                         </td>
                         <td>{datas.title}</td>
                         <td id="td-right">
                           <span></span>
                         </td>
                         <td></td>
                       </tr>

                       <tr>

                         <td id="td-right">
                           <span>Contact Email</span>
                         </td>
                         <td>{datas.contact_email}</td>
                         <td id="td-right">
                           <span></span>
                         </td>
                         <td></td>
                       </tr>
                       <tr>
                         <td id="td-right">
                           <span>Contact Mobile</span>
                         </td>
                         <td>{datas.contact_mobile}</td>
                         <td id="td-right">
                           <span></span>
                         </td>
                         <td></td>
                       </tr>
                     </tbody>
                   </table>
                 </div>

                 <div className="">
                   <div className="row">
                     <div className="col-md-12" id="head">
                       <h4 className="heading">Address Information</h4>
                       <hr></hr>
                     </div>
                   </div>
                   <table class="table table-bordered account-table tables">
                     <tbody>
                       <tr>
                         <td id="td-right">
                           <span>Mail Street</span>
                         </td>
                         <td>{datas.mail_street}</td>
                         <td id="td-right">
                           <span></span>
                         </td>
                         <td></td>
                       </tr>
                       <tr>

                         <td id="td-right">
                           <span>Mail Postal Code</span>
                         </td>
                         <td>{datas.mail_postal_code}</td>
                         <td id="td-right">
                           <span></span>
                         </td>
                         <td></td>
                       </tr>

                       <tr>
                         <td id="td-right">
                           <span>Mail City</span>
                         </td>
                         <td>{datas.mail_city}</td>
                         <td id="td-right">
                           <span></span>
                         </td>
                         <td></td>
                       </tr>
                       <tr>

                         <td id="td-right">
                           <span>Mail State</span>
                         </td>
                         <td>{datas.mail_state}</td>
                         <td id="td-right">
                           <span></span>
                         </td>
                         <td></td>
                       </tr>
                       <tr>
                         <td id="td-right">
                           <span>Mail Country</span>
                         </td>
                         <td>{datas.mail_country}</td>
                         <td></td>
                       </tr>
                     </tbody>
                   </table>
                 </div>

                 <div className="">
                   <div className="row">
                     <div className="col-md-12" id="head">
                       <h4 className="heading">System Information</h4>
                       <hr></hr>
                     </div>
                   </div>
                   <table class="table table-bordered account-table tables">
                     <tbody>
                       <tr>
                         <td id="td-right">
                           <span>Created By
                           </span>
                         </td>
                         <td>{datas.created_by}</td>
                         <td id="td-right">
                           <span>Last Modified By

                           </span>
                         </td>
                         <td>{datas.modified_by}</td>
                       </tr>

                     </tbody>
                   </table>
                 </div>
               </div>
             </div>

            <div className="">
               <div id="contracts">


                 <Row id="table-styles">
                   <div className="col-md-12" id="adatas">
                     <h4 className="heading">
                       <span>Opportunity Information</span>
                       <span>
                       <Popup
                         trigger={
                           <a className="button new-btn">
                             {" "}
                              New{" "}
                           </a>
                         }
                         modal
                       >
                         <form
                           onSubmit={handleSubmit}
                           className="add-accounts"
                         >
                           <div className="form-group">
                             <label>Opportunity Record Type</label>
                             <br />
                             <select
                               name="account_record"
                               className="form-control"
                               onChange={handleChange}
                               value={formData.account_record}
                             >
                               <option>
                                 --Select Opportunity Record Type--
                               </option>
                               {/* <option value="Buyer">Buyer</option> */}
                               <option value="Buyer - Long Term">
                                 Buyer - Long Term
                               </option>
                               <option value="Buyer - Spot">
                                 Buyer - Spot
                               </option>
                               {/* <option value="Supplier">Supplier</option> */}
                               <option value="Supplier - Long Term">
                                 Supplier - Long Term
                               </option>
                               <option value="Supplier - Long Term">
                                 Supplier - Spot
                               </option>
                             </select>
                             <input
                               type="submit"
                               className="btn btn-primary "
                               value="Submit"
                             />
                           </div>
                         </form>
                       </Popup>
                       </span>
                     </h4>
                     <hr></hr>
                   </div>
                   <table id="quotes" className="">
                     <thead>
                       <tr>
                         <th>Opportunity Name</th>
                         <th>Stage</th>
                         <th>Amount</th>
                         <th>Close Date</th>
                       </tr>
                     </thead>

                     {opp.length > 0 ? (
                       <tbody>
                         {opp.map((y, i) => (
                           <tr>

                             <td>
                               <Link
                                 to={
                                   "/opportunity/opportunity-detail" +
                                   "/" +
                                   y.id
                                 }
                               >
                                 {y.opportunity_name}
                               </Link>
                             </td>

                             <td>{y.stage}</td>
                             <td>{y.amount}</td>
                             <td>{y.closedate}</td>
                           </tr>
                         ))}
                       </tbody>
                     ) : (
                       <tbody>
                         <tr>

                           <td></td>
                           <td></td>
                           <td>No data available</td>
                           <td></td>

                         </tr>
                       </tbody>
                     )}
                   </table>
                 </Row>

                 <Row id="table-styles">
                          <div className="col-md-12" id="head">
                            <h4 className="heading">
                              <span>Files</span>
                              <span>
                                <i className=""></i>
                                Upload Files
                              </span>
                            </h4>
                            <hr></hr>
                          </div>
                          <table id="history1" className="">
                            <tr>
                              <td>
                                <p class="no-records">No records to display</p>
                              </td>
                            </tr>
                          </table>
                        </Row>
                        <Row id="table-styles">
                          <div className="col-md-12" id="head">
                            <h4 className="heading">
                              <span>Notes</span>
                              <span>
                                <i className=""></i>
                                New Note
                              </span>
                            </h4>
                            <hr></hr>
                          </div>
                          <table id="history1" className="">
                            <tr>
                              <td>
                                <p class="no-records">No records to display</p>
                              </td>
                            </tr>
                          </table>
                        </Row>
                        <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Cases</span>
                                      <span>
                                        <i className=""></i>
                                        New Case
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history1" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">
                                          No records to display
                                        </p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>
                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Open Activities</span>
                                      <span>
                                        <i className=""></i>
                                        New Task
                                      </span>
                                      <span>
                                        <i className=""></i>
                                        New Event
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history1" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">
                                          No records to display
                                        </p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>
                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Activity History</span>
                                      <span>
                                        <i className=""></i>
                                        Log a Call
                                      </span>
                                      <span>
                                        <i className=""></i>
                                        Mail merge
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history1" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">
                                          No records to display
                                        </p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>
                                <Row id="table-styles">
                   <div className="col-md-12" id="head">
                     <h4 className="heading"><span>History Information</span></h4>
                     <hr></hr>
                   </div>
                   <table id="history" className="">
                     <thead>
                       <tr>
                         <th>Field Name</th>
                         <th>New Value</th>
                         <th>Old Value</th>
                         <th>Changed By</th>
                         <th>Change Time</th>
                       </tr>
                     </thead>

                     {historys.length > 0 ? (
                       <tbody>
                         {historys.map((y, i) => (
                           <tr>
                             <td>{y.field_name}</td>
                             <td>{y.new_value}</td>
                             <td>{y.old_value}</td>
                             <td>{y.changed_by}</td>
                             <td>{y.change_time}</td>
                           </tr>
                         ))}
                       </tbody>
                     ) : (
                       <tbody>
                         <tr>
                           <td></td>
                           <td></td>
                           <td>No data available</td>
                           <td></td>
                           <td></td>
                         </tr>
                       </tbody>
                     )}
                   </table>
                 </Row>

               </div>
             </div>
           </>

         </div>

       </>
        )
}
      </div>

      ):(
        <div className="tasks">
        <h1 className="no-data-detail"><img src='../../images/loading.gif' className="loading"/></h1>
        </div>
      )}
      </div>
</div>

      <Footer />
    </div>
  );
};

export default ContactViewPage;
