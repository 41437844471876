import React from 'react';

const PreviewTable = ({ data, headers }) => {

    // Function to generate the table rows based on data
    const generateTableRows = (data) => {
        return (
            <tbody>
                {data.map((item, index) => (
                    <tr key={index}>
                        {Object.keys(headers).map((key) => (
                            <td key={key}>{item[key]}</td>
                        ))}
                    </tr>
                ))}
            </tbody>
        );
    };

    // Function to generate the table footer with totals
    const generateTableFooter = (data) => {
        return (
            <tfoot>
                <tr>
                    <td colSpan={Object.keys(headers).length}>Grand Totals ({data.length} records)</td>
                </tr>
            </tfoot>
        );
    };

    return (
        <div className="preview">
        
            <table>
                <thead>
                    <tr>
                        {Object.values(headers).map((header, index) => (
                            <th key={index}>{header}</th>
                        ))}
                    </tr>
                </thead>
                {data.length > 0 ? generateTableRows(data) : (
                    <tbody>
                        <tr>
                            <td colSpan={Object.keys(headers).length}>No data was returned. Check report filters.</td>
                        </tr>
                    </tbody>
                )}
                {data.length > 0 && generateTableFooter(data)}
            </table>
        </div>
    );
};

export default PreviewTable