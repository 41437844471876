import React, {
  Component,
  useState,
  useEffect,
  useContext,
  forwardRef,
} from "react";
import {
  Card,
  Col,
  OverlayTrigger,
  Row, 
  Table,
} from "react-bootstrap";
import Editor from 'react-simple-wysiwyg';
import Tooltip from '../tooltip/Tooltip';
import { IoIosHelpCircle } from "react-icons/io";
import RecentItems from "../recentItems/RecentItems";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "datatables.net-dt/js/dataTables.dataTables.js";
import { AES, enc } from "crypto-js";
import "datatables.net-responsive-dt";
import { API_URL } from '../config';
import Select from 'react-select';
import { parse, format , isValid } from 'date-fns';
import Form from "react-bootstrap/Form";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "react-data-table-component-extensions/dist/index.css";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import DatePicker from "react-datepicker";
import { Link, json, useNavigate } from "react-router-dom";
import { TokenContext } from "../AuthProvider";
import Header from "../Header";
import Footer from "../Footer";
import {
  FaEnvelope,
  FaPhoneSquareAlt,
  FaMobileAlt,
  FaFax,
} from "react-icons/fa";
import axios from "axios";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { FaPencilAlt, FaTrashAlt, FaPlus } from "react-icons/fa";
// import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
import $ from "jquery";
import { useParams, useLocation } from "react-router-dom";
import Popup from "reactjs-popup";
const ViewOrder = () => {
  const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
  const encryptedAccessToken = localStorage.getItem("access_token");
  const decryptedAccessToken = encryptedAccessToken
    ? AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8)
    : null;
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  // const{contractShipID}=location.state;

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [preshipments, setPreshipments] = useState([]);
  const [postshipments, setPostshipments] = useState([]);
  const [oderData, setOrderData] = useState([]);
  const [history, setHistory] = useState([]);
  const id = params.id;
  const userRole = localStorage.getItem("role");
  const [radio, setRadio] = useState(0);
  const { username, token } = useContext(TokenContext);
  const [quotes, setQuotes] = useState([]);
  const [orderProduct, setOrderProduct] = useState([]);
  const [buyer, setBuyer] = useState([]);
  const [tcf, setTcf] = useState([]);
  const [rw, setRw] = useState([]);
  const [datasContract, setdatasContract]=useState([]);

  const [vesselNomination,setVesselNomination]= useState();



  function onChangeVesselNomination(e) {
    setVesselNomination(e.target.value);
  }


  useEffect(() => {
    fetchContractList();
  }, [decryptedAccessToken]);

  const fetchContractList = async () => {
    try {
      const response = await axios.get(API_URL+'get/all/purchase_contract', {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      console.log(response)
      const data = response.data.purchase_contract;
      
      setdatasContract(data);

    } catch (error) {
      console.error(error);
    }
  };


  // useEffect(() => {
  //   getOpportunity();
  // }, [decryptedAccessToken]);

  // const getOpportunity = async () => {
  //   try {
  //     const response = await axios.get(API_URL+'all/opportunity', {
  //       headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
  //     );
  //     const data = response;
  //     console.log(data)
  //     // setdatasContract(data);

  //   } catch (error) {
  //     console.error(error);
  //   }
  // };


  // Agent Date
  const [selectAgent, setSelectedAgent] = useState(null);
  const[norResult, setNorResult]= useState('');

  const handleDateTimeChange = (date) => {
    setSelectedAgent(date); // Set the selected date and time
    handleSelectAgent(date); // Automatically send date and time when changed
  };

  const formatDateForBackend = (date) => {
    if (!date) return ''; // Return empty string if date is null

    const formattedDate = new Date(date);

    const day = String(formattedDate.getDate()).padStart(2, '0');
    const month = String(formattedDate.getMonth() + 1).padStart(2, '0'); // Adding 1 because getMonth() returns zero-based month
    const year = formattedDate.getFullYear();
    const hours = String(formattedDate.getHours()).padStart(2, '0');
    const minutes = String(formattedDate.getMinutes()).padStart(2, '0');
    const meridian = formattedDate.getHours() >= 12 ? 'PM' : 'AM';

    const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes} ${meridian}`;

    return formattedDateTime;
  };


  const handleSelectAgent = (dateTime) => {
    const formattedDateTime = formatDateForBackend(dateTime);
    setNorResult(formattedDateTime)
    // console.log('Formatted date and time:', formattedDateTime);
  };
  // date
  const [laycanFromDate, setLaycanFromDate] = useState(null);
  const [laycanToDateEdit, setLaycanToEdit] = useState(null);

  const [laycanFrom, setLaycanFrom] = useState("");
  const [laycanFrom1, setLaycanFrom1] = useState("");




   // Agent Date
   const [selectAgent1, setSelectedAgent1] = useState(null);
   const[norResult1, setNorResult1]= useState('');

   const handleDateTimeChange1 = (date) => {
     setSelectedAgent1(date); // Set the selected date and time
     handleSelectAgent1(date); // Automatically send date and time when changed
   };

   const formatDateForBackend1 = (date) => {
     if (!date) return ''; // Return empty string if date is null

     const formattedDate = new Date(date);

     const day = String(formattedDate.getDate()).padStart(2, '0');
     const month = String(formattedDate.getMonth() + 1).padStart(2, '0'); // Adding 1 because getMonth() returns zero-based month
     const year = formattedDate.getFullYear();
     const hours = String(formattedDate.getHours()).padStart(2, '0');
     const minutes = String(formattedDate.getMinutes()).padStart(2, '0');
     const meridian = formattedDate.getHours() >= 12 ? 'PM' : 'AM';

     const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes} ${meridian}`;

     return formattedDateTime;
   };


   const handleSelectAgent1 = (dateTime) => {
     const formattedDateTime = formatDateForBackend1(dateTime);
     setNorResult1(formattedDateTime)
    //  console.log('Formatted date and time:', formattedDateTime);
   };





   // Agent Date
   const [selectAgent2, setSelectedAgent2] = useState(null);
   const[norResult2, setNorResult2]= useState('');

   const handleDateTimeChange2 = (date) => {
     setSelectedAgent2(date); // Set the selected date and time
     handleSelectAgent2(date); // Automatically send date and time when changed
   };

   const formatDateForBackend2 = (date) => {
     if (!date) return ''; // Return empty string if date is null

     const formattedDate = new Date(date);

     const day = String(formattedDate.getDate()).padStart(2, '0');
     const month = String(formattedDate.getMonth() + 1).padStart(2, '0'); // Adding 1 because getMonth() returns zero-based month
     const year = formattedDate.getFullYear();
     const hours = String(formattedDate.getHours()).padStart(2, '0');
     const minutes = String(formattedDate.getMinutes()).padStart(2, '0');
     const meridian = formattedDate.getHours() >= 12 ? 'PM' : 'AM';

     const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes} ${meridian}`;

     return formattedDateTime;
   };


   const handleSelectAgent2 = (dateTime) => {
     const formattedDateTime = formatDateForBackend2(dateTime);
     setNorResult2(formattedDateTime)
    //  console.log('Formatted date and time:', formattedDateTime);
   };



   // Agent Date
   const [selectAgent3, setSelectedAgent3] = useState(null);
   const[norResult3, setNorResult3]= useState('');

   const handleDateTimeChange3 = (date) => {
     setSelectedAgent3(date); // Set the selected date and time
     handleSelectAgent3(date); // Automatically send date and time when changed
   };

   const formatDateForBackend3 = (date) => {
     if (!date) return ''; // Return empty string if date is null

     const formattedDate = new Date(date);

     const day = String(formattedDate.getDate()).padStart(2, '0');
     const month = String(formattedDate.getMonth() + 1).padStart(2, '0');
     const year = formattedDate.getFullYear();
     const hours = String(formattedDate.getHours()).padStart(2, '0');
     const minutes = String(formattedDate.getMinutes()).padStart(2, '0');
     const meridian = formattedDate.getHours() >= 12 ? 'PM' : 'AM';

     const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes} ${meridian}`;

     return formattedDateTime;
   };


   const handleSelectAgent3 = (dateTime) => {
     const formattedDateTime = formatDateForBackend3(dateTime);
     setNorResult3(formattedDateTime)
    //  console.log('Formatted date and time:', formattedDateTime);
   };



   // Agent Date
   const [selectAgent4, setSelectedAgent4] = useState(null);
   const[norResult4, setNorResult4]= useState('');

   const handleDateTimeChange4 = (date) => {
     setSelectedAgent4(date); // Set the selected date and time
     handleSelectAgent4(date); // Automatically send date and time when changed
   };

   const formatDateForBackend4 = (date) => {
     if (!date) return ''; // Return empty string if date is null

     const formattedDate = new Date(date);

     const day = String(formattedDate.getDate()).padStart(2, '0');
     const month = String(formattedDate.getMonth() + 1).padStart(2, '0'); // Adding 1 because getMonth() returns zero-based month
     const year = formattedDate.getFullYear();
     const hours = String(formattedDate.getHours()).padStart(2, '0');
     const minutes = String(formattedDate.getMinutes()).padStart(2, '0');
     const meridian = formattedDate.getHours() >= 12 ? 'PM' : 'AM';

     const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes} ${meridian}`;

     return formattedDateTime;
   };


   const handleSelectAgent4 = (dateTime) => {
     const formattedDateTime = formatDateForBackend4(dateTime);
     setNorResult4(formattedDateTime)
    //  console.log('Formatted date and time:', formattedDateTime);
   };


   // Agent Date
   const [selectAgent5, setSelectedAgent5] = useState(null);
   const[norResult5, setNorResult5]= useState('');

   const handleDateTimeChange5 = (date) => {
     setSelectedAgent5(date); // Set the selected date and time
     handleSelectAgent5(date); // Automatically send date and time when changed
   };

   const formatDateForBackend5 = (date) => {
     if (!date) return ''; // Return empty string if date is null

     const formattedDate = new Date(date);

     const day = String(formattedDate.getDate()).padStart(2, '0');
     const month = String(formattedDate.getMonth() + 1).padStart(2, '0'); // Adding 1 because getMonth() returns zero-based month
     const year = formattedDate.getFullYear();
     const hours = String(formattedDate.getHours()).padStart(2, '0');
     const minutes = String(formattedDate.getMinutes()).padStart(2, '0');
     const meridian = formattedDate.getHours() >= 12 ? 'PM' : 'AM';

     const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes} ${meridian}`;

     return formattedDateTime;
   };


   const handleSelectAgent5 = (dateTime) => {
     const formattedDateTime = formatDateForBackend5(dateTime);
     setNorResult5(formattedDateTime)
    //  console.log('Formatted date and time:', formattedDateTime);
   };


   // Agent Date
   const [selectAgent6, setSelectedAgent6] = useState(null);
   const[norResult6, setNorResult6]= useState('');

   const handleDateTimeChange6 = (date) => {
     setSelectedAgent6(date); // Set the selected date and time
     handleSelectAgent6(date); // Automatically send date and time when changed
   };

   const formatDateForBackend6 = (date) => {
     if (!date) return ''; // Return empty string if date is null

     const formattedDate = new Date(date);

     const day = String(formattedDate.getDate()).padStart(2, '0');
     const month = String(formattedDate.getMonth() + 1).padStart(2, '0'); // Adding 1 because getMonth() returns zero-based month
     const year = formattedDate.getFullYear();
     const hours = String(formattedDate.getHours()).padStart(2, '0');
     const minutes = String(formattedDate.getMinutes()).padStart(2, '0');
     const meridian = formattedDate.getHours() >= 12 ? 'PM' : 'AM';

     const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes} ${meridian}`;

     return formattedDateTime;
   };


   const handleSelectAgent6 = (dateTime) => {
     const formattedDateTime = formatDateForBackend6(dateTime);
     setNorResult6(formattedDateTime)
    //  console.log('Formatted date and time:', formattedDateTime);
   };



   // Agent Date
   const [selectAgent7, setSelectedAgent7] = useState(null);
   const[norResult7, setNorResult7]= useState('');

   const handleDateTimeChange7 = (date) => {
     setSelectedAgent7(date); // Set the selected date and time
     handleSelectAgent7(date); // Automatically send date and time when changed
   };

   const formatDateForBackend7 = (date) => {
     if (!date) return ''; // Return empty string if date is null

     const formattedDate = new Date(date);

     const day = String(formattedDate.getDate()).padStart(2, '0');
     const month = String(formattedDate.getMonth() + 1).padStart(2, '0'); // Adding 1 because getMonth() returns zero-based month
     const year = formattedDate.getFullYear();
     const hours = String(formattedDate.getHours()).padStart(2, '0');
     const minutes = String(formattedDate.getMinutes()).padStart(2, '0');
     const meridian = formattedDate.getHours() >= 12 ? 'PM' : 'AM';

     const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes} ${meridian}`;

     return formattedDateTime;
   };


   const handleSelectAgent7 = (dateTime) => {
     const formattedDateTime = formatDateForBackend7(dateTime);
     setNorResult7(formattedDateTime)
    //  console.log('Formatted date and time:', formattedDateTime);
   };


   // Agent Date
   const [selectAgent8, setSelectedAgent8] = useState(null);
   const[norResult8, setNorResult8]= useState('');

   const handleDateTimeChange8 = (date) => {
     setSelectedAgent8(date); // Set the selected date and time
     handleSelectAgent8(date); // Automatically send date and time when changed
   };

   const formatDateForBackend8 = (date) => {
     if (!date) return ''; // Return empty string if date is null

     const formattedDate = new Date(date);

     const day = String(formattedDate.getDate()).padStart(2, '0');
     const month = String(formattedDate.getMonth() + 1).padStart(2, '0'); // Adding 1 because getMonth() returns zero-based month
     const year = formattedDate.getFullYear();
     const hours = String(formattedDate.getHours()).padStart(2, '0');
     const minutes = String(formattedDate.getMinutes()).padStart(2, '0');
     const meridian = formattedDate.getHours() >= 12 ? 'PM' : 'AM';

     const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes} ${meridian}`;

     return formattedDateTime;
   };


   const handleSelectAgent8 = (dateTime) => {
     const formattedDateTime = formatDateForBackend8(dateTime);
     setNorResult8(formattedDateTime)
    //  console.log('Formatted date and time:', formattedDateTime);
   };




  const parseDate = (dateString) => {
    if (!dateString) return null;
    const [day, month, year] = dateString.split("/");
    return new Date(`${year}-${month}-${day}`);
  };
  const formatDate = (date) => {
    if (!date) return "";
    return date.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "numeric",
      year: "numeric",
    });
  };





  const parseDateLaycan = (dateString) => {
    if (!dateString) return null;
    const [day, month, year] = dateString.split("/");
    return new Date(`${year}-${month}-${day}`);
  };

  const formatDate1 = (date) => {
    if (!date) return "";
    return date.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "numeric",
      year: "numeric",
    });
  };


  const formatAmount = (amount) => {
    // Ensure the amount is a number
    let num = Number(amount);
    if (isNaN(num)) {
      return 'Invalid amount'; // Fallback for non-numeric values
    }

    // Ensure the number is negative
    if (num > 0) {
      num = -num;
    }

    // Format as (-number)
    return `(-${Math.abs(num).toLocaleString()})`;
  };

  useEffect(() => {
    const initialDate = parseDate(oderData.laycan_from_date);
    setLaycanFromDate(initialDate);
    const formatted = formatDate(initialDate);
    setLaycanFrom(formatted);



    const parseDateLaycanto = parseDateLaycan(oderData.laycan_to_date);
    setLaycanToEdit(parseDateLaycanto);
    const formatted1 = formatDate(initialDate);
    setLaycanFrom1(formatted1);



  }, [oderData.laycan_from_date, oderData.laycan_to_date]);

  const handleDateChange = (date) => {
    setLaycanFromDate(date);
    setLaycanFrom(formatDate(date));
  };
  const handleLaycantoDate = (date) => {
    setLaycanToEdit(date);
    setLaycanFrom1(formatDate(date));
  };

  // -----------------------------
  // date siva
  const [endDateError, setEndDateError] = useState('');
  const handleDateChangesOne = (date) => {
    setDate1(date);
  };
  const handleDateChangesOne1 = (date) => {
    setDate1_1(date);
  };
  const handleDateChangesTwo = (date) => {
    setDate2(date);
  };
  const handleDateChangesThree = (date) => {
    setDate3(date);
    if (!date2) {
      setEndDateError('Please select a Laycan From Date	.');
    } else {
      const diffInTime = date.getTime() - date2.getTime();
      const diffInDays = diffInTime / (1000 * 3600 * 24);
      if (diffInDays !== 10) {
        setEndDateError('Laycan to Date must be exactly 10 days after the Laycan From Date ');
      } else {
        setEndDateError('');
      }
    }
  };
  const handleDateChangesFour = (date) => {
    setDate4(date);
  };
  const handleDateChangesFive = (date) => {
    setDate5(date);
  };
  const handleDateChangesSix = (date) => {
    setDate6(date);
  };
  const handleDateChangesSeven = (date) => {
    setDate7(date);
  };
  const handleDateChangesSeven1 = (date) => {
    setDate71(date);
  };

  const handleDateChangesEight = (date) => {
    setDate8(date);
  };
  const handleDateChangesEights = (date) => {
    setDate88(date);
  };
  const handleDateChangesNine = (date) => {
    setDate9(date);
  };
  const handleDateChangesTen = (date) => {
    setDate10(date);

  };
  const handleDateChangesTwele = (date) => {
    setDate12(date);
  };
  const handleDateChangesThirteen = (date) => {
    setDate13(date);
  };
  const handleDateChangesfourteen = (date) => {
    setDate14(date);
  };
  const handleDateChangesfifteen = (date) => {
    setDate15(date);
  };
  const handleDateChangesSisteen = (date) => {
    setDate16(date);
  };
  const handleDateChangesSeventeen = (date) => {
    setDate17(date);
  };
  const handleDateChangesEighteen = (date) => {
    setDate18(date);
  };
  const handleDateChangesNineteen = (date) => {
    setDate19(date);
  };
  const handleDateChangesTwenty = (date) => {
    setDate20(date);
  };
  const handleDateChangesTwentyOne = (date) => {
    setDate21(date);
  };
  const handleDateChangesTwentytwo = (date) => {
    setDate22(date);
  };
  const handleDateChangesTwentyThree = (date) => {
    setDate23(date);
  };


  const options = { minimumFractionDigits: 2, maximumFractionDigits: 2 };
const formatNumber = (number) => {
  return new Intl.NumberFormat("en-US", options).format(number);
};

const renderShipmentQty = (tcfValues) => {
  if (tcfValues > 0) {
    return <span className='sr'>{formatNumber(tcfValues)}</span>;
  } else if (tcfValues < 0) {
    return <span className='sr'>({formatNumber(Math.abs(tcfValues))})</span>;
  } else {
    return <span className='sr'>{formatNumber(tcfValues)}</span>;
  }
};


  const handleDateChangesTwentyFour = (date) => {
    setDate24(date);
  };
  const handleDateChangesTwentyFive = (date) => {
    setDate25(date);
  };


  // datepicker
  const [orderStart, setOrderStart] = useState(new Date());
  const orderstartDate =
    orderStart.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    orderStart.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    orderStart.toLocaleDateString("en-US", { day: "2-digit" });
  const CustomOrder = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));

  const [laycantoDate, setLaycanToDate] = useState(new Date());
  const laycanToDates =
    laycantoDate.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    laycantoDate.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    laycantoDate.toLocaleDateString("en-US", { day: "2-digit" });
  const Customlaycantodate = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));

  const [shippingDate, setShippingDate] = useState(new Date());
  const shipping_date =
    shippingDate.toLocaleDateString("en-US", { day: "2-digit", month: "2-digit", year: "numeric" }) +
    " " +
    shippingDate.toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit", hour12: false });

  const Customshipping = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));

  const [eta1, setEta1] = useState(new Date());
  const etaDate1 =
    eta1.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    eta1.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    eta1.toLocaleDateString("en-US", { day: "2-digit" });
  const CustomEta1 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));

  const [eta2, setEta2] = useState(new Date());
  const etaDate2 =
    eta2.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    eta2.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    eta2.toLocaleDateString("en-US", { day: "2-digit" });
  const CustomEta2 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));

  const [eta3, setEta3] = useState(new Date());
  const etaDate3 =
    eta3.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    eta3.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    eta3.toLocaleDateString("en-US", { day: "2-digit" });
  const CustomEta3 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));

  const [eta4, setEta4] = useState(new Date());
  const etaDate4 =
    eta4.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    eta4.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    eta4.toLocaleDateString("en-US", { day: "2-digit" });
  const CustomEta4 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));

  const [eta5, setEta5] = useState(new Date());
  const etaDate5 =
    eta5.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    eta5.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    eta5.toLocaleDateString("en-US", { day: "2-digit" });
  const CustomEta5 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));

  const [confirmDate1, setConfirmDate1] = useState(new Date());
  const confirmDates1 =
    confirmDate1.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    confirmDate1.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    confirmDate1.toLocaleDateString("en-US", { day: "2-digit" });
  const CustomConfirmDate1 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));

  const [confirmDate, setConfirmDate] = useState(new Date());
  const confirmDates =
    confirmDate.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    confirmDate.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    confirmDate.toLocaleDateString("en-US", { day: "2-digit" });
  const CustomConfirmDate = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));

  const [startDate1, setStartDate1] = useState(new Date());
  const datepick1 =
    startDate1.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    startDate1.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    startDate1.toLocaleDateString("en-US", { day: "2-digit" });
  const CustomInput1 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));
  const [startDate2, setStartDate2] = useState(new Date());
  const datepick2 =
    startDate2.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    startDate1.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    startDate1.toLocaleDateString("en-US", { day: "2-digit" });
  const CustomInput2 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));

  const [startDate3, setStartDate3] = useState(new Date());
  const datepick3 =
    startDate3.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    startDate1.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    startDate1.toLocaleDateString("en-US", { day: "2-digit" });
  const CustomInput3 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));


  const [startDate4, setStartDate4] = useState(new Date());
  const datepick4 =
    startDate4.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    startDate1.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    startDate1.toLocaleDateString("en-US", { day: "2-digit" });
  const CustomInput4 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));

  const [startDate5, setStartDate5] = useState(new Date());
  const datepick5 =
    startDate5.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    startDate5.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    startDate5.toLocaleDateString("en-US", { day: "2-digit" });
  const CustomInput5 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));
  const [startDate6, setStartDate6] = useState(new Date());
  const datepick6 =
    startDate6.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    startDate6.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    startDate6.toLocaleDateString("en-US", { day: "2-digit" });
  const CustomInput6 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));
  const [startDate7, setStartDate7] = useState(new Date());
  const datepick7 =
    startDate7.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    startDate7.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    startDate7.toLocaleDateString("en-US", { day: "2-digit" });
  const CustomInput7 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));
  const [startDate8, setStartDate8] = useState(new Date());
  const datepick8 =
    startDate8.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    startDate8.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    startDate8.toLocaleDateString("en-US", { day: "2-digit" });
  const CustomInput8 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));
  const [startDate9, setStartDate9] = useState(new Date());
  const datepick9 =
    startDate9.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    startDate9.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    startDate9.toLocaleDateString("en-US", { day: "2-digit" });
  const CustomInput9 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));
  const [startDate10, setStartDate10] = useState(new Date());
  const datepick10 =
    startDate10.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    startDate10.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    startDate10.toLocaleDateString("en-US", { day: "2-digit" });
  const CustomInput10 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));

  const [startDate11, setStartDate11] = useState(new Date());
  const datepick11 =
    startDate11.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    startDate11.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    startDate11.toLocaleDateString("en-US", { day: "2-digit" });
  const CustomInput11 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));

  const [startDate12, setStartDate12] = useState(new Date());
  const datepick12 =
    startDate12.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    startDate12.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    startDate12.toLocaleDateString("en-US", { day: "2-digit" }) +
    startDate12.toLocaleTimeString("en-US", " ");
  const CustomInput12 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));
  const [startDate13, setStartDate13] = useState(new Date());
  const datepick13 =
    startDate13.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    startDate13.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    startDate13.toLocaleDateString("en-US", { day: "2-digit" });
  // startDate13.toLocaleTimeString('en-US',' ');
  const CustomInput13 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));
  const [startDate14, setStartDate14] = useState(new Date());
  const datepick14 =
    startDate14.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    startDate14.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    startDate14.toLocaleDateString("en-US", { day: "2-digit" }) +
    startDate14.toLocaleTimeString("en-US", " ");
  const CustomInput14 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));
  const [startDate15, setStartDate15] = useState(new Date());
  const datepick15 =
    startDate15.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    startDate15.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    startDate15.toLocaleDateString("en-US", { day: "2-digit" }) +
    " " +
    startDate15.toLocaleTimeString("en-US", " ");
  const CustomInput15 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));
  const [startDate16, setStartDate16] = useState(new Date());
  const datepick16 =
    startDate16.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    startDate16.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    startDate16.toLocaleDateString("en-US", { day: "2-digit" }) +
    startDate16.toLocaleTimeString("en-US", " ");
  const CustomInput16 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));

  const [startDate17, setStartDate17] = useState(new Date());
  const datepick17 =
    startDate17.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    startDate17.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    startDate17.toLocaleDateString("en-US", { day: "2-digit" }) +
    startDate17.toLocaleTimeString("en-US", " ");
  const CustomInput17 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));

  const [startDate18, setStartDate18] = useState(new Date());
  const datepick18 =
    startDate18.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    startDate18.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    startDate18.toLocaleDateString("en-US", { day: "2-digit" }) +
    startDate18.toLocaleTimeString("en-US", " ");
  const CustomInput18 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));
  const [startDate19, setStartDate19] = useState(new Date());
  const datepick19 =
    startDate19.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    startDate19.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    startDate19.toLocaleDateString("en-US", { day: "2-digit" }) +
    startDate19.toLocaleTimeString("en-US", " ");
  const CustomInput19 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));

  const [company, setCompany] = useState([]);
  useEffect(() => {
    fetchDataAccount();
  }, [decryptedAccessToken]);

  const fetchDataAccount = async () => {
    try {
      const response = await axios.get(
        API_URL+"get/all/rwoodcompany",
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data.rwoodcompany;
      setCompany(data);
      // console.log(data);
    } catch (error) {
      console.error(error);
    }
  };
  const [accountNames, setAccountName] = useState([]);

  useEffect(() => {
    fetchDataSupplier();
  }, [decryptedAccessToken]);

  const fetchDataSupplier = async () => {
    try {
      const response = await axios.get(
        API_URL+"all/accounts",
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data.accounts;
      setAccountName(data);
      // console.log("Supplier" + JSON.stringify(data));
    } catch (error) {
      console.error(error);
    }
  };

  const [suppliers, setSupplier] = useState([]);
  useEffect(() => {
    fetchData5();
  }, [decryptedAccessToken]);

  const fetchData5 = async () => {
    try {
      const response = await axios.get(
        API_URL+"get/supplier",
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data;
      console.log(data);
      setSupplier(data);
    } catch (error) {
      console.error(error);
    }
  };

  const [agents, setAgents] = useState([]);

  useEffect(() => {
    fetchDataAccount2();
  }, [decryptedAccessToken]);

  const fetchDataAccount2 = async () => {
    try {
      const response = await axios.get(API_URL+"get/agent", {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },
      });
      const data = response.data;
      setAgents(data);
      // console.log(data);
    } catch (error) {
      console.error(error);
    }
  };

  const [agent, setAgent] = useState([]);

  useEffect(() => {
    fetchDataSupplier1();
  }, [decryptedAccessToken]);

  const fetchDataSupplier1 = async () => {
    try {
      const response = await axios.get(
        API_URL+"get/surveyor",
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data;
      setAgent(data);
      // console.log("Supplier" + JSON.stringify(data));
    } catch (error) {
      console.error(error);
    }
  };

  const [contractNumber, setContractNumber] = useState([]);

  useEffect(() => {
    fetchDataAccount1();
  }, [decryptedAccessToken]);

  const fetchDataAccount1 = async () => {
    try {
      const response = await axios.get(
        API_URL+"get/all/contract",
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data.contract;
      setContractNumber(data);

    } catch (error) {
      console.error(error);
    }
  };

  console.log(contractNumber);
  function handleChangeRadio(e) {
    const { nodeName, value } = e.target;
    if (nodeName === "INPUT") {
      setRadio(value);
    }
  }

  const [formDatatcf, setFromdatatcf] = useState({
    account_record: "",
  });

  const handleChangetcf = (e) => {
    setFromdata({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmittcf = (event) => {
    event.preventDefault();

    const encryptedAccessToken = localStorage.getItem("access_token");
    const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
    if (encryptedAccessToken) {
      const decryptedAccessToken = AES.decrypt(
        encryptedAccessToken,
        secret_key
      ).toString(enc.Utf8);

      fetch(API_URL+"add/record", {
        method: "POST",
        body: JSON.stringify(formData),
        headers: {
          Authorization: `Bearer ${decryptedAccessToken}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())

        .then((response) => {
          // console.log(response)
          // console.log(response.status)
          // if (response.message === "record addedd successfully") {
          const account_record_new = response.account_type;

          navigate("/order/order-by-tcf/" + id, {
            state: { account_record: account_record_new },
          });

          // }
        });
    }
  };
  const [receipt, setReceipt] = useState([]);
  const [dataLength, setDataLength] = useState([]);
  const [date1, setDate1] = useState('');
  const [date2, setDate2] = useState('');
  const [date3, setDate3] = useState('');
  const [date4, setDate4] = useState('');
  const [date5, setDate5] = useState('');
  const [date6, setDate6] = useState('');
  const [date7, setDate7] = useState('');
  const [date71, setDate71] = useState('');
  const [date8, setDate8] = useState('');
  const [date88, setDate88] = useState('');
  const [date9, setDate9] = useState('');
  const [date10, setDate10] = useState('');
  const [date11, setDate11] = useState('');
  const [date1_1, setDate1_1]= useState('');
  const [date12, setDate12] = useState('');
  const [date13, setDate13] = useState('');
  const [date14, setDate14] = useState('');
  const [date15, setDate15] = useState('');
  const [date16, setDate16] = useState('');
  const [date17, setDate17] = useState('');
  const [date18, setDate18] = useState('');
  const [date19, setDate19] = useState('');
  const [date20, setDate20] = useState('');
  const [date21, setDate21] = useState('');
  const [date22, setDate22] = useState('');
  const [date23, setDate23] = useState('');
  const [date24, setDate24] = useState('');
  const [date25, setDate25] = useState('');




  const [lockeds, setLockeds] = useState(null);
  const [supplierCheck, setSupplierCheck] = useState(null);
  const [supplierChecks, setSupplierChecks] = useState(null);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false); // Button state

  const chek_bok = oderData.locked;
  const chek_bok1 = oderData.supplier_check_status;
  const chek_bok2 = oderData.buyer_check_status;
  useEffect(() => {
    setLockeds(chek_bok); // Update lockeds with the value of tt
    setSupplierCheck(chek_bok1)
    setSupplierChecks(chek_bok2)
  }, [chek_bok,chek_bok1,chek_bok2]); // Run this effect whenever tt changes

  useEffect(() => {
    console.log("Value of lockeds:", supplierCheck); // Log lockeds
  }, [lockeds, supplierCheck, supplierChecks]); // Run

  const handleCheckboxChange1 = (e) => {
    setLockeds(e.target.checked);
  };

  const handleCheckboxChange2 = (e) => {
    setSupplierCheck(e.target.checked);
  };
  const handleCheckboxChange3 = (e) => {
    setSupplierChecks(e.target.checked);
  };

  useEffect(() => {
    setIsButtonEnabled(true);
  }, []);






  const fetchData = async () => {
    try {
      const response = await axios.get(
        API_URL+"get/orderbyID/" + id,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      if (response.status === 200) {
        console.log(response);

        const data = response.data;
        const order_details = response.data.order_detail[0];
        setDataLength(response.data.order_detail.length);
        setVesselNomination(response.data.order_detail[0].vessel_nomination_description)
      const dates1 = order_details.order_start_date?parse(order_details.order_start_date, 'dd/MM/yyyy', new Date()):null;
      setDate1(dates1);
      const dates2 = order_details.laycan_from_date?parse(order_details.laycan_from_date, 'dd/MM/yyyy', new Date()):null;
      setDate2(dates2);
      const dates3 = order_details.laycan_to_date?parse(order_details.laycan_to_date, 'dd/MM/yyyy', new Date()):null;
      setDate3(dates3);
      const dates4 = order_details.eta_date?parse(order_details.eta_date, 'dd/MM/yyyy', new Date()):null;
      setDate4(dates4);
      const dates5 = order_details.business_confirm_to_supplier_date?parse(order_details.business_confirm_to_supplier_date, 'dd/MM/yyyy', new Date()):null;
      setDate5(dates5);
      const dates6 = order_details.etb_date?parse(order_details.etb_date, 'dd/MM/yyyy', new Date()):null;
      setDate6(dates6);
      const dates7 = order_details.vessel_nomination_date?parse(order_details.vessel_nomination_date, 'dd/MM/yyyy', new Date()):null;
      setDate7(dates7);
      const dates71 = order_details.vessel_nomination_confirm_date?parse(order_details.vessel_nomination_confirm_date, 'dd/MM/yyyy', new Date()):null;
      setDate71(dates71);
      const dates8 = order_details.vessel_acceptance_date?parse(order_details.vessel_acceptance_date, 'dd/MM/yyyy', new Date()):null;
      setDate8(dates8);
      const dates9 = order_details.vessel_acceptance_confirm_date?parse(order_details.vessel_acceptance_confirm_date, 'dd/MM/yyyy', new Date()):null;
      setDate9(dates9);
      const dates88 = order_details.agent_appointment_date?parse(order_details.agent_appointment_date, 'dd/MM/yyyy', new Date()):null;
      setDate88(dates88);
      const dates12 = order_details.shipping_instruction_date?parse(order_details.shipping_instruction_date, 'dd/MM/yyyy', new Date()):null;
      setDate12(dates12);
      const dates13 = order_details.eta_notice_recieved_date1?parse(order_details.eta_notice_recieved_date1, 'dd/MM/yyyy', new Date()):null;
      setDate13(dates13);
      const dates14 = order_details.eta_notice_recieved_date2?parse(order_details.eta_notice_recieved_date2, 'dd/MM/yyyy', new Date()):null;
      setDate14(dates14);
      const dates15 = order_details.eta_notice_recieved_date3?parse(order_details.eta_notice_recieved_date3, 'dd/MM/yyyy', new Date()):null;
      setDate15(dates15);
      const dates16 = order_details.eta_notice_recieved_date4?parse(order_details.eta_notice_recieved_date4, 'dd/MM/yyyy', new Date()):null;
      setDate16(dates16);
      const dates17 = order_details.eta_notice_recieved_date5?parse(order_details.eta_notice_recieved_date5, 'dd/MM/yyyy', new Date()):null;
      setDate17(dates17);

      const dates18 = order_details.nomination_of_independent_surveyor_date?parse(order_details.nomination_of_independent_surveyor_date, 'dd/MM/yyyy', new Date()):null;
      setDate18(dates18);
      const dates19 = order_details.additional_surveyor_by_rwood_confirm_date?parse(order_details.additional_surveyor_by_rwood_confirm_date, 'dd/MM/yyyy', new Date()):null;
      setDate19(dates19);
      const dates20 = order_details.bl_date?parse(order_details.bl_date, 'dd/MM/yyyy', new Date()):null;
      setDate20(dates20);
      const dates20_1 = order_details.notice_of_readiness_confirm_date?parse(order_details.notice_of_readiness_confirm_date, 'dd/MM/yyyy', new Date()):null;
      setDate1_1(dates20_1);


      const dates21 = order_details.all_expected_value_date?parse(order_details.all_expected_value_date, 'dd/MM/yyyy', new Date()):null;
      setDate21(dates21);
      const dates22 = order_details.ltc_sent_to_buyer_date?parse(order_details.ltc_sent_to_buyer_date, 'dd/MM/yyyy', new Date()):null;
      setDate22(dates22);
      const dates23 = order_details.supplier_confirm_date?parse(order_details.supplier_confirm_date, 'dd/MM/yyyy', new Date()):null;
      setDate23(dates23);
      const dates24 = order_details.ltc_sent_to_supplier_date?parse(order_details.ltc_sent_to_supplier_date, 'dd/MM/yyyy', new Date()):null;
      setDate24(dates24);
      const dates25 = order_details.buyer_confirm_date?parse(order_details.buyer_confirm_date, 'dd/MM/yyyy', new Date()):null;
      setDate25(dates25);

console.log(order_details.account_name)
        setQuotes(order_details);
        setOrderData(order_details);
        setPreshipments(data.preshipment_data);
        setPostshipments(data.postshipment_data);
        setOrderProduct(data.order_product)
        setBuyer(data.buyer_products);
        setTcf(data.tcf);
        setRw(data.payable_receivable_lineitem);
        setReceipt(data.payable_receipts_lineitem);
        setHistory(data.history);
        setTimeout(() => {
          $("#pre").DataTable();
          $("#post").DataTable();
          $("#buyer").DataTable();
          $("#tcf").DataTable();
           $("#rw").DataTable();$("#order-product").DataTable();
          $("#history-info").DataTable();
          $("#receipt").DataTable();
        }, 50);
      }
      else {
        toast.error("No Data Available", {
          position: "bottom-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [decryptedAccessToken, id]);




  const [formData, setFromdata] = useState({
    account_record: "",
  });


  const handleChange = (e) => {
    setFromdata({ ...formData, [e.target.name]: e.target.value });
  };
console.log(date1)
  const handleSubmit = (event) => {
    event.preventDefault();

    const encryptedAccessToken = localStorage.getItem("access_token");
    const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
    if (encryptedAccessToken) {
      const decryptedAccessToken = AES.decrypt(
        encryptedAccessToken,
        secret_key
      ).toString(enc.Utf8);

      fetch(API_URL+"add/record", {
        method: "POST",
        body: JSON.stringify(formData),
        headers: {
          Authorization: `Bearer ${decryptedAccessToken}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())

        .then((response) => {
          // console.log(response)
          // console.log(response.status)
          const account_record_new = response.account_type;

          navigate("/contract/shipment-by-contract/" + id, {
            state: { account_record: account_record_new },
          });
          if (response.message === "record addedd successfully") {
          }
        });
    }
  };

  // opportunity information
  const [formDataopp, setFromdataopp] = useState({
    type_of_new_record: "",
  });

  function handleClick(e) {
    const responseData = {
      record_type: radio,
    };
    const rtype = responseData.record_type;
    // const encryptedAccessToken = localStorage.getItem("access_token");
    // const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
    // if (encryptedAccessToken) {
    //   const decryptedAccessToken = AES.decrypt(
    //     encryptedAccessToken,
    //     secret_key
    //   ).toString(enc.Utf8);

    //   fetch(API_URL+"add/rw_pay_recieve/"+id, {
    //     method: "POST",
    //     body: JSON.stringify(responseData),
    //     headers: {
    //       Authorization: `Bearer ${decryptedAccessToken}`,
    //       "Content-Type": "application/json",
    //     },
    //   })
    //     .then((response) => response.json())

    //     .then((response) => {
    //       console.log(response);
    //       if(response.messege='Rw-Pay-recieve cre successfully'){
    //         toast.success("Rwood Payable/Recieve Created", {
    //           position: "bottom-center",
    //           autoClose: 1000,
    //           hideProgressBar: false,
    //           closeOnClick: true,
    //           pauseOnHover: true,
    //           draggable: true,
    //           progress: undefined,
    //           theme: "light",
    //         });

    navigate("/order/rw-payable/" + id, {
      state: { ids: id, recordtype: rtype },
    });
  }
  //     });
  // }

  // receipt

  function handleClicks(e) {
    const responseData = {
      record_type: radio,
    };
    const rtype = responseData.record_type;

    navigate("/order/order-receipt/" + id, {
      state: { ids: id, recordtype: rtype },
    });
  }

  // Edit
  const [data, setData] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [editedData, setEditedData] = useState([]);
  const handleEditClick = () => {
    setIsEditMode(true);
    setEditedData(data);
    $("#pre").DataTable();
    $("#post").DataTable();
    $("#buyer").DataTable();
    $("#tcf").DataTable();
     $("#rw").DataTable();$("#order-product").DataTable();
    $("#history").DataTable();
    $("#receipt").DataTable();
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setOrderData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  setTimeout(() => {
    $("#pre").DataTable();
    $("#post").DataTable();
    $("#buyer").DataTable();
    $("#tcf").DataTable();
     $("#rw").DataTable();$("#order-product").DataTable();
    $("#history").DataTable();
    $("#receipt").DataTable();
  }, 50);



  const [opp,setOpp]=useState([])
  useEffect(() => {

    fetchDataopp();
  }, [decryptedAccessToken]);

  const fetchDataopp = async () => {
    try {
      const response = await axios.get(API_URL+'all/opportunity', {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      const data = response.data.opportunity;
      setOpp(data);

    } catch (error) {
      console.error(error);
    }
  };



  const handleCancelEdit = () => {
    $("#pre").DataTable();
    $("#post").DataTable();
    $("#buyer").DataTable();
    $("#tcf").DataTable();
     $("#rw").DataTable();$("#order-product").DataTable();
    $("#history-info").DataTable();
    $("#receipt").DataTable();
    setIsEditMode(false);
    setEditedData(data);
  };





  const accountData = accountNames.map(item => ({
    value: item.account_name || oderData.account_name,
    label: item.account_name || oderData.contract_number
  }));
  const [accountDrop, setAccountDrop] = useState(null);

  const handleAccount = (accountData) => {
    setAccountDrop(accountData);
  };



  const contractData = contractNumber.map(item => ({
    value: item.contract_number,
    label: item.contract_number
  }));
  const [contractDrop, setContractDrop] = useState(null);

  const handleContract = (contractDrop) => {
    setContractDrop(contractDrop);
  };


  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    const newState = !isChecked;
    setIsChecked(newState);
    console.log("Checkbox is checked:", newState);
  };



  const handleSaveEdit = (e) => {
    e.preventDefault();
    $("#pre").DataTable();
    $("#post").DataTable();
    $("#buyer").DataTable();
    $("#tcf").DataTable();
     $("#rw").DataTable();$("#order-product").DataTable();
    $("#history").DataTable();
    $("#receipt").DataTable();
    const responseUpdate = {
      "company": oderData.company,
      "buyer_full_name":oderData.buyer_full_name,
      "currency": oderData.currency,
      "exchange_rate": oderData.exchange_rate,
      "account_name": oderData.account_name,
      "contract_number":oderData.contract_number,
      "order_start_date": date1?format(date1, 'dd/MM/yyyy'):null,
      "status": oderData.status,
      "nomination_of_independent_surveyor_date":date18?format(date18, 'dd/MM/yyyy'):null,
      "additional_surveyor_by_rwood_confirm_date":date19?format(date19, 'dd/MM/yyyy'):null,
      "opportunity": oderData.opportunity,
      "remark": oderData.remark,
      "buyer_check_status":supplierChecks,
      "supplier_check_status":supplierCheck,
      "vessel_type":oderData.vessel_type,
      "buyer_despatch_or_demurrage_amount":oderData.buyer_despatch_or_demurrage_amount,
      "nor_tendered_date_and_time":norResult1?norResult1:null,
      "all_expected_value_date":date21?format(date21, 'dd/MM/yyyy'):null,
      "locked": lockeds,
      "loa_number_2":oderData.loa_number_2,
      "loa_number":oderData.loa_number,
      "supplier_contract_refernce_2":oderData.supplier_contract_refernce_2,
      "purchase_contract_status_1":oderData.purchase_contract_status_1,
      "purchase_contract_status_2":oderData.purchase_contract_status_2,
      "bl_quantity_laytime_calculations":parseFloat(oderData.bl_quantity_laytime_calculations),
      "ltc_sent_to_buyer_date":date22?format(date22, 'dd/MM/yyyy'):null,
      "activated_by": oderData.activated_by,
      "status":oderData.status,
      "buyer_laytime_time_used":parseFloat(oderData.buyer_laytime_time_used),
      "buyer_despatch_demurrage_amount":parseFloat(oderData.buyer_despatch_demurrage_amount),
      "count_of_other_dn":parseFloat(oderData.count_of_other_dn),
      "order_record_type": oderData.order_record_type,
      "supplier": oderData.supplier,
      "contract_quantity_MT": oderData.contract_quantity_MT,
      "bl_no": oderData.bl_no,
      "ltc_sent_to_supplier_date":date24?format(date24, 'dd/MM/yyyy'):null,
      "purchase_contract_1": oderData.purchase_contract_1,
      "turn_time":oderData.turn_time,
      "buyer_despatch_or_demmurage":oderData.buyer_despatch_or_demmurage,
      "supplier_despatch_or_demmurage":oderData.supplier_despatch_or_demmurage,
      "supplier_contract_refernce_1": oderData.supplier_contract_refernce_1,
      "purchase_contract_status_1": oderData.purchase_contract_status_1,
      "purchase_contract_2": oderData.purchase_contract_2,
      "surveyor_agent":oderData.surveyor_agent,
      "net_des_dem_inflow_or_outflow":parseFloat(oderData.net_des_dem_inflow_or_outflow),
      "buyer_despatch_demurrage_status":oderData.buyer_despatch_demurrage_status,
      "supplier_contract_refernce_2": oderData.supplier_contract_refernce_2,
      "purchase_contract_status_2": oderData.purchase_contract_status_2,
      "product_specification": oderData.product_specification,
      "laycan_from_date": date2?format(date2, 'dd/MM/yyyy'):null,
      "laycan_to_date":date3? format(date3, 'dd/MM/yyyy'):null,
      "eta_date": date4? format(date4, 'dd/MM/yyyy'):null,
      "laycan_month": oderData.laycan_month,
      "laycan_year": oderData.laycan_year,
      "notice_of_readiness_confirm_date":date1_1? format(date1_1, 'dd/MM/yyyy'):null,
      "supplier_despatch_demurrage_rate":parseFloat(oderData.supplier_despatch_demurrage_rate),
      "supplier_ltc_rec_count":parseFloat(oderData.supplier_ltc_rec_count),
      "supplier_remark_despacth_demmurage":oderData.supplier_remark_despacth_demmurage,
      "ship_month": oderData.ship_month,
      "laycan_period": oderData.laycan_period,
      "business_confirm_to_supplier_no": oderData.business_confirm_to_supplier_no,
      "business_confirm_to_supplier_date":date5?format(date5, 'dd/MM/yyyy'):null,
      "vessel_name": oderData.vessel_name,
      "barge_name": oderData.barge_name,
      "buyer_laytime_time_saved_exceed":parseFloat(oderData.buyer_laytime_time_saved_exceed),
      "supplier_ltc_to_rw":parseFloat(oderData.supplier_ltc_to_rw),
      "buyer_ltc_to_rw":parseFloat(oderData.buyer_ltc_to_rw),
      "supplier_final_dem_or_despatch_payable":parseFloat(oderData.supplier_final_dem_or_despatch_payable),
      "buyer_final_dem_or_despatch_payable":parseFloat(oderData.buyer_final_dem_or_despatch_payable),
      "buyer_ltc_rec_count":parseFloat(oderData.buyer_ltc_rec_count),
      "tug_boat_no": oderData.tug_boat_no,
      "tonnage": oderData.tonnage,
      "buyer_remark_despacth_demmurage":oderData.buyer_remark_despacth_demmurage,
      "vessel_nomination_date": date7?format(date7, 'dd/MM/yyyy'):null,
      "vessel_nomination_confirm_date":date71?format(date71, 'dd/MM/yyyy'):null,
      "vessel_nomination_description": vesselNomination,
      "etb_date": date6 ? format(date6, 'dd/MM/yyyy'):null,
      "jakarta_team_member": oderData.jakarta_team_member,
      "loading_rate": oderData.loading_rate,
      "buyer_despatch_demurrage_rate": oderData.buyer_despatch_demurrage_rate,
      "days_allowed_for_laycan": oderData.days_allowed_for_laycan,
      "loading_at": oderData.loading_at,
      "supplier_final_other_payable":parseFloat(oderData.supplier_final_other_payable),
      "buyer_final_other_payable":parseInt(oderData.buyer_final_other_payable),
      "mate_recieved_date_and_time":norResult8?norResult8:null,
      "time_to_count_for_laytime": norResult2 ? norResult2:null,
      "vessel_acceptance_date": date8 ? format(date8, 'dd/MM/yyyy'):null,
      "vessel_acceptance_confirm_date": date9? format(date9, 'dd/MM/yyyy'):null,
      "load_port_agent1": oderData.load_port_agent1,
      "load_port_agent2": oderData.load_port_agent2,
      "sales_invoice":parseFloat(oderData.sales_invoice),
      "purchase_invoice":parseFloat(oderData.purchase_invoice),
      "surveyor_invoice":parseFloat(oderData.surveyor_invoice),
      "load_port_invoice":parseFloat(oderData.load_port_invoice),
      "load_port_agent3": oderData.load_port_agent3,
      "completion_loading_date_and_time":norResult6?norResult6:null,
      "agent_appointment_date":date88? format(date88, 'dd/MM/yyyy'):null,
      "shipping_instruction_no": oderData.shipping_instruction_no,
      "shipping_instruction_date":date12? format(date12, 'dd/MM/yyyy'):null,
      "supplier_despatch_demurrage_amount": oderData.supplier_despatch_demurrage_amount,
      "destination": oderData.destination,
      "commence_loading_date_and_time":norResult5?norResult5:null,
      "buyer_confirm_date":date25? format(date25, 'dd/MM/yyyy'):null,
      "supplier_confirm_date":date23? format(date23, 'dd/MM/yyyy'):null,
      "destination_port": oderData.destination_port,
      "pre_stowage": oderData.pre_stowage,
      "bl_date":date20 ? format(date20, 'dd/MM/yyyy'):null,
      "nor_acceptance_date_and_time":norResult ?norResult:null,
      "sailing_date_and_time":norResult7?norResult7:null,
      "contract_shipment": oderData.contract_shipment,
      "vessel_arrival_date_and_time": norResult3 ? norResult3:null,
      "eta_notice_recieved_date1": date13 ?format(date13, 'dd/MM/yyyy'): null,
      "eta_notice_recieved_date2": date14 ? format(date14, 'dd/MM/yyyy'):null,
      "eta_notice_recieved_date3": date15 ? format(date15, 'dd/MM/yyyy'):null,
      "eta_notice_recieved_date4":date16 ? format(date16, 'dd/MM/yyyy'):null,
      "eta_notice_recieved_date5": date17 ? format(date17, 'dd/MM/yyyy'):null,
      "supplier_laytime_time_used":parseFloat(oderData.supplier_laytime_time_used),
      "supplier_laytime_time_saved_exceed":parseFloat(oderData.supplier_laytime_time_saved_exceed),
      "supplier_despatch_demurrage_status":oderData.supplier_despatch_demurrage_status,
      "count_of_desp_demm_dn":parseFloat(oderData.count_of_desp_demm_dn),
      "rwood_surveyor_agent":oderData.rwood_surveyor_agent,
      "buyer_surveyor_agent":oderData.buyer_surveyor_agent,

    };
    console.log(JSON.stringify(responseUpdate))
    setIsSubmitted(true)

    axios.put(`${API_URL}update/order/${id}`, responseUpdate, {
        headers: {
          Authorization: `Bearer ${decryptedAccessToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log(response)

         if (response.data.success === false) {
          document.getElementById('messageContainer').innerHTML = `
              <div class="error-message">
                  ${response.data.error}
              </div>
          `;
          window.scrollTo({
            top: 0,
            behavior: "smooth" // Optional: animated scrolling
        });
      }
      else {
        setIsSubmitted(false)
        document.getElementById('messageContainer').innerHTML = `
            <div class="success-message">
                ${response.data.success}
            </div>
        `;

        window.scrollTo({
          top: 0,
          behavior: "smooth" // Optional: animated scrolling
      });

        window.scrollTo({
          top: 0,
          behavior: "smooth" // Optional: animated scrolling
      });
        fetchData();
        setTimeout(() => {
            $("#historys").dataTable();
            $('#shipment').DataTable();
            $('#user').DataTable();
        }, 500);
        setData(response);
        setIsEditMode(false);
    }
      })
      .catch((error) => console.error(error));
  };

  const buyerLaytime =()=>{
    // navigate("/order/laytime-details", {
    //   state: { ids: id, orderCode:oderData.order_no},
    // });
    const laytimeresponseData = {
      order_no: oderData.order_no,
    };
    // console.log(JSON.stringify(laytimeresponseData));
    if (encryptedAccessToken) {
      const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
      const decryptedAccessToken = AES.decrypt(
        encryptedAccessToken,
        secret_key
      ).toString(enc.Utf8);

      fetch(API_URL+"add/laytime/" + id, {
        method: "POST",
        body: JSON.stringify(laytimeresponseData),
        headers: {
          Authorization: `Bearer ${decryptedAccessToken}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())

        .then((response) => {
          const responseData = response.laytime_id;
          // console.log(responseData);
          navigate("/order/laytime-details", {
            state: { ids: responseData, orderId:id },
          });
        });
    }
  }

  const SupplierLaytime = () => {
    const laytimeresponseData = {
      order_no: oderData.order_no,
    };
    console.log(JSON.stringify(laytimeresponseData));
    if (encryptedAccessToken) {
      const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
      const decryptedAccessToken = AES.decrypt(
        encryptedAccessToken,
        secret_key
      ).toString(enc.Utf8);

      fetch(API_URL+"add/supplier_laytime/" + id, {
        method: "POST",
        body: JSON.stringify(laytimeresponseData),
        headers: {
          Authorization: `Bearer ${decryptedAccessToken}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())

        .then((response) => {
          const responseData = response.laytime_supplier_id;
          console.log(response);
          navigate("/order/suplier-laytime", {
            state: { id: responseData },
          });
        });
    }
  };
  const handleSubmitLaytime = () => {
    const laytimeresponseData = {
      order_no: oderData.order_no,
    };
    // console.log(JSON.stringify(laytimeresponseData));
    if (encryptedAccessToken) {
      const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
      const decryptedAccessToken = AES.decrypt(
        encryptedAccessToken,
        secret_key
      ).toString(enc.Utf8);

      fetch(API_URL+"add/laytime/" + id, {
        method: "POST",
        body: JSON.stringify(laytimeresponseData),
        headers: {
          Authorization: `Bearer ${decryptedAccessToken}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())

        .then((response) => {
          const responseData = response.laytime_id;
          // console.log(responseData);
          navigate("/order/laytime-details", {
            state: { id: responseData, orderId:id },
          });
        });
    }
  };

  // /get/laytime_byID/<id>

  return (
    <div id="view-page">
      <Header />
      
      <div className="row">
      <div className="col-md-2">
            <RecentItems/>
          </div>
          <div className="col-md-10 right-section order-details">
      <div className="contactAccount orders_details">
        <div className="page-header" id="add-account">
          <div>
            <h2 className="main-content-title tx-24 mg-b-5">View Order</h2>
            <ol className="breadcrumb">
              <li className="breadcrumb-item active" aria-current="page">
                Dashboard / Order / View Order
              </li>
            </ol>
          </div>
          <div className="d-flex opd">
            <div className="justify-content-center">
              {/* <button
                type="button"
                className="btn btn-primary my-2 btn-icon-text"
              >
                <Link to={`/preshipment/add-preshipment/${id}`}>
                  {" "}
                  <i className="fe fe-download-cloud me-2"></i>
                  <FaPlus /> Add Preshipment
                </Link>
              </button>
              <button
                type="button"
                className="btn btn-primary my-2 btn-icon-text"
              >
                <Link to={`/postshipment/add-postshipment/${id}`}>
                  {" "}
                  <i className="fe fe-download-cloud me-2"></i>
                  <FaPlus /> Add Postshipment
                </Link>
              </button> */}
            </div>
          </div>
        </div>
      </div>
      {dataLength > 0 ? (
        <>
          {isEditMode ? (
            <div className="tasks fulid-section order-section">
              <div id="messageContainer"></div>
              <p className="edit-btn">
              <input
      type="submit"
      className="account-save"
      onClick={handleSaveEdit}
      value={isSubmitted ? "Saving" : "Save"}
      disabled={isSubmitted}
    />
                <button className="btn btn-primary" onClick={handleCancelEdit}>
                  Cancel
                </button>
              </p>

              <Row className="mb-3">
                <h4 className="heading">Order Details</h4>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingSelect"
                    className="dropDown"
                    label="Company"
                  >
                    <Form.Select
                      aria-label="Floating label select example"
                      name="company"
                      placeholder="Company"
                      tabIndex={1}
                      defaultValue={oderData.company}
                      onChange={handleChangeInput}
                    >
                      <option value="">Select</option>
                      {company.map((x) => {
                        return (
                          <option value={x.company_name}>
                            {x.company_name}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="6">
                  <label>Locked</label>
                  <input
                    type='checkbox'
                    name='locked'
                    tabIndex={11}
                    checked={lockeds} // Use the state variable to determine if the checkbox is checked
                    onChange={handleCheckboxChange1} // Handle the change event
                    className='mx-3'

                  />
                </Form.Group>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingSelect"
                    className="dropDown"
                    label="Currency"
                  >
                    <Form.Select
                      aria-label="Floating label select example"
                      name="currency"
                      placeholder="Currency"
                      tabIndex={2}
                      defaultValue={oderData.currency}
                      onChange={handleChangeInput}
                    >
                      <option value="">Select</option>
                      <option value="USD">USD</option>
                      <option value="AED">IDR</option>
                    </Form.Select>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Order Record Type"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Order Record Type"
                      name="order_record_type"
                      tabIndex={12}
                      defaultValue={
                        oderData.order_record_type || "Shipment Activity"
                      }
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Exchange Rate"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Exchange Rate"
                      name="exchange_rate"
                      tabIndex={3}
                      defaultValue={oderData.exchange_rate}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingSelect"
                    className="dropDown"
                    label="Supplier"
                  >
                    <Form.Select
                      aria-label="Floating label select example"
                      name="supplier"
                      placeholder="Supplier"
                      tabIndex={13}
                      className="error-validation"
                      defaultValue={oderData.supplier}
                      onChange={handleChangeInput}
                    >
                      <option value="">Select</option>
                      {suppliers.map((x) => {
                        return (
                          <option value={x.account_name}>
                            {x.account_name}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </FloatingLabel>
                </Form.Group>


                {/* <Form.Group as={Col} md="6"> */}
          {/* <FloatingLabel
            controlId="floatingInput"
            label="Account Name"
            className="mb-3 datepick"


          >
    <Select
  options={accountData}
  value={accountDrop ? accountDrop : oderData.account_name}
  onChange={(selectedOption) => {
    console.log("Selected Value:", selectedOption);
    handleAccount(selectedOption);
  }}
  placeholderText="Select"
/>




          </FloatingLabel>
        </Form.Group> */}

        <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Account Name">
    <Form.Select aria-label="Floating label select example"
    name="account_name"
    placeholder="Account Name" className="error-validation"
    tabIndex={4}
    defaultValue= {oderData.account_name}
     onChange={handleChangeInput}
    >
      <option value='' >Select</option>
       {
          accountNames.map((x)=>{
              return(
                  <option value={x.account_name}>{x.account_name}</option>
              )
          })
       }
    </Form.Select>
  </FloatingLabel>
              </Form.Group>
                <Form.Group as={Col} md="6" className='tooltips'>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="BL No"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder="BL No"
                      name="bl_no"
                      tabIndex={14}
                      defaultValue={oderData.bl_no}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                   
                <Tooltip text={<span>All BL No, Seperated by Coma from <br/>post shipment document object</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                </Form.Group>

        <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Contract Number">
    <Form.Select aria-label="Floating label select example"
    name="contract"
    tabIndex={5}
    placeholder="Contract number" className="error-validation"

    defaultValue= {oderData.contract_number}
     onChange={handleChangeInput}

    >
      <option value='' >Select</option>
       {
          contractNumber.map((x)=>{
              return(
                  <option value={x.contract_number}>{x.contract_number}</option>
              )
          })
       }
    </Form.Select>
  </FloatingLabel>
              </Form.Group>
                {/* <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Contract Number"
            className="mb-3 datepick"


          >
                     <Select
     options={contractData}
     value={contractDrop||oderData.contract_number}
     onChange={handleContract}
     placeholderText="Select"

   />
                  </FloatingLabel>
                </Form.Group> */}
                <Form.Group as={Col} md="6"></Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Contract Shipment"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Contract Shipment"
                      tabIndex={6}
                      name="contract_shipment"
                      defaultValue={oderData.contract_shipment}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6"></Form.Group>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Order Start Date"
                    className="mb-3 datepick"
                  >

<DatePicker
        selected={date1}
        onChange={handleDateChangesOne}
        selectsStart
        dateFormat='dd/MM/yyyy'
        tabIndex={7}
         placeholderText="Select"
      />

                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="6"></Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingSelect"
                    className="dropDown"
                    label="Status"
                  >
                    <Form.Select
                      aria-label="Floating label select example"
                      name="status"
                      placeholder="Status"
                      tabIndex={8}
                      className="error-validation"
                      defaultValue={oderData.status}
                      onChange={handleChangeInput}
                    >
                      <option value="Additional Surveyor appointed by Rwood">
                        Additional Surveyor appointed by Rwood
                      </option>
                      <option value="Additional Surveyors certificates">
                        Additional Surveyors certificates
                      </option>
                      <option value="Agent Appointment">
                        Agent Appointment
                      </option>
                      <option value="AI-COO Courier">AI-COO Courier</option>
                      <option value="AI-COO issued">AI-COO issued</option>
                      <option value="Bl and AI COO Confirm">
                        Bl and AI COO Confirm
                      </option>
                      <option value="BL Issued">BL Issued</option>
                      <option value="Business Confirmation to Supplier">
                        Business Confirmation to Supplier
                      </option>
                      <option value="Cancelled">Cancelled</option>
                      <option value="Checking of Import Invoice">
                        Checking of Import Invoice
                      </option>
                      <option value="Transfer LC">Child LC</option>
                      <option value="Commercial Invoice">
                        Commercial Invoice
                      </option>
                      <option value="Commercial Invoice Bill of Exchange">
                        Commercial Invoice Bill of Exchange
                      </option>
                      <option value="Documents Arrive at RWood Bank">
                        Documents Arrive at RWood Bank
                      </option>
                      <option value="Documents negotiation by Rwood">
                        Documents negotiation by Rwood
                      </option>
                      <option value="Documents Submitted for LC Negotiation">
                        Documents Submitted for LC Negotiation
                      </option>
                      <option value="Draft">Draft</option>
                      <option value="Laycan Confirmation" selected="selected">
                        Laycan Confirmation
                      </option>
                      <option value="LC Discounting Application">
                        LC Discounting Application
                      </option>
                      <option value="Master LC Issued">Master LC Issued</option>
                      <option value="Negotiated Document Status">
                        Negotiated Document Status
                      </option>
                      <option value="NN Documets Sent">NN Documets Sent</option>
                      <option value="Nomination of Independent Surveyor">
                        Nomination of Independent Surveyor
                      </option>
                      <option value="Notice of Readiness">
                        Notice of Readiness
                      </option>
                      <option value="Shipments - Arutmin">
                        Shipments - Arutmin
                      </option>
                      <option value="Shipping Instruction">
                        Shipping Instruction
                      </option>
                      <option value="Surveyor Documents Issued">
                        Surveyor Documents Issued
                      </option>
                      <option value="Surveyor Send Invoice to Rwood">
                        Surveyor Send Invoice to Rwood
                      </option>
                      <option value="Vessel Acceptance">
                        Vessel Acceptance
                      </option>
                      <option value="Vessel Arrival / Loading commence">
                        Vessel Arrival / Loading commence
                      </option>
                      <option value="Vessel Loading Complete/Departure">
                        Vessel Loading Complete/Departure
                      </option>
                      <option value="Vessel Nomination">
                        Vessel Nomination
                      </option>
                      <option value="Cancelled with Penalty">
                        Cancelled with Penalty
                      </option>
                    </Form.Select>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6"></Form.Group>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="opportunity"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder="opportunity"
                      tabIndex={9}
                      name="opportunity"
                      defaultValue={oderData.opportunity}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                </Form.Group>




                {/* <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Opportunity">
    <Form.Select aria-label="Floating label select example"
    name="opportunity"
    placeholder="Opportunity"
    value={formData.opportunity}
    onChange={handleChangeInput}
    >
      <option value='' >Select</option>
       {
          opp.map((x)=>{
              return(
                  <option value={x.opportunity_name}>{x.opportunity_name}</option>
              )
          })
       }
    </Form.Select>
  </FloatingLabel>
              </Form.Group> */}
                <Form.Group as={Col} md="6"></Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Remark"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Remark"
                      name="remark"
                      tabIndex={10}
                      defaultValue={oderData.remark}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                </Form.Group>
              </Row>
              <Row>
                <h4 className="heading">Purchase Contract Information</h4>

                <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Purchase Contract 1">
    <Form.Select aria-label="Floating label select example"
    name="purchase_contract_1"
    tabIndex={16}
    placeholder="Purchase Contract 1"
    defaultValue={formData.purchase_contract_1||quotes.purchase_contract_1}
    onChange={handleChangeInput}
    >

<option defaultValue='' >Select </option>

      {datasContract.length>0?(
                 <>
                     {
            datasContract.map((x)=>{
                return(
                    <option value={x.contract_number}>{x.contract_number}</option>
                )
            })
         }

                </>
                ):(

                 <option className='no-data'>No data  available</option>
                 )}

    </Form.Select>

  </FloatingLabel>
</Form.Group>

<Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Purchase Contract 2">
    <Form.Select aria-label="Floating label select example"
    name="purchase_contract_2"
    tabIndex={20}
    placeholder="Purchase Contract 2"
    defaultValue={formData.purchase_contract_2||quotes.purchase_contract_2}
    onChange={handleChangeInput}
    >

<option defaultValue='' >Select </option>

      {datasContract.length>0?(
                 <>
                     {
            datasContract.map((x)=>{
                return(
                    <option value={x.contract_number}>{x.contract_number}</option>
                )
            })
         }

                </>
                ):(

                 <option className='no-data'>No data  available</option>
                 )}

    </Form.Select>

  </FloatingLabel>
</Form.Group>
<Form.Group as={Col} md="6" className="tooltips">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="purchase contract status 1"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder="purchase contract status 1"
                      tabIndex={17}
                      name="purchase_contract_status_1"
                      defaultValue={oderData.purchase_contract_status_1}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                   
                <Tooltip text={<span>Data coming from Order Product</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                </Form.Group>

<Form.Group as={Col} md="6" className="tooltips">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="purchase contract status 2"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder="purchase contract status 2"
                      name="purchase_contract_status_2"
                      tabIndex={21}
                      defaultValue={oderData.purchase_contract_status_2}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                   
                <Tooltip text={<span>Data coming from Order Product</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                </Form.Group>
<Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="supplier contract refernce 1"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder="supplier contract refernce 1"
                      name="supplier_contract_refernce_1"
                      tabIndex={18}
                      defaultValue={oderData.supplier_contract_refernce_1}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="supplier contract refernce 2"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder="supplier contract refernce 2"
                      name="supplier_contract_refernce_2"
                      tabIndex={22}
                      defaultValue={oderData.supplier_contract_refernce_2}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="LOA number"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder="LOA number"
                      name="loa_number"
                      tabIndex={19}
                      defaultValue={oderData.loa_number}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="LOA number 2"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder="LOA number 2"
                      name="loa_number_2"
                      tabIndex={23}
                      defaultValue={oderData.loa_number_2}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                </Form.Group>
              </Row>
              <Row>
                <h4 className="heading">Laycan Information</h4>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Laycan From Date"
                    className="mb-3 datepick"
                  >
                   <DatePicker
        selected={date2}
        onChange={handleDateChangesTwo}
        selectsStart
        dateFormat='dd/MM/yyyy'
         placeholderText="Select"
         tabIndex={24}
         disabled={true}
      />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingSelect"
                    className="dropDown"
                    label="Laycan Month"
                  >
                    <Form.Select
                      aria-label="Floating label select example"
                      name="laycan_month"
                      tabIndex={27}
                      placeholder="Laycan Month"
                      defaultValue={oderData.laycan_month}
                      onChange={handleChangeInput}
                    >
                      <option value="January">January</option>
                      <option value="February">February</option>
                      <option value="March">March</option>
                      <option value="April">April</option>
                      <option value="May">May</option>
                      <option value="June">June</option>
                      <option value="July">July</option>
                      <option value="August">August</option>
                      <option value="September">September</option>
                      <option value="October">October</option>
                      <option value="November">November</option>
                      <option value="December">December</option>
                    </Form.Select>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Laycan To Date"
                    className="mb-3 datepick"
                  >
                   <DatePicker
        selected={date3}
        onChange={handleDateChangesThree}
        selectsStart
        dateFormat='dd/MM/yyyy'
        tabIndex={25}
         placeholderText="Select"
         disabled={true}
      />
                  </FloatingLabel>
                  {endDateError && <div className='error-validations order-error' style={{ color: 'red' }}>{endDateError}</div>}
                </Form.Group>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingSelect"
                    className="dropDown"
                    label="Laycan Year"
                  >
                    <Form.Select
                      aria-label="Floating label select example"
                      name="laycan_year"
                      tabIndex={28}
                      placeholder="Laycan Year"
                      defaultValue={oderData.laycan_year}
                      onChange={handleChangeInput}
                    >
                      <option value="">--None--</option>
                      <option value="2021">2021</option>
                      <option value="2022">2022</option>
                      <option value="2023">2023</option>
                      <option value="2024">2024</option>
                      <option value="2025">2025</option>
                      <option value="2026">2026</option>
                      <option value="2015">2015</option>
                      <option value="2018">2018</option>
                      <option value="2019">2019</option>
                      <option value="2020">2020</option>
                    </Form.Select>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="ETA Date"
                    className="mb-3 datepick"
                  >
                   <DatePicker
        selected={date4}
        onChange={handleDateChangesFour}
        selectsStart
        dateFormat='dd/MM/yyyy'
        tabIndex={26}
         placeholderText="Select"
      />
                  </FloatingLabel>
                </Form.Group>
              </Row>
              <Row>
                <h4 className="heading">Business Confirmation to Supplier</h4>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Business Confirm To Supplier No"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Business Confirm To Supplier No"
                      name="business_confirm_to_supplier_no"
                      tabIndex={29}
                      defaultValue={oderData.business_confirm_to_supplier_no}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6"></Form.Group>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Business Confirm To Supplier Date"
                    className="mb-3 datepick"
                  >
                   <DatePicker
        selected={date5}
        onChange={handleDateChangesFive}
        selectsStart
        tabIndex={30}
        dateFormat='dd/MM/yyyy'
         placeholderText="Select"
      />
                  </FloatingLabel>
                </Form.Group>
              </Row>
              <Row>
                <h4 className="heading">Vessel Nomination</h4>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Vessel Name"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Vessel Name"
                      tabIndex={31}
                      name="vessel_name"
                      defaultValue={oderData.vessel_name}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="ETB Date"
                    className="mb-3 datepick"
                  >
                  <DatePicker
        selected={date6}
        onChange={handleDateChangesSix}
        tabIndex={39}
        selectsStart
        dateFormat='dd/MM/yyyy'
         placeholderText="Select"
      />
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Barge Name"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      tabIndex={32}
                      placeholder="Barge Name"
                      name="barge_name"
                      defaultValue={oderData.barge_name}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Jakarta Team Member"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      tabIndex={40}
                      placeholder="Jakarta Team Member"
                      name="jakarta_team_member"
                      defaultValue={oderData.jakarta_team_member}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="TUG Boat No"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder="TUG Boat No"
                      name="tug_boat_no"
                      tabIndex={33}
                      defaultValue={oderData.tug_boat_no}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Loading Rate"
                    className="mb-3"
                  >
                    <Form.Control
                      type="number"
                      placeholder="Loading Rate"
                      tabIndex={41}
                      name="loading_rate"
                      defaultValue={oderData.loading_rate}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Tonnage"
                    className="mb-3"
                  >
                    <Form.Control
                      type="number"
                      placeholder="Tonnage"
                      name="tonnage"
                      tabIndex={34}
                      defaultValue={oderData.loading_rate}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Buyer Demurrage Rate"
                    className="mb-3"
                  >
                    <Form.Control
                      type="number"
                      placeholder="Buyer Demurrage Rate"
                      tabIndex={42}
                      name="buyer_despatch_demurrage_rate"
                      defaultValue={oderData.buyer_despatch_demurrage_rate}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="6" className="tooltips">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Vessel Nomination Date"
                    className="mb-3 datepick"
                  >
                    <DatePicker
        selected={date7}
        onChange={handleDateChangesSeven}
        selectsStart
        tabIndex={35}
        dateFormat='dd/MM/yyyy'
         placeholderText="Select"
      />
                  </FloatingLabel>
                   
                <Tooltip text={<span>Vessel nomination date is the one on which<br/> the buyer nominates the vessel.</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                </Form.Group>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Supplier Demurrage Rate"
                    className="mb-3"
                  >
                    <Form.Control
                      type="number"
                      placeholder="Supplier  Demurrage Rate"
                      tabIndex={43}
                      name="supplier_despatch_demurrage_rate"
                      defaultValue={oderData.supplier_despatch_demurrage_rate}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                </Form.Group>
                 <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingSelect"
                    className="dropDown"
                    label="Vessel Type"
                  >
                    <Form.Select
                      aria-label="Floating label select example"
                      name="vessel_type"
                      tabIndex={36}
                      placeholder="Vessel Type"
                      defaultValue={oderData.vessel_type}
                      onChange={handleChangeInput}
                    >
                      <option value="">--None--</option>
                      <option value="Geared (GnG)">Geared (GnG)</option>
                      <option value="Gearless">Gearless</option>
                      <option value="Geared">Geared</option>
                      <option value="Barge">Barge</option>
                    </Form.Select>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Loading At"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Loading At"
                      tabIndex={44}
                      name="loading_at"
                      defaultValue={oderData.loading_at}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="6" className='tooltips'>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Vessel Nomination Confirm Date"
                    className="mb-3 datepick"
                  >
                     <DatePicker
        selected={date71}
        onChange={handleDateChangesSeven1}
        selectsStart
        tabIndex={37}
        dateFormat='dd/MM/yyyy'
         placeholderText="Select"
      />
                  </FloatingLabel>
                   
                <Tooltip text={<span>the day Vessel nomination gets confirm from<br/> buyer and Supplier</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                </Form.Group>

                <Form.Group as={Col} md="6"></Form.Group>
                <Form.Group as={Col} md="6" className='textEditor'>
  <label>Vessel Nomination Description</label>
  <Editor value={vesselNomination} onChange={onChangeVesselNomination} />
</Form.Group>
                  <Form.Group as={Col} md="6"></Form.Group>
              </Row>

              <Row>
                <h4 className="heading">Vessel Aceptance</h4>

                <Form.Group as={Col} md="6" className='tooltips'>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Vessel Acceptance Date"
                    className="mb-3 datepick"
                  >
                    <DatePicker
        selected={date8}
        onChange={handleDateChangesEight}
        selectsStart
        tabIndex={38}
        dateFormat='dd/MM/yyyy'
         placeholderText="Select"
      />
                  </FloatingLabel>
                   
                <Tooltip text={<span>Vessel acceptance date is the one on which <br/>shipper accept the vessel nomination</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                </Form.Group>

                <Form.Group as={Col} md="6" className="tooltips">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Vessel Acceptance Confirm Date"
                    className="mb-3 datepick"
                  >
                     <DatePicker
        selected={date9}
        onChange={handleDateChangesNine}
        selectsStart
        dateFormat='dd/MM/yyyy'
         placeholderText="Select"
      />
                  </FloatingLabel>
                   
                <Tooltip text={<span>Vessel acceptance confirm date is<br/> the one on which RW confirms the vessel <br/>nomination to buyer</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                </Form.Group>
              </Row>

              <Row>
                <h4 className="heading">Agent Appointment</h4>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingSelect"
                    className="dropDown"
                    label="Load Port Agent 1"
                  >
                    <Form.Select
                      aria-label="Floating label select example"
                      name="load_port_agent1"
                      id="account_name"
                      tabIndex={45}
                      // className="error-validation"
                      placeholder="Load Port Agent 1"
                      defaultValue={oderData.load_port_agent1}
                      onChange={handleChangeInput}
                    >
                      <option value="">Select</option>
                      {agents.map((x) => {
                        return (
                          <option value={x.account_name}>
                            {x.account_name}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Agent Appointment Date"
                    className="mb-3 datepick"
                  >
                    <DatePicker
        selected={date88}
        onChange={handleDateChangesEights}
        selectsStart
        tabIndex={48}
        dateFormat='dd/MM/yyyy'
         placeholderText="Select"
      />
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingSelect"
                    className="dropDown"
                    label="Load Port Agent 2"
                  >
                    <Form.Select
                      aria-label="Floating label select example"
                      name="load_port_agent2"
                      id="account_name"
                      placeholder="Load Port Agent 2"
                      tabIndex={46}
                      // className="error-validation"
                      defaultValue={oderData.load_port_agent2}
                      onChange={handleChangeInput}
                    >
                      <option value="">Select</option>
                      {agents.map((x) => {
                        return (
                          <option value={x.account_name}>
                            {x.account_name}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6"></Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingSelect"
                    className="dropDown"
                    label="Load Port Agent 3"
                  >
                    <Form.Select
                      aria-label="Floating label select example"
                      name="load_port_agent3"
                      id="account_name"
                      tabIndex={47}
                      // className="error-validation"
                      placeholder="Load Port Agent 3"
                      defaultValue={oderData.load_port_agent3}
                      onChange={handleChangeInput}
                    >
                      <option value="">Select</option>
                      {agents.map((x) => {
                        return (
                          <option value={x.account_name}>
                            {x.account_name}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </FloatingLabel>
                </Form.Group>
              </Row>
              <Row>
                <h4 className="heading">Shipping Instruction</h4>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Shipping Instruction No"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Shipping Instruction No"
                      name="shipping_instruction_no"
                      tabIndex={49}
                      defaultValue={oderData.shipping_instruction_no}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="6" className="tooltips">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Destination"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Destination"
                      name="destination"
                      tabIndex={51}
                      defaultValue={oderData.destination}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                   
                <Tooltip text={<span>Destination - Country Name</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="shipping instruction Date"
                    className="mb-3 datepick"
                  >
                     <DatePicker
        selected={date12}
        tabIndex={50}
        onChange={handleDateChangesTwele}
        selectsStart
        dateFormat='dd/MM/yyyy'
         placeholderText="Select"
      />
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Destination Port"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Destination Port"
                      tabIndex={52}
                      name="destination_port"
                      defaultValue={oderData.destination_port}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                </Form.Group>
              </Row>
              <Row>
                <h4 className="heading">Vessel Tracking</h4>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Pre-stowage"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Pre-stowage"
                      name="pre_stowage"
                      tabIndex={53}
                      defaultValue={oderData.pre_stowage}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="ETA notice recieved date 1"
                    className="mb-3 datepick"
                  >
                    <DatePicker
        selected={date13}
        onChange={handleDateChangesThirteen}
        selectsStart
        tabIndex={56}
        dateFormat='dd/MM/yyyy'
         placeholderText="Select"
      />
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="ETA notice recieved date 2"
                    className="mb-3 datepick"
                  >
                     <DatePicker
        selected={date14}
        onChange={handleDateChangesfourteen}
        selectsStart
        dateFormat='dd/MM/yyyy'
        tabIndex={54}
         placeholderText="Select"
      />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="ETA notice recieved date 3"
                    className="mb-3 datepick"
                  >
                      <DatePicker
        selected={date15}
        onChange={handleDateChangesfifteen}
        selectsStart
        tabIndex={57}
        dateFormat='dd/MM/yyyy'
         placeholderText="Select"
      />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="ETA notice recieved date 4"
                    className="mb-3 datepick"
                  >
                     <DatePicker
        selected={date16}
        onChange={handleDateChangesSisteen}
        selectsStart
        dateFormat='dd/MM/yyyy'
        tabIndex={55}
         placeholderText="Select"
      />
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="ETA notice recieved date 5"
                    className="mb-3 datepick"
                  >
                         <DatePicker
        selected={date17}
        onChange={handleDateChangesSeventeen}
        selectsStart
        dateFormat='dd/MM/yyyy'
         placeholderText="Select"
         tabIndex={58}
      />
                  </FloatingLabel>
                </Form.Group>
              </Row>
              <Row>
                <h4 className="heading">Vessel Arrival/Loading Commence</h4>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Vessel arrival date and time"
                    className="mb-3 datepick"
                  >
                     <DatePicker
        selected={selectAgent3}
        onChange={handleDateTimeChange3}
        showTimeSelect
        timeFormat="HH:mm"
        timeIntervals={15}
        tabIndex={59}
        dateFormat="dd/MM/yyyy h:mm aa"
        placeholderText="Select Date and Time"
      />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Notice of readiness confirm date"
                    className="mb-3 datepick"
                  >

<DatePicker
        selected={date1_1}
        onChange={handleDateChangesOne1}
        selectsStart
        tabIndex={63}
        dateFormat='dd/MM/yyyy'
         placeholderText="Select"
      />

                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Commence Loading Date and time"
                    className="mb-3 datepick"
                  >
                    <DatePicker
        selected={selectAgent5}
        onChange={handleDateTimeChange5}
        showTimeSelect
        tabIndex={60}
        timeFormat="HH:mm"
        timeIntervals={15}
        dateFormat="dd/MM/yyyy h:mm aa"
        placeholderText="Select Date and Time"
      />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="NOR Tendered date and time"
                    className="mb-3"
                  >
                      <DatePicker
        selected={selectAgent1}
        onChange={handleDateTimeChange1}
        showTimeSelect
        tabIndex={64}
        timeFormat="HH:mm"
        timeIntervals={15}
        dateFormat="dd/MM/yyyy h:mm aa"
        placeholderText="Select Date and Time"
      />


                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6"></Form.Group>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="NOR acceptance date and time"
                    className="mb-3 datepick"
                  >
                    <DatePicker
        selected={selectAgent}
        onChange={handleDateTimeChange}
        showTimeSelect
        timeFormat="HH:mm"
        timeIntervals={15}
        tabIndex={65}
        dateFormat="dd/MM/yyyy h:mm aa"
        placeholderText="Select Date and Time"
      />
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Completion loading date and time"
                    className="mb-3 datepick" 
                  >
                    <DatePicker
        selected={selectAgent6}
        onChange={handleDateTimeChange6}
        showTimeSelect
        timeFormat="HH:mm"
        tabIndex={61}
        timeIntervals={15}
        dateFormat="dd/MM/yyyy h:mm aa"
        placeholderText="Select Date and Time"
      />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Time to count for laytime"
                    className="mb-3"
                  >
                       <DatePicker
        selected={selectAgent2}
        onChange={handleDateTimeChange2}
        showTimeSelect
        timeFormat="HH:mm"
        tabIndex={66}
        timeIntervals={15}
        dateFormat="dd/MM/yyyy h:mm aa"
        placeholderText="Select Date and Time"
      />
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Sailing date and time"
                    className="mb-3 datepick"
                  >
                    <DatePicker
        selected={selectAgent7}
        onChange={handleDateTimeChange7}
        showTimeSelect
        tabIndex={62}
        timeFormat="HH:mm"
        timeIntervals={15}
        dateFormat="dd/MM/yyyy h:mm aa"
        placeholderText="Select Date and Time"
      />
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Turn Time"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      tabIndex={67}
                      placeholder="Turn Time"
                      name="turn_time"
                      defaultValue={oderData.turn_time}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                </Form.Group>
              </Row>
              <Row>
                <h4 className="heading">Mate Receipt</h4>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Mate recieved date and time"
                    className="mb-3 datepick"
                  >
                    <DatePicker
        selected={selectAgent8}
        onChange={handleDateTimeChange8}
        showTimeSelect
        timeFormat="HH:mm"
        timeIntervals={15}
        tabIndex={68}
        dateFormat="dd/MM/yyyy h:mm aa"
        placeholderText="Select Date and Time"
      />
                  </FloatingLabel>
                </Form.Group>
              </Row>
              <Row>
                <h4 className="heading">
                  Nomination of Independent Surveyour (Purchase)
                </h4>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingSelect"
                    className="dropDown"
                    label="Surveyor Agent"
                  >
                    <Form.Select
                      aria-label="Floating label select example"
                      name="surveyor_agent"
                      placeholder="Surveyor Agent"
                      tabIndex={69}
                      defaultValue={oderData.surveyor_agent}
                      onChange={handleChangeInput}
                    >
                      <option value="">Select</option>
                      {agent.map((x) => {
                        return (
                          <option value={x.account_name}>
                            {x.account_name}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Nomination of independent surveyor date"
                    className="mb-3"
                  >
                        <DatePicker
        selected={date18}
        onChange={handleDateChangesEighteen}
        selectsStart
        dateFormat='dd/MM/yyyy'
        tabIndex={70}
         placeholderText="Select"
      />
                  </FloatingLabel>
                </Form.Group>
              </Row>
              <Row>
                <h4 className="heading">
                  Nomination of Additional Surveyour (Sales)
                </h4>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingSelect"
                    className="dropDown"
                    label="Rwood surveyor agent"
                  >
                    <Form.Select
                      aria-label="Floating label select example"
                      name="rwood_surveyor_agent"
                      placeholder="Rwood surveyor agent"
                      tabIndex={71}
                      defaultValue={oderData.rwood_surveyor_agent}
                      onChange={handleChangeInput}
                    >
                      <option value="">Select</option>
                      {agent.map((x) => {
                        return (
                          <option value={x.account_name}>
                            {x.account_name}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Additional surveyor by rwood confirm date"
                    className="mb-3"
                  >

                   <DatePicker
        selected={date19}
        onChange={handleDateChangesNineteen}
        selectsStart
        tabIndex={73}
        dateFormat='dd/MM/yyyy'
         placeholderText="Select"
      />
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingSelect"
                    className="dropDown"
                    label="Buyer surveyor agent"
                  >
                    <Form.Select
                      aria-label="Floating label select example"
                      name="buyer_surveyor_agent"
                      placeholder="Buyer surveyor agent"
                      tabIndex={72}
                      defaultValue={oderData.buyer_surveyor_agent}
                      onChange={handleChangeInput}
                    >
                      <option value="">Select</option>
                      {agent.map((x) => {
                        return (
                          <option value={x.account_name}>
                            {x.account_name}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </FloatingLabel>
                </Form.Group>
              </Row>
              {/* <Row>
                <h4 className="heading">Summary of Documents</h4>
                <Form.Group as={Col} md="6"></Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="BL Date"
                    className="mb-3"
                  >

                    <DatePicker
        selected={date20}
        onChange={handleDateChangesTwenty}
        selectsStart
        dateFormat='dd/MM/yyyy'
         placeholderText="Select"
      />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6"></Form.Group>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="All actual payment recieved date"
                    className="mb-3"
                  >
                    <Form.Control
                      type="number"
                      placeholder="All actual payment recieved date"
                      name="all_actual_payment_recieved_date"
                      defaultValue={oderData.all_actual_payment_recieved_date}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6"></Form.Group>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="All document discount date"
                    className="mb-3"
                  >
                    <Form.Control
                      type="number"
                      placeholder="All document discount date"
                      name="all_document_discount_date"
                      defaultValue={oderData.all_document_discount_date}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6"></Form.Group>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="All expected value date"
                    className="mb-3"
                  >

                   <DatePicker
        selected={date21}
        onChange={handleDateChangesTwentyOne}
        selectsStart
        dateFormat='dd/MM/yyyy'
         placeholderText="Select"
      />
                  </FloatingLabel>
                </Form.Group>
              </Row> */}
              <Row>
                <h4 className="heading">Supplier Despatch/Demmurage LTC</h4>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingSelect"
                    className="dropDown"
                    label="Supplier despacth or demmurage"
                  >
                    <Form.Select
                      aria-label="Floating label select example"
                      name="supplier_despatch_or_demmurage"
                      placeholder="Supplier despacth or demmurage"
                      tabIndex={74}
                      defaultValue={oderData.supplier_despatch_or_demmurage}
                      onChange={handleChangeInput}
                    >
                      <option value="">--None--</option>
                      <option value="Despatch">Despatch</option>
                      <option value="Demmurage">Demurage</option>
                    </Form.Select>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6" className="tooltips">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="BL quantity laytime calculations"
                    className="mb-3"
                  >
                    <Form.Control
                      type="number"
                      placeholder="BL quantity laytime calculations"
                      name="bl_quantity_laytime_calculations"
                      tabIndex={85}
                      defaultValue={oderData.bl_quantity_laytime_calculations}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                   
                <Tooltip text={<span>BL Quantity for Laytime Calculations,<br/> if This is blank, System will take ALL BL Quantity ,<br/> if ALL BL Quantity is blank system will<br/> take Master LC Quantity.</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Supplier despatch or demurrage amount"
                    className="mb-3"
                  >
                    <Form.Control
                      type="number"
                      placeholder="Supplier despatch or demurrage amount"
                      tabIndex={75}
                      name="supplier_despatch_demurrage_amount"
                      defaultValue={
                        oderData.supplier_despatch_demurrage_amount
                      }
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6" className="tooltips">
                  <label>supplier check status</label>
                  <input
                    type='checkbox'
                    name=''
                    tabIndex={86}
                    checked={supplierCheck} // Use the state variable to determine if the checkbox is checked
                    onChange={handleCheckboxChange2} // Handle the change event
                    className='mx-3'
                  />
                   
                <Tooltip text={<span>Checking for Vinita - Supplier Despatch Demmurage</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                </Form.Group>


                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Supplier laytime time used"
                    className="mb-3"
                  >
                    <Form.Control
                      type="number"
                      placeholder="Supplier laytime time used"
                      tabIndex={76}
                      name="supplier_laytime_time_used"
                      defaultValue={oderData.supplier_laytime_time_used}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Supplier LTC Reccount"
                    className="mb-3"
                  >
                    <Form.Control
                      type="number"
                      placeholder="Supplier LTC Reccount"
                      name="supplier_ltc_rec_count"
                      tabIndex={87}
                      defaultValue={oderData.supplier_ltc_rec_count}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Supplier laytime time saved exceed"
                    className="mb-3"
                  >
                    <Form.Control
                      type="number"
                      placeholder="Supplier laytime time saved exceed"
                      name="supplier_laytime_time_saved_exceed"
                      tabIndex={77}
                      defaultValue={oderData.supplier_laytime_time_saved_exceed}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6"></Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingSelect"
                    className="dropDown"
                    label="Supplier despatch demurrage status"
                  >
                    <Form.Select
                      aria-label="Floating label select example"
                      name="supplier_despatch_demurrage_status"
                      tabIndex={78}
                      placeholder="Supplier despatch demurrage status"
                      defaultValue={oderData.supplier_despatch_demurrage_status}
                      onChange={handleChangeInput}
                    >
                      <option value="">--None--</option>
                      <option value="Draft">Draft</option>
                      <option value="Approved by Rwood">
                        Approved by Rwood
                      </option>
                      <option value="Not Confirmed">Not Confirmed</option>
                      <option value="Accepted">Confirmed</option>
                      <option value="Settled">Settled</option>
                      <option value="Split BL">Split BL</option>
                      <option value="-NA-">-NA-</option>
                      <option value="Not Confirmed but Paid">
                        Not Confirmed but Paid
                      </option>
                      <option value="Not Confirmed but Settled">
                        Not Confirmed but Settled
                      </option>
                    </Form.Select>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Count of desp demm DN"
                    className="mb-3"
                  >
                    <Form.Control
                      type="number"
                      tabIndex={88}
                      placeholder="Count of desp demm DN"
                      name="count_of_desp_demm_dn"
                      defaultValue={oderData.count_of_desp_demm_dn}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                   
                <Tooltip text={<span>Roll up (Debit Note) for  Debit Note Type = <br/>Dispatch/Demmurage</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                </Form.Group>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="LTC sent to supplier date"
                    className="mb-3"
                  >
                    <DatePicker
        selected={date24}
        onChange={handleDateChangesTwentyFour}
        selectsStart
        tabIndex={80}
        dateFormat='dd/MM/yyyy'
         placeholderText="Select"
      />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6" className="tooltips">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Count of Other DN"
                    className="mb-3"
                  >
                    <Form.Control
                      type="number"
                      placeholder="Count of Other DN"
                      name="count_of_other_dn"
                      tabIndex={89}
                      defaultValue={oderData.count_of_other_dn}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                   
                <Tooltip text={<span>Roll up (Debit Note) for Debit Note Type = <br/>Price Difference, GCV Variation, Umpire Analysis, Dead Freight, <br/>Interest - Late Payment</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Supplier LTC  to RW"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Supplier LTC  to RW"
                      tabIndex={81}
                      name="supplier_ltc_to_rw"
                      defaultValue={oderData.supplier_ltc_to_rw}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6" className='tooltips'>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Supplier final dem or despatch payable"
                    className="mb-3"
                  >
                    <Form.Control
                      type="number"
                      placeholder="Supplier final dem or despatch payable"
                      tabIndex={90}
                      name="supplier_final_dem_or_despatch_payable"
                      defaultValue={
                        oderData.supplier_final_dem_or_despatch_payable
                      }
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                   
                <Tooltip text={<span>Roll up from Payable/Receivable</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Confirm Date"
                    className="mb-3 datepick"
                  >
                     <DatePicker
        selected={date23}
        onChange={handleDateChangesTwentyThree}
        selectsStart
        tabIndex={83}
        dateFormat='dd/MM/yyyy'
         placeholderText="Select"
      />
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="6" className="tooltips">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Supplier final other payable"
                    className="mb-3"
                  >
                    <Form.Control
                      type="number"
                      placeholder="Supplier final other payable"
                      name="supplier_final_other_payable"
                      tabIndex={91}
                      defaultValue={oderData.supplier_final_other_payable}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                   
                <Tooltip text={<span>Roll up from Payable/Receivable</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                </Form.Group>
                <Form.Group as={Col} md="6"  className="textarea-half">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Supplier remark despacth demmurage"
                    className="mb-3"
                  >
                    <Form.Control
                      as="textarea"
                      placeholder="Supplier remark despacth demmurage"
                      tabIndex={84}
                      name="supplier_remark_despacth_demmurage"
                      defaultValue={oderData.supplier_remark_despacth_demmurage}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6"></Form.Group>
              </Row>
              <Row>
                <h4 className="heading">Buyer Despatch/Demmurage LTC</h4>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingSelect"
                    className="dropDown"
                    label="Buyer despacth or demmurage"
                  >
                    <Form.Select
                      aria-label="Floating label select example"
                      tabIndex={92}
                      name="buyer_despatch_or_demmurage"
                      placeholder="Buyer despacth or demmurage"
                      defaultValue={oderData.buyer_despatch_or_demmurage}
                      onChange={handleChangeInput}
                    >
                      <option value="">--None--</option>
                      <option value="Despatch">Despatch</option>
                      <option value="Demmurage">Demurage</option>
                    </Form.Select>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6" className="tooltips">
                  <label>buyer check status</label>
                  <input
                    type='checkbox'
                    name='buyer_check_status'
                    tabIndex={100}
                    checked={supplierChecks} // Use the state variable to determine if the checkbox is checked
                    onChange={handleCheckboxChange3} // Handle the change event
                    className='mx-3'
                  />
                   
                <Tooltip text={<span>Vinita - Buyer Check status for Desptach Demmurage</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Buyer despatch or demurrage amount"
                    className="mb-3"
                  >
                    <Form.Control
                      type="number"
                      placeholder="Buyer despatch or demurrage amount"
                      name="buyer_despatch_demurrage_amount"
                      tabIndex={93}
                      defaultValue={
                        oderData.buyer_despatch_demurrage_amount
                      }
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Buyer laytime time used"
                    className="mb-3"
                  >
                    <Form.Control
                      type="number"
                      tabIndex={101}
                      placeholder="Buyer laytime time used"
                      name="buyer_laytime_time_used"
                      defaultValue={oderData.buyer_laytime_time_used}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Buyer laytime time saved exceed"
                    className="mb-3"
                  >
                    <Form.Control
                      type="number"
                      placeholder="Buyer laytime time saved exceed"
                      name="buyer_laytime_time_saved_exceed"
                      tabIndex={94}
                      defaultValue={oderData.buyer_laytime_time_saved_exceed}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6" className='tooltips'>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Buyer final dem or despatch payable"
                    className="mb-3"
                  >
                    <Form.Control
                      type="number"
                      tabIndex={102}
                      placeholder="Buyer final dem or despatch payable"
                      name="buyer_final_dem_or_despatch_payable"
                      defaultValue={
                        oderData.buyer_final_dem_or_despatch_payable
                      }
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                   
                <Tooltip text={<span>Roll up from Payable/Receivable</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingSelect"
                    className="dropDown"
                    label="Buyer despatch demurrage status"
                  >
                    <Form.Select
                      aria-label="Floating label select example"
                      name="buyer_despatch_demurrage_status"
                      placeholder="Buyer despatch demurrage status"
                      tabIndex={95}
                      defaultValue={oderData.buyer_despatch_demurrage_status}
                      onChange={handleChangeInput}
                    >
                      <option value="">--None--</option>
                      <option value="Draft">Draft</option>
                      <option value="Approved by Rwood">
                        Approved by Rwood
                      </option>
                      <option value="Not Confirmed">Not Confirmed</option>
                      <option value="Accepted">Confirmed</option>
                      <option value="Settled">Settled</option>
                      <option value="Split BL">Split BL</option>
                      <option value="-NA-">-NA-</option>
                      <option value="Not Confirmed but Paid">
                        Not Confirmed but Paid
                      </option>
                      <option value="Not Confirmed but Settled">
                        Not Confirmed but Settled
                      </option>
                    </Form.Select>
                  </FloatingLabel>
                </Form.Group>{" "}
                <Form.Group as={Col} md="6" className="tooltips">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Buyer final other payable"
                    className="mb-3"
                  >
                    <Form.Control
                      type="number"
                      tabIndex={103}
                      placeholder="Buyer final other payable"
                      name="buyer_final_other_payable"
                      defaultValue={oderData.buyer_final_other_payable}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                   
                <Tooltip text={<span>Roll up from Payable/Receivable</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="LTC sent to Buyer date"
                    className="mb-3"
                  >
                    <DatePicker
        selected={date22}
        onChange={handleDateChangesTwentytwo}
        selectsStart
        tabIndex={96}
        dateFormat='dd/MM/yyyy'
         placeholderText="Select"
      />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6"></Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Buyer LTC  to RW"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Buyer LTC  to RW"
                      name="buyer_ltc_to_rw"
                      tabIndex={97}
                      defaultValue={oderData.buyer_ltc_to_rw}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6"></Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Confirm Date"
                    className="mb-3 datepick"
                  >
                     <DatePicker
        selected={date25}
        onChange={handleDateChangesTwentyFive}
        selectsStart
        dateFormat='dd/MM/yyyy'
        tabIndex={98}
         placeholderText="Select"
      />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6"></Form.Group>
                <Form.Group as={Col} md="6"  className="textarea-half">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Buyer remark despacth demmurage"
                    className="mb-3"
                  >
                    <Form.Control
                      as="textarea"
                      placeholder="Buyer remark despacth demmurage"
                      tabIndex={99}
                      name="buyer_remark_despacth_demmurage"
                      defaultValue={oderData.buyer_remark_despacth_demmurage}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                </Form.Group><Form.Group as={Col} md="6"></Form.Group>
              </Row>
              <Row className="mb-3">
                <h4 className="heading">Summary Payable Receivable</h4>

                <Form.Group as={Col} md="6" className="tooltips">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Sales invoice"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      tabIndex={104}
                      placeholder="Sales invoice"
                      name="sales_invoice"
                      defaultValue={oderData.sales_invoice}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                   
                <Tooltip text={<span>Roll up from Payable/Receivable</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                </Form.Group>

                <Form.Group as={Col} md="6" className="tooltips">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Surveyor Invoice"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Surveyor Invoice"
                      name="surveyor_invoice"
                      tabIndex={107}
                      defaultValue={oderData.surveyor_invoice}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                   
                <Tooltip text={<span>Roll up from Payable/Receivable</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                </Form.Group>
                <Form.Group as={Col} md="6" className="tooltips">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Purchase Invoice"
                    className="mb-3"
                  >
                    <Form.Control
                       type="text"
                       tabIndex={105}
                      placeholder="Purchase Invoice"
                      name="purchase_invoice"
                      defaultValue={oderData.purchase_invoice}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Load Port Invoice"
                    className="mb-3"
                  >
                    <Form.Control
                       type="text"
                      placeholder="Load Port Invoice"
                      name="load_port_invoice"
                      tabIndex={108}
                      defaultValue={oderData.load_port_invoice}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="net des dem inflow or outflow"
                    className="mb-3"
                  >
                    <Form.Control
                       type="text"
                       tabIndex={106}
                      placeholder="net des dem inflow or outflow"
                      name="net_des_dem_inflow_or_outflow"
                      defaultValue={oderData.net_des_dem_inflow_or_outflow}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                </Form.Group>
              </Row>

              <p className="edit-btn">
              <input
      type="submit"
      className="account-save"
      onClick={handleSaveEdit}
      value={isSubmitted ? "Saving" : "Save"}
      disabled={isSubmitted}
    />
                <button className="btn btn-primary" onClick={handleCancelEdit}>
                  Cancel
                </button>
              </p>
            </div>
          ) : (
            <div className="tasks fulid-section order-section">
              {/*
<p id='top-header'>
               <Popup className='post-buyer'
                                      trigger={
                                        <span className="button add-accountrecord">
                                          {" "}
                                          RW Payment/Receipt LineItem{" "}
                                        </span>
                                      }
                                      modal
                                    >
                                        <div className="form-group" onChange={handleChangeRadio}>
                                        <Form.Check
            type='radio'
            id=''
            name='type'
            label='Buyer - Credit Note'
            value='Buyer - Credit Note'
          />
          <Form.Check
            type='radio'
            id=''
            name='type'
            label='Supplier - Credit Note'
            value='Supplier - Credit Note'
          />
          <Form.Check
            type='radio'
            id=''
            name='type'
            label='Buyer - Debit Note'
            value='Buyer - Debit Note'
          />
          <Form.Check
            type='radio'
            id=''
            name='type'
            label='Supplier - Debit Note'
            value='Supplier - Debit Note'
          />
          <Form.Check
            type='radio'
            id=''
            name='type'
            label='Load Port Invoice'
            value='Load Port Invoice'
          />
          <Form.Check
            type='radio'
            id=''
            name='type'
            label='Other Payable'
            value='Other Payable'
          />
          <Form.Check
            type='radio'
            id=''
            name='type'
            label='Other Receivable'
            value='Other Receivable'
          />
             <Form.Check
            type='radio'
            id=''
            name='type'
            label='Purchase Invoice'
            value='Purchase Invoice'
          />
             <Form.Check
            type='radio'
            id=''
            name='type'
            label='Sales Invoice'
            value='Sales Invoice'
          />
             <Form.Check
            type='radio'
            id=''
            name='type'
            label='Surveyor Invoice'
            value='Surveyor Invoice'
          />

                                        </div>
                                        <input
                                          type="submit"
                                          className="btn btn-primary "
                                          value="Submit"
                                          onClick={handleClick}
                                        />

                                    </Popup>

                                    </p> */}
              <div className="edit-delete">
                <span id="edit" onClick={handleEditClick}>
                  Edit
                </span>
                <span>
                  <Link to={"/order/create-order-list"}> Cancel</Link>
                </span>
                
                <span Style="cursor:pointer" onClick={buyerLaytime}>
                  Buyer LayTime Calculation
                </span>
                <span onClick={SupplierLaytime}>
                  Supplier LayTime Calculation
                </span>
                <span>Vessel Wise Loading Report</span>
                <span>View Summary</span>
              </div>
              <div className="col-md-12" id="ax.account_details">
                <h4 className="heading">Order Details</h4>
                <hr></hr>
              </div>
              <table class="table table-bordered account-table tables">
                <tbody>
                  <tr>
                    <td id="td-right">
                      <span>order no</span>
                    </td>
                    <td>{oderData.order_no}</td>
                    <td id="td-right">
                      <span>order Owner</span>
                    </td>
                    <td>{oderData.order_owner}</td>
                  </tr>

                  <tr>
                    <td id="td-right">
                      <span>Company</span>
                    </td>
                    <td>
                      <Link
                        to={`/company/company-details/${oderData.rwood_company_id}`}
                      >
                        {oderData.company}
                      </Link>
                    </td>
                    <td id="td-right">
                      <span>Locked</span>
                    </td>
                    <td><input
                    type='checkbox'
                    name='locked'
                    checked={lockeds} // Use the state variable to determine if the checkbox is checked
                    onChange={handleCheckboxChange1} // Handle the change event
                    className='mx-3'
                    disabled={isButtonEnabled}
                  /></td>
                  </tr>
                  <tr>
                    <td id="td-right">
                      <span>Currency</span>
                    </td>
                    <td>{oderData.currency}</td>
                    <td id="td-right">
                      <span>Activated Date</span>
                    </td>
                    <td>{oderData.activated_date}</td>
                  </tr>
                  <tr>
                    <td id="td-right">
                      <span>Exchange Rate</span>
                    </td>
                    <td>{oderData.exchange_rate}</td>
                    <td id="td-right">
                      <span>Activated By</span>
                    </td>
                    <td>{oderData.activated_by}</td>
                  </tr>

                  <tr>
                    <td id="td-right">
                      <span>Account Name</span>
                    </td>
                    <td>
                      <Link to={"/accounts/" + oderData.account_id}>
                        {" "}
                        {oderData.account_name}{" "}
                      </Link>
                    </td>
                    <td id="td-right">
                      <span>order record type</span>
                    </td>
                    <td>{oderData.order_record_type}</td>
                  </tr>

                  <tr>
                    <td id="td-right">
                      <span>buyer full name</span>
                    </td>
                    <td>{oderData.buyer_full_name}</td>
                    <td id="td-right">
                      <span>supplier</span>
                    </td>
                    <td><Link to={`/accounts/${oderData.supplier_id}`}>{oderData.supplier}</Link></td>
                  </tr>

                  <tr>
                    <td id="td-right">
                      <span>contract Number</span>
                    </td>
                    <td><Link to={`/contract/contract-details/${oderData.contract_id}`}>{oderData.contract_number}</Link></td>
                    <td id="td-right">
                      <span>Supplier Address	</span>
                    </td>
                    <td>
                       {oderData.supplier_address}{" "}

                    </td>
                  </tr>
                  <tr>
                    <td id="td-right">
                      <span>Contract Shipment	</span>
                    </td>
                    <td><Link to={`/shipment/contract-shipment-details/${oderData.contract_ship_id}`}>{oderData.contract_shipment}</Link></td>
                    <td id="td-right">
                      <span>Supplier Name	</span>
                    </td>
                    <td>{oderData.supplier_name}</td>
                  </tr>
                  <tr>
                    <td id="td-right">
                      <span>Order Start Date	</span>
                    </td>
                    <td>{oderData.order_start_date}</td>
                    <td id="td-right">
                      <span>shipper	</span>
                    </td>
                    <td>{oderData.shipper}</td>
                  </tr>
                  <tr>
                    <td id="td-right">
                      <span>Status</span>
                    </td>
                    <td>{oderData.status}</td>
                    <td id="td-right">
                      <span>	</span>
                    </td>
                    <td></td>
                  </tr>

                  <tr>
                    <td id="td-right">
                    <span className="a-a">
                                        Vessel Barge Name{" "}
                                                <Tooltip text={<span>Vessel_Name__c &amp; &quot;-&quot; &amp;  Barge_Name__c</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                                              </span>
                    </td>
                    <td>{oderData.vessel_barge_name}</td>
                    <td id="td-right">
                      <span>Contract Quantity (MT)	</span>
                    </td>
                    <td>{renderShipmentQty(oderData.contract_quantity_MT)}</td>
                  </tr>

                  <tr>
                    <td id="td-right">
                      <span>Opportunity</span>
                    </td>
                    <td><Link to={`/opportunity/opportunity-detail/${oderData.opportunity_id}`}>{oderData.opportunity}</Link></td>
                    <td id="td-right">
                      <span>Shipment Qty</span>
                    </td>
                   <td>{renderShipmentQty(oderData.shipment_qty)}</td>
                  </tr>

                  <tr>
                    <td id="td-right">
                    <span className="a-a">
                                        End User{" "}
                                                <Tooltip text={<span>End User As Per COO Certificate</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                                              </span>
                    </td>
                    <td>{oderData.end_user}</td>
                    <td id="td-right">
                      <span>Shipment Quality Tolerance	</span>
                    </td>
                    <td>{renderShipmentQty(oderData.shipment_quality_tolerance)}</td>
                  </tr>
                  <tr>
                    <td id="td-right">
                      <span>Remark</span>
                    </td>
                    <td>{oderData.remark}</td>
                    <td id="td-right">
                    <span className="a-a">
                                        BL No{" "}
                                                <Tooltip text={<span>All BL No, Seperated by Coma from post shipment document object</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                                              </span>
                    </td>
                    <td>{oderData.bl_no}</td>
                  </tr>
                </tbody>
              </table>
              <div className="col-md-12" id="ax.account_details">
                <h4 className="heading">Purchase Contract Information</h4>
                <hr></hr>
              </div>
              <table class="table table-bordered account-table tables">
                <tbody>
                  <tr>
                    <td id="td-right">
                    <span className="a-a">
                                        Purchase Contract 1{" "}
                                                <Tooltip text={<span>Data coming from Order Product</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                                              </span>
                    </td>
                    <td><Link to={`/contract/contract-details/${oderData.purchase_contract_id}`}>{oderData.purchase_contract_1}</Link></td>
                    <td id="td-right">
                    <span className="a-a">
                                        Purchase Contract 2{" "}
                                                <Tooltip text={<span>Data coming from Order Product</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                                              </span>
                    </td>
                    <td><Link to={`/contract/contract-details/${oderData.purchase_contract_id_2}`}>{oderData.purchase_contract_2}</Link></td>
                  </tr>
                  <tr>
                    <td id="td-right">
                      <span>Purchase Contract Status 1</span>
                    </td>
                    <td>{oderData.purchase_contract_status_1}</td>
                    <td id="td-right">
                      <span>Purchase Contract Status 2</span>
                    </td>
                    <td>{oderData.purchase_contract_status_2}</td>
                  </tr>
                  <tr>
                    <td id="td-right">
                      <span>Supplier Contract Reference 1</span>
                    </td>
                    <td>{oderData.supplier_contract_refernce_1}</td>
                    <td id="td-right">
                      <span>Supplier Contract Reference 2</span>
                    </td>
                    <td>{oderData.supplier_contract_refernce_2}</td>
                  </tr>
                  <tr>
                    <td id="td-right">
                      <span>LOA Number 1</span>
                    </td>
                    <td>{oderData.loa_number}</td>
                    <td id="td-right">
                      <span>LOA Number 2</span>
                    </td>
                    <td>{oderData.loa_number_2}</td>
                  </tr>
                </tbody>
              </table>

              <div className="col-md-12" id="ax.account_details">
                <h4 className="heading">Laycan Information</h4>
                <hr></hr>
              </div>
              <table class="table table-bordered account-table tables">
                <tbody>
                  <tr>
                    <td id="td-right">
                      <span>Product Specification</span>
                    </td>
                    <td>{oderData.product_specification}</td>

                    <td id="td-right">
                      <span>Laycan Month</span>
                    </td>
                    <td>{oderData.laycan_month}</td>
                  </tr>
                  <tr>
                    <td id="td-right">
                      <span>Laycan From Date</span>
                    </td>
                    <td>{oderData.laycan_from_date}</td>
                    <td id="td-right">
                      <span>Laycan Year</span>
                    </td>
                    <td>{oderData.laycan_year}</td>
                  </tr>
                  <tr>
                    <td id="td-right">
                      <span>Laycan To Date</span>
                    </td>
                    <td>{oderData.laycan_to_date}</td>
                    <td id="td-right">
                      <span>Shipment Month</span>
                    </td>
                    <td>{oderData.shipment_month}</td>
                  </tr>
                  <tr>
                    <td id="td-right">
                      <span>ETA Date</span>
                    </td>
                    <td>{oderData.eta_date}</td>
                    <td id="td-right">
                    <span className="a-a">
                                        Ship Month{" "}
                                                <Tooltip text={<span>Laycan_From_Date__c</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                                              </span>
                    </td>
                    <td>{oderData.ship_month}</td>

                  </tr>
                  <tr>

                    <td id="td-right">
                      {/* <span>Ship Month</span> */}
                    </td>
                    <td></td>
                    <td id="td-right">
                      <span>Laycan Period</span>
                    </td>
                    <td>{oderData.laycan_period}</td>
                  </tr>
                </tbody>
              </table>
              <div className="col-md-12" id="ax.account_details">
                <h4 className="heading">Business Confirmation To Supplier</h4>
                <hr></hr>
              </div>
              <table class="table table-bordered account-table tables">
                <tbody>
                  <tr>
                    <td id="td-right">
                      <span>Business Confirm To Supplier No</span>
                    </td>
                    <td>{oderData.business_confirm_to_supplier_no}</td>
                    <td id="td-right">
                      <span></span>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td id="td-right">
                      <span>Business Confirm To Supplier Date</span>
                    </td>
                    <td>{oderData.business_confirm_to_supplier_date}</td>

                    <td id="td-right">
                      <span></span>
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
              <div className="col-md-12" id="ax.account_details">
                <h4 className="heading">Vessel Nomination</h4>
                <hr></hr>
              </div>
              <table class="table table-bordered account-table tables">
                <tbody>
                  <tr>
                    <td id="td-right">
                      <span>Vessel Name</span>
                    </td>
                    <td>{oderData.vessel_name}</td>
                    <td id="td-right">
                      <span>ETB Date</span>
                    </td>
                    <td>{oderData.etb_date}</td>
                  </tr>
                  <tr>
                    <td id="td-right">
                      <span>Barge Name</span>
                    </td>
                    <td>{oderData.barge_name}</td>
                    <td id="td-right">
                      <span>Jakarta Team Member</span>
                    </td>
                    <td>{oderData.jakarta_team_member}</td>
                  </tr>
                  <tr>
                    <td id="td-right">
                      <span>TUG Boat No</span>
                    </td>
                    <td>{renderShipmentQty(oderData.tug_boat_no)}</td>

                    <td id="td-right">
                      <span>loading rate</span>
                    </td>
                    <td>{renderShipmentQty(oderData.loading_rate)} </td>
                  </tr>
                  <tr>
                    <td id="td-right">
                      <span>Tonnage</span>
                    </td>
                    <td>{renderShipmentQty(oderData.tonnage)}</td>

                    <td id="td-right">
                      <span>Buyer Demurrage Rate</span>
                    </td>
                    <td>{renderShipmentQty(oderData.buyer_despatch_demurrage_rate)}</td>
                  </tr>
                  <tr>
                    <td id="td-right">
                      <span>Vessel Type</span>
                    </td>
                    <td>{oderData.vessel_type}</td>
                    <td id="td-right">
                    <span className="a-a">
                                        Days Allowed for Laycan{" "}
                                                <Tooltip text={<span>BL_Quantity_Laytime_Calculations__c)),<br/>(BL_Quantity_Laytime_Calculations__c/ <br/>Loading_Rate__c)<br/>Total_BL_Qty__c/ Loading_Rate__c,<br/>Total_Master_LC_Qty__c / <br/>Loading_Rate__c</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                                              </span>
                    </td>
                    <td>{renderShipmentQty(oderData.days_allowed_for_laycan)}</td>
                  </tr>
                  <tr>
                    <td id="td-right">
                    <span className="a-a">
                                        Vessel Nomination Date{" "}
                                                <Tooltip text={<span>Vessel nomination date is the one on which the buyer nominates the vessel.</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                                              </span>
                    </td>
                    <td>{oderData.vessel_nomination_date}</td>
                    <td id="td-right">
                      <span>Supplier  Demurrage Rate</span>
                    </td>
                    <td>{renderShipmentQty(oderData.supplier_despatch_demurrage_rate)}</td>
                  </tr>
                  <tr>
                    <td id="td-right">
                    <span className="a-a">
                                        Vessel Nomination Confirm Date{" "}
                                                <Tooltip text={<span>the day Vessel nomination gets confirm from buyer and Supplier</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                                              </span>
                    </td>
                    <td>{oderData.vessel_nomination_confirm_date}</td>
                     <td id="td-right">
                     <span className="a-a">
                                        Loading At{" "}
                                                <Tooltip text={<span>Load Port</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                                              </span>
                    </td>
                    <td>{oderData.loading_at}</td>
                  </tr>
                  <tr>
                  <td id="td-right">
                      <span>vessel nomination description</span>
                    </td>
                    <td><div dangerouslySetInnerHTML={{ __html: oderData.vessel_nomination_description }} /></td>
                    <td id="td-right">
                      {/* <span>Load Port Agent 1</span> */}
                    </td>
                    <td>
                      {/* {oderData.load_port_agent1} */}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="col-md-12" id="ax.account_details">
                <h4 className="heading">Vessel Acceptance</h4>
                <hr></hr>
              </div>
              <table class="table table-bordered account-table tables">
                <tbody>
                  <tr>
                    <td id="td-right">
                    <span className="a-a">
                                        Vessel Acceptance Date{" "}
                                                <Tooltip text={<span>Vessel acceptance date is <br/>the one on which shipper accept<br/> the vessel nomination</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                                              </span>
                    </td>
                    <td>{oderData.vessel_acceptance_date}</td>
                    <td id="td-right">
                    <span className="a-a">
                                        Vessel Acceptance Confirm Date{" "}
                                                <Tooltip text={<span>Vessel acceptance confirm date is <br/>the one on which RW confirms the vessel nomination<br/> to buyer</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                                              </span>
                    </td>
                    <td>{oderData.vessel_acceptance_confirm_date}</td>
                  </tr>
                </tbody>
              </table>

              <div className="col-md-12" id="ax.account_details">
                <h4 className="heading">Agent Appointment</h4>
                <hr></hr>
              </div>
              <table class="table table-bordered account-table tables">
                <tbody>
                  <tr>
                    <td id="td-right">
                      <span>load port agent1</span>
                    </td>
                    <td>{oderData.load_port_agent1}</td>
                    <td id="td-right">
                      <span>agent appointment date</span>
                    </td>
                    <td>{oderData.agent_appointment_date}</td>
                  </tr>
                  <tr>
                    <td id="td-right">
                      <span>load port agent2</span>
                    </td>
                    <td>{oderData.load_port_agent2}</td>
                    <td id="td-right">
                      <span></span>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td id="td-right">
                      <span>load port agent3</span>
                    </td>
                    <td>{oderData.load_port_agent3}</td>
                    <td id="td-right">
                      <span></span>
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>

              <div className="col-md-12" id="ax.account_details">
                <h4 className="heading">Shipping Instruction</h4>
                <hr></hr>
              </div>
              <table class="table table-bordered account-table tables">
                <tbody>
                  <tr>
                    <td id="td-right">
                      <span>Shipping Instruction No</span>
                    </td>
                    <td>{oderData.shipping_instruction_no}</td>
                    <td id="td-right">
                    <span className="a-a">
                                        Destination{" "}
                                                <Tooltip text={<span>Destination - Country Name</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                                              </span>
                    </td>
                    <td>{oderData.destination}</td>
                  </tr>
                  <tr>
                    <td id="td-right">
                      <span>Shipping Instruction Date</span>
                    </td>
                    <td>{oderData.shipping_instruction_date}</td>
                    <td id="td-right">
                      <span>Destination Port</span>
                    </td>
                    <td>{oderData.destination_port}</td>
                  </tr>
                </tbody>
              </table>

              <div className="col-md-12" id="ax.account_details">
                <h4 className="heading">Vessel Tracking</h4>
                <hr></hr>
              </div>
              <table class="table table-bordered account-table tables">
                <tbody>
                  <tr>
                    <td id="td-right">
                      <span>Pre Stowage</span>
                    </td>
                    <td>{renderShipmentQty(oderData.pre_stowage)}</td>
                    <td id="td-right">
                      <span>ETA Notice Recieved Date 3</span>
                    </td>
                    <td>{oderData.eta_notice_recieved_date3}</td>
                  </tr>
                  <tr>
                    <td id="td-right">
                      <span>ETA Notice Recieved Date 1</span>
                    </td>
                    <td>{oderData.eta_notice_recieved_date1}</td>
                    <td id="td-right">
                      <span>ETA Notice Recieved Date 4</span>
                    </td>
                    <td>{oderData.eta_notice_recieved_date4}</td>
                  </tr>
                  <tr>
                    <td id="td-right">
                      <span>ETA Notice Recieved Date 2</span>
                    </td>
                    <td>{oderData.eta_notice_recieved_date2}</td>
                    <td id="td-right">
                      <span>ETA Notice Recieved Date 5</span>
                    </td>
                    <td>{oderData.eta_notice_recieved_date5}</td>
                  </tr>
                </tbody>
              </table>

              <div className="col-md-12" id="ax.account_details">
                <h4 className="heading">Vessel Arrival/Loading Commence</h4>
                <hr></hr>
              </div>
              <table class="table table-bordered account-table tables">
                <tbody>
                  <tr>
                    <td id="td-right">
                      <span>vessel arrival date and time</span>
                    </td>
                    <td>{oderData.vessel_arrival_date_and_time}</td>
                    <td id="td-right">
                      <span>notice of readiness confirm date</span>
                    </td>
                    <td>{oderData.notice_of_readiness_confirm_date}</td>
                  </tr>
                  <tr>
                    <td id="td-right">
                      <span>commence loading date and time</span>
                    </td>
                    <td>{oderData.commence_loading_date_and_time}</td>
                    <td id="td-right">
                      <span>NOR tendered date and time</span>
                    </td>
                    <td>{oderData.nor_tendered_date_and_time}</td>
                  </tr>


                  <tr>
                    <td id="td-right">
                      <span></span>
                    </td>
                    <td></td>
                    <td id="td-right">
                      <span>NOR acceptance date and time</span>
                    </td>
                    <td>{oderData.nor_acceptance_date_and_time}</td>
                  </tr>
                  <tr>
                    <td id="td-right">
                      <span>completion loading date and time</span>
                    </td>
                    <td>{oderData.completion_loading_date_and_time}</td>
                    <td id="td-right">
                      <span>free detention time</span>
                    </td>
                    <td>{oderData.free_detention_time}</td>
                  </tr>
                  <tr>
                    <td id="td-right">
                      <span>sailing date and time</span>
                    </td>
                    <td>{oderData.sailing_date_and_time}</td>
                    <td id="td-right">
                      <span>time to count for laytime</span>
                    </td>
                    <td>{oderData.time_to_count_for_laytime}</td>
                  </tr>
                  <tr>
                    <td id="td-right">
                      <span></span>
                    </td>
                    <td></td>
                    <td id="td-right">
                      <span>turn time</span>
                    </td>
                    <td>{oderData.turn_time}</td>
                  </tr>
                </tbody>
              </table>

              <div className="col-md-12" id="ax.account_details">
                <h4 className="heading">Mate Receipt</h4>
                <hr></hr>
              </div>
              <table class="table table-bordered account-table tables">
                <tbody>
                  <tr>
                    <td id="td-right">
                      <span>Mate Recieved Date And Time</span>
                    </td>
                    <td>{oderData.mate_recieved_date_and_time}</td>
                    <td id="td-right">
                      <span></span>
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>

              <div className="col-md-12" id="ax.account_details">
                <h4 className="heading">
                  Nomination of Independent Surveyour (Purchase)
                </h4>
                <hr></hr>
              </div>
              <table class="table table-bordered account-table tables">
                <tbody>
                  <tr>
                    <td id="td-right">
                      <span>Surveyor Agent</span>
                    </td>
                    <td>{oderData.surveyor_agent}</td>
                    <td id="td-right">
                      <span>Nomination of Independent Surveyor Date</span>
                    </td>
                    <td>{oderData.nomination_of_independent_surveyor_date}</td>
                  </tr>
                </tbody>
              </table>

              <div className="col-md-12" id="ax.account_details">
                <h4 className="heading">
                  Nomination of Additional Surveyour (Sales)
                </h4>
                <hr></hr>
              </div>
              <table class="table table-bordered account-table tables">
                <tbody>
                  <tr>
                    <td id="td-right">
                      <span>Rwood Surveyour Agent</span>
                    </td>
                    <td>{oderData.rwood_surveyor_agent}</td>
                    <td id="td-right">
                      <span>Additional surveyor by rwood confirm date</span>
                    </td>
                    <td>
                      {oderData.additional_surveyor_by_rwood_confirm_date}
                    </td>
                  </tr>
                  <tr>
                    <td id="td-right">
                      <span>Buyer surveyor agent</span>
                    </td>
                    <td>{oderData.buyer_surveyor_agent}</td>
                    <td id="td-right">
                      <span></span>
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
              <div className="col-md-12" id="ax.account_details">
                <h4 className="heading">Summary of Documents</h4>
                <hr></hr>
              </div>
              <table class="table table-bordered account-table tables">
                <tbody>
                  <tr>
                    <td id="td-right">
                    <span className="a-a">
                                        Total LC Transfer Cost{" "}
                                                <Tooltip text={<span>Total LC Transfer Cost - Roll up Pre Shipment (LC Transfer Cost)</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                                              </span>
                    </td>
                    <td>{renderShipmentQty(oderData.total_lc_transer_cost)}</td>
                    <td id="td-right">
                      <span>Total Master LC Qty</span>
                    </td>
                    <td>{renderShipmentQty(oderData.total_master_lc_qty)}</td>
                  </tr>
                  <tr>
                    <td id="td-right">
                      <span>Total Other Bank Cost</span>
                    </td>
                    <td> {renderShipmentQty(oderData.total_other_bank_cost)}</td>
                    <td id="td-right">
                      <span>Total BL Qty</span>
                    </td>
                    <td>{renderShipmentQty(oderData.total_bl_qty)}</td>
                  </tr>
                  <tr>
                    <td id="td-right">
                      <span>Total Adding Confirmation charges</span>
                    </td>
                    <td>{renderShipmentQty(oderData.total_adding_confiramtion_charges)}</td>
                    <td id="td-right">
                      <span>BL Date</span>
                    </td>
                    <td>{oderData.bl_date}</td>
                  </tr>
                  <tr>
                    <td id="td-right">
                      <span>Total Interest</span>
                    </td>
                    <td> {renderShipmentQty(oderData.total_interest)}</td>
                    <td id="td-right">
                      <span>All Actual Payment Received Date</span>
                    </td>
                    <td>{oderData.all_actual_payment_recieved_date}</td>
                  </tr>
                  <tr>
                    <td id="td-right">
                    <span className="a-a">
                                        Total VAT on Bank Charges{" "}
                                                <Tooltip text={<span>Roll up Pre Shipment Document (VAT on Bank Charges)</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                                              </span>
                    </td>
                    <td>{renderShipmentQty(oderData.total_vat_on_bank_charges)}</td>
                    <td id="td-right">
                      <span>ALL Document Discount Date</span>
                    </td>
                    <td>{oderData.all_document_discount_date}</td>
                  </tr>
                  <tr>
                    <td id="td-right">
                      <span>Total Bill Handling Charges</span>
                    </td>
                    <td>{renderShipmentQty(oderData.total_bill_handling_charges)}</td>
                    <td id="td-right">
                      <span>ALL Expected Value Date</span>
                    </td>
                    <td>{oderData.all_expected_value_date}</td>
                  </tr>
                  <tr>
                    <td id="td-right">
                    <span className="a-a">
                                        Total Bank Charges{" "}
                                                <Tooltip text={<span>LC_Advising_Cost__c + <br/> Adding_Confirmation_Charges__c + <br/> Bill_Handling_Charges__c + <br/> Interest__c + <br/> Other_Bank_Cost__c +  <br/>VAT_ON_BANK_CHARGES__c</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                                              </span>
                    </td>
                    <td>{renderShipmentQty(oderData.total_bank_charges)}</td>
                    <td id="td-right">
                      <span></span>
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>

              <div className="col-md-12" id="ax.account_details">
                <h4 className="heading">
                  {" "}
                  Supplier Despatch /Demmurage
                  LTC
                </h4>
                <hr></hr>
              </div>
              <table class="table table-bordered account-table tables">
                <tbody>
                  <tr>
                    <td id="td-right">
                      <span>Supplier - Despatch/Demurrage</span>
                    </td>
                    <td>{oderData.supplier_despatch_or_demmurage}</td>
                    <td id="td-right">
                    <span className="a-a">
                                        BL Quantity (Laytime Calculations){" "}
                                                <Tooltip text={<span>BL Quantity for Laytime Calculations, <br/>if This is blank, System will take ALL BL Quantity , <br/>if ALL BL Quantity is blank system<br/> will take Master LC Quantity.</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                                              </span>
                    </td>
                    <td>  {renderShipmentQty(oderData.bl_quantity_laytime_calculations)}</td>
                  </tr>

                  <tr>
                    <td id="td-right">
                      <span>S Despatch/Demurrage Amount</span>
                    </td>
                    <td> {renderShipmentQty(oderData.supplier_despatch_demurrage_amount)}</td>
                    <td id="td-right">
                    <span className="a-a">
                                        Supplier LTC Reccount{" "}
                                                <Tooltip text={<span>Roll up Supplier LTC Record Count</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                                              </span>
                    </td>
                    <td>
                     {renderShipmentQty(oderData.supplier_ltc_rec_count)}
                    </td>
                  </tr>

                  <tr>
                    <td id="td-right">
                      <span>Supplier Laytime - Time Used</span>
                    </td>
                    <td>  {renderShipmentQty(oderData.supplier_laytime_time_used)}</td>
                    <td id="td-right">
                    <span className="a-a">
                                        Supplier Check Status{" "}
                                                <Tooltip text={<span>Checking for Vinita - Supplier Despatch Demmurage</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                                              </span>
                    </td>
                    <td> <input
          type='checkbox'
          name='supplier_check_status'
          checked={supplierCheck}
          onChange={handleCheckboxChange2}
          className='mx-3'
          disabled={isButtonEnabled} // Disable checkbox if button is enabled
        /></td>
                  </tr>

                  <tr>
                    <td id="td-right">
                      <span>Supplier Laytime Time Saved/Exceed</span>
                    </td>
                    <td> {renderShipmentQty(oderData.supplier_laytime_time_saved_exceed)}</td>
                    <td id="td-right">
                      <span></span>
                    </td>
                    <td></td>
                  </tr>

                  <tr>
                    <td id="td-right">
                      <span>Supplier - Despatch/Demurrage Status</span>
                    </td>
                    <td>{oderData.supplier_despatch_demurrage_status}</td>
                    <td id="td-right">
                      <span></span>
                    </td>
                    <td></td>
                  </tr>

                  <tr>
                    <td id="td-right">
                      <span>LTC Sent to Supplier Date</span>
                    </td>
                    <td>{oderData.ltc_sent_to_supplier_date}</td>
                    <td id="td-right">
                    <span className="a-a">
                                        Count of Desp Demm DN{" "}
                                                <Tooltip text={<span>Roll up (Debit Note) for  Debit Note Type = Dispatch/Demmurage</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                                              </span>
                    </td>
                    <td> {renderShipmentQty(oderData.count_of_desp_demm_dn)}</td>
                  </tr>

                  <tr>
                    <td id="td-right">
                      <span></span>
                    </td>
                    <td></td>
                    <td id="td-right">
                    <span className="a-a">
                                        Count of Other DN{" "}
                                                <Tooltip text={<span>Roll up (Debit Note) for Debit Note<br/> Type = Price Difference, GCV Variation, Umpire Analysis, Dead Freight,<br/> Interest - Late Payment</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                                              </span>
                    </td>
                    <td>  {renderShipmentQty(oderData.count_of_other_dn)}</td>
                  </tr>

                  <tr>
                    <td id="td-right">
                      <span>Supplier LTC To RW</span>
                    </td>
                    <td>  {renderShipmentQty(oderData.supplier_ltc_to_rw)}</td>
                    <td id="td-right">
                      <span></span>
                    </td>
                    <td></td>
                  </tr>

                  <tr>
                    <td id="td-right">
                      <span>Confirm Date</span>
                    </td>
                    <td>{oderData.supplier_confirm_date}</td>
                    <td id="td-right">
                    <span className="a-a">
                                       Supplier Final Dem/Despatch Payable{" "}
                                                <Tooltip text={<span>Roll up from Payable/Receivable</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                                              </span>
                    </td>
                    <td>{renderShipmentQty(oderData.supplier_final_dem_or_despatch_payable)}</td>
                  </tr>

                  <tr>
                    <td id="td-right">
                      <span>S Remark - Despatch / Demurrage</span>
                    </td>
                    <td>{oderData.supplier_remark_despacth_demmurage}</td>
                    <td id="td-right">
                    <span className="a-a">
                                       Supplier Final Other Payable{" "}
                                                <Tooltip text={<span>Roll up from Payable/Receivable</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                                              </span>
                    </td>
                    <td> {renderShipmentQty(oderData.supplier_final_other_payable)}</td>
                  </tr>

                  <tr>
                    <td id="td-right">
                      <span></span>
                    </td>
                    <td></td>
                    <td id="td-right">
                      <span>Supplier Despatch Demurrage Amount</span>
                    </td>
                    <td> {renderShipmentQty(oderData.supplier_despatch_or_demurrage_amount
)}</td>
                  </tr>
                </tbody>
              </table>
              <div className="col-md-12" id="ax.account_details">
                <h4 className="heading">Buyer - Despatch/Demmurage LTC</h4>
                <hr></hr>
              </div>
              <table class="table table-bordered account-table tables">
                <tbody>
                  <tr>
                    <td id="td-right">
                      <span>Buyer - Despatch/Demurrage</span>
                    </td>
                    <td>{oderData.buyer_despatch_or_demmurage}</td>
                    <td id="td-right">
                    <span className="a-a">
                                       Buyer LTC Rec Count{" "}
                                                <Tooltip text={<span>Roll up Buyer LTC - Load Port</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                                              </span>
                    </td>
                    <td> {renderShipmentQty(oderData.buyer_ltc_rec_count)}</td>
                  </tr>

                  <tr>
                    <td id="td-right">
                      <span>B Despatch/Demurrage Amount</span>
                    </td>
                    <td> {renderShipmentQty(oderData.buyer_despatch_demurrage_amount)}</td>
                    <td id="td-right">
                    <span className="a-a">
                                       Buyer Check Status{" "}
                                                <Tooltip text={<span>Vinita - Buyer Check status for Desptach Demmurage</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                                              </span>
                    </td>
                    <td>
                  <input
                    type='checkbox'
                    name='buyer_check_status'
                    checked={supplierChecks} // Use the state variable to determine if the checkbox is checked
                    onChange={handleCheckboxChange3} // Handle the change event
                    className='mx-3'
                    disabled={isButtonEnabled}
                  /> </td>
                  </tr>

                  <tr>
                    <td id="td-right">
                      <span>Buyer Laytime - Time Used</span>
                    </td>
                    <td> {renderShipmentQty(oderData.buyer_laytime_time_used)}</td>
                    <td id="td-right">
                      <span></span>
                    </td>
                    <td></td>
                  </tr>

                  <tr>
                    <td id="td-right">
                      <span>Buyer Laytime - Time Saved/Exceed</span>
                    </td>
                    <td>   {renderShipmentQty(oderData.buyer_laytime_time_saved_exceed)}</td>
                    <td id="td-right">
                      <span></span>
                    </td>
                    <td></td>
                  </tr>

                  <tr>
                    <td id="td-right">
                      <span>Buyer - Despatch/Demurrage Status</span>
                    </td>
                    <td>{oderData.buyer_despatch_demurrage_status}</td>
                    <td id="td-right">
                    <span className="a-a">
                                      Buyer Final Dem/Despatch Payable{" "}
                                                <Tooltip text={<span>Roll up from Payable/Receivable</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                                              </span>
                    </td>
                    <td>   {renderShipmentQty(oderData.buyer_final_dem_or_despatch_payable)}</td>
                  </tr>

                  <tr>
                    <td id="td-right">
                      <span>LTC Sent to Buyer Date</span>
                    </td>
                    <td>{oderData.ltc_sent_to_buyer_date}</td>
                    <td id="td-right">
                    <span className="a-a">
                                   Buyer Final Other Payable
                                                <Tooltip text={<span>Roll up from Payable/Receivable</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                                              </span>
                    </td>
                    <td> {renderShipmentQty(oderData.buyer_final_other_payable)}</td>
                  </tr>

                  <tr>
                    <td id="td-right">
                      <span></span>
                    </td>
                    <td></td>
                    <td id="td-right">
                    <span className="a-a">
                                   Buyer Despatch Demurrage Amt
                                                <Tooltip text={<span>If Buyer Final Dem/Despatch<br/> Payable =0 then B_Despatch/Demurrage Amount <br/>Other wise Buyer Final <br/>Dem/Despatch Payable/</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                                              </span>
                    </td>
                    <td>   {renderShipmentQty(oderData.buyer_despatch_or_demurrage_amount)}</td>
                  </tr>

                  <tr>
                    <td id="td-right">
                      <span>Buyer LTC TO RW</span>
                    </td>
                    <td> {renderShipmentQty(oderData.buyer_ltc_to_rw)}</td>
                    <td id="td-right">
                      <span></span>
                    </td>
                    <td></td>
                  </tr>

                  <tr>
                    <td id="td-right">
                      <span>Buyer Confirm Date</span>
                    </td>
                    {oderData.buyer_confirm_date}
                    <td></td>
                    <td id="td-right">
                      <span></span>
                    </td>
                    <td></td>
                  </tr>

                  <tr>
                    <td id="td-right">
                      <span>B Remark - Despatch/Demurrage</span>
                    </td>
                    <td>{oderData.buyer_remark_despacth_demmurage}</td>
                    <td id="td-right">
                      <span></span>
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>

              <div className="col-md-12" id="ax.account_details">
                <h4 className="heading">Summary Payable Receivable</h4>
                <hr></hr>
              </div>
              <table class="table table-bordered account-table tables">
                <tbody>
                  <tr>
                    <td id="td-right">
                    <span className="a-a">
                                   Sales Invoice
                                                <Tooltip text={<span>Roll up from Payable/Receivable</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                                              </span>
                    </td>
                    <td>{renderShipmentQty(oderData.sales_invoice)}</td>
                    <td id="td-right">
                    <span className="a-a">
                                   Surveyor Invoice
                                                <Tooltip text={<span>Roll up from Payable/Receivable</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                                              </span>
                    </td>
                    <td>{renderShipmentQty(oderData.surveyor_invoice)}</td>
                  </tr>
                  <tr>
                    <td id="td-right">
                    <span className="a-a">
                                   Purchase Invoice 
                                                <Tooltip text={<span>Roll up from Payable/Receivable</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                                              </span>
                    </td>
                    <td>{renderShipmentQty(oderData.purchase_invoice)}</td>
                    <td id="td-right">
                    <span className="a-a">
                                   Load Port Invoice
                                                <Tooltip text={<span>Roll up from Payable/Receivable</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                                              </span>
                    </td>
                    <td>{renderShipmentQty(oderData.load_port_invoice)}</td>
                  </tr>

                  <tr>
                    <td id="td-right">
                      {/* <span>Created By</span> */}
                    </td>
                    <td>
                      {/* {oderData.created_by} */}
                      </td>
                    <td id="td-right">
                      <span>net des dem inflow or outflow</span>
                    </td>
                    <td>{renderShipmentQty(oderData.net_des_dem_inflow_or_outflow)}</td>
                  </tr>
                  <tr>
                    <td id="td-right">
                      <span>Created By</span>
                    </td>
                    <td>{oderData.created_by}</td>
                    <td id="td-right">
                      <span>Last Modified By </span>
                    </td>
                    <td>{oderData.last_modified_by}</td>
                  </tr>
                </tbody>
              </table>
              <Row id="table-styles">
                <div className="col-md-12" id="head">
                  <h4 className="heading">
                    <span>Pre Shipment Documents </span>
                    <span className="links">
                      {" "}
                      <Link to={"/order/add-order-preshipment/" + id}>New</Link>
                    </span>
                  </h4>
                  <hr></hr>
                </div>
                <table id="pre" className="">
                  <thead>
                    <tr>
                      <th>Pre Shipment No</th>
                      <th>Master LC No</th>
                      <th>Master LC Quantity</th>
                      <th>Master LC Place of Expiry</th>
                      <th>Master LC Issue Date</th>
                      <th>Child LC No</th>
                      <th>Child LC Application Date</th>
                      <th>Child LC Shipment date</th>
                      <th>Child LC Port of Loading</th>
                    </tr>
                  </thead>

                  {preshipments.length > 0 ? (
                    <tbody>
                      {preshipments.map((y, i) => (
                        <tr>
                          <td>
                            <span id="codes">
                              <Link
                                to={"/shipment/preshipment-details/" + y.id}
                              >
                                {y.pre_code_no}
                              </Link>
                            </span>
                          </td>
                          <td>{y.master_lc_no}</td>
                          <td>{y.master_lc_quantity}</td>
                          <td>{y.master_lc_place_of_expiry}</td>
                          <td>{y.master_lc_issued_date}</td>
                          <td>{y.child_lc_no}</td>
                          <td>{y.child_lc_application_date}</td>
                          <td>{y.child_lc_shipment_date}</td>
                          <td>{y.child_lc_port_of_loading}</td>
                        </tr>
                      ))}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td></td><td></td>
                        <td></td>
                        <td></td>
                        <td>No data available</td>
                        <td></td>
                        <td></td><td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  )}
                </table>
              </Row>
              <Row id="table-styles">
                <div className="col-md-12" id="head">
                  <h4 className="heading">
                    <span>Post Shipment Documents</span>{" "}
                    <span className="links">
                      {" "}
                      <Link to={"/order/order-by-postshipment/" + id}>New</Link>
                    </span>
                  </h4>
                  <hr></hr>
                </div>
                <table id="post" className="post-table">
                  <thead>
                    <tr>
                      <th>Post Shipment Doc No</th>
                      <th>BL No</th>

                      <th>BL Quantity</th>
                      <th>Bill Date</th>
                      <th>Commercial Invoice Price	</th>

                      <th>Commercial Invoice Amount	</th>
                      <th>Supplier Invoice Price</th>
                      <th>Supplier Invoice Amount</th>
                      <th>Document Discount Date	</th>
                      <th>Submited by Shiper to Shiper Bank Date
</th>
                    </tr>
                  </thead>

                  {postshipments.length > 0 ? (
                    <tbody>
                      {postshipments.map((y, i) => (
                        <tr>
                          <td>
                            <span id="codes">
                              <Link
                                to={
                                  "/postshipment/postshipment-details/" + y.id
                                }
                              >
                                {y.post_code_no}
                              </Link>
                            </span>
                          </td>

                          <td>{y.bl_no}</td>

                          <td>{y.bl_quantity}</td>
                          <td>{y.bl_date}</td>
                          <td>{y.commercial_invoice_price}</td>

                          <td>{y.commercial_invoice_amount}</td>
                          <td>{y.supplier_invoice_price}</td>
                          <td>{y.supplier_invoice_amount}</td>
                          <td>{y.document_discount_date}</td>
                          <td>{y.submitted_by_shipper_to_shipper_bank_date}</td>
                        </tr>
                      ))}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                      <td></td>
                      <td></td><td></td>
                        <td></td>
                        <td></td>
                        <td>No data available</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  )}
                </table>
              </Row>
              <Row id="table-styles">
                <div className="col-md-12" id="head">
                  <h4 className="heading">
                    <span>Order Products (Standard Price Book)</span>
                    <span>
                      <i className=""></i>
                      Add Product
                    </span>
                  </h4>
                  <hr></hr>
                </div>
                <table id="order-product" className="post-table">
                  <thead>
                    <tr>
                      <th>Product</th>
                      <th>Type</th>
                      <th>Surveyor Agent	</th>
                      <th>Gross as received	</th>
                      <th>Net as received	</th>
                      <th>Sulphur ADB	</th>
                      <th>Ash</th>
                      <th>Total Moisture	</th>
                      <th>Ash ARB	</th>
                      <th>Sulphur ARB
                      </th>

                    </tr>
                  </thead>

                  {orderProduct.length > 0 ? (
                    <tbody>
                      {orderProduct.map((y, i) => (
                        <tr>
                          <td>
                            <span id="codes">
                              <Link
                                to={
                                  "/postshipment/postshipment-details/" + y.id
                                }
                              >
                                {y.product}
                              </Link>
                            </span>
                          </td>

                          <td></td>
                          <td>{y.surveyor_agent}</td>
                          <td>{y.gross_as_received}</td>
                          <td>{y.net_as_received}</td>
                          <td>{y.sulphur_adb}</td>
                          <td>{y.ash}</td>
                          <td>{y.total_moisture}</td>
                          <td>{y.ash_arb}</td>
                          <td>{y.sulphur_arb}</td>
                        </tr>
                      ))}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                      <td></td>
                      <td></td><td></td>
                        <td></td>
                        <td></td>
                        <td>No data available</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  )}
                </table>
              </Row>
              <Row id="table-styles">
                <div className="col-md-12" id="head">
                  <h4 className="heading">
                    <span>Buyer Products</span>
                  </h4>
                  <hr></hr>
                </div>
                <table id="buyer" className="">
                  <thead>
                    <tr>
                      <th>Buyer Product No</th>
                      <th>Buyer - Surveyor Agent</th>
                      <th>Surveyor Name</th>
                      <th>Type</th>
                      <th>Gross as received</th>
                      <th>Net as received	</th>
                      <th>Sulphur ADB	</th>
                      <th>Ash</th>
                      <th>Total Moisture	</th>
                      <th>Ash ARB	</th>
                      <th>Sulphur ARB
                      </th>
                    </tr>
                  </thead>

                  {buyer.length > 0 ? (
                    <tbody>
                      {buyer.map((y, i) => (
                        <tr>
                          <td>
                            <span id="codes">
                              <Link to={"/buyer/buyer-view/" + y.id}>
                                {y.buyer_product_no}
                              </Link>
                            </span>
                          </td>
                          <td>{y.buyer_surveyor_agent}</td>
                          <td>{y.surveyor_name}</td>
                          <td>{y.buyer_product_type}</td>
                          <td>{y.gross_as_recieved}</td>
                          <td>{y.net_as_recieved}</td>
                          <td>{y.sulphur_ARB}</td>
                          <td>{y.ash}</td>
                          <td>{y.total_moisture}</td>
                          <td>{y.ash_ARB}</td>
                          <td>{y.sulphur_ADB}</td>
                        </tr>
                      ))}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>No data available</td>

                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  )}
                </table>
              </Row>
              <Row id="table-styles">
                <div className="col-md-12" id="head">
                  <h4 className="heading">
                    <span>Loading Status</span>
                    <span>
                      <i className=""></i>
                      New Loading Status
                    </span>
                  </h4>
                  <hr></hr>
                </div>
                <table id="history" className="">
                  <tr>
                    <td>
                      <p class="no-records">No records to display</p>
                    </td>
                  </tr>
                </table>
              </Row>
              <Row id="table-styles">
                <div className="col-md-12" id="ax.account_details">
                  <h4 className="heading">
                    <span>Trade Confirmation Form</span>{" "}
                    <span>
                      <Popup
                        trigger={
                          <a className="button add-accountrecord"> New </a>
                        }
                        modal
                      >
                        <form
                          onSubmit={handleSubmittcf}
                          className="add-accounts"
                        >
                          <div className="form-group">
                            <label>TCF Record Type</label>
                            <br />
                            <select
                              name="account_record"
                              className="form-control"
                              onChange={handleChangetcf}
                              value={formData.account_record}
                            >
                              <option>--Select TCF Record Type--</option>
                              <option value="Buyer - Spot">Buyer - Spot</option>
                              <option value="Buyer - Long Term">
                                Buyer - Long Term
                              </option>
                            </select>
                          </div>
                          <input
                            type="submit"
                            className="btn btn-primary "
                            value="Submit"
                          />
                        </form>
                      </Popup>
                    </span>
                  </h4>
                  <hr></hr>
                </div>
                <table id="tcf" className="table table-bordered">
                  <thead>
                    <tr>
                      <th>TCF No</th>
                      <th>Actual Pur Price	</th>
                      <th>Actual Pur Amount	</th>
                      <th>Final Purchase Price	</th>
                      <th>Final Purchase Amount	</th>
                      <th>Actual Sales Price	</th>
                      <th>Actual Sales	</th>
                      <th>Final Sales Price	</th>
                      <th>Final Sales Amount	</th>
                      <th>Actual Gross Margin - Shipment
                      </th>
                    </tr>
                  </thead>
                  {tcf.length >= 0 ? (
                    <tbody>
                      {tcf.map((y, i) => (
                        <tr>
                          <td>
                            <Link to={"/tcf/tcf-details/" + y.id}>
                              {y.tcf_no}
                            </Link>
                          </td>
                          <td>{y.actual_pur_price}</td>
                          <td>{y.actual_pur_amount}</td>
                          <td>{y.final_purchase_price}</td>
                          <td>{y.final_purchase_amount}</td>
                          <td>{y.actual_sales_price}</td>
                          <td>{y.actual_sales}</td>
                          <td>{y.final_sales_price}</td>
                          <td>{y.final_sales_amount}</td>
                          <td>{y.actual_gross_margin_shipment}</td>
                        </tr>
                      ))}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                      <td></td><td></td>
                        <td></td>
                        <td></td>
                        <td>No data available</td>
                        <td></td>
                        <td></td>
                        <td></td><td></td><td></td>
                      </tr>
                    </tbody>
                  )}
                </table>
              </Row>
              <Row id="table-styles">
                <div className="col-md-12" id="head">
                  <h4 className="heading">
                    <span>Rw Payable/ Receivable LineItem </span>
                    <span>
                      <Popup
                        className="post-buyer"
                        trigger={
                          <a className="button add-accountrecord"> New</a>
                        }
                        modal
                      >
                        <div
                          className="form-group"
                          onChange={handleChangeRadio}
                        >
                          <Form.Check
                            type="radio"
                            id=""
                            name="type"
                            label="Buyer - Credit Note"
                            value="Buyer - Credit Note"
                          />
                          <Form.Check
                            type="radio"
                            id=""
                            name="type"
                            label="Supplier - Credit Note"
                            value="Supplier - Credit Note"
                          />
                          <Form.Check
                            type="radio"
                            id=""
                            name="type"
                            label="Buyer - Debit Note"
                            value="Buyer - Debit Note"
                          />
                          <Form.Check
                            type="radio"
                            id=""
                            name="type"
                            label="Supplier - Debit Note"
                            value="Supplier - Debit Note"
                          />
                          <Form.Check
                            type="radio"
                            id=""
                            name="type"
                            label="Load Port Invoice"
                            value="Load Port Invoice"
                          />
                          <Form.Check
                            type="radio"
                            id=""
                            name="type"
                            label="Other Payable"
                            value="Other Payable"
                          />
                          <Form.Check
                            type="radio"
                            id=""
                            name="type"
                            label="Other Receivable"
                            value="Other Receivable"
                          />
                          <Form.Check
                            type="radio"
                            id=""
                            name="type"
                            label="Purchase Invoice"
                            value="Purchase Invoice"
                          />
                          <Form.Check
                            type="radio"
                            id=""
                            name="type"
                            label="Sales Invoice"
                            value="Sales Invoice"
                          />
                          <Form.Check
                            type="radio"
                            id=""
                            name="type"
                            label="Surveyor Invoice"
                            value="Surveyor Invoice"
                          />
                        </div>
                        <input
                          type="submit"
                          className="btn btn-primary "
                          value="Submit"
                          onClick={handleClick}
                        />
                      </Popup>
                    </span>
                  </h4>
                  <hr></hr>
                </div>
                <table id="rw" className="">
                  <thead>
                    <tr>
                      <th>RW Billing	</th>
                      <th>Record Type</th>
                      <th>Line Item	</th>
                      <th>Document Date	</th>
                      <th>Unit Rate	</th>
                      <th>Amount</th>
                      <th>Paid / Received Amount	</th>
                      <th>Balance Due	</th>
                      <th>Status</th>
                      <th>Description</th>
                    </tr>
                  </thead>

                  {rw.length > 0 ? (
                    <tbody>
                      {rw.map((y, i) => (
                        <tr>

<td><Link

   onClick={(e) => {
     e.preventDefault(); // Prevent default action
     // Perform custom action here
     navigate("/order/order-receivable-details/" + y.rw_pay_recieve_id, {
       state: { postId:id },
     });

   }}
 >
   {y.rw_billing}
 </Link></td>


                          <td>{y.record_type}</td>
                          <td>{y.line_item}</td>
                          <td>{y.document_date
                          }</td>
                          <td>{y.unit_rate}</td>
                          <td>{renderShipmentQty(y.amount)}</td>
                          <td> {renderShipmentQty(y.paid_recieved_amount)}</td>
                          <td>{y.balance_due}</td>
                          <td>{y.status}</td>
                          <td>{y.description}</td>
                        </tr>
                      ))}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>No data available</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  )}
                </table>
              </Row>
              <Row id="table-styles">
                <div className="col-md-12" id="head">
                  <h4 className="heading">
                    <span>Rw Payable/ Receipt LineItem </span>
                    <span>
                      <Popup
                        className="post-buyer"
                        trigger={
                          <a className="button add-accountrecord"> New</a>
                        }
                        modal
                      >
                        <div
                          className="form-group"
                          onChange={handleChangeRadio}
                        >
                          <Form.Check
                            type="radio"
                            id=""
                            name="type"
                            label="Buyer - Credit Note"
                            value="Buyer - Credit Note"
                          />
                          <Form.Check
                            type="radio"
                            id=""
                            name="type"
                            label="Supplier - Credit Note"
                            value="Supplier - Credit Note"
                          />
                          <Form.Check
                            type="radio"
                            id=""
                            name="type"
                            label="Buyer - Debit Note"
                            value="Buyer - Debit Note"
                          />
                          <Form.Check
                            type="radio"
                            id=""
                            name="type"
                            label="Supplier - Debit Note"
                            value="Supplier - Debit Note"
                          />
                          <Form.Check
                            type="radio"
                            id=""
                            name="type"
                            label="Load Port Invoice"
                            value="Load Port Invoice"
                          />
                          <Form.Check
                            type="radio"
                            id=""
                            name="type"
                            label="Other Payable"
                            value="Other Payable"
                          />
                          <Form.Check
                            type="radio"
                            id=""
                            name="type"
                            label="Other Receivable"
                            value="Other Receivable"
                          />
                          <Form.Check
                            type="radio"
                            id=""
                            name="type"
                            label="Purchase Invoice"
                            value="Purchase Invoice"
                          />
                          <Form.Check
                            type="radio"
                            id=""
                            name="type"
                            label="Sales Invoice"
                            value="Sales Invoice"
                          />
                          <Form.Check
                            type="radio"
                            id=""
                            name="type"
                            label="Surveyor Invoice"
                            value="Surveyor Invoice"
                          />
                        </div>
                        <input
                          type="submit"
                          className="btn btn-primary "
                          value="Submit"
                          onClick={handleClicks}
                        />
                      </Popup>
                    </span>
                  </h4>
                  <hr></hr>
                </div>
                <table id="receipt" className="">
                  <thead>
                    <tr>
                      <th>RW Payment/Receipt</th>
                      <th>RW Billing	</th>
                      <th>Record Type	</th>
                      <th>Line Item	</th>
                      <th>Account</th>
                      <th>Amount</th>
                      <th>Description</th>
                      <th>Created Date
                      </th>
                    </tr>
                  </thead>

                  {receipt.length > 0 ? (
                    <tbody>
                      {receipt.map((y, i) => (
                        <tr>

<td><Link

   onClick={(e) => {
     e.preventDefault(); // Prevent default action
     // Perform custom action here
     navigate("/order/order-receipt-details/" + y.rw_pay_receipt_detail_id, {
       state: { postId:id },
     });

   }}
 >
   {y.rw_payment_receipt}
 </Link></td>

                          <td> <Link to={"/order/order-receivable-details/" + y.rw_pay_recieve_id}>
                              {y.rw_billing}
                            </Link></td>
                          <td>{y.record_type}</td>
                          <td>{y.line_item}</td>
                          <td><Link to={"/accounts/" + y.account_id}>{y.account}</Link></td>
                          <td> {renderShipmentQty(y.amount)}</td>
                          <td>{y.description}</td>
                          <td>{y.created_at}</td>
                        </tr>
                      ))}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>No data available</td>
                        <td></td>
                        <td></td><td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  )}
                </table>
              </Row>
              <Row id="table-styles">
                <div className="col-md-12" id="head">
                  <h4 className="heading">
                    <span>Debit Notes (Order)</span>
                  </h4>
                  <hr></hr>
                </div>
                <table id="history" className="">
                  <tr>
                    <td>
                      <p class="no-records">No records to display</p>
                    </td>
                  </tr>
                </table>
              </Row>

              <Row id="table-styles">
                <div className="col-md-12" id="head">
                  <h4 className="heading">
                    <span>Notes & Attachments</span>
                    <span>
                      <i className=""></i>
                      New Note
                    </span>
                    <span>
                      <i className=""></i>
                      Attach File
                    </span>
                  </h4>
                  <hr></hr>
                </div>
                <table id="history" className="">
                  <tr>
                    <td>
                      <p class="no-records">No records to display</p>
                    </td>
                  </tr>
                </table>
              </Row>
              <Row id="table-styles">
                <div className="col-md-12" id="head">
                  <h4 className="heading">
                    <span>Notes</span>
                    <span>
                      <i className=""></i>
                      New Note
                    </span>
                  </h4>
                  <hr></hr>
                </div>
                <table id="history" className="">
                  <tr>
                    <td>
                      <p class="no-records">No records to display</p>
                    </td>
                  </tr>
                </table>
              </Row>
              <Row id="table-styles">
                <div className="col-md-12" id="head">
                  <h4 className="heading">
                    <span>Files</span>
                    <span>
                      <i className=""></i>
                      Upload Files
                    </span>
                  </h4>
                  <hr></hr>
                </div>
                <table id="history" className="">
                  <tr>
                    <td>
                      <p class="no-records">No records to display</p>
                    </td>
                  </tr>
                </table>
              </Row>
              <Row id="table-styles">
                <div className="col-md-12" id="head">
                  <h4 className="heading">
                    <span>Open Activities</span>
                    <span>
                      <i className=""></i>
                      New Task
                    </span>
                    <span>
                      <i className=""></i>
                      New Event
                    </span>
                  </h4>
                  <hr></hr>
                </div>
                <table id="history" className="">
                  <tr>
                    <td>
                      <p class="no-records">No records to display</p>
                    </td>
                  </tr>
                </table>
              </Row>
              <Row id="table-styles">
                <div className="col-md-12" id="head">
                  <h4 className="heading">
                    <span>Activity History</span>
                    <span>
                      <i className=""></i>
                      Log a Call
                    </span>
                    <span>
                      <i className=""></i>
                      Mail merge
                    </span>
                  </h4>
                  <hr></hr>
                </div>
                <table id="history" className="">
                  <tr>
                    <td>
                      <p class="no-records">No records to display</p>
                    </td>
                  </tr>
                </table>
              </Row>
              <Row id="table-styles">
                <div className="col-md-12" id="head">
                  <h4 className="heading">
                    <span>History Information</span>
                  </h4>
                  <hr></hr>
                </div>
                <table id="history-info" className="">
                  <thead>
                    <tr>
                      <th>Field Name</th>
                      <th>New Value</th>
                      <th>Old Value</th>
                      <th>Changed By</th>
                      <th>Change Time</th>
                    </tr>
                  </thead>

                  {history.length > 0 ? (
                    <tbody>
                      {history.map((y, i) => (
                        <tr>
                          <td>{y.field_name}</td>
                          <td>{y.new_value}</td>
                          <td>{y.old_value}</td>
                          <td>{y.changed_by}</td>
                          <td>{y.change_time}</td>
                        </tr>
                      ))}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td></td>
                        <td></td>
                        <td>No data available</td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  )}
                </table>
              </Row>


            </div>
          )}
        </>
      ) : (
        <div className="tasks fulid-section order-section">
          <h1 className="no-data-detail"><img src='../../images/loading.gif' className="loading"/></h1>
        </div>
      )}
</div></div>
      <Footer />
    </div>
  );
};

export default ViewOrder;
