import React, { useEffect, useState } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { AES, enc } from 'crypto-js';
import Header from '../Header'
import Footer from '../Footer'
import $ from 'jquery';
import { useNavigate , Link} from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { API_URL } from '../config';
import RecentItem from "../recentitem/rececentItem";
  import 'react-toastify/dist/ReactToastify.css';
  import axios from 'axios';
function AddPricebook() {
  const navigate = useNavigate();
  const body=$(window).height()
  const header=$("#header").height()
  const nav=$(".navbar").height()
  const footer=$(".footer").height()
  const total_height=header+nav+footer;
  const window_height=body-total_height;
  console.log(total_height)
  console.log(body)
  $(".contactAccount").css({"min-height":(window_height)+"px"})

    const { register, handleSubmit,control, reset, formState: { errors } } = useForm({ defaultValues: { locked: true } });
    const [lockeds, setLockeds] = useState(false); // Initialize the state of the checkbox
    const [error, setError] = useState('');
  const handleCheckboxChange = (e) => {
    setLockeds(e.target.checked);
  };
  const [actives, setActives] = useState(false); // Initialize the state of the checkbox

  const handleCheckboxActive = (e) => {
    setActives(e.target.checked);
  };


  const onSubmit = async (data) => {
    try {
      const boolValue = data.locked;
      const responseData = {
        price_book_name: data.price_book_name,
        month: data.month,
        year: data.year,
        locked: lockeds,
        active: actives,
        description: data.description,
        created_by: data.created_by,
        last_modified_by: data.last_modified_by,
        projected_HBA: data.projected_HBA,
        projected_GCNEWC: data.projected_GCNEWC,
        actual_GCNEWC_for_the_month: data.actual_GCNEWC_for_the_month,
        applicable_GCNEWC_for_the_month: data.applicable_GCNEWC_for_the_month,
        avg_GCNEWC_prev_QTR: data.avg_GCNEWC_prev_QTR,
        avg_GCNEWC_current_QTR: data.avg_GCNEWC_current_QTR,
        actual_HBA: data.actual_HBA,
        actual_HBA_1: data.actual_HBA_1,
        actual_HBA_2: data.actual_HBA_2,
        actual_HBA_3: data.actual_HBA_3,
        ici1_6500_GAR: data.ici1_6500_GAR,
        ici_2_5800_GAR: data.ici_2_5800_GAR,
        ici_3_5000_GAR: data.ici_3_5000_GAR,
        ici_4_4200_GAR: data.ici_4_4200_GAR,
        ici_5_3400_GAR: data.ici_5_3400_GAR,
        platts_K5900: data.platts_K5900,
        Nex: data.Nex,
        des_ARA: data.des_ARA,
      };

      console.log(JSON.stringify(responseData));

      const encryptedAccessToken = localStorage.getItem('access_token');
      const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';

      if (!encryptedAccessToken) {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
        return;
      }

      const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);

      const response = await axios.post(API_URL + 'add/pricebook', responseData, {
        headers: {
          Authorization: `Bearer ${decryptedAccessToken}`,
          'Content-Type': 'application/json',
        },
      });

      console.log(response);

      if (response.data.success === true) {
        const pricebookID = response.data.price_book_id;
        toast.success('Pricebook added successfully', {
          position: 'bottom-center',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });

        // Run both API calls concurrently
        await Promise.all([
          axios.post(`${API_URL}add/hba/${pricebookID}`, {
            headers: {
              Authorization: `Bearer ${decryptedAccessToken}`,
              'Content-Type': 'application/json',
            },
          }),
        ]);

        // After both requests are successful, redirect and reset the form
        navigate(`/pricebook/pricebook-details/${pricebookID}`);

        setTimeout(() => {
          reset();
        }, 300);
      } else {
        // Handle unsuccessful response
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
        setTimeout(() => {
          setError('Price book name already exists');
        }, 300);
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('An unexpected error occurred. Please try again later.', {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  };

  // const onSubmit = async (data) => {
  //   try {
  //     const boolValue = data.locked;
  //     const responseData = {
  //       price_book_name: data.price_book_name,
  //       month: data.month,
  //       year: data.year,
  //       locked: lockeds, // Corrected variable name
  //       active: actives, // Assuming 'actives' was a typo
  //       description: data.description,
  //       created_by: data.created_by,
  //       last_modified_by: data.last_modified_by,
  //       projected_HBA: data.projected_HBA,
  //       projected_GCNEWC: data.projected_GCNEWC,
  //       actual_GCNEWC_for_the_month: data.actual_GCNEWC_for_the_month,
  //       applicable_GCNEWC_for_the_month: data.applicable_GCNEWC_for_the_month,
  //       avg_GCNEWC_prev_QTR: data.avg_GCNEWC_prev_QTR,
  //       avg_GCNEWC_current_QTR: data.avg_GCNEWC_current_QTR,
  //       actual_HBA: data.actual_HBA,
  //       actual_HBA_1: data.actual_HBA_1,
  //       actual_HBA_2: data.actual_HBA_2,
  //       actual_HBA_3: data.actual_HBA_3,
  //       ici1_6500_GAR: data.ici1_6500_GAR,
  //       ici_2_5800_GAR: data.ici_2_5800_GAR,
  //       ici_3_5000_GAR: data.ici_3_5000_GAR,
  //       ici_4_4200_GAR: data.ici_4_4200_GAR,
  //       ici_5_3400_GAR: data.ici_5_3400_GAR,
  //       platts_K5900: data.platts_K5900,
  //       Nex: data.Nex,
  //       des_ARA: data.des_ARA,
  //     };

  //     console.log(JSON.stringify(responseData));

  //     const encryptedAccessToken = localStorage.getItem('access_token');
  //     const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';

  //     if (!encryptedAccessToken) {
  //       window.scrollTo({
  //         top: 0,
  //         behavior: 'smooth'
  //       });
  //       return; // Exit early if access token is missing
  //     }

  //     const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);

  //     const response = await axios.post(API_URL+'add/pricebook', responseData, {
  //       headers: {
  //         Authorization: `Bearer ${decryptedAccessToken}`,
  //         'Content-Type': 'application/json',
  //       },
  //     });

  //     console.log(response);

  //     if (response.data.success===true) { // Corrected success check
  //       const pricebookID = response.data.price_book_id;
  //       toast.success('Pricebook added successfully', {
  //         position: "bottom-center",
  //         autoClose: 3000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //         theme: "light",
  //       });

  //       navigate(`/pricebook/pricebook-details/${pricebookID}`);

  //       setTimeout(() => {
  //         reset();
  //       }, 300);

  //       await axios.post(`${API_URL}add/hba/${pricebookID}`, responseData, {
  //         headers: {
  //           Authorization: `Bearer ${decryptedAccessToken}`,
  //           'Content-Type': 'application/json',
  //         },
  //       });
  //     } else {
  //       // Handle unsuccessful response
  //       window.scrollTo({
  //         top: 0,
  //         behavior: 'smooth'
  //     }); // Assuming error message is provided by the server
  //     setTimeout(() => {
  //       setError('Price book name already exist')
  //         }, 300);

  //     }
  //   } catch (error) {
  //     console.error("Error:", error);
  //     // Handle unexpected errors
  //     toast.error('An unexpected error occurred. Please try again later.', {
  //       position: "bottom-center",
  //       autoClose: 5000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: "light",
  //     });
  //   }
  // };


  return (

    <div className="addAccount fulid-section order-section">
    <Header/>
    
    <div className="row">
       <div className="col-md-2 recent-item">
       <RecentItem/>
</div>
       <div className="col-md-10 right-section">
    <div className="page-header" id='add-account'>
    <div>
      <h2 className="main-content-title tx-24 mg-b-5">Add pricebook</h2>
      <ol className="breadcrumb">
        <li className="breadcrumb-item active" aria-current="page">Dashboard / Add Pricebook </li>
      </ol>
    </div>
    <div className="d-flex">
      <div className="justify-content-center">

        {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
         <a href='/account/add-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</a>
        </button> */}

      </div>
    </div>
  </div>    <Form onSubmit={handleSubmit(onSubmit)}>
  <p className='edit-cancel'><input type="submit" className="account-save" value="Save" /><Link to={`/pricebook/create-view-list`}><input type="submit" className="account-save" value="Cancel" /></Link></p>
      <Row className="mb-3">
        <p className="errors">{error}</p>
        <h4 className='heading'>Pricebook Detail Information</h4>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Price Book Name"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Price Book Name"
              name="price_book_name"
              tabIndex={1}
              {...register("price_book_name", { required: false })}
            />
            {errors.price_book_name && <span>Required </span>}
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
          <label>Locked</label>
          <input
          type='checkbox'
          name='locked'
          tabIndex={4}
          checked={lockeds} // Use the state variable to determine if the checkbox is checked
          onChange={handleCheckboxChange} // Handle the change event
          className='mx-3'
        />
        </Form.Group>
        <Form.Group as={Col} md="6">
          <label>Active</label>
          <input
          type='checkbox'
          name='active'
          tabIndex={2}
          checked={actives} // Use the state variable to determine if the checkbox is checked
          onChange={handleCheckboxActive} // Handle the change event
          className='mx-3'
        />
        </Form.Group>
        <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Month"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="month"
                    placeholder="Month"
                    tabIndex={5}
                    defaultValue=""
                    {...register("month", { required: false })}
                  >
                   <option value="January">January</option><option value="February">February</option><option value="March">March</option><option value="April">April</option><option value="May">May</option><option value="June">June</option><option value="July">July</option><option value="August">August</option><option value="September">September</option><option value="October">October</option><option value="November">November</option><option value="December">December</option>
                  </Form.Select>
                  {errors.month && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"  className="textarea-half">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Description"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Description"
                    style={{ height: "100px" }}
                    name="description"
                    tabIndex={3}
                    {...register("description", { required: false })}
                  />
                  {errors.description && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="year"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="year"
                    placeholder="Year"
                    tabIndex={6}
                    defaultValue=""
                    {...register("year", { required: true })}
                  >
                    <option value="">--None--</option><option value="2021">2021</option><option value="2022">2022</option><option value="2023">2023</option><option value="2024">2024</option><option value="2025">2025</option><option value="2026">2026</option><option value="2015">2015</option><option value="2018">2018</option><option value="2019">2019</option><option value="2020">2020</option>
                  </Form.Select>
                  {errors.year && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <h4 className='heading'> Projected Index</h4>
              <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Projected HBA"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Projected HBA"
              name="projected_HBA"
              tabIndex={7}
              {...register("projected_HBA", {valueAsNumber:true, required: false })}
            />
            {errors.projected_HBA && <span>Required </span>}
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Projected GCNEWC"
            className="mb-3"


          >
            <Form.Control
              type="number"
              placeholder="Projected GCNEWC"
              name="projected_GCNEWC"
              tabIndex={8}
              {...register("projected_GCNEWC", {valueAsNumber:true, required: false })}
            />
            {errors.projected_GCNEWC  && <span>Required </span>}
          </FloatingLabel>
        </Form.Group>
        <h4 className='heading'>GCNEWC</h4>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Actual GCNEWC for the month"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Actual GCNEWC for the month"
              tabIndex={9}
              name="actual_GCNEWC_for_the_month"
              {...register("actual_GCNEWC_for_the_month", {valueAsNumber:true, required: false })}
            />
            {errors.actual_GCNEWC_for_the_month  && <span>Required </span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Avg GCNEWC prev QTR"
            className="mb-3"


          >
            <Form.Control
              type="text"
              tabIndex={11}
              placeholder="Avg GCNEWC prev QTR"
              name="avg_GCNEWC_prev_QTR"
              {...register("avg_GCNEWC_prev_QTR", {valueAsNumber:true, required: false })}
            />
            {errors.avg_GCNEWC_prev_QTR  && <span>Required </span>}
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Applicable GCNEWC for the month"
            className="mb-3"


          >
            <Form.Control
              type="text"
              tabIndex={10}
              placeholder="Applicable GCNEWC for the month"
              name="applicable_GCNEWC_for_the_month"
              {...register("applicable_GCNEWC_for_the_month", {valueAsNumber:true, required: false })}
            />
            {errors.applicable_GCNEWC_for_the_month  && <span>Required </span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Avg GCNEWC current QTR"
            className="mb-3"


          >
            <Form.Control
              type="text"
              tabIndex={12}
              placeholder="Avg GCNEWC current QTR"
              name="avg_GCNEWC_current_QTR"
              {...register("avg_GCNEWC_current_QTR", {valueAsNumber:true, required: false })}
            />
            {errors.avg_GCNEWC_current_QTR  && <span>Required </span>}
          </FloatingLabel>
        </Form.Group>


        <h4 className="heading">HBA</h4>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Actual HBA"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Actual HBA"
              tabIndex={13}
              name="actual_HBA"
              {...register("actual_HBA", {valueAsNumber:true, required: false })}
            />
            {errors.actual_HBA   && <span>Required </span>}
          </FloatingLabel>
        </Form.Group>


        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Actual HBA 1"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Actual HBA 1"
              name="actual_HBA_1"
              tabIndex={15}
              {...register("actual_HBA_1", {valueAsNumber:true, required: false })}
            />
            {errors.actual_HBA_1   && <span>Required </span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Actual HBA 2"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Actual HBA 2"
              name="actual_HBA_2"
              tabIndex={14}
              {...register("actual_HBA_2", {valueAsNumber:true, required: false })}
            />
            {errors.actual_HBA_2   && <span>Required </span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Actual HBA 3"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Actual HBA 3"
              tabIndex={16}
              name="actual_HBA_3"
              {...register("actual_HBA_3", {valueAsNumber:true, required: false })}
            />
            {errors.actual_HBA_3   && <span>Required </span>}
          </FloatingLabel>
        </Form.Group>

        <h4 className="heading">ICI Index</h4>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="ICI1 6500 GAR"
            className="mb-3"


          >
            <Form.Control
              type="text"
              tabIndex={17}
              placeholder="ICI1 6500 GAR"
              name="ici1_6500_GAR"
              {...register("ici1_6500_GAR", {valueAsNumber:true, required: false })}
            />
            {errors.ici1_6500_GAR  && <span>Required </span>}
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="ICI4 4200 GAR"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="ICI4 4200 GAR"
              tabIndex={20}
              name="ici_4_4200_GAR"
              {...register("ici_4_4200_GAR", {valueAsNumber:true, required: false })}
            />
            {errors.ici_4_4200_GAR  && <span>Required </span>}
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="ICI2 5800 GAR"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="ICI2 5800 GAR"
              tabIndex={18}
              name="ici_2_5800_GAR"
              {...register("ici_2_5800_GAR", {valueAsNumber:true, required: false })}
            />
            {errors.ici_2_5800_GAR  && <span>Required </span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="ICI 5 3400 GAR"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="ICI 5 3400 GAR"
              name="ci_5_3400_GAR"
              tabIndex={21}
              {...register("ci_5_3400_GAR", {valueAsNumber:true, required: false })}
            />
            {errors.ci_5_3400_GAR  && <span>Required </span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="ICI 3 5000 GAR"
            className="mb-3"


          >
            <Form.Control
              type="text"
              tabIndex={19}
              placeholder="ICI 3 5000 GAR"
              name="ici_3_5000_GAR"
              {...register("ici_3_5000_GAR", {valueAsNumber:true, required: false })}
            />
            {errors.ici_3_5000_GAR  && <span>Required </span>}
          </FloatingLabel>
        </Form.Group>
      <h4 className="heading">Other Indexes</h4>
      <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="platts K5900"
            className="mb-3"


          >
            <Form.Control
              type="text"
              tabIndex={22}
              placeholder="platts K5900"
              name="platts_K5900"
              {...register("platts_K5900", {valueAsNumber:true, required: false })}
            />
            {errors.ici_5_3400_GAR    && <span>Required </span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Des ARA "
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Des ARA "
              name="des_ARA"
              tabIndex={24}
              {...register("des_ARA", {valueAsNumber:true, required: false })}
            />
            {errors.des_ARA  && <span>Required </span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Nex"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Nex"
              name="Nex"
              tabIndex={23}
              {...register("Nex", {valueAsNumber:true, required: false })}
            />
            {errors.Nex && <span>Required </span>}
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="RB"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="RB"
              name="RB"
              tabIndex={25}
              {...register("RB", {valueAsNumber:true, required: false })}
            />
            {errors.RB && <span>Required </span>}
          </FloatingLabel>
        </Form.Group>


             </Row>
             <p className='edit-cancel'><input type="submit" className="account-save" value="Save" /><Link to={`/pricebook/create-view-list`}><input type="submit" className="account-save" value="Cancel" /></Link></p>      <div className='successDiv'><p></p><p></p></div>
    </Form></div></div>
    <Footer/>
    </div>
  );
}

export default AddPricebook;

