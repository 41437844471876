import React, { useState, useMemo, useEffect } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import Header from '../Header'
import Footer from '../Footer'
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import { AES, enc } from "crypto-js";
import { API_URL } from '../config';
import $ from 'jquery';
// import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
function RecentItem() {
  const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
  const encryptedAccessToken = localStorage.getItem("access_token");
  const decryptedAccessToken = encryptedAccessToken
    ? AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8)
    : null;
    const [recentItems, setRecentItems]=useState([])
    const [loading, setLoading] = useState(true);
    const fetchRecent = async () => {
        try {
          const response = await axios.get(API_URL+'get/recent_items');
          console.log(response)
          if(response.status===200){
            setRecentItems(response.data.recent_items)
          }

        } catch (error) {
          console.error(error);
        }
        finally{
          setLoading(false);
        }
      };
      useEffect(() => {

        fetchRecent();
      }, []);




      // Report
      const [loadings, setloadings] = useState(false);

      const downloadExcel = async () => {
        try {
          setloadings(true);
          const response = await fetch(API_URL+'get/reports?input_text=Accounts_Contacts', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                account_fields: ["company"],
                contact_fields: ["contact_code"]
            })
          });

          if (!response.ok) {
            throw new Error('Failed to generate report');
          }

          const blob = await response.blob();

          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = 'merged_data.xlsx';

          document.body.appendChild(a);
          a.click();

          // Clean up
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);

          setloadings(false);
        } catch (error) {
          console.error('Error downloadings Excel file:', error);
          setloadings(false);
        }
      };
  return (
    <>
    <h4>Recent Items</h4>
<div class='recent'>

         {loading ? (
       <p className="loading-text">Loading...</p>
     ) : (
         <div>
           {recentItems.map((x,i)=>(
             <p><Link to={x.url}>{x.code}</Link></p>
           ))}
         </div>
     )}



    </div>
    {/* <div class='reports'>
      <h4>Accounts & Contacts</h4>
<p class='reports' onClick={downloadExcel} disabled={loading}>
        {loading ? 'Generating Report...' : 'Generate Report'}
      </p>
    </div> */}
    </>
  );
}

export default RecentItem;
