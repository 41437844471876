import React, {
  Component,
  useState,
  useEffect,
  useContext,
  forwardRef,
} from "react";
import { Card, OverlayTrigger, Tooltip, Table } from "react-bootstrap";
import "datatables.net-dt/js/dataTables.dataTables.js";
import { AES, enc } from "crypto-js";
import "datatables.net-responsive-dt";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "react-data-table-component-extensions/dist/index.css";
import { Link, useNavigate } from "react-router-dom";
import { TokenContext } from "../AuthProvider";
import Header from "../Header";
import Footer from "../Footer";

import RecentItems from "../recentItems/RecentItems";
import { IoSearch } from "react-icons/io5";
import $ from "jquery";
import DatePicker from "react-datepicker";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Popup from "reactjs-popup";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { API_URL } from "../config";
import Editor from "react-simple-wysiwyg";
import { parse, format, isValid } from "date-fns";
import Col from "react-bootstrap/Col";
import {
  FaEnvelope,
  FaPhoneSquareAlt,
  FaMobileAlt,
  FaFax,
} from "react-icons/fa";
import axios from "axios";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { FaPencilAlt, FaTrashAlt, FaPlus } from "react-icons/fa";
// import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
import { useParams, useLocation } from "react-router-dom";
const Orderreceipt = () => {
  const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
  const encryptedAccessToken = localStorage.getItem("access_token");
  const decryptedAccessToken = encryptedAccessToken
    ? AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8)
    : null;
  const navigate = useNavigate();
  const params = useParams();
  const id = params.id;
  const userRole = localStorage.getItem("role");
  // console.log(userRole);
  const { username, token } = useContext(TokenContext);
  const [datas, setDatas] = useState([]);
  const [line, setLine] = useState([]);
  const [lockedCheck, setLockedCheck] = useState(false);
  const [paymentDate, setPaymentDate] = useState(null);
  const [document, setDocument] = useState(null);
  const [actual, setActual] = useState(null);
  const [OrderID, setOrderId] = useState([]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        API_URL + "get/rw_receipt_detail/" + id,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );

      console.log(response);

      setOrderId(response.data.rw_receipt_lineitem[0].order_id);
      const data = response.data.rw_receipt_detail[0];
      const dataLine = response.data.rw_receipt_lineitem;

      const payment_date =
        response.data.rw_receipt_detail[0].payment_receipt_date;
      const document_date =
        response.data.rw_receipt_detail[0].document_discount_date;
      const actual_date =
        response.data.rw_receipt_detail[0].actual_payment_recieved_date;

      if (payment_date !== null) {
        const defaultDatepicker = parse(payment_date, "dd/MM/yyyy", new Date());
        setPaymentDate(defaultDatepicker);
      } else {
        setPaymentDate(new Date());
      }

      if (document_date !== null) {
        const defaultDatepicker = parse(
          document_date,
          "dd/MM/yyyy",
          new Date()
        );
        setDocument(defaultDatepicker);
      } else {
        setDocument(new Date());
      }

      if (actual_date !== null) {
        const defaultDatepicker = parse(actual_date, "dd/MM/yyyy", new Date());
        setActual(defaultDatepicker);
      } else {
        setActual(new Date());
      }

      setLine(dataLine);
      setDatas(data);
      $("#rw").DataTable();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (decryptedAccessToken) {
      fetchData();
    }
  }, [decryptedAccessToken]);

  useEffect(() => {
    if (datas.description) {
      setEditor1(datas.description);
    }
  }, [datas]);

  const [editor1, setEditor1] = useState(datas.description);
  function onChanges(e) {
    console.log("onChange event:", e);
    console.log("Current HTML:", e.target.value);
    setEditor1(e.target.value);
  }

  const [payment, setPayment] = useState("");
  const [documents, setDocuments] = useState("");
  const [actuals, setActuals] = useState("");

  useEffect(() => {
    const dateObject = new Date(paymentDate);
    const document_date = new Date(document);
    const actual_Date = new Date(actual);
    if (dateObject) {
      const formattedDate = format(dateObject, "dd/MM/yyyy", {
        timeZone: "UTC",
      });
      setPayment(formattedDate);
      console.log(formattedDate);
    }
    if (document_date) {
      const formattedDate = format(document_date, "dd/MM/yyyy", {
        timeZone: "UTC",
      });
      setDocuments(formattedDate);
      console.log(formattedDate);
    }
    if (actual_Date) {
      const formattedDate = format(actual_Date, "dd/MM/yyyy", {
        timeZone: "UTC",
      });
      setActuals(formattedDate);
    }
  });

  const [company, setCompany] = useState([]);
  useEffect(() => {
    fetchDataAccount();
  }, [decryptedAccessToken]);

  const fetchDataAccount = async () => {
    try {
      const response = await axios.get(API_URL + "get/all/rwoodcompany", {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },
      });
      const data = response.data.rwoodcompany;
      setCompany(data);
      // console.log(data);
    } catch (error) {
      console.error(error);
    }
  };

  // edit option
  const [data, setData] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [editedData, setEditedData] = useState([]);
  const handleEditClick = () => {
    setIsEditMode(true);
    setEditedData(data);
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setDatas((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const location = useLocation();
  const { postId } = location.state || {};
 

  const options = { minimumFractionDigits: 2, maximumFractionDigits: 2 };
  const formatNumber = (number) => {
      return new Intl.NumberFormat("en-US", options).format(number);
  };
const renderShipmentQty = (tcfValues) => {
  if (tcfValues > 0) {
      return <span className='sr'>{formatNumber(tcfValues)}</span>;
  } else if (tcfValues < 0) {
      return <span className='sr'>({formatNumber(Math.abs(tcfValues))})</span>;
  } else {
      return <span className='sr'>{formatNumber(tcfValues)}</span>;
  }
};

  const handleCancelEdit = () => {
    setIsEditMode(false);
    setEditedData(data);
    setTimeout(() => {
      $("#rw").DataTable();
    }, 50);
  };

  const mapData = () => {
    if (line.length > 0) {
      const mappedData = {};

      line.forEach((item) => {
        const id = item.id;

        if (!mappedData[id]) {
          mappedData[id] = [];
        }

        mappedData[id].push({
          order: item.order,
          id: item.id,
          rw_pay_receivable_lineitem: item.rw_pay_receivable_lineitem,
          rw_billing: item.rw_billing,
          line_item_type: item.line_item_type,
          line_item: item.line_item,
          amount: item.amount,
          description: item.description,
          vessel_name: item.vessel_name,
        });
      });

      // Combine all the arrays into a single array
      const combinedData = Object.values(mappedData).reduce(
        (result, data) => [...result, ...data],
        []
      );

      setFormData1(combinedData);
    }
  };

  console.log(OrderID.length);

  const [bill, setBill] = useState([]);

  useEffect(() => {
    if (OrderID) {
      const fetchOrderDetails = async () => {
        try {
          console.log("Fetching order details for OrderID:", OrderID);
          const orderResponse = await axios.get(
            `${API_URL}get/order_pay_recieve/${OrderID}`,
            {
              headers: { Authorization: `Bearer ${decryptedAccessToken}` },
            }
          );
          console.log(orderResponse);
          const data = orderResponse.data.pay_recieve_detail;

          setBill(data);
        } catch (error) {
          console.error("Error fetching order details:", error);
        }
      };

      fetchOrderDetails();
    }
  }, [OrderID, decryptedAccessToken]);

  const [lineitems, setLineitems] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedProductCode, setSelectedProductCode] = useState("");
  const [lineitemText, setLineitemText] = useState("");

  const handleClear = () => {
    setSelectedProductCode("");
    // setFormData1([{ line_item_type: "", line_item: "" }]);
    setIsPopupOpen(true);
  };

  const handleRowClick = (productCode, lineItem) => {
    setSelectedProductCode(productCode);
    setLineitemText(lineItem);
    setIsPopupOpen(false);

    // Update formData1 with the selected product code, line item, and id
    setFormData1((prevFormData) => {
      const updatedFormData = [...prevFormData];

      // Update line item type, line item, and id
      updatedFormData[0] = {
        ...updatedFormData[0],
        line_item_type: productCode,
        line_item: lineItem,
      };

      // Debugging logs
      console.log("Updated formData1:", updatedFormData);

      return updatedFormData; // Return the updated form data
    });
  };

  const fetchLineitem = async () => {
    if (OrderID) {
      try {
        const response = await axios.get(
          `${API_URL}get/order_pay_recieve_lineitem/${OrderID}`,
          {
            headers: { Authorization: `Bearer ${decryptedAccessToken}` },
          }
        );
        console.log("*********************", response);
        const data = response.data.pay_lineitem_detail;

        setLineitems(data);
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    fetchLineitem();
  }, [OrderID, decryptedAccessToken]);

  const [lockeds, setLockeds] = useState(null);

  const chek_bok = datas.locked;
  const chek_payment = datas.payment;
  useEffect(() => {
    setLockeds(chek_bok);
  }, [chek_bok]); // Run this effect whenever tt changes

  useEffect(() => {}, [lockeds]); // Run

  const handleCheckboxChange1 = (e) => {
    setLockeds(e.target.checked);
  };

  const [dataBank, setDataBank] = useState([]);
  useEffect(() => {
    fetchDatas();
  }, [decryptedAccessToken]);

  const fetchDatas = async () => {
    try {
      const response = await axios.get(API_URL + "get/all/rwoodbank", {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },
      });
      console.log(response);
      const data = response.data.rwood_bank;
      setDataBank(data);
    } catch (error) {
      console.error(error);
    }
  };

  // const mapData = () => {
  //   if (line.length > 0) {
  //     const combinedData = line.reduce((result, item) => {
  //       return {
  //         ...result,
  //         ...item,
  //       };
  //     }, {});

  //     setFormData1(combinedData);
  //   }
  // };

  // Call the mapData function when needed, e.g., when formData2 changes
  useEffect(() => {
    mapData();
  }, [line]);

  const [formData1, setFormData1] = useState([
    {
      order: "",
      id: "",
      rw_pay_receivable_lineitem: "",
      rw_billing: "",
      line_item_type: "",
      line_item: "",
      amount: "",
      description: "",
      vessel_name: "",
    },
  ]);

  const [lineItem, setlineItem] = useState([]);

  const lineItemList = async () => {
    if (OrderID) {
      try {
        const response = await axios.get(API_URL + "get/all/financeconfig", {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        });
        const data = response.data.all_finance;
        setlineItem(data);
        console.log(data);
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    lineItemList();
  }, [OrderID, decryptedAccessToken]);

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;

    setFormData1((prev) => {
      const updated = [...prev];
      updated[index] = {
        ...updated[index],
        [name]: value,
      };

      return updated;
    });

    if (name === "line_item") {
      setLineitemText(value); // Update lineitemText state
    }
  };

  const [accountName, setAccountName] = useState([]);
  useEffect(() => {
    fetchDataSupplier();
  }, [decryptedAccessToken]);

  const fetchDataSupplier = async () => {
    try {
      const response = await axios.get(API_URL + "all/accounts", {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },
      });
      const data = response.data.accounts;
      setAccountName(data);
      console.log(data);
    } catch (error) {
      console.error(error);
    }
  };

  // console.log(formData1)
  const handleSaveEdit = (e) => {
    $("#table").DataTable();
    e.preventDefault();

    const requestData = {
      //
      receipt_update: formData1,
    };

    // console.log(requestData)
    const order_detail = {
      currency: datas.currency,
      account: datas.account,
      rw_payment_receipt: datas.rw_payment_receipt,
      company: datas.company || "",
      record_type: datas.record_type,
      owner: datas.owner,
      rwood_bank_master: datas.rwood_bank_master,
      mode_of_payment: datas.mode_of_payment,
      payment_receipt_ref_no: datas.payment_receipt_ref_no,
      receipt_status: datas.receipt_status,
      payment_receipt_date: payment,
      document_discount_date: documents,
      actual_payment_recieved_date: actuals,
      decription: editor1,
      total_amount: datas.total_amount,
    };
    console.log(JSON.stringify(order_detail));
    // console.log(JSON.stringify(requestData))
    fetch(`${API_URL}update/rw_pay_receipt_detail/${id}`, {
      method: "PUT",
      body: JSON.stringify(order_detail),
      headers: {
        Authorization: `Bearer ${decryptedAccessToken}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((updatedData) => {
        // console.log(updatedData)
        // if(updatedData.msg==="Updated successfully"){
        console.log(JSON.stringify(formData1));
        fetch(`${API_URL}update/rw_pay_receipt/${id}`, {
          method: "PUT",
          body: JSON.stringify(requestData),
          headers: {
            Authorization: `Bearer ${decryptedAccessToken}`,
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())
          .then((updatedData) => {
            console.log(updatedData);
            fetchData();
            // navigate(`/order/view-order-details/${id}`)

            // console.log(updatedData)
            $("#table").DataTable();
          });
        // }

        setData(updatedData);
        setIsEditMode(false);
      })
      .catch((error) => console.error(error));
  };

  return (
    <div id="view-page">
      <Header />
      <div className="row">
      <div className="col-md-2">
            <RecentItems/>
          </div>
          <div className="col-md-10">
      <div className="contactAccount">
        <div className="page-header btn-margin" id="add-account">
          <div>
            <h2 className="main-content-title tx-24 mg-b-5">
              RW Payment/Receipt Detail
            </h2>
            <ol className="breadcrumb">
              <li className="breadcrumb-item active" aria-current="page">
                Dashboard / Receipt details
              </li>
            </ol>
          </div>
        </div>
        {isEditMode ? (
          <>
            <div className="tasks">
              <p className="edit-btn">
                <button className="btn btn-primary" onClick={handleSaveEdit}>
                  Save
                </button>
                <button className="btn btn-primary" onClick={handleCancelEdit}>
                  Cancel
                </button>
              </p>
              <Row className="mb-3">
                <h4 className="heading">RW Payment/Receipt Information</h4>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Record Type"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Record Type"
                      tabIndex={1}
                      name="record_type"
                      defaultValue={datas.record_type}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6" id="lockeds">
                  <input
                    type="checkbox"
                    name="locked"
                    tabIndex={8}
                    onChange={(e) => setLockedCheck(e.target.checked)}
                  />
                  <label>Locked</label>
                </Form.Group>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingSelect"
                    className="dropDown"
                    label="Account"
                  >
                    <Form.Select
                      aria-label="Floating label select example"
                      name="account"
                      placeholder="Account"
                      value={datas.account}
                      tabIndex={2}
                      onChange={handleChangeInput}
                    >
                      <option value="">Select</option>
                      {accountName.length > 0 ? (
                        <>
                          {accountName.map((x) => {
                            return (
                              <option value={x.account_name}>
                                {x.account_name}
                              </option>
                            );
                          })}
                        </>
                      ) : (
                        <option className="no-data">No data available</option>
                      )}
                    </Form.Select>
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="6"></Form.Group>
                {/* <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingSelect"
                    className="dropDown"
                    label="Company"
                  >
                    <Form.Select
                      aria-label="Floating label select example"
                      name="company"
                      placeholder="Company"
                      defaultValue={datas.company || ""}
                      onChange={handleChangeInput}
                    >
                      <option value="">Select</option>
                      {company.map((x) => {
                        return (
                          <option value={x.company_name}>
                            {x.company_name}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </FloatingLabel>
                </Form.Group> */}

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingSelect"
                    className="dropDown"
                    label="Company"
                  >
                    <Form.Select
                      aria-label="Floating label select example"
                      name="company"
                      placeholder="Company"
                      tabIndex={3}
                      value={datas?.company || ""}
                      onChange={handleChangeInput}
                    >
                      <option value="" disabled>
                        Select
                      </option>

                      {company.map((x) => (
                        <option
                          key={x.rwood_company_code}
                          value={x.rwood_company_code}
                        >
                          {x.rwood_company_code}
                        </option>
                      ))}
                    </Form.Select>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="payment receipt ref no"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder="payment receipt ref no"
                      name="payment_receipt_ref_no"
                      tabIndex={9}
                      className="clear"
                      defaultValue={datas.payment_receipt_ref_no || ""}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingSelect"
                    className="dropDown"
                    label="bank master"
                  >
                    <Form.Select
                      aria-label="Floating label select example"
                      name="rwood_bank_master"
                      tabIndex={4}
                      placeholder="bank master master"
                      defaultValue={datas.rwood_bank_master || ""}
                      onChange={handleChangeInput}
                    >
                      <option value="" disabled>
                        Select
                      </option>
                      {dataBank.map((x) => {
                        return (
                          <option value={x.rwood_bank_name}>
                            {x.rwood_bank_name}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Payment Receipt Date"
                    className="mb-3 datepick"
                  >
                    <DatePicker
                      selected={paymentDate}
                      tabIndex={10}
                      onChange={(date) => setPaymentDate(date)}
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Select a date"
                    />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingSelect"
                    className="dropDown"
                    label="mode of payment"
                  >
                    <Form.Select
                      aria-label="Floating label select example"
                      name="mode_of_payment"
                      placeholder="mode of payment"
                      defaultValue={datas.mode_of_payment || ""}
                      tabIndex={5}
                      onChange={handleChangeInput}
                    >
                      <option value="">--None--</option>
                      <option value="Letter Of Credit" selected="selected">
                        Letter Of Credit
                      </option>
                      <option value="Telegraphic Transfer">
                        Telegraphic Transfer
                      </option>
                      <option value="Cheque">Cheque</option>
                      <option value="Cash">Cash</option>
                    </Form.Select>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="document discount date"
                    className="mb-3 datepick"
                  >
                    <DatePicker
                      selected={document}
                      tabIndex={11}
                      onChange={(date) => setDocument(date)}
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Select a date"
                    />
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="actual payment recieved date"
                    className="mb-3 datepick"
                  >
                    <DatePicker
                      selected={actual}
                      onChange={(date) => setActual(date)}
                      dateFormat="dd/MM/yyyy"
                      tabIndex={6}
                      placeholderText="Select a date"
                    />
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingSelect"
                    className="dropDown"
                    label="receipt_status"
                  >
                    <Form.Select
                      aria-label="Floating label select example"
                      name="receipt_status"
                      tabIndex={12}
                      placeholder="receipt_status"
                      defaultValue={datas.receipt_status || ""}
                      onChange={handleChangeInput}
                      className="clear"
                    >

<option value="" selected="selected">
        --None--
      </option>
                      <option value="Raised">
                        Raised
                      </option>
                      <option value="Paid">Paid</option>
                      <option value="Partial Payment">Partial Payment</option>
                      <option value="Adjusted">Adjusted</option>
                      <option value="Cancelled">Cancelled</option>
                      <option value="Settled By Arutmin">
                        Settled By Arutmin
                      </option>
                    </Form.Select>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Total Amount"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Total Amount"
                      tabIndex={7}
                      name="total_amount"
                      className="clear"
                      defaultValue={datas.total_amount || ""}
                      onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <label>Description</label>
                  <Editor
                    value={editor1}
                    name="description"
                    tabIndex={13}
                    onChange={onChanges}
                  />
                </Form.Group>
              </Row>
              <h4 className="heading">RW Payment Receipt Line Items</h4>
              <table id="rw-table" className="table">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Rw pay receivable lineitem</th>
                    <th>Rw billing</th>
                    <th>Line item type</th>
                    <th>Line item</th>
                    <th>Order</th>
                    <th>Amount</th>
                    <th>Description</th>
                    <th>Vessel name</th>
                    <th>BL Date</th>
                  </tr>
                </thead>
                <tbody>
                  {formData1.map((x, index) => (
                    <>
                      {line.map((y, index) => (
                        <tr key={index}>
                          <td>
                            <input
                              type="text"
                              name="id"
                              className="form-control clear"
                              placeholder="id"
                              defaultValue={x.id}
                              onChange={(e) => handleInputChange(index, e)}
                            />
                          </td>
                          {/*    <td>
                <input
                  type="text"
                  name="order"
                  placeholder="Order"
                  className="form-control"

                  // /value={formData1[index].currency !== undefined ? formData1[index].currency : ""}

                  defaultValue={ x.order}
                  onChange={(e) => handleInputChange(index, e)}
                  />
              </td> */}
                          <td>
                            <select
                              type="text"
                              name="rw_pay_receivable_lineitem"
                              className="form-control clear"
                              defaultValue={x.rw_pay_receivable_lineitem}
                              onChange={(e) => handleInputChange(index, e)}
                            >
                              <option>---None--</option>
                              {lineitems.map((x, index) => (
                                <>
                                  <option
                                    value={x.rw_pay_receivable_lineitem}
                                  >
                                    {x.rw_pay_receivable_lineitem}
                                  </option>
                                  {/* <option value='RW-PR_LI-01-0023'>RW-PR_LI-01-0023</option> */}
                                </>
                              ))}
                            </select>
                          </td>

                          <td>
                            <select
                              type="text"
                              name="rw_billing"
                              className="form-control clear"
                              defaultValue={x.rw_billing}
                              onChange={(e) => handleInputChange(index, e)}
                            >
                              <option>---None--</option>
                              {lineitems?.map((x) => (
                                <>
                                  <option value={x.rw_billing}>
                                    {x.rw_billing}
                                  </option>
                                  {/* <option value='RW-PR_LI-01-0023'>RW-PR_LI-01-0023</option> */}
                                </>
                              ))}
                            </select>
                          </td>
                          <td>
                            <div className="lineGroup">
                              <input
                                type="text"
                                placeholder="Line Item Type"
                                name="line_item_type"
                                id="line_item_clear"
                                value={selectedProductCode || y.line_item_type}
                                // Handle input change
                                onChange={(e) => {
                                  const newValue = e.target.value;
                                  setFormData1((prev) => {
                                    const updated = [...prev];
                                    updated[0].line_item_type = newValue; // Update the line item type
                                    return updated; // Return the updated form data
                                  });
                                }}
                              />
                              <button
                                className="button select-search"
                                onClick={handleClear}
                              >
                                <IoSearch />
                              </button>
                            </div>

                            <Popup
                              open={isPopupOpen}
                              onClose={() => setIsPopupOpen(false)}
                              modal
                            >
                              <table className="table table-hovered">
                                <thead>
                                  <tr>
                                    <th>Billing Config Name</th>
                                    <th>Line Item</th>
                                    <th>Lineitem Type</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {lineItem.map((y, i) => (
                                    <tr
                                      key={i}
                                      onClick={() =>
                                        handleRowClick(
                                          y.billing_config_name,
                                          y.line_item
                                        )
                                      }
                                    >
                                      <td>{y.billing_config_name}</td>
                                      <td>{y.line_item}</td>
                                      <td>{y.lineitem_type}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </Popup>
                            {/* <select className="form-control lineitem"
         name="line_item_type"
         defaultValue={x.line_item_type  ||y.line_item_type}
         onChange={(e) => handleInputChange(index, e)}>
            <option>---None---</option>
          {lineItems.map((x, idx) => (
            <option key={idx} value={x.billing_config_name}>{x.billing_config_name}</option>
          ))}
        </select> */}
                          </td>

                          {/* <td>
                  <select className="form-control lineitem"
                  name='line_item_type'
                  defaultValue={x.line_item_type}
                  onChange={(e) => handleInputChange(index, e)} >
                     <option>---None--</option>
                  {lineItem?.map((x) => (

                    <option value={x.billing_config_name}>{x.billing_config_name}</option>


                  ))}
                  </select>
                  </td> */}

                          <td>
                            <input
                              type="text"
                              name="line_item"
                              placeholder="Line item"
                              id="line_item_clear"
                              value={lineitemText || y.line_item}
                              className="form-control clear"
                              onChange={(e) => handleInputChange(index, e)}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              name="order"
                              placeholder="Order"
                              className="form-control"
                              // /value={formData1[index].currency !== undefined ? formData1[index].currency : ""}

                              defaultValue={x.order}
                              onChange={(e) => handleInputChange(index, e)}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              name="amount"
                              placeholder="Amount"
                              defaultValue={x.amount}
                              className="form-control clear"
                              onChange={(e) => handleInputChange(index, e)}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              name="description"
                              placeholder="Description"
                              defaultValue={x.description}
                              className="form-control clear"
                              onChange={(e) => handleInputChange(index, e)}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              name="vessel_name"
                              placeholder="Vessel name"
                              defaultValue={x.vessel_name}
                              className="form-control clear"
                              onChange={(e) => handleInputChange(index, e)}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              name="bl_date"
                              placeholder="BL Date"
                              defaultValue={x.bl_date}
                              className="form-control clear"
                              onChange={(e) => handleInputChange(index, e)}
                            />
                          </td>
                        </tr>
                      ))}
                    </>
                  ))}
                </tbody>
              </table>
              <p className="edit-btn">
                <button className="btn btn-primary" onClick={handleSaveEdit}>
                  Save
                </button>
                <button className="btn btn-primary" onClick={handleCancelEdit}>
                  Cancel
                </button>
              </p>
            </div>
          </>
        ) : (
          <div className="tasks">
            <p className="edit-cancel">
              <input
                type="submit"
                className="account-save"
                value="Edit"
                onClick={handleEditClick}
              />{" "}
              {postId ? (
                <Link to={`/order/view-order-details/${postId}`}>
                  <input
                    type="submit"
                    className="account-save"
                    value="Cancel"
                  />
                </Link>
              ) : (
                <Link to="/receipt-detail/list-view">
                  <input
                    type="submit"
                    className="account-save"
                    value="Cancel"
                  />
                </Link>
              )}
            </p>
            <div className="row">
              <div className="col-md-12" id="head">
                <h4 className="heading">Information</h4>
                <hr></hr>
              </div>
            </div>
            <table class="table table-bordered account-table tables">
              <tbody>
                <tr>
                  <td id="td-right">
                    <span>record type</span>
                  </td>
                  <td>{datas.record_type}</td>
                  <td id="td-right">
                    <span>locked</span>
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="locked"
                      checked={lockeds} // Use the state variable to determine if the checkbox is checked
                      onChange={handleCheckboxChange1} // Handle the change event
                      className="mx-3"
                    />
                  </td>
                </tr>
                <td id="td-right">
                  <span>account</span>
                </td>
                <td>
                  <Link to={`/accounts/${datas.account_id}`}>
                    {" "}
                    {datas.account}
                  </Link>
                </td>
                <td id="td-right">
                  <span>RW payment receipt</span>
                </td>
                <td>{datas.rw_payment_receipt}</td>
                <tr>
                  <td id="td-right">
                    <span>currency</span>
                  </td>
                  <td>{datas.currency}</td>

                  <td id="td-right">
                    <span>Company</span>
                  </td>
                  <td>
                    <Link
                      to={`/company/company-details/${datas.rwoodcompany_id}`}
                    >
                      {datas.company}
                    </Link>
                  </td>
                </tr>

                <tr>
                  <td id="td-right">
                    <span>Exchange rate</span>
                  </td>
                  <td>{datas.exchange_rate}</td>
                  <td id="td-right">
                    <span>owner</span>
                  </td>
                  <td>{datas.owner}</td>
                </tr>

                <tr>
                  <td id="td-right">
                    <span>Payment receipt date</span>
                  </td>
                  <td>{datas.payment_receipt_date}</td>
                  <td id="td-right">
                    {/* <span>Payment receipt date</span> */}
                  </td>
                  <td>{/* {datas.payment_receipt_date} */}</td>
                </tr>

                <tr>
                  <td id="td-right">
                    <span>rwood bank master</span>
                  </td>
                  <td>
                    <Link to={`/bank/bank-details/${datas.rwoodbank_id}`}>
                      {" "}
                      {datas.rwood_bank_master}
                    </Link>
                  </td>
                  <td id="td-right">{/* <span>total amount</span> */}</td>
                  <td>{/* {datas.total_amount} */}</td>
                </tr>

                <tr>
                  <td id="td-right">
                    <span>mode ofpayment</span>
                  </td>
                  <td>{datas.mode_of_payment}</td>
                  <td id="td-right">
                    <span>total amount</span>
                  </td>
                  <td>{renderShipmentQty(datas.total_amount)}</td>
                </tr>

                <tr>
                  <td id="td-right">
                    <span>payment receipt ref no</span>
                  </td>
                  <td>{datas.payment_receipt_ref_no}</td>

                  <td id="td-right">
                    <span>status</span>
                  </td>
                  <td>{datas.receipt_status}</td>
                </tr>

                <tr>
                  <td id="td-right">
                    <span>Description</span>
                  </td>
                  <td>{datas.description}</td>
                  <td id="td-right">{/* <span>Description</span> */}</td>
                  <td>{/* {datas.description} */}</td>
                </tr>

                <tr>
                  <td id="td-right">
                    <span>Remark</span>
                  </td>
                  <td>{datas.remark}</td>
                  <td id="td-right">{/* <span>Description</span> */}</td>
                  <td>{/* {datas.description} */}</td>
                </tr>

                <tr>
                  <td id="td-right">
                    <span>document discount date</span>
                  </td>
                  <td>{datas.document_discount_date}</td>
                  <td id="td-right">{/* <span>Description</span> */}</td>
                  <td>{/* {datas.description} */}</td>
                </tr>
                <tr>
                  <td id="td-right">
                    <span>actual payment recieved date</span>
                  </td>
                  <td>{datas.actual_payment_recieved_date}</td>
                  <td id="td-right">{/* <span>Description</span> */}</td>
                  <td>{/* {datas.description} */}</td>
                </tr>

                <tr>
                  <td id="td-right">
                    <span>Created By</span>
                  </td>
                  <td>{datas.created_by}</td>

                  <td id="td-right">
                    <span>last modified by</span>
                  </td>
                  <td>{datas.last_modified_by}</td>
                </tr>
              </tbody>
            </table>
            <Row id="table-styles">
              <div className="col-md-12" id="head">
                <h4 className="heading">
                  <span>Rw Payable/ Receipt LineItem </span>
                </h4>
                <hr></hr>
              </div>
              <table id="rw" className="">
                <thead>
                  <tr>
                    <th>RW Payment/Receipt LineItem Name </th>
                    <th>RW Billing </th>
                    <th>Line Item Type</th>
                    <th>Line Item </th>
                    <th>Order</th>
                    <th>Amount</th>
                    <th>Description</th>
                    <th>Vessel Name </th>
                    <th>BL Date</th>
                  </tr>
                </thead>

                {line.length > 0 ? (
                  <tbody>
                    {line.map((y, i) => (
                      <tr>
                        <td>
                          <Link to={`/order/receipt-details/${y.id}`}>
                            {y.rw_payment_receipt_name}
                          </Link>
                        </td>
                        <td>
                          <Link
                            to={`/order/order-receivable-details/${y.rw_pay_recieve_id}`}
                          >
                            {y.rw_billing}
                          </Link>
                        </td>
                        <td>{y.line_item_type}</td>
                        <td>{y.line_item}</td>

                        <td>
                          <Link to={`/order/view-order-details/${y.order_id}`}>
                            {y.order}
                          </Link>
                        </td>
                        <td>{renderShipmentQty(y.amount)}</td>
                        <td>{y.description}</td>
                        <td>{y.vessel_name}</td>
                        <td>{y.bl_date}</td>
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>No data available</td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                )}
              </table>
            </Row>
          </div>
        )}
      </div></div></div>
      <Footer />
    </div>
  );
};

export default Orderreceipt;
