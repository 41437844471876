import React, { Component, useState, useEffect, useContext } from "react";
import {
  Card,

  OverlayTrigger,

  Tooltip,
  Table,
} from "react-bootstrap";
import "datatables.net-dt/js/dataTables.dataTables.js";
import { AES, enc } from "crypto-js";
import "datatables.net-responsive-dt";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "react-data-table-component-extensions/dist/index.css";
import { Link, useNavigate } from "react-router-dom";
import { TokenContext } from "../AuthProvider";
import Header from '../Header'
import Footer from '../Footer'
import RecentItems from "../recentItems/RecentItems";
import { API_URL } from '../config';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FaEnvelope,FaPhoneSquareAlt,FaMobileAlt,FaFax } from "react-icons/fa";
import axios from "axios";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { FaPencilAlt, FaTrashAlt, FaPlus } from "react-icons/fa";
// import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
import { useParams  } from "react-router-dom";
import $ from 'jquery'
const ViewCompany = () => {

  const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
  const encryptedAccessToken = localStorage.getItem("access_token");
  const decryptedAccessToken = encryptedAccessToken
    ? AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8)
    : null;

  const params = useParams()
  const id=params.id
  const adminRole = localStorage.getItem("role");
  console.log(adminRole);
  const { username ,token} = useContext(TokenContext);
  const [datas, setDatas] = useState({});



  useEffect(() => {

    fetchData();
  }, [decryptedAccessToken]);

  const fetchData = async () => {
    try {
      const response = await axios.get(API_URL+'get/rwood_companybyID/'+id, {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      const data = response.data.rwood_company_details;
      console.log(data)
      setDatas(data);
      $("#contract").dataTable();
      $("#tcf").dataTable();
      $("#orders").dataTable();
      $("#rw-table").dataTable();
      $("#rw-table-1").dataTable();
      $("#rw-table-2").dataTable();
      $("#rw-table-3").dataTable();
      $("#rw-table-4").dataTable();
    } catch (error) {
      console.error(error);
    }
  };
  if (!datas) {
    return null;
  }
  return (

    <div id='view-page'>
    <Header/>

    <div className="row">
      <div className="col-md-2">
            <RecentItems/>
          </div>
          <div className="col-md-10">
             <div className="contactAccount">

     <div className="page-header btn-margin" id='add-account'>
     <div>
       <h2 className="main-content-title tx-24 mg-b-5">Company Details</h2>
       <ol className="breadcrumb">
         <li className="breadcrumb-item active" aria-current="page">Dashboard / Company details</li>
       </ol>
     </div>

       </div>
       <div className='tasks'>
       <p className='edit-cancel'><Link to={'/company/company-list'}><input type="submit" className="account-save" value="Cancel" /></Link></p>
   <div className=''>
   <div className='row'>
     <div className="col-md-12" id='adatas'>
     <h4 className="heading">Rwood Company Information</h4>
     <hr></hr>
     </div>
   </div>
   {datas.length > 0 ? (
    <>
    {datas.map((x)=>(
      <>
       <table class="table table-bordered account-table tables">
      <tbody>
        <tr>
          <td id="td-right"><span>company code</span> </td>
          <td>{x.rwood_company_code}</td>
          <td id="td-right"><span>Owner </span></td>
          <td>{x.adminRole}

          </td>

        </tr>
        <tr>
        <td id="td-right">
            <span>
            Company Name

            </span>
            </td><td>
            {x.company_name}
          </td>
        </tr>
        <tr>
        <td id="td-right">
            <span>Address 1</span>
            </td>
            <td>
             {x.address_1}
          </td>
          <td id="td-right">
            </td>
            <td>
          </td>
        </tr>
       <tr>
        <td id="td-right">
            <span>Address 2</span>
            </td>
            <td>
             {x.address_2}
          </td>
          <td id="td-right">
            </td>
            <td>
          </td>
        </tr>

        <tr>

        <td id="td-right">
            <span>Country</span>
            </td>
            <td>

            {x.country}
          </td>
          <td id="td-right">
            </td>
            <td>

          </td>
        </tr>
        <tr>
        <td id="td-right">
            <span>Pincode</span>
            </td>
            <td>

            {x.pincode}
          </td>
          <td id="td-right">
            </td>
            <td>

          </td>
        </tr>
        <tr>
        <td id="td-right"><span>Short Name </span></td>
        <td>{x.shortname}</td>
        <td id="td-right">
            <span>Starting Number</span>
          </td>
          <td>
            {x.starting_number}
          </td>

        </tr>
        <tr>
        <td id="td-right">
            <span>Created By</span>
            </td>
            <td>

            {x.created_at}
          </td>
          <td id="td-right">
            <span>Last Modified By
            </span>
            </td>
            <td>

            {x.updated_at}
          </td>

        </tr>
      </tbody>
    </table>
    <Row id="table-styles">
     <div className="col-md-12" id="head">
       <h4 className="heading">
         <span>Open Activities</span>
         <span>
           <i className=""></i> New Task </span>
         <span>
           <i className=""></i> New Event </span>
       </h4>
       <hr>
       </hr>
     </div>
     <table id="history" className="">
       <tr>
         <td>
           <p class="no-records"> No records to display </p>
         </td>
       </tr>
     </table>
   </Row>
   <Row id="table-styles">
       <div className="col-md-12" id="head">
         <h4 className="heading">
           <span>Activity History</span>
           <span>
             <i className=""></i> Log a Call </span>
           <span>
             <i className=""></i> Mail merge </span>
         </h4>
         <hr>
         </hr>
       </div>
       <table id="history" className="">
         <tr>
           <td>
             <p class="no-records"> No records to display </p>
           </td>
         </tr>
       </table>
     </Row>
     <Row id="table-styles">
       <div className="col-md-12" id="head">
         <h4 className="heading">
           <span>Notes & Attachments</span>
           <span>
             <i className=""></i> New Note </span>
           <span>
             <i className=""></i> Attach File </span>
         </h4>
         <hr>
         </hr>
       </div>
       <table id="history" className="">
         <tr>
           <td>
             <p class="no-records"> No records to display </p>
           </td>
         </tr>
       </table>
     </Row>
     <Row id="table-styles">
                  <div className="col-md-12" id="head">
                    <h4 className="heading"><span>Contracts</span></h4>
                    <hr></hr>
                  </div>
                  <table id="contract" className="">
                    <thead>
                      <tr>
                      <th>Contract Number	</th>
                      <th>Rwood Contract No	</th>
                      <th>Account Name		</th>
                      <th>Status</th>
                      <th>Contract Quantity (MT)
                      </th>



                      </tr>
                    </thead>

                      <tbody>
                        <tr>
                          <td></td>
                          <td></td>
                          <td>No data available</td>
                          <td></td>
                          <td></td>

                        </tr>
                      </tbody>
                  </table>
                </Row>

                <Row id="table-styles">
                  <div className="col-md-12" id="head">
                    <h4 className="heading"><span>Orders</span></h4>
                    <hr></hr>
                  </div>
                  <table id="orders" className="">
                    <thead>
                      <tr>
                      <th>Order Number		</th>
                      <th>Account Name		</th>
                      <th>Supplier	</th>
                      <th>Vessel Name		</th>
                      <th>BL Date		</th>
                      <th>DELETE BL Quantity	</th>
                      <th>Supplier Invoice Amount		</th>
                      <th>Supplier Invoice Price		</th>
                      <th>Commercial Invoice Price
                      </th>
                      </tr>
                    </thead>

                      <tbody>
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>No data available</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                  </table>
                </Row>
                <Row id="table-styles">
                  <div className="col-md-12" id="head">
                    <h4 className="heading"><span>Trade Confirmation Forms
                    </span></h4>
                    <hr></hr>
                  </div>
                  <table id="tcf" className="">
                    <thead>
                      <tr>
                      <th>TCF No	</th>
                      <th>Buyer	</th>
                      <th>Supplier</th>
                      <th>Quantity (MT)		</th>
                      <th>Contractual GAR (P)		</th>
                      <th>Actual GAR (P)		</th>
                      <th>AActual GAR (S)
                      </th>

                      </tr>
                    </thead>

                      <tbody>
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>No data available</td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                  </table>
                </Row>
                <Row id="table-styles">
                  <div className="col-md-12" id="head">
                    <h4 className="heading"><span>RW Payable/Receivables

                    </span></h4>
                    <hr></hr>
                  </div>
                  <table id="rw-table" className="">
                    <thead>
                      <tr>
                      <th>RW Payable/Receivable		</th>
                      <th>Account		</th>
                      <th>Status</th>
                      <th>Document Date			</th>
                      <th>Total Amount		</th>
                      <th>Total Balance Due		</th>
                      <th>Record Type

                      </th>

                      </tr>
                    </thead>

                      <tbody>
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>No data available</td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                  </table>
                </Row>

                <Row id="table-styles">
                  <div className="col-md-12" id="head">
                    <h4 className="heading"><span>Rw Payable/ Receivable LineItem


                    </span></h4>
                    <hr></hr>
                  </div>
                  <table id="rw-table-1" className="">
                    <thead>
                      <tr>
                      <th>Rw Payable/ Receivable LineItem		</th>
                      <th>Account		</th>
                      <th>Amount</th>
                      <th>Line Item				</th>
                      <th>Order	</th>
                      <th>Contract	</th>
                      <th>Buyer

                      </th>
                      <th>Supplier

                      </th>
                      <th>BL Date


</th>

                      </tr>
                    </thead>

                      <tbody>
                        <tr>
                          <td></td>
                          <td></td><td></td>
                          <td></td>
                          <td>No data available</td>
                          <td></td>
                          <td></td><td></td>
                          <td></td>
                        </tr>
                      </tbody>
                  </table>
                </Row>

                <Row id="table-styles">
                  <div className="col-md-12" id="head">
                    <h4 className="heading"><span>RW Payments/Receipts



                    </span></h4>
                    <hr></hr>
                  </div>
                  <table id="rw-table-2" className="">
                    <thead>
                      <tr>
                      <th>RW Payment/Receipt		</th>
                      <th>Record Type			</th>
                      <th>Payment/Receipt Date	</th>
                      <th>Status			</th>
                      <th>Total Amount		</th>
                      <th>Rwood Bank Master		</th>
                      <th>Mode Of Payment

                      </th>
                      <th>Description

                      </th>


                      </tr>
                    </thead>

                      <tbody>
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>No data available</td>
                          <td></td>
                          <td></td><td></td>
                          <td></td>
                        </tr>
                      </tbody>
                  </table>
                </Row>
                <Row id="table-styles">
                  <div className="col-md-12" id="head">
                    <h4 className="heading"><span>RW Payments/Receipts LineItem




                    </span></h4>
                    <hr></hr>
                  </div>
                  <table id="rw-table-3" className="">
                    <thead>
                      <tr>
                      <th>RW Payment/Receipt LineItem Name	</th>
                      <th>Account	</th>
                      <th>Amount	</th>
                      <th>TCF No				</th>
                      <th>Order		</th>
                      <th>RW Billing
                      </th>

                      </tr>
                    </thead>

                      <tbody>
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>No data available</td>
                          <td></td>
                          <td></td>

                        </tr>
                      </tbody>
                  </table>
                </Row>
                <Row id="table-styles">
                  <div className="col-md-12" id="head">
                    <h4 className="heading"><span>Opportunities




                    </span></h4>
                    <hr></hr>
                  </div>
                  <table id="rw-table-4" className="">
                    <thead>
                      <tr>
                      <th>Opportunity Name		</th>
                      <th>Account Name		</th>
                      <th>Close Date
                      </th>
                       </tr>
                    </thead>

                      <tbody>
                        <tr>
                          <td></td>
                          <td>No data available</td>
                          <td></td>

                        </tr>
                      </tbody>
                  </table>
                </Row>


    </>
    ))}
    </>
   ):(
    <div className="tasks">
        <h1 className="no-data-detail"><img src='../../images/loading.gif' className="loading"/></h1>
        </div>
   )}
  {/* <Container id='contracts'>
      <Row>
        <Col><p><span>Rwood company code</span><br/>{datas.rwood_company_code}</p></Col>
        <Col><p><span>Short Name </span><br/>{datas.shortname}</p></Col>
        <Col><p><span>Starting Number</span><br/>{datas.starting_number}</p></Col>

        </Row>

      <Row>
        <Col><p><span>Company Name</span><br/>{datas.company_name}</p></Col>
        <Col><p><span>Owner</span><br/>{datas.owner}</p></Col>
        <Col><p><span>Address 1</span><br/>{datas.address_1}</p></Col>
        </Row>
        <Row>
        <Col><p><span>Address 2</span><br/>{datas.address_2}</p></Col>
        <Col><p><span>Pincode</span><br/>{datas.pincode}</p></Col>
        <Col><p><span>Country</span><br/>{datas.country}</p></Col>
        </Row>
        <Row>
        <Col><p><span>Created At</span><br/>{datas.created_at}</p></Col>
        <Col><p><span>Updated At</span><br/>{datas.updated_at}</p></Col>
        <Col></Col>
      </Row>
      </Container> */}
      </div>
      <p className='edit-cancel'><Link to={'/company/company-list'}><input type="submit" className="account-save" value="Cancel" /></Link></p>
      </div></div></div></div>
      <Footer/>
      </div>
  );
};

export default ViewCompany;