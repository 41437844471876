import React, { useEffect, useState, forwardRef } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { useLocation } from "react-router-dom";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { AES, enc } from "crypto-js";
import Header from "../Header";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "../Footer";
import $ from "jquery";
import { API_URL } from '../config';
import axios from "axios";
// import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function CreateContract() {

  const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
  const encryptedAccessToken = localStorage.getItem("access_token");
  const decryptedAccessToken = encryptedAccessToken
    ? AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8)
    : null;
  const location=useLocation();
  const { id } = location.state;
  const { account_record } = location.state;

  // date
  const [contractshipID, setContractshipID]=useState({})
  console.log(contractshipID)
  const [startDate, setStartDate] = useState(new Date());
  const [contractStartdate, setContractStartdate] = useState(new Date());
  const [check,setCheck1]=useState(false)
  const [priceBook, setPriceBook]=useState([]);
  useEffect(() => {

    fetchData2();
  }, [decryptedAccessToken]);

  const fetchData2 = async () => {
    try {
      const response = await axios.get(`${API_URL}get/all/pricebook`, {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      const data = response.data.pricebook;
      // console.log(data)
      setPriceBook(data);

    } catch (error) {
      console.error(error);
    }
  };

  const renewalDate =
    startDate.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    startDate.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    startDate.toLocaleDateString("en-US", { day: "2-digit" });
  const ExampleCustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="example-custom-input"
      onClick={onClick}
      onChange={onChange}
      name="renewal_date"
      ref={ref}
    ></input>
  ));
  const [effective, setEffective] = useState(new Date());
  const contractEffective =
  effective.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    effective.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    effective.toLocaleDateString("en-US", { day: "2-digit" });
  const ExampleCustomInput1 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="example-custom-input"
      onClick={onClick}
      onChange={onChange}
      name="contract_effective_date"
      ref={ref}
    ></input>
  ));
  const [start, setStart] = useState(new Date());
  const startDates =
  start.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    start.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    start.toLocaleDateString("en-US", { day: "2-digit" });
  const ExampleCustomInput2 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="example-custom-input"
      onClick={onClick}
      onChange={onChange}
      name="contract_start_date"
      ref={ref}
    ></input>
  ));

  const [signed, setSigned] = useState(new Date());
  const startDates1 =
  signed.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    signed.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    signed.toLocaleDateString("en-US", { day: "2-digit" });
  const ExampleCustomInput4 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="example-custom-input"
      onClick={onClick}
      onChange={onChange}
      name=""
      ref={ref}
    ></input>
  ));

  const [received, setReceived] = useState(new Date());
  const startDates2 =
  received.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    received.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    received.toLocaleDateString("en-US", { day: "2-digit" });
  const ExampleCustomInput5 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="example-custom-input"
      onClick={onClick}
      onChange={onChange}
      name=""
      ref={ref}
    ></input>
  ));




  const [customer, setCustomer] = useState(new Date());
  const startDates3 =
  customer.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    customer.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    customer.toLocaleDateString("en-US", { day: "2-digit" });
  const ExampleCustomInput6 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="example-custom-input"
      onClick={onClick}
      onChange={onChange}
      name=""
      ref={ref}
    ></input>
  ));

  const [sent, setSent] = useState(new Date());
  const startDates4 =
  sent.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    sent.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    sent.toLocaleDateString("en-US", { day: "2-digit" });
  const ExampleCustomInput7 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="example-custom-input"
      onClick={onClick}
      onChange={onChange}
      name=""
      ref={ref}
    ></input>
  ));

  const body = $(window).height();
  const header = $("#header").height();
  const nav = $(".navbar").height();
  const footer = $(".footer").height();
  const total_height = header + nav + footer;
  const window_height = body - total_height;
  // console.log(total_height);
  // console.log(body);
  const [getPrice, setgetPrice] = useState([]);
  const [contacts, setContacts] = useState([]);
  useEffect(() => {
    fetchDataPrice();
  }, [decryptedAccessToken]);

  const fetchDataPrice = async () => {
    try {
      const response = await axios.get(
        `${API_URL}get/all/price_items`,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data.priceitem;
      // console.log(data)
      setgetPrice(data);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchDataContacts();
  }, [decryptedAccessToken]);

  const fetchDataContacts = async () => {
    try {
      const response = await axios.get(
        `${API_URL}allcontacts`,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data.contact;
      // console.log(data)
      setContacts(data);
    } catch (error) {
      console.error(error);
    }
  };

  $(".contactAccount").css({ "min-height": window_height + "px" });
  const [accountData, setAccountData] = useState([]);
  const [companyData, setcompanyData] = useState([]);
  const [traderData, setTraderData] = useState([]);
  const [supplierData, setsupplierData] = useState([]);
  const [surveyors, setSurveyor] = useState([]);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    fetchDatausers();
  }, [decryptedAccessToken]);

  const fetchDatausers = async () => {
    try {
      const response = await axios.get(
        `${API_URL}get/all/users`,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data;
      // console.log(data);
      setUsers(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDataSupplier();
  }, [decryptedAccessToken]);

  const fetchDataSupplier = async () => {
    try {
      const response = await axios.get(
        `${API_URL}get/supplier`,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data;
      // console.log(data)
      setsupplierData(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDataTrader();
  }, [decryptedAccessToken]);

  const fetchDataTrader = async () => {
    try {
      const response = await axios.get(
        `${API_URL}get/all/rwoodusers`,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data.rwoodusers;      // console.log(data)
      setTraderData(data);
    } catch (error) {
      console.error(error);
    }
  };
  const [datas, setDatas] = useState([]);

  useEffect(() => {
    fetchDataaccount();
  }, [decryptedAccessToken]);

  const fetchDataaccount = async () => {
    try {
      const response = await axios.get(
        `${API_URL}all/accounts`,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data.accounts;
      // console.log(data);
      setDatas(data);
      $(document).ready(function () {
        setTimeout(() => {
          $("#tables").DataTable();
        }, 5);
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDataCompany();
  }, [decryptedAccessToken]);

  const fetchDataCompany = async () => {
    try {
      const response = await axios.get(
        `${API_URL}get/all/rwoodcompany`,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data.rwoodcompany;
      // console.log(data)
      setcompanyData(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [decryptedAccessToken]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${API_URL}all/accounts`,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data.accounts;
      // console.log(data)

      setAccountData(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData6();
  }, [decryptedAccessToken]);

  const fetchData6 = async () => {
    try {
      const response = await axios.get(
        `${API_URL}get/surveyor`,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data;
      // console.log(JSON.stringify(data))
      setSurveyor(data);
    } catch (error) {
      console.error(error);
    }
  };

  console.log(id)
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();


  // if (account_record === "Buyer - Long Term") {
    const onSubmit = (data) => {
      console.log(id)
      const responseData = {
        // locked: 'true',
        company: data.company,
        currency: data.currency,
        trader_name: data.trader_name,
        exchange_rate: data.exchange_rate,
        contract_record_type: data.contract_record_type,
        price_book: data.price_book,
        contract_owner: data.contract_owner,
        Rwood_Contract_No: data.Rwood_Contract_No,

        contract_effective_date:'2024-03-22',
        account_name: data.account_name,
        // contract_status: data.contract_status,
        pending_matters: data.pending_matters,
        contract_term_in_months: data.contract_term_in_months,
        remark: data.remark,
        supplier: data.supplier,
        master_conference_reference: data.master_conference_reference,
        contract_start_date: '2024-03-22',
        renewal_date: '2024-03-22',
        renewal_contract_number: data.renewal_contract_number,
        origin: data.origin,
        commodity: data.commodity,
        interlab_tolerance: data.interlab_tolerance,
        contract_quantity_in_MT: data.contract_quantity_in_MT,
        destination: data.destination,
        pricing_terms: data.pricing_terms,
        // price_adjust_clause: data.price_adjust_clause,
        work_days_for_time_to_copy_doc_to_buyer:80,
        price_PMT:90,
        price_basis_GAR :100,
        bonus_penalty_clause: data.bonus_penalty_clause,
        payment_terms: data.payment_terms,
        general_terms: data.general_terms,
        hs_code: data.hs_code,
        rejections: data.rejections,
        loading_rate_per_day: data.loading_rate_per_day,
        lc_type: data.lc_type,
        master_lc_presentation_days: data.master_lc_presentation_days,
        payment_details: data.payment_details,
        master_lc_tolerance: data.master_lc_tolerance,
        lc_validity_no_of_days: data.lc_validity_no_of_days,
        lc_opening_days: data.lc_opening_days,
        lc_opening_days_in_words: data.lc_opening_days_in_words,
        list_of_documents_required: data.list_of_documents_required,
        kind_attention: data.kind_attention,
        customer_signed_by: data.customer_signed_by,
        customer_signed_title: data.customer_signed_title,
        company_signed_by: data.company_signed_by,
        company_signed_date: '2024-03-22',
        original_contract_recieved_date:'2024-03-22',
        original_contract_sent_date: '2024-03-22',
        applicable_law: data.applicable_law,
        customer_signed_date:'2024-03-22',
        arbitration: data.arbitration,
        rwood_surveryor_agent: data.rwood_surveryor_agent,
        surveyor_2: data.surveyor_2,
        surveyor_3: data.surveyor_3,
        status:data.status,
        quote_shipment:6,
        description: data.description,
        lc_presentation_period: data.lc_presentation_period,
        product_description: data.product_description,
        created_at:'2024-03-22',
        updated_at:'2024-03-22'
      };
      console.log(JSON.stringify(responseData));
      const encryptedAccessToken = localStorage.getItem("access_token");
      const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
      if (encryptedAccessToken) {
        const decryptedAccessToken = AES.decrypt(
          encryptedAccessToken,
          secret_key
        ).toString(enc.Utf8);

        fetch(`${API_URL}add/quotes/contract/`+id, {
          method: "POST",
          body: JSON.stringify(responseData),
          headers: {
            Authorization: `Bearer ${decryptedAccessToken}`,
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())

          .then((response) => {
            console.log(response)
            // setContractshipID(response.contract_id)
            // console.log(response)
            const contract_id = response.contract_id
            const new_contract_id = parseInt(contract_id, 10)
            alert(new_contract_id,"****************",id)
            if(response.message==='contract added successfuly'){
              // if(response.message==='contract added successfuly' &&  new_contract_id != null ){

              fetch(`${API_URL}add/contract_ship/`+id+"/"+new_contract_id
              , {
          method: "POST",
          // body: JSON.stringify(responseData),
          headers: {
            Authorization: `Bearer ${decryptedAccessToken}`,
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())

          .then((response) => {
            console.log(response)
            // if(response.message==="contract added successfuly"){
              fetch(`${API_URL}add/contract_products/222/78/`+"Buyer-Spot"
              , {
          method: "POST",
          headers: {
            Authorization: `Bearer ${decryptedAccessToken}`,
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())
          .then((response) => {
            console.log(response)
          })

            toast.success("Contract Shipment added successfully", {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,

              draggable: true,
              progress: undefined,
              theme: "light",
            });
            setTimeout(() => {
              reset();
            }, 300);
          // }
            })

            }
            else if(response.error==='Please add Quote Product to proceed further'){
              toast.success("Please add Quote Product to proceed further", {
                position: "bottom-center",
                autoClose: 2000,
                hideProgressBar: false,

                draggable: true,
                progress: undefined,
                theme: "light",
              });
              setTimeout(() => {
                reset();
              }, 300);
            }
            // /add/contract_products/<quotes_id>/<contract_id>/<record_type>

          });
      }
    };

    return (
      <>
        <Header />
        <div className="contactAccount contracts">
          <div className="page-header" id="add-account">
            <div>
              <h2 className="main-content-title tx-24 mg-b-5">
                Buyer Long Term Contract{" "}
              </h2>
              <ol className="breadcrumb">
                <li className="breadcrumb-item active" aria-current="page">
                  Dashboard / Contract / Buyer Long Term
                </li>
              </ol>
            </div>
            <div className="d-flex">
              <div className="justify-content-center">
                {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
          <Link to='/accounts/add-record'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</Link>
        </button> */}
              </div>
            </div>
          </div>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className="mb-3">
              <h4 className="heading">Contract Information</h4>
              {/* <Form.Group as={Col} md="6" id='lockeds'>
            <input type='checkbox' name='locked' onChange={(e) => setCheck1(e.target.checked) } /><label>Locked</label>
          </Form.Group>  */}
           <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Company"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="company"
                    placeholder="Company"

                    {...register("company", { required: true })}
                  >
                    <option value="">Select </option>
                    {companyData.map((x, i) => {
                      return (
                        <option key={i} value={x.company_name}>
                          {x.company_name}
                        </option>
                      );
                    })}
                  </Form.Select>
                  {errors.company && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Currency"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="currency"
                    placeholder="Currency"
                    className="error-validation"
                    {...register("currency", { required: true })}
                  >
                    <option value="">Select </option>
                    <option value="USD">USD </option>
                    <option value="IDR">IDR </option>
                  </Form.Select>
                  {errors.currency && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Trader Name"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="trader_name"
                    placeholder="Trader Name"
                    className="error-validation"
                    {...register("trader_name", { required: true })}
                  >
                    <option value="">Select </option>
                    {traderData.map((x, i) => {
                      return (
                        <option key={i} value={x.rwood_username}>
                          {x.rwood_username}
                        </option>
                      );
                    })}
                  </Form.Select>
                  {errors.trader_name && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Account Name"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="account_name"
                    placeholder="Account Name"
                    className="error-validation"
                    {...register("account_name", { required: true })}
                  >
                    <option value="">Select </option>
                    {supplierData.map((x) => {
                      return (
                        <option value={x.account_name}>{x.account_name}</option>
                      );
                    })}
                  </Form.Select>
                  {errors.account_name && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Exchange rate"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Exchange rate "
                    name="exchange_rate"
                    {...register("exchange_rate", {
                    valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.exchange_rate && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Contract record type"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Contract record type"
                    defaultValue={account_record}
                    name="contract_record_type" className='record-type'
                    {...register("contract_record_type", { required: true })}
                  />
                  {errors.contract_record_type && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Contract Owner"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Contract Owner"
                    name="contract_owner"
                    {...register("contract_owner", {

                      required: false,
                    })}
                  />
                  {errors.contract_owner && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Price book">
    <Form.Select aria-label="Floating label select example"
    name="price_book"
    placeholder="Price book"

    {...register("price_book", { required: false })}
    >
      <option value='' >Select</option>
       {
          priceBook.map((x)=>{
              return(
                  <option value={x.price_book_name}>{x.price_book_name}</option>
              )
          })
       }
    </Form.Select>
    {errors.price_book && <span>Required</span>}
  </FloatingLabel>
              </Form.Group>


              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Rwood contract no"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Rwood contract no"
                    name="Rwood_Contract_No"
                    className="error-validation"
                    {...register("Rwood_Contract_No", { required: true })}
                  />
                  {errors.Rwood_Contract_No && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              {/* <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Status"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="status"
                    placeholder="Status"

                    {...register("status", { required: true })}
                  >
                    <option value="">Select </option>
                    <option value="Draft">Draft </option>
                    <option value="Approved">Approved </option>
                  </Form.Select>
                  {errors.status && <span>Required</span>}
                </FloatingLabel>
              </Form.Group> */}
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Contract Status"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="status"
                    placeholder="Contract Status"
                    className="error-validation"

                    {...register("status", { required: true })}
                  >
                    <option value="">Select </option>
                    <option value="Draft">Draft </option>
                    <option value="Approved">Approved </option>
                  </Form.Select>
                  {errors.status && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              {/* <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Contract Status"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="contract_status"
                    placeholder="Contract Status"

                    {...register("contract_status", { required: true })}
                  >
                    <option value="">Select </option>
                    <option value="Draft">Draft </option>
                    <option value="Approved">Approved </option>
                  </Form.Select>
                  {errors.contract_status && <span>Required</span>}
                </FloatingLabel>
              </Form.Group> */}
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Contract term in months"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Contract term in months"
                    name="contract_term_in_months"
                    {...register("contract_term_in_months", {
                      required: false,
                    })}
                  />
                  {errors.contract_term_in_months && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Pending Matters"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Pending Matters"
                    style={{ height: "100px!important" }}
                    name="pending_matters"
                    {...register("pending_matters", { required: false })}
                  />
                  {errors.pending_matters && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel controlId="floatingTextarea2" label="Remark">
                  <Form.Control
                    as="textarea"
                    placeholder="Remark"
                    style={{ height: "100px!important" }}
                    name="remark"
                    {...register("remark", { required: false })}
                  />
                  {errors.remark && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Contract Start Date"
                  className="mb-3 date-picker"
                >
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    customInput={<ExampleCustomInput />}
                    dayClassName={() => "example-datepicker-day-class"}
                    popperClassName="example-datepicker-class"
                    todayButton="TODAY"
                    dateFormat="yyyy-MM-dd"
                    placeholderText="Choose Date"
                  />
                  {errors.contract_start_date && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Contract Effective Date"
                  className="mb-3 date-picker"
                >
                  <DatePicker
                    selected={start}
                    onChange={(date) => setStart(date)}
                    customInput={<ExampleCustomInput2 />}
                    dayClassName={() => "example-datepicker-day-class"}
                    popperClassName="example-datepicker-class"
                    todayButton="TODAY"
                    dateFormat="yyyy-MM-dd"
                    placeholderText="Choose Date"
                  />
                  {errors.contract_effective_date && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Master conference reference"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Master conference reference"
                    name="master_conference_reference"
                    {...register("master_conference_reference", {
                      required: false,
                    })}
                  />
                  {errors.master_conference_reference && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Renewal date"
                  className="mb-3 date-picker"
                >
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    customInput={<ExampleCustomInput />}
                    dayClassName={() => "example-datepicker-day-class"}
                    popperClassName="example-datepicker-class"
                    todayButton="TODAY"
                    dateFormat="yyyy-MM-dd"
                    placeholderText="Choose Date"
                  />
                  {errors.renewal_date && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Renewal contract number"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Renewal contract number "
                    name="renewal_contract_number "
                    {...register("renewal_contract_number ", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.renewal_contract_number && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Commodity and Contracted Quantity</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Origin"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Origin"
                    name="origin"
                    {...register("origin", { required: false })}
                  />
                  {errors.origin && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">

            <FloatingLabel controlId="floatingSelect"
            className='dropDown' label="Commodity">
  <Form.Select aria-label="Floating label select example"
  name="commodity"
  placeholder="Commodity"

  {...register("commodity", { required: false })}
  >
    <option>---None---</option>
    <option value="Indonesian Steam Coal in Bulk (&quot;Coal&quot;)">Indonesian Steam Coal in Bulk ("Coal")</option><option value="US Coal">US Coal</option><option value="Russia Coal">Russia Coal</option><option value="SA Coal">SA Coal</option><option value="Clinker in Bulk">Clinker in Bulk</option><option value="Australian Steam Coal">Australian Steam Coal</option>
  </Form.Select>
  {errors.commodity && <span>Required </span>}
</FloatingLabel>
            </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Interlab tolerance"
                  className="mb-3"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Interlab tolerance"
                    name="interlab_tolerance"
                    className="textarea"
                    style={{ height: "100px!important" }}
                    {...register("interlab_tolerance", { required: false })}
                  />
                  {errors.interlab_tolerance && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Contract quantity in MT"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Contract quantity in MT"
                    name="contract_quantity_in_MT"
                    className="error-validation"
                    {...register("contract_quantity_in_MT", {
                      valueAsNumber: true,
                      required: true,
                    })}
                  />
                  {errors.contract_quantity_in_MT && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Market Information</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Destination"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Destination"
                    name="destination"
                    {...register("destination", { required: false })}
                  />
                  {errors.destination && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="12">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Pricing terms"
                  className="mb-3"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Pricing terms"
                    name="pricing_terms"
                    style={{ height: "100px!important" }}
                    {...register("pricing_terms", { required: false })}
                  />
                  {errors.pricing_terms && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Price and Price Adjustment</h4>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Price  Adjustment Clause"
                  className="mb-3"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Price  Adjustment Clause"
                    name="price_adjustment_clause"
                    style={{ height: "100px!important" }}
                    {...register("price_adjustment_clause", { required: false })}
                  />
                  {errors.price_adjustment_clause && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Bonus penalty clause"
                  className="mb-3"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Bonus penalty clause"
                    name="bonus_penalty_clause"
                    style={{ height: "100px!important" }}
                    {...register("bonus_penalty_clause", { required: false })}
                  />
                  {errors.bonus_penalty_clause && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Payment terms"
                  className="mb-3"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Payment terms"
                    name="payment_terms"
                    {...register("payment_terms", { required: false })}
                  />
                  {errors.payment_terms && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="General terms"
                  className="mb-3"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="General terms"
                    name="general_terms"
                    {...register("general_terms", { required: false })}
                  />
                  {errors.general_terms && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Rejections"
                  className="mb-3"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Rejections"
                    name="rejections"
                    {...register("rejections", { required: false })}
                  />
                  {errors.rejections && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Hs code"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Hs code"
                    name="hs_code"
                    {...register("hs_code", { required: false })}
                  />
                  {errors.hs_code && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Payment Information</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="LC Type"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="lc_type"
                    placeholder="LC Type"

                    {...register("lc_type", { required: false })}
                  >
                    <option value="">Select</option>
                    <option value="">--None--</option><option value="Irrevocable transferrable Letter of Credit">Irrevocable transferrable Letter of Credit</option><option value="Irrevocable Non transferrable Letter of Credit">Irrevocable Non transferrable Letter of Credit</option><option value="Irrevocable Letter of Credit">Irrevocable Letter of Credit</option><option value="Irrevocable Back to Back LC">Irrevocable Back to Back LC</option><option value="Payment and Receipt">Payment and Receipt</option><option value="Standby_letter_of_credit_c">Standby Letter of Credit</option>
                  </Form.Select>
                  {errors.lc_type && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Master lc presentation days"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Master lc presentation days"
                    name="master_lc_presentation_days"
                    {...register("master_lc_presentation_days", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.master_lc_presentation_days && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Lc opening days"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Lc opening days"
                    name="lc_opening_days"
                    {...register("lc_opening_days", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.lc_opening_days && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Master lc tolerance"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Master lc tolerance"
                    name="master_lc_tolerance"
                    {...register("master_lc_tolerance", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.master_lc_tolerance && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Lc opening days in words"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Lc opening days in words"
                    name="lc_opening_days_in_words"
                    {...register("lc_opening_days_in_words", {
                      required: false,
                    })}
                  />
                  {errors.lc_opening_days_in_words && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Lc validity no of days"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Lc validity no of days"
                    name="lc_validity_no_of_days"
                    {...register("lc_validity_no_of_days", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.lc_validity_no_of_days && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Document presentation period"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Document presentation period"
                    name="document_presentation_period"
                    {...register("document_presentation_period", {
                      required: false,
                    })}
                  />
                  {errors.document_presentation_period && (
                    <span>Required </span>
                  )}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Latest date of shipment"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Latest date of shipment"
                    name="latest_date_of_shipment"
                    {...register("latest_date_of_shipment", {
                      required: false,
                    })}
                  />
                  {errors.latest_date_of_shipment && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Loading rate per day"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Loading rate per day"
                    name="loading_rate_per_day"
                    {...register("loading_rate_per_day", {
                      valueAsNumber: true,
                      required: false,
                    })}
                  />
                  {errors.loading_rate_per_day && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Important Terms Information</h4>
              <Form.Group as={Col} md="12">
                <FloatingLabel
                  controlId="floatingInput"
                  label="List of documents required"
                  className="mb-3"
                >
                  <Form.Control
                    as="textarea"
                    style={{ height: "200px!important" }}
                    placeholder="List of documents required"
                    name="list_of_documents_required"
                    {...register("list_of_documents_required", {
                      required: false,
                    })}
                  />
                  {errors.list_of_documents_required && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Signature Information</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Kind attention"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="kind_attention"
                    placeholder="Kind attention"

                    {...register("kind_attention", { required: false })}
                  >
                    <option value="">Select </option>
                    {contacts.map((x) => {
                      return (
                        <option value={x.contact_full_name}>
                          {x.contact_full_name}
                        </option>
                      );
                    })}
                  </Form.Select>
                  {errors.kind_attention && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Customer signed by"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="customer_signed_by"
                    placeholder="Customer signed by"

                    {...register("customer_signed_by", { required: false })}
                  >
                    <option value="">Select </option>
                    {contacts.map((x) => {
                      return (
                        <option value={x.contact_full_name}>
                          {x.contact_full_name}
                        </option>
                      );
                    })}
                  </Form.Select>
                  {errors.customer_signed_by && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Customer signed title"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Customer signed title"
                    name="customer_signed_title"
                    {...register("customer_signed_title", { required: false })}
                  />
                  {errors.customer_signed_title && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Company signed by"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="company_signed_by"
                    placeholder="Company signed by"

                    {...register("company_signed_by", { required: false })}
                  >
                    <option value="">Select </option>
                    {users.map((x) => {
                      return <option value={x.username}>{x.username}</option>;
                    })}
                  </Form.Select>
                  {errors.username && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Original Contract Recieved Date"
                  className="mb-3 date-picker"
                >
                  <DatePicker
                    selected={received}
                    onChange={(date) => setReceived(date)}
                    customInput={<ExampleCustomInput5 />}
                    dayClassName={() => "example-datepicker-day-class"}
                    popperClassName="example-datepicker-class"
                    todayButton="TODAY"
                    dateFormat="yyyy-MM-dd"
                    placeholderText="Choose Date"
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Company Signed Date"
                  className="mb-3 date-picker"
                >
                  <DatePicker
                    selected={signed}
                    onChange={(date) => setSigned(date)}
                    customInput={<ExampleCustomInput4 />}
                    dayClassName={() => "example-datepicker-day-class"}
                    popperClassName="example-datepicker-class"
                    todayButton="TODAY"
                    dateFormat="yyyy-MM-dd"
                    placeholderText="Choose Date"
                  />
                  {errors.company_signed_date && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Original contract sent date"
                  className="mb-3 date-picker"
                >
                  <DatePicker
                    selected={sent}
                    onChange={(date) => setSent(date)}
                    customInput={<ExampleCustomInput7 />}
                    dayClassName={() => "example-datepicker-day-class"}
                    popperClassName="example-datepicker-class"
                    todayButton="TODAY"
                    dateFormat="yyyy-MM-dd"
                    placeholderText="Choose Date"
                  />
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Other Standard Terms</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Applicable Law"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="applicable_law"
                    placeholder="Applicable Law"

                    {...register("applicable_law", { required: false })}
                  >
                   <option value="">--None--</option><option value="English Law">English Law</option><option value="Singapore Law">Singapore Law</option><option value="Indonesian Law">Indonesian Law</option>
                  </Form.Select>
                  {errors.applicable_law && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Rwood surveryor agent"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="rwood_surveryor_agent"
                    placeholder="Rwood surveryor agent"
                    {...register("rwood_surveryor_agent", { required: false })}
                  >
                    <option value="">Select </option>
                    {surveyors.map((x) => {
                      return (
                        <option value={x.account_name}>{x.account_name}</option>
                      );
                    })}
                  </Form.Select>
                  {errors.rwood_surveryor_agent && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Arbitration"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Arbitration"
                    name="arbitration"
                    {...register("arbitration", { required: false })}
                  />
                  {errors.arbitration && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Surveyor 1"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="surveyor_1"
                    placeholder="Surveyor 1"

                    {...register("surveyor_1", { required: false })}
                  >
                    <option value="">Select </option>
                    {surveyors.map((x) => {
                      return (
                        <option value={x.account_name}>{x.account_name}</option>
                      );
                    })}
                  </Form.Select>
                  {errors.surveyor_1 && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Surveyor 2"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="surveyor_2"
                    placeholder="Surveyor 2"

                    {...register("surveyor_2", { required: false })}
                  >
                    <option value="">Select </option>
                    {surveyors.map((x) => {
                      return (
                        <option value={x.account_name}>{x.account_name}</option>
                      );
                    })}
                  </Form.Select>
                  {errors.surveyor_2 && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <h4 className="heading">List Of Fields-Not Required</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="LC Presentation Period"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="LC Presentation Period"
                    style={{ height: "100px" }}
                    name="lc_presentation_period"
                    {...register("lc_presentation_period", { required: false })}
                  />
                  {errors.lc_presentation_period && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Description"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Description"
                    style={{ height: "100px" }}
                    name="description"
                    {...register("description", { required: false })}
                  />
                  {errors.description && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Product Description"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Product Description"
                    style={{ height: "100px" }}
                    name="product_description"
                    {...register("product_description", { required: false })}
                  />
                  {errors.product_description && <p id="text-area">Required</p>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <input type="submit" className="addaccount-save" value="Save" />
            <div className="successDiv">
              <p></p><p></p>
            </div>
          </Form>
        </div>
        <Footer />
      </>
    );
  }

  //  }

export default CreateContract;
