import React, { Component, useState, useEffect, useContext, forwardRef } from "react";
import { Card, OverlayTrigger, Tooltip, Table } from "react-bootstrap";
import "datatables.net-dt/js/dataTables.dataTables.js";
import { AES, enc } from "crypto-js";
import "datatables.net-responsive-dt";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "react-data-table-component-extensions/dist/index.css";
import { Link, useNavigate } from "react-router-dom";
import { TokenContext } from "../AuthProvider";
import Header from "../Header";
import Footer from "../Footer";
import $ from "jquery";
import { API_URL } from '../config';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Popup from "reactjs-popup";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { parse, format , isValid } from 'date-fns';
import Col from "react-bootstrap/Col";
import RecentItem from "../recentitem/rececentItem";
import {
  FaEnvelope,
  FaPhoneSquareAlt,
  FaMobileAlt,
  FaFax,
} from "react-icons/fa";
import axios from "axios";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { FaPencilAlt, FaTrashAlt, FaPlus } from "react-icons/fa";
// import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
import { useParams } from "react-router-dom";
const ViewFinanace = () => {

  const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
  const encryptedAccessToken = localStorage.getItem("access_token");
  const decryptedAccessToken = encryptedAccessToken
    ? AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8)
    : null;

  const [data, setData] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [editedData, setEditedData] = useState([]);
  const handleEditClick = () => {
    setIsEditMode(true);
    setEditedData(data);
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setDatas((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };


 const handleCancelEdit = () => {
    setIsEditMode(false);
    setEditedData(data);

  };



  const navigate = useNavigate();
  const [formData, setFromdata] = useState({
    account_record: "",
  });

  const handleChange = (e) => {
    setFromdata({ ...formData, [e.target.name]: e.target.value });
  };


  const params = useParams();
  const id = params.id;
  const userRole = localStorage.getItem("role");
  console.log(userRole);
  const { username, token } = useContext(TokenContext);
  const [datas, setDatas] = useState([]);
  const [lenthData, setlenthData] = useState([]);
  const [historys, setHistory] = useState([]);
  const [opp, setOpp] = useState([]);
  const [bdate, setbirthDate]=useState('')
  const [accountNamedrop, setAccountnameDropdown]=useState('')

  useEffect(() => {
    fetchData();
  }, [decryptedAccessToken], lenthData);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${API_URL}get/financeconfig_detail/` + id,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      setDatas(response.data.finance_detail[0]);

    } catch (error) {
      console.error(error);
    }
  };



  const handleSaveEdit = async (date) => {


    const responseData={
      "owner":datas.owner,
"billing_config_name":datas.billing_config_name,
"billing_doc_record_type":datas.billing_doc_record_type,
"line_item":datas.line_item,
"lineitem_type":datas.lineitem_type,
"order_object_field_name":datas.order_object_field_name,
"debit_credit":datas.debit_credit,
"calculation_type":datas.calculation_type,
"scale_base_type":datas.scale_base_type,
"payable_dr_acc_group":datas.payable_dr_acc_group,
"payable_dr_chart_account_no":datas.payable_dr_chart_account_no,
"payable_cr_chart_account_no":datas.payable_cr_chart_account_no,
"payable_dr_gl_type":datas.payable_dr_gl_type,
"payable_dr_field_name ":datas.payable_dr_field_name, 
"payable_cr_gl_type":datas.payable_cr_gl_type,
"payable_cr_field_name":datas.payable_cr_field_name,
"payment_dr_chart_account_no":datas.payment_dr_chart_account_no,
"payment_cr_chart_account_no":datas.payment_cr_chart_account_no,

    };
    console.log(JSON.stringify(responseData))

    fetch(`${API_URL}update/financeconfig/${id}`, {
      method: 'PUT',
      body: JSON.stringify(responseData),
      headers: { Authorization: `Bearer ${decryptedAccessToken}`,
              'Content-Type': 'application/json' },

    })
      .then((response) => response.json())
      .then((updatedData) => {
        console.log(updatedData)
        fetchData();
        if(updatedData.msg==='contact updated successfully'){

          // fetchData();
      }

        setData(updatedData);
        setIsEditMode(false);
      })
      .catch((error) => console.error(error));
    }

  return (
    <div id="view-page">
      <Header />
      <>
            {/* {lenthData.length > 0 ? ( */}
      <div className="contactAccount">
       
        <div className="row">

          <div className="col-md-2 recent-item">
            <RecentItem />
          </div>
          <div className="col-md-10 right-section">
          <div className="page-header btn-margin" id="add-account">
          <div>
            <h2 className="main-content-title tx-24 mg-b-5">
            Finance Details
            </h2>
            <ol className="breadcrumb">
              <li className="breadcrumb-item active" aria-current="page">
                Dashboard / Finance / Finance details
              </li>
            </ol>
          </div>
        </div>
        {isEditMode ? (

         <div className="tasks">

            <>

            <p className='edit-btn'>
     <button className='btn btn-primary' onClick={handleSaveEdit}>Save</button>
     <button className="btn btn-primary" onClick={handleCancelEdit}>Cancel</button>


     </p>
     <Row className="mb-3">

      <div className='col-md-12'>
      <h4 className='heading'>Information</h4>
        </div>


    <Form.Group
              as={Col}
              md="6"

            >
    <FloatingLabel
            controlId="floatingInput"
            label="billing config name"
            className="mb-3"
          >
            <Form.Control
              type="text"
              placeholder="billing config name"
              name="billing_config_name"
              tabIndex={1}
              defaultValue={datas.billing_config_name || ""}
     onChange={handleChangeInput}/>
          </FloatingLabel>

            </Form.Group>
            <Form.Group
              as={Col}
              md="6"

            >
    <FloatingLabel
            controlId="floatingInput"
            label="owner"
            className="mb-3"
          >
            <Form.Control
              type="text"
              placeholder="oWNER"
              name="owner"
              tabIndex={4}
              defaultValue={datas.owner || ""}
     onChange={handleChangeInput}/>
          </FloatingLabel>

            </Form.Group>


        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Billing Doc Record Type"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Billing Doc Record Type"
            tabIndex={2}
            name="billing_doc_record_type"
            defaultValue={datas.billing_doc_record_type || ""}
     onChange={handleChangeInput}/>
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6"></Form.Group>


            <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Line Item"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Line Item"
            name="line_item"
            tabIndex={3}
             defaultValue={datas.line_item || ""}
     onChange={handleChangeInput}/>
          </FloatingLabel>
        </Form.Group>


        <Form.Group
              as={Col}
              md="6"
              id=''
            >
              <FloatingLabel controlId="floatingSelect" className='dropDown' label="line item type">
      <Form.Select aria-label="Floating label select example"
      name="lineitem_type"
      placeholder="line item type"
      tabIndex={5}
      defaultValue={datas.lineitem_type || ""}
      onChange={handleChangeInput}
      >
       <option value="">--None--</option><option value="Line Item Level">Line Item Level</option><option value="Header Level">Header Level</option>
      </Form.Select>
    </FloatingLabel>

    </Form.Group>
     </Row>

     <Row className="mb-3">

<div className='col-md-12'>
<h4 className='heading'>Information</h4>
  </div>

  <Form.Group
              as={Col}
              md="6"
              id=''
            >
              <FloatingLabel controlId="floatingSelect" className='dropDown' label="debit credit">
      <Form.Select aria-label="Floating label select example"
      name="debit_credit"
      placeholder="debit credit"
      tabIndex={6}
      defaultValue={datas.debit_credit || ""}
      onChange={handleChangeInput}
      >
      <option value="">--None--</option><option value="Credit">Credit</option><option value="Debit">Debit</option>
      </Form.Select>
    </FloatingLabel>

    </Form.Group>

                  <Form.Group
                    as={Col}
                    md="6"
                    id=''
                  >
                    <FloatingLabel controlId="floatingSelect" className='dropDown' label="Order Object Field Name	">
                      <Form.Select aria-label="Floating label select example"
                        name="order_object_field_name"
                        placeholder="Order Object Field Name	"
                        tabIndex={9}
                        defaultValue={datas.order_object_field_name || ""}
                        onChange={handleChangeInput}
                      >
                        <option value="">--None--</option><option value="Buyer_Final_Dem_Despatch_Payable__c">Buyer Final Dem/Despatch Payable</option><option value="Buyer_Final_Other_Payable__c">Buyer Final Other Payable</option><option value="Supplier_Final_Dem_Despatch_Payable__c">Supplier Final Dem/Despatch Payable</option><option value="Supplier_Final_Other_Payable__c">Supplier Final Other Payable</option><option value="Sales_Invoice__c">Sales Invoice</option><option value="Purchase_Invoice__c">Purchase Invoice</option><option value="Surveyor_Invoice__c">Surveyor Invoice</option><option value="Load_Port_Invoice__c">Load Port Invoice</option>
                      </Form.Select>
                    </FloatingLabel>

                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md="6"
                    id=''
                  >
                    <FloatingLabel controlId="floatingSelect" className='dropDown' label="Calculation Type	">
                      <Form.Select aria-label="Floating label select example"
                        name="calculation_type"
                        placeholder="Calculation Type	"
                        tabIndex={7}
                        defaultValue={datas.calculation_type || ""}
                        onChange={handleChangeInput}
                      >
                        <option value="">--None--</option><option value="Buyer_Final_Dem_Despatch_Payable__c">Buyer Final Dem/Despatch Payable</option><option value="Buyer_Final_Other_Payable__c">Buyer Final Other Payable</option><option value="Supplier_Final_Dem_Despatch_Payable__c">Supplier Final Dem/Despatch Payable</option><option value="Supplier_Final_Other_Payable__c">Supplier Final Other Payable</option><option value="Sales_Invoice__c">Sales Invoice</option><option value="Purchase_Invoice__c">Purchase Invoice</option><option value="Surveyor_Invoice__c">Surveyor Invoice</option><option value="Load_Port_Invoice__c">Load Port Invoice</option>
                      </Form.Select>
                    </FloatingLabel>

                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="6"
                    id=''
                  > </Form.Group>

                  <Form.Group
                    as={Col}
                    md="6"
                    id=''
                  >
                    <FloatingLabel controlId="floatingSelect" className='dropDown' label="Scale Base Type	">
                      <Form.Select aria-label="Floating label select example"
                        name="scale_base_type"
                        placeholder="Scale Base Type	"
                        tabIndex={8}
                        defaultValue={datas.scale_base_type || ""}
                        onChange={handleChangeInput}
                      >
                       <option value="">--None--</option><option value="Value Scale">Value Scale</option><option value="Qty Scale">Qty Scale</option>
                      </Form.Select>
                    </FloatingLabel>

                  </Form.Group>
  </Row>
  <Row className="mb-3">

<div className='col-md-12'>
<h4 className='heading'>Payable / Receivable Config
</h4>
  </div>
  <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Payable DR Chart of Account No	"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Payable DR Chart of Account No	"
            name="payable_dr_chart_account_no"
            tabIndex={10}
            defaultValue={datas.payable_dr_chart_account_no || ""}
     onChange={handleChangeInput}/>
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Payable CR Chart of Account No	"
            className="mb-3"
          >

            <Form.Control type="text"
            placeholder="Payable CR Chart of Account No	"
            tabIndex={13}
            name="payable_cr_chart_account_no"
            defaultValue={datas.payable_cr_chart_account_no || ""}
     onChange={handleChangeInput}/>
          </FloatingLabel>
        </Form.Group>
        <Form.Group
              as={Col}
              md="6"
              id=''
            >
              <FloatingLabel controlId="floatingSelect" className='dropDown' label="Payable DR GL Type">
      <Form.Select aria-label="Floating label select example"
      name="payable_dr_gl_type"
      placeholder="Payable DR GL Type"
      tabIndex={11}
      defaultValue={datas.payable_dr_gl_type || ""}
      onChange={handleChangeInput}
      >
       <option value="">--None--</option><option value="Buyer">Buyer</option><option value="Supplier">Supplier</option><option value="Surveyor">Surveyor</option><option value="Agent">Agent</option><option value="Bank">Bank</option><option value="Account">Account</option>
      </Form.Select>
    </FloatingLabel>

    </Form.Group>
    <Form.Group
              as={Col}
              md="6"
              id=''
            >
              <FloatingLabel controlId="floatingSelect" className='dropDown' label="Payable C GL Type">
      <Form.Select aria-label="Floating label select example"
      name="payable_cr_gl_type"
      placeholder="Payable CR GL Type"
      tabIndex={14}
      defaultValue={datas.payable_cr_gl_type || ""}
      onChange={handleChangeInput}
      >
       <option value="">--None--</option><option value="Buyer">Buyer</option><option value="Supplier">Supplier</option><option value="Surveyor">Surveyor</option><option value="Agent">Agent</option><option value="Bank">Bank</option><option value="Account">Account</option>
      </Form.Select>
    </FloatingLabel>

    </Form.Group>

    <Form.Group
              as={Col}
              md="6"
              id=''
            >
              <FloatingLabel controlId="floatingSelect" className='dropDown' label="Payable DR Field Name">
      <Form.Select aria-label="Floating label select example"
      name="payable_dr_field_name"
      placeholder="Payable DR Field Name	"
      tabIndex={12}
      defaultValue={datas.payable_dr_field_name || ""}
      onChange={handleChangeInput}
      >
       <option value="">--None--</option><option value="Ledger_1__c">Ledger 1</option><option value="Ledger_2__c">Ledger 2</option><option value="Ledger_3__c">Ledger 3</option><option value="Ledger_4__c">Ledger 4</option>
      </Form.Select>
    </FloatingLabel>

    </Form.Group>

    <Form.Group
              as={Col}
              md="6"
              id=''
            >
              <FloatingLabel controlId="floatingSelect" className='dropDown' label="Payable CR Field Name">
      <Form.Select aria-label="Floating label select example"
      name="payable_cr_field_name"
      placeholder="Payable CR Field Name	"
      tabIndex={15}
      defaultValue={datas.payable_cr_field_name || ""}
      onChange={handleChangeInput}
      >
       <option value="">--None--</option><option value="Ledger_1__c">Ledger 1</option><option value="Ledger_2__c">Ledger 2</option><option value="Ledger_3__c">Ledger 3</option><option value="Ledger_4__c">Ledger 4</option>
      </Form.Select>
    </FloatingLabel>

    </Form.Group>
  </Row>

  <Row className="mb-3">

<div className='col-md-12'>
<h4 className='heading'>Payment / Receipt Config</h4>
<Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Payment DR Chart of Account No	"
            className="mb-3"
          >

            <Form.Control type="text"
            tabIndex={16}
            placeholder="Payment DR Chart of Account No		"
            name="payment_dr_chart_account_no"
            defaultValue={datas.payment_dr_chart_account_no || ""}
     onChange={handleChangeInput}/>
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Payment CR Chart of Account No	"
            className="mb-3"
          >

            <Form.Control type="text"
            tabIndex={17}
            placeholder="payment CR Chart of Account No		"
            name="payment_cr_chart_account_no"
            defaultValue={datas.payment_cr_chart_account_no || ""}
     onChange={handleChangeInput}/>
          </FloatingLabel>
        </Form.Group>
        <Form.Group
              as={Col}
              md="6"
              id=''
            >
              <FloatingLabel controlId="floatingSelect" className='dropDown' label="payment DR GL Type">
      <Form.Select aria-label="Floating label select example"
      name="payment_dr_gl_type"
      placeholder="payment DR GL Type"
      tabIndex={18}
      defaultValue={datas.payment_dr_gl_type || ""}
      onChange={handleChangeInput}
      >
       <option value="">--None--</option><option value="Buyer">Buyer</option><option value="Supplier">Supplier</option><option value="Surveyor">Surveyor</option><option value="Agent">Agent</option><option value="Bank">Bank</option><option value="Account">Account</option>
      </Form.Select>
    </FloatingLabel>

    </Form.Group>
    <Form.Group
              as={Col}
              md="6"
              id=''
            >
              <FloatingLabel controlId="floatingSelect" className='dropDown' label="Payable C GL Type">
      <Form.Select aria-label="Floating label select example"
      name="payable_cr_gl_type"
      placeholder="Payable CR GL Type"
      tabIndex={19}
      defaultValue={datas.payable_cr_gl_type || ""}
      onChange={handleChangeInput}
      >
       <option value="">--None--</option><option value="Buyer">Buyer</option><option value="Supplier">Supplier</option><option value="Surveyor">Surveyor</option><option value="Agent">Agent</option><option value="Bank">Bank</option><option value="Account">Account</option>
      </Form.Select>
    </FloatingLabel>

    </Form.Group>

    <Form.Group
              as={Col}
              md="6"
              id=''
            >
              <FloatingLabel controlId="floatingSelect" className='dropDown' label="payment DR Field Name">
      <Form.Select aria-label="Floating label select example"
      name="payment_dr_field_name"
      placeholder="payment DR Field Name	"
      tabIndex={20}
      defaultValue={datas.payment_dr_field_name || ""}
      onChange={handleChangeInput}
      >
       <option value="">--None--</option><option value="Ledger_1__c">Ledger 1</option><option value="Ledger_2__c">Ledger 2</option><option value="Ledger_3__c">Ledger 3</option><option value="Ledger_4__c">Ledger 4</option>
      </Form.Select>
    </FloatingLabel>

    </Form.Group>

    <Form.Group
              as={Col}
              md="6"
              id=''
            >
              <FloatingLabel controlId="floatingSelect" className='dropDown' label="payment CR Field Name">
      <Form.Select aria-label="Floating label select example"
      name="payment_cr_field_name"
      placeholder="payment CR Field Name	"
      tabIndex={21}
      defaultValue={datas.payment_cr_field_name || ""}
      onChange={handleChangeInput}
      >
       <option value="">--None--</option><option value="Ledger_1__c">Ledger 1</option><option value="Ledger_2__c">Ledger 2</option><option value="Ledger_3__c">Ledger 3</option><option value="Ledger_4__c">Ledger 4</option>
      </Form.Select>
    </FloatingLabel>

    </Form.Group>


  </div>
  </Row>
     <p className='edit-btn'>
     <button className='btn btn-primary' onClick={handleSaveEdit}>Save</button>
     <button className="btn btn-primary" onClick={handleCancelEdit}>Cancel</button>


     </p> </>

         </div>
        ):(
          <>




         <div className="tasks">
         <div className="edit-delete">
                  <span id="edit" onClick={handleEditClick}>
                    Edit
                  </span>
                  <span>
                  <Link to={'/finance/finance-list'}> Cancel</Link>
                  </span>

                  </div>

             <>
             <div className="">
               <div id="contracts">
                 <div className="">
                   <div className="row">
                     <div className="col-md-12" id="head">
                       <h4 className="heading">Information</h4>
                       <hr></hr>
                     </div>
                   </div>
                   <table class="table table-bordered account-table tables">
                     <tbody>
                        <tr>
                        <td id="td-right">
                           <span>Billing Config Name	</span>
                         </td>
                         <td>{datas.billing_config_name	}</td>
                         <td id="td-right">
                           <span>owner</span>
                         </td>
                         <td>{datas.owner}</td>

                       </tr>
                       <tr>
                        <td id="td-right">
                           <span>Billing Doc Record Type</span>
                         </td>
                         <td>{datas.billing_doc_record_type}</td>
                         <td id="td-right">
                         </td>
                         <td></td>

                       </tr>
                       <tr>
                        <td id="td-right">
                           <span>Line Item	</span>
                         </td>
                         <td>{datas.line_item}</td>
                         <td id="td-right">
                         </td>
                         <td></td>

                       </tr>
                       <tr>
                        <td id="td-right">
                           <span>Line Item Type	</span>
                         </td>
                         <td>{datas.lineitem_type}</td>
                         <td id="td-right">
                         </td>
                         <td></td>

                       </tr>
                        </tbody>
                   </table>

<div className="row">
                     <div className="col-md-12" id="head">
                       <h4 className="heading">Configuration</h4>
                       <hr></hr>
                     </div>
                   </div>
                   <table class="table table-bordered account-table tables">
                     <tbody>

                       <tr>
                        <td id="td-right">
                           <span>Debit Credit
	</span>
                         </td>
                         <td>{datas.debit_credit	}</td>
                         <td id="td-right">
                           <span>Order Object Field Name
</span>
                         </td>
                         <td>{datas.order_object_field_name}</td>

                       </tr>

                       <tr>
                        <td id="td-right">
                           <span>Calculation Type
	</span>
                         </td>
                         <td>{datas.calculation_type	}</td>
                         <td id="td-right">
                         </td>
                         <td></td>

                       </tr>
                       <tr>
                        <td id="td-right">
                           <span>Scale Base Type		</span>
                         </td>
                         <td>{datas.scale_base_type	}</td>
                         <td id="td-right">
                         </td>
                         <td></td>

                       </tr>
                         </tbody>
                   </table>
                   <div className="row">
                     <div className="col-md-12" id="head">
                       <h4 className="heading">Payable / Receivable Config</h4>
                       <hr></hr>
                     </div>
                   </div>
                   <table class="table table-bordered account-table tables">
                     <tbody>

                       <tr>
                        <td id="td-right">
                           <span>Payable DR Acc Group

	</span>
                         </td>
                         <td>{datas.payable_dr_acc_group	}</td>
                         <td id="td-right">
                           <span>Payable CR Acc Group

</span>
                         </td>
                         <td>{datas.payable_cr_acc_group}</td>

                       </tr>
                       <tr>
                        <td id="td-right">
                           <span>Payable DR Chart of Account No

	</span>
                         </td>
                         <td>{datas.payable_dr_chart_account_no	}</td>
                         <td id="td-right">
                           <span>Payable CR Chart of Account No

</span>
                         </td>
                         <td>{datas.payable_cr_chart_account_no}</td>

                       </tr>
                       <tr>
                        <td id="td-right">
                           <span>Payable DR Chart of Account Desc

	</span>
                         </td>
                         <td>{datas.payable_dr_chart_account_desc	}</td>
                         <td id="td-right">
                           <span>Payable CR Chart of Acc Desc

</span>
                         </td>
                         <td>{datas.payable_cr_chart_acc_desc}</td>

                       </tr>
                       <tr>
                        <td id="td-right">
                           <span>Payable DR GL Type

	</span>
                         </td>
                         <td>{datas.payable_dr_gl_type	}</td>
                         <td id="td-right">
                           <span>Payable CR GL Type

	</span>
                         </td>
                         <td>{datas.payable_cr_gl_type	}</td>
                       </tr>
                       <tr>
                        <td id="td-right">
                           <span>Payable DR Field Name


	</span>
                         </td>
                         <td>{datas.payable_dr_field_name	}</td>
                         <td id="td-right">
                           <span>Payable CR Field Name

</span>
                         </td>
                         <td>{datas.payable_cr_field_name}</td>

                       </tr>
                         </tbody>
                   </table>

                   <div className="row">
                     <div className="col-md-12" id="head">
                       <h4 className="heading">Payment / Receipt Config</h4>
                       <hr></hr>
                     </div>
                   </div>
                   <table class="table table-bordered account-table tables">
                     <tbody>

                       <tr>
                        <td id="td-right">
                           <span>Payment DR ACC Group
</span>
                         </td>
                         <td>{datas.payment_dr_acc_group	}</td>
                         <td id="td-right">
                           <span>Payment CR ACC Group
</span>
                         </td>
                         <td>{datas.payment_cr_acc_group}</td>

                       </tr>
                       <tr>
                        <td id="td-right">
                           <span>Payment DR Chart of Account No
</span>
                         </td>
                         <td>{datas.payment_dr_chart_account_no	}</td>
                         <td id="td-right">
                           <span>Payment CR Chart of Account No
</span>
                         </td>
                         <td>{datas.payment_cr_chart_account_no}</td>

                       </tr>
                       <tr>
                        <td id="td-right">
                           <span>Payment DR Chart of Account Desc
</span>
                         </td>
                         <td>{datas.payment_dr_chart_account_desc	}</td>
                         <td id="td-right">
                           <span>Payment CR Chart of Account Desc
</span>
                         </td>
                         <td>{datas.payment_cr_chart_acc_desc}</td>

                       </tr>
                       <tr>
                        <td id="td-right">
                           <span>Payment DR GL Type
</span>
                         </td>
                         <td>{datas.payment_dr_gl_type	}</td>
                         <td id="td-right">
                           <span>Payment CR GL Type
</span>
                         </td>
                         <td>{datas.payment_cr_gl_type}</td>

                       </tr>
                       <tr>
                        <td id="td-right">
                           <span>Payment Dr Fld Name
</span>
                         </td>
                         <td>{datas.payment_dr_field_name	}</td>
                         <td id="td-right">
                           <span>Payment Cr Fld Name
</span>
                         </td>
                         <td>{datas.payment_cr_field_name}</td>

                       </tr>
                          </tbody>
                   </table>
                 </div>
               <div className="edit-delete">
                  <span id="edit" onClick={handleEditClick}>
                    Edit
                  </span>
                  <span>
                  <Link to={'/price/price-list'}> Cancel</Link>
                  </span>

                  </div>
               </div>
             </div>

            <div className="">
               <div id="contracts">


                        <Row id="table-styles">
                          <div className="col-md-12" id="head">
                            <h4 className="heading">
                              <span>Notes</span>
                              <span>
                                <i className=""></i>
                                New Note
                              </span>
                            </h4>
                            <hr></hr>
                          </div>
                          <table id="history" className="">
                            <tr>
                              <td>
                                <p class="no-records">No records to display</p>
                              </td>
                            </tr>
                          </table>
                        </Row>
                        <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Cases</span>
                                      <span>
                                        <i className=""></i>
                                        New Case
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">
                                          No records to display
                                        </p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>
                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Open Activities</span>
                                      <span>
                                        <i className=""></i>
                                        New Task
                                      </span>
                                      <span>
                                        <i className=""></i>
                                        New Event
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">
                                          No records to display
                                        </p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>
                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Activity History</span>
                                      <span>
                                        <i className=""></i>
                                        Log a Call
                                      </span>
                                      <span>
                                        <i className=""></i>
                                        Mail merge
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">
                                          No records to display
                                        </p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>

               </div>
             </div>
           </>

         </div>

       </>
        )
}
</div> </div> </div>
      {/* ):(
        <div className="tasks">
        <h1 className="no-data-detail"><img src='../../images/loading.gif' className="loading"/></h1>
        </div>
      )} */}
</>

      <Footer />
    </div>
  );
};

export default ViewFinanace;
