//get/all/tcf/create_view   tcf_create_view

//get/tcf/view   tcfs


// //get/all/tcf/new   tcfs
///tcf/tcf-view-edit

///tcf/create-new-view
import React, { useState, useEffect, useRef } from "react";

import axios from "axios";

import { AES, enc } from "crypto-js";
import { API_URL } from "../config";
import './payreceive.css'

import { Link, useNavigate } from "react-router-dom";

import Header from "../Header";
import Footer from "../Footer";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from "@mui/material";

import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { AiFillDiff } from "react-icons/ai";
import { FaPencilAlt, FaTrashAlt, FaPlus } from "react-icons/fa";
import RecentItems from "../recentItems/RecentItems";


const  PayReceiveList= () => {

  const [isLoading, setLoading] = useState(false);
  const [receiveDetailViews, setreceiveDetailViews] = useState([]);
  const [isOptionsLoaded, setIsOptionsLoaded] = useState(false);
  const [selectedView, setSelectedView] = useState("all")
  const [selectedViewId, setSelectedViewId] = useState("")


  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });

  // Function to handle sorting logic
  const handleSort = (columnKey) => {
    let direction = "asc";
    if (sortConfig.key === columnKey && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key: columnKey, direction });
    sortTableData(columnKey, direction);
  };

  // Function to sort table data
  const sortTableData = (columnKey, direction) => {
    const sortedData = [...allTableData].sort((a, b) => {
      if (a[columnKey] < b[columnKey]) {
        return direction === "asc" ? -1 : 1;
      }
      if (a[columnKey] > b[columnKey]) {
        return direction === "asc" ? 1 : -1;
      }
      return 0;
    });
    setAllTableData(sortedData); // Make sure you update your table data state
  };

  const [requestDatatype, setRequestDataType] = useState("recentmodifier")

  const [allTableData, setAllTableData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [columns, setColumns] = useState([]);

  const [modifiedEntriesLabel, setModifiedEntriesLabel] = useState("recently_created")

  const navigate = useNavigate();


  const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
  const encryptedAccessToken = localStorage.getItem("access_token");
  const decryptedAccessToken = encryptedAccessToken ? AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8) : null;



  // Function To Handle Recent Modifiers //

  const handleRandomDropdownChange = (e) => {

    const modifiedEntry = e.target.value

    setModifiedEntriesLabel(modifiedEntry)

    setRequestDataType("recentmodifier")

  }

  // Function To Handle Page Change //

  const handlePageChange = (event, newPage) => {

    setPage(newPage);

  };


  // Function To Handle Rows Per Change //

  const handleRowsPerPageChange = (event) => {

    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);

  };



  const [excludedFields, setExcludedFields] = useState([
    "id",
    "account_id" ,
    "rwoodbank_id" ,
    "rwoodcompany_id" ,
    "user_id",
    "order_id",
    "postshipment_id"


  ]);
  const [linkFields, setLinkFields] = useState({

    rw_code_no: { id: "id", link: "/order/order-receivable-details/" },

    account: { id: "account_id", link: "/accounts/" },

    rwood_bank_master:{ id: "rwoodbank_id", link: "/bank/bank-details/" },

    company: { id: "rwoodcompany_id", link: "/company/company-details/" },



  });

  const renderTableRowsData = (data, type) => {
    switch (type) {
      case "headers":
        return data.map((col, index) => {
          if (!excludedFields.includes(col.field)) {
            return (
              <TableCell sx={{ width: 200 }} key={index}>
                {col.headerName}
              </TableCell>
            );
          }
          return null;
        });

      case "rows":
        return data.map((row, rowIndex) => (
          <TableRow key={rowIndex}>
            {columns.map((col, colIndex) => {
              if (!excludedFields.includes(col.field)) {
                return (
                  <TableCell sx={{ width: 200 }} key={colIndex}>
                    {linkFields.hasOwnProperty(col.field) ? (
                      <Link
                        to={`${linkFields[col.field].link}${
                          row[linkFields[col.field].id]
                        }`}
                      >
                        {row[col.field]}
                      </Link>
                    ) : (
                      row[col.field]
                    )}
                  </TableCell>
                );
              }
              return null;
            })}
          </TableRow>
        ));

      default:
        return null;
    }
  };


  // Formatting Table Header Data //

  const formatHeaderName = (key) => {

    const replaced = key.replace(/_/g, ' ').toLowerCase();
    return replaced.replace(/\b\w/g, char => char.toUpperCase());

  };


  const getColumnsData = (data) => {

    //desired order

    const desiredOrder = [

      'rw_code_no',
      'total_amount',
      'record_type',
      'total_paid_or_received_amount',
      'total_balance_due',
      'account',
      'company',


    ]

    // Get the keys from the data
    const dataKeys = Object.keys(data[0] || {});


    const columnsData = desiredOrder

      .filter(key => dataKeys.includes(key))

      .map((key) => ({

        field: key,
        headerName: formatHeaderName(key),

      }));


    const remainingColumns = dataKeys

      .filter(key => !desiredOrder.includes(key))

      .map((key) => ({

        field: key,
        headerName: formatHeaderName(key),

      }));

    return [...columnsData, ...remainingColumns];

  }

  // Function To Fetch Created Views //
  const fetchPayReceiveViews = async () => {

    try {

      setLoading(true)


      const createViewResponse = await axios.get(`${API_URL}get/pay_recieve_detail_all/create_view`)

      if (createViewResponse.status === 200) {

        const viewData = createViewResponse.data.pay_recieve_create_view;

        console.log(viewData, 'viewData')
        setreceiveDetailViews(viewData)

        setLoading(false)

        setIsOptionsLoaded(true)

      }

    }
    catch (err) {

      console.log("An Error Occurred", err)

    }

  }

  // const handleViewFocus = () => {

  //   !isOptionsLoaded && fetchPayReceiveViews();

  // };




  const handleViewChange = (e) => {

    const { value, selectedIndex } = e.target

    const selectedOptionId = e.target.options[selectedIndex].id;

    setSelectedViewId(selectedOptionId)

    setSelectedView(value)

    setRequestDataType("viewmodifier")

    sessionStorage.setItem("receiveDetailView", value)
    sessionStorage.setItem("receiveDetailViewId", selectedOptionId)

  }


  // On Component Load Set View To Previous //

  useEffect(() => {

    fetchPayReceiveViews()

    const receiveDetailViewName = sessionStorage.getItem("receiveDetailView")

    const receiveDetailViewNameId = sessionStorage.getItem("receiveDetailViewId")

    const receiveDetailViewEdit = sessionStorage.getItem("receiveDetailViewEdit")


    const isreceiveDetailView = sessionStorage.getItem("isreceiveDetailView")

    setSelectedView(receiveDetailViewName)

    setSelectedViewId(receiveDetailViewNameId)

    if (receiveDetailViewEdit && receiveDetailViewEdit === "true" && receiveDetailViewName && receiveDetailViewNameId) {

      setRequestDataType("viewmodifier")

    } else if (isreceiveDetailView && isreceiveDetailView === "true" && receiveDetailViewName && receiveDetailViewNameId) {

      setRequestDataType("viewmodifier")

    } else {

      setRequestDataType("recentmodifier")

      setModifiedEntriesLabel("recently_created")

    }

    sessionStorage.removeItem("receiveDetailViewEdit");
    sessionStorage.removeItem("isreceiveDetailView")

  }, [])
//   useEffect(() => {

//     fetchPayReceiveViews()

//     const receiveDetailViewName = sessionStorage.getItem("receiveDetailView")

//     const receiveDetailViewNameId = sessionStorage.getItem("receiveDetailViewId")

//     const receiveDetailViewEdit = sessionStorage.getItem("receiveDetailViewEdit")


//     if (receiveDetailViewEdit && receiveDetailViewName && receiveDetailViewNameId)    {
//       setSelectedView(receiveDetailViewName)

//       setSelectedViewId(receiveDetailViewNameId)

//       receiveDetailViewEdit === "true" && setRequestDataType("viewmodifier")
//     } else {

//       setRequestDataType("recentmodifier")
//       setModifiedEntriesLabel("recently_created")

//     }

//     sessionStorage.removeItem("receiveDetailViewEdit");

//   }, [])
  // Fetching View Based Data //

  const fetchViewData = async () => {

    try {

      setLoading(true)


      const URL = `${API_URL}get/pay_detail/view`

      const response = await axios.get(URL, {

        headers: {

          Authorization: `Bearer ${decryptedAccessToken}`,
          "Content-Type": "application/json",

        },

        params: {

          page: page + 1,
          page_size: rowsPerPage,
          view_name: selectedView

        }

      });

      if (response.status === 200) {

        const data = response.data.pay_recieve || [];


        if (data.length > 0) {

          setAllTableData(data);

          setTotalRecords(response.data.total || 0);

          const columnData = getColumnsData(data)

          setColumns(columnData)


        } else {

          setAllTableData([]);

          setTotalRecords(0)

          setColumns([])

        }

      }


    }

    catch (err) {

      console.log("An Error Occurred While Retriving View Data", err)

    }

    finally {

      setLoading(false)

    }

  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getModifiedEntries = async () => {


    setLoading(true)


    const URL = `${API_URL}get/allpay_recieve/new`

    try {

      const response = await axios.get(URL, {

        headers: {

          Authorization: `Bearer ${decryptedAccessToken}`,
          "Content-Type": "application/json",

        },

        params: {

          random_dropdown_option: modifiedEntriesLabel,
          page: page + 1,
          page_size: rowsPerPage,

        },

      });

      if (response.status === 200) {

        const data = response.data.pay_recieve || [];

        if (data.length > 0) {

          setAllTableData(data);

          setTotalRecords(response.data.total)

          const columnData = getColumnsData(data)

          setColumns(columnData)

        } else {

          setAllTableData([]);

          setTotalRecords(0)

          setColumns([])

        }

      }


    } catch (err) {

      console.log("An Error Occurred While Getting Modified Entries")

    } finally {

      setLoading(false)

    }

  }

  const navigatereceiveDetailViewEdit = (e) => {

    e.preventDefault()

    sessionStorage.setItem("receiveDetailViewEdit", true)

    navigate(`/pay-receive-detail/edit-view/${selectedViewId}`)

  }


  const navigateCreateView = (e) => {

    e.preventDefault()

    sessionStorage.setItem("receiveDetailViewEdit", true)

    navigate(`/pay-receive-detail/create-view`)

  }

  const navigateViewDelete = () => {

  }


  //  set page to 0 whenever request data type changes //
  useEffect(() => {
    if(requestDatatype){
      setPage(0)
    }


  },[requestDatatype])

  useEffect(() => {

    requestDatatype === "recentmodifier" && getModifiedEntries()

  }, [modifiedEntriesLabel, page, rowsPerPage])

  // Fetch Selected View //

  useEffect(() => {



    requestDatatype === "viewmodifier" && fetchViewData()

  }, [selectedView, page, rowsPerPage])






  return (

    <>

      <Header />

      <div className="dashboard-container">


        <div className="recent-items">

          <h2>Recent Items</h2>

        <RecentItems/>
        </div>

        <div className="accounts">

          <div className="accounts-header">

            <h2>Rw PayReceive Details</h2>

            <div className="header-actions">


            </div>

          </div>

          <div className="accounts-content">

            <div className="view-controls">

              <div className="view_label">

                <a>View:</a>

              </div>

              <div className="select-container">

                <select
                  value={selectedView}
                  onChange={handleViewChange}
                >
                  <option>Select a view</option>

                  {receiveDetailViews.length > 0 ? (

                    receiveDetailViews.map(option => (

                      <option key={option.id} id={option.id} value={option.unique_name}>

                        {option.view_name}

                      </option>

                    ))

                    ) : (

                      <option disabled>No Data Available</option>

                    )
                  }

                </select>

              </div>

              <div className="button-container">

                <button onClick={fetchViewData}>Go</button>

              </div>

              <div className="view_actions">

                <span className="rwood_view_edit" onClick={navigatereceiveDetailViewEdit}>Edit</span> | <span className="rwood_view_edit" onClick={navigateViewDelete}>Delete</span> | <span className="rwood_view_edit" onClick={navigateCreateView}>Create New View</span>

              </div>

            </div>

            <div className="recent-accounts">

              <div className="recemt_contract_content">

                <div className="account-search">

                  <p>Recent PayReceive</p>

                  <div className="select-modifier-container">

                    <select onChange={handleRandomDropdownChange}>

                      <option value="recently_created">Recently Created</option>
                      <option value="recently_modified">Recently Modified</option>
                      <option value="recently_viewed">Recently Viewed</option>

                    </select>

                  </div>

                </div>

                <div className="data_table">


                {!isLoading ? (
                    allTableData.length > 0 ? (
                      <TableContainer component={Paper}>
                        <Table
                          sx={{ tableLayout: "fixed", width: "100%" }}
                          size="small"
                          aria-label="a dense table"
                        >
                          <TableHead sx={{ background: "#f7f7f7" }}>
                            <TableRow>
                              {columns.map(
                                (col, index) =>
                                  col.field !== "id" && (
                                    <TableCell
                                      sx={{ width: 200, cursor: "pointer" }}
                                      key={index}
                                      onClick={() => handleSort(col.field)}
                                    >
                                      {col.headerName}
                                      {/* Add icons or indicators for sort direction */}
                                      {sortConfig.key === col.field && (
                                        <span>
                                          {sortConfig.direction === "asc"
                                            ? " 🔼"
                                            : " 🔽"}
                                        </span>
                                      )}
                                    </TableCell>
                                  )
                              )}
                            </TableRow>
                          </TableHead>
                             <TableBody>
                            {renderTableRowsData(allTableData, "rows")}
                          </TableBody>
                        </Table>

                        <TablePagination
                          component="div"
                          count={totalRecords}
                          page={page}
                          onPageChange={handlePageChange}
                          rowsPerPage={rowsPerPage}
                          onRowsPerPageChange={handleRowsPerPageChange}
                        />
                      </TableContainer>
                    ) : (
                      <div className="contract_data_status">
                        <h3>No Data Available</h3>
                      </div>
                    )
                  ) : (
                    <div className="rwood_spinner">
                      <img src="../../../images/loader.svg" alt="spinner" />
                    </div>
                  )}


                </div>

              </div>

            </div>

          </div>

        </div>

      </div>

      <Footer />

    </>

  );

}



export default PayReceiveList