import React, { useState, useEffect } from "react";
import TreeNode from './TreeNode';
import { v4 as uuidv4 } from 'uuid';
import "./report.css";
import PreviewTable from "./PreviewTable";
import axios from "axios";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import modifiedTableKeysData from "./utils";
import { AES, enc } from 'crypto-js';
import { useDispatch, useSelector } from 'react-redux';
import { setJsonData, setFilteredData } from '../../store/FilterDataReducer';

 import { API_URL } from "../config";

const ReportGenerator = ({ tableKeys, api_payload }) => {

    const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
    const encryptedAccessToken = localStorage.getItem("access_token");
    const decryptedAccessToken = encryptedAccessToken
        ? AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8)
        : null;
    const [selectedNodeId, setSelectedNodeId] = useState(null);
    const [tableHeaders, setTableHeaders] = useState({});
    const [searchQuery, setSearchQuery] = useState("");
    const [showCustomFilter, setShowCustomFilter] = useState(false);
    const [customFilterData, setCustomFilterData] = useState({});
    const [filterCondition, setFilterCondition] = useState([])
    const [sampleData, setSampleData] = useState([]);
    const [scope, setScope] = useState("")
    const [updatedFields, setupdatedFields] = useState({})

    const responseData = useSelector(state => state.filterData.responseData);

    const dispatch = useDispatch();

    const jsonData = useSelector(state => state.filterData);


    const dateFormatter = (dateStr) => {

        const [year, month, day] = dateStr.split('-').map(Number);
        const date = new Date(year, month - 1, day);

        const dayFormatted = String(date.getDate()).padStart(2, '0');
        const monthFormatted = String(date.getMonth() + 1).padStart(2, '0');
        const yearFormatted = date.getFullYear();

        return `${dayFormatted}/${monthFormatted}/${yearFormatted}`;

    }

    const generateFilename = () => {

        const now = new Date();
        const year = now.getFullYear();
        const month = (now.getMonth() + 1).toString().padStart(2, '0');   
        const day = now.getDate().toString().padStart(2, '0');
        const hours = now.getHours().toString().padStart(2, '0');
        const minutes = now.getMinutes().toString().padStart(2, '0');
        const seconds = now.getSeconds().toString().padStart(2, '0');

        return `report_${year}${month}${day}_${hours}${minutes}${seconds}.xlsx`;

    };


    const date_fields = ["created_at", "updated_at", "birthdate", "last_viewed_at"]


    // unmount filtered condition

    const unmountFilterCondition = (index) => {

        console.log(filterCondition,'filterCondition123')
        const updatedFilterCondition = [...filterCondition];

        updatedFilterCondition.splice(index, 1);  

        setFilterCondition(updatedFilterCondition);  

    };

    // generate Report

    const fecthFilteredResponseData = async () => {

        const URL = `${API_URL}get/keys`

        console.log(updatedFields, 'updatedFieldsres')

        const response = await axios.post(
            URL,
            updatedFields,
            {
                headers: {
                    'Content-Type': 'application/json'
                },
            }
        )
        const filteredResponse = response.data

        if (filteredResponse) {


            setSampleData(filteredResponse?.default_values)

            dispatch(setFilteredData(filteredResponse?.default_values))

        }

    }


    const transformData = modifiedTableKeysData(tableKeys);


    const filterOperators = [

        { id: "equals", label: "equals" },
        { id: "not_equals", label: "not equal" },
        { id: "greater_than", label: "greater than" },
        { id: "less_than", label: "less than" },
        { id: "greater_equal", label: "greater or equal" },
        { id: "less_equal", label: "lesser or equal" }

    ];



    //select json structure based on models

    const handleScope = (e) => {

        setScope(e.target.value)
    }



    const generateReport = async () => {

        const URL = `${API_URL}reports`


        if (responseData.length > 0) {

            const data = {

                "filtered_data": responseData
            }



            const response = await axios.post(

                URL,
                data,

                {
                    headers: {

                        Authorization: `Bearer ${decryptedAccessToken}`,
                        'Content-Type': 'application/json',

                    },
                    responseType: 'blob'
                }
            )


            const results = response.data

            if (results) {

                const file_name = generateFilename()

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;

                link.setAttribute('download', file_name);
                document.body.appendChild(link);
                link.click();
                link.remove();

            }

        } else {
            alert("No Report's Available !!")
        }



    }

    //table keys - default values


    const transformFieldType = (fieldType) => {
        return fieldType
            .replace(/_/g, ' ')             
            .split(' ')                    
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');                    
    };


    useEffect(() => {

        const defaultFields = tableKeys?.default_fields

        Object.keys(defaultFields).forEach(fieldType => {

            if (defaultFields[fieldType]) {

                const fieldValues = defaultFields[fieldType];

                const headers = fieldValues.reduce((acc, field) => {

                    acc[field] = transformFieldType(field);
                    return acc;

                }, {});


                setTableHeaders(prevHeaders => ({

                    ...prevHeaders,
                    ...headers

                }));


                setSampleData(tableKeys?.default_values)

                dispatch(setFilteredData(tableKeys?.default_values))

            }
        });

    }, [tableKeys])


    // if modification happens in json structure renders components //

    useEffect(() => {

        const updated_condition = Object.keys(updatedFields);

        

        if (updated_condition.length > 0) {

            fecthFilteredResponseData()

        }  

    }, [updatedFields])



    useEffect(() => {

        console.log("jsonDataIsBeingUpdated !!!", jsonData)

        jsonData && setupdatedFields(jsonData.filteredData)


    }, [jsonData])


     
    useEffect(() => {

        console.log(filterCondition,'filterConditionafterremovingcondition')
        
        console.log(updatedFields, 'updatedFields')

        if (filterCondition.length > 0) {

            // Create a copy of updatedFields to avoid mutating state directly
            let newFilteredFields = { ...updatedFields };

            // Define the key for filter conditions
            let field_type = "filterConditions";

            newFilteredFields["table_name"] = api_payload?.table_name

            // Ensure the field type exists in the newFilteredFields object
            if (!newFilteredFields[field_type]) {

                newFilteredFields[field_type] = [];

            }

            // Use a set to track unique conditions temporarily
            let uniqueConditions = new Set(newFilteredFields[field_type].map(condition => JSON.stringify(condition)));


            
            // Add new filter conditions to the set
            filterCondition.forEach(newCondition => {

                uniqueConditions.add(JSON.stringify(newCondition));

            });

            // Convert set back to array of objects
            let uniqueFilterConditions = Array.from(uniqueConditions).map(condition => JSON.parse(condition));

            // Update newFilteredFields with unique filter conditions
            newFilteredFields[field_type] = uniqueFilterConditions;

            // Dispatch the updated filtered fields to the Redux store
            dispatch(setJsonData(newFilteredFields));

        }    

    }, [filterCondition]);


    useEffect(() =>{
        console.log(jsonData,'jsonData123')
    },[jsonData])

    //handle drop of table key items
    const handleDrop = (transformData, draggableId) => {

        let field_type

        let newUpdatedFields = { ...updatedFields };

        transformData.forEach(group => {

            group.children.forEach(child => {

                if (child.id === draggableId) {

                    console.log(group, child.id, api_payload)

                    newUpdatedFields["table_name"] = api_payload?.table_name

                    if (group.id === "accounts") {

                        field_type = `${group.id}_fields`

                    }

                    if (group.id === "contacts") {

                        field_type = `${group.id}_fields`
                    }

                    if (!newUpdatedFields[field_type]) {

                        newUpdatedFields[field_type] = [];
                    }


                    if (!newUpdatedFields[field_type].includes(child.label)) {

                        newUpdatedFields[field_type] = [

                            ...newUpdatedFields[field_type],
                            child.id

                        ];

                    }

                    dispatch(setJsonData(newUpdatedFields));

                    setTableHeaders(prevHeaders => ({

                        ...prevHeaders,

                        [child.id]: child.label,

                    }));

                }

            });

        });

    };

    // trigger's on drag end
    const onDragEnd = (result) => {

        const { destination, draggableId } = result;

        if (!destination) {

            console.log("No destination, item was not dropped in a valid droppable area.");
            return;

        }

        if (destination.droppableId.includes("preview_table_drop_area")) {

            handleDrop(transformData, draggableId);

        }

    };

    // handle search functionality
    const handleSearch = (event) => {

        setSearchQuery(event.target.value);

    };

    // toggle custom filter modal
    const toggleCustomFilter = () => {

        setShowCustomFilter(!showCustomFilter);

    };


    //render based on sampleData

    useEffect(() => {

    }, [sampleData])

    // handle selection change in custom filter
    const handleCustomFilterChange = (event) => {

        let { id, value, type } = event.target;


        if (type === "date") {

            value = dateFormatter(value)

        }

        setCustomFilterData(prevState => ({

            ...prevState,
            [id]: value,

        }));

    };



    // close modal of filter
    const handleCustomFilterOK = () => {

        setFilterCondition(prevSampleData => [
            ...prevSampleData,
            {
                input: customFilterData.input,
                operator: customFilterData.operator,
                values: [customFilterData.values]
            }
        ]);

        toggleCustomFilter();

    };

    // Close modal without applying filter
    const handleCustomFilterCancel = () => {

        toggleCustomFilter();

    };

    const filteredData = transformData.filter((item) =>

        item.label.toLowerCase().includes(searchQuery.toLowerCase())

    );

    // Generate a unique droppableId for preview_table_drop_area
    const uniqueDropAreaId = `preview_table_drop_area_${Date.now()}`;

    return (

        <div>

            <div className="reports_top_banner">

                <button className="report_btn save">Save</button>
                <button className="report_btn save_as">Save As</button>
                <button className="report_btn close">Close</button>
                <button className="report_btn report_prop">Report Properties</button>
                <button className="report_btn add_report_type">Add Report Type</button>
                <button className="report_btn run_report">Run Report</button>

            </div>

            <DragDropContext onDragEnd={onDragEnd}>

                <div className="report_container">



                    <div class="fields-container">
                        <div class="header">

                            <div class="filter-icons">
                                <button>All</button>
                                <button className="report_action_btn"><img src="../../../images/text.png" alt="" /></button>
                                <button className="report_action_btn"><img src="../../../images/hash.png" alt="" /></button>
                                <button className="report_action_btn"><img src="../../../images/calendar.png" alt="" /></button>
                            </div>



                        </div>

                        <div className="report-search-bar">

                            <input type="text" class="search-bar" placeholder="Quick Find" onChange={handleSearch} />

                        </div>


                        <div class="drag-drop-text">
                            Drag and drop to add fields to the report.
                        </div>

                        <Droppable droppableId="fields_list" type="FIELD">

                            {(provided) => (

                                <ul className="rwood_reports_key_items" ref={provided.innerRef} {...provided.droppableProps}>

                                    {filteredData.map((item, index) => (

                                        <TreeNode
                                            key={item.id}
                                            node={item}
                                            selectedNodeId={selectedNodeId}
                                            setSelectedNodeId={setSelectedNodeId}
                                            index={index}
                                        />

                                    ))}

                                    {provided.placeholder}

                                </ul>

                            )}

                        </Droppable>

                    </div>

                    <div className="main-content">

                        <div className="filters">

                            <div className="add_filter_fields">

                                <p className="filter_label">Filters</p>

                                <button className="report_btn" onClick={toggleCustomFilter}>Add Fields</button>

                            </div>

                            <div className="show_fields">

                                <label className="filter_label" htmlFor="show">Show</label>

                                <select id="show" onChange={handleScope}>

                                    <option value="all_accounts">All Accounts</option>
                                    <option value="my_accounts">My Accounts</option>

                                </select>

                            </div>




                            <div className="rwood_reports_date_fields">

                                <label className="filter_label" htmlFor="date field">Date Field</label>

                                <select id="show" onChange={handleScope}>

                                    <option value="created_date">Created Date</option>

                                </select>


                                <label className="filter_label" htmlFor="range">Range</label>

                                <select id="show" onChange={handleScope}>

                                    <option value="custom">Custom</option>

                                </select>


                                <label className="filter_label" htmlFor="from">From</label>

                                <input className="custom_date" type="date" />

                                <label className="filter_label" htmlFor="to">to</label>

                                <input className="custom_date" type="date" />

                            </div>

                        </div>

                        <div className="show_custom_filter">

                            {showCustomFilter && (

                                <div className="custom-filter-modal">

                                    <h3>Add Custom Filter</h3>

                                    <div className="filter-options">

                                        <select id="input" className="field_filters" onChange={handleCustomFilterChange} value={customFilterData.label}>
                                            <option value="">Select a field to add</option>
                                            {transformData.map(group => (
                                                group.children.map(child => (
                                                    <option key={child.id} value={child.id}>{child.label}</option>
                                                ))
                                            ))}
                                        </select>

                                        <select id="operator" className="field_filters" onChange={handleCustomFilterChange} value={customFilterData.selectedOption2}>
                                            <option value="">Select a Operator</option>
                                            {filterOperators.map(operator => (
                                                <option key={operator.id} value={operator.label}>{operator.label}</option>
                                            ))}
                                        </select>


                                        {date_fields.includes(customFilterData.input) ? (
                                            <input id="values" className="field_filters" onChange={handleCustomFilterChange} type="date" placeholder="value" />
                                        ) : (
                                            <input id="values" className="field_filters" onChange={handleCustomFilterChange} type="text" placeholder="value" />

                                        )}


                                        <div className="modal-buttons">
                                            <button className="report_btn" onClick={handleCustomFilterOK}>OK</button>
                                            <button className="report_btn" onClick={handleCustomFilterCancel}>Cancel</button>
                                        </div>
                                    </div>

                                </div>
                            )}

                        </div>

                        {filterCondition.length > 0 &&

                            <div className="filter_conditions">
                                
                                {console.log(filterCondition,'filterCondition')}

                                {filterCondition.map((item, index) => (

                                    <div className="filter_condition_items" key={index}>

                                        <div className="filter_conditions_inputs">{transformFieldType(item?.input)}</div>
                                        <div className="filter_conditions_inputs">{item?.operator}</div>
                                        <div className="filter_conditions_inputs">{item?.values[0]}</div>

                                        <div className="filter_condition_remove" onClick={() => unmountFilterCondition(index)} >

                                            <img src="../../../images/close.png" alt="delete icon" />

                                        </div>
                                         
                                    </div>

                                ))}

                            </div>

                        }


                        <div className="preview">

                            <PreviewTable data={sampleData} headers={tableHeaders} />

                        </div>

                        <Droppable droppableId={uniqueDropAreaId} type="FIELD">

                            {(provided) => (
                                <div
                                    className="preview_table_drop_area"
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                >
                                    <div>
                                        Drop fields here
                                    </div>
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </div>
                </div>
            </DragDropContext>

        </div>
    );
};

export default ReportGenerator;
