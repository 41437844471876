import React, { Component, useState, useEffect, useContext, forwardRef } from "react";
import {
  Card,
  OverlayTrigger,
  Tooltip,
  Table,
} from "react-bootstrap";
import "datatables.net-dt/js/dataTables.dataTables.js";
import { AES, enc } from "crypto-js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import "datatables.net-responsive-dt";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "react-data-table-component-extensions/dist/index.css";
import { parse, format , isValid } from 'date-fns';
import { Link, useNavigate } from "react-router-dom";
import { TokenContext } from "../AuthProvider";
import Container from 'react-bootstrap/Container';
import Popup from "reactjs-popup";
import Row from 'react-bootstrap/Row';
import { API_URL } from '../config';
import Col from 'react-bootstrap/Col';
import Header from "../Header";
import Footer from "../Footer";

import {
  FaEnvelope,
  FaPhoneSquareAlt,
  FaMobileAlt,
  FaFax,
} from "react-icons/fa";
import axios from "axios";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { FaPencilAlt, FaTrashAlt, FaPlus } from "react-icons/fa";
// import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
import $ from 'jquery'
import { useParams } from "react-router-dom";
import DatePicker from "react-datepicker";

const ViewContract = () => {
  const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
  const encryptedAccessToken = localStorage.getItem("access_token");
  const decryptedAccessToken = encryptedAccessToken
    ? AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8)
    : null;

  const [document, setDocument]=useState([])
  const [history, setHistory]=useState([])
  const [contractProduct, setContractProduct]=useState([])
  const [ContractStart, setContractStart] = useState(null);
  console.log(ContractStart)
    const [data, setData] = useState({});
  useEffect(() => {
    fetchData();
  }, [decryptedAccessToken],data);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${API_URL}get/contractbyID/`+id,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );

      const data = response.data[0].contract_details;
      const contract_ship = response.data[0].contract_shipments;
      const tcfs = response.data[0].tcf;
      const opp = response.data;
      const order = response.data[0].orders;
      const contarct=response.data[0].contract_shipments;
      const documents=response.data[0].documents;
      const history=response.data[0].history;
      const contractProduct=response.data[0].contract_products;

      let contract_start_date=data.contract_start_date
      const contractStartDate = parse(contract_start_date, 'yyyy-MM-dd', new Date());
      setContractStart(contractStartDate);

      console.log(response.data)
      setHistory(history)
      setContractship(contarct)
      setDocument(documents)
      setTcf(tcfs)
      setOrdersData(order)
      setContractProduct(contractStartDate)

      setDatas(data)
      setTimeout(() => {
        $('#opportunity').DataTable();
      $('#history').DataTable();
      $('#tcf').DataTable();
      $('#orders').DataTable();
      $('#history').DataTable();
      $('#document').DataTable();
      $('#shipment').DataTable();


    }, 100);


    } catch (error) {
      console.error(error);
    }
  };


  const [contractdate, setContractDate]=useState('')

  // useEffect(()=>{
  //   const dateObject = new Date(ContractStart);
  //   if (dateObject) {
  //     const formattedDate = format(dateObject, 'dd/mm/yyyy', { timeZone: 'UTC' });
  //     setContractDate(formattedDate)
  //   }
  // })








  const [getPrice, setgetPrice] = useState([]);

  useEffect(() => {
    fetchDataPrice();
  }, [decryptedAccessToken]);

  const fetchDataPrice = async () => {
    try {
      const response = await axios.get(
        `${API_URL}get/all/price_items`,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data.priceitem;
      // console.log(data)
      setgetPrice(data);
    } catch (error) {
      console.error(error);
    }
  };
  const [effective, setEffective] = useState(new Date());
  const contractEffective =
  effective.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    effective.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    effective.toLocaleDateString("en-US", { day: "2-digit" });
  const ExampleCustomInput1 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="example-custom-input"
      onClick={onClick}
      onChange={onChange}
      name="contract_effective_date"
      ref={ref}
    ></input>
  ));

  const [customer, setCustomer] = useState(new Date());
  const startDates3 =
  customer.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    customer.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    customer.toLocaleDateString("en-US", { day: "2-digit" });
  const ExampleCustomInput6 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="example-custom-input"
      onClick={onClick}
      onChange={onChange}
      name=""
      ref={ref}
    ></input>
  ));

  const [check,setCheck1]=useState(false)
  const [startDate, setStartDate] = useState(new Date());
  const renewalDate =
    startDate.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    startDate.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    startDate.toLocaleDateString("en-US", { day: "2-digit" });
  const ExampleCustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="example-custom-input"
      onClick={onClick}
      onChange={onChange}
      name="renewal_date"
      ref={ref}
    ></input>
  ));

  const [signed, setSigned] = useState(new Date());
  const startDates1 =
  signed.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    signed.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    signed.toLocaleDateString("en-US", { day: "2-digit" });
  const ExampleCustomInput4 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="example-custom-input"
      onClick={onClick}
      onChange={onChange}
      name=""
      ref={ref}
    ></input>
  ));

  const [sent, setSent] = useState(new Date());
  const startDates4 =
  sent.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    sent.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    sent.toLocaleDateString("en-US", { day: "2-digit" });
  const ExampleCustomInput7 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="example-custom-input"
      onClick={onClick}
      onChange={onChange}
      name=""
      ref={ref}
    ></input>
  ));


  const [received, setReceived] = useState(new Date());
  const startDates2 =
  received.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    received.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    received.toLocaleDateString("en-US", { day: "2-digit" });
  const ExampleCustomInput5 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="example-custom-input"
      onClick={onClick}
      onChange={onChange}
      name=""
      ref={ref}
    ></input>
  ));


  const [start, setStart] = useState(new Date());
  const startDates =
  start.toLocaleDateString("en-US", { year: "numeric" }) +
    "-" +
    start.toLocaleDateString("en-US", { month: "2-digit" }) +
    "-" +
    start.toLocaleDateString("en-US", { day: "2-digit" });
  const ExampleCustomInput2 = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="example-custom-input"
      onClick={onClick}
      onChange={onChange}
      name="contract_start_date"
      ref={ref}
    ></input>
  ));

const [priceBook, setPriceBook]=useState([]);
  useEffect(() => {

    fetchData2();
  }, [decryptedAccessToken]);

  const fetchData2 = async () => {
    try {
      const response = await axios.get(`${API_URL}get/all/pricebook`, {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
      );
      const data = response.data.pricebook;
      console.log(data)
      setPriceBook(data);

    } catch (error) {
      console.error(error);
    }
  };

  const [supplierData, setsupplierData] = useState([]);

  useEffect(() => {
    fetchDataSupplier();
  }, [decryptedAccessToken]);

  const fetchDataSupplier = async () => {
    try {
      const response = await axios.get(
        `${API_URL}get/supplier`,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data;
      console.log(data)
      setsupplierData(data);
    } catch (error) {
      console.error(error);
    }
  };

  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    fetchDataContacts();
  }, [decryptedAccessToken]);

  const fetchDataContacts = async () => {
    try {
      const response = await axios.get(
        `${API_URL}allcontacts`,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data.contact;
      // console.log(data)
      setContacts(data);
    } catch (error) {
      console.error(error);
    }
  };
  const [users, setUsers] = useState([]);

  useEffect(() => {
    fetchDatausers();
  }, [decryptedAccessToken]);

  const fetchDatausers = async () => {
    try {
      const response = await axios.get(
        `${API_URL}get/all/users`,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data;
      console.log(data);
      setUsers(data);
    } catch (error) {
      console.error(error);
    }
  };

  const [accountName, setAccountName] = useState([]);
  useEffect(() => {
    fetchDataAccountName();
  }, [decryptedAccessToken]);

  const fetchDataAccountName = async () => {
    try {
      const response = await axios.get(
        `${API_URL}all/accounts`,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data.accounts;
      setAccountName(data);
      console.log(data)
    } catch (error) {
      console.error(error);
    }
  };



  const [surveyors, setSurveyor] = useState([]);

  useEffect(() => {
    fetchData6();
  }, [decryptedAccessToken]);

  const fetchData6 = async () => {
    try {
      const response = await axios.get(
        `${API_URL}get/surveyor`,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data;
      // console.log(JSON.stringify(data))
      setSurveyor(data);
    } catch (error) {
      console.error(error);
    }
  };



  const [companyData, setcompanyData] = useState([]);
  useEffect(() => {
    fetchDataCompany();
  }, [decryptedAccessToken]);

  const fetchDataCompany = async () => {
    try {
      const response = await axios.get(
        `${API_URL}get/all/rwoodcompany`,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data.rwoodcompany;
      // console.log(data)
      setcompanyData(data);
    } catch (error) {
      console.error(error);
    }
  };

  const [contractDocument, setcontractDocument] = useState([]);
  useEffect(() => {
    fetchDocument();
  }, [decryptedAccessToken]);

  const fetchDocument = async () => {
    try {
      const response = await axios.get(
        `${API_URL}get/all/contract_documents`,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data.contract;
      console.log(data)
      setcontractDocument(data);
    } catch (error) {
      console.error(error);
    }
  };

  const [traderData, setTraderData] = useState([]);

  useEffect(() => {
    fetchDataTrader();
  }, [decryptedAccessToken]);

  const fetchDataTrader = async () => {
    try {
      const response = await axios.get(
        `${API_URL}get/all/rwoodusers`,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data.rwoodusers;
      // console.log(data)
      setTraderData(data);
    } catch (error) {
      console.error(error);
    }
  };
  const navigate = useNavigate();
  const [formData, setFromdata] = useState({

    account_record: "",

  });


  const handleChange = (e) => {

    setFromdata({ ...formData, [e.target.name]: e.target.value });

  };

  const handleSubmit = (event) => {

    event.preventDefault();


    const encryptedAccessToken = localStorage.getItem('access_token');
    const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';
    if (encryptedAccessToken) {
      const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);

    fetch(`${API_URL}add/record`, {
              method: "POST",
              body: JSON.stringify(formData),
              headers: { Authorization: `Bearer ${decryptedAccessToken}`,
              'Content-Type': 'application/json' },
            })
              .then((response) => response.json())

              .then((response) => {

                  console.log(response)
                  console.log(response.status)
                if (response.message === "record addedd successfully") {
                                  const account_record_new = response.account_type;

                                  navigate("/contract/shipment-by-contract/"+id, {

                                    state: { account_record: account_record_new },

                                  });

                                }
              })
            }

    };

// opportunity information
const [formDataopp, setFromdataopp] = useState({

  type_of_new_record: "",

});


const handleChangeOpp = (e) => {

  setFromdataopp({ ...formDataopp, [e.target.name]: e.target.value });

};

const handleSubmitOpp = (event) => {
  event.preventDefault();
  const encryptedAccessToken = localStorage.getItem('access_token');
  const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';
  if (encryptedAccessToken) {
    const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);

  fetch(`${API_URL}add/new/record`, {
            method: "POST",
            body: JSON.stringify(formDataopp),
            headers: { Authorization: `Bearer ${decryptedAccessToken}`,
            'Content-Type': 'application/json' },
          })
            .then((response) => response.json())

            .then((response) => {
              if (response.message === "record addedd successfully") {
                                const record_type_new = response.account_type;

                                navigate("/contract/contract-by-opportunitys/"+id, {

                                  state: { record_type: record_type_new },

                                });
                              }
            })
          }

  };

  const [formDatatcf, setFromdatatcf] = useState({

    account_record: "",

  });


  const handleChangetcf = (e) => {

    setFromdata({ ...formData, [e.target.name]: e.target.value });

  };

  const handleSubmittcf = (event) => {

    event.preventDefault();


    const encryptedAccessToken = localStorage.getItem('access_token');
    const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';
    if (encryptedAccessToken) {
      const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);

    fetch(`${API_URL}add/record`, {
              method: "POST",
              body: JSON.stringify(formData),
              headers: { Authorization: `Bearer ${decryptedAccessToken}`,
              'Content-Type': 'application/json' },
            })
              .then((response) => response.json())

              .then((response) => {

                  console.log(response)
                  console.log(response.status)
                if (response.message === "record addedd successfully") {
                                  const account_record_new = response.account_type;

                                  navigate("/contract/contract-by-tcf/"+id, {

                                    state: { account_record: account_record_new },

                                  });

                                }
              })
            }

    };



  const params = useParams();
  const id = params.id;
  const userRole = localStorage.getItem("role");
  console.log(userRole);
  const { username, token } = useContext(TokenContext);
  const [datas, setDatas] = useState([]);
  const [opportunity, setOpportunity] = useState([]);
  const [conShipment, setContractship]=useState([]);
  const [tcf, setTcf]=useState([]);
  const [orderData, setOrdersData]=useState([])

  // const [datas1, setDatas1] = useState([]);

  // useEffect(() => {

  //   fetchData1();
  // }, [decryptedAccessToken]);

  // const fetchData1 = async () => {
  //   try {
  //     const response = await axios.get(`${API_URL}accounts/all/1', {
  //       headers: { Authorization: `Bearer ${decryptedAccessToken}` },        }
  //     );
  //     const data = response.data[0].opportunity;
  //     const dataQuotes = response.data[0].quotes;
  //     setQuotes(dataQuotes          )
  //     setDatas1(data);
  //     console.log(dataQuotes)
  //          setTimeout(()=>{

  //     $('#opp').DataTable();
  //     $('#tcf').DataTable();
  //       },500)
  //   } catch (error) {
  //     // console.log(error);
  //   }
  // };



  const [data1, setData1] = useState([]);
  const [isEditMode, setIsEditMode] = useState(true);
  const [editedData, setEditedData] = useState([]);
  const handleEditClick = () => {
    setIsEditMode(true);
    setEditedData(data);
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setDatas((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCancelEdit = () => {
    setIsEditMode(false);
    setEditedData(data);
    setTimeout(() => {
      $('#opportunity').DataTable();
    $('#history').DataTable();
    $('#tcf').DataTable();
    $('#orders').DataTable();
    $('#history').DataTable();
    $('#document').DataTable();
    $('#shipment').DataTable();

  }, 100);
  };


  const handleSaveEdit = (e) => {
  if(datas.contract_record_type === "Buyer - Long Term" ){

    e.preventDefault();
    const responseData = {
      "locked": datas.locked,
      "currency": datas.currency,
      "exchange_rate": datas.exchange_rate,
      "contract_record_type": datas.contract_record_type,
      "Rwood_Contract_No": datas.Rwood_Contract_No,
      "account_name": datas.account_name,
      "contract_status": datas.contract_status,
      "pending_matters": datas.pending_matters,
      "remark": datas.remark,
      // "customer_signed_date":startDates3,
      "master_conference_reference": datas.master_conference_reference,
      // "renewal_date": renewalDate,
      "renewal_contract_number": datas.renewal_contract_number,
      "company": datas.company,
      "trader_name": datas.trader_name,
      "contract_owner": datas.contract_owner,
      "price_book": datas.price_book,
      "supplier": datas.supplier,
      "status": datas.status,
      "contract_term_in_months": datas.contract_term_in_months,
      "contract_start_date": contractdate,
      "owner_expiration_notice": datas.owner_expiration_notice,
      "origin": datas.origin,
      "interlab_tolerance": datas.interlab_tolerance,
      "commodity": datas.commodity,
      "contract_quantity_in_MT": datas.contract_quantity_in_MT,
      "port_of_loading": datas.port_of_loading,
      "destination": datas.destination,
      "pricing_terms": datas.pricing_terms,
      "price_adjust_clause": datas.price_adjust_clause,
      "bonus_penalty_clause": datas.bonus_penalty_clause,
      "payment_terms": datas.payment_terms,
      "general_terms": datas.general_terms,
      "shipping_city": datas.shipping_city,
      "hs_code": datas.hs_code,
      "applicable_law": datas.applicable_law,
      "arbitration": datas.arbitration,
      "lc_type": datas.lc_type,
      "payment_details": datas.payment_details,
      "master_lc_tolerance": datas.master_lc_tolerance,
      "lc_validity_no_of_days": datas.lc_validity_no_of_days,
      "loading_rate_per_day": datas.loading_rate_per_day,
      "master_lc_presentation_days": datas.master_lc_presentation_days,
      "lc_opening_days": datas.lc_opening_days,
      "lc_opening_days_in_words": datas.lc_opening_days_in_words,
      "work_days_for_time_to_copy_doc_to_buyer":datas.work_days_for_time_to_copy_doc_to_buyer,
      "document_presentation_period": datas.document_presentation_period,
      "list_of_documents_required": datas.list_of_documents_required,
      "special_terms": datas.special_terms,
      // "company_signed_date": startDates1,
      "price_PMT": datas.price_PMT,
      "price_PMT_in_words": datas.price_PMT_in_words,
      "price_basis": datas.price_basis,
      "price_type": datas.price_type,
      "price_basis_GAR": datas.price_basis_GAR,
      "price_adjustment_clause": datas.price_adjustment_clause,
      "description": datas.description,
      "rejections": datas.rejections,
      "rwood_surveryor_agent": datas.rwood_surveryor_agent,
      "surveyor_2": datas.surveyor_2,
      "surveyor_3": datas.surveyor_3,
      "kind_attention": datas.kind_attention,
      "customer_signed_by": datas.customer_signed_by,
      "customer_signed_title": datas.customer_signed_title,
      "company_signed_by": datas.company_signed_by,
      "lc_presentation_period": datas.lc_presentation_period,
      "product_description": datas.product_description,
      "billing_street": datas.billing_street,
      "billing_zip_postal_code": datas.billing_zip_postal_code,
      "billing_city": datas.billing_city,
      "billing_state_or_province": datas.billing_state_or_province,
      "billing_country": datas.billing_country
    };

    console.log(JSON.stringify(responseData))
    fetch(`${API_URL}update/contract/${id}`, {
      method: 'PUT',
      body: JSON.stringify(responseData),
      headers: { Authorization: `Bearer ${decryptedAccessToken}`,
              'Content-Type': 'application/json' },

    })
      .then((response) => response.json())
      .then((updatedData) => {
        console.log(updatedData)
        if(updatedData.msg==='contract updated successfully'){
          toast.success("Contract Updated Successfully", {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          fetchData();
      }

        setData(updatedData);
        setIsEditMode(false);
      })
      .catch((error) => console.error(error));
  };
  if(datas.contract_record_type === "Buyer - Spot" ){

    e.preventDefault();
    const responseData = {
      "locked": datas.true,
      "currency": datas.currency,
      "exchange_rate": datas.exchange_rate,
      "contract_record_type": datas.contract_record_type,
      "Rwood_Contract_No": datas.Rwood_Contract_No,
      "account_name": datas.account_name,
      "original_contract_recieved_date":startDates2,
      "contract_status": datas.contract_status,
      "pending_matters": datas.pending_matters,
      "remark": datas.remark,
      "master_conference_reference": datas.master_conference_reference,
      "renewal_date": renewalDate,
      "renewal_contract_number": datas.renewal_contract_number,
      "company": datas.company,
      "trader_name": datas.trader_name,
      "contract_owner": datas.contract_owner,
      "price_book": datas.price_book,
      "supplier": datas.supplier,
      "status": datas.status,
      "contract_term_in_months": datas.contract_term_in_months,
      "contract_start_date": startDates,
      "owner_expiration_notice": datas.owner_expiration_notice,
      "origin": datas.origin,
      "interlab_tolerance": datas.interlab_tolerance,
      "commodity": datas.commodity,
      "contract_quantity_in_MT": datas.contract_quantity_in_MT,
      "port_of_loading": datas.port_of_loading,
      "destination": datas.destination,
      "pricing_terms": datas.pricing_terms,
      "price_adjust_clause": datas.price_adjust_clause,
      "bonus_penalty_clause": datas.bonus_penalty_clause,
      "payment_terms": datas.payment_terms,
      "general_terms": datas.general_terms,
      "shipping_city": datas.shipping_city,
      "hs_code": datas.hs_code,
      "applicable_law": datas.applicable_law,
      "arbitration": datas.arbitration,
      "ontract_effective_date":effective,
      "lc_type": datas.lc_type,
      "company_signed_date": startDates1,
      "customer_signed_date":startDates3,
      "payment_details": datas.payment_details,
      "master_lc_tolerance": datas.master_lc_tolerance,
      "lc_validity_no_of_days": datas.lc_validity_no_of_days,
      "loading_rate_per_day": datas.loading_rate_per_day,
      "master_lc_presentation_days": datas.master_lc_presentation_days,
      "lc_opening_days": datas.lc_opening_days,
      "lc_opening_days_in_words": datas.lc_opening_days_in_words,
      "work_days_for_time_to_copy_doc_to_buyer":datas.work_days_for_time_to_copy_doc_to_buyer,
      "datas.work_days_for_time_to_copy_doc_to_buyer":datas.work_days_for_time_to_copy_doc_to_buyer,
      "document_presentation_period": datas.document_presentation_period,
      "list_of_documents_required": datas.list_of_documents_required,
      "special_terms": datas.special_terms,
      "price_PMT": datas.price_PMT,
      "price_PMT_in_words": datas.price_PMT_in_words,
      "price_basis": datas.price_basis,
      "price_type": datas.price_type,
      "price_basis_GAR": datas.price_basis_GAR,
      "price_adjustment_clause": datas.price_adjustment_clause,
      "description": datas.description,
      "rejections": datas.rejections,
      "rwood_surveryor_agent": datas.rwood_surveryor_agent,
      "surveyor_2": datas.surveyor_2,
      "surveyor_3": datas.surveyor_3,
      "kind_attention": datas.kind_attention,
      "customer_signed_by": datas.customer_signed_by,
      "customer_signed_title": datas.customer_signed_title,
      "original_contract_sent_date": startDates4,
      "company_signed_by": datas.company_signed_by,
      "lc_presentation_period": datas.lc_presentation_period,
      "product_description": datas.product_description,
      "billing_street": datas.billing_street,
      "billing_zip_postal_code": datas.billing_zip_postal_code,
      "billing_city": datas.billing_city,
      "billing_state_or_province": datas.billing_state_or_province,
      "billing_country": datas.billing_country,
      };


    console.log(JSON.stringify(responseData))
    fetch(`${API_URL}update/contract/${id}`, {
      method: 'PUT',
      body: JSON.stringify(responseData),
      headers: { Authorization: `Bearer ${decryptedAccessToken}`,
              'Content-Type': 'application/json' },

    })
      .then((response) => response.json())
      .then((updatedData) => {
        console.log(updatedData)
        setTimeout(() => {
          $('#opportunity').DataTable();
        $('#history').DataTable();
        $('#tcf').DataTable();
        $('#orders').DataTable();
        $('#history').DataTable();
        $('#document').DataTable();
        $('#shipment').DataTable();


      }, 100);
        if(updatedData.msg==='contract updated successfully'){
          toast.success("Contract Updated Successfully", {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          fetchData();
      }

        setData(updatedData);
        setIsEditMode(false);
      })
      .catch((error) => console.error(error));
  };
  if(datas.contract_record_type === "Supplier" ){

    e.preventDefault();
    const responseData = {
      "locked": datas.locked,
      "currency": datas.currency,
      "exchange_rate": datas.exchange_rate,
      "contract_record_type": datas.contract_record_type,
      "Rwood_Contract_No": datas.Rwood_Contract_No,
      "account_name": datas.account_name,
      "contract_status": datas.contract_status,
      "pending_matters": datas.pending_matters,
      "remark": datas.remark,
      "master_conference_reference": datas.master_conference_reference,
      "renewal_date": renewalDate,
      "renewal_contract_number": datas.renewal_contract_number,
      "company": datas.company,
      "trader_name": datas.trader_name,
      "contract_owner": datas.contract_owner,
      "price_book": datas.price_book,
      "supplier": datas.supplier,
      "status": datas.status,
      "contract_term_in_months": datas.contract_term_in_months,
      "contract_start_date": startDates,
      "owner_expiration_notice": datas.owner_expiration_notice,
      "origin": datas.origin,
      "interlab_tolerance": datas.interlab_tolerance,
      "commodity": datas.commodity,
      "contract_quantity_in_MT": datas.contract_quantity_in_MT,
      "port_of_loading": datas.port_of_loading,
      "destination": datas.destination,
      "pricing_terms": datas.pricing_terms,
      "price_adjust_clause": datas.price_adjust_clause,
      "bonus_penalty_clause": datas.bonus_penalty_clause,
      "payment_terms": datas.payment_terms,
      "general_terms": datas.general_terms,
      "shipping_city": datas.shipping_city,
      "hs_code": datas.hs_code,
      "company_signed_date": startDates1,
      "applicable_law": datas.applicable_law,
      "arbitration": datas.arbitration,
      "lc_type": datas.lc_type,
      "payment_details": datas.payment_details,
      "master_lc_tolerance": datas.master_lc_tolerance,
      "lc_validity_no_of_days": datas.lc_validity_no_of_days,
      "loading_rate_per_day": datas.loading_rate_per_day,
      "master_lc_presentation_days": datas.master_lc_presentation_days,
      "lc_opening_days": datas.lc_opening_days,
      "lc_opening_days_in_words": datas.lc_opening_days_in_words,
      "work_days_for_time_to_copy_doc_to_buyer":datas.work_days_for_time_to_copy_doc_to_buyer,
      "document_presentation_period": datas.document_presentation_period,
      "list_of_documents_required": datas.list_of_documents_required,
      "special_terms": datas.special_terms,
      "price_PMT": datas.price_PMT,
      "price_PMT_in_words": datas.price_PMT_in_words,
      "price_basis": datas.price_basis,
      "price_type": datas.price_type,
      "price_basis_GAR": datas.price_basis_GAR,
      "price_adjustment_clause": datas.price_adjustment_clause,
      "description": datas.description,
      "rejections": datas.rejections,
      "rwood_surveryor_agent": datas.rwood_surveryor_agent,
      "surveyor_2": datas.surveyor_2,
      "surveyor_3": datas.surveyor_3,
      "kind_attention": datas.kind_attention,
      "customer_signed_by": datas.customer_signed_by,
      "customer_signed_title": datas.customer_signed_title,
      "company_signed_by": datas.company_signed_by,
      "lc_presentation_period": datas.lc_presentation_period,
      "product_description": datas.product_description,
      "original_contract_recieved_date":startDates2,
      "original_contract_sent_date": startDates4,
      "billing_street": datas.billing_street,
      "billing_zip_postal_code": datas.billing_zip_postal_code,
      "billing_city": datas.billing_city,
      "billing_state_or_province": datas.billing_state_or_province,
      "billing_country": datas.billing_country,
      "customer_signed_date":startDates3
      };



    console.log(JSON.stringify(responseData))
    fetch(`${API_URL}update/contract/${id}`, {
      method: 'PUT',
      body: JSON.stringify(responseData),
      headers: { Authorization: `Bearer ${decryptedAccessToken}`,
              'Content-Type': 'application/json' },

    })
      .then((response) => response.json())
      .then((updatedData) => {
        setTimeout(() => {
          $('#opportunity').DataTable();
        $('#history').DataTable();
        $('#tcf').DataTable();
        $('#orders').DataTable();
        $('#history').DataTable();
        $('#document').DataTable();
        $('#shipment').DataTable();


      }, 100);
        console.log(updatedData)
        if(updatedData.msg==='contract updated successfully'){
          toast.success("Contract Updated Successfully", {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          fetchData();
      }

        setData(updatedData);
        setIsEditMode(false);
      })
      .catch((error) => console.error(error));
  };
  if(datas.contract_record_type === "Supplier - Long Term" ){

    e.preventDefault();
    const responseData = {
      "locked": datas.locked,
      "currency": datas.currency,
      "exchange_rate": datas.exchange_rate,
      "contract_record_type": datas.contract_record_type,
      "Rwood_Contract_No": datas.Rwood_Contract_No,
      "account_name": datas.account_name,
      "contract_status": datas.contract_status,
      "pending_matters": datas.pending_matters,
      "remark": datas.remark,
      "master_conference_reference": datas.master_conference_reference,
      "renewal_date": renewalDate,
      "renewal_contract_number": datas.renewal_contract_number,
      "company": datas.company,
      "trader_name": datas.trader_name,
      "contract_owner": datas.contract_owner,
      "price_book": datas.price_book,
      "supplier": datas.supplier,
      "status": datas.status,
      "contract_term_in_months": datas.contract_term_in_months,
      "contract_start_date": contractdate,
      "owner_expiration_notice": datas.owner_expiration_notice,
      "origin": datas.origin,
      "interlab_tolerance": datas.interlab_tolerance,
      "commodity": datas.commodity,
      "contract_quantity_in_MT": datas.contract_quantity_in_MT,
      "port_of_loading": datas.port_of_loading,
      "destination": datas.destination,
      "pricing_terms": datas.pricing_terms,
      "price_adjust_clause": datas.price_adjust_clause,
      "bonus_penalty_clause": datas.bonus_penalty_clause,
      "payment_terms": datas.payment_terms,
      "general_terms": datas.general_terms,
      "shipping_city": datas.shipping_city,
      "hs_code": datas.hs_code,
      "applicable_law": datas.applicable_law,
      "arbitration": datas.arbitration,
      "lc_type": datas.lc_type,
      "company_signed_date": startDates1,
      "payment_details": datas.payment_details,
      "master_lc_tolerance": datas.master_lc_tolerance,
      "lc_validity_no_of_days": datas.lc_validity_no_of_days,
      "loading_rate_per_day": datas.loading_rate_per_day,
      "master_lc_presentation_days": datas.master_lc_presentation_days,
      "lc_opening_days": datas.lc_opening_days,
      "lc_opening_days_in_words": datas.lc_opening_days_in_words,
      "work_days_for_time_to_copy_doc_to_buyer":datas.work_days_for_time_to_copy_doc_to_buyer,
      "document_presentation_period": datas.document_presentation_period,
      "list_of_documents_required": datas.list_of_documents_required,
      "special_terms": datas.special_terms,
      "price_PMT": datas.price_PMT,
      "price_PMT_in_words": datas.price_PMT_in_words,
      "price_basis": datas.price_basis,
      "price_type": datas.price_type,
      "price_basis_GAR": datas.price_basis_GAR,
      "price_adjustment_clause": datas.price_adjustment_clause,
      "description": datas.description,
      "rejections": datas.rejections,
      "rwood_surveryor_agent": datas.rwood_surveryor_agent,
      "surveyor_2": datas.surveyor_2,
      "surveyor_3": datas.surveyor_3,
      "kind_attention": datas.kind_attention,
      "customer_signed_by": datas.customer_signed_by,
      "customer_signed_title": datas.customer_signed_title,
      "company_signed_by": datas.company_signed_by,
      "lc_presentation_period": datas.lc_presentation_period,
      "product_description": datas.product_description,
      "original_contract_recieved_date":startDates2,
      "billing_street": datas.billing_street,
      "contract_effective_date":contractEffective,
      "billing_zip_postal_code": datas.billing_zip_postal_code,
      "billing_city": datas.billing_city,
      "original_contract_sent_date": startDates4,
      "billing_state_or_province": datas.billing_state_or_province,
      "billing_country": datas.billing_country,
      "customer_signed_date":startDates3
      };




    console.log(JSON.stringify(responseData))
    fetch(`${API_URL}update/contract/${id}`, {
      method: 'PUT',
      body: JSON.stringify(responseData),
      headers: { Authorization: `Bearer ${decryptedAccessToken}`,
              'Content-Type': 'application/json' },

    })
      .then((response) => response.json())
      .then((updatedData) => {
        console.log(updatedData)
        setTimeout(() => {
          $('#opportunity').DataTable();
        $('#history').DataTable();
        $('#tcf').DataTable();
        $('#orders').DataTable();
        $('#history').DataTable();
        $('#document').DataTable();
        $('#shipment').DataTable();


      }, 100);
        if(updatedData.msg==='contract updated successfully'){
          toast.success("Contract Updated Successfully", {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          fetchData();
      }

        setData(updatedData);
        setIsEditMode(false);
      })
      .catch((error) => console.error(error));
  };
}

  return (
    <div id="view-page">
      <Header />
      <div className="contactAccount">

    <div className="page-header" id='add-account'>
    <div>
      <h2 className="main-content-title tx-24 mg-b-5">Contract Details</h2>
      <ol className="breadcrumb">
        <li className="breadcrumb-item active" aria-current="page">Dashboard / Contract Details</li>
      </ol>
    </div>

  </div>
  </div>

  <>
  {datas.contract_record_type === "Buyer - Long Term" ? (
   <>
   {isEditMode ? (
   <div className="tasks">
   <p className='edit-btn'>
   <button className='btn btn-primary' onClick={handleSaveEdit}>Save</button>
   <button className="btn btn-primary" onClick={handleCancelEdit}>Cancel</button>


   </p>

   <Row className="mb-3">
              <h4 className="heading">Contract Information</h4>
              <Form.Group as={Col} md="6" id='lockeds'>
            <input type='checkbox' name='locked' onChange={(e) => setCheck1(e.target.checked) } /><label>Locked</label>
          </Form.Group>
           <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Company"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="company"
                    placeholder="Company"
                    className="error-validation"
                    value={datas.company || ""}
                                          onChange={handleChangeInput}
                  >
                    <option value="">Select </option>
                    {companyData.map((x, i) => {
                      return (
                        <option key={i} value={x.company_name}>
                          {x.company_name}
                        </option>
                      );
                    })}
                  </Form.Select>

                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Currency"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="currency"
                    placeholder="Currency"
                    className="error-validation"
                    value={datas.currency || ""}
onChange={handleChangeInput}
                  >
                    <option value="">Select </option>
                    <option value="USD">USD </option>
                    <option value="IDR">IDR </option>
                  </Form.Select>

                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Trader Name"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="trader_name"
                    placeholder="Trader Name"
                    value={datas.trader_name || ""}
onChange={handleChangeInput}
                  >
                    <option value="">Select </option>
                    {traderData.map((x, i) => {
                      return (
                        <option key={i} value={x.rwood_username}>
                          {x.rwood_username}
                        </option>
                      );
                    })}
                  </Form.Select>

                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Account Name"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="account_name"
                    placeholder="Account Name"
                    className="error-validation"
                    value={datas.account_name || ""}
onChange={handleChangeInput}
                  >
                    <option value="">Select </option>
                    {accountName.map((x) => {
                      return (
                        <option value={x.account_name}>{x.account_name}</option>
                      );
                    })}
                  </Form.Select>

                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Exchange rate"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Exchange rate "
                    name="exchange_rate"
                     defaultValue={datas.exchange_rate || ""}
onChange={handleChangeInput}
                  />

                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Contract record type"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Contract record type"
                    name="contract_record_type" className='record-type'
                    defaultValue={datas.contract_record_type}
                    onChange={handleChangeInput}
                  />

                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Contract Owner"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Contract Owner"
                    name="contract_owner"
                   defaultValue={datas.contract_owner || ""}
onChange={handleChangeInput}
                  />

                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">

              <FloatingLabel controlId="floatingSelect"
              className='dropDown' label="Price book">
    <Form.Select aria-label="Floating label select example"
    name="price_book"
    placeholder="Price book"
     value={datas.price_book || ""}
onChange={handleChangeInput}
    >
      <option value='' >Select</option>
       {
          priceBook.map((x)=>{
              return(
                  <option value={x.price_book_name}>{x.price_book_name}</option>
              )
          })
       }
    </Form.Select>

  </FloatingLabel>
              </Form.Group>


              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Rwood contract no"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Rwood contract no"
                    name="Rwood_Contract_No"
                   defaultValue={datas.Rwood_Contract_No || ""}
onChange={handleChangeInput}
                  />

                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Status"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="status"
                    placeholder="Status"
                    className="error-validation"
                    value={datas.status || ""}
onChange={handleChangeInput}
                  >
                    <option value="">Select </option>
                    <option value="Draft">Draft </option>
                    <option value="Approved">Approved </option>
                  </Form.Select>

                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Contract Status"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="contract_status"
                    placeholder="Contract Status"
                    value={datas.contract_status || ""}
onChange={handleChangeInput}
                  >
                    <option value="">Select </option>
                    <option value="Draft">Draft </option>
                    <option value="Approved">Approved </option>
                  </Form.Select>

                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Contract term in months"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Contract term in months"
                    name="contract_term_in_months"
                    defaultValue={datas.contract_term_in_months || ""}
onChange={handleChangeInput}
                  />

                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Pending Matters"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Pending Matters"
                    style={{ height: "100px!important" }}
                    name="pending_matters"
           defaultValue={datas.pending_matters     || ""}
onChange={handleChangeInput}
                  />

                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel controlId="floatingTextarea2" label="Remark">
                  <Form.Control
                    as="textarea"
                    placeholder="Remark"
                    style={{ height: "100px!important" }}
                    name="remark"
                    defaultValue={datas.remark || ""}
onChange={handleChangeInput}
                  />

                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Contract Start Date"
                  className="mb-3 date-picker"
                >
                  <DatePicker
                    selected={ContractStart}
                    onChange={(date) => setContractStart(date)}
                    placeholderText="Choose Date"
                    dateFormat="yyyy-MM-dd"
                  />

                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Contract Effective Date"
                  className="mb-3 date-picker"
                >
                  <DatePicker
                    selected={start}
                    onChange={(date) => setStart(date)}
                    customInput={<ExampleCustomInput2 />}
                    dayClassName={() => "example-datepicker-day-class"}
                    popperClassName="example-datepicker-class"
                    todayButton="TODAY"
                    dateFormat="yyyy-MM-dd"
                    placeholderText="Choose Date"
                  />

                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Master conference reference"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Master conference reference"
                    name="master_conference_reference"
                   defaultValue={datas.master_conference_reference || ""}
onChange={handleChangeInput}
                  />

                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Renewal date"
                  className="mb-3 date-picker"
                >
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    customInput={<ExampleCustomInput />}
                    dayClassName={() => "example-datepicker-day-class"}
                    popperClassName="example-datepicker-class"
                    todayButton="TODAY"
                    dateFormat="yyyy-MM-dd"
                    placeholderText="Choose Date"
                  />

                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Renewal contract number"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Renewal contract number "
                    name="renewal_contract_number "
                    defaultValue={datas.renewal_contract_number || ""}
onChange={handleChangeInput}
                  />

                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Commodity and Contracted Quantity</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Origin"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Origin"
                    name="origin"
                    defaultValue={datas.origin || ""}
onChange={handleChangeInput}
                  />

                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">

            <FloatingLabel controlId="floatingSelect"
            className='dropDown' label="Commodity">
  <Form.Select aria-label="Floating label select example"
  name="commodity"
  placeholder="Commodity"
  value={datas.commodity || ""}
onChange={handleChangeInput}

  >
    <option>---None---</option>
    <option value="Indonesian Steam Coal in Bulk (&quot;Coal&quot;)">Indonesian Steam Coal in Bulk ("Coal")</option><option value="US Coal">US Coal</option><option value="Russia Coal">Russia Coal</option><option value="SA Coal">SA Coal</option><option value="Clinker in Bulk">Clinker in Bulk</option><option value="Australian Steam Coal">Australian Steam Coal</option>
  </Form.Select>

</FloatingLabel>
            </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Interlab tolerance"
                  className="mb-3"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Interlab tolerance"
                    name="interlab_tolerance"
                    className="textarea"
                    style={{ height: "100px!important" }}
                   defaultValue={datas.interlab_tolerance || ""}
onChange={handleChangeInput}
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Contract quantity in MT"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Contract quantity in MT"
                    name="contract_quantity_in_MT"
                    className="error-validation"
                   defaultValue={datas.contract_quantity_in_MT || ""}
onChange={handleChangeInput}
                  />
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Market Information</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Destination"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Destination"
                    name="destination"
                   defaultValue={datas.destination || ""}
onChange={handleChangeInput}
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="12">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Pricing terms"
                  className="mb-3"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Pricing terms"
                    name="pricing_terms"
                    style={{ height: "100px!important" }}
                   defaultValue={datas.pricing_terms || ""}
onChange={handleChangeInput}
                  />
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Price and Price Adjustment</h4>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Price  Adjustment Clause"
                  className="mb-3"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Price  Adjustment Clause"
                    name="price_adjustment_clause"
                    style={{ height: "100px!important" }}
                    className="error-validation"
                    defaultValue={datas.price_adjustment_clause || ""}
onChange={handleChangeInput}
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Bonus penalty clause"
                  className="mb-3"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Bonus penalty clause"
                    name="bonus_penalty_clause"
                    style={{ height: "100px!important" }}
                    defaultValue={datas.bonus_penalty_clause || ""}
onChange={handleChangeInput}
                  />
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Payment terms"
                  className="mb-3"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Payment terms"
                    name="payment_terms"
                    defaultValue={datas.payment_terms || ""}
onChange={handleChangeInput}
                  />

                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="General terms"
                  className="mb-3"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="General terms"
                    name="general_terms"
                    defaultValue={datas.general_terms || ""}
                    onChange={handleChangeInput}
                  />

                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Rejections"
                  className="mb-3"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Rejections"
                    name="rejections"
                  defaultValue={datas.rejections || ""}
onChange={handleChangeInput}
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Hs code"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Hs code"
                    name="hs_code"
                   defaultValue={datas.hs_code || ""}
onChange={handleChangeInput}
                  />
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Payment Information</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="LC Type"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="lc_type"
                    placeholder="LC Type"
                    value={datas.lc_type || ""}
onChange={handleChangeInput}
                  >
                    <option value="">Select</option>
                    <option value="">--None--</option><option value="Irrevocable transferrable Letter of Credit">Irrevocable transferrable Letter of Credit</option><option value="Irrevocable Non transferrable Letter of Credit">Irrevocable Non transferrable Letter of Credit</option><option value="Irrevocable Letter of Credit">Irrevocable Letter of Credit</option><option value="Irrevocable Back to Back LC">Irrevocable Back to Back LC</option><option value="Payment and Receipt">Payment and Receipt</option><option value="Standby_letter_of_credit_c">Standby Letter of Credit</option>
                  </Form.Select>
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Master lc presentation days"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Master lc presentation days"
                    name="master_lc_presentation_days"
                    defaultValue={datas.master_lc_presentation_days || ""}
onChange={handleChangeInput}
                  />
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Lc opening days"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Lc opening days"
                    name="lc_opening_days"
                    defaultValue={datas.lc_opening_days || ""}
onChange={handleChangeInput}
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Master lc tolerance"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Master lc tolerance"
                    name="master_lc_tolerance"
                    defaultValue={datas.master_lc_tolerance || ""}
onChange={handleChangeInput}
                  />
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Lc opening days in words"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Lc opening days in words"
                    name="lc_opening_days_in_words"
                  defaultValue={datas.lc_opening_days_in_words || ""}
onChange={handleChangeInput}
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Lc validity no of days"
                  className="mb-3"
                >
                  <Form.Control
                    type="number"
                    placeholder="Lc validity no of days"
                    name="lc_validity_no_of_days"
                    defaultValue={datas.lc_validity_no_of_days || ""}
onChange={handleChangeInput}
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Document presentation period"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Document presentation period"
                    name="document_presentation_period"
                    defaultValue={datas.document_presentation_period || ""}
onChange={handleChangeInput}
                  />

                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Latest date of shipment"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Latest date of shipment"
                    name="latest_date_of_shipment"
                   defaultValue={datas.latest_date_of_shipment || ""}
onChange={handleChangeInput}
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Loading rate per day"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Loading rate per day"
                    name="loading_rate_per_day"
                   defaultValue={datas.loading_rate_per_day || ""}
onChange={handleChangeInput}
                  />
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Important Terms Information</h4>
              <Form.Group as={Col} md="12">
                <FloatingLabel
                  controlId="floatingInput"
                  label="List of documents required"
                  className="mb-3"
                >
                  <Form.Control
                    as="textarea"
                    style={{ height: "200px!important" }}
                    placeholder="List of documents required"
                    name="list_of_documents_required"
                   defaultValue={datas.list_of_documents_required || ""}
onChange={handleChangeInput}
                  />
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Signature Information</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Kind attention"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="kind_attention"
                    placeholder="Kind attention"
                    value={datas.kind_attention || ""}
onChange={handleChangeInput}
                  >
                    <option value="">Select </option>
                    {contacts.map((x) => {
                      return (
                        <option value={x.contact_full_name}>
                          {x.contact_full_name}
                        </option>
                      );
                    })}
                  </Form.Select>
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Customer signed by"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="customer_signed_by"
                    placeholder="Customer signed by"
                    value={datas.customer_signed_by || ""}
onChange={handleChangeInput}
                  >
                    <option value="">Select </option>
                    {contacts.map((x) => {
                      return (
                        <option value={x.contact_full_name}>
                          {x.contact_full_name}
                        </option>
                      );
                    })}
                  </Form.Select>
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Customer signed title"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Customer signed title"
                    name="customer_signed_title"
                 defaultValue={datas.customer_signed_title || ""}
onChange={handleChangeInput}
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Company signed by"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="company_signed_by"
                    placeholder="Company signed by"
                    value={datas.company_signed_by || ""}
onChange={handleChangeInput}
                  >
                    <option value="">Select </option>
                    {users.map((x) => {
                      return <option value={x.username}>{x.username}</option>;
                    })}
                  </Form.Select>
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Original Contract Recieved Date"
                  className="mb-3 date-picker"
                >
                  <DatePicker
                    selected={received}
                    onChange={(date) => setReceived(date)}
                    customInput={<ExampleCustomInput5 />}
                    dayClassName={() => "example-datepicker-day-class"}
                    popperClassName="example-datepicker-class"
                    todayButton="TODAY"
                    dateFormat="yyyy-MM-dd"
                    placeholderText="Choose Date"
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Company Signed Date"
                  className="mb-3 date-picker"
                >
                  <DatePicker
                    selected={signed}
                    onChange={(date) => setSigned(date)}
                    customInput={<ExampleCustomInput4 />}
                    dayClassName={() => "example-datepicker-day-class"}
                    popperClassName="example-datepicker-class"
                    todayButton="TODAY"
                    dateFormat="yyyy-MM-dd"
                    placeholderText="Choose Date"
                  />

                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Original contract sent date"
                  className="mb-3 date-picker"
                >
                  <DatePicker
                    selected={sent}
                    onChange={(date) => setSent(date)}
                    customInput={<ExampleCustomInput7 />}
                    dayClassName={() => "example-datepicker-day-class"}
                    popperClassName="example-datepicker-class"
                    todayButton="TODAY"
                    dateFormat="yyyy-MM-dd"
                    placeholderText="Choose Date"
                  />
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <h4 className="heading">Other Standard Terms</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Applicable Law"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="applicable_law"
                    placeholder="Applicable Law"
                    value={datas.applicable_law || ""}
onChange={handleChangeInput}
                  >
                   <option value="">--None--</option><option value="English Law">English Law</option><option value="Singapore Law">Singapore Law</option><option value="Indonesian Law">Indonesian Law</option>
                  </Form.Select>
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Rwood surveryor agent"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="rwood_surveryor_agent"
                    placeholder="Rwood surveryor agent"
                    value={datas.rwood_surveryor_agent || ""}
onChange={handleChangeInput}
                  >
                    <option value="">Select </option>
                    {surveyors.map((x) => {
                      return (
                        <option value={x.account_name}>{x.account_name}</option>
                      );
                    })}
                  </Form.Select>
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Arbitration"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Arbitration"
                    name="arbitration"
                    defaultValue={datas.arbitration || ""}
onChange={handleChangeInput}
                  />
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Surveyor 1"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="surveyor_1"
                    placeholder="Surveyor 1"
                    value={datas.surveyor_1 || ""}
onChange={handleChangeInput}
                  >
                    <option value="">Select </option>
                    {surveyors.map((x) => {
                      return (
                        <option value={x.account_name}>{x.account_name}</option>
                      );
                    })}
                  </Form.Select>
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Surveyor 2"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="surveyor_2"
                    placeholder="Surveyor 2"
                    value={datas.surveyor_2 || ""}
onChange={handleChangeInput}
                  >
                    <option value="">Select </option>
                    {surveyors.map((x) => {
                      return (
                        <option value={x.account_name}>{x.account_name}</option>
                      );
                    })}
                  </Form.Select>
                </FloatingLabel>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <h4 className="heading">List Of Fields-Not Required</h4>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="LC Presentation Period"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="LC Presentation Period"
                    style={{ height: "100px" }}
                    name="lc_presentation_period"
                   defaultValue={datas.lc_presentation_period || ""}
onChange={handleChangeInput}
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Description"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Description"
                    style={{ height: "100px" }}
                    name="description"
                    defaultValue={datas.description || ""}
onChange={handleChangeInput}
                  />
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Product Description"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Product Description"
                    style={{ height: "100px" }}
                    name="product_description"
                    defaultValue={datas.product_description || ""}
onChange={handleChangeInput}
                  />
                </FloatingLabel>
              </Form.Group>
            </Row>
            <p className='edit-btn'>
   <button className='btn btn-primary' onClick={handleSaveEdit}>Save</button>
   <button className="btn btn-primary" onClick={handleCancelEdit}>Cancel</button>


   </p>


   </div>
   ):(
    <>
   <div className="contactAccount">
     <div className="tasks">
     <div className="edit-delete">
                  <span id="edit" onClick={handleEditClick}>
                    Edit
                  </span>
                  <span>
                    Delete
                  </span>
                  <span>
                    Activate
                  </span>
                  <span>
                    Generate Contract
                  </span>
<span>
                     Generate Contract Clauses
                  </span>
<span>
                    Shipment Checklist
                  </span>
                  </div>
       <div className="">
         <div className="row">
           <div className="col-md-12" id="head">
             <h4 className="heading">Account Information</h4>

             <hr></hr>
           </div>
         </div>
         <table class="table table-bordered account-table tables">
           <tbody>
             <tr>
               <td id="td-right"><span>Locked</span> </td>
               <td>{datas.locked}</td>
               <td id="td-right"><span>Company</span></td>
               <td>{datas.company}
               </td>

             </tr>
             <tr>
             <td id="td-right">
                 <span>Currency</span>
               </td>
               <td>
                 {datas.currency}
               </td>
               <td id="td-right">
                 <span>
                   Trader Name

                 </span>
                 </td><td>
                 {datas.trader_name}
               </td>
             </tr>
             <tr>
             <td id="td-right">
                 <span>Contract record type</span>
                 </td>
                 <td>
                  {datas.contract_record_type}
               </td>
               <td id="td-right">
                 <span>Account Name</span>
                 </td>
                 <td>
                  {datas.account_name}
               </td>
             </tr>
             <tr>

             <td id="td-right">
                 <span>Exchange Rate</span>
                 </td>
                 <td>

                 {datas.exchange_rate}
               </td>
               <td id="td-right">
                 <span>Contract Owner</span>
                 </td>
                 <td>
                 {datas.contract_owner}
               </td>

             </tr>
             <tr>

               <td id="td-right">
                   <span>Price book </span>
                   </td>
                   <td>

                   {datas.price_book}
                 </td>
                 <td id="td-right">
                   <span>Rwood contract no</span>
                   </td>
                   <td>
                   {datas.Rwood_Contract_No}
                 </td>

               </tr>
               <tr>

               <td id="td-right">
                   <span>Status</span>
                   </td>
                   <td>

                   {datas.status}
                 </td>
                 <td id="td-right">
                   <span>Contract Status</span>
                   </td>
                   <td>
                   {datas.contract_status}
                 </td>

               </tr>
               <tr>

               <td id="td-right">
                   <span>Contract term in months</span>
                   </td>
                   <td>

                   {datas.contract_term_in_months}
                 </td>
                 <td id="td-right">
                   <span>Pending Matters</span>
                   </td>
                   <td>
                   {datas.pending_matters}
                 </td>

               </tr>
               <tr>

               <td id="td-right">
                   <span>Remark</span>
                   </td>
                   <td>

                   {datas.remark}
                 </td>
                 <td id="td-right">
                   <span>Contract Start Date</span>
                   </td>
                   <td>
                   {datas.contract_start_date}
                 </td>

               </tr>

               <tr>

               <td id="td-right">
                   <span>Contract Effective Date</span>
                   </td>
                   <td>

                   {datas.contract_effective_date}
                 </td>
                 <td id="td-right">
                   <span>Master Contract Reference</span>
                   </td>
                   <td>
                   {datas.master_conference_reference}
                 </td>

               </tr>

               <tr>

               <td id="td-right">
                   <span>Renewal Date</span>
                   </td>
                   <td>

                   {datas.renewal_date}
                 </td>
                 <td id="td-right">
                   <span>Renewal contract number</span>
                   </td>
                   <td>
                   {datas.renewal_contract_number}
                 </td>

               </tr>
           </tbody>
         </table>
       </div>
       <div className="">
         <div className="row">
           <div className="col-md-12" id="head">
             <h4 className="heading">Commodity and Contracted Quantity</h4>
             <hr></hr>
           </div>
         </div>
         <table class="table table-bordered account-table tables">
           <tbody>

             <tr>
             <td id="td-right">
                 <span>Origin</span>
                 </td>
                 <td>
                 {datas.origin}
               </td>
               <td id="td-right">

                 <span>Commodity</span>
                 </td>
                 <td>
                 {datas.commodity}
               </td>

                 </tr>

             <tr>

             <td id="td-right">
                 <span>Interlab Tolerance</span>
                 </td>
                 <td>
                 {datas.interlab_tolerance}
               </td>
               <td id="td-right">
                 <span>Contract Quantity in MT</span>
                 </td>
                 <td>
                 {datas.contract_quantity_in_MT}
               </td>
               </tr>

           </tbody>
         </table>
       </div>
       <div className="">
         <div className="row">
           <div className="col-md-12" id="head">
             <h4 className="heading">Market Informatione</h4>
             <hr></hr>
           </div>
         </div>
         <table class="table table-bordered account-table tables">
           <tbody>
             <tr>
             <td id="td-right">
                 <span>Destination</span>
                </td><td>
                 {datas.destination}
               </td>
               <td id="td-right">
                 <span>Pricing Terms</span>
                </td>
                <td>
                 {datas.pricing_terms}
               </td>
               </tr>

           </tbody>
         </table>
       </div>
       <div className="">
         <div className="row">
           <div className="col-md-12" id="head">
             <h4 className="heading">Price and Price Adjustment</h4>
             <hr></hr>
           </div>
         </div>
         <table class="table table-bordered account-table tables">
           <tbody>
            <tr>
            <td id="td-right">
                 <span>Price adjustment clause</span>
                </td><td>
                {datas.price_adjustment_clause}
               </td>
               <td id="td-right">
               <span>Bonus penalty clause</span>
                </td><td>
                {datas.bonus_penalty_clause}
               </td>
             </tr>

             <tr>
            <td id="td-right">
            <span>Payment terms</span>
                </td><td>
                {datas.payment_terms}
               </td>
               <td id="td-right">
               <span>General terms</span>
                </td><td>
                 {
                   datas.general_terms
                 }
               </td>
             </tr>

             <tr>
            <td id="td-right">
            <span>Industry</span>
                </td><td>
                 {datas.industry}
               </td>
               <td id="td-right">
               <span>Imported Volume PA in MT</span>
                </td><td>
                 {
                   datas
                     .imported_volume_PA_in_MT
                 }
               </td>
             </tr>

             <tr>
            <td id="td-right">
            <span>Rejections</span>
                </td><td>
                 {datas.rejections}
               </td>
               <td id="td-right">
               <span>HS code</span>
                </td><td>
                 {datas.hs_code}
               </td>
             </tr>


           </tbody>
         </table>
       </div>


       <div className="">
         <div className="row">
           <div className="col-md-12" id="head">
             <h4 className="heading">Payment Information</h4>
             <hr></hr>
           </div>
         </div>
         <table class="table table-bordered account-table tables">
           <tbody>
             <tr>
             <td id="td-right">
                 <span>LC Type</span>
                </td><td>
                 {datas.lc_type}
               </td>
               <td id="td-right">
                 <span>Master lc presentation days</span>
                </td><td>
                 {datas.master_lc_presentation_days}
               </td>
               </tr>
               <tr>
               <td id="td-right">
                 <span>Lc opening days</span>
                </td><td>
                 {datas.lc_opening_days}
               </td>
               <td></td>
             </tr>


             <tr>
             <td id="td-right">
                 <span>Master lc tolerance</span>
                </td><td>
                 {datas.master_lc_tolerance}
               </td>
               <td id="td-right">
                 <span>Lc opening days in words</span>
                </td><td>
                 {datas.lc_opening_days_in_words}
               </td>
               </tr>



             <tr>
             <td id="td-right">
                 <span>Lc validity no of days</span>
                </td><td>
                 {datas.lc_validity_no_of_days}
               </td>
               <td id="td-right">
                 <span>Document presentation period</span>
                </td><td>
                 {datas.document_presentation_period}
               </td>
               </tr>

             <tr>
             <td id="td-right">
                 <span>Latest date of shipment</span>
                </td><td>
                 {datas.latest_date_of_shipment}
               </td>
               <td id="td-right">
                 <span>Loading rate per day</span>
                </td><td>
                 {datas.loading_rate_per_day}
               </td>
               </tr>
           </tbody>


         </table>
       </div>
       <div className="">
         <div className="row">
           <div className="col-md-12" id="head">
             <h4 className="heading">Important Terms Information</h4>
             <hr></hr>
           </div>
         </div>
         <table class="table table-bordered account-table tables">
           <tbody>
             <tr>
             <td id="td-right">
                 <span>List of documents required</span>
                </td><td>
                 {datas.list_of_documents_required}
               </td>
                <td></td>
                <td></td>
               </tr>

           </tbody>


         </table>
       </div>


       <div className="">
         <div className="row">
           <div className="col-md-12" id="head">
             <h4 className="heading">Signature Information</h4>
             <hr></hr>
           </div>
         </div>
         <table class="table table-bordered account-table tables">
           <tbody>
             <tr>
             <td id="td-right">
                 <span>kind_attention</span>
                </td><td>
                 {datas.kind_attention}
               </td>
               <td id="td-right">
                 <span>Customer signed by</span>
                </td><td>
                 {datas.customer_signed_by}
               </td>
               </tr>
               <tr>
               <td id="td-right">
                 <span>Customer signed title</span>
                </td><td>
                 {datas.customer_signed_title}
               </td>
               <td id="td-right">
                 <span>Company signed by</span>
                </td><td>
                 {datas.company_signed_by}
               </td>

             </tr>



             <tr>
             <td id="td-right">
                 <span>Original contract recieved date</span>
                </td><td>
                 {datas.original_contract_recieved_date}
               </td>
               <td id="td-right">
                 <span>Company signed date</span>
                </td><td>
                 {datas.company_signed_date}
               </td>
               </tr>



             <tr>
             <td id="td-right">
                 <span>Original contract sent date</span>
                </td><td>
                 {datas.original_contract_sent_date}
               </td>
               <td id="td-right">

                </td><td>

               </td>
               </tr>

           </tbody>


         </table>
       </div>


       <div className="">
         <div className="row">
           <div className="col-md-12" id="head">
             <h4 className="heading">Other Standard Terms</h4>
             <hr></hr>
           </div>
         </div>
         <table class="table table-bordered account-table tables">
           <tbody>
             <tr>
             <td id="td-right">
                 <span>Applicable law</span>
                </td><td>
                 {datas.applicable_law}
               </td>
               <td id="td-right">
                 <span>Rwood surveryor agent</span>
                </td><td>
                 {datas.rwood_surveryor_agent}
               </td>
               </tr>
               <tr>
               <td id="td-right">
                 <span>Arbitration</span>
                </td><td>
                 {datas.arbitration}
               </td>
               <td id="td-right">
                 <span>Surveyor 1</span>
                </td><td>
                 {datas.surveyor_1}
               </td>

             </tr>



             <tr>
             <td id="td-right">
                 <span>Surveyor 2</span>
                </td><td>
                 {datas.surveyor_2}
               </td>
               <td id="td-right">

                </td><td>

               </td>
               </tr>




           </tbody>


         </table>
       </div>



       <div className="">
         <div className="row">
           <div className="col-md-12" id="head">
             <h4 className="heading">List Of Fields-Not Required</h4>
             <hr></hr>
           </div>
         </div>
         <table class="table table-bordered account-table tables">
           <tbody>
             <tr>
             <td id="td-right">
                 <span>LC Presentation Period</span>
                </td><td>
                 {datas.lc_presentation_period}
               </td>
               <td id="td-right">
                 <span>Description</span>
                </td><td>
                 {datas.description}
               </td>
               </tr>
               <tr>
               <td id="td-right">
                 <span>Product Description</span>
                </td><td>
                 {datas.product_description}
               </td>
               <td id="td-right">

                </td><td>

               </td>

             </tr>

           </tbody>


         </table>
       </div>
     </div>
   </div>


   <div
     className="tasks view-table"
     Style="box-showdow:0px 13px 20px #d6d1d1"
   >
       <Row id="table-styles">
                  <div className="col-md-12" id="head">
                    <h4 className="heading"><span>History Information</span></h4>
                    <hr></hr>
                  </div>
                  <table id="history" className="">
                    <thead>
                      <tr>
                        <th>Field Name</th>
                        <th>New Value</th>
                        <th>Old Value</th>
                        <th>Changed By</th>
                        <th>Change Time</th>
                      </tr>
                    </thead>

                    {history.length > 0 ? (
                      <tbody>
                        {history.map((y, i) => (
                          <tr>
                            <td>{y.field_name}</td>
                            <td>{y.new_value}</td>
                            <td>{y.old_value}</td>
                            <td>{y.changed_by}</td>
                            <td>{y.change_time}</td>
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td></td>
                          <td></td>
                          <td>No data available</td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </Row>
     <Row id='table-styles'>
       <div className="col-md-12" id='head'>
     <h4 className="heading"><span>Contract Shipment</span>
     <span><Popup trigger={<a className="button add-accountrecord">  New </a>} modal>

    <form onSubmit={handleSubmit} className="add-accounts">
     <div className='form-group'>
     <label>Contract Shipment Record Type</label><br/>
     <select name='account_record' className='form-control' onChange={handleChange} value={formData.account_record}>

       <option >--Select Record Type--</option>
       <option value='Buyer - Long Term'>Buyer Long Term</option>
       <option value='Buyer - Spot'>Buyer Spot</option>
       <option value='Supplier - Long Term'>Supplier - Long Term</option>
       <option value='Supplier - Spot'>Supplier - Spot</option>

       </select>
       </div>
       <input type='submit' className="btn btn-primary " value='Submit'/>

       </form>
</Popup></span></h4>

     <hr></hr>
     </div>
     <div  id='inner-heading'>

  </div>
       <table id='shipment' className="">
    <thead>
    <tr>
     <th>Contract Shipment No</th>
    <th>Contract Shipment Status</th>
    <th>Currency</th>
      <th>Exchange Rate</th>
      <th>Order Quantity</th>
      <th>Parent Shipment</th>
      <th>Record Type</th>
    </tr>
    </thead>

    {conShipment.length> 0 ?

    <tbody>
    {
          conShipment.map((y,i)=>(
      <tr>
      <td><span id='codes'><Link to ={'/shipment/contract-shipment-details/'+y.id}>{y.contract}</Link></span></td>
            <td>{y.contract_shipment_status}</td>
            <td>{y.currency}</td>
            <td>{y.exchange_rate}</td>
            <td>{y.order_quantity}</td>
            <td>{y.parent_shipment}</td>
            <td>{y.record_type}</td>
      </tr>
       ))}
    </tbody>
    :<tbody>
    <tr>

      <td></td>
      <td></td>
      <td>No data available</td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      </tr>
      </tbody> }

    </table>

       </Row>
       <Row id='table-styles'>
       <div className="col-md-12" id='head'>
     <h4 className="heading"><span>Opportunity Information </span>
     {/* <Popup trigger={<span className="button add-accountrecord">  New </span>} modal>
          <form onSubmit={handleSubmitOpp} className="add-accounts">
        <div className='form-group'>
        <label>Opportunity Record Type</label><br/>
        <select  className='form-control' name="type_of_new_record" onChange={handleChangeOpp} value={formDataopp.account_record}>
          <option>----Select----</option>
<option value='Buyer' >Buyer</option>
      <option value='Buyer - Long Term' >Buyer - Long Term</option>
      <option value='Buyer - Spot' >Buyer - Spot</option>
      <option value='Supplier' >Supplier</option>
      <option value='Supplier - Long Term' >Supplier - Long Term</option>
      <option value='Supplier - Spot'>Supplier - Spot</option>

          </select>
          </div>
          <input type='submit' className="btn btn-primary " value='Submit'/>

          </form>
      </Popup> */}
      </h4>
     <hr></hr>
     </div>
       <table id='opportunity' className="">
    <thead>
    <tr>
      <th>Opportunity No</th>
      <th>Opportunity Name</th>
      <th>Stage</th>
      <th>Quantity (MT)	Supplier</th>
      <th>No of Order</th>

    </tr>
    </thead>

    {opportunity.length> 0  ?

      <tbody>
      {
          opportunity.map((y,i)=>(
      <tr>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
       ))}
    </tbody>
    :<tbody>
    <tr>
      <td></td>
      <td></td>
      <td>No data available</td>

      <td></td>
      <td></td>
      </tr>
      </tbody>
}
    </table>

       </Row>

       <Row id='table-styles'>
       <div className="col-md-12" id='head'>
     <h4 className="heading"><span>TCF Information</span>
     <span><Popup trigger={<a className="button add-accountrecord">  New </a>} modal>

    <form onSubmit={handleSubmittcf} className="add-accounts">
     <div className='form-group'>
     <label>TCF Record Type</label><br/>
     <select name='account_record' className='form-control' onChange={handleChangetcf} value={formData.account_record}>
       <option >--Select TCF Record Type--</option>
       <option value='Buyer - Spot'>Buyer - Spot</option>
       <option value='Buyer - Long Term'>Buyer - Long Term</option>
       </select>
       </div>
       <input type='submit' className="btn btn-primary " value='Submit'/>

       </form>
</Popup></span></h4>
     <hr></hr>
     </div>
       <table id='tcf' className="">
    <thead>
    <tr>
      <th>TCF No</th>
      <th>TCF Status</th>
      <th>Currency</th>
      <th>Exchange Rate</th>
      <th>Opportunity</th>
      <th>Record Type</th>
      <th>Trader Name</th>

    </tr>
    </thead>

    {tcf.length> 0  ?

      <tbody>
      {
          tcf.map((y,i)=>(
      <tr>
        <td><span id='codes'>{y.tcf_no}</span></td>
        <td>{y.tcf_status}</td>
        <td>{y.currency}</td>
        <td>{y.exchange_rate}</td>
        <td>{y.opportunity}</td>
        <td>{y.record_type}</td>
        <td>{y.trader_name}</td>
      </tr>
       ))}
    </tbody>
    :<tbody>
    <tr>
      <td></td>
      <td></td>
      <td></td>
      <td>No data available</td>
      <td></td>
      <td></td>
      <td></td>
      </tr>
      </tbody>
}
    </table>

       </Row>
       <Row id='table-styles'>
       <div className="col-md-12" id='head'>
     <h4 className="heading"><span>Orders Information </span></h4>
     <hr></hr>
     </div>
       <table id='orders' className="">
    <thead>
    <tr>
      <th>Order No</th>
      <th>Account Name</th>
      <th>Barge Name</th>
      <th>Laycan From Date</th>
      <th>Laycan From Date</th>
      <th>Laycan Month</th>
      <th>Laycan Period</th>

    </tr>
    </thead>

    {orderData.length> 0  ?

      <tbody>
      {
            orderData.map((y,i)=>(
      <tr>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
       ))}
    </tbody>
    :<tbody>
    <tr>
      <td></td>
      <td></td>
      <td></td>
      <td>No data available</td>
      <td></td>
      <td></td>
      <td></td>
      </tr>
      </tbody>
}
    </table>

       </Row>
       <Row id='table-styles'>
             <div className="col-md-12" id='head'>
           <h4 className="heading"><span>Contract Product</span></h4>
           <hr></hr>
           </div>
             <table id='orders' className="">
          <thead>
          <tr>
            <th>Contract Product Number</th>
            <th>Product Master</th>
            <th>Product Name</th>
            <th>Product Description</th>
            <th>Contract Shipment</th>

          </tr>
          </thead>

          {contractProduct.length> 0  ?

            <tbody>
            {
                  contractProduct.map((x,i)=>(
            <tr>
              <td>{x.contract_product_number}</td>
              <td>{x.product_master}</td>
              <td>{x.product_name}</td>
              <td>{x.product_description}</td>
              <td>{x.contract_shipment}</td>

            </tr>
             ))}
          </tbody>
          :<tbody>
          <tr>

            <td></td>
            <td></td>
            <td>No data available</td>
            <td></td>
            <td></td>

            </tr>
            </tbody>
      }
          </table>

             </Row>
       <Row id='table-styles'>
           <div className="col-md-12" id='head'>
         <h4 className="heading"><span>Documents Information</span><span>
         <Link to={"/contract/add-document/" + id}>
                        <i className="fe fe-download-cloud me-2"></i>
                         New
                      </Link> </span>
                      </h4>
         <hr></hr>
         </div>
           <table id='document' className="">
        <thead>
        <tr>
          <th>contract list of docs</th>
          <th>document required name</th>
          <th>list of docuemnts</th>
          <th>original in no</th>
          <th>suffix</th>

        </tr>
        </thead>

        {contractDocument.length> 0  ?

          <tbody>
          {
                contractDocument.map((y,i)=>(
          <tr>
            <td className=""><Link to={'/contract/document-view/'+y.id}>{y.contract_list_of_docs}</Link></td>
            <td>{y.document_required_name}</td>
            <td>{y.list_of_docuemnts}</td>
            <td>{y.original_in_no}</td>
            <td>{y.suffix}</td>

          </tr>
           ))}
        </tbody>
        :<tbody>
        <tr>
          <td></td>
          <td></td>

          <td>No data available</td>
          <td></td>

          </tr>
          </tbody>
    }
        </table>

           </Row>
   </div>
   </>
   )}
   </>
  ):(
    <>
    {datas.contract_record_type === "Buyer - Spot" ? (

       <>
       {isEditMode ? (
        <div>
          <div className="tasks">
          <p className='edit-btn'>
           <button className='btn btn-primary' onClick={handleSaveEdit}>Save</button>
           <button className="btn btn-primary" onClick={handleCancelEdit}>Cancel</button>


           </p>
      <Row className="mb-3">
         <h4 className="heading">Contract Information</h4>
         <Form.Group as={Col} md="6" id='lockeds'>
            <input type='checkbox' name='locked' onChange={(e) => setCheck1(e.target.checked) } /><label>Locked</label>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingInput"
               label="Contract Owner"
               className="mb-3"
               >
               <Form.Control
               type="text"
               placeholder="Contract Owner"
               name="contract_owner"
               defaultValue={datas.contract_owner || ""}
               onChange={handleChangeInput}
               />
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingSelect"
               className="dropDown"
               label="Currency"
               >
               <Form.Select
               aria-label="Floating label select example"
               name="currency"
               placeholder="Currency"
               value={datas.currency || ""}
               onChange={handleChangeInput}
               >
               <option value="">Select </option>
               <option value="IDR">IDR </option>
               <option value="USD">USD </option>
               </Form.Select>
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingSelect"
               className="dropDown"
               label="Trader Name"
               >
               <Form.Select
               aria-label="Floating label select example"
               name="trader_name"
               placeholder="Trader Name"
               value={datas.trader_name || ""}
               onChange={handleChangeInput}
               >
               <option value="">Select </option>
               {traderData.map((x, i) => {
               return (
               <option key={i} value={x.rwood_username}>
                  {x.rwood_username}
               </option>
               );
               })}
               </Form.Select>
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingInput"
               label="Contract record type"
               className="mb-3"
               >
               <Form.Control
               type="text"
               placeholder="Contract record type"
               name="contract_record_type" className='record-type'
               defaultValue={datas.contract_record_type || ""}
               onChange={handleChangeInput}
               />
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingSelect"
               className="dropDown"
               label="Account Name"
               >
               <Form.Select
               aria-label="Floating label select example"
               name="account_name"
               placeholder="Account Name"
               className="error-validation"
               value={datas.account_name || ""}
               onChange={handleChangeInput}
               >
               <option value="">Select </option>
               {accountName.map((x) => {
               return (
               <option value={x.account_name}>{x.account_name}</option>
               );
               })}
               </Form.Select>
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingInput"
               label="Exchange rate"
               className="mb-3"
               >
               <Form.Control
               type="text"
               placeholder="Exchange rate "
               name="exchange_rate"
               defaultValue={datas.exchange_rate || ""}
               onChange={handleChangeInput}
               />
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingSelect"
               className="dropDown"
               label="Company"
               >
               <Form.Select
               aria-label="Floating label select example"
               name="company"
               placeholder="Company"
               className="error-validation"
               value={datas.company || ""}
               onChange={handleChangeInput}
               >
               <option value="">Select </option>
               {companyData.map((x, i) => {
               return (
               <option key={i} value={x.company_name}>
                  {x.company_name}
               </option>
               );
               })}
               </Form.Select>
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingSelect"
               className="dropDown"
               label="Supplier"
               >
               <Form.Select
               aria-label="Floating label select example"
               name="supplier"
               placeholder="Supplier"
               className="error-validation"
               value={datas.supplier || ""}
               onChange={handleChangeInput}
               >
               <option value="">Select </option>
               {supplierData.map((x) => {
               return (
               <option value={x.account_name}>{x.account_name}</option>
               );
               })}
               </Form.Select>
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel controlId="floatingSelect"
               className='dropDown' label="Price book">
               <Form.Select aria-label="Floating label select example"
               name="price_book"
               placeholder="Price book"
               value={datas.price_book || ""}
               onChange={handleChangeInput}
               >
               <option value='' >Select</option>
               {
               priceBook.map((x)=>{
               return(
               <option value={x.price_book_name}>{x.price_book_name}</option>
               )
               })
               }
               </Form.Select>
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingSelect"
               className="dropDown"
               label="Status"
               >
               <Form.Select
               aria-label="Floating label select example"
               name="status"
               placeholder="Status"
               className="error-validation"
               defaultValue={datas.status || ""}
               onChange={handleChangeInput}
               >
               <option value="">Select </option>
               <option value="Draft">Draft </option>
               <option value="Approved">Approved </option>
               </Form.Select>
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingInput"
               label="Rwood contract no"
               className="mb-3"
               >
               <Form.Control
               type="text"
               placeholder="Rwood contract no"
               name="Rwood_Contract_No"
               defaultValue={datas.Rwood_Contract_No || ""}
               onChange={handleChangeInput}
               />
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingInput"
               label="Contract term in months"
               className="mb-3"
               >
               <Form.Control
               type="text"
               placeholder="Contract term in months"
               name="contract_term_in_months"
               defaultValue={datas.contract_term_in_months || ""}
               onChange={handleChangeInput}
               />
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingTextarea2"
               label="Pending Matters"
               >
               <Form.Control
               as="textarea"
               placeholder="Pending Matters"
               style={{ height: "100px!important" }}
               name="pending_matters"
               defaultValue={datas.pending_matters || ""}
               onChange={handleChangeInput}
               />
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel controlId="floatingTextarea2" label="Remark">
               <Form.Control
               as="textarea"
               placeholder="Remark"
               style={{ height: "100px!important" }}
               name="remark"
               defaultValue={datas.remark || ""}
               onChange={handleChangeInput}
               />
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingInput"
               label="Contract Start Date"
               className="mb-3 date-picker"
               >
               <DatePicker
                  selected={startDate}
                  onChange={(date) =>
               setStartDate(date)}
               customInput={
               <ExampleCustomInput />
               }
               dayClassName={() => "example-datepicker-day-class"}
               popperClassName="example-datepicker-class"
               todayButton="TODAY"
               dateFormat="yyyy-MM-dd"
               placeholderText="Choose Date"
               />
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingInput"
               label="Master conference reference"
               className="mb-3"
               >
               <Form.Control
               type="text"
               placeholder="Master conference reference"
               name="master_conference_reference"
               defaultValue={datas.master_conference_reference || ""}
               onChange={handleChangeInput}
               />
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingInput"
               label="Renewal date"
               className="mb-3 date-picker"
               >
               <DatePicker
                  selected={startDate}
                  onChange={(date) =>
               setStartDate(date)}
               customInput={
               <ExampleCustomInput />
               }
               dayClassName={() => "example-datepicker-day-class"}
               popperClassName="example-datepicker-class"
               todayButton="TODAY"
               dateFormat="yyyy-MM-dd"
               placeholderText="Choose Date"
               />
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingInput"
               label="Renewal contract number"
               className="mb-3"
               >
               <Form.Control
               type="number"
               placeholder="Renewal contract number "
               name="renewal_contract_number "
               defaultValue={datas.renewal_contract_number || ""}
               onChange={handleChangeInput}
               />
            </FloatingLabel>
         </Form.Group>
      </Row>
      <Row className="mb-3">
         <h4 className="heading">Commodity and Contracted Quantity</h4>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingInput"
               label="Origin"
               className="mb-3"
               >
               <Form.Control
               type="text"
               placeholder="Origin"
               name="origin"
               defaultValue={datas.origin || ""}
               onChange={handleChangeInput}
               />
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel controlId="floatingSelect"
               className='dropDown' label="Commodity">
               <Form.Select aria-label="Floating label select example"
               name="commodity"
               placeholder="Commodity"
               value={datas.commodity || ""}
               onChange={handleChangeInput}
               >
               <option>---None---</option>
               <option value="Indonesian Steam Coal in Bulk (&quot;Coal&quot;)">Indonesian Steam Coal in Bulk ("Coal")</option>
               <option value="US Coal">US Coal</option>
               <option value="Russia Coal">Russia Coal</option>
               <option value="SA Coal">SA Coal</option>
               <option value="Clinker in Bulk">Clinker in Bulk</option>
               <option value="Australian Steam Coal">Australian Steam Coal</option>
               </Form.Select>
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingInput"
               label="Interlab tolerance"
               className="mb-3"
               >
               <Form.Control
               as="textarea"
               placeholder="Interlab tolerance"
               name="interlab_tolerance"
               style={{ height: "100px!important" }}
               defaultValue={datas.interlab_tolerance || ""}
               onChange={handleChangeInput}
               />
            </FloatingLabel>
         </Form.Group>
      </Row>
      <Row className="mb-3">
         <h4 className="heading">Market Information</h4>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingInput"
               label="Port Of Loading"
               className="mb-3"
               >
               <Form.Control
               type="text"
               placeholder="Port Of Loading"
               name="port_of_loading"
               defaultValue={datas.port_of_loading || ""}
               onChange={handleChangeInput}
               />
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingInput"
               label="Destination"
               className="mb-3"
               >
               <Form.Control
               type="text"
               placeholder="Destination"
               name="destination"
               defaultValue={datas.destination || ""}
               onChange={handleChangeInput}
               />
            </FloatingLabel>
         </Form.Group>
      </Row>
      <Row className="mb-3">
         <h4 className="heading">Price and Price Adjustment</h4>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingInput"
               label="Price adjustment clause"
               className="mb-3"
               >
               <Form.Control
               type="text"
               placeholder="Price adjustment clause"
               name="price_adjustment_clause"
               defaultValue={datas.price_adjustment_clause || ""}
               onChange={handleChangeInput}
               />
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingInput"
               label="Bonus penalty clause"
               className="mb-3"
               >
               <Form.Control
               as="textarea"
               placeholder="Bonus penalty clause"
               name="bonus_penalty_clause"
               defaultValue={datas.bonus_penalty_clause || ""}
               onChange={handleChangeInput}
               />
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingInput"
               label="Payment terms"
               className="mb-3"
               >
               <Form.Control
               as="textarea"
               placeholder="Payment terms"
               name="payment_terms"
               defaultValue={datas.payment_terms || ""}
               onChange={handleChangeInput}
               />
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingInput"
               label="Rejections"
               className="mb-3"
               >
               <Form.Control
               as="textarea"
               placeholder="Rejections"
               name="rejections"
               defaultValue={datas.rejections || ""}
               onChange={handleChangeInput}
               />
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingInput"
               label="Hs code"
               className="mb-3"
               >
               <Form.Control
               type="text"
               placeholder="Hs code"
               name="hs_code"
               defaultValue={datas.hs_code || ""}
               onChange={handleChangeInput}
               />
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingTextarea2"
               label="Pricing Terms"
               >
               <Form.Control
               as="textarea"
               placeholder="Pricing Terms"
               style={{ height: "100px!important" }}
               name="pricing_terms"
               defaultValue={datas.pricing_terms || ""}
               onChange={handleChangeInput}
               />
            </FloatingLabel>
         </Form.Group>
      </Row>
      <Row className="mb-3">
         <h4 className="heading">Payment Information</h4>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingSelect"
               className="dropDown"
               label="LC Type"
               >
               <Form.Select
               aria-label="Floating label select example"
               name="lc_type"
               placeholder="LC Type"
               defaultValue={datas.lc_type || ""}
               onChange={handleChangeInput}
               >
               <option value="">--None--</option>
               <option value="Irrevocable transferrable Letter of Credit">Irrevocable transferrable Letter of Credit</option>
               <option value="Irrevocable Non transferrable Letter of Credit">Irrevocable Non transferrable Letter of Credit</option>
               <option value="Irrevocable Letter of Credit">Irrevocable Letter of Credit</option>
               <option value="Irrevocable Back to Back LC">Irrevocable Back to Back LC</option>
               <option value="Payment and Receipt">Payment and Receipt</option>
               <option value="Standby_letter_of_credit_c">Standby Letter of Credit</option>
               </Form.Select>
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingInput"
               label="Master lc presentation days"
               className="mb-3"
               >
               <Form.Control
               type="text"
               placeholder="Master lc presentation days"
               name="master_lc_presentation_days"
               defaultValue={datas.master_lc_presentation_days || ""}
               onChange={handleChangeInput}
               />
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingSelect"
               className="dropDown"
               label="Payment Details"
               >
               <Form.Select
               aria-label="Floating label select example"
               name="payment_details"
               placeholder="Payment Details"
               defaultValue={datas.payment_details || ""}
               onChange={handleChangeInput}
               >
               <option value="">--None--</option>
               <option value="Irrevocable transferrable Letter of Credit">Irrevocable transferrable Letter of Credit</option>
               <option value="Irrevocable Non transferrable Letter of Credit">Irrevocable Non transferrable Letter of Credit</option>
               <option value="Irrevocable Letter of Credit">Irrevocable Letter of Credit</option>
               <option value="Irrevocable Back to Back LC">Irrevocable Back to Back LC</option>
               <option value="Payment and Receipt">Payment and Receipt</option>
               <option value="Standby_letter_of_credit_c">Standby Letter of Credit</option>
               </Form.Select>
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingInput"
               label="Lc opening days"
               className="mb-3"
               >
               <Form.Control
               type="number"
               placeholder="Lc opening days"
               name="lc_opening_days"
               defaultValue={datas.lc_opening_days || ""}
               onChange={handleChangeInput}
               />
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingInput"
               label="Master lc tolerance"
               className="mb-3"
               >
               <Form.Control
               type="number"
               placeholder="Master lc tolerance"
               name="master_lc_tolerance"
               defaultValue={datas.master_lc_tolerance || ""}
               onChange={handleChangeInput}
               />
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingInput"
               label="Lc opening days in words"
               className="mb-3"
               >
               <Form.Control
               type="text"
               placeholder="Lc opening days in words"
               name="lc_opening_days_in_words"
               defaultValue={datas.lc_opening_days_in_words || ""}
               onChange={handleChangeInput}
               />
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingInput"
               label="Lc validity no of days"
               className="mb-3"
               >
               <Form.Control
               type="number"
               placeholder="Lc validity no of days"
               name="lc_validity_no_of_days"
               defaultValue={datas.lc_validity_no_of_days || ""}
               onChange={handleChangeInput}
               />
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingInput"
               label="Document presentation period"
               className="mb-3"
               >
               <Form.Control
               type="text"
               placeholder="Document presentation period"
               name="document_presentation_period"
               defaultValue={datas.document_presentation_period || ""}
               onChange={handleChangeInput}
               />


            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingInput"
               label="Latest date of shipment"
               className="mb-3"
               >
               <Form.Control
               type="text"
               placeholder="Latest date of shipment"
               name="latest_date_of_shipment"
               defaultValue={datas.latest_date_of_shipment || ""}
               onChange={handleChangeInput}
               />
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingInput"
               label="Loading rate per day"
               className="mb-3"
               >
               <Form.Control
               type="text"
               placeholder="Loading rate per day"
               name="loading_rate_per_day"
               defaultValue={datas.loading_rate_per_day || ""}
               onChange={handleChangeInput}
               />
            </FloatingLabel>
         </Form.Group>
      </Row>
      <Row className="mb-3">
         <h4 className="heading">Important Terms Information</h4>
         <Form.Group as={Col} md="12">
            <FloatingLabel
               controlId="floatingInput"
               label="List of documents required"
               className="mb-3"
               >
               <Form.Control
               as="textarea"
               style={{ height: "200px!important" }}
               placeholder="List of documents required"
               name="list_of_documents_required"
               defaultValue={datas.list_of_documents_required || ""}
               onChange={handleChangeInput}
               />
            </FloatingLabel>
         </Form.Group>
      </Row>
      <Row className="mb-3">
         <h4 className="heading">Signature Information</h4>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingSelect"
               className="dropDown"
               label="Kind attention"
               >
               <Form.Select
               aria-label="Floating label select example"
               name="kind_attention"
               placeholder="Kind attention"
               defaultValue={datas.kind_attention || ""}
               onChange={handleChangeInput}
               >
               <option value="">Select </option>
               {contacts.map((x) => {
               return (
               <option value={x.contact_full_name}>
                  {x.contact_full_name}
               </option>
               );
               })}
               </Form.Select>
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingSelect"
               className="dropDown"
               label="Customer signed by"
               >
               <Form.Select
               aria-label="Floating label select example"
               name="customer_signed_by"
               placeholder="Customer signed by"
               defaultValue={datas.customer_signed_by || ""}
               onChange={handleChangeInput}                  >
               <option value="">Select </option>
               {contacts.map((x) => {
               return (
               <option value={x.contact_full_name}>
                  {x.contact_full_name}
               </option>
               );
               })}
               </Form.Select>
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingInput"
               label="Customer signed title"
               className="mb-3"
               >
               <Form.Control
               type="text"
               placeholder="Customer signed title"
               name="customer_signed_title"
               defaultValue={datas.customer_signed_title || ""}
               onChange={handleChangeInput}
               />
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingSelect"
               className="dropDown"
               label="Company signed by"
               >
               <Form.Select
               aria-label="Floating label select example"
               name="company_signed_by"
               placeholder="Company signed by"
               defaultValue={datas.company_signed_by || ""}
               onChange={handleChangeInput}
               >
               <option value="">Select </option>
               {users.map((x) => {
               return
               <option value={x.username}>{x.username}</option>
               ;
               })}
               </Form.Select>
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingInput"
               label="Original Contract Recieved Date"
               className="mb-3 date-picker"
               >
               <DatePicker
                  selected={received}
                  onChange={(date) =>
               setReceived(date)}
               customInput={
               <ExampleCustomInput5 />
               }
               dayClassName={() => "example-datepicker-day-class"}
               popperClassName="example-datepicker-class"
               todayButton="TODAY"
               dateFormat="yyyy-MM-dd"
               placeholderText="Choose Date"
               />
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingInput"
               label="Company Signed Date"
               className="mb-3 date-picker"
               >
               <DatePicker
                  selected={signed}
                  onChange={(date) =>
               setSigned(date)}
               customInput={
               <ExampleCustomInput4 />
               }
               dayClassName={() => "example-datepicker-day-class"}
               popperClassName="example-datepicker-class"
               todayButton="TODAY"
               dateFormat="yyyy-MM-dd"
               placeholderText="Choose Date"
               />
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingInput"
               label="Original contract sent date"
               className="mb-3 date-picker"
               >
               <DatePicker
                  selected={sent}
                  onChange={(date) =>
               setSent(date)}
               customInput={
               <ExampleCustomInput7 />
               }
               dayClassName={() => "example-datepicker-day-class"}
               popperClassName="example-datepicker-class"
               todayButton="TODAY"
               dateFormat="yyyy-MM-dd"
               placeholderText="Choose Date"
               />
            </FloatingLabel>
         </Form.Group>
         {/*
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingInput"
               label="Original contract sent date"
               className="mb-3 date-picker"
               >
               <DatePicker
                  selected={sent}
                  onChange={(date) =>
               setSent(date)}
               customInput={
               <ExampleCustomInput7 />
               }
               dayClassName={() => "example-datepicker-day-class"}
               popperClassName="example-datepicker-class"
               todayButton="TODAY"
               dateFormat="yyyy-MM-dd"
               placeholderText="Choose Date"
               />
            </FloatingLabel>
         </Form.Group>
         */}
      </Row>
      <Row className="mb-3">
         <h4 className="heading">Other Standard Terms</h4>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingSelect"
               className="dropDown"
               label="Applicable Law"
               >
               <Form.Select
               aria-label="Floating label select example"
               name="applicable_law"
               placeholder="Applicable Law"
               defaultValue={datas.applicable_law || ""}
               onChange={handleChangeInput}
               >
               <option value="">--None--</option>
               <option value="English Law">English Law</option>
               <option value="Singapore Law">Singapore Law</option>
               <option value="Indonesian Law">Indonesian Law</option>
               </Form.Select>
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingSelect"
               className="dropDown"
               label="Rwood surveryor agent"
               >
               <Form.Select
               aria-label="Floating label select example"
               name="rwood_surveryor_agent"
               placeholder="Rwood surveryor agent"
               defaultValue={datas.rwood_surveryor_agent || ""}
               onChange={handleChangeInput}
               >
               <option value="">Select </option>
               {surveyors.map((x) => {
               return (
               <option value={x.account_name}>{x.account_name}</option>
               );
               })}
               </Form.Select>
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingSelect"
               className="dropDown"
               label="Arbitration"
               >
               <Form.Select
               aria-label="Floating label select example"
               name="arbitration"
               placeholder="Arbitration"
               defaultValue={datas.arbitration || ""}
               onChange={handleChangeInput}
               >
               <option value="">--None--</option>
               <option value="In Singapore as per SIAC">In Singapore as per SIAC</option>
               <option value="Singapore Chamber of Maritime Arbitration (SCMA)">Singapore Chamber of Maritime Arbitration (SCMA)</option>
               <option value="Laws of England and Wales">Laws of England and Wales</option>
               <option value="Rules of Badan Arbitrase Nasional Indonesia (BANI)">Rules of Badan Arbitrase Nasional Indonesia (BANI)</option>
               </Form.Select>
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingSelect"
               className="dropDown"
               label="Surveyor 1"
               >
               <Form.Select
               aria-label="Floating label select example"
               name="surveyor_1"
               placeholder="Surveyor 1"
               defaultValue={datas.surveyor_1 || ""}
               onChange={handleChangeInput}
               >
               <option value="">Select </option>
               {surveyors.map((x) => {
               return (
               <option value={x.account_name}>{x.account_name}</option>
               );
               })}
               </Form.Select>
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingSelect"
               className="dropDown"
               label="Surveyor 2"
               >
               <Form.Select
               aria-label="Floating label select example"
               name="surveyor_2"
               placeholder="Surveyor 2"
               defaultValue={datas.surveyor_2 || ""}
               onChange={handleChangeInput}
               >
               <option value="">Select </option>
               {surveyors.map((x) => {
               return (
               <option value={x.account_name}>{x.account_name}</option>
               );
               })}
               </Form.Select>
            </FloatingLabel>
         </Form.Group>
      </Row>
      <Row className="mb-3">
         <h4 className="heading">List Of Fields-Not Required</h4>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingTextarea2"
               label="Product description"
               >
               <Form.Control
               as="textarea"
               placeholder="Product description"
               style={{ height: "100px" }}
               name="product_description"
               defaultValue={datas.product_description || ""}
               onChange={handleChangeInput}
               />
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingTextarea2"
               label="Description"
               >
               <Form.Control
               as="textarea"
               placeholder="Description"
               style={{ height: "100px" }}
               name="description"
               defaultValue={datas.description || ""}
               onChange={handleChangeInput}
               />
            </FloatingLabel>
         </Form.Group>
      </Row>
      <p className='edit-btn'>
           <button className='btn btn-primary' onClick={handleSaveEdit}>Save</button>
           <button className="btn btn-primary" onClick={handleCancelEdit}>Cancel</button>


           </p>
   </div>
        </div>
       ):(
        <>
       <div className="">
         <div className="tasks">
         <div className="edit-delete">
                  <span id="edit" onClick={handleEditClick}>
                    Edit
                  </span>
                  <span>
                    Delete
                  </span>
                  <span>
                    Activate
                  </span>
                  <span>
                    Generate Contract
                  </span>
<span>
                     Generate Contract Clauses
                  </span>
<span>
                    Shipment Checklist
                  </span>
                  </div>
           <div className="">
             <div className="row">
               <div className="col-md-12" id="head">
                 <h4 className="heading">Information</h4>

                 <hr></hr>
               </div>
             </div>
             <table class="table table-bordered account-table tables">
               <tbody>
                 <tr>
                   <td id="td-right"><span>Locked</span> </td>
                   <td>{datas.locked}</td>


                 </tr>
                 <tr>
                 <td id="td-right">
                     <span>Currency</span>
                   </td>
                   <td>
                     {datas.currency}
                   </td>
                   <td id="td-right">
                     <span>
                       Trader Name

                     </span>
                     </td><td>
                     {datas.trader_name}
                   </td>
                 </tr>
                 <tr>
                 <td id="td-right">
                     <span>Contract record type</span>
                     </td>
                     <td>
                      {datas.contract_record_type}
                   </td>
                   <td id="td-right">
                     <span>Account Name</span>
                     </td>
                     <td>
                      {datas.account_name}
                   </td>
                 </tr>
                 <tr>

                 <td id="td-right">
                     <span>Exchange Rate</span>
                     </td>
                     <td>

                     {datas.exchange_rate}
                   </td>
                   <td id="td-right">
                     <span>Contract Owner</span>
                     </td>
                     <td>
                     {datas.contract_owner}
                   </td>

                 </tr>
                 <tr>

                   <td id="td-right">
                       <span>Price book </span>
                       </td>
                       <td>

                       {datas.price_book}
                     </td>
                     <td id="td-right">
                       <span>Rwood contract no</span>
                       </td>
                       <td>
                       {datas.Rwood_Contract_No}
                     </td>

                   </tr>
                   <tr>

                   <td id="td-right">
                       <span>Status</span>
                       </td>
                       <td>

                       {datas.status}
                     </td>
                     <td id="td-right">
                       <span>Contract Status</span>
                       </td>
                       <td>
                       {datas.contract_status}
                     </td>

                   </tr>
                   <tr>

                   <td id="td-right">
                       <span>Contract term in months</span>
                       </td>
                       <td>

                       {datas.contract_term_in_months}
                     </td>
                     <td id="td-right">
                       <span>Pending Matters</span>
                       </td>
                       <td>
                       {datas.pending_matters}
                     </td>

                   </tr>
                   <tr>

                   <td id="td-right">
                       <span>Remark</span>
                       </td>
                       <td>

                       {datas.remark}
                     </td>
                     <td id="td-right">
                       <span>Contract Start Date</span>
                       </td>
                       <td>
                       {datas.contract_start_date}
                     </td>

                   </tr>

                   <tr>

                   <td id="td-right">
                       <span>Contract Effective Date</span>
                       </td>
                       <td>

                       {datas.contract_effective_date}
                     </td>
                     <td id="td-right">
                       <span>Master Contract Reference</span>
                       </td>
                       <td>
                       {datas.master_conference_reference}
                     </td>

                   </tr>

                   <tr>

                   <td id="td-right">
                       <span>Renewal Date</span>
                       </td>
                       <td>

                       {datas.renewal_date}
                     </td>
                     <td id="td-right">
                       <span>Renewal contract number</span>
                       </td>
                       <td>
                       {datas.renewal_contract_number}
                     </td>

                   </tr>
               </tbody>
             </table>
           </div>
           <div className="">
             <div className="row">
               <div className="col-md-12" id="head">
                 <h4 className="heading">Commodity and Contracted Quantity</h4>
                 <hr></hr>
               </div>
             </div>
             <table class="table table-bordered account-table tables">
               <tbody>

                 <tr>
                 <td id="td-right">
                     <span>Origin</span>
                     </td>
                     <td>
                     {datas.origin}
                   </td>
                   <td id="td-right">

                     <span>Commodity</span>
                     </td>
                     <td>
                     {datas.commodity}
                   </td>

                     </tr>

                 <tr>

                 <td id="td-right">
                     <span>Interlab Tolerance</span>
                     </td>
                     <td>
                     {datas.interlab_tolerance}
                   </td>
                   <td id="td-right">
                     <span>Contract Quantity in MT</span>
                     </td>
                     <td>
                     {datas.contract_quantity_in_MT}
                   </td>
                   </tr>

               </tbody>
             </table>
           </div>
           <div className="">
             <div className="row">
               <div className="col-md-12" id="head">
                 <h4 className="heading">Market Informatione</h4>
                 <hr></hr>
               </div>
             </div>
             <table class="table table-bordered account-table tables">
               <tbody>
                 <tr>
                 <td id="td-right">
                     <span>Port Of Loading</span>
                    </td>
                    <td>
                     {datas.port_of_loading}
                   </td>
                 <td id="td-right">
                     <span>Destination</span>
                    </td><td>
                     {datas.destination}
                   </td>

                   </tr>

               </tbody>
             </table>
           </div>
           <div className="">
             <div className="row">
               <div className="col-md-12" id="head">
                 <h4 className="heading">Price and Price Adjustment</h4>
                 <hr></hr>
               </div>
             </div>
             <table class="table table-bordered account-table tables">
               <tbody>
                <tr>
                <td id="td-right">
                     <span>Price adjustment clause</span>
                    </td><td>
                    {datas.price_adjustment_clause}
                   </td>
                   <td id="td-right">
                   <span>Bonus penalty clause</span>
                    </td><td>
                    {datas.bonus_penalty_clause}
                   </td>
                 </tr>

                 <tr>
                <td id="td-right">
                <span>Payment terms</span>
                    </td><td>
                    {datas.payment_terms}
                   </td>
                   <td id="td-right">
                <span>Rejections</span>
                    </td><td>
                     {datas.rejections}
                   </td>
                 </tr>



                 <tr>
                 <td id="td-right">
                   <span>HS code</span>
                    </td><td>
                     {datas.hs_code}
                   </td>
                <td id="td-right">
                <span>Pricing Terms</span>
                    </td><td>
                     {datas.pricing_terms}
                   </td>

                 </tr>


               </tbody>
             </table>
           </div>


           <div className="">
             <div className="row">
               <div className="col-md-12" id="head">
                 <h4 className="heading">Payment Information</h4>
                 <hr></hr>
               </div>
             </div>
             <table class="table table-bordered account-table tables">
               <tbody>
                 <tr>
                 <td id="td-right">
                     <span>LC Type</span>
                    </td><td>
                     {datas.lc_type}
                   </td>
                   <td id="td-right">
                     <span>Master lc presentation days</span>
                    </td><td>
                     {datas.master_lc_presentation_days}
                   </td>
                   </tr>


                   <tr>
                   <td id="td-right">
                     <span>Lc opening days</span>
                    </td><td>
                     {datas.lc_opening_days}
                   </td>
                   <td id="td-right">
                     <span>Master lc tolerance</span>
                    </td><td>
                     {datas.master_lc_tolerance}
                   </td>
                 </tr>


                 <tr>

                   <td id="td-right">
                     <span>Lc opening days in words</span>
                    </td><td>
                     {datas.lc_opening_days_in_words}
                   </td>
                   <td id="td-right">
                     <span>Lc validity no of days</span>
                    </td><td>
                     {datas.lc_validity_no_of_days}
                   </td>
                   </tr>



                 <tr>

                   <td id="td-right">
                     <span>Document presentation period</span>
                    </td><td>
                     {datas.document_presentation_period}
                   </td>
                   <td id="td-right">
                     <span>Latest date of shipment</span>
                    </td><td>
                     {datas.latest_date_of_shipment}
                   </td>
                   </tr>

                 <tr>

                   <td id="td-right">
                     <span>Loading rate per day</span>
                    </td><td>
                     {datas.loading_rate_per_day}
                   </td>
                   <td></td>
                   <td></td>
                   </tr>
               </tbody>


             </table>
           </div>
           <div className="">
             <div className="row">
               <div className="col-md-12" id="head">
                 <h4 className="heading">Important Terms Information</h4>
                 <hr></hr>
               </div>
             </div>
             <table class="table table-bordered account-table tables">
               <tbody>
                 <tr>
                 <td id="td-right">
                     <span>List of documents required</span>
                    </td><td>
                     {datas.list_of_documents_required}
                   </td>
                    <td></td>
                    <td></td>
                   </tr>

               </tbody>


             </table>
           </div>


           <div className="">
             <div className="row">
               <div className="col-md-12" id="head">
                 <h4 className="heading">Signature Information</h4>
                 <hr></hr>
               </div>
             </div>
             <table class="table table-bordered account-table tables">
               <tbody>
                 <tr>
                 <td id="td-right">
                     <span>kind attention</span>
                    </td><td>
                     {datas.kind_attention}
                   </td>
                   <td id="td-right">
                     <span>Customer signed by</span>
                    </td><td>
                     {datas.customer_signed_by}
                   </td>
                   </tr>
                   <tr>
                   <td id="td-right">
                     <span>Customer signed title</span>
                    </td><td>
                     {datas.customer_signed_title}
                   </td>
                   <td id="td-right">
                     <span>Company signed by</span>
                    </td><td>
                     {datas.company_signed_by}
                   </td>

                 </tr>



                 <tr>
                 <td id="td-right">
                     <span>Original contract recieved date</span>
                    </td><td>
                     {datas.original_contract_recieved_date}
                   </td>
                   <td id="td-right">
                     <span>Company signed date</span>
                    </td><td>
                     {datas.company_signed_date}
                   </td>
                   </tr>



                 <tr>
                 <td id="td-right">
                     <span>Original contract sent date</span>
                    </td><td>
                     {datas.original_contract_sent_date}
                   </td>
                   <td id="td-right">

                    </td><td>

                   </td>
                   </tr>

               </tbody>


             </table>
           </div>


           <div className="">
             <div className="row">
               <div className="col-md-12" id="head">
                 <h4 className="heading">Other Standard Terms</h4>
                 <hr></hr>
               </div>
             </div>
             <table class="table table-bordered account-table tables">
               <tbody>
                 <tr>
                 <td id="td-right">
                     <span>Applicable law</span>
                    </td><td>
                     {datas.applicable_law}
                   </td>
                   <td id="td-right">
                     <span>Rwood surveryor agent</span>
                    </td><td>
                     {datas.rwood_surveryor_agent}
                   </td>
                   </tr>
                   <tr>
                   <td id="td-right">
                     <span>Arbitration</span>
                    </td><td>
                     {datas.arbitration}
                   </td>
                   <td id="td-right">
                     <span>Surveyor 1</span>
                    </td><td>
                     {datas.surveyor_1}
                   </td>

                 </tr>



                 <tr>
                 <td id="td-right">
                     <span>Surveyor 1</span>
                    </td><td>
                     {datas.surveyor_1}
                   </td>
                   <td id="td-right">

                    </td><td>

                   </td>
                   </tr>




               </tbody>


             </table>
           </div>



           <div className="">
             <div className="row">
               <div className="col-md-12" id="head">
                 <h4 className="heading">List Of Fields-Not Required</h4>
                 <hr></hr>
               </div>
             </div>
             <table class="table table-bordered account-table tables">
               <tbody>
                 <tr>

                   <td id="td-right">
                     <span>Description</span>
                    </td><td>
                     {datas.description}
                   </td>
                   <td id="td-right">
                     <span>Product Description</span>
                    </td><td>
                     {datas.product_description}
                   </td>
                   </tr>

               </tbody>


             </table>
           </div>
         </div>
       </div>



       <div
         className="tasks view-table"
         Style="box-showdow:0px 13px 20px #d6d1d1"
       >
           <Row id="table-styles">
                  <div className="col-md-12" id="head">
                    <h4 className="heading"><span>History Information</span></h4>
                    <hr></hr>
                  </div>
                  <table id="history" className="">
                    <thead>
                      <tr>
                        <th>Field Name</th>
                        <th>New Value</th>
                        <th>Old Value</th>
                        <th>Changed By</th>
                        <th>Change Time</th>
                      </tr>
                    </thead>

                    {history.length > 0 ? (
                      <tbody>
                        {history.map((y, i) => (
                          <tr>
                            <td>{y.field_name}</td>
                            <td>{y.new_value}</td>
                            <td>{y.old_value}</td>
                            <td>{y.changed_by}</td>
                            <td>{y.change_time}</td>
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td></td>
                          <td></td>
                          <td>No data available</td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </Row>
         <Row id='table-styles'>
           <div className="col-md-12" id='head'>
         <h4 className="heading"><span>Contract Shipment</span>
         <span><Popup trigger={<a className="button add-accountrecord">  New </a>} modal>

        <form onSubmit={handleSubmit} className="add-accounts">
         <div className='form-group'>
         <label>Contract Shipment Record Type</label><br/>
         <select name='account_record' className='form-control' onChange={handleChange} value={formData.account_record}>

           <option >--Select Record Type--</option>
           <option value='Buyer - Long Term'>Buyer Long Term</option>
           <option value='Buyer - Spot'>Buyer Spot</option>
           <option value='Supplier - Long Term'>Supplier - Long Term</option>
           <option value='Supplier - Spot'>Supplier - Spot</option>

           </select>
           </div>
           <input type='submit' className="btn btn-primary " value='Submit'/>

           </form>
    </Popup></span></h4>

         <hr></hr>
         </div>
         <div  id='inner-heading'>

      </div>
           <table id='shipment' className="">
        <thead>
        <tr>
         <th>Contract Shipment No</th>
        <th>Contract Shipment Status</th>
        <th>Currency</th>
          <th>Exchange Rate</th>
          <th>Order Quantity</th>
          <th>Parent Shipment</th>
          <th>Record Type</th>
        </tr>
        </thead>

        {conShipment.length> 0 ?

        <tbody>
        {
              conShipment.map((y,i)=>(
          <tr>
            <td><span id='codes'><Link to ={'/shipment/contract-shipment-details/'+y.id}>{y.contract}</Link></span></td>
            <td>{y.contract_shipment_status}</td>
            <td>{y.currency}</td>
            <td>{y.exchange_rate}</td>
            <td>{y.order_quantity}</td>
            <td>{y.parent_shipment}</td>
            <td>{y.record_type}</td>
          </tr>
           ))}
        </tbody>
        :<tbody>
        <tr>

          <td></td>
          <td></td>
          <td>No data available</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          </tr>
          </tbody> }

        </table>

           </Row>
           <Row id='table-styles'>
           <div className="col-md-12" id='head'>
         <h4 className="heading"><span>Opportunity Information </span>
         {/* <Popup trigger={<span className="button add-accountrecord">  New </span>} modal>
              <form onSubmit={handleSubmitOpp} className="add-accounts">
            <div className='form-group'>
            <label>Opportunity Record Type</label><br/>
            <select  className='form-control' name="type_of_new_record" onChange={handleChangeOpp} value={formDataopp.account_record}>
              <option>----Select----</option>
    <option value='Buyer' >Buyer</option>
          <option value='Buyer - Long Term' >Buyer - Long Term</option>
          <option value='Buyer - Spot' >Buyer - Spot</option>
          <option value='Supplier' >Supplier</option>
          <option value='Supplier - Long Term' >Supplier - Long Term</option>
          <option value='Supplier - Spot'>Supplier - Spot</option>

              </select>
              </div>
              <input type='submit' className="btn btn-primary " value='Submit'/>

              </form>
          </Popup> */}
          </h4>
         <hr></hr>
         </div>
           <table id='opportunity' className="">
        <thead>
        <tr>
          <th>Opportunity No</th>
          <th>Opportunity Name</th>
          <th>Stage</th>
          <th>Quantity (MT)	Supplier</th>
          <th>No of Order</th>

        </tr>
        </thead>

        {opportunity.length> 0  ?

          <tbody>
          {
              opportunity.map((y,i)=>(
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
           ))}
        </tbody>
        :<tbody>
        <tr>
          <td></td>
          <td></td>
          <td>No data available</td>

          <td></td>
          <td></td>
          </tr>
          </tbody>
    }
        </table>

           </Row>

           <Row id='table-styles'>
           <div className="col-md-12" id='head'>
         <h4 className="heading"><span>TCF Information</span>
         <span><Popup trigger={<a className="button add-accountrecord">  New </a>} modal>

        <form onSubmit={handleSubmittcf} className="add-accounts">
         <div className='form-group'>
         <label>TCF Record Type</label><br/>
         <select name='account_record' className='form-control' onChange={handleChangetcf} value={formData.account_record}>
           <option >--Select TCF Record Type--</option>
           <option value='Buyer - Spot'>Buyer - Spot</option>
           <option value='Buyer - Long Term'>Buyer - Long Term</option>
           </select>
           </div>
           <input type='submit' className="btn btn-primary " value='Submit'/>

           </form>
    </Popup></span></h4>
         <hr></hr>
         </div>
           <table id='tcf' className="">
        <thead>
        <tr>
          <th>TCF No</th>
          <th>TCF Status</th>
          <th>Currency</th>
          <th>Exchange Rate</th>
          <th>Opportunity</th>
          <th>Record Type</th>
          <th>Trader Name</th>

        </tr>
        </thead>

        {tcf.length> 0  ?

          <tbody>
          {
              tcf.map((y,i)=>(
          <tr>
            <td><span id='codes'>{y.tcf_no}</span></td>
            <td>{y.tcf_status}</td>
            <td>{y.currency}</td>
            <td>{y.exchange_rate}</td>
            <td>{y.opportunity}</td>
            <td>{y.record_type}</td>
            <td>{y.trader_name}</td>
          </tr>
           ))}
        </tbody>
        :<tbody>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td>No data available</td>
          <td></td>
          <td></td>
          <td></td>
          </tr>
          </tbody>
    }
        </table>

           </Row>
           <Row id='table-styles'>
           <div className="col-md-12" id='head'>
         <h4 className="heading"><span>Orders Information </span></h4>
         <hr></hr>
         </div>
           <table id='orders' className="">
        <thead>
        <tr>
          <th>Order No</th>
          <th>Account Name</th>
          <th>Barge Name</th>
          <th>Laycan From Date</th>
          <th>Laycan From Date</th>
          <th>Laycan Month</th>
          <th>Laycan Period</th>

        </tr>
        </thead>

        {orderData.length> 0  ?

          <tbody>
          {
                orderData.map((y,i)=>(
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
           ))}
        </tbody>
        :<tbody>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td>No data available</td>
          <td></td>
          <td></td>
          <td></td>
          </tr>
          </tbody>
    }
        </table>

           </Row>
           <Row id='table-styles'>
             <div className="col-md-12" id='head'>
           <h4 className="heading"><span>Contract Product</span></h4>
           <hr></hr>
           </div>
             <table id='orders' className="">
          <thead>
          <tr>
            <th>Contract Product Number</th>
            <th>Product Master</th>
            <th>Product Name</th>
            <th>Product Description</th>
            <th>Contract Shipment</th>

          </tr>
          </thead>

          {contractProduct.length> 0  ?

            <tbody>
            {
                  contractProduct.map((x,i)=>(
            <tr>
              <td>{x.contract_product_number}</td>
              <td>{x.product_master}</td>
              <td>{x.product_name}</td>
              <td>{x.product_description}</td>
              <td>{x.contract_shipment}</td>

            </tr>
             ))}
          </tbody>
          :<tbody>
          <tr>

            <td></td>
            <td></td>
            <td>No data available</td>
            <td></td>
            <td></td>
            </tr>
            </tbody>
      }
          </table>

             </Row>
           <Row id='table-styles'>
           <div className="col-md-12" id='head'>
         <h4 className="heading"><span>Documents Information</span><span>
         <Link to={"/contract/add-document/" + id}>
                        <i className="fe fe-download-cloud me-2"></i>
                         New
                      </Link>
                      </span>
                      </h4>
         <hr></hr>
         </div>
           <table id='document' className="">
        <thead>
        <tr>
          <th>contract list of docs</th>
          <th>document required name</th>
          <th>list of docuemnts</th>
          <th>original in no</th>
          <th>suffix</th>

        </tr>
        </thead>

        {contractDocument.length> 0  ?

          <tbody>
          {
                contractDocument.map((y,i)=>(
          <tr>
            <td className=""><Link to={'/contract/document-view/'+y.id}>{y.contract_list_of_docs}</Link></td>
            <td>{y.document_required_name}</td>
            <td>{y.list_of_docuemnts}</td>
            <td>{y.original_in_no}</td>
            <td>{y.suffix}</td>

          </tr>
           ))}
        </tbody>
        :<tbody>
        <tr>
          <td></td>
          <td></td>

          <td>No data available</td>
          <td></td>

          </tr>
          </tbody>
    }
        </table>

           </Row>
       </div>
       </>
       )}
       </>
    ):(
      <>
    {datas.contract_record_type === "Supplier - Long Term" ? (
      <>
      {isEditMode ? (
        <>
        <Row className="mb-3">
           <h4 className="heading">Contract Information</h4>
           <Form.Group as={Col} md="6">
              <FloatingLabel
                 controlId="floatingSelect"
                 className="dropDown"
                 label="Currency"
                 >
                 <Form.Select
                 aria-label="Floating label select example"
                 name="currency"
                 placeholder="Currency"
                 className="error-validation"
                 defaultValue={datas.currency || ""}
                 onChange={handleChangeInput}
                 >
                 <option value="">Select </option>
                 <option value="USD">USD </option>
                 <option value="IDR">IDR </option>
                 </Form.Select>
              </FloatingLabel>
           </Form.Group>
           <Form.Group as={Col} md="6" id='lockeds'>
              <input type='checkbox' name='locked' onChange={(e) => setCheck1(e.target.checked) } /><label>Locked</label>
           </Form.Group>
           <Form.Group as={Col} md="6">
              <FloatingLabel
                 controlId="floatingInput"
                 label="Exchange rate"
                 className="mb-3"
                 >
                 <Form.Control
                 type="text"
                 placeholder="Exchange rate "
                 name="exchange_rate"
                 defaultValue={datas.exchange_rate || ""}
                 onChange={handleChangeInput}
                 />
              </FloatingLabel>
           </Form.Group>
           <Form.Group as={Col} md="6">
              <FloatingLabel
                 controlId="floatingSelect"
                 className="dropDown"
                 label="Status"
                 >
                 <Form.Select
                 aria-label="Floating label select example"
                 name="status"
                 placeholder="Status"
                 className="error-validation"
                 defaultValue={datas.status || ""}
                 onChange={handleChangeInput}
                 >
                 <option value="">Select </option>
                 <option value="Draft">Draft </option>
                 <option value="Approved">Approved </option>
                 </Form.Select>
              </FloatingLabel>
           </Form.Group>
           <Form.Group as={Col} md="6">
              <FloatingLabel
                 controlId="floatingSelect"
                 className="dropDown"
                 label="Account Name"
                 >
                 <Form.Select
                 aria-label="Floating label select example"
                 name="account_name"
                 placeholder="Account Name"
                 className="error-validation"
                 defaultValue={datas.account_name || ""}
                 onChange={handleChangeInput}
                 >
                 <option value="">Select </option>
                 {accountName.map((x) => {
                 return (
                 <option value={x.account_name}>{x.account_name}</option>
                 );
                 })}
                 </Form.Select>
              </FloatingLabel>
           </Form.Group>
           <Form.Group as={Col} md="6">
              <FloatingLabel
                 controlId="floatingInput"
                 label="Contract record type"
                 className="mb-3"
                 >
                 <Form.Control
                 type="text"
                 placeholder="Contract record type"
                 name="contract_record_type" className='record-type'
                 defaultValue={datas.contract_record_type || ""}
                 onChange={handleChangeInput}
                 />
              </FloatingLabel>
           </Form.Group>
           <Form.Group as={Col} md="6">
              <FloatingLabel
                 controlId="floatingInput"
                 label="Contract Owner"
                 className="mb-3"
                 >
                 <Form.Control
                 type="text"
                 placeholder="Contract Owner"
                 name="contract_owner"
                 defaultValue={datas.contract_owner || ""}
                 onChange={handleChangeInput}
                 />
              </FloatingLabel>
           </Form.Group>
           <Form.Group as={Col} md="6">
              <FloatingLabel
                 controlId="floatingInput"
                 label="Rwood contract no"
                 className="mb-3"
                 >
                 <Form.Control
                 type="text"
                 placeholder="Rwood contract no"
                 name="Rwood_Contract_No"
                 defaultValue={datas.Rwood_Contract_No || ""}
                 onChange={handleChangeInput}
                 />
              </FloatingLabel>
           </Form.Group>
           <Form.Group as={Col} md="6">
              <FloatingLabel
                 controlId="floatingInput"
                 label="Contract Start Date"
                 className="mb-3 date-picker"
                 >
                 <DatePicker
                    selected={startDate}
                    onChange={(date) =>
                 setStartDate(date)}
                 customInput={
                 <ExampleCustomInput />
                 }
                 dayClassName={() => "example-datepicker-day-class"}
                 popperClassName="example-datepicker-class"
                 todayButton="TODAY"
                 dateFormat="yyyy-MM-dd"
                 placeholderText="Choose Date"
                 />
              </FloatingLabel>
           </Form.Group>
           <Form.Group as={Col} md="6">
              <FloatingLabel
                 controlId="floatingSelect"
                 className="dropDown"
                 label="Owner Expiration Notice"
                 >
                 <Form.Select
                 aria-label="Floating label select example"
                 name="owner_expiration_notice"
                 placeholder="Owner Expiration Notice"
                 defaultValue={datas.owner_expiration_notice || ""}
                 onChange={handleChangeInput}
                 >
                 <option value="30">30 Days</option>
                 <option value="45">45 Days</option>
                 <option value="60">60 Days</option>
                 <option value="90">90 Days</option>
                 <option value="120">120 Days</option>
                 </Form.Select>
              </FloatingLabel>
           </Form.Group>
           <Form.Group as={Col} md="6">
              <FloatingLabel
                 controlId="floatingInput"
                 label="Contract Effective Date"
                 className="mb-3 date-picker"
                 >
                 <DatePicker
                    selected={start}
                    onChange={(date) =>
                 setStart(date)}
                 customInput={
                 <ExampleCustomInput2 />
                 }
                 dayClassName={() => "example-datepicker-day-class"}
                 popperClassName="example-datepicker-class"
                 todayButton="TODAY"
                 dateFormat="yyyy-MM-dd"
                 placeholderText="Choose Date"
                 />
              </FloatingLabel>
           </Form.Group>
           <Form.Group as={Col} md="6">
              <FloatingLabel
                 controlId="floatingInput"
                 label="Contract term in months"
                 className="mb-3"
                 >
                 <Form.Control
                 type="text"
                 placeholder="Contract term in months"
                 name="contract_term_in_months"
                 defaultValue={datas.contract_term_in_months || ""}
                 onChange={handleChangeInput}
                 />
              </FloatingLabel>
           </Form.Group>
           <Form.Group as={Col} md="6">
              <FloatingLabel
                 controlId="floatingSelect"
                 className="dropDown"
                 label="Contract Status"
                 >
                 <Form.Select
                 aria-label="Floating label select example"
                 name="contract_status"
                 placeholder="Contract Status"
                 defaultValue={datas.contract_status || ""}
                 onChange={handleChangeInput}
                 >
                 <option value="">--None--</option>
                 <option value="In Approval Process">In Approval Process</option>
                 <option value="Activated">Activated</option>
                 <option value="Draft">Draft</option>
                 <option value="Under Review">Under Review</option>
                 <option value="Draft Sent for Signing">Draft Sent for Signing</option>
                 <option value="Draft Received for Signing">Draft Received for Signing</option>
                 <option value="Signed Contract yet to Receive">Signed Contract yet to Receive</option>
                 <option value="Signed Contract Sent for Sign">Signed Contract Sent for Sign</option>
                 <option value="Signed Original Contract Need to Courier">Signed Original Contract Need to Courier</option>
                 <option value="Signed Original Contract yet to Receive">Signed Original Contract yet to Receive</option>
                 <option value="Original Contract Signed by Both Parties">Original Contract Signed by Both Parties</option>
                 <option value="Original Contract Signed and Received by Both Parties">Original Contract Signed and Received by Both Parties</option>
                 <option value="Closed">Closed</option>
                 <option value="Partially completed">Partially completed</option>
                 <option value="Completed">Completed</option>
                 <option value="Cancelled">Cancelled</option>
                 <option value="Partially cancelled">Partially cancelled</option>
                 <option value="Shipments - Arutmin">Shipments - Arutmin</option>
                 <option value="Partially Signed Contract">Partially Signed Contract</option>
                 </Form.Select>
              </FloatingLabel>
           </Form.Group>
           <Form.Group as={Col} md="6">
              <FloatingLabel
                 controlId="floatingTextarea2"
                 label="Pending Matters"
                 >
                 <Form.Control
                 as="textarea"
                 placeholder="Pending Matters"
                 style={{ height: "100px!important" }}
                 name="pending_matters"
                 defaultValue={datas.pending_matters || ""}
                 onChange={handleChangeInput}
                 />
              </FloatingLabel>
           </Form.Group>
           <Form.Group as={Col} md="6">
              <FloatingLabel
                 controlId="floatingSelect"
                 className="dropDown"
                 label="Trader Name"
                 >
                 <Form.Select
                 aria-label="Floating label select example"
                 name="trader_name"
                 placeholder="Trader Name"
                 defaultValue={datas.trader_name || ""}
                 onChange={handleChangeInput}
                 >
                 <option value="">Select </option>
                 {traderData.map((x, i) => {
                 return (
                 <option key={i} value={x.rwood_username}>
                    {x.rwood_username}
                 </option>
                 );
                 })}
                 </Form.Select>
              </FloatingLabel>
           </Form.Group>
           <Form.Group as={Col} md="6">
              <FloatingLabel
                 controlId="floatingInput"
                 label="Master Contract Reference"
                 className="mb-3"
                 >
                 <Form.Control
                 type="text"
                 placeholder="Master Contract Reference"
                 name="master_conference_reference"
                 defaultValue={datas.master_conference_reference || ""}
                 onChange={handleChangeInput}
                 />
              </FloatingLabel>
           </Form.Group>
           <Form.Group as={Col} md="6">
              <FloatingLabel
                 controlId="floatingSelect"
                 className="dropDown"
                 label="Company"
                 >
                 <Form.Select
                 aria-label="Floating label select example"
                 name="company"
                 placeholder="Company"
                 className="error-validation"
                 value={datas.company || ""}
                 onChange={handleChangeInput}
                 >
                 <option value="">Select </option>
                 {companyData.map((x, i) => {
                 return (
                 <option key={i} value={x.company_name}>
                    {x.company_name}
                 </option>
                 );
                 })}
                 </Form.Select>
              </FloatingLabel>
           </Form.Group>
           <Form.Group as={Col} md="6">
              <FloatingLabel
                 controlId="floatingInput"
                 label="Renewal date"
                 className="mb-3 date-picker"
                 >
                 <DatePicker
                    selected={startDate}
                    onChange={(date) =>
                 setStartDate(date)}
                 customInput={
                 <ExampleCustomInput />
                 }
                 dayClassName={() => "example-datepicker-day-class"}
                 popperClassName="example-datepicker-class"
                 todayButton="TODAY"
                 dateFormat="yyyy-MM-dd"
                 placeholderText="Choose Date"
                 />
              </FloatingLabel>
           </Form.Group>
           <Form.Group as={Col} md="6">
              <FloatingLabel
                 controlId="floatingInput"
                 label="Renewal contract number"
                 className="mb-3"
                 >
                 <Form.Control
                 type="number"
                 placeholder="Renewal contract number "
                 name="renewal_contract_number "
                 defaultValue={datas.renewal_contract_number || ""}
                 onChange={handleChangeInput}
                 />
              </FloatingLabel>
           </Form.Group>
           <Form.Group as={Col} md="6">
              <FloatingLabel controlId="floatingTextarea2" label="Remark">
                 <Form.Control
                 as="textarea"
                 placeholder="Remark"
                 style={{ height: "100px!important" }}
                 name="remark"
                 defaultValue={datas.remark || ""}
                 onChange={handleChangeInput}
                 />
              </FloatingLabel>
           </Form.Group>
        </Row>
        <Row className="mb-3">
           <h4 className="heading">Commodity and Contracted Quantity</h4>
           <Form.Group as={Col} md="6">
              <FloatingLabel
                 controlId="floatingInput"
                 label="Port Of Loading"
                 className="mb-3"
                 >
                 <Form.Control
                 type="text"
                 placeholder="Port Of Loading"
                 name="port_of_loading"
                 defaultValue={datas.port_of_loading || ""}
                 onChange={handleChangeInput}
                 />
              </FloatingLabel>
           </Form.Group>
           <Form.Group as={Col} md="6">
              <FloatingLabel
                 controlId="floatingInput"
                 label="Origin"
                 className="mb-3"
                 >
                 <Form.Control
                 type="text"
                 placeholder="Origin"
                 name="origin"
                 defaultValue={datas.origin || ""}
                 onChange={handleChangeInput}
                 />
              </FloatingLabel>
           </Form.Group>
           <Form.Group as={Col} md="6">
              <FloatingLabel
                 controlId="floatingInput"
                 label="Contract quantity in MT"
                 className="mb-3"
                 >
                 <Form.Control
                 type="number"
                 placeholder="Contract quantity in MT"
                 name="contract_quantity_in_MT"
                 className="error-validation"
                 defaultValue={datas.contract_quantity_in_MT || ""}
                 onChange={handleChangeInput}/>
              </FloatingLabel>
           </Form.Group>
           <Form.Group as={Col} md="6">
              <FloatingLabel controlId="floatingSelect"
                 className='dropDown' label="Commodity">
                 <Form.Select aria-label="Floating label select example"
                 name="commodity"
                 placeholder="Commodity"
                 value={datas.commodity || ""}
                 onChange={handleChangeInput}
                 >
                 <option>---None---</option>
                 <option value="Indonesian Steam Coal in Bulk (&quot;Coal&quot;)">Indonesian Steam Coal in Bulk ("Coal")</option>
                 <option value="US Coal">US Coal</option>
                 <option value="Russia Coal">Russia Coal</option>
                 <option value="SA Coal">SA Coal</option>
                 <option value="Clinker in Bulk">Clinker in Bulk</option>
                 <option value="Australian Steam Coal">Australian Steam Coal</option>
                 </Form.Select>
              </FloatingLabel>
           </Form.Group>
        </Row>
        <Row className="mb-3">
           <h4 className="heading">Price and Price Adjustment</h4>
           <Form.Group as={Col} md="6">
              <FloatingLabel
                 controlId="floatingInput"
                 label="Price PMT"
                 className="mb-3"
                 >
                 <Form.Control
                 type="number"
                 placeholder="Price PMT"
                 name="price_PMT"
                 defaultValue={datas.price_PMT || ""}
                 onChange={handleChangeInput}
                 />
              </FloatingLabel>
           </Form.Group>
           <Form.Group as={Col} md="6">
              <FloatingLabel controlId="floatingSelect"
                 className='dropDown' label="Price book">
                 <Form.Select aria-label="Floating label select example"
                 name="price_book"
                 placeholder="Price book"
                 value={datas.price_book || ""}
                 onChange={handleChangeInput}
                 >
                 <option value='' >Select</option>
                 {
                 priceBook.map((x)=>{
                 return(
                 <option value={x.price_book_name}>{x.price_book_name}</option>
                 )
                 })
                 }
                 </Form.Select>
              </FloatingLabel>
           </Form.Group>
           <Form.Group as={Col} md="6">
              <FloatingLabel
                 controlId="floatingInput"
                 label="Price PMT in words"
                 className="mb-3"
                 >
                 <Form.Control
                 type="text"
                 placeholder="Price PMT in words"
                 name="price_PMT_in_words"
                 defaultValue={datas.price_PMT_in_words || ""}
                 onChange={handleChangeInput}
                 />
              </FloatingLabel>
           </Form.Group>
           <Form.Group as={Col} md="6">
              <FloatingLabel
                 controlId="floatingSelect"
                 className="dropDown"
                 label="Price Basis"
                 >
                 <Form.Select
                 aria-label="Floating label select example"
                 name="price_basis"
                 placeholder="Price Basis"
                 value={datas.price_basis || ""}
                 onChange={handleChangeInput}
                 >
                 <option value="">--None--</option>
                 <option value="In Approval Process">In Approval Process</option>
                 <option value="Activated">Activated</option>
                 <option value="Draft">Draft</option>
                 <option value="Under Review">Under Review</option>
                 <option value="Draft Sent for Signing">Draft Sent for Signing</option>
                 <option value="Draft Received for Signing">Draft Received for Signing</option>
                 <option value="Signed Contract yet to Receive">Signed Contract yet to Receive</option>
                 <option value="Signed Contract Sent for Sign">Signed Contract Sent for Sign</option>
                 <option value="Signed Original Contract Need to Courier">Signed Original Contract Need to Courier</option>
                 <option value="Signed Original Contract yet to Receive">Signed Original Contract yet to Receive</option>
                 <option value="Original Contract Signed by Both Parties">Original Contract Signed by Both Parties</option>
                 <option value="Original Contract Signed and Received by Both Parties">Original Contract Signed and Received by Both Parties</option>
                 <option value="Closed">Closed</option>
                 <option value="Partially completed">Partially completed</option>
                 <option value="Completed">Completed</option>
                 <option value="Cancelled">Cancelled</option>
                 <option value="Partially cancelled">Partially cancelled</option>
                 <option value="Shipments - Arutmin">Shipments - Arutmin</option>
                 <option value="Partially Signed Contract">Partially Signed Contract</option>
                 </Form.Select>
              </FloatingLabel>
           </Form.Group>
           <Form.Group as={Col} md="6">
              <FloatingLabel
                 controlId="floatingSelect"
                 className="dropDown"
                 label="Price type"
                 >
                 <Form.Select
                 aria-label="Floating label select example"
                 name="price_type"
                 placeholder="Price type"
                 value={datas.price_type || ""}
                 onChange={handleChangeInput}
                 >
                 <option value="">Select</option>
                 {getPrice.map((x) => {
                 return (
                 <option value={x.price_type_name}>
                    {x.price_type_name}
                 </option>
                 );
                 })}
                 </Form.Select>
              </FloatingLabel>
           </Form.Group>
           <Form.Group as={Col} md="6">
              <FloatingLabel
                 controlId="floatingInput"
                 label="Price basis GAR"
                 className="mb-3"
                 >
                 <Form.Control
                 type="number"
                 placeholder="Price basis GAR"
                 name="price_basis_GAR"
                 defaultValue={datas.price_basis_GAR || ""}
                 onChange={handleChangeInput}
                 />
              </FloatingLabel>
           </Form.Group>
           <Form.Group as={Col} md="6">
              <FloatingLabel
                 controlId="floatingInput"
                 label="Price adjustment clause"
                 className="mb-3"
                 >
                 <Form.Control
                 type="text"
                 placeholder="Price adjustment clause"
                 name="price_adjustment_clause"
                 defaultValue={datas.price_adjustment_clause || ""}
                 onChange={handleChangeInput}
                 />
              </FloatingLabel>
           </Form.Group>
           <Form.Group as={Col} md="6">
              <FloatingLabel
                 controlId="floatingInput"
                 label="Rejections"
                 className="mb-3"
                 >
                 <Form.Control
                 as="textarea"
                 placeholder="Rejections"
                 name="rejections"
                 defaultValue={datas.rejections || ""}
                 onChange={handleChangeInput}
                 />
              </FloatingLabel>
           </Form.Group>
           <Form.Group as={Col} md="6">
              <FloatingLabel
                 controlId="floatingTextarea2"
                 label="Description"
                 >
                 <Form.Control
                 as="textarea"
                 placeholder="Description"
                 style={{ height: "100px" }}
                 name="description"
                 defaultValue={datas.description || ""}
                 onChange={handleChangeInput}
                 />
              </FloatingLabel>
           </Form.Group>
           <Form.Group as={Col} md="6">
              <FloatingLabel
                 controlId="floatingTextarea2"
                 label="Pricing Terms"
                 >
                 <Form.Control
                 as="textarea"
                 placeholder="Pricing Terms"
                 style={{ height: "100px!important" }}
                 name="pricing_terms"
                 defaultValue={datas.pricing_terms || ""}
                 onChange={handleChangeInput}
                 />
              </FloatingLabel>
           </Form.Group>
        </Row>
        <Row className="mb-3">
           <h4 className="heading">Other Standard Term From Supplier</h4>
           <Form.Group as={Col} md="12">
              <FloatingLabel
                 controlId="floatingTextarea2"
                 label="Payment Terms"
                 >
                 <Form.Control
                 as="textarea"
                 placeholder="Payment Terms"
                 style={{ height: "100px!important" }}
                 name="payment_terms"
                 defaultValue={datas.payment_terms || ""}
                 onChange={handleChangeInput}
                 />
              </FloatingLabel>
           </Form.Group>
           <Form.Group as={Col} md="6">
              <FloatingLabel
                 controlId="floatingSelect"
                 className="dropDown"
                 label="Applicable Law"
                 >
                 <Form.Select
                 aria-label="Floating label select example"
                 name="applicable_law"
                 placeholder="Applicable Law"
                 value={datas.applicable_law || ""}
                 onChange={handleChangeInput}
                 >
                 <option value="">--None--</option>
                 <option value="English Law">English Law</option>
                 <option value="Singapore Law">Singapore Law</option>
                 <option value="Indonesian Law">Indonesian Law</option>
                 </Form.Select>
              </FloatingLabel>
           </Form.Group>
           <Form.Group as={Col} md="6">
              <FloatingLabel
                 controlId="floatingSelect"
                 className="dropDown"
                 label="Arbitration"
                 >
                 <Form.Select
                 aria-label="Floating label select example"
                 name="arbitration"
                 placeholder="Arbitration"
                 value={datas.arbitration || ""}
                 onChange={handleChangeInput}
                 >
                 <option value="">--None--</option>
                 <option value="In Singapore as per SIAC">In Singapore as per SIAC</option>
                 <option value="Singapore Chamber of Maritime Arbitration (SCMA)">Singapore Chamber of Maritime Arbitration (SCMA)</option>
                 <option value="Laws of England and Wales">Laws of England and Wales</option>
                 <option value="Rules of Badan Arbitrase Nasional Indonesia (BANI)">Rules of Badan Arbitrase Nasional Indonesia (BANI)</option>
                 </Form.Select>
              </FloatingLabel>
           </Form.Group>
           <Form.Group as={Col} md="6">
              <FloatingLabel
                 controlId="floatingInput"
                 label="General terms"
                 className="mb-3"
                 >
                 <Form.Control
                 as="textarea"
                 placeholder="General terms"
                 name="general_terms"
                 defaultValue={datas.general_terms || ""}
                 onChange={handleChangeInput}
                 />
              </FloatingLabel>
           </Form.Group>
        </Row>
        <Row className="mb-3">
           <h4 className="heading">Description Information</h4>
           <Form.Group as={Col} md="6">
              <FloatingLabel
                 controlId="floatingInput"
                 label="Loading rate per day"
                 className="mb-3"
                 >
                 <Form.Control
                 type="text"
                 placeholder="Loading rate per day"
                 name="loading_rate_per_day"
                 defaultValue={datas.loading_rate_per_day || ""}
                 onChange={handleChangeInput}
                 />
              </FloatingLabel>
           </Form.Group>
           <Form.Group as={Col} md="6">
              <FloatingLabel
                 controlId="floatingInput"
                 label="Special Terms"
                 className="mb-3"
                 >
                 <Form.Control
                 as="textarea"
                 placeholder="Special Terms"
                 name="special_terms"
                 defaultValue={datas.special_terms || ""}
                 onChange={handleChangeInput}
                 />
              </FloatingLabel>
           </Form.Group>
           <Form.Group as={Col} md="6">
              <FloatingLabel
                 controlId="floatingInput"
                 label="Bonus penalty clause"
                 className="mb-3"
                 >
                 <Form.Control
                 as="textarea"
                 placeholder="Bonus penalty clause"
                 name="bonus_penalty_clause"
                 defaultValue={datas.bonus_penalty_clause || ""}
                 onChange={handleChangeInput}
                 />
              </FloatingLabel>
           </Form.Group>
        </Row>
        <Row className="mb-3">
           <h4 className="heading">Description Information</h4>
           <Form.Group as={Col} md="6">
              <FloatingLabel
                 controlId="floatingInput"
                 label="Billing street"
                 className="mb-3"
                 >
                 <Form.Control
                 type="text"
                 placeholder="Billing street"
                 name="billing_street"
                 defaultValue={datas.billing_street || ""}
                 onChange={handleChangeInput}
                 />
              </FloatingLabel>
           </Form.Group>
           <Form.Group as={Col} md="6">
              <FloatingLabel
                 controlId="floatingInput"
                 label="Shipping street"
                 className="mb-3"
                 >
                 <Form.Control
                 type="text"
                 placeholder="Shipping street"
                 name="shipping_street"
                 defaultValue={datas.shipping_street || ""}
                 onChange={handleChangeInput}
                 />
              </FloatingLabel>
           </Form.Group>
           <Form.Group as={Col} md="6">
              <FloatingLabel
                 controlId="floatingInput"
                 label="Billing zip postal code"
                 className="mb-3"
                 >
                 <Form.Control
                 type="text"
                 placeholder="Billing zip postal code"
                 name="billing_zip_postal_code"
                 defaultValue={datas.billing_zip_postal_code || ""}
                 onChange={handleChangeInput}
                 />
              </FloatingLabel>
           </Form.Group>
           <Form.Group as={Col} md="6">
              <FloatingLabel
                 controlId="floatingInput"
                 label="Shipping zip postal code"
                 className="mb-3"
                 >
                 <Form.Control
                 type="text"
                 placeholder="Shipping zip postal code"
                 name="shipping_zip_postal_code"
                 defaultValue={datas.shipping_zip_postal_code || ""}
                 onChange={handleChangeInput}
                 />
              </FloatingLabel>
           </Form.Group>
           <Form.Group as={Col} md="6">
              <FloatingLabel
                 controlId="floatingInput"
                 label="Billing city"
                 className="mb-3"
                 >
                 <Form.Control
                 type="text"
                 placeholder="Billing city"
                 name="billing_city"
                 defaultValue={datas.billing_city || ""}
                 onChange={handleChangeInput}
                 />
              </FloatingLabel>
           </Form.Group>
           <Form.Group as={Col} md="6">
              <FloatingLabel
                 controlId="floatingInput"
                 label="Shipping city"
                 className="mb-3"
                 >
                 <Form.Control
                 type="text"
                 placeholder="Shipping city"
                 name="shipping_city"
                 defaultValue={datas.shipping_city || ""}
                 onChange={handleChangeInput}
                 />
              </FloatingLabel>
           </Form.Group>
           <Form.Group as={Col} md="6">
              <FloatingLabel
                 controlId="floatingInput"
                 label="Billing state or province"
                 className="mb-3"
                 >
                 <Form.Control
                 type="text"
                 placeholder="Billing state or province"
                 name="billing_state_or_province"
                 defaultValue={datas.billing_state_or_province || ""}
                 onChange={handleChangeInput}
                 />
              </FloatingLabel>
           </Form.Group>
           <Form.Group as={Col} md="6">
              <FloatingLabel
                 controlId="floatingInput"
                 label="Shipping state or province"
                 className="mb-3"
                 >
                 <Form.Control
                 type="text"
                 placeholder="Shipping state or province"
                 name="shipping_state_or_province"
                 defaultValue={datas.shipping_state_or_province || ""}
                 onChange={handleChangeInput}
                 />
              </FloatingLabel>
           </Form.Group>
           <Form.Group as={Col} md="6">
              <FloatingLabel
                 controlId="floatingInput"
                 label="Billing country"
                 className="mb-3"
                 >
                 <Form.Control
                 type="text"
                 placeholder="Billing country"
                 name="billing_country"
                 defaultValue={datas.billing_country || ""}
                 onChange={handleChangeInput}
                 />
              </FloatingLabel>
           </Form.Group>
           <Form.Group as={Col} md="6">
              <FloatingLabel
                 controlId="floatingInput"
                 label="Shipping country"
                 className="mb-3"
                 >
                 <Form.Control
                 type="text"
                 placeholder="Shipping country"
                 name="shipping_country"
                 defaultValue={datas.shipping_country || ""}
                 onChange={handleChangeInput}
                 />
              </FloatingLabel>
           </Form.Group>
        </Row>
        <Row className="mb-3">
           <h4 className="heading">Signature Information</h4>
           <Form.Group as={Col} md="6">
              <FloatingLabel
                 controlId="floatingSelect"
                 className="dropDown"
                 label="Customer signed by"
                 >
                 <Form.Select
                 aria-label="Floating label select example"
                 name="customer_signed_by"
                 placeholder="Customer signed by"
                 value={datas.customer_signed_by || ""}
                 onChange={handleChangeInput}
                 >
                 <option value="">Select </option>
                 {contacts.map((x) => {
                 return (
                 <option value={x.contact_full_name}>
                    {x.contact_full_name}
                 </option>
                 );
                 })}
                 </Form.Select>
              </FloatingLabel>
           </Form.Group>
           <Form.Group as={Col} md="6">
              <FloatingLabel
                 controlId="floatingInput"
                 label="Customer signed title"
                 className="mb-3"
                 >
                 <Form.Control
                 type="text"
                 placeholder="Customer signed title"
                 name="customer_signed_title"
                 defaultValue={datas.customer_signed_title || ""}
                 onChange={handleChangeInput}
                 />
              </FloatingLabel>
           </Form.Group>
           <Form.Group as={Col} md="6">
              <FloatingLabel
                 controlId="floatingSelect"
                 className="dropDown"
                 label="Company signed by"
                 >
                 <Form.Select
                 aria-label="Floating label select example"
                 name="company_signed_by"
                 placeholder="Company signed by"
                 value={datas.company_signed_by || ""}
                 onChange={handleChangeInput}
                 >
                 <option value="">Select </option>
                 {users.map((x) => {
                 return
                 <option value={x.username}>{x.username}</option>
                 ;
                 })}
                 </Form.Select>
              </FloatingLabel>
           </Form.Group>
           <Form.Group as={Col} md="6">
              <FloatingLabel
                 controlId="floatingInput"
                 label="Original Contract Recieved Date"
                 className="mb-3 date-picker"
                 >
                 <DatePicker
                    selected={received}
                    onChange={(date) =>
                 setReceived(date)}
                 customInput={
                 <ExampleCustomInput5 />
                 }
                 dayClassName={() => "example-datepicker-day-class"}
                 popperClassName="example-datepicker-class"
                 todayButton="TODAY"
                 dateFormat="yyyy-MM-dd"
                 placeholderText="Choose Date"
                 />
              </FloatingLabel>
           </Form.Group>
           <Form.Group as={Col} md="6">
              <FloatingLabel
                 controlId="floatingInput"
                 label="Company Signed Date"
                 className="mb-3 date-picker"
                 >
                 <DatePicker
                    selected={signed}
                    onChange={(date) =>
                 setSigned(date)}
                 customInput={
                 <ExampleCustomInput4 />
                 }
                 dayClassName={() => "example-datepicker-day-class"}
                 popperClassName="example-datepicker-class"
                 todayButton="TODAY"
                 dateFormat="yyyy-MM-dd"
                 placeholderText="Choose Date"
                 />
              </FloatingLabel>
           </Form.Group>
           <Form.Group as={Col} md="6">
              <FloatingLabel
                 controlId="floatingInput"
                 label="Original contract sent date"
                 className="mb-3 date-picker"
                 >
                 <DatePicker
                    selected={sent}
                    onChange={(date) =>
                 setSent(date)}
                 customInput={
                 <ExampleCustomInput7 />
                 }
                 dayClassName={() => "example-datepicker-day-class"}
                 popperClassName="example-datepicker-class"
                 todayButton="TODAY"
                 dateFormat="yyyy-MM-dd"
                 placeholderText="Choose Date"
                 />
              </FloatingLabel>
           </Form.Group>
        </Row>
        </>

      ):(
        <>
      <div className="contactAccount">
        <div className="tasks">
        <div className="edit-delete">
                  <span id="edit" onClick={handleEditClick}>
                    Edit
                  </span>
                  <span>
                    Delete
                  </span>
                  <span>
                    Activate
                  </span>
                  <span>
                    Generate Contract
                  </span>
<span>
                     Generate Contract Clauses
                  </span>
<span>
                    Shipment Checklist
                  </span>
                  </div>
          <div className="">
            <div className="row">
              <div className="col-md-12" id="head">
                <h4 className="heading">Information</h4>

                <hr></hr>
              </div>
            </div>
            <table class="table table-bordered account-table tables">
              <tbody>
                <tr>
                  <td id="td-right"><span>Locked</span> </td>
                  <td>{datas.locked}</td>


                </tr>
                <tr>
                <td id="td-right">
                    <span>Currency</span>
                  </td>
                  <td>
                    {datas.currency}
                  </td>
                  <td id="td-right">
                    <span>
                      Trader Name

                    </span>
                    </td><td>
                    {datas.trader_name}
                  </td>
                </tr>
                <tr>
                <td id="td-right">
                    <span>Contract record type</span>
                    </td>
                    <td>
                     {datas.contract_record_type}
                  </td>
                  <td id="td-right">
                    <span>Account Name</span>
                    </td>
                    <td>
                     {datas.account_name}
                  </td>
                </tr>
                <tr>

                <td id="td-right">
                    <span>Exchange Rate</span>
                    </td>
                    <td>

                    {datas.exchange_rate}
                  </td>
                  <td id="td-right">
                    <span>Contract Owner</span>
                    </td>
                    <td>
                    {datas.contract_owner}
                  </td>

                </tr>
                <tr>

                  <td id="td-right">
                      <span>Price book </span>
                      </td>
                      <td>

                      {datas.price_book}
                    </td>
                    <td id="td-right">
                      <span>Rwood contract no</span>
                      </td>
                      <td>
                      {datas.Rwood_Contract_No}
                    </td>

                  </tr>
                  <tr>

                  <td id="td-right">
                      <span>Status</span>
                      </td>
                      <td>

                      {datas.status}
                    </td>
                    <td id="td-right">
                      <span>Contract Status</span>
                      </td>
                      <td>
                      {datas.contract_status}
                    </td>

                  </tr>
                  <tr>

                  <td id="td-right">
                      <span>Contract term in months</span>
                      </td>
                      <td>

                      {datas.contract_term_in_months}
                    </td>
                    <td id="td-right">
                      <span>Pending Matters</span>
                      </td>
                      <td>
                      {datas.pending_matters}
                    </td>

                  </tr>
                  <tr>

                  <td id="td-right">
                      <span>Remark</span>
                      </td>
                      <td>

                      {datas.remark}
                    </td>
                    <td id="td-right">
                      <span>Contract Start Date</span>
                      </td>
                      <td>
                      {datas.contract_start_date}
                    </td>

                  </tr>

                  <tr>

                  <td id="td-right">
                      <span>Contract Effective Date</span>
                      </td>
                      <td>

                      {datas.contract_effective_date}
                    </td>
                    <td id="td-right">
                      <span>Master Contract Reference</span>
                      </td>
                      <td>
                      {datas.master_conference_reference}
                    </td>

                  </tr>

                  <tr>

                  <td id="td-right">
                      <span>Renewal Date</span>
                      </td>
                      <td>

                      {datas.renewal_date}
                    </td>
                    <td id="td-right">
                      <span>Renewal contract number</span>
                      </td>
                      <td>
                      {datas.renewal_contract_number}
                    </td>

                  </tr>
              </tbody>
            </table>
          </div>
          <div className="">
            <div className="row">
              <div className="col-md-12" id="head">
                <h4 className="heading">Commodity and Contracted Quantity</h4>
                <hr></hr>
              </div>
            </div>
            <table class="table table-bordered account-table tables">
              <tbody>

                <tr>
                <td id="td-right">
                    <span>Port Of Loading</span>
                    </td>
                    <td>
                    {datas.port_of_loading}
                  </td>
                    <td id="td-right">
                     <span>Origin</span>
                    </td>
                    <td>
                    {datas.origin}
                  </td>

                    </tr>

                <tr>


                  <td id="td-right">
                    <span>Contract Quantity in MT</span>
                    </td>
                    <td>
                    {datas.contract_quantity_in_MT}
                  </td>
                  <td id="td-right">

                    <span>Commodity</span>
                    </td>
                    <td>
                    {datas.commodity}
                  </td>

                  </tr>

              </tbody>
            </table>
          </div>

          <div className="">
            <div className="row">
              <div className="col-md-12" id="head">
                <h4 className="heading">Price and Price Adjustment</h4>
                <hr></hr>
              </div>
            </div>
            <table class="table table-bordered account-table tables">
              <tbody>
               <tr>
               <td id="td-right">
                    <span>Price PMT</span>
                   </td><td>
                   {datas.price_PMT}
                  </td>
                  <td id="td-right">
                  <span>Price Book</span>
                   </td><td>
                   {datas.price_book}
                  </td>
                </tr>

                <tr>
               <td id="td-right">
               <span>Payment terms</span>
                   </td><td>
                   {datas.payment_terms}
                  </td>
                  <td id="td-right">
               <span>Price PMT in words</span>
                   </td><td>
                    {datas.price_PMT_in_words}
                  </td>
                </tr>



                <tr>
                <td id="td-right">
                  <span>Price Basis</span>
                   </td><td>
                    {datas.price_basis}
                  </td>
               <td id="td-right">
               <span>Price Type</span>
                   </td><td>
                    {datas.price_type}
                  </td>

                </tr>


                <tr>
                <td id="td-right">
                  <span>Price Basis GAR</span>
                   </td><td>
                    {datas.price_basis_GAR}
                  </td>
               <td id="td-right">
               <span>Price adjustment clause</span>
                   </td><td>
                    {datas.price_adjustment_clause}
                  </td>

                </tr>


                <tr>
                <td id="td-right">
                  <span>Rejections</span>
                   </td><td>
                    {datas.rejections}
                  </td>
               <td id="td-right">
               <span>Price adjustment clause</span>
                   </td><td>
                    {datas.description}
                  </td>

                </tr>

                <tr>
                <td id="td-right">
                  <span>Pricing Terms</span>
                   </td><td>
                    {datas.pricing_terms}
                  </td>
               <td id="td-right">

                   </td><td>

                  </td>

                </tr>
              </tbody>
            </table>
          </div>


          <div className="">
            <div className="row">
              <div className="col-md-12" id="head">
                <h4 className="heading">Other Standard Term From Supplier</h4>
                <hr></hr>
              </div>
            </div>
            <table class="table table-bordered account-table tables">
              <tbody>
                <tr>
                <td id="td-right">
                    <span>Payment Terms</span>
                   </td><td>
                    {datas.payment_terms}
                  </td>
                  <td id="td-right">
                    <span>Applicable Law</span>
                   </td><td>
                    {datas.applicable_law}
                  </td>
                  </tr>


                  <tr>
                  <td id="td-right">
                    <span>Arbitration</span>
                   </td><td>
                    {datas.arbitration}
                  </td>
                  <td id="td-right">
                    <span>General Terms</span>
                   </td><td>
                    {datas.general_terms}
                  </td>
                </tr>


               </tbody>


            </table>
          </div>
          <div className="">
            <div className="row">
              <div className="col-md-12" id="head">
                <h4 className="heading">Description Information</h4>
                <hr></hr>
              </div>
            </div>
            <table class="table table-bordered account-table tables">
              <tbody>
                <tr>
                <td id="td-right">
                    <span>Loading rate per day</span>
                   </td><td>
                    {datas.loading_rate_per_day}
                  </td>
                  <td id="td-right">
                    <span>Special Terms</span>
                   </td><td>
                    {datas.special_terms}
                  </td>
                  </tr>

                  <tr>
                <td id="td-right">
                    <span>Bonus penalty clause</span>
                   </td><td>
                    {datas.bonus_penalty_clause}
                  </td>
                  <td id="td-right">

                   </td><td>

                  </td>
                  </tr>

              </tbody>


            </table>
          </div>




          <div className="">
            <div className="row">
              <div className="col-md-12" id="head">
                <h4 className="heading">Other Standard Terms</h4>
                <hr></hr>
              </div>
            </div>
            <table class="table table-bordered account-table tables">
              <tbody>
                <tr>
                <td id="td-right">
                    <span>Billing Street</span>
                   </td><td>
                    {datas.billing_street}
                  </td>
                  <td id="td-right">
                    <span>Shipping Street</span>
                   </td><td>
                    {datas.shipping_street}
                  </td>
                  </tr>
                  <tr>
                  <td id="td-right">
                    <span>Billing Zip Postal Code</span>
                   </td><td>
                    {datas.billing_zip_postal_code}
                  </td>
                  <td id="td-right">
                    <span>Shipping zip postal code</span>
                   </td><td>
                    {datas.shipping_zip_postal_code}
                  </td>

                </tr>



                <tr>
                <td id="td-right">
                    <span>Billing City</span>
                   </td><td>
                    {datas.billing_city}
                  </td>
                  <td id="td-right">
                    <span>Shipping City</span>
                   </td><td>
                    {datas.shipping_city}
                  </td>

                  </tr>


                  <tr>
                <td id="td-right">
                    <span>Billing state or province</span>
                   </td><td>
                    {datas.billing_state_or_province}
                  </td>
                  <td id="td-right">
                    <span>Shipping state or province</span>
                   </td><td>
                    {datas.shipping_state_or_province}
                  </td>

                  </tr>



                  <tr>
                <td id="td-right">
                    <span>Billing Country</span>
                   </td><td>
                    {datas.billing_country}
                  </td>
                  <td id="td-right">
                    <span>Shipping Country</span>
                   </td><td>
                    {datas.shipping_scountry}
                  </td>

                  </tr>



              </tbody>


            </table>
          </div>



          <div className="">
            <div className="row">
              <div className="col-md-12" id="head">
                <h4 className="heading">List Of Fields-Not Required</h4>
                <hr></hr>
              </div>
            </div>
            <table class="table table-bordered account-table tables">
              <tbody>
                <tr>

                  <td id="td-right">
                    <span>Customer signed by</span>
                   </td><td>
                    {datas.customer_signed_by}
                  </td>
                  <td id="td-right">
                    <span>Customer signed title</span>
                   </td><td>
                    {datas.customer_signed_title}
                  </td>
                  </tr>
                  <tr>


                  <td id="td-right">
                    <span>Company signed by</span>
                   </td><td>
                    {datas.company_signed_by}
                  </td>

                 <td id="td-right">
                   <span>Original Contract Recieved Date</span>
                  </td><td>
                   {datas.original_contract_recieved_date}
                 </td>
                 </tr>


                <tr>

                 <td id="td-right">
                   <span>Company Signed Date</span>
                  </td><td>
                   {datas.company_signed_date}
                 </td>

                 <td id="td-right">
                   <span>Original contract sent date</span>
                  </td><td>
                   {datas.original_contract_sent_date}
                 </td>
                </tr>
              </tbody>


            </table>
          </div>
        </div>
      </div>


      <div
        className="tasks view-table"
        Style="box-showdow:0px 13px 20px #d6d1d1"
      >
          <Row id="table-styles">
                  <div className="col-md-12" id="head">
                    <h4 className="heading"><span>History Information</span></h4>
                    <hr></hr>
                  </div>
                  <table id="history" className="">
                    <thead>
                      <tr>
                        <th>Field Name</th>
                        <th>New Value</th>
                        <th>Old Value</th>
                        <th>Changed By</th>
                        <th>Change Time</th>
                      </tr>
                    </thead>

                    {history.length > 0 ? (
                      <tbody>
                        {history.map((y, i) => (
                          <tr>
                            <td>{y.field_name}</td>
                            <td>{y.new_value}</td>
                            <td>{y.old_value}</td>
                            <td>{y.changed_by}</td>
                            <td>{y.change_time}</td>
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td></td>
                          <td></td>
                          <td>No data available</td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </Row>
        <Row id='table-styles'>
          <div className="col-md-12" id='head'>
        <h4 className="heading"><span>Contract Shipment</span>
        <span><Popup trigger={<a className="button add-accountrecord">  New </a>} modal>

       <form onSubmit={handleSubmit} className="add-accounts">
        <div className='form-group'>
        <label>Contract Shipment Record Type</label><br/>
        <select name='account_record' className='form-control' onChange={handleChange} value={formData.account_record}>

          <option >--Select Record Type--</option>
          <option value='Buyer - Long Term'>Buyer Long Term</option>
          <option value='Buyer - Spot'>Buyer Spot</option>
          <option value='Supplier - Long Term'>Supplier - Long Term</option>
          <option value='Supplier - Spot'>Supplier - Spot</option>

          </select>
          </div>
          <input type='submit' className="btn btn-primary " value='Submit'/>

          </form>
   </Popup></span></h4>

        <hr></hr>
        </div>
        <div  id='inner-heading'>

     </div>
          <table id='shipment' className="">
       <thead>
       <tr>
        <th>Contract Shipment No</th>
       <th>Contract Shipment Status</th>
       <th>Currency</th>
         <th>Exchange Rate</th>
         <th>Order Quantity</th>
         <th>Parent Shipment</th>
         <th>Record Type</th>
       </tr>
       </thead>

       {conShipment.length> 0 ?

       <tbody>
       {
             conShipment.map((y,i)=>(
         <tr>
           <td><span id='codes'><Link to ={'/shipment/contract-shipment-details/'+y.id}>{y.contract}</Link></span></td>
            <td>{y.contract_shipment_status}</td>
            <td>{y.currency}</td>
            <td>{y.exchange_rate}</td>
            <td>{y.order_quantity}</td>
            <td>{y.parent_shipment}</td>
            <td>{y.record_type}</td>
         </tr>
          ))}
       </tbody>
       :<tbody>
       <tr>

         <td></td>
         <td></td>
         <td>No data available</td>
         <td></td>
         <td></td>
         <td></td>
         <td></td>
         </tr>
         </tbody> }

       </table>

          </Row>
          <Row id='table-styles'>
          <div className="col-md-12" id='head'>
        <h4 className="heading"><span>Opportunity Information </span>
        {/* <Popup trigger={<span className="button add-accountrecord">  New </span>} modal>
             <form onSubmit={handleSubmitOpp} className="add-accounts">
           <div className='form-group'>
           <label>Opportunity Record Type</label><br/>
           <select  className='form-control' name="type_of_new_record" onChange={handleChangeOpp} value={formDataopp.account_record}>
             <option>----Select----</option>
   <option value='Buyer' >Buyer</option>
         <option value='Buyer - Long Term' >Buyer - Long Term</option>
         <option value='Buyer - Spot' >Buyer - Spot</option>
         <option value='Supplier' >Supplier</option>
         <option value='Supplier - Long Term' >Supplier - Long Term</option>
         <option value='Supplier - Spot'>Supplier - Spot</option>

             </select>
             </div>
             <input type='submit' className="btn btn-primary " value='Submit'/>

             </form>
         </Popup> */}
         </h4>
        <hr></hr>
        </div>
          <table id='opportunity' className="">
       <thead>
       <tr>
         <th>Opportunity No</th>
         <th>Opportunity Name</th>
         <th>Stage</th>
         <th>Quantity (MT)	Supplier</th>
         <th>No of Order</th>

       </tr>
       </thead>

       {opportunity.length> 0  ?

         <tbody>
         {
             opportunity.map((y,i)=>(
         <tr>
           <td></td>
           <td></td>
           <td></td>
           <td></td>
           <td></td>
         </tr>
          ))}
       </tbody>
       :<tbody>
       <tr>
         <td></td>
         <td></td>
         <td>No data available</td>

         <td></td>
         <td></td>
         </tr>
         </tbody>
   }
       </table>

          </Row>

          <Row id='table-styles'>
          <div className="col-md-12" id='head'>
        <h4 className="heading"><span>TCF Information</span>
        <span><Popup trigger={<a className="button add-accountrecord">  New </a>} modal>

       <form onSubmit={handleSubmittcf} className="add-accounts">
        <div className='form-group'>
        <label>TCF Record Type</label><br/>
        <select name='account_record' className='form-control' onChange={handleChangetcf} value={formData.account_record}>
          <option >--Select TCF Record Type--</option>
          <option value='Buyer - Spot'>Buyer - Spot</option>
          <option value='Buyer - Long Term'>Buyer - Long Term</option>
          </select>
          </div>
          <input type='submit' className="btn btn-primary " value='Submit'/>

          </form>
   </Popup></span></h4>
        <hr></hr>
        </div>
          <table id='tcf' className="">
       <thead>
       <tr>
         <th>TCF No</th>
         <th>TCF Status</th>
         <th>Currency</th>
         <th>Exchange Rate</th>
         <th>Opportunity</th>
         <th>Record Type</th>
         <th>Trader Name</th>

       </tr>
       </thead>

       {tcf.length> 0  ?

         <tbody>
         {
             tcf.map((y,i)=>(
         <tr>
           <td><span id='codes'>{y.tcf_no}</span></td>
           <td>{y.tcf_status}</td>
           <td>{y.currency}</td>
           <td>{y.exchange_rate}</td>
           <td>{y.opportunity}</td>
           <td>{y.record_type}</td>
           <td>{y.trader_name}</td>
         </tr>
          ))}
       </tbody>
       :<tbody>
       <tr>
         <td></td>
         <td></td>
         <td></td>
         <td>No data available</td>
         <td></td>
         <td></td>
         <td></td>
         </tr>
         </tbody>
   }
       </table>

          </Row>
          <Row id='table-styles'>
          <div className="col-md-12" id='head'>
        <h4 className="heading"><span>Orders Information </span></h4>
        <hr></hr>
        </div>
          <table id='orders' className="">
       <thead>
       <tr>
         <th>Order No</th>
         <th>Account Name</th>
         <th>Barge Name</th>
         <th>Laycan From Date</th>
         <th>Laycan From Date</th>
         <th>Laycan Month</th>
         <th>Laycan Period</th>

       </tr>
       </thead>

       {orderData.length> 0  ?

         <tbody>
         {
               orderData.map((y,i)=>(
         <tr>
           <td></td>
           <td></td>
           <td></td>
           <td></td>
           <td></td>
           <td></td>
           <td></td>
         </tr>
          ))}
       </tbody>
       :<tbody>
       <tr>
         <td></td>
         <td></td>
         <td></td>
         <td>No data available</td>
         <td></td>
         <td></td>
         <td></td>
         </tr>
         </tbody>
   }
       </table>

          </Row>
          <Row id='table-styles'>
           <div className="col-md-12" id='head'>
         <h4 className="heading"><span>Documents Information</span><span>
         <Link to={"/contract/add-document/" + id}>
                        <i className="fe fe-download-cloud me-2"></i>
                         New
                      </Link> </span>
                      </h4>
         <hr></hr>
         </div>
           <table id='document' className="">
        <thead>
        <tr>
          <th>contract list of docs</th>
          <th>document required name</th>
          <th>list of docuemnts</th>
          <th>original in no</th>
          <th>suffix</th>

        </tr>
        </thead>

        {contractDocument.length> 0  ?

          <tbody>
          {
                contractDocument.map((y,i)=>(
          <tr>
            <td className=""><Link to={'/contract/document-view/'+y.id}>{y.contract_list_of_docs}</Link></td>
            <td>{y.document_required_name}</td>
            <td>{y.list_of_docuemnts}</td>
            <td>{y.original_in_no}</td>
            <td>{y.suffix}</td>

          </tr>
           ))}
        </tbody>
        :<tbody>
        <tr>
          <td></td>
          <td></td>

          <td>No data available</td>
          <td></td>

          </tr>
          </tbody>
    }
        </table>

           </Row>
      </div>
      </>
      )}
      </>
    ):(
      <>
      {datas.contract_record_type === "Supplier" ? (
         <>
         {isEditMode ? (
   <>
   <div className="tasks">
      <p className="edit-btn">
         <button className="btn btn-primary" onClick={handleSaveEdit}>
         Save
         </button>
         <button className="btn btn-primary" onClick={handleCancelEdit}>
         Cancel
         </button>
      </p>
      <Row className="mb-3">
         <h4 className="heading">Contract Information</h4>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingSelect"
               className="dropDown"
               label="Currency"
               >
               <Form.Select
               aria-label="Floating label select example"
               name="currency"
               placeholder="Currency"
               className="error-validation"
               value={datas.currency || ""}
               onChange={handleChangeInput}
               >
               <option value="">Select </option>
               <option value="USD">USD </option>
               <option value="IDR">IDR </option>
               </Form.Select>

            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6" id='lockeds'>
            <input type='checkbox' name='locked' onChange={(e) => setCheck1(e.target.checked) } /><label>Locked</label>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingInput"
               label="Exchange rate"
               className="mb-3"
               >
               <Form.Control
               type="text"
               placeholder="Exchange rate "
               name="exchange_rate"
               defaultValue={datas.exchange_rate || ""}
               onChange={handleChangeInput}
               />
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingSelect"
               className="dropDown"
               label="Status"
               >
               <Form.Select
               aria-label="Floating label select example"
               name="status"
               placeholder="Status"
               className="error-validation"
               value={datas.status || ""}
               onChange={handleChangeInput}
               >
               <option value="">Select </option>
               <option value="Draft">Draft </option>
               <option value="Approved">Approved </option>
               </Form.Select>
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingSelect"
               className="dropDown"
               label="Account Name"
               >
               <Form.Select
               aria-label="Floating label select example"
               name="account_name"
               placeholder="Account Name"
               className="error-validation"
               value={datas.account_name || ""}
               onChange={handleChangeInput}
               >
               <option value="">Select </option>
               {accountName.map((x) => {
               return (
               <option value={x.account_name}>{x.account_name}</option>
               );
               })}
               </Form.Select>
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingInput"
               label="Contract record type"
               className="mb-3"
               >
               <Form.Control
               type="text"
               placeholder="Contract record type"
               name="contract_record_type" className='record-type'
               defaultValue={datas.contract_record_type || ""}
               onChange={handleChangeInput}
               />
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingInput"
               label="Contract Owner"
               className="mb-3"
               >
               <Form.Control
               type="text"
               placeholder="Contract Owner"
               name="contract_owner"
               defaultValue={datas.contract_owner || ""}
               onChange={handleChangeInput}
               />
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingInput"
               label="Rwood contract no"
               className="mb-3"
               >
               <Form.Control
               type="text"
               placeholder="Rwood contract no"
               name="Rwood_Contract_No"
               defaultValue={datas.Rwood_Contract_No || ""}
               onChange={handleChangeInput}
               />
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingInput"
               label="Contract Start Date"
               className="mb-3 date-picker"
               >
               <DatePicker
                  selected={startDate}
                  onChange={(date) =>
               setStartDate(date)}
               customInput={
               <ExampleCustomInput />
               }
               dayClassName={() => "example-datepicker-day-class"}
               popperClassName="example-datepicker-class"
               todayButton="TODAY"
               dateFormat="yyyy-MM-dd"
               placeholderText="Choose Date"
               />
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingSelect"
               className="dropDown"
               label="Owner Expiration Notice"
               >
               <Form.Select
               aria-label="Floating label select example"
               name="owner_expiration_notice"
               placeholder="Owner Expiration Notice"
               value={datas.owner_expiration_notice || ""}
               onChange={handleChangeInput}
               >
               <option>---None---</option>
               <option value="30">30 Days</option>
               <option value="45">45 Days</option>
               <option value="60">60 Days</option>
               <option value="90">90 Days</option>
               <option value="120">120 Days</option>
               </Form.Select>
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingInput"
               label="Contract Effective Date"
               className="mb-3 date-picker"
               >
               <DatePicker
                  selected={start}
                  onChange={(date) =>
               setStart(date)}
               customInput={
               <ExampleCustomInput2 />
               }
               dayClassName={() => "example-datepicker-day-class"}
               popperClassName="example-datepicker-class"
               todayButton="TODAY"
               dateFormat="yyyy-MM-dd"
               placeholderText="Choose Date"
               />
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingInput"
               label="Contract term in months"
               className="mb-3"
               >
               <Form.Control
               type="text"
               placeholder="Contract term in months"
               name="contract_term_in_months"
               defaultValue={datas.contract_term_in_months || ""}
               onChange={handleChangeInput}
               />
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingSelect"
               className="dropDown"
               label="Contract Status"
               >
               <Form.Select
               aria-label="Floating label select example"
               name="contract_status"
               placeholder="Contract Status"
               value={datas.contract_status || ""}
               onChange={handleChangeInput}
               >
               <option value="">--None--</option>
               <option value="In Approval Process">In Approval Process</option>
               <option value="Activated">Activated</option>
               <option value="Draft">Draft</option>
               <option value="Under Review">Under Review</option>
               <option value="Draft Sent for Signing">Draft Sent for Signing</option>
               <option value="Draft Received for Signing">Draft Received for Signing</option>
               <option value="Signed Contract yet to Receive">Signed Contract yet to Receive</option>
               <option value="Signed Contract Sent for Sign">Signed Contract Sent for Sign</option>
               <option value="Signed Original Contract Need to Courier">Signed Original Contract Need to Courier</option>
               <option value="Signed Original Contract yet to Receive">Signed Original Contract yet to Receive</option>
               <option value="Original Contract Signed by Both Parties">Original Contract Signed by Both Parties</option>
               <option value="Original Contract Signed and Received by Both Parties">Original Contract Signed and Received by Both Parties</option>
               <option value="Closed">Closed</option>
               <option value="Partially completed">Partially completed</option>
               <option value="Completed">Completed</option>
               <option value="Cancelled">Cancelled</option>
               <option value="Partially cancelled">Partially cancelled</option>
               <option value="Shipments - Arutmin">Shipments - Arutmin</option>
               <option value="Partially Signed Contract">Partially Signed Contract</option>
               </Form.Select>
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingTextarea2"
               label="Pending Matters"
               >
               <Form.Control
               as="textarea"
               placeholder="Pending Matters"
               style={{ height: "100px!important" }}
               name="pending_matters"
               defaultValue={datas.pending_matters || ""}
               onChange={handleChangeInput}
               />
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingSelect"
               className="dropDown"
               label="Trader Name"
               >
               <Form.Select
               aria-label="Floating label select example"
               name="trader_name"
               placeholder="Trader Name"
               value={datas.trader_name || ""}
               onChange={handleChangeInput}
               >
               <option value="">Select </option>
               {traderData.map((x, i) => {
               return (
               <option key={i} value={x.rwood_username}>
                  {x.rwood_username}
               </option>
               );
               })}
               </Form.Select>
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingInput"
               label="Master Contract Reference"
               className="mb-3"
               >
               <Form.Control
               type="text"
               placeholder="Master Contract Reference"
               name="master_conference_reference"
               defaultValue={datas.master_conference_reference || ""}
               onChange={handleChangeInput}
               />
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingSelect"
               className="dropDown"
               label="Company"
               >
               <Form.Select
               aria-label="Floating label select example"
               name="company"
               placeholder="Company"
               className="error-validation"
               value={datas.company || ""}
               onChange={handleChangeInput}
               >
               <option value="">Select </option>
               {companyData.map((x, i) => {
               return (
               <option key={i} value={x.company_name}>
                  {x.company_name}
               </option>
               );
               })}
               </Form.Select>
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingInput"
               label="Renewal date"
               className="mb-3 date-picker"
               >
               <DatePicker
                  selected={startDate}
                  onChange={(date) =>
               setStartDate(date)}
               customInput={
               <ExampleCustomInput />
               }
               dayClassName={() => "example-datepicker-day-class"}
               popperClassName="example-datepicker-class"
               todayButton="TODAY"
               dateFormat="yyyy-MM-dd"
               placeholderText="Choose Date"
               />
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingInput"
               label="Renewal contract number"
               className="mb-3"
               >
               <Form.Control
               type="number"
               placeholder="Renewal contract number "
               name="renewal_contract_number "
               defaultValue={datas.renewal_contract_number || ""}
               onChange={handleChangeInput}
               />
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel controlId="floatingTextarea2" label="Remark">
               <Form.Control
               as="textarea"
               placeholder="Remark"
               style={{ height: "100px!important" }}
               name="remark"
               defaultValue={datas.remark || ""}
               onChange={handleChangeInput}
               />
            </FloatingLabel>
         </Form.Group>
      </Row>
      <Row className="mb-3">
         <h4 className="heading">Commodity and Contracted Quantity</h4>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingInput"
               label="Port Of Loading"
               className="mb-3"
               >
               <Form.Control
               type="text"
               placeholder="Port Of Loading"
               name="port_of_loading"
               defaultValue={datas.port_of_loading || ""}
               onChange={handleChangeInput}
               />
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingInput"
               label="Origin"
               className="mb-3"
               >
               <Form.Control
               type="text"
               placeholder="Origin"
               name="origin"
               defaultValue={datas.origin || ""}
               onChange={handleChangeInput}
               />
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingInput"
               label="Contract quantity in MT"
               className="mb-3"
               >
               <Form.Control
               type="number"
               placeholder="Contract quantity in MT"
               name="contract_quantity_in_MT"
               className="error-validation"
               defaultValue={datas.contract_quantity_in_MT || ""}
               onChange={handleChangeInput}
               />
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel controlId="floatingSelect"
               className='dropDown' label="Commodity">
               <Form.Select aria-label="Floating label select example"
               name="commodity"
               placeholder="Commodity"
               value={datas.commodity || ""}
               onChange={handleChangeInput}
               >
               <option>---None---</option>
               <option value="Indonesian Steam Coal in Bulk (&quot;Coal&quot;)">Indonesian Steam Coal in Bulk ("Coal")</option>
               <option value="US Coal">US Coal</option>
               <option value="Russia Coal">Russia Coal</option>
               <option value="SA Coal">SA Coal</option>
               <option value="Clinker in Bulk">Clinker in Bulk</option>
               <option value="Australian Steam Coal">Australian Steam Coal</option>
               </Form.Select>
            </FloatingLabel>
         </Form.Group>
      </Row>
      <Row className="mb-3">
         <h4 className="heading">Price and Price Adjustment</h4>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingInput"
               label="Price PMT"
               className="mb-3"
               >
               <Form.Control
               type="number"
               placeholder="Price PMT"
               name="price_PMT"
               defaultValue={datas.price_PMT || ""}
               onChange={handleChangeInput}
               />
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel controlId="floatingSelect"
               className='dropDown' label="Price book">
               <Form.Select aria-label="Floating label select example"
               name="price_book"
               placeholder="Price book"
               value={datas.price_book || ""}
               onChange={handleChangeInput}
               >
               <option value='' >Select</option>
               {
               priceBook.map((x)=>{
               return(
               <option value={x.price_book_name}>{x.price_book_name}</option>
               )
               })
               }
               </Form.Select>
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingInput"
               label="Price PMT in words"
               className="mb-3"
               >
               <Form.Control
               type="text"
               placeholder="Price PMT in words"
               name="price_PMT_in_words"
               defaultValue={datas.price_PMT_in_words || ""}
               onChange={handleChangeInput}
               />
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingSelect"
               className="dropDown"
               label="Price Basis"
               >
               <Form.Select
               aria-label="Floating label select example"
               name="price_basis"
               placeholder="Price Basis"
               value={datas.price_basis || ""}
               onChange={handleChangeInput}
               >
               <option value="">--None--</option>
               <option value="In Approval Process">In Approval Process</option>
               <option value="Activated">Activated</option>
               <option value="Draft">Draft</option>
               <option value="Under Review">Under Review</option>
               <option value="Draft Sent for Signing">Draft Sent for Signing</option>
               <option value="Draft Received for Signing">Draft Received for Signing</option>
               <option value="Signed Contract yet to Receive">Signed Contract yet to Receive</option>
               <option value="Signed Contract Sent for Sign">Signed Contract Sent for Sign</option>
               <option value="Signed Original Contract Need to Courier">Signed Original Contract Need to Courier</option>
               <option value="Signed Original Contract yet to Receive">Signed Original Contract yet to Receive</option>
               <option value="Original Contract Signed by Both Parties">Original Contract Signed by Both Parties</option>
               <option value="Original Contract Signed and Received by Both Parties">Original Contract Signed and Received by Both Parties</option>
               <option value="Closed">Closed</option>
               <option value="Partially completed">Partially completed</option>
               <option value="Completed">Completed</option>
               <option value="Cancelled">Cancelled</option>
               <option value="Partially cancelled">Partially cancelled</option>
               <option value="Shipments - Arutmin">Shipments - Arutmin</option>
               <option value="Partially Signed Contract">Partially Signed Contract</option>
               </Form.Select>
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingSelect"
               className="dropDown"
               label="Price type"
               >
               <Form.Select
               aria-label="Floating label select example"
               name="price_type"
               placeholder="Price type"
               value={datas.price_type || ""}
               onChange={handleChangeInput}
               >
               <option value="">Select</option>
               {getPrice.map((x) => {
               return (
               <option value={x.price_type_name}>
                  {x.price_type_name}
               </option>
               );
               })}
               </Form.Select>
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingInput"
               label="Price basis GAR"
               className="mb-3"
               >
               <Form.Control
               type="number"
               placeholder="Price basis GAR"
               name="price_basis_GAR"
               defaultValue={datas.price_basis_GAR || ""}
               onChange={handleChangeInput}
               />
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingInput"
               label="Price adjustment clause"
               className="mb-3"
               >
               <Form.Control
               type="text"
               placeholder="Price adjustment clause"
               name="price_adjustment_clause"
               defaultValue={datas.price_adjustment_clause || ""}
               onChange={handleChangeInput}
               />
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingInput"
               label="Rejections"
               className="mb-3"
               >
               <Form.Control
               as="textarea"
               placeholder="Rejections"
               name="rejections"
               defaultValue={datas.rejections || ""}
               onChange={handleChangeInput}
               />
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingTextarea2"
               label="Description"
               >
               <Form.Control
               as="textarea"
               placeholder="Description"
               style={{ height: "100px" }}
               name="description"
               defaultValue={datas.description || ""}
               onChange={handleChangeInput}
               />
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingTextarea2"
               label="Pricing Terms"
               >
               <Form.Control
               as="textarea"
               placeholder="Pricing Terms"
               style={{ height: "100px!important" }}
               name="pricing_terms"
               defaultValue={datas.pricing_terms || ""}
               onChange={handleChangeInput}
               />
            </FloatingLabel>
         </Form.Group>
      </Row>
      <Row className="mb-3">
         <h4 className="heading">Other Standard Term From Supplier</h4>
         <Form.Group as={Col} md="12">
            <FloatingLabel
               controlId="floatingTextarea2"
               label="Payment Terms"
               >
               <Form.Control
               as="textarea"
               placeholder="Payment Terms"
               style={{ height: "100px!important" }}
               name="payment_terms"
               defaultValue={datas.payment_terms || ""}
               onChange={handleChangeInput}
               />
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingSelect"
               className="dropDown"
               label="Applicable Law"
               >
               <Form.Select
               aria-label="Floating label select example"
               name="applicable_law"
               placeholder="Applicable Law"
               value={datas.applicable_law || ""}
               onChange={handleChangeInput}
               >
               <option value="">--None--</option>
               <option value="English Law">English Law</option>
               <option value="Singapore Law">Singapore Law</option>
               <option value="Indonesian Law">Indonesian Law</option>
               </Form.Select>
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingSelect"
               className="dropDown"
               label="Arbitration"
               >
               <Form.Select
               aria-label="Floating label select example"
               name="arbitration"
               placeholder="Arbitration"
               value={datas.arbitration || ""}
               onChange={handleChangeInput}
               >
               <option value="">--None--</option>
               <option value="In Singapore as per SIAC">In Singapore as per SIAC</option>
               <option value="Singapore Chamber of Maritime Arbitration (SCMA)">Singapore Chamber of Maritime Arbitration (SCMA)</option>
               <option value="Laws of England and Wales">Laws of England and Wales</option>
               <option value="Rules of Badan Arbitrase Nasional Indonesia (BANI)">Rules of Badan Arbitrase Nasional Indonesia (BANI)</option>
               </Form.Select>
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingInput"
               label="General terms"
               className="mb-3"
               >
               <Form.Control
               as="textarea"
               placeholder="General terms"
               name="general_terms"
               defaultValue={datas.general_terms || ""}
               onChange={handleChangeInput}
               />
            </FloatingLabel>
         </Form.Group>
      </Row>
      <Row className="mb-3">
         <h4 className="heading">Description Information</h4>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingInput"
               label="Loading rate per day"
               className="mb-3"
               >
               <Form.Control
               type="text"
               placeholder="Loading rate per day"
               name="loading_rate_per_day"
               defaultValue={datas.loading_rate_per_day || ""}
               onChange={handleChangeInput}
               />
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingInput"
               label="Special Terms"
               className="mb-3"
               >
               <Form.Control
               as="textarea"
               placeholder="Special Terms"
               name="special_terms"
               defaultValue={datas.special_terms || ""}
               onChange={handleChangeInput}
               />
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingInput"
               label="Bonus penalty clause"
               className="mb-3"
               >
               <Form.Control
               as="textarea"
               placeholder="Bonus penalty clause"
               name="bonus_penalty_clause" defaultValue={datas.bonus_penalty_clause || ""}
               onChange={handleChangeInput}
               />
            </FloatingLabel>
         </Form.Group>
      </Row>
      <Row className="mb-3">
         <h4 className="heading">Description Information</h4>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingInput"
               label="Billing street"
               className="mb-3"
               >
               <Form.Control
               type="text"
               placeholder="Billing street"
               name="billing_street"
               defaultValue={datas.billing_street || ""}
               onChange={handleChangeInput}
               />
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingInput"
               label="Shipping street"
               className="mb-3"
               >
               <Form.Control
               type="text"
               placeholder="Shipping street"
               name="shipping_street"
               defaultValue={datas.shipping_street || ""}
               onChange={handleChangeInput}
               />
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingInput"
               label="Billing zip postal code"
               className="mb-3"
               >
               <Form.Control
               type="text"
               placeholder="Billing zip postal code"
               name="billing_zip_postal_code"
               defaultValue={datas.billing_zip_postal_code || ""}
               onChange={handleChangeInput}
               />
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingInput"
               label="Shipping zip postal code"
               className="mb-3"
               >
               <Form.Control
               type="text"
               placeholder="Shipping zip postal code"
               name="shipping_zip_postal_code"
               defaultValue={datas.shipping_zip_postal_code || ""}
               onChange={handleChangeInput}
               />
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingInput"
               label="Billing city"
               className="mb-3"
               >
               <Form.Control
               type="text"
               placeholder="Billing city"
               name="billing_city"
               defaultValue={datas.billing_city || ""}
               onChange={handleChangeInput}
               />
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingInput"
               label="Shipping city"
               className="mb-3"
               >
               <Form.Control
               type="text"
               placeholder="Shipping city"
               name="shipping_city"
               defaultValue={datas.shipping_city || ""}
               onChange={handleChangeInput}
               />
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingInput"
               label="Billing state or province"
               className="mb-3"
               >
               <Form.Control
               type="text"
               placeholder="Billing state or province"
               name="billing_state_or_province"
               defaultValue={datas.billing_state_or_province || ""}
               onChange={handleChangeInput}
               />
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingInput"
               label="Shipping state or province"
               className="mb-3"
               >
               <Form.Control
               type="text"
               placeholder="Shipping state or province"
               name="shipping_state_or_province"
               defaultValue={datas.shipping_state_or_province || ""}
               onChange={handleChangeInput}
               />
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingInput"
               label="Billing country"
               className="mb-3"
               >
               <Form.Control
               type="text"
               placeholder="Billing country"
               name="billing_country"
               defaultValue={datas.billing_country || ""}
               onChange={handleChangeInput}
               />
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingInput"
               label="Shipping country"
               className="mb-3"
               >
               <Form.Control
               type="text"
               placeholder="Shipping country"
               name="shipping_country"
               defaultValue={datas.shipping_country || ""}
               onChange={handleChangeInput}
               />
            </FloatingLabel>
         </Form.Group>
      </Row>
      <Row className="mb-3">
         <h4 className="heading">Signature Information</h4>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingSelect"
               className="dropDown"
               label="Customer signed by"
               >
               <Form.Select
               aria-label="Floating label select example"
               name="customer_signed_by"
               placeholder="Customer signed by"
               value={datas.customer_signed_by || ""}
               onChange={handleChangeInput}
               >
               <option value="">Select </option>
               {contacts.map((x) => {
               return (
               <option value={x.contact_full_name}>
                  {x.contact_full_name}
               </option>
               );
               })}
               </Form.Select>
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingInput"
               label="Customer signed title"
               className="mb-3"
               >
               <Form.Control
               type="text"
               placeholder="Customer signed title"
               name="customer_signed_title"
               defaultValue={datas.customer_signed_title || ""}
               onChange={handleChangeInput}
               />
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingSelect"
               className="dropDown"
               label="Company signed by"
               >
               <Form.Select
               aria-label="Floating label select example"
               name="company_signed_by"
               placeholder="Company signed by"
               value={datas.company_signed_by || ""}
               onChange={handleChangeInput}
               >
               <option value="">Select </option>
               {users.map((x) => {
               return
               <option value={x.username}>{x.username}</option>
               ;
               })}
               </Form.Select>
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingInput"
               label="Original Contract Recieved Date"
               className="mb-3 date-picker"
               >
               <DatePicker
                  selected={received}
                  onChange={(date) =>
               setReceived(date)}
               customInput={
               <ExampleCustomInput5 />
               }
               dayClassName={() => "example-datepicker-day-class"}
               popperClassName="example-datepicker-class"
               todayButton="TODAY"
               dateFormat="yyyy-MM-dd"
               placeholderText="Choose Date"
               />
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingInput"
               label="Company Signed Date"
               className="mb-3 date-picker"
               >
               <DatePicker
                  selected={signed}
                  onChange={(date) =>
               setSigned(date)}
               customInput={
               <ExampleCustomInput4 />
               }
               dayClassName={() => "example-datepicker-day-class"}
               popperClassName="example-datepicker-class"
               todayButton="TODAY"
               dateFormat="yyyy-MM-dd"
               placeholderText="Choose Date"
               />
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingInput"
               label="Customer Signed Date"
               className="mb-3 date-picker"
               >
               <DatePicker
                  selected={customer}
                  onChange={(date) =>
               setCustomer(date)}
               customInput={
               <ExampleCustomInput6 />
               }
               dayClassName={() => "example-datepicker-day-class"}
               popperClassName="example-datepicker-class"
               todayButton="TODAY"
               dateFormat="yyyy-MM-dd"
               placeholderText="Choose Date"
               />
            </FloatingLabel>
         </Form.Group>
         <Form.Group as={Col} md="6">
            <FloatingLabel
               controlId="floatingInput"
               label="Original contract sent date"
               className="mb-3 date-picker"
               >
               <DatePicker
                  selected={sent}
                  onChange={(date) =>
               setSent(date)}
               customInput={
               <ExampleCustomInput7 />
               }
               dayClassName={() => "example-datepicker-day-class"}
               popperClassName="example-datepicker-class"
               todayButton="TODAY"
               dateFormat="yyyy-MM-dd"
               placeholderText="Choose Date"
               />
            </FloatingLabel>
         </Form.Group>
      </Row>
      <p className="edit-btn">
         <button className="btn btn-primary" onClick={handleSaveEdit}>
         Save
         </button>
         <button className="btn btn-primary" onClick={handleCancelEdit}>
         Cancel
         </button>
      </p>
   </div>
   </>
   ):(
         <>
         <div className="contactAccount">
           <div className="tasks">
           <div className="edit-delete">
                  <span id="edit" onClick={handleEditClick}>
                    Edit
                  </span>
                  <span>
                    Delete
                  </span>
                  <span>
                    Activate
                  </span>
                  <span>
                    Generate Contract
                  </span>
<span>
                     Generate Contract Clauses
                  </span>
<span>
                    Shipment Checklist
                  </span>
                  </div>
             <div className="">
               <div className="row">
                 <div className="col-md-12" id="head">
                   <h4 className="heading">Information</h4>

                   <hr></hr>
                 </div>
               </div>
               <table class="table table-bordered account-table tables">
                 <tbody>
                   <tr>
                     <td id="td-right"><span>Locked</span> </td>
                     <td>{datas.locked}</td>


                   </tr>
                   <tr>
                   <td id="td-right">
                       <span>Currency</span>
                     </td>
                     <td>
                       {datas.currency}
                     </td>
                     <td id="td-right">
                       <span>
                         Trader Name

                       </span>
                       </td><td>
                       {datas.trader_name}
                     </td>
                   </tr>
                   <tr>
                   <td id="td-right">
                       <span>Contract record type</span>
                       </td>
                       <td>
                        {datas.contract_record_type}
                     </td>
                     <td id="td-right">
                       <span>Account Name</span>
                       </td>
                       <td>
                        {datas.account_name}
                     </td>
                   </tr>
                   <tr>

                   <td id="td-right">
                       <span>Exchange Rate</span>
                       </td>
                       <td>

                       {datas.exchange_rate}
                     </td>
                     <td id="td-right">
                       <span>Contract Owner</span>
                       </td>
                       <td>
                       {datas.contract_owner}
                     </td>

                   </tr>
                   <tr>

                     <td id="td-right">
                         <span>Price book </span>
                         </td>
                         <td>

                         {datas.price_book}
                       </td>
                       <td id="td-right">
                         <span>Rwood contract no</span>
                         </td>
                         <td>
                         {datas.Rwood_Contract_No}
                       </td>

                     </tr>
                     <tr>

                     <td id="td-right">
                         <span>Status</span>
                         </td>
                         <td>

                         {datas.status}
                       </td>
                       <td id="td-right">
                         <span>Contract Status</span>
                         </td>
                         <td>
                         {datas.contract_status}
                       </td>

                     </tr>
                     <tr>

                     <td id="td-right">
                         <span>Contract term in months</span>
                         </td>
                         <td>

                         {datas.contract_term_in_months}
                       </td>
                       <td id="td-right">
                         <span>Pending Matters</span>
                         </td>
                         <td>
                         {datas.pending_matters}
                       </td>

                     </tr>
                     <tr>

                     <td id="td-right">
                         <span>Remark</span>
                         </td>
                         <td>

                         {datas.remark}
                       </td>
                       <td id="td-right">
                         <span>Contract Start Date</span>
                         </td>
                         <td>
                         {datas.contract_start_date}
                       </td>

                     </tr>

                     <tr>

                     <td id="td-right">
                         <span>Contract Effective Date</span>
                         </td>
                         <td>

                         {datas.contract_effective_date}
                       </td>
                       <td id="td-right">
                         <span>Master Contract Reference</span>
                         </td>
                         <td>
                         {datas.master_conference_reference}
                       </td>

                     </tr>

                     <tr>

                     <td id="td-right">
                         <span>Renewal Date</span>
                         </td>
                         <td>

                         {datas.renewal_date}
                       </td>
                       <td id="td-right">
                         <span>Renewal contract number</span>
                         </td>
                         <td>
                         {datas.renewal_contract_number}
                       </td>

                     </tr>
                 </tbody>
               </table>
             </div>
             <div className="">
               <div className="row">
                 <div className="col-md-12" id="head">
                   <h4 className="heading">Commodity and Contracted Quantity</h4>
                   <hr></hr>
                 </div>
               </div>
               <table class="table table-bordered account-table tables">
                 <tbody>

                   <tr>
                   <td id="td-right">
                       <span>Port Of Loading</span>
                       </td>
                       <td>
                       {datas.port_of_loading}
                     </td>
                       <td id="td-right">
                        <span>Origin</span>
                       </td>
                       <td>
                       {datas.origin}
                     </td>

                       </tr>

                   <tr>


                     <td id="td-right">
                       <span>Contract Quantity in MT</span>
                       </td>
                       <td>
                       {datas.contract_quantity_in_MT}
                     </td>
                     <td id="td-right">

                       <span>Commodity</span>
                       </td>
                       <td>
                       {datas.commodity}
                     </td>

                     </tr>

                 </tbody>
               </table>
             </div>

             <div className="">
               <div className="row">
                 <div className="col-md-12" id="head">
                   <h4 className="heading">Price and Price Adjustment</h4>
                   <hr></hr>
                 </div>
               </div>
               <table class="table table-bordered account-table tables">
                 <tbody>
                  <tr>
                  <td id="td-right">
                       <span>Price PMT</span>
                      </td><td>
                      {datas.price_PMT}
                     </td>
                     <td id="td-right">
                     <span>Price Book</span>
                      </td><td>
                      {datas.price_book}
                     </td>
                   </tr>

                   <tr>
                  <td id="td-right">
                  <span>Payment terms</span>
                      </td><td>
                      {datas.payment_terms}
                     </td>
                     <td id="td-right">
                  <span>Price PMT in words</span>
                      </td><td>
                       {datas.price_PMT_in_words}
                     </td>
                   </tr>



                   <tr>
                   <td id="td-right">
                     <span>Price Basis</span>
                      </td><td>
                       {datas.price_basis}
                     </td>
                  <td id="td-right">
                  <span>Price Type</span>
                      </td><td>
                       {datas.price_type}
                     </td>

                   </tr>


                   <tr>
                   <td id="td-right">
                     <span>Price Basis GAR</span>
                      </td><td>
                       {datas.price_basis_GAR}
                     </td>
                  <td id="td-right">
                  <span>Price adjustment clause</span>
                      </td><td>
                       {datas.price_adjustment_clause}
                     </td>

                   </tr>


                   <tr>
                   <td id="td-right">
                     <span>Rejections</span>
                      </td><td>
                       {datas.rejections}
                     </td>
                  <td id="td-right">
                  <span>Price adjustment clause</span>
                      </td><td>
                       {datas.description}
                     </td>

                   </tr>

                   <tr>
                   <td id="td-right">
                     <span>Pricing Terms</span>
                      </td><td>
                       {datas.pricing_terms}
                     </td>
                  <td id="td-right">

                      </td><td>

                     </td>

                   </tr>
                 </tbody>
               </table>
             </div>


             <div className="">
               <div className="row">
                 <div className="col-md-12" id="head">
                   <h4 className="heading">Other Standard Term From Supplier</h4>
                   <hr></hr>
                 </div>
               </div>
               <table class="table table-bordered account-table tables">
                 <tbody>
                   <tr>
                   <td id="td-right">
                       <span>Payment Terms</span>
                      </td><td>
                       {datas.payment_terms}
                     </td>
                     <td id="td-right">
                       <span>Applicable Law</span>
                      </td><td>
                       {datas.applicable_law}
                     </td>
                     </tr>


                     <tr>
                     <td id="td-right">
                       <span>Arbitration</span>
                      </td><td>
                       {datas.arbitration}
                     </td>
                     <td id="td-right">
                       <span>General Terms</span>
                      </td><td>
                       {datas.general_terms}
                     </td>
                   </tr>


                  </tbody>


               </table>
             </div>
             <div className="">
               <div className="row">
                 <div className="col-md-12" id="head">
                   <h4 className="heading">Description Information</h4>
                   <hr></hr>
                 </div>
               </div>
               <table class="table table-bordered account-table tables">
                 <tbody>
                   <tr>
                   <td id="td-right">
                       <span>Loading rate per day</span>
                      </td><td>
                       {datas.loading_rate_per_day}
                     </td>
                     <td id="td-right">
                       <span>Special Terms</span>
                      </td><td>
                       {datas.special_terms}
                     </td>
                     </tr>

                     <tr>
                   <td id="td-right">
                       <span>Bonus penalty clause</span>
                      </td><td>
                       {datas.bonus_penalty_clause}
                     </td>
                     <td id="td-right">

                      </td><td>

                     </td>
                     </tr>

                 </tbody>


               </table>
             </div>




             <div className="">
               <div className="row">
                 <div className="col-md-12" id="head">
                   <h4 className="heading">Other Standard Terms</h4>
                   <hr></hr>
                 </div>
               </div>
               <table class="table table-bordered account-table tables">
                 <tbody>
                   <tr>
                   <td id="td-right">
                       <span>Billing Street</span>
                      </td><td>
                       {datas.billing_street}
                     </td>
                     <td id="td-right">
                       <span>Shipping Street</span>
                      </td><td>
                       {datas.shipping_street}
                     </td>
                     </tr>
                     <tr>
                     <td id="td-right">
                       <span>Billing Zip Postal Code</span>
                      </td><td>
                       {datas.billing_zip_postal_code}
                     </td>
                     <td id="td-right">
                       <span>Shipping zip postal code</span>
                      </td><td>
                       {datas.shipping_zip_postal_code}
                     </td>

                   </tr>



                   <tr>
                   <td id="td-right">
                       <span>Billing City</span>
                      </td><td>
                       {datas.billing_city}
                     </td>
                     <td id="td-right">
                       <span>Shipping City</span>
                      </td><td>
                       {datas.shipping_city}
                     </td>

                     </tr>


                     <tr>
                   <td id="td-right">
                       <span>Billing state or province</span>
                      </td><td>
                       {datas.billing_state_or_province}
                     </td>
                     <td id="td-right">
                       <span>Shipping state or province</span>
                      </td><td>
                       {datas.shipping_state_or_province}
                     </td>

                     </tr>



                     <tr>
                   <td id="td-right">
                       <span>Billing Country</span>
                      </td><td>
                       {datas.billing_country}
                     </td>
                     <td id="td-right">
                       <span>Shipping Country</span>
                      </td><td>
                       {datas.shipping_scountry}
                     </td>

                     </tr>



                 </tbody>


               </table>
             </div>



             <div className="">
               <div className="row">
                 <div className="col-md-12" id="head">
                   <h4 className="heading">List Of Fields-Not Required</h4>
                   <hr></hr>
                 </div>
               </div>
               <table class="table table-bordered account-table tables">
                 <tbody>
                   <tr>

                     <td id="td-right">
                       <span>Customer signed by</span>
                      </td><td>
                       {datas.customer_signed_by}
                     </td>
                     <td id="td-right">
                       <span>Customer signed title</span>
                      </td><td>
                       {datas.customer_signed_title}
                     </td>
                     </tr>
                     <tr>


                     <td id="td-right">
                       <span>Company signed by</span>
                      </td><td>
                       {datas.company_signed_by}
                     </td>

                    <td id="td-right">
                      <span>Original Contract Recieved Date</span>
                     </td><td>
                      {datas.original_contract_recieved_date}
                    </td>
                    </tr>


                   <tr>

                    <td id="td-right">
                      <span>Company Signed Date</span>
                     </td><td>
                      {datas.company_signed_date}
                    </td>

                    <td id="td-right">
                      <span>Original contract sent date</span>
                     </td><td>
                      {datas.original_contract_sent_date}
                    </td>
                   </tr>
                 </tbody>


               </table>
             </div>
           </div>
         </div>


         <div
           className="tasks view-table"
           Style="box-showdow:0px 13px 20px #d6d1d1"
         >
            <Row id="table-styles">
                  <div className="col-md-12" id="head">
                    <h4 className="heading"><span>History Information</span></h4>
                    <hr></hr>
                  </div>
                  <table id="history" className="">
                    <thead>
                      <tr>
                        <th>Field Name</th>
                        <th>New Value</th>
                        <th>Old Value</th>
                        <th>Changed By</th>
                        <th>Change Time</th>
                      </tr>
                    </thead>

                    {history.length > 0 ? (
                      <tbody>
                        {history.map((y, i) => (
                          <tr>
                            <td>{y.field_name}</td>
                            <td>{y.new_value}</td>
                            <td>{y.old_value}</td>
                            <td>{y.changed_by}</td>
                            <td>{y.change_time}</td>
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td></td>
                          <td></td>
                          <td>No data available</td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </Row>

           <Row id='table-styles'>
             <div className="col-md-12" id='head'>
           <h4 className="heading"><span>Contract Shipment</span>
           <span><Popup trigger={<a className="button add-accountrecord">  New </a>} modal>

          <form onSubmit={handleSubmit} className="add-accounts">
           <div className='form-group'>
           <label>Contract Shipment Record Type</label><br/>
           <select name='account_record' className='form-control' onChange={handleChange} value={formData.account_record}>

             <option >--Select Record Type--</option>
             <option value='Buyer - Long Term'>Buyer Long Term</option>
             <option value='Buyer - Spot'>Buyer Spot</option>
             <option value='Supplier - Long Term'>Supplier - Long Term</option>
             <option value='Supplier - Spot'>Supplier - Spot</option>

             </select>
             </div>
             <input type='submit' className="btn btn-primary " value='Submit'/>

             </form>
      </Popup></span></h4>

           <hr></hr>
           </div>
           <div  id='inner-heading'>

        </div>
             <table id='shipment' className="">
          <thead>
          <tr>
           <th>Contract Shipment No</th>
          <th>Contract Shipment Status</th>
          <th>Currency</th>
            <th>Exchange Rate</th>
            <th>Order Quantity</th>
            <th>Parent Shipment</th>
            <th>Record Type</th>
          </tr>
          </thead>

          {conShipment.length> 0 ?

          <tbody>
          {
                conShipment.map((y,i)=>(
            <tr>
              <td><span id='codes'><Link to ={'/shipment/contract-shipment-details/'+y.id}>{y.contract}</Link></span></td>
            <td>{y.contract_shipment_status}</td>
            <td>{y.currency}</td>
            <td>{y.exchange_rate}</td>
            <td>{y.order_quantity}</td>
            <td>{y.parent_shipment}</td>
            <td>{y.record_type}</td>
            </tr>
             ))}
          </tbody>
          :<tbody>
          <tr>

            <td></td>
            <td></td>
            <td>No data available</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            </tr>
            </tbody> }

          </table>

             </Row>
             <Row id='table-styles'>
             <div className="col-md-12" id='head'>
           <h4 className="heading"><span>Opportunity Information </span>
           {/* <Popup trigger={<span className="button add-accountrecord">  New </span>} modal>
                <form onSubmit={handleSubmitOpp} className="add-accounts">
              <div className='form-group'>
              <label>Opportunity Record Type</label><br/>
              <select  className='form-control' name="type_of_new_record" onChange={handleChangeOpp} value={formDataopp.account_record}>
                <option>----Select----</option>
      <option value='Buyer' >Buyer</option>
            <option value='Buyer - Long Term' >Buyer - Long Term</option>
            <option value='Buyer - Spot' >Buyer - Spot</option>
            <option value='Supplier' >Supplier</option>
            <option value='Supplier - Long Term' >Supplier - Long Term</option>
            <option value='Supplier - Spot'>Supplier - Spot</option>

                </select>
                </div>
                <input type='submit' className="btn btn-primary " value='Submit'/>

                </form>
            </Popup> */}
            </h4>
           <hr></hr>
           </div>
             <table id='opportunity' className="">
          <thead>
          <tr>
            <th>Opportunity No</th>
            <th>Opportunity Name</th>
            <th>Stage</th>
            <th>Quantity (MT)	Supplier</th>
            <th>No of Order</th>

          </tr>
          </thead>

          {opportunity.length> 0  ?

            <tbody>
            {
                opportunity.map((y,i)=>(
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
             ))}
          </tbody>
          :<tbody>
          <tr>
            <td></td>
            <td></td>
            <td>No data available</td>

            <td></td>
            <td></td>
            </tr>
            </tbody>
      }
          </table>

             </Row>

             <Row id='table-styles'>
             <div className="col-md-12" id='head'>
           <h4 className="heading"><span>TCF Information</span>
           <span><Popup trigger={<a className="button add-accountrecord">  New </a>} modal>

          <form onSubmit={handleSubmittcf} className="add-accounts">
           <div className='form-group'>
           <label>TCF Record Type</label><br/>
           <select name='account_record' className='form-control' onChange={handleChangetcf} value={formData.account_record}>
             <option >--Select TCF Record Type--</option>
             <option value='Buyer - Spot'>Buyer - Spot</option>
             <option value='Buyer - Long Term'>Buyer - Long Term</option>
             </select>
             </div>
             <input type='submit' className="btn btn-primary " value='Submit'/>

             </form>
      </Popup></span></h4>
           <hr></hr>
           </div>
             <table id='tcf' className="">
          <thead>
          <tr>
            <th>TCF No</th>
            <th>TCF Status</th>
            <th>Currency</th>
            <th>Exchange Rate</th>
            <th>Opportunity</th>
            <th>Record Type</th>
            <th>Trader Name</th>

          </tr>
          </thead>

          {tcf.length> 0  ?

            <tbody>
            {
                tcf.map((y,i)=>(
            <tr>
              <td><span id='codes'>{y.tcf_no}</span></td>
              <td>{y.tcf_status}</td>
              <td>{y.currency}</td>
              <td>{y.exchange_rate}</td>
              <td>{y.opportunity}</td>
              <td>{y.record_type}</td>
              <td>{y.trader_name}</td>
            </tr>
             ))}
          </tbody>
          :<tbody>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td>No data available</td>
            <td></td>
            <td></td>
            <td></td>
            </tr>
            </tbody>
      }
          </table>

             </Row>
             <Row id='table-styles'>
             <div className="col-md-12" id='head'>
           <h4 className="heading"><span>Orders Information </span></h4>
           <hr></hr>
           </div>
             <table id='orders' className="">
          <thead>
          <tr>
            <th>Order No</th>
            <th>Account Name</th>
            <th>Barge Name</th>
            <th>Laycan From Date</th>
            <th>Laycan From Date</th>
            <th>Laycan Month</th>
            <th>Laycan Period</th>

          </tr>
          </thead>

          {orderData.length> 0  ?

            <tbody>
            {
                  orderData.map((y,i)=>(
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
             ))}
          </tbody>
          :<tbody>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td>No data available</td>
            <td></td>
            <td></td>
            <td></td>
            </tr>
            </tbody>
      }
          </table>

             </Row>

             <Row id='table-styles'>
             <div className="col-md-12" id='head'>
           <h4 className="heading"><span>Contract Product</span></h4>
           <hr></hr>
           </div>
             <table id='orders' className="">
          <thead>
          <tr>
            <th>Contract Product Number</th>
            <th>Product Master</th>
            <th>Product Name</th>
            <th>Product Description</th>
            <th>Contract Shipment</th>

          </tr>
          </thead>

          {contractProduct.length> 0  ?

            <tbody>
            {
                  contractProduct.map((x,i)=>(
            <tr>
              <td>{x.contract_product_number}</td>
              <td>{x.product_master}</td>
              <td>{x.product_name}</td>
              <td>{x.product_description}</td>
              <td>{x.contract_shipment}</td>

            </tr>
             ))}
          </tbody>
          :<tbody>
          <tr>

            <td></td>
            <td></td>
            <td>No data available</td>
            <td></td>
            <td></td>
            </tr>
            </tbody>
      }
          </table>

             </Row>
             <Row id='table-styles'>
           <div className="col-md-12" id='head'>
         <h4 className="heading"><span>Documents Information</span><span> <Link to={"/contract/add-document/" + id}>
                        <i className="fe fe-download-cloud me-2"></i>
                         New
                      </Link> </span></h4>
         <hr></hr>
         </div>
           <table id='document' className="">
        <thead>
        <tr>
          <th>contract list of docs</th>
          <th>document required name</th>
          <th>list of docuemnts</th>
          <th>original in no</th>
          <th>suffix</th>

        </tr>
        </thead>

        {contractDocument.length> 0  ?

          <tbody>
          {
                contractDocument.map((y,i)=>(
          <tr>
            <td className=""><Link to={'/contract/document-view/'+y.id}>{y.contract_list_of_docs}</Link></td>
            <td>{y.document_required_name}</td>
            <td>{y.list_of_docuemnts}</td>
            <td>{y.original_in_no}</td>
            <td>{y.suffix}</td>

          </tr>
           ))}
        </tbody>
        :<tbody>
        <tr>
          <td></td>
          <td></td>

          <td>No data available</td>
          <td></td>

          </tr>
          </tbody>
    }
        </table>

           </Row>
         </div>
         </>)}
         </>
      ):(
        <>
    {datas.contract_record_type === "Supplier - Spot" ? (

       <>
       <div className="contactAccount">
         <div className="tasks">
         <div className="edit-delete">
                  <span id="edit" onClick={handleEditClick}>
                    Edit
                  </span>
                  <span>
                    Delete
                  </span>
                  <span>
                    Activate
                  </span>
                  <span>
                    Generate Contract
                  </span>
<span>
                     Generate Contract Clauses
                  </span>
<span>
                    Shipment Checklist
                  </span>
                  </div>
           <div className="">
             <div className="row">
               <div className="col-md-12" id="head">
                 <h4 className="heading">Information</h4>

                 <hr></hr>
               </div>
             </div>
             <table class="table table-bordered account-table tables">
               <tbody>
                 <tr>
                   <td id="td-right"><span>Locked</span> </td>
                   <td>{datas.locked}</td>


                 </tr>
                 <tr>
                 <td id="td-right">
                     <span>Currency</span>
                   </td>
                   <td>
                     {datas.currency}
                   </td>
                   <td id="td-right">
                     <span>
                       Trader Name

                     </span>
                     </td><td>
                     {datas.trader_name}
                   </td>
                 </tr>
                 <tr>
                 <td id="td-right">
                     <span>Contract record type</span>
                     </td>
                     <td>
                      {datas.contract_record_type}
                   </td>
                   <td id="td-right">
                     <span>Account Name</span>
                     </td>
                     <td>
                      {datas.account_name}
                   </td>
                 </tr>
                 <tr>

                 <td id="td-right">
                     <span>Exchange Rate</span>
                     </td>
                     <td>

                     {datas.exchange_rate}
                   </td>
                   <td id="td-right">
                     <span>Contract Owner</span>
                     </td>
                     <td>
                     {datas.contract_owner}
                   </td>

                 </tr>
                 <tr>

                   <td id="td-right">
                       <span>Price book </span>
                       </td>
                       <td>

                       {datas.price_book}
                     </td>
                     <td id="td-right">
                       <span>Rwood contract no</span>
                       </td>
                       <td>
                       {datas.Rwood_Contract_No}
                     </td>

                   </tr>
                   <tr>

                   <td id="td-right">
                       <span>Status</span>
                       </td>
                       <td>

                       {datas.status}
                     </td>
                     <td id="td-right">
                       <span>Contract Status</span>
                       </td>
                       <td>
                       {datas.contract_status}
                     </td>

                   </tr>
                   <tr>

                   <td id="td-right">
                       <span>Contract term in months</span>
                       </td>
                       <td>

                       {datas.contract_term_in_months}
                     </td>
                     <td id="td-right">
                       <span>Pending Matters</span>
                       </td>
                       <td>
                       {datas.pending_matters}
                     </td>

                   </tr>
                   <tr>

                   <td id="td-right">
                       <span>Remark</span>
                       </td>
                       <td>

                       {datas.remark}
                     </td>
                     <td id="td-right">
                       <span>Contract Start Date</span>
                       </td>
                       <td>
                       {datas.contract_start_date}
                     </td>

                   </tr>

                   <tr>

                   <td id="td-right">
                       <span>Contract Effective Date</span>
                       </td>
                       <td>

                       {datas.contract_effective_date}
                     </td>
                     <td id="td-right">
                       <span>Master Contract Reference</span>
                       </td>
                       <td>
                       {datas.master_conference_reference}
                     </td>

                   </tr>

                   <tr>

                   <td id="td-right">
                       <span>Renewal Date</span>
                       </td>
                       <td>

                       {datas.renewal_date}
                     </td>
                     <td id="td-right">
                       <span>Renewal contract number</span>
                       </td>
                       <td>
                       {datas.renewal_contract_number}
                     </td>

                   </tr>
               </tbody>
             </table>
           </div>
           <div className="">
             <div className="row">
               <div className="col-md-12" id="head">
                 <h4 className="heading">Commodity and Contracted Quantity</h4>
                 <hr></hr>
               </div>
             </div>
             <table class="table table-bordered account-table tables">
               <tbody>

                 <tr>
                 <td id="td-right">
                     <span>Port Of Loading</span>
                     </td>
                     <td>
                     {datas.port_of_loading}
                   </td>
                     <td id="td-right">
                      <span>Origin</span>
                     </td>
                     <td>
                     {datas.origin}
                   </td>

                     </tr>

                 <tr>


                   <td id="td-right">
                     <span>Contract Quantity in MT</span>
                     </td>
                     <td>
                     {datas.contract_quantity_in_MT}
                   </td>
                   <td id="td-right">

                     <span>Commodity</span>
                     </td>
                     <td>
                     {datas.commodity}
                   </td>

                   </tr>

               </tbody>
             </table>
           </div>

           <div className="">
             <div className="row">
               <div className="col-md-12" id="head">
                 <h4 className="heading">Price and Price Adjustment</h4>
                 <hr></hr>
               </div>
             </div>
             <table class="table table-bordered account-table tables">
               <tbody>
                <tr>
                <td id="td-right">
                     <span>Price PMT</span>
                    </td><td>
                    {datas.price_PMT}
                   </td>
                   <td id="td-right">
                   <span>Price Book</span>
                    </td><td>
                    {datas.price_book}
                   </td>
                 </tr>

                 <tr>
                <td id="td-right">
                <span>Payment terms</span>
                    </td><td>
                    {datas.payment_terms}
                   </td>
                   <td id="td-right">
                <span>Price PMT in words</span>
                    </td><td>
                     {datas.price_PMT_in_words}
                   </td>
                 </tr>



                 <tr>
                 <td id="td-right">
                   <span>Price Basis</span>
                    </td><td>
                     {datas.price_basis}
                   </td>
                <td id="td-right">
                <span>Price Type</span>
                    </td><td>
                     {datas.price_type}
                   </td>

                 </tr>


                 <tr>
                 <td id="td-right">
                   <span>Price Basis GAR</span>
                    </td><td>
                     {datas.price_basis_GAR}
                   </td>
                <td id="td-right">
                <span>Price adjustment clause</span>
                    </td><td>
                     {datas.price_adjustment_clause}
                   </td>

                 </tr>


                 <tr>
                 <td id="td-right">
                   <span>Rejections</span>
                    </td><td>
                     {datas.rejections}
                   </td>
                <td id="td-right">
                <span>Price adjustment clause</span>
                    </td><td>
                     {datas.description}
                   </td>

                 </tr>

                 <tr>
                 <td id="td-right">
                   <span>Pricing Terms</span>
                    </td><td>
                     {datas.pricing_terms}
                   </td>
                <td id="td-right">

                    </td><td>

                   </td>

                 </tr>
               </tbody>
             </table>
           </div>


           <div className="">
             <div className="row">
               <div className="col-md-12" id="head">
                 <h4 className="heading">Other Standard Term From Supplier</h4>
                 <hr></hr>
               </div>
             </div>
             <table class="table table-bordered account-table tables">
               <tbody>
                 <tr>
                 <td id="td-right">
                     <span>Payment Terms</span>
                    </td><td>
                     {datas.payment_terms}
                   </td>
                   <td id="td-right">
                     <span>Applicable Law</span>
                    </td><td>
                     {datas.applicable_law}
                   </td>
                   </tr>


                   <tr>
                   <td id="td-right">
                     <span>Arbitration</span>
                    </td><td>
                     {datas.arbitration}
                   </td>
                   <td id="td-right">
                     <span>General Terms</span>
                    </td><td>
                     {datas.general_terms}
                   </td>
                 </tr>


                </tbody>


             </table>
           </div>
           <div className="">
             <div className="row">
               <div className="col-md-12" id="head">
                 <h4 className="heading">Description Information</h4>
                 <hr></hr>
               </div>
             </div>
             <table class="table table-bordered account-table tables">
               <tbody>
                 <tr>
                 <td id="td-right">
                     <span>Loading rate per day</span>
                    </td><td>
                     {datas.loading_rate_per_day}
                   </td>
                   <td id="td-right">
                     <span>Special Terms</span>
                    </td><td>
                     {datas.special_terms}
                   </td>
                   </tr>

                   <tr>
                 <td id="td-right">
                     <span>Bonus penalty clause</span>
                    </td><td>
                     {datas.bonus_penalty_clause}
                   </td>
                   <td id="td-right">

                    </td><td>

                   </td>
                   </tr>

               </tbody>


             </table>
           </div>




           <div className="">
             <div className="row">
               <div className="col-md-12" id="head">
                 <h4 className="heading">Other Standard Terms</h4>
                 <hr></hr>
               </div>
             </div>
             <table class="table table-bordered account-table tables">
               <tbody>
                 <tr>
                 <td id="td-right">
                     <span>Billing Street</span>
                    </td><td>
                     {datas.billing_street}
                   </td>
                   <td id="td-right">
                     <span>Shipping Street</span>
                    </td><td>
                     {datas.shipping_street}
                   </td>
                   </tr>
                   <tr>
                   <td id="td-right">
                     <span>Billing Zip Postal Code</span>
                    </td><td>
                     {datas.billing_zip_postal_code}
                   </td>
                   <td id="td-right">
                     <span>Shipping zip postal code</span>
                    </td><td>
                     {datas.shipping_zip_postal_code}
                   </td>

                 </tr>



                 <tr>
                 <td id="td-right">
                     <span>Billing City</span>
                    </td><td>
                     {datas.billing_city}
                   </td>
                   <td id="td-right">
                     <span>Shipping City</span>
                    </td><td>
                     {datas.shipping_city}
                   </td>

                   </tr>


                   <tr>
                 <td id="td-right">
                     <span>Billing state or province</span>
                    </td><td>
                     {datas.billing_state_or_province}
                   </td>
                   <td id="td-right">
                     <span>Shipping state or province</span>
                    </td><td>
                     {datas.shipping_state_or_province}
                   </td>

                   </tr>



                   <tr>
                 <td id="td-right">
                     <span>Billing Country</span>
                    </td><td>
                     {datas.billing_country}
                   </td>
                   <td id="td-right">
                     <span>Shipping Country</span>
                    </td><td>
                     {datas.shipping_scountry}
                   </td>

                   </tr>



               </tbody>


             </table>
           </div>



           <div className="">
             <div className="row">
               <div className="col-md-12" id="head">
                 <h4 className="heading">List Of Fields-Not Required</h4>
                 <hr></hr>
               </div>
             </div>
             <table class="table table-bordered account-table tables">
               <tbody>
                 <tr>

                   <td id="td-right">
                     <span>Customer signed by</span>
                    </td><td>
                     {datas.customer_signed_by}
                   </td>
                   <td id="td-right">
                     <span>Customer signed title</span>
                    </td><td>
                     {datas.customer_signed_title}
                   </td>
                   </tr>
                   <tr>


                   <td id="td-right">
                     <span>Company signed by</span>
                    </td><td>
                     {datas.company_signed_by}
                   </td>

                  <td id="td-right">
                    <span>Original Contract Recieved Date</span>
                   </td><td>
                    {datas.original_contract_recieved_date}
                  </td>
                  </tr>


                 <tr>

                  <td id="td-right">
                    <span>Company Signed Date</span>
                   </td><td>
                    {datas.company_signed_date}
                  </td>

                  <td id="td-right">
                    <span>Original contract sent date</span>
                   </td><td>
                    {datas.original_contract_sent_date}
                  </td>
                 </tr>
               </tbody>


             </table>
           </div>
         </div>
       </div>


       <div
         className="tasks view-table"
         Style="box-showdow:0px 13px 20px #d6d1d1"
       >
           <Row id="table-styles">
                  <div className="col-md-12" id="head">
                    <h4 className="heading"><span>History Information</span></h4>
                    <hr></hr>
                  </div>
                  <table id="history" className="">
                    <thead>
                      <tr>
                        <th>Field Name</th>
                        <th>New Value</th>
                        <th>Old Value</th>
                        <th>Changed By</th>
                        <th>Change Time</th>
                      </tr>
                    </thead>

                    {history.length > 0 ? (
                      <tbody>
                        {history.map((y, i) => (
                          <tr>
                            <td>{y.field_name}</td>
                            <td>{y.new_value}</td>
                            <td>{y.old_value}</td>
                            <td>{y.changed_by}</td>
                            <td>{y.change_time}</td>
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td></td>
                          <td></td>
                          <td>No data available</td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </Row>
         <Row id='table-styles'>
           <div className="col-md-12" id='head'>
         <h4 className="heading"><span>Contract Shipment</span>
         <span><Popup trigger={<a className="button add-accountrecord">  New </a>} modal>

        <form onSubmit={handleSubmit} className="add-accounts">
         <div className='form-group'>
         <label>Contract Shipment Record Type</label><br/>
         <select name='account_record' className='form-control' onChange={handleChange} value={formData.account_record}>

           <option >--Select Record Type--</option>
           <option value='Buyer - Long Term'>Buyer Long Term</option>
           <option value='Buyer - Spot'>Buyer Spot</option>
           <option value='Supplier - Long Term'>Supplier - Long Term</option>
           <option value='Supplier - Spot'>Supplier - Spot</option>

           </select>
           </div>
           <input type='submit' className="btn btn-primary " value='Submit'/>

           </form>
    </Popup></span></h4>

         <hr></hr>
         </div>
         <div  id='inner-heading'>

      </div>
           <table id='shipment' className="">
        <thead>
        <tr>
         <th>Contract Shipment No</th>
        <th>Contract Shipment Status</th>
        <th>Currency</th>
          <th>Exchange Rate</th>
          <th>Order Quantity</th>
          <th>Parent Shipment</th>
          <th>Record Type</th>
        </tr>
        </thead>

        {conShipment.length> 0 ?

        <tbody>
        {
              conShipment.map((y,i)=>(
          <tr>
            <td><span id='codes'><Link to ={'/shipment/contract-shipment-details/'+y.id}>{y.contract}</Link></span></td>
            <td>{y.contract_shipment_status}</td>
            <td>{y.currency}</td>
            <td>{y.exchange_rate}</td>
            <td>{y.order_quantity}</td>
            <td>{y.parent_shipment}</td>
            <td>{y.record_type}</td>
          </tr>
           ))}
        </tbody>
        :<tbody>
        <tr>

          <td></td>
          <td></td>
          <td>No data available</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          </tr>
          </tbody> }

        </table>

           </Row>
           <Row id='table-styles'>
           <div className="col-md-12" id='head'>
         <h4 className="heading"><span>Opportunity Information </span>
         {/* <Popup trigger={<span className="button add-accountrecord">  New </span>} modal>
              <form onSubmit={handleSubmitOpp} className="add-accounts">
            <div className='form-group'>
            <label>Opportunity Record Type</label><br/>
            <select  className='form-control' name="type_of_new_record" onChange={handleChangeOpp} value={formDataopp.account_record}>
              <option>----Select----</option>
    <option value='Buyer' >Buyer</option>
          <option value='Buyer - Long Term' >Buyer - Long Term</option>
          <option value='Buyer - Spot' >Buyer - Spot</option>
          <option value='Supplier' >Supplier</option>
          <option value='Supplier - Long Term' >Supplier - Long Term</option>
          <option value='Supplier - Spot'>Supplier - Spot</option>

              </select>
              </div>
              <input type='submit' className="btn btn-primary " value='Submit'/>

              </form>
          </Popup> */}
          </h4>
         <hr></hr>
         </div>
           <table id='opportunity' className="">
        <thead>
        <tr>
          <th>Opportunity No</th>
          <th>Opportunity Name</th>
          <th>Stage</th>
          <th>Quantity (MT)	Supplier</th>
          <th>No of Order</th>

        </tr>
        </thead>

        {opportunity.length> 0  ?

          <tbody>
          {
              opportunity.map((y,i)=>(
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
           ))}
        </tbody>
        :<tbody>
        <tr>
          <td></td>
          <td></td>
          <td>No data available</td>

          <td></td>
          <td></td>
          </tr>
          </tbody>
    }
        </table>

           </Row>

           <Row id='table-styles'>
           <div className="col-md-12" id='head'>
         <h4 className="heading"><span>TCF Information</span>
         <span><Popup trigger={<a className="button add-accountrecord">  New </a>} modal>

        <form onSubmit={handleSubmittcf} className="add-accounts">
         <div className='form-group'>
         <label>TCF Record Type</label><br/>
         <select name='account_record' className='form-control' onChange={handleChangetcf} value={formData.account_record}>
           <option >--Select TCF Record Type--</option>
           <option value='Buyer - Spot'>Buyer - Spot</option>
           <option value='Buyer - Long Term'>Buyer - Long Term</option>
           </select>
           </div>
           <input type='submit' className="btn btn-primary " value='Submit'/>

           </form>
    </Popup></span></h4>
         <hr></hr>
         </div>
           <table id='tcf' className="">
        <thead>
        <tr>
          <th>TCF No</th>
          <th>TCF Status</th>
          <th>Currency</th>
          <th>Exchange Rate</th>
          <th>Opportunity</th>
          <th>Record Type</th>
          <th>Trader Name</th>

        </tr>
        </thead>

        {tcf.length> 0  ?

          <tbody>
          {
              tcf.map((y,i)=>(
          <tr>
            <td><span id='codes'>{y.tcf_no}</span></td>
            <td>{y.tcf_status}</td>
            <td>{y.currency}</td>
            <td>{y.exchange_rate}</td>
            <td>{y.opportunity}</td>
            <td>{y.record_type}</td>
            <td>{y.trader_name}</td>
          </tr>
           ))}
        </tbody>
        :<tbody>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td>No data available</td>
          <td></td>
          <td></td>
          <td></td>
          </tr>
          </tbody>
    }
        </table>

           </Row>
           <Row id='table-styles'>
           <div className="col-md-12" id='head'>
         <h4 className="heading"><span>Orders Information </span></h4>
         <hr></hr>
         </div>
           <table id='orders' className="">
        <thead>
        <tr>
          <th>Order No</th>
          <th>Account Name</th>
          <th>Barge Name</th>
          <th>Laycan From Date</th>
          <th>Laycan From Date</th>
          <th>Laycan Month</th>
          <th>Laycan Period</th>

        </tr>
        </thead>

        {orderData.length> 0  ?

          <tbody>
          {
                orderData.map((y,i)=>(
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
           ))}
        </tbody>
        :<tbody>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td>No data available</td>
          <td></td>
          <td></td>
          <td></td>
          </tr>
          </tbody>
    }
        </table>

           </Row>
           <Row id='table-styles'>
             <div className="col-md-12" id='head'>
           <h4 className="heading"><span>Contract Product</span></h4>
           <hr></hr>
           </div>
             <table id='orders' className="">
          <thead>
          <tr>
            <th>Contract Product Number</th>
            <th>Product Master</th>
            <th>Product Name</th>
            <th>Product Description</th>
            <th>Contract Shipment</th>

          </tr>
          </thead>

          {contractProduct.length> 0  ?

            <tbody>
            {
                  contractProduct.map((x,i)=>(
            <tr>
              <td>{x.contract_product_number}</td>
              <td>{x.product_master}</td>
              <td>{x.product_name}</td>
              <td>{x.product_description}</td>
              <td>{x.contract_shipment}</td>

            </tr>
             ))}
          </tbody>
          :<tbody>
          <tr>

            <td></td>
            <td></td>
            <td>No data available</td>
            <td></td>
            <td></td>

            </tr>
            </tbody>
      }
          </table>

             </Row>
             <Row id='table-styles'>
             <div className="col-md-12" id='head'>
           <h4 className="heading"><span>Contract Product</span></h4>
           <hr></hr>
           </div>
             <table id='orders' className="">
          <thead>
          <tr>
            <th>Contract Product Number</th>
            <th>Product Master</th>
            <th>Product Name</th>
            <th>Product Description</th>
            <th>Contract Shipment</th>

          </tr>
          </thead>

          {contractProduct.length> 0  ?

            <tbody>
            {
                  contractProduct.map((x,i)=>(
            <tr>
              <td>{x.contract_product_number}</td>
              <td>{x.product_master}</td>
              <td>{x.product_name}</td>
              <td>{x.product_description}</td>
              <td>{x.contract_shipment}</td>

            </tr>
             ))}
          </tbody>
          :<tbody>
          <tr>

            <td></td>
            <td></td>
            <td>No data available</td>
            <td></td>
            <td></td>

            </tr>
            </tbody>
      }
          </table>

             </Row>
           <Row id='table-styles'>
           <div className="col-md-12" id='head'>
         <h4 className="heading"><span>Documents Information</span><span> <Link to={"/contract/add-document/" + id}>
                        <i className="fe fe-download-cloud me-2"></i>
                         New
                      </Link> </span></h4>
         <hr></hr>
         </div>
           <table id='document' className="">
        <thead>
        <tr>
          <th>contract list of docs</th>
          <th>document required name</th>
          <th>list of docuemnts</th>
          <th>original in no</th>
          <th>suffix</th>

        </tr>
        </thead>

        {contractDocument.length> 0  ?

          <tbody>
          {
                contractDocument.map((y,i)=>(
          <tr>
            <td className=""><Link to={'/contract/document-view/'+y.id}>{y.contract_list_of_docs}</Link></td>
            <td>{y.document_required_name}</td>
            <td>{y.list_of_docuemnts}</td>
            <td>{y.original_in_no}</td>
            <td>{y.suffix}</td>

          </tr>
           ))}
        </tbody>
        :<tbody>
        <tr>
          <td></td>
          <td></td>

          <td>No data available</td>
          <td></td>

          </tr>
          </tbody>
    }
        </table>

           </Row>
       </div>
       </>
    ):(
      <p></p>
    )}
    </>
      )}
      </>
    )}
    </>
    )}
    </>
  )}

  </>

      <Footer />
    </div>
  );
};

export default ViewContract;
