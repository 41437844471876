import React, { Component, useState, useEffect, useContext, forwardRef } from "react";
import {
    Card,
    OverlayTrigger,
    Tooltip,
    Table,
} from "react-bootstrap";
import "datatables.net-dt/js/dataTables.dataTables.js";
import { AES, enc } from "crypto-js";
import { parse, format, isValid } from 'date-fns';
import RecentItems from "../recentItems/RecentItems";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "datatables.net-responsive-dt";
import { useQuill } from 'react-quilljs';
// or const { useQuill } = require('react-quilljs');
import { API_URL } from '../config';
import 'quill/dist/quill.snow.css';
import Select from 'react-select';
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "react-data-table-component-extensions/dist/index.css";
import { Link, useNavigate } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import Button from "react-bootstrap/Button";
import { IoIosHelpCircle } from "react-icons/io";
import { TokenContext } from "../AuthProvider";
import Header from "../Header";
import Footer from "../Footer";
import $ from "jquery";
import DatePicker from "react-datepicker";
import Popup from "reactjs-popup";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import {
    FaEnvelope,
    FaPhoneSquareAlt,
    FaMobileAlt,
    FaFax,
} from "react-icons/fa";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import axios from "axios";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { FaPencilAlt, FaTrashAlt, FaPlus } from "react-icons/fa";

import { useParams } from "react-router-dom";
const TradeConfirmDetail = () => {
    const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
  const encryptedAccessToken = localStorage.getItem("access_token");
  const decryptedAccessToken = encryptedAccessToken
    ? AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8)
    : null;
    const { quill, quillRef } = useQuill();
    const params = useParams();
    const id = params.id;


    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Please Enter Full Company Name here
        </Tooltip>
    );


    // useEffect(() => {

    //   fetchDatas();
    // }, [decryptedAccessToken]);

    const fetchDatas = async () => {
        try {
            const response = await axios.get(API_URL + 'get/all/rwoodbank', {
                headers: { Authorization: `Bearer ${decryptedAccessToken}` },
            }
            );
            console.log(response)
            const data = response.data.rwood_bank;
            setDatas(data);
        } catch (error) {
            console.error(error);
        }
    };

    const [isSubmitted, setIsSubmitted] = useState(false);
    const [product, setProduct] = useState([])
    // useEffect(() => {

    //   fetchDataProduct();
    // }, [decryptedAccessToken]);

    const fetchDataProduct = async () => {
        try {
            const response = await axios.get(API_URL + 'get/allproducts', {
                headers: { Authorization: `Bearer ${decryptedAccessToken}` },
            }
            );
            const data = response.data;
            console.log(data)
            setProduct(data);

        } catch (error) {
            console.error(error);
        }
    };


    const navigate = useNavigate();
    const userRole = localStorage.getItem("role");
    console.log(userRole);
    const [startDate2, setStartDate2] = useState(new Date());
    const [price, setPrice] = useState([]);
    const [startDate1, setStartDate1] = useState(new Date());
    const [radio, setRadio] = useState(0);
    function handleChangeLineitem(e) {
        const { nodeName, value } = e.target;
        if (nodeName === 'INPUT') {
            setRadio(value);
        }
    }
    function handleClick(e) {
        const responseData = {
            record_type: radio
        }
        const rtype = responseData.record_type;
        const encryptedAccessToken = localStorage.getItem("access_token");
        const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
        console.log(JSON.stringify(responseData))
        if (encryptedAccessToken) {
            const decryptedAccessToken = AES.decrypt(
                encryptedAccessToken,
                secret_key
            ).toString(enc.Utf8);

            fetch(API_URL + "add/rw_pay_recieve/" + id, {
                method: "POST",
                body: JSON.stringify(responseData),
                headers: {
                    Authorization: `Bearer ${decryptedAccessToken}`,
                    "Content-Type": "application/json",
                },
            })
                .then((response) => response.json())

                .then((response) => {
                    console.log(response);
                    if (response.messege = 'Rw-Pay-recieve added successfully') {
                        toast.success("Rwood Payable/Recieve Created", {
                            position: "bottom-center",
                            autoClose: 1000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                        setTimeout(() => {

                            navigate("/tcf/payable/" + id,
                                {


                                    state: { ids: id, recordtype: rtype, order_id: 3 },


                                });

                        }, 2000);
                    }
                });
        }
    }



    // useEffect(() => {

    //   fetchData6();
    // }, [decryptedAccessToken]);

    const fetchData6 = async () => {
        try {
            const response = await axios.get(API_URL + 'get/all/price_items', {
                headers: { Authorization: `Bearer ${decryptedAccessToken}` },
            }
            );
            const data = response.data.priceitem;
            console.log(data)
            setPrice(data);

        } catch (error) {
            console.error(error);
        }
    };
    const [buyers, setBuyer] = useState([]);
    const [shippment, setShippment] = useState([]);

    // useEffect(() => {

    //   fetchDataShipment();
    // }, [decryptedAccessToken]);

    const fetchDataShipment = async () => {
        try {
            const response = await axios.get(API_URL + 'get/products_api', {
                headers: { Authorization: `Bearer ${decryptedAccessToken}` },
            }
            );
            const data = response.data;

            setShippment(data)
            console.log(JSON.stringify(data))

        } catch (error) {
            console.error(error);
        }
    };


    const [editorDescription, setDescription] = useState([])

    useEffect(() => {
        if (quill) {
            quill.on('text-change', (delta, oldDelta, source) => {
                console.log('Text change!');
                console.log(quill.getText()); // Get text only
                console.log(quill.getContents()); // Get delta contents
                setDescription(quill.root.innerHTML); // Get innerHTML using quill
                console.log(quillRef.current.firstChild.innerHTML); // Get innerHTML using quillRef
            });
        }
    }, [quill]);


    // useEffect(() => {

    //   fetchData4();
    // }, [decryptedAccessToken]);

    const fetchData4 = async () => {
        try {
            const response = await axios.get(API_URL + 'get/buyer', {
                headers: { Authorization: `Bearer ${decryptedAccessToken}` },
            }
            );
            const data = response.data;
            console.log(response)
            setBuyer(data);

        } catch (error) {
            console.error(error);
        }
    };
    const [startDate6, setStartDate6] = useState(new Date());
    const BankDate6 = startDate2.toLocaleDateString("en-US", { year: 'numeric' }) + "-" + (startDate1.toLocaleDateString("en-US", { month: '2-digit' })) + "-" + (startDate1.toLocaleDateString("en-US", { day: '2-digit' }))
    const CustomInput6 = forwardRef(({ value, onClick, onChange }, ref) => (
        <input
            value={value}
            className="custom-input"
            onClick={onClick}
            onChange={onChange}
            ref={ref}
        ></input>
    ));






    const [suppliers, setSupplier] = useState([]);

    // useEffect(() => {

    //   fetchData5();
    // }, [decryptedAccessToken]);

    const fetchData5 = async () => {
        try {
            const response = await axios.get(API_URL + 'get/supplier', {
                headers: { Authorization: `Bearer ${decryptedAccessToken}` },
            }
            );
            const data = response.data;
            console.log(data)
            setSupplier(data);

        } catch (error) {
            console.error(error);
        }
    };

    const [isButtonEnabled, setIsButtonEnabled] = useState(false);
    useEffect(() => {
        setIsButtonEnabled(true);
    }, []);
    const [startDate3, setStartDate3] = useState(new Date());
    const BankDate1 = startDate2.toLocaleDateString("en-US", { year: 'numeric' }) + "-" + (startDate1.toLocaleDateString("en-US", { month: '2-digit' })) + "-" + (startDate1.toLocaleDateString("en-US", { day: '2-digit' }))
    const CustomInput3 = forwardRef(({ value, onClick, onChange }, ref) => (
        <input
            value={value}
            className="custom-input"
            onClick={onClick}
            onChange={onChange}
            ref={ref}
        ></input>
    ));





    const { username, token } = useContext(TokenContext);
    const [quotes, setQuotes] = useState({});
    const [quotesProduct, setQuotesProduct] = useState([]);
    const [tcf, setTcf] = useState([]);
    const [data, setData] = useState({});
    const [isEditMode, setIsEditMode] = useState(false);
    const [editedData, setEditedData] = useState({});

    const birthDate = startDate1.toLocaleDateString("en-US", { year: 'numeric' }) + "-" + (startDate1.toLocaleDateString("en-US", { month: '2-digit' })) + "-" + (startDate1.toLocaleDateString("en-US", { day: '2-digit' }))
    const CustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
        <input
            value={value}
            className="custom-input"
            onClick={onClick}
            onChange={onChange}
            ref={ref}
        ></input>
    ));
    const [companies, setCompanies] = useState([]);
    // useEffect(() => {

    //   fetchDatacompany();
    // }, [decryptedAccessToken]);

    const fetchDatacompany = async () => {
        try {
            const response = await axios.get(API_URL + 'get/all/rwoodcompany', {
                headers: { Authorization: `Bearer ${decryptedAccessToken}` },
            }
            );
            const data = response.data.rwoodcompany;
            setCompanies(data);

        } catch (error) {
            console.error(error);
        }
    };



    const [productName, setProductName] = useState([]);
    // useEffect(() => {

    //   fetchProductName();
    // }, [decryptedAccessToken]);

    const fetchProductName = async () => {
        try {
            const response = await axios.get(API_URL + '/get/all/newproducts', {
                headers: { Authorization: `Bearer ${decryptedAccessToken}` },
            }
            );
            const data = response.data.new_product
            setProductName(data)

        } catch (error) {
            console.error(error);
        }
    };

    const [rwoodbank, setRwoodbank] = useState([]);

    // useEffect(() => {

    //   fetchData1();
    // }, [decryptedAccessToken]);

    const fetchData1 = async () => {
        try {
            const response = await axios.get(API_URL + 'get/all/rwoodbank', {
                headers: { Authorization: `Bearer ${decryptedAccessToken}` },
            }
            );
            const data = response.data.rwood_bank;
            // console.log(data)
            setRwoodbank(data);

        } catch (error) {
            console.error(error);
        }
    };



    const [priceBook, setPriceBook] = useState([]);

    // useEffect(() => {

    //   fetchData2();
    // }, [decryptedAccessToken]);

    const fetchData2 = async () => {
        try {
            const response = await axios.get(API_URL + 'get/all/pricebook', {
                headers: { Authorization: `Bearer ${decryptedAccessToken}` },
            }
            );
            const data = response.data.pricebook;
            console.log(data)
            setPriceBook(data);

        } catch (error) {
            console.error(error);
        }
    };

    // dsdsdsd

    const [locked, setLocked] = useState(false);


    const [startDate5, setStartDate5] = useState(new Date());
    const BankDate5 = startDate2.toLocaleDateString("en-US", { year: 'numeric' }) + "-" + (startDate1.toLocaleDateString("en-US", { month: '2-digit' })) + "-" + (startDate1.toLocaleDateString("en-US", { day: '2-digit' }))
    const CustomInput5 = forwardRef(({ value, onClick, onChange }, ref) => (
        <input
            value={value}
            className="custom-input"
            onClick={onClick}
            onChange={onChange}
            ref={ref}
        ></input>
    ));



    const BankDate = startDate2.toLocaleDateString("en-US", { year: 'numeric' }) + "-" + (startDate1.toLocaleDateString("en-US", { month: '2-digit' })) + "-" + (startDate1.toLocaleDateString("en-US", { day: '2-digit' }))
    const CustomInput2 = forwardRef(({ value, onClick, onChange }, ref) => (
        <input
            value={value}
            className="custom-input"
            onClick={onClick}
            onChange={onChange}
            ref={ref}
        ></input>
    ));

    const [rwoodusers, setRwoodusers] = useState([]);
    // useEffect(() => {

    //   fetchData3();
    // }, [decryptedAccessToken]);

    const fetchData3 = async () => {
        try {
            const response = await axios.get(API_URL + 'get/all/rwoodusers ', {
                headers: { Authorization: `Bearer ${decryptedAccessToken}` },
            }
            );
            const data = response.data.rwoodusers;
            console.log(data)
            setRwoodusers(data);

        } catch (error) {
            console.error(error);
        }
    };





    const [history, setHistory] = useState([])
    const [receivable, setReceivable] = useState([])
    const [receipt, setReceipt] = useState([])
    const [purchaseConfirmDate, setPurchaseConfirmDate] = useState('')
    const [proposedDates, setProposedDates] = useState(null)
    const [proposedDates1, setProposedDates1] = useState(null)
    const [tcfDate, setTcfDate] = useState('')
    const [productMaster, setProductMaster] = useState('')
    const [checkBox, setCheck] = useState('')
    useEffect(() => {
        fetchData();
    }, [decryptedAccessToken]);

    const fetchData = async () => {
        try {
            const response = await axios.get(
                API_URL + "get/tcfbyID/" + id,
                {
                    headers: { Authorization: `Bearer ${decryptedAccessToken}` },
                }
            );

            console.log(response)
            const data = response.data;
                // console.log(response.data.trade_data.product_master)
                setTcf(data.trade_data)
            if (response.status === 200) {

                const data = response.data;
                // console.log(response.data.trade_data.product_master)
                // setTcf(data.trade_data)
                setHistory(data.history)
                setCheck(response.data.trade_data.actual_data_rcvd)
                setReceivable(data.recievable_lineitem)
                setReceipt(data.receipt_lineitem)
                console.log(response.data.trade_data.actual_data_rcvd)
                const purchaseConfirm = data.purchase_confirmation_date
                ? parse(data.purchase_confirmation_date, 'dd/MM/yyyy', new Date())
                : null;

            if (purchaseConfirm) {
                const blsentdate1 = parse(data.purchase_confirmation_date, "dd/MM/yyyy", new Date());
                setPurchaseConfirmDate(blsentdate1);
            } else {
                setPurchaseConfirmDate(null);
            }

            const proposedDate = data.trade_data.proposed_laycan_from;
            const proposedDates = data.trade_data.tcf_date;

            if (proposedDate) {
                const defaultDatepicker = parse(proposedDate, 'dd/MM/yyyy', new Date());
                setProposedDates(defaultDatepicker);
            } else {
                setProposedDates(null);
            }

            if (proposedDates) {
                const tcf_dates = parse(proposedDates, 'dd/MM/yyyy', new Date());
                setTcfDate(tcf_dates);
            } else {
                setTcfDate(null);
            }

            const proposedDate1 = data.trade_data.proposed_laycan_to;
            if (proposedDate1) {
                const defaultDatepicker1 = parse(proposedDate1, 'dd/MM/yyyy', new Date());
                setProposedDates1(defaultDatepicker1);
            } else {
                setProposedDates1(null);
            }

                setTimeout(() => {
                    $("#history").DataTable();
                    $("#rw").DataTable();
                    $("#receipt").DataTable();

                }, 100);
            }
            else {
                toast.error("No Data Available", {
                    position: "bottom-center",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                })
            }
        } catch (error) {
            console.error(error);
        }
    };






    useEffect(() => {
        fetchDataProduct();
    }, [decryptedAccessToken]);

    useEffect(() => {
        // Set the selected option to 'Siva' when accountName changes
        if (productMaster.length > 0) {
            setProductMaster({ value: productMaster, label: productMaster });
        }
    }, [productMaster]);


    const options_product = product.map(x => ({
        value: x.product_code,
        label: x.product_code
    }));

    // edit
    const handleTcfDate = (date) => {
        setTcfDate(date);
    };
    const handlePuchaseConfirm = (date) => {
        setPurchaseConfirmDate(date);
    };

    const handleProposeDate = (date) => {
        setProposedDates(date);
    };

    const handleProposeDate1 = (date) => {
        setProposedDates1(date);
    };




    const handleEditClick = () => {
        setIsEditMode(true);
        setEditedData(data);
        fetchDatas();
        fetchDataProduct();
        fetchData6();
        fetchDataShipment();
        fetchData4();
        fetchData5();
        fetchDatacompany();
        fetchProductName();
        fetchData1();
        fetchData2();
        fetchData3();
    };

    const handleChangeInput = (e) => {
        const { name, value } = e.target;
        setTcf((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };


    
    const options = { minimumFractionDigits: 2, maximumFractionDigits: 2 };
    const formatNumber = (number) => {
        return new Intl.NumberFormat("en-US", options).format(number);
    };

    const renderShipmentQty = (tcfValues) => {
        if (tcfValues > 0) {
            return <span className='sr'>{formatNumber(tcfValues)}</span>;
        } else if (tcfValues < 0) {
            return <span className='sr'>({formatNumber(Math.abs(tcfValues))})</span>;
        } else {
            return <span className='sr'>{formatNumber(tcfValues)}</span>;
        }
    };
    
    
    
    
    const handleCancelEdit = () => {
        setIsEditMode(false);
        setEditedData(data);
        setTimeout(() => {
            $("#history").DataTable();
            $("#rw").DataTable();
            $("#receipt").DataTable();

        }, 100);
    };

    const tradeConrfirm = () => {

        fetch(`${API_URL}generate_tcf_excel/${id}`, {
            method: 'GET',


        })
            .then((response) => response.blob())
            .then((blob) => {
                // Create a download link
                const downloadLink = document.createElement('a');
                downloadLink.href = window.URL.createObjectURL(blob);
                downloadLink.download = 'TCF.xlsx'; // You can set the desired file name here

                // Append the link to the body
                document.body.appendChild(downloadLink);

                // Trigger a click on the link
                downloadLink.click();

                // Remove the link from the body
                document.body.removeChild(downloadLink);
            })
            .catch((error) => {
                console.error('Error downloading file:', error);
            });

    }
    const [datas, setDatas] = useState({})
    const [lockedOne, setLockedOne] = useState(false)
    const [lockeds, setLockeds] = useState(false);
    const [actualCheck, setActualCheck] = useState(false);
    const [datasReceived, setDatasReceived] = useState(false);
    console.log("Checkbox clicked, new value: ", tcf.actual_data_rcvd);
    const check_box = tcf.auto_select_pricebook;
    const check_locked = tcf.locked;
    const check_actual = tcf.actual_payment_received;
    const dataReceived = tcf.actual_data_rcvd;
    // Initialize lockeds with null or any default value

    useEffect(() => {
        setLockeds(check_box);
        setLockedOne(check_locked)
        setActualCheck(check_actual)
        setDatasReceived(dataReceived)// Update lockeds with the value of tt
    }, [check_box, dataReceived, check_locked, check_actual]); // Run this effect whenever tt changes

    useEffect(() => {
        console.log("Value of lockeds:", lockeds); // Log lockeds
    }, [lockeds, lockedOne, datasReceived, actualCheck]); // Run this effect whenever lockeds changes

    const handleData = (e) => {
        setDatasReceived(e.target.checked);
        // setLockeds(!lockeds)
        // Debug log

    };
    const handleCheckboxChange = (e) => {
        setLockeds(e.target.checked);
        // setLockeds(!lockeds)
        // Debug log

    };

    const handleActual = (e) => {
        setActualCheck(e.target.checked);
        // setLockeds(!lockeds)
        // Debug log

    };
    const handleCheckboxLocked = (e) => {
        setLockedOne(e.target.checked);
        // setLockeds(!lockeds)
        // Debug log

    };


    const [proposed, setProposed] = useState('')
    const [proposed1, setProposed1] = useState('')
    useEffect(() => {
        const dateObject = new Date(proposedDates);
        if (dateObject) {
            const formattedDate = format(dateObject, 'dd/MM/yyyy', { timeZone: 'UTC' });
            setProposed(formattedDate)
            console.log(formattedDate)
        }
        const dateObject1 = new Date(proposedDates1);
        if (dateObject1) {
            const formattedDates = format(dateObject1, 'dd/MM/yyyy', { timeZone: 'UTC' });
            setProposed1(formattedDates)
        }
    })

    const handleSaveEdit = (e) => {
        e.preventDefault();
        const responseData = {
            "tcf_date": tcfDate ? format(tcfDate, 'dd/MM/yyyy') : null,
            "company": tcf.company,
            "locked": lockedOne,
            "currency": tcf.currency,
            "exchange_rate": parseFloat(tcf.exchange_rate),
            "order": tcf.order,
            "rwood_bank_master": tcf.rwood_bank_master,
            "tcf_status": tcf.tcf_status,
            "buyer_contract": tcf.buyer_contract,
            "contract_shipment": tcf.contract_shipment,
            "product": tcf.product,
            "status_of_buyer_contract": tcf.status_of_buyer_contract,
            "product_master": tcf.product_master,
            "product_name": tcf.product_name,
            "record_type": tcf.record_type,
            "opportunity": tcf.opportunity,
            "quote": tcf.quote,
            "owner": tcf.owner,
            "auto_select_pricebook": lockeds,
            "price_book": tcf.price_book,
            "trader_name": tcf.trader_name,
            "purchase_confirmation_date": purchaseConfirmDate ? format(purchaseConfirmDate, 'dd/MM/yyyy') : null,
            "shipment_qty": tcf.shipment_qty,
            "shipment_purchase_price": parseFloat(tcf.shipment_purchase_price),
            "shipment_purchase": parseFloat(tcf.shipment_purchase),
            "shipment_month": tcf.shipment_month,
            "shipment_sales": parseFloat(tcf.shipment_sales),
            "shipment_gross_margin": parseFloat(tcf.shipment_gross_margin),
            "shipment_gross_margin_ton": parseFloat(tcf.shipment_gross_margin_ton),
            "shipment_sales_price": parseFloat(tcf.shipment_sales_price),
            "buyer": tcf.buyer,
            "supplier": tcf.supplier,
            "product_description": tcf.product_description,
            "product_specification": tcf.product_specification,
            "quantity_MT": tcf.quantity_MT,
            "price_basis": tcf.price_basis,
            "vessel_type": tcf.vessel_type,
            "payment_term": tcf.payment_term,
            "selling_terms": tcf.selling_terms,
            "buying_terms": tcf.buying_terms,
            "price_adjustment": tcf.price_adjustment,
            "status_of_purchase_contract": tcf.status_of_purchase_contract,
            "proposed_laycan_from": proposed ? proposed : null,
            "proposed_laycan_to": proposed1 ? proposed1 : null,
            "contractual_pur_price": parseFloat(tcf.contractual_pur_price),
            "contractual_margin": parseFloat(tcf.contractual_margin),
            "contractual_GAR_S": parseFloat(tcf.contractual_GAR_S),
            "contractual_GAR_P": parseFloat(tcf.contractual_GAR_P),
            "contractual_TM_AR_P": parseFloat(tcf.contractual_TM_AR_P),
            "contractual_TM_AR_S": parseFloat(tcf.contractual_TM_AR_S),
            "contractual_Ash_AR_P": parseFloat(tcf.contractual_Ash_AR_P),
            "contractual_Ts_AR_p": parseFloat(tcf.contractual_Ts_AR_p),
            "contractual_Ts_AR_s": parseFloat(tcf.contractual_Ts_AR_s),
            "contractual_Ash_AR_S": parseFloat(tcf.contractual_Ash_AR_S),
            "contractual_Ts_ADB_P": parseFloat(tcf.contractual_Ts_ADB_P),
            "contractual_Ts_ADB_S": parseFloat(tcf.contractual_Ts_ADB_S),
            "contractual_Ash_ADB_P": parseFloat(tcf.contractual_Ash_ADB_P),
            "contractual_Ash_ADB_S": parseFloat(tcf.contractual_Ash_ADB_S),
            "projected_price_basis_GAR_P": parseFloat(tcf.projected_price_basis_GAR_P),
            "projected_price_basis_GAR_S": parseFloat(tcf.projected_price_basis_GAR_S),
            "contractual_HBA_year": tcf.contractual_HBA_year,
            "projected_hba": tcf.projected_hba,
            "contractual_HBA_month": tcf.contractual_HBA_month,
            "discounted_price": parseFloat(tcf.discounted_price),
            "projected_index_value": parseFloat(tcf.projected_index_value),
            "projected_purchase_type": tcf.projected_purchase_type,
            "projected_fixed_purchase_price": parseFloat(tcf.projected_fixed_purchase_price),
            "projected_purchase_price_margin": parseFloat(tcf.projected_purchase_price_margin),
            "projected_barge_discount": parseFloat(tcf.projected_barge_discount),
            "projected_sale_price_type": tcf.projected_sale_price_type,
            "projected_fixed_sales_price": parseFloat(tcf.projected_fixed_sales_price),
            "projected_sales_price_margin": parseFloat(tcf.projected_sales_price_margin),
            "projected_sales_price": parseFloat(tcf.projected_sales_price),
            "projected_sales": parseFloat(tcf.projected_sales),
            "projected_purchase_price": parseFloat(tcf.projected_purchase_price),
            "projected_gross_margin_shipment": parseFloat(tcf.projected_gross_margin_shipment),
            "projected_gross_margin_ton": parseFloat(tcf.projected_gross_margin_ton),
            "projected_net_margin_shipment": parseFloat(tcf.projected_net_margin_shipment),
            "projected_net_margin_ton": parseFloat(tcf.projected_net_margin_ton),
            "projected_total_bank_cost": parseFloat(tcf.projected_total_bank_cost),
            "projected_cost": parseFloat(tcf.projected_cost),
            "projected_cost_ton": parseFloat(tcf.projected_cost_ton),
            "projected_total_cost": parseFloat(tcf.projected_total_cost),
            "projected_lc_transfer_cost": parseFloat(tcf.projected_lc_transfer_cost),
            "projected_lc_negotiation_cost": parseFloat(tcf.projected_lc_negotiation_cost),
            "projected_withholding_tax": parseFloat(tcf.projected_withholding_tax),
            "projected_fixed_other_bank_costs": parseFloat(tcf.projected_fixed_other_bank_costs),
            "projected_fob_purchase": parseFloat(tcf.projected_fob_purchase),
            "projected_pur_amount": parseFloat(tcf.projected_pur_amount),
            "projected_freight_rate": parseFloat(tcf.projected_freight_rate),
            "projected_freight_amount": parseFloat(tcf.projected_freight_amount),
            "projected_insurance": parseFloat(tcf.projected_insurance),
            "projected_commission": parseFloat(tcf.projected_commission),
            "projected_fixed_apportioned_overheads": parseFloat(tcf.projected_fixed_apportioned_overheads),
            "projected_any_contract_specific_cost": parseFloat(tcf.projected_any_contract_specific_cost),
            "projected_extra_cost": parseFloat(tcf.projected_extra_cost),
            "projected_provision_for_corporate_tax": parseFloat(tcf.projected_provision_for_corporate_tax),
            "vessel_name": tcf.vessel_name,
            "stowage_qty": parseFloat(tcf.stowage_qty),
            "destination": tcf.destination,
            "actual_bl_date": tcf.actual_bl_date,
            "actual_bl_qty": parseFloat(tcf.actual_bl_qty),
            "actual_hba": parseFloat(tcf.actual_hba),
            "actual_hba_purchase": parseFloat(tcf.actual_hba_purchase),
            "expected_value_date": tcf.expected_value_date,
            "demmurage_rate_p": parseFloat(tcf.demmurage_rate_p),
            "demmurage_rate_s": parseFloat(tcf.demmurage_rate_s),
            "load_rate": parseFloat(tcf.load_rate),
            "gcnewc": parseFloat(tcf.gcnewc),
            "actual_gcnewc_sales": parseFloat(tcf.actual_gcnewc_sales),
            "laycan": tcf.laycan,
            "purchase_price_type": tcf.purchase_price_type,
            "sale_price_type": tcf.sale_price_type,
            "fixed_purchase_price": parseFloat(tcf.fixed_purchase_price),
            "purchase_price_margin": parseFloat(tcf.purchase_price_margin),
            "barge_discount": parseFloat(tcf.barge_discount),
            "actual_data_rcvd": dataReceived,
            "fixed_sale_price": parseFloat(tcf.fixed_sale_price),
            "sale_price_margin": parseFloat(tcf.sale_price_margin),
            "actual_gar_p": parseFloat(tcf.actual_gar_p),
            "actual_tm_p": parseFloat(tcf.actual_tm_p),
            "actual_ash_ar_p": parseFloat(tcf.actual_ash_ar_p),
            "actual_ts_ar_p": parseFloat(tcf.actual_ts_ar_p),
            "actual_ash_adb_p": parseFloat(tcf.actual_ash_adb_p),
            "actual_ts_adb_p": parseFloat(tcf.actual_ts_adb_p),
            "actual_aft_p": parseFloat(tcf.actual_aft_p),
            "actual_vm_p": parseFloat(tcf.actual_vm_p),
            "actual_hgi_p": parseFloat(tcf.actual_hgi_p),
            "price_basis_gar_p": parseFloat(tcf.price_basis_gar_p),
            "gar_p": parseFloat(tcf.gar_p),
            "actual_gar_s": parseFloat(tcf.actual_gar_s),
            "actual_tm_s": parseFloat(tcf.actual_tm_s),
            "actual_ash_ar_s": parseFloat(tcf.actual_ash_ar_s),
            "actual_ts_ar_s": parseFloat(tcf.actual_ts_ar_s),
            "actual_ash_adb_s": parseFloat(tcf.actual_ash_adb_s),
            "actual_ts_adb_s": parseFloat(tcf.actual_ts_adb_s),
            "actual_aft_s": parseFloat(tcf.actual_aft_s),
            "actual_vm_s": parseFloat(tcf.actual_vm_s),
            "actual_hgi_s": parseFloat(tcf.actual_hgi_s),
            "price_basis_gar_s": parseFloat(tcf.price_basis_gar_s),
            "gar_s": parseFloat(tcf.gar_s),
            "sulfur_bonus_rate_MT_S": parseFloat(tcf.sulfur_bonus_rate_MT_S),
            "sulfur_bonus_amount_s": parseFloat(tcf.sulfur_bonus_amount_s),
            "ash_bonus_amount_s": parseFloat(tcf.ash_bonus_amount_s),
            "sulfur_penalty_rate_MT_S": parseFloat(tcf.sulfur_penalty_rate_MT_S),
            "ash_bonus_rate_MT_S": parseFloat(tcf.ash_bonus_rate_MT_S),
            "ash_penalty_rate_MT_S": parseFloat(tcf.ash_penalty_rate_MT_S),
            "sulfur_penalty_amount_s": parseFloat(tcf.sulfur_penalty_amount_s),
            "ash_penalty_amount_s": parseFloat(tcf.ash_penalty_amount_s),
            "fob_pur_price": parseFloat(tcf.fob_pur_price),
            "actual_pur_price": parseFloat(tcf.actual_pur_price),
            "fob_sales_price": parseFloat(tcf.fob_sales_price),
            "actual_sales_price": parseFloat(tcf.actual_sales_price),
            "actual_sales": parseFloat(tcf.actual_sales),
            "actual_fob_purchase": parseFloat(tcf.actual_fob_purchase),
            "final_purchase_price": parseFloat(tcf.final_purchase_price),
            "final_purchase_amount": parseFloat(tcf.final_purchase_amount),
            "final_sales_price": parseFloat(tcf.final_sales_price),
            "final_sales_amount": parseFloat(tcf.final_sales_amount),
            "actual_gross_margin_shipment": parseFloat(tcf.actual_gross_margin_shipment),
            "actual_gross_margin_ton": parseFloat(tcf.actual_gross_margin_ton),
            "actual_net_margin_shipment": parseFloat(tcf.actual_net_margin_shipment),
            "actual_net_margin_ton": parseFloat(tcf.actual_net_margin_ton),
            "actual_pur_amount": parseFloat(tcf.actual_pur_amount),
            "actual_freight_rate": parseFloat(tcf.actual_freight_rate),
            "actual_freight_amount": parseFloat(tcf.actual_freight_amount),
            "actual_insurance": parseFloat(tcf.actual_insurance),
            "actual_commission": parseFloat(tcf.actual_commission),
            "actual_lc_transfer_cost": parseFloat(tcf.actual_lc_transfer_cost),
            "actual_bill_handling_charges": parseFloat(tcf.actual_bill_handling_charges),
            "actual_adding_confirmation_charges": parseFloat(tcf.actual_adding_confirmation_charges),
            "actual_fixed_other_bank_charges": parseFloat(tcf.actual_fixed_other_bank_charges),
            "bank_interest_or_discounting": parseFloat(tcf.bank_interest_or_discounting),
            "vat_on_bank_charges": parseFloat(tcf.vat_on_bank_charges),
            "document_discount_date": tcf.document_discount_date,
            "actual_payment_received": actualCheck,
            "actual_total_bank_cost": parseFloat(tcf.actual_total_bank_cost),
            "actual_costs": parseFloat(tcf.actual_costs),
            "actual_cost_ton": parseFloat(tcf.actual_cost_ton),
            "final_cost_ton": parseFloat(tcf.final_cost_ton),
            "actual_overheads": parseFloat(tcf.actual_overheads),
            "actual_fixed_apportioned_overheads": parseFloat(tcf.actual_fixed_apportioned_overheads),
            "actual_contract_specific_cost": parseFloat(tcf.actual_contract_specific_cost),
            "actual_prov_for_corporate_tax": parseFloat(tcf.actual_prov_for_corporate_tax),
            "actual_withholding_tax": parseFloat(tcf.actual_withholding_tax),
            "final_total_cost": parseFloat(tcf.final_total_cost),
            "supplier_dem_or_despatch": tcf.supplier_dem_or_despatch,
            "supplier_dem_or_despatch_DN_amount": parseFloat(tcf.supplier_dem_or_despatch_DN_amount),
            "supplier_DN_amount": parseFloat(tcf.supplier_DN_amount),
            "buyer_dem_or_despatch": tcf.buyer_dem_or_despatch,
            "buyer_dem_or_despatch_DN_amount": parseFloat(tcf.buyer_dem_or_despatch_DN_amount),
            "buyer_dem_or_despatch": parseFloat(tcf.buyer_dem_or_despatch),
            "any_deviation": tcf.any_deviation,
            "prepared_by": tcf.prepared_by,
            "check_and_approved_by": tcf.check_and_approved_by,
            "validated_by": tcf.validated_by,
            "remarks": tcf.remarks,

        };
        setIsSubmitted(true)

        console.log(JSON.stringify(responseData))
        axios.put(`${API_URL}update/tcf/${id}`, responseData, {
            headers: {
                Authorization: `Bearer ${decryptedAccessToken}`,
                'Content-Type': 'application/json',
            },
        })
            .then((response) => {
                console.log(response)
                if (response.data.success === true) {
                    // setIsSubmitted(false)
                    const messageContainer = document.getElementById('messageContainer');

                    // Create the success message element
                    const successMessage = document.createElement('div');
                    successMessage.className = 'success-message';
                    successMessage.innerText = response.data.msg;

                    // Append the success message to the container
                    messageContainer.appendChild(successMessage);

                    // Scroll to the top
                    window.scrollTo({
                        top: 0,
                        behavior: "smooth"
                    });

                    // Hide the message after 2 seconds
                    setTimeout(() => {
                        successMessage.remove();
                    }, 5000);


                    fetchData();


                    setData(response);
                    setIsEditMode(false);
                }

                else if (response.data.success === false) {
                    const messageContainer = document.getElementById('messageContainer');

                    // Create the success message element
                    const successMessage = document.createElement('div');
                    successMessage.className = 'success-message';
                    successMessage.innerText = response.data.msg;

                    // Append the success message to the container
                    messageContainer.appendChild(successMessage);

                    // Scroll to the top
                    window.scrollTo({
                        top: 0,
                        behavior: "smooth"
                    });

                    // Hide the message after 2 seconds
                    setTimeout(() => {
                        successMessage.remove();
                    }, 5000);


                }
            })
            .catch((error) => console.error(error));
    };

    return (
        <>

            <>
                {tcf.record_type === 'Buyer - Long Term' ? (
                    <>
                        {isEditMode ? (
                            <div id="view-page">
                                <Header />
                                <div className="contactAccount">
                                    <div className="row">
                                        <div className="col-md-2 recent-item">
                                            <RecentItems />
                                        </div>
                                        <div className="col-md-10 right-section">
                                            <div className="page-header" id='add-account'>
                                                <div>
                                                    <h2 className="main-content-title tx-24 mg-b-5">TCF Details</h2>
                                                    <ol className="breadcrumb">
                                                        <li className="breadcrumb-item active" aria-current="page">Dashboard / TCF Details</li>
                                                    </ol>
                                                </div>

                                            </div>

                                        <div className="tasks column-gap fulid-section order-section">
                                            <div id="messageContainer"></div>
                                            <p className="edit-btn">
                                                <input
                                                    type="submit"
                                                    className="account-save"
                                                    onClick={handleSaveEdit}
                                                    value="Save"
                                                //disabled={isSubmitted}
                                                />
                                                <button className="btn btn-primary" onClick={handleCancelEdit}>
                                                    Cancel
                                                </button>
                                            </p>

                                            <Row className="mb-3">
                                                <h4 className="heading">Information</h4>
                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="TCF no"
                                                        className="mb-3"


                                                    >
                                                        <Form.Control

                                                            type="text"
                                                            placeholder="TCF no"
                                                            tabIndex={1}
                                                            name="tcf_no"
                                                            defaultValue={tcf.tcf_no || ""} onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6">
                                                    <label>Locked</label>
                                                    <input
                                                        type='checkbox'
                                                        name='locked'
                                                        tabIndex={10}
                                                        checked={lockedOne} // Use the state variable to determine if the checkbox is checked
                                                        onChange={handleCheckboxLocked} // Handle the change event
                                                        className='mx-3'
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} md="6">

                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="TCF date"
                                                        className="mb-3 datepick"


                                                    >
                                                        <DatePicker
                                                            selected={tcfDate}
                                                            onChange={handleTcfDate}
                                                            selectsStart
                                                            tabIndex={2}
                                                            dateFormat='dd/MM/yyyy'
                                                            placeholderText="Select"
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>
                                                {/* <Form.Group as={Col} md="6"></Form.Group> */}
                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Record type"
                                                        className="mb-3"


                                                    >
                                                        <Form.Control

                                                            type="text"
                                                            placeholder="Record type"
                                                            tabIndex={11}
                                                            name="record_type"
                                                            defaultValue={tcf.record_type || ""} onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6">

                                                    <FloatingLabel controlId="floatingSelect"
                                                        className='dropDown' label="Company">
                                                        <Form.Select aria-label="Floating label select example"
                                                            name="company"
                                                            placeholder="Company"
                                                            defaultValue={tcf.company || ""}
                                                            onChange={handleChangeInput}
                                                            tabIndex={3}
                                                        >
                                                            <option value='' >Select</option>
                                                            {
                                                                companies.map((x) => {
                                                                    return (
                                                                        <option value={x.company_name}>{x.company_name}</option>
                                                                    )
                                                                })
                                                            }
                                                        </Form.Select>

                                                    </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Opportunity"
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            defaultValue={tcf.opportunity || ""}
                                                            onChange={handleChangeInput}
                                                            type="text"
                                                            tabIndex={12}
                                                            placeholder="opportunity"
                                                            name="opportunity"
                                                            disabled
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6">

                                                    <FloatingLabel controlId="floatingSelect"
                                                        className='dropDown' label="Currency">
                                                        <Form.Select aria-label="Floating label select example"
                                                            name="currency"
                                                            placeholder="Currency"
                                                            defaultValue={tcf.currency || ""}
                                                            onChange={handleChangeInput}
                                                            tabIndex={4}
                                                        >
                                                            <option value='' >Select</option>
                                                            <option value='USD' >USD</option>
                                                            <option value='IDR' >IDR</option>

                                                        </Form.Select>

                                                    </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="quote"
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="text"
                                                            tabIndex={13}
                                                            placeholder="quote"
                                                            name="quote"
                                                            defaultValue={tcf.quote || ""}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Exchange rate"
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Exchange rate"
                                                            name="exchange_rate"
                                                            tabIndex={5}
                                                            defaultValue={tcf.exchange_rate || ""}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6">

                                                    <FloatingLabel controlId="floatingSelect"
                                                        className='dropDown' label="Trader Name">
                                                        <Form.Select aria-label="Floating label select example"
                                                            name="trader_name"
                                                            placeholder="Trader Name"
                                                            tabIndex={14}
                                                            defaultValue={tcf.trader_name || ""}
                                                            onChange={handleChangeInput}

                                                        >
                                                            <option value='' >Select</option>
                                                            {
                                                                rwoodusers.map((x) => {
                                                                    return (
                                                                        <option value={x.rwood_username}>{x.rwood_username}</option>
                                                                    )
                                                                })
                                                            }
                                                        </Form.Select>

                                                    </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="order"
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="order"
                                                            name="order"
                                                            tabIndex={6}
                                                            defaultValue={tcf.order || ""}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="owner"
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="owner"
                                                            name="owner"
                                                            tabIndex={15}
                                                            defaultValue={tcf.owner || ""}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>

                                                <Form.Group as={Col} md="6"></Form.Group>
                                                <Form.Group as={Col} md="6">
                                                    <label>Auto select pricebook</label>
                                                    <input
                                                        type='checkbox'
                                                        name='auto_select_pricebook'
                                                        checked={lockeds} // Use the state variable to determine if the checkbox is checked
                                                        onChange={handleCheckboxChange} // Handle the change event
                                                        className='mx-3'
                                                        tabIndex={16}
                                                    />
                                                </Form.Group>

                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel controlId="floatingSelect" className="dropDown" label="Rwood bank master">
                                                        <Form.Select
                                                            aria-label="Floating label select example"
                                                            name="rwood_bank_master"
                                                            placeholder="bank master"
                                                            defaultValue={tcf.rwood_bank_master || ""}
                                                            tabIndex={7}
                                                            onChange={handleChangeInput}
                                                        >
                                                            <option value="">Select</option>
                                                            {
                                                                // Ensure datas is an array and sort it alphabetically by rwood_bank_name
                                                                (Array.isArray(datas) ? datas : [])
                                                                    .sort((a, b) => a.rwood_bank_name.localeCompare(b.rwood_bank_name))
                                                                    .map((x) => (
                                                                        <option key={x.rwood_bank_name} value={x.rwood_bank_name}>
                                                                            {x.rwood_bank_name}
                                                                        </option>
                                                                    ))
                                                            }
                                                        </Form.Select>
                                                    </FloatingLabel>
                                                </Form.Group>


                                                <Form.Group as={Col} md="6">

                                                    <FloatingLabel controlId="floatingSelect"
                                                        className='dropDown' label="Price book">
                                                        <Form.Select aria-label="Floating label select example"
                                                            name="price_book"
                                                            placeholder="Price book"
                                                            defaultValue={tcf.price_book || ""}
                                                            tabIndex={17}
                                                            onChange={handleChangeInput}

                                                        >
                                                            <option value='' >Select</option>
                                                            {
                                                                priceBook.map((x) => {
                                                                    return (
                                                                        <option value={x.price_book_name}>{x.price_book_name}</option>

                                                                    )
                                                                })
                                                            }
                                                        </Form.Select>

                                                    </FloatingLabel>
                                                </Form.Group>

                                                <Form.Group as={Col} md="6">

                                                    <FloatingLabel controlId="floatingSelect"
                                                        className='dropDown' label="TCF Status">
                                                        <Form.Select aria-label="Floating label select example"
                                                            name="tcf_status"
                                                            placeholder="TCF Status"
                                                            tabIndex={8}
                                                            defaultValue={tcf.tcf_status || ""}
                                                            onChange={handleChangeInput}
                                                        >
                                                            <option value="Draft">Draft</option><option value="Proposed">Proposed</option><option value="Proposed-Long Term Contract">Proposed-Long Term Contract</option><option value="Projected">Projected</option><option value="Actual Shipment Completed">Shipment Done</option><option value="Completed">Completed</option><option value="Cancelled">Z Cancelled</option><option value="Draft - Long Term">Draft - Long Term</option><option value="Shipments - Arutmin">Shipments - Arutmin</option><option value="Cancelled with Penalty">Cancelled with Penalty</option>
                                                        </Form.Select>

                                                    </FloatingLabel>
                                                </Form.Group>

                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Purchase confirmation date"
                                                        className="mb-3 datepick"


                                                    >

                                                        <DatePicker
                                                            selected={purchaseConfirmDate}
                                                            onChange={handlePuchaseConfirm}
                                                            selectsStart
                                                            tabIndex={18}
                                                            dateFormat='dd/MM/yyyy'
                                                            placeholderText="Select"
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Buyer contract"
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            disabled
                                                            type="text"
                                                            placeholder="Buyer contract"
                                                            tabIndex={9}
                                                            name="buyer_contract"
                                                            defaultValue={tcf.buyer_contract || ""}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6"></Form.Group>

                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Contract shipment"
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="text"
                                                            disabled
                                                            
                                                            placeholder="Contract shipment"
                                                            name="contract_shipment"
                                                            defaultValue={tcf.contract_shipment || ""}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6">

                                                    <FloatingLabel controlId="floatingSelect"
                                                        className='dropDown' label="Product Master">
                                                        <Form.Select aria-label="Floating label select example"
                                                            name="product_master"
                                                            placeholder="Product Master"
                                                            defaultValue={tcf.product_master}
                                                            tabIndex={19}
                                                            onChange={handleChangeInput}
                                                        >
                                                            <option value='' >Select</option>
                                                            {
                                                                product.map((x) => {
                                                                    return (
                                                                        <option value={x.product_code}>{x.product_code}</option>
                                                                    )
                                                                })
                                                            }
                                                        </Form.Select>
                                                    </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6"> </Form.Group>

                                                <Form.Group as={Col} md="6">

                                                    <FloatingLabel controlId="floatingSelect"
                                                        className='dropDown' label="Product">
                                                        <Form.Select aria-label="Floating label select example"
                                                            name="product"
                                                            placeholder="Product"
                                                            defaultValue={tcf.product}tabIndex={20}
                                                            onChange={handleChangeInput}
                                                        >
                                                            <option value='' >Select</option>
                                                            {
                                                                productName.map((x) => {
                                                                    return (
                                                                        <option value={x.product_name}>{x.product_name}</option>
                                                                    )
                                                                })
                                                            }
                                                        </Form.Select>
                                                    </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6"> </Form.Group>
                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Product name"
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Product name"
                                                            name="product_name"
                                                            tabIndex={21}
                                                            defaultValue={tcf.product_name || ""}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>
                                            </Row>
                                            {/* <Row className="mb-3">
         <h4 className="heading">Shipment Summary</h4>
         <Form.Group as={Col} md="6">
        <FloatingLabel
          controlId="floatingInput"
          label="shipment gross margin ton"
          className="mb-3"


        >
          <Form.Control
            type="text"
            placeholder="shipment gross margin ton"
            name="shipment_gross_margin_ton"
            defaultValue={tcf.shipment_gross_margin_ton || ""}
   onChange={handleChangeInput}
   disabled
            />
        </FloatingLabel>
      </Form.Group>
      <Form.Group as={Col} md="6">
        <FloatingLabel
          controlId="floatingInput"
          label="shipment_sales_price"
          className="mb-3"


        >
          <Form.Control
            type="text"
            placeholder="shipment sales price"
            name="shipment_sales_price"
            defaultValue={tcf.shipment_sales_price || ""}
   onChange={handleChangeInput}
   disabled
            />
        </FloatingLabel>
      </Form.Group>

         </Row>
          */}
                                            <Row>
                                                <h4 className='heading'>Trade Confirmation Details</h4>
                                                <Form.Group as={Col} md="6">

                                                    <FloatingLabel controlId="floatingSelect"
                                                        className='dropDown' label="Buyer">
                                                        <Form.Select aria-label="Floating label select example"
                                                            name="buyer"
                                                            placeholder="Buyer"
                                                            tabIndex={22}
                                                            defaultValue={tcf.buyer || ""}
                                                            onChange={handleChangeInput}
                                                        >
                                                            <option value='' >Select</option>
                                                            {
                                                                buyers.map((x) => {
                                                                    return (
                                                                        <option value={x.account_name}>{x.account_name}</option>
                                                                    )
                                                                })
                                                            }
                                                        </Form.Select>
                                                    </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6">

                                                    <FloatingLabel controlId="floatingSelect"
                                                        className='dropDown' label="status of purchase contract" >
                                                        <Form.Select aria-label="Floating label select example"
                                                            name="status_of_purchase_contract"
                                                            tabIndex={33}
                                                            placeholder="status of purchase contract"
                                                            defaultValue={tcf.status_of_purchase_contract || ""}
                                                            onChange={handleChangeInput}
                                                        >
                                                            <option value="">--None--</option><option value="Draft">Draft</option><option value="Activated">Activated</option><option value="In Approval Process">In Approval Process</option><option value="Under Review">Under Review</option><option value="Signed Contract yet to receive">Signed Contract yet to receive</option><option value="Signed Contract Sent for Sign">Signed Contract Sent for Sign</option><option value="Draft Sent for Signing">Draft Sent for Signing</option><option value="Draft Received for Signing">Draft Received for Signing</option><option value="Signed Original Contract Need to Courier">Signed Original Contract Need to Courier</option><option value="Signed Original Contract yet to Receive">Signed Original Contract yet to Receive</option><option value="Original Contract Signed by Both Parties">Original Contract Signed by Both Parties</option><option value="Original Contract Signed and Received by Both Parties">Original Contract Signed and Received by Both Parties</option>
                                                        </Form.Select>
                                                    </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6">

                                                    <FloatingLabel controlId="floatingSelect"
                                                        className='dropDown' label="Supplier">
                                                        <Form.Select aria-label="Floating label select example"
                                                            name="supplier"
                                                            placeholder="Supplier"
                                                            tabIndex={23}
                                                            defaultValue={tcf.supplier || ""}
                                                            onChange={handleChangeInput}

                                                        >
                                                            <option value='' >Select</option>
                                                            {
                                                                suppliers.map((x) => {
                                                                    return (
                                                                        <option value={x.account_name}>{x.account_name}</option>
                                                                    )
                                                                })
                                                            }
                                                        </Form.Select>
                                                    </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6"></Form.Group>
                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Product Description"
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            as="textarea"
                                                            tabIndex={24}
                                                            placeholder="Product Description"
                                                            name="product_description"
                                                            defaultValue={tcf.product_description || ""}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Proposed laycan from"
                                                        className="mb-3 datepick"


                                                    >
                                                        <DatePicker
                                                            selected={proposedDates}
                                                            onChange={(date) => setProposedDates(date)}
                                                            selectsStart
                                                            dateFormat='dd/MM/yyyy'
                                                            tabIndex={34}
                                                            placeholderText="Select"
                                                            disabled={true}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="product specification"
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="product specification"
                                                            tabIndex={25}
                                                            name="product_specification"
                                                            defaultValue={tcf.product_specification || ""}
                                                            onChange={handleChangeInput}

                                                        />

                                                    </FloatingLabel>
                                                </Form.Group>


                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Proposed laycan to"
                                                        className="mb-3 datepick"


                                                    >
                                                        <DatePicker
                                                            selected={proposedDates1}
                                                            onChange={(date) => setProposedDates1(date)}
                                                            selectsStart
                                                            tabIndex={35}
                                                            dateFormat='dd/MM/yyyy'
                                                            placeholderText="Select"
                                                            disabled={true}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>

                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Quantity MT"
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Quantity MT"
                                                            name="quantity_MT"
                                                            tabIndex={26}
                                                            defaultValue={tcf.quantity_MT || ""}
                                                            onChange={handleChangeInput}

                                                        />

                                                    </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6"></Form.Group>
                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingSelect"
                                                        className="dropDown"
                                                        label="price basis"
                                                    >
                                                        <Form.Select
                                                            aria-label="Floating label select example"
                                                            name="price_basis"
                                                            tabIndex={27}
                                                            placeholder="price basis"
                                                            defaultValue={tcf.price_basis || ""}
                                                            onChange={handleChangeInput}

                                                        >
                                                            <option value="">--None--</option><option value="GCV (ARB)">GCV (ARB)</option><option value="GCV (ADB)">GCV (ADB)</option><option value="NCV (ARB)">NCV (ARB)</option></Form.Select>

                                                    </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6"></Form.Group>
                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingSelect"
                                                        className="dropDown"
                                                        label="Vessel Type"
                                                    >
                                                        <Form.Select
                                                            aria-label="Floating label select example"
                                                            name="vessel_type"
                                                            tabIndex={28}
                                                            defaultValue={tcf.vessel_type || ""}
                                                            onChange={handleChangeInput}
                                                            placeholder="Vessel Type"
                                                        >
                                                            <option value="">--None--</option><option value="Geared (GnG)">Geared (GnG)</option><option value="Gearless">Gearless</option><option value="Geared">Geared</option><option value="Barge">Barge</option>
                                                        </Form.Select>

                                                    </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6"></Form.Group>

                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Payment term"
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Payment term"
                                                            name="payment_term"
                                                            tabIndex={29}
                                                            defaultValue={tcf.payment_term || ""}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6"></Form.Group>
                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Selling terms"
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Selling terms"
                                                            name="selling_terms"
                                                            tabIndex={30}
                                                            defaultValue={tcf.selling_terms || ""}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6"></Form.Group>
                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Buying terms"
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="text"
                                                            tabIndex={31}
                                                            placeholder="Buying terms"
                                                            defaultValue={tcf.buying_terms || ""}
                                                            onChange={handleChangeInput}
                                                            name="buying_terms"
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>

                                                <Form.Group as={Col} md="6"></Form.Group>
                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Price adjustment"
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            as="textarea"
                                                            placeholder="Price adjustment"
                                                            name="price_adjustment"

                                                            tabIndex={32}
                                                            defaultValue={tcf.price_adjustment || ""}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group> <Form.Group as={Col} md="6"></Form.Group>
                                            </Row>
                                            <Row>
                                                <h4 className='heading'>Contractual</h4>

                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Contractual GAR (P)"
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Contractual GAR (P)"
                                                            name="contractual_GAR_P"
                                                            tabIndex={36}
                                                            defaultValue={tcf.contractual_GAR_P || ""}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Contractual GAR (S)"
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Contractual GAR (S)"
                                                            name="contractual_GAR_S"
                                                            tabIndex={50}
                                                            defaultValue={tcf.contractual_GAR_S || ""}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Contractual TM (AR) (P)"
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Contractual TM (AR) (P)"
                                                            tabIndex={37}
                                                            name="contractual_TM_AR_P"
                                                            defaultValue={tcf.contractual_TM_AR_P || ""}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Contractual TM (AR) (S)"
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Contractual TM (AR) (S)"
                                                            name="contractual_TM_AR_S"
                                                            tabIndex={51}
                                                            defaultValue={tcf.contractual_TM_AR_S || ""}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Contractual TS (AR) (P)"
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Contractual TS (AR) (P)"
                                                            tabIndex={38}
                                                            name="contractual_Ts_AR_p"
                                                            defaultValue={tcf.contractual_Ts_AR_p || ""}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Contractual TS (AR) (S)"
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Contractual TS (AR) (S)"
                                                            name="contractual_Ts_AR_s"
                                                            tabIndex={52}
                                                            defaultValue={tcf.contractual_Ts_AR_s || ""}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>

                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Contractual Ash (AR) (P)"
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Contractual Ash (AR) (P)"
                                                            name="contractual_Ash_AR_P"
                                                            tabIndex={39}
                                                            defaultValue={tcf.contractual_Ash_AR_P || ""}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>

                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Contractual Ash (AR) (S)"
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Contractual Ash (AR) (S)"
                                                            name="contractual_Ash_AR_S"
                                                            tabIndex={53}
                                                            defaultValue={tcf.contractual_Ash_AR_S || ""}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Contractual TS (ADB) (P)"
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Contractual  TS (ADB) (P)"
                                                            name="contractual_Ts_ADB_P"
                                                            tabIndex={40}
                                                            defaultValue={tcf.contractual_Ts_ADB_P || ""}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Contractual TS (ADB) (S)"
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Contractual TS (ADB) (S)"
                                                            name="contractual_Ts_ADB_S"
                                                            tabIndex={54}
                                                            defaultValue={tcf.contractual_Ts_ADB_S || ""}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Contractual Ash (ADB) (P)"
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Contractual Ash (ADB) (P)"
                                                            name="contractual_Ash_ADB_P"
                                                            tabIndex={41}
                                                            defaultValue={tcf.contractual_Ash_ADB_P || ""}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Contractual Ash (ADB) (S)"
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Contractual Ash (ADB) (S)"
                                                            name="contractual_Ash_ADB_S"
                                                            tabIndex={55}
                                                            defaultValue={tcf.contractual_Ash_ADB_S || ""}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>


                                                <Form.Group as={Col} md="6" className="spaces">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Projected price basis GAR (P)"
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Projected price basis GAR (P)"
                                                            tabIndex={42}
                                                            name="projected_price_basis_GAR_P"
                                                            defaultValue={tcf.projected_price_basis_GAR_P || ""}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Projected price basis GAR (S)"
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Projected price basis GAR (S)"
                                                            name="projected_price_basis_GAR_S"
                                                            tabIndex={56}
                                                            defaultValue={tcf.projected_price_basis_GAR_S || ""}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6">

                                                    <FloatingLabel controlId="floatingSelect"
                                                        className='dropDown' label="contractual HBA year">
                                                        <Form.Select aria-label="Floating label select example"
                                                            name="contractual_HBA_year"
                                                            placeholder="contractual HBA year"
                                                            defaultValue={tcf.contractual_HBA_year}
                                                            tabIndex={43}
                                                            onChange={handleChangeInput}
                                                        >
                                                            <option value="">--None--</option>
                                                            <option value="2024">2024</option>
                                                            <option value="2025">2025</option>
                                                            <option value="2026">2026</option>
                                                            <option value="2027">2027</option>
                                                            <option value="2023">2023</option>
                                                            <option value="2022">2022</option>
                                                            <option value="2021">2021</option>

                                                        </Form.Select>
                                                    </FloatingLabel>
                                                </Form.Group>

                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Discounted price"
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Discounted price"
                                                            tabIndex={57}
                                                            name="discounted_price"
                                                            defaultValue={tcf.discounted_price || ""}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6">

                                                    <FloatingLabel controlId="floatingSelect"
                                                        className='dropDown' label="contractual HBA Month">
                                                        <Form.Select aria-label="Floating label select example"
                                                            name="contractual_HBA_month"
                                                            placeholder="contractual HBA Month"
                                                            tabIndex={44}
                                                            defaultValue={tcf.contractual_HBA_month}
                                                            onChange={handleChangeInput}
                                                        >
                                                            <option value="">--None--</option><option value="January">January</option><option value="February">February</option><option value="March">March</option><option value="April">April</option><option value="May">May</option><option value="June">June</option><option value="July">July</option><option value="August">August</option><option value="September">September</option><option value="October">October</option><option value="November">November</option><option value="December">December</option>
                                                        </Form.Select>
                                                    </FloatingLabel>
                                                </Form.Group>

                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Projected index value"
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Projected index value"
                                                            name="projected_index_value"
                                                            tabIndex={58}
                                                            defaultValue={tcf.projected_index_value || ""}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>

                                                <Form.Group as={Col} md="6" className="spaces">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Projected HBA"
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="projected HBA"
                                                            name="projected_hba"
                                                            tabIndex={45}
                                                            defaultValue={tcf.projected_hba || ""}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6"></Form.Group>
                                                <Form.Group as={Col} md="6"></Form.Group>
                                                <Form.Group as={Col} md="6">

                                                    <FloatingLabel controlId="floatingSelect"
                                                        className='dropDown' label="Projected Sale Price Type">
                                                        <Form.Select aria-label="Floating label select example"
                                                            name="projected_sale_price_type"
                                                            placeholder="Projected Sale Price Type"
                                                            tabIndex={59}
                                                            defaultValue={tcf.projected_sale_price_type || ""}
                                                            onChange={handleChangeInput}

                                                        >
                                                            <option value='' >Select</option>
                                                            {
                                                                price.map((x) => {
                                                                    return (
                                                                        <option value={x.price_type_name}>{x.price_type_name}</option>
                                                                    )
                                                                })
                                                            }
                                                        </Form.Select>
                                                    </FloatingLabel>
                                                </Form.Group>

                                                <Form.Group as={Col} md="6">

                                                    <FloatingLabel controlId="floatingSelect"
                                                        className='dropDown' label="Projected Purchase Price Type">
                                                        <Form.Select aria-label="Floating label select example"
                                                            name="projected_purchase_type"
                                                            placeholder="Projected Purchase Price Type"
                                                            defaultValue={tcf.projected_purchase_type || ""}
                                                            tabIndex={46}
                                                            onChange={handleChangeInput}

                                                        >
                                                            <option value='' >Select</option>
                                                            {
                                                                price.map((x) => {
                                                                    return (
                                                                        <option value={x.price_type_name}>{x.price_type_name}</option>
                                                                    )
                                                                })
                                                            }
                                                        </Form.Select>
                                                    </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Projected fixed sales price"
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Projected fixed sales price"
                                                            tabIndex={60}
                                                            name="projected_fixed_sales_price"
                                                            defaultValue={tcf.projected_fixed_sales_price || ""}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Projected fixed purchase price"
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Projected fixed purchase price"
                                                            tabIndex={47}
                                                            name="projected_fixed_purchase_price"
                                                            defaultValue={tcf.projected_fixed_purchase_price || ""}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>

                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Projected sales price margin"
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Projected sales price margin"
                                                            tabIndex={61}
                                                            name="projected_sales_price_margin"
                                                            defaultValue={tcf.projected_sales_price_margin || ""}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>

                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Projected purchase price margin"
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="number"
                                                            placeholder="Projected purchase price margin"
                                                            name="projected_purchase_price_margin"
                                                            tabIndex={48}
                                                            defaultValue={tcf.projected_purchase_price_margin || ""}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>

                                                <Form.Group as={Col} md="6"></Form.Group>



                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Projected barge discount"
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="text"
                                                            tabIndex={49}
                                                            placeholder="Projected barge discount"
                                                            name="projected_barge_discount"
                                                            defaultValue={tcf.projected_barge_discount || ""}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>
                                            </Row>
                                            <Row>
                                                <h4 className='heading'>Cost Sheet Projection</h4>
                                                {/* <Form.Group as={Col} md="6">
        <FloatingLabel
          controlId="floatingInput"
          label="Projected Pur Amount"
          className="mb-3"


        >
          <Form.Control
            type="text"
            placeholder="Projected Pur Amount"
            name="projected_pur_amount"
            defaultValue={tcf.projected_pur_amount || ""} disabled
            onChange={handleChangeInput}
           />
        </FloatingLabel>
      </Form.Group>
      <Form.Group as={Col} md="6">
        <FloatingLabel
          controlId="floatingInput"
          label="projected sales"
          className="mb-3"


        >
          <Form.Control
            type="text"
            placeholder="projected sales"
            name="projected_sales"
            defaultValue={tcf.projected_sales || ""} disabled
            onChange={handleChangeInput}
           />
        </FloatingLabel>
      </Form.Group>  */}
                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Projected Freight Rate "
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Projected Freight Rate "
                                                            name="projected_freight_rate"
                                                            defaultValue={tcf.projected_freight_rate||""}
                                                            onChange={handleChangeInput}
                                                            
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6"></Form.Group>
                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Projected Freight Amount "
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Projected Freight Amount "
                                                            name="projected_freight_amount"
                                                            tabIndex={63}
                                                            defaultValue={tcf.projected_freight_amount || ""}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6"></Form.Group>
                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Projected Insurance"
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Projected Insurance"
                                                            name="projected_insurance"
                                                            tabIndex={64}
                                                            defaultValue={tcf.projected_insurance || ""}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6"></Form.Group>
                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Projected Commission"
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Projected Commission"
                                                            tabIndex={65}
                                                            name="projected_commission"
                                                            defaultValue={tcf.projected_commission || ""}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6"></Form.Group>
                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Projected Fixed Apportioned Overheads  "
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Projected Fixed Apportioned Overheads"
                                                            name="projected_fixed_apportioned_overheads"
                                                            tabIndex={66}
                                                            defaultValue={tcf.projected_fixed_apportioned_overheads || ""}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6"></Form.Group>
                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Projected Any Contract Specific Cost "
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Projected Any Contract Specific Cost"
                                                            name="projected_any_contract_specific_cost"
                                                            tabIndex={67} 
                                                            defaultValue={tcf.projected_any_contract_specific_cost || ""}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6"></Form.Group>
                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Projected Extra Cost"
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Projected Extra Cost"
                                                            tabIndex={68}
                                                            name="projected_extra_cost"
                                                            defaultValue={tcf.projected_extra_cost || ""}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6"></Form.Group>

                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Projected Provision For Corporate Tax"
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Projected Provision For Corporate Tax"
                                                            tabIndex={69}
                                                            name="projected_provision_for_corporate_tax"
                                                            defaultValue={tcf.projected_provision_for_corporate_tax || ""}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>

                                            </Row>
                                            {/* <Row className="mb-3">
         <h4 className="heading">Actual Shipment Details</h4>
         <Form.Group as={Col} md="6">
        <FloatingLabel
          controlId="floatingInput"
          label="Vessel Name"
          className="mb-3"


        >
          <Form.Control
            type="text"
            placeholder="Vessel Name"
            name="vessel_name"
            defaultValue={tcf.vessel_name || ""}
            onChange={handleChangeInput}
            disabled
           />
        </FloatingLabel>
      </Form.Group>
         <Form.Group as={Col} md="6">
        <FloatingLabel
          controlId="floatingInput"
          label="Demmurage Rate (P)"
          className="mb-3"


        >
          <Form.Control
            type="text"
            placeholder="Demmurage Rate (P)"
            name="demmurage_rate_p"
            defaultValue={tcf.demmurage_rate_p || ""}
            onChange={handleChangeInput}
            disabled
           />
        </FloatingLabel>
      </Form.Group>
         <Form.Group as={Col} md="6">
        <FloatingLabel
          controlId="floatingInput"
          label="Stowage Quantity"
          className="mb-3"


        >
          <Form.Control
            type="text"
            placeholder="Stowage Quantity"
            name="stowage_qty"
            defaultValue={tcf.stowage_qty || ""}
            onChange={handleChangeInput}
            disabled
           />
        </FloatingLabel>
      </Form.Group>
         <Form.Group as={Col} md="6">
        <FloatingLabel
          controlId="floatingInput"
          label="Demmurage Rate (S)"
          className="mb-3"


        >
          <Form.Control
            type="text"
            placeholder="Demmurage Rate (S)"
            name="demmurage_rate_s"
            defaultValue={tcf.demmurage_rate_s || ""}
            onChange={handleChangeInput}
            disabled
           />
        </FloatingLabel>
      </Form.Group>
         <Form.Group as={Col} md="6">
        <FloatingLabel
          controlId="floatingInput"
          label="Destination"
          className="mb-3"


        >
          <Form.Control
            type="text"
            placeholder="Destination"
            name="destination"
            defaultValue={tcf.destination || ""}
            onChange={handleChangeInput}
            disabled
           />
        </FloatingLabel>
      </Form.Group>
         <Form.Group as={Col} md="6">
        <FloatingLabel
          controlId="floatingInput"
          label="Load Rate"
          className="mb-3"


        >
          <Form.Control
            type="text"
            placeholder="Load Rate"
            name="load_rate"
            defaultValue={tcf.load_rate || ""}
            onChange={handleChangeInput}
            disabled
           />
        </FloatingLabel>
      </Form.Group>
         <Form.Group as={Col} md="6">
        <FloatingLabel
          controlId="floatingInput"
          label="Actual BL Date"
          className="mb-3"


        >
          <Form.Control
            type="text"
            placeholder="Actual BL Date"
            name="actual_bl_date"
            defaultValue={tcf.actual_bl_date || ""}
            onChange={handleChangeInput}
            disabled
           />
        </FloatingLabel>
      </Form.Group>
         <Form.Group as={Col} md="6">
        <FloatingLabel
          controlId="floatingInput"
          label="PActual BL Qty"
          className="mb-3"


        >
          <Form.Control
            type="text"
            placeholder="Actual BL Qty"
            name="actual_bl_qty"
            defaultValue={tcf.actual_bl_qty || ""}
            onChange={handleChangeInput}
            disabled
           />
        </FloatingLabel>
      </Form.Group>
         <Form.Group as={Col} md="6">
        <FloatingLabel
          controlId="floatingInput"
          label="GCNEWC"
          className="mb-3"


        >
          <Form.Control
            type="text"
            placeholder="GCNEWC"
            name="gcnewc"
            defaultValue={tcf.gcnewc || ""}
            onChange={handleChangeInput}
            disabled
           />
        </FloatingLabel>
      </Form.Group>
         <Form.Group as={Col} md="6">
        <FloatingLabel
          controlId="floatingInput"
          label="Actual HBA"
          className="mb-3"


        >
          <Form.Control
            type="text"
            placeholder="Actual HBA"
            name="actual_hba"
            defaultValue={tcf.actual_hba || ""}
            onChange={handleChangeInput} disabled
           />
        </FloatingLabel>
      </Form.Group>
         <Form.Group as={Col} md="6">
        <FloatingLabel
          controlId="floatingInput"
          label="Actual GCNEWC - Sales"
          className="mb-3"


        >
          <Form.Control
            type="text"
            placeholder="Actual GCNEWC - Sales"
            name="actual_gcnewc_sales"
            defaultValue={tcf.actual_gcnewc_sales || ""}
            onChange={handleChangeInput} disabled
           />
        </FloatingLabel>
      </Form.Group>
         <Form.Group as={Col} md="6">
        <FloatingLabel
          controlId="floatingInput"
          label="Actual HBA - Purchase"
          className="mb-3"


        >
          <Form.Control
            type="text"
            placeholder="Actual HBA - Purchase"
            name="actual_hba_purchase"
            defaultValue={tcf.actual_hba_purchase || ""}
            onChange={handleChangeInput} disabled
           />
        </FloatingLabel>
      </Form.Group>
         <Form.Group as={Col} md="6">
        <FloatingLabel
          controlId="floatingInput"
          label="Laycan"
          className="mb-3"


        >
          <Form.Control
            type="text"
            placeholder="Laycan"
            name="laycan"
            defaultValue={tcf.laycan || ""}
            onChange={handleChangeInput} disabled
           />
        </FloatingLabel>
      </Form.Group>
         <Form.Group as={Col} md="6">
        <FloatingLabel
          controlId="floatingInput"
          label="Expected Value Date"
          className="mb-3"


        >
          <Form.Control
            type="text"
            placeholder="Expected Value Date"
            name="expected_value_date"
            defaultValue={tcf.expected_value_date || ""}
            onChange={handleChangeInput} disabled
           />
        </FloatingLabel>
      </Form.Group>
         </Row> */}
                                            <Row>
                                                <h4 className='heading'>Actual Cost Elements</h4>

                                                <Form.Group as={Col} md="6">

                                                    <FloatingLabel controlId="floatingSelect"
                                                        className='dropDown' label="Purchase Price Type">
                                                        <Form.Select aria-label="Floating label select example"
                                                            name="purchase_price_type"
                                                            tabIndex={70}
                                                            placeholder="Purchase Price Type"
                                                            defaultValue={tcf.purchase_price_type || ""}
                                                            onChange={handleChangeInput}
                                                        >
                                                            <option value='' >Select</option>
                                                            {
                                                                price.map((x) => {
                                                                    return (
                                                                        <option value={x.price_type_name}>{x.price_type_name}</option>
                                                                    )
                                                                })
                                                            }
                                                        </Form.Select>

                                                    </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6">
                                                    <label>Actual Data Rcvd	</label>
                                                    <input
                                                        type='checkbox'
                                                        name='actual_data_rcvd'
                                                        checked={dataReceived} // Use th e state variable to determine if the checkbox is checked
                                                        onChange={handleData} // Handle the change event
                                                        tabIndex={85}
                                                        className='mx-3'
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Fixed purchase price"
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Fixed purchase price"
                                                            name="fixed_purchase_price"
                                                            tabIndex={71}
                                                            defaultValue={tcf.fixed_purchase_price || ""}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>

                                                <Form.Group as={Col} md="6">

                                                    <FloatingLabel controlId="floatingSelect"
                                                        className='dropDown' label="Sale Price Type">
                                                        <Form.Select aria-label="Floating label select example"
                                                            name="sale_price_type"
                                                            placeholder="Sale Price Type"
                                                            tabIndex={86}
                                                            defaultValue={tcf.sale_price_type || ""}
                                                            onChange={handleChangeInput}

                                                        >
                                                            <option value='' >Select</option>
                                                            {
                                                                price.map((x) => {
                                                                    return (
                                                                        <option value={x.price_type_name}>{x.price_type_name}</option>
                                                                    )
                                                                })
                                                            }
                                                        </Form.Select>

                                                    </FloatingLabel>
                                                </Form.Group>

                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Purchase price margin"
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Purchase price margin"
                                                            name="purchase_price_margin"
                                                            tabIndex={72}
                                                            defaultValue={tcf.purchase_price_margin || ""}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>

                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Fixed sale price"
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Fixed sale price"
                                                            name="fixed_sale_price"
                                                            tabIndex={87}
                                                            defaultValue={tcf.fixed_sale_price || ""}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Barge discount"
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Barge discount"
                                                            name="barge_discount"
                                                            tabIndex={73}
                                                            defaultValue={tcf.barge_discount || ""}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Sales Price Margin "
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Sales Price Margin "
                                                            name="sale_price_margin"
                                                            tabIndex={88}
                                                            defaultValue={tcf.sale_price_margin || ""}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Actual GAR (P)"
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="number"
                                                            placeholder="Actual GAR (P)"
                                                            name="actual_gar_p"
                                                            tabIndex={74}
                                                            defaultValue={tcf.actual_gar_p || ""}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Actual GAR (S)"
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="number"
                                                            placeholder="Actual GAR (S)"
                                                            name="actual_gar_s"
                                                            tabIndex={89}
                                                            defaultValue={tcf.actual_gar_s || ""}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Actual TM (AR) (P) "
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="number"
                                                            placeholder="Actual TM (AR) (P) "
                                                            name="actual_tm_p"
                                                            tabIndex={75}
                                                            defaultValue={tcf.actual_tm_p || ""}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Actual TM (AR) (S) "
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="number"
                                                            placeholder="Actual TM (AR) (S) "
                                                            name="actual_tm_s"
                                                            tabIndex={90}
                                                            defaultValue={tcf.actual_tm_s || ""}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Actual Ash (AR) (P)"
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="number"
                                                            placeholder="Actual Ash (AR) (P)"
                                                            name="actual_ash_ar_p"
                                                            tabIndex={76}
                                                            defaultValue={tcf.actual_ash_ar_p || ""}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Actual Ash (AR) (S)"
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="number"
                                                            placeholder="Actual Ash (AR) (S)"
                                                            name="actual_ash_ar_s"
                                                            tabIndex={91}
                                                            defaultValue={tcf.actual_ash_ar_s || ""}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>

                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Actual TS (AR) (P) "
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="number"
                                                            placeholder="Actual TS (AR) (P) "
                                                            name="actual_ts_ar_p"
                                                            tabIndex={77}
                                                            defaultValue={tcf.actual_ts_ar_p || ""}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>

                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Actual TS (AR) (S) "
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="number"
                                                            placeholder="Actual TS (AR) (S) "
                                                            name="actual_ts_ar_s"
                                                            tabIndex={92}
                                                            defaultValue={tcf.actual_ts_ar_s || ""}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>

                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Actual Ash (ADB) (P) "
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="number"
                                                            placeholder="Actual Ash (ADB) (P) "
                                                            tabIndex={78}
                                                            name="actual_ash_adb_p"
                                                            defaultValue={tcf.actual_ash_adb_p || ""}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>

                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Actual Ash (ADB) (S) "
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="number"
                                                            placeholder="Actual Ash (ADB) (S) "
                                                            tabIndex={93}
                                                            name="actual_ash_adb_s"
                                                            defaultValue={tcf.actual_ash_adb_s || ""}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>

                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Actual TS (ADB) (P)"
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="number"
                                                            placeholder="Actual TS (ADB) (P)"
                                                            name="actual_ts_adb_p"
                                                            defaultValue={tcf.actual_ts_adb_p || ""}
                                                            tabIndex={79}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Actual TS (ADB) (S)"
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="number"
                                                            placeholder="Actual TS (ADB) (S)"
                                                            tabIndex={94}
                                                            name="actual_ts_adb_s"
                                                            defaultValue={tcf.actual_ts_adb_s || ""}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Actual AFT (P) "
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="number"
                                                            placeholder="Actual AFT (P) "
                                                            name="actual_aft_p"
                                                            tabIndex={80}
                                                            defaultValue={tcf.actual_aft_p || ""}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Actual AFT (S) "
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="number"
                                                            placeholder="Actual AFT (S) "
                                                            tabIndex={95}
                                                            name="actual_aft_s"
                                                            defaultValue={tcf.actual_aft_s || ""}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>

                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Actual VM (P)  "
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="number"
                                                            placeholder="Actual VM (P)  "
                                                            name="actual_vm_p"
                                                            tabIndex={81}
                                                            defaultValue={tcf.actual_vm_p || ""}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Actual VM (S)  "
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="number"
                                                            placeholder="Actual VM (S)  "
                                                            name="actual_vm_s"
                                                            tabIndex={96}
                                                            defaultValue={tcf.actual_vm_s || ""}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Actual HGI (P) "
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="number"
                                                            placeholder="Actual HGI (P) "
                                                            name="actual_hgi_p"
                                                            tabIndex={82}
                                                            defaultValue={tcf.actual_hgi_p || ""}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Actual HGI (S) "
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="number"
                                                            placeholder="Actual HGI (S) "
                                                            name="actual_hgi_s"
                                                            tabIndex={97}
                                                            defaultValue={tcf.actual_hgi_s || ""}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Price Basis GAR (P)  "
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="number"
                                                            placeholder="Price Basis GAR (P)  "
                                                            tabIndex={83}
                                                            name="price_basis_gar_p"
                                                            defaultValue={tcf.price_basis_gar_p || ""}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Price Basis GAR (S)  "
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="number"
                                                            placeholder="Price Basis GAR (S)  "
                                                            name="price_basis_gar_s"
                                                            tabIndex={98}
                                                            defaultValue={tcf.price_basis_gar_s || ""}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>

                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="GAR (P)"
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="number"
                                                            placeholder="GAR (P)"
                                                            name="gar_p"
                                                            tabIndex={84}
                                                            defaultValue={tcf.gar_p || ""}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="GAR (S)"
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="number"
                                                            placeholder="GAR (S)"
                                                            name="gar_s"
                                                            tabIndex={99}
                                                            defaultValue={tcf.gar_s || ""}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>

                                            </Row>
                                            <Row>
                                                <h4 className='heading'>Bonus / Penalty</h4>
                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Sulfur Bonus Rate (MT) (S)"
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Sulfur Bonus Rate (MT) (S)"
                                                            tabIndex={100}
                                                            name="sulfur_bonus_rate_MT_S"
                                                            defaultValue={tcf.sulfur_bonus_rate_MT_S || ""}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>

                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Ash Bonus Rate (MT) (S)"
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Ash Bonus Rate (MT) (S)"
                                                            name="ash_bonus_rate_MT_S"
                                                            tabIndex={102}
                                                            defaultValue={tcf.ash_bonus_rate_MT_S || ""}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Sulfur Penalty Rate (MT) (S)"
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="text"
                                                            tabIndex={101}
                                                            placeholder="Sulfur Penalty Rate (MT) (S)"
                                                            name="sulfur_penalty_rate_MT_S"
                                                            defaultValue={tcf.sulfur_penalty_rate_MT_S || ""}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>

                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Ash Penalty Rate (MT) (S)  "
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="text"
                                                            tabIndex={103}
                                                            placeholder="Ash Penalty Rate (MT) (S)"
                                                            name="ash_penalty_rate_MT_S"
                                                            defaultValue={tcf.ash_penalty_rate_MT_S || ""}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>
                                            </Row>
                                            <Row>
                                                <h4 className='heading'>Cost Sheet Actual</h4>
                                                {/* <Form.Group as={Col} md="6">
        <FloatingLabel
          controlId="floatingInput"
          label="Actual Pur Amount"
          className="mb-3"


        >
          <Form.Control
            type="number"
            placeholder="Actual Pur Amount"
            name="actual_pur_amount" defaultValue={tcf.actual_pur_amount || ""}
            onChange={handleChangeInput}
           />
        </FloatingLabel>
      </Form.Group>   */}
                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Actual Freight Rate"
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="number"
                                                            placeholder="Actual Freight Rate"
                                                            tabIndex={104}
                                                            name="actual_freight_rate" defaultValue={tcf.actual_freight_rate || ""}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>

                                                <Form.Group as={Col} md="6"></Form.Group>

                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Actual Freight Amount"
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="number"
                                                            tabIndex={105}
                                                            placeholder="Actual Freight Amount"
                                                            name="actual_freight_amount" defaultValue={tcf.actual_freight_amount || ""}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6"></Form.Group>

                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Actual Insurance"
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="number"
                                                            tabIndex={106}
                                                            placeholder="Actual Insurance"
                                                            name="actual_insurance" defaultValue={tcf.actual_insurance || ""}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6"></Form.Group>
                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Actual Commission"
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="number"
                                                            tabIndex={107}
                                                            placeholder="Actual Commission"
                                                            name="actual_commission" defaultValue={tcf.actual_commission || ""}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6"></Form.Group>
                                            </Row>
                                            <Row>
                                                <h4 className='heading'>Shipment Cost</h4>
                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Actual Fixed Apportioned Overheads"
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="number"
                                                            tabIndex={108}
                                                            placeholder="Actual Fixed Apportioned Overheads"
                                                            name="actual_fixed_apportioned_overheads"
                                                            defaultValue={renderShipmentQty(tcf.actual_fixed_apportioned_overheads)}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6"></Form.Group>
                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Actual Contract Specific Cost"
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="number"
                                                            tabIndex={109}
                                                            placeholder="Actual Contract Specific Cost"
                                                            name="actual_contract_specific_cost"
                                                            defaultValue={tcf.actual_contract_specific_cost || ""}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>
                                                {/* <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Final Total Cost "
            className="mb-3"


          >
            <Form.Control
              type="number"
              placeholder="Final Total Cost "
              name="final_total_cost"
              defaultValue={tcf.final_total_cost || ""}
              onChange={handleChangeInput}
             />
          </FloatingLabel>
        </Form.Group>  */}
                                                <Form.Group as={Col} md="6"></Form.Group>
                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Actual Prov For Corporate Tax"
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="number"
                                                            tabIndex={110}
                                                            placeholder="Actual Prov For Corporate Tax"
                                                            name="actual_prov_for_corporate_tax"
                                                            defaultValue={tcf.actual_prov_for_corporate_tax || ""}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6"></Form.Group>
                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label=" Actual Withholding Tax"
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="number"
                                                            placeholder=" Actual Withholding Tax"
                                                            name="actual_withholding_tax"
                                                            tabIndex={111}
                                                            defaultValue={tcf.actual_withholding_tax || ""}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>

                                            </Row>


                                            {/* <Row>
      <h4 className='heading'>Debit Note Details</h4>

      <Form.Group as={Col} md="6">
        <FloatingLabel
          controlId="floatingInput"
          label="Supplier Dem/Despatch"
          className="mb-3"


        >
          <Form.Control
            type="number"
            placeholder="Supplier Dem/Despatch"
            name="supplier_dem_or_despatch"
           />
        </FloatingLabel>
      </Form.Group>
      <Form.Group as={Col} md="6">
        <FloatingLabel
          controlId="floatingInput"
          label="Buyer Dem/Despatch"
          className="mb-3"


        >
          <Form.Control
            type="number"
            placeholder="Buyer Dem/Despatch"
            name="buyer_dem_or_despatch"
           />
        </FloatingLabel>
      </Form.Group>
      <Form.Group as={Col} md="6">
        <FloatingLabel
          controlId="floatingInput"
          label="Supplier Dem/Despatch DN Amount"
          className="mb-3"


        >
          <Form.Control
            type="number"
            placeholder="Supplier Dem/Despatch DN Amount"
            name="supplier_dem_or_despatch_DN_amount"
           />
        </FloatingLabel>
      </Form.Group>
      <Form.Group as={Col} md="6">
        <FloatingLabel
          controlId="floatingInput"
          label="Buyer Dem/Despatch DN Amount"
          className="mb-3"


        >
          <Form.Control
            type="number"
            placeholder="Buyer Dem/Despatch DN Amount"
            name="buyer_dem_or_despatch_DN_amount"
           />
        </FloatingLabel>
      </Form.Group>   <Form.Group as={Col} md="6">
        <FloatingLabel
          controlId="floatingInput"
          label="Supplier DN Amount"
          className="mb-3"


        >
          <Form.Control
            type="number"
            placeholder="Supplier DN Amount"
            name="supplier_DN_amount"
           />
        </FloatingLabel>
      </Form.Group>
      <Form.Group as={Col} md="6">
        <FloatingLabel
          controlId="floatingInput"
          label="Supplier DN Amount"
          className="mb-3"


        >
          <Form.Control
            type="number"
            placeholder="Supplier DN Amount"
            name="supplier_DN_amount"
           />
        </FloatingLabel>
      </Form.Group>    <Form.Group as={Col} md="6">
        <FloatingLabel
          controlId="floatingInput"
          label="Buyer DN Amount"
          className="mb-3"


        >
          <Form.Control
            type="number"
            placeholder="Buyer DN Amount"
            name="buyer_DN_amount"
           />
        </FloatingLabel>
      </Form.Group>
     </Row> */}
                                            <Row>
                                                <h4 className='heading'>Rwood Internal Information
                                                </h4>
                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="any deviation"
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="text"
                                                            tabIndex={112}
                                                            placeholder="any deviation"
                                                            name="any_deviation" defaultValue={tcf.any_deviation} onChange={handleChangeInput}

                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6"></Form.Group>
                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="prepared by"
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="text"
                                                            tabIndex={113}
                                                            placeholder="prepared by"
                                                            name="prepared_by" defaultValue={tcf.prepared_by} onChange={handleChangeInput}

                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6"></Form.Group>
                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Approved By	"
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Approved By	"
                                                            tabIndex={114}
                                                            name="check_and_approved_by" defaultValue={tcf.check_and_approved_by} onChange={handleChangeInput}

                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6"></Form.Group>
                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="Check and Validated By"
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Check and Validated By"
                                                            tabIndex={115}
                                                            name="validated_by" defaultValue={tcf.validated_by} onChange={handleChangeInput}
                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6"></Form.Group>
                                                <Form.Group as={Col} md="6">
                                                    <FloatingLabel
                                                        controlId="floatingInput"
                                                        label="remarks"
                                                        className="mb-3"


                                                    >
                                                        <Form.Control
                                                            type="text"
                                                            tabIndex={116}
                                                            placeholder="remarks"
                                                            name="remarks" defaultValue={tcf.remarks} onChange={handleChangeInput}

                                                        />
                                                    </FloatingLabel>
                                                </Form.Group>
                                            </Row>
                                            <p className="edit-btn">
                                                <button className="btn btn-primary" onClick={handleSaveEdit}>
                                                    Save
                                                </button>
                                                <button className="btn btn-primary" onClick={handleCancelEdit}>
                                                    Cancel
                                                </button>
                                            </p>
                                        </div></div></div></div>
                                <Footer />
                            </div>
                        ) : (
                            <div id="view-page">
                                <Header />
                                <div className="contactAccount">
                                    <div className="row">
                                        <div className="col-md-2 recent-item">
                                            <RecentItems />
                                        </div>
                                        <div className="col-md-10 right-section">
                                            <div className="page-header" id="add-account">
                                                <div>
                                                    <h2 className="main-content-title tx-24 mg-b-5">
                                                        TCF Details
                                                    </h2>
                                                    <ol className="breadcrumb">
                                                        <li className="breadcrumb-item active" aria-current="page">
                                                            Dashboard / TCF / TCF Details
                                                        </li>
                                                    </ol>
                                                </div>
                                                <div className="d-flex opd">

                                                </div>
                                            </div>
                                            <div className="tasks column-gap fulid-section order-section tcf-section">
                                                <div className="edit-delete">
                                                    <span id="edit" onClick={handleEditClick}>
                                                        Edit
                                                    </span>
                                                    <span>
                                                        <Link to={'/tcf/create-view-list'}> Cancel</Link>
                                                    </span>
                                                    <span>
                                                        Sales Report
                                                    </span>
                                                    <span onClick={tradeConrfirm}>
                                                        Trade Confirmation Sheet
                                                    </span>

                                                </div>
                                                <div className="col-md-12" id="ax.account_details">
                                                    <h4 className="heading">Information</h4>
                                                    <hr></hr>
                                                </div>
                                                <table class="table table-bordered account-table tables">
                                                    <tbody>
                                                        <tr>
                                                            <td id="td-right">
                                                                <span>TCF No</span>
                                                            </td><td>
                                                                {tcf.tcf_no}
                                                            </td>
                                                            <td id="td-right">
                                                                <span>Locked</span>
                                                            </td><td>
                                                                <input
                                                                    type='checkbox'
                                                                    name='locked'
                                                                    checked={lockedOne} // Use the state variable to determine if the checkbox is checked
                                                                    onChange={handleCheckboxLocked} // Handle the change event
                                                                    className='mx-3' disabled={isButtonEnabled}
                                                                />
                                                            </td>

                                                        </tr>
                                                        <tr>
                                                            <td id="td-right">
                                                                <span>TCF Date </span>
                                                            </td><td>
                                                                {tcf.tcf_date}
                                                            </td>
                                                            <td id="td-right">
                                                                <span>Record Type</span>
                                                            </td><td>
                                                                {tcf.record_type}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td id="td-right">
                                                                Company
                                                            </td><td>
                                                                <Link to={`/company/company-details/${tcf.rwoodcompany_id}`}><span>{tcf.company} </span></Link>
                                                            </td>
                                                            <td id="td-right">
                                                                <span>opportunity </span>
                                                            </td><td>
                                                                <Link to={`/opportunity/opportunity-detail/${tcf.opportunity_id}`}>{tcf.opportunity}</Link>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td id="td-right">
                                                                <span>Currency</span>
                                                            </td><td>
                                                                {tcf.currency}
                                                            </td>
                                                            <td id="td-right">
                                                                <span>Quote</span>
                                                            </td><td>
                                                                <Link to={`/opportunity/quote-view/${tcf.offer_id}`}>{tcf.quote}</Link>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td id="td-right">
                                                                <span>Exchange Rate </span>
                                                            </td><td>
                                                                {tcf.exchange_rate}
                                                            </td>
                                                            <td id="td-right">
                                                                <span>trader name</span>
                                                            </td><td>
                                                                <Link to={`/user/user-details/${tcf.rwoodusers_id}`}>{tcf.trader_name}</Link>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td id="td-right">
                                                                <span>order</span>
                                                            </td><td>
                                                                <Link to={`/order/view-order-details/${tcf.rwood_order_id}`}>{tcf.order}</Link>
                                                            </td>
                                                            <td id="td-right">
                                                                <span>owner </span>
                                                            </td><td>
                                                                {tcf.owner}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td id="td-right">
                                                            </td><td>
                                                            </td>
                                                            <td id="td-right">
                                                                <span className="tooltips-label">Auto Select Pricebook</span>
                                                                <span id='tooltip' data-tooltip-id="button-tooltip" data-tooltip-place='right'>
                                                                    <IoIosHelpCircle />
                                                                </span>
                                                                <ReactTooltip
                                                                    id="button-tooltip"
                                                                    place="bottom"
                                                                    content="If Checked Pricebook will be based on Month of BL Date Of Order If Unchecked Price book will be based on TCF.Pricebook field"
                                                                />  </td><td>
                                                                <input
                                                                    type='checkbox'
                                                                    name='auto_select_pricebook'
                                                                    checked={lockeds} // Use the state variable to determine if the checkbox is checked
                                                                    onChange={handleCheckboxChange} // Handle the change event
                                                                    className='mx-3'
                                                                    disabled={isButtonEnabled}
                                                                />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td id="td-right">
                                                                <span>Rwood Bank Master</span>
                                                            </td><td>
                                                                <Link to={`/bank/bank-details/${tcf.rwoodbank_id}`}>{tcf.rwood_bank_master}</Link>
                                                            </td>
                                                            <td id="td-right">
                                                                <span>Pricebook</span>
                                                            </td><td>
                                                                <Link to={`/pricebook/pricebook-details/${tcf.price_book_id}`}>{tcf.price_book}</Link>
                                                            </td>
                                                        </tr>
                                                        <tr>


                                                            <td id="td-right">
                                                                <span>TCF Status</span>
                                                            </td><td>
                                                                {tcf.tcf_status}
                                                            </td>
                                                            <td id="td-right">
                                                                <span>Purchase Confirmation Date</span>
                                                                <span id='tooltip' data-tooltip-id="button-tooltips" data-tooltip-place='right'>
                                                                    <IoIosHelpCircle />
                                                                </span>
                                                                <ReactTooltip
                                                                    id="button-tooltips"
                                                                    place="bottom"
                                                                    content="Date on which the purchase was confirmed"
                                                                />
                                                            </td><td>
                                                                {tcf.purchase_confirmation_date}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td id="td-right">
                                                                <span>Buyer Contract</span>
                                                            </td><td>
                                                                <Link to={`/contract/contract-details/${tcf.contract_id}`}>{tcf.buyer_contract}</Link>


                                                            </td>
                                                            <td id="td-right">
                                                            </td><td>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td id="td-right">
                                                                <span>Contract Shipment</span>
                                                            </td><td>

                                                                <Link to={`/shipment/contract-shipment-details/${tcf.contract_ship_id}`}>{tcf.contract_shipment}</Link>
                                                            </td>
                                                            <td id="td-right">
                                                            </td><td>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td id="td-right">
                                                                <span className="tooltips-label">Product Master</span>
                                                                <span id='tooltip' data-tooltip-id="button-tooltip1" data-tooltip-place='right'>
                                                                    <IoIosHelpCircle />
                                                                </span>
                                                                <ReactTooltip
                                                                    id="button-tooltip1"
                                                                    place="bottom"
                                                                    content="Product Master"
                                                                />
                                                            </td><td>
                                                                <Link to={`/productmaster/product-detail/${tcf.product_id}`}>{tcf.product_master}</Link>
                                                            </td>
                                                            <td id="td-right">
                                                            </td><td>
                                                            </td>

                                                        </tr>
                                                        <tr>
                                                            <td id="td-right">
                                                                <span>Product</span>
                                                            </td><td>
                                                                <Link to={`/product/product-detail/${tcf.new_products_id}`}>{tcf.product}</Link>
                                                            </td>
                                                            <td id="td-right">
                                                            </td><td>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td id="td-right">
                                                                <span>Product Name</span>
                                                            </td><td>
                                                                {tcf.product_name}
                                                            </td>
                                                            <td id="td-right">
                                                            </td><td>
                                                            </td>
                                                        </tr>
                                                        <tr>

                                                        </tr>
                                                        <tr>

                                                        </tr>
                                                        <tr>

                                                        </tr>
                                                        <tr>

                                                        </tr>
                                                    </tbody>
                                                </table>

                                                <div className="col-md-12" id="ax.account_details">
                                                    <h4 className="heading">Shipment Quantity</h4>
                                                    <hr></hr>
                                                </div>
                                                <table class="table table-bordered account-table tables">
                                                    <tbody>
                                                        <tr>
                                                            <td id="td-right">
                                                                <span className="tooltips-label">Shipment Qty</span>
                                                                <span id='tooltip' data-tooltip-id="button-tooltip2" data-tooltip-place='right'>
                                                                    <IoIosHelpCircle />
                                                                </span>
                                                                <ReactTooltip
                                                                    id="button-tooltip2"
                                                                    place="bottom"
                                                                    content="IF (ISNULL( A_BL_Quantity__c ),  IF(ISNULL(Stowage_Qty__c), Contract_Quantity_MT__c  , Stowage_Qty__c),  A_BL_Quantity__c )"
                                                                />
                                                            </td><td>
                                                                {renderShipmentQty(tcf.shipment_qty)}
                                                            </td>
                                                            <td id="td-right">
                                                                <span className="tooltips-label">Shipment Month</span>
                                                                <span id='tooltip' data-tooltip-id="button-tooltip3" data-tooltip-place='right'>
                                                                    <IoIosHelpCircle />
                                                                </span>
                                                                <ReactTooltip
                                                                    id="button-tooltip3"
                                                                    place="bottom"
                                                                    content="text(MONTH(Ship_Month__c )) &amp; &quot; &quot; &amp; text (Year (Ship_Month__c))"
                                                                />

                                                            </td><td>
                                                                {tcf.shipment_month}
                                                            </td>

                                                        </tr>
                                                        <tr>
                                                            <td id="td-right">
                                                                <span>shipment purchase price</span>
                                                            </td><td>
                                                                {renderShipmentQty(tcf.shipment_purchase_price)}
                                                            </td>
                                                            <td id="td-right">
                                                                <span className="tooltips-label">shipment sales price</span>
                                                                <span id='tooltip' data-tooltip-id="button-tooltip4" data-tooltip-place='right'>
                                                                    <IoIosHelpCircle />
                                                                </span>
                                                                <ReactTooltip
                                                                    id="button-tooltip4"
                                                                    place="bottom"
                                                                    content="text(MONTH(Ship_Month__c )) &amp; &quot; &quot; &amp; text (Year (Ship_Month__c))"
                                                                />

                                                            </td><td>
                                                                {renderShipmentQty(tcf.shipment_sales_price)}
                                                            </td>
                                                        </tr><tr>
                                                            <td id="td-right">
                                                                <span>shipment purchase </span>
                                                            </td><td>
                                                                {renderShipmentQty(tcf.shipment_purchase)}
                                                            </td>
                                                            <td id="td-right">
                                                                <span>shipment sales</span>
                                                            </td><td>
                                                                {renderShipmentQty(tcf.shipment_sales)}
                                                            </td>
                                                        </tr>{" "}
                                                        <tr>
                                                            <td id="td-right">
                                                                <span className="tooltips-label">shipment gross margin ton</span>
                                                                <span id='tooltip' data-tooltip-id="button-tooltip7" data-tooltip-place='right'>
                                                                    <IoIosHelpCircle />
                                                                </span>
                                                                <ReactTooltip
                                                                    id="button-tooltip7"
                                                                    place="bottom"
                                                                    content="Workflow Rule IF (ISBLANK(Actual_Gross_Margin_Ton__c ), Projected_Gross_Margin_Ton__c , Actual_Gross_Margin_Ton__c "
                                                                />
                                                            </td><td>
                                                                {renderShipmentQty(tcf.shipment_gross_margin_ton)}
                                                            </td>
                                                            <td id="td-right">
                                                                <span className="tooltips-label">shipment gross margin</span>
                                                                <span id='tooltip' data-tooltip-id="button-tooltip5" data-tooltip-place='right'>
                                                                    <IoIosHelpCircle />
                                                                </span>
                                                                <ReactTooltip
                                                                    id="button-tooltip5"
                                                                    place="bottom"
                                                                    content="Shipment_Gross_Margin_Ton__c \* Shipment_Qty__c"
                                                                />
                                                            </td><td>
                                                                {renderShipmentQty(tcf.shipment_gross_margin)}
                                                            </td>

                                                        </tr>

                                                    </tbody>
                                                </table>
                                                <div className="col-md-12" id="ax.account_details">
                                                    <h4 className="heading">Trade Confirmation Details</h4>
                                                    <hr></hr>
                                                </div>
                                                <table class="table table-bordered account-table tables">
                                                    <tbody>

                                                        <tr>
                                                            <td id="td-right"><span>Buyer</span></td>
                                                            <td><Link to={`/accounts/${tcf.account_id}`}>{tcf.buyer}</Link></td>
                                                            <td id="td-right"><span>Status of Buyer Contract</span></td>
                                                            <td>{tcf.status_of_buyer_contract}</td>
                                                        </tr>
                                                        <tr>
                                                            <td id="td-right"><span>Supplier</span></td>
                                                            <td><Link to={`/accounts/${tcf.supplier_id}`}>{tcf.supplier}</Link></td>
                                                            <td id="td-right"><span>Status of purchase contract</span></td>
                                                            <td>{tcf.status_of_purchase_contract}</td>
                                                        </tr>
                                                        <tr>
                                                            <td id="td-right"><span>Product Description</span></td>
                                                            <td>{tcf.product_description}</td>
                                                            <td id="td-right"><span className="tooltips-label">ship Month</span>
                                                                <span id='tooltip' data-tooltip-id="button-tooltip8" data-tooltip-place='right'>
                                                                    <IoIosHelpCircle />
                                                                </span>
                                                                <ReactTooltip
                                                                    id="button-tooltip8"
                                                                    place="bottom"
                                                                    content="Laycan From Date"
                                                                /> </td>
                                                            <td>{tcf.ship_month}</td>
                                                        </tr>
                                                        <tr>
                                                            <td id="td-right"><span>Product Specification</span></td>
                                                            <td>{tcf.product_specification}</td>
                                                            <td id="td-right"></td>
                                                            <td></td>

                                                        </tr>
                                                        <tr>
                                                            <td id="td-right"><span>Quantity MT</span></td>
                                                            <td>{renderShipmentQty(tcf.quantity_MT)}</td>
                                                            <td id="td-right"><span>Proposed Laycan From</span></td>
                                                            <td>{tcf.proposed_laycan_from}</td>

                                                        </tr>
                                                        <tr>
                                                            <td id="td-right"><span>Price Basis</span></td>
                                                            <td>{tcf.price_basis}</td>
                                                            <td id="td-right"><span>Proposed Laycan To</span></td>
                                                            <td>{tcf.proposed_laycan_to}</td>

                                                        </tr>
                                                        <tr>
                                                            <td id="td-right"><span>Vessel Type</span></td>
                                                            <td>{tcf.vessel_type}</td>
                                                            <td id="td-right"></td>
                                                            <td></td>

                                                        </tr>
                                                        <tr>
                                                            <td id="td-right"><span>Payment Terms</span></td>
                                                            <td>{tcf.payment_term}</td>
                                                            <td id="td-right"></td>
                                                            <td></td>

                                                        </tr>
                                                        <tr>
                                                            <td id="td-right"><span>Selling Terms</span></td>
                                                            <td>{tcf.selling_terms}</td>
                                                            <td id="td-right"></td>
                                                            <td></td>

                                                        </tr>
                                                        <tr>
                                                            <td id="td-right"><span>Buying Terms</span></td>
                                                            <td>{tcf.buying_terms}</td>
                                                            <td id="td-right"></td>
                                                            <td></td>

                                                        </tr>
                                                        <tr>
                                                            <td id="td-right"><span>Price Adjustment</span></td>
                                                            <td>{tcf.price_adjustment}</td>
                                                            <td id="td-right"></td>
                                                            <td></td>
                                                        </tr>

                                                    </tbody>
                                                </table>

                                                <div className="col-md-12" id="ax.account_details">
                                                    <h4 className="heading">Contractual</h4>
                                                    <hr></hr>
                                                </div>
                                                <table class="table table-bordered account-table tables">
                                                    <tbody>
                                                        <tr>
                                                            <td id="td-right"><span>Contractual GAR (P)</span></td>
                                                            <td>{renderShipmentQty(tcf.contractual_GAR_P)}</td>
                                                            <td id="td-right"><span>Contractual GAR (S)</span></td>
                                                            <td>{renderShipmentQty(tcf.contractual_GAR_S)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td id="td-right"><span>Contractual TM (AR) (P)</span></td>
                                                            <td>{renderShipmentQty(tcf.contractual_TM_AR_P)}</td>
                                                            <td id="td-right"><span>Contractual TM (AR) (S)</span></td>
                                                            <td>{renderShipmentQty(tcf.contractual_TM_AR_S)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td id="td-right"><span>Contractual TS (AR) (P)</span></td>
                                                            <td>{renderShipmentQty(tcf.contractual_Ts_AR_p)}</td>
                                                            <td id="td-right"><span>Contractual TS (AR) (S)</span></td>
                                                            <td>{renderShipmentQty(tcf.contractual_Ts_AR_s)}</td>

                                                        </tr>
                                                        <tr>
                                                            <td id="td-right"><span>Contractual Ash (AR) (P)</span></td>
                                                            <td>{renderShipmentQty(tcf.contractual_Ash_AR_P)}</td>
                                                            <td id="td-right"><span>Contractual Ash (AR) (S)</span></td>
                                                            <td>{renderShipmentQty(tcf.contractual_Ash_AR_S)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td id="td-right"><span>Contractual TS (ADB) (P)</span></td>
                                                            <td>{renderShipmentQty(tcf.contractual_Ts_ADB_P)}</td>
                                                            <td id="td-right"><span>Contractual TS (ADB) (S)</span></td>
                                                            <td>{renderShipmentQty(tcf.contractual_Ts_ADB_S)}</td>
                                                        </tr>

                                                        <tr>
                                                            <td id="td-right"><span>Contractual Ash (ADB) (P)	</span></td>
                                                            <td>{renderShipmentQty(tcf.contractual_Ash_ADB_P)}</td>
                                                            <td id="td-right"><span>Contractual Ash (ADB) (S)	</span></td>
                                                            <td>{renderShipmentQty(tcf.contractual_Ash_ADB_S)}</td>
                                                        </tr>


                                                        <tr>
                                                            <td id="td-right"><span>Projected Price Basis GAR (P)
                                                            </span></td>
                                                            <td>{renderShipmentQty(tcf.projected_price_basis_GAR_P)}</td>
                                                            <td id="td-right"><span>Projected Price Basis GAR (S)
                                                            </span></td>
                                                            <td>{renderShipmentQty(tcf.projected_price_basis_GAR_S)}</td>
                                                        </tr>

                                                        <tr class="space"><td id="td-right"><span></span></td><td></td><td id="td-right"><span></span></td><td></td></tr>

                                                        <tr>
                                                            <td id="td-right"><span>Contractual HBA Year</span></td>
                                                            <td>{tcf.contractual_HBA_year}</td>
                                                            <td id="td-right">  <span className="tooltips-label">Discounted Price</span>
                                                                <span id='tooltip' data-tooltip-id="button-tooltip11" data-tooltip-place='right'>
                                                                    <IoIosHelpCircle />
                                                                </span>
                                                                <ReactTooltip
                                                                    id="button-tooltip11"
                                                                    place="bottom"
                                                                    content="Discounted Price as per Vessel Type \r\u003Cbr\u003EIf Geared (GNG) - 0.90\r\u003Cbr\u003Eif Grearless - 0.92"
                                                                /> </td>
                                                            <td>{renderShipmentQty(tcf.discounted_price)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td id="td-right"><span>Contractual HBA Month</span></td>
                                                            <td>{tcf.contractual_HBA_month}</td>
                                                            <td id="td-right">  <span className="tooltips-label">G1/G2  </span>
                                                                <span id='tooltip' data-tooltip-id="button-tooltip12" data-tooltip-place='right'>
                                                                    <IoIosHelpCircle />
                                                                </span>
                                                                <ReactTooltip
                                                                    id="button-tooltip12"
                                                                    place="bottom"
                                                                    content="( Actual_GAR_P__c \*  GCNEWC__c  / 6322 ) \*  Discounted_Price__c')"
                                                                /> </td>
                                                            <td>{tcf.g1_g2}</td>
                                                        </tr>
                                                        <tr>
                                                            <td id="td-right">  <span className="tooltips-label">Projected HBA</span>
                                                                <span id='tooltip' data-tooltip-id="button-tooltip10" data-tooltip-place='right'>
                                                                    <IoIosHelpCircle />
                                                                </span>
                                                                <ReactTooltip
                                                                    id="button-tooltip10"
                                                                    place="bottom"
                                                                    content="Projected HBA - Derrived from Opportunity.Pricebook.Projected HBA (Contractual_HBA)"
                                                                /> </td>
                                                            <td>{renderShipmentQty(tcf.projected_hba)}</td>

                                                            <td id="td-right"><span>Projected Index Value</span></td>
                                                            <td>{renderShipmentQty(tcf.projected_index_value)}</td>
                                                        </tr>
                                                        <tr class="space"><td id="td-right"><span></span></td><td></td><td id="td-right"><span></span></td><td></td></tr>
                                                        <tr>
                                                            <td id="td-right"><span>Projected Purchase Price Type</span></td>
                                                            <td>{tcf.projected_purchase_type}</td>
                                                            <td id="td-right"><span>Projected Sale Price Type</span></td>
                                                            <td>{tcf.projected_sale_price_type}</td>
                                                        </tr>
                                                        <tr>
                                                            <td id="td-right"><span>Projected Fixed Purchase Price</span></td>
                                                            <td>{renderShipmentQty(tcf.projected_fixed_purchase_price)}</td>
                                                            <td id="td-right"><span>Projected Fixed Sales Price</span></td>
                                                            <td>{renderShipmentQty(tcf.projected_fixed_sales_price)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td id="td-right"><span>Projected Purchase Price Margin</span></td>
                                                            <td>{renderShipmentQty(tcf.projected_purchase_price_margin)}</td>
                                                            <td id="td-right"><span>Projected Sales Price Margin</span></td>
                                                            <td>{renderShipmentQty(tcf.projected_sales_price_margin)}</td>
                                                        </tr>
                                                        <tr>

                                                            <td id="td-right"><span>Projected Barge Discount</span></td>
                                                            <td>{renderShipmentQty(tcf.barge_discount)}</td>
                                                            <td id="td-right"></td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>

                                                            <td id="td-right">  <span className="tooltips-label">Contractual Pur Price</span>
                                                                <span id='tooltip' data-tooltip-id="button-tooltip14" data-tooltip-place='right'>
                                                                    <IoIosHelpCircle />
                                                                </span>
                                                                <ReactTooltip
                                                                    id="button-tooltip14"
                                                                    place="bottom"
                                                                    content="HPB=(Contractual_HBA\*C_GAR_P)/ 6322)\*( (100-TM_AR)/(100-8)))-(((ContractualTS(ARB)-0.8)\*4)+((Contractual_Ash-15)\*0.4)))\r\u003Cbr\u003EFixed price=ProjFixed_Purchase_Price\*C_GAR P /Proj Price_Basis_GAR P\r\u003Cbr\u003EGCNEWC=Proj. GCNEWC\*CGAR P/ProjectPrice Basis GAR P + ProjMargin"
                                                                /> </td>
                                                            <td>{renderShipmentQty(tcf.contractual_pur_price)}</td>
                                                            <td id="td-right"><span></span></td>
                                                            <td></td>
                                                        </tr>

                                                        <tr>


                                                            <td id="td-right"><span>Projected Purchase Price</span></td>
                                                            <td>{renderShipmentQty(tcf.projected_purchase_price)}</td>

                                                            <td id="td-right"><span></span></td>
                                                            <td></td>
                                                        </tr>

                                                        <tr>
                                                            <td id="td-right"><span>Projected Sales Price</span></td>
                                                            <td>{renderShipmentQty(tcf.projected_sales_price)}</td>
                                                            <td id="td-right"></td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>  <td id="td-right"><span>Contractual Margin</span></td>
                                                            <td>{renderShipmentQty(tcf.contractual_margin)}</td>
                                                            <td id="td-right"><span></span></td>
                                                            <td></td></tr>
                                                    </tbody>
                                                </table>

                                                <div className="col-md-12" id="ax.account_details">
                                                    <h4 className="heading">Cost Sheet Projection</h4>
                                                    <hr></hr>
                                                </div>

                                                <table class="table table-bordered account-table tables">
                                                    <tbody>
                                                        <tr>
                                                            <td id="td-right"><span>projected pur amount</span>
                                                                <span id='tooltip' data-tooltip-id="button-tooltips1" data-tooltip-place='right'>
                                                                    <IoIosHelpCircle />
                                                                </span>
                                                                <ReactTooltip
                                                                    id="button-tooltips1"
                                                                    place="bottom"
                                                                    content="Work Flow Rule - (Contract_Quantity_MT \* round(Contractual_Pur_Price,2))"
                                                                />  </td>
                                                            <td>{renderShipmentQty(tcf.projected_pur_amount)}</td>
                                                            <td id="td-right"><span>projected sales</span>
                                                                <span id='tooltip' data-tooltip-id="button-tooltips2" data-tooltip-place='right'>
                                                                    <IoIosHelpCircle />
                                                                </span>
                                                                <ReactTooltip
                                                                    id="button-tooltips2"
                                                                    place="bottom"
                                                                    content="Workflow Rules - Projected_Sales_Price__c \* Contract_Quantity_MT__c'"
                                                                />  </td>
                                                            <td>{renderShipmentQty(tcf.projected_sales)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td id="td-right"><span>projected fob purchase</span>
                                                                <span id='tooltip' data-tooltip-id="button-tooltips3" data-tooltip-place='right'>
                                                                    <IoIosHelpCircle />
                                                                </span>
                                                                <ReactTooltip
                                                                    id="button-tooltips3"
                                                                    place="bottom"
                                                                    content="Projected Pur Amount"
                                                                />  </td>
                                                            <td>{renderShipmentQty(tcf.projected_fob_purchase)}</td>
                                                            <td id="td-right"><span></span></td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>

                                                            <td id="td-right"><span></span></td>
                                                            <td></td>
                                                            <td id="td-right"><span>projected gross margin shipment</span>
                                                                <span id='tooltip' data-tooltip-id="button-tooltips4" data-tooltip-place='right'>
                                                                    <IoIosHelpCircle />
                                                                </span>
                                                                <ReactTooltip
                                                                    id="button-tooltips4"
                                                                    place="bottom"
                                                                    content="Projected_Sales__c - Projected_FOB_Purchase__c - Projected_Freight_Amount__c -  Projected_Insurance__c - Projected_Commision__c"
                                                                />  </td>
                                                            <td>{renderShipmentQty(tcf.projected_gross_margin_shipment)}</td>

                                                        </tr>
                                                        <tr>
                                                            <td id="td-right"><span>projected freight rate</span></td>
                                                            <td>{renderShipmentQty(tcf.projected_freight_rate)}</td>
                                                            <td id="td-right"><span>projected gross margin TON</span>
                                                                <span id='tooltip' data-tooltip-id="button-tooltips5" data-tooltip-place='right'>
                                                                    <IoIosHelpCircle />
                                                                </span>
                                                                <ReactTooltip
                                                                    id="button-tooltips5"
                                                                    place="bottom"
                                                                    content="Projected_Gross_Margin_Shipment__c /  Contract_Quantity_MT__c"
                                                                />  </td>
                                                            <td>{renderShipmentQty(tcf.projected_gross_margin_ton)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td id="td-right"><span>projected freight amount</span></td>
                                                            <td>{renderShipmentQty(tcf.projected_freight_amount)}</td>
                                                            <td id="td-right"><span></span></td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td id="td-right"><span></span></td>
                                                            <td></td>
                                                            <td id="td-right"><span>projected insurance</span></td>
                                                            <td>{renderShipmentQty(tcf.projected_insurance)}</td>
                                                        </tr>
                                                        <tr>

                                                            <td id="td-right"><span>projected  LC Transfer cost</span>
                                                                <span id='tooltip' data-tooltip-id="button-tooltips9" data-tooltip-place='right'>
                                                                    <IoIosHelpCircle />
                                                                </span>
                                                                <ReactTooltip
                                                                    id="button-tooltips9"
                                                                    place="bottom"
                                                                    content="Projected_FOB_Purchase__c + Projected_FOB_Purchase__c /10 ) \* Rwood_Bank_Master__r.LC_Transfer_Rate__c"
                                                                />  </td>
                                                            <td>{renderShipmentQty(tcf.projected_lc_transfer_cost)}</td>
                                                            <td id="td-right"><span>projected total bank cost</span>
                                                                <span id='tooltip' data-tooltip-id="button-tooltips6" data-tooltip-place='right'>
                                                                    <IoIosHelpCircle />
                                                                </span>
                                                                <ReactTooltip
                                                                    id="button-tooltips6"
                                                                    place="bottom"
                                                                    content="Projected_LC_Transfer_Cost__c + \r\u003Cbr\u003EProjected_LC_Negotiation_Cost__c + \r\u003Cbr\u003EProjected_Fixed_Other_Bank_Costs__c + \r\u003Cbr\u003EProjected_Adding_Confirmation_Charges__c"
                                                                />  </td>
                                                            <td>{renderShipmentQty(tcf.projected_total_bank_cost)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td id="td-right"><span>projected  LC negotiation cost</span>
                                                                <span id='tooltip' data-tooltip-id="button-tooltips8" data-tooltip-place='right'>
                                                                    <IoIosHelpCircle />
                                                                </span>
                                                                <ReactTooltip
                                                                    id="button-tooltips8"
                                                                    place="bottom"
                                                                    content="Projected_Sales__c \* Rwood_Bank_Master__r.LC_Negotiation_Rate__c Adding Confirmation Charges"
                                                                />  </td>
                                                            <td>{renderShipmentQty(tcf.projected_lc_negotiation_cost)}</td>
                                                            <td id="td-right"><span>projected cost</span>
                                                                <span id='tooltip' data-tooltip-id="button-tooltips7" data-tooltip-place='right'>
                                                                    <IoIosHelpCircle />
                                                                </span>
                                                                <ReactTooltip
                                                                    id="button-tooltips7"
                                                                    place="bottom"
                                                                    content="Pj_LC_Transfer_Cost+Pj_LC_Nego_Cost+Pj_Fixed_Other_Bank_Costs+Pj_Any_Contract_Spe_Cost+Pj_Fixed_Appo_Overheads+Pj_Adding_Conf_Charges+Pj_Extra_Cost+Pj_Freight_Amt+Pj. Insurance+Pj_Prov_for_corp Tax+Pj_Commission+PJ_Witholding Tax"
                                                                />  </td>
                                                            <td>{renderShipmentQty(tcf.projected_cost)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td id="td-right"><span>projected fixed other bank costs</span>
                                                                <span id='tooltip' data-tooltip-id="button-tooltips10" data-tooltip-place='right'>
                                                                    <IoIosHelpCircle />
                                                                </span>
                                                                <ReactTooltip
                                                                    id="button-tooltips10"
                                                                    place="bottom"
                                                                    content="Rwood_Bank_Master__r.Fixed_Other_Cost__c \* Contract_Quantity_MT__c"
                                                                />  </td>
                                                            <td>{renderShipmentQty(tcf.projected_fixed_other_bank_costs)}</td>
                                                            <td id="td-right"><span>projected cost TON</span></td>
                                                            <td>{renderShipmentQty(tcf.projected_cost_ton)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td id="td-right"><span>projected fixed apportioned overheads</span></td>
                                                            <td>{renderShipmentQty(tcf.projected_fixed_apportioned_overheads)}</td>
                                                            <td id="td-right"><span>projected total cost</span></td>
                                                            <td>{renderShipmentQty(tcf.projected_total_cost)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td id="td-right"><span>projected any contract specific cost</span></td>
                                                            <td>{renderShipmentQty(tcf.projected_any_contract_specific_cost)}</td>
                                                            <td id="td-right"><span></span></td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td id="td-right"><span>projected extra  cost</span></td>
                                                            <td>{renderShipmentQty(tcf.projected_extra_cost)}</td>
                                                            <td id="td-right"><span></span></td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td id="td-right"><span>projected provision for corporate tax</span>
                                                                <span id='tooltip' data-tooltip-id="button-tooltips11" data-tooltip-place='right'>
                                                                    <IoIosHelpCircle />
                                                                </span>
                                                                <ReactTooltip
                                                                    id="button-tooltips11"
                                                                    place="bottom"
                                                                    content="1.5% Revenue"
                                                                />  </td>
                                                            <td>{renderShipmentQty(tcf.projected_provision_for_corporate_tax)}</td>
                                                            <td id="td-right"><span></span></td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td id="td-right"><span>projected withholding tax</span>
                                                                <span id='tooltip' data-tooltip-id="button-tooltips12" data-tooltip-place='right'>
                                                                    <IoIosHelpCircle />
                                                                </span>
                                                                <ReactTooltip
                                                                    id="button-tooltips12"
                                                                    place="bottom"
                                                                    content="IF ( Company__c= PT RRI , Projected_Sales__c \* Rwood_Bank_Master__r.Tax__c, 0)"
                                                                />  </td>
                                                            <td>{renderShipmentQty(tcf.projected_withholding_tax)}</td>
                                                            <td id="td-right"><span></span></td>
                                                            <td></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <div className="col-md-12" id="ax.account_details">
                                                    <h4 className="heading">Actual Shipment Details</h4>
                                                    <hr></hr>
                                                </div>

                                                <table class="table table-bordered account-table tables">
                                                    <tbody>
                                                        <tr>
                                                            <td id="td-right"><span>vessel name</span>
                                                                <span id='tooltip' data-tooltip-id="button-tooltips13" data-tooltip-place='right'>
                                                                    <IoIosHelpCircle />
                                                                </span>
                                                                <ReactTooltip
                                                                    id="button-tooltips13"
                                                                    place="bottom"
                                                                    content="if Barge name is BLANK - Order__r.Vessel_Name__c OR Vessel Name &amp; Barge Name"
                                                                />  </td>
                                                            <td>{tcf.vessel_name}</td>
                                                            <td id="td-right"><span>demmurage rate (P)</span>
                                                                <span id='tooltip' data-tooltip-id="button-tooltips14" data-tooltip-place='right'>
                                                                    <IoIosHelpCircle />
                                                                </span>
                                                                <ReactTooltip
                                                                    id="button-tooltips14"
                                                                    place="bottom"
                                                                    content="Order__r.S_Despatch_Demmurage_Rate__c"
                                                                />  </td>
                                                            <td>{renderShipmentQty(tcf.demmurage_rate_p)}</td>
                                                        </tr>

                                                        <tr>
                                                            <td id="td-right"><span>stowage quantity</span>
                                                                <span id='tooltip' data-tooltip-id="button-tooltips15" data-tooltip-place='right'>
                                                                    <IoIosHelpCircle />
                                                                </span>
                                                                <ReactTooltip
                                                                    id="button-tooltips15"
                                                                    place="bottom"
                                                                    content="Order__r.Pre_stowage__c"
                                                                />  </td>
                                                            <td>{renderShipmentQty(tcf.stowage_qty)}</td>
                                                            <td id="td-right"><span>demmurage rate (S)</span>
                                                                <span id='tooltip' data-tooltip-id="button-tooltips16" data-tooltip-place='right'>
                                                                    <IoIosHelpCircle />
                                                                </span>
                                                                <ReactTooltip
                                                                    id="button-tooltips16"
                                                                    place="bottom"
                                                                    content="Order__r.B_Despatch_Demmurage_Rate__c"
                                                                />  </td>
                                                            <td>{renderShipmentQty(tcf.demmurage_rate_s)}</td>
                                                        </tr>

                                                        <tr>
                                                            <td id="td-right"><span>Destination</span>
                                                                <span id='tooltip' data-tooltip-id="button-tooltips17" data-tooltip-place='right'>
                                                                    <IoIosHelpCircle />
                                                                </span>
                                                                <ReactTooltip
                                                                    id="button-tooltips17"
                                                                    place="bottom"
                                                                    content="Order. Destination or Buyer contract.destination."
                                                                />  </td>
                                                            <td>{tcf.destination}</td>
                                                            <td id="td-right"><span>Load Rate</span>
                                                                <span id='tooltip' data-tooltip-id="button-tooltips18" data-tooltip-place='right'>
                                                                    <IoIosHelpCircle />
                                                                </span>
                                                                <ReactTooltip
                                                                    id="button-tooltips18"
                                                                    place="bottom"
                                                                    content="Order__r.Loading_Rate__c"
                                                                />  </td>
                                                            <td>{renderShipmentQty(tcf.load_rate)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td id="td-right"><span>Actual BL Date</span>
                                                                <span id='tooltip' data-tooltip-id="button-tooltips19" data-tooltip-place='right'>
                                                                    <IoIosHelpCircle />
                                                                </span>
                                                                <ReactTooltip
                                                                    id="button-tooltips19"
                                                                    place="bottom"
                                                                    content="Order__r.Loading_Rate__c"
                                                                />  </td>
                                                            <td>{tcf.actual_bl_date}</td>
                                                            <td id="td-right"><span></span></td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>

                                                            <td id="td-right"><span>Actual BL Qty</span>
                                                                <span id='tooltip' data-tooltip-id="button-tooltips20" data-tooltip-place='right'>
                                                                    <IoIosHelpCircle />
                                                                </span>
                                                                <ReactTooltip
                                                                    id="button-tooltips20"
                                                                    place="bottom"
                                                                    content="Order.Total BL Quantity"
                                                                />  </td>
                                                            <td>{renderShipmentQty(tcf.actual_bl_qty)}</td>
                                                            <td id="td-right"><span>GCNEWC</span>
                                                                <span id='tooltip' data-tooltip-id="button-tooltips21" data-tooltip-place='right'>
                                                                    <IoIosHelpCircle />
                                                                </span>
                                                                <ReactTooltip
                                                                    id="button-tooltips21"
                                                                    place="bottom"
                                                                    content="pricebook.GCNEWC"
                                                                />  </td>
                                                            <td>{renderShipmentQty(tcf.gcnewc)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td id="td-right"><span>Actual HBA</span>
                                                                <span id='tooltip' data-tooltip-id="button-tooltips22" data-tooltip-place='right'>
                                                                    <IoIosHelpCircle />
                                                                </span>
                                                                <ReactTooltip
                                                                    id="button-tooltips22"
                                                                    place="bottom"
                                                                    content="Price Book.Actual HBA"
                                                                />  </td>
                                                            <td>{renderShipmentQty(tcf.actual_hba)}</td>
                                                            <td id="td-right"><span>Actual GCNEWC - Sales</span>
                                                                <span id='tooltip' data-tooltip-id="button-tooltips23" data-tooltip-place='right'>
                                                                    <IoIosHelpCircle />
                                                                </span>
                                                                <ReactTooltip
                                                                    id="button-tooltips23"
                                                                    place="bottom"
                                                                    content="CASE(Sale_Price_Type, '(Avg Prev Qtr GCNEWC + Margin)  GCV Adj', Price_Book__r.Avg_GCNEWC_Prev_QTR__c , '(Avg Curr Qtr GCNEWC + Margin) GCV Adj',  Price_Book__r.Avg_GCNEWC_Current_QTR__c , 'Avg Prev Qtr GCNEWC + Margin',  Price_Book__r.Av"
                                                                /> </td>
                                                            <td>{renderShipmentQty(tcf.actual_gcnewc_sales)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td id="td-right"><span>Actual HBA - Purchase</span>
                                                                <span id='tooltip' data-tooltip-id="button-tooltips24" data-tooltip-place='right'>
                                                                    <IoIosHelpCircle />
                                                                </span>
                                                                <ReactTooltip
                                                                    id="button-tooltips24"
                                                                    place="bottom"
                                                                    content="CASE ( Purchase_Price_Type.Name , 'Avg Prev Qtr HBA + Margin', Price_Book__r.Avg_HBA_Prev_QTR__c, 'Avg Current Qtr HBA + Margin', 'Price_Book__r.Avg_HBA_Current_QTR__c , 'Price_Book__r.Actual_HBA__c)"
                                                                />  </td>
                                                            <td>{renderShipmentQty(tcf.actual_hba_purchase)}</td>
                                                            <td id="td-right"><span>Laycan</span>
                                                                <span id='tooltip' data-tooltip-id="button-tooltips25" data-tooltip-place='right'>
                                                                    <IoIosHelpCircle />
                                                                </span>
                                                                <ReactTooltip
                                                                    id="button-tooltips25"
                                                                    place="bottom"
                                                                    content="Text(Day (Order__r.Laycan_From_Date__c)) & / TEXT(Month (Order__r.Laycan_From_Date__c)) & - & TExt(Day ( Order__r.Laycan_To_Date__c )) & / TEXT(Month(Order__r.Laycan_To_Date__c))  & '' & TEXT(year (Order__r.Laycan_From_Date__c))"
                                                                />  </td>
                                                            <td>{tcf.laycan}</td>
                                                        </tr>
                                                        <tr>

                                                            <td id="td-right"><span>Expected Value Date</span>
                                                                <span id='tooltip' data-tooltip-id="button-tooltips26" data-tooltip-place='right'>
                                                                    <IoIosHelpCircle />
                                                                </span>
                                                                <ReactTooltip
                                                                    id="button-tooltips26"
                                                                    place="bottom"
                                                                    content="Order__r.all Expected_Value_Date__c"
                                                                />  </td>
                                                            <td>{tcf.expected_value_date}</td>

                                                            <td id="td-right"><span></span></td>
                                                            <td></td>
                                                        </tr>
                                                    </tbody>
                                                </table>


                                                <div className="col-md-12" id="ax.account_details">
                                                    <h4 className="heading">Actual Cost Elements</h4>
                                                    <hr></hr>
                                                </div>

                                                <table class="table table-bordered account-table tables">
                                                    <tbody>
                                                        <tr>
                                                            <td id="td-right"><span>Purchase Price Type</span></td>
                                                            <td>{tcf.purchase_price_type}</td>
                                                            <td id="td-right"><span>Actual Data Rcvd</span></td>
                                                            <td><input
                                                                type='checkbox'
                                                                name='actual_data_rcvd'
                                                                checked={dataReceived} // Use th e state variable to determine if the checkbox is checked
                                                                onChange={handleData}
                                                                className='mx-3' disabled={isButtonEnabled}
                                                            /></td>
                                                        </tr>
                                                        <tr>
                                                            <td id="td-right"><span>fixed purchase price</span></td>
                                                            <td>{tcf.fixed_purchase_price}</td>
                                                            <td id="td-right"><span>Sale Price Type</span></td>
                                                            <td>{tcf.sale_price_type}</td>

                                                        </tr>
                                                        <tr>
                                                            <td id="td-right"><span>Purchase Price Margin</span></td>
                                                            <td>{tcf.purchase_price_margin}</td>
                                                            <td id="td-right"><span>Fixed Sale Price</span></td>
                                                            <td>{tcf.fixed_sale_price}</td>

                                                        </tr>
                                                        <tr>
                                                            <td id="td-right"><span>Barge Discount</span>
                                                                <span id='tooltip' data-tooltip-id="button-tooltips27" data-tooltip-place='right'>
                                                                    <IoIosHelpCircle />
                                                                </span>
                                                                <ReactTooltip
                                                                    id="button-tooltips27"
                                                                    place="bottom"
                                                                    content="Barge Discount if applicable"
                                                                />  </td>
                                                            <td>{renderShipmentQty(tcf.barge_discount)}</td>
                                                            <td id="td-right"><span>Sales Price Margin</span></td>
                                                            <td>{tcf.sale_price_margin}</td>
                                                        </tr>
                                                        <tr>

                                                            <td id="td-right"><span>Actual GAR (P)</span></td>
                                                            <td>{renderShipmentQty(tcf.actual_gar_p)}</td>
                                                            <td id="td-right"><span>Actual GAR (s)</span></td>
                                                            <td>{renderShipmentQty(tcf.actual_gar_s)}</td>
                                                        </tr>
                                                        <tr>

                                                            <td id="td-right"><span>Actual TM (AR) (P)</span></td>
                                                            <td>{renderShipmentQty(tcf.actual_tm_p)}</td>
                                                            <td id="td-right"><span>Actual TM (AR) (S)</span></td>
                                                            <td>{renderShipmentQty(tcf.actual_tm_s)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td id="td-right"><span>Actual Ash (AR) (P)</span></td>
                                                            <td>{renderShipmentQty(tcf.actual_ash_ar_p)}</td>
                                                            <td id="td-right"><span>Actual Ash (AR) (S)</span></td>
                                                            <td>{renderShipmentQty(tcf.actual_ash_ar_s)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td id="td-right"><span>Actual TS (AR) (P)</span></td>
                                                            <td>{renderShipmentQty(tcf.actual_ts_ar_p)}</td>
                                                            <td id="td-right"><span>Actual TS (AR) (S)</span></td>
                                                            <td>{renderShipmentQty(tcf.actual_ts_ar_s)}</td>
                                                        </tr>
                                                        <tr>

                                                            <td id="td-right"><span>Actual Ash (ADB) (P)</span></td>
                                                            <td>{renderShipmentQty(tcf.actual_ash_adb_p)}</td>
                                                            <td id="td-right"><span>Actual Ash (ADB) (S)</span></td>
                                                            <td>{renderShipmentQty(tcf.actual_ash_adb_s)}</td>

                                                        </tr>
                                                        <tr>

                                                            <td id="td-right"><span>Actual TS (ADB) (P)</span></td>
                                                            <td>{renderShipmentQty(tcf.actual_ts_adb_p)}</td>
                                                            <td id="td-right"><span>Actual TS (ADB) (S)</span></td>
                                                            <td>{renderShipmentQty(tcf.actual_ts_adb_s)}</td>
                                                        </tr>
                                                        <tr>

                                                            <td id="td-right"><span>Actual AFT (P)</span></td>
                                                            <td>{renderShipmentQty(tcf.actual_aft_p)}</td>
                                                            <td id="td-right"><span>Actual AFT (S)</span></td>
                                                            <td>{renderShipmentQty(tcf.actual_aft_s)}</td>
                                                        </tr>
                                                        <tr>

                                                            <td id="td-right"><span>Actual VM (P)</span></td>
                                                            <td>{renderShipmentQty(tcf.actual_vm_p)}</td>
                                                            <td id="td-right"><span>Actual VM (S)</span></td>
                                                            <td>{renderShipmentQty(tcf.actual_vm_s)}</td>
                                                        </tr>
                                                        <tr>

                                                            <td id="td-right"><span>Actual HGI (P)</span></td>
                                                            <td>{renderShipmentQty(tcf.actual_hgi_p)}</td>
                                                            <td id="td-right"><span>Actual HGI (S)</span></td>
                                                            <td>{renderShipmentQty(tcf.actual_hgi_s)}</td>
                                                        </tr>
                                                        <tr>

                                                            <td id="td-right"><span>Price Basis GAR (P)</span></td>
                                                            <td>{renderShipmentQty(tcf.price_basis_gar_p)}</td>
                                                            <td id="td-right"><span>Price Basis GAR (S)</span></td>
                                                            <td>{renderShipmentQty(tcf.price_basis_gar_s)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td id="td-right"><span>GAR (P)</span>
                                                                <span id='tooltip' data-tooltip-id="button-tooltips28" data-tooltip-place='right'>
                                                                    <IoIosHelpCircle />
                                                                </span>
                                                                <ReactTooltip
                                                                    id="button-tooltips28"
                                                                    place="bottom"
                                                                    content="GAR (P) - Equivalent GAR (Purchase) - for Reporting Purpose"
                                                                />  </td>
                                                            <td>{renderShipmentQty(tcf.gar_p)}</td>
                                                            <td id="td-right"><span>GAR (S)</span>
                                                                <span id='tooltip' data-tooltip-id="button-tooltips29" data-tooltip-place='right'>
                                                                    <IoIosHelpCircle />
                                                                </span>
                                                                <ReactTooltip
                                                                    id="button-tooltips29"
                                                                    place="bottom"
                                                                    content="GAR (S) - Equivalent GAR (Sale) - for Reporting Purpose"
                                                                />  </td>
                                                            <td>{renderShipmentQty(tcf.gar_s)}</td>
                                                        </tr>

                                                    </tbody>
                                                </table>

                                                <div className="col-md-12" id="ax.account_details">
                                                    <h4 className="heading">Bonus / Penalty</h4>
                                                    <hr></hr>
                                                </div>

                                                <table class="table table-bordered account-table tables">
                                                    <tbody>
                                                        <tr>
                                                            <td id="td-right"><span>Sulfur Bonus Rate (MT) (S)</span></td>
                                                            <td>{renderShipmentQty(tcf.sulfur_bonus_rate_MT_S)}</td>
                                                            <td id="td-right"><span>Ash Bonus Rate (MT) (S)</span></td>
                                                            <td>{renderShipmentQty(tcf.ash_bonus_rate_MT_S)}</td>
                                                        </tr>

                                                        <tr>
                                                            <td id="td-right"><span>Sulfur Bonus Amount (S)</span></td>
                                                            <td>{renderShipmentQty(tcf.sulfur_bonus_amount_s)}</td>
                                                            <td id="td-right"><span>Ash Bonus Amount (S)</span></td>
                                                            <td>{renderShipmentQty(tcf.ash_bonus_amount_s)}</td>
                                                        </tr>

                                                        <tr>
                                                            <td id="td-right"><span>Sulfur Penalty Rate (MT) (S)</span></td>
                                                            <td>{renderShipmentQty(tcf.sulfur_penalty_rate_MT_S)}</td>
                                                            <td id="td-right"><span>Ash Penalty Rate (MT) (S)</span></td>
                                                            <td>{renderShipmentQty(tcf.ash_penalty_rate_MT_S)}</td>
                                                        </tr>

                                                        <tr>
                                                            <td id="td-right"><span>Sulfur Penalty Amount (S)</span></td>
                                                            <td>{renderShipmentQty(tcf.sulfur_penalty_amount_s)}</td>
                                                            <td id="td-right"><span>Ash Penalty Amount (S)</span></td>
                                                            <td>{renderShipmentQty(tcf.ash_penalty_amount_s)}</td>
                                                        </tr>

                                                    </tbody>
                                                </table>



                                                <div className="col-md-12" id="ax.account_details">
                                                    <h4 className="heading">Cost Sheet Actual</h4>
                                                    <hr></hr>
                                                </div>

                                                <table class="table table-bordered account-table tables">
                                                    <tbody>
                                                        <tr>
                                                            <td id="td-right"><span>FOB Pur Price</span></td>
                                                            <td>{renderShipmentQty(tcf.fob_pur_price)}</td>
                                                            <td id="td-right"><span>FOB Sales Price</span></td>
                                                            <td>{renderShipmentQty(tcf.fob_sales_price)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td id="td-right"><span>Actual Pur Price</span></td>
                                                            <td>{renderShipmentQty(tcf.actual_pur_price)}</td>
                                                            <td id="td-right"><span>Actual Sales Price</span></td>
                                                            <td>{renderShipmentQty(tcf.actual_sales_price)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td id="td-right"><span>Actual Pur Amount</span></td>
                                                            <td>{renderShipmentQty(tcf.actual_pur_amount)}</td>
                                                            <td id="td-right"><span>Actual Sales</span></td>
                                                            <td>{renderShipmentQty(tcf.actual_sales)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td id="td-right"><span>Actual FOB Purchase</span></td>
                                                            <td>{renderShipmentQty(tcf.actual_fob_purchase)}</td>
                                                            <td id="td-right"><span>Final Purchase Price</span></td>
                                                            <td>{renderShipmentQty(tcf.final_purchase_price)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td id="td-right"><span>Final Sales Price</span></td>
                                                            <td>{renderShipmentQty(tcf.final_sales_price)}</td>
                                                            <td id="td-right"><span>Final Purchase Amount</span></td>
                                                            <td>{renderShipmentQty(tcf.final_purchase_amount)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td id="td-right"><span>Actual Freight Rate
                                                            </span></td>
                                                            <td>{renderShipmentQty(tcf.actual_freight_rate)}</td>
                                                            <td id="td-right"><span>Actual Freight Amount
                                                            </span></td>
                                                            <td>{renderShipmentQty(tcf.actual_freight_amount)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td id="td-right"><span>Actual Insurance
                                                            </span></td>
                                                            <td>{renderShipmentQty(tcf.actual_insurance)}</td>
                                                            <td id="td-right"><span>Actual Net Margin - Shipment</span></td>
                                                            <td>{renderShipmentQty(tcf.actual_net_margin_shipment)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td id="td-right"><span>Actual Commission	</span></td>
                                                            <td>{renderShipmentQty(tcf.actual_commission)}</td>
                                                            <td id="td-right"><span>Actual Net Margin - Ton</span></td>
                                                            <td>{renderShipmentQty(tcf.actual_net_margin_ton)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td id="td-right"><span>Actual Gross Margin - Shipment</span></td>
                                                            <td>{renderShipmentQty(tcf.actual_gross_margin_shipment)}</td>
                                                            <td id="td-right"><span>Actual Gross Margin - Ton</span></td>
                                                            <td>{renderShipmentQty(tcf.actual_gross_margin_ton)}</td>
                                                        </tr>



                                                    </tbody>
                                                </table>


                                                <div className="col-md-12" id="ax.account_details">
                                                    <h4 className="heading">Shipment Cost</h4>
                                                    <hr></hr>
                                                </div>

                                                <table class="table table-bordered account-table tables">
                                                    <tbody>
                                                        <tr>
                                                            <td id="td-right"><span>Actual LC Transfer Cost</span></td>
                                                            <td>{renderShipmentQty(tcf.actual_lc_transfer_cost)}</td>
                                                            <td id="td-right"><span>Document Discount Date</span></td>
                                                            <td>{tcf.document_discount_date}</td>
                                                        </tr>

                                                        <tr>
                                                            <td id="td-right"><span>Actual Bill Handling Charges</span></td>
                                                            <td>{renderShipmentQty(tcf.actual_bill_handling_charges)}</td>
                                                            <td id="td-right"><span>Actual Payment Received</span></td>
                                                            <td><input
                                                                type='checkbox'
                                                                name='actual_payment_received'
                                                                checked={actualCheck} // Use the state variable to determine if the checkbox is checked
                                                                onChange={handleActual} // Handle the change event
                                                                className='mx-3' disabled={isButtonEnabled}
                                                            /></td>
                                                        </tr>

                                                        <tr>
                                                            <td id="td-right"><span>Actual Adding Confirmation Charges</span></td>
                                                            <td>{renderShipmentQty(tcf.actual_adding_confirmation_charges)}</td>
                                                            <td id="td-right"><span>Actual Payment Received Date</span></td>
                                                            <td>{tcf.actual_payment_received_date}</td>
                                                        </tr>

                                                        <tr>
                                                            <td id="td-right"><span>Actual Fixed Other Bank Cost</span></td>
                                                            <td>{renderShipmentQty(tcf.actual_fixed_other_bank_charges)}</td>
                                                            <td id="td-right"><span></span></td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td id="td-right"><span>Bank Interest/ Discounting</span></td>
                                                            <td>{renderShipmentQty(tcf.bank_interest_or_discounting)}</td>
                                                            <td id="td-right"><span>Actual Total Bank Cost</span></td>
                                                            <td>{renderShipmentQty(tcf.actual_total_bank_cost)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td id="td-right"><span>VAT on Bank Charges</span></td>
                                                            <td>{renderShipmentQty(tcf.vat_on_bank_charges)}</td>
                                                            <td id="td-right"><span>Actual Costs</span></td>
                                                            <td>{renderShipmentQty(tcf.actual_costs)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td id="td-right"><span>Actual Fixed Apportioned Overheads  </span></td>
                                                            <td>{renderShipmentQty(tcf.actual_fixed_apportioned_overheads)}</td>
                                                            <td id="td-right"><span>Actual Cost -Ton</span></td>
                                                            <td>{renderShipmentQty(tcf.actual_cost_ton)}</td>
                                                        </tr>

                                                        <tr>
                                                            <td id="td-right"><span>Actual Contract Specific Cost </span></td>
                                                            <td>{renderShipmentQty(tcf.actual_contract_specific_cost)}</td>
                                                            <td id="td-right"><span>Final Total Cost</span></td>
                                                            <td>{renderShipmentQty(tcf.final_total_cost)}</td>
                                                        </tr>

                                                        <tr>
                                                            <td id="td-right"><span>Actual Prov For Corporate Tax</span></td>
                                                            <td>{renderShipmentQty(tcf.actual_prov_for_corporate_tax)}</td>
                                                            <td id="td-right"><span>Actual Overheads</span></td>
                                                            <td>{renderShipmentQty(tcf.actual_overheads)}</td>
                                                        </tr>

                                                        <tr>
                                                            <td id="td-right"><span>Actual Withholding Tax</span></td>
                                                            <td>{renderShipmentQty(tcf.actual_withholding_tax)}</td>
                                                            <td id="td-right"><span></span></td>
                                                            <td>{ }</td>
                                                        </tr>

                                                    </tbody>
                                                </table>


                                                <div className="col-md-12" id="ax.account_details">
                                                    <h4 className="heading">Debit Note Details</h4>
                                                    <hr></hr>
                                                </div>

                                                <table class="table table-bordered account-table tables">
                                                    <tbody>
                                                        <tr>
                                                            <td id="td-right"><span>Supplier Dem/Despatch
                                                            </span></td>
                                                            <td>{tcf.supplier_dem_or_despatch}</td>
                                                            <td id="td-right"><span>Buyer Dem/Despatch	</span></td>
                                                            <td>{tcf.buyer_dem_or_despatch}</td>
                                                        </tr>

                                                        <tr>
                                                            <td id="td-right"><span>Supplier Dem/Despatch DN Amount</span></td>
                                                            <td>{renderShipmentQty(tcf.supplier_dem_or_despatch_DN_amount)}</td>
                                                            <td id="td-right"><span>Buyer Dem/Despatch DN Amount</span></td>
                                                            <td>{renderShipmentQty(tcf.buyer_dem_or_despatch_DN_amount)}</td>
                                                        </tr>

                                                        <tr>
                                                            <td id="td-right"><span>supplier DN amount</span></td>
                                                            <td>{renderShipmentQty(tcf.supplier_DN_amount)}</td>
                                                            <td id="td-right"><span>buyer DN amount</span></td>
                                                            <td>{renderShipmentQty(tcf.buyer_DN_amount)}</td>
                                                        </tr>

                                                    </tbody>
                                                </table>

                                                <div className="col-md-12" id="ax.account_details">
                                                    <h4 className="heading">Rwood Internal Information</h4>
                                                    <hr></hr>
                                                </div>

                                                <table class="table table-bordered account-table tables">
                                                    <tbody>
                                                        <tr>
                                                            <td id="td-right"><span>any deviation
                                                            </span></td>
                                                            <td>{tcf.any_deviation}</td>
                                                            <td id="td-right"><span>	</span></td>
                                                            <td></td>
                                                        </tr>

                                                        <tr>
                                                            <td id="td-right"><span>prepared by</span></td>
                                                            <td>{tcf.prepared_by}</td>

                                                        </tr>

                                                        <tr>
                                                            <td id="td-right"><span>Approved By	</span></td>
                                                            <td>{tcf.check_and_approved_by}</td>
                                                            <td id="td-right"><span></span></td>
                                                            <td>{ }</td>
                                                        </tr>
                                                        <tr>
                                                            <td id="td-right"><span>Check and Validated By</span></td>
                                                            <td>{tcf.validated_by}</td>
                                                            <td id="td-right"><span></span></td>
                                                            <td>{ }</td>
                                                        </tr>
                                                        <tr>
                                                            <td id="td-right"><span>remarks</span></td>
                                                            <td>{tcf.remarks}</td>
                                                            <td id="td-right"><span></span></td>
                                                            <td>{ }</td>
                                                        </tr>
                                                        <tr>
                                                            <td id="td-right"><span>Created By</span></td>
                                                            <td>{tcf.created_by}</td>
                                                            <td id="td-right"><span>Last Modified By
                                                            </span></td>
                                                            <td>{tcf.modified_by}</td>
                                                        </tr>

                                                    </tbody>
                                                </table>


                                                <Row id="table-styles">
                                                    <div className="col-md-12" id="head">
                                                        <h4 className="heading"><span>Rw Payable/ Receivable LineItem </span>
                                                            <span>
                                                                <Popup className='post-buyer'
                                                                    trigger={
                                                                        <a className="button add-accountrecord">
                                                                            {" "}
                                                                            New
                                                                        </a>
                                                                    }
                                                                    modal
                                                                >
                                                                    <div className="form-group">
                                                                        <Form.Check
                                                                            type='radio'
                                                                            id=''
                                                                            name='type'
                                                                            label='Buyer - Credit Note'
                                                                            value='Buyer - Credit Note'
                                                                        />
                                                                        <Form.Check
                                                                            type='radio'
                                                                            id=''
                                                                            name='type'
                                                                            label='Supplier - Credit Note'
                                                                            value='Supplier - Credit Note'
                                                                        />
                                                                        <Form.Check
                                                                            type='radio'
                                                                            id=''
                                                                            name='type'
                                                                            label='Buyer - Debit Note'
                                                                            value='Buyer - Debit Note'
                                                                        />
                                                                        <Form.Check
                                                                            type='radio'
                                                                            id=''
                                                                            name='type'
                                                                            label='Supplier - Debit Note'
                                                                            value='Supplier - Debit Note'
                                                                        />
                                                                        <Form.Check
                                                                            type='radio'
                                                                            id=''
                                                                            name='type'
                                                                            label='Load Port Invoice'
                                                                            value='Load Port Invoice'
                                                                        />
                                                                        <Form.Check
                                                                            type='radio'
                                                                            id=''
                                                                            name='type'
                                                                            label='Other Payable'
                                                                            value='Other Payable'
                                                                        />
                                                                        <Form.Check
                                                                            type='radio'
                                                                            id=''
                                                                            name='type'
                                                                            label='Other Receivable'
                                                                            value='Other Receivable'
                                                                        />
                                                                        <Form.Check
                                                                            type='radio'
                                                                            id=''
                                                                            name='type'
                                                                            label='Purchase Invoice'
                                                                            value='Purchase Invoice'
                                                                        />
                                                                        <Form.Check
                                                                            type='radio'
                                                                            id=''
                                                                            name='type'
                                                                            label='Sales Invoice'
                                                                            value='Sales Invoice'
                                                                        />
                                                                        <Form.Check
                                                                            type='radio'
                                                                            id=''
                                                                            name='type'
                                                                            label='Surveyor Invoice'
                                                                            value='Surveyor Invoice'
                                                                        />

                                                                    </div>
                                                                    <input
                                                                        type="submit"
                                                                        className="btn btn-primary "
                                                                        value="Submit"
                                                                        onClick={handleClick}
                                                                    />

                                                                </Popup>
                                                            </span>

                                                        </h4>
                                                        <hr></hr>
                                                    </div>
                                                    <table id="rw" className="">
                                                        <thead>
                                                            <tr>
                                                                <th>RW Billing	</th>
                                                                <th>Document Date	</th>
                                                                <th>Record Type	</th>
                                                                <th>Line Item	</th>
                                                                <th>Account</th>
                                                                <th>Order No & Vessel Name	</th>
                                                                <th>Amount</th>
                                                                <th>Paid / Received Amount	</th>

                                                                <th>Balance Due	</th>
                                                                <th>Status</th>                    </tr>
                                                        </thead>

                                                        {receivable.length > 0 ? (
                                                            <tbody>
                                                                {receivable.map((y, i) => (
                                                                    <tr>
                                                                        <td> <Link to={"/tcf/receivable-details/" + y.rw_pay_recieve_id}>
                                                                            {y.rw_billing}
                                                                        </Link></td>
                                                                        <td>{y.document_date}</td>
                                                                        <td>{y.record_type}</td>
                                                                        <td>{y.line_item}</td>
                                                                        <td><Link to={`/accounts/${y.account_id}`}>{y.account}</Link></td>
                                                                        <td></td>
                                                                        <td>{renderShipmentQty(y.amount)}</td>
                                                                        <td>{renderShipmentQty(y.paid_recieved_amount)}</td>
                                                                        <td>{renderShipmentQty(y.balance_due)}</td>
                                                                        <td>{y.status}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        ) : (
                                                            <tbody>
                                                                <tr>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>

                                                                    <td>No data available</td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>                      </tr>
                                                            </tbody>
                                                        )}
                                                    </table>
                                                </Row>
                                                <Row id="table-styles">
                                                    <div className="col-md-12" id="head">
                                                        <h4 className="heading"><span>Rw Payable/ Receipt LineItem </span><span>
                                                            <Popup className='post-buyer'
                                                                trigger={
                                                                    <a className="button add-accountrecord">
                                                                        {" "}
                                                                        New
                                                                    </a>
                                                                }
                                                                modal
                                                            >
                                                                <div className="form-group" >
                                                                    <Form.Check
                                                                        type='radio'
                                                                        id=''
                                                                        name='type'
                                                                        label='Buyer - Credit Note'
                                                                        value='Buyer - Credit Note'
                                                                    />
                                                                    <Form.Check
                                                                        type='radio'
                                                                        id=''
                                                                        name='type'
                                                                        label='Supplier - Credit Note'
                                                                        value='Supplier - Credit Note'
                                                                    />
                                                                    <Form.Check
                                                                        type='radio'
                                                                        id=''
                                                                        name='type'
                                                                        label='Buyer - Debit Note'
                                                                        value='Buyer - Debit Note'
                                                                    />
                                                                    <Form.Check
                                                                        type='radio'
                                                                        id=''
                                                                        name='type'
                                                                        label='Supplier - Debit Note'
                                                                        value='Supplier - Debit Note'
                                                                    />
                                                                    <Form.Check
                                                                        type='radio'
                                                                        id=''
                                                                        name='type'
                                                                        label='Load Port Invoice'
                                                                        value='Load Port Invoice'
                                                                    />
                                                                    <Form.Check
                                                                        type='radio'
                                                                        id=''
                                                                        name='type'
                                                                        label='Other Payable'
                                                                        value='Other Payable'
                                                                    />
                                                                    <Form.Check
                                                                        type='radio'
                                                                        id=''
                                                                        name='type'
                                                                        label='Other Receivable'
                                                                        value='Other Receivable'
                                                                    />
                                                                    <Form.Check
                                                                        type='radio'
                                                                        id=''
                                                                        name='type'
                                                                        label='Purchase Invoice'
                                                                        value='Purchase Invoice'
                                                                    />
                                                                    <Form.Check
                                                                        type='radio'
                                                                        id=''
                                                                        name='type'
                                                                        label='Sales Invoice'
                                                                        value='Sales Invoice'
                                                                    />
                                                                    <Form.Check
                                                                        type='radio'
                                                                        id=''
                                                                        name='type'
                                                                        label='Surveyor Invoice'
                                                                        value='Surveyor Invoice'
                                                                    />

                                                                </div>
                                                                <input
                                                                    type="submit"
                                                                    className="btn btn-primary "
                                                                    value="Submit"

                                                                />

                                                            </Popup>
                                                        </span>

                                                        </h4>
                                                        <hr></hr>
                                                    </div>
                                                    <table id="receipt" className="">
                                                        <thead>
                                                            <tr>
                                                                <th>Rw payment receipt</th>
                                                                <th>Payment/Receipt Date</th>
                                                                <th>Record Type</th>
                                                                <th>Line Item</th>
                                                                <th>Vessel Name</th>
                                                                <th>Amount</th>
                                                                <th>Account</th>
                                                                <th>RW Billing</th>
                                                                <th>Order</th>
                                                                <th>Description</th>
                                                            </tr>
                                                        </thead>

                                                        {receipt.length > 0 ? (
                                                            <tbody>
                                                                {receipt.map((y, i) => (
                                                                    <tr>
                                                                        <td><Link to={'/tcf/tcf-receipt-details/' + y.rw_pay_receipt_detail_id}>{y.rw_payment_receipt}</Link></td>
                                                                        <td>{y.payment_receipt_date}</td>
                                                                        <td>{y.record_type}</td>
                                                                        <td>{y.line_item}</td>
                                                                        <td>{y.vessel_name}</td>
                                                                        <td>{renderShipmentQty(y.amount)}</td>
                                                                        <td><Link to={`/accounts/${y.account_id}`}>{y.account}</Link></td>
                                                                        <td> <Link to={"/tcf/receivable-details/" + y.rw_pay_recieve_id}>
                                                                            {y.rw_billing}
                                                                        </Link></td>
                                                                        <td><Link to={`/order/view-order-details/${y.order_id}`}>{y.order}</Link></td>
                                                                        <td>{y.description}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        ) : (
                                                            <tbody>
                                                                <tr>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td><td></td>
                                                                    <td></td>

                                                                    <td>No data available</td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                </tr>
                                                            </tbody>
                                                        )}
                                                    </table>
                                                </Row>
                                                <Row id="table-styles">
                                                    <div className="col-md-12" id="head">
                                                        <h4 className="heading"><span>Rw Payable/ Receipt LineItem </span><span>
                                                            <Popup className='post-buyer'
                                                                trigger={
                                                                    <a className="button add-accountrecord">
                                                                        {" "}
                                                                        New
                                                                    </a>
                                                                }
                                                                modal
                                                            >
                                                                <div className="form-group" >
                                                                    <Form.Check
                                                                        type='radio'
                                                                        id=''
                                                                        name='type'
                                                                        label='Buyer - Credit Note'
                                                                        value='Buyer - Credit Note'
                                                                    />
                                                                    <Form.Check
                                                                        type='radio'
                                                                        id=''
                                                                        name='type'
                                                                        label='Supplier - Credit Note'
                                                                        value='Supplier - Credit Note'
                                                                    />
                                                                    <Form.Check
                                                                        type='radio'
                                                                        id=''
                                                                        name='type'
                                                                        label='Buyer - Debit Note'
                                                                        value='Buyer - Debit Note'
                                                                    />
                                                                    <Form.Check
                                                                        type='radio'
                                                                        id=''
                                                                        name='type'
                                                                        label='Supplier - Debit Note'
                                                                        value='Supplier - Debit Note'
                                                                    />
                                                                    <Form.Check
                                                                        type='radio'
                                                                        id=''
                                                                        name='type'
                                                                        label='Load Port Invoice'
                                                                        value='Load Port Invoice'
                                                                    />
                                                                    <Form.Check
                                                                        type='radio'
                                                                        id=''
                                                                        name='type'
                                                                        label='Other Payable'
                                                                        value='Other Payable'
                                                                    />
                                                                    <Form.Check
                                                                        type='radio'
                                                                        id=''
                                                                        name='type'
                                                                        label='Other Receivable'
                                                                        value='Other Receivable'
                                                                    />
                                                                    <Form.Check
                                                                        type='radio'
                                                                        id=''
                                                                        name='type'
                                                                        label='Purchase Invoice'
                                                                        value='Purchase Invoice'
                                                                    />
                                                                    <Form.Check
                                                                        type='radio'
                                                                        id=''
                                                                        name='type'
                                                                        label='Sales Invoice'
                                                                        value='Sales Invoice'
                                                                    />
                                                                    <Form.Check
                                                                        type='radio'
                                                                        id=''
                                                                        name='type'
                                                                        label='Surveyor Invoice'
                                                                        value='Surveyor Invoice'
                                                                    />

                                                                </div>
                                                                <input
                                                                    type="submit"
                                                                    className="btn btn-primary "
                                                                    value="Submit"

                                                                />

                                                            </Popup>
                                                        </span>

                                                        </h4>
                                                        <hr></hr>
                                                    </div>
                                                    <table id="receipt" className="">
                                                        <thead>
                                                            <tr>
                                                                <th>Rw payment receipt</th>
                                                                <th>Payment/Receipt Date</th>
                                                                <th>Record Type</th>
                                                                <th>Line Item</th>
                                                                <th>Vessel Name</th>
                                                                <th>Amount</th>
                                                                <th>Account</th>
                                                                <th>RW Billing</th>
                                                                <th>Order</th>
                                                                <th>Description</th>
                                                            </tr>
                                                        </thead>

                                                        {receipt.length > 0 ? (
                                                            <tbody>
                                                                {receipt.map((y, i) => (
                                                                    <tr>
                                                                        <td><Link to={'/tcf/tcf-receipt-details/' + y.rw_pay_receipt_detail_id}>{y.rw_payment_receipt}</Link></td>
                                                                        <td>{y.payment_receipt_date}</td>
                                                                        <td>{y.record_type}</td>
                                                                        <td>{y.line_item}</td>
                                                                        <td>{y.vessel_name}</td>
                                                                        <td>{renderShipmentQty(y.amount)}</td>
                                                                        <td><Link to={`/accounts/${y.account_id}`}>{y.account}</Link></td>
                                                                        <td> <Link to={"/tcf/receivable-details/" + y.rw_pay_recieve_id}>
                                                                            {y.rw_billing}
                                                                        </Link></td>
                                                                        <td><Link to={`/order/view-order-details/${y.order_id}`}>{y.order}</Link></td>
                                                                        <td>{y.description}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        ) : (
                                                            <tbody>
                                                                <tr>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td><td></td>
                                                                    <td></td>

                                                                    <td>No data available</td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                </tr>
                                                            </tbody>
                                                        )}
                                                    </table>
                                                </Row>
                                                <Row id="table-styles">
                                                    <div className="col-md-12" id="head">
                                                        <h4 className="heading">
                                                            <span>Notes & Attachments</span>
                                                            <span>
                                                                <i className=""></i> New Note </span>
                                                            <span>
                                                                <i className=""></i> Attach File </span>
                                                        </h4>
                                                        <hr>
                                                        </hr>
                                                    </div>
                                                    <table id="history" className="">
                                                        <tr>
                                                            <td>
                                                                <p class="no-records"> No records to display </p>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </Row>

                                                <Row id="table-styles">
                                                    <div className="col-md-12" id="head">
                                                        <h4 className="heading">
                                                            <span>Trade Confirmation Form History</span>
                                                        </h4>
                                                        <hr></hr>
                                                    </div>
                                                    <table id="history" className="">
                                                        <thead>
                                                            <tr>
                                                                <th>Field Name</th>
                                                                <th>New Value</th>
                                                                <th>Old Value</th>
                                                                <th>Changed By</th>
                                                                <th>Change Time</th>
                                                            </tr>
                                                        </thead>

                                                        {history.length > 0 ? (
                                                            <tbody>
                                                                {history.map((y, i) => (
                                                                    <tr>
                                                                        <td>{y.field_name}</td>
                                                                        <td>{y.new_value}</td>
                                                                        <td>{y.old_value}</td>
                                                                        <td>{y.changed_by}</td>
                                                                        <td>{y.change_time}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        ) : (
                                                            <tbody>
                                                                <tr>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td>No data available</td>
                                                                    <td></td>
                                                                    <td></td>
                                                                </tr>
                                                            </tbody>
                                                        )}
                                                    </table>
                                                </Row>
                                                {/*  */}

                                            </div>
                                        </div>
                                        </div>
                                        </div>
                                <Footer />
                            </div>

                        )}
                    </>

                ) : (
                    <>
                        {tcf.record_type === 'Buyer - Spot' ? (
                            <>
                                {isEditMode ? (
                                    <div id="view-page">
                                        <Header />
                                        <div className="contactAccount">
                                            <div className="row">
                                                <div className="col-md-2 recent-item">
                                                    <RecentItems />
                                                </div>
                                                <div className="col-md-10 right-section">
                                                    <div className="page-header" id='add-account'>
                                                        <div>
                                                            <h2 className="main-content-title tx-24 mg-b-5">TCF Details</h2>
                                                            <ol className="breadcrumb">
                                                                <li className="breadcrumb-item active" aria-current="page">Dashboard / TCF Details</li>
                                                            </ol>
                                                        </div>

                                                    </div>

                                                <div className="tasks column-gap fulid-section order-section">
                                                    <div id="messageContainer"></div>
                                                    <p className="edit-btn">
                                                        <input
                                                            type="submit"
                                                            className="account-save"
                                                            onClick={handleSaveEdit}
                                                            value="Save"
                                                        //disabled={isSubmitted}
                                                        />
                                                        <button className="btn btn-primary" onClick={handleCancelEdit}>
                                                            Cancel
                                                        </button>
                                                    </p>

                                                    <Row className="mb-3">
                                                        <h4 className="heading">Information</h4>
                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="TCF no"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control

                                                                    type="text"
                                                                    tabIndex={1}
                                                                    placeholder="TCF no"
                                                                    name="tcf_no"
                                                                    defaultValue={tcf.tcf_no || ""} onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6">
                                                            <label>Locked</label>
                                                            <input
                                                                type='checkbox'
                                                                name='locked'
                                                                tabIndex={14}
                                                                checked={lockedOne} // Use the state variable to determine if the checkbox is checked
                                                                onChange={handleCheckboxLocked} // Handle the change event
                                                                className='mx-3'
                                                            />
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6">

                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="TCF date"
                                                                className="mb-3 datepick"


                                                            >
                                                                <DatePicker
                                                                    selected={tcfDate}
                                                                    onChange={handleTcfDate}
                                                                    selectsStart
                                                                    tabIndex={2}
                                                                    dateFormat='dd/MM/yyyy'
                                                                    placeholderText="Select"
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Record type"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control

                                                                    type="text"
                                                                    placeholder="Record type"
                                                                    tabIndex={15}
                                                                    name="record_type"
                                                                    defaultValue={tcf.record_type || ""} onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6">

                                                            <FloatingLabel controlId="floatingSelect"
                                                                className='dropDown' label="Company">
                                                                <Form.Select aria-label="Floating label select example"
                                                                    name="company"
                                                                    placeholder="Company"
                                                                    defaultValue={tcf.company || ""}
                                                                    tabIndex={3}
                                                                    onChange={handleChangeInput}
                                                                >
                                                                    <option value='' >Select</option>
                                                                    {
                                                                        companies.map((x) => {
                                                                            return (
                                                                                <option value={x.company_name}>{x.company_name}</option>
                                                                            )
                                                                        })
                                                                    }
                                                                </Form.Select>

                                                            </FloatingLabel>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Opportunity"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    defaultValue={tcf.opportunity || ""}
                                                                    onChange={handleChangeInput}
                                                                    type="text"
                                                                    tabIndex={16}
                                                                    placeholder="opportunity"
                                                                    name="opportunity"
                                                                    disabled
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6">

                                                            <FloatingLabel controlId="floatingSelect"
                                                                className='dropDown' label="Currency">
                                                                <Form.Select aria-label="Floating label select example"
                                                                    name="currency"
                                                                    tabIndex={4}
                                                                    placeholder="Currency"
                                                                    defaultValue={tcf.currency || ""}
                                                                    onChange={handleChangeInput}
                                                                >
                                                                    <option value='' >Select</option>
                                                                    <option value='USD' >USD</option>
                                                                    <option value='IDR' >IDR</option>

                                                                </Form.Select>

                                                            </FloatingLabel>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="quote"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="quote"
                                                                    name="quote"
                                                                    tabIndex={17}
                                                                    defaultValue={tcf.quote || ""}
                                                                    onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Exchange rate"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="text"
                                                                    tabIndex={5}
                                                                    placeholder="Exchange rate"
                                                                    name="exchange_rate"
                                                                    defaultValue={tcf.exchange_rate || ""}
                                                                    onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6">

                                                            <FloatingLabel controlId="floatingSelect"
                                                                className='dropDown' label="Trader Name">
                                                                <Form.Select aria-label="Floating label select example"
                                                                    name="trader_name"
                                                                    placeholder="Trader Name"
                                                                    tabIndex={18}
                                                                    defaultValue={tcf.trader_name || ""}
                                                                    onChange={handleChangeInput}

                                                                >
                                                                    <option value='' >Select</option>
                                                                    {
                                                                        rwoodusers.map((x) => {
                                                                            return (
                                                                                <option value={x.rwood_username}>{x.rwood_username}</option>
                                                                            )
                                                                        })
                                                                    }
                                                                </Form.Select>

                                                            </FloatingLabel>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="order"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="order"
                                                                    name="order"
                                                                    tabIndex={6}
                                                                    defaultValue={tcf.order || ""}
                                                                    onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="owner"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="owner"
                                                                    name="owner"
                                                                    tabIndex={19}
                                                                    defaultValue={tcf.owner || ""}
                                                                    onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>

                                                        <Form.Group as={Col} md="6"></Form.Group>
                                                        <Form.Group as={Col} md="6">
                                                            <label>Auto select pricebook</label>
                                                            <input
                                                                type='checkbox'
                                                                name='auto_select_pricebook'
                                                                checked={lockeds} // Use the state variable to determine if the checkbox is checked
                                                                tabIndex={20}
                                                                onChange={handleCheckboxChange} // Handle the change event
                                                                className='mx-3'
                                                            />
                                                        </Form.Group>

                                                        {/* <Form.Group as={Col} md="6">
  <FloatingLabel controlId="floatingSelect" className="dropDown" label="Rwood bank master">
    <Form.Select
      aria-label="Floating label select example"
      name="rwood_bank_master"
      placeholder="bank master master"
      defaultValue={tcf.rwood_bank_master || ""}
      onChange={handleChangeInput}
    >
      <option value="">Select</option>
      {
        // Sort the datas array alphabetically by rwood_bank_name
        datas
          .sort((a, b) => a.rwood_bank_name.localeCompare(b.rwood_bank_name))
          .map((x) => (
            <option key={x.rwood_bank_name} value={x.rwood_bank_name}>
              {x.rwood_bank_name}
            </option>
          ))
      }
    </Form.Select>
  </FloatingLabel>
</Form.Group> */}
                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel controlId="floatingSelect" className="dropDown" label="Rwood bank master">
                                                                <Form.Select
                                                                    aria-label="Floating label select example"
                                                                    name="rwood_bank_master"
                                                                    tabIndex={7}
                                                                    placeholder="bank master"
                                                                    defaultValue={tcf.rwood_bank_master || ""}
                                                                    onChange={handleChangeInput}
                                                                >
                                                                    <option value="">Select</option>
                                                                    {
                                                                        // Ensure datas is an array and sort it alphabetically by rwood_bank_name
                                                                        (Array.isArray(datas) ? datas : [])
                                                                            .sort((a, b) => a.rwood_bank_name.localeCompare(b.rwood_bank_name))
                                                                            .map((x) => (
                                                                                <option key={x.rwood_bank_name} value={x.rwood_bank_name}>
                                                                                    {x.rwood_bank_name}
                                                                                </option>
                                                                            ))
                                                                    }
                                                                </Form.Select>
                                                            </FloatingLabel>
                                                        </Form.Group>

                                                        <Form.Group as={Col} md="6">

                                                            <FloatingLabel controlId="floatingSelect"
                                                                className='dropDown' label="Price book">
                                                                <Form.Select aria-label="Floating label select example"
                                                                    name="price_book"
                                                                    placeholder="Price book"
                                                                    tabIndex={21}
                                                                    defaultValue={tcf.price_book || ""}
                                                                    onChange={handleChangeInput}

                                                                >
                                                                    <option value='' >Select</option>
                                                                    {
                                                                        priceBook.map((x) => {
                                                                            return (
                                                                                <option value={x.price_book_name}>{x.price_book_name}</option>
                                                                            )
                                                                        })
                                                                    }
                                                                </Form.Select>

                                                            </FloatingLabel>
                                                        </Form.Group>

                                                        <Form.Group as={Col} md="6">

                                                            <FloatingLabel controlId="floatingSelect"
                                                                className='dropDown' label="TCF Status">
                                                                <Form.Select aria-label="Floating label select example"
                                                                    name="tcf_status"
                                                                    placeholder="TCF Status"
                                                                    defaultValue={tcf.tcf_status || ""}
                                                                    tabIndex={8}
                                                                    onChange={handleChangeInput}
                                                                >
                                                                    <option value="Draft">Draft</option><option value="Proposed">Proposed</option><option value="Proposed-Long Term Contract">Proposed-Long Term Contract</option><option value="Projected">Projected</option><option value="Actual Shipment Completed">Shipment Done</option><option value="Completed">Completed</option><option value="Cancelled">Z Cancelled</option><option value="Draft - Long Term">Draft - Long Term</option><option value="Shipments - Arutmin">Shipments - Arutmin</option><option value="Cancelled with Penalty">Cancelled with Penalty</option>
                                                                </Form.Select>

                                                            </FloatingLabel>
                                                        </Form.Group>

                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Purchase confirmation date"
                                                                className="mb-3 datepick"


                                                            >

                                                                <DatePicker
                                                                    selected={purchaseConfirmDate}
                                                                    onChange={handlePuchaseConfirm}
                                                                    selectsStart
                                                                    tabIndex={22}
                                                                    dateFormat='dd/MM/yyyy'
                                                                    placeholderText="Select"
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Buyer contract"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    disabled
                                                                    type="text"
                                                                    tabIndex={9}
                                                                    placeholder="Buyer contract"
                                                                    name="buyer_contract"
                                                                    defaultValue={tcf.buyer_contract || ""}
                                                                    onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6"></Form.Group>
                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Contract shipment"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="text"
                                                                    disabled
                                                                    tabIndex={10}
                                                                    placeholder="Contract shipment"
                                                                    name="contract_shipment"
                                                                    defaultValue={tcf.contract_shipment || ""}
                                                                    onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6"></Form.Group>
                                                        <Form.Group as={Col} md="6">

                                                            <FloatingLabel controlId="floatingSelect"
                                                                className='dropDown' label="Product Master">
                                                                <Form.Select aria-label="Floating label select example"
                                                                    name="product_master"
                                                                    placeholder="Product Master"
                                                                    defaultValue={tcf.product_master}
                                                                    tabIndex={11}
                                                                    onChange={handleChangeInput}
                                                                >
                                                                    <option value='' >Select</option>
                                                                    {
                                                                        product.map((x) => {
                                                                            return (
                                                                                <option value={x.product_code}>{x.product_code}</option>
                                                                            )
                                                                        })
                                                                    }
                                                                </Form.Select>
                                                            </FloatingLabel>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6"> </Form.Group>
                                                        <Form.Group as={Col} md="6">

                                                            <FloatingLabel controlId="floatingSelect"
                                                                className='dropDown' label="Product">
                                                                <Form.Select aria-label="Floating label select example"
                                                                    name="product"
                                                                    placeholder="Product"
                                                                    tabIndex={12}
                                                                    defaultValue={tcf.product}
                                                                    onChange={handleChangeInput}
                                                                >
                                                                    <option value='' >Select</option>
                                                                    {
                                                                        productName.map((x) => {
                                                                            return (
                                                                                <option value={x.product_name}>{x.product_name}</option>
                                                                            )
                                                                        })
                                                                    }
                                                                </Form.Select>
                                                            </FloatingLabel>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6"> </Form.Group>
                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Product name"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Product name"
                                                                    name="product_name"
                                                                    tabIndex={13}
                                                                    defaultValue={tcf.product_name || ""}
                                                                    onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>
                                                    </Row>
                                                    {/* <Row className="mb-3">
           <h4 className="heading">Shipment Summary</h4>
           <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="shipment gross margin ton"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="shipment gross margin ton"
              name="shipment_gross_margin_ton"
              defaultValue={tcf.shipment_gross_margin_ton || ""}
     onChange={handleChangeInput}
     disabled
              />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="shipment_sales_price"  m nbv
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="shipment sales price"
              name="shipment_sales_price"
              defaultValue={tcf.shipment_sales_price || ""}
     onChange={handleChangeInput}
     disabled
              />
          </FloatingLabel>
        </Form.Group>

           </Row> */}

                                                    <Row>
                                                        <h4 className='heading'>Trade Confirmation Details</h4>
                                                        <Form.Group as={Col} md="6">

                                                            <FloatingLabel controlId="floatingSelect"
                                                                className='dropDown' label="Buyer">
                                                                <Form.Select aria-label="Floating label select example"
                                                                    name="buyer"
                                                                    placeholder="Buyer"
                                                                    tabIndex={23}
                                                                    defaultValue={tcf.buyer || ""}
                                                                    onChange={handleChangeInput}
                                                                >
                                                                    <option value='' >Select</option>
                                                                    {
                                                                        buyers.map((x) => {
                                                                            return (
                                                                                <option value={x.account_name}>{x.account_name}</option>
                                                                            )
                                                                        })
                                                                    }
                                                                </Form.Select>
                                                            </FloatingLabel>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6">

                                                            <FloatingLabel controlId="floatingSelect"
                                                                className='dropDown' label="status of purchase contract" >
                                                                <Form.Select aria-label="Floating label select example"
                                                                    name="status_of_purchase_contract"
                                                                    tabIndex={34}
                                                                    placeholder="status of purchase contract"
                                                                    defaultValue={tcf.status_of_purchase_contract || ""}
                                                                    onChange={handleChangeInput}
                                                                >
                                                                    <option value="">--None--</option><option value="Draft">Draft</option><option value="Activated">Activated</option><option value="In Approval Process">In Approval Process</option><option value="Under Review">Under Review</option><option value="Signed Contract yet to receive">Signed Contract yet to receive</option><option value="Signed Contract Sent for Sign">Signed Contract Sent for Sign</option><option value="Draft Sent for Signing">Draft Sent for Signing</option><option value="Draft Received for Signing">Draft Received for Signing</option><option value="Signed Original Contract Need to Courier">Signed Original Contract Need to Courier</option><option value="Signed Original Contract yet to Receive">Signed Original Contract yet to Receive</option><option value="Original Contract Signed by Both Parties">Original Contract Signed by Both Parties</option><option value="Original Contract Signed and Received by Both Parties">Original Contract Signed and Received by Both Parties</option>
                                                                </Form.Select>
                                                            </FloatingLabel>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6">

                                                            <FloatingLabel controlId="floatingSelect"
                                                                className='dropDown' label="Supplier">
                                                                <Form.Select aria-label="Floating label select example"
                                                                    name="supplier"
                                                                    tabIndex={24}
                                                                    placeholder="Supplier"
                                                                    defaultValue={tcf.supplier || ""}
                                                                    onChange={handleChangeInput}

                                                                >
                                                                    <option value='' >Select</option>
                                                                    {
                                                                        suppliers.map((x) => {
                                                                            return (
                                                                                <option value={x.account_name}>{x.account_name}</option>
                                                                            )
                                                                        })
                                                                    }
                                                                </Form.Select>
                                                            </FloatingLabel>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6"></Form.Group>

                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Product Description"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    as="textarea"
                                                                    placeholder="Product Description"
                                                                    tabIndex={25}
                                                                    name="product_description"
                                                                    defaultValue={tcf.product_description || ""}
                                                                    onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>

                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Proposed laycan from"
                                                                className="mb-3 datepick"


                                                            >
                                                                <DatePicker
                                                                    selected={proposedDates}
                                                                    onChange={handleProposeDate}
                                                                    selectsStart
                                                                    dateFormat='dd/MM/yyyy'
                                                                    tabIndex={35}
                                                                    placeholderText="Select"
                                                                    disabled={true}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="product specification"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="product specification"
                                                                    name="product_specification"
                                                                    defaultValue={tcf.product_specification || ""}
                                                                    tabIndex={26}
                                                                    onChange={handleChangeInput}

                                                                />

                                                            </FloatingLabel>
                                                        </Form.Group>

                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Proposed laycan to"
                                                                tabIndex={36}
                                                                className="mb-3 datepick"


                                                            >
                                                                <DatePicker
                                                                    selected={proposedDates1}
                                                                    onChange={handleProposeDate1}
                                                                    selectsStart
                                                                    dateFormat='dd/MM/yyyy'
                                                                    placeholderText="Select"
                                                                    disabled={true}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Quantity MT"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                tabIndex={27}
                                                                    type="text"
                                                                    placeholder="Quantity MT"
                                                                    name="quantity_MT"
                                                                    defaultValue={tcf.quantity_MT || ""}
                                                                    onChange={handleChangeInput}

                                                                />

                                                            </FloatingLabel>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6"></Form.Group>
                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingSelect"
                                                                className="dropDown"
                                                                label="price basis"
                                                            >
                                                                <Form.Select
                                                                    aria-label="Floating label select example"
                                                                    name="price_basis"
                                                                    placeholder="price basis"
                                                                    tabIndex={28}
                                                                    defaultValue={tcf.price_basis || ""}
                                                                    onChange={handleChangeInput}

                                                                >
                                                                    <option value="">--None--</option><option value="GCV (ARB)">GCV (ARB)</option><option value="GCV (ADB)">GCV (ADB)</option><option value="NCV (ARB)">NCV (ARB)</option></Form.Select>

                                                            </FloatingLabel>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6"></Form.Group>
                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingSelect"
                                                                className="dropDown"
                                                                label="Vessel Type"
                                                            >
                                                                <Form.Select
                                                                    aria-label="Floating label select example"
                                                                    name="vessel_type"
                                                                    defaultValue={tcf.vessel_type || ""}
                                                                    onChange={handleChangeInput}
                                                                    tabIndex={29}
                                                                    placeholder="Vessel Type"
                                                                >
                                                                    <option value="">--None--</option><option value="Geared (GnG)">Geared (GnG)</option><option value="Gearless">Gearless</option><option value="Geared">Geared</option><option value="Barge">Barge</option>
                                                                </Form.Select>

                                                            </FloatingLabel>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6"></Form.Group>

                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Payment term"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="text"
                                                                    tabIndex={30}
                                                                    placeholder="Payment term"
                                                                    name="payment_term"
                                                                    defaultValue={tcf.payment_term || ""}
                                                                    onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6"></Form.Group>
                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Selling terms"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Selling terms"
                                                                    name="selling_terms"
                                                                    tabIndex={31}
                                                                    defaultValue={tcf.selling_terms || ""}
                                                                    onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6"></Form.Group>
                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Buying terms"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="text"
                                                                    tabIndex={32}
                                                                    placeholder="Buying terms"
                                                                    defaultValue={tcf.buying_terms || ""}
                                                                    onChange={handleChangeInput}
                                                                    name="buying_terms"
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>

                                                    </Row>
                                                    <Form.Group as={Col} md="6"></Form.Group>
                                                    <Form.Group as={Col} md="6">
                                                        <FloatingLabel
                                                            controlId="floatingInput"
                                                            label="Price adjustment"
                                                            className="mb-3"


                                                        >
                                                            <Form.Control
                                                                as="textarea"
                                                                placeholder="Price adjustment"
                                                                name="price_adjustment"
                                                                tabIndex={33}
                                                                defaultValue={tcf.price_adjustment || ""}
                                                                onChange={handleChangeInput}
                                                            />
                                                        </FloatingLabel>
                                                    </Form.Group> <Form.Group as={Col} md="6"></Form.Group>
                                                    <Row>
                                                        <h4 className='heading'>Contractual</h4>

                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Contractual GAR (P)"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="text"
                                                                    tabIndex={37}
                                                                    placeholder="Contractual GAR (P)"
                                                                    name="contractual_GAR_P"
                                                                    defaultValue={tcf.contractual_GAR_P || ""}
                                                                    onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Contractual GAR (S)"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Contractual GAR (S)"
                                                                    name="contractual_GAR_S"
                                                                    defaultValue={tcf.contractual_GAR_S || ""}
                                                                    onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Contractual TM (AR) (P)"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Contractual TM (AR) (P)"
                                                                    name="contractual_TM_AR_P"
                                                                    tabIndex={38}
                                                                    defaultValue={tcf.contractual_TM_AR_P || ""}
                                                                    onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Contractual TM (AR) (S)"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Contractual TM (AR) (S)"
                                                                    tabIndex={51}
                                                                    name="contractual_TM_AR_S"
                                                                    defaultValue={tcf.contractual_TM_AR_S || ""}
                                                                    onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Contractual TS (AR) (P)"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Contractual TS (AR) (P)"
                                                                    name="contractual_Ts_AR_p"
                                                                    tabIndex={39}
                                                                    defaultValue={tcf.contractual_Ts_AR_p || ""}
                                                                    onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Contractual TS (AR) (S)"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Contractual TS (AR) (S)"
                                                                    name="contractual_Ts_AR_s"

                                                                    tabIndex={52}
                                                                    defaultValue={tcf.contractual_Ts_AR_s || ""}
                                                                    onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>

                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Contractual Ash (AR) (P)"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="text"
                                                                    tabIndex={40}
                                                                    placeholder="Contractual Ash (AR) (P)"
                                                                    name="contractual_Ash_AR_P"
                                                                    defaultValue={tcf.contractual_Ash_AR_P || ""}
                                                                    onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>

                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Contractual Ash (AR) (S)"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="text"
                                                                    tabIndex={53}
                                                                    placeholder="Contractual Ash (AR) (S)"
                                                                    name="contractual_Ash_AR_S"
                                                                    defaultValue={tcf.contractual_Ash_AR_S || ""}
                                                                    onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Contractual TS (ADB) (P)"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Contractual  TS (ADB) (P)"
                                                                    tabIndex={41}
                                                                    name="contractual_Ts_ADB_P"
                                                                    defaultValue={tcf.contractual_Ts_ADB_P || ""}
                                                                    onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Contractual TS (ADB) (S)"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Contractual TS (ADB) (S)"
                                                                    name="contractual_Ts_ADB_S"
                                                                    tabIndex={54}
                                                                    defaultValue={tcf.contractual_Ts_ADB_S || ""}
                                                                    onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Contractual Ash (ADB) (P)"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Contractual Ash (ADB) (P)"
                                                                    name="contractual_Ash_ADB_P"
                                                                    tabIndex={42}
                                                                    defaultValue={tcf.contractual_Ash_ADB_P || ""}
                                                                    onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Contractual Ash (ADB) (S)"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Contractual Ash (ADB) (S)"
                                                                    name="contractual_Ash_ADB_S"
                                                                    tabIndex={55}
                                                                    defaultValue={tcf.contractual_Ash_ADB_S || ""}
                                                                    onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>


                                                        <Form.Group as={Col} md="6" className="spaces">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Projected price basis GAR (P)"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Projected price basis GAR (P)"
                                                                    tabIndex={43}
                                                                    name="projected_price_basis_GAR_P"
                                                                    defaultValue={tcf.projected_price_basis_GAR_P || ""}
                                                                    onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Projected price basis GAR (S)"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Projected price basis GAR (S)"
                                                                    tabIndex={56}
                                                                    name="projected_price_basis_GAR_S"
                                                                    defaultValue={tcf.projected_price_basis_GAR_S || ""}
                                                                    onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6">

                                                            <FloatingLabel controlId="floatingSelect"
                                                                className='dropDown' label="contractual HBA year">
                                                                <Form.Select aria-label="Floating label select example"
                                                                    name="contractual_HBA_year"
                                                                    placeholder="contractual HBA year"
                                                                    tabIndex={44}
                                                                    defaultValue={tcf.contractual_HBA_year}
                                                                    onChange={handleChangeInput}
                                                                >
                                                                    <option value="">--None--</option>
                                                                    <option value="2024">2024</option>
                                                                    <option value="2025">2025</option>
                                                                    <option value="2026">2026</option>
                                                                    <option value="2027">2027</option>
                                                                    <option value="2023">2023</option>
                                                                    <option value="2022">2022</option>
                                                                    <option value="2021">2021</option>

                                                                </Form.Select>
                                                            </FloatingLabel>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Discounted price"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Discounted price"
                                                                    name="discounted_price"
                                                                    tabIndex={57}
                                                                    defaultValue={tcf.discounted_price || ""}
                                                                    onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6">

                                                            <FloatingLabel controlId="floatingSelect"
                                                                className='dropDown' label="contractual HBA Month">
                                                                <Form.Select aria-label="Floating label select example"
                                                                    name="contractual_HBA_month"
                                                                    tabIndex={45}
                                                                    placeholder="contractual HBA Month"
                                                                    defaultValue={tcf.contractual_HBA_month}
                                                                    onChange={handleChangeInput}
                                                                >
                                                                    <option value="">--None--</option><option value="January">January</option><option value="February">February</option><option value="March">March</option><option value="April">April</option><option value="May">May</option><option value="June">June</option><option value="July">July</option><option value="August">August</option><option value="September">September</option><option value="October">October</option><option value="November">November</option><option value="December">December</option>
                                                                </Form.Select>
                                                            </FloatingLabel>
                                                        </Form.Group>

                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Projected index value"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Projected index value"
                                                                    tabIndex={58}
                                                                    name="projected_index_value"
                                                                    defaultValue={tcf.projected_index_value || ""}
                                                                    onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>

                                                        <Form.Group as={Col} md="6" className="spaces">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Projected HBA"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="projected HBA"
                                                                    name="projected_hba"
                                                                    tabIndex={46}
                                                                    defaultValue={tcf.projected_hba || ""}
                                                                    onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6"></Form.Group>
                                                        <Form.Group as={Col} md="6"></Form.Group>
                                                        <Form.Group as={Col} md="6">

                                                            <FloatingLabel controlId="floatingSelect"
                                                                className='dropDown' label="Projected Sale Price Type">
                                                                <Form.Select aria-label="Floating label select example"
                                                                    name="projected_sale_price_type"
                                                                    placeholder="Projected Sale Price Type"
                                                                    defaultValue={tcf.projected_sale_price_type || ""}
                                                                    tabIndex={59}
                                                                    onChange={handleChangeInput}

                                                                >
                                                                    <option value='' >Select</option>
                                                                    {
                                                                        price.map((x) => {
                                                                            return (
                                                                                <option value={x.price_type_name}>{x.price_type_name}</option>
                                                                            )
                                                                        })
                                                                    }
                                                                </Form.Select>
                                                            </FloatingLabel>
                                                        </Form.Group>

                                                        <Form.Group as={Col} md="6">

                                                            <FloatingLabel controlId="floatingSelect"
                                                                className='dropDown' label="Projected Purchase Price Type">
                                                                <Form.Select aria-label="Floating label select example"
                                                                    name="projected_purchase_type"
                                                                    tabIndex={47}
                                                                    placeholder="Projected Purchase Price Type"
                                                                    defaultValue={tcf.projected_purchase_type || ""}
                                                                    onChange={handleChangeInput}

                                                                >
                                                                    <option value='' >Select</option>
                                                                    {
                                                                        price.map((x) => {
                                                                            return (
                                                                                <option value={x.price_type_name}>{x.price_type_name}</option>
                                                                            )
                                                                        })
                                                                    }
                                                                </Form.Select>
                                                            </FloatingLabel>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Projected fixed sales price"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Projected fixed sales price"
                                                                    name="projected_fixed_sales_price"
                                                                    tabIndex={60}
                                                                    defaultValue={tcf.projected_fixed_sales_price || ""}
                                                                    onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Projected fixed purchase price"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="text"
                                                                    tabIndex={48}
                                                                    placeholder="Projected fixed purchase price"
                                                                    name="projected_fixed_purchase_price"
                                                                    defaultValue={tcf.projected_fixed_purchase_price || ""}
                                                                    onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>

                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Projected sales price margin"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Projected sales price margin"
                                                                    name="projected_sales_price_margin"
                                                                    tabIndex={61}
                                                                    defaultValue={tcf.projected_sales_price_margin || ""}
                                                                    onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>

                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Projected purchase price margin"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="number"
                                                                    placeholder="Projected purchase price margin"
                                                                    name="projected_purchase_price_margin"
                                                                    tabIndex={49}
                                                                    defaultValue={tcf.projected_purchase_price_margin || ""}
                                                                    onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>

                                                        <Form.Group as={Col} md="6"></Form.Group>



                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Projected barge discount"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="text"
                                                                    tabIndex={50}
                                                                    placeholder="Projected barge discount"
                                                                    name="projected_barge_discount"
                                                                    defaultValue={tcf.projected_barge_discount || ""}
                                                                    onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>
                                                    </Row>
                                                    <Row>
                                                        <h4 className='heading'>Cost Sheet Projection</h4>
                                                        {/* <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Projected Pur Amount"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Projected Pur Amount"
              name="projected_pur_amount"
              defaultValue={tcf.projected_pur_amount || ""} disabled
              onChange={handleChangeInput}
             />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="projected sales"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="projected sales"
              name="projected_sales"
              defaultValue={tcf.projected_sales || ""} disabled
              onChange={handleChangeInput}
             />
          </FloatingLabel>
        </Form.Group>  */}
                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Projected Freight Rate "
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Projected Freight Rate "
                                                                    name="projected_freight_rate"
                                                                    tabIndex={62}
                                                                    defaultValue={tcf.projected_freight_rate || ""}
                                                                    onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6"></Form.Group>
                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Projected Freight Amount "
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Projected Freight Amount "
                                                                    name="projected_freight_amount"
                                                                    tabIndex={62}
                                                                    defaultValue={tcf.projected_freight_amount || ""}
                                                                    onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6"></Form.Group>
                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Projected Insurance"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Projected Insurance"
                                                                    tabIndex={63}
                                                                    name="projected_insurance"
                                                                    defaultValue={tcf.projected_insurance || ""}
                                                                    onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6"></Form.Group>
                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Projected Commission"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="text"
                                                                    tabIndex={64}
                                                                    placeholder="Projected Commission"
                                                                    name="projected_commission"
                                                                    defaultValue={tcf.projected_commission || ""}
                                                                    onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6"></Form.Group>
                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Projected Fixed Apportioned Overheads  "
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Projected Fixed Apportioned Overheads"
                                                                    tabIndex={65}
                                                                    name="projected_fixed_apportioned_overheads"
                                                                    defaultValue={tcf.projected_fixed_apportioned_overheads || ""}
                                                                    onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6"></Form.Group>
                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Projected Any Contract Specific Cost "
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="text"
                                                                    tabIndex={66}
                                                                    placeholder="Projected Any Contract Specific Cost"
                                                                    name="projected_any_contract_specific_cost"
                                                                    defaultValue={tcf.projected_any_contract_specific_cost || ""}
                                                                    onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6"></Form.Group>
                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Projected Extra Cost"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Projected Extra Cost"
                                                                    name="projected_extra_cost"
                                                                    tabIndex={67}
                                                                    defaultValue={tcf.projected_extra_cost || ""}
                                                                    onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6"></Form.Group>

                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Projected Provision For Corporate Tax"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Projected Provision For Corporate Tax"
                                                                    name="projected_provision_for_corporate_tax"
                                                                    tabIndex={68}
                                                                    defaultValue={tcf.projected_provision_for_corporate_tax || ""}
                                                                    onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>

                                                    </Row>
                                                    {/* <Row className="mb-3">
           <h4 className="heading">Actual Shipment Details</h4>
           <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Vessel Name"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Vessel Name"
              name="vessel_name"
              defaultValue={tcf.vessel_name || ""}
              onChange={handleChangeInput}
              disabled
             />
          </FloatingLabel>
        </Form.Group>
           <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Demmurage Rate (P)"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Demmurage Rate (P)"
              name="demmurage_rate_p"
              defaultValue={tcf.demmurage_rate_p || ""}
              onChange={handleChangeInput}
              disabled
             />
          </FloatingLabel>
        </Form.Group>
           <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Stowage Quantity"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Stowage Quantity"
              name="stowage_qty"
              defaultValue={tcf.stowage_qty || ""}
              onChange={handleChangeInput}
              disabled
             />
          </FloatingLabel>
        </Form.Group>
           <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Demmurage Rate (S)"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Demmurage Rate (S)"
              name="demmurage_rate_s"
              defaultValue={tcf.demmurage_rate_s || ""}
              onChange={handleChangeInput}
              disabled
             />
          </FloatingLabel>
        </Form.Group>
           <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Destination"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Destination"
              name="destination"
              defaultValue={tcf.destination || ""}
              onChange={handleChangeInput}
              disabled
             />
          </FloatingLabel>
        </Form.Group>
           <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Load Rate"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Load Rate"
              name="load_rate"
              defaultValue={tcf.load_rate || ""}
              onChange={handleChangeInput}
              disabled
             />
          </FloatingLabel>
        </Form.Group>
           <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Actual BL Date"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Actual BL Date"
              name="actual_bl_date"
              defaultValue={tcf.actual_bl_date || ""}
              onChange={handleChangeInput}
              disabled
             />
          </FloatingLabel>
        </Form.Group>
           <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Actual BL Qty"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Actual BL Qty"
              name="actual_bl_qty"
              defaultValue={tcf.actual_bl_qty || ""}
              onChange={handleChangeInput}
              disabled
             />
          </FloatingLabel>
        </Form.Group>
           <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="GCNEWC"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="GCNEWC"
              name="gcnewc"
              defaultValue={tcf.gcnewc || ""}
              onChange={handleChangeInput}
              disabled
             />
          </FloatingLabel>
        </Form.Group>
           <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Actual HBA"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Actual HBA"
              name="actual_hba"
              defaultValue={tcf.actual_hba || ""}
              onChange={handleChangeInput}
              disabled
             />
          </FloatingLabel>
        </Form.Group>
           <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Actual GCNEWC - Sales"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Actual GCNEWC - Sales"
              name="actual_gcnewc_sales"
              defaultValue={tcf.actual_gcnewc_sales || ""}
              onChange={handleChangeInput}
              disabled
             />
          </FloatingLabel>
        </Form.Group>
           <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Actual HBA - Purchase"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Actual HBA - Purchase"
              name="actual_hba_purchase"
              defaultValue={tcf.actual_hba_purchase || ""}
              onChange={handleChangeInput}
              disabled
             />
          </FloatingLabel>
        </Form.Group>
           <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Laycan"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Laycan"
              name="laycan"
              defaultValue={tcf.laycan || ""}
              onChange={handleChangeInput}
              disabled
             />
          </FloatingLabel>
        </Form.Group>
           <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Expected Value Date"
            className="mb-3"


          >
            <Form.Control
              type="text"
              placeholder="Expected Value Date"
              name="expected_value_date"
              defaultValue={tcf.expected_value_date || ""}
              onChange={handleChangeInput}
              disabled
             />
          </FloatingLabel>
        </Form.Group>
           </Row> */}
                                                    <Row>
                                                        <h4 className='heading'>Actual Cost Elements</h4>

                                                        <Form.Group as={Col} md="6">

                                                            <FloatingLabel controlId="floatingSelect"
                                                                className='dropDown' label="Purchase Price Type">
                                                                <Form.Select aria-label="Floating label select example"
                                                                    name="purchase_price_type"
                                                                    placeholder="Purchase Price Type"
                                                                    tabIndex={69}
                                                                    defaultValue={tcf.purchase_price_type || ""}
                                                                    onChange={handleChangeInput}
                                                                >
                                                                    <option value='' >Select</option>
                                                                    {
                                                                        price.map((x) => {
                                                                            return (
                                                                                <option value={x.price_type_name}>{x.price_type_name}</option>
                                                                            )
                                                                        })
                                                                    }
                                                                </Form.Select>

                                                            </FloatingLabel>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6">
                                                            <label>Actual Data Rcvd	</label>
                                                            <input
                                                                type='checkbox'
                                                                name='actual_data_rcvd'
                                                                checked={dataReceived} // Use th e state variable to determine if the checkbox is checked
                                                                onChange={handleData}// Handle the change event
                                                                tabIndex={84}
                                                                className='mx-3'
                                                            />
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Fixed purchase price"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Fixed purchase price"
                                                                    name="fixed_purchase_price"
                                                                    tabIndex={70}
                                                                    defaultValue={tcf.fixed_purchase_price || ""}
                                                                    onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>

                                                        <Form.Group as={Col} md="6">

                                                            <FloatingLabel controlId="floatingSelect"
                                                                className='dropDown' label="Sale Price Type">
                                                                <Form.Select aria-label="Floating label select example"
                                                                    name="sale_price_type"
                                                                    placeholder="Sale Price Type"
                                                                    tabIndex={85}
                                                                    defaultValue={tcf.sale_price_type || ""}
                                                                    onChange={handleChangeInput}

                                                                >
                                                                    <option value='' >Select</option>
                                                                    {
                                                                        price.map((x) => {
                                                                            return (
                                                                                <option value={x.price_type_name}>{x.price_type_name}</option>
                                                                            )
                                                                        })
                                                                    }
                                                                </Form.Select>

                                                            </FloatingLabel>
                                                        </Form.Group>

                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Purchase price margin"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="text"
                                                                    tabIndex={71}
                                                                    placeholder="Purchase price margin"
                                                                    name="purchase_price_margin"
                                                                    defaultValue={tcf.purchase_price_margin || ""}
                                                                    onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>

                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Fixed sale price"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Fixed sale price"
                                                                    name="fixed_sale_price"
                                                                    tabIndex={86}
                                                                    defaultValue={tcf.fixed_sale_price || ""}
                                                                    onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Barge discount"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Barge discount"
                                                                    name="barge_discount"
                                                                    tabIndex={72}
                                                                    defaultValue={tcf.barge_discount || ""}
                                                                    onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Sales Price Margin	"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Sales Price Margin	"
                                                                    name="sale_price_margin"
                                                                    tabIndex={87}
                                                                    defaultValue={tcf.sale_price_margin || ""}
                                                                    onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Actual GAR (P)"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="number"
                                                                    placeholder="Actual GAR (P)"
                                                                    tabIndex={73}
                                                                    name="actual_gar_p"
                                                                    defaultValue={tcf.actual_gar_p || ""}
                                                                    onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Actual GAR (S)"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="number"
                                                                    placeholder="Actual GAR (S)"
                                                                    name="actual_gar_s"
                                                                    tabIndex={88}
                                                                    defaultValue={tcf.actual_gar_s || ""}
                                                                    onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Actual TM (AR) (P)	"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="number"
                                                                    tabIndex={74}
                                                                    placeholder="Actual TM (AR) (P)	"
                                                                    name="actual_tm_p"
                                                                    defaultValue={tcf.actual_tm_p || ""}
                                                                    onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Actual TM (AR) (S)	"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="number"
                                                                    placeholder="Actual TM (AR) (S)	"
                                                                    name="actual_tm_s"
                                                                    tabIndex={89}
                                                                    defaultValue={tcf.actual_tm_s || ""}
                                                                    onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Actual Ash (AR) (P)"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="number"
                                                                    placeholder="Actual Ash (AR) (P)"
                                                                    name="actual_ash_ar_p"
                                                                    tabIndex={75}
                                                                    defaultValue={tcf.actual_ash_ar_p || ""}
                                                                    onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Actual Ash (AR) (S)"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="number"
                                                                    tabIndex={90}
                                                                    placeholder="Actual Ash (AR) (S)"
                                                                    name="actual_ash_ar_s"
                                                                    defaultValue={tcf.actual_ash_ar_s || ""}
                                                                    onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>

                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Actual TS (AR) (P)	"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="number"
                                                                    placeholder="Actual TS (AR) (P)	"
                                                                    name="actual_ts_ar_p"
                                                                    tabIndex={76}
                                                                    defaultValue={tcf.actual_ts_ar_p || ""}
                                                                    onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>

                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Actual TS (AR) (S)	"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="number"
                                                                    placeholder="Actual TS (AR) (S)	"
                                                                    name="actual_ts_ar_s"
                                                                    tabIndex={91}
                                                                    defaultValue={tcf.actual_ts_ar_s || ""}
                                                                    onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>

                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Actual Ash (ADB) (P)	"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="number"
                                                                    placeholder="Actual Ash (ADB) (P)	"
                                                                    name="actual_ash_adb_p"
                                                                    tabIndex={77}
                                                                    defaultValue={tcf.actual_ash_adb_p || ""}
                                                                    onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>

                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Actual Ash (ADB) (S)	"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="number"
                                                                    tabIndex={92}
                                                                    placeholder="Actual Ash (ADB) (S)	"
                                                                    name="actual_ash_adb_s"
                                                                    defaultValue={tcf.actual_ash_adb_s || ""}
                                                                    onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>

                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Actual TS (ADB) (P)"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="number"
                                                                    placeholder="Actual TS (ADB) (P)"
                                                                    tabIndex={78}
                                                                    name="actual_ts_adb_p"
                                                                    defaultValue={tcf.actual_ts_adb_p || ""}
                                                                    onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Actual TS (ADB) (S)"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="number"
                                                                    placeholder="Actual TS (ADB) (S)"
                                                                    tabIndex={93}
                                                                    name="actual_ts_adb_s"
                                                                    defaultValue={tcf.actual_ts_adb_s || ""}
                                                                    onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Actual AFT (P)	"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="number"
                                                                    tabIndex={79}
                                                                    placeholder="Actual AFT (P)	"
                                                                    name="actual_aft_p"
                                                                    defaultValue={tcf.actual_aft_p || ""}
                                                                    onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Actual AFT (S)	"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="number"
                                                                    placeholder="Actual AFT (S)	"
                                                                    tabIndex={94}
                                                                    name="actual_aft_s"
                                                                    defaultValue={tcf.actual_aft_s || ""}
                                                                    onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>

                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Actual VM (P)	"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="number"
                                                                    tabIndex={80}
                                                                    placeholder="Actual VM (P)	"
                                                                    name="actual_vm_p"
                                                                    defaultValue={tcf.actual_vm_p || ""}
                                                                    onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Actual VM (S)	"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="number"
                                                                    placeholder="Actual VM (S)	"
                                                                    name="actual_vm_s"
                                                                    tabIndex={95}
                                                                    defaultValue={tcf.actual_vm_s || ""}
                                                                    onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Actual HGI (P)	"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="number"
                                                                    placeholder="Actual HGI (P)	"
                                                                    name="actual_hgi_p"
                                                                    tabIndex={81}
                                                                    defaultValue={tcf.actual_hgi_p || ""}
                                                                    onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Actual HGI (S)	"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="number"
                                                                    tabIndex={96}
                                                                    placeholder="Actual HGI (S)	"
                                                                    name="actual_hgi_s"
                                                                    defaultValue={tcf.actual_hgi_s || ""}
                                                                    onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Price Basis GAR (P)	"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="number"
                                                                    tabIndex={82}
                                                                    placeholder="Price Basis GAR (P)	"
                                                                    name="price_basis_gar_p"
                                                                    defaultValue={tcf.price_basis_gar_p || ""}
                                                                    onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Price Basis GAR (S)	"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="number"
                                                                    placeholder="Price Basis GAR (S)	"
                                                                    name="price_basis_gar_s"
                                                                    tabIndex={97}
                                                                    defaultValue={tcf.price_basis_gar_s || ""}
                                                                    onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>

                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="GAR (P)"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="number"
                                                                    placeholder="GAR (P)"
                                                                    tabIndex={83}
                                                                    name="gar_p"
                                                                    defaultValue={tcf.gar_p || ""}
                                                                    onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="GAR (S)"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="number"
                                                                    tabIndex={98}
                                                                    placeholder="GAR (S)"
                                                                    name="gar_s"
                                                                    defaultValue={tcf.gar_s || ""}
                                                                    onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>

                                                    </Row>
                                                    <Row>
                                                        <h4 className='heading'>Bonus / Penalty</h4>
                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Sulfur Bonus Rate (MT) (S)"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="text"
                                                                    tabIndex={99}
                                                                    placeholder="Sulfur Bonus Rate (MT) (S)"
                                                                    name="sulfur_bonus_rate_MT_S"
                                                                    defaultValue={tcf.sulfur_bonus_rate_MT_S || ""}
                                                                    onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>

                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Ash Bonus Rate (MT) (S)"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="text"
                                                                    tabIndex={101}
                                                                    placeholder="Ash Bonus Rate (MT) (S)"
                                                                    name="ash_bonus_rate_MT_S"
                                                                    defaultValue={tcf.ash_bonus_rate_MT_S || ""}
                                                                    onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Sulfur Penalty Rate (MT) (S)"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="text"
                                                                    tabIndex={100}
                                                                    placeholder="Sulfur Penalty Rate (MT) (S)"
                                                                    name="sulfur_penalty_rate_MT_S"
                                                                    defaultValue={tcf.sulfur_penalty_rate_MT_S || ""}
                                                                    onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>

                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Ash Penalty Rate (MT) (S)  "
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="text"
                                                                    tabIndex={101}
                                                                    placeholder="Ash Penalty Rate (MT) (S)"
                                                                    name="ash_penalty_rate_MT_S"
                                                                    defaultValue={tcf.ash_penalty_rate_MT_S || ""}
                                                                    onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>
                                                    </Row>
                                                    <Row>
                                                        <h4 className='heading'>Cost Sheet Actual</h4>
                                                        {/* <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Actual Pur Amount"
            className="mb-3"


          >
            <Form.Control
              type="number"
              placeholder="Actual Pur Amount"
              name="actual_pur_amount" defaultValue={tcf.actual_pur_amount || ""}
              onChange={handleChangeInput}
             />
          </FloatingLabel>
        </Form.Group>   */}
                                                        {/* <Form.Group as={Col} md="6"></Form.Group> */}
                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Actual Freight Rate"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="number"
                                                                    tabIndex={102}
                                                                    placeholder="Actual Freight Rate"
                                                                    name="actual_freight_rate" defaultValue={tcf.actual_freight_rate || ""}
                                                                    onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>

                                                        <Form.Group as={Col} md="6"></Form.Group>

                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Actual Freight Amount"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="number"
                                                                    placeholder="Actual Freight Amount"
                                                                    tabIndex={103}
                                                                    name="actual_freight_amount" defaultValue={tcf.actual_freight_amount || ""} onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6"></Form.Group>

                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Actual Insurance"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="number"
                                                                    tabIndex={104}
                                                                    placeholder="Actual Insurance"
                                                                    name="actual_insurance" defaultValue={tcf.actual_insurance || ""}
                                                                    onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6"></Form.Group>
                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Actual Commission"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="number"
                                                                    placeholder="Actual Commission"
                                                                    tabIndex={105}
                                                                    name="actual_commission" defaultValue={tcf.actual_commission || ""}
                                                                    onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6"></Form.Group>
                                                    </Row>
                                                    <Row>
                                                        <h4 className='heading'>Shipment Cost</h4>
                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Actual Fixed Apportioned Overheads"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="number"
                                                                    placeholder="Actual Fixed Apportioned Overheads"
                                                                    name="actual_fixed_apportioned_overheads"
                                                                    tabIndex={106}
                                                                    defaultValue={tcf.actual_fixed_apportioned_overheads || ""}
                                                                    onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6"></Form.Group>
                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Actual Contract Specific Cost"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="number"
                                                                    placeholder="Actual Contract Specific Cost"
                                                                    name="actual_contract_specific_cost"
                                                                    tabIndex={107}
                                                                    defaultValue={tcf.actual_contract_specific_cost || ""}
                                                                    onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>
                                                        {/* <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Final Total Cost "
            className="mb-3"


          >
            <Form.Control
              type="number"
              placeholder="Final Total Cost "
              name="final_total_cost"
              defaultValue={tcf.final_total_cost || ""}
              onChange={handleChangeInput}
             />
          </FloatingLabel>
        </Form.Group>  */}
                                                        <Form.Group as={Col} md="6"></Form.Group>        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Actual Prov For Corporate Tax"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="number"
                                                                    tabIndex={108}
                                                                    placeholder="Actual Prov For Corporate Tax"
                                                                    name="actual_prov_for_corporate_tax"
                                                                    defaultValue={tcf.actual_prov_for_corporate_tax || ""}
                                                                    onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6"></Form.Group>
                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label=" Actual Withholding Tax"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="number"
                                                                    placeholder=" Actual Withholding Tax"
                                                                    name="actual_withholding_tax"
                                                                    tabIndex={109}
                                                                    defaultValue={tcf.actual_withholding_tax || ""}
                                                                    onChange={handleChangeInput}
                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>

                                                    </Row>

                                                    {/* <Row>
        <h4 className='heading'>Debit Note Details</h4>

        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Supplier Dem/Despatch"
            className="mb-3"


          >
            <Form.Control
              type="number"
              placeholder="Supplier Dem/Despatch"
              name="supplier_dem_or_despatch"
             />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Buyer Dem/Despatch"
            className="mb-3"


          >
            <Form.Control
              type="number"
              placeholder="Buyer Dem/Despatch"
              name="buyer_dem_or_despatch"
             />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Supplier Dem/Despatch DN Amount"
            className="mb-3"


          >
            <Form.Control
              type="number"
              placeholder="Supplier Dem/Despatch DN Amount"
              name="supplier_dem_or_despatch_DN_amount"
             />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Buyer Dem/Despatch DN Amount"
            className="mb-3"


          >
            <Form.Control
              type="number"
              placeholder="Buyer Dem/Despatch DN Amount"
              name="buyer_dem_or_despatch_DN_amount"
             />
          </FloatingLabel>
        </Form.Group>   <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Supplier DN Amount"
            className="mb-3"


          >
            <Form.Control
              type="number"
              placeholder="Supplier DN Amount"
              name="supplier_DN_amount"
             />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Supplier DN Amount"
            className="mb-3"


          >
            <Form.Control
              type="number"
              placeholder="Supplier DN Amount"
              name="supplier_DN_amount"
             />
          </FloatingLabel>
        </Form.Group>    <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Buyer DN Amount"
            className="mb-3"


          >
            <Form.Control
              type="number"
              placeholder="Buyer DN Amount"
              name="buyer_DN_amount"
             />
          </FloatingLabel>
        </Form.Group>
       </Row> */}
                                                    <Row>
                                                        <h4 className='heading'>Rwood Internal Information
                                                        </h4>
                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="any deviation"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                tabIndex={110}
                                                                    type="text"
                                                                    placeholder="any deviation"
                                                                    name="any_deviation" defaultValue={tcf.any_deviation} onChange={handleChangeInput}

                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6"></Form.Group>
                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="prepared by"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="text"
                                                                    tabIndex={111}
                                                                    placeholder="prepared by"
                                                                    name="prepared_by" defaultValue={tcf.prepared_by} onChange={handleChangeInput}

                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6"></Form.Group>
                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Approved By	"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="text"
                                                                    tabIndex={112}
                                                                    placeholder="Approved By	"
                                                                    name="check_and_approved_by" defaultValue={tcf.check_and_approved_by} onChange={handleChangeInput}

                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6"></Form.Group>
                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Approved By	"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="text"
                                                                    tabIndex={113}
                                                                    placeholder="Approved By	"
                                                                    name="check_and_approved_by" defaultValue={tcf.check_and_approved_by} onChange={handleChangeInput}

                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6"></Form.Group>
                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="Check and Validated By"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="text"
                                                                    tabIndex={114}
                                                                    placeholder="Check and Validated By"
                                                                    name="validated_by" defaultValue={tcf.validated_by} onChange={handleChangeInput}

                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="6"></Form.Group>
                                                        <Form.Group as={Col} md="6">
                                                            <FloatingLabel
                                                                controlId="floatingInput"
                                                                label="remarks"
                                                                className="mb-3"


                                                            >
                                                                <Form.Control
                                                                    type="text"
                                                                    tabIndex={115}
                                                                    placeholder="remarks"
                                                                    name="remarks" defaultValue={tcf.remarks} onChange={handleChangeInput}

                                                                />
                                                            </FloatingLabel>
                                                        </Form.Group>
                                                    </Row>
                                                    <p className="edit-btn">
                                                        <input
                                                            type="submit"
                                                            className="account-save"
                                                            onClick={handleSaveEdit}
                                                            value="Save"
                                                        //disabled={isSubmitted}
                                                        />
                                                        <button className="btn btn-primary" onClick={handleCancelEdit}>
                                                            Cancel
                                                        </button>
                                                    </p>
                                                </div></div></div></div>
                                        <Footer />
                                    </div>
                                ) : (
                                    <div id="view-page">
                                        <Header />
                                        <div className="contactAccount">
                                            <div className="row">
                                                <div className="col-md-2 recent-item">
                                                    <RecentItems />
                                                </div>
                                                <div className="col-md-10 right-section">
                                                    <div className="page-header" id="add-account">
                                                        <div>
                                                            <h2 className="main-content-title tx-24 mg-b-5">
                                                                TCF Details
                                                            </h2>
                                                            <ol className="breadcrumb">
                                                                <li className="breadcrumb-item active" aria-current="page">
                                                                    Dashboard / TCF / TCF Details
                                                                </li>
                                                            </ol>
                                                        </div>
                                                        <div className="d-flex opd">

                                                        </div>
                                                    </div>
                                                    <div className="tasks column-gap fulid-section order-section">
                                                        <div className="edit-delete">
                                                            <span id="edit" onClick={handleEditClick}>
                                                                Edit
                                                            </span>
                                                            <span>
                                                                <Link to={'/tcf/create-view-list'}> Cancel</Link>
                                                            </span>
                                                            <span>
                                                                Sales Report
                                                            </span>
                                                            <span onClick={tradeConrfirm}>
                                                                Trade Confirmation Sheet
                                                            </span>

                                                        </div>
                                                        <div className="col-md-12" id="ax.account_details">
                                                            <h4 className="heading">Information</h4>
                                                            <hr></hr>
                                                        </div>
                                                        <table class="table table-bordered account-table tables">
                                                            <tbody>
                                                                <tr>
                                                                    <td id="td-right">
                                                                        <span>TCF No</span>
                                                                    </td><td>
                                                                        {tcf.tcf_no}
                                                                    </td>
                                                                    <td id="td-right">
                                                                        <span>Locked</span>
                                                                    </td><td>
                                                                        <input
                                                                            type='checkbox'
                                                                            name='locked'
                                                                            checked={lockedOne} // Use the state variable to determine if the checkbox is checked
                                                                            onChange={handleCheckboxLocked} // Handle the change event
                                                                            className='mx-3' disabled={isButtonEnabled}
                                                                        />
                                                                    </td>

                                                                </tr>
                                                                <tr>
                                                                    <td id="td-right">
                                                                        <span>TCF Date </span>
                                                                    </td><td>
                                                                        {tcf.tcf_date}
                                                                    </td>
                                                                    <td id="td-right">
                                                                        <span>Record Type</span>
                                                                    </td><td>
                                                                        {tcf.record_type}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td id="td-right">
                                                                        Company
                                                                    </td><td>
                                                                        <Link to={`/company/company-details/${tcf.rwoodcompany_id}`}><span>{tcf.company} </span></Link>
                                                                    </td>
                                                                    <td id="td-right">
                                                                        <span>opportunity </span>
                                                                    </td><td>
                                                                        <Link to={`/opportunity/opportunity-detail/${tcf.opportunity_id}`}>{tcf.opportunity}</Link>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td id="td-right">
                                                                        <span>Currency</span>
                                                                    </td><td>
                                                                        {tcf.currency}
                                                                    </td>
                                                                    <td id="td-right">
                                                                        <span>Quote</span>
                                                                    </td><td>
                                                                        <Link to={`/opportunity/quote-view/${tcf.offer_id}`}>{tcf.quote}</Link>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td id="td-right">
                                                                        <span>Exchange Rate </span>
                                                                    </td><td>
                                                                        {tcf.exchange_rate}
                                                                    </td>
                                                                    <td id="td-right">
                                                                        <span>trader name</span>
                                                                    </td><td>
                                                                        <Link to={`/user/user-details/${tcf.rwoodusers_id}`}>{tcf.trader_name}</Link>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td id="td-right">
                                                                        <span>order</span>
                                                                    </td><td>
                                                                        <Link to={`/order/view-order-details/${tcf.rwood_order_id}`}>{tcf.order}</Link>
                                                                    </td>
                                                                    <td id="td-right">
                                                                        <span>owner </span>
                                                                    </td><td>
                                                                        {tcf.owner}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td id="td-right">
                                                                    </td><td>
                                                                    </td>
                                                                    <td id="td-right">
                                                                        <span className="tooltips-label">Auto Select Pricebook</span>
                                                                        <span id='tooltip' data-tooltip-id="button-tooltip" data-tooltip-place='right'>
                                                                            <IoIosHelpCircle />
                                                                        </span>
                                                                        <ReactTooltip
                                                                            id="button-tooltip"
                                                                            place="bottom"
                                                                            content="If Checked Pricebook will be based on Month of BL Date Of Order If Unchecked Price book will be based on TCF.Pricebook field"
                                                                        />  </td><td>
                                                                        <input
                                                                            type='checkbox'
                                                                            name='auto_select_pricebook'
                                                                            checked={lockeds} // Use the state variable to determine if the checkbox is checked
                                                                            onChange={handleCheckboxChange} // Handle the change event
                                                                            className='mx-3'
                                                                            disabled={isButtonEnabled}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td id="td-right">
                                                                        <span>Rwood Bank Master</span>
                                                                    </td><td>
                                                                        <Link to={`/bank/bank-details/${tcf.rwoodbank_id}`}>{tcf.rwood_bank_master}</Link>
                                                                    </td>
                                                                    <td id="td-right">
                                                                        <span>Pricebook</span>
                                                                    </td><td>
                                                                        <Link to={`/pricebook/pricebook-details/${tcf.price_book_id}`}>{tcf.price_book}</Link>
                                                                    </td>
                                                                </tr>
                                                                <tr>


                                                                    <td id="td-right">
                                                                        <span>TCF Status</span>
                                                                    </td><td>
                                                                        {tcf.tcf_status}
                                                                    </td>
                                                                    <td id="td-right">
                                                                        <span>Purchase Confirmation Date</span>
                                                                        <span id='tooltip' data-tooltip-id="button-tooltips" data-tooltip-place='right'>
                                                                            <IoIosHelpCircle />
                                                                        </span>
                                                                        <ReactTooltip
                                                                            id="button-tooltips"
                                                                            place="bottom"
                                                                            content="Date on which the purchase was confirmed"
                                                                        />
                                                                    </td><td>
                                                                        {tcf.purchase_confirmation_date}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td id="td-right">
                                                                        <span>Buyer Contract</span>
                                                                    </td><td>
                                                                        <Link to={`/contract/contract-details/${tcf.contract_id}`}>{tcf.buyer_contract}</Link>
                                                                    </td>
                                                                    <td id="td-right">
                                                                    </td><td>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td id="td-right">
                                                                        <span>Contract Shipment</span>
                                                                    </td><td>
                                                                        <Link to={`/shipment/contract-shipment-details/${tcf.contract_ship_id}`}>{tcf.contract_shipment}</Link>
                                                                    </td>
                                                                    <td id="td-right">
                                                                    </td><td>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td id="td-right">
                                                                        <span className="tooltips-label">Product Master</span>
                                                                        <span id='tooltip' data-tooltip-id="button-tooltip1" data-tooltip-place='right'>
                                                                            <IoIosHelpCircle />
                                                                        </span>
                                                                        <ReactTooltip
                                                                            id="button-tooltip1"
                                                                            place="bottom"
                                                                            content="Product Master"
                                                                        />
                                                                    </td><td>
                                                                        <Link to={`/productmaster/product-detail/${tcf.product_id}`}>{tcf.product_master}</Link>
                                                                    </td>
                                                                    <td id="td-right">
                                                                    </td><td>
                                                                    </td>

                                                                </tr>
                                                                <tr>
                                                                    <td id="td-right">
                                                                        <span>Product</span>
                                                                    </td><td>
                                                                        <Link to={`/product/product-detail/${tcf.new_products_id}`}>{tcf.product}</Link>
                                                                    </td>
                                                                    <td id="td-right">
                                                                    </td><td>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td id="td-right">
                                                                        <span>Product Name</span>
                                                                    </td><td>
                                                                        {tcf.product_name}
                                                                    </td>
                                                                    <td id="td-right">
                                                                    </td><td>
                                                                    </td>
                                                                </tr>
                                                                <tr>

                                                                </tr>
                                                                <tr>

                                                                </tr>
                                                                <tr>

                                                                </tr>
                                                                <tr>

                                                                </tr>
                                                            </tbody>
                                                        </table>

                                                        <div className="col-md-12" id="ax.account_details">
                                                            <h4 className="heading">Shipment Quantity</h4>
                                                            <hr></hr>
                                                        </div>
                                                        <table class="table table-bordered account-table tables">
                                                            <tbody>
                                                                <tr>
                                                                    <td id="td-right">
                                                                        <span className="tooltips-label">Shipment Qty</span>
                                                                        <span id='tooltip' data-tooltip-id="button-tooltip2" data-tooltip-place='right'>
                                                                            <IoIosHelpCircle />
                                                                        </span>
                                                                        <ReactTooltip
                                                                            id="button-tooltip2"
                                                                            place="bottom"
                                                                            content="IF (ISNULL( A_BL_Quantity__c ),  IF(ISNULL(Stowage_Qty__c), Contract_Quantity_MT__c  , Stowage_Qty__c),  A_BL_Quantity__c )"
                                                                        />
                                                                    </td><td>
                                                                        {renderShipmentQty(tcf.shipment_qty)}
                                                                    </td>
                                                                    <td id="td-right">
                                                                        <span className="tooltips-label">Shipment Month</span>
                                                                        <span id='tooltip' data-tooltip-id="button-tooltip3" data-tooltip-place='right'>
                                                                            <IoIosHelpCircle />
                                                                        </span>
                                                                        <ReactTooltip
                                                                            id="button-tooltip3"
                                                                            place="bottom"
                                                                            content="text(MONTH(Ship_Month__c )) &amp; &quot; &quot; &amp; text (Year (Ship_Month__c))"
                                                                        />

                                                                    </td><td>
                                                                        {tcf.shipment_month}
                                                                    </td>

                                                                </tr>
                                                                <tr>
                                                                    <td id="td-right">
                                                                        <span>shipment purchase price</span>
                                                                    </td><td>
                                                                        {formatNumber(tcf.shipment_purchase_price)}
                                                                    </td>
                                                                    <td id="td-right">
                                                                        <span className="tooltips-label">shipment sales price</span>
                                                                        <span id='tooltip' data-tooltip-id="button-tooltip4" data-tooltip-place='right'>
                                                                            <IoIosHelpCircle />
                                                                        </span>
                                                                        <ReactTooltip
                                                                            id="button-tooltip4"
                                                                            place="bottom"
                                                                            content="text(MONTH(Ship_Month__c )) &amp; &quot; &quot; &amp; text (Year (Ship_Month__c))"
                                                                        />

                                                                    </td><td>
                                                                        {renderShipmentQty(tcf.shipment_sales_price)}
                                                                    </td>
                                                                </tr><tr>
                                                                    <td id="td-right">
                                                                        <span>shipment purchase </span>
                                                                    </td><td>
                                                                        {renderShipmentQty(tcf.shipment_purchase)}
                                                                    </td>
                                                                    <td id="td-right">
                                                                        <span>shipment sales</span>
                                                                    </td><td>
                                                                        {renderShipmentQty(tcf.shipment_sales)}
                                                                    </td>
                                                                </tr>{" "}
                                                                <tr>
                                                                    <td id="td-right">
                                                                        <span className="tooltips-label">shipment gross margin ton</span>
                                                                        <span id='tooltip' data-tooltip-id="button-tooltip7" data-tooltip-place='right'>
                                                                            <IoIosHelpCircle />
                                                                        </span>
                                                                        <ReactTooltip
                                                                            id="button-tooltip7"
                                                                            place="bottom"
                                                                            content="Workflow Rule IF (ISBLANK(Actual_Gross_Margin_Ton__c ), Projected_Gross_Margin_Ton__c , Actual_Gross_Margin_Ton__c "
                                                                        />
                                                                    </td><td>
                                                                        {renderShipmentQty(tcf.shipment_gross_margin_ton)}
                                                                    </td>
                                                                    <td id="td-right">
                                                                        <span className="tooltips-label">shipment gross margin</span>
                                                                        <span id='tooltip' data-tooltip-id="button-tooltip5" data-tooltip-place='right'>
                                                                            <IoIosHelpCircle />
                                                                        </span>
                                                                        <ReactTooltip
                                                                            id="button-tooltip5"
                                                                            place="bottom"
                                                                            content="Shipment_Gross_Margin_Ton__c \* Shipment_Qty__c"
                                                                        />
                                                                    </td><td>
                                                                        {renderShipmentQty(tcf.shipment_gross_margin)}
                                                                    </td>

                                                                </tr>

                                                            </tbody>
                                                        </table>
                                                        <div className="col-md-12" id="ax.account_details">
                                                            <h4 className="heading">Trade Confirmation Details</h4>
                                                            <hr></hr>
                                                        </div>
                                                        <table class="table table-bordered account-table tables">
                                                            <tbody>

                                                                <tr>
                                                                    <td id="td-right"><span>Buyer</span></td>
                                                                    <td><Link to={`/accounts/${tcf.account_id}`}>{tcf.buyer}</Link></td>
                                                                    <td id="td-right"><span>Status of Buyer Contract</span></td>
                                                                    <td>{tcf.status_of_buyer_contract}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td id="td-right"><span>Supplier</span></td>
                                                                    <td><Link to={`/accounts/${tcf.supplier_id}`}>{tcf.supplier}</Link></td>
                                                                    <td id="td-right"><span>Status of purchase contract</span></td>
                                                                    <td>{tcf.status_of_purchase_contract}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td id="td-right"><span>Product Description</span></td>
                                                                    <td>{tcf.product_description}</td>
                                                                    <td id="td-right"><span className="tooltips-label">ship Month</span>
                                                                        <span id='tooltip' data-tooltip-id="button-tooltip8" data-tooltip-place='right'>
                                                                            <IoIosHelpCircle />
                                                                        </span>
                                                                        <ReactTooltip
                                                                            id="button-tooltip8"
                                                                            place="bottom"
                                                                            content="Laycan From Date"
                                                                        /> </td>
                                                                    <td>{tcf.ship_month}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td id="td-right"><span>Product Specification</span></td>
                                                                    <td>{tcf.product_specification}</td>
                                                                    <td id="td-right"></td>
                                                                    <td></td>

                                                                </tr>
                                                                <tr>
                                                                    <td id="td-right"><span>Quantity MT</span></td>
                                                                    <td>{renderShipmentQty(tcf.quantity_MT)}</td>
                                                                    <td id="td-right"><span>Proposed Laycan From</span></td>
                                                                    <td>{tcf.proposed_laycan_from}</td>

                                                                </tr>
                                                                <tr>
                                                                    <td id="td-right"><span>Price Basis</span></td>
                                                                    <td>{tcf.price_basis}</td>
                                                                    <td id="td-right"><span>Proposed Laycan To</span></td>
                                                                    <td>{tcf.proposed_laycan_to}</td>

                                                                </tr>
                                                                <tr>
                                                                    <td id="td-right"><span>Vessel Type</span></td>
                                                                    <td>{tcf.vessel_type}</td>
                                                                    <td id="td-right"></td>
                                                                    <td></td>

                                                                </tr>
                                                                <tr>
                                                                    <td id="td-right"><span>Payment Terms</span></td>
                                                                    <td>{tcf.payment_term}</td>
                                                                    <td id="td-right"></td>
                                                                    <td></td>

                                                                </tr>
                                                                <tr>
                                                                    <td id="td-right"><span>Selling Terms</span></td>
                                                                    <td>{tcf.selling_terms}</td>
                                                                    <td id="td-right"></td>
                                                                    <td></td>

                                                                </tr>
                                                                <tr>
                                                                    <td id="td-right"><span>Buying Terms</span></td>
                                                                    <td>{tcf.buying_terms}</td>
                                                                    <td id="td-right"></td>
                                                                    <td></td>

                                                                </tr>
                                                                <tr>
                                                                    <td id="td-right"><span>Price Adjustment</span></td>
                                                                    <td>{tcf.price_adjustment}</td>
                                                                    <td id="td-right"></td>
                                                                    <td></td>
                                                                </tr>

                                                            </tbody>
                                                        </table>

                                                        <div className="col-md-12" id="ax.account_details">
                                                            <h4 className="heading">Contractual</h4>
                                                            <hr></hr>
                                                        </div>
                                                        <table class="table table-bordered account-table tables">
                                                            <tbody>
                                                                <tr>
                                                                    <td id="td-right"><span>Contractual GAR (P)</span></td>
                                                                    <td>{renderShipmentQty(tcf.contractual_GAR_P)}</td>
                                                                    <td id="td-right"><span>Contractual GAR (S)</span></td>
                                                                    <td>{renderShipmentQty(tcf.contractual_GAR_S)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td id="td-right"><span>Contractual TM (AR) (P)</span></td>
                                                                    <td>{renderShipmentQty(tcf.contractual_TM_AR_P)}</td>
                                                                    <td id="td-right"><span>Contractual TM (AR) (S)</span></td>
                                                                    <td>{renderShipmentQty(tcf.contractual_TM_AR_S)}</td>
                                                                </tr>
                                                                <tr>

                                                                    <td id="td-right"><span>Contractual TS (AR) (P)</span></td>
                                                                    <td>{renderShipmentQty(tcf.contractual_Ts_AR_p)}</td>
                                                                    <td id="td-right"><span>Contractual TS (AR) (S)</span></td>
                                                                    <td>{renderShipmentQty(tcf.contractual_Ts_AR_s)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td id="td-right"><span>Contractual Ash (AR) (P)</span></td>
                                                                    <td>{renderShipmentQty(tcf.contractual_Ash_AR_P)}</td>
                                                                    <td id="td-right"><span>Contractual Ash (AR) (S)</span></td>
                                                                    <td>{renderShipmentQty(tcf.contractual_Ash_AR_S)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td id="td-right"><span>Contractual TS (ADB) (P)</span></td>
                                                                    <td>{renderShipmentQty(tcf.contractual_Ts_ADB_P)}</td>
                                                                    <td id="td-right"><span>Contractual TS (ADB) (S)</span></td>
                                                                    <td>{renderShipmentQty(tcf.contractual_Ts_ADB_S)}</td>
                                                                </tr>

                                                                <tr>
                                                                    <td id="td-right"><span>Contractual Ash (ADB) (P)	</span></td>
                                                                    <td>{renderShipmentQty(tcf.contractual_Ash_ADB_P)}</td>
                                                                    <td id="td-right"><span>Contractual Ash (ADB) (S)	</span></td>
                                                                    <td>{renderShipmentQty(tcf.contractual_Ash_ADB_S)}</td>
                                                                </tr>



                                                                <tr>
                                                                    <td id="td-right"><span>Projected Price Basis GAR (P)
                                                                    </span></td>
                                                                    <td>{renderShipmentQty(tcf.projected_price_basis_GAR_P)}</td>
                                                                    <td id="td-right"><span>Projected Price Basis GAR (S)
                                                                    </span></td>
                                                                    <td>{renderShipmentQty(tcf.projected_price_basis_GAR_S)}</td>
                                                                </tr>


                                                                <tr class="space"><td id="td-right"><span></span></td><td></td><td id="td-right"><span></span></td><td></td></tr>


                                                                <tr>
                                                                    <td id="td-right"><span>Contractual HBA Year</span></td>
                                                                    <td>{tcf.contractual_HBA_year}</td>
                                                                    <td id="td-right">  <span className="tooltips-label">Discounted Price</span>
                                                                        <span id='tooltip' data-tooltip-id="button-tooltip11" data-tooltip-place='right'>
                                                                            <IoIosHelpCircle />
                                                                        </span>
                                                                        <ReactTooltip
                                                                            id="button-tooltip11"
                                                                            place="bottom"
                                                                            content="Discounted Price as per Vessel Type \r\u003Cbr\u003EIf Geared (GNG) - 0.90\r\u003Cbr\u003Eif Grearless - 0.92"
                                                                        /> </td>
                                                                    <td>{renderShipmentQty(tcf.discounted_price)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td id="td-right"><span>Contractual HBA Month</span></td>
                                                                    <td>{tcf.contractual_HBA_month}</td>
                                                                    <td id="td-right">  <span className="tooltips-label">G1/G2  </span>
                                                                        <span id='tooltip' data-tooltip-id="button-tooltip12" data-tooltip-place='right'>
                                                                            <IoIosHelpCircle />
                                                                        </span>
                                                                        <ReactTooltip
                                                                            id="button-tooltip12"
                                                                            place="bottom"
                                                                            content="( Actual_GAR_P__c \*  GCNEWC__c  / 6322 ) \*  Discounted_Price__c')"
                                                                        /> </td>
                                                                    <td>{tcf.g1_g2}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td id="td-right">  <span className="tooltips-label">Projected HBA</span>
                                                                        <span id='tooltip' data-tooltip-id="button-tooltip10" data-tooltip-place='right'>
                                                                            <IoIosHelpCircle />
                                                                        </span>
                                                                        <ReactTooltip
                                                                            id="button-tooltip10"
                                                                            place="bottom"
                                                                            content="Projected HBA - Derrived from Opportunity.Pricebook.Projected HBA (Contractual_HBA)"
                                                                        /> </td>
                                                                    <td>{renderShipmentQty(tcf.projected_hba)}</td>

                                                                    <td id="td-right"><span>Projected Index Value</span></td>
                                                                    <td>{renderShipmentQty(tcf.projected_index_value)}</td>
                                                                </tr>


                                                                <tr class="space"><td id="td-right"><span></span></td><td></td><td id="td-right"><span></span></td><td></td></tr>

                                                                <tr>
                                                                    <td id="td-right"><span>Projected Purchase Price Type</span></td>
                                                                    <td>{tcf.projected_purchase_type}</td>
                                                                    <td id="td-right"><span>Projected Sale Price Type</span></td>
                                                                    <td>{tcf.projected_sale_price_type}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td id="td-right"><span>Projected Fixed Purchase Price</span></td>
                                                                    <td>{renderShipmentQty(tcf.projected_fixed_purchase_price)}</td>
                                                                    <td id="td-right"><span>Projected Fixed Sales Price</span></td>
                                                                    <td>{renderShipmentQty(tcf.projected_fixed_sales_price)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td id="td-right"><span>Projected Purchase Price Margin</span></td>
                                                                    <td>{renderShipmentQty(tcf.projected_purchase_price_margin)}</td>
                                                                    <td id="td-right"><span>Projected Sales Price Margin</span></td>
                                                                    <td>{renderShipmentQty(tcf.projected_sales_price_margin)}</td>
                                                                </tr>
                                                                <tr>

                                                                    <td id="td-right"><span>Projected Barge Discount</span></td>
                                                                    <td>{renderShipmentQty(tcf.barge_discount)}</td>
                                                                    <td id="td-right"></td>
                                                                    <td></td>
                                                                </tr>

                                                                <tr class="space"><td id="td-right"><span></span></td><td></td><td id="td-right"><span></span></td><td></td></tr>

                                                                <tr>

                                                                    <td id="td-right">  <span className="tooltips-label">Contractual Pur Price</span>
                                                                        <span id='tooltip' data-tooltip-id="button-tooltip14" data-tooltip-place='right'>
                                                                            <IoIosHelpCircle />
                                                                        </span>
                                                                        <ReactTooltip
                                                                            id="button-tooltip14"
                                                                            place="bottom"
                                                                            content="HPB=(Contractual_HBA\*C_GAR_P)/ 6322)\*( (100-TM_AR)/(100-8)))-(((ContractualTS(ARB)-0.8)\*4)+((Contractual_Ash-15)\*0.4)))\r\u003Cbr\u003EFixed price=ProjFixed_Purchase_Price\*C_GAR P /Proj Price_Basis_GAR P\r\u003Cbr\u003EGCNEWC=Proj. GCNEWC\*CGAR P/ProjectPrice Basis GAR P + ProjMargin"
                                                                        /> </td>
                                                                    <td>{renderShipmentQty(tcf.contractual_pur_price)}</td>
                                                                    <td id="td-right"><span></span></td>
                                                                    <td></td>
                                                                </tr>

                                                                <tr>

                                                                    <td id="td-right"><span>Projected Purchase Price</span></td>
                                                                    <td>{renderShipmentQty(tcf.projected_purchase_price)}</td>
                                                                    <td id="td-right"><span></span></td>
                                                                    <td></td>
                                                                </tr>

                                                                <tr>
                                                                    <td id="td-right"><span>Projected Sales Price</span></td>
                                                                    <td>{renderShipmentQty(tcf.projected_sales_price)}</td>
                                                                    <td id="td-right"></td>
                                                                    <td></td>
                                                                </tr>
                                                                <tr>  <td id="td-right"><span>Contractual Margin</span></td>
                                                                    <td>{renderShipmentQty(tcf.contractual_margin)}</td>
                                                                    <td id="td-right"><span></span></td>
                                                                    <td></td></tr>
                                                            </tbody>
                                                        </table>

                                                        <div className="col-md-12" id="ax.account_details">
                                                            <h4 className="heading">Cost Sheet Projection</h4>
                                                            <hr></hr>
                                                        </div>

                                                        <table class="table table-bordered account-table tables">
                                                            <tbody>
                                                                <tr>
                                                                    <td id="td-right"><span>projected pur amount</span>
                                                                        <span id='tooltip' data-tooltip-id="button-tooltips1" data-tooltip-place='right'>
                                                                            <IoIosHelpCircle />
                                                                        </span>
                                                                        <ReactTooltip
                                                                            id="button-tooltips1"
                                                                            place="bottom"
                                                                            content="Work Flow Rule - (Contract_Quantity_MT \* round(Contractual_Pur_Price,2))"
                                                                        />  </td>
                                                                    <td>{renderShipmentQty(tcf.projected_pur_amount)}</td>
                                                                    <td id="td-right"><span>projected sales</span>
                                                                        <span id='tooltip' data-tooltip-id="button-tooltips2" data-tooltip-place='right'>
                                                                            <IoIosHelpCircle />
                                                                        </span>
                                                                        <ReactTooltip
                                                                            id="button-tooltips2"
                                                                            place="bottom"
                                                                            content="Workflow Rules - Projected_Sales_Price__c \* Contract_Quantity_MT__c'"
                                                                        />  </td>
                                                                    <td>{renderShipmentQty(tcf.projected_sales)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td id="td-right"><span>projected fob purchase</span>
                                                                        <span id='tooltip' data-tooltip-id="button-tooltips3" data-tooltip-place='right'>
                                                                            <IoIosHelpCircle />
                                                                        </span>
                                                                        <ReactTooltip
                                                                            id="button-tooltips3"
                                                                            place="bottom"
                                                                            content="Projected Pur Amount"
                                                                        />  </td>
                                                                    <td>{renderShipmentQty(tcf.projected_fob_purchase)}</td>
                                                                    <td id="td-right"><span></span></td>
                                                                    <td></td>
                                                                </tr>
                                                                <tr>

                                                                    <td id="td-right"><span></span></td>
                                                                    <td></td>
                                                                    <td id="td-right"><span>projected gross margin shipment</span>
                                                                        <span id='tooltip' data-tooltip-id="button-tooltips4" data-tooltip-place='right'>
                                                                            <IoIosHelpCircle />
                                                                        </span>
                                                                        <ReactTooltip
                                                                            id="button-tooltips4"
                                                                            place="bottom"
                                                                            content="Projected_Sales__c - Projected_FOB_Purchase__c - Projected_Freight_Amount__c -  Projected_Insurance__c - Projected_Commision__c"
                                                                        />  </td>
                                                                    <td>{renderShipmentQty(tcf.projected_gross_margin_shipment)}</td>

                                                                </tr>
                                                                <tr>
                                                                    <td id="td-right"><span>projected freight rate</span></td>
                                                                    <td>{renderShipmentQty(tcf.projected_freight_rate)}</td>
                                                                    <td id="td-right"><span>projected gross margin TON</span>
                                                                        <span id='tooltip' data-tooltip-id="button-tooltips5" data-tooltip-place='right'>
                                                                            <IoIosHelpCircle />
                                                                        </span>
                                                                        <ReactTooltip
                                                                            id="button-tooltips5"
                                                                            place="bottom"
                                                                            content="Projected_Gross_Margin_Shipment__c /  Contract_Quantity_MT__c"
                                                                        />  </td>
                                                                    <td>{renderShipmentQty(tcf.projected_gross_margin_ton)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td id="td-right"><span>projected freight amount</span></td>
                                                                    <td>{renderShipmentQty(tcf.projected_freight_amount)}</td>
                                                                    <td id="td-right"><span></span></td>
                                                                    <td></td>
                                                                </tr>
                                                                <tr>
                                                                    <td id="td-right"><span>projected insurance</span></td>
                                                                    <td>{renderShipmentQty(tcf.projected_insurance)}</td>

                                                                    <td id="td-right"><span>Projected Net Margin - Shipment</span></td>
                                                                    <td>{renderShipmentQty(tcf.projected_net_margin_shipment)}</td>
                                                                </tr>

                                                                <tr>
                                                                    <td id="td-right"><span>Projected Commision
                                                                    </span></td>
                                                                    <td>{renderShipmentQty(tcf.projected_commission)}</td>

                                                                    <td id="td-right"><span>Projected Net Margin - Ton</span></td>
                                                                    <td>{renderShipmentQty(tcf.projected_net_margin_ton)}</td>
                                                                </tr>
                                                                <tr class="space"><td id="td-right"><span></span></td><td></td><td id="td-right"><span></span></td><td></td></tr>
                                                                <tr>

                                                                    <td id="td-right"><span>projected  LC Transfer cost</span>
                                                                        <span id='tooltip' data-tooltip-id="button-tooltips9" data-tooltip-place='right'>
                                                                            <IoIosHelpCircle />
                                                                        </span>
                                                                        <ReactTooltip
                                                                            id="button-tooltips9"
                                                                            place="bottom"
                                                                            content="Projected_FOB_Purchase__c + Projected_FOB_Purchase__c /10 ) \* Rwood_Bank_Master__r.LC_Transfer_Rate__c"
                                                                        />
                                                                    </td>
                                                                    <td>{renderShipmentQty(tcf.projected_lc_transfer_cost)}</td>
                                                                    <td id="td-right"><span>projected total bank cost</span>
                                                                        <span id='tooltip' data-tooltip-id="button-tooltips6" data-tooltip-place='right'>
                                                                            <IoIosHelpCircle />
                                                                        </span>
                                                                        <ReactTooltip
                                                                            id="button-tooltips6"
                                                                            place="bottom"
                                                                            content="Projected_LC_Transfer_Cost__c + \r\u003Cbr\u003EProjected_LC_Negotiation_Cost__c + \r\u003Cbr\u003EProjected_Fixed_Other_Bank_Costs__c + \r\u003Cbr\u003EProjected_Adding_Confirmation_Charges__c"
                                                                        />
                                                                    </td>
                                                                    <td>{renderShipmentQty(tcf.projected_total_bank_cost)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td id="td-right"><span>projected  LC negotiation cost</span>
                                                                        <span id='tooltip' data-tooltip-id="button-tooltips8" data-tooltip-place='right'>
                                                                            <IoIosHelpCircle />
                                                                        </span>
                                                                        <ReactTooltip
                                                                            id="button-tooltips8"
                                                                            place="bottom"
                                                                            content="Projected_Sales__c \* Rwood_Bank_Master__r.LC_Negotiation_Rate__c Adding Confirmation Charges"
                                                                        />  </td>
                                                                    <td>{renderShipmentQty(tcf.projected_lc_negotiation_cost)}</td>
                                                                    <td id="td-right"><span>projected cost</span>
                                                                        <span id='tooltip' data-tooltip-id="button-tooltips7" data-tooltip-place='right'>
                                                                            <IoIosHelpCircle />
                                                                        </span>
                                                                        <ReactTooltip
                                                                            id="button-tooltips7"
                                                                            place="bottom"
                                                                            content="Pj_LC_Transfer_Cost+Pj_LC_Nego_Cost+Pj_Fixed_Other_Bank_Costs+Pj_Any_Contract_Spe_Cost+Pj_Fixed_Appo_Overheads+Pj_Adding_Conf_Charges+Pj_Extra_Cost+Pj_Freight_Amt+Pj. Insurance+Pj_Prov_for_corp Tax+Pj_Commission+PJ_Witholding Tax"
                                                                        />  </td>
                                                                    <td>{renderShipmentQty(tcf.projected_cost)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td id="td-right"><span>projected fixed other bank costs</span>
                                                                        <span id='tooltip' data-tooltip-id="button-tooltips10" data-tooltip-place='right'>
                                                                            <IoIosHelpCircle />
                                                                        </span>
                                                                        <ReactTooltip
                                                                            id="button-tooltips10"
                                                                            place="bottom"
                                                                            content="Rwood_Bank_Master__r.Fixed_Other_Cost__c \* Contract_Quantity_MT__c"
                                                                        />  </td>
                                                                    <td>{renderShipmentQty(tcf.projected_fixed_other_bank_costs)}</td>
                                                                    <td id="td-right"><span>projected cost TON</span></td>
                                                                    <td>{renderShipmentQty(tcf.projected_cost_ton)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td id="td-right"><span>projected fixed apportioned overheads</span></td>
                                                                    <td>{renderShipmentQty(tcf.projected_fixed_apportioned_overheads)}</td>
                                                                    <td id="td-right"><span>projected total cost</span></td>
                                                                    <td>{renderShipmentQty(tcf.projected_total_cost)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td id="td-right"><span>projected any contract specific cost</span></td>
                                                                    <td>{renderShipmentQty(tcf.projected_any_contract_specific_cost)}</td>
                                                                    <td id="td-right"><span></span></td>
                                                                    <td></td>
                                                                </tr>
                                                                <tr>
                                                                    <td id="td-right"><span>projected extra  cost</span></td>
                                                                    <td>{renderShipmentQty(tcf.projected_extra_cost)}</td>
                                                                    <td id="td-right"><span></span></td>
                                                                    <td></td>
                                                                </tr>
                                                                <tr>
                                                                    <td id="td-right"><span>projected provision for corporate tax</span>
                                                                        <span id='tooltip' data-tooltip-id="button-tooltips11" data-tooltip-place='right'>
                                                                            <IoIosHelpCircle />
                                                                        </span>
                                                                        <ReactTooltip
                                                                            id="button-tooltips11"
                                                                            place="bottom"
                                                                            content="1.5% Revenue"
                                                                        />     </td>
                                                                    <td>{renderShipmentQty(tcf.projected_provision_for_corporate_tax)}</td>
                                                                    <td id="td-right"><span></span></td>
                                                                    <td></td>
                                                                </tr>
                                                                <tr>
                                                                    <td id="td-right"><span>projected withholding tax</span>
                                                                        <span id='tooltip' data-tooltip-id="button-tooltips12" data-tooltip-place='right'>
                                                                            <IoIosHelpCircle />
                                                                        </span>
                                                                        <ReactTooltip
                                                                            id="button-tooltips12"
                                                                            place="bottom"
                                                                            content="IF ( Company__c= PT RRI , Projected_Sales__c \* Rwood_Bank_Master__r.Tax__c, 0)"
                                                                        />  </td>
                                                                    <td>{renderShipmentQty(tcf.projected_withholding_tax)}</td>
                                                                    <td id="td-right"><span></span></td>
                                                                    <td></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        <div className="col-md-12" id="ax.account_details">
                                                            <h4 className="heading">Actual Shipment Details</h4>
                                                            <hr></hr>
                                                        </div>

                                                        <table class="table table-bordered account-table tables">
                                                            <tbody>
                                                                <tr>
                                                                    <td id="td-right"><span>vessel name</span>
                                                                        <span id='tooltip' data-tooltip-id="button-tooltips13" data-tooltip-place='right'>
                                                                            <IoIosHelpCircle />
                                                                        </span>
                                                                        <ReactTooltip
                                                                            id="button-tooltips13"
                                                                            place="bottom"
                                                                            content="if Barge name is BLANK - Order__r.Vessel_Name__c OR Vessel Name &amp; Barge Name"
                                                                        />  </td>
                                                                    <td>{tcf.vessel_name}</td>
                                                                    <td id="td-right"><span>demmurage rate (P)</span>
                                                                        <span id='tooltip' data-tooltip-id="button-tooltips14" data-tooltip-place='right'>
                                                                            <IoIosHelpCircle />
                                                                        </span>
                                                                        <ReactTooltip
                                                                            id="button-tooltips14"
                                                                            place="bottom"
                                                                            content="Order__r.S_Despatch_Demmurage_Rate__c"
                                                                        />  </td>
                                                                    <td>{renderShipmentQty(tcf.demmurage_rate_p)}</td>
                                                                </tr>

                                                                <tr>
                                                                    <td id="td-right"><span>stowage quantity</span>
                                                                        <span id='tooltip' data-tooltip-id="button-tooltips15" data-tooltip-place='right'>
                                                                            <IoIosHelpCircle />
                                                                        </span>
                                                                        <ReactTooltip
                                                                            id="button-tooltips15"
                                                                            place="bottom"
                                                                            content="Order__r.Pre_stowage__c"
                                                                        />  </td>
                                                                    <td>{renderShipmentQty(tcf.stowage_qty)}</td>
                                                                    <td id="td-right"><span>demmurage rate (S)</span>
                                                                        <span id='tooltip' data-tooltip-id="button-tooltips16" data-tooltip-place='right'>
                                                                            <IoIosHelpCircle />
                                                                        </span>
                                                                        <ReactTooltip
                                                                            id="button-tooltips16"
                                                                            place="bottom"
                                                                            content="Order__r.B_Despatch_Demmurage_Rate__c"
                                                                        />  </td>
                                                                    <td>{renderShipmentQty(tcf.demmurage_rate_s)}</td>
                                                                </tr>

                                                                <tr>
                                                                    <td id="td-right"><span>Destination</span>
                                                                        <span id='tooltip' data-tooltip-id="button-tooltips17" data-tooltip-place='right'>
                                                                            <IoIosHelpCircle />
                                                                        </span>
                                                                        <ReactTooltip
                                                                            id="button-tooltips17"
                                                                            place="bottom"
                                                                            content="Order. Destination or Buyer contract.destination."
                                                                        />  </td>
                                                                    <td>{tcf.destination}</td>
                                                                    <td id="td-right"><span>Load Rate</span>
                                                                        <span id='tooltip' data-tooltip-id="button-tooltips18" data-tooltip-place='right'>
                                                                            <IoIosHelpCircle />
                                                                        </span>
                                                                        <ReactTooltip
                                                                            id="button-tooltips18"
                                                                            place="bottom"
                                                                            content="Order__r.Loading_Rate__c"
                                                                        />  </td>
                                                                    <td>{renderShipmentQty(tcf.load_rate)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td id="td-right"><span>Actual BL Date</span>

                                                                        <span id='tooltip' data-tooltip-id="button-tooltips19" data-tooltip-place='right'>
                                                                            <IoIosHelpCircle />
                                                                        </span>
                                                                        <ReactTooltip
                                                                            id="button-tooltips19"
                                                                            place="bottom"
                                                                            content="Order__r.Loading_Rate__c"
                                                                        />  </td>
                                                                    <td>{tcf.actual_bl_date}</td>
                                                                    <td id="td-right"><span></span></td>
                                                                    <td></td>
                                                                </tr>
                                                                <tr>

                                                                    <td id="td-right"><span>Actual BL Qty</span>
                                                                        <span id='tooltip' data-tooltip-id="button-tooltips20" data-tooltip-place='right'>
                                                                            <IoIosHelpCircle />
                                                                        </span>
                                                                        <ReactTooltip
                                                                            id="button-tooltips20"
                                                                            place="bottom"
                                                                            content="Order.Total BL Quantity"
                                                                        />  </td>
                                                                    <td>{renderShipmentQty(tcf.actual_bl_qty)}</td>
                                                                    <td id="td-right"><span>GCNEWC</span>
                                                                        <span id='tooltip' data-tooltip-id="button-tooltips21" data-tooltip-place='right'>
                                                                            <IoIosHelpCircle />
                                                                        </span>
                                                                        <ReactTooltip
                                                                            id="button-tooltips21"
                                                                            place="bottom"
                                                                            content="pricebook.GCNEWC"
                                                                        />  </td>
                                                                    <td>{renderShipmentQty(tcf.gcnewc)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td id="td-right"><span>Actual HBA</span>
                                                                        <span id='tooltip' data-tooltip-id="button-tooltips22" data-tooltip-place='right'>
                                                                            <IoIosHelpCircle />
                                                                        </span>
                                                                        <ReactTooltip
                                                                            id="button-tooltips22"
                                                                            place="bottom"
                                                                            content="Price Book.Actual HBA"
                                                                        />  </td>
                                                                    <td>{renderShipmentQty(tcf.actual_hba)}</td>
                                                                    <td id="td-right"><span>Actual GCNEWC - Sales</span>
                                                                        <span id='tooltip' data-tooltip-id="button-tooltips23" data-tooltip-place='right'>
                                                                            <IoIosHelpCircle />
                                                                        </span>
                                                                        <ReactTooltip
                                                                            id="button-tooltips23"
                                                                            place="bottom"
                                                                            content="CASE(Sale_Price_Type, '(Avg Prev Qtr GCNEWC + Margin)  GCV Adj', Price_Book__r.Avg_GCNEWC_Prev_QTR__c , '(Avg Curr Qtr GCNEWC + Margin) GCV Adj',  Price_Book__r.Avg_GCNEWC_Current_QTR__c , 'Avg Prev Qtr GCNEWC + Margin',  Price_Book__r.Av"
                                                                        /> </td>
                                                                    <td>{renderShipmentQty(tcf.actual_gcnewc_sales)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td id="td-right"><span>Actual HBA - Purchase</span>
                                                                        <span id='tooltip' data-tooltip-id="button-tooltips24" data-tooltip-place='right'>
                                                                            <IoIosHelpCircle />
                                                                        </span>
                                                                        <ReactTooltip
                                                                            id="button-tooltips24"
                                                                            place="bottom"
                                                                            content="CASE ( Purchase_Price_Type.Name , 'Avg Prev Qtr HBA + Margin', Price_Book__r.Avg_HBA_Prev_QTR__c, 'Avg Current Qtr HBA + Margin', 'Price_Book__r.Avg_HBA_Current_QTR__c , 'Price_Book__r.Actual_HBA__c)"
                                                                        />  </td>
                                                                    <td>{renderShipmentQty(tcf.actual_hba_purchase)}</td>
                                                                    <td id="td-right"><span>Laycan</span>
                                                                        <span id='tooltip' data-tooltip-id="button-tooltips25" data-tooltip-place='right'>
                                                                            <IoIosHelpCircle />
                                                                        </span>
                                                                        <ReactTooltip
                                                                            id="button-tooltips25"
                                                                            place="bottom"
                                                                            content="Text(Day (Order__r.Laycan_From_Date__c)) & / TEXT(Month (Order__r.Laycan_From_Date__c)) & - & TExt(Day ( Order__r.Laycan_To_Date__c )) & / TEXT(Month(Order__r.Laycan_To_Date__c))  & '' & TEXT(year (Order__r.Laycan_From_Date__c))"
                                                                        />  </td>
                                                                    <td>{tcf.laycan}</td>
                                                                </tr>
                                                                <tr>

                                                                    <td id="td-right"><span>Expected Value Date</span>
                                                                        <span id='tooltip' data-tooltip-id="button-tooltips26" data-tooltip-place='right'>
                                                                            <IoIosHelpCircle />
                                                                        </span>
                                                                        <ReactTooltip
                                                                            id="button-tooltips26"
                                                                            place="bottom"
                                                                            content="Order__r.all Expected_Value_Date__c"
                                                                        />  </td>
                                                                    <td>{tcf.expected_value_date}</td>

                                                                    <td id="td-right"><span></span></td>
                                                                    <td></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>


                                                        <div className="col-md-12" id="ax.account_details">
                                                            <h4 className="heading">Actual Cost Elements</h4>
                                                            <hr></hr>
                                                        </div>

                                                        <table class="table table-bordered account-table tables">
                                                            <tbody>
                                                                <tr>
                                                                    <td id="td-right"><span>Purchase Price Type</span></td>
                                                                    <td>{tcf.purchase_price_type}</td>
                                                                    <td id="td-right"><span>Actual Data Rcvd</span></td>
                                                                    <td><input
                                                                        type='checkbox'
                                                                        name='actual_data_rcvd'
                                                                        checked={dataReceived} // Use th e state variable to determine if the checkbox is checked
                                                                        onChange={handleData}// Handle the change event
                                                                        className='mx-3' disabled={isButtonEnabled}
                                                                    /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td id="td-right"><span>fixed purchase price</span></td>
                                                                    <td>{tcf.fixed_purchase_price}</td>
                                                                    <td id="td-right"><span>Sale Price Type</span></td>
                                                                    <td>{tcf.sale_price_type}</td>

                                                                </tr>
                                                                <tr>
                                                                    <td id="td-right"><span>Purchase Price Margin</span></td>
                                                                    <td>{tcf.purchase_price_margin}</td>
                                                                    <td id="td-right"><span>Fixed Sale Price</span></td>
                                                                    <td>{tcf.fixed_sale_price}</td>

                                                                </tr>
                                                                <tr>
                                                                    <td id="td-right"><span>Barge Discount</span>
                                                                        <span id='tooltip' data-tooltip-id="button-tooltips27" data-tooltip-place='right'>
                                                                            <IoIosHelpCircle />
                                                                        </span>
                                                                        <ReactTooltip
                                                                            id="button-tooltips27"
                                                                            place="bottom"
                                                                            content="Barge Discount if applicable"
                                                                        />  </td>
                                                                    <td>{renderShipmentQty(tcf.barge_discount)}</td>
                                                                    <td id="td-right"><span>Sales Price Margin</span></td>
                                                                    <td>{tcf.sale_price_margin}</td>
                                                                </tr>
                                                                <tr class="space"><td id="td-right"><span></span></td><td></td><td id="td-right"><span></span></td><td></td></tr>
                                                                <tr>

                                                                    <td id="td-right"><span>Actual GAR (P)</span></td>
                                                                    <td>{renderShipmentQty(tcf.actual_gar_p)}</td>
                                                                    <td id="td-right"><span>Actual GAR (s)</span></td>
                                                                    <td>{renderShipmentQty(tcf.actual_gar_s)}</td>
                                                                </tr>
                                                                <tr>

                                                                    <td id="td-right"><span>Actual TM (AR) (P)</span></td>
                                                                    <td>{renderShipmentQty(tcf.actual_tm_p)}</td>
                                                                    <td id="td-right"><span>Actual TM (AR) (S)</span></td>
                                                                    <td>{renderShipmentQty(tcf.actual_tm_s)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td id="td-right"><span>Actual Ash (AR) (P)</span></td>
                                                                    <td>{renderShipmentQty(tcf.actual_ash_ar_p)}</td>
                                                                    <td id="td-right"><span>Actual Ash (AR) (S)</span></td>
                                                                    <td>{renderShipmentQty(tcf.actual_ash_ar_s)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td id="td-right"><span>Actual TS (AR) (P)</span></td>
                                                                    <td>{renderShipmentQty(tcf.actual_ts_ar_p)}</td>
                                                                    <td id="td-right"><span>Actual TS (AR) (S)</span></td>
                                                                    <td>{renderShipmentQty(tcf.actual_ts_ar_s)}</td>
                                                                </tr>
                                                                <tr>

                                                                    <td id="td-right"><span>Actual Ash (ADB) (P)</span></td>
                                                                    <td>{renderShipmentQty(tcf.actual_ash_adb_p)}</td>
                                                                    <td id="td-right"><span>Actual Ash (ADB) (S)</span></td>
                                                                    <td>{renderShipmentQty(tcf.actual_ash_adb_s)}</td>

                                                                </tr>
                                                                <tr>

                                                                    <td id="td-right"><span>Actual TS (ADB) (P)</span></td>
                                                                    <td>{renderShipmentQty(tcf.actual_ts_adb_p)}</td>
                                                                    <td id="td-right"><span>Actual TS (ADB) (S)</span></td>
                                                                    <td>{renderShipmentQty(tcf.actual_ts_adb_s)}</td>
                                                                </tr>
                                                                <tr>

                                                                    <td id="td-right"><span>Actual AFT (P)</span></td>
                                                                    <td>{renderShipmentQty(tcf.actual_aft_p)}</td>
                                                                    <td id="td-right"><span>Actual AFT (S)</span></td>
                                                                    <td>{renderShipmentQty(tcf.actual_aft_s)}</td>
                                                                </tr>
                                                                <tr>

                                                                    <td id="td-right"><span>Actual VM (P)</span></td>
                                                                    <td>{renderShipmentQty(tcf.actual_vm_p)}</td>
                                                                    <td id="td-right"><span>Actual VM (S)</span></td>
                                                                    <td>{renderShipmentQty(tcf.actual_vm_s)}</td>
                                                                </tr>
                                                                <tr>

                                                                    <td id="td-right"><span>Actual HGI (P)</span></td>
                                                                    <td>{renderShipmentQty(tcf.actual_hgi_p)}</td>
                                                                    <td id="td-right"><span>Actual HGI (S)</span></td>
                                                                    <td>{renderShipmentQty(tcf.actual_hgi_s)}</td>
                                                                </tr>
                                                                <tr>

                                                                    <td id="td-right"><span>Price Basis GAR (P)</span></td>
                                                                    <td>{renderShipmentQty(tcf.price_basis_gar_p)}</td>
                                                                    <td id="td-right"><span>Price Basis GAR (S)</span></td>
                                                                    <td>{renderShipmentQty(tcf.price_basis_gar_s)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td id="td-right"><span>GAR (P)</span>
                                                                        <span id='tooltip' data-tooltip-id="button-tooltips28" data-tooltip-place='right'>
                                                                            <IoIosHelpCircle />
                                                                        </span>
                                                                        <ReactTooltip
                                                                            id="button-tooltips28"
                                                                            place="bottom"
                                                                            content="GAR (P) - Equivalent GAR (Purchase) - for Reporting Purpose"
                                                                        />  </td>
                                                                    <td>{renderShipmentQty(tcf.gar_p)}</td>
                                                                    <td id="td-right"><span>GAR (S)</span>
                                                                        <span id='tooltip' data-tooltip-id="button-tooltips29" data-tooltip-place='right'>
                                                                            <IoIosHelpCircle />
                                                                        </span>
                                                                        <ReactTooltip
                                                                            id="button-tooltips29"
                                                                            place="bottom"
                                                                            content="GAR (S) - Equivalent GAR (Sale) - for Reporting Purpose"
                                                                        />  </td>
                                                                    <td>{renderShipmentQty(tcf.gar_s)}</td>
                                                                </tr>

                                                            </tbody>
                                                        </table>

                                                        <div className="col-md-12" id="ax.account_details">
                                                            <h4 className="heading">Bonus / Penalty</h4>
                                                            <hr></hr>
                                                        </div>

                                                        <table class="table table-bordered account-table tables">
                                                            <tbody>
                                                                <tr>
                                                                    <td id="td-right"><span>Sulfur Bonus Rate (MT) (S)</span></td>
                                                                    <td>{renderShipmentQty(tcf.sulfur_bonus_rate_MT_S)}</td>
                                                                    <td id="td-right"><span>Ash Bonus Rate (MT) (S)</span></td>
                                                                    <td>{renderShipmentQty(tcf.ash_bonus_rate_MT_S)}</td>
                                                                </tr>

                                                                <tr>
                                                                    <td id="td-right"><span>Sulfur Bonus Amount (S)</span></td>
                                                                    <td>{renderShipmentQty(tcf.sulfur_bonus_amount_s)}</td>
                                                                    <td id="td-right"><span>Ash Bonus Amount (S)</span></td>
                                                                    <td>{renderShipmentQty(tcf.ash_bonus_amount_s)}</td>
                                                                </tr>

                                                                <tr>
                                                                    <td id="td-right"><span>Sulfur Penalty Rate (MT) (S)</span></td>
                                                                    <td>{renderShipmentQty(tcf.sulfur_penalty_rate_MT_S)}</td>
                                                                    <td id="td-right"><span>Ash Penalty Rate (MT) (S)</span></td>
                                                                    <td>{renderShipmentQty(tcf.ash_penalty_rate_MT_S)}</td>
                                                                </tr>

                                                                <tr>
                                                                    <td id="td-right"><span>Sulfur Penalty Amount (S)</span></td>
                                                                    <td>{renderShipmentQty(tcf.sulfur_penalty_amount_s)}</td>
                                                                    <td id="td-right"><span>Ash Penalty Amount (S)</span></td>
                                                                    <td>{renderShipmentQty(tcf.ash_penalty_amount_s)}</td>
                                                                </tr>

                                                            </tbody>
                                                        </table>



                                                        <div className="col-md-12" id="ax.account_details">
                                                            <h4 className="heading">Cost Sheet Actual</h4>
                                                            <hr></hr>
                                                        </div>

                                                        <table class="table table-bordered account-table tables">
                                                            <tbody>
                                                                <tr>
                                                                    <td id="td-right"><span>FOB Pur Price</span></td>
                                                                    <td>{renderShipmentQty(tcf.fob_pur_price)}</td>
                                                                    <td id="td-right"><span>FOB Sales Price</span></td>
                                                                    <td>{renderShipmentQty(tcf.fob_sales_price)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td id="td-right"><span>Actual Pur Price</span></td>
                                                                    <td>{renderShipmentQty(tcf.actual_pur_price)}</td>
                                                                    <td id="td-right"><span>Actual Sales Price</span></td>
                                                                    <td>{renderShipmentQty(tcf.actual_sales_price)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td id="td-right"><span>Actual Pur Amount</span></td>
                                                                    <td>{renderShipmentQty(tcf.actual_pur_amount)}</td>
                                                                    <td id="td-right"><span>Actual Sales</span></td>
                                                                    <td>{renderShipmentQty(tcf.actual_sales)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td id="td-right"><span>Actual FOB Purchase</span></td>
                                                                    <td>{renderShipmentQty(tcf.actual_fob_purchase)}</td>
                                                                    <td id="td-right"><span></span></td>
                                                                    <td></td>
                                                                </tr>
                                                                <tr class="space"><td id="td-right"><span></span></td><td></td><td id="td-right"><span></span></td><td></td></tr>
                                                                <tr>
                                                                    <td id="td-right"><span></span></td>
                                                                    <td></td>
                                                                    <td id="td-right"><span>Final Sales Price</span></td>
                                                                    <td>{renderShipmentQty(tcf.final_sales_price)}</td>
                                                                </tr>

                                                                <tr>
                                                                    <td id="td-right"><span>Final Purchase Price</span></td>
                                                                    <td>{renderShipmentQty(tcf.final_purchase_price)}</td>
                                                                    <td id="td-right"><span>Final Sales Amount</span></td>
                                                                    <td>{renderShipmentQty(tcf.final_sales_amount)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td id="td-right"><span>Final Purchase Amount</span></td>
                                                                    <td>{renderShipmentQty(tcf.final_purchase_amount)}</td>
                                                                    <td id="td-right"><span></span></td>
                                                                    <td></td>
                                                                </tr>
                                                                <tr class="space"><td id="td-right"><span></span></td><td></td><td id="td-right"><span></span></td><td></td></tr>
                                                                <tr>
                                                                    <td id="td-right"><span>Actual Freight Rate
                                                                    </span></td>
                                                                    <td>{renderShipmentQty(tcf.actual_freight_rate)}</td>
                                                                    <td id="td-right"><span>
                                                                    </span></td>
                                                                    <td></td>
                                                                </tr>
                                                                <tr>
                                                                    <td id="td-right"><span>Actual Freight Amount
                                                                    </span></td>
                                                                    <td>{renderShipmentQty(tcf.actual_freight_amount)}</td>
                                                                    <td id="td-right"><span>
                                                                    </span></td>
                                                                    <td></td>
                                                                </tr>
                                                                <tr>
                                                                    <td id="td-right"><span>Actual Insurance
                                                                    </span></td>
                                                                    <td>{renderShipmentQty(tcf.actual_insurance)}</td>
                                                                    <td id="td-right"><span>Actual Net Margin - Shipment</span></td>
                                                                    <td>{renderShipmentQty(tcf.actual_net_margin_shipment)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td id="td-right"><span>Actual Commission </span></td>
                                                                    <td>{renderShipmentQty(tcf.actual_commission)}</td>
                                                                    <td id="td-right"><span>Actual Net Margin - Ton</span></td>
                                                                    <td>{renderShipmentQty(tcf.actual_net_margin_ton)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td id="td-right"><span>Actual Gross Margin - Shipment</span></td>
                                                                    <td>{renderShipmentQty(tcf.actual_gross_margin_shipment)}</td>
                                                                    <td id="td-right"><span></span></td>
                                                                    <td></td>
                                                                </tr>
                                                                <tr>
                                                                    <td id="td-right"><span>Actual Gross Margin - Ton</span></td>
                                                                    <td>{renderShipmentQty(tcf.actual_gross_margin_ton)}</td>
                                                                    <td id="td-right"><span></span></td>
                                                                    <td></td>

                                                                </tr>

                                                            </tbody>
                                                        </table>

                                                        <div className="col-md-12" id="ax.account_details">
                                                            <h4 className="heading">Shipment Cost</h4>
                                                            <hr></hr>
                                                        </div>

                                                        <table class="table table-bordered account-table tables">
                                                            <tbody>
                                                                <tr>
                                                                    <td id="td-right"><span>Actual LC Transfer Cost</span></td>
                                                                    <td>{renderShipmentQty(tcf.actual_lc_transfer_cost)}</td>
                                                                    <td id="td-right"><span>Document Discount Date</span></td>
                                                                    <td>{tcf.document_discount_date}</td>
                                                                </tr>

                                                                <tr>
                                                                    <td id="td-right"><span>Actual Bill Handling Charges</span></td>
                                                                    <td>{renderShipmentQty(tcf.actual_bill_handling_charges)}</td>
                                                                    <td id="td-right"><span>Actual Payment Received</span></td>
                                                                    <td><input
                                                                        type='checkbox'
                                                                        name='actual_payment_received'
                                                                        checked={actualCheck} // Use the state variable to determine if the checkbox is checked
                                                                        onChange={handleActual} // Handle the change event
                                                                        className='mx-3' disabled={isButtonEnabled}
                                                                    /></td>
                                                                </tr>

                                                                <tr>
                                                                    <td id="td-right"><span>Actual Adding Confirmation Charges</span></td>
                                                                    <td>{renderShipmentQty(tcf.actual_adding_confirmation_charges)}</td>
                                                                    <td id="td-right"><span>Actual Payment Received Date</span></td>
                                                                    <td>{tcf.actual_payment_received_date}</td>
                                                                </tr>

                                                                <tr>
                                                                    <td id="td-right"><span>Actual Fixed Other Bank Cost</span></td>
                                                                    <td>{renderShipmentQty(tcf.actual_fixed_other_bank_charges)}</td>
                                                                    <td id="td-right"><span></span></td>
                                                                    <td></td>
                                                                </tr>
                                                                <tr>
                                                                    <td id="td-right"><span>Bank Interest/ Discounting</span></td>
                                                                    <td>{renderShipmentQty(tcf.bank_interest_or_discounting)}</td>
                                                                    <td id="td-right"><span>Actual Total Bank Cost</span></td>
                                                                    <td>{renderShipmentQty(tcf.actual_total_bank_cost)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td id="td-right"><span>VAT on Bank Charges</span></td>
                                                                    <td>{renderShipmentQty(tcf.vat_on_bank_charges)}</td>
                                                                    <td id="td-right"><span>Actual Costs</span></td>
                                                                    <td>{renderShipmentQty(tcf.actual_costs)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td id="td-right"><span>Actual Fixed Apportioned Overheads  </span></td>
                                                                    <td>{renderShipmentQty(tcf.actual_fixed_apportioned_overheads)}</td>
                                                                    <td id="td-right"><span>Actual Cost -Ton</span></td>
                                                                    <td>{renderShipmentQty(tcf.actual_cost_ton)}</td>
                                                                </tr>

                                                                <tr>
                                                                    <td id="td-right"><span>Actual Contract Specific Cost </span></td>
                                                                    <td>{renderShipmentQty(tcf.actual_contract_specific_cost)}</td>
                                                                    <td id="td-right"><span>Final Total Cost</span></td>
                                                                    <td>{renderShipmentQty(tcf.final_total_cost)}</td>
                                                                </tr>

                                                                <tr>
                                                                    <td id="td-right"><span>Actual Prov For Corporate Tax</span></td>
                                                                    <td>{renderShipmentQty(tcf.actual_prov_for_corporate_tax)}</td>
                                                                    <td id="td-right"><span>Actual Overheads</span></td>
                                                                    <td> {renderShipmentQty(tcf.actual_overheads)}</td>
                                                                </tr>

                                                                <tr>
                                                                    <td id="td-right"><span>Actual Withholding Tax</span></td>
                                                                    <td>{renderShipmentQty(tcf.actual_withholding_tax)}</td>
                                                                    <td id="td-right"><span></span></td>
                                                                    <td>{ }</td>
                                                                </tr>

                                                            </tbody>
                                                        </table>

                                                        <div className="col-md-12" id="ax.account_details">
                                                            <h4 className="heading">Debit Note Details</h4>
                                                            <hr></hr>
                                                        </div>

                                                        <table class="table table-bordered account-table tables">
                                                            <tbody>
                                                                <tr>
                                                                    <td id="td-right"><span>Supplier Dem/Despatch
                                                                    </span></td>
                                                                    <td>{tcf.supplier_dem_or_despatch}</td>
                                                                    <td id="td-right"><span>Buyer Dem/Despatch	</span></td>
                                                                    <td>{tcf.buyer_dem_or_despatch}</td>
                                                                </tr>

                                                                <tr>
                                                                    <td id="td-right"><span>Supplier Dem/Despatch DN Amount</span></td>
                                                                    <td>{renderShipmentQty(tcf.supplier_dem_or_despatch_DN_amount)}</td>
                                                                    <td id="td-right"><span>Buyer Dem/Despatch DN Amount</span></td>
                                                                    <td>
                                                                        {renderShipmentQty(tcf.buyer_dem_or_despatch_DN_amount)}</td>
                                                                </tr>

                                                                <tr>
                                                                    <td id="td-right"><span>supplier DN amount</span></td>
                                                                    <td>{renderShipmentQty(tcf.supplier_DN_amount)}</td>
                                                                    <td id="td-right"><span>buyer DN amount</span></td>
                                                                    <td>{renderShipmentQty(tcf.buyer_DN_amount)}</td>
                                                                </tr>

                                                            </tbody>
                                                        </table>

                                                        <div className="col-md-12" id="ax.account_details">
                                                            <h4 className="heading">Rwood Internal Information</h4>
                                                            <hr></hr>
                                                        </div>

                                                        <table class="table table-bordered account-table tables">
                                                            <tbody>
                                                                <tr>
                                                                    <td id="td-right"><span>any deviation
                                                                    </span></td>
                                                                    <td>{tcf.any_deviation}</td>
                                                                    <td id="td-right"><span>	</span></td>
                                                                    <td></td>
                                                                </tr>

                                                                <tr>
                                                                    <td id="td-right"><span>prepared by</span></td>
                                                                    <td>{tcf.prepared_by}</td>

                                                                </tr>

                                                                <tr>
                                                                    <td id="td-right"><span>Approved By	</span></td>
                                                                    <td>{tcf.check_and_approved_by}</td>
                                                                    <td id="td-right"><span></span></td>
                                                                    <td>{ }</td>
                                                                </tr>
                                                                <tr>
                                                                    <td id="td-right"><span>Check and Validated By</span></td>
                                                                    <td>{tcf.validated_by}</td>
                                                                    <td id="td-right"><span></span></td>
                                                                    <td>{ }</td>
                                                                </tr>
                                                                <tr>
                                                                    <td id="td-right"><span>remarks</span></td>
                                                                    <td>{tcf.remarks}</td>
                                                                    <td id="td-right"><span></span></td>
                                                                    <td>{ }</td>
                                                                </tr>

                                                            </tbody>
                                                        </table>

                                                        <Row id="table-styles">
                                                            <div className="col-md-12" id="head">
                                                                <h4 className="heading"><span>Rw Payable/ Receivable LineItem </span>
                                                                    <span>
                                                                        <Popup className='post-buyer'
                                                                            trigger={
                                                                                <a className="button add-accountrecord">
                                                                                    {" "}
                                                                                    New
                                                                                </a>
                                                                            }
                                                                            modal
                                                                        >
                                                                            <div className="form-group">
                                                                                <Form.Check
                                                                                    type='radio'
                                                                                    id=''
                                                                                    name='type'
                                                                                    label='Buyer - Credit Note'
                                                                                    value='Buyer - Credit Note'
                                                                                />
                                                                                <Form.Check
                                                                                    type='radio'
                                                                                    id=''
                                                                                    name='type'
                                                                                    label='Supplier - Credit Note'
                                                                                    value='Supplier - Credit Note'
                                                                                />
                                                                                <Form.Check
                                                                                    type='radio'
                                                                                    id=''
                                                                                    name='type'
                                                                                    label='Buyer - Debit Note'
                                                                                    value='Buyer - Debit Note'
                                                                                />
                                                                                <Form.Check
                                                                                    type='radio'
                                                                                    id=''
                                                                                    name='type'
                                                                                    label='Supplier - Debit Note'
                                                                                    value='Supplier - Debit Note'
                                                                                />
                                                                                <Form.Check
                                                                                    type='radio'
                                                                                    id=''
                                                                                    name='type'
                                                                                    label='Load Port Invoice'
                                                                                    value='Load Port Invoice'
                                                                                />
                                                                                <Form.Check
                                                                                    type='radio'
                                                                                    id=''
                                                                                    name='type'
                                                                                    label='Other Payable'
                                                                                    value='Other Payable'
                                                                                />
                                                                                <Form.Check
                                                                                    type='radio'
                                                                                    id=''
                                                                                    name='type'
                                                                                    label='Other Receivable'
                                                                                    value='Other Receivable'
                                                                                />
                                                                                <Form.Check
                                                                                    type='radio'
                                                                                    id=''
                                                                                    name='type'
                                                                                    label='Purchase Invoice'
                                                                                    value='Purchase Invoice'
                                                                                />
                                                                                <Form.Check
                                                                                    type='radio'
                                                                                    id=''
                                                                                    name='type'
                                                                                    label='Sales Invoice'
                                                                                    value='Sales Invoice'
                                                                                />
                                                                                <Form.Check
                                                                                    type='radio'
                                                                                    id=''
                                                                                    name='type'
                                                                                    label='Surveyor Invoice'
                                                                                    value='Surveyor Invoice'
                                                                                />

                                                                            </div>
                                                                            <input
                                                                                type="submit"
                                                                                className="btn btn-primary "
                                                                                value="Submit"
                                                                                onClick={handleClick}
                                                                            />

                                                                        </Popup>
                                                                    </span>

                                                                </h4>
                                                                <hr></hr>
                                                            </div>
                                                            <table id="rw" className="">
                                                                <thead>
                                                                    <tr>
                                                                        <th>RW Billing	</th>
                                                                        <th>Document Date	</th>
                                                                        <th>Record Type	</th>
                                                                        <th>Line Item	</th>
                                                                        <th>Account</th>
                                                                        <th>Order No & Vessel Name	</th>
                                                                        <th>Amount</th>
                                                                        <th>Paid / Received Amount	</th>

                                                                        <th>Balance Due	</th>
                                                                        <th>Status</th>                    </tr>
                                                                </thead>

                                                                {receivable.length > 0 ? (
                                                                    <tbody>
                                                                        {receivable.map((y, i) => (
                                                                            <tr>
                                                                                <td> <Link to={"/tcf/receivable-details/" + y.rw_pay_recieve_id}>
                                                                                    {y.rw_billing}
                                                                                </Link></td>
                                                                                <td>{y.document_date}</td>
                                                                                <td>{y.record_type}</td>
                                                                                <td>{y.line_item}</td>
                                                                                <td><Link to={`/accounts/${y.account_id}`}>{y.account}</Link></td>
                                                                                <td></td>
                                                                                <td>{renderShipmentQty(y.amount)}</td>
                                                                                <td>{renderShipmentQty(y.paid_recieved_amount)}</td>
                                                                                <td>{renderShipmentQty(y.balance_due)}</td>
                                                                                <td>{y.status}</td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                ) : (
                                                                    <tbody>
                                                                        <tr>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>

                                                                            <td>No data available</td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>                      </tr>
                                                                    </tbody>
                                                                )}
                                                            </table>
                                                        </Row>
                                                        <Row id="table-styles">
                                                            <div className="col-md-12" id="head">
                                                                <h4 className="heading"><span>Rw Payable/ Receipt LineItem </span><span>
                                                                    <Popup className='post-buyer'
                                                                        trigger={
                                                                            <a className="button add-accountrecord">
                                                                                {" "}
                                                                                New
                                                                            </a>
                                                                        }
                                                                        modal
                                                                    >
                                                                        <div className="form-group" >
                                                                            <Form.Check
                                                                                type='radio'
                                                                                id=''
                                                                                name='type'
                                                                                label='Buyer - Credit Note'
                                                                                value='Buyer - Credit Note'
                                                                            />
                                                                            <Form.Check
                                                                                type='radio'
                                                                                id=''
                                                                                name='type'
                                                                                label='Supplier - Credit Note'
                                                                                value='Supplier - Credit Note'
                                                                            />
                                                                            <Form.Check
                                                                                type='radio'
                                                                                id=''
                                                                                name='type'
                                                                                label='Buyer - Debit Note'
                                                                                value='Buyer - Debit Note'
                                                                            />
                                                                            <Form.Check
                                                                                type='radio'
                                                                                id=''
                                                                                name='type'
                                                                                label='Supplier - Debit Note'
                                                                                value='Supplier - Debit Note'
                                                                            />
                                                                            <Form.Check
                                                                                type='radio'
                                                                                id=''
                                                                                name='type'
                                                                                label='Load Port Invoice'
                                                                                value='Load Port Invoice'
                                                                            />
                                                                            <Form.Check
                                                                                type='radio'
                                                                                id=''
                                                                                name='type'
                                                                                label='Other Payable'
                                                                                value='Other Payable'
                                                                            />
                                                                            <Form.Check
                                                                                type='radio'
                                                                                id=''
                                                                                name='type'
                                                                                label='Other Receivable'
                                                                                value='Other Receivable'
                                                                            />
                                                                            <Form.Check
                                                                                type='radio'
                                                                                id=''
                                                                                name='type'
                                                                                label='Purchase Invoice'
                                                                                value='Purchase Invoice'
                                                                            />
                                                                            <Form.Check
                                                                                type='radio'
                                                                                id=''
                                                                                name='type'
                                                                                label='Sales Invoice'
                                                                                value='Sales Invoice'
                                                                            />
                                                                            <Form.Check
                                                                                type='radio'
                                                                                id=''
                                                                                name='type'
                                                                                label='Surveyor Invoice'
                                                                                value='Surveyor Invoice'
                                                                            />

                                                                        </div>
                                                                        <input
                                                                            type="submit"
                                                                            className="btn btn-primary "
                                                                            value="Submit"

                                                                        />

                                                                    </Popup>
                                                                </span>

                                                                </h4>
                                                                <hr></hr>
                                                            </div>
                                                            <table id="receipt" className="">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Rw payment receipt</th>
                                                                        <th>Payment/Receipt Date</th>
                                                                        <th>Record Type</th>
                                                                        <th>Line Item</th>
                                                                        <th>Vessel Name</th>
                                                                        <th>Amount</th>
                                                                        <th>Account</th>
                                                                        <th>RW Billing</th>
                                                                        <th>Order</th>
                                                                        <th>Description</th>
                                                                    </tr>
                                                                </thead>

                                                                {receipt.length > 0 ? (
                                                                    <tbody>
                                                                        {receipt.map((y, i) => (
                                                                            <tr>
                                                                                <td><Link to={'/tcf/tcf-receipt-details/' + y.rw_pay_receipt_detail_id}>{y.rw_payment_receipt}</Link></td>
                                                                                <td>{y.payment_receipt_date}</td>
                                                                                <td>{y.record_type}</td>
                                                                                <td>{y.line_item}</td>
                                                                                <td>{y.vessel_name}</td>
                                                                                <td>{renderShipmentQty(y.amount)}</td>
                                                                                <td><Link to={`/accounts/${y.account_id}`}>{y.account}</Link></td>
                                                                                <td> <Link to={"/tcf/receivable-details/" + y.rw_pay_recieve_id}>
                                                                                    {y.rw_billing}
                                                                                </Link></td>
                                                                                <td><Link to={`/order/view-order-details/${y.order_id}`}>{y.order}</Link></td>
                                                                                <td>{y.description}</td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                ) : (
                                                                    <tbody>
                                                                        <tr>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td><td></td>
                                                                            <td></td>

                                                                            <td>No data available</td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td></td>
                                                                        </tr>
                                                                    </tbody>
                                                                )}
                                                            </table>
                                                        </Row>
                                                        <Row id="table-styles">
                                                            <div className="col-md-12" id="head">
                                                                <h4 className="heading">
                                                                    <span>Notes & Attachments</span>
                                                                    <span>
                                                                        <i className=""></i> New Note </span>
                                                                    <span>
                                                                        <i className=""></i> Attach File </span>
                                                                </h4>
                                                                <hr>
                                                                </hr>
                                                            </div>
                                                            <table id="history" className="">
                                                                <tr>
                                                                    <td>
                                                                        <p class="no-records"> No records to display </p>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </Row>

                                                        <Row id="table-styles">
                                                            <div className="col-md-12" id="head">
                                                                <h4 className="heading">
                                                                    <span>Trade Confirmation Form History</span>
                                                                </h4>
                                                                <hr></hr>
                                                            </div>
                                                            <table id="history" className="">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Field Name</th>
                                                                        <th>New Value</th>
                                                                        <th>Old Value</th>
                                                                        <th>Changed By</th>
                                                                        <th>Change Time</th>
                                                                    </tr>
                                                                </thead>

                                                                {history.length > 0 ? (
                                                                    <tbody>
                                                                        {history.map((y, i) => (
                                                                            <tr>
                                                                                <td>{y.field_name}</td>
                                                                                <td>{y.new_value}</td>
                                                                                <td>{y.old_value}</td>
                                                                                <td>{y.changed_by}</td>
                                                                                <td>{y.change_time}</td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                ) : (
                                                                    <tbody>
                                                                        <tr>
                                                                            <td></td>
                                                                            <td></td>
                                                                            <td>No data available</td>
                                                                            <td></td>
                                                                            <td></td>
                                                                        </tr>
                                                                    </tbody>
                                                                )}
                                                            </table>
                                                        </Row>

                                                        {/*  */}

                                                    </div>
                                                </div>
                                                </div>
                                                </div>
                                        <Footer />
                                    </div>

                                )}
                            </>
                        ) : (
                            <>
                                {tcf.record_type === 'Supplier - Spot' ? (
                                    <>
                                        {isEditMode ? (
                                            <div id="view-page">
                                                <Header />
                                                <div className="contactAccount">
                                                    <div className="row">
                                                        <div className="col-md-2 recent-item">
                                                            <RecentItems />
                                                        </div>
                                                        <div className="col-md-10 right-section">

                                                            <div className="page-header" id='add-account'>
                                                                <div>
                                                                    <h2 className="main-content-title tx-24 mg-b-5">TCF Details</h2>
                                                                    <ol className="breadcrumb">
                                                                        <li className="breadcrumb-item active" aria-current="page">Dashboard / TCF Details</li>
                                                                    </ol>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className="tasks column-gap fulid-section order-section">
                                                            <div id="messageContainer"></div>
                                                            <p className="edit-btn">
                                                                <input
                                                                    type="submit"
                                                                    className="account-save"
                                                                    onClick={handleSaveEdit}
                                                                    value="Save"
                                                                //disabled={isSubmitted}
                                                                />
                                                                <button className="btn btn-primary" onClick={handleCancelEdit}>
                                                                    Cancel
                                                                </button>
                                                            </p>

                                                            <Row className="mb-3">
                                                                <h4 className="heading">Information</h4>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="TCF no"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control

                                                                            type="text"
                                                                            placeholder="TCF no"
                                                                            name="tcf_no"
                                                                            defaultValue={tcf.tcf_no || ""} onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <label>Locked</label>
                                                                    <input
                                                                        type='checkbox'
                                                                        name='locked'
                                                                        checked={lockedOne} // Use the state variable to determine if the checkbox is checked
                                                                        onChange={handleCheckboxLocked} // Handle the change event
                                                                        className='mx-3'
                                                                    />
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">

                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="TCF date"
                                                                        className="mb-3 datepick"


                                                                    >
                                                                        <DatePicker
                                                                            selected={tcfDate}
                                                                            onChange={handleTcfDate}
                                                                            selectsStart
                                                                            dateFormat='dd/MM/yyyy'
                                                                            placeholderText="Select"
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                {/* <Form.Group as={Col} md="6"></Form.Group> */}
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Record type"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control

                                                                            type="text"
                                                                            placeholder="Record type"
                                                                            name="record_type"
                                                                            defaultValue={tcf.record_type || ""} onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">

                                                                    <FloatingLabel controlId="floatingSelect"
                                                                        className='dropDown' label="Company">
                                                                        <Form.Select aria-label="Floating label select example"
                                                                            name="company"
                                                                            placeholder="Company"
                                                                            defaultValue={tcf.company || ""}
                                                                            onChange={handleChangeInput}
                                                                        >
                                                                            <option value='' >Select</option>
                                                                            {
                                                                                companies.map((x) => {
                                                                                    return (
                                                                                        <option value={x.company_name}>{x.company_name}</option>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </Form.Select>

                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Opportunity"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            defaultValue={tcf.opportunity || ""}
                                                                            onChange={handleChangeInput}
                                                                            type="text"
                                                                            placeholder="opportunity"
                                                                            name="opportunity"
                                                                            disabled
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">

                                                                    <FloatingLabel controlId="floatingSelect"
                                                                        className='dropDown' label="Currency">
                                                                        <Form.Select aria-label="Floating label select example"
                                                                            name="currency"
                                                                            placeholder="Currency"
                                                                            defaultValue={tcf.currency || ""}
                                                                            onChange={handleChangeInput}
                                                                        >
                                                                            <option value='' >Select</option>
                                                                            <option value='USD' >USD</option>
                                                                            <option value='IDR' >IDR</option>

                                                                        </Form.Select>

                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="quote"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder="quote"
                                                                            name="quote"
                                                                            defaultValue={tcf.quote || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Exchange rate"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder="Exchange rate"
                                                                            name="exchange_rate"
                                                                            defaultValue={tcf.exchange_rate || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">

                                                                    <FloatingLabel controlId="floatingSelect"
                                                                        className='dropDown' label="Trader Name">
                                                                        <Form.Select aria-label="Floating label select example"
                                                                            name="trader_name"
                                                                            placeholder="Trader Name"
                                                                            defaultValue={tcf.trader_name || ""}
                                                                            onChange={handleChangeInput}

                                                                        >
                                                                            <option value='' >Select</option>
                                                                            {
                                                                                rwoodusers.map((x) => {
                                                                                    return (
                                                                                        <option value={x.rwood_username}>{x.rwood_username}</option>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </Form.Select>

                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="order"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder="order"
                                                                            name="order"
                                                                            defaultValue={tcf.order || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="owner"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder="owner"
                                                                            name="owner"
                                                                            defaultValue={tcf.owner || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel controlId="floatingSelect" label="Product Master" className="select-dropdown">
                                                                        <Select
                                                                            className="select"
                                                                            value={productMaster}
                                                                            onChange={setProductMaster}
                                                                            options={options_product}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <label>Auto select pricebook</label>
                                                                    <input
                                                                        type='checkbox'
                                                                        name='auto_select_pricebook'
                                                                        checked={lockeds} // Use the state variable to determine if the checkbox is checked
                                                                        onChange={handleCheckboxChange} // Handle the change event
                                                                        className='mx-3'
                                                                    />
                                                                </Form.Group>

                                                                {/* <Form.Group as={Col} md="6">
  <FloatingLabel controlId="floatingSelect" className="dropDown" label="Rwood bank master">
    <Form.Select
      aria-label="Floating label select example"
      name="rwood_bank_master"
      placeholder="bank master master"
      defaultValue={tcf.rwood_bank_master || ""}
      onChange={handleChangeInput}
    >
      <option value="">Select</option>
      {
        // Sort the datas array alphabetically by rwood_bank_name
        datas
          .sort((a, b) => a.rwood_bank_name.localeCompare(b.rwood_bank_name))
          .map((x) => (
            <option key={x.rwood_bank_name} value={x.rwood_bank_name}>
              {x.rwood_bank_name}
            </option>
          ))
      }
    </Form.Select>
  </FloatingLabel>
</Form.Group> */}
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel controlId="floatingSelect" className="dropDown" label="Rwood bank master">
                                                                        <Form.Select
                                                                            aria-label="Floating label select example"
                                                                            name="rwood_bank_master"
                                                                            placeholder="bank master"
                                                                            defaultValue={tcf.rwood_bank_master || ""}
                                                                            onChange={handleChangeInput}
                                                                        >
                                                                            <option value="">Select</option>
                                                                            {
                                                                                // Ensure datas is an array and sort it alphabetically by rwood_bank_name
                                                                                (Array.isArray(datas) ? datas : [])
                                                                                    .sort((a, b) => a.rwood_bank_name.localeCompare(b.rwood_bank_name))
                                                                                    .map((x) => (
                                                                                        <option key={x.rwood_bank_name} value={x.rwood_bank_name}>
                                                                                            {x.rwood_bank_name}
                                                                                        </option>
                                                                                    ))
                                                                            }
                                                                        </Form.Select>
                                                                    </FloatingLabel>
                                                                </Form.Group>

                                                                <Form.Group as={Col} md="6">

                                                                    <FloatingLabel controlId="floatingSelect"
                                                                        className='dropDown' label="Price book">
                                                                        <Form.Select aria-label="Floating label select example"
                                                                            name="price_book"
                                                                            placeholder="Price book"
                                                                            defaultValue={tcf.price_book || ""}
                                                                            onChange={handleChangeInput}

                                                                        >
                                                                            <option value='' >Select</option>
                                                                            {
                                                                                priceBook.map((x) => {
                                                                                    return (
                                                                                        <option value={x.price_book_name}>{x.price_book_name}</option>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </Form.Select>

                                                                    </FloatingLabel>
                                                                </Form.Group>

                                                                <Form.Group as={Col} md="6">

                                                                    <FloatingLabel controlId="floatingSelect"
                                                                        className='dropDown' label="TCF Status">
                                                                        <Form.Select aria-label="Floating label select example"
                                                                            name="tcf_status"
                                                                            placeholder="TCF Status"
                                                                            defaultValue={tcf.tcf_status || ""}
                                                                            onChange={handleChangeInput}
                                                                        >
                                                                            <option value="Draft">Draft</option><option value="Proposed">Proposed</option><option value="Proposed-Long Term Contract">Proposed-Long Term Contract</option><option value="Projected">Projected</option><option value="Actual Shipment Completed">Shipment Done</option><option value="Completed">Completed</option><option value="Cancelled">Z Cancelled</option><option value="Draft - Long Term">Draft - Long Term</option><option value="Shipments - Arutmin">Shipments - Arutmin</option><option value="Cancelled with Penalty">Cancelled with Penalty</option>
                                                                        </Form.Select>

                                                                    </FloatingLabel>
                                                                </Form.Group>

                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Purchase confirmation date"
                                                                        className="mb-3 datepick"


                                                                    >

                                                                        <DatePicker
                                                                            selected={purchaseConfirmDate}
                                                                            onChange={handlePuchaseConfirm}
                                                                            selectsStart
                                                                            dateFormat='dd/MM/yyyy'
                                                                            placeholderText="Select"
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Buyer contract"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            disabled
                                                                            type="text"
                                                                            placeholder="Buyer contract"
                                                                            name="buyer_contract"
                                                                            defaultValue={tcf.buyer_contract || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6"></Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Contract shipment"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="text"
                                                                            disabled
                                                                            placeholder="Contract shipment"
                                                                            name="contract_shipment"
                                                                            defaultValue={tcf.contract_shipment || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Product master"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder="product_master"
                                                                            name="Product master"
                                                                            defaultValue={tcf.product_master || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">

                                                                    <FloatingLabel controlId="floatingSelect"
                                                                        className='dropDown' label="Product">
                                                                        <Form.Select aria-label="Floating label select example"
                                                                            name="product"
                                                                            placeholder="Product"
                                                                            defaultValue={tcf.product}
                                                                            onChange={handleChangeInput}
                                                                        >
                                                                            <option value='' >Select</option>
                                                                            {
                                                                                productName.map((x) => {
                                                                                    return (
                                                                                        <option value={x.product_name}>{x.product_name}</option>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </Form.Select>
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Product name"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder="Product name"
                                                                            name="product_name"
                                                                            defaultValue={tcf.product_name || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                            </Row>
                                                            {/* <Row className="mb-3">
               <h4 className="heading">Shipment Summary</h4>
               <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="shipment gross margin ton"
                className="mb-3"


              >
                <Form.Control
                  type="text"
                  placeholder="shipment gross margin ton"
                  name="shipment_gross_margin_ton"
                  defaultValue={tcf.shipment_gross_margin_ton || ""}
         onChange={handleChangeInput}
         disabled
                  />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="shipment sales price"
                className="mb-3"


              >
                <Form.Control
                  type="text"
                  placeholder="shipment sales price"
                  name="shipment_sales_price"
                  defaultValue={tcf.shipment_sales_price || ""}
         onChange={handleChangeInput}
         disabled
                  />
              </FloatingLabel>
            </Form.Group>

               </Row> */}

                                                            <Row>
                                                                <h4 className='heading'>Trade Confirmation Details</h4>
                                                                <Form.Group as={Col} md="6">

                                                                    <FloatingLabel controlId="floatingSelect"
                                                                        className='dropDown' label="Buyer">
                                                                        <Form.Select aria-label="Floating label select example"
                                                                            name="buyer"
                                                                            placeholder="Buyer"
                                                                            defaultValue={tcf.buyer || ""}
                                                                            onChange={handleChangeInput}
                                                                        >
                                                                            <option value='' >Select</option>
                                                                            {
                                                                                buyers.map((x) => {
                                                                                    return (
                                                                                        <option value={x.account_name}>{x.account_name}</option>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </Form.Select>
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">

                                                                    <FloatingLabel controlId="floatingSelect"
                                                                        className='dropDown' label="status of purchase contract" >
                                                                        <Form.Select aria-label="Floating label select example"
                                                                            name="status_of_purchase_contract"
                                                                            placeholder="status of purchase contract"
                                                                            defaultValue={tcf.status_of_purchase_contract || ""}
                                                                            onChange={handleChangeInput}
                                                                        >
                                                                            <option value="">--None--</option><option value="Draft">Draft</option><option value="Activated">Activated</option><option value="In Approval Process">In Approval Process</option><option value="Under Review">Under Review</option><option value="Signed Contract yet to receive">Signed Contract yet to receive</option><option value="Signed Contract Sent for Sign">Signed Contract Sent for Sign</option><option value="Draft Sent for Signing">Draft Sent for Signing</option><option value="Draft Received for Signing">Draft Received for Signing</option><option value="Signed Original Contract Need to Courier">Signed Original Contract Need to Courier</option><option value="Signed Original Contract yet to Receive">Signed Original Contract yet to Receive</option><option value="Original Contract Signed by Both Parties">Original Contract Signed by Both Parties</option><option value="Original Contract Signed and Received by Both Parties">Original Contract Signed and Received by Both Parties</option>
                                                                        </Form.Select>
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">

                                                                    <FloatingLabel controlId="floatingSelect"
                                                                        className='dropDown' label="Supplier">
                                                                        <Form.Select aria-label="Floating label select example"
                                                                            name="supplier"
                                                                            placeholder="Supplier"
                                                                            defaultValue={tcf.supplier || ""}
                                                                            onChange={handleChangeInput}

                                                                        >
                                                                            <option value='' >Select</option>
                                                                            {
                                                                                suppliers.map((x) => {
                                                                                    return (
                                                                                        <option value={x.account_name}>{x.account_name}</option>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </Form.Select>
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Product Description"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            as="textarea"
                                                                            placeholder="Product Description"
                                                                            name="product_description"
                                                                            defaultValue={tcf.product_description || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Proposed laycan from"
                                                                        className="mb-3 datepick"


                                                                    >
                                                                        <DatePicker
                                                                            selected={proposedDates}
                                                                            onChange={handleProposeDate}
                                                                            selectsStart
                                                                            dateFormat='dd/MM/yyyy'
                                                                            placeholderText="Select"
                                                                            disabled={true}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Proposed laycan to"
                                                                        className="mb-3 datepick"


                                                                    >
                                                                        <DatePicker
                                                                            selected={proposedDates1}
                                                                            onChange={handleProposeDate1}
                                                                            selectsStart
                                                                            dateFormat='dd/MM/yyyy'
                                                                            placeholderText="Select"
                                                                            disabled={true}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Quantity MT"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder="Quantity MT"
                                                                            name="quantity_MT"
                                                                            defaultValue={tcf.quantity_MT || ""}
                                                                            onChange={handleChangeInput}

                                                                        />

                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingSelect"
                                                                        className="dropDown"
                                                                        label="price basis"
                                                                    >
                                                                        <Form.Select
                                                                            aria-label="Floating label select example"
                                                                            name="price_basis"
                                                                            placeholder="price basis"
                                                                            defaultValue={tcf.price_basis || ""}
                                                                            onChange={handleChangeInput}

                                                                        >
                                                                            <option value="">--None--</option><option value="GCV (ARB)">GCV (ARB)</option><option value="GCV (ADB)">GCV (ADB)</option><option value="NCV (ARB)">NCV (ARB)</option></Form.Select>

                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingSelect"
                                                                        className="dropDown"
                                                                        label="Vessel Type"
                                                                    >
                                                                        <Form.Select
                                                                            aria-label="Floating label select example"
                                                                            name="vessel_type"
                                                                            defaultValue={tcf.vessel_type || ""}
                                                                            onChange={handleChangeInput}
                                                                            placeholder="Vessel Type"
                                                                        >
                                                                            <option value="">--None--</option><option value="Geared (GnG)">Geared (GnG)</option><option value="Gearless">Gearless</option><option value="Geared">Geared</option><option value="Barge">Barge</option>
                                                                        </Form.Select>

                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Payment term"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder="Payment term"
                                                                            name="payment_term"
                                                                            defaultValue={tcf.payment_term || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>

                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Selling terms"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder="Selling terms"
                                                                            name="selling_terms"
                                                                            defaultValue={tcf.selling_terms || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>

                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Buying terms"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder="Buying terms"
                                                                            defaultValue={tcf.buying_terms || ""}
                                                                            onChange={handleChangeInput}
                                                                            name="buying_terms"
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>

                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Price adjustment"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder="Price adjustment"
                                                                            name="price_adjustment"
                                                                            defaultValue={tcf.price_adjustment || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                            </Row>
                                                            <Row>
                                                                <h4 className='heading'>Contractual</h4>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Contractual GAR S"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder="Contractual GAR S"
                                                                            name="contractual_GAR_S"
                                                                            defaultValue={tcf.contractual_GAR_S || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Contractual GAR P"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder="Contractual GAR P"
                                                                            name="contractual_GAR_P"
                                                                            defaultValue={tcf.contractual_GAR_P || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Contractual TM AR P"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder="Contractual TM AR P"
                                                                            name="contractual_TM_AR_P"
                                                                            defaultValue={tcf.contractual_TM_AR_P || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Contractual TM AR S"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder="Contractual TM AR S"
                                                                            name="contractual_TM_AR_S"
                                                                            defaultValue={tcf.contractual_TM_AR_S || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Contractual Ash AR P"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder="Contractual Ash AR P"
                                                                            name="contractual_Ash_AR_P"
                                                                            defaultValue={tcf.contractual_Ash_AR_P || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>

                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Contractual Ash AR S"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder="Contractual Ash AR S"
                                                                            name="contractual_Ash_AR_S"
                                                                            defaultValue={tcf.contractual_Ash_AR_S || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Contractual Ts ADB P"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder="Contractual Ts ADB P"
                                                                            name="contractual_Ts_ADB_P"
                                                                            defaultValue={tcf.contractual_Ts_ADB_P || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Contractual Ts ADB S"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder="Contractual Ts ADB S"
                                                                            name="contractual_Ts_ADB_S"
                                                                            defaultValue={tcf.contractual_Ts_ADB_S || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Contractual Ash ADB P"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder="Contractual Ash ADB P"
                                                                            name="contractual_Ash_ADB_P"
                                                                            defaultValue={tcf.contractual_Ash_ADB_P || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Contractual Ash ADB S"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder="Contractual Ash ADB S"
                                                                            name="contractual_Ash_ADB_S"
                                                                            defaultValue={tcf.contractual_Ash_ADB_S || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Projected price basis GAR P"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder="Projected price basis GAR P"
                                                                            name="projected_price_basis_GAR_P"
                                                                            defaultValue={tcf.projected_price_basis_GAR_P || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Projected price basis GAR S"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder="Projected price basis GAR S"
                                                                            name="projected_price_basis_GAR_S"
                                                                            defaultValue={tcf.projected_price_basis_GAR_S || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">

                                                                    <FloatingLabel controlId="floatingSelect"
                                                                        className='dropDown' label="contractual HBA year">
                                                                        <Form.Select aria-label="Floating label select example"
                                                                            name="contractual_HBA_year"
                                                                            placeholder="contractual HBA year"
                                                                            defaultValue={tcf.contractual_HBA_year}
                                                                            onChange={handleChangeInput}
                                                                        >
                                                                            <option value="">--None--</option>
                                                                            <option value="2024">2024</option>
                                                                            <option value="2025">2025</option>
                                                                            <option value="2026">2026</option>
                                                                            <option value="2027">2027</option>
                                                                            <option value="2023">2023</option>
                                                                            <option value="2022">2022</option>
                                                                            <option value="2021">2021</option>

                                                                        </Form.Select>
                                                                    </FloatingLabel>
                                                                </Form.Group>



                                                                <Form.Group as={Col} md="6">

                                                                    <FloatingLabel controlId="floatingSelect"
                                                                        className='dropDown' label="contractual HBA Month">
                                                                        <Form.Select aria-label="Floating label select example"
                                                                            name="contractual_HBA_month"
                                                                            placeholder="contractual HBA Month"
                                                                            defaultValue={tcf.contractual_HBA_month}
                                                                            onChange={handleChangeInput}
                                                                        >
                                                                            <option value="">--None--</option><option value="January">January</option><option value="February">February</option><option value="March">March</option><option value="April">April</option><option value="May">May</option><option value="June">June</option><option value="July">July</option><option value="August">August</option><option value="September">September</option><option value="October">October</option><option value="November">November</option><option value="December">December</option>
                                                                        </Form.Select>
                                                                    </FloatingLabel>
                                                                </Form.Group>

                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Discounted price"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder="Discounted price"
                                                                            name="discounted_price"
                                                                            defaultValue={tcf.discounted_price || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Projected index value"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder="Projected index value"
                                                                            name="projected_index_value"
                                                                            defaultValue={tcf.projected_index_value || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">

                                                                    <FloatingLabel controlId="floatingSelect"
                                                                        className='dropDown' label="Projected Purchase Type">
                                                                        <Form.Select aria-label="Floating label select example"
                                                                            name="projected_purchase_type"
                                                                            placeholder="Projected Purchase Type"
                                                                            defaultValue={tcf.projected_purchase_type || ""}
                                                                            onChange={handleChangeInput}

                                                                        >
                                                                            <option value='' >Select</option>
                                                                            {
                                                                                price.map((x) => {
                                                                                    return (
                                                                                        <option value={x.price_type_name}>{x.price_type_name}</option>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </Form.Select>
                                                                    </FloatingLabel>
                                                                </Form.Group>

                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Projected fixed purchase price"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder="Projected fixed purchase price"
                                                                            name="projected_fixed_purchase_price"
                                                                            defaultValue={tcf.projected_fixed_purchase_price || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Projected purchase price margin"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="number"
                                                                            placeholder="Projected purchase price margin"
                                                                            name="projected_purchase_price_margin"
                                                                            defaultValue={tcf.projected_purchase_price_margin || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Projected barge discount"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder="Projected barge discount"
                                                                            name="projected_barge_discount"
                                                                            defaultValue={tcf.projected_barge_discount || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">

                                                                    <FloatingLabel controlId="floatingSelect"
                                                                        className='dropDown' label="Projected Sale Price Type">
                                                                        <Form.Select aria-label="Floating label select example"
                                                                            name="projected_sale_price_type"
                                                                            placeholder="Projected Sale Price Type"
                                                                            defaultValue={tcf.projected_sale_price_type || ""}
                                                                            onChange={handleChangeInput}

                                                                        >
                                                                            <option value='' >Select</option>
                                                                            {
                                                                                price.map((x) => {
                                                                                    return (
                                                                                        <option value={x.price_type_name}>{x.price_type_name}</option>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </Form.Select>
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Projected fixed sales price"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder="Projected fixed sales price"
                                                                            name="projected_fixed_sales_price"
                                                                            defaultValue={tcf.projected_fixed_sales_price || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Projected sales price margin"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder="Projected sales price margin"
                                                                            name="projected_sales_price_margin"
                                                                            defaultValue={tcf.projected_sales_price_margin || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                            </Row>
                                                            <Row>
                                                                <h4 className='heading'>Cost Sheet Projection</h4>
                                                                {/* <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Projected Pur Amount"
                className="mb-3"


              >
                <Form.Control
                  type="text"
                  placeholder="Projected Pur Amount"
                  name="projected_pur_amount"
                  defaultValue={tcf.projected_pur_amount || ""} disabled
                  onChange={handleChangeInput}
                 />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="projected sales"
                className="mb-3"


              >
                <Form.Control
                  type="text"
                  placeholder="projected sales"
                  name="projected_sales"
                  defaultValue={tcf.projected_sales || ""} disabled
                  onChange={handleChangeInput}
                 />
              </FloatingLabel>
            </Form.Group>  */}
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="projected fob purchase"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder="projected fob purchase"
                                                                            name="projected_fob_purchase"
                                                                            defaultValue={tcf.projected_fob_purchase || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="projected gross margin shipment"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder="projected gross margin shipment"
                                                                            name="projected_gross_margin_shipment"
                                                                            defaultValue={tcf.projected_gross_margin_shipment || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Projected Freight Rate	"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder="Projected Freight Rate	"
                                                                            name="projected_freight_rate"
                                                                            defaultValue={tcf.projected_freight_rate || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Projected Gross Margin TON"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder="Projected Gross Margin TON"
                                                                            name="projected_gross_margin_ton"
                                                                            defaultValue={tcf.projected_gross_margin_ton || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Projected Freight Amount	"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder="Projected Freight Amount	"
                                                                            name="projected_freight_amount"
                                                                            defaultValue={tcf.projected_freight_amount || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Projected Insurance"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder="Projected Insurance"
                                                                            name="projected_insurance"
                                                                            defaultValue={tcf.projected_insurance || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Projected Net Margin TON	"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder="Projected Net Margin TON	"
                                                                            name="projected_net_margin_ton"
                                                                            defaultValue={tcf.projected_net_margin_ton || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Projected Commission"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder="Projected Commission"
                                                                            name="projected_commission"
                                                                            defaultValue={tcf.projected_commission || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Projected LC Negotiation Cost"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder="Projected LC Negotiation Cost"
                                                                            name="projected_lc_negotiation_cost"
                                                                            defaultValue={tcf.projected_lc_negotiation_cost || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Projected Total Bank Cost"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder="Projected Total Bank Cost"
                                                                            name="projected_total_bank_cost"
                                                                            defaultValue={tcf.projected_total_bank_cost || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>


                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Projected Cost	"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder="Projected Cost"
                                                                            name="projected_cost"
                                                                            defaultValue={tcf.projected_cost || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>  <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Projected Fixed Other Bank Costs"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder="Projected Fixed Other Bank Costs"
                                                                            name="projected_fixed_other_bank_costs"
                                                                            defaultValue={tcf.projected_fixed_other_bank_costs || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>  <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Projected Cost TON"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder="Projected Cost TON"
                                                                            name="projected_cost_ton"
                                                                            defaultValue={tcf.projected_cost_ton || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>  <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Projected Fixed Apportioned Overheads	"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder="Projected Fixed Apportioned Overheads"
                                                                            name="projected_fixed_apportioned_overheads"
                                                                            defaultValue={tcf.projected_fixed_apportioned_overheads || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>  <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Projected Total Cost"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder="Projected Total Cost"
                                                                            name="projected_total_cost"
                                                                            defaultValue={tcf.projected_total_cost || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>  <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Projected Any Contract Specific Cost	"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder="Projected Any Contract Specific Cost"
                                                                            name="projected_any_contract_specific_cost"
                                                                            defaultValue={tcf.projected_any_contract_specific_cost || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>  <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Projected Extra Cost"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder="Projected Extra Cost"
                                                                            name="projected_extra_cost"
                                                                            defaultValue={tcf.projected_extra_cost || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>  <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Projected Provision For Corporate Tax"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder="Projected Provision For Corporate Tax"
                                                                            name="projected_provision_for_corporate_tax"
                                                                            defaultValue={tcf.projected_provision_for_corporate_tax || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>  <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Projected Withholding Tax"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder="Projected Withholding Tax"
                                                                            name="projected_withholding_tax"
                                                                            defaultValue={tcf.projected_withholding_tax || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                            </Row>
                                                            {/* <Row className="mb-3">
               <h4 className="heading">Actual Shipment Details</h4>
               <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Vessel Name"
                className="mb-3"


              >
                <Form.Control
                  type="text"
                  placeholder="Vessel Name"
                  name="vessel_name"
                  defaultValue={tcf.vessel_name || ""}
                  onChange={handleChangeInput}
                  disabled
                 />
              </FloatingLabel>
            </Form.Group>
               <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Demmurage Rate (P)"
                className="mb-3"


              >
                <Form.Control
                  type="text"
                  placeholder="Demmurage Rate (P)"
                  name="demmurage_rate_p"
                  defaultValue={tcf.demmurage_rate_p || ""}
                  onChange={handleChangeInput}
                  disabled
                 />
              </FloatingLabel>
            </Form.Group>
               <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Stowage Quantity"
                className="mb-3"


              >
                <Form.Control
                  type="text"
                  placeholder="Stowage Quantity"
                  name="stowage_qty"
                  defaultValue={tcf.stowage_qty || ""}
                  onChange={handleChangeInput}
                  disabled
                 />
              </FloatingLabel>
            </Form.Group>
               <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Demmurage Rate (S)"
                className="mb-3"


              >
                <Form.Control
                  type="text"
                  placeholder="Demmurage Rate (S)"
                  name="demmurage_rate_s"
                  defaultValue={tcf.demmurage_rate_s || ""}
                  onChange={handleChangeInput}
                  disabled
                 />
              </FloatingLabel>
            </Form.Group>
               <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Destination"
                className="mb-3"


              >
                <Form.Control
                  type="text"
                  placeholder="Destination"
                  name="destination"
                  defaultValue={tcf.destination || ""}
                  onChange={handleChangeInput}
                  disabled
                 />
              </FloatingLabel>
            </Form.Group>
               <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Load Rate"
                className="mb-3"


              >
                <Form.Control
                  type="text"
                  placeholder="Load Rate"
                  name="load_rate"
                  defaultValue={tcf.load_rate || ""}
                  onChange={handleChangeInput}
                  disabled
                 />
              </FloatingLabel>
            </Form.Group>
               <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Actual BL Date"
                className="mb-3"


              >
                <Form.Control
                  type="text"
                  placeholder="Actual BL Date"
                  name="actual_bl_date"
                  defaultValue={tcf.actual_bl_date || ""}
                  onChange={handleChangeInput}
                  disabled
                 />
              </FloatingLabel>
            </Form.Group>
               <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="PActual BL Qty"
                className="mb-3"


              >
                <Form.Control
                  type="text"
                  placeholder="Actual BL Qty"
                  name="actual_bl_qty"
                  defaultValue={tcf.actual_bl_qty || ""}
                  onChange={handleChangeInput}
                  disabled
                 />
              </FloatingLabel>
            </Form.Group>
               <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="GCNEWC"
                className="mb-3"


              >
                <Form.Control
                  type="text"
                  placeholder="GCNEWC"
                  name="gcnewc"
                  defaultValue={tcf.gcnewc || ""}
                  onChange={handleChangeInput}
                  disabled
                 />
              </FloatingLabel>
            </Form.Group>
               <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Actual HBA"
                className="mb-3"


              >
                <Form.Control
                  type="text"
                  placeholder="Actual HBA"
                  name="actual_hba"
                  defaultValue={tcf.actual_hba || ""}
                  onChange={handleChangeInput}
                  disabled
                 />
              </FloatingLabel>
            </Form.Group>
               <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Actual GCNEWC - Sales"
                className="mb-3"


              >
                <Form.Control
                  type="text"
                  placeholder="Actual GCNEWC - Sales"
                  name="actual_gcnewc_sales"
                  defaultValue={tcf.actual_gcnewc_sales || ""}
                  onChange={handleChangeInput}
                  disabled
                 />
              </FloatingLabel>
            </Form.Group>
               <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Actual HBA - Purchase"
                className="mb-3"


              >
                <Form.Control
                  type="text"
                  placeholder="Actual HBA - Purchase"
                  name="actual_hba_purchase"
                  defaultValue={tcf.actual_hba_purchase || ""}
                  onChange={handleChangeInput}
                  disabled
                 />
              </FloatingLabel>
            </Form.Group>
               <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Laycan"
                className="mb-3"


              >
                <Form.Control
                  type="text"
                  placeholder="Laycan"
                  name="laycan"
                  defaultValue={tcf.laycan || ""}
                  onChange={handleChangeInput}
                  disabled
                 />
              </FloatingLabel>
            </Form.Group>
               <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Expected Value Date"
                className="mb-3"


              >
                <Form.Control
                  type="text"
                  placeholder="Expected Value Date"
                  name="expected_value_date"
                  defaultV
                  alue={tcf.expected_value_date || ""}
                  onChange={handleChangeInput}
                  disabled
                 />
              </FloatingLabel>
            </Form.Group>
               </Row> */}
                                                            <Row>
                                                                <h4 className='heading'>Actual Cost Elements</h4>

                                                                <Form.Group as={Col} md="6">

                                                                    <FloatingLabel controlId="floatingSelect"
                                                                        className='dropDown' label="Purchase Price Type">
                                                                        <Form.Select aria-label="Floating label select example"
                                                                            name="purchase_price_type"
                                                                            placeholder="Purchase Price Type"
                                                                            defaultValue={tcf.purchase_price_type || ""}
                                                                            onChange={handleChangeInput}
                                                                        >
                                                                            <option value='' >Select</option>
                                                                            {
                                                                                price.map((x) => {
                                                                                    return (
                                                                                        <option value={x.price_type_name}>{x.price_type_name}</option>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </Form.Select>

                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <label>Actual Data Rcvd	</label>
                                                                    <input
                                                                        type='checkbox'
                                                                        name='actual_data_rcvd'
                                                                        checked={dataReceived} // Use th e state variable to determine if the checkbox is checked
                                                                        onChange={handleData}// Handle the change event
                                                                        className='mx-3'
                                                                    />
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Fixed purchase price"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder="Fixed purchase price"
                                                                            name="fixed_purchase_price"
                                                                            defaultValue={tcf.fixed_purchase_price || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Purchase price margin"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder="Purchase price margin"
                                                                            name="purchase_price_margin"
                                                                            defaultValue={tcf.purchase_price_margin || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Barge discount"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder="Barge discount"
                                                                            name="barge_discount"
                                                                            defaultValue={tcf.barge_discount || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Projected Pur Amount"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder="Projected Pur Amount"
                                                                            name="projected_pur_amount"
                                                                            defaultValue={tcf.projected_pur_amount || ""} disabled
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>

                                                                <Form.Group as={Col} md="6">

                                                                    <FloatingLabel controlId="floatingSelect"
                                                                        className='dropDown' label="Sale Price Type">
                                                                        <Form.Select aria-label="Floating label select example"
                                                                            name="sale_price_type"
                                                                            placeholder="Sale Price Type"
                                                                            defaultValue={tcf.sale_price_type || ""}
                                                                            onChange={handleChangeInput}

                                                                        >
                                                                            <option value='' >Select</option>
                                                                            {
                                                                                price.map((x) => {
                                                                                    return (
                                                                                        <option value={x.price_type_name}>{x.price_type_name}</option>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </Form.Select>

                                                                    </FloatingLabel>
                                                                </Form.Group>

                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Fixed sale price"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder="Fixed sale price"
                                                                            name="fixed_sale_price"
                                                                            defaultValue={tcf.fixed_sale_price || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Sale price margin"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="number"
                                                                            placeholder="Sale price margin"
                                                                            name="sale_price_margin"
                                                                            defaultValue={tcf.sale_price_margin || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Actual gar p"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="number"
                                                                            placeholder="Actual gar p"
                                                                            name="actual_gar_p"
                                                                            defaultValue={tcf.actual_gar_p || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Actual tm p"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="number"
                                                                            placeholder="Actual tm p"
                                                                            name="actual_tm_p"
                                                                            defaultValue={tcf.actual_tm_p || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Actual ash ar p"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="number"
                                                                            placeholder="Actual ash ar p"
                                                                            name="actual_ash_ar_p"
                                                                            defaultValue={tcf.actual_ash_ar_p || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Actual ts ar p"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="number"
                                                                            placeholder="Actual ts ar p"
                                                                            name="actual_ts_ar_p"
                                                                            defaultValue={tcf.actual_ts_ar_p || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Actual ash adb p"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="number"
                                                                            placeholder="Actual ash adb p"
                                                                            name="actual_ash_adb_p"
                                                                            defaultValue={tcf.actual_ash_adb_p || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Actual ts adb p"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="number"
                                                                            placeholder="Actual ts adb p"
                                                                            name="actual_ts_adb_p"
                                                                            defaultValue={tcf.actual_ts_adb_p || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Actual aft p"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="number"
                                                                            placeholder="Actual aft p"
                                                                            name="actual_aft_p"
                                                                            defaultValue={tcf.actual_aft_p || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Actual vm p"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="number"
                                                                            placeholder="Actual vm p"
                                                                            name="actual_vm_p"
                                                                            defaultValue={tcf.actual_vm_p || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Actual hgi p"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="number"
                                                                            placeholder="Actual hgi p"
                                                                            name="actual_hgi_p"
                                                                            defaultValue={tcf.actual_hgi_p || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Price basis gar p"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="number"
                                                                            placeholder="Price basis gar p"
                                                                            name="price_basis_gar_p"
                                                                            defaultValue={tcf.price_basis_gar_p || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Gar p"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="number"
                                                                            placeholder="Gar p"
                                                                            name="gar_p"
                                                                            defaultValue={tcf.gar_p || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Actual gar s"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="number"
                                                                            placeholder="Actual gar s"
                                                                            name="actual_gar_s"
                                                                            defaultValue={tcf.actual_gar_s || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Actual tm s"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="number"
                                                                            placeholder="Actual tm s"
                                                                            name="actual_tm_s"
                                                                            defaultValue={tcf.actual_tm_s || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Actual ash ar s"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="number"
                                                                            placeholder="Actual ash ar s"
                                                                            name="actual_ash_ar_s"
                                                                            defaultValue={tcf.actual_ash_ar_s || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Actual ts ar s"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="number"
                                                                            placeholder="Actual ts ar s"
                                                                            name="actual_ts_ar_s"
                                                                            defaultValue={tcf.actual_ts_ar_s || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Actual ash adb s"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="number"
                                                                            placeholder="Actual ash adb s"
                                                                            name="actual_ash_adb_s"
                                                                            defaultValue={tcf.actual_ash_adb_s || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Actual ts adb s"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="number"
                                                                            placeholder="Actual ts adb s"
                                                                            name="actual_ts_adb_s"
                                                                            defaultValue={tcf.actual_ts_adb_s || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Actual aft s"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="number"
                                                                            placeholder="Actual aft s"
                                                                            name="actual_aft_s"
                                                                            defaultValue={tcf.actual_aft_s || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Actual vm s"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="number"
                                                                            placeholder="Actual vm s"
                                                                            name="actual_vm_s"
                                                                            defaultValue={tcf.actual_vm_s || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Actual hgi s"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="number"
                                                                            placeholder="Actual hgi s"
                                                                            name="actual_hgi_s"
                                                                            defaultValue={tcf.actual_hgi_s || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Price basis gar s"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="number"
                                                                            placeholder="Price basis gar s"
                                                                            name="price_basis_gar_s"
                                                                            defaultValue={tcf.price_basis_gar_s || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Gar s"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="number"
                                                                            placeholder="Gar s"
                                                                            name="gar_s"
                                                                            defaultValue={tcf.gar_s || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                            </Row>
                                                            <Row>
                                                                <h4 className='heading'>Bonus / Penalty</h4>

                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Sulfur Bonus Rate (MT) (S)"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder="Sulfur Bonus Rate (MT) (S)"
                                                                            name="sulfur_bonus_rate_MT_S"
                                                                            defaultValue={tcf.sulfur_bonus_rate_MT_S || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Ash Bonus Rate (MT) (S)"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder="Ash Bonus Rate (MT) (S)"
                                                                            name="ash_bonus_rate_MT_S"
                                                                            defaultValue={tcf.ash_bonus_rate_MT_S || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Sulfur Bonus Amount (S)"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder="Sulfur Bonus Amount (S)"
                                                                            name="sulfur_bonus_amount_s"
                                                                            defaultValue={tcf.sulfur_bonus_amount_s || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Ash Bonus Amount (S)"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder="Ash Bonus Amount (S)"
                                                                            name="actual_gcnewc_sales"
                                                                            defaultValue={tcf.actual_gcnewc_sales || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Ash Bonus Amount (S)"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder="Ash Bonus Amount (S)"
                                                                            name="ash_bonus_amount_s"
                                                                            defaultValue={tcf.ash_bonus_amount_s || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Sulfur Penalty Rate (MT) (S)"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder="Sulfur Penalty Rate (MT) (S)"
                                                                            name="sulfur_penalty_rate_MT_S"
                                                                            defaultValue={tcf.sulfur_penalty_rate_MT_S || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Ash Penalty Rate (MT) (S)	"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder="Ash Penalty Rate (MT) (S)"
                                                                            name="ash_penalty_rate_MT_S"
                                                                            defaultValue={tcf.ash_penalty_rate_MT_S || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Ash Penalty Amount (S)"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder="Ash Penalty Amount (S)"
                                                                            name="ash_penalty_amount_s"
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                            </Row>
                                                            <Row>
                                                                <h4 className='heading'>Cost Sheet Actual</h4>

                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="FOB Pur Price"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="number"
                                                                            placeholder="FOB Pur Price"
                                                                            name="fob_pur_price"
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="FOB Sales Price"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="number"
                                                                            placeholder="FOB Sales Price"
                                                                            name="fob_sales_price"
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>   <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Actual Pur Price"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="number"
                                                                            placeholder="Actual Pur Price"
                                                                            name="actual_pur_price"
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>   <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Actual Sales Price"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="number"
                                                                            placeholder="Actual Sales Price"
                                                                            name="actual_sales_price"
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>

                                                                {/* <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Actual Pur Amount"
                className="mb-3"


              >
                <Form.Control
                  type="number"
                  placeholder="Actual Pur Amount"
                  name="actual_pur_amount" defaultValue={tcf.actual_pur_amount || ""}
                  onChange={handleChangeInput}
                 />
              </FloatingLabel>
            </Form.Group>   */}
                                                                {/* <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Actual Pur Amount"
                className="mb-3"


              >
                <Form.Control
                  type="number"
                  placeholder="Actual Pur Amount"
                  name="actual_pur_amount" defaultValue={tcf.actual_pur_amount || ""}
                  onChange={handleChangeInput}
                 />
              </FloatingLabel>
            </Form.Group>  */}
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Actual Sales"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="number"
                                                                            placeholder="Actual Sales"
                                                                            name="actual_sales"
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>   <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Actual FOB Purchase"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="number"
                                                                            placeholder="Actual FOB Purchase"
                                                                            name="actual_fob_purchase"
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>   <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Final Purchase Price	"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="number"
                                                                            placeholder="Final Purchase Price	"
                                                                            name="final_purchase_price"
                                                                             onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>   <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Final Sales Price"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="number"
                                                                            placeholder="Final Sales Price"
                                                                            name="final_sales_price" onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>   <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Final Purchase Amount"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="number"
                                                                            placeholder="Final Purchase Amount"
                                                                            name="final_purchase_amount"
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Actual Freight Rate"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="number"
                                                                            placeholder="Actual Freight Rate"
                                                                            name="actual_freight_rate" defaultValue={tcf.actual_freight_rate || ""} onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>

                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Actual Freight Amount"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="number"
                                                                            placeholder="Actual Freight Amount"
                                                                            name="actual_freight_amount" defaultValue={tcf.actual_freight_amount || ""} onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>   <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Actual Insurance"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="number"
                                                                            placeholder="Actual Insurance"
                                                                            name="actual_insurance" defaultValue={tcf.actual_insurance || ""}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>   <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Actual Net Margin - Shipment"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="number"
                                                                            placeholder="Actual Net Margin - Shipment"
                                                                            name="actual_net_margin_shipment" onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>   <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Actual Commission"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="number"
                                                                            placeholder="Actual Commission"
                                                                            name="actual_commission" defaultValue={tcf.actual_commission || ""} onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>   <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Actual Net Margin - Ton"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="number"
                                                                            placeholder="Actual Net Margin - Ton"
                                                                            name="actual_net_margin_ton" onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>   <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Actual Gross Margin - Shipment	"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="number"
                                                                            placeholder="Actual Gross Margin - Shipment	"
                                                                            name="actual_gross_margin_shipment" onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>   <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Actual Gross Margin - Ton"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="number"
                                                                            placeholder="Actual Gross Margin - Ton"
                                                                            name="actual_gross_margin_ton" onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                            </Row>
                                                            <Row>
                                                                <h4 className='heading'>Shipment Cost</h4>

                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Actual LC Transfer Cost"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="number"
                                                                            placeholder="Actual LC Transfer Cost"
                                                                            name="actual_lc_transfer_cost"
                                                                            defaultValue={tcf.actual_lc_transfer_cost || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Document Discount Date"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="number"
                                                                            placeholder="Document Discount Date"
                                                                            name="document_discount_date"
                                                                            defaultValue={tcf.document_discount_date}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Actual Bill Handling Charges"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="number"
                                                                            placeholder="Actual Bill Handling Charges"
                                                                            name="actual_bill_handling_charges"
                                                                            defaultValue={tcf.actual_bill_handling_charges || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                {/* <Form.Group as={Col} md="6">

          <input
          type='checkbox'
          name='actual_payment_received'
          checked={actualCheck} // Use th e state variable to determine if the checkbox is checked
          onChange={handleActual}// Handle the change event
          className='mx-3'
        />
        <label>actual payment received	</label>
        </Form.Group> */}
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Actual Adding Confirmation Charges"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="number"
                                                                            placeholder="Actual Adding Confirmation Charges"
                                                                            name="actual_adding_confirmation_charges"
                                                                            defaultValue={tcf.actual_adding_confirmation_charges || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                {/* <Form.Group as={Col} md="6">
          <input
          type='checkbox'
          name='actual_payment_received'
          checked={actualCheck} // Use th e state variable to determine if the checkbox is checked
          onChange={handleActual}// Handle the change event
          className='mx-3'
        /><label>actual payment received	</label>
        </Form.Group> */}
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Actual Fixed Other Bank Cost"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="number"
                                                                            placeholder="Actual Fixed Other Bank Cost"
                                                                            name="actual_fixed_other_bank_charges"
                                                                            defaultValue={tcf.actual_fixed_other_bank_charges || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Bank Interest/ Discounting"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="number"
                                                                            placeholder="Bank Interest/ Discounting"
                                                                            name="bank_interest_or_discounting"
                                                                            defaultValue={tcf.bank_interest_or_discounting || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="VAT On Bank Charges"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="number"
                                                                            placeholder="VAT On Bank Charges"
                                                                            name="vat_on_bank_charges"
                                                                            defaultValue={tcf.vat_on_bank_charges || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Actual Costs"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="number"
                                                                            placeholder="Actual Costs"
                                                                            name="actual_costs"
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Actual Fixed Apportioned Overheads"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="number"
                                                                            placeholder="Actual Fixed Apportioned Overheads"
                                                                            name="actual_fixed_apportioned_overheads"
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Actual Cost -Ton"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="number"
                                                                            placeholder="Actual Cost -Ton"
                                                                            name="actual_cost_ton"
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Actual Contract Specific Cost"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="number"
                                                                            placeholder="Actual Contract Specific Cost"
                                                                            name="actual_contract_specific_cost"
                                                                            defaultValue={tcf.actual_contract_specific_cost || ""}
                                                                            onChange={handleChangeInput}
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6"></Form.Group>
                                                                {/* <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Final Total Cost	"
                className="mb-3"


              >
                <Form.Control
                  type="number"
                  placeholder="Final Total Cost	"
                  name="final_total_cost"
                 />
              </FloatingLabel>
            </Form.Group>  */}
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Actual Prov For Corporate Tax"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="number"
                                                                            placeholder="Actual Prov For Corporate Tax"
                                                                            name="actual_prov_for_corporate_tax"
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Actual Overheads"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="number"
                                                                            placeholder="Actual Overheads"
                                                                            name="actual_overheads"
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label=" Actual Withholding Tax"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="number"
                                                                            placeholder=" Actual Withholding Tax"
                                                                            name="actual_withholding_tax"
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>

                                                            </Row>

                                                            <Row>
                                                                <h4 className='heading'>Debit Note Details</h4>

                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Supplier Dem/Despatch"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="number"
                                                                            placeholder="Supplier Dem/Despatch"
                                                                            name="supplier_dem_or_despatch"
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Buyer Dem/Despatch"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="number"
                                                                            placeholder="Buyer Dem/Despatch"
                                                                            name="buyer_dem_or_despatch"
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Supplier Dem/Despatch DN Amount"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="number"
                                                                            placeholder="Supplier Dem/Despatch DN Amount"
                                                                            name="supplier_dem_or_despatch_DN_amount"
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Buyer Dem/Despatch DN Amount"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="number"
                                                                            placeholder="Buyer Dem/Despatch DN Amount"
                                                                            name="buyer_dem_or_despatch_DN_amount"
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>   <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Supplier DN Amount"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="number"
                                                                            placeholder="Supplier DN Amount"
                                                                            name="supplier_DN_amount"
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Supplier DN Amount"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="number"
                                                                            placeholder="Supplier DN Amount"
                                                                            name="supplier_DN_amount"
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>    <Form.Group as={Col} md="6">
                                                                    <FloatingLabel
                                                                        controlId="floatingInput"
                                                                        label="Buyer DN Amount"
                                                                        className="mb-3"


                                                                    >
                                                                        <Form.Control
                                                                            type="number"
                                                                            placeholder="Buyer DN Amount"
                                                                            name="buyer_DN_amount"
                                                                        />
                                                                    </FloatingLabel>
                                                                </Form.Group>
                                                            </Row>
                                                            <p className="edit-btn">
                                                                <input
                                                                    type="submit"
                                                                    className="account-save"
                                                                    onClick={handleSaveEdit}
                                                                    value="Save"
                                                                //disabled={isSubmitted}
                                                                />
                                                                <button className="btn btn-primary" onClick={handleCancelEdit}>
                                                                    Cancel
                                                                </button>
                                                            </p>
                                                        </div> </div> </div>
                                                <Footer />
                                            </div>
                                        ) : (
                                            <div id="view-page">
                                                <Header />
                                                <div className="contactAccount">
                                                    <div className="row">
                                                        <div className="col-md-2 recent-item">
                                                            <RecentItems />
                                                        </div>
                                                        <div className="col-md-10 right-section">
                                                            <div className="page-header" id="add-account">
                                                                <div>
                                                                    <h2 className="main-content-title tx-24 mg-b-5">
                                                                        TCF Details
                                                                    </h2>
                                                                    <ol className="breadcrumb">
                                                                        <li className="breadcrumb-item active" aria-current="page">
                                                                            Dashboard / TCF / TCF Details
                                                                        </li>
                                                                    </ol>
                                                                </div>
                                                                <div className="d-flex opd">

                                                                </div>
                                                            </div>
                                                            <div className="tasks column-gap fulid-section order-section">
                                                                <div className="edit-delete">
                                                                    <span id="edit" onClick={handleEditClick}>
                                                                        Edit
                                                                    </span>
                                                                    <span>
                                                                        <Link to={'/tcf/create-view-list'}> Cancel</Link>
                                                                    </span>
                                                                    <span>
                                                                        Sales Report
                                                                    </span>
                                                                    <span onClick={tradeConrfirm}>
                                                                        Trade Confirmation Sheet
                                                                    </span>

                                                                </div>
                                                                <div className="col-md-12" id="ax.account_details">
                                                                    <h4 className="heading">Information</h4>
                                                                    <hr></hr>
                                                                </div>
                                                                <table class="table table-bordered account-table tables">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td id="td-right">
                                                                                <span>TCF No</span>
                                                                            </td><td>
                                                                                {tcf.tcf_no}
                                                                            </td>
                                                                            <td id="td-right">
                                                                                <span>Locked</span>
                                                                            </td><td>
                                                                                <input
                                                                                    type='checkbox'
                                                                                    name='locked'
                                                                                    checked={lockedOne} // Use the state variable to determine if the checkbox is checked
                                                                                    onChange={handleCheckboxLocked} // Handle the change event
                                                                                    className='mx-3' disabled={isButtonEnabled}
                                                                                />
                                                                            </td>

                                                                        </tr>
                                                                        <tr>
                                                                            <td id="td-right">
                                                                                <span>TCF Date </span>
                                                                            </td><td>
                                                                                {tcf.tcf_date}
                                                                            </td>
                                                                            <td id="td-right">
                                                                                <span>Record Type</span>
                                                                            </td><td>
                                                                                {tcf.record_type}
                                                                            </td>
                                                                        </tr><tr>
                                                                            <td id="td-right">
                                                                                <span>opportunity </span>
                                                                            </td><td>
                                                                                <Link to={`/opportunity/opportunity-detail/${tcf.opportunity_id}`}>{tcf.opportunity}</Link>
                                                                            </td>
                                                                            <td id="td-right">
                                                                                <span>opportunity name</span>
                                                                            </td><td>
                                                                                <Link to={`/opportunity/opportunity-detail/${tcf.opportunity_id}`}>{tcf.opportunity_name}</Link>
                                                                            </td>
                                                                        </tr>{" "}
                                                                        <tr>
                                                                            <td id="td-right">
                                                                                <span>Company</span>
                                                                            </td><td>
                                                                                <Link to={`/company/company-details/${tcf.rwoodcompany_id}`}><span>{tcf.company} </span></Link>
                                                                            </td>
                                                                            <td id="td-right">
                                                                                <span>Currency</span>
                                                                            </td><td>
                                                                                {tcf.currency}
                                                                            </td>

                                                                        </tr>
                                                                        <tr>
                                                                            <td id="td-right">
                                                                                <span>Quote</span>
                                                                            </td><td>
                                                                                <Link to={`/opportunity/quote-view/${tcf.offer_id}`}>{tcf.quote}</Link>
                                                                            </td>
                                                                            <td id="td-right">
                                                                                <span>Exchange Rate </span>
                                                                            </td><td>
                                                                                {tcf.exchange_rate}
                                                                            </td>
                                                                        </tr><tr>
                                                                            <td id="td-right">
                                                                                <span>trader name</span>
                                                                            </td><td>
                                                                                <Link to={`/user/user-details/${tcf.rwoodusers_id}`}>{tcf.trader_name}</Link>
                                                                            </td>
                                                                            <td id="td-right">
                                                                                <span>order</span>
                                                                            </td><td>
                                                                                <Link to={`/order/view-order-details/${tcf.rwood_order_id}`}>{tcf.order}</Link>
                                                                            </td>
                                                                        </tr>{" "}
                                                                        <tr>
                                                                            <td id="td-right">
                                                                                <span>owner </span>
                                                                            </td><td>
                                                                                {tcf.owner}
                                                                            </td>

                                                                            <td id="td-right">
                                                                                <span>Rwood Bank Master</span>
                                                                            </td><td>
                                                                                <Link to={`/bank/bank-details/${tcf.rwoodbank_id}`}>{tcf.rwood_bank_master}</Link>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>

                                                                            <td id="td-right">
                                                                                <span className="tooltips-label">Auto Select Pricebook</span>
                                                                                <span id='tooltip' data-tooltip-id="button-tooltip" data-tooltip-place='right'>
                                                                                    <IoIosHelpCircle />
                                                                                </span>
                                                                                <ReactTooltip
                                                                                    id="button-tooltip"
                                                                                    place="bottom"
                                                                                    content="If Checked Pricebook will be based on Month of BL Date Of Order If Unchecked Price book will be based on TCF.Pricebook field"
                                                                                />  </td><td>
                                                                                <input
                                                                                    type='checkbox'
                                                                                    name='auto_select_pricebook'
                                                                                    checked={lockeds} // Use the state variable to determine if the checkbox is checked
                                                                                    onChange={handleCheckboxChange} // Handle the change event
                                                                                    className='mx-3'
                                                                                    disabled={isButtonEnabled}
                                                                                />
                                                                            </td>
                                                                            <td id="td-right">
                                                                                <span>Pricebook</span>
                                                                            </td><td>
                                                                                <Link to={`/pricebook/pricebook-details/${tcf.price_book_id}`}>{tcf.price_book}</Link>
                                                                            </td>
                                                                        </tr><tr>
                                                                            <td id="td-right">
                                                                                <span>TCF Status</span>
                                                                            </td><td>
                                                                                {tcf.tcf_status}
                                                                            </td>
                                                                            <td id="td-right">
                                                                                <span>Purchase Confirmation Date</span>
                                                                                <span id='tooltip' data-tooltip-id="button-tooltips" data-tooltip-place='right'>
                                                                                    <IoIosHelpCircle />
                                                                                </span>
                                                                                <ReactTooltip
                                                                                    id="button-tooltips"
                                                                                    place="bottom"
                                                                                    content="Date on which the purchase was confirmed"
                                                                                />
                                                                            </td><td>
                                                                                {tcf.purchase_confirmation_date}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td id="td-right">
                                                                                <span>Buyer Contract</span>
                                                                            </td><td>
                                                                                <Link to={`/contract/contract-details/${tcf.contract_id}`}>{tcf.buyer_contract}</Link>
                                                                            </td>
                                                                            <td id="td-right">
                                                                                <span>Contract Shipment</span>
                                                                            </td><td>
                                                                                <Link to={`/shipment/contract-shipment-details/${tcf.contract_ship_id}`}>{tcf.contract_shipment}</Link>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td id="td-right">
                                                                                <span className="tooltips-label">Product Master</span>
                                                                                <span id='tooltip' data-tooltip-id="button-tooltip1" data-tooltip-place='right'>
                                                                                    <IoIosHelpCircle />
                                                                                </span>
                                                                                <ReactTooltip
                                                                                    id="button-tooltip1"
                                                                                    place="bottom"
                                                                                    content="Product Master"
                                                                                />
                                                                            </td><td>
                                                                                <Link to={`/productmaster/product-detail/${tcf.product_id}`}>{tcf.product_master}</Link>
                                                                            </td>
                                                                            <td id="td-right">
                                                                                <span>Product</span>
                                                                            </td><td>
                                                                                <Link to={`/product/product-detail/${tcf.new_products_id}`}>{tcf.product}</Link>
                                                                            </td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td id="td-right">
                                                                                <span>Product Name</span>
                                                                            </td><td>
                                                                                {tcf.product_name}
                                                                            </td>
                                                                            <td>

                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>

                                                                <div className="col-md-12" id="ax.account_details">
                                                                    <h4 className="heading">Shipment Quantity</h4>
                                                                    <hr></hr>
                                                                </div>
                                                                <table class="table table-bordered account-table tables">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td id="td-right">
                                                                                <span className="tooltips-label">Shipment Qty</span>
                                                                                <span id='tooltip' data-tooltip-id="button-tooltip2" data-tooltip-place='right'>
                                                                                    <IoIosHelpCircle />
                                                                                </span>
                                                                                <ReactTooltip
                                                                                    id="button-tooltip2"
                                                                                    place="bottom"
                                                                                    content="IF (ISNULL( A_BL_Quantity__c ),  IF(ISNULL(Stowage_Qty__c), Contract_Quantity_MT__c  , Stowage_Qty__c),  A_BL_Quantity__c )"
                                                                                />
                                                                            </td><td>
                                                                                {renderShipmentQty(tcf.shipment_qty)}
                                                                            </td>
                                                                            <td id="td-right">
                                                                                <span className="tooltips-label">Shipment Month</span>
                                                                                <span id='tooltip' data-tooltip-id="button-tooltip3" data-tooltip-place='right'>
                                                                                    <IoIosHelpCircle />
                                                                                </span>
                                                                                <ReactTooltip
                                                                                    id="button-tooltip3"
                                                                                    place="bottom"
                                                                                    content="text(MONTH(Ship_Month__c )) &amp; &quot; &quot; &amp; text (Year (Ship_Month__c))"
                                                                                />

                                                                            </td><td>
                                                                                {tcf.shipment_month}
                                                                            </td>

                                                                        </tr>
                                                                        <tr>
                                                                            <td id="td-right">
                                                                                <span>shipment purchase price</span>
                                                                            </td><td>
                                                                                {renderShipmentQty(tcf.shipment_purchase_price)}
                                                                            </td>
                                                                            <td id="td-right">
                                                                                <span className="tooltips-label">shipment sales price</span>
                                                                                <span id='tooltip' data-tooltip-id="button-tooltip4" data-tooltip-place='right'>
                                                                                    <IoIosHelpCircle />
                                                                                </span>
                                                                                <ReactTooltip
                                                                                    id="button-tooltip4"
                                                                                    place="bottom"
                                                                                    content="text(MONTH(Ship_Month__c )) &amp; &quot; &quot; &amp; text (Year (Ship_Month__c))"
                                                                                />

                                                                            </td><td>
                                                                                {renderShipmentQty(tcf.shipment_sales_price)}
                                                                            </td>
                                                                        </tr><tr>
                                                                            <td id="td-right">
                                                                                <span>shipment purchase </span>
                                                                            </td><td>
                                                                                {renderShipmentQty(tcf.shipment_purchase)}
                                                                            </td>
                                                                            <td id="td-right">
                                                                                <span>shipment sales</span>
                                                                            </td><td>
                                                                                {renderShipmentQty(tcf.shipment_sales)}
                                                                            </td>
                                                                        </tr>{" "}
                                                                        <tr>
                                                                            <td id="td-right">
                                                                                <span className="tooltips-label">shipment gross margin ton</span>
                                                                                <span id='tooltip' data-tooltip-id="button-tooltip7" data-tooltip-place='right'>
                                                                                    <IoIosHelpCircle />
                                                                                </span>
                                                                                <ReactTooltip
                                                                                    id="button-tooltip7"
                                                                                    place="bottom"
                                                                                    content="Workflow Rule IF (ISBLANK(Actual_Gross_Margin_Ton__c ), Projected_Gross_Margin_Ton__c , Actual_Gross_Margin_Ton__c "
                                                                                />
                                                                            </td><td>
                                                                                {renderShipmentQty(tcf.shipment_gross_margin_ton)}
                                                                            </td>
                                                                            <td id="td-right">
                                                                                <span className="tooltips-label">shipment gross margin</span>
                                                                                <span id='tooltip' data-tooltip-id="button-tooltip5" data-tooltip-place='right'>
                                                                                    <IoIosHelpCircle />
                                                                                </span>
                                                                                <ReactTooltip
                                                                                    id="button-tooltip5"
                                                                                    place="bottom"
                                                                                    content="Shipment_Gross_Margin_Ton__c \* Shipment_Qty__c"
                                                                                />
                                                                            </td><td>
                                                                                {renderShipmentQty(tcf.shipment_gross_margin)}
                                                                            </td>

                                                                        </tr>

                                                                    </tbody>
                                                                </table>
                                                                <div className="col-md-12" id="ax.account_details">
                                                                    <h4 className="heading">Trade Confirmation Details</h4>
                                                                    <hr></hr>
                                                                </div>
                                                                <table class="table table-bordered account-table tables">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td id="td-right"><span>Buyer</span></td>
                                                                            <td><Link to={`/accounts/${tcf.account_id}`}>{tcf.buyer}</Link></td>
                                                                            <td id="td-right"><span>Status of Buyer Contract</span></td>
                                                                            <td>{tcf.status_of_buyer_contract}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td id="td-right"><span>Supplier</span></td>
                                                                            <td><Link to={`/accounts/${tcf.supplier_id}`}>{tcf.supplier}</Link></td>
                                                                            <td id="td-right"><span>Status of purchase contract</span></td>
                                                                            <td>{tcf.status_of_purchase_contract}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td id="td-right"><span>Product Description</span></td>
                                                                            <td>{tcf.product_description}</td>
                                                                            <td id="td-right"><span className="tooltips-label">ship Month</span>
                                                                                <span id='tooltip' data-tooltip-id="button-tooltip8" data-tooltip-place='right'>
                                                                                    <IoIosHelpCircle />
                                                                                </span>
                                                                                <ReactTooltip
                                                                                    id="button-tooltip8"
                                                                                    place="bottom"
                                                                                    content="Laycan From Date"
                                                                                /> </td>
                                                                            <td>{tcf.ship_month}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td id="td-right"><span>Product Specification</span></td>
                                                                            <td>{tcf.product_specification}</td>
                                                                            <td id="td-right"><span>Quantity MT</span></td>
                                                                            <td>{renderShipmentQty(tcf.quantity_MT)}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td id="td-right"><span>Proposed Laycan From</span></td>
                                                                            <td>{tcf.proposed_laycan_from}</td>
                                                                            <td id="td-right"><span>Proposed Laycan To</span></td>
                                                                            <td>{tcf.proposed_laycan_to}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td id="td-right"><span>Price Basis</span></td>
                                                                            <td>{tcf.price_basis}</td>
                                                                            <td id="td-right"><span>Vessel Type</span></td>
                                                                            <td>{tcf.vessel_type}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td id="td-right"><span>Payment Terms</span></td>
                                                                            <td>{tcf.payment_term}</td>
                                                                            <td id="td-right"><span>Selling Terms</span></td>
                                                                            <td>{tcf.selling_terms}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td id="td-right"><span>Price Adjustment</span></td>
                                                                            <td>{tcf.price_adjustment}</td>
                                                                            <td id="td-right"><span>Buying Terms</span></td>
                                                                            <td>{tcf.buying_terms}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>

                                                                <div className="col-md-12" id="ax.account_details">
                                                                    <h4 className="heading">Contractual</h4>
                                                                    <hr></hr>
                                                                </div>
                                                                <table class="table table-bordered account-table tables">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td id="td-right"><span>Contractual GAR (P)</span></td>
                                                                            <td>{renderShipmentQty(tcf.contractual_GAR_P)}</td>
                                                                            <td id="td-right"><span>Contractual GAR (S)</span></td>
                                                                            <td>{renderShipmentQty(tcf.contractual_GAR_S)}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td id="td-right"><span>Contractual TM (AR) (P)</span></td>
                                                                            <td>{renderShipmentQty(tcf.contractual_TM_AR_P)}</td>
                                                                            <td id="td-right"><span>Contractual TM (AR) (S)</span></td>
                                                                            <td>{renderShipmentQty(tcf.contractual_TM_AR_S)}</td>
                                                                        </tr>
                                                                        <tr>

                                                                            <td id="td-right"><span>Contractual TS (AR) (P)</span></td>
                                                                            <td>{renderShipmentQty(tcf.contractual_Ts_AR_p)}</td>
                                                                            <td id="td-right"><span>Contractual TS (AR) (S)</span></td>
                                                                            <td>{renderShipmentQty(tcf.contractual_Ts_AR_s)}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td id="td-right"><span>Contractual Ash (AR) (P)</span></td>
                                                                            <td>{renderShipmentQty(tcf.contractual_Ash_AR_P)}</td>
                                                                            <td id="td-right"><span>Contractual Ash (AR) (S)</span></td>
                                                                            <td>{renderShipmentQty(tcf.contractual_Ash_AR_S)}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td id="td-right"><span>Contractual TS (ADB) (P)</span></td>
                                                                            <td>{renderShipmentQty(tcf.contractual_Ts_ADB_P)}</td>
                                                                            <td id="td-right"><span>Contractual TS (ADB) (S)</span></td>
                                                                            <td>{renderShipmentQty(tcf.contractual_Ts_ADB_S)}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td id="td-right"><span>Contractual Ash (AR) (S)</span></td>
                                                                            <td>{renderShipmentQty(tcf.contractual_Ash_AR_S)}</td>
                                                                            <td id="td-right"><span>Projected Price Basis (GAR) (P)</span></td>
                                                                            <td>{renderShipmentQty(tcf.projected_price_basis_GAR_P)}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td id="td-right"><span>Contractual HBA Year</span></td>
                                                                            <td>{tcf.contractual_HBA_year}</td>
                                                                            <td id="td-right">  <span className="tooltips-label">Discounted Price</span>
                                                                                <span id='tooltip' data-tooltip-id="button-tooltip11" data-tooltip-place='right'>
                                                                                    <IoIosHelpCircle />
                                                                                </span>
                                                                                <ReactTooltip
                                                                                    id="button-tooltip11"
                                                                                    place="bottom"
                                                                                    content="Discounted Price as per Vessel Type \r\u003Cbr\u003EIf Geared (GNG) - 0.90\r\u003Cbr\u003Eif Grearless - 0.92"
                                                                                /> </td>
                                                                            <td>{renderShipmentQty(tcf.discounted_price)}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td id="td-right"><span>Contractual HBA Month</span></td>
                                                                            <td>{tcf.contractual_HBA_month}</td>
                                                                            <td id="td-right">  <span className="tooltips-label">Projected HBA</span>
                                                                                <span id='tooltip' data-tooltip-id="button-tooltip10" data-tooltip-place='right'>
                                                                                    <IoIosHelpCircle />
                                                                                </span>
                                                                                <ReactTooltip
                                                                                    id="button-tooltip10"
                                                                                    place="bottom"
                                                                                    content="Projected HBA - Derrived from Opportunity.Pricebook.Projected HBA (Contractual_HBA)"
                                                                                /> </td>
                                                                            <td>{renderShipmentQty(tcf.projected_hba)}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td id="td-right">  <span className="tooltips-label">G1/G2  </span>
                                                                                <span id='tooltip' data-tooltip-id="button-tooltip12" data-tooltip-place='right'>
                                                                                    <IoIosHelpCircle />
                                                                                </span>
                                                                                <ReactTooltip
                                                                                    id="button-tooltip12"
                                                                                    place="bottom"
                                                                                    content="( Actual_GAR_P__c \*  GCNEWC__c  / 6322 ) \*  Discounted_Price__c')"
                                                                                /> </td>
                                                                            <td>{tcf.g1_g2}</td>
                                                                            <td id="td-right"><span>Projected Index Value</span></td>
                                                                            <td>{renderShipmentQty(tcf.projected_index_value)}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td id="td-right"><span>Projected Purchase Price Type</span></td>
                                                                            <td>{tcf.projected_purchase_type}</td>
                                                                            <td id="td-right"><span>Projected Sale Price Type</span></td>
                                                                            <td>{tcf.projected_sale_price_type}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td id="td-right"><span>Projected Fixed Purchase Price</span></td>
                                                                            <td>{renderShipmentQty(tcf.projected_fixed_purchase_price)}</td>
                                                                            <td id="td-right"><span>Projected Fixed Sales Price</span></td>
                                                                            <td>{renderShipmentQty(tcf.projected_fixed_sales_price)}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td id="td-right"><span>Projected Purchase Price Margin</span></td>
                                                                            <td>{renderShipmentQty(tcf.projected_purchase_price_margin)}</td>
                                                                            <td id="td-right"><span>Projected Sales Price Margin</span></td>
                                                                            <td>{renderShipmentQty(tcf.projected_sales_price_margin)}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td id="td-right"><span>Projected Fixed Purchase Price</span></td>
                                                                            <td>{renderShipmentQty(tcf.projected_fixed_purchase_price)}</td>
                                                                            <td id="td-right"><span>Projected Barge Discount</span></td>
                                                                            <td>{renderShipmentQty(tcf.barge_discount)}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td id="td-right"><span>Projected Purchase Type</span></td>
                                                                            <td>{tcf.projected_purchase_type}</td>
                                                                            <td id="td-right">  <span className="tooltips-label">Contractual Pur Price</span>
                                                                                <span id='tooltip' data-tooltip-id="button-tooltip14" data-tooltip-place='right'>
                                                                                    <IoIosHelpCircle />
                                                                                </span>
                                                                                <ReactTooltip
                                                                                    id="button-tooltip14"
                                                                                    place="bottom"
                                                                                    content="HPB=(Contractual_HBA\*C_GAR_P)/ 6322)\*( (100-TM_AR)/(100-8)))-(((ContractualTS(ARB)-0.8)\*4)+((Contractual_Ash-15)\*0.4)))\r\u003Cbr\u003EFixed price=ProjFixed_Purchase_Price\*C_GAR P /Proj Price_Basis_GAR P\r\u003Cbr\u003EGCNEWC=Proj. GCNEWC\*CGAR P/ProjectPrice Basis GAR P + ProjMargin"
                                                                                /> </td>
                                                                            <td>{renderShipmentQty(tcf.contractual_pur_price)}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td id="td-right"><span>Projected Sales Price</span></td>
                                                                            <td>{renderShipmentQty(tcf.projected_sales_price)}</td>
                                                                            <td id="td-right"><span>Contractual Margin</span></td>
                                                                            <td>{renderShipmentQty(tcf.contractual_margin)}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>

                                                                <div className="col-md-12" id="ax.account_details">
                                                                    <h4 className="heading">Cost Sheet Projection</h4>
                                                                    <hr></hr>
                                                                </div>

                                                                <table class="table table-bordered account-table tables">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td id="td-right"><span>projected pur amount</span>
                                                                                <span id='tooltip' data-tooltip-id="button-tooltips1" data-tooltip-place='right'>
                                                                                    <IoIosHelpCircle />
                                                                                </span>
                                                                                <ReactTooltip
                                                                                    id="button-tooltips1"
                                                                                    place="bottom"
                                                                                    content="Work Flow Rule - (Contract_Quantity_MT \* round(Contractual_Pur_Price,2))"
                                                                                />  </td>
                                                                            <td>{renderShipmentQty(tcf.projected_pur_amount)}</td>
                                                                            <td id="td-right"><span>projected sales</span>
                                                                                <span id='tooltip' data-tooltip-id="button-tooltips2" data-tooltip-place='right'>
                                                                                    <IoIosHelpCircle />
                                                                                </span>
                                                                                <ReactTooltip
                                                                                    id="button-tooltips2"
                                                                                    place="bottom"
                                                                                    content="Workflow Rules - Projected_Sales_Price__c \* Contract_Quantity_MT__c'"
                                                                                />  </td>
                                                                            <td>{renderShipmentQty(tcf.projected_sales)}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td id="td-right"><span>projected fob purchase</span>
                                                                                <span id='tooltip' data-tooltip-id="button-tooltips3" data-tooltip-place='right'>
                                                                                    <IoIosHelpCircle />
                                                                                </span>
                                                                                <ReactTooltip
                                                                                    id="button-tooltips3"
                                                                                    place="bottom"
                                                                                    content="Projected Pur Amount"
                                                                                />  </td>
                                                                            <td>{renderShipmentQty(tcf.projected_fob_purchase)}</td>
                                                                            <td id="td-right"><span>projected gross margin shipment</span>
                                                                                <span id='tooltip' data-tooltip-id="button-tooltips4" data-tooltip-place='right'>
                                                                                    <IoIosHelpCircle />
                                                                                </span>
                                                                                <ReactTooltip
                                                                                    id="button-tooltips4"
                                                                                    place="bottom"
                                                                                    content="Projected_Sales__c - Projected_FOB_Purchase__c - Projected_Freight_Amount__c -  Projected_Insurance__c - Projected_Commision__c"
                                                                                />  </td>
                                                                            <td>{renderShipmentQty(tcf.projected_gross_margin_shipment)}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td id="td-right"><span>projected freight rate</span></td>
                                                                            <td>{renderShipmentQty(tcf.projected_freight_rate)}</td>
                                                                            <td id="td-right"><span>projected gross margin TON</span>
                                                                                <span id='tooltip' data-tooltip-id="button-tooltips5" data-tooltip-place='right'>
                                                                                    <IoIosHelpCircle />
                                                                                </span>
                                                                                <ReactTooltip
                                                                                    id="button-tooltips5"
                                                                                    place="bottom"
                                                                                    content="Projected_Gross_Margin_Shipment__c /  Contract_Quantity_MT__c"
                                                                                />  </td>
                                                                            <td>{renderShipmentQty(tcf.projected_gross_margin_ton)}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td id="td-right"><span>projected freight amount</span></td>
                                                                            <td>{renderShipmentQty(tcf.projected_freight_amount)}</td>
                                                                            <td id="td-right"><span>projected insurance</span></td>
                                                                            <td>{renderShipmentQty(tcf.projected_insurance)}</td>
                                                                        </tr>
                                                                        <tr>

                                                                            <td id="td-right"><span>projected net margin TON</span></td>
                                                                            <td>{renderShipmentQty(tcf.projected_net_margin_ton)}</td>
                                                                            <td id="td-right"><span>projected commission</span></td>
                                                                            <td>{renderShipmentQty(tcf.projected_commission)}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td id="td-right"><span>projected  LC negotiation cost</span>
                                                                                <span id='tooltip' data-tooltip-id="button-tooltips8" data-tooltip-place='right'>
                                                                                    <IoIosHelpCircle />
                                                                                </span>
                                                                                <ReactTooltip
                                                                                    id="button-tooltips8"
                                                                                    place="bottom"
                                                                                    content="Projected_Sales__c \* Rwood_Bank_Master__r.LC_Negotiation_Rate__c Adding Confirmation Charges"
                                                                                />  </td>
                                                                            <td>{renderShipmentQty(tcf.projected_lc_negotiation_cost)}</td>
                                                                            <td id="td-right"><span>projected total bank cost</span>
                                                                                <span id='tooltip' data-tooltip-id="button-tooltips6" data-tooltip-place='right'>
                                                                                    <IoIosHelpCircle />
                                                                                </span>
                                                                                <ReactTooltip
                                                                                    id="button-tooltips6"
                                                                                    place="bottom"
                                                                                    content="Projected_LC_Transfer_Cost__c + \r\u003Cbr\u003EProjected_LC_Negotiation_Cost__c + \r\u003Cbr\u003EProjected_Fixed_Other_Bank_Costs__c + \r\u003Cbr\u003EProjected_Adding_Confirmation_Charges__c"
                                                                                />  </td>
                                                                            <td>{renderShipmentQty(tcf.projected_total_bank_cost)}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td id="td-right"><span>projected cost</span>
                                                                                <span id='tooltip' data-tooltip-id="button-tooltips7" data-tooltip-place='right'>
                                                                                    <IoIosHelpCircle />
                                                                                </span>
                                                                                <ReactTooltip
                                                                                    id="button-tooltips7"
                                                                                    place="bottom"
                                                                                    content="Pj_LC_Transfer_Cost+Pj_LC_Nego_Cost+Pj_Fixed_Other_Bank_Costs+Pj_Any_Contract_Spe_Cost+Pj_Fixed_Appo_Overheads+Pj_Adding_Conf_Charges+Pj_Extra_Cost+Pj_Freight_Amt+Pj. Insurance+Pj_Prov_for_corp Tax+Pj_Commission+PJ_Witholding Tax"
                                                                                />  </td>
                                                                            <td>{renderShipmentQty(tcf.projected_cost)}</td>
                                                                            <td id="td-right"><span>projected fixed other bank costs</span>
                                                                                <span id='tooltip' data-tooltip-id="button-tooltips10" data-tooltip-place='right'>
                                                                                    <IoIosHelpCircle />
                                                                                </span>
                                                                                <ReactTooltip
                                                                                    id="button-tooltips10"
                                                                                    place="bottom"
                                                                                    content="Rwood_Bank_Master__r.Fixed_Other_Cost__c \* Contract_Quantity_MT__c"
                                                                                />  </td>
                                                                            <td>{renderShipmentQty(tcf.projected_fixed_other_bank_costs)}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td id="td-right"><span>projected cost TON</span></td>
                                                                            <td>{renderShipmentQty(tcf.projected_cost_ton)}</td>
                                                                            <td id="td-right"><span>projected fixed apportioned overheads</span></td>
                                                                            <td>{renderShipmentQty(tcf.projected_fixed_apportioned_overheads)}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td id="td-right"><span>projected total cost</span></td>
                                                                            <td>{renderShipmentQty(tcf.projected_total_cost)}</td>
                                                                            <td id="td-right"><span>projected any contract specific cost</span></td>
                                                                            <td>{renderShipmentQty(tcf.projected_any_contract_specific_cost)}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td id="td-right"><span>projected extra  cost</span></td>
                                                                            <td>{renderShipmentQty(tcf.projected_extra_cost)}</td>
                                                                            <td id="td-right"><span>projected provision for corporate tax</span>
                                                                                <span id='tooltip' data-tooltip-id="button-tooltips11" data-tooltip-place='right'>
                                                                                    <IoIosHelpCircle />
                                                                                </span>
                                                                                <ReactTooltip
                                                                                    id="button-tooltips11"
                                                                                    place="bottom"
                                                                                    content="1.5% Revenue"
                                                                                />  </td>
                                                                            <td>{renderShipmentQty(tcf.projected_provision_for_corporate_tax)}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td id="td-right"><span>projected withholding tax</span>
                                                                                <span id='tooltip' data-tooltip-id="button-tooltips12" data-tooltip-place='right'>
                                                                                    <IoIosHelpCircle />
                                                                                </span>
                                                                                <ReactTooltip
                                                                                    id="button-tooltips12"
                                                                                    place="bottom"
                                                                                    content="IF ( Company__c= PT RRI , Projected_Sales__c \* Rwood_Bank_Master__r.Tax__c, 0)"
                                                                                />  </td>
                                                                            <td>{renderShipmentQty(tcf.projected_withholding_tax)}</td>
                                                                            <td id="td-right"><span></span></td>
                                                                            <td></td>
                                                                        </tr>

                                                                    </tbody>
                                                                </table>
                                                                <div className="col-md-12" id="ax.account_details">
                                                                    <h4 className="heading">Actual Shipment Details</h4>
                                                                    <hr></hr>
                                                                </div>

                                                                <table class="table table-bordered account-table tables">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td id="td-right"><span>vessel name</span>
                                                                                <span id='tooltip' data-tooltip-id="button-tooltips13" data-tooltip-place='right'>
                                                                                    <IoIosHelpCircle />
                                                                                </span>
                                                                                <ReactTooltip
                                                                                    id="button-tooltips13"
                                                                                    place="bottom"
                                                                                    content="if Barge name is BLANK - Order__r.Vessel_Name__c OR Vessel Name &amp; Barge Name"
                                                                                />  </td>
                                                                            <td>{tcf.vessel_name}</td>
                                                                            <td id="td-right"><span>demmurage rate (P)</span>
                                                                                <span id='tooltip' data-tooltip-id="button-tooltips14" data-tooltip-place='right'>
                                                                                    <IoIosHelpCircle />
                                                                                </span>
                                                                                <ReactTooltip
                                                                                    id="button-tooltips14"
                                                                                    place="bottom"
                                                                                    content="Order__r.S_Despatch_Demmurage_Rate__c"
                                                                                />  </td>
                                                                            <td>{renderShipmentQty(tcf.demmurage_rate_p)}</td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td id="td-right"><span>stowage quantity</span>
                                                                                <span id='tooltip' data-tooltip-id="button-tooltips15" data-tooltip-place='right'>
                                                                                    <IoIosHelpCircle />
                                                                                </span>
                                                                                <ReactTooltip
                                                                                    id="button-tooltips15"
                                                                                    place="bottom"
                                                                                    content="Order__r.Pre_stowage__c"
                                                                                />  </td>
                                                                            <td>{renderShipmentQty(tcf.stowage_qty)}</td>
                                                                            <td id="td-right"><span>demmurage rate (S)</span>
                                                                                <span id='tooltip' data-tooltip-id="button-tooltips16" data-tooltip-place='right'>
                                                                                    <IoIosHelpCircle />
                                                                                </span>
                                                                                <ReactTooltip
                                                                                    id="button-tooltips16"
                                                                                    place="bottom"
                                                                                    content="Order__r.B_Despatch_Demmurage_Rate__c"
                                                                                />  </td>
                                                                            <td>{renderShipmentQty(tcf.demmurage_rate_s)}</td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td id="td-right"><span>Destination</span>
                                                                                <span id='tooltip' data-tooltip-id="button-tooltips17" data-tooltip-place='right'>
                                                                                    <IoIosHelpCircle />
                                                                                </span>
                                                                                <ReactTooltip
                                                                                    id="button-tooltips17"
                                                                                    place="bottom"
                                                                                    content="Order. Destination or Buyer contract.destination."
                                                                                />  </td>
                                                                            <td>{tcf.destination}</td>
                                                                            <td id="td-right"><span>Load Rate</span>
                                                                                <span id='tooltip' data-tooltip-id="button-tooltips18" data-tooltip-place='right'>
                                                                                    <IoIosHelpCircle />
                                                                                </span>
                                                                                <ReactTooltip
                                                                                    id="button-tooltips18"
                                                                                    place="bottom"
                                                                                    content="Order__r.Loading_Rate__c"
                                                                                />  </td>
                                                                            <td>{renderShipmentQty(tcf.load_rate)}</td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td id="td-right"><span>Actual BL Date</span>
                                                                                <span id='tooltip' data-tooltip-id="button-tooltips19" data-tooltip-place='right'>
                                                                                    <IoIosHelpCircle />
                                                                                </span>
                                                                                <ReactTooltip
                                                                                    id="button-tooltips19"
                                                                                    place="bottom"
                                                                                    content="Order__r.Loading_Rate__c"
                                                                                />  </td>
                                                                            <td>{tcf.actual_bl_date}</td>
                                                                            <td id="td-right"><span>Actual BL Qty</span>
                                                                                <span id='tooltip' data-tooltip-id="button-tooltips20" data-tooltip-place='right'>
                                                                                    <IoIosHelpCircle />
                                                                                </span>
                                                                                <ReactTooltip
                                                                                    id="button-tooltips20"
                                                                                    place="bottom"
                                                                                    content="Order.Total BL Quantity"
                                                                                />  </td>
                                                                            <td>{renderShipmentQty(tcf.actual_bl_qty)}</td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td id="td-right"><span>GCNEWC</span>
                                                                                <span id='tooltip' data-tooltip-id="button-tooltips21" data-tooltip-place='right'>
                                                                                    <IoIosHelpCircle />
                                                                                </span>
                                                                                <ReactTooltip
                                                                                    id="button-tooltips21"
                                                                                    place="bottom"
                                                                                    content="pricebook.GCNEWC"
                                                                                />  </td>
                                                                            <td>{renderShipmentQty(tcf.gcnewc)}</td>
                                                                            <td id="td-right"><span>Actual HBA</span>
                                                                                <span id='tooltip' data-tooltip-id="button-tooltips22" data-tooltip-place='right'>
                                                                                    <IoIosHelpCircle />
                                                                                </span>
                                                                                <ReactTooltip
                                                                                    id="button-tooltips22"
                                                                                    place="bottom"
                                                                                    content="Price Book.Actual HBA"
                                                                                />  </td>
                                                                            <td>{renderShipmentQty(tcf.actual_hba)}</td>
                                                                        </tr> <tr>
                                                                            <td id="td-right"><span>Actual GCNEWC - Sales</span>
                                                                                <span id='tooltip' data-tooltip-id="button-tooltips23" data-tooltip-place='right'>
                                                                                    <IoIosHelpCircle />
                                                                                </span>
                                                                                <ReactTooltip
                                                                                    id="button-tooltips23"
                                                                                    place="bottom"
                                                                                    content="CASE(Sale_Price_Type, '(Avg Prev Qtr GCNEWC + Margin)  GCV Adj', Price_Book__r.Avg_GCNEWC_Prev_QTR__c , '(Avg Curr Qtr GCNEWC + Margin) GCV Adj',  Price_Book__r.Avg_GCNEWC_Current_QTR__c , 'Avg Prev Qtr GCNEWC + Margin',  Price_Book__r.Av"
                                                                                /> </td>
                                                                            <td>{renderShipmentQty(tcf.actual_gcnewc_sales)}</td>
                                                                            <td id="td-right"><span>Actual HBA - Purchase</span>
                                                                                <span id='tooltip' data-tooltip-id="button-tooltips24" data-tooltip-place='right'>
                                                                                    <IoIosHelpCircle />
                                                                                </span>
                                                                                <ReactTooltip
                                                                                    id="button-tooltips24"
                                                                                    place="bottom"
                                                                                    content="CASE ( Purchase_Price_Type.Name , 'Avg Prev Qtr HBA + Margin', Price_Book__r.Avg_HBA_Prev_QTR__c, 'Avg Current Qtr HBA + Margin', 'Price_Book__r.Avg_HBA_Current_QTR__c , 'Price_Book__r.Actual_HBA__c)"
                                                                                />  </td>
                                                                            <td>{renderShipmentQty(tcf.actual_hba_purchase)}</td>
                                                                        </tr> <tr>
                                                                            <td id="td-right"><span>Laycan</span>
                                                                                <span id='tooltip' data-tooltip-id="button-tooltips25" data-tooltip-place='right'>
                                                                                    <IoIosHelpCircle />
                                                                                </span>
                                                                                <ReactTooltip
                                                                                    id="button-tooltips25"
                                                                                    place="bottom"
                                                                                    content="Text(Day (Order__r.Laycan_From_Date__c)) & / TEXT(Month (Order__r.Laycan_From_Date__c)) & - & TExt(Day ( Order__r.Laycan_To_Date__c )) & / TEXT(Month(Order__r.Laycan_To_Date__c))  & '' & TEXT(year (Order__r.Laycan_From_Date__c))"
                                                                                />  </td>
                                                                            <td>{tcf.laycan}</td>
                                                                            <td id="td-right"><span>Expected Value Date</span> <span id='tooltip' data-tooltip-id="button-tooltips26" data-tooltip-place='right'>
                                                                                <IoIosHelpCircle />
                                                                            </span>
                                                                                <ReactTooltip
                                                                                    id="button-tooltips26"
                                                                                    place="bottom"
                                                                                    content="Order__r.all Expected_Value_Date__c"
                                                                                />  </td>
                                                                            <td>{tcf.expected_value_date}</td>
                                                                        </tr>

                                                                    </tbody>
                                                                </table>


                                                                <div className="col-md-12" id="ax.account_details">
                                                                    <h4 className="heading">Actual Cost Elements</h4>
                                                                    <hr></hr>
                                                                </div>

                                                                <table class="table table-bordered account-table tables">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td id="td-right"><span>Purchase Price Type</span></td>
                                                                            <td>{tcf.purchase_price_type}</td>
                                                                            <td id="td-right"><span>Actual Data Rcvd</span></td>
                                                                            <td><input
                                                                                type='checkbox'
                                                                                name='actual_data_rcvd'
                                                                                checked={dataReceived} // Use th e state variable to determine if the checkbox is checked
                                                                                onChange={handleData} // Handle the change event
                                                                                className='mx-3' disabled={isButtonEnabled}
                                                                            /></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td id="td-right"><span>fixed purchase price</span></td>
                                                                            <td>{tcf.fixed_purchase_price}</td>
                                                                            <td id="td-right"><span>Sale Price Type</span></td>
                                                                            <td>{tcf.sale_price_type}</td>

                                                                        </tr>
                                                                        <tr>
                                                                            <td id="td-right"><span>Purchase Price Margin</span></td>
                                                                            <td>{tcf.purchase_price_margin}</td>
                                                                            <td id="td-right"><span>Fixed Sale Price</span></td>
                                                                            <td>{tcf.fixed_sale_price}</td>

                                                                        </tr>
                                                                        <tr>
                                                                            <td id="td-right"><span>Barge Discount</span>
                                                                                <span id='tooltip' data-tooltip-id="button-tooltips27" data-tooltip-place='right'>
                                                                                    <IoIosHelpCircle />
                                                                                </span>
                                                                                <ReactTooltip
                                                                                    id="button-tooltips27"
                                                                                    place="bottom"
                                                                                    content="Barge Discount if applicable"
                                                                                />  </td>
                                                                            <td>{renderShipmentQty(tcf.barge_discount)}</td>
                                                                            <td id="td-right"><span>Sales Price Margin</span></td>
                                                                            <td>{tcf.sale_price_margin}</td>
                                                                        </tr>
                                                                        <tr>

                                                                            <td id="td-right"><span>Actual GAR (P)</span></td>
                                                                            <td>{renderShipmentQty(tcf.actual_gar_p)}</td>
                                                                            <td id="td-right"><span>Actual GAR (s)</span></td>
                                                                            <td>{renderShipmentQty(tcf.actual_gar_s)}</td>
                                                                        </tr>
                                                                        <tr>

                                                                            <td id="td-right"><span>Actual TM (AR) (P)</span></td>
                                                                            <td>{renderShipmentQty(tcf.actual_tm_p)}</td>
                                                                            <td id="td-right"><span>Actual TM (AR) (S)</span></td>
                                                                            <td>{renderShipmentQty(tcf.actual_tm_s)}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td id="td-right"><span>Actual Ash (AR) (P)</span></td>
                                                                            <td>{renderShipmentQty(tcf.actual_ash_ar_p)}</td>
                                                                            <td id="td-right"><span>Actual Ash (AR) (S)</span></td>
                                                                            <td>{renderShipmentQty(tcf.actual_ash_ar_s)}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td id="td-right"><span>Actual TS (AR) (P)</span></td>
                                                                            <td>{renderShipmentQty(tcf.actual_ts_ar_p)}</td>
                                                                            <td id="td-right"><span>Actual TS (AR) (S)</span></td>
                                                                            <td>{renderShipmentQty(tcf.actual_ts_ar_s)}</td>
                                                                        </tr>
                                                                        <tr>

                                                                            <td id="td-right"><span>Actual Ash (ADB) (P)</span></td>
                                                                            <td>{renderShipmentQty(tcf.actual_ash_adb_p)}</td>
                                                                            <td id="td-right"><span>Actual Ash (ADB) (S)</span></td>
                                                                            <td>{renderShipmentQty(tcf.actual_ash_adb_s)}</td>

                                                                        </tr>
                                                                        <tr>

                                                                            <td id="td-right"><span>Actual TS (ADB) (P)</span></td>
                                                                            <td>{renderShipmentQty(tcf.actual_ts_adb_p)}</td>
                                                                            <td id="td-right"><span>Actual TS (ADB) (S)</span></td>
                                                                            <td>{renderShipmentQty(tcf.actual_ts_adb_s)}</td>
                                                                        </tr>
                                                                        <tr>

                                                                            <td id="td-right"><span>Actual AFT (P)</span></td>
                                                                            <td>{renderShipmentQty(tcf.actual_aft_p)}</td>
                                                                            <td id="td-right"><span>Actual AFT (S)</span></td>
                                                                            <td>{renderShipmentQty(tcf.actual_aft_s)}</td>
                                                                        </tr>
                                                                        <tr>

                                                                            <td id="td-right"><span>Actual VM (P)</span></td>
                                                                            <td>{renderShipmentQty(tcf.actual_vm_p)}</td>
                                                                            <td id="td-right"><span>Actual VM (S)</span></td>
                                                                            <td>{renderShipmentQty(tcf.actual_vm_s)}</td>
                                                                        </tr>
                                                                        <tr>

                                                                            <td id="td-right"><span>Actual HGI (P)</span></td>
                                                                            <td>{renderShipmentQty(tcf.actual_hgi_p)}</td>
                                                                            <td id="td-right"><span>Actual HGI (S)</span></td>
                                                                            <td>{renderShipmentQty(tcf.actual_hgi_s)}</td>
                                                                        </tr>
                                                                        <tr>

                                                                            <td id="td-right"><span>Price Basis GAR (P)</span></td>
                                                                            <td>{renderShipmentQty(tcf.price_basis_gar_p)}</td>
                                                                            <td id="td-right"><span>Price Basis GAR (S)</span></td>
                                                                            <td>{renderShipmentQty(tcf.price_basis_gar_s)}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td id="td-right"><span>GAR (P)</span>
                                                                                <span id='tooltip' data-tooltip-id="button-tooltips28" data-tooltip-place='right'>
                                                                                    <IoIosHelpCircle />
                                                                                </span>
                                                                                <ReactTooltip
                                                                                    id="button-tooltips28"
                                                                                    place="bottom"
                                                                                    content="GAR (P) - Equivalent GAR (Purchase) - for Reporting Purpose"
                                                                                />  </td>
                                                                            <td>{renderShipmentQty(tcf.gar_p)}</td>
                                                                            <td id="td-right"><span>GAR (S)</span>
                                                                                <span id='tooltip' data-tooltip-id="button-tooltips29" data-tooltip-place='right'>
                                                                                    <IoIosHelpCircle />
                                                                                </span>
                                                                                <ReactTooltip
                                                                                    id="button-tooltips29"
                                                                                    place="bottom"
                                                                                    content="GAR (S) - Equivalent GAR (Sale) - for Reporting Purpose"
                                                                                />  </td>
                                                                            <td>{renderShipmentQty(tcf.gar_s)}</td>
                                                                        </tr>

                                                                    </tbody>
                                                                </table>

                                                                <div className="col-md-12" id="ax.account_details">
                                                                    <h4 className="heading">Bonus / Penalty</h4>
                                                                    <hr></hr>
                                                                </div>

                                                                <table class="table table-bordered account-table tables">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td id="td-right"><span>Sulfur Bonus Rate (MT) (S)</span></td>
                                                                            <td>{renderShipmentQty(tcf.sulfur_bonus_rate_MT_S)}</td>
                                                                            <td id="td-right"><span>Ash Bonus Rate (MT) (S)</span></td>
                                                                            <td>{renderShipmentQty(tcf.ash_bonus_rate_MT_S)}</td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td id="td-right"><span>Sulfur Bonus Amount (S)</span></td>
                                                                            <td>{renderShipmentQty(tcf.sulfur_bonus_amount_s)}</td>
                                                                            <td id="td-right"><span>Ash Bonus Amount (S)</span></td>
                                                                            <td>{renderShipmentQty(tcf.ash_bonus_amount_s)}</td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td id="td-right"><span>Sulfur Penalty Rate (MT) (S)</span></td>
                                                                            <td>{renderShipmentQty(tcf.sulfur_penalty_rate_MT_S)}</td>
                                                                            <td id="td-right"><span>Ash Penalty Rate (MT) (S)</span></td>
                                                                            <td>{renderShipmentQty(tcf.ash_penalty_rate_MT_S)}</td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td id="td-right"><span>Sulfur Penalty Amount (S)</span></td>
                                                                            <td>{renderShipmentQty(tcf.sulfur_penalty_amount_s)}</td>
                                                                            <td id="td-right"><span>Ash Penalty Amount (S)</span></td>
                                                                            <td>{renderShipmentQty(tcf.ash_penalty_amount_s)}</td>
                                                                        </tr>

                                                                    </tbody>
                                                                </table>



                                                                <div className="col-md-12" id="ax.account_details">
                                                                    <h4 className="heading">Cost Sheet Actual</h4>
                                                                    <hr></hr>
                                                                </div>

                                                                <table class="table table-bordered account-table tables">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td id="td-right"><span>FOB Pur Price</span></td>
                                                                            <td>{renderShipmentQty(tcf.fob_pur_price)}</td>
                                                                            <td id="td-right"><span>FOB Sales Price</span></td>
                                                                            <td>{renderShipmentQty(tcf.fob_sales_price)}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td id="td-right"><span>Actual Pur Price</span></td>
                                                                            <td>{renderShipmentQty(tcf.actual_pur_price)}</td>
                                                                            <td id="td-right"><span>Actual Sales Price</span></td>
                                                                            <td>{renderShipmentQty(tcf.actual_sales_price)}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td id="td-right"><span>Actual Pur Amount</span></td>
                                                                            <td>{renderShipmentQty(tcf.actual_pur_amount)}</td>
                                                                            <td id="td-right"><span>Actual Sales</span></td>
                                                                            <td>{renderShipmentQty(tcf.actual_sales)}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td id="td-right"><span>Actual FOB Purchase</span></td>
                                                                            <td>{renderShipmentQty(tcf.actual_fob_purchase)}</td>
                                                                            <td id="td-right"><span>Final Purchase Price</span></td>
                                                                            <td>{renderShipmentQty(tcf.final_purchase_price)}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td id="td-right"><span>Final Sales Price</span></td>
                                                                            <td>{renderShipmentQty(tcf.final_sales_price)}</td>
                                                                            <td id="td-right"><span>Final Purchase Amount</span></td>
                                                                            <td>{renderShipmentQty(tcf.final_purchase_amount)}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td id="td-right"><span>Actual Freight Rate
                                                                            </span></td>
                                                                            <td>{renderShipmentQty(tcf.actual_freight_rate)}</td>
                                                                            <td id="td-right"><span>Actual Freight Amount
                                                                            </span></td>
                                                                            <td>{renderShipmentQty(tcf.actual_freight_amount)}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td id="td-right"><span>Actual Insurance
                                                                            </span></td>
                                                                            <td>{renderShipmentQty(tcf.actual_insurance)}</td>
                                                                            <td id="td-right"><span>Actual Net Margin - Shipment</span></td>
                                                                            <td>{renderShipmentQty(tcf.actual_net_margin_shipment)}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td id="td-right"><span>Actual Commission	</span></td>
                                                                            <td>{renderShipmentQty(tcf.actual_commission)}</td>
                                                                            <td id="td-right"><span>Actual Net Margin - Ton</span></td>
                                                                            <td>{renderShipmentQty(tcf.actual_net_margin_ton)}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td id="td-right"><span>Actual Gross Margin - Shipment</span></td>
                                                                            <td>{renderShipmentQty(tcf.actual_gross_margin_shipment)}</td>
                                                                            <td id="td-right"><span>Actual Gross Margin - Ton</span></td>
                                                                            <td>{renderShipmentQty(tcf.actual_gross_margin_ton)}</td>
                                                                        </tr>



                                                                    </tbody>
                                                                </table>

                                                                <div className="col-md-12" id="ax.account_details">
                                                                    <h4 className="heading">Shipment Cost</h4>
                                                                    <hr></hr>
                                                                </div>

                                                                <table class="table table-bordered account-table tables">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td id="td-right"><span>Actual LC Transfer Cost</span></td>
                                                                            <td>{renderShipmentQty(tcf.actual_lc_transfer_cost)}</td>
                                                                            <td id="td-right"><span>Document Discount Date</span></td>
                                                                            <td>{tcf.document_discount_date}</td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td id="td-right"><span>Actual Bill Handling Charges</span></td>
                                                                            <td>{renderShipmentQty(tcf.actual_bill_handling_charges)}</td>
                                                                            <td id="td-right"><span>Actual Payment Received</span></td>
                                                                            <td><input
                                                                                type='checkbox'
                                                                                name='actual_payment_received'
                                                                                checked={actualCheck} // Use the state variable to determine if the checkbox is checked
                                                                                onChange={handleActual} // Handle the change event
                                                                                className='mx-3' disabled={isButtonEnabled}
                                                                            /></td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td id="td-right"><span>Actual Adding Confirmation Charges</span></td>
                                                                            <td>{renderShipmentQty(tcf.actual_adding_confirmation_charges)}</td>
                                                                            <td id="td-right"><span>Actual Payment Received Date</span></td>
                                                                            <td>{tcf.actual_payment_received_date}</td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td id="td-right"><span>Actual Fixed Other Bank Cost</span></td>
                                                                            <td>{renderShipmentQty(tcf.actual_fixed_other_bank_charges)}</td>
                                                                            <td id="td-right"><span>Bank Interest/ Discounting</span></td>
                                                                            <td>{renderShipmentQty(tcf.bank_interest_or_discounting)}</td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td id="td-right"><span>VAT on Bank Charges</span></td>
                                                                            <td>{renderShipmentQty(tcf.vat_on_bank_charges)}</td>
                                                                            <td id="td-right"><span>Actual Costs</span></td>
                                                                            <td>{renderShipmentQty(tcf.actual_costs)}</td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td id="td-right"><span>Actual Fixed Apportioned Overheads	</span></td>
                                                                            <td>{renderShipmentQty(tcf.actual_fixed_apportioned_overheads)}</td>
                                                                            <td id="td-right"><span>Actual Cost -Ton</span></td>
                                                                            <td>{renderShipmentQty(tcf.actual_cost_ton)}</td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td id="td-right"><span>Actual Contract Specific Cost	</span></td>
                                                                            <td>{renderShipmentQty(tcf.actual_contract_specific_cost)}</td>
                                                                            <td id="td-right"><span>Final Total Cost</span></td>
                                                                            <td>{renderShipmentQty(tcf.final_total_cost)}</td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td id="td-right"><span>Actual Prov For Corporate Tax</span></td>
                                                                            <td>{renderShipmentQty(tcf.actual_prov_for_corporate_tax)}</td>
                                                                            <td id="td-right"><span>Actual Overheads</span></td>
                                                                            <td>{renderShipmentQty(tcf.actual_overheads)}</td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td id="td-right"><span>Actual Withholding Tax</span></td>
                                                                            <td>{renderShipmentQty(tcf.actual_withholding_tax)}</td>
                                                                            <td id="td-right"><span></span></td>
                                                                            <td>{ }</td>
                                                                        </tr>

                                                                    </tbody>
                                                                </table>

                                                                <div className="col-md-12" id="ax.account_details">
                                                                    <h4 className="heading">Debit Note Details</h4>
                                                                    <hr></hr>
                                                                </div>

                                                                <table class="table table-bordered account-table tables">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td id="td-right"><span>Supplier Dem/Despatch
                                                                            </span></td>
                                                                            <td>{tcf.supplier_dem_or_despatch}</td>
                                                                            <td id="td-right"><span>Buyer Dem/Despatch	</span></td>
                                                                            <td>{tcf.buyer_dem_or_despatch}</td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td id="td-right"><span>Supplier Dem/Despatch DN Amount</span></td>
                                                                            <td>{renderShipmentQty(tcf.supplier_dem_or_despatch_DN_amount)}</td>
                                                                            <td id="td-right"><span>Buyer Dem/Despatch DN Amount</span></td>
                                                                            <td>{renderShipmentQty(tcf.buyer_dem_or_despatch_DN_amount)}</td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td id="td-right"><span>supplier DN amount</span></td>
                                                                            <td>{renderShipmentQty(tcf.supplier_DN_amount)}</td>
                                                                            <td id="td-right"><span>buyer DN amount</span></td>
                                                                            <td>{renderShipmentQty(tcf.buyer_DN_amount)}</td>
                                                                        </tr>

                                                                    </tbody>
                                                                </table>

                                                                <div className="col-md-12" id="ax.account_details">
                                                                    <h4 className="heading">Rwood Internal Information</h4>
                                                                    <hr></hr>
                                                                </div>

                                                                <table class="table table-bordered account-table tables">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td id="td-right"><span>any deviation
                                                                            </span></td>
                                                                            <td>{tcf.any_deviation}</td>
                                                                            <td id="td-right"><span>	</span></td>
                                                                            <td></td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td id="td-right"><span>prepared by</span></td>
                                                                            <td>{tcf.prepared_by}</td>

                                                                        </tr>

                                                                        <tr>
                                                                            <td id="td-right"><span>Approved By	</span></td>
                                                                            <td>{tcf.check_and_approved_by}</td>
                                                                            <td id="td-right"><span></span></td>
                                                                            <td>{ }</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td id="td-right"><span>Check and Validated By</span></td>
                                                                            <td>{tcf.validated_by}</td>
                                                                            <td id="td-right"><span></span></td>
                                                                            <td>{ }</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td id="td-right"><span>remarks</span></td>
                                                                            <td>{tcf.remarks}</td>
                                                                            <td id="td-right"><span></span></td>
                                                                            <td>{ }</td>
                                                                        </tr>

                                                                    </tbody>
                                                                </table>

                                                                <Row id="table-styles">
                                                                    <div className="col-md-12" id="head">
                                                                        <h4 className="heading"><span>Rw Payable/ Receivable LineItem </span>
                                                                            <span>
                                                                                <Popup className='post-buyer'
                                                                                    trigger={
                                                                                        <a className="button add-accountrecord">
                                                                                            {" "}
                                                                                            New
                                                                                        </a>
                                                                                    }
                                                                                    modal
                                                                                >
                                                                                    <div className="form-group">
                                                                                        <Form.Check
                                                                                            type='radio'
                                                                                            id=''
                                                                                            name='type'
                                                                                            label='Buyer - Credit Note'
                                                                                            value='Buyer - Credit Note'
                                                                                        />
                                                                                        <Form.Check
                                                                                            type='radio'
                                                                                            id=''
                                                                                            name='type'
                                                                                            label='Supplier - Credit Note'
                                                                                            value='Supplier - Credit Note'
                                                                                        />
                                                                                        <Form.Check
                                                                                            type='radio'
                                                                                            id=''
                                                                                            name='type'
                                                                                            label='Buyer - Debit Note'
                                                                                            value='Buyer - Debit Note'
                                                                                        />
                                                                                        <Form.Check
                                                                                            type='radio'
                                                                                            id=''
                                                                                            name='type'
                                                                                            label='Supplier - Debit Note'
                                                                                            value='Supplier - Debit Note'
                                                                                        />
                                                                                        <Form.Check
                                                                                            type='radio'
                                                                                            id=''
                                                                                            name='type'
                                                                                            label='Load Port Invoice'
                                                                                            value='Load Port Invoice'
                                                                                        />
                                                                                        <Form.Check
                                                                                            type='radio'
                                                                                            id=''
                                                                                            name='type'
                                                                                            label='Other Payable'
                                                                                            value='Other Payable'
                                                                                        />
                                                                                        <Form.Check
                                                                                            type='radio'
                                                                                            id=''
                                                                                            name='type'
                                                                                            label='Other Receivable'
                                                                                            value='Other Receivable'
                                                                                        />
                                                                                        <Form.Check
                                                                                            type='radio'
                                                                                            id=''
                                                                                            name='type'
                                                                                            label='Purchase Invoice'
                                                                                            value='Purchase Invoice'
                                                                                        />
                                                                                        <Form.Check
                                                                                            type='radio'
                                                                                            id=''
                                                                                            name='type'
                                                                                            label='Sales Invoice'
                                                                                            value='Sales Invoice'
                                                                                        />
                                                                                        <Form.Check
                                                                                            type='radio'
                                                                                            id=''
                                                                                            name='type'
                                                                                            label='Surveyor Invoice'
                                                                                            value='Surveyor Invoice'
                                                                                        />

                                                                                    </div>
                                                                                    <input
                                                                                        type="submit"
                                                                                        className="btn btn-primary "
                                                                                        value="Submit"
                                                                                        onClick={handleClick}
                                                                                    />

                                                                                </Popup>
                                                                            </span>

                                                                        </h4>
                                                                        <hr></hr>
                                                                    </div>
                                                                    <table id="rw" className="">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>RW Billing	</th>
                                                                                <th>Document Date	</th>
                                                                                <th>Record Type	</th>
                                                                                <th>Line Item	</th>
                                                                                <th>Account</th>
                                                                                <th>Order No & Vessel Name	</th>
                                                                                <th>Amount</th>
                                                                                <th>Paid / Received Amount	</th>

                                                                                <th>Balance Due	</th>
                                                                                <th>Status</th>                    </tr>
                                                                        </thead>

                                                                        {receivable.length > 0 ? (
                                                                            <tbody>
                                                                                {receivable.map((y, i) => (
                                                                                    <tr>
                                                                                        <td> <Link to={"/tcf/receivable-details/" + y.rw_pay_recieve_id}>
                                                                                            {y.rw_billing}
                                                                                        </Link></td>
                                                                                        <td>{y.document_date}</td>
                                                                                        <td>{y.record_type}</td>
                                                                                        <td>{y.line_item}</td>
                                                                                        <td><Link to={`/accounts/${y.account_id}`}>{y.account}</Link></td>
                                                                                        <td></td>
                                                                                        <td>{renderShipmentQty(y.amount)}</td>
                                                                                        <td>{renderShipmentQty(y.paid_recieved_amount)}</td>
                                                                                        <td>{renderShipmentQty(y.balance_due)}</td>
                                                                                        <td>{y.status}</td>
                                                                                    </tr>
                                                                                ))}
                                                                            </tbody>
                                                                        ) : (
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td></td>

                                                                                    <td>No data available</td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td></td>                      </tr>
                                                                            </tbody>
                                                                        )}
                                                                    </table>
                                                                </Row>
                                                                <Row id="table-styles">
                                                                    <div className="col-md-12" id="head">
                                                                        <h4 className="heading"><span>Rw Payable/ Receipt LineItem </span><span>
                                                                            <Popup className='post-buyer'
                                                                                trigger={
                                                                                    <a className="button add-accountrecord">
                                                                                        {" "}
                                                                                        New
                                                                                    </a>
                                                                                }
                                                                                modal
                                                                            >
                                                                                <div className="form-group" >
                                                                                    <Form.Check
                                                                                        type='radio'
                                                                                        id=''
                                                                                        name='type'
                                                                                        label='Buyer - Credit Note'
                                                                                        value='Buyer - Credit Note'
                                                                                    />
                                                                                    <Form.Check
                                                                                        type='radio'
                                                                                        id=''
                                                                                        name='type'
                                                                                        label='Supplier - Credit Note'
                                                                                        value='Supplier - Credit Note'
                                                                                    />
                                                                                    <Form.Check
                                                                                        type='radio'
                                                                                        id=''
                                                                                        name='type'
                                                                                        label='Buyer - Debit Note'
                                                                                        value='Buyer - Debit Note'
                                                                                    />
                                                                                    <Form.Check
                                                                                        type='radio'
                                                                                        id=''
                                                                                        name='type'
                                                                                        label='Supplier - Debit Note'
                                                                                        value='Supplier - Debit Note'
                                                                                    />
                                                                                    <Form.Check
                                                                                        type='radio'
                                                                                        id=''
                                                                                        name='type'
                                                                                        label='Load Port Invoice'
                                                                                        value='Load Port Invoice'
                                                                                    />
                                                                                    <Form.Check
                                                                                        type='radio'
                                                                                        id=''
                                                                                        name='type'
                                                                                        label='Other Payable'
                                                                                        value='Other Payable'
                                                                                    />
                                                                                    <Form.Check
                                                                                        type='radio'
                                                                                        id=''
                                                                                        name='type'
                                                                                        label='Other Receivable'
                                                                                        value='Other Receivable'
                                                                                    />
                                                                                    <Form.Check
                                                                                        type='radio'
                                                                                        id=''
                                                                                        name='type'
                                                                                        label='Purchase Invoice'
                                                                                        value='Purchase Invoice'
                                                                                    />
                                                                                    <Form.Check
                                                                                        type='radio'
                                                                                        id=''
                                                                                        name='type'
                                                                                        label='Sales Invoice'
                                                                                        value='Sales Invoice'
                                                                                    />
                                                                                    <Form.Check
                                                                                        type='radio'
                                                                                        id=''
                                                                                        name='type'
                                                                                        label='Surveyor Invoice'
                                                                                        value='Surveyor Invoice'
                                                                                    />

                                                                                </div>
                                                                                <input
                                                                                    type="submit"
                                                                                    className="btn btn-primary "
                                                                                    value="Submit"

                                                                                />

                                                                            </Popup>
                                                                        </span>

                                                                        </h4>
                                                                        <hr></hr>
                                                                    </div>
                                                                    <table id="receipt" className="">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Rw payment receipt</th>
                                                                                <th>Payment/Receipt Date</th>
                                                                                <th>Record Type</th>
                                                                                <th>Line Item</th>
                                                                                <th>Vessel Name</th>
                                                                                <th>Amount</th>
                                                                                <th>Account</th>
                                                                                <th>RW Billing</th>
                                                                                <th>Order</th>
                                                                                <th>Description</th>
                                                                            </tr>
                                                                        </thead>

                                                                        {receipt.length > 0 ? (
                                                                            <tbody>
                                                                                {receipt.map((y, i) => (
                                                                                    <tr>
                                                                                        <td><Link to={'/tcf/tcf-receipt-details/' + y.rw_pay_receipt_detail_id}>{y.rw_payment_receipt}</Link></td>
                                                                                        <td>{y.payment_receipt_date}</td>
                                                                                        <td>{y.record_type}</td>
                                                                                        <td>{y.line_item}</td>
                                                                                        <td>{y.vessel_name}</td>
                                                                                        <td>{renderShipmentQty(y.amount)}</td>
                                                                                        <td><Link to={`/accounts/${y.account_id}`}>{y.account}</Link></td>
                                                                                        <td> <Link to={"/tcf/receivable-details/" + y.rw_pay_recieve_id}>
                                                                                            {y.rw_billing}
                                                                                        </Link></td>
                                                                                        <td><Link to={`/order/view-order-details/${y.order_id}`}>{y.order}</Link></td>
                                                                                        <td>{y.description}</td>
                                                                                    </tr>
                                                                                ))}
                                                                            </tbody>
                                                                        ) : (
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td></td><td></td>
                                                                                    <td></td>

                                                                                    <td>No data available</td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                </tr>
                                                                            </tbody>
                                                                        )}
                                                                    </table>
                                                                </Row>
                                                                <Row id="table-styles">
                                                                    <div className="col-md-12" id="head">
                                                                        <h4 className="heading">
                                                                            <span>Trade Confirmation Form History</span>
                                                                        </h4>
                                                                        <hr></hr>
                                                                    </div>
                                                                    <table id="history" className="">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Field Name</th>
                                                                                <th>New Value</th>
                                                                                <th>Old Value</th>
                                                                                <th>Changed By</th>
                                                                                <th>Change Time</th>
                                                                            </tr>
                                                                        </thead>

                                                                        {history.length > 0 ? (
                                                                            <tbody>
                                                                                {history.map((y, i) => (
                                                                                    <tr>
                                                                                        <td>{y.field_name}</td>
                                                                                        <td>{y.new_value}</td>
                                                                                        <td>{y.old_value}</td>
                                                                                        <td>{y.changed_by}</td>
                                                                                        <td>{y.change_time}</td>
                                                                                    </tr>
                                                                                ))}
                                                                            </tbody>
                                                                        ) : (
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td>No data available</td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                </tr>
                                                                            </tbody>
                                                                        )}
                                                                    </table>
                                                                </Row>

                                                                {/*  */}

                                                            </div>
                                                        </div></div></div>
                                                <Footer />
                                            </div>

                                        )}
                                    </>

                                ) : (
                                    <>
                                        {tcf.record_type === 'Supplier - Long Term' ? (
                                            <>
                                                {isEditMode ? (
                                                    <div id="view-page">
                                                        <Header />
                                                        <div className="contactAccount">
                                                            <div className="row">
                                                                <div className="col-md-2 recent-item">
                                                                    <RecentItems />
                                                                </div>
                                                                <div className="col-md-10 right-section">
                                                                    <div className="page-header" id='add-account'>
                                                                        <div>
                                                                            <h2 className="main-content-title tx-24 mg-b-5">TCF Details</h2>
                                                                            <ol className="breadcrumb">
                                                                                <li className="breadcrumb-item active" aria-current="page">Dashboard / TCF Details</li>
                                                                            </ol>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div className="tasks column-gap fulid-section order-section">
                                                                    <div id="messageContainer"></div>
                                                                    <p className="edit-btn">
                                                                        <input
                                                                            type="submit"
                                                                            className="account-save"
                                                                            onClick={handleSaveEdit}
                                                                            value="Save"
                                                                        //disabled={isSubmitted}
                                                                        />
                                                                        <button className="btn btn-primary" onClick={handleCancelEdit}>
                                                                            Cancel
                                                                        </button>
                                                                    </p>

                                                                    <Row className="mb-3">
                                                                        <h4 className="heading">Information</h4>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="TCF no"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control

                                                                                    type="text"
                                                                                    placeholder="TCF no"
                                                                                    name="tcf_no"
                                                                                    defaultValue={tcf.tcf_no || ""} onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <label>Locked</label>
                                                                            <input
                                                                                type='checkbox'
                                                                                name='locked'
                                                                                checked={lockedOne} // Use the state variable to determine if the checkbox is checked
                                                                                onChange={handleCheckboxLocked} // Handle the change event
                                                                                className='mx-3'
                                                                            />
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">

                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="TCF date"
                                                                                className="mb-3 datepick"


                                                                            >
                                                                                <DatePicker
                                                                                    selected={tcfDate}
                                                                                    onChange={handleTcfDate}
                                                                                    selectsStart
                                                                                    dateFormat='dd/MM/yyyy'
                                                                                    placeholderText="Select"
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Record type"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control

                                                                                    type="text"
                                                                                    placeholder="Record type"
                                                                                    name="record_type"
                                                                                    defaultValue={tcf.record_type || ""} onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">

                                                                            <FloatingLabel controlId="floatingSelect"
                                                                                className='dropDown' label="Company">
                                                                                <Form.Select aria-label="Floating label select example"
                                                                                    name="company"
                                                                                    placeholder="Company"
                                                                                    defaultValue={tcf.company || ""}
                                                                                    onChange={handleChangeInput}
                                                                                >
                                                                                    <option value='' >Select</option>
                                                                                    {
                                                                                        companies.map((x) => {
                                                                                            return (
                                                                                                <option value={x.company_name}>{x.company_name}</option>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </Form.Select>

                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Opportunity"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    defaultValue={tcf.opportunity || ""}
                                                                                    onChange={handleChangeInput}
                                                                                    type="text"
                                                                                    placeholder="opportunity"
                                                                                    name="opportunity"
                                                                                    disabled
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">

                                                                            <FloatingLabel controlId="floatingSelect"
                                                                                className='dropDown' label="Currency">
                                                                                <Form.Select aria-label="Floating label select example"
                                                                                    name="currency"
                                                                                    placeholder="Currency"
                                                                                    defaultValue={tcf.currency || ""}
                                                                                    onChange={handleChangeInput}
                                                                                >
                                                                                    <option value='' >Select</option>
                                                                                    <option value='USD' >USD</option>
                                                                                    <option value='IDR' >IDR</option>

                                                                                </Form.Select>

                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="quote"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    placeholder="quote"
                                                                                    name="quote"
                                                                                    defaultValue={tcf.quote || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Exchange rate"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    placeholder="Exchange rate"
                                                                                    name="exchange_rate"
                                                                                    defaultValue={tcf.exchange_rate || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">

                                                                            <FloatingLabel controlId="floatingSelect"
                                                                                className='dropDown' label="Trader Name">
                                                                                <Form.Select aria-label="Floating label select example"
                                                                                    name="trader_name"
                                                                                    placeholder="Trader Name"
                                                                                    defaultValue={tcf.trader_name || ""}
                                                                                    onChange={handleChangeInput}

                                                                                >
                                                                                    <option value='' >Select</option>
                                                                                    {
                                                                                        rwoodusers.map((x) => {
                                                                                            return (
                                                                                                <option value={x.rwood_username}>{x.rwood_username}</option>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </Form.Select>

                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="order"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    placeholder="order"
                                                                                    name="order"
                                                                                    defaultValue={tcf.order || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="owner"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    placeholder="owner"
                                                                                    name="owner"
                                                                                    defaultValue={tcf.owner || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel controlId="floatingSelect" label="Product Master" className="select-dropdown">
                                                                                <Select
                                                                                    className="select"
                                                                                    value={productMaster}
                                                                                    onChange={setProductMaster}
                                                                                    options={options_product}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <label>Auto select pricebook</label>
                                                                            <input
                                                                                type='checkbox'
                                                                                name='auto_select_pricebook'
                                                                                checked={lockeds} // Use the state variable to determine if the checkbox is checked
                                                                                onChange={handleCheckboxChange} // Handle the change event
                                                                                className='mx-3'
                                                                            />
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">

                                                                            <FloatingLabel controlId="floatingSelect"
                                                                                className='dropDown' label="Price book">
                                                                                <Form.Select aria-label="Floating label select example"
                                                                                    name="price_book"
                                                                                    placeholder="Price book"
                                                                                    defaultValue={tcf.price_book || ""}
                                                                                    onChange={handleChangeInput}

                                                                                >
                                                                                    <option value='' >Select</option>
                                                                                    {
                                                                                        priceBook.map((x) => {
                                                                                            return (
                                                                                                <option value={x.price_book_name}>{x.price_book_name}</option>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </Form.Select>

                                                                            </FloatingLabel>
                                                                        </Form.Group>

                                                                        <Form.Group as={Col} md="6">

                                                                            <FloatingLabel controlId="floatingSelect"
                                                                                className='dropDown' label="TCF Status">
                                                                                <Form.Select aria-label="Floating label select example"
                                                                                    name="tcf_status"
                                                                                    placeholder="TCF Status"
                                                                                    defaultValue={tcf.tcf_status || ""}
                                                                                    onChange={handleChangeInput}
                                                                                >
                                                                                    <option value="Draft">Draft</option><option value="Proposed">Proposed</option><option value="Proposed-Long Term Contract">Proposed-Long Term Contract</option><option value="Projected">Projected</option><option value="Actual Shipment Completed">Shipment Done</option><option value="Completed">Completed</option><option value="Cancelled">Z Cancelled</option><option value="Draft - Long Term">Draft - Long Term</option><option value="Shipments - Arutmin">Shipments - Arutmin</option><option value="Cancelled with Penalty">Cancelled with Penalty</option>
                                                                                </Form.Select>

                                                                            </FloatingLabel>
                                                                        </Form.Group>

                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Purchase confirmation date"
                                                                                className="mb-3 datepick"


                                                                            >

                                                                                <DatePicker
                                                                                    selected={purchaseConfirmDate}
                                                                                    onChange={handlePuchaseConfirm}
                                                                                    selectsStart
                                                                                    dateFormat='dd/MM/yyyy'
                                                                                    placeholderText="Select"
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Buyer contract"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    disabled
                                                                                    type="text"
                                                                                    placeholder="Buyer contract"
                                                                                    name="buyer_contract"
                                                                                    defaultValue={tcf.buyer_contract || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6"></Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Contract shipment"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    disabled
                                                                                    placeholder="Contract shipment"
                                                                                    name="contract_shipment"
                                                                                    defaultValue={tcf.contract_shipment || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Product master"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    placeholder="product_master"
                                                                                    name="Product master"
                                                                                    defaultValue={tcf.product_master || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">

                                                                            <FloatingLabel controlId="floatingSelect"
                                                                                className='dropDown' label="Product">
                                                                                <Form.Select aria-label="Floating label select example"
                                                                                    name="product"
                                                                                    placeholder="Product"
                                                                                    defaultValue={tcf.product}
                                                                                    onChange={handleChangeInput}
                                                                                >
                                                                                    <option value='' >Select</option>
                                                                                    {
                                                                                        productName.map((x) => {
                                                                                            return (
                                                                                                <option value={x.product_name}>{x.product_name}</option>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </Form.Select>
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Product name"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    placeholder="Product name"
                                                                                    name="product_name"
                                                                                    defaultValue={tcf.product_name || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                    </Row>
                                                                    {/* <Row className="mb-3">
               <h4 className="heading">Shipment Summary</h4>
               <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="shipment gross margin ton"
                className="mb-3"


              >
                <Form.Control
                  type="text"
                  placeholder="shipment gross margin ton"
                  name="shipment_gross_margin_ton"
                  defaultValue={tcf.shipment_gross_margin_ton || ""}
         onChange={handleChangeInput}
         disabled
                  />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="shipment_sales_price"
                className="mb-3"


              >
                <Form.Control
                  type="text"
                  placeholder="shipment sales price"
                  name="shipment_sales_price"
                  defaultValue={tcf.shipment_sales_price || ""}
         onChange={handleChangeInput}
         disabled
                  />
              </FloatingLabel>
            </Form.Group>

               </Row> */}

                                                                    <Row>
                                                                        <h4 className='heading'>Trade Confirmation Details</h4>
                                                                        <Form.Group as={Col} md="6">

                                                                            <FloatingLabel controlId="floatingSelect"
                                                                                className='dropDown' label="Buyer">
                                                                                <Form.Select aria-label="Floating label select example"
                                                                                    name="buyer"
                                                                                    placeholder="Buyer"
                                                                                    defaultValue={tcf.buyer || ""}
                                                                                    onChange={handleChangeInput}
                                                                                >
                                                                                    <option value='' >Select</option>
                                                                                    {
                                                                                        buyers.map((x) => {
                                                                                            return (
                                                                                                <option value={x.account_name}>{x.account_name}</option>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </Form.Select>
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">

                                                                            <FloatingLabel controlId="floatingSelect"
                                                                                className='dropDown' label="status of purchase contract" >
                                                                                <Form.Select aria-label="Floating label select example"
                                                                                    name="status_of_purchase_contract"
                                                                                    placeholder="status of purchase contract"
                                                                                    defaultValue={tcf.status_of_purchase_contract || ""}
                                                                                    onChange={handleChangeInput}
                                                                                >
                                                                                    <option value="">--None--</option><option value="Draft">Draft</option><option value="Activated">Activated</option><option value="In Approval Process">In Approval Process</option><option value="Under Review">Under Review</option><option value="Signed Contract yet to receive">Signed Contract yet to receive</option><option value="Signed Contract Sent for Sign">Signed Contract Sent for Sign</option><option value="Draft Sent for Signing">Draft Sent for Signing</option><option value="Draft Received for Signing">Draft Received for Signing</option><option value="Signed Original Contract Need to Courier">Signed Original Contract Need to Courier</option><option value="Signed Original Contract yet to Receive">Signed Original Contract yet to Receive</option><option value="Original Contract Signed by Both Parties">Original Contract Signed by Both Parties</option><option value="Original Contract Signed and Received by Both Parties">Original Contract Signed and Received by Both Parties</option>
                                                                                </Form.Select>
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">

                                                                            <FloatingLabel controlId="floatingSelect"
                                                                                className='dropDown' label="Supplier">
                                                                                <Form.Select aria-label="Floating label select example"
                                                                                    name="supplier"
                                                                                    placeholder="Supplier"
                                                                                    defaultValue={tcf.supplier || ""}
                                                                                    onChange={handleChangeInput}

                                                                                >
                                                                                    <option value='' >Select</option>
                                                                                    {
                                                                                        suppliers.map((x) => {
                                                                                            return (
                                                                                                <option value={x.account_name}>{x.account_name}</option>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </Form.Select>
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Product Description"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    as="textarea"
                                                                                    placeholder="Product Description"
                                                                                    name="product_description"
                                                                                    defaultValue={tcf.product_description || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Proposed laycan from"
                                                                                className="mb-3 datepick"


                                                                            >
                                                                                <DatePicker
                                                                                    selected={proposedDates}
                                                                                    onChange={handleProposeDate}
                                                                                    selectsStart
                                                                                    dateFormat='dd/MM/yyyy'
                                                                                    placeholderText="Select"
                                                                                    disabled={true}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Proposed laycan to"
                                                                                className="mb-3 datepick"


                                                                            >
                                                                                <DatePicker
                                                                                    selected={proposedDates1}
                                                                                    onChange={handleProposeDate1}
                                                                                    selectsStart
                                                                                    dateFormat='dd/MM/yyyy'
                                                                                    placeholderText="Select"
                                                                                    disabled={true}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Quantity MT"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    placeholder="Quantity MT"
                                                                                    name="quantity_MT"
                                                                                    defaultValue={tcf.quantity_MT || ""}
                                                                                    onChange={handleChangeInput}

                                                                                />

                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingSelect"
                                                                                className="dropDown"
                                                                                label="price basis"
                                                                            >
                                                                                <Form.Select
                                                                                    aria-label="Floating label select example"
                                                                                    name="price_basis"
                                                                                    placeholder="price basis"
                                                                                    defaultValue={tcf.price_basis || ""}
                                                                                    onChange={handleChangeInput}

                                                                                >
                                                                                    <option value="">--None--</option><option value="GCV (ARB)">GCV (ARB)</option><option value="GCV (ADB)">GCV (ADB)</option><option value="NCV (ARB)">NCV (ARB)</option></Form.Select>

                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingSelect"
                                                                                className="dropDown"
                                                                                label="Vessel Type"
                                                                            >
                                                                                <Form.Select
                                                                                    aria-label="Floating label select example"
                                                                                    name="vessel_type"
                                                                                    defaultValue={tcf.vessel_type || ""}
                                                                                    onChange={handleChangeInput}
                                                                                    placeholder="Vessel Type"
                                                                                >
                                                                                    <option value="">--None--</option><option value="Geared (GnG)">Geared (GnG)</option><option value="Gearless">Gearless</option><option value="Geared">Geared</option><option value="Barge">Barge</option>
                                                                                </Form.Select>

                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Payment term"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    placeholder="Payment term"
                                                                                    name="payment_term"
                                                                                    defaultValue={tcf.payment_term || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>

                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Selling terms"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    placeholder="Selling terms"
                                                                                    name="selling_terms"
                                                                                    defaultValue={tcf.selling_terms || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>

                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Buying terms"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    placeholder="Buying terms"
                                                                                    defaultValue={tcf.buying_terms || ""}
                                                                                    onChange={handleChangeInput}
                                                                                    name="buying_terms"
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>

                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Price adjustment"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    placeholder="Price adjustment"
                                                                                    name="price_adjustment"
                                                                                    defaultValue={tcf.price_adjustment || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                    </Row>
                                                                    <Row>
                                                                        <h4 className='heading'>Contractual</h4>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Contractual GAR S"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    placeholder="Contractual GAR S"
                                                                                    name="contractual_GAR_S"
                                                                                    defaultValue={tcf.contractual_GAR_S || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Contractual GAR P"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    placeholder="Contractual GAR P"
                                                                                    name="contractual_GAR_P"
                                                                                    defaultValue={tcf.contractual_GAR_P || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Contractual TM AR P"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    placeholder="Contractual TM AR P"
                                                                                    name="contractual_TM_AR_P"
                                                                                    defaultValue={tcf.contractual_TM_AR_P || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Contractual TM AR S"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    placeholder="Contractual TM AR S"
                                                                                    name="contractual_TM_AR_S"
                                                                                    defaultValue={tcf.contractual_TM_AR_S || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Contractual Ash AR P"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    placeholder="Contractual Ash AR P"
                                                                                    name="contractual_Ash_AR_P"
                                                                                    defaultValue={tcf.contractual_Ash_AR_P || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>

                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Contractual Ash AR S"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    placeholder="Contractual Ash AR S"
                                                                                    name="contractual_Ash_AR_S"
                                                                                    defaultValue={tcf.contractual_Ash_AR_S || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Contractual Ts ADB P"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    placeholder="Contractual Ts ADB P"
                                                                                    name="contractual_Ts_ADB_P"
                                                                                    defaultValue={tcf.contractual_Ts_ADB_P || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Contractual Ts ADB S"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    placeholder="Contractual Ts ADB S"
                                                                                    name="contractual_Ts_ADB_S"
                                                                                    defaultValue={tcf.contractual_Ts_ADB_S || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Contractual Ash ADB P"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    placeholder="Contractual Ash ADB P"
                                                                                    name="contractual_Ash_ADB_P"
                                                                                    defaultValue={tcf.contractual_Ash_ADB_P || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Contractual Ash ADB S"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    placeholder="Contractual Ash ADB S"
                                                                                    name="contractual_Ash_ADB_S"
                                                                                    defaultValue={tcf.contractual_Ash_ADB_S || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Projected price basis GAR P"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    placeholder="Projected price basis GAR P"
                                                                                    name="projected_price_basis_GAR_P"
                                                                                    defaultValue={tcf.projected_price_basis_GAR_P || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Projected price basis GAR S"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    placeholder="Projected price basis GAR S"
                                                                                    name="projected_price_basis_GAR_S"
                                                                                    defaultValue={tcf.projected_price_basis_GAR_S || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">

                                                                            <FloatingLabel controlId="floatingSelect"
                                                                                className='dropDown' label="contractual HBA year">
                                                                                <Form.Select aria-label="Floating label select example"
                                                                                    name="contractual_HBA_year"
                                                                                    placeholder="contractual HBA year"
                                                                                    defaultValue={tcf.contractual_HBA_year}
                                                                                    onChange={handleChangeInput}
                                                                                >
                                                                                    <option value="">--None--</option>
                                                                                    <option value="2024">2024</option>
                                                                                    <option value="2025">2025</option>
                                                                                    <option value="2026">2026</option>
                                                                                    <option value="2027">2027</option>
                                                                                    <option value="2023">2023</option>
                                                                                    <option value="2022">2022</option>
                                                                                    <option value="2021">2021</option>

                                                                                </Form.Select>
                                                                            </FloatingLabel>
                                                                        </Form.Group>



                                                                        <Form.Group as={Col} md="6">

                                                                            <FloatingLabel controlId="floatingSelect"
                                                                                className='dropDown' label="contractual HBA Month">
                                                                                <Form.Select aria-label="Floating label select example"
                                                                                    name="contractual_HBA_month"
                                                                                    placeholder="contractual HBA Month"
                                                                                    defaultValue={tcf.contractual_HBA_month}
                                                                                    onChange={handleChangeInput}
                                                                                >
                                                                                    <option value="">--None--</option><option value="January">January</option><option value="February">February</option><option value="March">March</option><option value="April">April</option><option value="May">May</option><option value="June">June</option><option value="July">July</option><option value="August">August</option><option value="September">September</option><option value="October">October</option><option value="November">November</option><option value="December">December</option>
                                                                                </Form.Select>
                                                                            </FloatingLabel>
                                                                        </Form.Group>

                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Discounted price"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    placeholder="Discounted price"
                                                                                    name="discounted_price"
                                                                                    defaultValue={tcf.discounted_price || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Projected index value"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    placeholder="Projected index value"
                                                                                    name="projected_index_value"
                                                                                    defaultValue={tcf.projected_index_value || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">

                                                                            <FloatingLabel controlId="floatingSelect"
                                                                                className='dropDown' label="Projected Purchase Type">
                                                                                <Form.Select aria-label="Floating label select example"
                                                                                    name="projected_purchase_type"
                                                                                    placeholder="Projected Purchase Type"
                                                                                    defaultValue={tcf.projected_purchase_type || ""}
                                                                                    onChange={handleChangeInput}

                                                                                >
                                                                                    <option value='' >Select</option>
                                                                                    {
                                                                                        price.map((x) => {
                                                                                            return (
                                                                                                <option value={x.price_type_name}>{x.price_type_name}</option>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </Form.Select>
                                                                            </FloatingLabel>
                                                                        </Form.Group>

                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Projected fixed purchase price"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    placeholder="Projected fixed purchase price"
                                                                                    name="projected_fixed_purchase_price"
                                                                                    defaultValue={tcf.projected_fixed_purchase_price || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Projected purchase price margin"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    placeholder="Projected purchase price margin"
                                                                                    name="projected_purchase_price_margin"
                                                                                    defaultValue={tcf.projected_purchase_price_margin || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Projected barge discount"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    placeholder="Projected barge discount"
                                                                                    name="projected_barge_discount"
                                                                                    defaultValue={tcf.projected_barge_discount || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">

                                                                            <FloatingLabel controlId="floatingSelect"
                                                                                className='dropDown' label="Projected Sale Price Type">
                                                                                <Form.Select aria-label="Floating label select example"
                                                                                    name="projected_sale_price_type"
                                                                                    placeholder="Projected Sale Price Type"
                                                                                    defaultValue={tcf.projected_sale_price_type || ""}
                                                                                    onChange={handleChangeInput}

                                                                                >
                                                                                    <option value='' >Select</option>
                                                                                    {
                                                                                        price.map((x) => {
                                                                                            return (
                                                                                                <option value={x.price_type_name}>{x.price_type_name}</option>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </Form.Select>
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Projected fixed sales price"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    placeholder="Projected fixed sales price"
                                                                                    name="projected_fixed_sales_price"
                                                                                    defaultValue={tcf.projected_fixed_sales_price || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Projected sales price margin"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    placeholder="Projected sales price margin"
                                                                                    name="projected_sales_price_margin"
                                                                                    defaultValue={tcf.projected_sales_price_margin || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                    </Row>
                                                                    <Row>
                                                                        <h4 className='heading'>Cost Sheet Projection</h4>
                                                                        {/* <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Projected Pur Amount"
                className="mb-3"


              >
                <Form.Control
                  type="text"
                  placeholder="Projected Pur Amount"
                  name="projected_pur_amount"
                  defaultValue={tcf.projected_pur_amount || ""}disabled
                  onChange={handleChangeInput}
                 />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="projected sales"
                className="mb-3"


              >
                <Form.Control
                  type="text"
                  placeholder="projected sales"
                  name="projected_sales"
                  defaultValue={tcf.projected_sales || ""} disabled
                  onChange={handleChangeInput}
                 />
              </FloatingLabel>
            </Form.Group>  */}
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="projected fob purchase"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    placeholder="projected fob purchase"
                                                                                    name="projected_fob_purchase"
                                                                                    defaultValue={tcf.projected_fob_purchase || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="projected gross margin shipment"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    placeholder="projected gross margin shipment"
                                                                                    name="projected_gross_margin_shipment"
                                                                                    defaultValue={tcf.projected_gross_margin_shipment || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Projected Freight Rate	"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    placeholder="Projected Freight Rate	"
                                                                                    name="projected_freight_rate"
                                                                                    defaultValue={tcf.projected_freight_rate || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Projected Gross Margin TON"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    placeholder="Projected Gross Margin TON"
                                                                                    name="projected_gross_margin_ton"
                                                                                    defaultValue={tcf.projected_gross_margin_ton || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Projected Freight Amount	"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    placeholder="Projected Freight Amount	"
                                                                                    name="projected_freight_amount"
                                                                                    defaultValue={tcf.projected_freight_amount || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Projected Insurance"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    placeholder="Projected Insurance"
                                                                                    name="projected_insurance"
                                                                                    defaultValue={tcf.projected_insurance || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Projected Net Margin TON	"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    placeholder="Projected Net Margin TON	"
                                                                                    name="projected_net_margin_ton"
                                                                                    defaultValue={tcf.projected_net_margin_ton || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Projected Commission"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    placeholder="Projected Commission"
                                                                                    name="projected_commission"
                                                                                    defaultValue={tcf.projected_commission || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Projected LC Negotiation Cost"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    placeholder="Projected LC Negotiation Cost"
                                                                                    name="projected_lc_negotiation_cost"
                                                                                    defaultValue={tcf.projected_lc_negotiation_cost || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Projected Total Bank Cost"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    placeholder="Projected Total Bank Cost"
                                                                                    name="projected_total_bank_cost"
                                                                                    defaultValue={tcf.projected_total_bank_cost || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>


                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Projected Cost	"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    placeholder="Projected Cost"
                                                                                    name="projected_cost"
                                                                                    defaultValue={tcf.projected_cost || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>  <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Projected Fixed Other Bank Costs"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    placeholder="Projected Fixed Other Bank Costs"
                                                                                    name="projected_fixed_other_bank_costs"
                                                                                    defaultValue={tcf.projected_fixed_other_bank_costs || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>  <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Projected Cost TON"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    placeholder="Projected Cost TON"
                                                                                    name="projected_cost_ton"
                                                                                    defaultValue={tcf.projected_cost_ton || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>  <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Projected Fixed Apportioned Overheads	"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    placeholder="Projected Fixed Apportioned Overheads"
                                                                                    name="projected_fixed_apportioned_overheads"
                                                                                    defaultValue={tcf.projected_fixed_apportioned_overheads || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>  <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Projected Total Cost"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    placeholder="Projected Total Cost"
                                                                                    name="projected_total_cost"
                                                                                    defaultValue={tcf.projected_total_cost || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>  <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Projected Any Contract Specific Cost	"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    placeholder="Projected Any Contract Specific Cost"
                                                                                    name="projected_any_contract_specific_cost"
                                                                                    defaultValue={tcf.projected_any_contract_specific_cost || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>  <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Projected Extra Cost"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    placeholder="Projected Extra Cost"
                                                                                    name="projected_extra_cost"
                                                                                    defaultValue={tcf.projected_extra_cost || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>  <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Projected Provision For Corporate Tax"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    placeholder="Projected Provision For Corporate Tax"
                                                                                    name="projected_provision_for_corporate_tax"
                                                                                    defaultValue={tcf.projected_provision_for_corporate_tax || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>  <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Projected Withholding Tax"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    placeholder="Projected Withholding Tax"
                                                                                    name="projected_withholding_tax"
                                                                                    defaultValue={tcf.projected_withholding_tax || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                    </Row>
                                                                    {/* <Row className="mb-3">
               <h4 className="heading">Actual Shipment Details</h4>
               <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Vessel Name"
                className="mb-3"


              >
                <Form.Control
                  type="text"
                  placeholder="Vessel Name"
                  name="vessel_name"
                  defaultValue={tcf.vessel_name || ""}
                  onChange={handleChangeInput}
                  disabled
                 />
              </FloatingLabel>
            </Form.Group>
               <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Demmurage Rate (P)"
                className="mb-3"


              >
                <Form.Control
                  type="text"
                  placeholder="Demmurage Rate (P)"
                  name="demmurage_rate_p"
                  defaultValue={tcf.demmurage_rate_p || ""}
                  onChange={handleChangeInput} disabled
                 />
              </FloatingLabel>
            </Form.Group>
               <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Stowage Quantity"
                className="mb-3"


              >
                <Form.Control
                  type="text"
                  placeholder="Stowage Quantity"
                  name="stowage_qty"
                  defaultValue={tcf.stowage_qty || ""}
                  onChange={handleChangeInput} disabled
                 />
              </FloatingLabel>
            </Form.Group>
               <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Demmurage Rate (S)"
                className="mb-3"


              >
                <Form.Control
                  type="text"
                  placeholder="Demmurage Rate (S)"
                  name="demmurage_rate_s"
                  defaultValue={tcf.demmurage_rate_s || ""}
                  onChange={handleChangeInput} disabled
                 />
              </FloatingLabel>
            </Form.Group>
               <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Destination"
                className="mb-3"


              >
                <Form.Control
                  type="text"
                  placeholder="Destination"
                  name="destination"
                  defaultValue={tcf.destination || ""}
                  onChange={handleChangeInput} disabled
                 />
              </FloatingLabel>
            </Form.Group>
               <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Load Rate"
                className="mb-3"


              >
                <Form.Control
                  type="text"
                  placeholder="Load Rate"
                  name="load_rate"
                  defaultValue={tcf.load_rate || ""}
                  onChange={handleChangeInput} disabled
                 />
              </FloatingLabel>
            </Form.Group>
               <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Actual BL Date"
                className="mb-3"


              >
                <Form.Control
                  type="text"
                  placeholder="Actual BL Date"
                  name="actual_bl_date"
                  defaultValue={tcf.actual_bl_date || ""}
                  onChange={handleChangeInput} disabled
                 />
              </FloatingLabel>
            </Form.Group>
               <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="PActual BL Qty"
                className="mb-3"


              >
                <Form.Control
                  type="text"
                  placeholder="Actual BL Qty"
                  name="actual_bl_qty"
                  defaultValue={tcf.actual_bl_qty || ""}
                  onChange={handleChangeInput} disabled
                 />
              </FloatingLabel>
            </Form.Group>
               <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="GCNEWC"
                className="mb-3"


              >
                <Form.Control
                  type="text"
                  placeholder="GCNEWC"
                  name="gcnewc"
                  defaultValue={tcf.gcnewc || ""}
                  onChange={handleChangeInput} disabled
                 />
              </FloatingLabel>
            </Form.Group>
               <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Actual HBA"
                className="mb-3"


              >
                <Form.Control
                  type="text"
                  placeholder="Actual HBA"
                  name="actual_hba"
                  defaultValue={tcf.actual_hba || ""}
                  onChange={handleChangeInput} disabled
                 />
              </FloatingLabel>
            </Form.Group>
               <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Actual GCNEWC - Sales"
                className="mb-3"


              >
                <Form.Control
                  type="text"
                  placeholder="Actual GCNEWC - Sales"
                  name="actual_gcnewc_sales"
                  defaultValue={tcf.actual_gcnewc_sales || ""}
                  onChange={handleChangeInput} disabled
                 />
              </FloatingLabel>
            </Form.Group>
               <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Actual HBA - Purchase"
                className="mb-3"


              >
                <Form.Control
                  type="text"
                  placeholder="Actual HBA - Purchase"
                  name="actual_hba_purchase"
                  defaultValue={tcf.actual_hba_purchase || ""}
                  onChange={handleChangeInput} disabled
                 />
              </FloatingLabel>
            </Form.Group>
               <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Laycan"
                className="mb-3"


              >
                <Form.Control
                  type="text"
                  placeholder="Laycan"
                  name="laycan"
                  defaultValue={tcf.laycan || ""}
                  onChange={handleChangeInput} disabled
                 />
              </FloatingLabel>
            </Form.Group>
               <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Expected Value Date"
                className="mb-3"


              >
                <Form.Control
                  type="text"
                  placeholder="Expected Value Date"
                  name="expected_value_date"
                  defaultValue={tcf.expected_value_date || ""}
                  onChange={handleChangeInput} disabled
                 />
              </FloatingLabel>
            </Form.Group>
               </Row> */}
                                                                    <Row>
                                                                        <h4 className='heading'>Actual Cost Elements</h4>

                                                                        <Form.Group as={Col} md="6">

                                                                            <FloatingLabel controlId="floatingSelect"
                                                                                className='dropDown' label="Purchase Price Type">
                                                                                <Form.Select aria-label="Floating label select example"
                                                                                    name="purchase_price_type"
                                                                                    placeholder="Purchase Price Type"
                                                                                    defaultValue={tcf.purchase_price_type || ""}
                                                                                    onChange={handleChangeInput}
                                                                                >
                                                                                    <option value='' >Select</option>
                                                                                    {
                                                                                        price.map((x) => {
                                                                                            return (
                                                                                                <option value={x.price_type_name}>{x.price_type_name}</option>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </Form.Select>

                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <label>Actual Data Rcvd	</label>
                                                                            <input
                                                                                type='checkbox'
                                                                                name='actual_data_rcvd'
                                                                                checked={dataReceived} // Use th e state variable to determine if the checkbox is checked
                                                                                onChange={handleData}// Handle the change event
                                                                                className='mx-3'
                                                                            />
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Fixed purchase price"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    placeholder="Fixed purchase price"
                                                                                    name="fixed_purchase_price"
                                                                                    defaultValue={tcf.fixed_purchase_price || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Purchase price margin"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    placeholder="Purchase price margin"
                                                                                    name="purchase_price_margin"
                                                                                    defaultValue={tcf.purchase_price_margin || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Barge discount"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    placeholder="Barge discount"
                                                                                    name="barge_discount"
                                                                                    defaultValue={tcf.barge_discount || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Projected Pur Amount"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    placeholder="Projected Pur Amount"
                                                                                    name="projected_pur_amount"
                                                                                    defaultValue={tcf.projected_pur_amount || ""} disabled
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>

                                                                        <Form.Group as={Col} md="6">

                                                                            <FloatingLabel controlId="floatingSelect"
                                                                                className='dropDown' label="Sale Price Type">
                                                                                <Form.Select aria-label="Floating label select example"
                                                                                    name="sale_price_type"
                                                                                    placeholder="Sale Price Type"
                                                                                    defaultValue={tcf.sale_price_type || ""}
                                                                                    onChange={handleChangeInput}

                                                                                >
                                                                                    <option value='' >Select</option>
                                                                                    {
                                                                                        price.map((x) => {
                                                                                            return (
                                                                                                <option value={x.price_type_name}>{x.price_type_name}</option>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </Form.Select>

                                                                            </FloatingLabel>
                                                                        </Form.Group>

                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Fixed sale price"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    placeholder="Fixed sale price"
                                                                                    name="fixed_sale_price"
                                                                                    defaultValue={tcf.fixed_sale_price || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Sale price margin"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    placeholder="Sale price margin"
                                                                                    name="sale_price_margin"
                                                                                    defaultValue={tcf.sale_price_margin || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Actual gar p"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    placeholder="Actual gar p"
                                                                                    name="actual_gar_p"
                                                                                    defaultValue={tcf.actual_gar_p || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Actual tm p"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    placeholder="Actual tm p"
                                                                                    name="actual_tm_p"
                                                                                    defaultValue={tcf.actual_tm_p || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Actual ash ar p"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    placeholder="Actual ash ar p"
                                                                                    name="actual_ash_ar_p"
                                                                                    defaultValue={tcf.actual_ash_ar_p || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Actual ts ar p"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    placeholder="Actual ts ar p"
                                                                                    name="actual_ts_ar_p"
                                                                                    defaultValue={tcf.actual_ts_ar_p || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Actual ash adb p"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    placeholder="Actual ash adb p"
                                                                                    name="actual_ash_adb_p"
                                                                                    defaultValue={tcf.actual_ash_adb_p || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Actual ts adb p"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    placeholder="Actual ts adb p"
                                                                                    name="actual_ts_adb_p"
                                                                                    defaultValue={tcf.actual_ts_adb_p || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Actual aft p"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    placeholder="Actual aft p"
                                                                                    name="actual_aft_p"
                                                                                    defaultValue={tcf.actual_aft_p || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Actual vm p"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    placeholder="Actual vm p"
                                                                                    name="actual_vm_p"
                                                                                    defaultValue={tcf.actual_vm_p || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Actual hgi p"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    placeholder="Actual hgi p"
                                                                                    name="actual_hgi_p"
                                                                                    defaultValue={tcf.actual_hgi_p || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Price basis gar p"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    placeholder="Price basis gar p"
                                                                                    name="price_basis_gar_p"
                                                                                    defaultValue={tcf.price_basis_gar_p || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Gar p"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    placeholder="Gar p"
                                                                                    name="gar_p"
                                                                                    defaultValue={tcf.gar_p || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Actual gar s"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    placeholder="Actual gar s"
                                                                                    name="actual_gar_s"
                                                                                    defaultValue={tcf.actual_gar_s || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Actual tm s"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    placeholder="Actual tm s"
                                                                                    name="actual_tm_s"
                                                                                    defaultValue={tcf.actual_tm_s || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Actual ash ar s"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    placeholder="Actual ash ar s"
                                                                                    name="actual_ash_ar_s"
                                                                                    defaultValue={tcf.actual_ash_ar_s || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Actual ts ar s"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    placeholder="Actual ts ar s"
                                                                                    name="actual_ts_ar_s"
                                                                                    defaultValue={tcf.actual_ts_ar_s || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Actual ash adb s"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    placeholder="Actual ash adb s"
                                                                                    name="actual_ash_adb_s"
                                                                                    defaultValue={tcf.actual_ash_adb_s || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Actual ts adb s"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    placeholder="Actual ts adb s"
                                                                                    name="actual_ts_adb_s"
                                                                                    defaultValue={tcf.actual_ts_adb_s || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Actual aft s"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    placeholder="Actual aft s"
                                                                                    name="actual_aft_s"
                                                                                    defaultValue={tcf.actual_aft_s || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Actual vm s"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    placeholder="Actual vm s"
                                                                                    name="actual_vm_s"
                                                                                    defaultValue={tcf.actual_vm_s || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Actual hgi s"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    placeholder="Actual hgi s"
                                                                                    name="actual_hgi_s"
                                                                                    defaultValue={tcf.actual_hgi_s || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Price basis gar s"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    placeholder="Price basis gar s"
                                                                                    name="price_basis_gar_s"
                                                                                    defaultValue={tcf.price_basis_gar_s || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Gar s"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    placeholder="Gar s"
                                                                                    name="gar_s"
                                                                                    defaultValue={tcf.gar_s || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                    </Row>
                                                                    <Row>
                                                                        <h4 className='heading'>Bonus / Penalty</h4>

                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Sulfur Bonus Rate (MT) (S)"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    placeholder="Sulfur Bonus Rate (MT) (S)"
                                                                                    name="sulfur_bonus_rate_MT_S"
                                                                                    defaultValue={tcf.sulfur_bonus_rate_MT_S || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Ash Bonus Rate (MT) (S)"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    placeholder="Ash Bonus Rate (MT) (S)"
                                                                                    name="ash_bonus_rate_MT_S"
                                                                                    defaultValue={tcf.ash_bonus_rate_MT_S || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Sulfur Bonus Amount (S)"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    placeholder="Sulfur Bonus Amount (S)"
                                                                                    name="sulfur_bonus_amount_s"
                                                                                    defaultValue={tcf.sulfur_bonus_amount_s || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Ash Bonus Amount (S)"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    placeholder="Ash Bonus Amount (S)"
                                                                                    name="actual_gcnewc_sales"
                                                                                    defaultValue={tcf.actual_gcnewc_sales || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Ash Bonus Amount (S)"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    placeholder="Ash Bonus Amount (S)"
                                                                                    name="ash_bonus_amount_s"
                                                                                    defaultValue={tcf.ash_bonus_amount_s || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Sulfur Penalty Rate (MT) (S)"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    placeholder="Sulfur Penalty Rate (MT) (S)"
                                                                                    name="sulfur_penalty_rate_MT_S"
                                                                                    defaultValue={tcf.sulfur_penalty_rate_MT_S || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Ash Penalty Rate (MT) (S)	"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    placeholder="Ash Penalty Rate (MT) (S)"
                                                                                    name="ash_penalty_rate_MT_S"
                                                                                    defaultValue={tcf.ash_penalty_rate_MT_S || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Ash Penalty Amount (S)"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    placeholder="Ash Penalty Amount (S)"
                                                                                    name="ash_penalty_amount_s"
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                    </Row>
                                                                    <Row>
                                                                        <h4 className='heading'>Cost Sheet Actual</h4>

                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="FOB Pur Price"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    placeholder="FOB Pur Price"
                                                                                    name="fob_pur_price"
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="FOB Sales Price"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    placeholder="FOB Sales Price"
                                                                                    name="fob_sales_price"
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>   <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Actual Pur Price"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    placeholder="Actual Pur Price"
                                                                                    name="actual_pur_price"
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>   <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Actual Sales Price"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    placeholder="Actual Sales Price"
                                                                                    name="actual_sales_price"
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        {/* <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Actual Pur Amount"
                className="mb-3"


              >
                <Form.Control
                  type="number"
                  placeholder="Actual Pur Amount"
                  name="actual_pur_amount" defaultValue={tcf.actual_pur_amount || ""}
                  onChange={handleChangeInput}
                 />
              </FloatingLabel>
            </Form.Group>  */}
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Actual Sales"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    placeholder="Actual Sales"
                                                                                    name="actual_sales"
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>   <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Actual FOB Purchase"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    placeholder="Actual FOB Purchase"
                                                                                    name="actual_fob_purchase"
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>   <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Final Purchase Price	"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    placeholder="Final Purchase Price	"
                                                                                    name="final_purchase_price"
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>   <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Final Sales Price"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    placeholder="Final Sales Price"
                                                                                    name="final_sales_price"
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>   <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Final Purchase Amount"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    placeholder="Final Purchase Amount"
                                                                                    name="final_purchase_amount"
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Actual Freight Rate"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    placeholder="Actual Freight Rate"
                                                                                    name="actual_freight_rate" defaultValue={tcf.actual_freight_rate || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>

                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Actual Freight Amount"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    placeholder="Actual Freight Amount"
                                                                                    name="actual_freight_amount" defaultValue={tcf.actual_freight_amount || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>   <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Actual Insurance"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    placeholder="Actual Insurance"
                                                                                    name="actual_insurance" defaultValue={tcf.actual_insurance || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>   <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Actual Net Margin - Shipment"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    placeholder="Actual Net Margin - Shipment"
                                                                                    name="actual_net_margin_shipment"
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>   <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Actual Commission"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    placeholder="Actual Commission"
                                                                                    name="actual_commission" defaultValue={tcf.actual_commission || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>   <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Actual Net Margin - Ton"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    placeholder="Actual Net Margin - Ton"
                                                                                    name="actual_net_margin_ton"
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>   <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Actual Gross Margin - Shipment	"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    placeholder="Actual Gross Margin - Shipment	"
                                                                                    name="actual_gross_margin_shipment"
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>   <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Actual Gross Margin - Ton"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    placeholder="Actual Gross Margin - Ton"
                                                                                    name="actual_gross_margin_ton"
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                    </Row>
                                                                    <Row>
                                                                        <h4 className='heading'>Shipment Cost</h4>

                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Actual LC Transfer Cost"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    placeholder="Actual LC Transfer Cost"
                                                                                    name="actual_lc_transfer_cost"
                                                                                    defaultValue={tcf.actual_lc_transfer_cost || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Document Discount Date"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    placeholder="Document Discount Date"
                                                                                    name="document_discount_date"
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Actual Bill Handling Charges"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    placeholder="Actual Bill Handling Charges"
                                                                                    name="actual_bill_handling_charges"
                                                                                    defaultValue={tcf.actual_bill_handling_charges || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        {/* <Form.Group as={Col} md="6">
          <input
          type='checkbox'
          name='actual_payment_received'
          checked={actualCheck} // Use th e state variable to determine if the checkbox is checked
          onChange={handleActual}// Handle the change event
          className='mx-3'
        />
        <label>actual payment received	</label>
        </Form.Group> */}
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Actual Adding Confirmation Charges"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    placeholder="Actual Adding Confirmation Charges"
                                                                                    name="actual_adding_confirmation_charges"
                                                                                    defaultValue={tcf.actual_adding_confirmation_charges || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        {/* <Form.Group as={Col} md="6">
          <input
          type='checkbox'
          name='actual_payment_received'
          checked={actualCheck} // Use th e state variable to determine if the checkbox is checked
          onChange={handleActual}// Handle the change event
          className='mx-3'
        />
        <label>actual payment received	</label>
        </Form.Group> */}
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Actual Fixed Other Bank Cost"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    placeholder="Actual Fixed Other Bank Cost"
                                                                                    name="actual_fixed_other_bank_charges"
                                                                                    defaultValue={tcf.actual_fixed_other_bank_charges || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Bank Interest/ Discounting"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    placeholder="Bank Interest/ Discounting"
                                                                                    name="bank_interest_or_discounting"
                                                                                    defaultValue={tcf.bank_interest_or_discounting || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="VAT On Bank Charges"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    placeholder="VAT On Bank Charges"
                                                                                    name="vat_on_bank_charges"
                                                                                    defaultValue={tcf.vat_on_bank_charges || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Actual Costs"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    placeholder="Actual Costs"
                                                                                    name="actual_costs"
                                                                                    defaultValue={tcf.actual_costs || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Actual Fixed Apportioned Overheads"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    placeholder="Actual Fixed Apportioned Overheads"
                                                                                    name="actual_fixed_apportioned_overheads"
                                                                                    defaultValue={tcf.actual_fixed_apportioned_overheads || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Actual Cost -Ton"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    placeholder="Actual Cost -Ton"
                                                                                    name="actual_cost_ton"
                                                                                    defaultValue={tcf.actual_cost_ton || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Actual Contract Specific Cost"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    placeholder="Actual Contract Specific Cost"
                                                                                    name="actual_contract_specific_cost"
                                                                                    defaultValue={tcf.actual_contract_specific_cost || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6"></Form.Group>
                                                                        {/* <Form.Group as={Col} md="6">
              <FloatingLabel
                controlId="floatingInput"
                label="Final Total Cost	"
                className="mb-3"


              >
                <Form.Control
                  type="number"
                  placeholder="Final Total Cost	"
                  name="final_total_cost"
                 />
              </FloatingLabel>
            </Form.Group>  */}
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Actual Prov For Corporate Tax"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    placeholder="Actual Prov For Corporate Tax"
                                                                                    name="actual_prov_for_corporate_tax"
                                                                                    defaultValue={tcf.actual_prov_for_corporate_tax || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Actual Overheads"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    placeholder="Actual Overheads"
                                                                                    name="actual_overheads"
                                                                                    defaultValue={tcf.actual_overheads || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label=" Actual Withholding Tax"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    placeholder=" Actual Withholding Tax"
                                                                                    name="actual_withholding_tax"
                                                                                    defaultValue={tcf.actual_withholding_tax || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>

                                                                    </Row>

                                                                    <Row>
                                                                        <h4 className='heading'>Debit Note Details</h4>

                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Supplier Dem/Despatch"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    placeholder="Supplier Dem/Despatch"
                                                                                    name="supplier_dem_or_despatch"
                                                                                    defaultValue={tcf.supplier_dem_or_despatch || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Buyer Dem/Despatch"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    placeholder="Buyer Dem/Despatch"
                                                                                    name="buyer_dem_or_despatch"
                                                                                    defaultValue={tcf.buyer_dem_or_despatch || ""}
                                                                                    onChange={handleChangeInput}
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Supplier Dem/Despatch DN Amount"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    placeholder="Supplier Dem/Despatch DN Amount"
                                                                                    name="supplier_dem_or_despatch_DN_amount"
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Buyer Dem/Despatch DN Amount"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    placeholder="Buyer Dem/Despatch DN Amount"
                                                                                    name="buyer_dem_or_despatch_DN_amount"
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>   <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Supplier DN Amount"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    placeholder="Supplier DN Amount"
                                                                                    name="supplier_DN_amount"
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                        <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Supplier DN Amount"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    placeholder="Supplier DN Amount"
                                                                                    name="supplier_DN_amount"
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>    <Form.Group as={Col} md="6">
                                                                            <FloatingLabel
                                                                                controlId="floatingInput"
                                                                                label="Buyer DN Amount"
                                                                                className="mb-3"


                                                                            >
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    placeholder="Buyer DN Amount"
                                                                                    name="buyer_DN_amount"
                                                                                />
                                                                            </FloatingLabel>
                                                                        </Form.Group>
                                                                    </Row>
                                                                    <p className="edit-btn">
                                                                        <input
                                                                            type="submit"
                                                                            className="account-save"
                                                                            onClick={handleSaveEdit}
                                                                            value="Save"
                                                                        //disabled={isSubmitted}
                                                                        />
                                                                        <button className="btn btn-primary" onClick={handleCancelEdit}>
                                                                            Cancel
                                                                        </button>
                                                                    </p>
                                                                </div></div></div>
                                                        <Footer />
                                                    </div>
                                                ) : (
                                                    <div id="view-page">
                                                        <Header />
                                                        <div className="contactAccount">

                                                            <div className="row">
                                                                <div className="col-md-2 recent-item">
                                                                    <RecentItems />
                                                                </div>
                                                                <div className="col-md-10 right-section">
                                                                    <div className="page-header" id="add-account">
                                                                        <div>
                                                                            <h2 className="main-content-title tx-24 mg-b-5">
                                                                                TCF Details
                                                                            </h2>
                                                                            <ol className="breadcrumb">
                                                                                <li className="breadcrumb-item active" aria-current="page">
                                                                                    Dashboard / TCF / TCF Details
                                                                                </li>
                                                                            </ol>
                                                                        </div>
                                                                        <div className="d-flex opd">

                                                                        </div>
                                                                    </div>
                                                                    <div className="tasks column-gap fulid-section order-section">
                                                                        <div className="edit-delete">
                                                                            <span id="edit" onClick={handleEditClick}>
                                                                                Edit
                                                                            </span>
                                                                            <span>
                                                                                <Link to={'/tcf/create-view-list'}> Cancel</Link>
                                                                            </span>
                                                                            <span>
                                                                                Sales Report
                                                                            </span>
                                                                            <span className='excel-download' onClick={tradeConrfirm}>
                                                                                Trade Confirmation Sheet
                                                                            </span>

                                                                        </div>
                                                                        <div className="col-md-12" id="ax.account_details">
                                                                            <h4 className="heading">Information</h4>
                                                                            <hr></hr>
                                                                        </div>
                                                                        <table class="table table-bordered account-table tables">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td id="td-right">
                                                                                        <span>TCF No</span>
                                                                                    </td><td>
                                                                                        {tcf.tcf_no}
                                                                                    </td>
                                                                                    <td id="td-right">
                                                                                        <span>Locked</span>
                                                                                    </td><td>
                                                                                        <input
                                                                                            type='checkbox'
                                                                                            name='locked'
                                                                                            checked={lockedOne} // Use the state variable to determine if the checkbox is checked
                                                                                            onChange={handleCheckboxLocked} // Handle the change event
                                                                                            className='mx-3' disabled={isButtonEnabled}
                                                                                        />
                                                                                    </td>

                                                                                </tr>
                                                                                <tr>
                                                                                    <td id="td-right">
                                                                                        <span>TCF Date </span>
                                                                                    </td><td>
                                                                                        {tcf.tcf_date}
                                                                                    </td>
                                                                                    <td id="td-right">
                                                                                        <span>Record Type</span>
                                                                                    </td><td>
                                                                                        {tcf.record_type}
                                                                                    </td>
                                                                                </tr><tr>
                                                                                    <td id="td-right">
                                                                                        <span>opportunity </span>
                                                                                    </td><td>
                                                                                        <Link to={`/opportunity/opportunity-detail/${tcf.opportunity_id}`}>{tcf.opportunity}</Link>
                                                                                    </td>
                                                                                    <td id="td-right">
                                                                                        <span>opportunity name</span>
                                                                                    </td><td>
                                                                                        <Link to={`/opportunity/opportunity-detail/${tcf.opportunity_id}`}>{tcf.opportunity_name}</Link>
                                                                                    </td>
                                                                                </tr>{" "}
                                                                                <tr>
                                                                                    <td id="td-right">
                                                                                        Company
                                                                                    </td><td>
                                                                                        <Link to={`/company/company-details/${tcf.rwoodcompany_id}`}><span>{tcf.company} </span></Link>
                                                                                    </td>
                                                                                    <td id="td-right">
                                                                                        <span>Currency</span>
                                                                                    </td><td>
                                                                                        {tcf.currency}
                                                                                    </td>

                                                                                </tr>
                                                                                <tr>
                                                                                    <td id="td-right">
                                                                                        <span>Quote</span>
                                                                                    </td><td>
                                                                                        <Link to={`/opportunity/quote-view/${tcf.offer_id}`}>{tcf.quote}</Link>
                                                                                    </td>
                                                                                    <td id="td-right">
                                                                                        <span>Exchange Rate </span>
                                                                                    </td><td>
                                                                                        {tcf.exchange_rate}
                                                                                    </td>
                                                                                </tr><tr>
                                                                                    <td id="td-right">
                                                                                        <span>trader name</span>
                                                                                    </td><td>
                                                                                        <Link to={`/user/user-details/${tcf.rwoodusers_id}`}>{tcf.trader_name}</Link>
                                                                                    </td>
                                                                                    <td id="td-right">
                                                                                        <span>order</span>
                                                                                    </td><td>
                                                                                        <Link to={`/order/view-order-details/${tcf.rwood_order_id}`}>{tcf.order}</Link>
                                                                                    </td>
                                                                                </tr>{" "}
                                                                                <tr>
                                                                                    <td id="td-right">
                                                                                        <span>owner </span>
                                                                                    </td><td>
                                                                                        {tcf.owner}
                                                                                    </td>

                                                                                    <td id="td-right">
                                                                                        <span>Rwood Bank Master</span>
                                                                                    </td><td>
                                                                                        <Link to={`/bank/bank-details/${tcf.rwoodbank_id}`}>{tcf.rwood_bank_master}</Link>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>

                                                                                    <td id="td-right">
                                                                                        <span className="tooltips-label">Auto Select Pricebook</span>
                                                                                        <span id='tooltip' data-tooltip-id="button-tooltip" data-tooltip-place='right'>
                                                                                            <IoIosHelpCircle />
                                                                                        </span>
                                                                                        <ReactTooltip
                                                                                            id="button-tooltip"
                                                                                            place="bottom"
                                                                                            content="If Checked Pricebook will be based on Month of BL Date Of Order If Unchecked Price book will be based on TCF.Pricebook field"
                                                                                        />
                                                                                    </td><td>
                                                                                        <input
                                                                                            type='checkbox'
                                                                                            name='auto_select_pricebook'
                                                                                            checked={lockeds} // Use the state variable to determine if the checkbox is checked
                                                                                            onChange={handleCheckboxChange} // Handle the change event
                                                                                            className='mx-3'
                                                                                            disabled={isButtonEnabled}
                                                                                        />
                                                                                    </td>
                                                                                    <td id="td-right">
                                                                                        <span>Pricebook</span>
                                                                                    </td><td>
                                                                                        <Link to={`/pricebook/pricebook-details/${tcf.price_book_id}`}>{tcf.price_book}</Link>
                                                                                    </td>
                                                                                </tr><tr>
                                                                                    <td id="td-right">
                                                                                        <span>TCF Status</span>
                                                                                    </td><td>
                                                                                        {tcf.tcf_status}
                                                                                    </td>
                                                                                    <td id="td-right">
                                                                                        <span>Purchase Confirmation Date</span>
                                                                                        <span id='tooltip' data-tooltip-id="button-tooltips" data-tooltip-place='right'>
                                                                                            <IoIosHelpCircle />
                                                                                        </span>
                                                                                        <ReactTooltip
                                                                                            id="button-tooltips"
                                                                                            place="bottom"
                                                                                            content="Date on which the purchase was confirmed"
                                                                                        />
                                                                                    </td><td>
                                                                                        {tcf.purchase_confirmation_date}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td id="td-right">
                                                                                        <span>Buyer Contract</span>
                                                                                    </td><td>
                                                                                        <Link to={`/contract/contract-details/${tcf.contract_id}`}>{tcf.buyer_contract}</Link>
                                                                                    </td>
                                                                                    <td id="td-right">
                                                                                        <span>Contract Shipment</span>
                                                                                    </td><td>
                                                                                        <Link to={`/shipment/contract-shipment-details/${tcf.contract_ship_id}`}>{tcf.contract_shipment}</Link>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td id="td-right">
                                                                                        <span className="tooltips-label">Product Master</span>
                                                                                        <span id='tooltip' data-tooltip-id="button-tooltip1" data-tooltip-place='right'>
                                                                                            <IoIosHelpCircle />
                                                                                        </span>
                                                                                        <ReactTooltip
                                                                                            id="button-tooltip1"
                                                                                            place="bottom"
                                                                                            content="Product Master"
                                                                                        />

                                                                                    </td><td>
                                                                                        <Link to={`/productmaster/product-detail/${tcf.product_id}`}>{tcf.product_master}</Link>
                                                                                    </td>
                                                                                    <td id="td-right">
                                                                                        <span>Product</span>
                                                                                    </td><td>
                                                                                        <Link to={`/product/product-detail/${tcf.new_products_id}`}>{tcf.product}</Link>
                                                                                    </td>
                                                                                </tr>

                                                                                <tr>
                                                                                    <td id="td-right">
                                                                                        <span>Product Name</span>
                                                                                    </td><td>
                                                                                        {tcf.product_name}
                                                                                    </td>
                                                                                    <td>

                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>

                                                                        <div className="col-md-12" id="ax.account_details">
                                                                            <h4 className="heading">Shipment Quantity</h4>
                                                                            <hr></hr>
                                                                        </div>
                                                                        <table class="table table-bordered account-table tables">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td id="td-right">
                                                                                        <span className="tooltips-label">Shipment Qty</span>
                                                                                        <span id='tooltip' data-tooltip-id="button-tooltip2" data-tooltip-place='right'>
                                                                                            <IoIosHelpCircle />
                                                                                        </span>
                                                                                        <ReactTooltip
                                                                                            id="button-tooltip2"
                                                                                            place="bottom"
                                                                                            content="IF (ISNULL( A_BL_Quantity__c ),  IF(ISNULL(Stowage_Qty__c), Contract_Quantity_MT__c  , Stowage_Qty__c),  A_BL_Quantity__c )"
                                                                                        />

                                                                                    </td><td>
                                                                                        {renderShipmentQty(tcf.shipment_qty)}
                                                                                    </td>
                                                                                    <td id="td-right">
                                                                                        <span className="tooltips-label">Shipment Month</span>
                                                                                        <span id='tooltip' data-tooltip-id="button-tooltip3" data-tooltip-place='right'>
                                                                                            <IoIosHelpCircle />
                                                                                        </span>
                                                                                        <ReactTooltip
                                                                                            id="button-tooltip3"
                                                                                            place="bottom"
                                                                                            content="text(MONTH(Ship_Month__c )) &amp; &quot; &quot; &amp; text (Year (Ship_Month__c))"
                                                                                        />
                                                                                    </td><td>
                                                                                        {tcf.shipment_month}
                                                                                    </td>

                                                                                </tr>
                                                                                <tr>
                                                                                    <td id="td-right">
                                                                                        <span>shipment purchase price</span>
                                                                                    </td><td>
                                                                                        {renderShipmentQty(tcf.shipment_purchase_price)}
                                                                                    </td>
                                                                                    <td id="td-right">
                                                                                        <span className="tooltips-label">shipment sales price</span>
                                                                                        <span id='tooltip' data-tooltip-id="button-tooltip4" data-tooltip-place='right'>
                                                                                            <IoIosHelpCircle />
                                                                                        </span>
                                                                                        <ReactTooltip
                                                                                            id="button-tooltip4"
                                                                                            place="bottom"
                                                                                            content="text(MONTH(Ship_Month__c )) &amp; &quot; &quot; &amp; text (Year (Ship_Month__c))"
                                                                                        />
                                                                                    </td><td>
                                                                                        {renderShipmentQty(tcf.shipment_sales_price)}
                                                                                    </td>
                                                                                </tr><tr>
                                                                                    <td id="td-right">
                                                                                        <span>shipment purchase </span>
                                                                                    </td><td>
                                                                                        {renderShipmentQty(tcf.shipment_purchase)}
                                                                                    </td>
                                                                                    <td id="td-right">
                                                                                        <span>shipment sales</span>
                                                                                    </td><td>
                                                                                        {renderShipmentQty(tcf.shipment_sales)}
                                                                                    </td>
                                                                                </tr>{" "}
                                                                                <tr>
                                                                                    <td id="td-right">
                                                                                        <span className="tooltips-label">shipment gross margin ton</span>
                                                                                        <span id='tooltip' data-tooltip-id="button-tooltip7" data-tooltip-place='right'>
                                                                                            <IoIosHelpCircle />
                                                                                        </span>
                                                                                        <ReactTooltip
                                                                                            id="button-tooltip7"
                                                                                            place="bottom"
                                                                                            content="Workflow Rule IF (ISBLANK(Actual_Gross_Margin_Ton__c ), Projected_Gross_Margin_Ton__c , Actual_Gross_Margin_Ton__c "
                                                                                        />
                                                                                    </td><td>
                                                                                        {renderShipmentQty(tcf.shipment_gross_margin_ton)}
                                                                                    </td>
                                                                                    <td id="td-right">
                                                                                        <span className="tooltips-label">shipment gross margin</span>
                                                                                        <span id='tooltip' data-tooltip-id="button-tooltip5" data-tooltip-place='right'>
                                                                                            <IoIosHelpCircle />
                                                                                        </span>
                                                                                        <ReactTooltip
                                                                                            id="button-tooltip5"
                                                                                            place="bottom"
                                                                                            content="Shipment_Gross_Margin_Ton__c \* Shipment_Qty__c"
                                                                                        />
                                                                                    </td><td>
                                                                                        {renderShipmentQty(tcf.shipment_gross_margin)}
                                                                                    </td>

                                                                                </tr>

                                                                            </tbody>
                                                                        </table>
                                                                        <div className="col-md-12" id="ax.account_details">
                                                                            <h4 className="heading">Trade Confirmation Details</h4>
                                                                            <hr></hr>
                                                                        </div>
                                                                        <table class="table table-bordered account-table tables">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td id="td-right"><span>Buyer</span></td>
                                                                                    <td><Link to={`/accounts/${tcf.account_id}`}>{tcf.buyer}</Link></td>
                                                                                    <td id="td-right"><span>Status of Buyer Contract</span></td>
                                                                                    <td>{tcf.status_of_buyer_contract}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td id="td-right"><span>Supplier</span></td>
                                                                                    <td><Link to={`/accounts/${tcf.supplier_id}`}>{tcf.supplier}</Link></td>
                                                                                    <td id="td-right"><span>Status of purchase contract</span></td>
                                                                                    <td>{tcf.status_of_purchase_contract}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td id="td-right"><span>Product Description</span></td>
                                                                                    <td>{tcf.product_description}</td>
                                                                                    <td id="td-right">
                                                                                        <span className="tooltips-label">ship Month</span>
                                                                                        <span id='tooltip' data-tooltip-id="button-tooltip8" data-tooltip-place='right'>
                                                                                            <IoIosHelpCircle />
                                                                                        </span>
                                                                                        <ReactTooltip
                                                                                            id="button-tooltip8"
                                                                                            place="bottom"
                                                                                            content="Laycan From Date"
                                                                                        />
                                                                                    </td>
                                                                                    <td>{tcf.ship_month}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td id="td-right"><span>Product Specification</span></td>
                                                                                    <td>{tcf.product_specification}</td>
                                                                                    <td id="td-right"><span>Quantity MT</span></td>
                                                                                    <td>{renderShipmentQty(tcf.quantity_MT)}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td id="td-right"><span>Proposed Laycan From</span></td>
                                                                                    <td>{tcf.proposed_laycan_from}</td>
                                                                                    <td id="td-right"><span>Proposed Laycan To</span></td>
                                                                                    <td>{tcf.proposed_laycan_to}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td id="td-right"><span>Price Basis</span></td>
                                                                                    <td>{tcf.price_basis}</td>
                                                                                    <td id="td-right"><span>Vessel Type</span></td>
                                                                                    <td>{tcf.vessel_type}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td id="td-right"><span>Payment Terms</span></td>
                                                                                    <td>{tcf.payment_term}</td>
                                                                                    <td id="td-right"><span>Selling Terms</span></td>
                                                                                    <td>{tcf.selling_terms}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td id="td-right"><span>Price Adjustment</span></td>
                                                                                    <td>{tcf.price_adjustment}</td>
                                                                                    <td id="td-right"><span>Buying Terms</span></td>
                                                                                    <td>{tcf.buying_terms}</td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>

                                                                        <div className="col-md-12" id="ax.account_details">
                                                                            <h4 className="heading">Contractual</h4>
                                                                            <hr></hr>
                                                                        </div>
                                                                        <table class="table table-bordered account-table tables">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td id="td-right"><span>Contractual GAR (P)</span></td>
                                                                                    <td>{renderShipmentQty(tcf.contractual_GAR_P)}</td>
                                                                                    <td id="td-right"><span>Contractual GAR (S)</span></td>
                                                                                    <td>{renderShipmentQty(tcf.contractual_GAR_S)}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td id="td-right"><span>Contractual TM (AR) (P)</span></td>
                                                                                    <td>{renderShipmentQty(tcf.contractual_TM_AR_P)}</td>
                                                                                    <td id="td-right"><span>Contractual TM (AR) (S)</span></td>
                                                                                    <td>{renderShipmentQty(tcf.contractual_TM_AR_S)}</td>
                                                                                </tr>
                                                                                <tr>

                                                                                    <td id="td-right"><span>Contractual TS (AR) (P)</span></td>
                                                                                    <td>{renderShipmentQty(tcf.contractual_Ts_AR_p)}</td>
                                                                                    <td id="td-right"><span>Contractual TS (AR) (S)</span></td>
                                                                                    <td>{renderShipmentQty(tcf.contractual_Ts_AR_s)}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td id="td-right"><span>Contractual Ash (AR) (P)</span></td>
                                                                                    <td>{renderShipmentQty(tcf.contractual_Ash_AR_P)}</td>
                                                                                    <td id="td-right"><span>Contractual Ash (AR) (S)</span></td>
                                                                                    <td>{renderShipmentQty(tcf.contractual_Ash_AR_S)}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td id="td-right"><span>Contractual TS (ADB) (P)</span></td>
                                                                                    <td>{renderShipmentQty(tcf.contractual_Ts_ADB_P)}</td>
                                                                                    <td id="td-right"><span>Contractual TS (ADB) (S)</span></td>
                                                                                    <td>{renderShipmentQty(tcf.contractual_Ts_ADB_S)}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td id="td-right"><span>Contractual Ash (AR) (S)</span></td>
                                                                                    <td>{renderShipmentQty(tcf.contractual_Ash_AR_S)}</td>
                                                                                    <td id="td-right"><span>Projected Price Basis (GAR) (P)</span></td>
                                                                                    <td>{renderShipmentQty(tcf.projected_price_basis_GAR_P)}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td id="td-right"><span>Contractual HBA Year</span></td>
                                                                                    <td>{tcf.contractual_HBA_year}</td>
                                                                                    <td id="td-right"> <span className="tooltips-label">Discounted Price</span>
                                                                                        <span id='tooltip' data-tooltip-id="button-tooltip11" data-tooltip-place='right'>
                                                                                            <IoIosHelpCircle />
                                                                                        </span>
                                                                                        <ReactTooltip
                                                                                            id="button-tooltip11"
                                                                                            place="bottom"
                                                                                            content="Discounted Price as per Vessel Type \r\u003Cbr\u003EIf Geared (GNG) - 0.90\r\u003Cbr\u003Eif Grearless - 0.92"
                                                                                        /> </td>
                                                                                    <td>{renderShipmentQty(tcf.discounted_price)}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td id="td-right"><span>Contractual HBA Month</span></td>
                                                                                    <td>{tcf.contractual_HBA_month}</td>
                                                                                    <td id="td-right">  <span className="tooltips-label">Projected HBA</span>
                                                                                        <span id='tooltip' data-tooltip-id="button-tooltip10" data-tooltip-place='right'>
                                                                                            <IoIosHelpCircle />
                                                                                        </span>
                                                                                        <ReactTooltip
                                                                                            id="button-tooltip10"
                                                                                            place="bottom"
                                                                                            content="Projected HBA - Derrived from Opportunity.Pricebook.Projected HBA (Contractual_HBA)"
                                                                                        /> </td>
                                                                                    <td>{renderShipmentQty(tcf.projected_hba)}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td id="td-right">  <span className="tooltips-label">G1/G2  </span>
                                                                                        <span id='tooltip' data-tooltip-id="button-tooltip12" data-tooltip-place='right'>
                                                                                            <IoIosHelpCircle />
                                                                                        </span>
                                                                                        <ReactTooltip
                                                                                            id="button-tooltip12"
                                                                                            place="bottom"
                                                                                            content="( Actual_GAR_P__c \*  GCNEWC__c  / 6322 ) \*  Discounted_Price__c')"
                                                                                        /> </td>
                                                                                    <td>{tcf.g1_g2}</td>
                                                                                    <td id="td-right"><span>Projected Index Value</span></td>
                                                                                    <td>{renderShipmentQty(tcf.projected_index_value)}</td>
                                                                                </tr>

                                                                                <tr>
                                                                                    <td id="td-right"><span>Projected Purchase Price Type</span></td>
                                                                                    <td>{tcf.projected_purchase_type}</td>
                                                                                    <td id="td-right"><span>Projected Sale Price Type</span></td>
                                                                                    <td>{tcf.projected_sale_price_type}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td id="td-right"><span>Projected Fixed Purchase Price</span></td>
                                                                                    <td>{renderShipmentQty(tcf.projected_fixed_purchase_price)}</td>
                                                                                    <td id="td-right"><span>Projected Fixed Sales Price</span></td>
                                                                                    <td>{renderShipmentQty(tcf.projected_fixed_sales_price)}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td id="td-right"><span>Projected Purchase Price Margin</span></td>
                                                                                    <td>{renderShipmentQty(tcf.projected_purchase_price_margin)}</td>
                                                                                    <td id="td-right"><span>Projected Sales Price Margin</span></td>
                                                                                    <td>{renderShipmentQty(tcf.projected_sales_price_margin)}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td id="td-right"><span>Projected Fixed Purchase Price</span></td>
                                                                                    <td>{renderShipmentQty(tcf.projected_fixed_purchase_price)}</td>
                                                                                    <td id="td-right"><span>Projected Barge Discount</span></td>
                                                                                    <td>{renderShipmentQty(tcf.barge_discount)}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td id="td-right"><span>Projected Purchase Type</span></td>
                                                                                    <td>{tcf.projected_purchase_type}</td>
                                                                                    <td id="td-right">  <span className="tooltips-label">Contractual Pur Price</span>
                                                                                        <span id='tooltip' data-tooltip-id="button-tooltip14" data-tooltip-place='right'>
                                                                                            <IoIosHelpCircle />
                                                                                        </span>
                                                                                        <ReactTooltip
                                                                                            id="button-tooltip14"
                                                                                            place="bottom"
                                                                                            content="HPB=(Contractual_HBA\*C_GAR_P)/ 6322)\*( (100-TM_AR)/(100-8)))-(((ContractualTS(ARB)-0.8)\*4)+((Contractual_Ash-15)\*0.4)))\r\u003Cbr\u003EFixed price=ProjFixed_Purchase_Price\*C_GAR P /Proj Price_Basis_GAR P\r\u003Cbr\u003EGCNEWC=Proj. GCNEWC\*CGAR P/ProjectPrice Basis GAR P + ProjMargin"
                                                                                        /> </td>
                                                                                    <td>{renderShipmentQty(tcf.contractual_pur_price)}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td id="td-right"><span>Projected Sales Price</span></td>
                                                                                    <td>{renderShipmentQty(tcf.projected_sales_price)}</td>
                                                                                    <td id="td-right"><span>Contractual Margin</span></td>
                                                                                    <td>{renderShipmentQty(tcf.contractual_margin)}</td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>

                                                                        <div className="col-md-12" id="ax.account_details">
                                                                            <h4 className="heading">Cost Sheet Projection</h4>
                                                                            <hr></hr>
                                                                        </div>

                                                                        <table class="table table-bordered account-table tables">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td id="td-right"><span>projected pur amount</span>
                                                                                        <span id='tooltip' data-tooltip-id="button-tooltips1" data-tooltip-place='right'>
                                                                                            <IoIosHelpCircle />
                                                                                        </span>
                                                                                        <ReactTooltip
                                                                                            id="button-tooltips1"
                                                                                            place="bottom"
                                                                                            content="Work Flow Rule - (Contract_Quantity_MT \* round(Contractual_Pur_Price,2))"
                                                                                        />  </td>
                                                                                    <td>{renderShipmentQty(tcf.projected_pur_amount)}</td>
                                                                                    <td id="td-right"><span>projected sales</span>
                                                                                        <span id='tooltip' data-tooltip-id="button-tooltips2" data-tooltip-place='right'>
                                                                                            <IoIosHelpCircle />
                                                                                        </span>
                                                                                        <ReactTooltip
                                                                                            id="button-tooltips2"
                                                                                            place="bottom"
                                                                                            content="Workflow Rules - Projected_Sales_Price__c \* Contract_Quantity_MT__c'"
                                                                                        />  </td>
                                                                                    <td>{renderShipmentQty(tcf.projected_sales)}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td id="td-right"><span>projected fob purchase</span>
                                                                                        <span id='tooltip' data-tooltip-id="button-tooltips3" data-tooltip-place='right'>
                                                                                            <IoIosHelpCircle />
                                                                                        </span>
                                                                                        <ReactTooltip
                                                                                            id="button-tooltips3"
                                                                                            place="bottom"
                                                                                            content="Projected Pur Amount"
                                                                                        />  </td>
                                                                                    <td>{renderShipmentQty(tcf.projected_fob_purchase)}</td>
                                                                                    <td id="td-right"><span>projected gross margin shipment</span>
                                                                                        <span id='tooltip' data-tooltip-id="button-tooltips4" data-tooltip-place='right'>
                                                                                            <IoIosHelpCircle />
                                                                                        </span>
                                                                                        <ReactTooltip
                                                                                            id="button-tooltips4"
                                                                                            place="bottom"
                                                                                            content="Projected_Sales__c - Projected_FOB_Purchase__c - Projected_Freight_Amount__c -  Projected_Insurance__c - Projected_Commision__c"
                                                                                        />  </td>
                                                                                    <td>{renderShipmentQty(tcf.projected_gross_margin_shipment)}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td id="td-right"><span>projected freight rate</span></td>
                                                                                    <td>{renderShipmentQty(tcf.projected_freight_rate)}</td>
                                                                                    <td id="td-right"><span>projected gross margin TON</span>
                                                                                        <span id='tooltip' data-tooltip-id="button-tooltips5" data-tooltip-place='right'>
                                                                                            <IoIosHelpCircle />
                                                                                        </span>
                                                                                        <ReactTooltip
                                                                                            id="button-tooltips5"
                                                                                            place="bottom"
                                                                                            content="Projected_Gross_Margin_Shipment__c /  Contract_Quantity_MT__c"
                                                                                        />  </td>
                                                                                    <td>{renderShipmentQty(tcf.projected_gross_margin_ton)}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td id="td-right"><span>projected freight amount</span></td>
                                                                                    <td>{renderShipmentQty(tcf.projected_freight_amount)}</td>
                                                                                    <td id="td-right"><span>projected insurance</span></td>
                                                                                    <td>{renderShipmentQty(tcf.projected_insurance)}</td>
                                                                                </tr>
                                                                                <tr>

                                                                                    <td id="td-right"><span>projected net margin TON</span></td>
                                                                                    <td>{renderShipmentQty(tcf.projected_net_margin_ton)}</td>
                                                                                    <td id="td-right"><span>projected commission</span></td>
                                                                                    <td>{renderShipmentQty(tcf.projected_commission)}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td id="td-right"><span>projected  LC negotiation cost</span>
                                                                                        <span id='tooltip' data-tooltip-id="button-tooltips8" data-tooltip-place='right'>
                                                                                            <IoIosHelpCircle />
                                                                                        </span>
                                                                                        <ReactTooltip
                                                                                            id="button-tooltips8"
                                                                                            place="bottom"
                                                                                            content="Projected_Sales__c \* Rwood_Bank_Master__r.LC_Negotiation_Rate__c Adding Confirmation Charges"
                                                                                        />  </td>
                                                                                    <td>{renderShipmentQty(tcf.projected_lc_negotiation_cost)}</td>
                                                                                    <td id="td-right"><span>projected total bank cost</span>
                                                                                        <span id='tooltip' data-tooltip-id="button-tooltips6" data-tooltip-place='right'>
                                                                                            <IoIosHelpCircle />
                                                                                        </span>
                                                                                        <ReactTooltip
                                                                                            id="button-tooltips6"
                                                                                            place="bottom"
                                                                                            content="Projected_LC_Transfer_Cost__c + \r\u003Cbr\u003EProjected_LC_Negotiation_Cost__c + \r\u003Cbr\u003EProjected_Fixed_Other_Bank_Costs__c + \r\u003Cbr\u003EProjected_Adding_Confirmation_Charges__c"
                                                                                        />  </td>
                                                                                    <td>{renderShipmentQty(tcf.projected_total_bank_cost)}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td id="td-right"><span>projected cost</span>
                                                                                        <span id='tooltip' data-tooltip-id="button-tooltips7" data-tooltip-place='right'>
                                                                                            <IoIosHelpCircle />
                                                                                        </span>
                                                                                        <ReactTooltip
                                                                                            id="button-tooltips7"
                                                                                            place="bottom"
                                                                                            content="Pj_LC_Transfer_Cost+Pj_LC_Nego_Cost+Pj_Fixed_Other_Bank_Costs+Pj_Any_Contract_Spe_Cost+Pj_Fixed_Appo_Overheads+Pj_Adding_Conf_Charges+Pj_Extra_Cost+Pj_Freight_Amt+Pj. Insurance+Pj_Prov_for_corp Tax+Pj_Commission+PJ_Witholding Tax"
                                                                                        />  </td>
                                                                                    <td>{renderShipmentQty(tcf.projected_cost)}</td>
                                                                                    <td id="td-right"><span>projected fixed other bank costs</span>
                                                                                        <span id='tooltip' data-tooltip-id="button-tooltips10" data-tooltip-place='right'>
                                                                                            <IoIosHelpCircle />
                                                                                        </span>
                                                                                        <ReactTooltip
                                                                                            id="button-tooltips10"
                                                                                            place="bottom"
                                                                                            content="Rwood_Bank_Master__r.Fixed_Other_Cost__c \* Contract_Quantity_MT__c"
                                                                                        />  </td>
                                                                                    <td>{renderShipmentQty(tcf.projected_fixed_other_bank_costs)}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td id="td-right"><span>projected cost TON</span></td>
                                                                                    <td>{renderShipmentQty(tcf.projected_cost_ton)}</td>
                                                                                    <td id="td-right"><span>projected fixed apportioned overheads</span></td>
                                                                                    <td>{renderShipmentQty(tcf.projected_fixed_apportioned_overheads)}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td id="td-right"><span>projected total cost</span></td>
                                                                                    <td>{renderShipmentQty(tcf.projected_total_cost)}</td>
                                                                                    <td id="td-right"><span>projected any contract specific cost</span></td>
                                                                                    <td>{renderShipmentQty(tcf.projected_any_contract_specific_cost)}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td id="td-right"><span>projected extra  cost</span></td>
                                                                                    <td>{renderShipmentQty(tcf.projected_extra_cost)}</td>
                                                                                    <td id="td-right"><span>projected provision for corporate tax</span>
                                                                                        <span id='tooltip' data-tooltip-id="button-tooltips11" data-tooltip-place='right'>
                                                                                            <IoIosHelpCircle />
                                                                                        </span>
                                                                                        <ReactTooltip
                                                                                            id="button-tooltips11"
                                                                                            place="bottom"
                                                                                            content="1.5% Revenue"
                                                                                        />  </td>
                                                                                    <td>{renderShipmentQty(tcf.projected_provision_for_corporate_tax)}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td id="td-right"><span>projected withholding tax</span>
                                                                                        <span id='tooltip' data-tooltip-id="button-tooltips12" data-tooltip-place='right'>
                                                                                            <IoIosHelpCircle />
                                                                                        </span>
                                                                                        <ReactTooltip
                                                                                            id="button-tooltips12"
                                                                                            place="bottom"
                                                                                            content="IF ( Company__c= PT RRI , Projected_Sales__c \* Rwood_Bank_Master__r.Tax__c, 0)"
                                                                                        />  </td>
                                                                                    <td>{renderShipmentQty(tcf.projected_withholding_tax)}</td>
                                                                                    <td id="td-right"><span></span></td>
                                                                                    <td></td>
                                                                                </tr>

                                                                            </tbody>
                                                                        </table>
                                                                        <div className="col-md-12" id="ax.account_details">
                                                                            <h4 className="heading">Actual Shipment Details</h4>
                                                                            <hr></hr>
                                                                        </div>

                                                                        <table class="table table-bordered account-table tables">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td id="td-right"><span>vessel name</span>
                                                                                        <span id='tooltip' data-tooltip-id="button-tooltips13" data-tooltip-place='right'>
                                                                                            <IoIosHelpCircle />
                                                                                        </span>
                                                                                        <ReactTooltip
                                                                                            id="button-tooltips13"
                                                                                            place="bottom"
                                                                                            content="if Barge name is BLANK - Order__r.Vessel_Name__c OR Vessel Name &amp; Barge Name"
                                                                                        />  </td>
                                                                                    <td>{tcf.vessel_name}</td>
                                                                                    <td id="td-right"><span>demmurage rate (P)</span>
                                                                                        <span id='tooltip' data-tooltip-id="button-tooltips14" data-tooltip-place='right'>
                                                                                            <IoIosHelpCircle />
                                                                                        </span>
                                                                                        <ReactTooltip
                                                                                            id="button-tooltips14"
                                                                                            place="bottom"
                                                                                            content="Order__r.S_Despatch_Demmurage_Rate__c"
                                                                                        />  </td>
                                                                                    <td>{renderShipmentQty(tcf.demmurage_rate_p)}</td>
                                                                                </tr>

                                                                                <tr>
                                                                                    <td id="td-right"><span>stowage quantity</span>
                                                                                        <span id='tooltip' data-tooltip-id="button-tooltips15" data-tooltip-place='right'>
                                                                                            <IoIosHelpCircle />
                                                                                        </span>
                                                                                        <ReactTooltip
                                                                                            id="button-tooltips15"
                                                                                            place="bottom"
                                                                                            content="Order__r.Pre_stowage__c"
                                                                                        />  </td>
                                                                                    <td>{renderShipmentQty(tcf.stowage_qty)}</td>
                                                                                    <td id="td-right"><span>demmurage rate (S)</span>
                                                                                        <span id='tooltip' data-tooltip-id="button-tooltips16" data-tooltip-place='right'>
                                                                                            <IoIosHelpCircle />
                                                                                        </span>
                                                                                        <ReactTooltip
                                                                                            id="button-tooltips16"
                                                                                            place="bottom"
                                                                                            content="Order__r.B_Despatch_Demmurage_Rate__c"
                                                                                        />  </td>
                                                                                    <td>{renderShipmentQty(tcf.demmurage_rate_s)}</td>
                                                                                </tr>

                                                                                <tr>
                                                                                    <td id="td-right"><span>Destination</span>
                                                                                        <span id='tooltip' data-tooltip-id="button-tooltips17" data-tooltip-place='right'>
                                                                                            <IoIosHelpCircle />
                                                                                        </span>
                                                                                        <ReactTooltip
                                                                                            id="button-tooltips17"
                                                                                            place="bottom"
                                                                                            content="Order. Destination or Buyer contract.destination."
                                                                                        />  </td>
                                                                                    <td>{tcf.destination}</td>
                                                                                    <td id="td-right"><span>Load Rate</span>
                                                                                        <span id='tooltip' data-tooltip-id="button-tooltips18" data-tooltip-place='right'>
                                                                                            <IoIosHelpCircle />
                                                                                        </span>
                                                                                        <ReactTooltip
                                                                                            id="button-tooltips18"
                                                                                            place="bottom"
                                                                                            content="Order__r.Loading_Rate__c"
                                                                                        />  </td>
                                                                                    <td>{renderShipmentQty(tcf.load_rate)}</td>
                                                                                </tr>

                                                                                <tr>
                                                                                    <td id="td-right"><span>Actual BL Date</span>
                                                                                        <span id='tooltip' data-tooltip-id="button-tooltips19" data-tooltip-place='right'>
                                                                                            <IoIosHelpCircle />
                                                                                        </span>
                                                                                        <ReactTooltip
                                                                                            id="button-tooltips19"
                                                                                            place="bottom"
                                                                                            content="Order__r.Loading_Rate__c"
                                                                                        />  </td>
                                                                                    <td>{tcf.actual_bl_date}</td>
                                                                                    <td id="td-right"><span>Actual BL Qty</span>
                                                                                        <span id='tooltip' data-tooltip-id="button-tooltips20" data-tooltip-place='right'>
                                                                                            <IoIosHelpCircle />
                                                                                        </span>
                                                                                        <ReactTooltip
                                                                                            id="button-tooltips20"
                                                                                            place="bottom"
                                                                                            content="Order.Total BL Quantity"
                                                                                        />  </td>
                                                                                    <td>{renderShipmentQty(tcf.actual_bl_qty)}</td>
                                                                                </tr>

                                                                                <tr>
                                                                                    <td id="td-right"><span>GCNEWC</span>
                                                                                        <span id='tooltip' data-tooltip-id="button-tooltips21" data-tooltip-place='right'>
                                                                                            <IoIosHelpCircle />
                                                                                        </span>
                                                                                        <ReactTooltip
                                                                                            id="button-tooltips21"
                                                                                            place="bottom"
                                                                                            content="pricebook.GCNEWC"
                                                                                        />  </td>
                                                                                    <td>{renderShipmentQty(tcf.gcnewc)}</td>
                                                                                    <td id="td-right"><span>Actual HBA</span>
                                                                                        <span id='tooltip' data-tooltip-id="button-tooltips22" data-tooltip-place='right'>
                                                                                            <IoIosHelpCircle />
                                                                                        </span>
                                                                                        <ReactTooltip
                                                                                            id="button-tooltips22"
                                                                                            place="bottom"
                                                                                            content="Price Book.Actual HBA"
                                                                                        />  </td>
                                                                                    <td>{renderShipmentQty(tcf.actual_hba)}</td>
                                                                                </tr> <tr>
                                                                                    <td id="td-right"><span>Actual GCNEWC - Sales</span>
                                                                                        <span id='tooltip' data-tooltip-id="button-tooltips23" data-tooltip-place='right'>
                                                                                            <IoIosHelpCircle />
                                                                                        </span>
                                                                                        <ReactTooltip
                                                                                            id="button-tooltips23"
                                                                                            place="bottom"
                                                                                            content="CASE(Sale_Price_Type, '(Avg Prev Qtr GCNEWC + Margin)  GCV Adj', Price_Book__r.Avg_GCNEWC_Prev_QTR__c , '(Avg Curr Qtr GCNEWC + Margin) GCV Adj',  Price_Book__r.Avg_GCNEWC_Current_QTR__c , 'Avg Prev Qtr GCNEWC + Margin',  Price_Book__r.Av"
                                                                                        />  </td>
                                                                                    <td>{renderShipmentQty(tcf.actual_gcnewc_sales)}</td>
                                                                                    <td id="td-right"><span>Actual HBA - Purchase</span>
                                                                                        <span id='tooltip' data-tooltip-id="button-tooltips24" data-tooltip-place='right'>
                                                                                            <IoIosHelpCircle />
                                                                                        </span>
                                                                                        <ReactTooltip
                                                                                            id="button-tooltips24"
                                                                                            place="bottom"
                                                                                            content="CASE ( Purchase_Price_Type.Name , 'Avg Prev Qtr HBA + Margin', Price_Book__r.Avg_HBA_Prev_QTR__c, 'Avg Current Qtr HBA + Margin', 'Price_Book__r.Avg_HBA_Current_QTR__c , 'Price_Book__r.Actual_HBA__c)"
                                                                                        />  </td>
                                                                                    <td>{renderShipmentQty(tcf.actual_hba_purchase)}</td>
                                                                                </tr> <tr>
                                                                                    <td id="td-right"><span>Laycan</span>
                                                                                        <span id='tooltip' data-tooltip-id="button-tooltips25" data-tooltip-place='right'>
                                                                                            <IoIosHelpCircle />
                                                                                        </span>
                                                                                        <ReactTooltip
                                                                                            id="button-tooltips25"
                                                                                            place="bottom"
                                                                                            content="Text(Day (Order__r.Laycan_From_Date__c)) & / TEXT(Month (Order__r.Laycan_From_Date__c)) & - & TExt(Day ( Order__r.Laycan_To_Date__c )) & / TEXT(Month(Order__r.Laycan_To_Date__c))  & '' & TEXT(year (Order__r.Laycan_From_Date__c))"
                                                                                        />  </td>
                                                                                    <td>{tcf.laycan}</td>
                                                                                    <td id="td-right"><span>Expected Value Date</span> <span id='tooltip' data-tooltip-id="button-tooltips26" data-tooltip-place='right'>
                                                                                        <IoIosHelpCircle />
                                                                                    </span>
                                                                                        <ReactTooltip
                                                                                            id="button-tooltips26"
                                                                                            place="bottom"
                                                                                            content="Order__r.all Expected_Value_Date__c"
                                                                                        />  </td>
                                                                                    <td>{tcf.expected_value_date}</td>
                                                                                </tr>

                                                                            </tbody>
                                                                        </table>


                                                                        <div className="col-md-12" id="ax.account_details">
                                                                            <h4 className="heading">Actual Cost Elements</h4>
                                                                            <hr></hr>
                                                                        </div>

                                                                        <table class="table table-bordered account-table tables">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td id="td-right"><span>Purchase Price Type</span></td>
                                                                                    <td>{tcf.purchase_price_type}</td>
                                                                                    <td id="td-right"><span>Actual Data Rcvd</span></td>
                                                                                    <td><input
                                                                                        type='checkbox'
                                                                                        name='actual_data_rcvd'
                                                                                        checked={dataReceived} // Use th e state variable to determine if the checkbox is checked
                                                                                        onChange={handleData}
                                                                                        className='mx-3' disabled={isButtonEnabled}
                                                                                    /></td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td id="td-right"><span>fixed purchase price</span></td>
                                                                                    <td>{tcf.fixed_purchase_price}</td>
                                                                                    <td id="td-right"><span>Sale Price Type</span></td>
                                                                                    <td>{tcf.sale_price_type}</td>

                                                                                </tr>
                                                                                <tr>
                                                                                    <td id="td-right"><span>Purchase Price Margin</span></td>
                                                                                    <td>{tcf.purchase_price_margin}</td>
                                                                                    <td id="td-right"><span>Fixed Sale Price</span></td>
                                                                                    <td>{tcf.fixed_sale_price}</td>

                                                                                </tr>
                                                                                <tr>
                                                                                    <td id="td-right"><span>Barge Discount</span>
                                                                                        <span id='tooltip' data-tooltip-id="button-tooltips27" data-tooltip-place='right'>
                                                                                            <IoIosHelpCircle />
                                                                                        </span>
                                                                                        <ReactTooltip
                                                                                            id="button-tooltips27"
                                                                                            place="bottom"
                                                                                            content="Barge Discount if applicable"
                                                                                        />  </td>
                                                                                    <td>{renderShipmentQty(tcf.barge_discount)}</td>
                                                                                    <td id="td-right"><span>Sales Price Margin</span></td>
                                                                                    <td>{tcf.sale_price_margin}</td>
                                                                                </tr>
                                                                                <tr>

                                                                                    <td id="td-right"><span>Actual GAR (P)</span></td>
                                                                                    <td>{renderShipmentQty(tcf.actual_gar_p)}</td>
                                                                                    <td id="td-right"><span>Actual GAR (s)</span></td>
                                                                                    <td>{renderShipmentQty(tcf.actual_gar_s)}</td>
                                                                                </tr>
                                                                                <tr>

                                                                                    <td id="td-right"><span>Actual TM (AR) (P)</span></td>
                                                                                    <td>{renderShipmentQty(tcf.actual_tm_p)}</td>
                                                                                    <td id="td-right"><span>Actual TM (AR) (S)</span></td>
                                                                                    <td>{renderShipmentQty(tcf.actual_tm_s)}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td id="td-right"><span>Actual Ash (AR) (P)</span></td>
                                                                                    <td>{renderShipmentQty(tcf.actual_ash_ar_p)}</td>
                                                                                    <td id="td-right"><span>Actual Ash (AR) (S)</span></td>
                                                                                    <td>{renderShipmentQty(tcf.actual_ash_ar_s)}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td id="td-right"><span>Actual TS (AR) (P)</span></td>
                                                                                    <td>{renderShipmentQty(tcf.actual_ts_ar_p)}</td>
                                                                                    <td id="td-right"><span>Actual TS (AR) (S)</span></td>
                                                                                    <td>{renderShipmentQty(tcf.actual_ts_ar_s)}</td>
                                                                                </tr>
                                                                                <tr>

                                                                                    <td id="td-right"><span>Actual Ash (ADB) (P)</span></td>
                                                                                    <td>{renderShipmentQty(tcf.actual_ash_adb_p)}</td>
                                                                                    <td id="td-right"><span>Actual Ash (ADB) (S)</span></td>
                                                                                    <td>{renderShipmentQty(tcf.actual_ash_adb_s)}</td>

                                                                                </tr>
                                                                                <tr>

                                                                                    <td id="td-right"><span>Actual TS (ADB) (P)</span></td>
                                                                                    <td>{renderShipmentQty(tcf.actual_ts_adb_p)}</td>
                                                                                    <td id="td-right"><span>Actual TS (ADB) (S)</span></td>
                                                                                    <td>{renderShipmentQty(tcf.actual_ts_adb_s)}</td>
                                                                                </tr>
                                                                                <tr>

                                                                                    <td id="td-right"><span>Actual AFT (P)</span></td>
                                                                                    <td>{renderShipmentQty(tcf.actual_aft_p)}</td>
                                                                                    <td id="td-right"><span>Actual AFT (S)</span></td>
                                                                                    <td>{renderShipmentQty(tcf.actual_aft_s)}</td>
                                                                                </tr>
                                                                                <tr>

                                                                                    <td id="td-right"><span>Actual VM (P)</span></td>
                                                                                    <td>{renderShipmentQty(tcf.actual_vm_p)}</td>
                                                                                    <td id="td-right"><span>Actual VM (S)</span></td>
                                                                                    <td>{renderShipmentQty(tcf.actual_vm_s)}</td>
                                                                                </tr>
                                                                                <tr>

                                                                                    <td id="td-right"><span>Actual HGI (P)</span></td>
                                                                                    <td>{renderShipmentQty(tcf.actual_hgi_p)}</td>
                                                                                    <td id="td-right"><span>Actual HGI (S)</span></td>
                                                                                    <td>{renderShipmentQty(tcf.actual_hgi_s)}</td>
                                                                                </tr>
                                                                                <tr>

                                                                                    <td id="td-right"><span>Price Basis GAR (P)</span></td>
                                                                                    <td>{renderShipmentQty(tcf.price_basis_gar_p)}</td>
                                                                                    <td id="td-right"><span>Price Basis GAR (S)</span></td>
                                                                                    <td>{renderShipmentQty(tcf.price_basis_gar_s)}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td id="td-right"><span>GAR (P)</span>
                                                                                        <span id='tooltip' data-tooltip-id="button-tooltips28" data-tooltip-place='right'>
                                                                                            <IoIosHelpCircle />
                                                                                        </span>
                                                                                        <ReactTooltip
                                                                                            id="button-tooltips28"
                                                                                            place="bottom"
                                                                                            content="GAR (P) - Equivalent GAR (Purchase) - for Reporting Purpose"
                                                                                        />  </td>
                                                                                    <td>{renderShipmentQty(tcf.gar_p)}</td>
                                                                                    <td id="td-right"><span>GAR (S)</span>
                                                                                        <span id='tooltip' data-tooltip-id="button-tooltips29" data-tooltip-place='right'>
                                                                                            <IoIosHelpCircle />
                                                                                        </span>
                                                                                        <ReactTooltip
                                                                                            id="button-tooltips29"
                                                                                            place="bottom"
                                                                                            content="GAR (S) - Equivalent GAR (Sale) - for Reporting Purpose"
                                                                                        />  </td>
                                                                                    <td>{renderShipmentQty(tcf.gar_s)}</td>
                                                                                </tr>

                                                                            </tbody>
                                                                        </table>

                                                                        <div className="col-md-12" id="ax.account_details">
                                                                            <h4 className="heading">Bonus / Penalty</h4>
                                                                            <hr></hr>
                                                                        </div>

                                                                        <table class="table table-bordered account-table tables">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td id="td-right"><span>Sulfur Bonus Rate (MT) (S)</span></td>
                                                                                    <td>{renderShipmentQty(tcf.sulfur_bonus_rate_MT_S)}</td>
                                                                                    <td id="td-right"><span>Ash Bonus Rate (MT) (S)</span></td>
                                                                                    <td>{renderShipmentQty(tcf.ash_bonus_rate_MT_S)}</td>
                                                                                </tr>

                                                                                <tr>
                                                                                    <td id="td-right"><span>Sulfur Bonus Amount (S)</span></td>
                                                                                    <td>{renderShipmentQty(tcf.sulfur_bonus_amount_s)}</td>
                                                                                    <td id="td-right"><span>Ash Bonus Amount (S)</span></td>
                                                                                    <td>{renderShipmentQty(tcf.ash_bonus_amount_s)}</td>
                                                                                </tr>

                                                                                <tr>
                                                                                    <td id="td-right"><span>Sulfur Penalty Rate (MT) (S)</span></td>
                                                                                    <td>{renderShipmentQty(tcf.sulfur_penalty_rate_MT_S)}</td>
                                                                                    <td id="td-right"><span>Ash Penalty Rate (MT) (S)</span></td>
                                                                                    <td>{renderShipmentQty(tcf.ash_penalty_rate_MT_S)}</td>
                                                                                </tr>

                                                                                <tr>
                                                                                    <td id="td-right"><span>Sulfur Penalty Amount (S)</span></td>
                                                                                    <td>{renderShipmentQty(tcf.sulfur_penalty_amount_s)}</td>
                                                                                    <td id="td-right"><span>Ash Penalty Amount (S)</span></td>
                                                                                    <td>{renderShipmentQty(tcf.ash_penalty_amount_s)}</td>
                                                                                </tr>

                                                                            </tbody>
                                                                        </table>



                                                                        <div className="col-md-12" id="ax.account_details">
                                                                            <h4 className="heading">Cost Sheet Actual</h4>
                                                                            <hr></hr>
                                                                        </div>

                                                                        <table class="table table-bordered account-table tables">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td id="td-right"><span>FOB Pur Price</span></td>
                                                                                    <td>{renderShipmentQty(tcf.fob_pur_price)}</td>
                                                                                    <td id="td-right"><span>FOB Sales Price</span></td>
                                                                                    <td>{renderShipmentQty(tcf.fob_sales_price)}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td id="td-right"><span>Actual Pur Price</span></td>
                                                                                    <td>{renderShipmentQty(tcf.actual_pur_price)}</td>
                                                                                    <td id="td-right"><span>Actual Sales Price</span></td>
                                                                                    <td>{renderShipmentQty(tcf.actual_sales_price)}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td id="td-right"><span>Actual Pur Amount</span></td>
                                                                                    <td>{renderShipmentQty(tcf.actual_pur_amount)}</td>
                                                                                    <td id="td-right"><span>Actual Sales</span></td>
                                                                                    <td>{renderShipmentQty(tcf.actual_sales)}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td id="td-right"><span>Actual FOB Purchase</span></td>
                                                                                    <td>{renderShipmentQty(tcf.actual_fob_purchase)}</td>
                                                                                    <td id="td-right"><span>Final Purchase Price</span></td>
                                                                                    <td>{renderShipmentQty(tcf.final_purchase_price)}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td id="td-right"><span>Final Sales Price</span></td>
                                                                                    <td>{renderShipmentQty(tcf.final_sales_price)}</td>
                                                                                    <td id="td-right"><span>Final Purchase Amount</span></td>
                                                                                    <td>{renderShipmentQty(tcf.final_purchase_amount)}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td id="td-right"><span>Actual Freight Rate
                                                                                    </span></td>
                                                                                    <td>{renderShipmentQty(tcf.actual_freight_rate)}</td>
                                                                                    <td id="td-right"><span>Actual Freight Amount
                                                                                    </span></td>
                                                                                    <td>{renderShipmentQty(tcf.actual_freight_amount)}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td id="td-right"><span>Actual Insurance
                                                                                    </span></td>
                                                                                    <td>{renderShipmentQty(tcf.actual_insurance)}</td>
                                                                                    <td id="td-right"><span>Actual Net Margin - Shipment</span></td>
                                                                                    <td>{renderShipmentQty(tcf.actual_net_margin_shipment)}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td id="td-right"><span>Actual Commission	</span></td>
                                                                                    <td>{renderShipmentQty(tcf.actual_commission)}</td>
                                                                                    <td id="td-right"><span>Actual Net Margin - Ton</span></td>
                                                                                    <td>{renderShipmentQty(tcf.actual_net_margin_ton)}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td id="td-right"><span>Actual Gross Margin - Shipment</span></td>
                                                                                    <td>{renderShipmentQty(tcf.actual_gross_margin_shipment)}</td>
                                                                                    <td id="td-right"><span>Actual Gross Margin - Ton</span></td>
                                                                                    <td>{renderShipmentQty(tcf.actual_gross_margin_ton)}</td>
                                                                                </tr>



                                                                            </tbody>
                                                                        </table>

                                                                        <div className="col-md-12" id="ax.account_details">
                                                                            <h4 className="heading">Shipment Cost</h4>
                                                                            <hr></hr>
                                                                        </div>

                                                                        <table class="table table-bordered account-table tables">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td id="td-right"><span>Actual LC Transfer Cost</span></td>
                                                                                    <td>{renderShipmentQty(tcf.actual_lc_transfer_cost)}</td>
                                                                                    <td id="td-right"><span>Document Discount Date</span></td>
                                                                                    <td>{tcf.document_discount_date}</td>
                                                                                </tr>

                                                                                <tr>
                                                                                    <td id="td-right"><span>Actual Bill Handling Charges</span></td>
                                                                                    <td>{renderShipmentQty(tcf.actual_bill_handling_charges)}</td>
                                                                                    <td id="td-right"><span>Actual Payment Received</span></td>
                                                                                    <td><input
                                                                                        type='checkbox'
                                                                                        name='actual_payment_received'
                                                                                        checked={actualCheck} // Use the state variable to determine if the checkbox is checked
                                                                                        onChange={handleActual} // Handle the change event
                                                                                        className='mx-3' disabled={isButtonEnabled}
                                                                                    /></td>
                                                                                </tr>

                                                                                <tr>
                                                                                    <td id="td-right"><span>Actual Adding Confirmation Charges</span></td>
                                                                                    <td>{renderShipmentQty(tcf.actual_adding_confirmation_charges)}</td>
                                                                                    <td id="td-right"><span>Actual Payment Received Date</span></td>
                                                                                    <td>{tcf.actual_payment_received_date}</td>
                                                                                </tr>

                                                                                <tr>
                                                                                    <td id="td-right"><span>Actual Fixed Other Bank Cost</span></td>
                                                                                    <td>{renderShipmentQty(tcf.actual_fixed_other_bank_charges)}</td>
                                                                                    <td id="td-right"><span>Bank Interest/ Discounting</span></td>
                                                                                    <td>{renderShipmentQty(tcf.bank_interest_or_discounting)}</td>
                                                                                </tr>

                                                                                <tr>
                                                                                    <td id="td-right"><span>VAT on Bank Charges</span></td>
                                                                                    <td>{renderShipmentQty(tcf.vat_on_bank_charges)}</td>
                                                                                    <td id="td-right"><span>Actual Costs</span></td>
                                                                                    <td>{renderShipmentQty(tcf.actual_costs)}</td>
                                                                                </tr>

                                                                                <tr>
                                                                                    <td id="td-right"><span>Actual Fixed Apportioned Overheads	</span></td>
                                                                                    <td>{renderShipmentQty(tcf.actual_fixed_apportioned_overheads)}</td>
                                                                                    <td id="td-right"><span>Actual Cost -Ton</span></td>
                                                                                    <td>{renderShipmentQty(tcf.actual_cost_ton)}</td>
                                                                                </tr>

                                                                                <tr>
                                                                                    <td id="td-right"><span>Actual Contract Specific Cost	</span></td>
                                                                                    <td>{renderShipmentQty(tcf.actual_contract_specific_cost)}</td>
                                                                                    <td id="td-right"><span>Final Total Cost</span></td>
                                                                                    <td>{renderShipmentQty(tcf.final_total_cost)}</td>
                                                                                </tr>

                                                                                <tr>
                                                                                    <td id="td-right"><span>Actual Prov For Corporate Tax</span></td>
                                                                                    <td>{renderShipmentQty(tcf.actual_prov_for_corporate_tax)}</td>
                                                                                    <td id="td-right"><span>Actual Overheads</span></td>
                                                                                    <td>{renderShipmentQty(tcf.actual_overheads)}</td>
                                                                                </tr>

                                                                                <tr>
                                                                                    <td id="td-right"><span>Actual Withholding Tax</span></td>
                                                                                    <td>{renderShipmentQty(tcf.actual_withholding_tax)}</td>
                                                                                    <td id="td-right"><span></span></td>
                                                                                    <td>{ }</td>
                                                                                </tr>

                                                                            </tbody>
                                                                        </table>

                                                                        <div className="col-md-12" id="ax.account_details">
                                                                            <h4 className="heading">Debit Note Details</h4>
                                                                            <hr></hr>
                                                                        </div>

                                                                        <table class="table table-bordered account-table tables">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td id="td-right"><span>Supplier Dem/Despatch
                                                                                    </span></td>
                                                                                    <td>{tcf.supplier_dem_or_despatch}</td>
                                                                                    <td id="td-right"><span>Buyer Dem/Despatch	</span></td>
                                                                                    <td>{tcf.buyer_dem_or_despatch}</td>
                                                                                </tr>

                                                                                <tr>
                                                                                    <td id="td-right"><span>Supplier Dem/Despatch DN Amount</span></td>
                                                                                    <td>{renderShipmentQty(tcf.supplier_dem_or_despatch_DN_amount)}</td>
                                                                                    <td id="td-right"><span>Buyer Dem/Despatch DN Amount</span></td>
                                                                                    <td>{renderShipmentQty(tcf.buyer_dem_or_despatch_DN_amount)}</td>
                                                                                </tr>

                                                                                <tr>
                                                                                    <td id="td-right"><span>supplier DN amount</span></td>
                                                                                    <td>{renderShipmentQty(tcf.supplier_DN_amount)}</td>
                                                                                    <td id="td-right"><span>buyer DN amount</span></td>
                                                                                    <td>{renderShipmentQty(tcf.buyer_DN_amount)}</td>
                                                                                </tr>

                                                                            </tbody>
                                                                        </table>

                                                                        <div className="col-md-12" id="ax.account_details">
                                                                            <h4 className="heading">Rwood Internal Information</h4>
                                                                            <hr></hr>
                                                                        </div>

                                                                        <table class="table table-bordered account-table tables">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td id="td-right"><span>any deviation
                                                                                    </span></td>
                                                                                    <td>{tcf.any_deviation}</td>
                                                                                    <td id="td-right"><span>	</span></td>
                                                                                    <td></td>
                                                                                </tr>

                                                                                <tr>
                                                                                    <td id="td-right"><span>prepared by</span></td>
                                                                                    <td>{tcf.prepared_by}</td>

                                                                                </tr>

                                                                                <tr>
                                                                                    <td id="td-right"><span>Approved By	</span></td>
                                                                                    <td>{tcf.check_and_approved_by}</td>
                                                                                    <td id="td-right"><span></span></td>
                                                                                    <td>{ }</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td id="td-right"><span>Check and Validated By</span></td>
                                                                                    <td>{tcf.validated_by}</td>
                                                                                    <td id="td-right"><span></span></td>
                                                                                    <td>{ }</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td id="td-right"><span>remarks</span></td>
                                                                                    <td>{tcf.remarks}</td>
                                                                                    <td id="td-right"><span></span></td>
                                                                                    <td>{ }</td>
                                                                                </tr>

                                                                            </tbody>
                                                                        </table>

                                                                        <Row id="table-styles">
                                                                            <div className="col-md-12" id="head">
                                                                                <h4 className="heading"><span>Rw Payable/ Receivable LineItem </span>
                                                                                    <span>
                                                                                        <Popup className='post-buyer'
                                                                                            trigger={
                                                                                                <a className="button add-accountrecord">
                                                                                                    {" "}
                                                                                                    New
                                                                                                </a>
                                                                                            }
                                                                                            modal
                                                                                        >
                                                                                            <div className="form-group">
                                                                                                <Form.Check
                                                                                                    type='radio'
                                                                                                    id=''
                                                                                                    name='type'
                                                                                                    label='Buyer - Credit Note'
                                                                                                    value='Buyer - Credit Note'
                                                                                                />
                                                                                                <Form.Check
                                                                                                    type='radio'
                                                                                                    id=''
                                                                                                    name='type'
                                                                                                    label='Supplier - Credit Note'
                                                                                                    value='Supplier - Credit Note'
                                                                                                />
                                                                                                <Form.Check
                                                                                                    type='radio'
                                                                                                    id=''
                                                                                                    name='type'
                                                                                                    label='Buyer - Debit Note'
                                                                                                    value='Buyer - Debit Note'
                                                                                                />
                                                                                                <Form.Check
                                                                                                    type='radio'
                                                                                                    id=''
                                                                                                    name='type'
                                                                                                    label='Supplier - Debit Note'
                                                                                                    value='Supplier - Debit Note'
                                                                                                />
                                                                                                <Form.Check
                                                                                                    type='radio'
                                                                                                    id=''
                                                                                                    name='type'
                                                                                                    label='Load Port Invoice'
                                                                                                    value='Load Port Invoice'
                                                                                                />
                                                                                                <Form.Check
                                                                                                    type='radio'
                                                                                                    id=''
                                                                                                    name='type'
                                                                                                    label='Other Payable'
                                                                                                    value='Other Payable'
                                                                                                />
                                                                                                <Form.Check
                                                                                                    type='radio'
                                                                                                    id=''
                                                                                                    name='type'
                                                                                                    label='Other Receivable'
                                                                                                    value='Other Receivable'
                                                                                                />
                                                                                                <Form.Check
                                                                                                    type='radio'
                                                                                                    id=''
                                                                                                    name='type'
                                                                                                    label='Purchase Invoice'
                                                                                                    value='Purchase Invoice'
                                                                                                />
                                                                                                <Form.Check
                                                                                                    type='radio'
                                                                                                    id=''
                                                                                                    name='type'
                                                                                                    label='Sales Invoice'
                                                                                                    value='Sales Invoice'
                                                                                                />
                                                                                                <Form.Check
                                                                                                    type='radio'
                                                                                                    id=''
                                                                                                    name='type'
                                                                                                    label='Surveyor Invoice'
                                                                                                    value='Surveyor Invoice'
                                                                                                />

                                                                                            </div>
                                                                                            <input
                                                                                                type="submit"
                                                                                                className="btn btn-primary "
                                                                                                value="Submit"
                                                                                                onClick={handleClick}
                                                                                            />

                                                                                        </Popup>
                                                                                    </span>

                                                                                </h4>
                                                                                <hr></hr>
                                                                            </div>
                                                                            <table id="rw" className="">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>RW Billing	</th>
                                                                                        <th>Document Date	</th>
                                                                                        <th>Record Type	</th>
                                                                                        <th>Line Item	</th>
                                                                                        <th>Account</th>
                                                                                        <th>Order No & Vessel Name	</th>
                                                                                        <th>Amount</th>
                                                                                        <th>Paid / Received Amount	</th>

                                                                                        <th>Balance Due	</th>
                                                                                        <th>Status</th>                    </tr>
                                                                                </thead>

                                                                                {receivable.length > 0 ? (
                                                                                    <tbody>
                                                                                        {receivable.map((y, i) => (
                                                                                            <tr>
                                                                                                <td> <Link to={"/tcf/receivable-details/" + y.rw_pay_recieve_id}>
                                                                                                    {y.rw_billing}
                                                                                                </Link></td>
                                                                                                <td>{y.document_date}</td>
                                                                                                <td>{y.record_type}</td>
                                                                                                <td>{y.line_item}</td>
                                                                                                <td><Link to={`/accounts/${y.account_id}`}>{y.account}</Link></td>
                                                                                                <td></td>
                                                                                                <td>{renderShipmentQty(y.amount)}</td>
                                                                                                <td>{renderShipmentQty(y.paid_recieved_amount)}</td>
                                                                                                <td>{renderShipmentQty(y.balance_due)}</td>
                                                                                                <td>{y.status}</td>
                                                                                            </tr>
                                                                                        ))}
                                                                                    </tbody>
                                                                                ) : (
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td></td>
                                                                                            <td></td>
                                                                                            <td></td>
                                                                                            <td></td>
                                                                                            <td></td>

                                                                                            <td>No data available</td>
                                                                                            <td></td>
                                                                                            <td></td>
                                                                                            <td></td>
                                                                                            <td></td>                      </tr>
                                                                                    </tbody>
                                                                                )}
                                                                            </table>
                                                                        </Row>
                                                                        <Row id="table-styles">
                                                                            <div className="col-md-12" id="head">
                                                                                <h4 className="heading"><span>Rw Payable/ Receipt LineItem </span><span>
                                                                                    <Popup className='post-buyer'
                                                                                        trigger={
                                                                                            <a className="button add-accountrecord">
                                                                                                {" "}
                                                                                                New
                                                                                            </a>
                                                                                        }
                                                                                        modal
                                                                                    >
                                                                                        <div className="form-group" >
                                                                                            <Form.Check
                                                                                                type='radio'
                                                                                                id=''
                                                                                                name='type'
                                                                                                label='Buyer - Credit Note'
                                                                                                value='Buyer - Credit Note'
                                                                                            />
                                                                                            <Form.Check
                                                                                                type='radio'
                                                                                                id=''
                                                                                                name='type'
                                                                                                label='Supplier - Credit Note'
                                                                                                value='Supplier - Credit Note'
                                                                                            />
                                                                                            <Form.Check
                                                                                                type='radio'
                                                                                                id=''
                                                                                                name='type'
                                                                                                label='Buyer - Debit Note'
                                                                                                value='Buyer - Debit Note'
                                                                                            />
                                                                                            <Form.Check
                                                                                                type='radio'
                                                                                                id=''
                                                                                                name='type'
                                                                                                label='Supplier - Debit Note'
                                                                                                value='Supplier - Debit Note'
                                                                                            />
                                                                                            <Form.Check
                                                                                                type='radio'
                                                                                                id=''
                                                                                                name='type'
                                                                                                label='Load Port Invoice'
                                                                                                value='Load Port Invoice'
                                                                                            />
                                                                                            <Form.Check
                                                                                                type='radio'
                                                                                                id=''
                                                                                                name='type'
                                                                                                label='Other Payable'
                                                                                                value='Other Payable'
                                                                                            />
                                                                                            <Form.Check
                                                                                                type='radio'
                                                                                                id=''
                                                                                                name='type'
                                                                                                label='Other Receivable'
                                                                                                value='Other Receivable'
                                                                                            />
                                                                                            <Form.Check
                                                                                                type='radio'
                                                                                                id=''
                                                                                                name='type'
                                                                                                label='Purchase Invoice'
                                                                                                value='Purchase Invoice'
                                                                                            />
                                                                                            <Form.Check
                                                                                                type='radio'
                                                                                                id=''
                                                                                                name='type'
                                                                                                label='Sales Invoice'
                                                                                                value='Sales Invoice'
                                                                                            />
                                                                                            <Form.Check
                                                                                                type='radio'
                                                                                                id=''
                                                                                                name='type'
                                                                                                label='Surveyor Invoice'
                                                                                                value='Surveyor Invoice'
                                                                                            />

                                                                                        </div>
                                                                                        <input
                                                                                            type="submit"
                                                                                            className="btn btn-primary "
                                                                                            value="Submit"

                                                                                        />

                                                                                    </Popup>
                                                                                </span>

                                                                                </h4>
                                                                                <hr></hr>
                                                                            </div>
                                                                            <table id="receipt" className="">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>Rw payment receipt</th>
                                                                                        <th>Payment/Receipt Date</th>
                                                                                        <th>Record Type</th>
                                                                                        <th>Line Item</th>
                                                                                        <th>Vessel Name</th>
                                                                                        <th>Amount</th>
                                                                                        <th>Account</th>
                                                                                        <th>RW Billing</th>
                                                                                        <th>Order</th>
                                                                                        <th>Description</th>
                                                                                    </tr>
                                                                                </thead>

                                                                                {receipt.length > 0 ? (
                                                                                    <tbody>
                                                                                        {receipt.map((y, i) => (
                                                                                            <tr>
                                                                                                <td><Link to={'/tcf/tcf-receipt-details/' + y.rw_pay_receipt_detail_id}>{y.rw_payment_receipt}</Link></td>
                                                                                                <td>{y.payment_receipt_date}</td>
                                                                                                <td>{y.record_type}</td>
                                                                                                <td>{y.line_item}</td>
                                                                                                <td>{y.vessel_name}</td>
                                                                                                <td>{renderShipmentQty(y.amount)}</td>
                                                                                                <td><Link to={`/accounts/${y.account_id}`}>{y.account}</Link></td>
                                                                                                <td> <Link to={"/tcf/receivable-details/" + y.rw_pay_recieve_id}>
                                                                                                    {y.rw_billing}
                                                                                                </Link></td>
                                                                                                <td><Link to={`/order/view-order-details/${y.order_id}`}>{y.order}</Link></td>
                                                                                                <td>{y.description}</td>
                                                                                            </tr>
                                                                                        ))}
                                                                                    </tbody>
                                                                                ) : (
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td></td>
                                                                                            <td></td>
                                                                                            <td></td><td></td>
                                                                                            <td></td>

                                                                                            <td>No data available</td>
                                                                                            <td></td>
                                                                                            <td></td>
                                                                                            <td></td>
                                                                                            <td></td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                )}
                                                                            </table>
                                                                        </Row>

                                                                        <Row id="table-styles">
                                                                            <div className="col-md-12" id="head">
                                                                                <h4 className="heading">
                                                                                    <span>Trade Confirmation Form History</span>
                                                                                </h4>
                                                                                <hr></hr>
                                                                            </div>
                                                                            <table id="history" className="">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>Field Name</th>
                                                                                        <th>New Value</th>
                                                                                        <th>Old Value</th>
                                                                                        <th>Changed By</th>
                                                                                        <th>Change Time</th>
                                                                                    </tr>
                                                                                </thead>

                                                                                {history.length > 0 ? (
                                                                                    <tbody>
                                                                                        {history.map((y, i) => (
                                                                                            <tr>
                                                                                                <td>{y.field_name}</td>
                                                                                                <td>{y.new_value}</td>
                                                                                                <td>{y.old_value}</td>
                                                                                                <td>{y.changed_by}</td>
                                                                                                <td>{y.change_time}</td>
                                                                                            </tr>
                                                                                        ))}
                                                                                    </tbody>
                                                                                ) : (
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td></td>
                                                                                            <td></td>
                                                                                            <td>No data available</td>
                                                                                            <td></td>
                                                                                            <td></td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                )}
                                                                            </table>
                                                                        </Row>
                                                                    </div></div></div>
                                                        </div>
                                                        <Footer />
                                                    </div>

                                                )}
                                            </>
                                        ) : (<></>)}
                                    </>
                                )}</>

                        )}
                    </>
                )}


            </>
            {/* ):(<>
  <div className="tasks column-gap fulid-section order-section">
    <Header/>
        <h1 className="no-data-detail"><img src='../../images/loading.gif' className="loading"/></h1>
        <Footer/>
        </div></>)} */}
        </>

    )

}
export default TradeConfirmDetail
