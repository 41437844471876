import React, { useState } from 'react'
import ReportGenerator from './ReportGenerator'
import ReportSelection from './ReportSelection'
import Header from '../Header'
import Footer from '../Footer'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import './report.css'

const Report = () => {

    const [successRetrive, setSuccessRetrive] = useState(false)

    const[models, setModels] = useState("")
    
    const [tableKeys, setTableKeys] = useState({});

    // hanldes api state response after file select

    const handleApiStateResponse = (tableKeys, models) => {

        setSuccessRetrive(true)
        setTableKeys(tableKeys)
        setModels(models)

    }


    return (

        <> 
            <Header />

            <div className='rwood_report_generation_container'>

                {!successRetrive ? (
                    <>
                        <ReportSelection onFileSelection={handleApiStateResponse} />
                    </>
                ) : (
                    <>
                        <ReportGenerator tableKeys={tableKeys} api_payload={models} />
                    </>
                )}

            </div>
            
            <Footer />
        </>
    )
}

export default Report
