

import React, { useEffect, useState } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { AES, enc } from 'crypto-js';
import Header from '../Header' 
import Footer from '../Footer' 
import { API_URL } from '../config';
import $ from 'jquery';
import RecentItem from "../recentitem/rececentItem";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function AddRwoodusers() {
  
  const body=$(window).height()
  const header=$("#header").height()
  const nav=$(".navbar").height()
  const footer=$(".footer").height()
  const total_height=header+nav+footer;
  const window_height=body-total_height;
  console.log(total_height)
  console.log(body)
  $(".contactAccount").css({"min-height":(window_height)+"px"})
    
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const onSubmit = data =>{ 
        const responseData={
            rwood_username:data.rwood_username,
            rwood_username_no:data.rwood_username_no,
            rwood_user_type:data.rwood_user_type,
            active:true,
      }; 
      console.log(JSON.stringify(responseData))
      const encryptedAccessToken = localStorage.getItem('access_token');
        const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';
        if (encryptedAccessToken) {
          const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);
          
        fetch(API_URL+'add/rwooduser', {
                  method: "POST",
                  body: JSON.stringify(responseData),
                  headers: { Authorization: `Bearer ${decryptedAccessToken}`,
                  'Content-Type': 'application/json' },
                })
                  .then((response) => response.json())
                  
                  .then((response) => {  
                    toast.success("User added successfully",
                                   {
                                    position: "bottom-center",
                                    autoClose: 1000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                    });
                    setTimeout(() => { 
                      reset();
                    }, 300); 
                 
                  })
                  
                } 
                 
        };
      
  return (

    <div className="addAccount">
    <Header/>
    <div className="row">
       <div className="col-md-2 recent-item">
       <RecentItem/>
</div>
       <div className="col-md-10 right-section">
    
    <div className="page-header" id='add-account'>
    <div>
      <h2 className="main-content-title tx-24 mg-b-5">Add Rwood users</h2>
      <ol className="breadcrumb"> 
        <li className="breadcrumb-item active" aria-current="page">Dashboard / Add rwood users </li>
      </ol>
    </div>
    <div className="d-flex">
      <div className="justify-content-center">
      
        {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
         <a href='/account/add-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</a>
        </button> */}
        
      </div>
    </div> 
  </div>  
    <Form onSubmit={handleSubmit(onSubmit)}>
    <p className='edit-cancel'><input type="submit" className="account-save" value="Save" /><Link to={'/user/userlist'}><input type="submit" className="account-save" value="Cancel" /></Link></p>
      <Row className="mb-3">
        <h4 className='heading'>Account Information</h4>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Rwood username no"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Rwood username no"
              name="rwood_username_no"
              tabIndex={1}
              className="error-validation"
              {...register("rwood_username_no", {required: true})}
            />
            {errors.rwood_username_no && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Rwood username"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Rwood username"
              name="rwood_username"
              tabIndex={3} 
              {...register("rwood_username", {required: false})}
            />
            {errors.rwood_username && <span>Required</span>}
          </FloatingLabel>
        </Form.Group>
        
        <Form.Group as={Col} md="6">
              
              <FloatingLabel controlId="floatingSelect" 
              className='dropDown' label="Rwood user type">
    <Form.Select aria-label="Floating label select example"
    name="rwood_user_type" 
    placeholder="Rwood user type" 
    tabIndex={2}
    {...register("rwood_user_type", { required:false })}
    >
      <option value='' >Select</option>
      <option value='Trader' >Trader</option>
      <option value='Operation' >Operation</option>
      <option value='Staff' >Staff</option>
        
    </Form.Select>
    {errors.rwood_user_type && <span>Required</span>}
  </FloatingLabel>
              </Form.Group> 
       
       <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Active"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              tabIndex={4}
              placeholder="Active"
              name="active"
              {...register("active", {required: false})}
            />
            {errors.active && <span>Required </span>}
          </FloatingLabel>
        </Form.Group>    
          
                </Row>
                <p className='edit-cancel'><input type="submit" className="account-save" value="Save" /><Link to={'/user/userlist'}><input type="submit" className="account-save" value="Cancel" /></Link></p>
      <div className='successDiv'><p></p><p></p></div>
    </Form> </div>  </div> 
    <Footer/>
    </div>
  );
}

export default AddRwoodusers;

