import React, { useEffect, useState, forwardRef } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { useForm, Controller } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import Header from "../Header";
import Footer from "../Footer";
import { API_URL } from "../config";
import { AES, enc } from "crypto-js";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import $ from "jquery";
import DatePicker from "react-datepicker";
// import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
import axios from "axios";
import RecentItem from "../recentitem/rececentItem";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
function AddContactInner(props) {
  const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
  const encryptedAccessToken = localStorage.getItem("access_token");
  const decryptedAccessToken = encryptedAccessToken
    ? AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8)
    : null;
  const params = useParams();
  const navigate = useNavigate();
  const id = params.id;
  const location = useLocation();
  const { accountName } = location.state;
  const [accountNames, setAccountName] = useState([]);
  const body = $(window).height();
  const header = $("#header").height();
  const nav = $(".navbar").height();
  const footer = $(".footer").height();
  const total_height = header + nav + footer;
  const window_height = body - total_height;
  console.log(window_height);

  useEffect(() => {
    fetchDataSupplier();
  }, [decryptedAccessToken]);

  const fetchDataSupplier = async () => {
    try {
      const response = await axios.get(API_URL + "all/accounts", {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },
      });
      const data = response.data.accounts;
      setAccountName(data);
    } catch (error) {
      console.error(error);
    }
  };

  const [contact, setContact] = useState([]);
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    fetchData();
  }, [decryptedAccessToken]);

  const fetchData = async () => {
    try {
      const response = await axios.get(API_URL + "allcontactsbyID/" + id, {
        headers: {
          Authorization: `Bearer ${decryptedAccessToken}`,
          "Content-Type": "application/json",
        },
      });
      const data = response.data[0].contact_details;
      console.log(data);
      setContact(data);
      setValue("account_name", data.account_name);
    } catch (error) {
      console.error(error);
    }
  };

  const [startDate, setStartDate] = useState(null);
  const birthDate = startDate
    ? `${startDate.getDate().toString().padStart(2, "0")}/${(
        startDate.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}/${startDate.getFullYear()}`
    : null;
  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  $(".contactAccount").css({ "min-height": window_height + "px" });

  const onSubmit = (data) => {
    const responseData = {
      saluation: data.saluation,
      first_name: data.first_name,
      last_name: data.last_name,
      birthdate: birthDate,
      title: data.title,
      account_name: data.account_name,
      reports_to: data.reports_to,
      contact_email: data.contact_email,
      contact_mobile: data.contact_mobile,
      contact_owner: data.contact_owner,
      department: data.department,
      lead_source: data.lead_source,
      mail_street: data.mail_street,
      mail_postal_code: data.mail_postal_code,
      mail_city: data.mail_city,
      mail_state: data.mail_state,
      mail_country: data.mail_country,
    };
    console.log(JSON.stringify(data));
    const encryptedAccessToken = localStorage.getItem("access_token");
    const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
    if (encryptedAccessToken) {
      const decryptedAccessToken = AES.decrypt(
        encryptedAccessToken,
        secret_key
      ).toString(enc.Utf8);

      fetch(API_URL + "add/contact_by_account/" + id, {
        method: "POST",
        body: JSON.stringify(responseData),
        headers: {
          Authorization: `Bearer ${decryptedAccessToken}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((response) => {
          console.log(response);
          if (response.message === "Contact already Present") {
            toast.success("Contact already Present", {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          } else {
            toast.success("Contact By Account Added Successfully", {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            setTimeout(() => {
              reset();
              navigate("/accounts/" + id);
            }, 2000);
          }
        });
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setContact((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <div className="contactAccount full-container" id="contacts">
      <Header />
      <div className="page-header" id="add-account">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">
            Add Contact By Account
          </h2>
          <ol className="breadcrumb">
            <li className="breadcrumb-item active" aria-current="page">
              Dashboard / Contact / Add Contact By Account
            </li>
          </ol>
        </div>
        <div className="d-flex">
          <div className="justify-content-center">
            {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
         <a href='/account/add-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</a>
        </button> */}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-2 recent-item">
          <RecentItem />
        </div>
        <div className="col-md-10 right-section">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <p className="edit-cancel">
              <input type="submit" className="account-save" value="Save" />
              <Link to={`/accounts/${id}`}>
                <input type="submit" className="account-save" value="Cancel" />
              </Link>
            </p>
            <Row className="mb-3">
              <div className="col-md-12">
                <h4 className="heading">Contact Information</h4>
              </div>

              <Form.Group as={Col} md="6" id="">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Salutation"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="saluation"
                    placeholder="Salutation"
                    defaultValue=""
                    {...register("saluation", { required: false })}
                  >
                    <option value="" disabled>
                      --None--
                    </option>
                    <option value="Mr">Mr</option>
                    <option value="Ms">Ms</option>
                    <option value="Mrs">Mrs</option>
                    <option value="Dr">Dr</option>
                    <option value="Prof">Prof</option>
                  </Form.Select>
                  {errors.saluation && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Contact Owner"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Contact Owner"
                    name="contact_owner"
                    {...register("contact_owner", { required: false })}
                  />
                  {errors.contact_owner && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="First Name"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="First Name"
                    name="first_name"
                    {...register("first_name", { required: false })}
                  />
                  {errors.first_name && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Reports to"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Reports to"
                    name="reports_to"
                    {...register("reports_to", { required: false })}
                  />
                  {errors.reports_to && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Last Name"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Last Name"
                    name="last_name"
                    className="error-validation"
                    {...register("last_name", { required: true })}
                  />
                  {errors.last_name && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Department"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Department"
                    name="department"
                    {...register("department", { required: false })}
                  />
                  {errors.department && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Account name"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="account_name"
                    id="account_name"
                    placeholder="Account name"
                    className="error-validation"
                    value={accountName || ""}
                    {...register("account_name", { required: false })}
                  >
                    <option value="">Select</option>
                    {accountNames.map((x) => {
                      return (
                        <option value={x.account_name}>{x.account_name}</option>
                      );
                    })}
                  </Form.Select>
                  {errors.account_name && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown"
                  label="Lead Source"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="lead_source"
                    id="account_name"
                    placeholder="Account name"

                    {...register("lead_source", { required: false })}
                  >
                    <option value="">--None--</option>
                    <option value="Advertisement">Advertisement</option>
                    <option value="Customer Event">Customer Event</option>
                    <option value="Employee Referral">Employee Referral</option>
                    <option value="Google AdWords">Google AdWords</option>
                    <option value="Other">Other</option>
                    <option value="Partner">Partner</option>
                    <option value="Purchased List">Purchased List</option>
                    <option value="Trade Show">Trade Show</option>
                    <option value="Webinar">Webinar</option>
                    <option value="Website">Website</option>
                    <option value="Rajgopalan">Rajgopalan</option>
                    <option value="Olivia">Olivia</option>
                  </Form.Select>
                  {errors.lead_source && <span>Required </span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Birth Date"
                  className="mb-3 datepick"
                >
                  <DatePicker
                    selected={startDate}
                    onChange={handleStartDateChange}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="Select"
                  />
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row>
              <div className="col-md-12">
                <h4 className="heading">Contact Information</h4>
              </div>
              <Form.Group as={Col} md="6" id="contact-title">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Title"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Title"
                    name="title"
                    {...register("title", { required: false })}
                  />
                  {errors.title && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Contact Email"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Contact Email"
                    name="contact_email"
                    {...register("contact_email", { required: false })}
                  />
                  {errors.contact_email && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Contact Mobile"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Contact Mobile"
                    name="contact_mobile"
                    {...register("contact_mobile", { required: false })}
                  />
                  {errors.contact_mobile && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
            </Row>

            <Row>
              <div className="col-md-12">
                <h4 className="heading">Address Information</h4>
              </div>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Mail Street"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Mail Street"
                    name="mail_street"
                    {...register("mail_street", { required: false })}
                  />
                  {errors.mail_street && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Mail postal code"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Mail postal code"
                    name="mail_postal_code"
                    {...register("mail_postal_code", { required: false })}
                  />
                  {errors.mail_postal_code && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Mail city"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Mail city"
                    name="mail_city"
                    {...register("mail_city", { required: false })}
                  />
                  {errors.mail_city && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Mail state"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Mail state"
                    name="mail_state"
                    {...register("mail_state", { required: false })}
                  />
                  {errors.mail_state && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col} md="6"></Form.Group>
              <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Mail country"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Mail country"
                    name="mail_country"
                    {...register("mail_country", { required: false })}
                  />
                  {errors.mail_country && <span>Required</span>}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <p className="edit-cancel">
              <input type="submit" className="account-save" value="Save" />
              <Link to={`/accounts/${id}`}>
                <input type="submit" className="account-save" value="Cancel" />
              </Link>
            </p>
            <div className="contact-success">
              <p></p>
              <p></p>
            </div>
          </Form>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default AddContactInner;
