// import React, { useState } from "react";
// import Box from "@mui/material/Box";
// import Button from "@mui/material/Button";
// import Stack from "@mui/material/Stack";
// import axios from "axios";
// import { AES, enc } from 'crypto-js';

// import { API_URL } from "../config";
// import { RichTreeView } from "@mui/x-tree-view/RichTreeView";

// const ReportSelection = ({ onFileSelection }) => {

//     const [apiPayload, setApiPayload] = useState({});

//     // Function to fetch keys based on API call
//     const getKeys = async () => {

//         try {

//             const response = await axios.get(
//                 `${API_URL}/get/keys`, {
//                     params: {
//                         table_name: "accounts_contacts"
//                     }
//                 }
//             );

//             if (response.data) {

//                 console.log(response.data)
//                 onFileSelection(response.data, apiPayload);
//             }

//         } catch (error) {
//             console.error("Error fetching keys:", error);
//         }
//     };

//     // Item selection handler
//     const handleItemSelectionToggle = (event, itemId, isSelected) => {

//         if (isSelected) {

//             setApiPayload({ table_name: itemId });

//         }

//     };



//     // Recursive Tree Component with folder icons
// const TreeItem = ({ item }) => {
//     const [expanded, setExpanded] = useState(false);
  
//     const hasChildren = item.children && item.children.length > 0;
  
//     return (
//       <li className="tree-item">
//         <div
//           className={`tree-label ${hasChildren ? 'expandable' : ''}`}
//           onClick={() => hasChildren && setExpanded(!expanded)}
//         >
//           {hasChildren && (
//             <img
//               src={expanded ? '/images/open-folder.png' : '/images/closed-folder.png'}
//               alt="folder-icon"
//               className="folder-icon"
//             />
//           )}
//           {!hasChildren && (
//             <img
//               src="/images/file-icon.png"
//               alt="file-icon"
//               className="file-icon"
//             />
//           )}
//           {item.label}
//         </div>
//         {hasChildren && expanded && (
//           <ul className="tree-children">
//             {item.children.map((child) => (
//               <TreeItem key={child.id} item={child} />
//             ))}
//           </ul>
//         )}
//       </li>
//     );
//   };
  
//   const TreeView = ({ data }) => {
//     return (
//       <div className="tree-container">
//         <ul className="tree-root">
//           {data.map((item) => (
//             <TreeItem key={item.id} item={item} />
//           ))}
//         </ul>
//       </div>
//     );
//   };
  
  
//     // Define your report tree structure
//     const RWOOD_REPORT_DOCX = [
        
//         {
//             id: "accounts_and_contacts",
//             label: "Accounts & Contacts",
//             children: [
//                 { id: "accounts", label: "Accounts" },
//                 { id: "accounts_ledger_1", label: "Accounts and Ledger 1" },
//                 { id: "accounts_ledger_2", label: "Accounts and Ledger 2" },
//                 { id: "accounts_ledger_3", label: "Accounts and Ledger 3" },
//                 { id: "accounts_ledger_4", label: "Accounts and Ledger 4" },
//                 {
//                     id: "contacts_accounts_ledger_1",
//                     label: "Contacts & Accounts and Ledger 1",
//                 },
//                 {
//                     id: "contacts_accounts_ledger_2",
//                     label: "Contacts & Accounts and Ledger 2",
//                 },
//                 {
//                     id: "contacts_accounts_ledger_3",
//                     label: "Contacts & Accounts and Ledger 3",
//                 },
//                 {
//                     id: "contacts_accounts_ledger_4",
//                     label: "Contacts & Accounts and Ledger 4",
//                 },
//                 { id: "accounts_contacts", label: "Contact & Accounts" },
//             ],
//         },
//         {
//             id: "opportunities",
//             label: "Opportunities",
//             children: [
//                 { id: "opportunities_report", label: "Opportunities" },
//                 {
//                     id: "opportunities_trader_name",
//                     label: "Opportunities and Trader Name",
//                 },
//                 {
//                     id: "opportunities_price_type",
//                     label: "Opportunities and Price Type",
//                 },
//                 { id: "opportunities_company", label: "Opportunities and Company" },
//             ],
//         },
//         {
//             id: "forecasts",
//             label: "ForeCasts",
//             children: [{ id: "rw_price_books", label: "RW Price Books" }],
//         },
//         {
//             id: "customer_support_reports",
//             label: "Customer Support Reports",
//             children: [
//                 { id: "cases", label: "Cases" },
//                 { id: "cases_lifecycle", label: "Cases Lifecycle" },
//                 { id: "cases_contact_roles", label: "Cases With Contact Roles" },
//             ],
//         },
//         {
//             id: "leads",
//             label: "Leads",
//             children: [
//                 { id: "leads_1", label: "Leads" },
//                 {
//                     id: "leads_converted_lead_info",
//                     label: "Leads with converted lead information",
//                 },
//                 {
//                     id: "leads_converted_lead_info_trader_name",
//                     label: "Leads with converted lead information and Trader Name",
//                 },
//             ],
//         },
//         {
//             id: "campaigns",
//             label: "Campaigns",
//             children: [
//                 { id: "campaigns_1", label: "Campaigns" },
//                 { id: "campaigns_contacts", label: "Campaigns With Contacts" },
//                 { id: "campaigns_lead", label: "Campaigns with Leads" },
//             ],
//         },
//         {
//             id: "activities",
//             label: "Activities",
//             children: [
//                 { id: "task_events", label: "Task and Events" },
//                 { id: "events_invitees", label: "Events with Invitees" },
//             ],
//         },
//         {
//             id: "contracts_orders",
//             label: "Contracts and Orders",
//             children: [
//                 { id: "contract", label: "Contracts" },
//                 { id: "contract_ledger_1", label: "Contracts and Ledger 1" },
//                 { id: "contract_ledger_2", label: "Contracts and Ledger 2" },
//                 { id: "contract_ledger_3", label: "Contracts and Ledger 3" },
//                 { id: "contract_ledger_4", label: "Contracts and Ledger 4" },
//             ],
//         },
//     ];

//     return (
//         <div className="rwood_report_selection_container">

//         <div class="report-container">

//             <label for="select-report">

//                 <input type="checkbox" id="hide-report-types" /> Select Report Types to Hide

//             </label>

//             <div class="report-selector">

//                 <input type="text" id="search-report" placeholder="Quick Find" />

//                 <TreeView data={RWOOD_REPORT_DOCX} />


//             </div>

             
//         </div>

//             <div className="rwood_report_action_btn">
//                 <Button variant="contained" onClick={getKeys}>
//                     Create
//                 </Button>
//             </div>
//         </div>
//     );
// };

// export default ReportSelection;


import React, { useState } from "react";

 
import axios from "axios";
 
import { API_URL } from "../config";
 

// const TreeItem = ({ item, onSelect }) => {
//     const [expanded, setExpanded] = useState(false);
//     const hasChildren = item.children && item.children.length > 0;

//     // Handle click on the label to expand/collapse
//     const handleToggle = () => {
//         if (hasChildren) {
//             setExpanded(!expanded);
//         }
//     };

//     // Handle click on the item (child or parent)
//     const handleClick = () => {
//         console.log('Clicked');
//         // Call onSelect with the item's ID
//         onSelect(item.id);
//     };

//     return (
//         <li className={`tree-item ${expanded ? "expanded" : ""}`}>
//             <div
//                 className={`tree-label ${hasChildren ? "expandable" : ""}`}
//                 onClick={handleToggle} // Toggle for expanding/collapsing
//             >
//                 {hasChildren && (
//                     <img
//                         src={expanded ? "../../../images/open_folder.svg" : "../../../images/closed_folder.svg"}
//                         alt="folder-icon"
//                         className="folder-icon"
//                     />
//                 )}
//                 {!hasChildren && (
//                     <img src="../../../images/report.svg" alt="file-icon" className="file-icon" />
//                 )}
//                 {item.label}
//             </div>

//             {hasChildren && expanded && (
//                 <ul className="tree-children">
//                     {item.children.map((child) => (
//                         <TreeItem  key={child.id} item={child} />
//                     ))}
//                 </ul>
//             )}

             
//         </li>
//     );
// };

 

 
 

const TreeItem = ({ item, setReportName }) => {

    const [expanded, setExpanded] = useState(false);
    const hasChildren = item.children && item.children.length > 0;

    // Handle click on the label to expand/collapse
    const handleToggle = () => {
        if (hasChildren) {
            setExpanded(!expanded);
        }
    };

    // Handle click on the item (child or parent)
    const handleClick = () => {
         
        setReportName({ table_name: item.id })
        

    };

    return (
        <li className={`tree-item ${expanded ? "expanded" : ""}`}>
            <div
                className={`tree-label ${hasChildren ? "expandable" : ""}`}
                onClick={handleToggle} // Toggle for expanding/collapsing
            >
                {hasChildren && (
                    <img
                        src={expanded ? "../../../images/open_folder.svg" : "../../../images/closed_folder.svg"}
                        alt="folder-icon"
                        className="folder-icon"
                    />
                )}
                {!hasChildren && (
                    <img src="../../../images/report.svg" alt="file-icon" className="file-icon" />
                )}
                <span onClick={handleClick}>{item.label}</span> {/* Make the label clickable */}
            </div>

            {hasChildren && expanded && (
                <ul className="tree-children">
                    {item.children.map((child) => (
                        <TreeItem key={child.id} item={child}  setReportName={setReportName}/>  
                    ))}
                </ul>
            )}
        </li>
    );
};

 


const TreeView = ({ data, setReportName }) => {

  return (

    <div className="tree-container">

      <ul className="tree-root">

        {data.map((item) => (

          <TreeItem key={item.id} item={item} setReportName={setReportName}/>

        ))}

      </ul>

    </div>

  );
};

const ReportSelection = ({ onFileSelection }) => {

  const [reportName, setReportName] = useState({});


    // Item selection handler

    const handleReportSelection = (event, itemId, isSelected) => {

        if (isSelected) {

            setReportName({ table_name: itemId });

        }

    };

  // Function to fetch keys based on API call
  const getModelFields = async () => {

    try {

      const response = await axios.get(`${API_URL}/get/keys`, {

        params: {

          table_name: "accounts_contacts",

        },

      });

      if (response.data) {
         
        onFileSelection(response.data, reportName);

      }

    } catch (error) {

      console.error("Error fetching keys:", error);

    }

  };

  // Recursive tree data structure
  const RWOOD_REPORT_DOCX = [
    {
      id: "accounts_and_contacts",
      label: "Accounts & Contacts",
      children: [
        { id: "accounts", label: "Accounts" },
        { id: "accounts_ledger_1", label: "Accounts and Ledger 1" },
        { id: "accounts_ledger_2", label: "Accounts and Ledger 2" },
        { id: "accounts_ledger_3", label: "Accounts and Ledger 3" },
        { id: "accounts_ledger_4", label: "Accounts and Ledger 4" },
        {id: "contacts_accounts_ledger_1", label: "Contacts & Accounts and Ledger 1",},
        {id: "contacts_accounts_ledger_2",label: "Contacts & Accounts and Ledger 2",},
        {
          id: "contacts_accounts_ledger_3",
          label: "Contacts & Accounts and Ledger 3",
        },
        {
          id: "contacts_accounts_ledger_4",
          label: "Contacts & Accounts and Ledger 4",
        },
        { id: "accounts_contacts", label: "Contact & Accounts" },
      ],
    },
    {
      id: "opportunities",
      label: "Opportunities",
      children: [
        { id: "opportunities_report", label: "Opportunities" },
        {
          id: "opportunities_trader_name",
          label: "Opportunities and Trader Name",
        },
        {
          id: "opportunities_price_type",
          label: "Opportunities and Price Type",
        },
        { id: "opportunities_company", label: "Opportunities and Company" },
      ],
    },
    {
      id: "forecasts",
      label: "ForeCasts",
      children: [{ id: "rw_price_books", label: "RW Price Books" }],
    },
    {
      id: "customer_support_reports",
      label: "Customer Support Reports",
      children: [
        { id: "cases", label: "Cases" },
        { id: "cases_lifecycle", label: "Cases Lifecycle" },
        { id: "cases_contact_roles", label: "Cases With Contact Roles" },
      ],
    },
    {
      id: "leads",
      label: "Leads",
      children: [
        { id: "leads_1", label: "Leads" },
        {
          id: "leads_converted_lead_info",
          label: "Leads with converted lead information",
        },
        {
          id: "leads_converted_lead_info_trader_name",
          label: "Leads with converted lead information and Trader Name",
        },
      ],
    },
    {
      id: "campaigns",
      label: "Campaigns",
      children: [
        { id: "campaigns_1", label: "Campaigns" },
        { id: "campaigns_contacts", label: "Campaigns With Contacts" },
        { id: "campaigns_lead", label: "Campaigns with Leads" },
      ],
    },
    {
      id: "activities",
      label: "Activities",
      children: [
        { id: "task_events", label: "Task and Events" },
        { id: "events_invitees", label: "Events with Invitees" },
      ],
    },
    {
      id: "contracts_orders",
      label: "Contracts and Orders",
      children: [
        { id: "contract", label: "Contracts" },
        { id: "contract_ledger_1", label: "Contracts and Ledger 1" },
        { id: "contract_ledger_2", label: "Contracts and Ledger 2" },
        { id: "contract_ledger_3", label: "Contracts and Ledger 3" },
        { id: "contract_ledger_4", label: "Contracts and Ledger 4" },
      ],
    },
  ];

  return (

    <div className="rwood_report_selection_container">

      <div className="report-container">

        <label htmlFor="select-report">

          <input type="checkbox" id="hide-report-types" /> Select Report Types to Hide

        </label>

        <div className="report-selector">

          <input type="text" id="search-report" placeholder="Quick Find" />

          <TreeView data={RWOOD_REPORT_DOCX} setReportName={setReportName}/>

        </div>

      </div>

      <div className="rwood_report_action_btn">
         
        <button className="button" onClick={getModelFields}>

          Create

        </button>

      </div>

    </div>

  );

};

export default ReportSelection;

