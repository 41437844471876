import React, { useState, useEffect, useRef } from "react";
import Button from "@mui/material/Button";

import axios from "axios";

import { AES, enc } from "crypto-js";
import { API_URL } from "../config";
import "./products.css";

import { Link, useNavigate } from "react-router-dom";

import Header from "../Header";
import Footer from "../Footer";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from "@mui/material";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { AiFillDiff } from "react-icons/ai";
import { FaPencilAlt, FaTrashAlt, FaPlus } from "react-icons/fa";
import RecentItems from "../recentItems/RecentItems";

const ProductList = () => {
  const [isLoading, setLoading] = useState(false);
  const [productViews, setproductViews] = useState([]);
  const [isOptionsLoaded, setIsOptionsLoaded] = useState(false);
  const [selectedView, setSelectedView] = useState("all");
  const [selectedViewId, setSelectedViewId] = useState("");

  const [requestDatatype, setRequestDataType] = useState("recentmodifier");

  const [allTableData, setAllTableData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [columns, setColumns] = useState([]);

  const [modifiedEntriesLabel, setModifiedEntriesLabel] =
    useState("recently_created");


    const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });

  // Function to handle sorting logic
  const handleSort = (columnKey) => {
    let direction = "asc";
    if (sortConfig.key === columnKey && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key: columnKey, direction });
    sortTableData(columnKey, direction);
  };

  // Function to sort table data
  const sortTableData = (columnKey, direction) => {
    const sortedData = [...allTableData].sort((a, b) => {
      if (a[columnKey] < b[columnKey]) {
        return direction === "asc" ? -1 : 1;
      }
      if (a[columnKey] > b[columnKey]) {
        return direction === "asc" ? 1 : -1;
      }
      return 0;
    });
    setAllTableData(sortedData); // Make sure you update your table data state
  };

  const navigate = useNavigate();

  const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
  const encryptedAccessToken = localStorage.getItem("access_token");
  const decryptedAccessToken = encryptedAccessToken
    ? AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8)
    : null;

  // Function To Handle Recent Modifiers //

  const handleRandomDropdownChange = (e) => {
    const modifiedEntry = e.target.value;

    setModifiedEntriesLabel(modifiedEntry);

    setRequestDataType("recentmodifier");
  };

  // Function To Handle Page Change //

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  // Function To Handle Rows Per Change //

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Formatting Table Header Data //

  const formatHeaderName = (key) => {
    const replaced = key.replace(/_/g, " ").toLowerCase();
    return replaced.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const [excludedFields, setExcludedFields] = useState([
    "id",
    "product_master_id",
    "price_type_id",
    "account_id",
    "user_id",
    "contract_id",
    "purchase_contract_id",
    "contract_shipment_id",
  ]);
  const [linkFields, setLinkFields] = useState({
    price_type: { id: "price_type_id", link: "/price/price-type-details/" },

    product_master: {
      id: "product_master_id",
      link: "/productmaster/product-detail/",
    },

    product_no: { id: "id", link: "/product/product-detail/" },

    contract_shipment: {
      id: "contract_shipment_id",
      link: "/contract-shipment/detail/",
    },

    supplier: { id: "account_id", link: "/accounts/" },

    buyer_contract: { id: "contract_id", link: "/contract/contract-details/" },

    purchase_contract: {
      id: "purchase_contract_id",
      link: "/contract-shipment/detail/",
    },
  });

  const renderTableRowsData = (data, type) => {
    switch (type) {
      case "headers":
        return data.map((col, index) => {
          if (!excludedFields.includes(col.field)) {
            return (
              <TableCell sx={{ width: 200 }} key={index}>
                {col.headerName}
              </TableCell>
            );
          }
          return null;
        });

      case "rows":
        return data.map((row, rowIndex) => (
          <TableRow key={rowIndex}>
            {columns.map((col, colIndex) => {
              if (!excludedFields.includes(col.field)) {
                return (
                  <TableCell sx={{ width: 200 }} key={colIndex}>
                    {linkFields.hasOwnProperty(col.field) ? (
                      <Link
                        to={`${linkFields[col.field].link}${
                          row[linkFields[col.field].id]
                        }`}
                      >
                        {row[col.field]}
                      </Link>
                    ) : (
                      row[col.field]
                    )}
                  </TableCell>
                );
              }
              return null;
            })}
          </TableRow>
        ));

      default:
        return null;
    }
  };

  const getColumnsData = (data) => {
    //desired order

    const desiredOrder = [
      "product_no",
      "product_master",
      "product_name",
      "product_code",
      "buyer_contract",
      "contract_shipment",
      "quantity_MT",
      "product_status",
    ];

    // Get the keys from the data
    const dataKeys = Object.keys(data[0] || {});

    const columnsData = desiredOrder

      .filter((key) => dataKeys.includes(key))

      .map((key) => ({
        field: key,
        headerName: formatHeaderName(key),
      }));

    const remainingColumns = dataKeys

      .filter((key) => !desiredOrder.includes(key))

      .map((key) => ({
        field: key,
        headerName: formatHeaderName(key),
      }));

    return [...columnsData, ...remainingColumns];
  };

  // Function To Fetch Created Views //
  const fetchContractViews = async () => {
    try {
      setLoading(true);

      const createViewResponse = await axios.get(
        `${API_URL}get/all/product_createview`
      );

      if (createViewResponse.status === 200) {
        const viewData = createViewResponse.data.product_createview;

        console.log(viewData, "viewData");
        setproductViews(viewData);

        setLoading(false);

        setIsOptionsLoaded(true);
      }
    } catch (err) {
      console.log("An Error Occurred", err);
    }
  };

  // const handleViewFocus = () => {

  //   !isOptionsLoaded && fetchContractViews();

  // };

  const handleViewChange = (e) => {
    const { value, selectedIndex } = e.target;

    const selectedOptionId = e.target.options[selectedIndex].id;

    setSelectedViewId(selectedOptionId);

    setSelectedView(value);

    setRequestDataType("viewmodifier");

    sessionStorage.setItem("productView", value);
    sessionStorage.setItem("productViewId", selectedOptionId);
  };

  // On Component Load Set View To Previous //

  // useEffect(() => {
  //   fetchContractViews();

  //   const productViewName = sessionStorage.getItem("productView");

  //   const productViewNameId = sessionStorage.getItem("productViewId");

  //   const productViewEdit = sessionStorage.getItem("productViewEdit");

  //   setSelectedView(productViewName);

  //   setSelectedViewId(productViewNameId);

  //   productViewEdit === "true" && setRequestDataType("viewmodifier");

  //   sessionStorage.removeItem("productViewEdit");
  // }, []);

  useEffect(() => {
    fetchContractViews();

    const productViewName = sessionStorage.getItem("productView");

    const productViewNameId = sessionStorage.getItem("productViewId");

    const productViewEdit = sessionStorage.getItem("productViewEdit");
    const isproductCreateView = sessionStorage.getItem("isproductCreateView");

    setSelectedView(productViewName);

    setSelectedViewId(productViewNameId);

    if (
      productViewEdit &&
      productViewEdit === "true" &&
      productViewName &&
      productViewNameId
    ) {
      setRequestDataType("viewmodifier");
    } else if (
      isproductCreateView &&
      isproductCreateView === "true" &&
      productViewName &&
      productViewNameId
    ) {
      setRequestDataType("viewmodifier");
    } else {
      setRequestDataType("recentmodifier");

      setModifiedEntriesLabel("recently_created");
    }

    sessionStorage.removeItem("productViewEdit");
    sessionStorage.removeItem("isproductCreateView");
  }, []);
  // Fetching View Based Data //

  const fetchViewData = async () => {
    try {
      setLoading(true);

      const URL = `${API_URL}get/newproduct/view`;

      const response = await axios.get(URL, {
        headers: {
          Authorization: `Bearer ${decryptedAccessToken}`,
          "Content-Type": "application/json",
        },

        params: {
          page: page + 1,
          page_size: rowsPerPage,
          view_name: selectedView,
        },
      });

      if (response.status === 200) {
        const data = response.data.products || [];

        if (data.length > 0) {
          setAllTableData(data);

          setTotalRecords(response.data.total || 0);

          const columnData = getColumnsData(data);

          setColumns(columnData);
        } else {
          setAllTableData([]);

          setTotalRecords(0);

          setColumns([]);
        }
      }
    } catch (err) {
      console.log("An Error Occurred While Retriving View Data", err);
    } finally {
      setLoading(false);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getModifiedEntries = async () => {
    setLoading(true);

    const URL = `${API_URL}get/all/newproducts/new`;

    try {
      const response = await axios.get(URL, {
        headers: {
          Authorization: `Bearer ${decryptedAccessToken}`,
          "Content-Type": "application/json",
        },

        params: {
          random_dropdown_option: modifiedEntriesLabel,
          page: page + 1,
          page_size: rowsPerPage,
        },
      });

      if (response.status === 200) {
        const data = response.data.products || [];

        if (data.length > 0) {
          setAllTableData(data);

          setTotalRecords(response.data.total);

          const columnData = getColumnsData(data);

          setColumns(columnData);
        } else {
          setAllTableData([]);

          setTotalRecords(0);

          setColumns([]);
        }
      }
    } catch (err) {
      console.log("An Error Occurred While Getting Modified Entries");
    } finally {
      setLoading(false);
    }
  };

  const navigateViewEdit = (e) => {
    e.preventDefault();

    sessionStorage.setItem("productViewEdit", true);

    navigate(`/product/product-edit-view/${selectedViewId}`);
  };

  const navigateCreateView = (e) => {
    e.preventDefault();

    sessionStorage.setItem("productViewEdit", true);

    navigate(`/product/product-create-view`);
  };

  const navigateViewDelete = () => {};

  //  set page to 0 whenever request data type changes //
  useEffect(() => {
    setPage(0);
  }, [requestDatatype]);

  useEffect(() => {
    requestDatatype === "recentmodifier" && getModifiedEntries();
  }, [modifiedEntriesLabel, page, rowsPerPage]);

  // Fetch Selected View //

  useEffect(() => {
    requestDatatype === "viewmodifier" && fetchViewData();
  }, [selectedView, page, rowsPerPage]);

  //// accounts add

  ///// account end

  return (
    <>
      <Header />

      <div className="dashboard-container">
        <div className="recent-items">
          <h2>Recent Items</h2>

          <RecentItems />
        </div>

        <div className="accounts">
          <div className="accounts-header">
            <h2>Product</h2>

            <div className="header-actions"></div>
          </div>

          <div className="accounts-content">
            <div className="view-controls">
              <div className="view_label">
                <a>View:</a>
              </div>

              <div className="select-container">
                <select value={selectedView} onChange={handleViewChange}>
                  <option>Select a view</option>

                  {productViews.length > 0 ? (
                    productViews.map((option) => (
                      <option
                        key={option.id}
                        id={option.id}
                        value={option.unique_name}
                      >
                        {option.view_name}
                      </option>
                    ))
                  ) : (
                    <option disabled>No Data Available</option>
                  )}
                </select>
              </div>

              <div className="button-container">
                <button onClick={fetchViewData}>Go</button>
              </div>

              <div className="view_actions">
                <span className="rwood_view_edit" onClick={navigateViewEdit}>
                  Edit
                </span>{" "}
                |{" "}
                <span className="rwood_view_edit" onClick={navigateViewDelete}>
                  Delete
                </span>{" "}
                |{" "}
                <span className="rwood_view_edit" onClick={navigateCreateView}>
                  Create New View
                </span>
              </div>
            </div>

            <div className="recent-accounts">
              <div className="recemt_contract_content">
                <div className="account-search">
                  <p>Recent Products</p>

                  <div className="select-modifier-container">
                    <select onChange={handleRandomDropdownChange}>
                      <option value="recently_created">Recently Created</option>
                      <option value="recently_modified">
                        Recently Modified
                      </option>
                      <option value="recently_viewed">Recently Viewed</option>
                    </select>
                  </div>
                </div>

                <div className="data_table">
                  {!isLoading ? (
                    allTableData.length > 0 ? (
                      <TableContainer component={Paper}>
                      <Table
                        sx={{ tableLayout: "fixed", width: "100%" }}
                        size="small"
                        aria-label="a dense table"
                      >
                        <TableHead sx={{ background: "#f7f7f7" }}>
                          <TableRow>
                            {columns.map(
                              (col, index) =>
                                col.field !== "id" && (
                                  <TableCell
                                    sx={{ width: 200, cursor: "pointer" }}
                                    key={index}
                                    onClick={() => handleSort(col.field)}
                                  >
                                    {col.headerName}
                                    {/* Add icons or indicators for sort direction */}
                                    {sortConfig.key === col.field && (
                                      <span>
                                        {sortConfig.direction === "asc"
                                          ? " 🔼"
                                          : " 🔽"}
                                      </span>
                                    )}
                                  </TableCell>
                                )
                            )}
                          </TableRow>
                        </TableHead>
                           <TableBody>
                          {renderTableRowsData(allTableData, "rows")}
                        </TableBody>
                      </Table>

                      <TablePagination
                        component="div"
                        count={totalRecords}
                        page={page}
                        onPageChange={handlePageChange}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleRowsPerPageChange}
                      />
                    </TableContainer>
                    ) : (
                      <div className="contract_data_status">
                        <h3>No Data Available</h3>
                      </div>
                    )
                  ) : (
                    <div className="rwood_spinner">
                      <img src="../../../images/loader.svg" alt="spinner" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default ProductList;
