// import React, { useState, useEffect, useRef } from "react";

// import axios from "axios";

// import { AES, enc } from "crypto-js";
// import { API_URL } from "../config";
// import './contract.css'

// import { Link, useNavigate } from "react-router-dom";

// import Header from "../Header";
// import Footer from "../Footer";

// import {
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   TablePagination,
// } from "@mui/material";

// import Popup from "reactjs-popup";
// import "reactjs-popup/dist/index.css";
// import { AiFillDiff } from "react-icons/ai";
// import { FaPencilAlt, FaTrashAlt, FaPlus } from "react-icons/fa";
// import RecentItems from "../recentItems/RecentItems";

// // import { AiFillDiff } from "react-icons/ai";
// // import Popup from "reactjs-popup";
// // import axios from "axios";
// // import { Link, useNavigate } from "react-router-dom";
// // import { AES, enc } from "crypto-js";
// // import { API_URL } from "../config";
// // import { FaPencilAlt, FaTrashAlt, FaPlus } from "react-icons/fa";

// // import "reactjs-popup/dist/index.css";
// // import { set } from "date-fns";

// const  ContractList= () => {

//   const [isLoading, setLoading] = useState(false);
//   const [contractViews, setContractViews] = useState([]);
//   const [isOptionsLoaded, setIsOptionsLoaded] = useState(false);
//   const [selectedView, setSelectedView] = useState("all")
//   const [selectedViewId, setSelectedViewId] = useState("")

//   const [requestDatatype, setRequestDataType] = useState("recentmodifier")

//   const [allTableData, setAllTableData] = useState([]);
//   const [page, setPage] = useState(0);
//   const [rowsPerPage, setRowsPerPage] = useState(10);
//   const [totalRecords, setTotalRecords] = useState(0);
//   const [columns, setColumns] = useState([]);

//   const [modifiedEntriesLabel, setModifiedEntriesLabel] = useState("recently_created")z

//   const navigate = useNavigate();

//   const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
//   const encryptedAccessToken = localStorage.getItem("access_token");
//   const decryptedAccessToken = encryptedAccessToken ? AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8) : null;

//   // Function To Handle Recent Modifiers //

//   const handleRandomDropdownChange = (e) => {

//     const modifiedEntry = e.target.value

//     setModifiedEntriesLabel(modifiedEntry)

//     setRequestDataType("recentmodifier")

//   }

//   // Function To Handle Page Change //

//   const handlePageChange = (event, newPage) => {

//     setPage(newPage);

//   };

//   // Function To Handle Rows Per Change //

//   const handleRowsPerPageChange = (event) => {

//     setRowsPerPage(parseInt(event.target.value, 10));
//     setPage(0);

//   };

//   // Formatting Table Header Data //

//   const formatHeaderName = (key) => {

//     const replaced = key.replace(/_/g, ' ').toLowerCase();
//     return replaced.replace(/\b\w/g, char => char.toUpperCase());

//   };

//   const getColumnsData = (data) => {

//     //desired order

//     const desiredOrder = [

//       "contract_record_type",
//       "contract_number",
//       "account_name",
//       "supplier",
//       "contract_status",
//       "destination",
//       "company",
//       "no_of_orders",
//       "no_of_shipments"

//     ]

//     // Get the keys from the data
//     const dataKeys = Object.keys(data[0] || {});

//     const columnsData = desiredOrder

//       .filter(key => dataKeys.includes(key))

//       .map((key) => ({

//         field: key,
//         headerName: formatHeaderName(key),

//       }));

//     const remainingColumns = dataKeys

//       .filter(key => !desiredOrder.includes(key))

//       .map((key) => ({

//         field: key,
//         headerName: formatHeaderName(key),

//       }));

//     return [...columnsData, ...remainingColumns];

//   }

//   // Function To Fetch Created Views //
//   const fetchContractViews = async () => {

//     try {

//       setLoading(true)

//       const createViewResponse = await axios.get(`${API_URL}get/all/contractcreateview`)

//       if (createViewResponse.status === 200) {

//         const viewData = createViewResponse.data.contract_create_view;

//         console.log(viewData, 'viewData')
//         setContractViews(viewData)

//         setLoading(false)

//         setIsOptionsLoaded(true)

//       }

//     }
//     catch (err) {

//       console.log("An Error Occurred", err)

//     }

//   }

//   // const handleViewFocus = () => {

//   //   !isOptionsLoaded && fetchContractViews();

//   // };

//   const handleViewChange = (e) => {

//     const { value, selectedIndex } = e.target

//     const selectedOptionId = e.target.options[selectedIndex].id;

//     setSelectedViewId(selectedOptionId)

//     setSelectedView(value)

//     setRequestDataType("viewmodifier")

//     sessionStorage.setItem("contractView", value)
//     sessionStorage.setItem("contractViewId", selectedOptionId)

//   }

//   // On Component Load Set View To Previous //

//   useEffect(() => {

//     fetchContractViews()

//     const viewName = sessionStorage.getItem("contractView")

//     const viewNameId = sessionStorage.getItem("contractViewId")

//     const viewEdit = sessionStorage.getItem("viewEdit")

//     const isCreateView = sessionStorage.getItem("isCreateView")

//     setSelectedView(viewName)

//     setSelectedViewId(viewNameId)

//     if (viewEdit && viewEdit === "true" && viewName && viewNameId) {

//       setRequestDataType("viewmodifier")

//     } else if (isCreateView && isCreateView === "true" && viewName && viewNameId) {

//       setRequestDataType("viewmodifier")

//     } else {

//       setRequestDataType("recentmodifier")

//       setModifiedEntriesLabel("recently_created")

//     }

//     sessionStorage.removeItem("viewEdit");
//     sessionStorage.removeItem("isCreateView")

//   }, [])
//   // useEffect(() => {

//   //   fetchContractViews()

//   //   const viewName = sessionStorage.getItem("contractView")

//   //   const viewNameId = sessionStorage.getItem("contractViewId")

//   //   const viewEdit = sessionStorage.getItem("viewEdit")

//   //   setSelectedView(viewName)

//   //   setSelectedViewId(viewNameId)

//   //   viewEdit==="true" && setRequestDataType("viewmodifier")

//   //   sessionStorage.removeItem("viewEdit");

//   // }, [])

//   // Fetching View Based Data //

//   const fetchViewData = async () => {

//     try {

//       setLoading(true)

//       const URL = `${API_URL}get/contract/view`

//       const response = await axios.get(URL, {

//         headers: {

//           Authorization: `Bearer ${decryptedAccessToken}`,
//           "Content-Type": "application/json",

//         },

//         params: {

//           page: page + 1,
//           page_size: rowsPerPage,
//           view_name: selectedView

//         }

//       });

//       if (response.status === 200) {

//         const data = response.data.contracts || [];

//         if (data.length > 0) {

//           setAllTableData(data);

//           setTotalRecords(response.data.total || 0);

//           const columnData = getColumnsData(data)

//           setColumns(columnData)

//         } else {

//           setAllTableData([]);

//           setTotalRecords(0)

//           setColumns([])

//         }

//       }

//     }

//     catch (err) {

//       console.log("An Error Occurred While Retriving View Data", err)

//     }

//     finally {

//       setLoading(false)

//     }

//   }

//   // eslint-disable-next-line react-hooks/exhaustive-deps
//   const getModifiedEntries = async () => {

//     setLoading(true)

//     const URL = `${API_URL}get/all/contract/new`

//     try {

//       const response = await axios.get(URL, {

//         headers: {

//           Authorization: `Bearer ${decryptedAccessToken}`,
//           "Content-Type": "application/json",

//         },

//         params: {

//           random_dropdown_option: modifiedEntriesLabel,
//           page: page + 1,
//           page_size: rowsPerPage,

//         },

//       });

//       if (response.status === 200) {

//         const data = response.data.contracts || [];

//         if (data.length > 0) {

//           setAllTableData(data);

//           setTotalRecords(response.data.total)

//           const columnData = getColumnsData(data)

//           setColumns(columnData)

//         } else {

//           setAllTableData([]);

//           setTotalRecords(0)

//           setColumns([])

//         }

//       }

//     } catch (err) {

//       console.log("An Error Occurred While Getting Modified Entries")

//     } finally {

//       setLoading(false)

//     }

//   }

//   const navigateViewEdit = (e) => {

//     e.preventDefault()

//     // sessionStorage.setItem("viewEdit", true)

//     navigate(`/contract/edit/view/${selectedViewId}`)

//   }

//   const navigateCreateView = (e) => {

//     e.preventDefault()

//     // sessionStorage.setItem("viewEdit", true)

//     navigate(`/contract/create/view`)

//   }

//   const navigateViewDelete = () => {

//   }

//   //  set page to 0 whenever request data type changes //
//   useEffect(() => {
//     if(requestDatatype){
//       setPage(0)
//     }

//   },[requestDatatype])

//   useEffect(() => {

//     requestDatatype === "recentmodifier" && getModifiedEntries()

//   }, [modifiedEntriesLabel, page, rowsPerPage])

//   // Fetch Selected View //

//   useEffect(() => {

//     requestDatatype === "viewmodifier" && fetchViewData()

//   }, [selectedView, page, rowsPerPage])

//   //// add new contrcat

//   const [formData, setFromdata] = useState({
//     account_record: "",
//   });

//   const [error, setError] = useState({});
//   const validate = () => {
//     const newErrors = {};
//     if (!formData.account_record) {
//       newErrors.account_record = "Contract Record Type is required";
//     }
//     return newErrors;
//   };
//   const handleChange = (e) => {
//     setFromdata({ ...formData, [e.target.name]: e.target.value });
//   };

//   const handleSubmit = (event) => {
//     event.preventDefault();
//     const validationErrors = validate();
//     if (Object.keys(validationErrors).length > 0) {
//       setError(validationErrors);
//     } else {
//       const encryptedAccessToken = localStorage.getItem("access_token");
//       const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
//       if (encryptedAccessToken) {
//         const decryptedAccessToken = AES.decrypt(
//           encryptedAccessToken,
//           secret_key
//         ).toString(enc.Utf8);

//         fetch(`${API_URL}add/record`, {
//           method: "POST",
//           body: JSON.stringify(formData),
//           headers: {
//             Authorization: `Bearer ${decryptedAccessToken}`,
//             "Content-Type": "application/json",
//           },
//         })
//           .then((response) => response.json())

//           .then((response) => {
//             console.log(response);
//             console.log(response.status);
//             const record_type_new = response.account_type;

//             navigate("/contract/add-contract", {
//               state: { account_record: record_type_new },
//             });
//             // if (response.message === "record addedd successfully") {

//             //                 }
//           });
//       }
//     }
//   };

//   ///recent items
//   const [recentItems, setRecentItems]=useState([])

// const fetchRecent = async () => {
//   try {
//     const response = await axios.get(`${API_URL}get/recent_items`);
//     console.log(response)
//     if(response.status===200){
//       setRecentItems(response.data.recent_items)
//     }

//   } catch (error) {
//     console.error(error);
//   }
//   finally{
//     setLoading(false);
//   }
// };
// useEffect(() => {

//   fetchRecent();
// }, []);

//   return (

//     <>

//       <Header />

//       <div className="dashboard-container">

//         <div className="recent-items">

//         <h2>Recent Items</h2>

// <RecentItems/>

//         </div>

//         <div className="accounts">

//           <div className="accounts-header">

//             <h2>Contracts</h2>

//             <div className="header-actions">

//             <Popup
//                 trigger={
//                   <button className="add-account-btn">+ Add Contract</button>
//                 }
//                 modal
//               >
//                  <form onSubmit={handleSubmit} className="add-accounts">
//                   <div className="form-group">
//                     <label>Account Record Type</label>
//                     <br />
//                     <select
//                       name="account_record"
//                       className="form-control"
//                       onChange={handleChange}
//                       value={formData.account_record}
//                     >
//                       <option>--Select Contract Type--</option>
//                       <option value="Buyer - Long Term">Buyer Long Term</option>
//                       <option value="Buyer - Spot">Buyer Spot</option>
//                       <option value="Supplier - Long Term">
//                         Supplier Long Term
//                       </option>
//                       {/* <option value='Supplier'>Supplier</option> */}
//                       <option value="Supplier - Spot">Supplier Spot</option>
//                     </select>
//                     {error.account_record && (
//                       <div className="errors-msg">{error.account_record}</div>
//                     )}
//                   </div>
//                   <input
//                     type="submit"
//                     className="btn btn-primary "
//                     value="Submit"
//                   />
//                 </form>
//               </Popup>

//             </div>

//           </div>

//           <div className="accounts-content">

//             <div className="view-controls">

//               <div className="view_label">

//                 <a>View:</a>

//               </div>

//               <div className="select-container">

//                 <select
//                   value={selectedView}
//                   onChange={handleViewChange}
//                 >
//                   <option>Select a view</option>

//                   {contractViews.length > 0 ? (

//                     contractViews.map(option => (

//                       <option key={option.id} id={option.id} value={option.unique_name}>

//                         {option.view_name}

//                       </option>

//                     ))

//                     ) : (

//                       <option disabled>No Data Available</option>

//                     )
//                   }

//                 </select>

//               </div>

//               <div className="button-container">

//                 <button onClick={fetchViewData}>Go</button>

//               </div>

//               <div className="view_actions">

//                 <span className="rwood_view_edit" onClick={navigateViewEdit}>Edit</span> | <span className="rwood_view_edit" onClick={navigateViewDelete}>Delete</span> | <span className="rwood_view_edit" onClick={navigateCreateView}>Create New View</span>

//               </div>

//             </div>

//             <div className="recent-accounts">

//               <div className="recemt_contract_content">

//                 <div className="account-search">

//                   <p>Recent Contracts</p>

//                   <div className="select-modifier-container">

//                     <select onChange={handleRandomDropdownChange}>

//                       <option value="recently_created">Recently Created</option>
//                       <option value="recently_modified">Recently Modified</option>
//                       <option value="recently_viewed">Recently Viewed</option>

//                     </select>

//                   </div>

//                 </div>

//                 <div className="data_table">

//                   {!isLoading ? (
//                     allTableData.length > 0 ? (
//                       <TableContainer component={Paper}>
//                         <Table sx={{ tableLayout: 'fixed', width: '100%' }} size="small" aria-label="a dense table">
//                           <TableHead sx={{ background: '#f7f7f7' }}>
//                             <TableRow>
//                               {columns.map((col, index) => (

//                                 col.field !== 'id' && (
//                                   <TableCell sx={{ width: 200 }} key={index}>
//                                     {col.headerName}
//                                   </TableCell>
//                                 )
//                               ))}
//                             </TableRow>
//                           </TableHead>
//                           <TableBody>
//                             {allTableData.map((row, rowIndex) => (
//                               <TableRow key={rowIndex}>
//                                 {columns.map((col, colIndex) => (
//                                   col.field !== 'id' && (
//                                     <TableCell sx={{ width: 200 }} key={colIndex}>
//                                       {col.field === 'contract_number' ? (
//                                         <Link to={`/contract/contract-details/${row.id}`}>{row[col.field]}</Link>
//                                       ) : (
//                                         row[col.field]
//                                       )}
//                                     </TableCell>
//                                   )
//                                 ))}
//                               </TableRow>
//                             ))}
//                           </TableBody>
//                         </Table>

//                         <TablePagination
//                           component="div"
//                           count={totalRecords}
//                           page={page}
//                           onPageChange={handlePageChange}
//                           rowsPerPage={rowsPerPage}
//                           onRowsPerPageChange={handleRowsPerPageChange}
//                         />
//                       </TableContainer>
//                     ) : (
//                       <div className="contract_data_status"><h3>No Data Available</h3></div>
//                     )
//                   ) : (
//                     <div className="rwood_spinner">
//                       <img src="../../../images/loader.svg" alt="spinner" />
//                     </div>
//                   )}

//                 </div>

//               </div>

//             </div>

//           </div>

//         </div>

//       </div>

//       <Footer />

//     </>

//   );

// }

// export default ContractList

import React, { useState, useEffect, useRef } from "react";

import axios from "axios";

import { AES, enc } from "crypto-js";
import { API_URL } from "../config";
import "./contract.css";

import { Link, useNavigate } from "react-router-dom";

import Header from "../Header";
import Footer from "../Footer";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from "@mui/material";

import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { AiFillDiff } from "react-icons/ai";
import { FaPencilAlt, FaTrashAlt, FaPlus } from "react-icons/fa";
import RecentItems from "../recentItems/RecentItems";

// import { AiFillDiff } from "react-icons/ai";
// import Popup from "reactjs-popup";
// import axios from "axios";
// import { Link, useNavigate } from "react-router-dom";
// import { AES, enc } from "crypto-js";
// import { API_URL } from "../config";
// import { FaPencilAlt, FaTrashAlt, FaPlus } from "react-icons/fa";

// import "reactjs-popup/dist/index.css";
// import { set } from "date-fns";

const ContractList = () => {
  const [isLoading, setLoading] = useState(false);
  const [contractViews, setContractViews] = useState([]);
  const [isOptionsLoaded, setIsOptionsLoaded] = useState(false);
  const [selectedView, setSelectedView] = useState("all");
  const [selectedViewId, setSelectedViewId] = useState("");

  const [requestDatatype, setRequestDataType] = useState("recentmodifier");

  const [allTableData, setAllTableData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [columns, setColumns] = useState([]);


  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });

  // Function to handle sorting logic
  const handleSort = (columnKey) => {
    let direction = "asc";
    if (sortConfig.key === columnKey && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key: columnKey, direction });
    sortTableData(columnKey, direction);
  };

  // Function to sort table data
  const sortTableData = (columnKey, direction) => {
    const sortedData = [...allTableData].sort((a, b) => {
      if (a[columnKey] < b[columnKey]) {
        return direction === "asc" ? -1 : 1;
      }
      if (a[columnKey] > b[columnKey]) {
        return direction === "asc" ? 1 : -1;
      }
      return 0;
    });
    setAllTableData(sortedData); // Make sure you update your table data state
  };

  const [modifiedEntriesLabel, setModifiedEntriesLabel] =
    useState("recently_created");

  const [excludedFields, setExcludedFields] = useState([
    "id",
    "account_id",
    "supplier_id",
    "price_type_id",
    "price_book_id",
    "rwood_user_id",
    "quote_id",
    "rwood_company_id",
    "contact_id",
    "rwood_user_id",
  ]);
  const [linkFields, setLinkFields] = useState({
    contract_number: { id: "id", link: "/contract/contract-details/" },

    account_name: { id: "account_id", link: "/accounts/" },

    supplier: { id: "supplier_id", link: "/accounts/" },

    price_type: { id: "price_type_id", link: "/price/price-type-details/" },

    price_book: { id: "price_book_id", link: "/pricebook/pricebook-details/" },

    trader_name: { id: "rwood_user_id", link: "/user/user-details/" },

    company: { id: "rwood_company_id", link: "/company/company-details/" },
  });

  const navigate = useNavigate();

  const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
  const encryptedAccessToken = localStorage.getItem("access_token");
  const decryptedAccessToken = encryptedAccessToken
    ? AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8)
    : null;

  const renderTableRowsData = (data, type) => {
    switch (type) {
      case "headers":
        return data.map((col, index) => {
          if (!excludedFields.includes(col.field)) {
            return (
              <TableCell sx={{ width: 200 }} key={index}>
                {col.headerName}
              </TableCell>
            );
          }
          return null;
        });

      case "rows":
        return data.map((row, rowIndex) => (
          <TableRow key={rowIndex}>
            {columns.map((col, colIndex) => {
              if (!excludedFields.includes(col.field)) {
                return (
                  <TableCell sx={{ width: 200 }} key={colIndex}>
                    {linkFields.hasOwnProperty(col.field) ? (
                      <Link
                        to={`${linkFields[col.field].link}${
                          row[linkFields[col.field].id]
                        }`}
                      >
                        {row[col.field]}
                      </Link>
                    ) : (
                      row[col.field]
                    )}
                  </TableCell>
                );
              }
              return null;
            })}
          </TableRow>
        ));

      default:
        return null;
    }
  };

  // Function To Handle Recent Modifiers //

  const handleRandomDropdownChange = (e) => {
    const modifiedEntry = e.target.value;

    setModifiedEntriesLabel(modifiedEntry);

    setRequestDataType("recentmodifier");
  };

  // Function To Handle Page Change //

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  // Function To Handle Rows Per Change //

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Formatting Table Header Data //

  const formatHeaderName = (key) => {
    const replaced = key.replace(/_/g, " ").toLowerCase();
    return replaced.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const getColumnsData = (data) => {
    //desired order

    const desiredOrder = [
      "contract_record_type",
      "contract_number",
      "account_name",
      "supplier",
      "contract_status",
      "destination",
      "company",
      "no_of_orders",
      "no_of_shipments",
    ];

    // Get the keys from the data
    const dataKeys = Object.keys(data[0] || {});

    const columnsData = desiredOrder

      .filter((key) => dataKeys.includes(key))

      .map((key) => ({
        field: key,
        headerName: formatHeaderName(key),
      }));

    const remainingColumns = dataKeys

      .filter((key) => !desiredOrder.includes(key))

      .map((key) => ({
        field: key,
        headerName: formatHeaderName(key),
      }));

    return [...columnsData, ...remainingColumns];
  };

  // Function To Fetch Created Views //
  const fetchContractViews = async () => {
    try {
      setLoading(true);

      const createViewResponse = await axios.get(
        `${API_URL}get/all/contractcreateview`
      );

      if (createViewResponse.status === 200) {
        const viewData = createViewResponse.data.contract_create_view;

        console.log(viewData, "viewData");
        setContractViews(viewData);

        setLoading(false);

        setIsOptionsLoaded(true);
      }
    } catch (err) {
      console.log("An Error Occurred", err);
    }
  };

  // const handleViewFocus = () => {

  //   !isOptionsLoaded && fetchContractViews();

  // };

  const handleViewChange = (e) => {
    const { value, selectedIndex } = e.target;

    const selectedOptionId = e.target.options[selectedIndex].id;

    setSelectedViewId(selectedOptionId);

    setSelectedView(value);

    setRequestDataType("viewmodifier");

    sessionStorage.setItem("contractView", value);
    sessionStorage.setItem("contractViewId", selectedOptionId);
  };

  // On Component Load Set View To Previous //

  useEffect(() => {
    fetchContractViews();

    const viewName = sessionStorage.getItem("contractView");

    const viewNameId = sessionStorage.getItem("contractViewId");

    const viewEdit = sessionStorage.getItem("viewEdit");

    const isCreateView = sessionStorage.getItem("isCreateView");

    setSelectedView(viewName);

    setSelectedViewId(viewNameId);

    if (viewEdit && viewEdit === "true" && viewName && viewNameId) {
      setRequestDataType("viewmodifier");
    } else if (
      isCreateView &&
      isCreateView === "true" &&
      viewName &&
      viewNameId
    ) {
      setRequestDataType("viewmodifier");
    } else {
      setRequestDataType("recentmodifier");

      setModifiedEntriesLabel("recently_created");
    }

    sessionStorage.removeItem("viewEdit");
    sessionStorage.removeItem("isCreateView");
  }, []);

  const fetchViewData = async () => {
    try {
      setLoading(true);

      const URL = `${API_URL}get/contract/view`;

      const response = await axios.get(URL, {
        headers: {
          Authorization: `Bearer ${decryptedAccessToken}`,
          "Content-Type": "application/json",
        },

        params: {
          page: page + 1,
          page_size: rowsPerPage,
          view_name: selectedView,
        },
      });

      if (response.status === 200) {
        const data = response.data.contracts || [];

        if (data.length > 0) {
          setAllTableData(data);

          setTotalRecords(response.data.total || 0);

          const columnData = getColumnsData(data);

          setColumns(columnData);
        } else {
          setAllTableData([]);

          setTotalRecords(0);

          setColumns([]);
        }
      }
    } catch (err) {
      console.log("An Error Occurred While Retriving View Data", err);
    } finally {
      setLoading(false);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getModifiedEntries = async () => {
    setLoading(true);

    const URL = `${API_URL}get/all/contract/new`;

    try {
      const response = await axios.get(URL, {
        headers: {
          Authorization: `Bearer ${decryptedAccessToken}`,
          "Content-Type": "application/json",
        },

        params: {
          random_dropdown_option: modifiedEntriesLabel,
          page: page + 1,
          page_size: rowsPerPage,
        },
      });

      if (response.status === 200) {
        const data = response.data.contracts || [];

        if (data.length > 0) {
          setAllTableData(data);

          setTotalRecords(response.data.total);

          const columnData = getColumnsData(data);

          setColumns(columnData);
        } else {
          setAllTableData([]);

          setTotalRecords(0);

          setColumns([]);
        }
      }
    } catch (err) {
      console.log("An Error Occurred While Getting Modified Entries");
    } finally {
      setLoading(false);
    }
  };

  const navigateViewEdit = (e) => {
    e.preventDefault();

    // sessionStorage.setItem("viewEdit", true)

    navigate(`/contract/edit/view/${selectedViewId}`);
  };

  const navigateCreateView = (e) => {
    e.preventDefault();

    // sessionStorage.setItem("viewEdit", true)

    navigate(`/contract/create/view`);
  };

  const navigateViewDelete = () => {};

  //  set page to 0 whenever request data type changes //
  useEffect(() => {
    if (requestDatatype) {
      setPage(0);
    }
  }, [requestDatatype]);

  useEffect(() => {
    requestDatatype === "recentmodifier" && getModifiedEntries();
  }, [modifiedEntriesLabel, page, rowsPerPage]);

  // Fetch Selected View //

  useEffect(() => {
    requestDatatype === "viewmodifier" && fetchViewData();
  }, [selectedView, page, rowsPerPage]);

  //// add new contrcat

  const [formData, setFromdata] = useState({
    account_record: "",
  });

  const [error, setError] = useState({});
  const validate = () => {
    const newErrors = {};
    if (!formData.account_record) {
      newErrors.account_record = "Contract Record Type is required";
    }
    return newErrors;
  };
  const handleChange = (e) => {
    setFromdata({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setError(validationErrors);
    } else {
      const encryptedAccessToken = localStorage.getItem("access_token");
      const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
      if (encryptedAccessToken) {
        const decryptedAccessToken = AES.decrypt(
          encryptedAccessToken,
          secret_key
        ).toString(enc.Utf8);

        fetch(`${API_URL}add/record`, {
          method: "POST",
          body: JSON.stringify(formData),
          headers: {
            Authorization: `Bearer ${decryptedAccessToken}`,
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())

          .then((response) => {
            console.log(response);
            console.log(response.status);
            const record_type_new = response.account_type;

            navigate("/contract/add-contract", {
              state: { account_record: record_type_new },
            });
            // if (response.message === "record addedd successfully") {

            //                 }
          });
      }
    }
  };

  ///recent items
  const [recentItems, setRecentItems] = useState([]);

  const fetchRecent = async () => {
    try {
      const response = await axios.get(`${API_URL}get/recent_items`);
      console.log(response);
      if (response.status === 200) {
        setRecentItems(response.data.recent_items);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchRecent();
  }, []);

  return (
    <>
      <Header />

      <div className="dashboard-container">
        <div className="recent-items">
          <h2>Recent Items</h2>

          <RecentItems />
        </div>

        <div className="accounts">
          <div className="accounts-header">
            <h2>Contracts</h2>

            <div className="header-actions">
              <Popup
                trigger={
                  <button className="add-account-btn">+ Add Contract</button>
                }
                modal
              >
                <form onSubmit={handleSubmit} className="add-accounts">
                  <div className="form-group">
                    <label>Account Record Type</label>
                    <br />
                    <select
                      name="account_record"
                      className="form-control"
                      onChange={handleChange}
                      value={formData.account_record}
                    >
                      <option>--Select Contract Type--</option>
                      <option value="Buyer - Long Term">Buyer Long Term</option>
                      <option value="Buyer - Spot">Buyer Spot</option>
                      <option value="Supplier - Long Term">
                        Supplier Long Term
                      </option>
                      {/* <option value='Supplier'>Supplier</option> */}
                      <option value="Supplier - Spot">Supplier Spot</option>
                    </select>
                    {error.account_record && (
                      <div className="errors-msg">{error.account_record}</div>
                    )}
                  </div>
                  <input
                    type="submit"
                    className="btn btn-primary "
                    value="Submit"
                  />
                </form>
              </Popup>
            </div>
          </div>

          <div className="accounts-content">
            <div className="view-controls">
              <div className="view_label">
                <a>View:</a>
              </div>

              <div className="select-container">
                <select value={selectedView} onChange={handleViewChange}>
                  <option>Select a view</option>

                  {contractViews.length > 0 ? (
                    contractViews.map((option) => (
                      <option
                        key={option.id}
                        id={option.id}
                        value={option.unique_name}
                      >
                        {option.view_name}
                      </option>
                    ))
                  ) : (
                    <option disabled>No Data Available</option>
                  )}
                </select>
              </div>

              <div className="button-container">
                <button onClick={fetchViewData}>Go</button>
              </div>

              <div className="view_actions">
                <span className="rwood_view_edit" onClick={navigateViewEdit}>
                  Edit
                </span>{" "}
                |{" "}
                <span className="rwood_view_edit" onClick={navigateViewDelete}>
                  Delete
                </span>{" "}
                |{" "}
                <span className="rwood_view_edit" onClick={navigateCreateView}>
                  Create New View
                </span>
              </div>
            </div>

            <div className="recent-accounts">
              <div className="recemt_contract_content">
                <div className="account-search">
                  <p>Recent Contracts</p>

                  <div className="select-modifier-container">
                    <select onChange={handleRandomDropdownChange}>
                      <option value="recently_created">Recently Created</option>
                      <option value="recently_modified">
                        Recently Modified
                      </option>
                      <option value="recently_viewed">Recently Viewed</option>
                    </select>
                  </div>
                </div>

                <div className="data_table">
                  {!isLoading ? (
                    allTableData.length > 0 ? (
                      <TableContainer component={Paper}>
                        <Table
                          sx={{ tableLayout: "fixed", width: "100%" }}
                          size="small"
                          aria-label="a dense table"
                        >
                          <TableHead sx={{ background: "#f7f7f7" }}>
                            <TableRow>
                              {columns.map(
                                (col, index) =>
                                  col.field !== "id" && (
                                    <TableCell
                                      sx={{ width: 200, cursor: "pointer" }}
                                      key={index}
                                      onClick={() => handleSort(col.field)}
                                    >
                                      {col.headerName}
                                      {/* Add icons or indicators for sort direction */}
                                      {sortConfig.key === col.field && (
                                        <span>
                                          {sortConfig.direction === "asc"
                                            ? " 🔼"
                                            : " 🔽"}
                                        </span>
                                      )}
                                    </TableCell>
                                  )
                              )}
                            </TableRow>
                          </TableHead>
                             <TableBody>
                            {renderTableRowsData(allTableData, "rows")}
                          </TableBody>
                        </Table>

                        <TablePagination
                          component="div"
                          count={totalRecords}
                          page={page}
                          onPageChange={handlePageChange}
                          rowsPerPage={rowsPerPage}
                          onRowsPerPageChange={handleRowsPerPageChange}
                        />
                      </TableContainer>
                    ) : (
                      <div className="contract_data_status">
                        <h3>No Data Available</h3>
                      </div>
                    )
                  ) : (
                    <div className="rwood_spinner">
                      <img src="../../../images/loader.svg" alt="spinner" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default ContractList;
