import React from 'react';

const Tooltip = ({ text, children, placement = 'top' }) => {
    return (
      <div className="tooltip-wrapper">
        {children}
        <div className={`tooltip-box tooltip-${placement}`}>
          {text}
        </div>
      </div>
    );
  };

export default Tooltip;
